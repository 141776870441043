import React from 'react';
import { BreedingRhombusSpinner } from 'react-epic-spinners';
import '../styles/RealmLoader.css';

const RealmLoader = ({ found }) => (
  <React.Fragment>
    {!found && (
      <div className='realm-loader'>
        <div className='container'>
          <h3 className='title'>Checking your browser...</h3>
          <BreedingRhombusSpinner className='spinner' color="#4285f4" size={75} />
        </div>
      </div>
    )}
  </React.Fragment>
)

export default RealmLoader;