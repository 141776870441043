import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiEnterpriseOtherBusiness } from '../../../../constants/api';

const apiEnterpriseBusinessIncomes = `${apiEnterpriseOtherBusiness}/incomes`;

export function fetchBusinessIncomes(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_BUSINESS_INCOMES`, `${apiEnterpriseBusinessIncomes}`, params);
}

export function fetchBusinessIncome(params) {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_BUSINESS_INCOME',
      `${apiEnterpriseBusinessIncomes}/${params.division_id}`,
      params
    );
}

export function createBusinessIncome(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_BUSINESS_INCOME`, `${apiEnterpriseBusinessIncomes}`, data);
}

export function updateBusinessIncome(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      `UPDATE_BUSINESS_INCOME`,
      `${apiEnterpriseBusinessIncomes}/${data.id}`,
      data
    );
}

export function removeBusinessIncome(id) {
  return async (dispatch) =>
    await remove(dispatch, `REMOVE_BUSINESS_INCOME`, `${apiEnterpriseBusinessIncomes}/${id}`);
}
