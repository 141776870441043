import { noop } from 'lodash';
import { Button } from 'reactstrap';

const LivestockAssumptionsToolbar = ({ onAdd = noop }) => (
  <div className="d-flex justify-content-end m-2">
    <Button size="sm" color="success" onClick={onAdd}>
      Add Benchmark Class
    </Button>
  </div>
);

export default LivestockAssumptionsToolbar;
