import React, { useState } from 'react';
import {
  Button
} from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';

import DirectDownload from '../../../../core/form/components/DirectDownload';
import ProjectStageModal from "../../containers/ProjectStageModal";
import StageRequirementsLsv from "./StageRequirementsLsv";

const StagesLsv = (props) => {
  const {
    stages,
    project_id,
    group_id,
    history,
    unlockTemplate,
    checkAccess,
    handleStageDownload,
    handleRequirementDownload,
    fetchStages
  } = props;

  const iconName = 'layer-group';
  const emptyCaption = "No project stages found";

  const [isOpen, setModal] = useState(false);
  const [id, setId] = useState(null);

  const createRequirement = (stageId, requirement_id) => {
    console.log("create", stageId, requirement_id)
    const lnk = `/home/projects/${project_id}/stage/${stageId}/requirements/${requirement_id}`;
    history.push(lnk);
  }

  const editStage = (id) => {
    setId(id);
    setModal(true);
  }

  let sections = (
    <div className="p-5 text-center">
      <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
      <div className="mt-3">{emptyCaption}</div>
    </div>
  )

  if (stages.length > 0) {
    const filteredStages = stages.filter(stage => stage.group_id === group_id)
    sections = filteredStages.map((stage, index) => {
      return (
        <div key={index} className="mb-3 border rounded bg-white">
          <div className="d-flex justify-content-between flex-row p-2 bg-light">
            <div className="d-flex flex-row">
              <h5 className='text-success'>{stage.name} </h5>
            </div>
            <div className="d-flex flex-row">
              <DirectDownload
                classNames="ml-2 mr-2"
                buttonType="link"
                caption="Download Items"
                handleDownload={(onProgressChange) => handleStageDownload(stage.id, onProgressChange)}
                size="sm"
                showLabel={false}
                width={185}
                showIcon={true}
              />
              <div>
                {unlockTemplate && (
                  <div>
                    <Button onClick={() => editStage(stage.id)} color="link" size="sm" className="mr-2">Edit</Button>
                    <Button onClick={() => createRequirement(stage.id, 'new')} color="link" size="sm">Add Requirement</Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <StageRequirementsLsv rows={stage.requirements} history={history} project_id={project_id} handleDownload={handleRequirementDownload} />
        </div>
      )
    })
  }

  return (
    <React.Fragment>
      <div >
        {(unlockTemplate || stages.length === 0) && (
          <div className="d-flex justify-content-end mb-2">
            <Button disabled={!checkAccess('projectUpdate')} size="sm" color="link" onClick={() => editStage(null)}>Add New Stage</Button>
          </div>
        )}
        <ProjectStageModal fetchStages={fetchStages} setModal={setModal} group_id={group_id} id={id} isOpen={isOpen} />
        {sections}
      </div>
    </React.Fragment>
  );
}

export default StagesLsv;
