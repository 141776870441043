import { allowed, defaults } from '../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    responseMessage: null,
    advisors: { count: 0, rows: [] },
  },
  action,
) {
  // Set expected reducer types
  const types = ['ADMIN'];

  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_ADMIN_ADVISORS_FULFILLED': {
      return {
        ...updatedState,
        advisors: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
