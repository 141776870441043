export const defaults = (action) => {
  const { type } = action;
  const { payload } = action;

  // Account for general types
  switch (true) {
    // Ending with PENDING
    case /_PENDING$/.test(type): {
      return {
        fetching: true,
        fetched: false,
        authResponseMessage: null,
        responseMessage: null,
      };
    }

    // Ending with FULFILLED
    case /_FULFILLED$/.test(type): {
      return {
        fetching: false,
        fetched: true,
        authorised: true,
        responseMessage: null,
      };
    }

    // Starting with SET
    // Starting with RESET
    case /^SET_/.test(type):
    case /^RESET_/.test(type): {
      return {
        fetching: false,
        fetched: true,
        responseMessage: null,
      };
    }

    // Ending with REJECTED
    case /_REJECTED$/.test(type): {
      return {
        fetching: false,
        fetched: false,
        responseMessage: payload,
      };
    }
    // Ending with DENIED
    case /_DENIED$/.test(type): {
      return {
        fetching: false,
        fetched: false,
        authorised: false,
        authResponseMessage: payload,
      };
    }
    // Starting with UNSET
    case /^UNSET_/.test(type): {
      return {
        fetching: false,
        fetched: true,
        responseMessage: null,
      };
    }
    default:
      return {};
  }
};

export const allowed = (types, action) => {
  const action_type = action.type;
  const allowed = types.map((type) => action_type.includes(type)).filter(Boolean);
  return allowed.length > 0;
};
