import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormBase from 'jsx/components/core/form/components/FormBase';

import { cloneDeep } from 'lodash';
import { DaysheetComboFilter } from '../components/DaysheetComboFilter';

class DaysheetFilterModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      isValid: true,
      data: {},
      setModal: null,
      controls: cloneDeep(this.props.controls),
    };
  }

  render() {
    const { isModalOpen } = this.props;
    const iconName = 'filter';
    return (
      <Modal isOpen={isModalOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          Filters for Work Activity and Assets
        </ModalHeader>
        <ModalBody>
          <DaysheetComboFilter
            onFilter={(type, property, payload) => {
              this.props.clearActivity();
              this.props.onFilter(type, property, payload);
            }}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button
              size="sm"
              className="mr-2"
              color="light"
              onClick={() => this.props.setModal(false)}
            >
              Close
            </Button>
            <Button
              size="sm"
              className="mr-2"
              color="warning"
              onClick={() => this.props.onClearModalFilters()}
            >
              Clear Filters
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DaysheetFilterModal;
