/**
 * Utilities for decoding images from the database
 */

export const BASE_64_FLAG = 'data:image/jpeg;base64,';

export const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

/**
 * Converts a buffer to source. Assumes source is a image/jpeg
 * Usage: \<img src={convertBufferToImageSource(buffer)} />
 * @param {buffer} buffer
 * @returns {string} Source of image. Returns empty string if error with decoding
 */
export const convertBufferToImageSource = (buffer) => {
  if (!buffer) return '';
  const imgStr = arrayBufferToBase64(buffer);
  return `${BASE_64_FLAG}${imgStr}`;
};
