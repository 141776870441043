import React from 'react';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import WoolMenu from './WoolMenu';

const LivestockEnterpriseToolbar = ({
  onRefresh = noop,
  setWoolTransactionModal = noop,
  setWoolAdjustmentModal = noop,
}) => (
  <div className="d-flex justify-content-end">
    <WoolMenu
      setWoolTransactionModal={setWoolTransactionModal}
      setWoolAdjustmentModal={setWoolAdjustmentModal}
    />
    <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
      <Icon name="rotate" />
    </Button>
  </div>
);

export default LivestockEnterpriseToolbar;
