import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name:{
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Client Name',
    description: 'Required Field',
    validationRules: {
      isRequired: true
    },
  },
  crm_url:{
    ...controlDefaults,
    placeholder: '',
    name: 'crm_url',
    type: 'text',
    isUrl: true,
    caption: 'Salesforce Attendee URL',
    validationRules: {
      isRequired: false
    },
  }
};
