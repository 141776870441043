import { get, post, put, remove } from '../../../../lib/genericAction';

import {
  apiProjects,
  apiPeriods
} from '../../../../constants/api.js';

export function fetchPeriods(project_id) {
  return async dispatch => await get(
    dispatch,
    'FETCH_PERIODS',
    `${apiProjects}/${project_id}/periods`
  )
}

export function fetchPeriod(id) {
  return async dispatch => await get(
    dispatch,
    'FETCH_PERIOD',
    `${apiPeriods}/${id}`
  )
}

export function updatePeriod(data) {
  return async dispatch => await put(
    dispatch,
    'UPDATE_PERIOD',
    `${apiPeriods}/${data.id}`,
    data
  )
}

export function createPeriod(data) {
  return async dispatch => await post(
    dispatch,
    'CREATE_PERIOD',
    `${apiProjects}/${data.project_id}/periods`,
    data
  )
}

export function removePeriod(id) {
  return async dispatch => await remove(
    dispatch,
    'REMOVE_PERIOD',
    `${apiPeriods}/${id}`
  )
}