import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  version: {
    ...controlDefaults,
    placeholder: '',
    name: 'version',
    type: 'select',
    caption: 'Enabled',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    options: [],
  },
};
