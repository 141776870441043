import React, { useCallback, useEffect, useState } from 'react';
import { CustomInput } from 'reactstrap';
import FormInput from 'jsx/components/core/form/components/FormInput';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { noop } from 'lodash';

const enabledName = 'enabled';
const expiresEnabledName = 'expires';

const SubscriptionRow = ({ type, caption, recordUpdated = noop, orgId }) => {
  const { subscriptions: subscriptionDefs } = useSelector((state) => state.attributes);
  const { subscriptions: clientSubscriptions } = useSelector((state) => state.org_subscriptions);
  const currentDefinition = subscriptionDefs.find((def) => def.name === type);
  const existingSubscription = clientSubscriptions.find(
    (sub) => sub.subscription_id === currentDefinition?.id,
  );
  const [record, setRecord] = useState({});

  const updateRecord = useCallback(
    (newRecord) => {
      const fullRecord =
        newRecord === undefined
          ? newRecord
          : {
              ...newRecord,
              subscription_id: currentDefinition?.id,
              org_id: orgId,
            };
      setRecord(fullRecord);
      recordUpdated(type, fullRecord);
    },
    [currentDefinition?.id, orgId, recordUpdated, type],
  );

  const updateExpiry = (checked) => {
    if (checked) {
      updateRecord({ ...record, expires_on: moment().add(1, 'years') });
    } else {
      updateRecord({ ...record, expires_on: null });
    }
  };

  useEffect(() => {
    updateRecord(existingSubscription);
  }, [existingSubscription, updateRecord]);

  return (
    <tr className="py-2">
      <td>{caption}</td>
      <td>
        <CustomInput
          type="switch"
          role="switch"
          name={enabledName}
          id={`subscription-${type}-${enabledName}`}
          disabled={false}
          checked={Boolean(record?.enabled)}
          onChange={(e) => updateRecord({ ...record, enabled: e.target.checked })}
        />
      </td>
      <td>
        <CustomInput
          type="switch"
          role="switch"
          name={expiresEnabledName}
          id={`subscription-${type}-${expiresEnabledName}`}
          checked={Boolean(record?.expires_on)}
          disabled={!record?.enabled}
          onChange={(e) => updateExpiry(e.target.checked)}
        />
      </td>
      <td>
        <FormInput
          control={{
            type: 'date',
            placeholder: 'No expiry set',
            value: record?.expires_on,
          }}
          disabled={!record?.expires_on || !record.enabled}
          handleChange={(e) => updateRecord({ ...record, expires_on: e.target.value })}
        />
      </td>
      <td>
        <FormInput
          control={{
            type: 'text',
            value: record?.comments,
          }}
          disabled={!record?.enabled}
          handleChange={(e) => updateRecord({ ...record, comments: e.target.value })}
        />
      </td>
    </tr>
  );
};

export default SubscriptionRow;
