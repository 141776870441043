import React from 'react';
import moment from 'moment';
import { noop } from 'lodash';
import Listview from '../../../core/form/components/Listview';

const AgistmentIncomeLsv = ({ rows, onClick = noop }) => {
  const renderRows = (headers, record) => {
    const tableTd = headers.map(({ caption, field, classes, sortColumn }, index) => {
      switch (caption) {
        case 'From Date': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>
              {record[field] && record[field].name
                ? record[field].name
                : moment(record[field]).format('DD/MM/YYYY')}
            </td>
          );
        }
        case 'To Date': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>
              {record[field] ? moment(record[field]).format('DD/MM/YYYY') : '-'}
            </td>
          );
        }
        default:
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>
              {record[field] && record[field].name ? record[field].name : record[field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const iconName = 'handshake';
  const emptyCaption = 'No Agistment Expenses found';

  const headers = [
    { caption: 'Enterprise', field: 'enterprise', classes: 'text-left' },
    { caption: 'Property', field: 'property', classes: 'text-left' },
    { caption: 'Class', field: 'animal_class', classes: 'text-left' },
    { caption: 'Transaction', field: 'transaction_type', classes: 'text-left' },
    { caption: 'From Date', field: 'open_date', classes: 'text-left', sortColumn: 'open_date' },
    { caption: 'To Date', field: 'close_date', classes: 'text-left', sortColumn: 'close_date' },
    { caption: 'AU Rating', field: 'au_rating_lsu', classes: 'text-right' },
    { caption: 'Quantity', field: 'quantity_head', classes: 'text-right' },
    { caption: 'Value Per Head / Week', field: 'value_per_head_week', classes: 'text-right' },
    { caption: 'Area', field: 'area_ha', classes: 'text-right' },
  ];

  const tableHeadTh = headers.map(({ classes, caption }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = [];
  let finalRows = rows;
  if (rows?.rows) finalRows = rows.rows;

  const haveRows = finalRows && finalRows.length > 0;
  if (haveRows) {
    tableBodyTr = finalRows.map((row) => (
      <tr key={row.id} onClick={() => onClick(row.id)} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={finalRows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default AgistmentIncomeLsv;
