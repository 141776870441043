import * as React from 'react';
import { FieldValues, useForm, useFormContext } from 'react-hook-form';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fromPairs, isNil } from 'lodash';

import Icon from 'jsx/components/core/icons/Icon';
import ResponseMessage from 'jsx/components/core/form/components/ResponseMessageTab';
import {
  CarbonizerFormProvider,
  OnInvalid,
  OnSubmit,
  RenderInputs,
  Controls,
} from '../FormBuilder';

// Future enhancement - this should be typed based on the controls passed in
type ControlString = string;

type BaseGenericModalProps<T extends string> = {
  /**
   * Whether the modal is open or not
   */
  isOpen: boolean;
  /**
   * Icon to display in the Modal header
   */
  iconName?: string;
  /**
   * Title to display in the Modal header
   */
  title: string;
  /**
   * Form controls to render in the modal
   */
  controls: Controls<T>;
  /**
   * Error message to display in the modal
   */
  errorMessage?: string;
  /**
   * Callback for when the form is submitted but a field is invalid
   */
  onInvalid?: OnInvalid;
  /**
   * Form validation successful
   */
  onSubmit: OnSubmit;
  /**
   * Cancel and exit the modal
   */
  onCancel: (param: any) => void;
};

type RemovePropOnlyWhenNew =
  | {
      isNew: true;
    }
  | {
      /**
       * When modal is in edit mode, optionally allow onRemove handler
       */
      isNew: false;
      onRemove: () => void;
    };

export type GenericFormModalProps<T extends string> = BaseGenericModalProps<T> &
  RemovePropOnlyWhenNew;

/**
 * Wrapper for the Modal that takes care of generic layouts/and rendering control.
 * For simple usages, the <code>GenericFormModal</code> can be used directly. For more complex
 * use cases, it is recommended to use the <code>GenericFormModal</code> with the
 * <code>ModalProvider</code>
 */
export const GenericFormModal = ({
  isOpen,
  controls,
  ...props
}: GenericFormModalProps<ControlString>) => {
  const setDefaults = React.useMemo(
    () =>
      isNil(controls)
        ? {}
        : fromPairs(Object.values(controls).map(({ name, value }) => [name, value])),
    [controls],
  );

  const reactHookForm = useForm({
    defaultValues: setDefaults,
  });
  const { reset } = reactHookForm;

  React.useEffect(() => {
    // Reset form when modal is changes between open and closed
    reset(setDefaults);
  }, [isOpen, reset, setDefaults]);

  React.useEffect(() => {
    // Reset form when controls change (to preserve default values)
    reset(setDefaults);
  }, [reset, setDefaults]);

  if (!isOpen) return null;

  return (
    <CarbonizerFormProvider controls={controls}>
      <InternalGenericFormModal isOpen={isOpen} {...props} />
    </CarbonizerFormProvider>
  );
};

type InternalGenericFormModalProps = Omit<GenericFormModalProps<ControlString>, 'controls'> &
  RemovePropOnlyWhenNew & {
    /** When children provided, it is assumed that rendering inputs will be handled by the consumer */
    children?: React.ReactNode;
  };
export const InternalGenericFormModal = ({
  isOpen,
  iconName,
  title,
  errorMessage,
  onSubmit,
  onCancel,
  onInvalid,
  children = null,
  ...props
}: InternalGenericFormModalProps) => {
  const {
    handleSubmit,
    formState: { dirtyFields },
  } = useFormContext();

  if (!handleSubmit) {
    throw new Error('GenericFormModal must be used within a CarbonizerFormProvider');
  }

  const handleFormSubmit = (data: FieldValues) => {
    onSubmit(data, dirtyFields);
  };

  const handleFormInvalid = (data: FieldValues) => {
    // eslint-disable-next-line no-console
    console.warn(data);
    if (onInvalid) {
      onInvalid(data);
    }
  };

  let deleteButton = null;
  if (props.isNew === false) {
    deleteButton = (
      <Button size="sm" color="danger" className="ml-2" onClick={props?.onRemove} disabled={false}>
        Delete
      </Button>
    );
  }
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader className="bg-corporate text-white">
        {iconName && <Icon size="1x" name={iconName} className="mr-2" />}
        {title}
      </ModalHeader>
      {errorMessage && <ResponseMessage responseMessage={errorMessage} />}
      <form onSubmit={handleSubmit(handleFormSubmit, handleFormInvalid)}>
        <ModalBody>{children || <RenderInputs />}</ModalBody>
        <ModalFooter>
          <div>
            <Button size="sm" className="mr-2" color="success" type="submit" value="Submit">
              Save
            </Button>
            <Button size="sm" color="light" onClick={onCancel}>
              Cancel
            </Button>
            {deleteButton}
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};
