import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  property_id: {
    ...controlDefaults,
    placeholder: 'Organisation',
    name: 'property_id',
    description: 'Property',
    fieldName: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {},
  },
  group_id: {
    ...controlDefaults,
    fieldName: 'group_id',
    placeholder: '',
    name: 'group_id',
    description: 'Group',
    type: 'select',
    caption: 'Group',
    validationRules: {
      isRequired: true,
    },
  },
  type_id: {
    ...controlDefaults,
    fieldName: 'type_id',
    placeholder: '',
    name: 'type_id',
    description: 'Type',
    type: 'select',
    caption: 'Type',
    validationRules: {
      isRequired: true,
    },
  },
  transaction_interval_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_interval_id',
    fieldName: 'transaction_interval.name',
    description: 'Interval',
    type: 'select',
    caption: 'Interval',
    validationRules: {
      isRequired: true,
    },
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    fieldName: 'transaction_date',
    type: 'date',
    caption: 'Transaction Date',
    description: 'Transaction Date',
    validationRules: {},
  },
  distributions: {
    ...controlDefaults,
    name: 'distributions',
    type: 'json',
    caption: 'Distributions',
    disabled: false,
    validationRules: {},
  },
  total_amount: {
    ...controlDefaults,
    placeholder: '',
    name: 'total_amount',
    fieldName: 'total_amount',
    type: 'number',
    caption: 'Total Amount ($)',
    description: 'Total Amount ($)',
    disabled: true,
    validationRules: {
      isRequired: true,
    },
  },
};
