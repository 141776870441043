import { apiPropertyAnalysisPeriods } from 'jsx/constants/api';
import { get, post, put, remove } from 'jsx/lib/genericAction';

export function fetchProjectPropertyAnalysisPeriods(params) {
  return async (dispatch) =>
    get(
      dispatch,
      'FETCH_PROJECT_PROPERTY_ANALYSIS_PERIODS',
      `${apiPropertyAnalysisPeriods}`,
      params,
    );
}

export function fetchProjectPropertyAnalysisPeriod(params) {
  return (dispatch) =>
    get(
      dispatch,
      'FETCH_PROJECT_PROPERY_ANALYSIS_PERIOD',
      `${apiPropertyAnalysisPeriods}/${params.id}`,
      params,
    );
}

export function updateProjectPropertyAnalysisPeriod(data) {
  return (dispatch) =>
    put(
      dispatch,
      'UPDATE_PROJECT_PROPERY_ANALYSIS_PERIOD',
      `${apiPropertyAnalysisPeriods}/${data.id}`,
      data,
    );
}

export function createProjectPropertyAnalysisPeriod(data) {
  return (dispatch) =>
    post(dispatch, 'CREATE_PROJECT_PROPERY_ANALYSIS_PERIOD', `${apiPropertyAnalysisPeriods}`, data);
}

export function removeProjectPropertyAnalysisPeriod(id) {
  return (dispatch) =>
    remove(
      dispatch,
      'REMOVE_PROJECT_PROPERY_ANALYSIS_PERIOD',
      `${apiPropertyAnalysisPeriods}/${id}`,
    );
}
