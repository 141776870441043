import { connect } from 'react-redux';
import FormBase from 'jsx/components/core/form/components/FormBase';
import Pill from 'jsx/components/core/form/components/Pill';

import AdminWarehouseMetricsLsv from '../../components/admin/AdminWarehouseMetricsLsv';
import AdminWarehouseMetricsToolbar from '../../components/admin/AdminWarehouseMetricsToolbar';
import { controls as metricControls } from '../../forms/admin/metric_filters';

import {
  fetchWarehouseMetrics,
  fetchWarehouseDistinctProperties,
  downloadWarehouseMetrics
} from '../../actions/warehouse';

import { fetchLookupMetrics } from '../../actions/reports';

class AdminProbeMetricsPanel extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      metricFilterControls: metricControls
    };

    this.loadMetrics = this.loadMetrics.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.downloadMetrics = this.downloadMetrics.bind(this);
  }

  async componentDidMount() {
    const { lookupMetrics } = this.props.reports;

    this.loadMetrics();
    this.props.dispatch(fetchWarehouseDistinctProperties());

    if (lookupMetrics.length === 0) await this.props.dispatch(fetchLookupMetrics());
  }

  loadMetrics() {
    const { filters } = this.state;
    this.props.dispatch(fetchWarehouseMetrics({}, filters));
  }

  async handleFilterChange(event, field) {
    const { filters } = this.props.warehouse;
    const { value } = event;

    filters[field].push(value);
    this.props.dispatch({type: 'SET_WAREHOUSE_FILTER_FULFILLED', payload: filters});

    this.props.dispatch(fetchWarehouseMetrics({}, filters));
  }

  async onHandleFilterDelete(field, row) {
    const { filters } = this.props.warehouse;

    const idx = filters[field].findIndex(filter => filter === row.id);
    filters[field].splice(idx, 1);

    this.props.dispatch({type: 'SET_WAREHOUSE_FILTER_FULFILLED', payload: filters});
    this.props.dispatch(fetchWarehouseMetrics({}, filters));
  };

  renderPropertyFilters() {
    const { distinct_properties, filters } = this.props.warehouse;

    const pills = filters.property_id.map(property_id => {
      const property = distinct_properties.find(distinct_property => distinct_property.id === property_id);

      return (
        <Pill
          classes="bg-pink"
          key={property_id}
          caption={`PROPERTY: ${property.name}`}
          onDelete={() => this.onHandleFilterDelete('property_id', property)}
        />
      );
    });

    return pills;
  }

  renderMetricFilters() {
    const { filters } = this.props.warehouse;
    const { lookupMetrics } = this.props.reports;

    const pills = filters.metric_id.map(metric_id => {
      const metric = lookupMetrics.find(lookupMetric => lookupMetric.id === metric_id);

      return (
        <Pill
          classes="bg-orange"
          key={metric_id}
          caption={`METRIC: ${metric.name}`}
          onDelete={() => this.onHandleFilterDelete('metric_id', metric)}
        />
      );
    });

    return pills;
  }

  renderPeriodFilters() {
    const { filters } = this.props.warehouse;
    const { periods } = this.props.analysis_periods;

    const pills = filters.period_id.map(period_id => {
      const analysis_period = periods.rows.find(period => period.id === period_id);

      return (
        <Pill
          classes="bg-indigo"
          key={period_id}
          caption={`PERIOD: ${analysis_period.probe_period}`}
          onDelete={() => this.onHandleFilterDelete('period_id', analysis_period)}
        />
      );
    });

    return pills;
  }

  copyToClipboard() {
    const { metrics } = this.props.warehouse;

    // Iterate through row arrays and return comman-delimited strings
    const rows = metrics.map(dataRow => {
      const csvRow = `"${dataRow.metric.key}","${dataRow.period.description}","${dataRow.property.name}","${dataRow.kpigroup?.caption}",${dataRow.client_value},${dataRow.benchmark_value},"${dataRow.updated}"`;
      return csvRow.replace(/.$/,'');
    });

    // Copy to clipboard
    navigator.clipboard.writeText(rows.join('\n'));
  };

  downloadMetrics(onProgressChange) {
    const { filters } = this.props.warehouse;
    return this.props.dispatch(downloadWarehouseMetrics({}, filters, onProgressChange));
  }

  render() {
    const { periods } = this.props.analysis_periods;
    const { metricFilterControls } = this.state;
    const { metrics, distinct_properties, filters } = this.props.warehouse;
    const { lookupMetrics } = this.props.reports;

    return (
      <div>
        <AdminWarehouseMetricsToolbar
          onRefresh={this.loadMetrics}
          periods={periods}
          properties={distinct_properties}
          metrics={lookupMetrics}
          rows={metrics.length}
          metricFilterControls={metricFilterControls}
          handleFilterChange={this.handleFilterChange}
          onCopy={this.copyToClipboard}
          onDownload={this.downloadMetrics}
          filters={filters}
        />
        <div className="d-flex float mt-2">
          {this.renderPropertyFilters()}
          {this.renderMetricFilters()}
          {this.renderPeriodFilters()}
        </div>
        <AdminWarehouseMetricsLsv rows={metrics} />
      </div>
    );
  }
}

const mapStoreToProps = ({ analysis_periods, warehouse, reports }) => ({
  analysis_periods,
  warehouse,
  reports
});

export default connect(mapStoreToProps)(AdminProbeMetricsPanel);
