import { get, post, put, remove, download } from '../../../../lib/genericAction';

import {
  apiDeadlines,
  apiStatuses,
  apiStages,
  apiStageRequirements,
  apiStageRequirementItems,
  apiDocumentGroups
} from '../../../../constants/api.js';

export function fetchStages(params) {
  return async dispatch => await get(
    dispatch,
    'FETCH_STAGES',
    `${apiDocumentGroups}/${params.group_id}/stages`,
    params
  )
}

export function fetchStage(id) {
  return async dispatch => await get(
    dispatch,
    'FETCH_STAGE',
    `${apiStages}/${id}`
  )
}

export function createStage(data) {
  return async dispatch => await post(
    dispatch,
    'CREATE_STAGE',
    `${apiDocumentGroups}/${data.group_id}/stages`,
    data
  )
}

export function updateStage(data) {
  return async dispatch => await put(
    dispatch,
    'UPDATE_STAGE',
    `${apiStages}/${data.id}`,
    data
  )
}

export function removeStage(id) {
  return async dispatch => await remove(
    dispatch,
    'REMOVE_STAGE',
    `${apiStages}/${id}`
  )
}

export function downloadStageItems(id, onProgressChange) {
  return async dispatch => await download(
    dispatch,
    'DOWNLOAD_STAGE_ITEMS',
    `${apiStages}/${id}/download`,
    onProgressChange,
  )
}


export function fetchRequirementItems(params) {
  return async dispatch => await get(
    dispatch,
    'FETCH_STAGE_REQUIREMENT_ITEMS',
    `${apiStageRequirements}/${params.requirement_id}/items`
  )
}

export function fetchRequirementItem(id) {
  return async dispatch => await get(
    dispatch,
    'FETCH_STAGE_REQUIREMENT_ITEM',
    `${apiStageRequirementItems}/${id}`
  )
}

export function updateRequirementItem(formData) {
  return async dispatch => await put(
    dispatch,
    'UPDATE_STAGE_REQUIREMENT_ITEM',
    `${apiStageRequirementItems}/${formData.get('id')}`,
    formData
  )
}

export function createRequirementItem(formData) {
  return async dispatch => await post(
    dispatch,
    'CREATE_STAGE_REQUIREMENT_ITEM',
    `${apiStageRequirements}/${formData.get('requirement_id')}/items`,
    formData
  )
}

export function deleteRequirementItem(id) {
  return async dispatch => await remove(
    dispatch,
    'DELETE_STAGE_REQUIREMENT_ITEM',
    `${apiStageRequirements}/items/${id}`
  )
}

export function fetchRequirementStatuses() {
  return async dispatch => await get(
    dispatch,
    'FETCH_REQUIREMENT_STATUSES',
    `${apiStatuses}?type=requirement`
  )
}

export function fetchRequirementItemCategories() {
  return async dispatch => await get(
    dispatch,
    'FETCH_REQUIREMENT_ITEM_CATEGORIES',
    `${apiStatuses}?type=requirement_category`
  )
}

export function fetchRequirements(params) {
  return async dispatch => await get(
    dispatch,
    'FETCH_STAGE_REQUIREMENTS',
    `${apiStages}/${params.stage_id}/requirements`
  )
}

export function fetchRequirement(id) {
  return async dispatch => await get(
    dispatch,
    'FETCH_STAGE_REQUIREMENT',
    `${apiStageRequirements}/${id}`
  )
}

export function deleteRequirement(id) {
  return async dispatch => await remove(
    dispatch,
    'DELETE_STAGE_REQUIREMENT',
    `${apiStageRequirements}/${id}`
  )
}

export function updateRequirement(data) {
  return async dispatch => await put(
    dispatch,
    'UPDATE_STAGE_REQUIREMENT',
    `${apiStageRequirements}/${data.id}`,
    data
  )
}

export function createRequirement(data) {
  return async dispatch => await post(
    dispatch,
    'CREATE_STAGE_REQUIREMENT',
    `${apiStages}/${data.stage_id}/requirements`,
    data
  )
}

export function fetchDeadlines() {
  return async dispatch => await get(
    dispatch,
    'FETCH_DEADLINES',
    `${apiDeadlines}`
  )
}

export function downloadStageRequirementItems(id, onProgressChange) {
  return async dispatch => await download(
    dispatch,
    'DOWNLOAD_STAGE_REQUIREMENT_ITEMS',
    `${apiStageRequirements}/${id}/download`,
    onProgressChange,
  )
}


export function downloadStageRequirementItem(id, onProgressChange) {
  return async dispatch => await download(
    dispatch,
    'DOWNLOAD_STAGE_REQUIREMENT_ITEM',
    `${apiStageRequirementItems}/${id}/download`,
    onProgressChange,
  )
}