import { defaults } from '../../../../lib/genericReducer';
export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    authorised: true,
    authResponseMessage: null,
    filters: {
      system: [],
      associate_with: [],
      org_id: [],
    },
    system_types: [
      { id: 'true', name: 'System Only' },
      { id: 'false', name: 'User Only' },
    ],
    org_activity_types: [],
    params: {},
    activities: [],
    searchValue: '',
  },
  action
) {
  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_ACTIVITIES_FULFILLED': {
      return {
        ...state,
        activities: action.payload,
      };
    }
    case 'FETCH_ORG_ACTIVITY_TYPES_FULFILLED': {
      return {
        ...state,
        org_activity_types: action.payload,
      };
    }
    case 'SET_ACTIVITY_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case 'SET_ACTIVITY_PARAMS': {
      return {
        ...state,
        params: action.payload,
      };
    }
    case 'UNSET_ACTIVITIES': {
      return {
        ...state,
        activities: [],
      };
    }
    case 'SET_ACTIVITIES_SEARCH_VALUE': {
      return {
        ...state,
        searchValue: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
