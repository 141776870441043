/* eslint-disable id-length */
import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import {
  fetchListviewConfiguration,
  updateListviewConfiguration,
} from 'jsx/components/manage/actions/listview_configuration';
import { selectListviewColumns, selectListviewSortParams } from 'jsx/components/manage/selectors';
import { isNil } from 'lodash';
import { numberRuleFormat, strip } from '../lib/fieldFormat';
import { compare } from '../lib/compare';
import ListviewConfiguration from './ListviewConfiguration';
import { applyClassName, modifyChildElements } from '../lib/elements';
import { ScrollBox } from './ScrollBox';

/**
 * @param {Object} props - Component props
 * @param {Array.<React.Component>} [props.upperHeadTh] - Array of th elements to be placed above the table head
 * @param {Array.<React.ReactNode>} props.tableHeadTh - Array of th elements to be placed in the table head
 * @param {Array.<React.ReactNode>} props.tableBodyTr - Array of tr elements to be placed in the table body
 * @param {Array.<object>} props.rows - Array of objects to be placed in the table body
 * @param {number} props.rowsCount - Total number of rows in the table
 * @param {function} [props.onLoadMore] - Function to be called when the load more button is clicked
 * @param {string} props.iconName - Name of icon to be placed in the empty table caption
 * @param {string} props.emptyCaption - Caption to be placed in the empty table caption
 * @param {string} [props.sortType] - Type of sorting to be applied to the table
 * @param {Array.<object>} [props.totals] - Array of objects to be placed in the table footer
 * @param {Array.<object>} [props.totalFormattingRules] - Array of objects to be used to format the totals
 * @param {Array.<object>} [props.headers] - Array of objects to be used to format the table head
 * @param {boolean} [props.isHorizontalScrollEnabled] - Enable horizontal scrolling
 * @param {boolean} [props.hideHeader] - Hide the table header
 * @param {boolean} [props.darkTable] - Dark table
 * @param {String} [props.className] - Applied to table element
 *
 * @param {boolean} [props.isConfigurable] - If true, the columns will be configurable
 * @param {boolean} [props.rememberUserState] - If true, the state of the table view will be persisted, and restored on the next page load
 * @param {string} [props.instanceName] - A unique name for this type of LSV. Currently required if rememberUserState is enabled
 * @param {Array.<object>} [props.configuration] - Array of objects to be used to configure the table columns
 * @param {function} [props.onUpdateConfiguration] - Function to be called when the configuration is updated
 * @returns
 */
const Listview = ({
  upperHeadTh,
  tableHeadTh,
  tableBodyTr,
  rows,
  rowsCount,
  onLoadMore,
  iconName,
  emptyCaption,
  sortType,
  totals,
  totalFormattingRules,
  headers,
  hideHeader,
  darkTable = false,
  className = '',
  responsive = true,
  isHorizontalScrollEnabled = false,

  // Listview Configuration props
  instanceName,
  isConfigurable = false,
  rememberUserState = false,
}) => {
  const dispatch = useDispatch();
  const [sortState, setSortState] = useState({
    key: null,
    isAsc: true,
  });

  useEffect(() => {
    async function fetchData() {
      dispatch(fetchListviewConfiguration(instanceName));
    }
    if (rememberUserState) {
      if (process.env.NODE_ENV === 'development' && isNil(instanceName)) {
        // eslint-disable-next-line no-console
        console.warn('You must supply an instanceName when rememberUserState is true');
      }
      fetchData();
    }
  }, [dispatch, instanceName, rememberUserState]);

  const columns = useSelector((state) => selectListviewColumns(state, instanceName));
  const sortParams = useSelector((state) => selectListviewSortParams(state, instanceName));

  /* Use the sortParams config from the store if:
   * persistence is enabled + the current sortState looks untouched + there is something from the store */
  useEffect(() => {
    if (rememberUserState && isNil(sortState.key) && !isNil(sortParams)) {
      setSortState(sortParams);
    }
  }, [sortState, sortParams, rememberUserState]);

  const hiddenColumnFields = columns.filter(({ hidden }) => hidden).map(({ field }) => field);

  let filteredThs = tableHeadTh.map((header) => {
    if (hiddenColumnFields.includes(header.key)) {
      return applyClassName(header, 'd-none');
    }

    return header;
  });

  const filteredTrs =
    tableBodyTr &&
    Array.isArray(tableBodyTr) &&
    tableBodyTr.map((row) => {
      const modifiedRow = modifyChildElements(row, (childOfTr) => {
        if (childOfTr.type === 'td' && hiddenColumnFields.includes(childOfTr.key)) {
          return applyClassName(childOfTr, 'd-none');
        }
        return childOfTr;
      });

      return modifiedRow;
    });

  const setSortParams = (key) => {
    const newSortParams = {
      key,
      isAsc: true,
    };
    // -- If key equal and ascending, set descending
    if (key === sortState.key) {
      newSortParams.isAsc = !sortState.isAsc;
    }
    if (rememberUserState) {
      dispatch(
        updateListviewConfiguration(instanceName, { sortParams: newSortParams }, rememberUserState),
      );
    }
    setSortState(newSortParams);
  };

  const getSortKey = (tr) => {
    let key;

    tr.props.children.map((td) => {
      if (td.key === sortState.key) {
        // If there is a data-sort-value, use that as the sorting value instead of the children
        if (td.props['data-sort-value'] !== undefined) {
          key = td.props['data-sort-value'];
        } else {
          key = td.props.children;
        }
      }
      return false;
    });
    return key;
  };

  const sortClientSide = () => {
    if (sortState.key == null || !Array.isArray(filteredTrs)) {
      return;
    }

    filteredTrs.sort((elementA, elementB) => {
      const { isAsc } = sortState;
      let a = elementA;
      let b = elementB;
      // If a fragment, assumption is the first child is the sorting row
      // eslint-disable-next-line prefer-destructuring
      if (a.type === Symbol.for('react.fragment')) a = a.props.children[0];
      // eslint-disable-next-line prefer-destructuring
      if (b.type === Symbol.for('react.fragment')) b = b.props.children[0];

      let keyOrder;
      if (isAsc) {
        keyOrder = [a, b];
      } else {
        keyOrder = [b, a];
      }

      const keyA = getSortKey(keyOrder[0]);
      const keyB = getSortKey(keyOrder[1]);

      return compare(keyA, keyB);
    });
  };

  // -- Set default sort method
  const currentSortType = sortType === undefined ? 'client' : sortType;
  switch (currentSortType) {
    case 'client':
      // -- Set onClick method and sort table
      filteredThs = filteredThs.map((header) => {
        const iconNameTh = sortState.isAsc ? 'sort-up' : 'sort-down';
        const icon =
          sortState.key === header.key ? (
            <Icon name={iconNameTh} className="mr-2 mt-1 text-primary" />
          ) : null;
        return React.cloneElement(
          header,
          {
            onClick: () => {
              setSortParams(header.key);
            },
          },
          <span>
            {header.props.children} {icon}
          </span>,
        );
      });

      sortClientSide();
      break;
    default:
      break;
  }

  const totalsTf =
    totals &&
    filteredTrs.length > 0 &&
    filteredThs.map(({ key, props }, h_index) => {
      const { className: totalsClassName = '' } = props;

      let total_value = '';
      const initialValue = 0;
      if (totals.includes(parseInt(key))) {
        const sum_values = filteredTrs
          .map((tr) => {
            let copiedTr = { ...tr };
            /** We're making the assumption here that the first tr in the fragment is the
            /* one we are calculating the total for. */
            if (copiedTr.type === Symbol.for('react.fragment')) {
              // eslint-disable-next-line prefer-destructuring
              copiedTr = tr.props.children[0];
            }
            const value = copiedTr.props.children
              ?.map((col, c_index) => (h_index === c_index ? col.props.children : false))
              .filter(Boolean);
            if (value.length > 0) return value.reduce((a, b) => strip(a) + strip(b), initialValue);
            return false;
          })
          .filter(Boolean);

        if (sum_values.length > 0) {
          total_value = sum_values.reduce((a, b) => strip(a) + strip(b), initialValue);
        }

        const formattingRules = headers ? headers[key].formattingRules : totalFormattingRules;

        if (formattingRules && total_value !== '') {
          total_value = numberRuleFormat(total_value, formattingRules);
        }
      }

      if (typeof total_value === 'number') total_value = total_value.toFixed(2);

      return (
        <td key={h_index} className={`pt-1 totals ${totalsClassName}`}>
          {total_value}
        </td>
      );
    });

  const tableFootTr = rowsCount && (
    <tr>
      <td
        role="gridcell"
        className="p-3 text-center selectable"
        colSpan={filteredThs.length}
        onClick={onLoadMore != null ? () => onLoadMore() : null}
        onKeyDown={onLoadMore != null ? () => onLoadMore() : null}
      >
        Load More Rows
      </td>
    </tr>
  );

  const loadMore = rowsCount && rows.length < rowsCount;

  const renderTable = () => (
    <Table
      dark={darkTable}
      size="sm"
      striped
      responsive={responsive && !isHorizontalScrollEnabled}
      className={className}
      style={isHorizontalScrollEnabled ? { borderCollapse: 'separate', borderSpacing: 0 } : {}}
    >
      {!hideHeader && (
        <thead
          style={isHorizontalScrollEnabled ? { top: 0 } : {}}
          className={
            isHorizontalScrollEnabled
              ? 'position-sticky border-bottom-2 border-corporate bg-white'
              : null
          }
        >
          {upperHeadTh && <tr>{upperHeadTh}</tr>}
          <tr>{filteredThs}</tr>
        </thead>
      )}
      <tbody>{filteredTrs}</tbody>
      <tfoot>
        {!loadMore && totals && <tr>{totalsTf}</tr>}
        {loadMore && tableFootTr}
      </tfoot>
    </Table>
  );

  return (
    <>
      {rows && rows.length === 0 && (
        <div className={`p-5 text-center ${className}`}>
          <div>
            <Icon size="3x" name={iconName} className="text-corporate" />
          </div>
          <div className="mt-3">{emptyCaption}</div>
        </div>
      )}
      {rows && rows.length > 0 && (
        <>
          {isConfigurable && (
            <ListviewConfiguration
              headers={headers}
              columns={columns}
              rememberUserState={rememberUserState}
              instanceName={instanceName}
            />
          )}

          {isHorizontalScrollEnabled ? (
            <div className="d-flex">
              <ScrollBox offsetPixels={300}>{renderTable()}</ScrollBox>
            </div>
          ) : (
            <div>{renderTable()}</div>
          )}
        </>
      )}
    </>
  );
};

export default Listview;
