import React from 'react';
import { wrap } from 'lodash';
import { connect } from 'react-redux';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * This HOC wraps the FormTab component and dispatches a NAVIGATION_SET_ACTIVE_TAB
 * action every time the wrapped FormTab triggers the toggle function
 */
function FormTabHOC(WrappedComponent) {
  class WithFormTabNavigation extends React.Component {
    render() {
      const wrappedToggle = wrap(this.props.toggle, (originalFunction, ...params) => {
        this.props.dispatch({
          type: 'NAVIGATION_SET_ACTIVE_TAB',
          payload: {
            name: this.props.tabTag,
          },
        });
        originalFunction(...params);
      });

      return <WrappedComponent {...this.props} toggle={wrappedToggle} />;
    }
  }
  WithFormTabNavigation.displayName = `withFormTabNavigation(${getDisplayName(WrappedComponent)})`;

  return WithFormTabNavigation;
}

const mapStoreToProps = (store) => ({
  projects: store.projects,
  stages: store.stages,
  realm: store.realm,
});

const withFormTabNavigation = (WrappedComponent) =>
  connect(mapStoreToProps)(FormTabHOC(WrappedComponent));

export default withFormTabNavigation;
