import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Col, Row } from 'reactstrap';
import { cloneDeep, omit } from 'lodash';

import {
  initControls,
  saveControls,
  updateControls,
  validateFormFieldControls,
} from 'jsx/components/core/form/lib/validateForm';
import Icon from 'jsx/components/core/icons/Icon';
import FormBase from 'jsx/components/core/form/components/FormBase';
import FormInput from 'jsx/components/core/form/components/FormInput';
import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';

import { controls as shiftControls } from '../../forms/rosters/shift';
import { createShift, fetchShift, removeShift, updateShift } from '../../actions/rosters/shifts';
import ShiftSlotLsv from '../../components/rosters/ShiftSlotLsv';
import { fetchShiftSlots, removeShiftSlot } from '../../actions/rosters/shiftSlots';
import ShiftSlotModal from './ShiftSlotModal';

class ShiftModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(shiftControls),
      data: {},
      id: null,
      isNew: false,
      isShiftSlotModalOpen: false,
      shiftSlotId: null,
      title: 'Shift',
    };
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const controls = initControls(cloneDeep(shiftControls));
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        isShiftSlotModalOpen: false,
        shiftSlotId: null,
        title: 'New Shift',
      };

      if (this.props.id) {
        const { id } = this.props;
        const shift = await this.props.dispatch(fetchShift({ id }));

        if (shift?.roster_shift_slots?.length > 0) {
          // Store shift slots from shift association in reducer
          const { roster_shift_slots } = shift;
          this.props.dispatch({
            type: 'SET_ROSTER_SHIFT_SLOTS',
            payload: { count: roster_shift_slots.length, rows: roster_shift_slots },
          });
        }

        updatedState = {
          ...updatedState,
          controls: updateControls(controls, shift),
          data: shift,
          id,
          isNew: false,
          title: 'Edit Shift',
        };
      }

      this.setState(updatedState);
    }
  }

  onClose = (refresh = true, reopen = false, id = null) => {
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_ROSTER_SHIFT_SLOTS' });
    if (refresh && this.props.onRefresh) {
      this.props.onRefresh();
    }

    if (reopen) {
      this.setState({ isOpen: false }, () => this.props.setModal(true, id));
    }
  };

  onRemove = async () => {
    const { data } = this.state;
    const confirmed = window.confirm('Removing Shift permanently. Continue?');
    if (!confirmed) return;

    const success = await this.props.dispatch(removeShift(data.id));
    if (!success) return;

    this.onClose(true);
  };

  onSave = async () => {
    const { controls, data, isNew } = this.state;

    let saveData = saveControls(controls, data);
    const { isValid, updatedControls } = await validateFormFieldControls(saveData, controls);

    if (!isValid) {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
      return;
    }

    let success;
    if (!isNew) {
      success = await this.props.dispatch(updateShift(saveData));
      if (success) {
        this.onClose(true);
        return;
      }
    }

    saveData = omit(saveData, ['id']);
    success = await this.props.dispatch(createShift(saveData));
    if (!success) {
      return;
    }

    const { id } = success;
    this.onClose(false, true, id);
  };

  onRemoveShiftSlot = async (id) => {
    const confirmed = window.confirm('Removing Shift Slot permanently. Continue?');
    if (!confirmed) return;

    const success = await this.props.dispatch(removeShiftSlot(id));
    if (success) {
      this.onShiftSlotRefresh();
    }
  };

  onShiftSlotRefresh = () => {
    this.loadShiftSlots();
  };

  loadShiftSlots = () => {
    if (this.props?.id) {
      const { id: shift_id } = this.props;
      this.props.dispatch(fetchShiftSlots({ shift_id }));
    }
  };

  setShiftSlotModal = (isShiftSlotModalOpen, shiftSlotId = null) => {
    this.setState({ isShiftSlotModalOpen, shiftSlotId });
  };

  render() {
    const { controls, id, isNew, isShiftSlotModalOpen, shiftSlotId, title } = this.state;
    const { isOpen } = this.props;
    const { responseMessage, shiftSlots } = this.props.rosters;
    const iconName = 'calendar';

    return (
      <Modal isOpen={isOpen} className="w60-modal">
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <h5 className="my-2 text-corporate border-bottom">Shift</h5>
            <Row className="bg-light m-0 pb-2">
              <Col sm={9}>
                <FormInput handleChange={this.handleChange} control={controls.name} />
              </Col>
              <Col sm={3}>
                <FormInputSwitch
                  groupClassName="mt-2"
                  handleChange={this.handleChange}
                  control={controls.enabled}
                />
              </Col>
            </Row>
          </Form>
          {!isNew && (
            <>
              <ShiftSlotModal
                id={shiftSlotId}
                isNew={shiftSlotId === null}
                isOpen={isShiftSlotModalOpen}
                onRefresh={this.onShiftSlotRefresh}
                responseMessage={responseMessage}
                setModal={this.setShiftSlotModal}
                shiftId={id}
              />
              <h5 className="mt-4 mb-0 text-corporate border-bottom">Shift Slots</h5>
              <Row className="d-flex justify-content-end m-0 p-0 bg-light">
                <Button size="sm" color="link" onClick={() => this.setShiftSlotModal(true)}>
                  Add Shift Slot
                </Button>
              </Row>
              <ShiftSlotLsv
                rows={shiftSlots}
                onEdit={this.setShiftSlotModal}
                onDelete={this.onRemoveShiftSlot}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            {isNew && (
              <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
                Save/Continue
              </Button>
            )}
            {!isNew && (
              <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
                Save
              </Button>
            )}

            <Button size="sm" color="light" onClick={this.onClose}>
              {isNew ? 'Cancel' : 'Close'}
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ rosters }) => ({
  rosters,
});

export default connect(mapStoreToProps)(ShiftModal);
