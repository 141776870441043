import FormInput from 'jsx/components/core/form/components/FormInput';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { getLastChildValue } from '../../../../../lib/generic';
import Listview from '../../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../../core/form/lib/fieldFormat';

const CropflowLsv = ({ rows, onValueChange = noop }) => {
  const [changed, setChanged] = useState(false);

  const renderRows = (headers, row) => {
    const tableTd = headers.map(
      ({ classes, field, formattingRules }, index) => {
        let caption = field?.includes('.')
          ? getLastChildValue(field, row)
          : row[field];

        if (formattingRules)
          caption = numberRuleFormat(caption, formattingRules);

        switch (field) {
          case 'transactions.adjustments.total_units_adjusted': {
            const { opening, units } = row.transactions.adjustments;
            const tooltip = `# Units Adjusted: ${units}\nOpening Units: ${opening}`;
            return (
              <td key={index} className={classes} title={tooltip}>
                {caption}
              </td>
            );
          }
          case 'closing.unit_amount': {
            const control = {
              id: `${index}-${field}`,
              name: field,
              value: caption,
              isEdit: false,
              ...row[field],
            };

            if (control.isEdit) {
              return (
                <td key={index} className={classes}>
                  {
                    <FormInput
                      className={'text-center p-0 slim'}
                      autoFocus={true}
                      handleChange={(event) => handleChange(event.target, row)}
                      handleKeyDown={(event) =>
                        handleKeyDown(event.keyCode, field, row)
                      }
                      handleBlur={() => handleBlur(field, row)}
                      control={control}
                    />
                  }
                </td>
              );
            }
            return (
              <td
                key={index}
                className={classes}
                onClick={() => {
                  setEditMode(field, row, true);
                }}
              >
                {control.value}
              </td>
            );
          }
          default: {
            return (
              <td key={index} className={classes}>
                {caption}
              </td>
            );
          }
        }
      },
    );

    return tableTd;
  };

  const handleChange = ({ name, value }, row) => {
    row[name] = {
      ...row[name],
      value,
      change: true,
    };

    setChanged(!changed);
  };

  // Handle user pressing the enter key
  const handleKeyDown = (eventKeyCode, field, row) => {
    const keyboardEnterKeyCode = 13;
    if (eventKeyCode === keyboardEnterKeyCode) setEditMode(field, row);
  };

  // On unfocus, turn off edit mode
  const handleBlur = (field, row) => setEditMode(field, row);

  const setEditMode = (name, row, isEdit = false) => {
    row[name] = {
      ...row[name],
      isEdit,
    };

    // If edit mode false and value has changed
    if (!isEdit && row[name]?.change) {
      onValueChange(name, row);

      // Reset change to false
      row[name] = {
        ...row[name],
        change: false,
      };
    }

    setChanged(!changed);
  };

  const upperHeaders = [
    { caption: null, cells: 4, classes: 'border-bottom-0' },
    {
      caption: 'Opening',
      cells: 3,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    {
      caption: 'Purchases',
      cells: 2,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    {
      caption: null,
      cells: 5,
      classes: 'border-bottom-0',
    },
    {
      caption: 'Adjustments',
      cells: 1,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    {
      caption: 'Sales',
      cells: 3,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    {
      caption: 'Grazings',
      cells: 1,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    {
      caption: 'Produce Fed Out',
      cells: 2,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    {
      caption: 'Closing',
      cells: 3,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
  ];

  const headers = [
    { caption: 'Property', field: 'property.name', classes: 'text-center' },
    { caption: 'Enterprise', field: 'enterprise.name', classes: 'text-center' },
    { caption: 'Product', field: 'product.name', classes: 'text-center' },
    {
      caption: 'Production Unit',
      field: 'production_unit',
      classes: 'text-center',
    },
    {
      caption: '# Units',
      field: 'opening.quantity',
      classes: 'text-center border-left',
      header_totals: true,
      formattingRules: { includeCommas: true, includeDecimals: true },
    },
    {
      caption: 'Gross ($)',
      field: 'opening.amount',
      classes: 'text-center',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Gross ($/unit)',
      field: 'opening.unit_amount',
      classes: 'text-center border-right',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: '# Units',
      field: 'transactions.purchases.quantity',
      classes: 'text-center',
      formattingRules: { includeCommas: true, includeDecimals: true },
      header_totals: true,
    },
    {
      caption: 'Gross ($)',
      field: 'transactions.purchases.amount',
      classes: 'text-center border-right',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Planted (ha)',
      field: 'transactions.adjustments.planted',
      classes: 'text-center border-left',
      formattingRules: { includeCommas: true, includeDecimals: true },
      header_totals: true,
    },
    {
      caption: 'Harvested (ha)',
      field: 'transactions.adjustments.harvested',
      classes: 'text-center',
      formattingRules: { includeCommas: true, includeDecimals: true },
      header_totals: true,
    },
    {
      caption: 'Seeds Used',
      field: 'transactions.adjustments.used',
      classes: 'text-center',
      formattingRules: { includeCommas: true, includeDecimals: true },
      header_totals: true,
    },
    {
      caption: 'Yield',
      field: 'transactions.adjustments.yield.quantity',
      classes: 'text-center',
      formattingRules: { includeCommas: true, includeDecimals: true },
      header_totals: true,
    },
    {
      caption: 'Yield (unit/ha)',
      field: 'transactions.adjustments.yield.unit_quantity',
      classes: 'text-center border-right',
      formattingRules: { includeCommas: true, includeDecimals: true },
    },
    {
      caption: '# Units',
      field: 'transactions.adjustments.total_units_adjusted',
      classes: 'text-center border-right',
      formattingRules: { includeCommas: true, includeDecimals: true },
      header_totals: true,
    },
    {
      caption: '# Units',
      field: 'transactions.sales.quantity',
      classes: 'text-center',
      formattingRules: { includeCommas: true, includeDecimals: true },
      header_totals: true,
    },
    {
      caption: 'Gross ($)',
      field: 'transactions.sales.amount',
      classes: 'text-center',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Gross ($/unit)',
      field: 'transactions.sales.unit_amount',
      classes: 'text-center border-right',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Gross ($)',
      field: 'transfers.grazing.amount',
      classes: 'text-center border-right',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: '# Units',
      field: 'transfers.feedlot.quantity',
      classes: 'text-center',
      header_totals: true,
      formattingRules: { includeCommas: true, includeDecimals: true },
    },
    {
      caption: 'Gross ($)',
      field: 'transfers.feedlot.amount',
      classes: 'text-center',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: '# Units',
      field: 'closing.quantity',
      classes: 'text-center border-left',
      header_totals: true,
      formattingRules: { includeCommas: true, includeDecimals: true },
    },
    {
      caption: 'Gross ($)',
      field: 'closing.amount',
      classes: 'text-center',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Gross ($/unit)',
      field: 'closing.unit_amount',
      classes: 'text-center border-right',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
  ];

  const upperHeadTh = upperHeaders.map(({ caption, cells, classes }, index) => (
    <th key={index} colSpan={cells} className={classes}>
      {caption}
    </th>
  ));

  const totals = [];
  const tableHeadTh = headers.map(
    ({ caption, classes, header_totals }, index) => {
      if (header_totals) totals.push(index);
      return (
        <th key={index} className={classes}>
          {caption}
        </th>
      );
    },
  );

  let tableBodyTr = <tr></tr>;
  if (rows.length > 0) {
    tableBodyTr = rows.map((row) => (
      <tr key={row.id}>{renderRows(headers, row)}</tr>
    ));
  }

  const iconName = 'seedling';
  const emptyCaption =
    'No transactions or transfers found. Have you got the right date range set?';

  return (
    <>
      <Listview
        rows={rows || []}
        tableHeadTh={tableHeadTh}
        upperHeadTh={upperHeadTh}
        tableBodyTr={tableBodyTr}
        totals={totals}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </>
  );
};

export default CropflowLsv;
