import React from 'react';
import { groupBy } from 'lodash';
import { connect } from 'react-redux';
import FormBase from '../../../core/form/components/FormBase';
import AssetReadingsLsv from '../components/AssetReadingsLsv';
import AssetsReadingsToolbar from '../components/AssetsReadingsToolbar';
import AssetReadingsInlineEntry from '../components/AssetReadingsInlineEntry';
import AssetReadingsModal from './AssetReadingsModal';
import { controls as readingControls } from '../forms/asset_reading';
import { cloneDeep } from 'lodash';
import {
  fetchPlantAssetReadings,
  createPlantAssetReading,
  deletePlantAssetReading,
} from '../actions/asset_readings';

import { fetchPlantAssetAssociatedReadings } from '../actions/assets';

import { saveControls, validateFormFieldControls } from '../../../core/form/lib/validateForm';

class AssetReadings extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(readingControls),
      showAdd: false,
      data: {},
      readings: this.props.wipstar.readings,
      isModalOpen: false,
      row: {},
      filteredAssets: [],
      searchTerm: '',
    };

    this.onToggleAdd = this.onToggleAdd.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.updateAssetOptions = this.updateAssetOptions.bind(this);
    this.setAssetReadingModal = this.setAssetReadingModal.bind(this);
    this.filterReadingsByAssetId = this.filterReadingsByAssetId.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.loadAssets = this.loadAssets.bind(this);
    this.addFilteringToReadings = this.addFilteringToReadings.bind(this);
  }

  async componentDidMount() {
    this.loadAssets();
  }

  async setAssetReadingModal(isModalOpen, row) {
    if (row) {
      const { asset_id } = row;
      await this.props.dispatch(fetchPlantAssetAssociatedReadings(asset_id));
    }

    this.setState({ isModalOpen, row });
  }

  updatedReadingRows() {
    const { readings } = this.props.wipstar;
    return readings;
  }

  async onDelete({ id, asset_id }) {
    const confirmed = window.confirm(`This will remove the asset reading permanently. Continue?`);
    if (confirmed) {
      await this.props.dispatch(deletePlantAssetReading(id));
      await this.props.dispatch(fetchPlantAssetAssociatedReadings(asset_id));
      this.loadAssets();
    }
  }
  onSelectChange(event) {
    const { controls } = this.state;
    controls.asset_id.value = event?.value ?? '';
    controls.asset_id.label = event?.label ?? '';
    this.setState({ controls });
  }

  onClear() {
    let { filteredAssets, searchTerm } = this.state;
    const { controls } = this.state;
    const { asset_id, id, reading_date, value } = controls;

    asset_id.value = null;
    id.value = '';
    reading_date.value = '';
    value.value = '';
    filteredAssets = [];
    searchTerm = '';

    this.setState({ controls, filteredAssets, searchTerm });
  }

  onRefresh() {
    this.onClear();
    this.loadAssets();
  }

  async onSave() {
    let { data } = this.state;
    const { controls } = this.state;
    data = saveControls(controls, data);
    delete data.id;

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (isValid) {
      const success = await this.props.dispatch(createPlantAssetReading(data));
      if (success) {
        this.onClear();
        this.loadAssets();
      }
    } else {
      this.setState({
        controls: updatedControls,
      });
    }
  }

  loadAssets() {
    this.props.dispatch(fetchPlantAssetReadings());
  }

  onToggleAdd() {
    this.setState({ showAdd: !this.state.showAdd });
  }

  updateAssetOptions(controls) {
    controls.asset_id.options = this.props?.wipstar?.assets?.rows?.map((asset) => {
      return { label: asset.name, value: asset.id };
    });

    return controls;
  }

  // Group assets by asset_id in the listview.
  filterReadingsByAssetId(readings) {
    const { rows } = readings;
    const filteredAssets = [];

    const unique_asset_ids = groupBy(rows, 'asset_id');

    const assets = Object.keys(unique_asset_ids);

    for (const asset of assets) {
      const row = rows.find(({ asset_id }) => asset_id === asset);
      filteredAssets.push(row);
    }

    return filteredAssets;
  }

  handleSearchChange(searchTerm) {
    const { readings } = this.props.wipstar;
    let filteredAssets = readings;

    if (searchTerm.length > 0) {
      filteredAssets = readings.rows.filter(
        (reading) =>
          reading.plant_asset.description.toLowerCase().includes(searchTerm) ||
          reading.plant_asset.org.name.toLowerCase().includes(searchTerm) ||
          reading.plant_asset.name.toLowerCase().includes(searchTerm)
      );
    }
    this.setState({ filteredAssets, searchTerm });
  }

  addFilteringToReadings() {
    const { filteredAssets } = this.state;
    return { count: filteredAssets.length, rows: filteredAssets };
  }

  render() {
    let { readings } = this.props.wipstar;
    const { selected_asset_readings } = this.props.wipstar;
    const { controls, showAdd, isModalOpen, searchTerm } = this.state;
    const { row } = this.state;

    if (searchTerm.length > 1) {
      readings = this.addFilteringToReadings();
    }

    const updatedRows = this.filterReadingsByAssetId(readings);

    this.updateAssetOptions(controls);

    return (
      <>
        <AssetReadingsModal
          isModalOpen={isModalOpen}
          setAssetReadingModal={this.setAssetReadingModal}
          row={row}
          readings={selected_asset_readings.rows}
          onDelete={this.onDelete}
          onRefresh={this.onRefresh}
        />
        <AssetsReadingsToolbar
          onToggleAdd={this.onToggleAdd}
          handleSearchChange={this.handleSearchChange}
          onClear={this.onClear}
          onRefresh={this.onRefresh}
          searchTerm={searchTerm}
        />

        {showAdd && (
          <div className="mb-2">
            <div className="border-bottom border-corporate text-corporate">
              <small>Add New Asset Reading</small>
            </div>
            <AssetReadingsInlineEntry
              controls={controls}
              handleChange={this.handleChange}
              onSelectChange={this.onSelectChange}
              onClear={this.onClear}
              onSave={this.onSave}
            />
          </div>
        )}

        <AssetReadingsLsv readings={updatedRows} setAssetReadingModal={this.setAssetReadingModal} />
      </>
    );
  }
}

const mapStoreToProps = ({ wipstar }) => {
  return {
    wipstar,
  };
};

export default connect(mapStoreToProps)(AssetReadings);
