import socketIOClient from 'socket.io-client';
import { ioUrl, ioOptions } from '../constants/api';

class SocketReducer {
  constructor(props) {
    this.props = props;
    this.socket = socketIOClient(ioUrl, ioOptions);
    this.setTopics();
  }

  setTopics() {
    this.socket.on('topicPipelineUpdate', (data) => this.handlePipelineUpdate(data));
    this.socket.on('topicBatchUpdate', (data) => this.handleBatchUpdate(data));
    this.socket.on('topicInsightsUpdate', (data) => this.handleInsightsUpdate(data));
  }

  handlePipelineUpdate(data) {
    try {
      this.props.dispatch({ type: 'UPDATE_PIPELINE_TOPIC', payload: data });
    } catch (_) {
      console.log(_);
      console.error(`SOCKETREDUCER Error(handlePipelineUpdate): ${data}`);
    }
  }

  handleBatchUpdate(data) {
    try {
      this.props.dispatch({ type: 'UPDATE_PROPERTY_ROUND_BATCH_TOPIC', payload: data });
    } catch (_) {
      console.log(_);
      console.error(`SOCKETREDUCER Error(handleBatchUpdate): ${data}`);
    }
  }

  handleInsightsUpdate(data) {
    try {
      this.props.dispatch({ type: 'UPDATE_PROPERTY_INSIGHTS_TOPIC', payload: data });
    } catch (_) {
      console.log(_);
      console.error(`SOCKETREDUCER Error(handleInsightsUpdate): ${data}`);
    }
  }
}

export default SocketReducer;
