import React from 'react';
import {
  FormGroup,
  Label,
  Col,
  CustomInput
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const FormInputFile = (props) => {
  const {
    handleChange,
    control
  } = props;

  let iconName = 'times-circle';
  let iconClass = 'ml-2 text-danger text-bold';
  let title = 'File is NOT uploaded';
  if (control.fileUploaded) {
    iconName = 'check';
    iconClass = 'ml-2 text-success text-bold';
    title = 'File has been uploaded'
  }

  return (
    <React.Fragment>
      <FormGroup row className="mt-0 mb-0">
        <Label
          for={control.name}
          sm={12} className="font-weight-bold"
        >
          {control.caption}
          <Icon name={iconName} className={iconClass} title={title} />
        </Label>
        <Col sm={12}>
          <CustomInput
            accept={control.accept || null}
            type="file"
            name={control.name}
            id={control.name}
            label={control.placeholder}
            disabled={control.disabled}
            onChange={handleChange}
          />
          {control.touched && !control.valid && (<small className="text-danger">{control.message}</small>)}
          {control.description && (<small className="text-success ml-1">{control.description}</small>)}
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

export default FormInputFile;
