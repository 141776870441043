import React from 'react';
import { connect } from "react-redux";

import {
  ComposedChart,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

import Icon from 'jsx/components/core/icons/Icon';
import ChartBase from './ChartBase';
import HorizontalChartConfigLsv from './HorizontalChartConfigLsv';
import ChartTitle from './ChartTitle';
import ChartScatter from './ChartScatter';

// Extended Chart from ChartBase
class HorizontalChart extends ChartBase {
  constructor(props) {
    super(props)

    this.state = ({
      chartData: [],
      singleSelection: false,
      widget: {
        datasets: []
      },
      height: 300,
      margin: {
        top: 20, right: -10, left: 0, bottom: 5,
      },
      filter: {},
      checksum: 0,
      availableDatasets: []
    })
  }

  // Reload datasets when checksum increases
  componentDidUpdate(prevProps) {
    if (this.props.analytics.checksum > this.state.checksum) {
      this.loadDatasets()
    }

    if (this.props.checksum !== prevProps.checksum) {
      this.loadDatasets()
    }
  }

  // Load dataset and widget for chart
  async loadDatasets() {
    const {
      group,
      selectionKey
    } = this.props;

    const {
      filters,
      checksum
    } = this.props.analytics;

    let {
      availableDatasets
    } = this.state;

    // Set filter selection in state
    const filterSelections = this.props.analytics.selections[selectionKey];

    // Pick datasets
    if (this.props.datasets && this.props.datasets.length > 0) {
      availableDatasets = this.props.datasets;
    } else {
      availableDatasets = this.props.analytics.datasets[group];
    }

    const filter = filters[group];

    // Find the site that matches
    const widget = this.props.widget;
    if (widget) {
      const chartData = [];

      // Iterate through datasets for widget
      widget.datasets.map((dataset, index) => {
        const rowName = dataset.rowName;
        const selectedColumn = dataset.selected;
        const columnName = dataset.id;

        // Get available Dataset
        const dataSource = availableDatasets.find(availableDataset => availableDataset.id === dataset.sourceId);

        // Sort if required
        // if (dataset.sortSelected) {
        //   dataSource.data.sort(function (a, b) { return a[selectedColumn.name] - b[selectedColumn.name] });
        // }

        if (dataset.sortByXAxis) {
          dataSource.data.sort(function (a, b) {
            if (a[rowName] > b[rowName]) return 1;
            if (b[rowName] > a[rowName]) return -1;
            return null;
          });
        }

        // Apply datasource to widget dataset. Filter if applicable
        widget.datasets[index].data = dataSource.data;
        if (dataset && dataset.filterColumn && dataSource.data) {
          widget.datasets[index].data = dataSource.data.filter(d => d[dataset.filterColumn] === dataset.filterValue)
        }

        // Iterate through row in dataset.data and populate chartData
        let types = ['bar', 'line']
        if (dataSource.data && types.includes(widget.datasets[index].type)) {
          dataSource.data.map(row => {
            const idx = chartData.findIndex(data => String(data.name) === String(row[rowName]));

            let columnValue = parseFloat(row[selectedColumn.calculation_type][selectedColumn.name]).toFixed(dataset.decimals || 0);
            if (widget.inverted) columnValue = -columnValue;

            // Update existing chartData row or push new row
            if (idx > 0) {
              chartData[idx][columnName] = columnValue;
            } else {
              const line = { name: row[rowName] };
              line[columnName] = columnValue;
              chartData.push(line)
            }
            return false;
          })
        }

        types = ['scatter']
        if (dataSource.data && types.includes(widget.datasets[index].type)) {
          dataSource.data.map(row => {
            const idx = chartData.findIndex(data => String(data.name) === String(row[rowName]));

            let columnValue = parseFloat(row[selectedColumn.name]).toFixed(dataset.decimals || 0);
            if (widget.inverted) columnValue = -columnValue;

            const rowValue = parseFloat(row[rowName]).toFixed(dataset.decimals || 0);

            // Update existing chartData row or push new row
            if (idx > 0) {
              chartData[idx][selectedColumn.name] = columnValue;
              chartData[idx][rowName] = rowValue;
            } else {
              const line = { name: row[rowName] };
              line[selectedColumn.name] = columnValue;
              line[rowName] = rowValue;
              chartData.push(line)
            }
            return false;
          })
        }

        if (dataSource.data && widget.datasets[index].type === 'stack') {
          dataSource.data.map(row => {
            const stackField = widget.datasets[index].stackField;
            const line = { name: String(row[rowName]) };

            row[stackField].map(stack => {
              const columnName = `${stack.upper_depth}-${stack.lower_depth}`;
              const columnValue = -parseFloat(stack[selectedColumn.calculation_type][selectedColumn.name]).toFixed(1);
              line[columnName] = columnValue;
              return false;
            })
            chartData.push(line);
            return false;
          })
          console.log(chartData)
        }
        return false;
      })

      this.setState({
        chartData,
        widget,
        availableDatasets,
        group,
        filterSelections,
        filter,
        checksum
      })
    }
  }

  render() {
    const {
      chartData,
      isPaneOpen,
      widget,
      // group,
      height,
      margin,
      availableDatasets,
      filterSelections
    } = this.state;

    // If widget is empty
    const emptyCaption = `No Chart data available for ${widget.title}`;
    const iconName = 'chart-mixed';

    let selectedColumn = '';
    if (widget.datasets.length === 1) {
      selectedColumn = ` - ${widget.datasets[0].selected.caption}`
    }
    const title = `${widget.title}${selectedColumn}`;

    return (
      <React.Fragment>
        {chartData.length === 0 && (
          <div className="p-5 text-center" style={{ height: height }}>
            <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
            <div className="mt-3">{emptyCaption}</div>
          </div>
        )}

        {chartData.length > 0 && (
          <div>
            <ChartTitle title={title} togglePane={this.togglePane} isPaneOpen={isPaneOpen} disableConfig={widget.disableConfig} />

            {!isPaneOpen && widget.style === 'scatter' && (
              <ChartScatter
                filterSelections={filterSelections}
                onSelect={this.onSelect}
                onSelectPosition={this.onSelectPosition}
                widget={widget}
                chartData={chartData}
                height={height}
                margin={margin}
              />
            )}

            {!isPaneOpen && !widget.style && (
              <ResponsiveContainer height={height}>
                <ComposedChart
                  data={chartData}
                  onClick={(e) => this.onSelect(e.activeLabel)}
                  margin={margin}
                >
                  <XAxis type={widget.type} dataKey={widget.dataKeyX ?? 'name'} />

                  {this.renderAxis('left')}
                  {this.renderAxis('right')}
                  {this.renderBarLines()}

                  <Tooltip />
                  <Legend />
                  <CartesianGrid strokeDasharray="3 3" />
                </ComposedChart>
              </ResponsiveContainer>
            )}
          </div>
        )}

        {isPaneOpen && (
          <div style={{ height: height }}>
            <HorizontalChartConfigLsv
              widget={widget}
              availableDatasets={availableDatasets}
              onChangeWidgetDataset={this.onChangeWidgetDataset}
              onUpdateWidgetDataset={this.onUpdateWidgetDataset}
              onRemoveWidgetDataset={this.onRemoveWidgetDataset}
              onAddWidgetDataset={this.onAddWidgetDataset}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    analytics: store.analytics
  }
}

export default connect(mapStoreToProps)(HorizontalChart);
