import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    activeReportVersion: {},
    activeTab: 'metrics',
    filters: {
      divisions: [],
      enterprises: [],
      enterpriseTypes: [],
    },
    hasFiltersChanged: false,
    previousFilters: {},
    reportCellEditable: true,
    reportEditable: false,
    reports: [],
    versions: [],
    lookupMetrics: [],
    matrix: {},
  },
  action,
) {
  const types = ['REPORT', 'REPORT_VERSIONS', 'REPORT_MATRIX', 'LOOKUP_METRICS', 'METRIC'];
  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_REPORT_MATRIX_FULFILLED': {
      return {
        ...updatedState,
        matrix: action.payload,
      };
    }
    case 'SET_REPORT_EDITABLE_FULFILLED': {
      return {
        ...updatedState,
        reportEditable: action.payload,
        reportCellEditable: action.payload,
      };
    }
    case 'FETCH_LOOKUP_METRICS_FULFILLED': {
      return {
        ...updatedState,
        lookupMetrics: action.payload,
      };
    }
    case 'FETCH_REPORT_VERSIONS_FULFILLED': {
      return {
        ...updatedState,
        versions: action.payload,
      };
    }
    case 'FETCH_REPORTS_FULFILLED': {
      return {
        ...updatedState,
        reports: action.payload,
      };
    }
    case 'FETCH_REPORT_FINSTATEMENT': {
      return {
        ...updatedState,
        reports: [],
      };
    }
    case 'SET_METRICS_ACTIVE_TAB': {
      return {
        ...updatedState,
        activeTab: action.payload,
      };
    }
    case 'SET_REPORT_FILTERS': {
      return {
        ...updatedState,
        filters: {
          ...updatedState.filters,
          ...action.payload,
        },
      };
    }
    case 'CLEAR_REPORT_FILTERS': {
      return {
        ...updatedState,
        filters: {
          divisions: [],
          enterprises: [],
        },
      };
    }
    case 'SET_HAS_REPORT_FILTERS_CHANGED': {
      return {
        ...updatedState,
        hasFiltersChanged: action.payload,
      };
    }
    case 'SET_PREVIOUS_REPORT_FILTERS': {
      return {
        ...updatedState,
        previousFilters: action.payload,
      };
    }
    case 'REORDER_REPORTS_FULFILLED': {
      return {
        ...updatedState,
        reports: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
