import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  comment: {
    ...controlDefaults,
    placeholder: 'Add note, comment or message',
    name: 'comment',
    type: 'textarea',
    caption: 'Comment',
    validationRules: {
      minLength: 1,
      isRequired: true
    },
  },
  level: {
    ...controlDefaults,
    name: 'level',
    type: 'select',
    caption: 'Level',
    options: [
      {id: 'info', name: 'Info'},
      {id: 'warning', name: 'critical'},
      {id: 'important', name: 'important'},
      {id: 'critical', name: 'critical'}
    ]
  },
  notify: {
    ...controlDefaults,
    name: 'notify',
    type: 'select',
    caption: 'Send Email Notification',
    options: [
      {id: false, name: 'No'},
      {id: true, name: 'Yes'}
    ]
  },
  files:{
    ...controlDefaults,
    placeholder: 'Upload supporting images or documents',
    name: 'geom',
    type: 'file',
    caption: 'Documents/Image',
    validationRules: {},
  },
}