import React, { useState } from 'react';
import Listview from "../../core/form/components/Listview";
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../core/form/components/FormInput';

const DocumentDefDefinitionLsv = (props) => {
  const {
    rows
  } = props;

  const [changed, setChanged] = useState(false);

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'edit': return (
          <td key={index} className={header.classes}><Icon name="pen-to-square" style={{ cursor: 'pointer' }} onClick={() => { setEditMode(row, true) }} /></td>
        )
        case 'type': return (
          <td key={index} className={header.classes}>{(row[header.field] ? row[header.field] : 'string')}</td>
        )
        default: return (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const renderEditRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      let control = {
        id: `${index}-${header.field}`,
        name: header.field,
        value: row[header.field]
      }
      switch (header.field) {
        case 'unit':
        case 'name':
          control.type = 'text';
          return (
            <td key={index} className={header.classes}>{<FormInput handleChange={((event) => handleChange(event, row))} control={control} />}</td>
          )
        case 'type':
          control.type = 'select';
          control.options = [
            { id: null, name: 'string' },
            { id: 'float', name: 'float' },
            { id: 'int', name: 'int' },
            { id: 'array(int)', name: 'array(int)'},
            { id: 'array(float)', name: 'array(float)'},
            { id: 'array(string)', name: 'array(string)'}
          ]
          return (
            <td key={index} className={header.classes}>{<FormInput handleChange={((event) => handleChange(event, row))} control={control} />}</td>
          )
        case 'remove': return (
          <td key={index} className={header.classes}><Icon name="trash" style={{ cursor: 'pointer' }} onClick={() => { onRowRemove(row) }} /></td>
        )
        case 'edit': return (
          <td key={index} className={`${header.classes} pt-3`}><Icon name="check" style={{ cursor: 'pointer' }} onClick={() => { setEditMode(row, false) }} /></td>
        )
        default: return (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        )
      }
    })

    return tableTd;
  };

  const handleChange = (event, row) => {
    let name = event.target.name;
    let value = event.target.value;
    row[name] = value;
    setChanged(!changed);
  }

  const onRowRemove = (target) => {
    let idx = rows.findIndex(row => row === target)
    if (idx > -1) {
      rows.splice(idx, 1);
    }
    setChanged(!changed);
  }

  const setEditMode = (row, value) => {
    row.editMode = value;
    setChanged(!changed);
  }

  const addRow = () => {
    rows.push({ name: null, type: null, unit: null, editMode: true })
    setChanged(!changed);
  }

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Type', field: 'type', classes: 'text-left' },
    { caption: 'Unit', field: 'unit', classes: 'text-left' },
    { caption: '', field: 'remove', classes: 'text-center text-danger pt-3' },
    { caption: '', field: 'edit', classes: 'text-right text-success' }
  ]


  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      if (row.editMode) {
        return (<tr key={index}>
          {renderEditRows(headers, row)}
        </tr>)
      } else {
        return (<tr key={index} onClick={() => { setEditMode(row, true) }} style={{cursor: 'pointer'}}>
          {renderRows(headers, row)}
        </tr>)
      }
    });
  }

  const iconName = 'clipboard-list';
  const emptyCaption = 'No definition values found';

  return (
    <React.Fragment>
      <div className="d-flex justify-content-end pt-1">
        <small style={{ cursor: 'pointer' }} className="text-primary" onClick={addRow}>
          <Icon name="plus" className="mr-2" />Add New Row
        </small>
      </div>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </React.Fragment>
  );
}

export default DocumentDefDefinitionLsv;
