import faker from '@faker-js/faker';
import moment from 'moment';

export const fakerIndicatorCharts = () => {
  const indicators = getFakeMetrics();

  const charts = indicators.map(indicator => {
    const data = [];
    for (let i = 0; i < 6; i++) {
      data[i] = {
        collectedAt: getFakeAgo(),
        value: faker.datatype.number({min: indicator.minValue, max: indicator.maxValue})
      }
    }
    return {
      name: indicator.name,
      category_name: indicator.category_name,
      colour: indicator.colour,
      value: faker.datatype.float({min: 5, max: 15}),
      data
    }
  })

  return charts;
}

export const fakerIndicators = (count) => {
  const rows = []
  for (let step = 0; step < count; step++) {
    const indicator = fakerIndicatorName()
    rows.push(
      {
        name: indicator.name,
        level: indicator.level,
        measures: indicator.measures,
        collected_at: getFakeAgo(),
        category_name: indicator.category_name,
        value: faker.datatype.number({min: indicator.minValue, max: indicator.maxValue}),
        acceptable_trend: indicator.acceptable_trend,
        location: indicator.location,
        colour: indicator.colour
      }
    )
  }

  rows.sort(function (a, b) { return (a.collected_at < b.collected_at ? 1 : -1) });
  return rows;
}

export const fakerIndicatorName = () => {
  const metrics = getFakeMetrics();
  const idx = faker.datatype.number({min: 1, max: metrics.length - 1});
  return metrics[idx];
}

const getFakeAgo = () => {
  const units = ['weeks', 'months', 'years'];
  const unit = units[faker.datatype.number({min: 0, max: units.length - 1})];
  return moment().subtract(faker.datatype.number({min: 1, max: 24}), unit).format('YYYY-MM-DD');
}

const getFakeCoords = () => {
  return [faker.datatype.float({min: 149.1234, max: 150.1234}), faker.datatype.float({max: -22.5432, min: -23.5543})]
}
const getFakeCoordsFormatted = () => {
  const coords = getFakeCoords();
  return `${coords[0]}, ${coords[1]}`
}

const getFakeMetrics = () =>{
  return [
    {name: 'Tree Cover', unit: '%', level:'Property', measures: 'Native Vegetation/habitat', category_name: 'Biodiversity', minValue: 0, maxValue: 100, acceptable_trend: 'Up', location: '', colour: 'pink'},
    {name: 'Connectivity', unit: '', level:'Property', measures: 'Native Vegetation/habitat', category_name: 'Biodiversity', minValue: 0, maxValue: 10, acceptable_trend: 'Up', location: '', colour: 'green'},
    {name: 'Structural complexity', unit: '%', level:'Site', measures: 'Native Vegetation/habitat', category_name: 'Biodiversity', minValue: 0, maxValue: 100, acceptable_trend: 'Up', location: getFakeCoordsFormatted(), colour: 'purple'},
    {name: '# Large Trees', unit: '', level:'Site', measures: 'Native Vegetation/habitat', category_name: 'Biodiversity', minValue: 0, maxValue: 100, acceptable_trend: 'Up', location: getFakeCoordsFormatted(), colour: 'orange'},
    {name: 'Tree Canopy Height', unit: 'm', level:'Site', measures: 'Native Vegetation/habitat', category_name: 'Biodiversity', minValue: 0, maxValue: 15, acceptable_trend: 'Up', location: getFakeCoordsFormatted(), colour: 'red'},
    {name: 'Tree Canopy Cover', unit: '%', level:'Site', measures: 'Native Vegetation/habitat', category_name: 'Biodiversity', minValue: 0, maxValue: 100, acceptable_trend: 'Up', location: getFakeCoordsFormatted() , colour: 'cyan'},
    {name: 'Shrub layer % cover', unit: '%', level:'Site', measures: 'Native Vegetation/habitat', category_name: 'Biodiversity', minValue: 0, maxValue: 100, acceptable_trend: 'Up', location: getFakeCoordsFormatted(), colour: 'blue'},
    {name: 'Coarse Woody debris', unit: '%', level:'Site', measures: 'Native Vegetation/habitat', category_name: 'Biodiversity', minValue: 0, maxValue: 100, acceptable_trend: 'Up', location: getFakeCoordsFormatted(), colour: 'yellow'},
    // {name: 'Natural ecosytems of conservation importance', unit: 'ha', level:'Property', measures: 'Natural ecosystems', category_name: 'Biodiversity', minValue: 0, maxValue: 100, acceptable_trend: 'Up', location: '', colour: 'teal'},
    {name: 'Mammal Species', unit: '', level:'Site', measures: 'Native Species', category_name: 'Biodiversity', minValue: 0, maxValue: 10, acceptable_trend: 'Up', location: getFakeCoordsFormatted(), colour: 'indigo'},
    {name: 'Bird Species', unit: '%', level:'Site', measures: 'Native Species', category_name: 'Biodiversity', minValue: 0, maxValue: 6, acceptable_trend: 'Up', location: getFakeCoordsFormatted(), colour: 'primary'},
    {name: 'Waterways Health', unit: '%', level:'Site', measures: 'Water ways', category_name: 'Waterways', minValue: 0, maxValue: 100, acceptable_trend: 'Up', location: getFakeCoordsFormatted(), colour: 'black'},
    {name: 'Ground Cover', unit: '%', level:'Site', measures: 'Water Quality', category_name: 'Water Cycle health', minValue: 0, maxValue: 100, acceptable_trend: 'Up', location: getFakeCoordsFormatted(), colour: 'warning'},
  ]
}
