import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  transaction_month: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'transaction_date',
    name: 'transaction_month',
    type: 'date-month',
    caption: 'Month',
    showInEditor: false,
    showInListview: true,
    sortColumn: 'created',
  },
  enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'enterprise.name',
    name: 'enterprise_id',
    type: 'select',
    caption: 'Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  property_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'property.name',
    name: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  product_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'product.name',
    name: 'product_id',
    type: 'select',
    caption: 'Product',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_type_id',
    type: 'select',
    caption: 'Transaction Type',
    disabled: true,
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  adjustment_type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'adjustment_type_id',
    fieldName: 'adjustment_type.name',
    type: 'select',
    caption: 'Adjustment Type',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    type: 'date',
    caption: 'Transaction Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  area_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'area_ha',
    type: 'number',
    caption: 'Area (ha)',
    description: '',
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  quantity_kg: {
    ...controlDefaults,
    placeholder: '',
    name: 'quantity_kg',
    type: 'number',
    caption: 'Quantity',
    description: 'Enter negative or positive as required',
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    totals: true,
  },
  value: {
    ...controlDefaults,
    placeholder: '',
    name: 'value',
    type: 'number',
    caption: 'Value ($/unit)',
    validationRules: {},
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
      includeDollarSign: true,
    },
    showInEditor: true,
    showInListview: false,
    totals: true,
  },
  notes: {
    ...controlDefaults,
    placeholder: '',
    name: 'notes',
    type: 'textarea',
    caption: 'Comments',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  notes_list: {
    ...controlDefaults,
    placeholder: '',
    name: 'notes',
    caption: 'Comments',
    showInListview: true,
    displayLimit: 30,
  },
};
