import React from 'react';
import SingleSearchInput from 'jsx/components/core/form/components/SingleSearchInput';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import { Button } from 'reactstrap';

const AssetsReadingsToolbar = ({
  handleSearchChange = noop,
  onToggleAdd = noop,
  onClear = noop,
  onRefresh = noop,
  searchTerm,
}) => {
  return (
    <div className="d-flex justify-content-end bg-light p-2 rounded">
      <SingleSearchInput
        placeholder="Keyword Search..."
        iconClassName="text-white bg-orange"
        value={searchTerm}
        onChange={({ target }) => handleSearchChange(target.value)}
      />
      <Button size="sm" color="success" className="mr-2" onClick={onToggleAdd}>
        <Icon name={'plus'} className="text-white" title="Add New Reading" />
      </Button>
      <Button size="sm" color="primary" onClick={onRefresh}>
        <Icon name={'rotate-right'} className="text-white" />
      </Button>
    </div>
  );
};

export default AssetsReadingsToolbar;
