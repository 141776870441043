import React from 'react';
import { connect } from "react-redux";
import Icon from 'jsx/components/core/icons/Icon';

import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';

import ChartBase from "./ChartBase";
import VerticalChartConfigLsv from "./VerticalChartConfigLsv";
import ChartTitle from './ChartTitle';

class VerticalChart extends ChartBase {
  constructor(props) {
    super(props)

    this.state = ({
      chartData: [],
      widget: {
        datasets: []
      },
      height: 400,
      filterSelections: [],
      checksum: 0,
      availableDatasets: []
    })
  }

  async loadDatasets() {
    const {
      group,
      selectionKey
    } = this.props;
  
    const {
      selections,
      checksum
    } = this.props.analytics;

    let {
      availableDatasets
    } = this.state;
    
    // Find the site that matches
    const filterSelections = selections[selectionKey];

    // Calculate the chart data set
    const widget = this.props.widget;
    if (widget) {
      const chartData = [];

      widget.datasets.map((dataset) => {
        const selectedColumn = dataset.selected;

        // Find actual source data
        if (this.props.datasets && this.props.datasets.length > 0) {
          availableDatasets = this.props.datasets;
        } else {
          availableDatasets = this.props.analytics.datasets[group];
        }

        const dataSource = availableDatasets.find(availableDataset => availableDataset.id === dataset.sourceId);

        // Filter cores to selected only
        const filterName = dataSource.filterName;
        const rowName = dataSource.rowName;
        const filteredRows = dataSource.data.filter(row => filterSelections.includes(parseInt(row[filterName])))

        filteredRows.map(row => {
          const idx = chartData.findIndex(data => String(data.name) === String(row[rowName]));
          const columnValue = parseFloat(row[selectedColumn.name]).toFixed(2);

          // Update existing chartData row or push new row
          if (columnValue !== 0) {
            if (idx > 0) {
              chartData[idx][row[filterName]] = columnValue;
            } else {
              const line = {name: row[rowName]};
              line[row[filterName]] = columnValue;
              chartData.push(line)
            }
          }
          return false;
        })
        return false;
      })

      this.setState({
        chartData,
        widget,
        group,
        filterSelections,
        checksum,
        availableDatasets
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.analytics.checksum > this.state.checksum) {
      this.loadDatasets()
    }

    if (this.props.checksum !== prevProps.checksum) {
      this.loadDatasets()
    }
  }

  getCurrentLowerDepth() {
    const {
      depth
    } = this.props.analytics;

    if (depth.lower) {
      return (<ReferenceLine y={depth.lower} label="Lower Depth" stroke="blue" />)
    }
  }

  getCurrentUpperDepth() {
    const {
      depth
    } = this.props.analytics;

    if (depth.upper) {
      return (<ReferenceLine y={depth.upper} label="Upper Depth" stroke="blue" />)
    }
  }

  render() {
    const {
      chartData,
      isPaneOpen,
      widget,
      height,
      filterSelections,
      availableDatasets
    } = this.state;

    // Draw lines or bars
    let lines = (<Line />);
    let dataset = {};
    let chartTitle = widget.title;
    // let unit = '';
    if (widget.datasets.length > 0) {
      dataset = widget.datasets[0];
      chartTitle = `${widget.title} - ${dataset.selected.caption}`
      // unit = dataset.selected.unit;

      lines = filterSelections.map((filterValue, index) => {
        switch (dataset.type) {
          case 'line':
            return (<Line key={index} name={filterValue} dataKey={filterValue} stroke={this.getNextColour(index)} type="monotone" strokeWidth="2" dot={false}/>);
          case 'bar':
            return (<Bar key={index} name={filterValue} dataKey={filterValue} fill={this.getNextColour(index)} />);
          default: break;
        }
        return false;
      })
    }

    // If widget is empty
    const emptyCaption = `No Chart data available for ${widget.title}. Select one or more sites in above charts or map`;
    const iconName = 'chart-mixed';

    return (
      <React.Fragment>
        {chartData.length === 0 && (
          <div className="p-5 text-center" style={{height: height}}>
            <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
            <div className="mt-3">{emptyCaption}</div>
          </div>
        )}

        {chartData.length > 0 && (
          <div>
            <ChartTitle title={chartTitle} togglePane={this.togglePane} isPaneOpen={isPaneOpen} />

            {!isPaneOpen && (
              <ResponsiveContainer height={height}>
                <ComposedChart
                  layout="vertical"
                  data={chartData}
                  margin={{
                      top: 20, right: 10, left: 0, bottom: 5,
                  }}
                >
                  <XAxis name={`X Axis`} type="number" />
                  <YAxis dataKey="name" type="category" unit=" cm"/>
                  {this.getCurrentUpperDepth()}
                  {this.getCurrentLowerDepth()}
                  {lines}
                  
                  <Tooltip />
                  <Legend unit=" cm"/>
                  <CartesianGrid strokeDasharray="3 3" />
                </ComposedChart>
              </ResponsiveContainer>
            )}
          </div>
        )}

        {isPaneOpen && (
          <div style={{height: height}}>
            <VerticalChartConfigLsv
              widget={widget}
              availableDatasets={availableDatasets}
              onChangeWidgetDataset={this.onChangeWidgetDataset}
              onUpdateWidgetDataset={this.onUpdateWidgetDataset}
            />
          </div>
        )}

      </React.Fragment> 
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    analytics: store.analytics
  }
}

export default connect(mapStoreToProps)(VerticalChart);
