import { isNil } from 'lodash';

export interface UnitAttributes {
  decimals?: string;
  prefix?: string;
  suffix?: string;
}
export interface MetricData {
  value: string;
}
export interface MetricItem {
  unit_attributes: UnitAttributes;
  data: MetricData;
}

export interface FormattedMetric {
  numericValue: number;
  formattedValue: string;
  prefix: string;
  suffix: string;
}

type MetricFormatter = (value: number, decimals: number) => string;

const numberFormatter: MetricFormatter = (value, decimals) =>
  Intl.NumberFormat('en', { maximumFractionDigits: decimals }).format(value);

export const getFormattedValue = ({ data, unit_attributes }: MetricItem): FormattedMetric => {
  const parsedValue = parseFloat(data?.value);
  const numericValue = Number.isNaN(parsedValue) ? 0 : parsedValue;
  const prefix = isNil(unit_attributes?.prefix) ? '' : unit_attributes.prefix;
  const suffix = isNil(unit_attributes?.suffix) ? '' : unit_attributes.suffix;
  let decimals = 0;
  if (unit_attributes?.decimals) {
    decimals = parseInt(unit_attributes.decimals);
  }

  const isNegative = numericValue < 0;
  const formattedValue = isNegative
    ? `-${prefix}${numberFormatter(Math.abs(numericValue), decimals)}${suffix}`
    : `${prefix}${numberFormatter(numericValue, decimals)}${suffix}`;

  return {
    formattedValue,
    numericValue,
    prefix,
    suffix,
  };
};
