import React from 'react';
import { Button, Alert } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const GenericToolbar = (props) => {
  const { onAddNew, addButtonText, alertText, filterClick } = props;

  return (
    <Alert color="primary" className="d-flex justify-content-end p-2">
      <div>{alertText}</div>
      <Button onClick={onAddNew} size="sm" color="success">
        <Icon name="plus" className="mr-2" />
        {addButtonText}
      </Button>
      {filterClick && (
        <Button
          id="toggler"
          size="sm"
          color="warning"
          className="text-white ml-2"
          onClick={filterClick}
        >
          Filter
        </Button>
      )}
    </Alert>
  );
};

export default GenericToolbar;
