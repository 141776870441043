import React from 'react';
import { connect } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import StatsTile from '../components/core/form/components/StatsTile';
import { Row } from 'reactstrap';

import { fetchDashboardStatsBySites } from '../components/modules/sites/actions';
import { fetchDashboardStatsByProjects } from '../components/modules/projects/actions/projects';
import { fetchDashboardStatsByAreas } from '../components/modules/areas/actions';
import { fetchDashboardStatsBySamples } from '../components/modules/lab/actions';

class DashboardCarbonizer extends React.Component {
  constructor() {
    super();

    this.state = {
      dashboard_sites: [],
      dashboard_projects: [],
      dashboard_areas: [],
      dashboard_samples: [],
    };

    this.buildDashboardSites = this.buildDashboardSites.bind(this);
    this.buildDashboardProjects = this.buildDashboardProjects.bind(this);
    this.buildDashboardAreas = this.buildDashboardAreas.bind(this);
    this.buildDashboardSamples = this.buildDashboardSamples.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchDashboardStatsBySites());
    this.props.dispatch(fetchDashboardStatsByProjects());
    this.props.dispatch(fetchDashboardStatsByAreas());
    this.props.dispatch(fetchDashboardStatsBySamples());

    this.buildDashboardSites();
    this.buildDashboardProjects();
    this.buildDashboardAreas();
    this.buildDashboardSamples();
  }

  componentDidUpdate(prevProps) {
    // Sites stats
    if (
      this.props.sites.dashboard_stats.length > 0 &&
      prevProps.sites.dashboard_stats.length === 0
    ) {
      this.buildDashboardSites();
    }

    // Projects stats
    if (
      this.props.projects.dashboard_stats.length > 0 &&
      prevProps.projects.dashboard_stats.length === 0
    ) {
      this.buildDashboardProjects();
    }

    // Area stats
    if (
      this.props.areas.dashboard_stats.length > 0 &&
      prevProps.areas.dashboard_stats.length === 0
    ) {
      this.buildDashboardAreas();
    }

    // Lab Samples stats
    if (this.props.lab.dashboard_stats.length > 0 && prevProps.lab.dashboard_stats.length === 0) {
      this.buildDashboardSamples();
    }
  }

  buildDashboardSamples() {
    const dashboard_samples = [];
    this.props.lab.dashboard_stats.map((stat, index) => {
      dashboard_samples.push(
        <StatsTile
          bottomClasses="bg-pink"
          key={index}
          value={stat.value}
          unit={stat.unit}
          title={stat.name}
        />
      );
      return false;
    });
    this.setState({ dashboard_samples });
  }

  buildDashboardSites() {
    const dashboard_sites = [];
    this.props.sites.dashboard_stats.map((stat, index) => {
      dashboard_sites.push(
        <StatsTile
          bottomClasses="bg-primary"
          key={index}
          value={stat.value}
          unit={stat.unit}
          title={stat.name}
        />
      );
      return false;
    });
    this.setState({ dashboard_sites });
  }

  buildDashboardProjects() {
    const dashboard_projects = [];
    this.props.projects.dashboard_stats.map((stat, index) => {
      dashboard_projects.push(
        <StatsTile
          bottomClasses="bg-purple"
          key={index}
          value={stat.value}
          unit={stat.unit}
          title={stat.name}
        />
      );
      return false;
    });
    this.setState({ dashboard_projects });
  }

  buildDashboardAreas() {
    const dashboard_areas = [];
    this.props.areas.dashboard_stats.map((stat, index) => {
      dashboard_areas.push(
        <StatsTile
          bottomClasses="bg-success"
          key={index}
          value={stat.value}
          unit={stat.unit}
          title={stat.name}
        />
      );
      return false;
    });
    this.setState({ dashboard_areas });
  }

  render() {
    const { currentApp } = this.props.realm;

    const { dashboard_sites, dashboard_projects, dashboard_areas, dashboard_samples } = this.state;

    const app = currentApp ? currentApp : {};

    return (
      <div className="p-5 text-center">
        <div>
          <Icon size="4x" name="wave-pulse" style={{ color: '#83a326' }} />
        </div>
        <h1 className="mt-3">{app.title} Vitals</h1>
        <p>
          Managing the CarbonLink production pipeline from Expression of Interest to completion and
          carbon credits...
        </p>

        <Row className="mt-4 d-flex justify-content-center">{dashboard_sites}</Row>

        <Row className="d-flex justify-content-center">{dashboard_projects}</Row>

        <Row className="mb-2 d-flex justify-content-center">
          {dashboard_areas}
          {dashboard_samples}
        </Row>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
    sites: store.sites,
    projects: store.projects,
    areas: store.areas,
    lab: store.lab,
  };
};

export default connect(mapStoreToProps)(DashboardCarbonizer);
