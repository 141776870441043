/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import Listview from '../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';
import { columnWidths } from '../constants/listviews';
import OverheadsLsvFeature from './OverheadsLsvFeature';

const OverheadflowLsvFeature = (props) => {
  const {
    editTransaction,
    emptyCaption,
    iconName,
    overheads,
    rows,
    overheadListControls,
    onOverheadSave,
    onOverheadDelete,
    overhead_groups,
    overhead_types,
    distributions,
    properties,
    userSelectionRef,
    transaction_intervals,
  } = props;

  const [renderState, setRenderState] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({});

  const openOverheads = async (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    collapsedSections[id] = !collapsedSections[id];
    setCollapsedSections(collapsedSections);

    setRenderState(!renderState);
  };

  const addTransactionRow = () => {
    const initDistributions = distributions.map((distribution) => ({
      ...distribution,
      distribution_value: 0,
      distribution_pcnt: 0,
    }));

    if (userSelectionRef?.current) {
      return {
        id: null,
        property_id: userSelectionRef.current?.property_id,
        group_id: userSelectionRef.current?.group_id,
        type_id: userSelectionRef.current?.type_id,
        transaction_date: userSelectionRef.current?.transaction_date,
        transaction_interval_id: userSelectionRef.current?.transaction_interval_id,
        distributions: initDistributions,
        total_amount: 0,
        editMode: true,
      };
    }

    const allPropertyIds = properties.map(({ id }) => id);
    const allGroupIds = overhead_groups.map(({ id }) => id);
    const [group_id] = allGroupIds;
    const filteredOverheadTypes = overhead_types.filter(({ parent_id }) => parent_id === group_id);
    const allTransactionIntervalIds = transaction_intervals.map(({ id }) => id);

    // add object details to below
    return {
      id: null,
      property_id: allPropertyIds[0],
      group_id,
      type_id: filteredOverheadTypes[0]?.id,
      transaction_interval_id: allTransactionIntervalIds[0],
      distributions: initDistributions,
      total_amount: 0,
      editMode: true,
    };
  };

  const renderOverheads = (group, type) => {
    // Filter transactions by group and type
    const filtered_overheads = overheads.filter(
      (overhead) => overhead.type?.parent.name === group && overhead.type?.name === type,
    );

    return (
      <OverheadsLsvFeature
        responsive={false}
        darkTable
        hideHeader
        emptyCaption={emptyCaption}
        iconName={iconName}
        onClick={editTransaction}
        rows={cloneDeep(filtered_overheads)}
        overheadListControls={overheadListControls}
        onOverheadSave={onOverheadSave}
        onOverheadDelete={onOverheadDelete}
        overhead_groups={overhead_groups}
        overhead_types={overhead_types}
        distributions={distributions}
      />
    );
  };

  const isOverheadOpen = (id) => collapsedSections[id];

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const { classes, field, formattingRules, width } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (const key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }

      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      switch (field) {
        case 'toggle': {
          const { group, type } = row;
          const overheadflowKey = `${group} - ${type}`;
          return (
            <td
              role="presentation"
              key={index}
              className={classes}
              onClick={(event) => openOverheads(event, overheadflowKey)}
              onKeyDown={(event) => openOverheads(event, overheadflowKey)}
              width={width}
            >
              <small className="mt-1 mr-2">
                <Icon
                  name={collapsedSections[overheadflowKey] ? 'chevron-up' : 'chevron-down'}
                  className="text-corporate"
                />
              </small>
            </td>
          );
        }
        default:
          return (
            <td key={index} className={classes} width={width}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const numberFormattingRules = {
    includeCommas: true,
    includeDecimals: true,
    includeDollarSign: true,
    blankIfZero: true,
  };

  const headers = [
    {
      caption: 'Group',
      classes: 'text-left',
      field: 'group',
      width: `${columnWidths.common}px`,
    },
    {
      caption: 'Type',
      classes: 'text-left',
      field: 'type',
    },
    {
      caption: 'Total',
      classes: 'text-right',
      field: 'total_amount',
      formattingRules: numberFormattingRules,
      totals: true,
      width: `${columnWidths.common}px`,
    },
    {
      caption: '',
      field: 'toggle',
      classes: 'text-center cursor-pointer',
      width: `${columnWidths.icon}px`,
    },
  ];

  // Get unique distribution headers
  const toEnd = 1;
  const headerPosition = 2;
  const deleteCount = 0;
  rows?.forEach((row) => {
    // Sort distributions by division name alphabetically
    row.distributions.sort((a, b) =>
      a?.division.name ? a.division.name.localeCompare(b.division.name) : toEnd,
    );
    row.distributions.forEach((distribution, idx) => {
      // Only add to headers if unique
      const caption = `${distribution.division.name}`;
      const headers_idx = headers.findIndex(
        (existing_header) => existing_header.caption === caption,
      );

      if (headers_idx === -1) {
        // Add idx to position number when splicing to keep alphabetical order of division names
        headers.splice(headerPosition + idx, deleteCount, {
          caption,
          field: `distributions.${idx}.amount`,
          classes: 'text-right',
          formattingRules: numberFormattingRules,
          totals: true,
          width: `${columnWidths.common}px`,
        });
      }
    });
  });

  const totals = [];
  const tableHeadTh = headers.map((header, index) => {
    if (header.totals) totals.push(index);
    return (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    );
  });

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows?.map((row, index) => {
      const { group, type } = row;
      const overheadflowKey = `${group} - ${type}`;
      return (
        <React.Fragment key={index}>
          <tr key={index}>{renderRows(headers, row)}</tr>
          {isOverheadOpen(overheadflowKey) && (
            <tr>
              <td className="bg-white" colSpan={headers.length + 1}>
                {renderOverheads(group, type)}
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
  }

  const newRow = addTransactionRow();
  const totalFormattingRules = {
    includeCommas: true,
    includeDollarSign: true,
    includeDecimals: true,
  };

  return (
    <>
      <OverheadsLsvFeature
        hideHeader
        responsive={false}
        emptyCaption={emptyCaption}
        iconName={iconName}
        onClick={editTransaction}
        rows={[newRow]}
        overheadListControls={overheadListControls}
        onOverheadSave={onOverheadSave}
        isRowEditor
        onOverheadDelete={onOverheadDelete}
        overhead_groups={overhead_groups}
        overhead_types={overhead_types}
      />

      <Listview
        responsive={false}
        rows={rows || []}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
        totals={totals}
        totalFormattingRules={totalFormattingRules}
      />
    </>
  );
};

export default OverheadflowLsvFeature;
