import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  strataVersion: {
    ...controlDefaults,
    placeholder: 'Current Strata Version',
    name: 'strata_version',
    type: 'select',
    caption: 'Current Strata Version',
    options: [
      {id: 1, name: 'Round 1 - Strata v3'},
      {id: 2, name: 'Round 1 - Strata v2'},
      {id: 3, name: 'Round 1 - Strata v1'},
    ],
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  }
}