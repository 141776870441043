import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  project_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'project_id',
    type: 'hidden',
    caption: 'Project ID',
    validationRules: {
      minLength: 6,
      isRequired: true,
    },
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Property Name',
    validationRules: {
      minLength: 6,
      isRequired: true,
    },
  },
  code: {
    ...controlDefaults,
    placeholder: '',
    name: 'code',
    type: 'text',
    caption: 'Property Code',
    validationRules: {},
  },
  idx: {
    ...controlDefaults,
    placeholder: '',
    name: 'idx',
    type: 'text',
    caption: 'Property Index',
    description:
      'Used in building the core identifier. Can be a string or number. Must be 4 characters. This field can not be altered after property has been created.',
    validationRules: {
      len: 4,
      isRequired: true,
      isAlphaNumeric: true,
    },
  },
  area_ha: {
    ...controlDefaults,
    disabled: true,
    placeholder: '',
    name: 'area_ha',
    type: 'text',
    caption: 'Area (ha)',
    description: 'Area size will be overwritten when uploading geometry',
    validationRules: {},
  },
  address: {
    ...controlDefaults,
    placeholder: 'Property Address',
    name: 'address',
    type: 'text',
    caption: 'Property Address',
    description:
      'Property Address is used to position on a map. Make sure address includes Street Address, Town and PostCode',
    validationRules: {},
  },
  state: {
    ...controlDefaults,
    placeholder: '',
    name: 'state',
    type: 'text',
    caption: 'State',
    validationRules: {},
  },
  nrm_region: {
    ...controlDefaults,
    placeholder: 'NRM Region',
    name: 'nrm_region',
    type: 'text',
    caption: 'NRM Region',
    description: 'This should probably be a lookup',
    validationRules: {},
  },
  lga: {
    ...controlDefaults,
    placeholder: 'Local Gov Area',
    name: 'lga',
    type: 'text',
    caption: 'Local Gov Area',
    description: 'This should probably be a lookup',
    validationRules: {},
  },
  nearest_town: {
    ...controlDefaults,
    name: 'nearest_town',
    type: 'text',
    caption: 'Nearest Town',
    validationRules: {},
  },
  primary_practice: {
    ...controlDefaults,
    placeholder: 'Primary Agriculture Practice',
    name: 'primary_practice',
    type: 'textarea',
    caption: 'Primary Agriculture Practice',
    textRows: 5,
    validationRules: {},
  },
  geom: {
    ...controlDefaults,
    placeholder: 'Browse to select a geojson file to replace existing property geometry',
    name: 'geom',
    type: 'file',
    caption: 'Property Geometry',
    validationRules: {},
  },
  included: {
    ...controlDefaults,
    placeholder: '',
    name: 'included',
    type: 'select',
    caption: 'Included',
    description: 'Is Property included in Project Registration?',
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    validationRules: {},
  },
  avg_rainfall: {
    ...controlDefaults,
    placeholder: '',
    name: 'avg_rainfall',
    type: 'number',
    caption: 'Rainfall',
    description: 'Average annual Rainfall',
    validationRules: {},
  },
  soc_sequestration_rate: {
    ...controlDefaults,
    placeholder: '',
    name: 'soc_sequestration_rate',
    type: 'number',
    caption: 'Seq Rate',
    description: 'SOC Sequestration Rate',
    validationRules: {},
  },
  net_income_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'net_income_ha',
    type: 'number',
    caption: 'Net Income/ha',
    description: 'Annual Net Income per Hectare',
    validationRules: {},
  },
  cost_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'cost_ha',
    type: 'number',
    caption: 'Cost/ha',
    description: 'Annual Cost per Hectare',
    validationRules: {},
  },
  baseline_cost_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'baseline_cost_ha',
    type: 'number',
    caption: 'Baseline Cost/ha',
    description: 'Baseline Cost per Hectare',
    validationRules: {},
  },
};
