// Set Gateway API parameters from Environment
export const GATEWAY_API_HOST = window.location.hostname;
export const GATEWAY_API_PORT = process.env.REACT_APP_GATEWAY_API_PORT;
export const GATEWAY_API_PROTOCOL = window.location.protocol;

// API Version
const apiVersion = 'v1';

// Hostname based staging detection with staging prefix
const { hostname } = window.location;
const stagingPrefix = 'staging-';

// Set staging environment in localstorage
localStorage.setItem('staging', hostname.substring(0, stagingPrefix.length) === stagingPrefix);

// Set Gateway API URL from Environment
let url = `${GATEWAY_API_PROTOCOL}//${GATEWAY_API_HOST}:${GATEWAY_API_PORT}`;
if (!GATEWAY_API_PORT) {
  url = `${GATEWAY_API_PROTOCOL}//${GATEWAY_API_HOST}`;
}

// Configure socket parameters from Environment
const GATEWAY_API_IO_PORT = process.env.REACT_APP_GATEWAY_IO_PORT;
const GATEWAY_API_IO_PROTOCOL = process.env.REACT_APP_GATEWAY_IO_PROTOCOL || 'http';
const GATEWAY_API_IO_HOST = process.env.REACT_APP_GATEWAY_IO_HOST || 'localhost';

// Set Socket IO url from Environment
let socketUrl = `${GATEWAY_API_IO_PROTOCOL}://${GATEWAY_API_IO_HOST}:${GATEWAY_API_IO_PORT}`;
if (!GATEWAY_API_IO_PORT) {
  socketUrl = `${GATEWAY_API_IO_PROTOCOL}://${GATEWAY_API_IO_HOST}`;
}
export const ioUrl = socketUrl;
export const ioOptions = {
  secure: true,
  reconnect: true,
  reconnectionAttempts: 5,
  transports: ['websocket'],
};

// API constants
const baseRoute = `${url}/api/${apiVersion}`;
export const apiRealm = `${baseRoute}/realm`;
export const currentUserUrl = `${baseRoute}/current`;
export const apiAdmin = `${baseRoute}/admin`;
export const apiLogin = `${baseRoute}/login`;
export const apiLogout = `${baseRoute}/logout`;
export const apiMode = `${baseRoute}/mode`;
export const apiVerify = `${baseRoute}/verify`;
export const apiRegister = `${baseRoute}/register`;
export const apiAssociate = `${baseRoute}/associate`;
export const apiRefresh = `${baseRoute}/refresh`;
export const apiReauthenticate = `${baseRoute}/reauthenticate`;
export const apiUsers = `${baseRoute}/users`;
export const apiMasquerade = `${baseRoute}/masquerade`;
export const apiRoles = `${baseRoute}/roles`;
export const apiDocumentDefs = `${baseRoute}/document_defs`;
export const apiOutputMappings = `${baseRoute}/output_mappings`;
export const apiAttributeDefs = `${baseRoute}/attribute_defs`;
export const apiDeadlines = `${baseRoute}/deadlines`;
export const apiProjects = `${baseRoute}/projects`;
export const apiProjectsRegisters = `${baseRoute}/projects/registers`;
export const apiProjectsProperties = `${baseRoute}/projects/properties`;
export const apiProperties = `${baseRoute}/properties`;
export const apiPropertySearch = `${baseRoute}/propertysearch`;
export const apiDocumentGroups = `${baseRoute}/projects/document_groups`;
export const apiStages = `${apiDocumentGroups}/stages`;
export const apiStageRequirements = `${apiDocumentGroups}/stages/requirements`;
export const apiStageRequirementItems = `${apiStageRequirements}/items`;
export const apiRounds = `${baseRoute}/rounds`;
export const apiPeriods = `${baseRoute}/periods`;
export const apiStratas = `${baseRoute}/stratas`;
export const apiSites = `${baseRoute}/sites`;
export const apiSamples = `${baseRoute}/samples`;
export const apiScanSamples = `${baseRoute}/scan/samples`;
export const apiScans = `${baseRoute}/scans`;
export const apiScanTags = `${baseRoute}/scantags`;
export const apiDataPreparations = `${baseRoute}/data_preparations`;
export const apiMethodologies = `${baseRoute}/methodologies`;
export const apiScripts = `${baseRoute}/scripts`;
export const apiStatuses = `${baseRoute}/statuses`;
export const apiTestCodes = `${baseRoute}/test_codes`;
export const apiActivities = `${baseRoute}/activities`;
export const apiInvitations = `${baseRoute}/invitations`;
export const apiForgot = `${baseRoute}/forgot`;
export const apiReset = `${baseRoute}/reset`;
export const apiAnalytics = `${baseRoute}/analytics`;
export const apiFieldTrips = `${baseRoute}/fieldtrips`;
export const apiFieldTripSites = `${baseRoute}/fieldbook`;
export const apiFieldUploads = `${baseRoute}/fielduploads`;
export const apiRoundDocuments = `${apiRounds}/documents`;
export const apiReconTrips = `${baseRoute}/recon/trips`;
export const apiLabRegister = `${baseRoute}/lab_register`;
export const apiOrgs = `${baseRoute}/orgs`;
export const apiSoilTemplates = `${baseRoute}/soil_templates`;
export const apiCrm = `${baseRoute}/crm`;
export const apiTimesheets = `${baseRoute}/timesheets`;
export const apiCostcodes = `${baseRoute}/costcodes`;
export const apiLeavecodes = `${baseRoute}/leavecodes`;
export const apiPhasecodes = `${baseRoute}/phasecodes`;
export const apiLeaves = `${baseRoute}/leaves`;
export const apiJira = `${baseRoute}/jira`;
export const apiPowerBI = `${baseRoute}/power_bi`;
export const apiHashLink = `${baseRoute}/hashlinks`;
export const apiFieldInstruments = `${baseRoute}/field_instruments`;
export const apiPipelines = `${baseRoute}/pipelines`;
export const apiReportMetrics = `${baseRoute}/report/metrics`;
export const apiReports = `${baseRoute}/reports`;
export const apiExportReports = `${apiReports}/export`;
export const apiExportEnterpriseAnalysis = `${apiReports}/enterprise-analysis/export`;

export const apiEnterprises = `${baseRoute}/enterprises`;
export const apiOffFarmIncomes = `${baseRoute}/enterprises/off-farm/incomes`;
export const apiAttributes = `${baseRoute}/attributes`;
export const apiEnterpriseLivestocks = `${apiEnterprises}/livestocks`;
export const apiEnterpriseOtherBusiness = `${apiEnterprises}/other_business`;
export const apiEnterpriseAgistments = `${apiEnterprises}/agistments`;
export const apiAnimalClasses = `${baseRoute}/orgs/animal_classes`;
export const apiAnimalClassValues = `${apiAnimalClasses}/values`;
export const apiAnimalClassValuesByMonth = `${apiAnimalClasses}/monthly-values`;
export const apiAnimalClassReproduction = `${apiAnimalClasses}/reproduction`;

export const apiAssumedAnimalClasses = `${baseRoute}/orgs/assumed_animal_classes`;
export const apiAssumedAnimalClassValues = `${apiAssumedAnimalClasses}/values`;
export const apiAssumedAnimalClassValuesByMonth = `${apiAssumedAnimalClasses}/monthly-values`;
export const apiAssumedAnimalClassValuesRanges = `${apiAssumedAnimalClassValues}/ranges`;

export const apiOrgOverheads = `${baseRoute}/orgs/overheads`;
export const apiOrgSubscriptions = `${baseRoute}/orgs/subscriptions`;
export const apiCapitalExpenditures = `${baseRoute}/orgs/capital_expenditures`;
export const apiOrgLiabilities = `${baseRoute}/orgs/liabilities`;
export const apiOrgAssets = `${baseRoute}/orgs/assets`;
export const apiLabours = `${baseRoute}/orgs/labours`;
export const apiDirectCosts = `${apiEnterprises}/direct_costs`;
export const apiPropertyOtherIncomes = `${baseRoute}/orgs/property_other_incomes`;
export const apiPropertyUsages = `${baseRoute}/properties/usages`;
export const apiOrgPlantAssetReadings = `${baseRoute}/orgs/plantassets/readings`;
export const apiPropertyRainfalls = `${baseRoute}/properties/rainfalls`;
export const apiOrgPlantAssets = `${baseRoute}/orgs/plantassets`;
export const apiLookupLocations = `${baseRoute}/locations`;
export const apiLookupLocationProjects = `${apiLookupLocations}/projects`;
export const apiOrgPlantAssetProjects = `${baseRoute}/orgs/plant/asset_projects`;
export const apiJobs = `${baseRoute}/jobs`;
export const apiSamplingPlans = `${baseRoute}/samplingplans`;
export const apiListviewConfiguration = `${baseRoute}/users/listviews`;
export const apiUserProjects = `${baseRoute}/users/projects`;
export const apiProjectPhasecodes = `${baseRoute}/projects/phasecodes`;
export const apiDaysheets = `${baseRoute}/daysheets`;
export const apiRosterShifts = `${baseRoute}/rosters/shifts`;
export const apiRosterShiftSlots = `${apiRosterShifts}/slots`;
export const apiRosterShiftAllocations = `${apiRosterShifts}allocations`;
export const apiFeatureFlags = `${baseRoute}/feature_flags`;
export const apiDownloads = `${baseRoute}/downloads`;
export const apiAnalysisGroups = `${baseRoute}/analysis-groups`;
export const apiAnalysisGroupMembers = `${baseRoute}/analysis-group-members`;
export const apiImportTransactions = `${baseRoute}/import/transactions`;
export const apiImportErrors = `${baseRoute}/import/errors`;
export const apiAnalysisPeriods = `${baseRoute}/analysis-periods`;
export const apiAdminAdvisors = `${apiAdmin}/advisors`;
export const apiPropertyAnalysisPeriods = `${apiProperties}/analysis-periods`;
export const apiPropertyAnalysis = `${apiProperties}/analysis`;

export const apiWarehouse = `${baseRoute}/warehouse`;
export const apiProbe = `${baseRoute}/probes`;
