import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { noop } from 'lodash';

const SingleSearchInput = ({ onChange = noop, value, placeholder }) => {
  return (
    <InputGroup style={{ width: 300 }} className="mr-2" size="sm">
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <Icon name="magnifying-glass" />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        className="h-auto"
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onChange}
        value={value}
      />
    </InputGroup>
  );
};

export default SingleSearchInput;
