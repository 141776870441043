import { isNil, set } from 'lodash';

/**
 * Takes data from a form and casts inputs with type of number to the correct types
 * @param data
 * @param form
 * @returns
 */
export const normaliseNumbers = (
  data: Record<string, unknown>,
  form: Record<string, { type: string; name: string; [key: string]: unknown }>,
) => {
  // Get all controls with type number, and cast the values to numbers
  Object.values(form)
    .filter(({ type }) => type === 'number')
    .forEach(({ name }) => {
      if (isNil(data[name]) || data[name] === 0) return; // Don't cast null or 0 to a number
      if (typeof data[name] === 'number') return; // Ignore if it's already a number

      let value = data[name] as string;
      // Remove all alphabetical characters, commas, and spaces
      value = value.replace(/[\sa-zA-Z,]/g, '');
      set(data, name, parseInt(value, 10));
    });

  return data;
};
