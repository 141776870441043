import axios from 'axios';
import { apiProjects, apiUsers, apiStatuses } from '../../../../constants/api.js';
import errorResponse from '../../../../lib/errorResponse.js';
import { download, get } from '../../../../lib/genericAction';

export function fetchProjectStatuses(inBackground = false) {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_PROJECT_STATUSES',
      `${apiStatuses}?type=project`,
      null,
      null,
      inBackground
    );
}

export function setFilters(filters) {
  return function (dispatch) {
    dispatch({ type: 'SET_PROJECT_FILTERS', payload: filters });
    dispatch(fetchProjects({}, filters));
  };
}

export function fetchProjects(params, filters = {}) {
  if (filters) {
    const filterKeys = Object.keys(filters);
    filterKeys.forEach((key) => {
      if (key !== 'from_created_date' && key !== 'limit') {
        params[`filter_${key}`] = filters[key];
      } else {
        params[key] = filters[key];
      }
    });
  }

  return async (dispatch) => await get(dispatch, 'FETCH_PROJECTS', apiProjects, params);
}

export function fetchProject(id) {
  return async (dispatch) => {
    dispatch({ type: 'FETCH_PROJECT_PENDING' });

    try {
      const response = await axios({
        method: 'GET',
        url: `${apiProjects}/${id}`,
      });

      dispatch({ type: 'FETCH_PROJECT_FULFILLED', payload: response.data.result });

      return response.data.result;
    } catch (err) {
      errorResponse(dispatch, err, 'FETCH_PROJECT_REJECTED');
      return false;
    }
  };
}

export function updateProject(project) {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_PROJECT_PENDING' });

    try {
      const success = await axios({
        method: 'PUT',
        url: `${apiProjects}/${project.id}`,
        data: project,
      });
      dispatch({ type: 'UPDATE_PROJECT_FULFILLED', payload: success });
      return true;
    } catch (err) {
      errorResponse(dispatch, err, 'UPDATE_PROJECT_REJECTED');
      return false;
    }
  };
}

export function createProject(project) {
  return async (dispatch) => {
    dispatch({ type: 'CREATE_PROJECT_PENDING' });

    try {
      const response = await axios({
        method: 'POST',
        url: `${apiProjects}`,
        data: project,
      });
      dispatch({ type: 'CREATE_PROJECT_FULFILLED', payload: response.data.result });
      return true;
    } catch (err) {
      errorResponse(dispatch, err, 'CREATE_PROJECT_REJECTED');
      return false;
    }
  };
}

export function removeProject(id) {
  return async (dispatch) => {
    dispatch({ type: 'REMOVE_PROJECT_PENDING' });

    try {
      const success = await axios({
        method: 'DELETE',
        url: `${apiProjects}/${id}`,
      });
      dispatch({ type: 'REMOVE_PROJECT_FULFILLED', payload: success });
      return true;
    } catch (err) {
      errorResponse(dispatch, err, 'REMOVE_PROJECT_REJECTED');
      return false;
    }
  };
}

export function fetchProjectOfficers(inBackground = false) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_PROJECT_OFFICERS', `${apiUsers}/roles/po`, null, null, inBackground);
}

export function fetchDashboardStatsByProjects() {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_DASHBOARD_STATS_BYPROJECTS',
      `${apiProjects}/statistics/dashboard`,
      null,
      null,
      true
    );
}

export function downloadProjectStatusMap(onProgressChange, params) {
  return async (dispatch) =>
    await download(
      dispatch,
      'DOWNLOAD_PROJECT_STATUS_MAP',
      `${apiProjects}/status/geojson`,
      onProgressChange,
      params
    );
}

export function fetchProjectCentroids() {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_PROJECT_CENTROIDS', `${apiProjects}/centroids`);
}
