import React from 'react';
import {
  Input
} from 'reactstrap';

const ConfigControlDatasets = (props) => {
  const {
    dataset,
    onChangeWidgetDataset,
    availableDatasets
  } = props;

  const datasetOptions = availableDatasets.map((dataset, index) => {
    return (
      <option key={index} value={dataset.id}>{dataset.name}</option>
    )
  })

  return (
    <React.Fragment>
      <Input
        onChange={(e) => onChangeWidgetDataset(e, dataset.id)}
        value={dataset.sourceId}
        type="select"
        bsSize="sm"
        style={{width: '100%'}} 
      >
        {datasetOptions}
      </Input>
    </React.Fragment>
  );
}

export default ConfigControlDatasets;
