import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button
} from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from "../../core/form/components/FormInput";
import FormBase from "../../core/form/components/FormBase";
import { initControls, saveControls, updateControls } from '../../core/form/lib/validateForm';
import { controls } from '../forms/output_mapping';

import OutputMappingDefinitionLsv from './OutputMappingDefinitionLsv';

import {
  createOutputMapping,
  fetchOutputMappings,
  updateOutputMapping,
  removeOutputMapping
} from '../actions';

class OutputMappingModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isValid: true,
      isOpen: false,
      title: 'Document Mapping',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls
    })

    this.onSave = this.onSave.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen } = this.state;
    let {
      id,
      isNew,
      title,
      controls,
      data
    } = this.state;

    const { outputMappings } = this.props.manage;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = `New Document Mapping`;
      controls = initControls(controls);
      controls.mappings.value = [];

      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = `Edit Document Mapping`;

        data = outputMappings.find(def => def.id === id);
        controls = updateControls(controls, data);
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls
      })
    }

  }

  async onSave() {
    let { data } = this.state;
    const {
      controls,
      isNew
    } = this.state;

    data = saveControls(controls, data)
    data.mappings = controls.mappings.value

    if (data.mappings) {
      data.mappings.map((row) => {
        if (row.editMode !== undefined) {
          delete row.editMode;
        }
        return row;
      })
    }

    let success;
    if (isNew) {
      delete data.id
      success = await this.props.dispatch(createOutputMapping(data));
    } else {
      success = await this.props.dispatch(updateOutputMapping(data));
    }

    if (success) {
      this.props.dispatch(fetchOutputMappings());
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const {
      data
    } = this.state;

    const confirmed = window.confirm(`Removing ${data.name.toUpperCase()} mapping permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeOutputMapping(data.id));
      if (success) {
        this.props.dispatch(fetchOutputMappings());
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  render() {
    const {
      controls,
      isOpen,
      title,
      isNew
    } = this.state;

    const {
      responseMessage
    } = this.props.manage;

    const iconName = 'clipboard-list';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && (
            <div className="text-center text-danger">{responseMessage}</div>
          )}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput handleChange={this.handleChange} control={controls.expected_name} />
            <FormInput handleChange={this.handleChange} control={controls.group_key} />
          </Form>
          <OutputMappingDefinitionLsv rows={controls.mappings.value || []} />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          {!isNew && (<Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>Delete</Button>)}
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Cancel</Button>
            <Button size="sm" color="success" onClick={this.onSave}>Save</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }

}

const mapStoreToProps = (store) => {
  return {
    manage: store.manage
  }
}

export default connect(mapStoreToProps)(OutputMappingModal);