import React from 'react';
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { initControls, saveControls, updateControls } from "../../core/form/lib/validateForm";
import FormBase from "../../core/form/components/FormBase";
import FormInput from "../../core/form/components/FormInput";
import ResponseMessage from "../../core/form/components/ResponseMessageTab";

import { controls } from '../forms/soil_template';

import {
  fetchSoilTemplates,
  createSoilTemplate,
  updateSoilTemplate,
  removeSoilTemplate
} from '../actions';


class SoilTemplateModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isOpen: false,
      title: 'Soil Template',
      setModal: null,
      isNew: false,
      id: null,
      controls: controls,
      data: {}
    })

    this.onSave = this.onSave.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen } = this.state;
    let {
      controls,
      title,
      id,
      isNew,
      data
    } = this.state;

    const { soilTemplates } = this.props.manage;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = 'New Soil Template';
      controls = initControls(controls);

      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = 'Edit Soil Template';
        data = soilTemplates.find(soilTemplate => soilTemplate.id === id);
        controls = updateControls(controls, data);
      }

      this.setState({
        isOpen: this.props.isOpen,
        setModal: this.props.setModal,
        controls,
        id,
        isNew,
        title,
        data
      })
    }

  }

  async onSave() {
    const { controls, isNew } = this.state;
    let { data } = this.state;

    data = saveControls(controls, data)

    let success;
    if (isNew) {
      delete data.id;
      success = await this.props.dispatch(createSoilTemplate(data));
    } else {
      success = await this.props.dispatch(updateSoilTemplate(data));
    }

    if (success) {
      this.props.dispatch(fetchSoilTemplates());
      this.onCancel();
    }
  }

  async onRemove() {
    const {
      data
    } = this.state;

    const confirmed = window.confirm(`Removing ${data.name.toUpperCase()} template permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeSoilTemplate(data.id));
      if (success) {
        this.props.dispatch(fetchSoilTemplates());
        this.onCancel();
      }
    }
  }


  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  render() {
    const {
      controls,
      isOpen,
      title,
      isNew
    } = this.state;

    const {
      responseMessage
    } = this.props.manage;

    const iconName = 'layer-group';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          <ResponseMessage responseMessage={responseMessage} />
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput handleChange={this.handleChange} control={controls.type} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          {!isNew && (<Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>Delete</Button>)}
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Cancel</Button>
            <Button size="sm" color="success" onClick={this.onSave}>Save</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }

}

const mapStoreToProps = (store) => {
  return {
    manage: store.manage
  }
}

export default connect(mapStoreToProps)(SoilTemplateModal);
