import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Alert } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import {
  saveControls,
  initControls,
  validateFormFieldControls,
} from '../../core/form/lib/validateForm';
import { controls as inviteControls } from '../forms/invitation';
import FormInput from '../../core/form/components/FormInput';
import FormBase from '../../core/form/components/FormBase';
import { fetchOrg, inviteUser, fetchInviteOrgs, inviteLink } from '../actions';

class Invitation extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Invite New User',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: inviteControls,
    };

    this.onSend = this.onSend.bind(this);
    this.onGenerate = this.onGenerate.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen, controls } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      initControls(controls);

      // Check if org_id has been passed
      const { orgId } = this.props;

      if (orgId) {
        // Add specific client organisation (verified on the api as allowed for user)
        this.props.dispatch(fetchOrg(orgId)).then((clientOrg) => {
          controls.default_org_id.options.push({ id: clientOrg.id, name: clientOrg.name });
          controls.default_org_id.value = clientOrg.id;
          controls.default_org_id.disabled = true;
        });
      } else {
        // Build options list from user associated orgs
        this.props.dispatch(fetchInviteOrgs()).then((orgs) => {
          controls.default_org_id.options = orgs.map((org) => ({ id: org.id, name: org.name }));

          // Set default organsation
          if (orgs.length > 0) {
            controls.default_org_id.value = orgs[0].id;
          }
        });
      }

      this.setState({
        controls,
        isOpen: this.props.isOpen,
        setModal: this.props.setModal,
      });
    }
  }

  async onGenerate() {
    const { controls } = this.state;
    const currentRealm = this.props.realm.currentApp.id;
    let { data } = this.state;
    data = saveControls(controls, data);

    // Check if child app invite
    const { isChildAppInvite } = this.props;

    if (isChildAppInvite) data = { ...data, isChildAppInvite };

    if (currentRealm !== 'wipstar') {
      delete controls.mobile;
    }

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (isValid) {
      const success = await this.props.dispatch(inviteLink(data));
      if (success) {
        this.setState({
          controls: updatedControls,
        });
      }
    } else {
      this.setState({
        controls: updatedControls,
      });
    }
  }

  async onSend() {
    const { controls } = this.state;
    const currentRealm = this.props.realm.currentApp.id;
    let { data } = this.state;
    data = saveControls(controls, data);

    // Check if child app invite
    const { isChildAppInvite } = this.props;
    if (isChildAppInvite) data = { ...data, isChildAppInvite };

    if (currentRealm !== 'wipstar') {
      delete controls.mobile;
    }

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (isValid) {
      const success = await this.props.dispatch(inviteUser(data));
      if (success) {
        this.state.setModal(false);
        this.setState({
          isOpen: false,
          controls: updatedControls,
        });
      }
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
    this.props.dispatch({ type: 'UNSET_USER_INVITE_LINK' });
  }

  render() {
    const { controls, isOpen, title } = this.state;
    const currentRealm = this.props.realm.currentApp.id;

    let { showSendButton } = this.props;

    showSendButton = showSendButton ?? true;

    const { responseMessage, invitationLink } = this.props.manage;

    const infoText = `This form will send your new user an email inviting them to log into this application.
    They will need to click on the email link, add their firstname/lastname and set their
    password before they will be access the application. PLEASE NOTE that user passwords are
    case-sensitive!`;

    // const responseMessage = '';
    const iconName = 'user';

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          <Alert>{infoText}</Alert>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          {invitationLink && (
            <Alert className="alert alert-info">Invitation link: {invitationLink}</Alert>
          )}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.firstname} />
            <FormInput handleChange={this.handleChange} control={controls.lastname} />
            <FormInput handleChange={this.handleChange} control={controls.email} />
            {currentRealm === 'wipstar' && (
              <FormInput handleChange={this.handleChange} control={controls.mobile} />
            )}
            <FormInput handleChange={this.handleChange} control={controls.default_org_id} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="ml-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
            {showSendButton && (
              <Button size="sm" className="ml-2" color="success" onClick={this.onSend}>
                Send Invite
              </Button>
            )}

            {!showSendButton && (
              <Button size="sm" className="ml-2" color="primary" onClick={this.onGenerate}>
                Generate Invite Link
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ manage, realm }) => ({ manage, realm });

export default connect(mapStoreToProps)(Invitation);
