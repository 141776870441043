import React, {Fragment} from 'react';
import {
  Row,
  Col
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const PropertySearchLotLsvBeta = (props) => {
  const {
    selected,
    zoomToLot
  } = props;

  let selectedItems = (<div className="text-center p-2 text-secondary">No lots selected. Start selecting blocks by clicking on the map lots.</div>)
  if (selected.length > 0) {
    selectedItems = selected.map((item, index) => {
      return (
        <Row key={index} className="m-0 ml-2 p-1 cells " onClick={() => zoomToLot(item, 100)} >
          <Col sm={1} className="icon">
            <Icon name="chevron-left" />
          </Col>
          <Col className="m-0 p-0">
            <div className="title">{item.properties.lotplan}</div>
            <div className="subtitle-left">{item.properties.ha} ha</div>
          </Col>
        </Row>
      )
    })
  }

  return (
    <Fragment>
      {selectedItems.length > 0 && (
        <div className="text-center">
          <small className="text-secondary m-1">Click on lotplan to zoom</small>
        </div>
      )}
      {selectedItems}
    </Fragment>
  );
}

export default PropertySearchLotLsvBeta;
