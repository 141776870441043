/* eslint-disable no-restricted-syntax, no-param-reassign */
import React from 'react';
import moment from 'moment';
import Listview from '../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';
import { columnWidths } from '../constants/listviews';

const OverheadsLsv = (props) => {
  const { darkTable, emptyCaption, hideHeader, iconName, onClick, rows } = props;

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const { classes, field, formattingRules, type, width } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (const key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }

      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      switch (type) {
        case 'date':
          return (
            <td key={index} className={classes} width={width}>
              {caption ? moment(caption).format('Do MMM YYYY') : ''}
            </td>
          );
        case 'date-month':
          return (
            <td key={index} className={classes} width={width}>
              {caption ? moment(caption).format('MMMM YYYY') : ''}
            </td>
          );
        default:
          return (
            <td key={index} className={classes} width={width}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const headers = [
    {
      caption: 'Month',
      field: 'transaction_date',
      type: 'date-month',
      classes: 'text-left',
      width: `${columnWidths.common}px`,
      sortColumn: 'created',
    },
    {
      caption: 'Description',
      field: 'type.name',
      classes: 'text-left',
    },
    {
      caption: 'Total',
      field: 'total_amount',
      classes: 'text-right',
      formattingRules: {
        includeCommas: true,
        includeDollarSign: true,
        includeDecimals: true,
      },
      width: `${columnWidths.common}px`,
    },
    {
      caption: '',
      classes: 'text-left',
      width: `${columnWidths.icon - columnWidths.offset}px`,
    },
  ];

  // Get unique distribution headers
  const toEnd = -1;
  const headerPosition = 2;
  const deleteCount = 0;
  rows.forEach((row) => {
    // Sort distributions by division name alphabetically
    row.distributions.sort((a, b) =>
      a?.division.name ? a.division.name.localeCompare(b.division.name) : toEnd,
    );

    row.distributions.forEach((distribution, idx) => {
      // Only add to headers if unique
      const caption = `${distribution.division.name}`;
      distribution.distribution_value = row.total_amount * distribution.distribution_pcnt;
      const headers_idx = headers.findIndex(
        (existing_header) => existing_header.caption === caption,
      );

      if (headers_idx === -1) {
        headers.splice(headerPosition + idx, deleteCount, {
          caption,
          field: `distributions.${idx}.distribution_value`,
          classes: 'text-right',
          formattingRules: {
            includeDecimals: true,
            includeDollarSign: true,
            includeCommas: true,
            blankIfZero: true,
          },
          width: `${columnWidths.common}px`,
        });
      }
    });
  });

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes} width={header.width}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      const properties = {
        onClick: () => onClick(row.id),
        style: { cursor: 'pointer' },
      };

      // Check and remove table row properties for converted Unpaid Labour rows
      if (row.removeOnClick) {
        delete properties.onClick;
        delete properties.style;
      }

      return (
        <tr key={index} {...properties}>
          {renderRows(headers, row)}
        </tr>
      );
    });
  }

  return (
    <Listview
      darkTable={darkTable}
      hideHeader={hideHeader}
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default OverheadsLsv;
