import React from 'react';
import { connect } from "react-redux";
import {
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import ResponseMessage from "../../../core/form/components/ResponseMessageTab";
import SpinnerButton from "../../../core/form/components/SpinnerButton";
import DirectUpload from "../../../core/form/components/DirectUpload";
import DirectDownload from '../../../core/form/components/DirectDownload';
import LabRegisterRecordSamplesLsv from '../components/LabRegisterRecordSamplesLsv';
import FormBase from "../../../core/form/components/FormBase";

import {
  fetchLabRegisterRecord,
  importLabRegisterSamples,
  uploadLabRegisterResults,
  downloadLabRegisterCustodyRecord,
  downloadLabRegisterLabels,
  bulkUpdateLabRegisterSamples,
  fetchLabRegisterSampleImage
} from '../actions';

class LabRegisterRecordSamples extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      importing: false,
      sampleSelection: [],
      sampleTestCodes: [],
      modifyDisabled: true
    })

    this.onImport = this.onImport.bind(this);
    this.onDownloadCustodyRecord = this.onDownloadCustodyRecord.bind(this);
    this.onDownloadLabels = this.onDownloadLabels.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onToggleSelectAll = this.onToggleSelectAll.bind(this);
    this.fetchImage = this.fetchImage.bind(this);
    this.setLabCode = this.setLabCode.bind(this);
  }

  async onImport() {
    const { currentRegisterRecord } = this.props.lab;

    this.setState({
      importing: true
    });

    await this.props.dispatch(importLabRegisterSamples(currentRegisterRecord.id));

    this.props.dispatch(fetchLabRegisterRecord(currentRegisterRecord.id));

    this.setState({
      importing: false
    })
  }

  async onDownloadCustodyRecord(onProgressChange) {
    const { currentRegisterRecord } = this.props.lab;

    return await this.props.dispatch(downloadLabRegisterCustodyRecord(currentRegisterRecord.id, onProgressChange));
  }

  async onDownloadLabels(onProgressChange) {
    const { currentRegisterRecord } = this.props.lab;

    return await this.props.dispatch(downloadLabRegisterLabels(currentRegisterRecord.id, onProgressChange));
  }

  async onUpload(event, onProgressChange) {
    const { currentRegisterRecord } = this.props.lab;

    if (event.target.files.length === 0) return false;

    const file = event.target.files[0];

    let formData = new FormData();
    formData.append('document', file);
    formData.append('register_id', currentRegisterRecord.id);

    const success = await this.props.dispatch(uploadLabRegisterResults(formData, onProgressChange))
    if (success) this.props.dispatch(fetchLabRegisterRecord(currentRegisterRecord.id));
  }

  onSelect(event) {
    let {
      sampleSelection,
      modifyDisabled
    } = this.state;

    const state = event.target.checked;
    const id = event.target.value;
    if (state) {
      sampleSelection.push(id);
    } else {
      const idx = sampleSelection.findIndex(sample_id => sample_id === id);
      if (idx > -1) {
        sampleSelection.splice(idx, 1);
      }
    }

    if (sampleSelection.length > 0) {
      modifyDisabled = false
    }

    this.setState({
      sampleSelection,
      modifyDisabled
    })
  }

  onToggleSelectAll() {
    let {
      sampleSelection,
      modifyDisabled
    } = this.state;

    const { currentRegisterRecord } = this.props.lab;
    const rows = currentRegisterRecord.lab_register_samples;

    modifyDisabled = rows && rows.length === sampleSelection.length;
    sampleSelection = [];

    if (!modifyDisabled) {
      rows.map(row => {
        sampleSelection.push(row.id);
        return false;
      })
    }

    this.setState({
      sampleSelection,
      modifyDisabled
    })
  }

  async setLabCode(code) {
    let {
      sampleSelection
    } = this.state;

    const { currentRegisterRecord } = this.props.lab;

    let formData = new FormData();
    sampleSelection.map(selection_id => {
      formData.append('ids[]', selection_id);
      return false;
    })
    
    formData.append('test_code_ids[]', code)
    await this.props.dispatch(bulkUpdateLabRegisterSamples(currentRegisterRecord.id, formData));


    this.props.dispatch(fetchLabRegisterRecord(currentRegisterRecord.id));
  }

  async fetchImage(params) {
    return await this.props.dispatch(fetchLabRegisterSampleImage(params));
  }

  render() {
    const {
      importing,
      modifyDisabled,
      sampleSelection
    } = this.state;

    const {
      currentRegisterRecord,
      responseMessage
    } = this.props.lab;

    const { orgCodes } = this.props.manage;
    const disabled = !(this.checkAccess('labUpload') && currentRegisterRecord.lab_register_samples && currentRegisterRecord.lab_register_samples.length > 0);

    let rows = currentRegisterRecord.lab_register_samples;
    let labCodes = (<DropdownItem key={0} disabled={true}>---</DropdownItem>)
    if (orgCodes.length > 0) {
      labCodes = orgCodes.map((code, index) => {
        return (
          <DropdownItem key={index} onClick={() => this.setLabCode(code.test_code.id)}>{code.test_code.code}</DropdownItem>
        )
      })
    }


    let selectAllCaption = "Select All"
    if (rows && rows.length === sampleSelection.length) {
      selectAllCaption = "Deselect All"
    }

    return (
      <div>
        <div className="bg-light p-2 d-flex justify-content-end rounded mb-1">
          <ResponseMessage responseMessage={responseMessage} />
          <Button onClick={this.onToggleSelectAll} className="bg-corporate btn-sm ml-2 mr-2">{selectAllCaption}</Button>
          <UncontrolledButtonDropdown className="ml-2 mr-2">
            <DropdownToggle disabled={modifyDisabled || !this.checkAccess('labRecordUpdate')} caret color='primary' size="sm">Modify Selected </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header className="bg-light">Set Lab Test Code</DropdownItem>
              {labCodes}
            </DropdownMenu>
          </UncontrolledButtonDropdown>

          <SpinnerButton
            buttonColor="primary"
            className="ml-2 mr-2"
            onClick={this.onImport}
            isSpinning={importing}
            disabled={!disabled || !this.checkAccess('labUpload')}
            caption="Import Samples"
          />
          <DirectDownload
            classNames="ml-2 mr-2"
            handleDownload={(onProgressChange) => this.onDownloadCustodyRecord(onProgressChange)}
            caption="Download Chain of Custody"
            size="sm"
            disabled={disabled}
            showLabel={false}
            width={185}
          />
          <DirectDownload
            classNames="ml-2 mr-2"
            handleDownload={(onProgressChange) => this.onDownloadLabels(onProgressChange)}
            caption="Download Sample Labels"
            size="sm"
            disabled={disabled}
            showLabel={false}
            width={185}
          />
          <DirectUpload
            classNames="ml-2 mr-2"
            handleUpload={(event, onProgressChange) => this.onUpload(event, onProgressChange)}
            caption="Upload Lab Results"
            size="sm"
            disabled={disabled}
            showLabel={false}
            width={135}
          />
        </div>
        <LabRegisterRecordSamplesLsv
          currentRecord={currentRegisterRecord}
          fetchImage={this.fetchImage}
          rows={rows}
          rowSelection={sampleSelection}
          handleSelect={this.onSelect}
        />
      </div>
    )
  }
}
const mapStoreToProps = (store) => {
  return {
    lab: store.lab,
    realm: store.realm,
    manage: store.manage
  }
}

export default connect(mapStoreToProps)(LabRegisterRecordSamples);
