import React from 'react';

const SiteSamplesColours = (props) => {
  const {
    samples
  } = props;

  let colourDepths = (<td />)
  if (samples && samples.length > 0) {
    const colours = samples.filter(sample => sample.reading_red && sample.reading_green && sample.reading_blue);
    colourDepths = colours.map((sample, index) => {
      const rgb = `rgb( ${sample.reading_red}, ${sample.reading_green}, ${sample.reading_blue})`

      // Set a border cause it is selected position
      let className = "border border-white"

      return (
        <td 
          title={`Position: ${sample.position}mm, RGB: ${rgb}`}
          key={index}
          className={className}
          style={{backgroundColor: rgb}}
        >
        </td>
      )
    })
  }

  return (
    <React.Fragment>
      <div className='core-viewer'>
        <div className="d-flex justify-content-between bg-light p-2">
          <small className="text-secondary">Top of Core</small>
          <div>Core Depth Colours (Hover for RGB)</div>
          <small className="text-secondary">Bottom of Core</small>
        </div>
        <div className="bg-light border p-2" >
          <table>
            <tbody>
              <tr>
                {colourDepths}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SiteSamplesColours;
