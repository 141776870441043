import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import DirectUpload from "../../../core/form/components/DirectUpload";

const AreaMenu = (props) => {
  const {
    handleAreaUpload,
    handleSiteUpload,
    handleStrataUpload,
    allowStrata,
    allowSite,
    checkAccess
  } = props;

  return (
    <React.Fragment>
      <div className="mt-1">
        <UncontrolledButtonDropdown>
          <DropdownToggle caret color='primary' size="sm">Action </DropdownToggle>
          <DropdownMenu right>

            <DropdownItem divider />
            <DropdownItem header className="bg-light">Upload Actions</DropdownItem>

            <DropdownItem toggle={true} onClick={handleAreaUpload} disabled={!checkAccess('propertyMapUpdate')}>
              Upload Area Geometry
            </DropdownItem>

            <DropdownItem toggle={false} disabled={(!checkAccess('propertyMapUpdate') && !allowStrata) || !allowStrata}>
              <DirectUpload
                handleUpload={handleStrataUpload}
                caption="Upload Strata Geometry"
                size="sm"
                width={150}
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem toggle={true} onClick={handleSiteUpload} disabled={(!checkAccess('propertyMapUpdate') && !allowSite) || !allowSite}>
              Upload Site Geometry
            </DropdownItem>

          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </React.Fragment>
  );
}

export default AreaMenu;
