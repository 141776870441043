// ES-280 https://elementree.atlassian.net/browse/ES-280
/* eslint-disable implicit-arrow-linebreak  */
import React from 'react';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { fetchUsers } from 'jsx/components/manage/actions';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import FormBase from '../../../core/form/components/FormBase';
import AssetWorkActivitiesModal from '../components/AssetWorkActivitiesModal';
import AssetWorkActivitiesLsv from '../components/AssetWorkActivitiesLsv';
import { controls as jobsControls } from '../forms/workactivity';
import AssetWorkActivitiesToolbar from '../components/AssetActivitiesToolbar';
import { fetchAttributes } from '../../portrait/actions/attributes';
import { createJob, fetchJobs, updateJob, fetchJob, removeJob } from '../actions/jobs';
import { fetchProjects } from '../../projects/actions/projects';
import { fetchComments } from '../actions/job_comments';
import { fetchProjectPhasecodes } from '../../projects/actions/phasecodes';

class AssetWorkActivities extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      filteredAssets: [],
      isNew: false,
      isModalOpen: false,
      modalData: null,
      modalType: 'workactivities',
      status: null,
      project: null,
      phasecode: null,
      jobComments: null,
      jobId: null,
      projectId: null,
    };
    this.setModal = this.setModal.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.loadJobs = this.loadJobs.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onActivitiesFilterClear = this.onActivitiesFilterClear.bind(this);
    this.onProjectChange = this.onProjectChange.bind(this);
    this.onPhasecodeChange = this.onPhasecodeChange.bind(this);
    this.loadComments = this.loadComments.bind(this);
    this.copySelections = this.copySelections.bind(this);
    this.fillControlDefaults = this.fillControlDefaults.bind(this);
    this.setNewModal = this.setNewModal.bind(this);
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchAttributes({ type: 'job_types' }));
    dispatch(fetchAttributes({ type: 'job_statuses' }));
    dispatch(fetchAttributes({ type: 'job_priorities' }));
    dispatch(fetchUsers());
    dispatch(fetchProjects());
    dispatch(fetchJobs());
    dispatch(fetchProjectPhasecodes());
  }

  loadJobs() {
    this.props.dispatch(fetchJobs());
  }

  async loadComments(job_id) {
    await this.props.dispatch(fetchComments(job_id));
  }

  copySelections(projectId) {
    this.setState({ projectId });
  }

  onClose() {
    const { activeTabJobs } = this.props.wipstar;
    this.props.dispatch({ type: 'SET_WORKACTIVITY_TAB', payload: 'activity_tab' });

    switch (activeTabJobs) {
      case 'workactivities': {
        this.loadJobs();
        break;
      }

      default:
        break;
    }
  }

  onSave(data, isNew) {
    const { modalType } = this.state;
    const updatedData = cloneDeep(data);

    if (isNew) delete updatedData.id;

    let saveMethod;
    switch (modalType) {
      case 'workactivities': {
        saveMethod = isNew ? createJob : updateJob;
        break;
      }

      default:
        break;
    }

    return this.props.dispatch(saveMethod(updatedData));
  }

  onRemove(id) {
    const { modalType } = this.state;
    const removeMethod = modalType === 'workactivities' ? removeJob : null;

    if (removeMethod) {
      return this.props.dispatch(removeMethod(id));
    }

    return false;
  }

  async setModal(isModalOpen, modalType, modalId, row) {
    const jobId = row ? row.id : null;

    let modalData;
    let jobComments;

    if (isModalOpen) {
      switch (modalType) {
        case 'workactivities': {
          if (modalId) {
            modalData = await this.props.dispatch(fetchJob(modalId));
            jobComments = await this.props.dispatch(fetchComments(modalId));
          }
          break;
        }

        default:
          break;
      }
    }

    this.setState({ isModalOpen, modalData, jobComments, jobId });
  }

  async setNewModal(isModalOpen, modalType, modalId, row) {
    this.setState({ projectId: null });
    await this.setModal(isModalOpen, modalType, modalId, row);
  }

  onStatusChange = (status) => {
    this.setState({ status });
  };

  onProjectChange = (project) => {
    this.setState({ project });
  };

  onPhasecodeChange = (phasecode) => this.setState({ phasecode });

  onActivitiesFilterClear = () => {
    this.setState({ status: null, project: null, phasecode: null });
  };

  setModalOptions(type) {
    const { job_priorities, job_types, job_statuses } = this.props.attributes;
    const {
      projects: { rows },
      phasecodes,
    } = this.props.projects;
    const { users } = this.props.manage;

    let options = {};
    let controls = {};

    switch (type) {
      case 'workactivities': {
        const workActivityType = job_types.find(({ tag }) => tag === 'job_type_workactivity');
        controls = cloneDeep(jobsControls);
        options = {
          allowRemoveMethod: false,
          title: 'Work Activity',
          iconName: 'list',
          controls,
          options: {
            type_id: workActivityType ? [workActivityType] : [],
            status_id: job_statuses,
            priority_id: job_priorities,
            assignedto_id: users,
            project_id: rows.map(({ project_job_no, name, ...project }) => ({
              ...project,
              name: `${name} (${project_job_no})`,
            })),
            phasecode_id: phasecodes.map(({ code, name, ...phasecode }) => ({
              ...phasecode,
              name: `${name} (${code})`,
            })),
          },
        };
        break;
      }

      default:
        break;
    }

    return options;
  }

  static applyFilters({ rows, status, project, phasecode }) {
    let filteredJobsByAssignee = cloneDeep(rows);

    if (project) {
      filteredJobsByAssignee = filteredJobsByAssignee.filter(
        ({ project_id, job_project }) =>
          project_id === project.value || job_project.project_job_no === project.value,
      );
    }

    if (status) {
      filteredJobsByAssignee = filteredJobsByAssignee.filter(
        ({ status_id }) => status_id === status.value,
      );
    }

    if (phasecode) {
      filteredJobsByAssignee = filteredJobsByAssignee.filter(
        ({ phasecode_id }) => phasecode_id === phasecode.value,
      );
    }

    return filteredJobsByAssignee;
  }

  async fillControlDefaults(controls) {
    const { projectId } = this.state;

    // Set default organsation
    if (projectId !== null) {
      controls.project_id.value = projectId;
    }

    return controls;
  }

  render() {
    const { modalType, isModalOpen, modalData, status, project, phasecode, jobComments, jobId } =
      this.state;
    const { responseMessage, jobs } = this.props.wipstar;
    const modalOptions = this.setModalOptions(modalType);
    let { rows } = jobs;
    const { currentUser } = this.props.manage;
    const { job_statuses } = this.props.attributes;
    const { phasecodes, projects } = this.props.projects;
    const job_projects = projects.rows;

    rows = AssetWorkActivities.applyFilters({
      rows,
      status,
      project,
      phasecode,
    });

    return (
      <div className="d-flex flex-column h-100">
        <AssetWorkActivitiesToolbar
          setNewModal={this.setNewModal}
          onActivitiesFilterClear={this.onActivitiesFilterClear}
          onStatusChange={this.onStatusChange}
          onPhasecodeChange={this.onPhasecodeChange}
          onProjectChange={this.onProjectChange}
          phasecode={phasecode}
          phasecodes={phasecodes}
          job_projects={job_projects}
          job_statuses={job_statuses}
          project={project}
          status={status}
        />
        <div className="mt-2">
          <AssetWorkActivitiesLsv rows={rows} setModal={this.setModal} />
        </div>
        <AssetWorkActivitiesModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          copySelections={this.copySelections}
          setModal={this.setModal}
          data={modalData}
          isOpen={isModalOpen}
          iconName={modalOptions.iconName}
          onClose={this.onClose}
          onSave={this.onSave}
          onRemove={this.onRemove}
          responseMessage={responseMessage}
          jobComments={jobComments}
          loadComments={this.loadComments}
          currentUser={currentUser}
          jobId={jobId}
          fillControlDefaults={this.fillControlDefaults}
        />
      </div>
    );
  }
}

const mapStoreToProps = ({ wipstar, attributes, manage, projects }) => ({
  wipstar,
  attributes,
  manage,
  projects,
});

export default connect(mapStoreToProps)(withContainerError(AssetWorkActivities));
