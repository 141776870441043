import React from 'react';
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Row,
  Col,
  Alert
} from 'reactstrap';

import {
  updateControls,
  initControls,
  saveFormControls
} from "../../../core/form/lib/validateForm";

import { controls } from "../forms/projectStageRequirementItem.js";
import ResponseMessageTab from "../../../core/form/components/ResponseMessageTab";
import FormInput from "../../../core/form/components/FormInput";
import FormInputFile from "../../../core/form/components/FormInputFile";
import FormBase from "../../../core/form/components/FormBase";
import Icon from 'jsx/components/core/icons/Icon';
import DirectDownload from '../../../core/form/components/DirectDownload';

import Downloads from '../lib/downloads';

import {
  fetchRequirementItems,
  createRequirementItem,
  updateRequirementItem,
  fetchRequirementItem,
  deleteRequirementItem,
  fetchRequirementItemCategories
} from '../actions/stages';


class StageRequirementItemModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isValid: true,
      isOpen: false,
      title: 'Requirement Item',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls,
      requirement_id: null
    })

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async componentDidUpdate() {
    let {
      isOpen,
      id,
      isNew,
      title,
      controls
    } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = `New Requirement Item`;
      this.setState({
        controls: initControls(this.state.controls),
        data: {}
      })

      this.downloads = new Downloads();
      this.downloads.set(this.props.dispatch)

      this.props.dispatch(fetchRequirementItemCategories())

      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = `Edit Requirement Item`;
        const result = this.props.dispatch(fetchRequirementItem(id));
        result.then(data => {
          controls = updateControls(controls, data);
          this.setState({ controls, data })
        })
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        project_id: this.props.project_id,
        setModal: this.props.setModal,
      })
    }
    this.bindOptions()
  }

  bindOptions() {
    let {
      controls
    } = this.state;
    const { itemCategories } = this.props.stages;

    if (!controls['status_id'].options && itemCategories.length > 0) {
      controls['status_id'].options = itemCategories;
      this.setState({ controls })
    }

    if (!controls['status_id'].value && controls['status_id'].options) {
      controls['status_id'].value = controls['status_id'].options[0].id;
      this.setState({ controls })
    }

    if (!controls['type_key'].value && controls['type_key'].options) {
      controls['type_key'].value = controls['type_key'].options[0].id;
      this.setState({ controls })
    }
  }

  async onSave() {
    let {
      data,
      controls,
      isNew
    } = this.state;

    let formData = saveFormControls(controls, data)

    let success;
    if (isNew) {
      formData.delete('id');
      formData.set('requirement_id', this.props.requirement_id)
      formData.set('external_reference', {})

      success = await this.props.dispatch(createRequirementItem(formData));
    } else {
      success = await this.props.dispatch(updateRequirementItem(formData));
    }

    if (success) {
      this.props.dispatch(fetchRequirementItems({ requirement_id: this.props.requirement_id }))
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const {
      data
    } = this.state;

    const confirmed = window.confirm(`Removing requirement item ${data.name} permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(deleteRequirementItem(data.id));
      if (success) {
        this.props.dispatch(fetchRequirementItems({ requirement_id: data.requirement_id }))
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  render() {
    const {
      controls,
      isOpen,
      title,
      isNew,
      data
    } = this.state;

    const {
      responseMessage,
      requirementMessage,
      unlockTemplate
    } = this.props.stages;

    const iconName = 'file';

    // Set document link
    let downloadLink = (<div className="text-secondary">--</div>)
    if (data.external_reference && data.external_reference.originalname) {
      const caption = `Download ${data.external_reference.originalname}`;

      downloadLink = (<DirectDownload
        classNames="p-0"
        buttonType="primary"
        caption={caption}
        handleDownload={(event) => this.downloads.handleStageRequirementItemDownload(data.id, event)}
        showLabel={false}
        showIcon={true}
      />)
    }

    controls.name.disabled = !unlockTemplate;
    controls.sequence.disabled = !unlockTemplate;
    controls.type_key.disabled = !unlockTemplate;

    if (controls.type_key.value) {
      controls.document.validationRules['fileType'] = controls.type_key.value;
      let accept = null;
      switch (controls.type_key.value) {
        case 'pdf':
        case 'csv':
        case 'png':
        case 'msg':
        case 'txt':
        default:
          accept = `.${controls.type_key.value}`;
          break;
        case 'xls':
          accept = '.xls,.xlsx';
          break;
        case 'jpg':
          accept = '.jpg,.jpe,.jpeg';
          break;
        case 'json':
          accept = '.json,.geojson';
          break;
        case 'geotiff':
          accept = '.tif,.tiff';
          break;
      }
      controls.document.accept = accept;
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {/* { responseMessage && (
            <div className="text-center text-danger">{responseMessage}</div>
          )} */}
          <ResponseMessageTab responseMessage={requirementMessage || responseMessage} />
          <Alert color="primary">Document requirement item. Please note that Item Types can only be set when creating the item. Once a document have been uploaded, it is also flagged as validated by current user. Item names can not be duplicated within a given requirement.</Alert>
          <Form>
            <Row>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.name} />
              </Col>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.status_id} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.due} />
              </Col>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.type_key} />
              </Col>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.sequence} />
              </Col>
            </Row>

            {!isNew && (
              <FormInput handleChange={this.handleChange} control={controls.notes} />
            )}

            <div>
              <FormInputFile handleChange={this.handleChange} control={controls.document} />
              {data.external_reference && data.external_reference.originalname && (
                <div className="p-2 mt-2 rounded border bg-light">
                  {downloadLink}
                </div>
              )}
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          {!isNew && unlockTemplate && (<Button size="sm" color="danger" onClick={this.onRemove} >Delete</Button>)}
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Cancel</Button>
            <Button size="sm" color="success" onClick={this.onSave}>Save</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    stages: store.stages,
  }
}

export default connect(mapStoreToProps)(StageRequirementItemModal);
