import React from 'react';
import {
  Row,
  Col,
  Button,
  Collapse,
  Alert,
  Form
} from 'reactstrap';
import ProjectOverviewContactsLsv from './ProjectOverviewContactsLsv'
import FormInput from "../../../../core/form/components/FormInput";

const ProjectOverview = (props) => {
  const {
    controls,
    handleChange,
    sections,
    toggleSection,
    onSave,
    onCancel,
    responseMessage,
    isValid,
    company,
    companies,
    handleCompanyChange,
    projectOfficers,
    projectStatuses
  } = props;

  let contacts = <tr />
  if (company.id) {
    contacts = company.contacts.map(contact => {
      const fullname = `${contact.properties.firstname} ${contact.properties.lastname}`
      return {
        name: fullname,
        mobilephone: contact.properties.mobilephone,
        phone: contact.properties.phone,
        email: contact.properties.email,
        jobtitle: contact.properties.jobtitle
      }
    })
  }

  if (companies.length > 0) {
    let options = companies.map(company => {
      return { id: company.id, name: company.properties.name }
    });

    options.push({ id: '', name: 'Select a CRM Company' })
    controls.crm_id.options = options;
  }

  if (controls && controls['assignedto_id']) {
    const options = projectOfficers;

    const option = options.find(option => option.id === '');
    if (!option) {
      options.push({ id: '', name: '-- None Selected' });
    }

    controls['assignedto_id'].options = options;
  }

  if (controls && controls['project_status_id']) {
    const options = projectStatuses;

    const option = options.find(option => option.id === '');
    if (!option) {
      options.push({ id: '', name: '-- None Selected' });
    }

    controls['project_status_id'].options = options;
  }

  return (
    <React.Fragment>
      {responseMessage && (
        <Alert color="danger">{responseMessage}</Alert>
      )}
      <Form>
        <Row>
          <Col sm={10}>
            <Row>
              <Col sm={3}>
                <FormInput handleChange={handleChange} control={controls.name} />
              </Col>
              <Col sm={3}>
                <FormInput handleChange={handleCompanyChange} control={controls.crm_id} />
              </Col>
              <Col sm={3}>
                <FormInput handleChange={handleChange} control={controls.jira_key} />
              </Col>
              <Col sm={3}>
                <FormInput handleChange={handleChange} control={controls.created} />
              </Col>
            </Row>
          </Col>
          <Col>
            <div className="d-flex flex-column justify-content-end">
              <Button disabled={!isValid} className="mt-4" size="sm" color="success" onClick={() => onSave()}>Save Changes</Button>
              <Button className="mt-2" size="sm" color="light" onClick={() => onCancel()}>Cancel</Button>
            </div>
          </Col>
        </Row>

        <div className="mt-4 border rounded bg-light">
          <div className="d-flex justify-content-between form-section" onClick={() => toggleSection('contacts')}>
            <h5 className="m-0">Contact Details</h5>
            <small className="mt-1 mr-2"><span className="text-white" href="#">{sections.contacts.show ? 'Hide' : 'Show'}</span></small>
          </div>

          <Collapse isOpen={sections.contacts.show} className="p-2">
            <ProjectOverviewContactsLsv rows={contacts} />
          </Collapse>
        </div>

        <div className="mt-4 border rounded bg-light">
          <div className="d-flex justify-content-between form-section" onClick={() => toggleSection('internal')}>
            <h5 className="m-0">CLO Internal Information</h5>
            <small className="mt-1 mr-2"><span className="text-white" href="#">{sections.internal.show ? 'Hide' : 'Show'}</span></small>
          </div>
          <Collapse isOpen={sections.internal.show} className="p-2">
            <Row>
              <Col sm={3}>
                <FormInput handleChange={handleChange} control={controls.project_job_no} />
              </Col>
              <Col sm={3}>
                <FormInput handleChange={handleChange} control={controls.assignedto_id} />
              </Col>
              <Col sm={3}>
                <FormInput handleChange={handleChange} control={controls.project_area_description} />
              </Col>
              <Col sm={3}>
                <FormInput handleChange={handleChange} control={controls.project_status_id} />
              </Col>
            </Row>
          </Collapse>
        </div>

        <div className="mt-4 border rounded bg-light">
          <div className="d-flex justify-content-between form-section" onClick={() => toggleSection('registration')}>
            <h5 className="m-0">Project Registration</h5>
            <small className="mt-1 mr-2"><span className="text-white" href="#">{sections.registration.show ? 'Hide' : 'Show'}</span></small>
          </div>
          <Collapse isOpen={sections.registration.show} className="p-2">
            <Row>
              <Col sm={3}>
                <FormInput handleChange={handleChange} control={controls.erf_project_no} />
              </Col>
              <Col sm={3}>
                <FormInput handleChange={handleChange} control={controls.methodology_original} />
              </Col>
              <Col sm={2}>
                <FormInput handleChange={handleChange} control={controls.declaration_date} />
              </Col>
              <Col sm={2}>
                <FormInput handleChange={handleChange} control={controls.permanence_period} />
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <FormInput handleChange={handleChange} control={controls.participant} />
              </Col>
              <Col sm={4}>
                <FormInput handleChange={handleChange} control={controls.participant_id} />
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <FormInput handleChange={handleChange} control={controls.anreu_account} />
              </Col>
              <Col sm={4}>
                <FormInput handleChange={handleChange} control={controls.anreu_client_number} />
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <FormInput handleChange={handleChange} control={controls.nominee} />
              </Col>
            </Row>
          </Collapse>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default ProjectOverview;
