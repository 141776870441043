import { noop } from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';

const MetricsToolbar = ({ onAddMetric = noop }) => {
  return (
    <div className="d-flex justify-content-end m-2">
      <Button size="sm" color="success" onClick={onAddMetric}>
        Add Metric
      </Button>
    </div>
  );
};

export default MetricsToolbar;
