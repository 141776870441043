import React from 'react';
import { connect } from 'react-redux';
import { Card, Nav, TabContent, TabPane } from 'reactstrap';
import Mapster from '../../projects/containers/Mapster.jsx';

import Icon from 'jsx/components/core/icons/Icon';
import { updateControls, saveControls } from '../../../core/form/lib/validateForm';
import { controls } from '../forms/areaRoundStratas';
import FormBase from '../../../core/form/components/FormBase';
import SitesLsv from '../../sites/components/SitesLsv';
import FormTab from '../../../core/form/components/FormTab';
import AreaRoundStrataProfile from '../components/AreaRoundStrataProfile';

import { buildFeatureCollection, buildFeature } from '../../projects/lib/mapster.js';
import Downloads from '../../sites/lib/downloads';

import {
  fetchAreaRoundStrata,
  updateAreaRoundStrata,
  removeAreaRoundStrata,
  uploadSites,
} from '../actions';

import { fetchStrataSites, fetchSiteSamples } from '../../sites/actions';

import BreadcrumbsRoute from 'jsx/components/core/form/components/BreadcrumbsRoute.jsx';
class AreaRoundStrata extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      showMap: false,
      activeTab: '2',
      showMiniMap: false,
      controls: controls,
      backlink: '/home/projects',
      homeLink: null,
      isValid: true,
      mapSources: [],
      data: {},
      project_id: null,
      id: null,
      coreSelection: [],
      currentSite: {},
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleSiteUpload = this.handleSiteUpload.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.toggleMiniMap = this.toggleMiniMap.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.fetchSamples = this.fetchSamples.bind(this);
  }

  async componentDidMount() {
    const { id, property_id, project_id, area_id, round_id } = this.props.match.params;

    let { data, controls } = this.state;

    this.downloads = new Downloads();
    this.downloads.set(this.props.dispatch);

    const backlink = `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${round_id}`;

    this.resetGeometry();

    if (id !== 'new') {
      data = await this.props.dispatch(fetchAreaRoundStrata(id));
      this.props.dispatch(fetchStrataSites(id));
      controls = updateControls(controls, data);
    }

    this.setState({
      id,
      backlink,
      data,
      controls,
    });
  }

  componentDidUpdate() {
    this.setStrataMaps();
    this.setSiteMaps();
  }

  resetGeometry() {
    const mapSources = [
      {
        id: 'strata',
        source: { type: 'Feature', features: [] },
        load: true,
        title: 'Strata',
        count: 0,
        style: 'Polygon',
      },
      {
        id: 'sites',
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Sites',
        count: 0,
        style: 'Point',
      },
    ];
    this.setState({ mapSources });
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  async onSave() {
    let { data } = this.state;
    const { controls, backlink } = this.state;

    data = saveControls(controls, data);

    const success = await this.props.dispatch(updateAreaRoundStrata(data));
    if (success) this.props.history.push(backlink);
  }

  onCancel() {
    this.props.history.push(this.state.backlink);
  }

  async onRemove() {
    const { id, data } = this.state;

    // const confirmed = window.confirm(`Removing the ${data.name.toUpperCase()} strata permanently with all its associated datas. Are you sure?`);
    const confirm_text = `DELETE STRATA ${data.name.toUpperCase()}`;
    let confirm_display = `Removing strata ${data.name.toUpperCase()} PERMANENTLY including all its associated sites, scans and sample results.`;
    confirm_display += `This action can not be reversed. If you are sure, please confirm this action by typing '${confirm_text}' in the input field below and press OK`;

    const confirmation = prompt(confirm_display);
    if (confirmation === confirm_text) {
      await this.props.dispatch(removeAreaRoundStrata(id));
      this.props.history.push(this.state.backlink);
    } else {
      alert('Invalid confirmation, delete aborted');
    }
  }

  setStrataMaps() {
    const currentStrata = this.props.areas.currentStrata;
    const mapSources = this.state.mapSources;

    if (currentStrata.geom) {
      const idx = mapSources.findIndex((source) => source.id === 'strata');
      const feature = buildFeature(currentStrata.geom, { title: mapSources[idx].title });

      if (mapSources[idx].load) {
        mapSources[idx].load = false;
        mapSources[idx].source = feature;
        mapSources[idx].count = 1;
        this.setState({ mapSources });
      }
    }
  }

  async handleSiteUpload(event) {
    if (event.target.files.length === 0) return;

    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('geom', file);
    formData.append('round_id', this.state.data.id);

    await this.props.dispatch(uploadSites(formData));
    this.props.dispatch(fetchStrataSites(this.state.data.id));
    this.setSiteMaps();
  }

  setSiteMaps() {
    const sites = this.props.areas.currentStrata.sites;
    const mapSources = this.state.mapSources;

    if (sites && sites.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'sites');
      const featureCollection = buildFeatureCollection();

      sites.forEach((site) => {
        if (site.geom) {
          const feature = buildFeature(site.geom, { title: `SITE ${site.name}`, color: 'blue' });
          featureCollection.features.push(feature);
        }
      });

      if (mapSources[idx].load && featureCollection.features.length > 0) {
        mapSources[idx].load = false;
        mapSources[idx].source = featureCollection;
        mapSources[idx].count = featureCollection.features.length;
        this.setState({ mapSources });
      }
    }
  }

  toggleMap() {
    this.setState({
      showMap: !this.state.showMap,
    });
  }

  toggleMiniMap() {
    this.setState({
      showMiniMap: !this.state.showMiniMap,
    });
  }

  fetchSamples(site) {
    this.props.dispatch(fetchSiteSamples({ site_id: site.id }));
    this.setState({ currentSite: site });
  }

  render() {
    const { coreSelection, mapSources, homeLink, currentSite, controls } = this.state;
    const { currentStrata } = this.props.areas;
    const { sites, samples } = this.props.sites;

    const iconName = 'border-none';
    let strata = {};

    if (currentStrata) {
      strata = currentStrata;
    }

    const lngLat = [145, -16.5];

    return (
      <div>
        {!this.state.showMap && (
          <div className="p-3">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-row">
                <Icon size="2x" name={iconName} className="appForeTint mr-2" />
                <h3>Strata: {strata.name}</h3>
              </div>
            </div>

            <div className="d-flex justify-content-between bg-light rounded p-2 border">
              <div>Manage strata and sites</div>
              <div>
                <Icon name="list" style={{ color: '#bbbbbb' }} className="mr-3" />
                <Icon
                  name="location-pin"
                  className="text-primary mr-3"
                  style={{ cursor: 'pointer' }}
                  onClick={this.toggleMiniMap}
                />
              </div>
            </div>

            <BreadcrumbsRoute match={this.props.match} />

            {this.state.showMiniMap && (
              <Card className="border rounded mt-2" style={{ minHeight: '300px' }}>
                <Mapster
                  lngLatCenter={lngLat}
                  lngLatMarker={lngLat}
                  source={currentStrata.geom}
                  mapSources={mapSources}
                />
              </Card>
            )}

            <Nav tabs className="mt-2">
              <FormTab
                caption="Profile"
                tabId="1"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Sites"
                tabId="2"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
              />
            </Nav>

            <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
              <TabPane tabId="1" className="p-2">
                <AreaRoundStrataProfile
                  handleChange={this.handleChange}
                  controls={controls}
                  onSave={this.onSave}
                  onCancel={this.onCancel}
                  onRemove={this.onRemove}
                  checkAccess={this.checkAccess}
                />
              </TabPane>
              <TabPane tabId="2">
                <SitesLsv
                  homeLink={homeLink}
                  match={this.props.match}
                  history={this.props.history}
                  rows={sites || []}
                  coreSelection={coreSelection || []}
                  disableCheckboxes={true}
                  handleSelectAll={() => {}}
                  handleDownload={(id, event) => this.downloads.handleSiteDownload(id, event)}
                  fetchSamples={this.fetchSamples}
                  samples={samples}
                  currentSite={currentSite}
                />
              </TabPane>
            </TabContent>
          </div>
        )}
        {this.state.showMap && (
          <Mapster
            lngLatCenter={lngLat}
            lngLatMarker={lngLat}
            source={currentStrata.geom}
            mapSources={mapSources}
          />
        )}
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    areas: store.areas,
    sites: store.sites,
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(AreaRoundStrata);
