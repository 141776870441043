import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  collected_at: {
    ...controlDefaults,
    placeholder: 'Collected At',
    name: 'collected_at',
    type: 'datetime-local',
    caption: 'Collected Date/Time',
    persist: true,
    validationRules: {
      isRequired: true
    },
  },
  target_depth: {
    ...controlDefaults,
    name: 'target_depth',
    type: 'number',
    caption: 'Target Depth (mm)',
    persist: true,
    validationRules: {},
  },
  stickup_height: {
    ...controlDefaults,
    name: 'stickup_height',
    type: 'number',
    caption: 'Stickup Height (mm)',
    persist: false,
    validationRules: {},
  },
  notes: {
    ...controlDefaults,
    placeholder: 'Field Notes',
    name: 'notes',
    type: 'textarea',
    caption: 'Notes',
    persist: false,
    validationRules: {
      isRequired: false
    }
  }
}