import React from 'react';
import { connect } from "react-redux";
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from 'mapbox-gl-geocoder';
import { accessToken } from '../../../../constants/map.js';
import Icon from 'jsx/components/core/icons/Icon';
import { getCollectionBoundary, zoomToBounds, getDefaultLayer, getBoundary } from "../lib/mapster.js";

import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { removeSource } from '../lib/propertySearchUtils.js';

mapboxgl.accessToken = accessToken

class Mapster extends React.Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();

    this.state = {
      lng: 150.7333,
      lat: -23.1333,
      zoom: 8,
      map: null,
      mapSources: null,
      mapIsLoaded: false,
      mapStyle: 'mapbox://styles/mapbox/outdoors-v11',
      showLegend: true,
      responseMessage: null,
      geocoderAddress: ''
    };

    this.zoomToBounds = this.zoomToBounds.bind(this);
    this.toggleLegend = this.toggleLegend.bind(this);
    this.setStyle = this.setStyle.bind(this);
  }

  componentDidMount() {
    const latLngCenter = this.props.lngLatCenter;

    let {
      mapStyle,
      marker
    } = this.state;

    const map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: mapStyle,
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,

      // Terrain modelling properties
      // style: 'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y',
      // pitch: 85,
      // bearing: 80,
    });

    const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metrix'
    });
    map.addControl(scale, 'bottom-right');

    const nav = new mapboxgl.NavigationControl({
      showCompass: true
    });
    map.addControl(nav, 'top-right');

    // Set Geocoder
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl
    })
    geocoder.onAdd(map)

    // Geocode onResult
    geocoder.on('result', async (e) => {
      if (e.result.center) {
        if (!marker) {
          marker = new mapboxgl.Marker()
            .setLngLat(e.result.center)
            .addTo(map);
        } else {
          marker.setLngLat(e.result.center);
        }

        this.setState({ marker });
        map.flyTo({ center: e.result.center, zoom: 15 });
      }
    });

    map.on("style.load", () => {
      if (this.state.map !== null) {
        this.loadSources(this.state.map);
        this.setState({ mapIsLoaded: true, map });
      }
    });

    map.on("load", () => {
      if (this.props.onMapLoad) {
        this.props.onMapLoad(map);
      }
    });

    this.setState({
      lng: latLngCenter[0],
      lat: latLngCenter[1],
      map,
      geocoder
    })
  }

  loadSources(map) {
    const mapSources = this.props.mapSources;
    mapSources.forEach(mapSource => {

      removeSource(map, mapSource)
      map.addSource(mapSource.id, {
        type: 'geojson',
        data: { 'type': 'Feature' }
      });

      mapSource.layers = []
      mapSource.markers = []

      const layer = getDefaultLayer(mapSource.id, mapSource.style)
      map.addLayer(layer);

      mapSource.layers.push(layer)

      if (mapSource.showLabels) {
        const labelsLayer = getDefaultLayer(mapSource.id, 'PolyLabel', 'title')
        map.addLayer(labelsLayer);
        mapSource.layers.push(labelsLayer)
      }

      if (mapSource.showMarker) {
        mapSource.source.features.forEach(feature => {
          const popup = new mapboxgl.Popup({offset: 25, closeButton: false});
          if (feature.properties.popupText) {
            popup.setText(feature.properties.popupText);
          }

          const marker = new mapboxgl.Marker({
            color: feature.properties.colour
            }).setLngLat(feature.geometry.coordinates)
            .setPopup(popup)
            .addTo(map)

          mapSource.markers.push(marker)
        })
      }

      map.getSource(mapSource.id).setData(mapSource.source);

      if (mapSource.id === 'sites') {
        // Set the click
        map.on('click', `${mapSource.id}-circle`, function (e) {
          const id = e.features[0].properties.id;
          if (e.features[0].properties.scan_samples_exists) {
            mapSource.onClick(id);
          }
        })

        const sitesPopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });

        map.on('mouseenter', `${mapSource.id}-circle`, function (e) {
          // Change the cursor style as a UI indicator.
          map.getCanvas().style.cursor = 'pointer';

          var coordinates = e.features[0].geometry.coordinates.slice();
          let description = `Core ID: ${e.features[0].properties.core_id}`;

          if (e.features[0].properties.scan_samples_exists) {
            description = `${description} - Scan completed for this\ncore, click to analyse`
          }

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          sitesPopup.setLngLat(coordinates).setHTML(description).addTo(map);
        });

        map.on('mouseleave', `${mapSource.id}-circle`, function () {
          map.getCanvas().style.cursor = '';
          sitesPopup.remove();
        });
      }
    })

    // Terrain modelling source and layer
    // map.addSource('mapbox-dem', {
    //   'type': 'raster-dem',
    //   'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
    //   'tileSize': 512,
    //   'maxzoom': 14
    //   });

    // // add the DEM source as a terrain layer with exaggerated height
    // map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 3.5 });

    // map.addLayer({
    //   'id': 'sky',
    //   'type': 'sky',
    //   'paint': {
    //   'sky-type': 'atmosphere',
    //   'sky-atmosphere-sun': [0.0, 0.0],
    //   'sky-atmosphere-sun-intensity': 15
    //   }
    // });
  }

  updateSources(map) {
    const {
      mapSources
    } = this.props;

    const {
      marker
    } = this.state;

    let bounds = []

    this.loadSources(map)

    mapSources.forEach(mapSource => {
      if (map.getSource(mapSource.id)) {
        // map.getSource(mapSource.id).setData(mapSource.source);
        if (mapSource.source.type === 'Feature') {
          bounds = bounds.concat(getBoundary(mapSource.source));
        }
        if (mapSource.source.type === 'FeatureCollection') {
          bounds = bounds.concat(getCollectionBoundary(mapSource.source));
        }
      }
    })

    if (bounds.length > 0) {
      try {

        // include marker in bounds if it exist
        if (marker) {
          bounds = bounds.concat(this.state.marker.getLngLat())
        }

        zoomToBounds(map, bounds);
      } catch (error) {
        if (!this.state.responseMessage) {
          this.setState({ responseMessage: "Error: Cannot zoom to features. Invalid geometry" })
        }
      }
    }
  }

  zoomToBounds() {
    const {
      map
    } = this.state;

    let bounds = []
    const mapSources = this.props.mapSources;

    mapSources.forEach(mapSource => {
      if (map.getSource(mapSource.id)) {
        if (mapSource.source.type === 'Feature') {
          bounds = bounds.concat(getBoundary(mapSource.source));
        }
        if (mapSource.source.type === 'FeatureCollection') {
          bounds = bounds.concat(getCollectionBoundary(mapSource.source));
        }
      }
    })
    if (bounds.length > 0) {
      try {
        zoomToBounds(map, bounds);
      } catch (error) {
        if (!this.state.responseMessage) {
          this.setState({ responseMessage: "Error: Cannot zoom to features. Invalid geometry" })
        }
      }
    }
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  componentDidUpdate() {
    const lng = this.props.lngLatCenter[0];
    const lat = this.props.lngLatCenter[1];
    const sourceFilter = this.props.sourceFilter;

    let {
      marker,
      map,
      mapIsLoaded,
      geocoderAddress,
      geocoder
    } = this.state;

    const {
      project
    } = this.props;

    const {
      zoomCoords
    } = this.props.mapster;

    if (this.props.geocoderAddress && this.props.geocoderAddress !== geocoderAddress) {
      geocoder.query(this.props.geocoderAddress)

      this.setState({
        geocoderAddress: this.props.geocoderAddress,
      })
    }

    if (mapIsLoaded) {
      this.updateSources(map)
    }

    if (zoomCoords.length > 0) {
      zoomToBounds(map, zoomCoords);
    }
    
    if (sourceFilter !== project) {
      this.setState({
        project: sourceFilter
      })
    }

    if (lng !== this.state.lng && lat !== this.state.lat) {
      this.setState({
        lng,
        lat,
        marker
      })
    }
  }

  setStyle(style) {
    const map = this.state.map;
    const {
      mapStyle
    } = this.state;

    if (mapStyle !== style) {
      map.setStyle(style)

      this.setState({
        mapStyle: style
      })
    }
  }

  toggleLegend() {
    this.setState({
      showLegend: !this.state.showLegend
    })
  }

  render() {
    const {
      mapStyle,
      responseMessage,
      showLegend
    } = this.state;

    const {
      mapSources
    } = this.props;

    const mapStyles = this.props.mapster.styles;
    const expandIcon = (this.props.expandMap ? 'arrows-minimize' : 'arrows-maximize');

    const keys = Object.keys(mapStyles);
    const styles = keys.map((key, index) => {
      const name = key[0].toUpperCase() + key.slice(1)
      let check;
      if (mapStyles[key] === mapStyle) {
        check = (<Icon name="check" className="text-success mt-1" />)
      }
      return (
        <DropdownItem
          onClick={() => this.setStyle(mapStyles[key])}
          className="d-flex justify-content-between"
          key={index}
        ><span>{name}</span>{check
          }</DropdownItem>
      )
    })

    const layers = mapSources.map((source, index) => {
      const name = source.title;
      let check;
      if (source.visible) {
        check = (<Icon name="check" className="text-success mt-1" />)
      }
      return (
        <DropdownItem
          onClick={() => this.props.handleSourceVisibility(index)}
          className="d-flex justify-content-between"
          key={index}
        ><span>{name}</span>{check
          }</DropdownItem>
      )
    })

    let legends = mapSources.map((source, index) => {
      if (source.legends && source.visible) return (
        <div key={index}>
          <h3>{source.title}</h3>
          {source.legends}
          <DropdownItem divider />
        </div>
      )
      else return false;
    }).filter(Boolean);

    return (
      <div>
        <div className='mapsterBlock rounded d-flex justify-content-between'>
          <div className="d-flex">
            {/* {mapMessages} */}
            {responseMessage && (
              <div className="d-flex mt-3">
                <span>{`${responseMessage}`}</span>
              </div>
            )}
          </div>
          <div className='text-black d-flex justify-content-end'>
            {this.props.expandMap && (<Icon style={{ cursor: 'pointer' }} name="key" onClick={this.toggleLegend} className="mr-2 mt-1" />)}

            <UncontrolledDropdown className="p-0 m-0">
              <DropdownToggle
                className="p-0 m-0"
                tag="div"
                data-toggle="dropdown"
                aria-expanded={true}
              >
                <Icon style={{ cursor: 'pointer' }} name="layer-group" className="ml-2 mr-2" />
              </DropdownToggle>
              <DropdownMenu right>
                {styles}
                <DropdownItem divider />
                {layers}
              </DropdownMenu>
            </UncontrolledDropdown>
            <Icon style={{ cursor: 'pointer' }} name="expand" onClick={this.zoomToBounds} className="mt-1 ml-2 mr-2" />
            <Icon style={{ cursor: 'pointer' }} name={expandIcon} onClick={this.props.toggleMap} className="mt-1 ml-2 mr-2" />
          </div>
        </div>

        <div ref={this.mapRef} className="mapster compass-only" />

        {this.props.expandMap && showLegend && (<div className="p-2 mapsterLegend">
          {legends}
        </div>)}
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    mapster: store.mapster
  }
}

export default connect(mapStoreToProps)(Mapster);
