import React, { Fragment } from 'react';
import PropertySearchAttribute from './PropertySearchAttribute';

const PropertySearchAttributes = (props) => {
  const {
    attributes
  } = props;

  let renderedAttributes = (<div className="text-center text-corporate p-2">No attributes found.</div>)
  if (attributes.length > 0) {
    renderedAttributes = attributes.map((attribute, index) => {
      return (<PropertySearchAttribute key={index} attribute={attribute} />);
    })
  }

  return (
    <Fragment>
      <div className="m-2 text-center">
        <div className="mt-2 text-left">
          {renderedAttributes}
        </div>
      </div>

    </Fragment>

  );
}

export default PropertySearchAttributes;
