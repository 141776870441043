import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  category: {
    ...controlDefaults,
    placeholder: '',
    name: 'category',
    fieldName: 'category',
    type: 'select',
    validationRules: {
      minLength: 2,
      isRequired: true
    },
    options: [
      {id: 1, name: 'Show ALL'},
      {id: 1, name: 'Biodiversity'},
      {id: 2, name: 'Soil Health'},
      {id: 3, name: 'Waterways'},
      {id: 4, name: 'Water Cycle health'},
      {id: 5, name: 'Climate'}
    ],
    showInListview: true
  },
  location: {
    ...controlDefaults,
    placeholder: '',
    name: 'location',
    fieldName: 'location',
    type: 'select',
    validationRules: {
      minLength: 2,
      isRequired: true
    },
    options: [
      {id: 1, name: 'Show ALL'},
      {id: 1, name: 'West Site'},
      {id: 2, name: 'East Site'},
      {id: 3, name: 'NorthEast Site'},
      {id: 4, name: 'Property'},
      {id: 5, name: 'South Site'}
    ],
    showInListview: true
  },
  metric: {
    ...controlDefaults,
    placeholder: '',
    name: 'metric',
    fieldName: 'metric',
    type: 'select',
    validationRules: {
      minLength: 2,
      isRequired: true
    },
    options: [
      {id: 1, name: 'Tree Cover'},
      {id: 1, name: 'Connectivity'},
      {id: 2, name: '# Large Trees'}
    ],
    showInListview: true
  }
}
