import { evaluate } from 'mathjs';

export const evalFormula = (controls, formula, data = null) => {
  let keys = formula
    .split(/##(.*?)##/)
    .filter((key) => Object.keys(controls).includes(key) || key?.includes('.'));
  if (keys.length > 0) {
    keys.forEach((key) => {
      let value = 0;
      if (key?.includes('.')) {
        let parent = data;
        for (let sub_value of key.split('.').values()) {
          parent = sub_value && parent ? parent[sub_value] : null;
        }
        value = parent;
      } else if (controls[key]?.unitType && data && data[key].value) value = data[key].value;
      else if (data && data[key]) value = data[key];
      else if (controls[key]?.value) value = controls[key].value;

      value = parseFloat(value);
      if (isNaN(value)) value = 0;
      formula = formula.replace(`##${key}##`, value);
    });
  }
  const result = evaluate(formula);
  if (isNaN(result) || result === Infinity) return 0;
  else return result.toFixed(2);
};
