import React from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Nav,
  TabContent,
  TabPane,
} from 'reactstrap';
import { cloneDeep } from 'lodash';
import FormBase from '../../../core/form/components/FormBase';
import FormInput from '../../../core/form/components/FormInput';
import FormTab from '../../../core/form/components/FormTab';
import Icon from 'jsx/components/core/icons/Icon';

import GenericDistributionsLsv from '../components/GenericDistributionsLsv';
import { controls as liabilityControls } from '../forms/liabilities';

import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { fetchAttributes } from '../actions/attributes';
import { fetchEnterpriseDistributions } from '../actions/enterprises';

import {
  createLiability,
  fetchLiability,
  removeLiability,
  updateLiability,
} from '../actions/liabilities';
import LiabilityRatesLsv from '../components/LiabilityRatesLsv';

class LiabilityModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'profile',
      controls: cloneDeep(liabilityControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Liability',
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { properties } = this.props.properties;
      let controls = initControls(cloneDeep(liabilityControls));
      controls.distributions.value = [];
      controls.rates.value = [];
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Liability',
      };

      this.props.dispatch(fetchAttributes({ type: 'liability_categories' }));
      this.props.dispatch(fetchAttributes({ type: 'divisions' }));
      this.props.dispatch(fetchEnterpriseDistributions({ type: 'division' }));

      if (properties?.rows.length > 0) {
        controls = updateControlOptions(controls, 'property_id', properties.rows);
      }

      if (this.props.id) {
        const { id } = this.props;
        updatedState = {
          ...updatedState,
          id,
          isNew: false,
          title: 'Edit Liability',
        };

        this.props.dispatch(fetchLiability(id)).then((data) => {
          this.setState({
            data,
            controls: updateControls(controls, data),
          });
        });
      }

      this.setState(updatedState);
    }
  }

  toggleTab(activeTab) {
    this.setState({ activeTab });
  }

  async onSave(close = true) {
    let { data, controls, isNew } = this.state;

    let unsavedData = saveControls(controls, data);
    unsavedData.distributions = controls.distributions.value;
    unsavedData.rates = controls.rates.value;

    const { isValid, updatedControls } = await validateFormFieldControls(unsavedData, controls);

    if (isValid) {
      let success;
      if (isNew) {
        delete unsavedData.id;
        success = await this.props.dispatch(createLiability(unsavedData));
        if (success?.id) this.onClose(true, success.id);
      } else {
        success = await this.props.dispatch(updateLiability(unsavedData));
        if (success) {
          if (close) this.onClose(true);
          else {
            this.setState({
              data: {
                ...data,
                ...unsavedData,
              },
            });
          }
        }
      }
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing ${data.name} liability permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeLiability(data.id));
      if (success) this.onClose(true);
    }
  }

  onClose(refresh = false, liabilityId = null) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);

    if (liabilityId) {
      this.props.setModal(true, liabilityId);
    }
  }

  onChange(event) {
    const { controls } = this.state;
    const { liability_categories } = this.props.attributes;

    const old_category_id = controls.category_id.value;

    this.handleChange(event);

    const { name, value } = event.target;

    switch (name) {
      /** If the category selected is Current Creditors, set the liability
       * name to Current Creditors (RCS-277).  Also set the liability name to read
       * only.
       * **/
      case 'category_id':
        if (old_category_id !== value) {
          let index = liability_categories.findIndex((cat) => cat.id === value);
          if (index > -1) {
            if (liability_categories[index].tag === 'current_creditors') {
              controls.name.value = 'Current Creditors';
              controls.name.disabled = true;
            } else {
              controls.name.value =
                controls.name.value === 'Current Creditors' ? '' : controls.name.value;
              controls.name.disabled = false;
            }
          }
        }
        break;
      default:
        break;
    }
  }

  setOptions(controls) {
    const { isNew } = this.state;
    const { liability_categories } = this.props.attributes;
    const { distributions } = this.props.enterprises;

    controls.category_id.disabled = !isNew;
    controls = updateControlOptions(controls, 'category_id', liability_categories);

    // Add default distribution values if no values are populated
    if (controls.distributions.value === null || controls.distributions.value.length === 0)
      controls.distributions.value = distributions;

    return controls;
  }

  render() {
    let { title, isNew, controls, id } = this.state;
    const { isOpen } = this.props;
    const { divisions, liability_categories } = this.props.attributes;
    const { responseMessage, transactions } = this.props.liabilities;

    controls = this.setOptions(controls);

    const iconName = 'shapes';

    // Determine if distributions are to be displayed
    const category_id = controls.category_id.value;
    const category = liability_categories.find((category) => category.id === category_id);
    const showDistributions = category_id && category?.rules?.allowDistributions === true;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}

          <Nav tabs className="mt-2">
            <FormTab
              caption="Profile"
              tabId="profile"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
            />
            <FormTab
              caption="Rates"
              tabId="rates"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
              disabled={id === null}
            />
          </Nav>
          <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
            <TabPane tabId="profile" className="mb-2 p-1">
              <Form>
                <FormInput handleChange={this.onChange} control={controls.property_id} />
                <small className="text-success">Leave blank for organisational transactions.</small>
                <FormInput handleChange={this.onChange} control={controls.category_id} />
                <FormInput handleChange={this.handleChange} control={controls.name} />
              </Form>
              {showDistributions && (
                <GenericDistributionsLsv
                  className={'mt-2'}
                  rows={controls.distributions.value || []}
                  divisions={divisions ?? []}
                />
              )}
            </TabPane>
            <TabPane tabId="rates" className="mb-2 p-1">
              <LiabilityRatesLsv className={'mt-2'} rows={controls.rates.value || []} />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              {`Save${isNew ? '/Continue' : ''}`}
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button
              size="sm"
              color="danger"
              onClick={this.onRemove}
              disabled={transactions.rows?.length > 0}
            >
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, enterprises, liabilities, properties }) => {
  return {
    attributes,
    enterprises,
    liabilities,
    properties,
  };
};

export default connect(mapStoreToProps)(LiabilityModal);
