import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    userProjects: [],
    params: {
      search_value: '',
    },
  },
  action,
) {
  const types = ['USER_PROJECT'];

  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_USER_PROJECTS_FULFILLED': {
      return {
        ...state,
        userProjects: action.payload,
      };
    }
    case 'SET_USER_PROJECTS_PARAMS': {
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      };
    }
    default:
      break;
  }

  return state;
}
