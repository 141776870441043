import React from 'react';
import Select from 'react-select';
import { Button, Col, Row, Label } from 'reactstrap';
import { noop } from 'lodash';

const AssetWorkActivitiesToolbar = ({
  setNewModal = noop,
  onStatusChange = noop,
  onActivitiesFilterClear = noop,
  status = null,
  onPhasecodeChange = noop,
  onProjectChange = noop,
  phasecode = null,
  phasecodes = [],
  project = null,
  job_projects = [],
  job_statuses = [],
}) => {
  const status_options = job_statuses.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const project_options = job_projects.map(({ id, name, project_job_no }) => ({
    value: id,
    label: `${name} (${project_job_no})`,
  }));

  const phasecode_options = phasecodes.map(({ id: value, name: label }) => ({ value, label }));

  return (
    <div className="d-flex justify-content-between bg-light p-2 rounded">
      <div style={{ width: '1200px' }}>
        <Row>
          <Col sm={3}>
            <Label>Filter By Project</Label>
            <Select
              options={project_options}
              placeholder="Select To Filter"
              onChange={onProjectChange}
              value={project}
              isClearable
            />
          </Col>
          <Col sm={3}>
            <Label>Filter By Status</Label>
            <Select
              options={status_options}
              placeholder="Select to Filter"
              onChange={onStatusChange}
              value={status}
              isClearable
            />
          </Col>
          <Col sm={2}>
            <Label>Filter By Phasecode</Label>
            <Select
              placeholder="Select To Filter"
              options={phasecode_options}
              onChange={onPhasecodeChange}
              value={phasecode}
              isClearable
            />
          </Col>
          <Col sm={2} className="align-self-end">
            <Button
              color="primary"
              size="sm"
              onClick={onActivitiesFilterClear}
              style={{ marginBottom: '4px' }}
            >
              Clear Filters
            </Button>
          </Col>
        </Row>
      </div>
      <Row className="m-0 p-0 align-items-end">
        <Col sm={12} className="justify-contend-end align-contend-end">
          <Button
            color="success"
            onClick={() => setNewModal(true, 'workactivities')}
            size="sm"
            style={{ marginBottom: '4px' }}
          >
            Add Activity
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AssetWorkActivitiesToolbar;
