import React from 'react';
import { noop } from 'lodash';
import { Button } from 'reactstrap';

const PropertiesToolbar = (props) => {
  const { onAddNew = noop } = props;

  return (
    <div className="d-flex justify-content-end">
      <Button onClick={() => onAddNew(true, true)} size="sm" color="success">
        Add New Property
      </Button>
    </div>
  );
};

export default PropertiesToolbar;
