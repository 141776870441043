export default function reducer( state={
    fetching: false,
    fetched: false,
    error: null,
    requirements: [
      {
        description: 'Generate Baseline Emission docs.',
        created: '2020-03-01 12:21',
        status: 'Completed',
        deadline: null,
        id: 1,
        comments: [
          {created: '2020-07-01 12:34', comment: 'This thing must be completed first', createdby: 'Robert'},
          {created: '2020-07-01 12:34', comment: 'This thing must be completed first', createdby: 'Barry'}
        ],
        jobs: [
          {created: '2020-07-01 12:34', description: 'Carry out site inspection', assignedTo: 'Corey', due: '2020-07-01 12:34', status: 'Scheduled'},
        ],
        documents: [
          {description: 'Customer Registration Form', created: '2020-07-01 12:34', status: 'Completed', uploaded: false, validation: "Validated by Corey 2020-05-01", type: 'pdf'},
          {description: 'Lot/Plan', created: '', status: 'Not Uploaded', uploaded: false, validation: '-', type: 'pdf'}
        ],
        tempDocs: '3/3'
      },
      {
        description: 'Generate Sampling Plan.',
        created: '2020-03-01 12:21',
        status: 'InProgress',
        deadline: null,
        id: 1,
        comments: [
          {created: '2020-07-01 12:34', comment: 'This thing must be completed first', createdby: 'Robert'},
          {created: '2020-07-01 12:34', comment: 'This thing must be completed first', createdby: 'Barry'},
          {created: '2020-07-01 12:34', comment: 'This thing must be completed first', createdby: 'Robert'},
          {created: '2020-07-01 12:34', comment: 'This thing must be completed first', createdby: 'Barry'}
        ],
        jobs: [
          {created: '2020-07-01 12:34', description: 'Carry out site inspection', assignedTo: 'Corey', due: '2020-07-01 12:34', status: 'Scheduled'},
        ],
        documents: [
          {description: 'Customer Registration Form', created: '2020-07-01 12:34', status: 'Completed', uploaded: false, validation: "Validated by Corey 2020-05-01", type: 'pdf'},
          {description: 'Lot/Plan', created: '', status: 'Not Uploaded', uploaded: false, validation: '-', type: 'pdf'}
        ],
        tempDocs: '3/3'
      }
    ]
    }, action) {

    switch (action.type) {
  
      default: break;
    }
  
    return state;
  }
  