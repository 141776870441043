import React from 'react';
import Listview from "../../../core/form/components/Listview";

const Placeholder = (props) => {
  const {
    rows,
    title,
    description
  } = props;

  const iconName = 'puzzle-piece';
  const emptyCaption = `Placeholder Component for ${title}`;

  const headers = []

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  )

  let tableBodyTr = (<tr></tr>);
  let rowsCount;

  return (
    <div className="b">
      <Listview rows={rows} rowsCount={rowsCount} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
      <div className="text-center text-secondary">{description}</div>
    </div>
  );
}

export default Placeholder;
