import FormSearch from 'jsx/components/core/form/components/FormSearch';
import FilterIntervalDatePicker from 'jsx/components/modules/portrait/components/FilterIntervalDatePicker';
import { noop } from 'lodash';
import React from 'react';
import { Col, Label, Row } from 'reactstrap';

const ReportPreviewToolbar = (props) => {
  const {
    controls,
    onChange = noop,
    onClientSelect = noop,
    isClientSelected,
    intervalOptions,
    selectedInterval,
    onIntervalDateChange,
    onIntervalChange,
  } = props;

  return (
    <div className="bg-light rounded p-1 m-1 border border-lightgray">
      <Row className="mt-2 mb-2">
        <Col sm={8} className="d-flex justify-content-start">
          <Label for={controls.client_id.name} className="mr-2 mb-2 mt-2">
            Client:
          </Label>
          <FormSearch
            handleChange={onChange}
            handleSelect={onClientSelect}
            control={controls.client_id}
            selected={isClientSelected}
            minimumLength={1}
            width={'100%'}
          />
        </Col>
      </Row>
      <Row></Row>
      <Row className="mt-2 mb-2">
        <Col sm={12} className="mb-2 d-flex justify-content-start">
          <FilterIntervalDatePicker
            intervalOptions={intervalOptions}
            interval={selectedInterval.interval}
            transaction_date={selectedInterval.transaction_date}
            onIntervalDateChange={onIntervalDateChange}
            onIntervalChange={onIntervalChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ReportPreviewToolbar;
