import { get, put, post, remove } from '../../../../lib/genericAction';

import { apiOffFarmIncomes } from '../../../../constants/api';

export function fetchOffFarmIncomes(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_OFF_FARM_INCOMES`, `${apiOffFarmIncomes}`, params);
}

export function fetchOffFarmIncome(id) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_OFF_FARM_INCOME`, `${apiOffFarmIncomes}/${id}`);
}

export function createOffFarmIncome(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_OFF_FARM_INCOME`, `${apiOffFarmIncomes}`, data);
}

export function updateOffFarmIncome(data) {
  return async (dispatch) =>
    await put(dispatch, `UPDATE_OFF_FARM_INCOME`, `${apiOffFarmIncomes}/${data.id}`, data);
}

export function removeOffFarmIncome(id) {
  return async (dispatch) =>
    await remove(dispatch, `REMOVE_OFF_FARM_INCOME`, `${apiOffFarmIncomes}/${id}`);
}
