// https://elementree.atlassian.net/browse/ES-375
/* eslint-disable no-restricted-syntax, guard-for-in, no-param-reassign, id-length, no-alert */
import Icon from 'jsx/components/core/icons/Icon';
import { cloneDeep, noop } from 'lodash';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { isFieldShown } from '../lib/showRules';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../lib/validateForm';
import FormBase from './FormBase';
import FormInput from './FormInput';
import FormInputSelect from './FormInputSelect';
import FormInputSwitch from './FormInputSwitch';
import FormIntervalDatePicker from './FormIntervalDatePicker';

export class GenericModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      data: {},
      id: null,
      isNew: false,
      title: '',
      setModal: null,
      controls: cloneDeep(this.props.controls),
      description: null,
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.renderInputs = this.renderInputs.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen } = this.state;
    let { id, isNew, controls, title, data, description } = this.state;

    const { modalTitle } = this.props;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = `New ${modalTitle}`;
      ({ description } = this.props);
      data = {};
      controls = await initControls(cloneDeep(this.props.controls));
      if (this.props.fillControlDefaults) {
        controls = await this.props.fillControlDefaults(controls);
      }

      id = null;

      if (this.props.data?.id) {
        isNew = false;
        ({ id } = this.props);
        title = `Edit ${modalTitle}`;

        ({ data } = this.props);
        controls = updateControls(controls, data);
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls,
        description,
      });
    }
  }

  async onSave({ middlewareCheck = null, closeModal = true } = {}) {
    const { data, controls, isNew } = this.state;
    const changes = await saveControls(controls, data);
    const { isValid, updatedControls } = await validateFormFieldControls(changes, controls);

    if (isValid) {
      let middlewareCheckSuccessful = true;
      if (middlewareCheck) middlewareCheckSuccessful = await middlewareCheck();

      if (!middlewareCheckSuccessful) return;

      const success = await this.props.onSave({ ...data, ...changes }, isNew, changes);
      if (success && closeModal) {
        this.onClose(true);
        return;
      }

      // Populates list view in the background
      this.props.onRefresh();

      // Indicates the user has saved the form and wants to stay within the modal to use the form again.
      const refreshedControls = cloneDeep(updatedControls);
      // Iterate through each control and see if reset is enabled. If so, reset the control value.
      Object.entries(refreshedControls).forEach(([controlKey, controlValues]) => {
        if (Object.keys(controlValues).includes('resetValue'))
          refreshedControls[controlKey].value = controlValues.resetValue ?? null;
      });
      this.setState({ controls: refreshedControls });
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  async onRemove() {
    const { data } = this.state;
    const confirmed = window.confirm(`Removing ${this.props.modalTitle} permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.onRemove(data.id);
      if (success) this.onClose(true);
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onClose) this.props.onClose();
    this.state.setModal(false);
    this.setState({
      isOpen: false,
    });
  }

  setOptions(controls) {
    const { controlOptions } = this.props;

    for (const key in controls) {
      let { options } = controls;
      if (controlOptions && controlOptions[key]) options = controlOptions[key];
      if (options) controls = updateControlOptions(controls, key, options);
    }

    return controls;
  }

  renderInputs(controls, onChange = null, forcedWidth = noop) {
    const groupInputs = {};

    const inputs = Object.entries(controls)
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .filter(([_, control]) => control.showInEditor === true)
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .filter(([_, control]) => isFieldShown(controls, control))
      .map(([key, control], index) => {
        let input;
        switch (controls[key].controlType) {
          case 'interval_date': {
            input = (
              <FormIntervalDatePicker
                key={index}
                handleChange={this.handleChange}
                controls={controls}
                intervals={controls[key].options ?? []}
                intervalKey={key}
                dateKey={controls[key].linkedWith}
              />
            );
            break;
          }
          case 'reactselect': {
            const onSelectChange = onChange
              ? ({ value, label }) => {
                  const event = {
                    value,
                    label,
                    name: key,
                  };
                  this.onChange(event);
                }
              : ({ value }) => {
                  const event = {
                    target: {
                      name: key,
                      value,
                    },
                  };
                  this.handleChange(event);
                };

            input = (
              <FormInputSelect
                key={index}
                handleChange={onSelectChange}
                control={controls[key]}
                isDisabled={controls[key]?.disabled ?? false}
              />
            );
            break;
          }
          case 'switch': {
            input = (
              <FormInputSwitch
                key={index}
                handleChange={onChange ?? this.handleChange}
                control={controls[key]}
              />
            );
            break;
          }
          default: {
            input = (
              <FormInput
                key={index}
                handleChange={onChange ?? this.handleChange}
                control={controls[key]}
              />
            );
            break;
          }
        }

        // If group set, add to group row, else return the raw form input
        const group_key = control.group;
        if (group_key) {
          if (!groupInputs[group_key]) groupInputs[group_key] = [];
          groupInputs[group_key].push(input);
          return false;
        } else return input;
      });

    // Create groups from the added inputs.
    const groups = [];
    for (const key in groupInputs) {
      const rows = [];
      const rawInputs = groupInputs[key];

      // Maximum 3 inputs per row.
      for (let i = 0; i <= rawInputs.length; i += 3) {
        rows.push(
          <Row key={i} className="bg-light m-0">
            {rawInputs.map((group, index) => {
              // Check if special col width requirements provided
              const { name } = group.props.control;
              const forcedColWidth = forcedWidth[name] ? forcedWidth[name] : null;

              const small = forcedColWidth?.sm ?? group.props.control.cols?.sm;
              const medium = forcedColWidth?.md ?? group.props.control.cols?.md;
              const large = forcedColWidth?.lg ?? group.props.control.cols?.lg;

              if (index < i + 3 && index >= i)
                return (
                  <Col sm={small} md={medium} lg={large} key={index} className="pb-2">
                    {group}
                  </Col>
                );
              return false;
            })}
          </Row>,
        );
      }

      groups.push(
        <Fragment key={key}>
          <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">
            {key[0].toUpperCase() + key.substring(1).toLowerCase()}
          </h5>
          {rows}
        </Fragment>,
      );
    }

    // Return groups first then any which were not assigned a group. This may mess with logical sequences if a mix of groups/direct is used.
    return [...groups, ...inputs];
  }

  render() {
    let { controls } = this.state;
    const { isOpen, title, isNew, description } = this.state;
    const { iconName } = this.props;

    controls = this.setOptions(controls);

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon size="1x" name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody>
          {description && <p>{description}</p>}
          {this.props.responseMessage && (
            <div className="text-center text-danger">{this.props.responseMessage}</div>
          )}
          <Form>{this.renderInputs(controls)}</Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && this.props.onRemove && (
            <Button
              size="sm"
              color="danger"
              onClick={this.onRemove}
              disabled={this.props.removeDisabled || false}
            >
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ manage }) => ({
  manage,
});

export default connect(mapStoreToProps)(GenericModal);
