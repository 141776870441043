import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Button } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { fromPairs, set } from 'lodash';

import {
  prepareForSubmission,
  useUpdateControls,
  RenderInput,
  removeUntouchedFields,
  selectDefaultForControls,
} from 'jsx/components/core/form/components/FormBuilder';
import { useCheckAccess } from 'jsx/lib/hooks';
import ResponseMessage from 'jsx/components/core/form/components/ResponseMessageTab';
import { projectRegisterForm as form } from '../../forms/project_register';
import { updateProjectPropertyRegister } from '../../actions/project_register';

const RenderForm = ({ registerForm, isDirty, onCancel, isReadOnly = false }) => (
  <>
    <Row>
      <Col sm={3}>
        <RenderInput control={registerForm.lga} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={registerForm.participant_client_no} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={registerForm.nrm_region} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={registerForm.nearest_town} disabled={isReadOnly} />
      </Col>
    </Row>
    <Row>
      <Col sm={3}>
        <RenderInput control={registerForm.delivery_officer_id} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={registerForm.abatement_volume} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={registerForm.planning_officer_id} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={registerForm.state} disabled={isReadOnly} />
      </Col>
    </Row>
    <Row>
      <Col sm={3}>
        <RenderInput control={registerForm.declaration_date} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={registerForm.lodged_date} disabled={isReadOnly} />
      </Col>
      {!isReadOnly && (
        <Col sm={3} className="d-flex align-items-end">
          <Button type="submit" color="primary" disabled={!isDirty}>
            Save changes
          </Button>
          {isDirty && (
            <Button type="button" color="secondary" className="ml-2" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Col>
      )}
    </Row>
  </>
);
const ProjectRegisterPropertyForm = ({
  register,
  propertyId,
  officers = [],
  refresh = () => undefined,
}) => {
  // Map officers to options and add blank option to the first element
  const officerOptions = useMemo(() => {
    const result = officers.map(({ id: optionId, name }) => ({
      name,
      label: name,
      value: optionId,
      id: optionId,
    }));
    result.unshift({ name: '-', label: '-', value: '-' });
    return result;
  }, [officers]);

  const registerForm = useUpdateControls(form, register);
  const formWithDelivery = set(registerForm, 'delivery_officer_id.options', officerOptions);
  const formWithAllOfficers = set(formWithDelivery, 'planning_officer_id.options', officerOptions);

  const defaultValues = selectDefaultForControls(formWithAllOfficers);

  const reactHookForm = useForm({ defaultValues });
  const dispatch = useDispatch();
  const handleInvalid = () => undefined;
  const {
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = reactHookForm;
  const {
    formStates: { projectRegisterProperty },
  } = useSelector((state) => state.project_register);

  const onCancel = () => {
    reactHookForm.reset();
  };

  const onSubmit = async (data) => {
    const hiddenFields = fromPairs(
      Object.entries(form).map(([key, value]) => {
        if (value.type === 'hidden') {
          return [key, true];
        }
        return [key, false];
      }),
    );
    const strippedData = removeUntouchedFields(data, {
      ...hiddenFields,
      ...dirtyFields,
    });

    const dataToSubmit = prepareForSubmission(strippedData, form);

    const results = await dispatch(updateProjectPropertyRegister(propertyId, dataToSubmit));

    if (results) {
      reactHookForm.reset(results);
      refresh();
    }
  };

  useEffect(() => {
    reactHookForm.reset();
  }, [officerOptions, reactHookForm]);

  const hasWriteAccess = useCheckAccess('projectRegisterUpdate');

  return (
    <>
      <div className="d-flex justify-content-between border-bottom align-items-end pb-1 mt-4">
        <h5 className="text-corporate mb-0">Register</h5>
      </div>
      <FormProvider {...reactHookForm}>
        <ResponseMessage responseMessage={projectRegisterProperty} />
        <form onSubmit={handleSubmit(onSubmit, handleInvalid)}>
          <RenderForm
            registerForm={formWithAllOfficers}
            isDirty={isDirty}
            onCancel={onCancel}
            isReadOnly={!hasWriteAccess}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default ProjectRegisterPropertyForm;
