const bigDecimal = require('js-big-decimal');

export const prettyFormat = (value) => {
  if (!value) return '--';
  value = value.toFixed(2).toString();
  value = addDollarSign(value);
  return addThousandsComma(value);
};

export const cardFormat = (value) => {
  if (!value) return '--';
  value = Math.floor(value).toString();
  value = addDollarSign(value);
  return addThousandsComma(value);
};

export const numberRuleFormat = (value, rules) => {
  if (value == null) return value;

  value = parseFloat(value);
  if (isNaN(value)) return null;

  if (rules.blankIfZero && value === 0) return null;

  // No other rules apply if using the prefix asSomething
  if (rules.asPercentage) value = convertFromDecimal(value, rules.includeDecimals ? 2 : 0);

  if (rules.asDollar) value = convertFromDecimal(value, rules.includeDecimals ? 2 : 0);

  if (rules.includeDecimals === true) {
    value = value.toFixed(2);
  } else {
    // In some instances, we don't want any formatting done to the value.
    if (rules.includeDecimals !== 'default') value = value.toFixed(0);
  }

  // String manipulation
  if (rules.includeDollarSign && notZero(value)) value = addDollarSign(value);

  if (rules.includePercentageSign) value = addPercentageSign(value);

  if (rules.includeCommas) value = addThousandsComma(value);

  return value;
};

export const strip = (caption) => {
  // Remove all previous formatting and return a float value
  try {
    if (typeof caption === 'number') return caption;
    let value = caption.replace(/[,$]+/g, '');
    value = parseFloat(value);
    return isNaN(value) ? 0 : value;
  } catch (_) {
    return 0;
  }
};

export const addThousandsComma = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const addDollarSign = (value) => {
  return value.toString().charAt(0) === '-' ? (value = `-$${value.substring(1)}`) : `$${value}`;
};

export const addPercentageSign = (value) => {
  return `${value}%`;
};

export const convertFromDecimal = (value, toFixedValue) => {
  if (!isNaN(value)) return parseFloat(bigDecimal.multiply(value, '100'));
};

const notZero = (value) => value !== '0' && value !== 0;
