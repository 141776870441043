import Icon from 'jsx/components/core/icons/Icon';
import React from 'react';

export const scanValidity = (site, onClick) => {
  switch (site.scan_valid_tag) {
    case 'invalid_core_length': {
      return {
        classes: 'bg-danger text-white',
        message: <div className="text-danger">Invalid core/depth length</div>,
      };
    }
    case 'missing_files': {
      return {
        classes: '',
        message: <div className="text-danger">Missing files</div>,
      };
    }
    case 'scan_fail': {
      return {
        classes: '',
        message: (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            className="text-danger"
            style={{ cursor: 'pointer' }}
            onClick={() => onClick(site, '4')}
          >
            FAILED (Invalid scan)
            <Icon name="comment" className="ml-2 text-danger" />
          </div>
        ),
      };
    }
    case 'exceed_gamma_threshold': {
      return {
        classes: '',
        message: (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div className="text-danger" style={{ cursor: 'pointer' }} onClick={() => onClick(site)}>
            FAILED (Gamma threshold exceeded)
            <Icon name="exclamation" className="ml-2 text-danger" />
          </div>
        ),
      };
    }
    case 'invalid_site_status': {
      return {
        classes: 'bg-danger text-white',
        message: <div className="text-danger">Scan Passed, Site not Active</div>,
      };
    }
    default: {
      if (site.scan_valid && site.compression_ratio < 0.45) {
        return {
          classes: '',
          message: (
            <div className="text-warning">
              Passed - Low C.Ratio
              <Icon name="triangle-exclamation" className="ml-2 text-warning" />
            </div>
          ),
        };
      }
      if (site.scan_valid) {
        return {
          classes: '',
          message: (
            <div className="text-success">
              Scan Passed <Icon name="check" className="ml-2 text-success" />
            </div>
          ),
        };
      }
      return {
        classes: '',
        message: '',
      };
    }
  }
};
