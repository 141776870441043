import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { authenticated, logout } from '../actions/';

class Logout extends React.Component {
  componentDidMount() {
    this.props.dispatch(authenticated(false));
    this.props.dispatch(logout());
  }

  render() {
    const { authenticated } = this.props.auth;

    return (
      <div>
        {!authenticated && <Redirect to="/login" />}
        Logging out...
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    auth: store.auth,
  };
};

export default connect(mapStoreToProps)(Logout);
