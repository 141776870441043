import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import FormInput from '../../../core/form/components/FormInput';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import Icon from 'jsx/components/core/icons/Icon';
import {
  validateChange,
  initControls,
  saveControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { controls } from '../forms/project.js';
import { createProject, fetchProjects } from '../actions/projects';

import { fetchCompany, fetchCompanies } from '../actions/crm';

class ProjectNewModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      activeTab: '1',
      project: {},
      controls: controls,
      backlink: '/home/projects',
      isValid: false,
      data: {},
      isOpen: false,
    };

    this.onCreate = this.onCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
  }

  componentDidMount() {
    this.props.dispatch({ type: 'UNSET_PROJECT' });
    this.setState({ controls: initControls(this.state.controls) });
    this.props.dispatch(fetchCompanies());
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_PROJECT' });
  }

  async componentDidUpdate() {
    let { isOpen, id, isNew, title } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        property_id: this.props.property_id,
        setModal: this.props.setModal,
      });
    }
  }

  updateControls() {
    const { currentProject } = this.props.projects;

    let { controls } = this.state;

    if (currentProject && this.state.data.id !== currentProject.id) {
      const keys = Object.keys(controls);
      keys.forEach((key) => {
        const fieldName = controls[key].name;
        controls[key].value = currentProject[fieldName];
      });

      this.setState({
        data: currentProject,
        controls,
      });
    }
  }

  async onCreate() {
    let { data, controls } = this.state;

    const keys = Object.keys(controls);
    keys.forEach((key) => {
      data[key] = controls[key].value;
    });

    const success = await this.props.dispatch(createProject(data));

    if (success) {
      this.props.dispatch(fetchProjects());
      this.props.dispatch({ type: 'UNSET_PROJECT' });

      this.props.setModal(false);
      this.setState({ isOpen: false, controls: initControls(this.state.controls) });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  handleChange(event) {
    const controls = validateChange(event, this.state.controls);
    const changes = saveControls(controls, this.state.data);
    const { isValid } = validateFormFieldControls(changes, controls);

    this.setState({
      controls,
      formChanged: true,
      isValid,
    });
  }

  handleCompanyChange(event) {
    this.handleChange(event);

    // Get CRM company details
    const crm_id = event.target.value;
    if (crm_id) {
      this.props.dispatch(fetchCompany(crm_id));
    }
  }

  render() {
    const { responseMessage } = this.props.projects;

    const { companies } = this.props.crm;

    const { controls, isValid, isOpen } = this.state;

    if (companies.length > 0) {
      let options = companies.map((company) => {
        return { id: company.id, name: company.properties.name };
      });

      options.push({ id: '', name: 'Select a CRM Company' });
      controls.crm_id.options = options;
    }

    const iconName = 'diagram-project';
    const title = 'New Project';

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          <ResponseMessage responseMessage={responseMessage} />
          <Form>
            <div className="p-2">
              <FormInput handleChange={this.handleChange} control={controls.name} />
              <FormInput handleChange={this.handleChange} control={controls.jira_key} />
              <FormInput handleChange={this.handleChange} control={controls.project_job_no} />
              <FormInput handleChange={this.handleCompanyChange} control={controls.crm_id} />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button disabled={!isValid} size="sm" color="success" onClick={() => this.onCreate()}>
            Create Project
          </Button>
          <Button size="sm" color="light" onClick={this.onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    projects: store.projects,
    crm: store.crm,
  };
};

export default connect(mapStoreToProps)(ProjectNewModal);
