import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  caption: {
    ...controlDefaults,
    placeholder: 'Enter Section name...',
    name: 'caption',
    type: 'text',
    validationRules: {}
  },
  row_type: {
    ...controlDefaults,
    placeholder: '',
    name: 'row_type',
    type: 'select',
    value: 'rows',
    validationRules: {},
    options: [
      {id: 'rows', name: 'Rows'},
      {id: 'widgets', name: 'Widgets'},
    ],
  },
  column_count: {
    ...controlDefaults,
    placeholder: 'Column count...',
    name: 'column_count',
    type: 'number',
    value: 2,
    validationRules: {}
  },
}
