import React from 'react';
import { isNil } from 'lodash';
import { fetchReportMetric, updateReportSectionRowCol } from '../../actions/reports';

class ReportCellBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lastReloadValue: null,
      fetching: false,
      metric: { id: null },
    };
  }

  componentDidUpdate(prevProps) {
    const { cell, currentReloadValue } = this.props;
    const { client_id } = this.props.report_preview;
    const { filters, hasFiltersChanged, previousFilters, reportEditable } = this.props.reports;
    const { divisions: division_ids = [], enterprises: enterprise_ids = [] } = filters;
    const {
      selectedProperties: property_ids = [],
      includeOrganisationPropertyOption,
      selectedRanges,
      local_timezone,
    } = this.props.enterprises;
    const { from_date: prev_from_date, to_date: prev_to_date } =
      prevProps.enterprises.selectedRanges;
    const { from_date, to_date } = selectedRanges;
    const { isLivestockAssumptionsToggled } = this.props.livestocks;
    const { lastReloadValue, fetching } = this.state;

    let reload = false;
    if (!isNil(currentReloadValue) && lastReloadValue !== currentReloadValue) {
      reload = true;
      this.setState({ lastReloadValue: currentReloadValue });
    }

    if (!isNil(cell?.metric_id)) {
      const params = hasFiltersChanged
        ? previousFilters
        : {
            from_date,
            to_date,
            local_timezone,
            property_ids,
            includeOrganisationPropertyOption,
            division_ids,
            enterprise_ids,
            isLivestockAssumptionsToggled
          };
      if (reload && !fetching && !reportEditable) {
        if (client_id) params.org_id = client_id;
        this.loadMetric(params);
      } else if (
        (prevProps.report_preview.client_id !== client_id ||
          prev_from_date !== from_date ||
          prev_to_date !== to_date) &&
        client_id
      ) {
        this.loadMetric({ ...params, org_id: client_id });
      }
    }

    // Reset metric state if cell has been cleared.
    if (prevProps.cell.metric_id && !this.props.cell.metric_id) {
      this.setState({ metric: {} });
    }
  }

  loadMetric = async (params) => {
    const {
      cell: { metric_id = null },
      report_id,
    } = this.props;

    let metric = {};
    if (metric_id) {
      const localParams = { ...params, report_id };
      this.setState({ fetching: true });

      metric = await this.props.dispatch(
        fetchReportMetric({ ...localParams, id: metric_id }, true),
      );
    }
    this.setState({ metric, fetching: false });
  };

  removeCell = async () => {
    const { id } = this.props.cell;
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm('Removing cell content. Continue?');
    if (confirmed) {
      await this.props.dispatch(updateReportSectionRowCol(id, { metric_id: null }));

      this.props.functions.loadReportVersion();
    }
  };

  editContent = () => {
    const { id, metric_id } = this.props.cell;

    this.props.functions.setSelectModal(true, id, metric_id);
  };

  viewMetric = () => {
    const { cell, report_id } = this.props;

    if (!cell.metric_id) return;
    const { client_id } = this.props.report_preview;

    // Associate cell with metric so we can clear cells from parent components.
    const modalMetric = {
      ...this.state.metric,
      cell,
      org_id: client_id,
    };

    this.props.functions.setMetricModal(true, modalMetric, report_id);
  };
}

export default ReportCellBase;
