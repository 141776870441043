import { Button, Label, CustomInput, UncontrolledPopover, PopoverBody } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';

import TrendAxisPropertiesLsv from './TrendAxisPropertiesLsv';
import TrendAxisGroupsLsv from './TrendAxisGroupsLsv';

const AdminTrendAnalysisToolbar = (props) => {
  const {
    handleFilterChange = noop,
    onHandleConfigChange = noop,
    config = {},
    filterControls = noop,
    properties = [],
    propertyFilters = [],
    buttonsDisabled=false,
    showDefaultsButton=false,
    filters={},
    updateField= noop,
    propertyFields= [],
    groupFields=[]
  } = props;

  filterControls.property_id.options = properties?.map(property => ({id: property?.id, name: property?.name}));

  const popoverId = 'settings_popover';

  // Build property defaults
  const propertyDefaults = [];
  propertyFields.map(field => {
    propertyDefaults.push(
      {
        id: uuidv4(),
        field_type: 'property',
        property_name: `Property ${field.index}`,
        index: field.index,
        dataKey: field.dataKey,
        caption: field.name,
        chart: field.chart,
        colour: field.colour
      }
    );
  });

  // Build group defaults
  const groupDefaults = [];
  groupFields.map(field => {
    groupDefaults.push(
      {
        id: uuidv4(),
        field_type: 'group',
        group_name: `Group ${field.index}`,
        index: field.index,
        dataKey: field.dataKey,
        chart: field.chart,
        colour: field.colour,
        caption: field.name
      }
    );
  });

  return (
    <div className="m-1 p-1 d-flex justify-content-between bg-light rounded">
      <div className="d-flex justify-content-between">
        <Label className="m-0 mt-1 ml-2 mr-2 " style={{fontSize: 14}}>Property</Label>
        <FormInputSelect selectFirst={false} className="mr-2" size="30px" handleChange={(event) => handleFilterChange(event, 'property_id')} control={filterControls.property_id} />
        <div className="d-flex float">
          {propertyFilters}
        </div>

        {filters.property_id.length > 0 && (
          <>
            <Label className="m-0  mt-1 ml-3" style={{fontSize: 14}}>Benchmark Group</Label>
            <FormInputSelect className="ml-1"  size="30px" hideCaption handleChange={(event) => this.handleFilterChange('group_id', event.value)} control={filterControls.group_id} />
          </>
        )}
      </div>

      <div className="d-flex justify-content-end">
        {filters.property_id.length > 0 && (
          <>
            <Label className="m-1" style={{fontSize: 14}}>Show Tooltip</Label>
            <CustomInput
              type="switch"
              role="switch"
              id={'showTooltip'}
              name={'showTooltip'}
              checked={config.showTooltip}
              onChange={onHandleConfigChange}
              className="m-1"
            />

            <Label className="m-1" style={{fontSize: 14}}>Sync Tip</Label>
            <CustomInput
              type="switch"
              role="switch"
              id={'syncTip'}
              name={'syncTip'}
              checked={config.syncTip}
              onChange={onHandleConfigChange}
              className="m-1"
            />

            {showDefaultsButton && (
              <>
                <Button 
                id={popoverId}
                type="button"
                size="sm"
                color="success"
                disabled={buttonsDisabled}
              >
                <Icon
                  id="gear"
                  name="gear"
                  title="Default Chart Settings"
                  className="text-white"
                />
              </Button>

              <UncontrolledPopover
                placement="bottom"
                target={popoverId}
                trigger="legacy"
              >
                <PopoverBody className="bg-gray-20" >
                  <div className="mt-2 border-bottom border-green d-flex justify-content-between text-green">
                    <small>Properties</small>
                    <small>+</small>
                  </div>
                  <TrendAxisPropertiesLsv
                    rows={propertyDefaults}
                    updateField={updateField}
                  />

                  <div className="mt-2 border-bottom border-green d-flex justify-content-between text-green">
                    <small>Benchmark Groups</small>
                    <small>+</small>
                  </div>
                  <TrendAxisGroupsLsv
                    rows={groupDefaults}
                    updateField={updateField}
                  />
                </PopoverBody>
              </UncontrolledPopover>
              </>
            )}

            
          </>
        )}
      </div>

    </div>
  );
};

export default AdminTrendAnalysisToolbar;
