/** ES-296 */
/* eslint-disable no-param-reassign, no-plusplus */

import { cloneDeep, isEmpty } from 'lodash';
import FormBase from '../../../core/form/components/FormBase';
import { controls as controlsTransaction } from '../forms/livestockTransactions';
import { controls as controlsAdjustment } from '../forms/livestockAdjustments';
import { controls as controlsBreeding } from '../forms/livestockBreedings';
import { controls as controlsLivestockIncome } from '../forms/livestockIncome';
import { controls as controlsTransfer } from '../forms/livestockTransfers';

import { controls as controlsDirectCost } from '../forms/directCosts';
import { controls as controlsAnimalClassValue } from '../forms/animalClassValues';
import { controls as controlsAgistmentExpense } from '../forms/agistments/agistmentExpense';
import { controls as controlsAgistmentIncome } from '../forms/agistments/agistmentIncome';

import {
  createLivestockBreeding,
  createLivestockTransaction,
  createLivestockTransfer,
  fetchLivestockBreeding,
  fetchLivestockBreedings,
  fetchLivestockStockflows,
  fetchLivestockSummaries,
  fetchLivestockTransaction,
  fetchLivestockTransactions,
  fetchLivestockTransfer,
  fetchLivestockTransfers,
  fetchLivestockIncomes,
  removeLivestockBreeding,
  removeLivestockTransaction,
  removeLivestockTransfer,
  removeLivestockOpeningAdjustment,
  updateLivestockBreeding,
  updateLivestockTransaction,
  updateLivestockTransfer,
  fetchAgistments,
  fetchAgistment,
  createAgistment,
  updateAgistment,
  removeAgistment,
} from '../actions/livestocks';

import { fetchDirectCosts } from '../actions/directCosts';
import { fetchAttribute, fetchAttributes } from '../actions/attributes';
import { fetchEnterpriseRanges } from '../actions/enterprises';
import {
  createAnimalClassValue,
  fetchAnimalClasses,
  fetchAnimalClassValue,
  fetchAnimalClassValues,
  removeAnimalClassValue,
  updateAnimalClassValue,
  createAnimalClassReproduction,
  updateAnimalClassReproduction,
  fetchAnimalClassValuesByMonth,
} from '../actions/animal_classes';

class LivestockEnterprises extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      modalType: null,
      isModalOpen: false,
      isDirectCostModalOpen: false,
      isLivestockIncomeModalOpen: false,
      isLivestockTransactionModalOpen: false,
      isLivestockAdjustmentModalOpen: false,
      isLivestockBreedingModalOpen: false,
      isTransferModalOpen: false,
      isLivestockValueModalOpen: false,
      modalData: null,
      division_id: null,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setDirectCostModal = this.setDirectCostModal.bind(this);
    this.setLivestockOpeningModal = this.setLivestockOpeningModal.bind(this);
    this.setLivestockIncomeModal = this.setLivestockIncomeModal.bind(this);
    this.setLivestockBreedingModal = this.setLivestockBreedingModal.bind(this);
    this.setTransferModal = this.setTransferModal.bind(this);
    this.setAgistmentIncomeModal = this.setAgistmentIncomeModal.bind(this);
    this.setLivestockTransactionModal = this.setLivestockTransactionModal.bind(this);
    this.setLivestockAdjustmentModal = this.setLivestockAdjustmentModal.bind(this);
    this.setLivestockValueModal = this.setLivestockValueModal.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.onValuationChange = this.onValuationChange.bind(this);
    this.onRIChange = this.onRIChange.bind(this);
    this.loadDivision = this.loadDivision.bind(this);
  }

  async componentDidMount() {
    await this.props.dispatch(fetchAttributes({ type: 'transaction_intervals' }));
    await this.props.dispatch(fetchAttributes({ type: 'transaction_types' }));
  }

  componentDidUpdate() {
    const { isActive, forceRefresh, setRefresh } = this.props;

    if (isActive && forceRefresh && setRefresh) {
      this.onRefresh();
      if (setRefresh) setRefresh(false);
    }
  }

  async toggleTab(tab) {
    let { modalType } = this.state;
    const { division_id } = this.state;
    const { byMonth } = this.props.animal_classes;
    const { selectedRanges, selectedProperties } = this.props.enterprises;
    const { isLivestockAssumptionsToggled } = this.props.livestocks;

    this.props.dispatch(fetchEnterpriseRanges());

    switch (parseInt(tab)) {
      case 1: {
        this.props.dispatch(
          fetchLivestockStockflows({
            ...selectedRanges,
            division_id,
            property_ids: selectedProperties,
            isLivestockAssumptionsToggled,
          }),
        );
        break;
      }
      case 2: {
        // Check if client has toggled livestock assumptions
        this.props.dispatch(
          fetchAnimalClassValues({
            ...selectedRanges,
            division_id,
            property_ids: selectedProperties,
            isLivestockAssumptionsToggled,
          }),
        );
        this.props.dispatch(
          fetchAnimalClassValuesByMonth({
            ...selectedRanges,
            division_id,
            property_ids: selectedProperties,
            valueType: byMonth.valueType,
            isLivestockAssumptionsToggled,
          }),
        );
        modalType = 'value';
        break;
      }
      case 3: {
        this.props.dispatch(
          fetchLivestockTransactions('purchases', {
            division_id,
            transaction_type_tag: 'purchase',
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'transaction';
        break;
      }
      case 4: {
        this.props.dispatch(
          fetchLivestockTransactions('sales', {
            division_id,
            transaction_type_tag: 'sale',
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'transaction';
        break;
      }
      case 5: {
        this.props.dispatch(
          fetchLivestockTransactions('adjustments', {
            division_id,
            transaction_type_tag: 'adjustment',
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'adjustment';
        break;
      }
      case 6: {
        this.props.dispatch(
          fetchLivestockBreedings({
            division_id,
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'breeding';
        break;
      }
      case 7: {
        this.props.dispatch(
          fetchLivestockTransfers({
            ...selectedRanges,
            division_id,
            property_ids: selectedProperties,
          }),
        );
        modalType = 'transfer';
        break;
      }
      case 8: {
        await this.props.dispatch(
          fetchAttributes({ type: 'cost_categories', parent_id: division_id }),
        );
        this.props.dispatch(
          fetchDirectCosts({
            division_id,
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'direct';
        break;
      }
      case 9: {
        this.props.dispatch(
          fetchLivestockIncomes({
            division_id,
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'livestock_income';
        break;
      }
      case 10: {
        this.props.dispatch(
          fetchAgistments({
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'agistment_income';
        break;
      }
      case 11: {
        this.props.dispatch(
          fetchAgistments({
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'agistment_expense';
        break;
      }
      default:
        break;
    }

    if (selectedRanges.from_date && division_id) {
      this.props.dispatch(
        fetchLivestockSummaries({
          ...selectedRanges,
          division_id,
          property_ids: selectedProperties,
          isLivestockAssumptionsToggled,
        }),
      );
    }

    this.setState({
      modalType,
    });
  }

  stripUnitObjects = (data) => {
    const updatedData = cloneDeep(data);
    Object.keys(updatedData).forEach((fieldIdx) => {
      if (
        typeof updatedData[fieldIdx] === 'object' &&
        updatedData[fieldIdx] !== null &&
        updatedData[fieldIdx].unit_type &&
        updatedData[fieldIdx].value
      ) {
        delete updatedData[fieldIdx].unit_type;
      }
    });

    return updatedData;
  };

  onSave(data, isNew) {
    let updatedData = cloneDeep(data);
    const { modalType } = this.state;
    if (isNew) delete updatedData.id;
    updatedData = this.stripUnitObjects(updatedData);

    let saveMethod;
    switch (modalType) {
      case 'breeding': {
        saveMethod = isNew ? createLivestockBreeding : updateLivestockBreeding;
        break;
      }
      case 'transfer': {
        saveMethod = isNew ? createLivestockTransfer : updateLivestockTransfer;
        break;
      }
      case 'value': {
        saveMethod = isNew ? createAnimalClassValue : updateAnimalClassValue;
        break;
      }
      case 'agistment_income': {
        saveMethod = isNew ? createAgistment : updateAgistment;
        break;
      }
      case 'agistment_expense': {
        saveMethod = isNew ? createAgistment : updateAgistment;
        break;
      }
      default: {
        saveMethod = isNew ? createLivestockTransaction : updateLivestockTransaction;
        break;
      }
    }

    return this.props.dispatch(saveMethod(updatedData));
  }

  onRemove(id) {
    const { modalType } = this.state;

    let removeMethod;
    switch (modalType) {
      case 'breeding': {
        removeMethod = removeLivestockBreeding;
        break;
      }
      case 'transfer': {
        removeMethod = removeLivestockTransfer;
        break;
      }
      case 'agistment_income': {
        removeMethod = removeAgistment;
        break;
      }
      case 'agistment_expense': {
        removeMethod = removeAgistment;
        break;
      }
      case 'opening_value': {
        removeMethod = removeLivestockOpeningAdjustment;
        break;
      }
      case 'value': {
        removeMethod = removeAnimalClassValue;
        break;
      }
      default: {
        removeMethod = removeLivestockTransaction;
        break;
      }
    }

    return this.props.dispatch(removeMethod(id));
  }

  onRefresh() {
    const { activeLiveStockTab: tab_id } = this.props.livestocks;
    if (tab_id) this.toggleTab(tab_id);
  }

  async onRIChange(value, row) {
    const { division_id } = this.state;
    const { selectedRanges, selectedProperties } = this.props.enterprises;

    const data = {
      animal_class_id: row.id,
      enterprise_id: row.enterprise.id,
      is_reproductive: value,
      id: row.animal_class_reproduction?.id ?? null,
    };

    let success;
    if (data.id === null) success = await this.props.dispatch(createAnimalClassReproduction(data));
    else success = await this.props.dispatch(updateAnimalClassReproduction(data));

    if (success)
      this.props.dispatch(
        fetchLivestockStockflows(
          { division_id, property_ids: selectedProperties, ...selectedRanges },
          true,
        ),
      );
  }

  async onValuationChange(field, row) {
    const { division_id } = this.state;
    const { selectedRanges, selectedProperties } = this.props.enterprises;

    const animal_class_id = row.id;
    const enterprise_id = row.enterprise.id;
    const property_id = row.property.id;
    const date = field.includes('opening') ? selectedRanges.from_date : selectedRanges.to_date;

    const value = row[field].value === '' ? 0 : row[field].value;

    const type = 'value';

    const data = {
      animal_class_id,
      enterprise_id,
      date,
      property_id,
    };

    if (field.includes('weight')) {
      data.weight = { value };
    } else if (field.includes('au_rating')) {
      data.au_rating = { value };
    } else {
      data[type] = value;
    }
    const success = await this.props.dispatch(createAnimalClassValue(data, true));

    if (success) {
      const isLivestockAssumptionsToggled = this.checkLivestockAssumptionsToggled(division_id);
      this.props.dispatch(
        fetchLivestockStockflows(
          {
            ...selectedRanges,
            division_id,
            property_ids: selectedProperties,
            isLivestockAssumptionsToggled,
          },
          true,
        ),
      );
      this.props.dispatch(
        fetchLivestockSummaries({
          ...selectedRanges,
          division_id,
          property_ids: selectedProperties,
          isLivestockAssumptionsToggled,
        }),
      );
    }
  }

  async setLivestockAdjustmentModal(isLivestockAdjustmentModalOpen, id = null) {
    const { division_id } = this.state;
    let modalData;

    await this.props.dispatch(
      fetchAttributes({
        type: 'transaction_types',
      }),
    );
    await this.props.dispatch(fetchAnimalClasses({ division_id, valid: true }));

    await this.props.dispatch(fetchAttributes({ type: 'livestock_adjustment_types' }));

    if (id) modalData = await this.props.dispatch(fetchLivestockTransaction(id));

    this.setState({
      isLivestockAdjustmentModalOpen,
      id,
      modalData,
      modalType: 'adjustment',
    });
  }

  async setModal(isModalOpen, modalType = null, modalId = null, animal_class_id = null) {
    const { division_id } = this.state;

    let modalData;

    // Refresh lookups involved in all modal types
    this.props.dispatch(fetchAnimalClasses({ division_id, valid: true, animal_class_id }));

    // Refresh dependant lookups based on type
    if (isModalOpen) {
      switch (modalType) {
        case 'transfer': {
          if (modalId) {
            modalData = await this.props.dispatch(fetchLivestockTransfer(modalId));
          }
          break;
        }
        case 'agistment_income': {
          if (modalId) {
            modalData = await this.props.dispatch(fetchAgistment(modalId));
          }
          break;
        }
        case 'agistment_expense': {
          if (modalId) {
            modalData = await this.props.dispatch(fetchAgistment(modalId));
          }
          break;
        }
        case 'adjustment':
        default: {
          await this.props.dispatch(
            fetchAttributes({
              type: 'transaction_types',
            }),
          );

          await this.props.dispatch(fetchAttributes({ type: 'livestock_adjustment_types' }));

          if (modalId) modalData = await this.props.dispatch(fetchLivestockTransaction(modalId));
          break;
        }
      }
    }
    const state = {
      isModalOpen,
      modalData,
    };
    if (modalType) state.modalType = modalType;
    this.setState(state);
  }

  setLivestockOpeningModal(isLivestockOpeningModalOpen, id = null) {
    this.setState({
      isLivestockOpeningModalOpen,
      id,
    });
  }

  async setLivestockValueModal(isLivestockValueModalOpen, id = null, animal_class_id = null) {
    const { division_id } = this.state;

    await this.props.dispatch(fetchAnimalClasses({ division_id, animal_class_id }));

    await this.props.dispatch(
      fetchAttributes({
        type: 'breeding_periods',
        parent_id: division_id,
      }),
    );

    let modalData;
    if (id) {
      modalData = await this.props.dispatch(fetchAnimalClassValue(id));
    }
    this.setState({
      isLivestockValueModalOpen,
      modalData,
      modalType: 'value',
      id,
    });
  }

  setDirectCostModal(isDirectCostModalOpen, id = null) {
    this.setState({
      isDirectCostModalOpen,
      id,
    });
  }

  setLivestockIncomeModal(isLivestockIncomeModalOpen, id = null) {
    this.setState({
      isLivestockIncomeModalOpen,
      id,
    });
  }

  async setLivestockTransactionModal(isLivestockTransactionModalOpen, id = null) {
    const { division_id } = this.state;
    await this.props.dispatch(fetchAnimalClasses({ division_id }));
    await this.props.dispatch(fetchAttributes({ type: 'transaction_types' }));

    let modalData;
    if (id) modalData = await this.props.dispatch(fetchLivestockTransaction(id));

    this.setState({
      isLivestockTransactionModalOpen,
      id,
      modalData,
      modalType: 'transaction',
    });
  }

  async setLivestockBreedingModal(isLivestockBreedingModalOpen, id = null) {
    const { division_id } = this.state;

    this.props.dispatch(fetchAnimalClasses({ division_id, valid: true }));
    this.props.dispatch(fetchAttributes({ type: 'breeding_periods', parent_id: division_id }));

    let modalData;
    if (id) {
      modalData = await this.props.dispatch(fetchLivestockBreeding(id));
    }
    this.setState({
      isLivestockBreedingModalOpen,
      modalData,
      modalType: 'breeding',
      id,
    });
  }

  async setTransferModal(isTransferModalOpen, id = null) {
    const { division_id } = this.state;
    const modalData = id ? await this.props.dispatch(fetchLivestockTransfer(id)) : null;

    this.props.dispatch(fetchAnimalClasses({ division_id, valid: true }));

    this.setState({
      isTransferModalOpen,
      id,
      modalData,
      modalType: 'transfer',
    });
  }

  setAgistmentIncomeModal(isAgistmentIncomeModalOpen, id = null) {
    this.setState({
      isAgistmentIncomeModalOpen,
      id,
    });
  }

  setModalOptions(type) {
    const { division_id, modalData } = this.state;
    const { enterprises } = this.props.enterprises;
    const {
      breeding_periods,
      cost_types,
      livestock_adjustment_types,
      divisions,
      transaction_intervals,
      livestock_income_categories,
    } = this.props.attributes;
    let { transaction_types } = this.props.attributes;

    // Get current division to check if rules apply
    const currentDivision = divisions.find(({ id }) => id === division_id);

    // Division level filter for transaction types
    transaction_types = transaction_types.filter(
      ({ tag }) => !currentDivision?.rules?.denyTransactionTypes?.includes(tag),
    );

    const { animal_classes } = this.props.animal_classes;
    const { direct_costs } = this.props.direct_costs;
    const { livestock_incomes } = this.props.livestocks;

    const division_enterprises = enterprises?.rows
      ? enterprises.rows.filter(({ division_id: id }) => id === division_id)
      : [];

    const livestock_enterprises = enterprises?.rows
      ? enterprises?.rows.filter(
          ({ division }) =>
            division.tag === 'cattle' ||
            division.tag === 'sheep' ||
            division.tag === 'other_livestock',
        )
      : [];

    let options;

    switch (type) {
      case 'direct': {
        // Add distribution controls
        let updatedControls = this.addDistributionControls(controlsDirectCost, direct_costs);
        updatedControls = this.addTotalsToDirectCosts(updatedControls, direct_costs);
        options = {
          title: 'Direct Cost',
          iconName: 'handshake',
          controls: updatedControls,
          options: {
            type_id: cost_types,
            transaction_interval_id: transaction_intervals,
          },
        };
        break;
      }
      case 'livestock_income': {
        const updatedControls = this.addOtherIncomeDistributionControls(
          controlsLivestockIncome,
          livestock_incomes,
        );
        options = {
          title: 'Other Income',
          iconName: 'handshake',
          controls: updatedControls,
          options: {
            category_id: livestock_income_categories,
            transaction_interval_id: transaction_intervals,
          },
        };
        break;
      }
      case 'breeding': {
        options = {
          title: 'Breeding',
          iconName: 'handshake',
          controls: controlsBreeding,
          options: {
            breeder_enterprise_id: division_enterprises,
            offspring_enterprise_id: division_enterprises,
            breeder_animal_class_id: animal_classes,
            offspring_animal_class_id: animal_classes,
            breeding_period_id: breeding_periods,
            joined_condition_score: [
              { id: '1', name: 1 },
              { id: '2', name: 2 },
              { id: '3', name: 3 },
              { id: '4', name: 4 },
              { id: '5', name: 5 },
            ],
          },
        };
        break;
      }
      case 'transfer': {
        options = {
          title: 'Transfer',
          iconName: 'handshake',
          controls: controlsTransfer,
          options: {
            source_enterprise_id: division_enterprises,
            target_enterprise_id: division_enterprises,
            source_animal_class_id: animal_classes,
            target_animal_class_id: animal_classes,
          },
        };
        break;
      }
      case 'adjustment': {
        const controls = cloneDeep(controlsAdjustment);
        const filtered_transaction_types = transaction_types.filter(({ tag }) => tag === type);

        controls.transaction_type_id.value = filtered_transaction_types[0]?.id;

        let filtered_adjustment_types = livestock_adjustment_types.filter(
          ({ tag }) => tag !== 'opening_quantity',
        );
        controls.adjustment_type_id.disabled = false;

        // Check and override adjustment type
        if (modalData && modalData?.adjustment_type?.tag === 'opening_quantity') {
          filtered_adjustment_types = livestock_adjustment_types.filter(
            ({ tag }) => tag === 'opening_quantity',
          );
          controls.adjustment_type_id.disabled = true;
          controls.transaction_date.disabled = true;

          // Set inidicator message under the notes field (last input)
          controls.notes.description = 'Edit Opening Values in the Values tab.';
        }

        options = {
          title: 'Adjustment',
          iconName: 'handshake',
          controls,
          options: {
            enterprise_id: division_enterprises,
            animal_class_id: animal_classes,
            transaction_type_id: filtered_transaction_types,
            adjustment_type_id: filtered_adjustment_types,
          },
        };
        break;
      }
      case 'value': {
        options = {
          title: 'Values',
          iconName: 'paw',
          emptyCaption: 'No values found',
          controls: controlsAnimalClassValue,
          options: {
            animal_class_id: animal_classes,
            enterprise_id: division_enterprises,
          },
        };
        break;
      }
      case 'agistment_income': {
        const filtered_transaction_types = transaction_types.filter(
          (transaction_type) => transaction_type.tag === type,
        );

        options = {
          title: 'Income',
          iconName: 'dollar-sign',
          emptyCaption: 'No agistment incomes found',
          controls: controlsAgistmentIncome,
          options: {
            animal_class_id: animal_classes,
            enterprise_id: livestock_enterprises,
            transaction_type_id: filtered_transaction_types,
          },
        };
        break;
      }
      case 'agistment_expense': {
        const filtered_transaction_types = transaction_types.filter(
          (transaction_type) => transaction_type.tag === type,
        );
        options = {
          title: 'Expense',
          iconName: 'dollar-sign',
          emptyCaption: 'No agistment expenses found',
          controls: controlsAgistmentExpense,
          options: {
            animal_class_id: animal_classes,
            enterprise_id: livestock_enterprises,
            transaction_type_id: filtered_transaction_types,
          },
        };
        break;
      }
      case 'transaction':
      default: {
        const controls = cloneDeep(controlsTransaction);

        if (modalData) {
          // Set user entered fields for amount/weight controls
          controls.amount.user_entry = true;
          controls.weight.user_entry = true;
        }

        options = {
          title: 'Sale/Purchase',
          iconName: 'handshake',
          controls,
          options: {
            enterprise_id: division_enterprises,
            animal_class_id: animal_classes,
            transaction_type_id: transaction_types.filter(
              (transaction_type) =>
                transaction_type.tag === 'sale' || transaction_type.tag === 'purchase',
            ),
          },
        };
        break;
      }
    }

    return options;
  }

  addDistributionControls = (controls, rows) => {
    const { distribution_default } = controls;
    const updatedControls = cloneDeep(controls);

    // Check rows exist
    if (rows.rows?.length > 0) {
      // Check distributions exist and are populated
      rows.rows.forEach((row) => {
        if (row.distributions?.length > 0) {
          // Iterate through each distribution
          row.distributions.forEach((distribution, idx) => {
            // Add distribution control
            const enterpriseName = `${distribution.enterprise.name
              .toString()
              .toLowerCase()}_distribution`;

            updatedControls[enterpriseName] = {
              ...distribution_default,
              caption: `${distribution.enterprise.name} (%)`,
              fieldName: `distributions.${idx++}.distribution_pcnt`,
              formattingRules: {
                asPercentage: true,
                includeDecimals: true,
                includePercentageSign: true,
              },
              name: `${enterpriseName}`,
              showInListview: true,
            };
          });
        }
      });
    }

    return updatedControls;
  };

  addOtherIncomeDistributionControls = (controls, rows) => {
    const { distribution_default } = controls;
    const updatedControls = cloneDeep(controls);

    // Check rows exist
    if (rows.rows?.length > 0) {
      // Check distributions exist and are populated
      rows.rows.forEach((row) => {
        if (!isEmpty(row.distributions)) {
          // Iterate through each distribution
          Object.entries(row.distributions).forEach(([key, value]) => {
            updatedControls[key] = {
              ...distribution_default,
              caption: `${value.enterprise.name} (%)`,
              fieldName: `distributions.${key}.distribution_pcnt`,
              formattingRules: {
                asPercentage: true,
                includeDecimals: true,
                includePercentageSign: true,
              },
              name: `${key}`,
              showInListview: true,
            };
          });
        }
      });
    }

    return updatedControls;
  };

  addTotalsToDirectCosts = (controls, rows) => {
    const updatedControls = cloneDeep(controls);

    // Check rows exist
    if (rows.rows?.length > 0) {
      // Check distributions exist and are populated
      rows.rows.forEach((row) => {
        if (row.value && updatedControls.value) {
          updatedControls.value = {
            ...updatedControls.value,
            totals: true,
          };
        }
      });
    }

    return updatedControls;
  };

  async loadDivision(tag) {
    const { id: division_id } = await this.props.dispatch(
      fetchAttribute({ type: 'divisions', tag }),
    );
    this.setState({ division_id });
  }
}

export default LivestockEnterprises;
