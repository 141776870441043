import React from 'react';
import { connect } from 'react-redux';
import GenericLsv from '../../../core/form/components/GenericLsv';
import PropertiesToolbar from '../components/PropertiesToolbar.jsx';
import GenericModal from '../../../core/form/components/GenericModal';
import FormBase from '../../../core/form/components/FormBase';
import Icon from 'jsx/components/core/icons/Icon';

import { controls } from '../forms/properties.js';

import { Alert } from 'reactstrap';

class Properties extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      controls: controls,
      isModalOpen: false,
      isNew: false,
      data: null,
    };

    this.goBack = this.goBack.bind(this);
    this.findOne = this.findOne.bind(this);
    this.setModal = this.setModal.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  setModal(isOpen, isNew) {
    console.log(isOpen, isNew);
    this.setState({ isNew, isModalOpen: isOpen });
  }

  async findOne(id) {
    const data = await this.props.clients.properties.find((property) => property.id === id);
    this.setState({ isNew: false, isModalOpen: true, data });
  }

  async onSave() {}

  async onRemove() {}

  render() {
    const { properties } = this.props.clients;

    const { controls, isModalOpen, isNew, data } = this.state;

    const iconName = 'draw-polygon';
    const emptyCaption = 'No Properties found';

    return (
      <div className="p-0 h-100">
        <Alert className="mt-1" color="warning">
          <Icon size="1x" name="lightbulb-on" className="mr-2" />
          Select or update properties that should be part of this probe. Snapshot of selected
          properties are saved with each version of the profit probe publication
        </Alert>
        <PropertiesToolbar setModal={this.setModal} />
        <GenericLsv
          controls={controls}
          iconName={iconName}
          emptyCaption={emptyCaption}
          onClick={this.findOne}
          rows={properties}
        />
        <GenericModal
          controls={controls}
          modalTitle="Property"
          setModal={this.setModal}
          isOpen={isModalOpen}
          isNew={isNew}
          iconName="draw-polygon"
          data={data}
          onSave={this.onSave}
          onRemove={this.onRemove}
        />
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
    clients: store.clients,
  };
};

export default connect(mapStoreToProps)(Properties);
