/* eslint-disable no-alert */
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane, Row, Col, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import { fetchAnalysisGroupMembers } from 'jsx/components/manage/actions/analysis_group_members';
import { fetchAnalysisGroups } from 'jsx/components/manage/actions/analysis_groups';
import { fetchProjectPropertyAnalysisPeriods } from 'jsx/components/manage/actions/project_property_analysis_periods';

import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';

import PropertyProfile from './PropertyProfile';
// import PropertyLots from './PropertyLots';
import PropertyPaddocks from './PropertyPaddocks';
import PropertyUsages from './PropertyUsages';
import PropertyRainfalls from './PropertyRainfalls';
import PropertyAllocations from './PropertyAllocations';

import { fetchProperty } from '../../projects/actions/properties';
import { fetchPropertyUsages } from '../actions/propertyUsages';
import { fetchPropertyRainfalls } from '../actions/propertyRainfalls';
import { fetchEnterpriseAllocations, fetchEnterprises } from '../actions/enterprises';

import PropertyAnalysisGroups from './PropertyAnalysisGroups';
import PropertyAnalysisPeriods from './PropertyAnalysisPeriods';

class Property extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activePropertyTab: 'profile',
      data: {},
      errorMessage: null,
      id: null,
      map: null,
      refresh: false,
    };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;

    const data = await this.props.dispatch(fetchProperty(id));
    this.setState({
      id,
      data,
    });
  }

  componentDidUpdate() {
    // Force a map redraw
    if (this.state.map) this.state.map.resize();
  }

  toggleTab = (tabId) => {
    const { activePropertyTab, id, refresh } = this.state;

    switch (tabId) {
      case 'usages': {
        this.props.dispatch(fetchPropertyUsages({ property_id: id }));
        break;
      }
      case 'allocations': {
        this.props.dispatch(fetchEnterprises());
        this.props.dispatch(fetchEnterpriseAllocations({ property_id: id }));
        break;
      }
      case 'rainfalls': {
        this.props.dispatch(fetchPropertyRainfalls({ property_id: id }));
        break;
      }
      case 'groups': {
        this.props.dispatch(fetchAnalysisGroups());
        this.props.dispatch(fetchAnalysisGroupMembers({ property_id: id }));
        break;
      }
      case 'periods': {
        this.props.dispatch(fetchProjectPropertyAnalysisPeriods({ property_id: id }));
        break;
      }
      default:
        break;
    }

    if (activePropertyTab !== tabId) {
      this.setState({ activePropertyTab: tabId, refresh: !refresh });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  onRefresh = () => {
    const { activePropertyTab } = this.state;
    if (activePropertyTab) this.toggleTab(activePropertyTab);
  };

  render() {
    const { authResponseMessage, authorised } = this.props.portal;
    const { activePropertyTab, data, id, refresh } = this.state;
    const title = data.name;
    return (
      <div className="p-0 h-100 d-flex flex-column">
        <ResponseMessage responseMessage={authResponseMessage} />
        {authorised && (
          <>
            <div className="bg-light p-2 border-bottom border-corporate d-flex justify-content-between">
              <div className="d-flex justify-content-start">
                <Button size="sm" color="success" onClick={this.goBack}>
                  <Icon size="1x" name="chevron-left" className="mr-2" />
                  Back
                </Button>
                <h5 className="m-1 ml-2">{title}</h5>
              </div>
            </div>
            <Row className="p-0 m-0 flex-fill">
              <Col className="p-0 m-0 h-100 verticalnav">
                <Nav vertical className="mt-2">
                  <FormTab
                    caption="Property Profile"
                    activeTab={activePropertyTab}
                    toggle={this.toggleTab}
                    tabId="profile"
                  />
                  <FormTab
                    caption="Lots and Parcels"
                    disabled
                    activeTab={activePropertyTab}
                    toggle={this.toggleTab}
                    tabId="lots"
                  />
                  <FormTab
                    caption="Paddocks"
                    disabled
                    activeTab={activePropertyTab}
                    toggle={this.toggleTab}
                    tabId="paddocks"
                  />

                  <FormTab
                    caption="Property Area"
                    activeTab={activePropertyTab}
                    toggle={this.toggleTab}
                    tabId="usages"
                  />
                  <FormTab
                    caption="Rainfall"
                    activeTab={activePropertyTab}
                    toggle={this.toggleTab}
                    tabId="rainfalls"
                  />
                  <FormTab
                    caption="Enterprise Allocations"
                    activeTab={activePropertyTab}
                    toggle={this.toggleTab}
                    tabId="allocations"
                  />
                  <FormTab
                    caption="Analysis Groups"
                    activeTab={activePropertyTab}
                    toggle={this.toggleTab}
                    tabId="groups"
                  />
                  <FormTab
                    caption="Analysis Periods"
                    activeTab={activePropertyTab}
                    toggle={this.toggleTab}
                    tabId="periods"
                  />
                </Nav>
              </Col>
              <Col className="h-100">
                <TabContent activeTab={activePropertyTab} className="h-100">
                  <TabPane tabId="profile" className="mb-1 h-100">
                    <PropertyProfile
                      history={this.props.history}
                      property={data}
                      refresh={refresh}
                    />
                  </TabPane>
                  <TabPane tabId="lots" className="mb-2 h-100">
                    {/* <PropertyLots onMapLoad={this.onMapLoad} property={data} property_id={id} /> */}
                  </TabPane>
                  <TabPane tabId="paddocks" className="mb-2 p-3 h-100">
                    <PropertyPaddocks />
                  </TabPane>
                  <TabPane tabId="usages" className="mb-2 p-2 h-100">
                    <PropertyUsages property={data} />
                  </TabPane>
                  <TabPane tabId="rainfalls" className="mb-2 p-2 h-100">
                    <PropertyRainfalls property={data} />
                  </TabPane>
                  <TabPane tabId="allocations" className="mb-2 p-2 h-100">
                    <PropertyAllocations property_id={id} toggleTab={this.toggleTab} />
                  </TabPane>
                  <TabPane tabId="groups" className="mb-2 p-2 h-100">
                    <PropertyAnalysisGroups propertyId={id} onRefresh={this.onRefresh} />
                  </TabPane>
                  <TabPane tabId="periods" className="mb-2 p-2 h-100">
                    <PropertyAnalysisPeriods propertyId={id} onRefresh={this.onRefresh} />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ auth, clients, farmportrait_portal, profile, realm }) => ({
  auth,
  clients,
  portal: farmportrait_portal,
  profile,
  realm,
});

export default connect(mapStoreToProps)(withContainerError(Property));
