import React from 'react';
import { connect } from "react-redux";
import {
  Button
} from 'reactstrap';
import SpinnerButton from "../../../core/form/components/SpinnerButton";
import FormBase from "../../../core/form/components/FormBase";
import TextDuration from '../../../core/form/components/TextDuration';

// WAITING ON PIPELINES
// import {
//   fetchSubSamples,
//   fetchAreaRoundStatus
// } from '../actions';

import {
  executePipeline,
  fetchPipelineDocument,
  removePipelineDocuments,
  updatePipeline
} from '../../../core/pipeline/actions';
import PipelineSubsamplesLsv from '../components/PipelineSubsamplesLsv';


class PipelinePanelSubsampling extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      data: {}
    })

    this.executeSubsampling = this.executeSubsampling.bind(this);
    this.resetSubSampling = this.resetSubSampling.bind(this);
  }

  componentDidUpdate() {
    let {
      data
    } = this.state;

    if (data.id !== this.props.round_id) {
      data.id = this.props.round_id;

      this.setState({
        data
      })
    }

    const pipeline = this.props.pipeline.subsampling;
    if (pipeline?.status_key && pipeline?.status_key !== data.status_key) {
      data.status_key = pipeline.status_key;
      if (pipeline.status_key === 'completed') this.props.dispatch(fetchPipelineDocument(pipeline.id, 'lr_subsample_selection'));
      this.setState({
        data
      })
    }
  }

  async executeSubsampling() {
    // const {
    //   data
    // } = this.state;

    let is_valid = true;
    // TEMPORARILY DISABLING THIS, NEED TO DO A PIPELINE CHECK FOR DEPENDENCIES AS WELL AS ROUND STATUS. API SIDE QUERY

    // const round_status = await this.props.dispatch(fetchAreaRoundStatus(data.id));
    // let responseStatusString = [`Required sites are ${round_status.required_sites.count}`]
    // for (let status in round_status) {
    //   if (!round_status[status].is_valid) {
    //     is_valid = false;
    //     responseStatusString.push(`${status}: ${round_status[status].count}`)
    //   }
    // }

    if (is_valid) {
      const pipeline = this.props.pipeline.subsampling;
      const confirmed = window.confirm(`This will remove all previous subsampling results permanently and rerun the Subsampling script. Continue?`);
      if (confirmed) {
        // Remove all related workflow documents
        await this.props.dispatch(removePipelineDocuments(pipeline.id));
        this.props.dispatch({ type: 'REMOVE_PIPELINE_DOCUMENTS_FULFILLED', payload: { pipeline_id: pipeline.id } });
        this.props.dispatch(executePipeline(pipeline.id))
      }
    }
    else {
      // this.props.dispatch({ type: 'FETCH_SUBSAMPLES_REJECTED', payload: `Invalid round status count for executing script. ${responseStatusString.join(', ')}` });
    }
  }

  async resetSubSampling() {
    const pipeline = this.props.pipeline.subsampling;
    const confirmed = window.confirm(`WARNING: This will reset the pipeline. Only reset the pipeline if you believe the service has failed or is not running. Continue?`);
    if (confirmed) {
      // Update Workflow
      this.props.dispatch(updatePipeline(pipeline.id, {
        status_key: 'new',
        last_message: 'Pipeline reset manually'
      }));
    }
  }

  render() {
    const {
      executePipelineTag
    } = this.props;

    const {
      documents
    } = this.props.pipelines;

    // Find pipeline for current round
    const pipeline = this.props.pipeline.subsampling;
    const document = documents.find(document => document.pipeline_id === pipeline.id && document.key === 'lr_subsample_selection')

    let disabled = false;
    let message = pipeline?.last_message ?? '-'
    if (pipeline?.status_key?.toUpperCase() === 'DISABLED') {
      disabled = true;
      message = 'Subsampling has been disabled for this round';
    }

    return (
      <div>
        <div className="d-flex justify-content-end bg-light p-1 mb-2 rounded">
          <small className={`text-${pipeline?.statusClass ?? 'success'} mt-1 mr-2`}>{message}</small>
          {!disabled && (
            <React.Fragment>
              <TextDuration
                className={`mt-1 mr-2`}
                start={pipeline?.started}
                end={pipeline?.completed}
              />
              <SpinnerButton
                buttonColor="success"
                className="ml-2 mr-2"
                onClick={this.executeSubsampling}
                isSpinning={pipeline?.isSpinning}
                caption="Execute Script"
                disabled={executePipelineTag ? !this.checkAccess(executePipelineTag) : true}
              />
              <Button
                color='danger'
                onClick={this.resetSubSampling}
                className="ml-2 mr-2"
                size="sm"
                disabled={!pipeline?.isSpinning ?? true}
              >Reset</Button>
            </React.Fragment>
          )}
        </div>

        {!pipeline?.isSpinning && (
          <PipelineSubsamplesLsv rows={document?.document || []} pipeline={pipeline} />
        )}
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    pipelines: store.pipelines,
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(PipelinePanelSubsampling);
