import { Controls } from 'jsx/components/core/form/components/FormBuilder';

export type periodKeys =
  | 'id'
  | 'period_type_id'
  | 'property_id'
  | 'report_submission_date'
  | 'report_extension_date'
  | 'start_date'
  | 'end_date';

export const periodsForm: Controls<periodKeys> = {
  id: {
    name: 'id',
    path: 'id',
    value: '',
    type: 'hidden',
    validationRules: {},
  },
  period_type_id: {
    name: 'period_type_id',
    path: 'period_type_id',
    caption: 'Name',
    value: '',
    type: 'select',
    options: [],
    validationRules: {
      required: true,
    },
  },
  property_id: {
    name: 'property_id',
    path: 'property_id',
    value: '',
    type: 'hidden',
    validationRules: {},
  },
  report_submission_date: {
    name: 'report_submission_date',
    path: 'report_submission_date',
    caption: 'Report Due Date',
    value: '',
    type: 'date',
    validationRules: {},
  },
  report_extension_date: {
    name: 'report_extension_date',
    path: 'report_extension_date',
    caption: 'Report Extension Date',
    value: '',
    type: 'date',
    validationRules: {},
  },
  start_date: {
    name: 'start_date',
    path: 'start_date',
    caption: 'Start Date',
    value: '',
    type: 'date',
    validationRules: {},
  },
  end_date: {
    name: 'end_date',
    path: 'end_date',
    caption: 'End Date',
    value: '',
    type: 'date',
    validationRules: {},
  },
};
