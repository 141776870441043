import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Progress } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

import { initControls, updateControlOptions, saveControls } from '../lib/validateForm';
import FormBase from './FormBase';
import {
  CarbonizerFormProvider,
  FormConsumer,
  RenderInputs,
  prepareForSubmission,
} from './FormBuilder';
import { migrateControlsFromLegacyFormat } from './GenericLsvAdapter';

class DownloadModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      downloading: false,
      progressValue: 0,
      progressText: '',
      title: 'Download',
      type: '',
      data: {},
      controls: {},
      setModal: noop,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.animateProgress = this.animateProgress.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
  }

  componentDidUpdate() {
    const { isOpen } = this.state;
    const { title } = this.props;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      const updatedControls = initControls(this.props.controls);

      this.setState({
        isOpen: this.props.isOpen,
        title,
        controls: updatedControls,
        data: {},
        setModal: this.props.setModal,
      });
    }
  }

  updateProgress(value) {
    let updatedValue = String(value);
    this.setState({ progressText: updatedValue });
    if (value.endsWith('%')) {
      updatedValue = parseFloat(updatedValue.slice(0, -1));
      this.setState({ progressValue: updatedValue });
    } else {
      this.setState({ progressValue: 100 });
    }
  }

  animateProgress(delay) {
    const interval = setInterval(() => {
      const { progressText } = this.state;

      if (progressText.startsWith('Preparing')) {
        let text = 'Preparing';
        if (progressText.endsWith('...')) text += '.';
        else if (progressText.endsWith('..')) text += '...';
        else text += '..';

        this.setState({
          progressText: text,
        });
      } else clearInterval(interval);
    }, delay);
  }

  async onSubmit(formData) {
    const { controls } = this.state;

    const requestParams = prepareForSubmission(formData, controls);

    if (this.props.asyncDownload) {
      await this.props.onSubmit(requestParams);
    } else {
      this.setState({
        progressValue: 100,
        progressText: 'Preparing...',
        downloading: true,
      });
      this.animateProgress(1000);
      const response = await this.props.onSubmit(this.updateProgress, formData);

      if (response) {
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'placeholder.pdf';
        if (contentDisposition) {
          filename = contentDisposition.split('=')[1];
        }

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.setState({
        progressValue: 0,
        downloading: false,
      });
    }

    this.onCancel();
  }

  onCancel() {
    this.state.setModal();
    this.setState({
      isOpen: false,
      type: '',
      data: {},
      controls: {},
    });
  }

  render() {
    const { controls, isOpen, title, downloading, progressValue, progressText } = this.state;

    const newControls = migrateControlsFromLegacyFormat(controls);

    const iconName = 'cloud-arrow-down';
    return (
      <Modal isOpen={isOpen}>
        <CarbonizerFormProvider controls={newControls}>
          <FormConsumer isOpen={isOpen}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <ModalHeader className="bg-corporate text-white">
                  <Icon size="1x" name={iconName} className="mr-2" />
                  {title}
                </ModalHeader>
                <ModalBody>
                  <RenderInputs />
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end">
                  {downloading && (
                    <Progress
                      className={'m-1 text-center w-100 thick'}
                      animated
                      value={progressValue}
                    >{`${progressText}`}</Progress>
                  )}
                  {!downloading && (
                    <div>
                      <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
                        Cancel
                      </Button>
                      <Button size="sm" color="success" type="submit">
                        Download
                      </Button>
                    </div>
                  )}
                </ModalFooter>
              </form>
            )}
          </FormConsumer>
        </CarbonizerFormProvider>
      </Modal>
    );
  }
}

export default connect(null)(DownloadModal);
