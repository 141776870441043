import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  transaction_interval_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_interval_id',
    fieldName: 'transaction_interval.name',
    type: 'select',
    caption: 'Transaction Interval',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    fieldName: 'transaction_date',
    caption: 'Transaction Date',
    type: 'date',
    validationRules: {
      isRequired: true,
    },
    showInListview: false,
    showInEditor: true,
  },
  transaction_month: {
    ...controlDefaults,
    name: 'transaction_month',
    fieldName: 'transaction_date',
    caption: 'Month',
    type: 'date-month',
    showInEditor: false,
    showInListview: true,
    sortColumn: 'created',
  },
  transaction_type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_type_id',
    fieldName: 'transaction_type_id',
    caption: 'Transaction Type',
    type: 'select',
    includeEmptyOption: true,
    validationRules: {
      isRequired: true,
    },
    showInListview: false,
    showInEditor: true,
  },
  asset_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'asset_id',
    fieldName: 'asset_id',
    caption: 'Asset',
    type: 'select',
    includeEmptyOption: true,
    validationRules: {
      isRequired: true,
    },
    showInListview: false,
    showInEditor: true,
  },
  asset: {
    ...controlDefaults,
    name: 'asset',
    fieldName: 'asset.name',
    caption: 'Asset',
    showInListview: true,
    showInEditor: false,
  },
  transaction_type: {
    ...controlDefaults,
    name: 'transaction_type',
    fieldName: 'transaction_type.name',
    caption: 'Transaction Type',
    showInListview: true,
    showInEditor: false,
  },
  adjustment_type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'adjustment_type_id',
    fieldName: 'adjustment_type.name',
    type: 'select',
    caption: 'Adjustment Type',
    validationRules: { isRequired: true },
    showInEditor: true,
  },
  target_direct_cost_type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'target_direct_cost_type_id',
    fieldName: 'target_direct_cost_type_id.name',
    type: 'select',
    caption: 'Direct Cost Type',
    includeEmptyOption: true,
    validationRules: { isRequired: true },
    showInEditor: true,
  },
  target_enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'target_enterprise_id',
    fieldName: 'target_enterprise.name',
    type: 'select',
    caption: 'Target Enterprise',
    includeEmptyOption: true,
    validationRules: { isRequired: true },
    showInEditor: true,
  },
  notes: {
    ...controlDefaults,
    name: 'notes',
    fieldName: 'notes',
    caption: 'Notes',
    type: 'textarea',
    validationRules: { isRequired: true },
    showInListview: false,
    showInEdit: true,
  },
  amount: {
    ...controlDefaults,
    name: 'amount',
    fieldName: 'amount',
    caption: 'Amount',
    type: 'number',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeDecimals: true,
      includeDollarSign: true,
      includeCommas: true,
    },
    showInListview: true,
    showInEditor: false,
  },
};
