import { noop } from 'lodash';
import React from 'react';
import { Alert, Button } from 'reactstrap';

const LaboursToolbar = ({ onAddNew = noop, filterClick = noop }) => {
  return (
    <Alert color="primary" className="d-flex justify-content-end m-2">
      <Button onClick={onAddNew} size="sm" color="success">
        Add Labour
      </Button>
      <Button
        id="toggler"
        size="sm"
        color="warning"
        className="text-white ml-2"
        onClick={filterClick}
      >
        Filter
      </Button>
    </Alert>
  );
};

export default LaboursToolbar;
