import React from 'react';
import Listview from '../../../core/form/components/Listview';
import { HollowDotsSpinner } from 'react-epic-spinners';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';

import TimeFormat from '../lib/timeFormat';
import { noop } from 'lodash';

const TimekeeperTimesheetsLsv = (props) => {
  const { onEdit = noop, rows, ignoreFields, customFields } = props;

  const format = new TimeFormat();

  const renderRows = (headers, record) => {
    const costcode_name = record.costcode.name;
    const clientorg_name = record.costcode.client_org.name;

    let phasecode_name = record.costcode.costcode;
    let job_phase;
    if (record.phasecode) {
      phasecode_name = record.phasecode.description;
      job_phase = `${record.costcode.jobno}/${record.phasecode.phase_code}`;
    }

    // Dates
    const from_date = moment(record.from_date).format(format.day);

    // Times
    const from_time = moment(record.from_date).format(format.time);
    const to_time = record.to_date ? moment(record.to_date).format(format.time) : '';
    const time = `${from_time} - ${to_time}`;

    let statusIndicator = <Icon name="check" className="mr-2 text-success" />;
    if (!record.to_date) {
      statusIndicator = <HollowDotsSpinner className="mt-2" color="#cc0000" size={5} />;
    }

    let comments = record.comments;

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'client':
          return (
            <td key={index} className={header.classes} style={header.style}>
              {clientorg_name}
            </td>
          );
        case 'from_date':
          return (
            <td key={index} className={header.classes} style={header.style}>
              {from_date}
            </td>
          );
        case 'week_day':
          return (
            <td key={index} className={header.classes} style={header.style}>
              {moment(record.from_date).format(format.week)}
            </td>
          );
        case 'net_hours':
          return (
            <td key={index} className={header.classes} style={header.style}>
              {format.asString(record[header.field])}
            </td>
          );
        case 'time':
          return (
            <td key={index} className={header.classes} style={header.style}>
              {time}
            </td>
          );
        case 'job_phase':
          return (
            <td key={index} className={header.classes} style={header.style}>
              {job_phase}
            </td>
          );
        case 'costcode_name':
          return (
            <td key={index} className={header.classes} style={header.style}>
              {costcode_name}
            </td>
          );
        case 'phasecode_name':
          return (
            <td key={index} className={header.classes} style={header.style}>
              {phasecode_name}
            </td>
          );
        case 'comments':
          return (
            <td key={index} className={header.classes} style={header.style}>
              {comments}
            </td>
          );
        case 'user_name':
          return (
            <td key={index} className={header.classes} style={header.style}>
              {record.user.name}
            </td>
          );
        case 'status_indicator':
          return (
            <td key={index} className={header.classes} style={header.style}>
              {statusIndicator}
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes} style={header.style}>
              {record[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const iconName = 'calendar';
  const emptyCaption = 'No timesheets found for this week';

  let headers = [
    { caption: 'From', field: 'from_date', classes: 'text-left', style: {width: 90}},
    { caption: 'Time', field: 'time', classes: 'text-left', style: {width: 100} },
    { caption: 'Break', field: 'break_hours', classes: 'text-right', style: { width: 50 } },
    { caption: '#', field: 'status_indicator', classes: 'text-left', style: {width: 40} },
    { caption: 'Week Day', field: 'week_day', classes: 'text-left', style: {width: 80} },
    { caption: 'Job/Phase', field: 'job_phase', classes: 'text-left', style: {width: 120} },
    { caption: 'Job Description', field: 'costcode_name', classes: 'text-left', style: {width: 250} },
    { caption: 'Phase Description', field: 'phasecode_name', classes: 'text-left', style: { width: 250 } },
    { caption: 'Comments', field: 'comments', classes: 'text-left' },
    { caption: 'Jira Key', field: 'jira_key', classes: 'text-left', style: { width: 80 } },
    { caption: 'User', field: 'user_name', classes: 'text-left', style: { width: 100 } },
    { caption: 'Hours', field: 'net_hours', classes: 'text-right', style: { width: 60 } }
  ]

  // Ignore fields if defined
  if (ignoreFields?.length > 0) headers = headers.filter(header => !ignoreFields.includes(header.field) );

  // Customise header outside this component. Sort according to order of header fields in array.
  if (customFields?.length > 0) {
    headers = customFields.map(field => {
      return headers.find(header => header.field === field);
    })
  }

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  )

  let tableBodyTr = (<tr></tr>);
  let rowsCount;

  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => (
      <tr key={index} onClick={() => (onEdit ? onEdit(row.id, false) : null)}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <div className="b">
      <Listview
        rows={rows}
        rowsCount={rowsCount}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </div>
  );
};

export default TimekeeperTimesheetsLsv;
