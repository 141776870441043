import { cloneDeep, isEmpty, isNil } from 'lodash';

const prefix = 'farmeye';

export const setFarmEyePreferences = (keyPrefix = null, preferences = []) => {
  if (preferences.length === 0) return;
  const cookies = cloneDeep(document.cookie.split('; '));

  preferences.forEach(({ key, value }) => {
    const fullPrefix = keyPrefix ? `${prefix}_${keyPrefix}_${key}` : `${prefix}_${key}`;
    // Remove existing cookie if exists
    const existingCookie = cookies.find((cookie) => cookie.startsWith(fullPrefix));
    if (existingCookie) document.cookie = `${fullPrefix}=; Max-Age=0; path=/`;

    document.cookie = `${fullPrefix}=${value}`;
  });
};

export const getFarmEyePreferences = (key = null) => {
  const cookies = document.cookie.split('; ');
  const preferences = {};
  if (isNil(cookies) || isEmpty(cookies)) return preferences;

  cookies.forEach((cookie) => {
    const [name, value] = cookie.split('=');
    // Get all preferences
    if (!key) {
      preferences[name] = value;
      return;
    }

    if (name.startsWith(`${prefix}_${key}`)) {
      preferences[name] = value;
    }
  });

  return preferences;
};
