import React from 'react';

import Listview from "../../../core/form/components/Listview";
import Icon from 'jsx/components/core/icons/Icon';

const ActivitiesLsv = (props) => {
  const {
    rows
  } = props;

const renderRows = (headers, row) => {
    let iconName;
    let iconColour = "mr-3 "

    switch (row.type) {
      case 'comment':
        iconName = "comment";
      break;
      case 'update':
        iconName = "edit";
      break;
      default: break;
    }

    switch (row.level) {
      case 'info':
        iconColour += "text-primary";
      break;
      case 'warning':
        iconColour += "text-warning";
      break;
      case 'important':
        iconColour += "text-danger";
      break;
      default:
        iconColour += "text-success";
      break;
    }

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        default: return  (
          <td className={`${iconColour} ${header.classes}`} key={index}>{(index === 0 ? <Icon name={iconName} className={iconColour} /> : null)}{row[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const iconName = 'comment';
  const emptyCaption = "No Cores found"
  const headers = [
    {caption: 'Comment', field: 'comment', classes:"text-left"},
    {caption: 'Created By', field: 'created_by', classes:"text-left"},
    {caption: 'Created', field: 'created', classes:"text-left"},
    {caption: 'Type', field: 'type', classes:"text-left"},
    {caption: 'Level', field: 'level', classes:"text-left"},
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => {}} style={{cursor: "pointer"}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  return (
    <div>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </div>
  )
}

export default ActivitiesLsv;
