import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    params: {
      search_value: '',
    },
    responseMessage: null,
    shifts: { count: 0, rows: [] },
    shiftSlots: { count: 0, rows: [] },
    shiftAllocations: { count: 0, rows: [] },
  },
  action,
) {
  const types = ['ROSTER_SHIFT'];

  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_ROSTER_SHIFTS_FULFILLED': {
      return {
        ...updatedState,
        shifts: action.payload,
      };
    }
    case 'FETCH_ROSTER_SHIFT_SLOTS_FULFILLED': {
      return {
        ...updatedState,
        shiftSlots: action.payload,
      };
    }
    case 'FETCH_ROSTER_SHIFT_ALLOCATIONS_FULFILLED': {
      return {
        ...updatedState,
        shiftAllocations: action.payload,
      };
    }
    case 'SET_ROSTER_SHIFT_SEARCH_VALUE': {
      return {
        ...updatedState,
        params: {
          ...updatedState.params,
          search_value: action.payload,
        },
      };
    }
    case 'SET_ROSTER_SHIFT_SLOTS': {
      return {
        ...updatedState,
        shiftSlots: action.payload,
      };
    }
    case 'UNSET_ROSTER_SHIFT_SLOTS': {
      return {
        ...updatedState,
        shiftSlots: { count: 0, rows: [] },
      };
    }
    default:
      break;
  }

  return updatedState;
}
