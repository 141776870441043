import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiDirectCosts } from '../../../../constants/api';

export function fetchDirectCosts(params) {
  return async (dispatch) => await get(dispatch, `FETCH_DIRECT_COSTS`, `${apiDirectCosts}`, params);
}

export function fetchDirectCost(params) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_DIRECT_COST', `${apiDirectCosts}/${params.id}`, params);
}

export function createDirectCost(data) {
  return async (dispatch) => await post(dispatch, `CREATE_DIRECT_COST`, `${apiDirectCosts}`, data);
}

export function updateDirectCost(data) {
  return async (dispatch) =>
    await put(dispatch, 'UPDATE_DIRECT_COST', `${apiDirectCosts}/${data.id}`, data);
}

export function removeDirectCost(id) {
  return async (dispatch) =>
    await remove(dispatch, 'REMOVE_DIRECT_COST', `${apiDirectCosts}/${id}`);
}
