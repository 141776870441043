import React from 'react';
import Listview from "../../core/form/components/Listview";
import PageTitleH5 from "../../core/form/components/PageTitleH5";

const RolesLsv = (props) => {
  const {
    rows
  } = props;

  const renderRows = (headers, user) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        default: return  (
          <td key={index} className={header.classes}>{user[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const headers = [
    {caption: 'Name', field: 'name', classes:'text-left'},
    {caption: 'Description', field: 'description', classes:'text-left'}
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='user-tag';
  const title = 'Roles';
  const emptyCaption='No Roles found';

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between border-bottom border-corporate bg-light p-1 rounded">
        <PageTitleH5 iconName={iconName} title={title} />
      </div>

      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
}

export default RolesLsv;
