import React from 'react';
import Comments from 'jsx/components/core/form/components/Comments';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Input,
} from 'reactstrap';
import FormTab from 'jsx/components/core/form/components/FormTab';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from 'jsx/components/core/form/components/FormInput';
import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';
import { cloneDeep, orderBy } from 'lodash';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import { GenericModal } from 'jsx/components/core/form/components/GenericModal';
import { updateControlOptions } from 'jsx/components/core/form/lib/validateForm';

import { createComment, removeComment, updateComment } from '../actions/job_comments';

export class AssetWorkActivitiesModal extends GenericModal {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      comment: null,
      showAddComment: false,
      isCommentEditMode: false,
      editedCommentId: null,
      jobId: null,
      projectId: null,
    };

    this.filterPhasecodes = this.filterPhasecodes.bind(this);

    this.onAssigneeChange = this.onAssigneeChange.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
    this.onCommentDelete = this.onCommentDelete.bind(this);
    this.onCommentUpdate = this.onCommentUpdate.bind(this);
    this.onCommentUpdateCancel = this.onCommentUpdateCancel.bind(this);
    this.onCommentUpdateSave = this.onCommentUpdateSave.bind(this);
    this.onCommentSave = this.onCommentSave.bind(this);
    this.onPhasecodeChange = this.onPhasecodeChange.bind(this);
    this.onProjectChange = this.onProjectChange.bind(this);
    this.onSave = this.onSave.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleShowAddComment = this.handleShowAddComment.bind(this);

    this.setEditMode = this.setEditMode.bind(this);
    this.setOptions = this.setOptions.bind(this);

    this.toggleTab = this.toggleTab.bind(this);
    this.toggleTabAndClearEditMode = this.toggleTabAndClearEditMode.bind(this);
  }

  handleClose() {
    this.setState({ isCommentEditMode: false });
    this.props.dispatch({ type: 'SET_WORKACTIVITY_TAB', payload: 'activity_tab' });
    this.onClose();
  }

  async handleSave(reopen) {
    this.setState({ isCommentEditMode: false });
    this.props.dispatch({ type: 'SET_WORKACTIVITY_TAB', payload: 'activity_tab' });
    await this.onSave({});

    if (reopen) {
      const projectId = this.state?.controls?.project_id?.value;
      await this.props.setModal(true, 'workactivities', null);
      await this.props.copySelections(projectId);
    }
  }

  handleRemove() {
    this.setState({ isCommentEditMode: false });
    this.props.dispatch({ type: 'SET_WORKACTIVITY_TAB', payload: 'activity_tab' });
    this.onRemove();
  }

  onCommentUpdate({ target }) {
    const comment = { ...this.state.comment };
    comment.comment = target.value;
    this.setState({ comment });
  }

  onCommentUpdateSave(event) {
    event.preventDefault();
    const { comment, id, job_id } = this.state.comment;
    const data = {};
    data.comment = comment;
    data.id = id;
    data.job_id = job_id;
    this.props.dispatch(updateComment(data));
    this.setState({ isCommentEditMode: false });
    this.props.setModal(true, 'workactivities', job_id);
  }

  onCommentDelete(job_id, id) {
    const confirmed = window.confirm('This will remove the comment permanently. Continue?');
    if (confirmed) {
      this.props.dispatch(removeComment(job_id, id));
      this.props.setModal(true, 'workactivities', job_id);
    }
  }

  onCommentUpdateCancel() {
    this.setState({ isCommentEditMode: false });
  }

  setEditMode(job_id = null, id = null, currentComment = null) {
    let comment = {};
    let editedCommentId;

    if (job_id && id && currentComment) {
      comment = {
        id,
        job_id,
        comment: currentComment,
      };
      editedCommentId = id;
    }

    this.setState({
      isCommentEditMode: !this.state.isCommentEditMode,
      comment,
      editedCommentId,
      showAddComment: false,
    });
  }

  toggleTabAndClearEditMode(tab) {
    this.setState({ isCommentEditMode: false });
    this.toggleTab(tab);
  }

  toggleTab(tab) {
    this.setState({ isCommentEditMode: false });
    this.props.dispatch({ type: 'SET_WORKACTIVITY_TAB', payload: tab });
  }

  handleShowAddComment() {
    this.setState({ showAddComment: !this.state.showAddComment });
  }

  onCommentChange({ target }) {
    this.setState({ comment: target.value });
  }

  async onCommentSave(id) {
    const { comment } = this.state;

    const jobId = id || this.state.jobId;

    const data = { comment, job_id: jobId };

    await this.props.dispatch(createComment(data));
    this.props.setModal(true, 'workactivities', jobId);
    this.setState({ comment: null, showAddComment: false, jobId });
  }

  setOptions(controls) {
    const { controlOptions } = this.props;
    let updatedControls = cloneDeep(controls);

    let { options } = updatedControls;
    Object.keys(updatedControls).forEach((key) => {
      if (controlOptions && controlOptions[key]) {
        options = controlOptions[key];
      }

      if (updatedControls[key].excludeFromSetOptions) {
        updatedControls[key].options = options;

        if (updatedControls[key].value === null) {
          updatedControls[key].value = controls[key].options[0]?.id ?? null;
        }

        return;
      }

      updatedControls = updateControlOptions(controls, key, options);
    });

    return updatedControls;
  }

  onAssigneeChange(option) {
    const { controls } = this.state;
    const value = option ? option.value : ''; // No option is supplied when clearing select field

    controls.assignedto_id.value = value === '' ? null : value;

    this.setState({ controls });
  }

  onProjectChange({ value }) {
    const { controls } = this.state;
    controls.project_id.value = value;

    const phasecodes = this.filterPhasecodes(value);
    controls.phasecode_id.value = phasecodes.length > 0 ? phasecodes[0].id : null;

    this.setState({ controls });
  }

  onPhasecodeChange({ value = null }) {
    const { controls } = this.state;
    controls.phasecode_id.value = value;
    this.setState({ controls });
  }

  renderSelectedAsignee() {
    const { controls } = this.state;
    if (controls.assignedto_id.value) {
      const asignee = controls.assignedto_id.options.find(
        (option) => option.id === controls.assignedto_id.value,
      );
      return { value: asignee.id, label: `${asignee.firstname} ${asignee.lastname}` };
    }

    return { value: null, label: 'Unassigned' };
  }

  async onSave() {
    const { controls } = this.state;

    /** Check if phasecode is required from selected job type. */
    controls.phasecode_id.validationRules.isRequired =
      AssetWorkActivitiesModal.checkIsPhasecodeRequired(controls.type_id);

    this.setState({ controls });

    await super.onSave({});
  }

  filterPhasecodes(project_id) {
    const { phasecode_id: options } = this.props.controlOptions;
    return options?.filter(({ project_id: id }) => id === project_id) ?? [];
  }

  static checkIsPhasecodeRequired(typeIdControl) {
    const { value, options } = typeIdControl;

    /** Base check, phasecode not required if no job type selected. */
    if (!value) return false;

    return options.find(({ id, rules }) => id === value && rules.requirePhasecode);
  }

  render() {
    let { controls } = this.state;
    const {
      isOpen,
      title,
      isNew,
      description,
      showAddComment,
      isCommentEditMode,
      comment,
      editedCommentId,
    } = this.state;

    const { jobComments, jobId, iconName } = this.props;

    const comments = jobComments?.rows;
    const hasComments = comments && comments.length > 0;
    const latestComments = orderBy(comments, ['created'], ['desc']).slice(0, 1);
    const { workActivityActiveTab, responseMessage } = this.props.wipstar;

    const { currentUser } = this.props.profile;
    const currentUserId = currentUser.id;
    const currentlyEditedComment = comment && comment.id === editedCommentId;

    controls = this.setOptions(controls);
    controls.phasecode_id.options = this.filterPhasecodes(controls.project_id.value);

    if (controls.phasecode_id.options.length === 0) controls.phasecode_id.value = null;

    /** Disable type_id options for the MVP */
    const disabledTypeOptions = controls?.type_id?.options.filter(({ id }) => id !== null);
    controls.type_id.options = disabledTypeOptions;

    const renderWorkActivityNo = () => (
      <Col>
        <Label className="mt-2">Work Activity #</Label>
        <div className="border border-gray text-secondary rounded pl-3">
          {controls?.code?.value}
        </div>
      </Col>
    );

    const renderEditComment = () => (
      <FormGroup>
        <Label for="editComment">Edit Comment</Label>
        <Input
          type="textarea"
          name="comment"
          id="editComment"
          onChange={this.onCommentUpdate}
          value={comment.comment}
          rows={4}
        />
        <div className="d-flex justify-content-end">
          <Button
            color="secondary"
            size="sm"
            className="mt-3 "
            onClick={this.onCommentUpdateCancel}
          >
            Cancel
          </Button>
          <Button
            color="success"
            size="sm"
            className="mt-3 ml-1"
            onClick={this.onCommentUpdateSave}
          >
            Save
          </Button>
        </div>
      </FormGroup>
    );

    const renderAddComment = () => (
      <Row>
        <Col className="bg-light ml-4 mr-3 mb-3 p-3">
          <FormGroup>
            <Label for="exampleText">Add New Comment</Label>
            <Input
              type="textarea"
              name="comment"
              id="newComment"
              onChange={this.onCommentChange}
              rows={4}
            />
            <div className="d-flex justify-content-end">
              <Button
                color="secondary"
                size="sm"
                className="mt-3 mr-1"
                onClick={this.handleShowAddComment}
              >
                Cancel
              </Button>
              <Button
                color="success"
                size="sm"
                className="mt-3"
                onClick={() => this.onCommentSave(jobId)}
              >
                Save
              </Button>
            </div>
          </FormGroup>
        </Col>
      </Row>
    );

    return (
      <Modal isOpen={isOpen} className="w60-modal">
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon size="1x" name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Nav tabs className="mt-2">
            <FormTab
              iconRightClass="primary"
              caption="Details"
              tabId="activity_tab"
              activeTab={workActivityActiveTab}
              toggle={() => this.toggleTab('activity_tab')}
            />
            {!isNew && (
              <FormTab
                iconRightClass="primary"
                caption="Comments"
                tabId="activity_comments"
                activeTab={workActivityActiveTab}
                toggle={() => this.toggleTab('activity_comments')}
              />
            )}
          </Nav>
          <TabContent activeTab={workActivityActiveTab} className="d-flex flex-column flex-grow-1">
            <TabPane tabId="activity_tab" className="mb-2 p-1 h-100">
              {description && <p>{description}</p>}
              <Row className="ml-0 mt-2 text-corporate">
                <Col>
                  <h5>Activity</h5>
                </Col>
              </Row>
              <Row className="bg-light m-0">
                <Col sm={9}>
                  <FormInput handleChange={this.handleChange} control={controls.name} />
                </Col>
                {!isNew && renderWorkActivityNo()}
              </Row>
              <Row className="bg-light m-0  pb-2">
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.description} />
                </Col>
              </Row>
              <Row className="ml-0 mt-3 text-corporate">
                <Col>
                  <h5>Attributes</h5>
                </Col>
              </Row>
              <Row className="bg-light m-0 pb-2">
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.priority_id} />
                </Col>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.status_id} />
                </Col>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.type_id} />
                </Col>
              </Row>
              <Row className="bg-light m-0 pb-2 pt-2">
                <Col>
                  <FormInputSelect
                    control={controls.assignedto_id}
                    handleChange={this.onAssigneeChange}
                    isClearable={true}
                  />
                </Col>
                <Col>
                  <FormInputSelect
                    control={controls.project_id}
                    handleChange={this.onProjectChange}
                  />
                </Col>
                <Col>
                  <FormInputSelect
                    control={controls.phasecode_id}
                    isDisabled={controls.project_id.value === null}
                    handleChange={this.onPhasecodeChange}
                  />
                </Col>
              </Row>
              {!isNew && (
                <Row className="ml-0 mt-3 text-corporate">
                  <Col>
                    <h5>Latest Comment</h5>
                  </Col>
                </Row>
              )}
              {!isNew && !isCommentEditMode && (
                <Comments
                  currentUserId={currentUserId}
                  comments={latestComments}
                  setEditMode={this.setEditMode}
                  isCommentEditMode={isCommentEditMode}
                  onCommentDelete={this.onCommentDelete}
                />
              )}
              {!isNew && isCommentEditMode && currentlyEditedComment && renderEditComment()}
              {!isNew && hasComments && (
                <Row>
                  <Col className="d-flex justify-content-end">
                    <small
                      role="presentation"
                      style={{ cursor: 'pointer' }}
                      className="text-primary mt-3"
                      onClick={() => this.toggleTabAndClearEditMode('activity_comments')}
                      onKeyDown={() => this.toggleTabAndClearEditMode('activity_comments')}
                    >
                      See All Comments
                    </small>
                  </Col>
                </Row>
              )}
            </TabPane>
            {!isNew && (
              <TabPane tabId="activity_comments" className="mb-2 p-1 h-100">
                <Row className="ml-0 mt-3">
                  <Col className="d-flex justify-content-end mb-3">
                    <Button color="primary" size="sm" onClick={this.handleShowAddComment}>
                      <Icon name="circle-plus" className="mr-2" />
                      Add New Comment
                    </Button>
                  </Col>
                </Row>
                {showAddComment && renderAddComment()}
                {!isNew && !isCommentEditMode && (
                  <Comments
                    currentUserId={currentUserId}
                    comments={comments}
                    workActivityActiveTab={workActivityActiveTab}
                    setEditMode={this.setEditMode}
                    isCommentEditMode={isCommentEditMode}
                    onCommentUpdateCancel={this.onCommentUpdateCancel}
                    onCommentDelete={this.onCommentDelete}
                  />
                )}
                {!isNew && isCommentEditMode && currentlyEditedComment && renderEditComment()}
              </TabPane>
            )}
          </TabContent>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center mb-3">
          <div>
            {workActivityActiveTab !== 'activity_comments' && (
              <>
                <Button
                  size="sm"
                  className="mr-2"
                  color="success"
                  onClick={() => this.handleSave(false)}
                >
                  Save
                </Button>

                <Button size="sm mr-2" color="primary" onClick={() => this.handleSave(true)}>
                  Save & Add
                </Button>
              </>
            )}
            <Button size="sm" color="light" onClick={this.handleClose}>
              Cancel
            </Button>
          </div>
          {!isNew && this.props.onRemove && workActivityActiveTab !== 'activity_comments' && (
            <Button size="sm" color="danger" onClick={this.handleRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ manage, wipstar, profile }) => ({
  manage,
  wipstar,
  profile,
});

export default connect(mapStoreToProps)(withContainerError(AssetWorkActivitiesModal));
