import { get, post, remove } from '../../../../lib/genericAction';

import { apiOrgPlantAssetReadings } from '../../../../constants/api';

export function fetchPlantAssetReadings(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_PLANT_ASSET_READINGS`, `${apiOrgPlantAssetReadings}`, params);
}

export function fetchPlantAssetReading(id) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_PLANT_ASSET_READING`, `${apiOrgPlantAssetReadings}/${id}`);
}

export function createPlantAssetReading(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_PLANT_ASSET_READING`, `${apiOrgPlantAssetReadings}`, data);
}

export function deletePlantAssetReading(id) {
  return async (dispatch) =>
    await remove(dispatch, `DELETE_PLANT_ASSET_READING`, `${apiOrgPlantAssetReadings}/${id}`);
}
