import React from 'react';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

const FormSlider = (props) => {
  const {
    max,
    min,
    value,
    onChange,
    onAfterChange,
    className,
    style,
    colour
  } = props;

  let trackBackground = '#83a326';
  let handleBorder = '#83a326';
  if (colour) {
    trackBackground = colour;
    handleBorder = colour;
  }
  const handleBackground = 'white';

  let railbackground = '#111111';
  if (style && style.railbackground) {
    railbackground = style.railbackground;
  }

  return (
    <React.Fragment>
      {Array.isArray(value) && (
        <Range
          className={className}
          max={max}
          min={min}
          value={value}
          onChange={onChange}
          onAfterChange={onAfterChange}
          trackStyle={[{backgroundColor: trackBackground}]}
          handleStyle={[{backgroundColor: handleBackground, borderColor: handleBorder, borderWidth: 2},{backgroundColor: handleBackground, borderColor: handleBorder, borderWidth: 2}]}
          railStyle={{backgroundColor: railbackground}}
        />
      )}
      {!Array.isArray(value) && (
        <Slider
          className={className}
          max={max}
          min={min}
          value={value}
          onChange={onChange}
          onAfterChange={onAfterChange}
          trackStyle={[{backgroundColor: trackBackground}]}
          handleStyle={[{backgroundColor: handleBackground, borderColor: handleBorder, borderWidth: 2}]}
          railStyle={{backgroundColor: railbackground}}
        />
      )}

    </React.Fragment>

  )
}

export default FormSlider;
