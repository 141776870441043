import { defaults } from '../../../../lib/genericReducer';
// Datasets are the arrays that will have data loaded into them and then used by Widgets. These definitions should eventually
// be moved into a analytics_datasets table and coupled with lookup_doc_definitions.

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    authorised: true,
    authResponseMessage: null,
    loadSamples: false,
    checksum: 0,
    datasets: {
      layers: [
        {
          id: 'mass',
          name: 'Carbon Mass (Whole) by Round/Property Round',
          data: [],
          default: 0,
          rowName: 'round_name',
          sortSelected: true,
          columns: [
            {
              name: 'whole_carbon_mass',
              caption: 'Carbon Mass t/ha',
              unit: ' t/ha',
              colour: '#8884d8',
            },
            { name: 'soil_mass', caption: 'Soil Mass t/ha', unit: ' t/ha', colour: 'red' },
          ],
        },
        {
          id: 'soilmass',
          name: 'Soil Mass by Core/Layer',
          data: [],
          default: 0,
          rowName: 'core_id',
          sortSelected: true,
          columns: [
            { name: 'carbon_mass', caption: 'Carbon Mass t/ha', unit: ' t/ha', colour: '#8884d8' },
            { name: 'soil_mass', caption: 'Soil Mass t/ha', unit: ' t/ha', colour: 'red' },
          ],
        },
        {
          id: 'core_layers',
          name: 'Core Carbon Stocks by Layers',
          data: [],
          default: 0,
          sortSelected: true,
          rowName: 'core_id',
          columns: [{ name: 'carbon_mass', caption: 'SOC t/ha', unit: ' t/ha', colour: 'red' }],
        },
        {
          id: 'model_performance',
          name: 'Local/Global Model Performance',
          data: [],
          default: 0,
          sortSelected: false,
          rowName: 'p',
          columns: [
            { name: 'a', caption: 'Local', unit: ' %', colour: 'red' },
            { name: 'p', caption: 'Global', unit: ' %', colour: 'blue' },
          ],
        },
      ],
      samples: [
        {
          id: 'splines_pred_carbon',
          name: 'Predicted Carbon Splines (1cm)',
          rowName: 'lower_depth',
          unit: '%',
          sortSelected: false,
          data: [],
          filterName: 'site.core_id',
          columns: [
            { name: 'soc', caption: 'Carbon (%)', unit: '%', colour: 'red' },
            { name: 'bulk_density', caption: 'Bulk Density (g/cc)', unit: 'g/cc', colour: 'red' },
          ],
        },
      ],
    },
    filters: {
      sites: {
        area_round_id: [],
      },
      ceas: {},
    },
    topFilter: {
      property_id: null,
      property_round_id: null,
      area_round_id: null,
    },
    selections: {
      sites: [],
      ceas: [],
    },
    depth: {},
    colours: [
      '#f66D9b',
      '#f6993f',
      '#4285f4',
      '#6574cd',
      '#34a853',
      '#9561e2',
      '#ea4335',
      '#000000',
      '#fbbc05',
      '#6cb2eb',
      '#38c172',
      '#8884d8',
      '#f6993f',
    ],
  },
  action
) {
  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'UNSET_SELECTIONS': {
      const selections = {
        sites: [],
        ceas: [],
      };

      return {
        ...state,
        selections: selections,
      };
    }
    case 'SET_DEPTH_FULLFILLED': {
      return {
        ...state,
        fetching: true,
        fetched: false,
        depth: action.payload,
      };
    }
    case 'FETCH_ESM_PENDING': {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case 'FETCH_ESM_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }
    case 'FETCH_ESM_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
      };
    }
    case 'SET_LOAD_SAMPLES': {
      return {
        ...state,
        loadSamples: action.payload,
      };
    }
    case 'SET_TOPFILTER_FULFILLED': {
      return {
        ...state,
        topFilter: action.payload,
      };
    }
    case 'SELECT_CHART_CELL_FULFILLED': {
      const { selections } = state;
      const { selectionKey } = action.payload;
      const { name } = action.payload;
      const idx = selections[selectionKey].findIndex((selection) => selection === name);
      if (idx > -1) {
        selections[selectionKey].splice(idx, 1);
      } else {
        if (action.payload.singleSelection) {
          selections[selectionKey] = [name];
        } else {
          selections[selectionKey].push(name);
        }
      }

      const checksum = new Date().getTime();
      return {
        ...state,
        loadSamples: true,
        selections: selections,
        checksum: checksum,
      };
    }
    case 'UPDATE_ANALYTICS_WIDGET_DATASET_FULFILLED': {
      const { widgets } = state;
      const widgetIdx = widgets.findIndex((widget) => widget.id === action.payload.widgetId);
      const widgetDatasetIdx = widgets[widgetIdx].datasets.findIndex(
        (dataset) => dataset.id === action.payload.widgetDatasetId
      );

      widgets[widgetIdx].datasets[widgetDatasetIdx].sourceId = action.payload.selectedDataset.id;
      widgets[widgetIdx].datasets[widgetDatasetIdx].data = action.payload.selectedDataset.data;
      widgets[widgetIdx].datasets[widgetDatasetIdx].rowName =
        action.payload.selectedDataset.rowName;
      [widgets[widgetIdx].datasets[widgetDatasetIdx].selected] =
        action.payload.selectedDataset.columns;

      return {
        ...state,
        widgets: widgets,
      };
    }
    case 'FILL_ANALYTICS_DATASET_FULFILLED': {
      const datasets = { ...state.datasets };
      const idx = datasets[action.payload.group].findIndex(
        (dataset) => dataset.id === action.payload.key
      );
      if (idx > -1) {
        datasets[action.payload.group][idx].data = action.payload.dataset || [];
      }

      const checksum = new Date().getTime();
      return {
        ...state,
        checksum: checksum,
        datasets: datasets,
      };
    }
    case 'SET_ANALYTICS_SITES_FILTER': {
      const filters = { ...state.filters };
      const idx = filters.sites.area_round_id.findIndex((filter) => filter === action.payload.id);
      if (idx > -1) {
        filters.sites.area_round_id.splice(idx, 1);
      } else {
        filters.sites.area_round_id.push(action.payload.id);
      }

      return {
        ...state,
        filters: filters,
      };
    }
    default:
      break;
  }

  return state;
}
