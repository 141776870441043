import { apiEnterprises } from '../../../../constants/api';
import { get, post, put, remove } from '../../../../lib/genericAction';

const apiEnterpriseCrops = `${apiEnterprises}/crops`;
const apiEnterpriseCropTransfers = `${apiEnterpriseCrops}/transfers`;
const apiEnterpriseCropGrazings = `${apiEnterpriseCrops}/grazings`;
const apiEnterpriseCropIrrigations = `${apiEnterpriseCrops}/irrigations`;
const apiEnterpriseCropValues = `${apiEnterpriseCrops}/values`;

export function fetchCroppingSummaries(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_CROPPING_SUMMARIES`, `${apiEnterpriseCrops}/summaries`, params);
}

export function fetchCropflows(params, inBackground = false) {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_CROPFLOWS',
      `${apiEnterpriseCrops}/cropflows`,
      params,
      null,
      inBackground
    );
}

// Transactions
export function fetchCroppingTransactions(type, params) {
  return async (dispatch) =>
    get(dispatch, `FETCH_CROPPING_${type.toUpperCase()}`, `${apiEnterpriseCrops}`, params);
}

export function fetchCroppingTransaction(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_CROPPING_TRANSACTION', `${apiEnterpriseCrops}/${id}`);
}

export function createCroppingTransaction(data) {
  return async (dispatch) =>
    post(dispatch, `CREATE_CROPPING_TRANSACTION`, `${apiEnterpriseCrops}`, data);
}

export function updateCroppingTransaction(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_CROPPING_TRANSACTION', `${apiEnterpriseCrops}/${data.id}`, data);
}

export function removeCroppingTransaction(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_CROPPING_TRANSACTION', `${apiEnterpriseCrops}/${id}`);
}

// Transfers
export function fetchCroppingTransfers(params) {
  return async (dispatch) =>
    get(dispatch, `FETCH_CROPPING_TRANSFERS`, apiEnterpriseCropTransfers, params);
}

export function fetchCroppingTransfer(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_CROPPING_TRANSFER', `${apiEnterpriseCropTransfers}/${id}`);
}

export function createCroppingTransfer(data) {
  return async (dispatch) =>
    post(dispatch, `CREATE_CROPPING_TRANSFER`, apiEnterpriseCropTransfers, data);
}

export function updateCroppingTransfer(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_CROPPING_TRANSFER', `${apiEnterpriseCropTransfers}/${data.id}`, data);
}

export function removeCroppingTransfer(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_CROPPING_TRANSFER', `${apiEnterpriseCropTransfers}/${id}`);
}

// Grazings
export function fetchCroppingGrazings(params) {
  return async (dispatch) =>
    get(dispatch, `FETCH_CROPPING_GRAZINGS`, apiEnterpriseCropGrazings, params);
}

export function fetchCroppingGrazing(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_CROPPING_GRAZING', `${apiEnterpriseCropGrazings}/${id}`);
}

export function createCroppingGrazing(data) {
  return async (dispatch) =>
    post(dispatch, `CREATE_CROPPING_GRAZING`, apiEnterpriseCropGrazings, data);
}

export function updateCroppingGrazing(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_CROPPING_GRAZING', `${apiEnterpriseCropGrazings}/${data.id}`, data);
}

export function removeCroppingGrazing(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_CROPPING_GRAZING', `${apiEnterpriseCropGrazings}/${id}`);
}

// Irrigations
export function fetchCroppingIrrigations(params) {
  return async (dispatch) =>
    get(dispatch, `FETCH_CROPPING_IRRIGATIONS`, apiEnterpriseCropIrrigations, params);
}

export function fetchCroppingIrrigation(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_CROPPING_IRRIGATION', `${apiEnterpriseCropIrrigations}/${id}`);
}

export function createCroppingIrrigation(data) {
  return async (dispatch) =>
    post(dispatch, `CREATE_CROPPING_IRRIGATION`, apiEnterpriseCropIrrigations, data);
}

export function updateCroppingIrrigation(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_CROPPING_IRRIGATION', `${apiEnterpriseCropIrrigations}/${data.id}`, data);
}

export function removeCroppingIrrigation(id) {
  return async (dispatch) =>
    await remove(dispatch, 'REMOVE_CROPPING_IRRIGATION', `${apiEnterpriseCropIrrigations}/${id}`);
}

// Values
export function fetchCroppingValues(params) {
  return async (dispatch) =>
    get(dispatch, `FETCH_CROPPING_VALUES`, apiEnterpriseCropValues, params);
}

export function fetchCroppingValue(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_CROPPING_VALUE', `${apiEnterpriseCropValues}/${id}`);
}

export function createCroppingValue(data) {
  return async (dispatch) => post(dispatch, `CREATE_CROPPING_VALUE`, apiEnterpriseCropValues, data);
}

export function updateCroppingValue(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_CROPPING_VALUE', `${apiEnterpriseCropValues}/${data.id}`, data);
}

export function removeCroppingValue(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_CROPPING_VALUE', `${apiEnterpriseCropValues}/${id}`);
}
