import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const AssetReadingsLsv = ({ row, rows, ignoreFields, onDelete = noop }) => {
  const { asset_id } = row || '';
  const filteredRows = rows.filter((row) => row.asset_id === asset_id);

  const deleteIcon = 'trash';

  const handleKeyDown = (e) => {};

  const renderRows = (headers, row) => {
    const tableTd = headers.map(({ field, classes }, index) => {
      let caption = row[field];
      if (field?.includes('.')) {
        let parent = row;
        for (const key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }
      switch (field) {
        case 'reading_date':
          return (
            <td key={index} className={classes}>
              {caption ? moment(caption).format('YYYY-MM-DD') : ''}
            </td>
          );
        case 'delete':
          return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <td
              key={index}
              className={classes}
              style={{ cursor: 'pointer' }}
              onClick={() => onDelete(row)}
              onKeyDown={handleKeyDown}
            >
              <Icon name={deleteIcon} />
            </td>
          );
        default: {
          const td = (
            <td key={index} className={classes}>
              {caption}
            </td>
          );

          return td;
        }
      }
    });

    return tableTd;
  };

  let headers = [
    { caption: 'Plant No', field: 'plant_asset.name', classes: 'text-left' },
    { caption: 'Description', field: 'plant_asset.description', classes: 'text-left' },
    { caption: 'Owner', field: 'plant_asset.org.name', classes: 'text-left' },
    { caption: 'Date', field: 'reading_date', classes: 'text-left' },
    { caption: 'Reading', field: 'value', classes: 'text-left' },
    { caption: '', field: 'delete', classes: 'text-danger' },
  ];

  if (ignoreFields?.length > 0)
    headers = headers.filter(({ field }) => !ignoreFields.inclucaptionscaptionscaptionses(field));

  const tableHeadTh = headers.map(({ caption, classes }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = filteredRows && filteredRows.length > 0;
  if (haveRows) {
    tableBodyTr = filteredRows.map((row, index) => {
      return (
        <tr key={index} style={{ cursor: 'pointer' }}>
          {renderRows(headers, row, index)}
        </tr>
      );
    });
  }

  const iconName = 'clipboard-list';
  const emptyCaption = 'No readings found';

  return (
    <Listview
      rows={filteredRows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default AssetReadingsLsv;
