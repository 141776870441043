import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import {
  initControls,
  saveControls,
  updateControlOptions,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { controls as controlsOpeningValue } from '../forms/livestockOpeningValues';

import { fetchAttributes } from '../actions/attributes';

import {
  createLivestockOpeningAdjustment,
  removeLivestockOpeningAdjustment,
} from '../actions/livestocks';

import ResponseMessageTab from '../../../core/form/components/ResponseMessageTab';
import { fetchAnimalClasses } from '../actions/animal_classes';
import { fetchEnterprises } from '../actions/enterprises';

export class LivestockOpeningModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(controlsOpeningValue),
      data: {},
      id: null,
      isNew: false,
      title: 'Opening Value',
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { division_id } = this.props;
      const controls = initControls(cloneDeep(controlsOpeningValue));

      const updatedState = {
        controls,
        data: {},
        isNew: true,
        title: 'New Opening Value',
      };

      this.props.dispatch(fetchAttributes({ type: 'transaction_types', tag: 'adjustment' }));
      this.props.dispatch(
        fetchAttributes({ type: 'livestock_adjustment_types', tag: 'opening_quantity' }),
      );
      this.props.dispatch(fetchEnterprises);
      this.props.dispatch(fetchAnimalClasses({ division_id, valid: true }));

      this.setState(updatedState);
    }
  }

  stripUnitObjects = (data) => {
    const updatedData = cloneDeep(data);
    Object.keys(updatedData).forEach((fieldIdx) => {
      if (
        typeof updatedData[fieldIdx] === 'object' &&
        updatedData[fieldIdx] !== null &&
        updatedData[fieldIdx].unit_type &&
        updatedData[fieldIdx].value
      ) {
        delete updatedData[fieldIdx].unit_type;
      }
    });

    return updatedData;
  };

  onClose = (refresh = false) => {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
  };

  onRemove = async () => {
    const { data } = this.state;

    const confirmed = window.confirm('Removing Opening Value permanently. Continue?');
    if (confirmed) {
      const success = await this.props.dispatch(removeLivestockOpeningAdjustment(data.id));
      if (success) this.onClose(true);
    }
  };

  onSave = async () => {
    let { data } = this.state;
    const { controls } = this.state;
    data = saveControls(controls, data);
    delete data.id;
    data = this.stripUnitObjects(data);

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (isValid) {
      const success = await this.props.dispatch(createLivestockOpeningAdjustment(data));

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  };

  onEnterpriseChange = (event) => {
    const { value: enterprise_id } = event.target;
    const enterpriseProperties = this.getEnterpriseProperties(enterprise_id);
    const updatedControls = cloneDeep(this.state.controls);
    updatedControls.enterprise_id.value = enterprise_id;

    // Update opening date to the first property of the selected enterprise
    if (enterpriseProperties.length > 0) {
      const [{ open_date }] = enterpriseProperties;
      const openingDate = this.formatOpeningTransactionDateString(open_date);

      updatedControls.transaction_date.value = openingDate;
    }

    this.setState({ controls: updatedControls });
  };

  onPropertyChange = (event) => {
    const { controls } = this.state;
    const { value: enterprise_id } = controls.enterprise_id;
    const { value: property_id } = event.target;

    const enterpriseProperties = this.getEnterpriseProperties(enterprise_id);
    const openingDate = this.getOpeningDate(property_id, enterpriseProperties);

    const updatedControls = cloneDeep(controls);
    updatedControls.property_id.value = property_id;
    updatedControls.transaction_date.value = openingDate;
    this.setState({ controls: updatedControls });
  };

  getEnterpriseProperties = (enterprise_id) => {
    // Only display properties that have been previously allocated to the enterprise
    const { enterprises } = this.props.enterprises;
    const enterprise = enterprises?.rows.find(({ id }) => id === enterprise_id);
    if (!enterprise || enterprises.allocations?.length === 0) return [];

    return enterprise.allocations.map(({ open_date, property }) => ({ ...property, open_date }));
  };

  getOpeningDate = (property_id, enterprise_properties = []) => {
    const { selectedRanges } = this.props.enterprises;
    const defaultOpeningDate = this.formatOpeningTransactionDateString(selectedRanges.from_date);

    const selectedProperty = enterprise_properties.find(({ id }) => id === property_id);

    return selectedProperty
      ? this.formatOpeningTransactionDateString(selectedProperty.open_date)
      : defaultOpeningDate;
  };

  formatOpeningTransactionDateString = (date) =>
    moment(date).subtract(1, 'day').format('YYYY-MM-DD');

  render() {
    let { controls } = this.state;
    const { title } = this.state;
    const { division_id } = this.props;
    const { isOpen } = this.props;
    const { responseMessage } = this.props.livestocks;
    const { enterprises } = this.props.enterprises;
    const { livestock_adjustment_types, divisions } = this.props.attributes;
    let { transaction_types } = this.props.attributes;
    const { animal_classes } = this.props.animal_classes;

    const iconName = 'clipboard-list';

    const currentDivision = divisions.find(({ id }) => id === division_id);

    // Division level filter for transaction types
    transaction_types = transaction_types.filter(
      ({ tag }) => !currentDivision?.rules?.denyTransactionTypes?.includes(tag),
    );

    const division_enterprises = enterprises?.rows
      ? enterprises.rows.filter((enterprise) => enterprise.division_id === division_id)
      : [];

    controls.adjustment_type_id.disabled = true;
    controls.transaction_date.disabled = true;
    const filtered_adjustment_types = livestock_adjustment_types.filter(
      (adj_type) => adj_type.tag === 'opening_quantity',
    );

    controls.adjustment_type_id.value = filtered_adjustment_types[0]?.id;

    controls.transaction_type_id.validationOptions = { override: { disable: true } };
    controls.adjustment_type_id.validationOptions = { override: { disable: true } };

    let enterprise_properties = [];
    if (controls.enterprise_id.value || division_enterprises.length > 0) {
      enterprise_properties = this.getEnterpriseProperties(
        controls.enterprise_id.value ?? division_enterprises[0]?.id,
      );
    }

    const controlOptions = [
      { key: 'enterprise_id', options: division_enterprises },
      { key: 'property_id', options: enterprise_properties },
      { key: 'transaction_type_id', options: transaction_types },
      { key: 'adjustment_type_id', options: filtered_adjustment_types },
      { key: 'animal_class_id', options: animal_classes },
    ];

    controlOptions.forEach(({ key, options }) => {
      controls = updateControlOptions(controls, key, options);
    });

    if (!controls.transaction_date.value && controls.property_id.value) {
      const openingDate = this.getOpeningDate(controls.property_id.value, enterprise_properties);
      controls.transaction_date.value = openingDate;
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <ResponseMessageTab responseMessage={responseMessage} />}
          <Form>
            <FormInput handleChange={this.onEnterpriseChange} control={controls.enterprise_id} />
            {enterprise_properties?.length > 1 && (
              <FormInput handleChange={this.onPropertyChange} control={controls.property_id} />
            )}
            <FormInput handleChange={this.handleChange} control={controls.animal_class_id} />
            <FormInput handleChange={this.handleChange} control={controls.transaction_type_id} />
            <FormInput handleChange={this.handleChange} control={controls.adjustment_type_id} />
            <FormInput handleChange={this.handleChange} control={controls.transaction_date} />
            <FormInput handleChange={this.handleChange} control={controls.quantity} />
            <FormInput handleChange={this.handleChange} control={controls.notes} />
            <FormInput handleChange={this.handleChange} control={controls.weight} />
            <FormInput handleChange={this.handleChange} control={controls.value} />
            <FormInput handleChange={this.handleChange} control={controls.au_rating} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ animal_classes, attributes, enterprises, livestocks }) => ({
  animal_classes,
  attributes,
  enterprises,
  livestocks,
});

export default connect(mapStoreToProps)(LivestockOpeningModal);
