import { controlDefaults } from "../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  name: {
    ...controlDefaults,
    name: 'name',
    type: 'text',
    caption: 'Original Filename',
    disabled: false,
    validationRules: {
      isRequired: true
    },
  },
  expected_name: {
    ...controlDefaults,
    name: 'expected_name',
    type: 'text',
    caption: 'Replacement Filename',
    disabled: false,
    validationRules: {
      isRequired: false
    },
  },
  group_key: {
    ...controlDefaults,
    name: 'group_key',
    type: 'text',
    caption: 'Document Group',
    disabled: false,
    validationRules: {
      isRequired: true
    },
  },
  mappings: {
    ...controlDefaults,
    name: 'mappings',
    type: 'json',
    caption: 'Mappings',
    disabled: false,
    validationRules: {
      isRequired: true,
      isArray: true
    },
  }
}