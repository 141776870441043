import React from 'react';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import AgistmentMenu from './AgistmentMenu';
import { noop } from 'lodash';

const AgistmentToolbar = (props) => {
  const { onRefresh = noop, setModal = noop } = props;

  return (
    <div className="d-flex justify-content-end">
      <AgistmentMenu setModal={setModal} />
      <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
        <Icon name="rotate" />
      </Button>
    </div>
  );
};

export default AgistmentToolbar;
