import { defaults } from '../../../../lib/genericReducer';
export default function reducer(state = {
  fetching: false,
  fetched: false,
  error: null,
  authorised: true,
  authResponseMessage: null,
  responseMessage: null,
  groups: [],
  currentGroup: {}
}, action) {

  state = {
    ...state,
    ...defaults(action)
  }

  // Account for specific types
  switch (action.type) {
    case "FETCH_DOCUMENT_GROUPS_PENDING":
      {
        return {
          ...state,
          groups: [],
        }
      }

    case "FETCH_DOCUMENT_GROUPS_FULFILLED":
      {
        return {
          ...state,
          groups: action.payload
        }
      }
      case "FETCH_DOCUMENT_GROUP_PENDING":
        {
          return {
            ...state,
            currentGroup: {},
          }
        }
  
      case "FETCH_DOCUMENT_GROUP_FULFILLED":
        {
          return {
            ...state,
            currentGroup: action.payload
          }
        }
    default: break;
  }
  return state;
}
