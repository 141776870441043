import React, { useState } from 'react';
import Listview from '../../../core/form/components/Listview';
import FormInput from '../../../core/form/components/FormInput';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';

const PropertyUsageDistributionsLsv = (props) => {
  const { rows } = props;
  const [changed, setChanged] = useState(false);

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      let { field, classes, formattingRules } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (let key of field.split('.').values()) {
          parent = key && parent ? parent[key] : null;
          caption = parent;
        }
      }

      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      let id = row.usage_type_id;
      let control = {
        id,
        type: 'number',
        name: field,
        value: caption,
      };

      if (id === 'available_distribution') {
        control.disabled = true;
        // Set areas from each row into an object by usage type. There will always only be one unique row of each type.
        const usageAreas = {};
        if (rows.length > 0) {
          rows.forEach((row) => {
            if (row.usage_type?.tag !== undefined) {
              usageAreas[row.usage_type.tag] = row.area_ha;
            }
          });
        }
        const { property_leasing, property_leased, property_owned, property_unavailable } =
          usageAreas;

        // Simplified leasing formula
        const leasing = property_leasing;
        const leased = property_leased;
        const lease_area_formula = leased - leasing;
        control.value = parseFloat(property_owned);

        control.value += lease_area_formula;

        if (property_unavailable) control.value -= parseFloat(property_unavailable);

        if (isNaN(control.value)) control.value = 0;

        // Apply formatting rules to calculated value
        control.value = numberRuleFormat(control.value, {
          includeCommas: true,
          includeDecimals: true,
        });
      }

      let { value } = control;
      switch (field) {
        case 'area_ha':
          // Remove commas
          if (typeof value === 'string') {
            control.value = value.split(',').join('');
          }

          return (
            <td key={index} className={classes}>
              <FormInput handleChange={(event) => handleChange(event, row)} control={control} />
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {caption}
            </td>
          );
      }
    });

    return tableTd;
  };

  const handleChange = (event, row) => {
    const { name, value } = event.target;
    row[name] = value;
    setChanged(!changed);
  };

  const headers = [
    { caption: 'Usage Type', field: 'usage_type.name', classes: 'text-left' },
    {
      caption: 'Area (ha)',
      field: 'area_ha',
      classes: 'text-left',
    },
  ];

  const tableHeadTh = headers.map(({ classes, caption }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  const tableBodyTr = rows.map((row) => {
    return (
      <tr key={row.usage_type_id} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    );
  });

  return <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} />;
};

export default PropertyUsageDistributionsLsv;
