import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  version_id: {
    ...controlDefaults,
    fieldName: 'version_id',
    name: 'version_id',
    type: 'hidden',
    caption: 'Version ID',
  },
  property_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'property_id',
    fieldName: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {},
    showInEditor: true,
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    fieldName: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  address: {
    ...controlDefaults,
    placeholder: 'Geocodable address string including street address, town, postcode',
    name: 'address',
    fieldName: 'address',
    type: 'text',
    caption: 'Street Address',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  state: {
    ...controlDefaults,
    placeholder: 'State',
    name: 'state',
    fieldName: 'state',
    type: 'text',
    caption: 'State',
    validationRules: {
      isRequired: false,
    },
    showInListview: true,
  },
  lga: {
    ...controlDefaults,
    placeholder: 'Local Government Area',
    name: 'lga',
    fieldName: 'lga',
    type: 'text',
    caption: 'LGA',
    validationRules: {
      isRequired: false,
    },
    showInListview: true,
  },
  nearest_town: {
    ...controlDefaults,
    placeholder: '',
    name: 'nearest_town',
    fieldName: 'nearest_town',
    type: 'text',
    caption: 'Nearest Town',
    validationRules: {
      isRequired: false,
    },
    showInListview: false,
  },

  area_owned_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'area_owned_ha',
    fieldName: 'area_owned_ha',
    type: 'number',
    caption: 'Area Owned (ha)',
    validationRules: {
      isRequired: false,
    },
    showInEditor: false,
    showInListview: false,
  },
  area_leased_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'area_leased_ha',
    fieldName: 'area_leased_ha',
    type: 'number',
    caption: 'Area Leased (ha)',
    validationRules: {
      isRequired: false,
    },
    showInEditor: false,
    showInListview: false,
  },
  area_leasing_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'area_leasing_ha',
    fieldName: 'area_leasing_ha',
    type: 'number',
    caption: 'Area Leasing (ha)',
    validationRules: {
      isRequired: false,
    },
    showInEditor: false,
    showInListview: false,
  },
  area_unavailable_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'area_unavailable_ha',
    fieldName: 'area_unavailable_ha',
    type: 'number',
    caption: 'Area Unavailable (ha)',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
  },
  area_production_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'area_production_ha',
    fieldName: 'area_production_ha',
    type: 'number',
    caption: 'Area Production (ha)',
    validationRules: {
      isRequired: false,
    },
    showInListview: false,
  },
};

/** Does Not Contribute checkbox for Client Properties (Admin only) */
export const dncControls = {
  ...controls,
  dnc: {
    ...controlDefaults,
    name: 'dnc',
    fieldName: 'dnc',
    type: 'checkbox',
    caption: 'DNC',
    showInListview: true,
  },
};
