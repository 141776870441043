import React from 'react';
import {
  Button,
  Row,
  Col
} from 'reactstrap';
import FormInput from "../../../core/form/components/FormInput";

const AreaRoundStrataProfile = (props) => {
  const {
    handleChange,
    controls,
    onSave,
    onCancel,
    onRemove,
    checkAccess
  } = props;

  return (
    <React.Fragment>
      <Row>
        <Col><FormInput handleChange={handleChange} control={controls.name} /></Col>
        <Col><FormInput handleChange={handleChange} control={controls.area_ha} disabled={true} /></Col>
      </Row>
      <div className="p-2 d-flex justify-content-center mt-2">
        <Button disabled={!checkAccess('roundStrataUpdate')} color="success" className="m-1" onClick={onSave} >Save</Button>
        <Button color="light" className="m-1" onClick={onCancel}>Cancel</Button>
        <Button disabled={!checkAccess('roundStrataUpdate')} color="danger" className="m-1" onClick={onRemove}>Delete</Button>
      </div>
    </React.Fragment>
  );
}

export default AreaRoundStrataProfile;
