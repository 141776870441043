import { controlDefaults } from 'jsx/components/core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  caption: {
    ...controlDefaults,
    caption: 'Caption',
    name: 'caption',
    showInEditor: true,
    type: 'text',
    validationRules: {
      isRequired: true,
    },
  },
  type_id: {
    ...controlDefaults,
    caption: 'Type',
    name: 'type_id',
    fieldName: 'type.name',
    showInEditor: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  start_day: {
    ...controlDefaults,
    caption: 'Start Day',
    name: 'start_day',
    showInEditor: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  start_time: {
    ...controlDefaults,
    caption: 'Start Time',
    name: 'start_time',
    showInEditor: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  end_day: {
    ...controlDefaults,
    caption: 'End Day',
    name: 'end_day',
    showInEditor: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  end_time: {
    ...controlDefaults,
    caption: 'End Time',
    name: 'end_time',
    showInEditor: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  is_paid: {
    ...controlDefaults,
    caption: 'Paid',
    defaultValue: true,
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    name: 'is_paid',
    showInEditor: true,
    type: 'checkbox',
    validationRules: {
      isRequired: true,
    },
  },
};
