import Icon from 'jsx/components/core/icons/Icon';
import React, { useState } from 'react';
import Listview from '../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';
import { columnWidths } from '../constants/listviews';
import LiabilityTransactionsLsv from './LiabilityTransactionsLsv';

const LiabilityflowLsv = (props) => {
  let { rows, editLiability, editTransaction, transactions } = props;

  const [renderState, setRenderState] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({});

  const openLiabilities = async (event, id) => {
    event.preventDefault();
    event.stopPropagation();

    collapsedSections[id] = !collapsedSections[id];
    setCollapsedSections(collapsedSections);

    setRenderState(!renderState);
  };

  const renderLiabilityTransactions = (liability_id) => {
    // Filter transactions by liability id
    const filtered_transactions = transactions?.filter(
      (transaction) => transaction.liability_id === liability_id
    );

    return (
      <LiabilityTransactionsLsv
        darkTable={true}
        hideHeader={false}
        rows={filtered_transactions}
        editTransaction={editTransaction}
      />
    );
  };

  const isLiabilityOpen = (id) => collapsedSections[id];

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const { classes, field, formattingRules, width } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (let key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }

      switch (field) {
        case 'name': {
          // Add edit icon next to liability name
          return (
            <td key={index} className={classes} onClick={() => editLiability(row.id)} width={width}>
              {caption}
              <Icon name="pen-to-square" className="white ml-3 cursor-pointer" />
            </td>
          );
        }
        case 'opening.value':
        case 'closing.value': {
          let tooltip;
          let tooltip_classes;
          // Highlight any discrepancies
          if (field === 'closing.value' && caption < 0) {
            tooltip_classes = 'bg-danger text-white';
            tooltip = 'Negative Values';
          }

          if (formattingRules) {
            caption = numberRuleFormat(caption, formattingRules);
          }
          return (
            <td
              key={index}
              className={`${classes} ${tooltip_classes}`}
              title={tooltip ?? null}
              width={width}
              onClick={(event) => openLiabilities(event, row.id)}
            >
              {caption}
            </td>
          );
        }

        case 'rate_pcnt':
          return (
            <td
              key={index}
              className={classes}
              width={width}
              onClick={(event) => openLiabilities(event, row.id)}
            >
              {numberRuleFormat(caption, header.formattingRules)}
            </td>
          );
        case 'toggle': {
          return (
            <td
              key={index}
              className={classes}
              width={width}
              onClick={(event) => openLiabilities(event, row.id)}
            >
              <small className="mt-1 mr-2">
                <Icon
                  name={collapsedSections[row.id] ? 'chevron-up' : 'chevron-down'}
                  className="text-corporate"
                />
              </small>
            </td>
          );
        }
        default:
          if (formattingRules) {
            caption = numberRuleFormat(caption, formattingRules);
          }
          return (
            <td key={index} className={classes} width={width}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const { common, icon } = columnWidths;
  let headers = [
    {
      caption: 'Category',
      field: 'category.name',
      classes: 'text-left',
      width: `${common}px`,
    },
    {
      caption: 'Liability',
      field: 'name',
      classes: 'text-left',
      width: `${common}px`,
    },
    {
      caption: 'Opening Value',
      field: 'opening.value',
      classes: 'text-right border-left border-right',
      totals: true,
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
      width: `${common}px`,
    },
    {
      caption: 'Principal Paid',
      field: 'principal_paid',
      classes: 'text-right border-left',
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
      width: `${common}px`,
    },
    {
      caption: 'Adjustments',
      field: 'liability_adjustment',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
      width: `${common}px`,
    },
    {
      caption: 'Closing Value',
      field: 'closing.value',
      classes: 'text-right border-left border-right',
      totals: true,
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
      width: `${common}px`,
    },
    {
      caption: 'Interest Rate (%)',
      field: 'rate_pcnt',
      classes: 'text-right',
      formattingRules: { asPercentage: true, includeDecimals: true },
    },
    {
      caption: 'Interest Paid',
      field: 'interest_paid',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
    },
    {
      caption: 'Non-Interest Borrowing Costs',
      field: 'non_interest_paid',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
      width: `${common}px`,
    },

    {
      caption: '',
      field: 'toggle',
      classes: 'text-center',
      width: `${icon}px`,
    },
  ];

  // Get unique distribution headers
  const toEnd = 1;
  const headerPosition = 3;
  rows.forEach((row) => {
    // Sort distributions by division name alphabetically
    row.distributions.sort((a, b) =>
      a?.division?.name ? a.division.name.localeCompare(b.division.name) : toEnd
    );
    row.distributions.forEach((distribution, idx) => {
      // Only add to headers if unique
      let caption = `${distribution.division.name} (%)`;
      let headers_idx = headers.findIndex((existing_header) => existing_header.caption === caption);

      if (headers_idx === -1) {
        // Add idx to position number when splicing to keep alphabetical order of division names
        headers.splice(headerPosition + idx, 0, {
          caption,
          field: `distributions.${idx}.distribution_pcnt`,
          classes: 'text-right',
          formattingRules: {
            asPercentage: true,
            includePercentageSign: true,
            includeDecimals: true,
          },
          width: `${common}px`,
        });
      }
    });
  });

  const tableHeadTh = headers.map((header, index) => {
    return (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    );
  });

  let tableBodyTr = <tr></tr>;
  if (rows?.rows) rows = rows.rows;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => (
      <React.Fragment key={index}>
        <tr key={index} className={'cursor-pointer'}>
          {renderRows(headers, row)}
        </tr>
        {isLiabilityOpen(row.id) && (
          <tr>
            <td className="bg-white" colSpan={headers.length + 1}>
              {renderLiabilityTransactions(row.id)}
            </td>
          </tr>
        )}
      </React.Fragment>
    ));
  }

  const iconName = 'weight-hanging';
  const emptyCaption = 'No liabilities found. Have you added any liabilities yet?';

  return (
    <React.Fragment>
      <Listview
        rows={rows || []}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default LiabilityflowLsv;
