import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    activeTab: '1',
    assets: {},
    assetflows: [],
    transactions: {},
    distributions: {},
    enterprises: [],
    filters: {
      categories: [],
      types: [],
    },
    params: {},
  },
  action
) {
  // Set expected reducer types
  const types = ['ASSET'];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'UNSET_ASSETS': {
      return {
        ...state,
        assets: {},
      };
    }
    case 'UNSET_ASSET_TRANSACTIONS': {
      return {
        ...state,
        transactions: {},
      };
    }
    case 'UNSET_ASSET_ENTERPRISES': {
      return { ...state, enterprises: [] };
    }
    case 'FETCH_ASSETS_FULFILLED': {
      return {
        ...state,
        assets: action.payload,
      };
    }
    case 'FETCH_ASSET_TRANSACTIONS_FULFILLED': {
      return {
        ...state,
        transactions: action.payload,
      };
    }
    case 'FETCH_ASSETFLOWS_FULFILLED': {
      return {
        ...state,
        assetflows: action.payload,
      };
    }
    case 'FETCH_ENTERPRISES_BY_ASSET_TYPE_FULFILLED': {
      return {
        ...state,
        enterprises: action.payload,
      };
    }
    case 'SET_ASSETFLOW_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case 'SET_ASSET_PARAMS': {
      return {
        ...state,
        params: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
