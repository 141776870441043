import FormInput from 'jsx/components/core/form/components/FormInput';
import Icon from 'jsx/components/core/icons/Icon';
import { isEmpty, noop } from 'lodash';
import Select from 'react-select';
import { Button, Col, Label, Row } from 'reactstrap';

/**
 * Takes a col number and converts it to a bootstrap column object
 */
const mapColNumberToObject = (col) => {
  let tempCol = col;
  if (typeof col === 'number') {
    // eslint-disable-next-line id-length
    tempCol = { sm: col };
  }
  return tempCol;
};

// Set Filter icon colour
let filterIconName = 'filter-slash';
let activityIconClassName = 'text-black m-1 p-0';
let filterTitle = 'Set filters to reduce items in Work Activities and Assets combo';
let selectBorder = '1px solid lightgray';

const DaysheetInlineEntry = ({
  assetFieldReference = null,
  controls,
  handleChange = noop,
  handleSelectChange = noop,
  isAssetSelected = false,
  onCopy = noop,
  onSave = noop,
  onActivitySave = noop,
  onActivityClear = noop,
  setFilterModal = noop,
  isFilterSet = false,
  addDisabled = false,
}) => {
  const keys = Object.keys(controls);
  let rowKeys = keys.filter((key) => controls[key].showInEditor && !controls[key].row2);
  const row = rowKeys.map((key, index) => {
    const control = controls[key];

    const {
      includeInputReference = false,
      showInEditor,
      type,
      row2,
      name,
      caption,
      disabled,
      col,
      value,
      validationRules,
      message,
    } = control;
    let { options } = control;

    if (showInEditor && type === 'reactselect' && !row2) {
      options = options.map(({ id, name: label }) => ({ value: id, label }));

      let selectedValue = options.find((option) => option.value === value);
      if (selectedValue === undefined) {
        selectedValue = { value: null, label: '-' };
      }

      if (isFilterSet) {
        filterIconName = 'filter-solid';
        activityIconClassName = 'text-success m-1 p-0';
        filterTitle = 'One or more filters have been set for Locations/Projects. Click for details';
        selectBorder = '1px solid #34a853';
      }

      return (
        <Col key={index} {...mapColNumberToObject(col)} className="pt-2">
          <div className="d-flex justify-content-between">
            <Label className="m-0 ml-1 font-weight-bold">{caption}</Label>
            <div className="d-flex">
              {name === 'job_id' && (
                <Icon
                  name="copy"
                  as="icon"
                  className={activityIconClassName}
                  onClick={() => onCopy()}
                  title="Copy selected user's most recent activity"
                />
              )}
              <Icon
                name={filterIconName}
                as="icon"
                className={activityIconClassName}
                onClick={() => setFilterModal(true)}
                title={filterTitle}
              />
            </div>
          </div>

          <Select
            ref={includeInputReference ? assetFieldReference : null}
            onChange={({ value: changeValue }) => {
              handleSelectChange({ value: changeValue, label: name });
            }}
            required
            control={control}
            options={options}
            isDisabled={disabled}
            value={selectedValue}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                marginTop: '6px',
                fontSize: '14px',
                border: selectBorder,
              }),
            }}
          />
          {!value && validationRules.isRequired && message && (
            <small className="text-danger">{message}</small>
          )}
          {name === 'job_id' && !isEmpty(controls.description.value) && (
            <small className="text-corporate">
              <span className="font-weight-bold">Work Description: </span>
              {controls.description.value}
            </small>
          )}
        </Col>
      );
    }

    const isDisabled = ({ name: fieldName }) =>
      !isAssetSelected && ['asset_hours', 'smu_end', 'smu_start', 'load_count'].includes(fieldName);
    if (showInEditor && !row2) {
      return (
        <Col key={index} {...mapColNumberToObject(col)}>
          <FormInput handleChange={handleChange} control={control} disabled={isDisabled(control)} />
        </Col>
      );
    }

    return false;
  });

  rowKeys = keys.filter((key) => controls[key].showInEditor && controls[key].row2);
  const row2 = rowKeys.map((key, index) => {
    const control = controls[key];
    if (control.showInEditor && control.row2) {
      return (
        <Col key={index} {...mapColNumberToObject(control.col)} className="pl-3 pb-3">
          <FormInput handleChange={handleChange} control={control} />
        </Col>
      );
    }

    return false;
  });

  return (
    <div className="bg-lightgray pt-1 border-bottom border-primary" style={{ fontSize: 12 }}>
      {row.length > 0 && (
        <Row className="m-0 p-0">
          {row}
          {row2.length === 0 && (
            <Col sm={2} className="d-flex justify-content-end" style={{ marginTop: 18 }}>
              <div>
                <Button color="success" className="" size="sm" onClick={onActivitySave}>
                  Add
                </Button>
                <Button color="link" className="" size="sm" onClick={onActivityClear}>
                  Clear
                </Button>
              </div>
            </Col>
          )}
        </Row>
      )}

      {row2.length > 0 && (
        <Row className="d-flex m-0 p-0">
          {row2}
          <Col sm={2} className="d-flex align-items-center mt-3 ">
            <Button color="success" size="sm" onClick={onSave} disabled={addDisabled}>
              Add Line
            </Button>
            <Button color="link" className="ml-1" size="sm" onClick={onActivityClear}>
              Clear
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DaysheetInlineEntry;
