import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import { initControls, saveControls } from '../../../core/form/lib/validateForm';
import { controls } from '../forms/property';

import { createProperty } from '../../projects/actions/properties';
import { cloneDeep, omit } from 'lodash';

class PropertyNew extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(controls),
      data: {},
      id: null,
      isNew: true,
      isOpen: false,
      mapSources: [],
      setModal: null,
      title: 'New Property',
    };

    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  async componentDidUpdate() {
    let { controls, data, id, isNew, isOpen } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      controls = initControls(cloneDeep(controls));
      id = null;

      this.setState({
        controls,
        data,
        id,
        isNew,
        isOpen: this.props.isOpen,
        setModal: this.props.setModal,
      });
    }
  }

  async onSave() {
    const { data, controls } = this.state;
    const { project } = this.props.portal;

    let saveData = saveControls(controls, data);

    // Remove id property as we want to create a new property
    saveData = omit(saveData, ['id']);

    /**
     * Add save data properties as it shares the same endpoint for Carbonizer
     * project_id: Organisation id
     * idx_required: Not required for FarmEye
     */
    saveData = { ...saveData, project_id: project.id, idx_required: false };

    const property = await this.props.dispatch(createProperty(saveData));
    if (property) this.onClose(true);
  }

  onClose(refresh = false) {
    if (refresh) this.props.toggleTab('properties');

    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  render() {
    const { controls, isOpen, title } = this.state;
    const { responseMessage } = this.props.properties;
    const iconName = 'draw-polygon';

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}

          <FormInput handleChange={this.handleChange} control={controls.name} />
          <FormInput handleChange={this.handleChange} control={controls.address} />
          <FormInput handleChange={this.handleChange} control={controls.state} />
          <FormInput handleChange={this.handleChange} control={controls.lga} />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    portal: store.farmportrait_portal,
    properties: store.properties,
  };
};

export default connect(mapStoreToProps)(PropertyNew);
