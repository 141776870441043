import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  method: {
    ...controlDefaults,
    name: 'method',
    field_name: 'method',
    type: 'select',
    caption: 'Method',
    options: [],
    validationRules: {
      isRequired: true,
    },
  },
  baseline_id: {
    ...controlDefaults,
    name: 'baseline_id',
    field_name: 'baseline_id',
    type: 'select',
    caption: 'Baseline Property Round',
    options: [],
    validationRules: {
      isRequired: true,
    },
  },
  template_id: {
    ...controlDefaults,
    name: 'template_id',
    field_name: 'template_id',
    type: 'select',
    caption: 'Soil Template',
    options: [],
    validationRules: {
      isRequired: true,
    },
  }
}