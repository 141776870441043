import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';
import { cloneDeep, noop } from 'lodash';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import PageTitle from '../../../../core/form/components/PageTitle';
import FormBase from '../../../../core/form/components/FormBase';
import FormTab from '../../../../core/form/components/FormTab';
import GenericLsv from '../../../../core/form/components/GenericLsv';
import GenericModal from '../../../../core/form/components/GenericModal';
import WoolTransactionModal from './WoolTransactionModal';
import WoolflowLsv from '../../components/wool/WoolflowLsv';
import WoolCharts from '../../components/wool/WoolCharts';
import WoolToolbar from '../../components/wool/WoolToolbar';
import { fetchAttributes, fetchAttribute } from '../../actions/attributes';
import WoolAdjustmentModal from './WoolAdjustmentModal.jsx';
import WoolValueModal from './WoolValueModal';
import {
  createWoolTransaction,
  createWoolValue,
  fetchWoolflows,
  fetchWoolSummaries,
  fetchWoolTransaction,
  fetchWoolTransactions,
  fetchWoolValue,
  fetchWoolValues,
  removeWoolTransaction,
  removeWoolValue,
  updateWoolTransaction,
  updateWoolValue,
} from '../../actions/wools';
import { fetchEnterpriseRanges } from '../../actions/enterprises';
import { controls as controlsAdjustment } from '../../forms/wool/woolAdjustments';
import { controls as controlsTransaction } from '../../forms/wool/woolTransactions';
import { controls as controlsValue } from '../../forms/wool/woolValues';
import { fetchAnimalClasses } from '../../actions/animal_classes';
import { strip } from '../../../../core/form/lib/fieldFormat';

class WoolEnterprises extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      id: null,
      isModalOpen: false,
      isWoolAdjustmentModalOpen: false,
      modalType: null,
      modalData: null,
      division_id: null,
      isWoolTransactionModalOpen: false,
    };

    this.onRefresh = this.onRefresh.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setWoolAdjustmentModal = this.setWoolAdjustmentModal.bind(this);
    this.setWoolValueModal = this.setWoolValueModal.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.setWoolTransactionModal = this.setWoolTransactionModal.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchAttributes({ type: 'transaction_types' }));
    this.props.dispatch(fetchAttributes({ type: 'wool_adjustment_types' }));
  }

  componentDidUpdate() {
    const { isActive, forceRefresh, setRefresh = noop } = this.props;

    if (isActive && forceRefresh && setRefresh) {
      this.onRefresh();
      if (setRefresh) setRefresh(false);
    }
  }

  async onRefresh() {
    this.props.dispatch(fetchAnimalClasses({ '$type.parent.tag$': 'sheep' }));
    const division = await this.props.dispatch(fetchAttribute({ type: 'divisions', tag: 'sheep' }));
    if (division?.id) this.setState({ division_id: division.id });

    const { activeTab } = this.props.wools;
    if (activeTab) this.toggleTab(activeTab);
  }

  onRemove(id) {
    const { modalType } = this.state;

    let removeMethod;
    switch (modalType) {
      case 'adjustment':
      case 'sale': {
        removeMethod = removeWoolTransaction;
        break;
      }
      case 'value': {
        removeMethod = removeWoolValue;
        break;
      }
      default: {
        break;
      }
    }

    return this.props.dispatch(removeMethod(id));
  }

  onSave(data, isNew) {
    const { modalType } = this.state;
    const updatedData = cloneDeep(data);
    if (isNew) delete updatedData.id;

    let saveMethod;
    switch (modalType) {
      case 'adjustment':
      case 'sale': {
        saveMethod = isNew ? createWoolTransaction : updateWoolTransaction;
        break;
      }
      case 'value': {
        saveMethod = isNew ? createWoolValue : updateWoolValue;
        break;
      }
      default: {
        break;
      }
    }

    return this.props.dispatch(saveMethod(updatedData));
  }

  async onValueChange(field, row) {
    // Create/update wool value on the closing date
    const { selectedRanges, selectedProperties } = this.props.enterprises;
    const { animal_class, enterprise, property } = row;
    const value = row[field].value === '' ? 0 : strip(row[field].value);

    const data = {
      animal_class_id: animal_class.id,
      enterprise_id: enterprise.id,
      property_id: property.id,
      date: selectedRanges.to_date,
      value,
    };

    const success = await this.props.dispatch(createWoolValue(data));
    if (success) {
      this.props.dispatch(fetchWoolflows({ property_ids: selectedProperties, ...selectedRanges }));
      this.props.dispatch(
        fetchWoolSummaries({
          property_ids: selectedProperties,
          ...selectedRanges,
        }),
      );
    }
  }

  async setWoolTransactionModal(isWoolTransactionModalOpen, id = null) {
    const { division_id } = this.state;
    this.props.dispatch(fetchAnimalClasses({ division_id }));

    let modalData;
    if (id) modalData = await this.props.dispatch(fetchWoolTransaction(id));

    this.setState({
      isWoolTransactionModalOpen,
      modalType: 'sale',
      id,
      modalData,
    });
  }

  async setWoolAdjustmentModal(isWoolAdjustmentModalOpen, id = null) {
    let modalData;

    if (id) modalData = await this.props.dispatch(fetchWoolTransaction(id));

    this.setState({
      isWoolAdjustmentModalOpen,
      id,
      modalData,
      modalType: 'adjustment',
    });
  }

  async setWoolValueModal(isWoolValueModalOpen, id = null) {
    let modalData;
    if (id) {
      modalData = await this.props.dispatch(fetchWoolValue(id));
    }
    this.setState({
      isWoolValueModalOpen,
      modalData,
      modalType: 'value',
      id,
    });
  }

  async setModal(isModalOpen, modalType = null, modalId = null) {
    let modalData;
    if (isModalOpen) {
      switch (modalType) {
        case 'sale': {
          if (modalId) modalData = await this.props.dispatch(fetchWoolTransaction(modalId));
          break;
        }
        default: {
          break;
        }
      }
    }

    const state = { isModalOpen, modalData };
    if (modalType) state.modalType = modalType;
    this.setState(state);
  }

  setModalOptions(type) {
    const { animal_classes } = this.props.animal_classes;
    const { transaction_types, wool_adjustment_types } = this.props.attributes;
    const { enterprises } = this.props.enterprises;
    const iconName = 'handshake';

    const sheep_enterprises = enterprises?.rows
      ? enterprises.rows.filter((enterprise) => enterprise.division.tag === 'sheep')
      : [];

    let options;
    switch (type) {
      case 'adjustment': {
        const filtered_transaction_types = transaction_types.filter(
          (transaction_type) => transaction_type.tag === type,
        );

        options = {
          title: 'Adjustment',
          iconName,
          emptyCaption: 'No wool adjustments found',
          controls: controlsAdjustment,
          options: {
            adjustment_type_id: wool_adjustment_types,
            animal_class_id: animal_classes,
            enterprise_id: sheep_enterprises,
            transaction_type_id: filtered_transaction_types,
          },
        };

        break;
      }
      case 'sale': {
        // Set transaction type option as sale
        const filtered_transaction_types = transaction_types.filter(
          (transaction_type) => transaction_type.tag === type,
        );

        options = {
          title: 'Sale',
          iconName,
          emptyCaption: 'No wool sales found',
          controls: controlsTransaction,
          options: {
            animal_class_id: animal_classes,
            enterprise_id: sheep_enterprises,
            transaction_type_id: filtered_transaction_types,
          },
        };
        break;
      }
      case 'value': {
        options = {
          title: 'Value',
          iconName,
          emptyCaption: 'No wool values found',
          controls: controlsValue,
          options: {
            animal_class_id: animal_classes,
            enterprise_id: sheep_enterprises,
          },
        };
        break;
      }
      default: {
        options = {
          title: '',
          iconName,
          controls: {},
          options: {},
        };
        break;
      }
    }

    return options;
  }

  toggleTab(tab) {
    // Update active tab in reducer
    if (this.props.wools.activeTab !== tab)
      this.props.dispatch({ type: 'SET_WOOL_ACTIVE_TAB', payload: tab });

    let { modalType } = this.state;
    const { selectedRanges, selectedProperties } = this.props.enterprises;

    this.props.dispatch(fetchEnterpriseRanges());

    switch (parseInt(tab)) {
      case 1: {
        this.props.dispatch(
          fetchWoolflows({
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        break;
      }
      case 2: {
        this.props.dispatch(
          fetchWoolTransactions('sales', {
            transaction_type_tag: 'sale',
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'sale';
        break;
      }
      case 3: {
        this.props.dispatch(
          fetchWoolTransactions('adjustments', {
            transaction_type_tag: 'adjustment',
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'adjustment';
        break;
      }
      case 4: {
        this.props.dispatch(
          fetchWoolValues({
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'value';
        break;
      }
      default: {
        break;
      }
    }

    if (selectedRanges?.from_date)
      this.props.dispatch(
        fetchWoolSummaries({
          property_ids: selectedProperties,
          ...selectedRanges,
        }),
      );

    this.setState({ modalType });
  }

  render() {
    const {
      isModalOpen,
      modalData,
      modalType,
      isWoolTransactionModalOpen,
      isWoolAdjustmentModalOpen,
      isWoolValueModalOpen,
    } = this.state;
    const { responseMessage } = this.props.livestocks;
    const { activeTab, adjustments, sales, values, woolflows } = this.props.wools;

    const modalOptions = this.setModalOptions(modalType);

    const title = 'Wool Enterprises';

    return (
      <div className="p-0 h-100">
        <PageTitle title={title} />
        <WoolCharts />
        <WoolToolbar
          onRefresh={this.onRefresh}
          setModal={this.setModal}
          setWoolTransactionModal={this.setWoolTransactionModal}
          setWoolAdjustmentModal={this.setWoolAdjustmentModal}
        />
        <WoolTransactionModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setWoolTransactionModal}
          data={modalData}
          isOpen={isWoolTransactionModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />
        <GenericModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setModal}
          data={modalData}
          isOpen={isModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />
        <WoolAdjustmentModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setWoolAdjustmentModal}
          data={modalData}
          isOpen={isWoolAdjustmentModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />

        <WoolValueModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setWoolValueModal}
          data={modalData}
          isOpen={isWoolValueModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
        />
        <Nav tabs className="mt-2">
          <FormTab
            caption="Woolflow"
            tabId="1"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="woolflow"
          />
          <FormTab
            caption="Sales"
            tabId="2"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="sales"
          />
          <FormTab
            caption="Adjustments"
            tabId="3"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="adjustments"
          />
          <FormTab
            caption="Values"
            tabId="4"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="values"
          />
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="mb-2 p-1">
            <WoolflowLsv onValueChange={this.onValueChange} rows={woolflows} />
          </TabPane>
          <TabPane tabId="2" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption={modalOptions.emptyCaption}
              onClick={(id) => {
                this.setWoolTransactionModal(true, id);
              }}
              rows={sales}
            />
          </TabPane>
          <TabPane tabId="3" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption={modalOptions.emptyCaption}
              onClick={(id) => {
                this.setWoolAdjustmentModal(true, id);
              }}
              rows={adjustments}
            />
          </TabPane>
          <TabPane tabId="4" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption={modalOptions.emptyCaption}
              onClick={(id) => {
                this.setWoolValueModal(true, id);
              }}
              rows={values}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = (store) => ({
  animal_classes: store.animal_classes,
  attributes: store.attributes,
  enterprises: store.enterprises,
  livestocks: store.livestocks,
  realm: store.realm,
  wools: store.wools,
});

export default connect(mapStoreToProps)(withContainerError(WoolEnterprises));
