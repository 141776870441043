import { get, post, put, remove } from '../../../../../lib/genericAction';
import { apiRosterShifts, apiRosterShiftSlots } from 'jsx/constants/api';

const name = 'ROSTER_SHIFT_SLOT';

export function fetchShiftSlots(params) {
  return (dispatch) =>
    get(dispatch, `FETCH_${name}S`, `${apiRosterShifts}/${params.shift_id}/slots`, params);
}

export function fetchShiftSlot(params) {
  return (dispatch) =>
    get(dispatch, `FETCH_${name}`, `${apiRosterShiftSlots}/${params.id}`, params);
}

export function createShiftSlot(data) {
  return (dispatch) =>
    post(dispatch, `CREATE_${name}`, `${apiRosterShifts}/${data.shift_id}/slots`, data);
}

export function updateShiftSlot(data) {
  return (dispatch) => put(dispatch, `UPDATE_${name}`, `${apiRosterShiftSlots}/${data.id}`, data);
}

export function removeShiftSlot(id) {
  return (dispatch) => remove(dispatch, `REMOVE_${name}`, `${apiRosterShiftSlots}/${id}`);
}
