import { defaults } from '../../../../lib/genericReducer';
export default function reducer(state = {
  fetching: false,
  fetched: false,
  error: null,
  responseMessage: null,
  activeTab: '1',
  filters: [],
  orgs: [
    {id: 1, name: 'Ford Brothers', primary_contact: 'Henry Ford', membership: 'Bronze'},
    {id: 2, name: 'McDonald Farming', primary_contact: 'Old McDonald', membership: 'Platinum'}
  ],
  properties: [
    {id: 1, property_name: 'Ol McDonald Property', name: 'Bingarra Downs', address: '12 Lockwood Rd, Armidale, NSW 2500', area_owned_ha: '100ha', lots: 6, area_leased_ha: '0ha', area_leasing_ha: '50ha', area_production_ha: '150ha', area_unavailable_ha: '0ha', seasonal_conditions: 'Poor'},
    {id: 2, property_name: 'Paragon Property', name: 'Upper Bingarra ', address: '54 Spinhead Rd, Armidale, NSW 2500', area_owned_ha: '454ha', lots: 21, area_leased_ha: '20ha', area_leasing_ha: '10ha', area_production_ha: '424ha', area_unavailable_ha: '20ha', seasonal_conditions: 'Bad'}
  ],
  contacts: [
    {id: 1, name: 'Old McDonald', mobile: '0438 555 555', email: 'old@mcdonaldfarm.net', role:'Owner', status: 'Login Enabled'},
    {id: 2, name: 'Young McDonald', mobile: '0438 555 551', email: 'young@mcdonaldfarm.net', role:'Primary Contact', status: 'Invite Pending'},
    {id: 3, name: 'David McClean', mobile: '0438 555 552', email: 'dmclean@rcsaustralia.com.au', role:'Your RCS Project Contact', status: 'n/a'},
  ],
  authorised: true,
  authResponseMessage: null
}, action) {

  state = {
    ...state,
    ...defaults(action)
  }

  switch (action.type) {
    case "FETCH_CLIENTS_FULFILLED": {
      return {
        ...state,
        orgs: action.payload
      }
    }
    case "SET_ACTIVE_CLIENT_TAB": {
      return {
        ...state,
        activeTab: action.payload
      }
    }
    
    default: break;
  }
  return state;
}
