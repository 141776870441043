import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { insertItemInSequence } from 'jsx/lib/list';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Col, Row } from 'reactstrap';
import { ReportsDraggableRow } from 'jsx/components/modules/portrait/components/analytics/ReportsDraggableRow';
import Icon from 'jsx/components/core/icons/Icon';
import FormBase from '../../core/form/components/FormBase';
import { controls as reportControls } from '../forms/reports';
import ReportsToolbar from '../components/ReportsToolbar';
import { saveControls } from '../../core/form/lib/validateForm';

import { fetchReports, createReport, reorderReports } from '../../modules/portrait/actions/reports';

class Reports extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      showAddNew: false,
      controls: cloneDeep(reportControls),
    };

    this.addNew = this.addNew.bind(this);
    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.edit = this.edit.bind(this);
  }

  onDragEnd = ({ source, destination }) => {
    if (!source || !destination) {
      return;
    }

    const fromSequence = source.index;
    const toSequence = destination.index;
    // Base check - No change
    if (fromSequence === toSequence) {
      return;
    }

    const { reports } = this.props.reports;
    const prevSequences = reports.map(({ id, sequence }) => ({ id, sequence }));
    const updatedSequences = insertItemInSequence(fromSequence, toSequence, prevSequences);

    this.props.dispatch(reorderReports(updatedSequences));
  };

  async addNew() {
    const { controls } = this.state;

    const unsavedData = saveControls(controls, {});

    const success = await this.props.dispatch(
      createReport({ requires_subscription: false, ...unsavedData }),
    );
    if (success?.id) {
      this.props.dispatch(fetchReports());
      this.toggleAddNew();
    }
  }

  edit(id) {
    if (id) this.props.history.push(`/home/reports/${id}`);
  }

  toggleAddNew() {
    this.setState({ showAddNew: !this.state.showAddNew });
  }

  render() {
    const { showAddNew, controls } = this.state;
    const { reports } = this.props.reports;

    return (
      <>
        <ReportsToolbar
          addNew={this.addNew}
          controls={controls}
          toggleAddNew={this.toggleAddNew}
          showAddNew={showAddNew}
          handleChange={this.handleChange}
        />
        {reports.length === 0 && (
          <div className="p-5 text-center">
            <div>
              <Icon size="3x" name="file-chart-column" className="text-corporate" />
            </div>
            <div className="mt-3">No Reports found</div>
          </div>
        )}
        {reports && reports.length > 0 && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Row
              className="m-0 font-weight-bold border-corporate"
              style={{ borderBottom: 'solid', borderBottomWidth: '2px' }}
            >
              <div style={{ width: '24px' }}></div>
              <Col className="pl-1">Name</Col>
              <Col className="pl-1">Requires Subscription</Col>
            </Row>
            <Droppable droppableId="droppable">
              {(dropProvided) => (
                <div
                  {...dropProvided.droppableProps}
                  ref={dropProvided.innerRef}
                  className="droppable-area"
                >
                  {reports.map((row, index) => (
                    <Draggable
                      key={row.id}
                      draggableId={row.id}
                      index={row.sequence}
                      disableInteractiveElementBlocking={false}
                    >
                      {(dragProvided, snapshot) => (
                        <ReportsDraggableRow
                          controls={reportControls}
                          key={row.id}
                          isDragging={snapshot.isDragging}
                          draggableProps={dragProvided.draggableProps}
                          innerRef={dragProvided.innerRef}
                          dragHandleProps={dragProvided.dragHandleProps}
                          row={row}
                          onRowClick={this.edit}
                          index={index}
                        />
                      )}
                    </Draggable>
                  ))}
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </>
    );
  }
}

const mapStoreToProps = ({ reports }) => ({
  reports,
});

export default connect(mapStoreToProps)(Reports);
