import React from 'react';
import { connect } from 'react-redux';
import FormBase from '../../../core/form/components/FormBase';
import GenericLsv from '../../../core/form/components/GenericLsv';
import PropertyResultsToolbar from '../components/properties/PropertyResultsToolbar';

import {
  initControls,
  saveControls,
  updateControlOptions,
} from '../../../core/form/lib/validateForm';
import { controls as controlsPropertyReportingToolbar } from '../forms/propertyReportingToolbar';
import { controls as controlsPropertyComparisons } from '../forms/propertyComparisons';
import { fetchComparisonsByProperty } from '../../analytics/actions';
import { Collapse } from 'reactstrap';

class ProjectPropertyComparisons extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: controlsPropertyReportingToolbar,
      collapsedSections: {},
    };

    this.onRefresh = this.onRefresh.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
    this.loadSections = this.loadSections.bind(this);
  }

  componentDidMount() {
    let { controls } = this.state;

    this.setState({
      controls: initControls(controls),
    });
  }

  onRefresh() {
    const { controls } = this.state;
    let params = saveControls(controls, {});
    this.props.dispatch(fetchComparisonsByProperty(params));
  }

  toggleSection(id) {
    let { collapsedSections } = this.state;
    collapsedSections[id] = !collapsedSections[id];
    this.setState({
      collapsedSections,
    });
  }

  loadSections() {
    const { collapsedSections } = this.state;
    const { massComparisons } = this.props.properties;

    if (massComparisons.length === 0) return [];

    let sections = [];
    massComparisons.forEach((massComparison) => {
      massComparison.layers.forEach((layer) => {
        if (
          !sections.find(
            (section) =>
              section.id === massComparison.property_round.id && section.layer_id === layer.id
          )
        ) {
          sections.push({
            ...massComparison.property_round,
            layer_id: layer.id,
            layer_no: layer.layer_no,
          });
        }
      });
    });

    return sections.map((section, s_idx) => {
      let rows = massComparisons.filter(
        (massComparison) => massComparison.property_round.id === section.id
      );

      let collapse_id = `${section.id}-${section.layer_id}`;

      rows = rows
        .map((row) => ({
          ...row,
          layers: row.layers.find((layer) => layer.id === section.layer_id),
        }))
        .sort((a, b) => a.area.name - b.area.name);

      let title = `${section.name} - Layer ${section.layer_no}`;
      return (
        <div key={s_idx} className="border rounded bg-light mb-2">
          <div
            className="d-flex justify-content-between form-section border-bottom bg-success"
            onClick={() => this.toggleSection(collapse_id)}
          >
            <h5 className="m-0">{title}</h5>
            <div className="d-flex flex-row">
              <small className="mt-1 mr-2">
                <span style={{ cursor: 'position' }} className="text-white">
                  {collapsedSections[collapse_id] ? 'Hide' : 'Show'}
                </span>
              </small>
            </div>
          </div>
          <Collapse isOpen={collapsedSections[collapse_id]} className="p-2">
            <GenericLsv
              controls={controlsPropertyComparisons}
              iconName="chart-mixed"
              emptyCaption="No results found"
              rows={rows}
            />
          </Collapse>
        </div>
      );
    });
  }

  render() {
    const { methodologies, soilTemplates } = this.props.manage;
    const { propertyRounds } = this.props.properties;
    let { controls } = this.state;

    // Set filter bar options
    controls = updateControlOptions(
      controls,
      'method',
      methodologies.map((method) => ({ id: method.tag, name: method.name }))
    );
    controls = updateControlOptions(controls, 'baseline_id', propertyRounds);
    controls = updateControlOptions(controls, 'template_id', soilTemplates);

    // Load LSV results
    const sections = this.loadSections();

    return (
      <div>
        <PropertyResultsToolbar
          controls={controls}
          handleChange={this.handleChange}
          onClick={this.onRefresh}
        />
        {sections}
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    properties: store.properties,
    realm: store.realm,
    manage: store.manage,
  };
};

export default connect(mapStoreToProps)(ProjectPropertyComparisons);
