import React from 'react';
import { validateChange } from '../../../core/form/lib/validateForm';

class FormBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      formChanged: false,
      data: {},
      backlink: null,
      map: null,
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.checkAccess = this.checkAccess.bind(this);
    this.onMapLoad = this.onMapLoad.bind(this);
  }

  onMapLoad(map) {
    this.setState({ map });
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleChange(event) {
    const controls = validateChange(event, this.state.controls);

    this.setState({
      controls,
      formChanged: true,
    });
  }

  checkAccess(tag) {
    if (this.props.realm.currentApp.features.includes(tag)) {
      return true;
    }

    // this.accessMessage = `No access permissions for ${tag}`;
    return false;
  }

  async onSave() {}

  onCancel() {
    this.props.history.goBack();
    // this.props.history.push(this.state.backlink);
  }

  async onRemove() {}
}

export default FormBase;
