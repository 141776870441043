import React from 'react';

import Listview from 'jsx/components/core/form/components/Listview';
import moment from 'moment';
import { noop } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import { getLastChildValue } from 'jsx/lib/generic';
import { name as phasecode_name } from '../lib/phasecode';
import TimeFormat from '../../office/lib/timeFormat';

const ActivitiesSubLsv = ({
  darkTable = false,
  hideHeader = false,
  onLoadMore = noop,
  editAction = null,
  removeAction = null,
  rows = [],
  rowsCount = null,
}) => {
  const format = new TimeFormat();

  const renderCaption = (field, row) => {
    switch (field) {
      case 'project_phasecode':
        return phasecode_name(row.job.job_project, row.job.phasecode);
      case 'asset_id':
        return row?.asset?.name || '-';
      case 'job_id':
        return row?.job?.name || '-';
      case 'start_time':
        return moment(row?.start_time).format(format.time);
      case 'end_time':
        return moment(row?.end_time).format(format.time);
      default: {
        const caption = field.includes('.') ? getLastChildValue(field, row) : row[field];
        return caption || '-';
      }
    }
  };

  const renderRemove = (classes, index, row) => {
    const { iconName, onClick = noop } = removeAction;
    return (
      <td key={index} className={classes}>
        <Icon name={iconName} onClick={() => onClick(row.id, true)} />
      </td>
    );
  };

  const renderRows = (headers, row) => {
    const tableTd = headers.map(({ classes, field }, index) => {
      switch (field) {
        case 'edit': {
          const { iconName } = editAction;
          return (
            <td key={index} className={classes}>
              <Icon
                name={iconName}
                onClick={() => {
                  const { onClick } = editAction;
                  onClick(row);
                }}
              />
            </td>
          );
        }
        case 'remove': {
          return renderRemove(classes, index, row);
        }
        default: {
          return (
            <td key={index} className={classes}>
              {renderCaption(field, row)}
            </td>
          );
        }
      }
    });

    return tableTd;
  };

  const upperHeaders = [];

  const headers = [
    { caption: 'Asset #', field: 'asset_id', classes: 'text-left' },
    { caption: 'Work Activity', field: 'job_id', classes: 'text-left' },
    { caption: 'Project/Phasecode', field: 'project_phasecode', classes: 'text-left' },
    { caption: 'Labour Hrs', field: 'labour_hours', classes: 'text-right' },
    { caption: 'Start', field: 'start_time', classes: 'text-right' },
    { caption: 'End', field: 'end_time', classes: 'text-right' },
    { caption: 'Break Hrs', field: 'break_hours', classes: 'text-right' },
    { caption: 'Asset Hrs', field: 'asset_hours', classes: 'text-right' },
    { caption: 'Load Count', field: 'load_count', classes: 'text-right' },
    { caption: 'SMU Start', field: 'smu_start', classes: 'text-right' },
    { caption: 'SMU End', field: 'smu_end', classes: 'text-right' },
    { caption: 'Seq', field: 'sequence', classes: 'text-right' },
  ];

  if (editAction) {
    const { classes } = editAction;
    headers.push({ caption: '', field: 'edit', classes });
  }

  if (removeAction) {
    const { classes } = removeAction;
    headers.push({ caption: '', field: 'remove', classes });
  }

  const upperHeadTh = upperHeaders.map(({ cells, caption, classes }, index) => (
    <th key={index} colSpan={cells} className={classes}>
      {caption}
    </th>
  ));
  const tableHeadTh = headers.map(({ classes, caption }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  const tableBodyTr = (rows || []).map((row, index) => (
    <tr key={index} style={{ cursor: 'pointer' }}>
      {renderRows(headers, row)}
    </tr>
  ));

  const iconName = 'person-digging';
  const emptyCaption = 'No Activities found';

  return (
    <Listview
      darkTable={darkTable}
      upperHeadTh={upperHeadTh}
      hideHeader={hideHeader}
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
      rowsCount={rowsCount}
      onLoadMore={onLoadMore}
    />
  );
};

export default ActivitiesSubLsv;
