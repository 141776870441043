import React from 'react';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import DirectUpload from 'jsx/components/core/form/components/DirectUpload';
import { noop } from 'lodash';
import LivestockEnterpriseMenu from './LivestockEnterpriseMenu';

const LivestockEnterpriseToolbar = ({
  setDirectCostModal = noop,
  setLivestockIncomeModal = noop,
  setLivestockTransactionModal = noop,
  setLivestockOpeningModal = noop,
  setLivestockAdjustmentModal = noop,
  setLivestockBreedingModal = noop,
  setTransferModal = noop,
  onRefresh = noop,
  onImport = noop,
  showImportButton = false,
  schemaType = '',
}) => (
  <div className="d-flex justify-content-end">
    {showImportButton && (
      <DirectUpload
        caption="Import"
        size="sm"
        classNames="mr-2"
        handleUpload={onImport}
        schemaType={schemaType}
      />
    )}
    <LivestockEnterpriseMenu
      setDirectCostModal={setDirectCostModal}
      setLivestockIncomeModal={setLivestockIncomeModal}
      setLivestockTransactionModal={setLivestockTransactionModal}
      setLivestockOpeningModal={setLivestockOpeningModal}
      setLivestockAdjustmentModal={setLivestockAdjustmentModal}
      setLivestockBreedingModal={setLivestockBreedingModal}
      setTransferModal={setTransferModal}
    />
    <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
      <Icon name="rotate" />
    </Button>
  </div>
);

export default LivestockEnterpriseToolbar;
