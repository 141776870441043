import { noop } from 'lodash';
import Listview from '../../../../core/form/components/Listview';
import Pill from 'jsx/components/core/form/components/Pill';

const AdminProbeTemplateLsv = ({ rows, onClick = noop, id, section }) => {

  const renderFilters = (filterAttributes) => {
    const filters = [];
    const baseClasses = 'd-flex float-left m-1';

    filterAttributes?.divisions.map((division, index) => {
      const name = `Division: ${ division.name }`;
      const classes = `bg-success ${baseClasses}`;
      filters.push(<Pill size="sm" style={{fontSize: 12}} classes={classes} key={`${division.id} - ${index}`} caption={name} />);
    });

    filterAttributes?.enterprise_types.map((enterprise_type, index) => {
      const name = `Enterprise Type: ${ enterprise_type.name }`;
      const classes = `bg-pink ${baseClasses}`;
      filters.push(<Pill size="sm" classes={classes} key={`${enterprise_type.id} - ${index}`} caption={name} />);
    });

    if (filterAttributes && filterAttributes.aggregation) {
      const name = `Aggregation: ${ filterAttributes.aggregation?.name }`;
      const classes = `bg-orange ${baseClasses}`;
      filters.push(<Pill size="sm" classes={classes} caption={name} />);
    }

    return filters;
  };

  const renderRows = (headers, record) => {
    const show_benchmarks = (record.show_benchmarks ? 'Yes' : 'No');

    const tableTd = headers.map(({ caption, field, classes, sortColumn, styles }, index) => {
      switch (field) {
        case 'show_benchmarks': return (
          <td key={index} className={classes} data-sort-value={record[sortColumn]} style={styles}>{show_benchmarks}</td>
        );
        case 'filter_conditions': return (
          <td key={index} className={classes} data-sort-value={record[sortColumn]} style={styles}>
            <div >{renderFilters(record.attributes?.filter)}</div>
          </td>
        );
        default:
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]} style={styles}>
              {record[field] && record[field].name ? record[field].name : record[field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const iconName = 'ruler';
  const emptyCaption = 'No KPI Metrics found for this section';

  const headers = [
    { caption: 'KPI Group', field: 'group_caption', classes: 'text-left', styles: {width: '300px'} },
    { caption: 'Metric Name', field: 'metric_name', classes: 'text-left', styles: {width: '300px'} },
    { caption: 'Metric Key', field: 'metric_key', classes: 'text-left', styles: {width: '200px'}  },
    { caption: 'Unit', field: 'unit', classes: 'text-left', styles: {width: '110px'} },
    { caption: 'Filter Conditions', field: 'filter_conditions', classes: 'text-center' },
    { caption: 'Benchmarks', field: 'show_benchmarks', classes: 'text-center', styles: {width: '150px'} },
    { caption: 'Series', field: 'isSeries', classes: 'text-center', styles: {width: '150px'} },
  ];

  const tableHeadTh = headers.map(({ caption, classes, styles }, index) => (
    <th key={index} className={classes} style={styles}>
      {caption}
    </th>
  ));

  let tableBodyTr = [];
  let finalRows = rows;
  if (rows?.rows) finalRows = rows.rows;

  const haveRows = finalRows && finalRows.length > 0;
  if (haveRows) {
    tableBodyTr = finalRows.map((row, index) => (
      <tr key={index} onClick={() => onClick(row.id, section)} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={finalRows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default AdminProbeTemplateLsv;
