import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep, uniq } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import FormBase from '../../../core/form/components/FormBase';

import { controls as projectControls } from '../forms/project';
import {
  createProject,
  fetchProjects,
  removeProject,
  updateProject,
} from '../../projects/actions/projects';

import PlantProjectsToolbar from '../components/PlantProjectsToolbar';
import AssetProjectAssociationModal from './AssetProjectAssociationModal';
import { fetchOrgs, fetchUsers } from '../../../manage/actions/index';
import { fetchAttributes } from '../../portrait/actions/attributes';
import ProjectModal from './ProjectModal';
import LocationProjectAssociationModal from './LocationProjectAssociationModal';

class PlantProjects extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      assetProjectAssociationId: null,
      isNew: false,
      isAssetProjectModalOpen: false,
      isLocationProjectModalOpen: false,
      modalType: 'projects',
      params: {},
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.loadProjects = this.loadProjects.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.setAssetProjectModal = this.setAssetProjectModal.bind(this);
    this.setProjectModal = this.setProjectModal.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.refresh = this.refresh.bind(this);
    this.setLocationProjectModal = this.setLocationProjectModal.bind(this);
  }

  async componentDidMount() {
    const { location, dispatch, history } = this.props;
    this.loadProjects();

    await Promise.all([
      dispatch(fetchOrgs({ category_tag: 'owners' })),
      dispatch(fetchAttributes({ type: 'plant_asset_project_statuses' })),
      dispatch(fetchUsers()),
    ]);

    const searchParams = new URLSearchParams(location.search);
    const selectedProject = searchParams.get('selected');
    if (selectedProject) {
      this.setProjectModal(true, selectedProject);
      history.replace(location.pathname);
    }
  }

  handleSearchChange(search_value) {
    this.loadProjects({ search_value });
  }

  loadProjects(params = {}) {
    let fetchParams = params;
    if (Object.keys(params).length > 0) this.setState({ params });
    else fetchParams = this.state.params;

    if (params) this.props.dispatch(fetchProjects(fetchParams));
  }

  onClose() {
    this.loadProjects();
  }

  refresh() {
    this.loadProjects();
  }

  onRemove(id) {
    return this.props.dispatch(removeProject(id));
  }

  onSave(data, isNew) {
    const { modalType } = this.state;

    const saveData = cloneDeep(data);
    if (isNew) delete saveData.id;

    let saveMethod;
    switch (modalType) {
      case 'projects': {
        saveMethod = isNew ? createProject : updateProject;
        break;
      }
      default:
        break;
    }

    return this.props.dispatch(saveMethod(saveData));
  }

  setAssetProjectModal(isAssetProjectModalOpen, assetProjectAssociationId = null) {
    this.setState({ isAssetProjectModalOpen, assetProjectAssociationId });
  }

  setProjectModal(isProjectModalOpen, projectId = null) {
    this.setState({ isProjectModalOpen, projectId });
  }

  setModalOptions(type) {
    const { orgs, users } = this.props.manage;

    let options = {};
    switch (type) {
      case 'projects': {
        options = {
          allowRemoveMethod: true,
          title: 'Project',
          iconName: 'diagram-project',
          controls: cloneDeep(projectControls),
          options: {
            org_id: orgs,
            assignedto_id: users,
          },
        };
        break;
      }
      default:
        break;
    }

    return options;
  }

  setLocationProjectModal(isLocationProjectModalOpen) {
    this.setState({ isLocationProjectModalOpen });
  }

  render() {
    const {
      isProjectModalOpen,
      modalType,
      isAssetProjectModalOpen,
      assetProjectAssociationId,
      isLocationProjectModalOpen,
      projectId,
    } = this.state;
    const { projects } = this.props.projects;
    const iconName = 'diagram-project';
    const modalOptions = this.setModalOptions(modalType);

    const emptyCaptions = {
      projects: 'No Projects found.',
    };

    const icons = {
      projects: 'diagram-project',
    };

    return (
      <div className="p-2 h-100 d-flex flex-column">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row m-2">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Projects</h3>
          </div>
        </div>
        <ProjectModal
          setModal={this.setProjectModal}
          isOpen={isProjectModalOpen}
          id={projectId}
          refresh={this.refresh}
        />
        <LocationProjectAssociationModal
          setModal={this.setLocationProjectModal}
          isOpen={isLocationProjectModalOpen}
        />
        <AssetProjectAssociationModal
          setModal={this.setAssetProjectModal}
          isOpen={isAssetProjectModalOpen}
          id={assetProjectAssociationId}
          refresh={this.refresh}
        />
        <PlantProjectsToolbar
          handleSearchChange={this.handleSearchChange}
          onTransferPlant={this.setAssetProjectModal}
          onProjectAssociation={this.setLocationProjectModal}
          onReload={() => this.loadProjects()}
        />
        <Alert color="warning" className="my-2">
          Projects are synchronised from ViewPoint and can not be created/deleted from this
          application
        </Alert>

        <GenericLsv
          controls={modalOptions.controls}
          emptyCaption={emptyCaptions.projects}
          iconName={icons.projects}
          onClick={(id) => this.setProjectModal(true, id)}
          rows={projects}
        />
      </div>
    );
  }
}

const mapStoreToProps = ({ attributes, manage, projects, wipstar }) => ({
  attributes,
  manage,
  projects,
  wipstar,
});

export default connect(mapStoreToProps)(withContainerError(withRouter(PlantProjects)));
