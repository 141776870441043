import React from 'react';
import { connect } from 'react-redux';
import FormTab from '../../../core/form/components/FormTab';
import PageTitle from '../../../core/form/components/PageTitle';
import AgistmentToolbar from '../components/agistment/AgistmentToolbar';
import AgistmentExpensesLsv from '../components/AgistmentExpensesLsv';
import AgistmentIncomeLsv from '../components/AgistmentIncomeLsv';
import LivestockEnterprises from './LivestockEnterprises';
import AgistmentModal from './AgistmentModal';
import { Nav, TabContent, TabPane, Alert } from 'reactstrap';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';

class Agistments extends LivestockEnterprises {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      activeAgistmentTab: '10',
      isModalOpen: false,
      modalData: null,
      enterprises: null,
      animal_classes: null,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.goBack = this.goBack.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
  }

  componentDidMount() {
    this.toggleTab(this.props.livestocks.activeAgistmentTab);
  }

  goBack() {
    this.props.history.goBack();
  }

  setModalOptions(type) {
    let options = super.setModalOptions(type);
    options.title = `Agistment ${options.title}`;
    return options;
  }

  toggleTab(tab) {
    super.toggleTab(tab);

    if (this.props.livestocks.activeAgistmentTab !== tab) {
      this.props.dispatch({ type: 'SET_LIVESTOCK_AGISTMENT_ACTIVE_TAB', payload: tab });
    }
  }

  onRefresh() {
    let tab_id = this.props.livestocks.activeAgistmentTab;
    if (tab_id) this.toggleTab(tab_id);
  }

  render() {
    const { modalType, isModalOpen, modalData } = this.state;
    const { agistments, activeAgistmentTab } = this.props.livestocks;

    let incomeAgistments = [];
    let expenseAgistments = [];

    if (agistments && agistments.rows) {
      const incomeAgistmentsFilter = agistments.rows.filter(
        (agistment) => agistment.transaction_type.name === 'Income'
      );
      const expenseAgistmentsFilter = agistments.rows.filter(
        (agistment) => agistment.transaction_type.name === 'Expense'
      );

      incomeAgistments = { count: incomeAgistmentsFilter.length, rows: incomeAgistmentsFilter };
      expenseAgistments = {
        count: expenseAgistmentsFilter.length,
        rows: expenseAgistmentsFilter,
      };
    }

    let modalOptions = this.setModalOptions(modalType);
    const title = 'Agistments';
    return (
      <div className="p-0 h-100">
        <PageTitle title={title} />
        <AgistmentToolbar setModal={this.setModal} onRefresh={this.onRefresh} />
        <AgistmentModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setModal}
          data={modalData}
          isOpen={isModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
        />
        <Nav tabs className="mt-2">
          <FormTab
            caption="Income"
            tabId="10"
            activeTab={activeAgistmentTab}
            toggle={this.toggleTab}
            tabTag="income"
          />
          <FormTab
            caption="Expense"
            tabId="11"
            activeTab={activeAgistmentTab}
            toggle={this.toggleTab}
            tabTag="expense"
          />
        </Nav>

        <TabContent activeTab={activeAgistmentTab}>
          <TabPane tabId="10" className="mb-2 p-1">
            <Alert>
              Animals included here should NOT be included in the stockflow. This is infomation
              about other stock that been agisted onto your land.
            </Alert>
            <AgistmentIncomeLsv
              rows={incomeAgistments}
              onClick={(id) => {
                this.setModal(true, 'agistment_income', id);
              }}
            />
          </TabPane>
          <TabPane tabId="11" className="mb-2 p-1">
            <Alert>
              Animals included here SHOULD be included in the stockflow. This is infomation about
              your stock that have gone onto agistment on other land.
            </Alert>
            <AgistmentExpensesLsv
              rows={expenseAgistments}
              onClick={(id) => {
                this.setModal(true, 'agistment_expense', id);
              }}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = (store) => ({
  realm: store.realm,
  livestocks: store.livestocks,
  attributes: store.attributes,
  enterprises: store.enterprises,
  animal_classes: store.animal_classes,
  direct_costs: store.direct_costs,
});

export default connect(mapStoreToProps)(withContainerError(Agistments));
