import React from 'react';
import DirectDownload from '../../../../core/form/components/DirectDownload';
import Listview from "../../../../core/form/components/Listview";
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';

const StageRequirementItemsLsv = (props) => {
  const {
    rows,
    editItem,
    handleDownload,
  } = props;


  const renderRows = (headers, row) => {
    let iconColour;

    let statusName;
    if (row.external_reference.mimetype) {
      iconColour = "green";
      statusName = "Document Available";
    } else {
      iconColour = "red";
      statusName = "Document Missing"
    }

    let type_key = checkFileType(row.external_reference.mimetype);

    // Check due date
    let dueDate;
    let dueClass;
    if (row.due) {
      dueDate = moment(row.due).fromNow();
      const now = moment();
      const then = moment(row.due)
      const diff = now.diff(then, 'minutes')

      if (diff > 0) {
        // Overdue
        dueClass = 'text-danger'
      } else if (diff < 0 && diff > (-3 * 60)) {
        // Due Soon
        dueClass = 'text-warning'
      } else {
        // All good
        dueClass = 'text-success'
      }
    }

    // Check validation
    let validation = 'Not validated';
    if (row.validatedby_id) {
      validation = `${moment(row.validated).format('YYYY-MM-DD HH:mm')} by ${row.validatedby.name}`;
    }

    // Link document
    let downloadLink = (<div className="text-secondary">--</div>)
    if (row.external_reference.mimetype) {
      downloadLink = (<DirectDownload
        classNames="p-0"
        buttonType="primary"
        handleDownload={(onProgressChange) => handleDownload(row.id, onProgressChange)}
        showLabel={true}
        showIcon={true}
      />)
    }

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case "document": return (
          <td className={`${header.classes}`} key={index}>{downloadLink}</td>
        )
        case "validation": return (
          <td onClick={() => editItem(row.id)} className={`${header.classes}`} key={index}>{validation}</td>
        )
        case "due": return (
          <td onClick={() => editItem(row.id)} className={`${header.classes} ${dueClass}`} key={index}>{dueDate}</td>
        )
        case "category": return (
          <td onClick={() => editItem(row.id)} style={{ color: iconColour }} className={`${header.classes}`} key={index}>{row.category.name}</td>
        )
        case "status": return (
          <td onClick={() => editItem(row.id)} style={{ color: iconColour }} className={`${header.classes}`} key={index}>{statusName}</td>
        )
        case "type_key": return (
          <td onClick={() => editItem(row.id)} className={`${iconColour} ${header.classes}`} key={index}>{row.type_key}</td>
        )
        case 'file_type': return (
          <td key={index} className={`${iconColour} ${header.classes}`}><Icon name={type_key}/></td>
        )
        default: return (
          <td onClick={() => editItem(row.id)} className={`${iconColour} ${header.classes}`} key={index}>{row[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const checkFileType = (file_type) => {
    switch (true) {
      case /^image/.test(file_type): return 'file-image';
      case /^video/.test(file_type): return 'file-video';
      case /^audio/.test(file_type): return 'file-video';
      case /excel/.test(file_type): return 'file-excel';
      case /word/.test(file_type): return 'file-word';
      case /pdf$/.test(file_type): return 'file-pdf';
      case /octet-stream$/.test(file_type): return 'file-code';
      default: return 'file';
    }
  }

  const iconName = 'folder-open';
  const emptyCaption = "No Requirement Items found. Add Document definition and task required to fully complete the overall requirement.";

  const headers = [
    { caption: '', field: 'file_type', classes: 'text-center' },
    { caption: 'Item Description', field: 'name', classes: "text-left" },
    { caption: 'Type', field: 'type_key', classes: "text-left" },
    { caption: 'Due Date', field: 'due', classes: "text-left" },
    { caption: 'Category', field: 'category', classes: "text-left" },
    { caption: 'Status', field: 'status', classes: "text-left" },
    { caption: 'Validation', field: 'validation', classes: "text-left" },
    { caption: 'Document', field: 'document', classes: "text-right" }
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => { }} style={{ cursor: "pointer" }}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  return (
    <div>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </div>
  )
}

export default StageRequirementItemsLsv;
