import { allowed, defaults } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    responseMessage: null,
    activeTab: 'enterprises',
    authorised: true,
    transactional: true,
    project: null,
    current_client_org: null,
  },
  action
) {
  // Set expected reducer types
  const types = ['FARMEYE', 'PORTAL', 'CLIENT'];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'SET_FARMEYE_ACTIVETAB': {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case 'SET_PORTAL_PROJECT': {
      return {
        ...state,
        project: action.payload,
      };
    }
    case 'SET_CURRENT_CLIENT': {
      return {
        ...state,
        current_client_org: action.payload,
      };
    }
    default:
      break;
  }
  return state;
}
