import React from 'react';

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../constants/authConfigMicrosoft";

import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const LoginMicrosoft = (props) => {
  const {
    onAzureLogin,
    onAzureError
  } = props;

  let { instance, accounts } = useMsal();
  let isAuthenticated = useIsAuthenticated();

  const onLogin = async () => {

    try {
      let response = await instance.loginPopup(loginRequest);
      if (response) {
        isAuthenticated = true;
        accounts = [response.account]
      }
      await onAzureLogin(isAuthenticated, instance, accounts);
    } catch (error) {
      console.log("Error", error.message);
      onAzureError(`Authentication Failure: ${error.message}`);
    }
  }

  return (
    <React.Fragment>
      <Button onClick={onLogin} className="bg-warning mt-3 w-100 mb-3">
        <Icon size="1x" name="microsoft" className="text-white mr-3" />
        <span>Sign in with Microsoft</span>
      </Button>
    </React.Fragment>
  )
}

export default LoginMicrosoft;
