import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import SingleSearch from '../../../core/form/components/SingleSearch';

const CoresToolbar = (props) => {
  const {
    statuses,
    scanValids,
    operators_scan,
    updateSelected,
    refresh,
    modifyDisabled,
    onSearch,
    handleSearchChange,
    searchValue,
    checkAccess,
  } = props;

  const statusItems = statuses.map((status, index) => {
    return (
      <DropdownItem key={index} onClick={() => updateSelected('status_id', status.id)}>
        {status.name}
      </DropdownItem>
    );
  });

  const scanValidItems = scanValids.map((scanValid, index) => {
    return (
      <DropdownItem key={index} onClick={() => updateSelected('scan_valid', scanValid.id)}>
        {scanValid.name}
      </DropdownItem>
    );
  });

  const operatorsScanItems = operators_scan.map((operator, index) => {
    return (
      <DropdownItem key={index} onClick={() => updateSelected('scannedby_id', operator.id)}>
        {operator.name}
      </DropdownItem>
    );
  });

  return (
    <React.Fragment>
      <div className="bg-light p-2 d-flex justify-content-end">
        <SingleSearch
          handleSearchChange={handleSearchChange}
          onSearch={onSearch}
          placeholder={`Search`}
          value={searchValue}
        />

        <Button color="success" onClick={refresh} className="mr-2" size="sm">
          <Icon name="rotate" />
        </Button>

        <UncontrolledButtonDropdown>
          <DropdownToggle
            disabled={modifyDisabled || !checkAccess('siteUpdate')}
            caret
            color="primary"
            size="sm"
          >
            Modify Selected{' '}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header className="bg-light">
              Set Status
            </DropdownItem>
            {statusItems}

            <DropdownItem divider />

            <DropdownItem header className="bg-light">
              Set Validity
            </DropdownItem>
            {scanValidItems}

            <DropdownItem divider />

            <DropdownItem header className="bg-light">
              Set Scan Operator
            </DropdownItem>
            {operatorsScanItems}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </React.Fragment>
  );
};

export default CoresToolbar;
