import React from 'react';
import WidgetGroup from './WidgetGroup';

const WidgetGroups = (props) => {
  const { groups, onEditMetric } = props;

  const renderGroups = () => {
    return groups.map((group, index) => {
      if (group.metrics && group.metrics.length > 0) {
        return <WidgetGroup key={index} group={group} onEditMetric={onEditMetric} />;
      }

      return false;
    });
  };

  return <div className="b">{renderGroups()}</div>;
};

export default WidgetGroups;
