import { get, post, put, remove } from 'jsx/lib/genericAction';
import {
  apiProjectsRegisters,
  apiProjectsProperties,
  apiStatuses,
  apiUsers,
  apiAttributes,
  apiProperties,
  apiProjects,
} from 'jsx/constants/api';
import { createParamsFromFilters } from 'jsx/lib/actions';
import { updateListviewConfiguration } from 'jsx/components/manage/actions/listview_configuration';
import { selectProjectRegisterFilters } from '../selectors';

export const projectRegisterInstanceName = 'project-register-lsv';

export function createProject(projectId) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_PROJECT_REGISTER_PROJECT', `${apiProjects}/${projectId}/registers`);
}

export function createProjectProperty(propertyId) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_PROJECT_REGISTER_PROPERTY', `${apiProperties}/${propertyId}/registers`);
}

export function getProjects({ filters = {}, params = {} } = {}) {
  const paramsWithFilters = { ...params, ...createParamsFromFilters(filters) };

  return async (dispatch) =>
    get(dispatch, 'GET_PROJECT_REGISTER_PROJECTS', `${apiProjectsRegisters}`, paramsWithFilters);
}

export function getProject(id) {
  return async (dispatch) =>
    get(dispatch, 'GET_PROJECT_REGISTER_PROJECT', `${apiProjects}/${id}/registers`);
}

export function updateProjectRegisterProject(id, params) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_PROJECT_REGISTER_PROJECT', `${apiProjects}/${id}/registers`, params);
}

export function updateProjectPropertyRegister(property_id, params) {
  return async (dispatch) =>
    put(
      dispatch,
      'UPDATE_PROJECT_PROPERTY_REGISTER',
      `${apiProperties}/${property_id}/registers`,
      params,
    );
}

export function getProjectStatuses(inBackground = false) {
  return async (dispatch) =>
    get(
      dispatch,
      'GET_PROJECT_REGISTER_STATUSES',
      `${apiStatuses}?type=project`,
      null,
      null,
      inBackground,
    );
}

export function getProjectOfficers() {
  return async (dispatch) => get(dispatch, 'GET_PROJECT_REGISTER_OFFICERS', `${apiUsers}/roles/po`);
}

export function updateVariation({ id, data }) {
  return async (dispatch) =>
    put(
      dispatch,
      'UPDATE_PROJECT_REGISTER_VARIATION',
      `${apiProjectsProperties}/variations/${id}`,
      data,
    );
}

export function createVariation({ data }) {
  return async (dispatch) =>
    post(
      dispatch,
      'CREATE_PROJECT_REGISTER_VARIATION',
      `${apiProjectsProperties}/variations/`,
      data,
    );
}

export function removeVariation(id) {
  return async (dispatch) =>
    remove(
      dispatch,
      'REMOVE_PROJECT_REGISTER_VARIATION',
      `${apiProjectsProperties}/variations/${id}`,
    );
}

export function updateNote({ id, data }) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_PROJECT_REGISTER_NOTE', `${apiProjectsProperties}/notes/${id}`, data);
}

export function createNote({ data }) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_PROJECT_REGISTER_NOTE', `${apiProjectsProperties}/notes/`, data);
}

export function removeNote(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_PROJECT_REGISTER_NOTE', `${apiProjectsProperties}/notes/${id}`);
}

export function updatePeriod({ id, data }) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_PROJECT_REGISTER_PERIOD', `${apiProjectsProperties}/periods/${id}`, data);
}

export function createPeriod({ data }) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_PROJECT_REGISTER_PERIOD', `${apiProjectsProperties}/periods/`, data);
}
export function removePeriod(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_PROJECT_REGISTER_PERIOD', `${apiProjectsProperties}/periods/${id}`);
}

export function updateAudit({ id, data }) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_PROJECT_REGISTER_AUDIT', `${apiProjectsProperties}/audits/${id}`, data);
}

export function createAudit({ data }) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_PROJECT_REGISTER_AUDIT', `${apiProjectsProperties}/audits/`, data);
}

export function removeAudit(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_PROJECT_REGISTER_AUDIT', `${apiProjectsProperties}/audits/${id}`);
}

export function updateClient({ id, data }) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_PROJECT_REGISTER_CLIENT', `${apiProjectsProperties}/clients/${id}`, data);
}

export function createClient({ data }) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_PROJECT_REGISTER_CLIENT', `${apiProjectsProperties}/clients/`, data);
}

export function removeClient(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_PROJECT_REGISTER_CLIENT', `${apiProjectsProperties}/clients/${id}`);
}

export function fetchAttributes(params) {
  return async (dispatch) =>
    get(
      dispatch,
      `FETCH_PROJECT_REGISTER_ATTRIBUTES${params.type ? `_${params.type.toUpperCase()}` : ''}`,
      `${apiAttributes}`,
      params,
    );
}

export function setProjectRegisterFilter(filterToUpdate, value) {
  return {
    type: 'SET_PROJECT_REGISTER_FILTER',
    payload: {
      filterToUpdate,
      value,
    },
  };
}

export function setProjectRegisterFilters(filters) {
  return {
    type: 'SET_PROJECT_REGISTER_FILTERS',
    payload: filters,
  };
}

export function saveProjectRegisterFilter() {
  return async (dispatch, getState) => {
    const filters = selectProjectRegisterFilters(getState());
    dispatch(updateListviewConfiguration(projectRegisterInstanceName, { filters }), true);
  };
}
