import React from 'react';
import { noop } from 'lodash';
import { Button } from 'reactstrap';

const Footer = ({
  closeButtonText = 'Cancel',
  removeButtonText = 'Delete',
  saveButtonText = 'Save',
  isNew = true,
  onClose = noop,
  onRemove = noop,
  onSave = noop,
}) => {
  return (
    <>
      <Button size="sm" className="mr-2" color="success" onClick={() => onSave()}>
        {saveButtonText}
      </Button>
      <Button size="sm" color="light" onClick={() => onClose()}>
        {closeButtonText}
      </Button>
      {!isNew && (
        <Button size="sm" color="danger" onClick={() => onRemove()}>
          {removeButtonText}
        </Button>
      )}
    </>
  );
};

export default Footer;
