import { Controls } from 'jsx/components/core/form/components/FormBuilder';
import { columnWidths } from '../../portrait/constants/listviews';

export const controls: Controls<string> = {
  asset_code: {
    name: 'asset_code',
    path: 'asset.name',
    caption: 'Asset Code',
    type: 'text',
    listview: {
      show: true,
      width: `${columnWidths.small}px`,
    },
  },
  asset_description: {
    name: 'asset_description',
    path: 'asset.description',
    caption: 'Asset Description',
    type: 'text',
    listview: {
      show: true,
    },
  },
  location: {
    name: 'location',
    path: 'location.name',
    caption: 'Location',
    type: 'text',
    listview: {
      show: true,
    },
  },
  transfer_date: {
    name: 'transfer_date',
    path: 'from_date',
    caption: 'Transfer Date',
    type: 'date',
    listview: {
      show: true,
    },
  },
  to_date: {
    name: 'to_date',
    path: 'to_date',
    caption: 'End Date',
    type: 'date',
    listview: {
      show: true,
    },
  },
  status: {
    name: 'status',
    path: 'status.name',
    caption: 'Status',
    type: 'text',
    listview: {
      show: true,
    },
  },
  project: {
    name: 'project',
    path: 'project.name',
    caption: 'Project',
    type: 'text',
    listview: {
      show: true,
    },
  },
  project_no: {
    name: 'project_no',
    path: 'project.project_job_no',
    caption: 'Project No',
    type: 'text',
    listview: {
      show: true,
    },
  },
  contact: {
    name: 'contact',
    path: 'contact.name',
    caption: 'Contact',
    type: 'text',
    listview: {
      show: true,
    },
  },
  mobile: {
    name: 'mobile',
    path: 'contact.mobile',
    caption: 'Mobile',
    type: 'text',
    listview: {
      show: true,
    },
  },
};
