import React from 'react';
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button
} from 'reactstrap';

import {
  updateControls,
  initControls,
  saveControls
} from "../../../../core/form/lib/validateForm";

import { controls } from "../../forms/projectPropertyRound";
import FormInput from "../../../../core/form/components/FormInput";
import FormBase from "../../../../core/form/components/FormBase";
import Icon from 'jsx/components/core/icons/Icon';

import {
  fetchPropertyRounds,
  fetchPropertyRound,
  createPropertyRound,
  updatePropertyRound,
  removePropertyRound
} from '../../actions/property_rounds';

import { fetchDataPreparations } from '../../../../manage/actions';

class PropertyRoundModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isValid: true,
      isOpen: false,
      title: 'Property Round',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls,
      project_id: null
    })

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.bindOptions = this.bindOptions.bind(this);
  }

  async componentDidUpdate() {
    let {
      isOpen,
      id,
      isNew,
      title,
      controls
    } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = `New Round`;
      this.setState({ controls: initControls(this.state.controls) })

      this.props.dispatch(fetchDataPreparations())

      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = `Edit Round`;
        const result = this.props.dispatch(fetchPropertyRound(id));
        result.then(data => {
          controls = updateControls(controls, data);
          this.setState({ controls, data })
        })
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        property_id: this.props.property_id,
        setModal: this.props.setModal,
      })
    }

    this.bindOptions();

  }
  bindOptions() {
    let { controls } = this.state;
    const {
      data_preparations
    } = this.props.manage;

    const emptyValue = '-';
    const emptyOption = [{ id: null, name: emptyValue }];

    if (data_preparations.length > 0) {
      controls['data_preparation_id'].options = emptyOption.concat(data_preparations);
      if (controls['data_preparation_id'].value === emptyValue) {
        controls['data_preparation_id'].value = null;
        this.setState({ controls })
      }
    }
  }

  async onSave() {
    let {
      data,
      controls,
      isNew,
      property_id
    } = this.state;

    if (!controls['data_preparation_id'].value) {
      this.props.dispatch({ type: 'UPDATE_PROPERTY_ROUNDS_REJECTED', payload: 'Data Preparation Method cannot be blank.' })
      return;
    }

    data = saveControls(controls, data)

    let success;
    if (isNew) {
      delete data.id
      data.property_id = property_id;
      success = await this.props.dispatch(createPropertyRound(data));
    } else {
      success = await this.props.dispatch(updatePropertyRound(data));
    }

    if (success) {
      this.props.dispatch(fetchPropertyRounds(property_id));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const {
      data,
      property_id
    } = this.state;

    const confirmed = window.confirm(`Removing this round and all associated requirements permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removePropertyRound(data.id));
      if (success) {
        this.props.dispatch(fetchPropertyRounds(property_id));
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  render() {
    const {
      controls,
      isOpen,
      title,
      isNew
    } = this.state;

    const {
      responseMessage
    } = this.props.properties;

    const iconName = 'layer-group';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && (
            <div className="text-center text-danger">{responseMessage}</div>
          )}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput handleChange={this.handleChange} control={controls.subsample_type} />
            <FormInput handleChange={this.handleChange} control={controls.selections_seed} />
            <FormInput handleChange={this.handleChange} control={controls.selections_count} />
            <FormInput handleChange={this.handleChange} control={controls.backup_selections_count} />
            <FormInput handleChange={this.handleChange} control={controls.sites_seed} />
            <FormInput handleChange={this.handleChange} control={controls.tonnes_biochar} />
            <FormInput handleChange={this.handleChange} control={controls.data_preparation_id} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          {!isNew && (<Button size="sm" color="danger" onClick={this.onRemove} >Delete</Button>)}
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Cancel</Button>
            <Button size="sm" color="success" onClick={this.onSave}>Save</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    properties: store.properties,
    manage: store.manage
  }
}

export default connect(mapStoreToProps)(PropertyRoundModal);
