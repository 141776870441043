import React from 'react';
import { connect } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import { Card } from 'reactstrap';

import Loader from '../components/core/loader/components/Loader';
import Downloads from '../components/modules/areas/lib/downloads';

class Download extends React.Component {
  constructor() {
    super();

    this.state = {
      tag: null,
      loading: false,
      name: '',
      value: '',
    };

    this.areaDownloads = new Downloads();

    this.download = this.download.bind(this);
  }

  async componentDidMount() {
    let { tag } = this.state;

    tag = this.props.match.params.tag;
    const round_id = this.props.match.params.round_id;

    this.areaDownloads.set(round_id, this.props.dispatch);

    this.download(tag);
  }

  async download(tag) {
    let response;

    this.setState({ loading: true });

    switch (tag) {
      case 'round_validation':
        this.setState({ name: 'Round Validation Pack' });
        response = await this.areaDownloads.handleValidationPackDownload((value) => {
          this.setState({ value });
        });
        break;
      default:
        response = false;
        break;
    }

    if (response) {
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'file.pdf';
      if (contentDisposition) {
        filename = contentDisposition.split('=')[1];
      }

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    this.setState({ loading: false });
  }

  render() {
    const { loading, name, value } = this.state;

    const { currentApp } = this.props.realm;

    return (
      <div className="container-fluid p-0 h-100 bg-light row align-items-center d-flex justify-content-center">
        <Loader loading={loading}>
          <Card className="p-2 card-border-color border-corporate login text-center">
            <Icon size="4x" name={currentApp.icon} className="text-corporate m-3" />
            <h3 className="text-secondary mt-2">{currentApp.title}</h3>
            <div className="m-4">
              <p>{name}</p>
              {loading && <p>{`Preparing ${value}`}</p>}
              {!loading && <p>{`Done!`}</p>}
            </div>
          </Card>
        </Loader>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(Download);
