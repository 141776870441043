import React from 'react';
import {
  Button
} from 'reactstrap';

const BusinessExpenditureToolbar = (props) => {
  const {
    onAddNew
  } = props;

  return (
    <div className="d-flex justify-content-between m-1 p-1 rounded bg-light">
      <div className=" text-danger p-1">Do not include plant and equipment purchases, these will be included on the Assets page.</div>
      <Button onClick={onAddNew} size="sm" color="success">Add Capital Expenditure</Button>
    </div>
  );
}

export default BusinessExpenditureToolbar
