import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import DirectDownload from '../../../../core/form/components/DirectDownload';

const PropertyRoundMenu = (props) => {
  const {
    handleReportingPackDownload,
    handleLocationsDownload,
    handleSitesDownload,
    handleSiteGenerate,
    handleSelectionScansDownload,
    allowSite,
    checkAccess,
  } = props;

  return (
    <React.Fragment>
      <div className="mr-2">
        <UncontrolledButtonDropdown>
          <DropdownToggle caret color="primary" size="sm">
            Action{' '}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem divider />
            <DropdownItem header className="bg-light">
              Download Actions
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(event) => handleLocationsDownload(event)}
                caption="Download Locations Pack"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(event) => handleReportingPackDownload(event)}
                caption="Download Reporting Pack"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(onProgressChange) =>
                  handleSelectionScansDownload(onProgressChange)
                }
                caption="Download Lab Selection Scans Report"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(event) => handleSitesDownload(event)}
                caption="Download Sites GeoJSON"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem divider />
            <DropdownItem header className="bg-light">
              Upload Actions
            </DropdownItem>

            <DropdownItem
              toggle={true}
              onClick={handleSiteGenerate}
              disabled={(!checkAccess('propertyMapUpdate') && !allowSite) || !allowSite}
            >
              Generate Site Geometry
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </React.Fragment>
  );
};

export default PropertyRoundMenu;
