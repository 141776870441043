import React from 'react';
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Progress
} from 'reactstrap';
import { initControls, saveControls, controlDefaults } from "../../../core/form/lib/validateForm";
import FormInput from "../../../core/form/components/FormInput";
import FormBase from "../../../core/form/components/FormBase";
import ResponseMessage from "../../../core/form/components/ResponseMessageTab";
import Icon from 'jsx/components/core/icons/Icon';


class AreaUploadModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isOpen: false,
      title: 'Upload Area Details',
      rows: [],
      data: {},
      fileName: null,
      controls: {
        reason: {
          ...controlDefaults,
          placeholder: '',
          name: 'reason',
          type: 'textarea',
          caption: 'Reason',
          textRows: 2,
          validationRules: {
            isRequired: true
          },
        }
      }
    })

    this.inputFileRef = React.createRef(null);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.handleFileChoice = this.handleFileChoice.bind(this);
  }

  async componentDidUpdate() {
    const {
      isOpen,
    } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      this.setState({
        controls: initControls(this.state.controls),
        isOpen: this.props.isOpen
      })
    }
  }

  async onSave() {
    const { controls } = this.state;
    let { data } = this.state;

    const {
      onSave
    } = this.props;

    this.setState({
      isUploading: true
    })

    data = saveControls(controls, data)

    const success = await onSave(this.inputFileRef.current.files, this.updateProgress, data.reason);
    if (!success) {
      this.setState({
        isUploading: false
      })
    } else {
      this.onCancel();
    }

  }

  onCancel() {
    this.props.setModal(false);
    this.setState({isOpen: false});
  }

  updateProgress(value) {
    let {
      fileName,
      isUploading
    } = this.state;


    if (value === 100) {
      this.inputFileRef.current.value = null;
      isUploading = false;
      value = 0;
      fileName = null;
    }

    this.setState({
      progressValue: value,
      isUploading: isUploading,
      fileName: fileName
    })
  }


  handleBtnClick() {
    this.inputFileRef.current.click();
  }

  handleFileChoice() {
    this.setState({
      fileName: this.inputFileRef.current.files[0].name
    })
  }

  render() {
    const {
      isOpen,
      title,
      controls,
      isUploading,
      progressValue,
      fileName
    } = this.state;

    const {
      rows
    } = this.props;

    const {
      responseMessage
    } = this.props.areas;

    const existing_areas = [];
    rows.map((row) => {
      existing_areas.push(row.name);
      return false;
    })

    const iconName = 'circle-notch';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {existing_areas.length > 0 && (
            <div className="mb-2">
              <div className="text-center text-danger">{`WARNING - There are ${existing_areas.length} existing areas in this property!`}</div>
              <div className="text-center text-danger">Uploading will overwrite these area geometries. Reason must be provided to upload new geometries.</div>
              <Form>
                <FormInput handleChange={this.handleChange} control={controls.reason} />
              </Form>
            </div>
          )}
          <div className='d-flex justify-content-between'>
            {isUploading && (
              <Progress style={{ width: 450 }} className={'m-2 text-center'} animated value={progressValue}>{progressValue}%</Progress>
            )}
            {!isUploading && (<Button id="caret" color="primary" onClick={this.handleBtnClick}>Choose File</Button>)}
            {fileName && (<div className='mt-2'>{fileName}</div>)}
            <input style={{ display: 'none' }} onChange={this.handleFileChoice} type="file" ref={this.inputFileRef} />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <ResponseMessage responseMessage={responseMessage} />
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Cancel</Button>
            <Button size="sm" color="success" onClick={this.onSave}>Confirm</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    areas: store.areas
  }
}

export default connect(mapStoreToProps)(AreaUploadModal);
