import { apiAnalysisPeriods } from 'jsx/constants/api';
import { get, post, put, remove } from 'jsx/lib/genericAction';

export function fetchAnalysisPeriods(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ANALYSIS_PERIODS', `${apiAnalysisPeriods}`, params);
}

export function fetchAnalysisPeriod(params) {
  return (dispatch) =>
    get(dispatch, 'FETCH_ANALYSIS_PERIOD', `${apiAnalysisPeriods}/${params.id}`, params);
}

export function updateAnalysisPeriod(data) {
  return (dispatch) =>
    put(dispatch, 'UPDATE_ANALYSIS_PERIOD', `${apiAnalysisPeriods}/${data.id}`, data);
}

export function createAnalysisPeriod(data) {
  return (dispatch) => post(dispatch, 'CREATE_ANALYSIS_PERIOD', `${apiAnalysisPeriods}`, data);
}

export function removeAnalysisPeriod(id) {
  return (dispatch) => remove(dispatch, 'REMOVE_ANALYSIS_PERIOD', `${apiAnalysisPeriods}/${id}`);
}

export function setAnalysisPeriodParams(params) {
  return async (dispatch) => {
    dispatch({ type: 'SET_ANALYSIS_PERIOD_PARAMS', payload: params });
    return dispatch(fetchAnalysisPeriods(params));
  };
}
