import Icon from 'jsx/components/core/icons/Icon';
import React from 'react';
import { useState } from 'react';
import Listview from '../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';
import AssetTransactionsLsv from './AssetTransactionsLsv';

const AssetflowLsv = (props) => {
  let { rows, transactions, editAsset, editTransaction } = props;

  const [renderState, setRenderState] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({});

  const openAssets = async (event, id) => {
    event.preventDefault();
    event.stopPropagation();

    collapsedSections[id] = !collapsedSections[id];
    setCollapsedSections(collapsedSections);

    setRenderState(!renderState);
  };

  const renderAssetTransactions = (asset_type, columnWidths) => {
    // Filter transactions by type
    const filtered_transactions = transactions.filter(
      (transaction) => transaction?.asset?.asset_type_id === asset_type
    );

    return (
      <AssetTransactionsLsv
        darkTable={true}
        hideHeader={false}
        rows={filtered_transactions}
        editAsset={editAsset}
        editTransaction={editTransaction}
        columnWidths={columnWidths}
      />
    );
  };

  const isAssetOpen = (id) => collapsedSections[id];

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const { classes, field, formattingRules, width } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (let key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }

      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      switch (field) {
        case 'toggle': {
          return (
            <td key={index} className={classes} width={width}>
              <small className="mt-1 mr-2">
                <Icon
                  name={collapsedSections[row.asset_type_id] ? 'chevron-up' : 'chevron-down'}
                  className="text-corporate"
                />
              </small>
            </td>
          );
        }
        case 'opening.value':
        case 'closing.value':
        case 'sales':
        case 'purchases':
        case 'adjustments': {
          let tooltip;
          let tooltip_classes;
          // Highlight any discrepancies
          if (field === 'closing.value' && caption < 0) {
            tooltip_classes = 'bg-danger text-white';
            tooltip = 'Negative Values';
          }

          return (
            <td
              key={index}
              className={`${classes} ${tooltip_classes}`}
              title={tooltip ?? null}
              width={width}
            >
              {caption}
            </td>
          );
        }
        default:
          return (
            <td key={index} className={classes} width={width}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  let columnWidths = {
    common: 200,
    icon: 50,
    offset: 8,
  };
  let headers = [
    {
      caption: 'Asset Category',
      field: 'asset_category.name',
      classes: 'text-left',
      width: `${columnWidths.common}px`,
    },
    {
      caption: 'Asset Type',
      field: 'asset_type.name',
      classes: 'text-left',
      width: `${columnWidths.common}px`,
    },
    {
      caption: 'Opening Value',
      field: 'opening.total',
      classes: 'text-right border-left border-right',
      totals: true,
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
      width: `${columnWidths.common}px`,
    },
    {
      caption: 'Purchases ($)',
      field: 'closing.purchases',
      classes: 'text-right border-left',
      totals: true,
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
    },
    {
      caption: 'Sales ($)',
      field: 'closing.sales',
      classes: 'text-right',
      totals: true,
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
    },
    {
      caption: 'Transfers ($)',
      field: 'closing.transfers',
      classes: 'text-right',
      totals: true,
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
    },
    {
      caption: 'Adjustments ($)',
      field: 'closing.adjustments',
      classes: 'text-right',
      totals: true,
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
    },
    {
      caption: 'Closing Value',
      field: 'closing.total',
      classes: 'text-right border-left ',
      totals: true,
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
      width: `${columnWidths.common}px`,
    },
    {
      caption: '',
      field: 'toggle',
      classes: 'text-center',
      width: `${columnWidths.icon}px`,
    },
  ];

  // Get unique distribution headers
  const toEnd = 1;
  const headerPosition = 3;
  rows.forEach((row) => {
    // Sort distributions by division name alphabetically
    row.distributions.sort((a, b) =>
      a?.division?.name ? a.division.name.localeCompare(b.division.name) : toEnd
    );
    row.distributions.forEach((distribution, idx) => {
      // Only add to headers if unique
      let caption = `${distribution.division.name} (%)`;
      let headers_idx = headers.findIndex((existing_header) => existing_header.caption === caption);

      if (headers_idx === -1) {
        // Add idx to position number when splicing to keep alphabetical order of division names
        headers.splice(headerPosition + idx, 0, {
          caption,
          field: `distributions.${idx}.distribution_pcnt`,
          classes: 'text-right',
          formattingRules: {
            asPercentage: true,
            includePercentageSign: true,
            includeDecimals: true,
          },
          width: `${columnWidths.common}px`,
        });
      }
    });
  });

  let headerTotals = [];
  const tableHeadTh = headers.map(({ caption, classes, totals }, index) => {
    if (totals) headerTotals.push(index);
    return (
      <th key={index} className={classes}>
        {caption}
      </th>
    );
  });

  let tableBodyTr = <tr></tr>;
  if (rows?.rows) rows = rows.rows;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row) => (
      <React.Fragment key={row.id}>
        <tr className={'cursor-pointer'} onClick={(event) => openAssets(event, row.asset_type_id)}>
          {renderRows(headers, row)}
        </tr>
        {isAssetOpen(row.asset_type_id) && (
          <tr>
            <td className="bg-white" colSpan={headers.length + 1}>
              {renderAssetTransactions(row.asset_type_id, columnWidths)}
            </td>
          </tr>
        )}
      </React.Fragment>
    ));
  }

  const iconName = 'cow';
  const emptyCaption = 'No assets found. Have you added any assets yet?';
  const totalFormattingRules = {
    includeCommas: true,
    includeDollarSign: true,
    includeDecimals: true,
  };

  return (
    <React.Fragment>
      <Listview
        rows={rows || []}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
        totals={headerTotals}
        totalFormattingRules={totalFormattingRules}
      />
    </React.Fragment>
  );
};

export default AssetflowLsv;
