/**
 * Largely a copy-paste from https://floating-ui.com/docs/tooltip
 */
import * as React from 'react';
import {
  useFloating,
  autoUpdate,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  flip,
  arrow,
  offset,
} from '@floating-ui/react';

export function useTooltip({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  styleOverride = {},
} = {}) {
  const arrowRef = React.useRef(null);
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip(), arrow({ element: arrowRef }), offset(10)],
  });

  const { context } = data;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      styleOverride,
      arrowRef,
    }),
    [open, setOpen, interactions, data, styleOverride, arrowRef],
  );
}

const TooltipContext = React.createContext(null);

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

export const Tooltip = ({ children, ...options }) => {
  // This can accept any props as options, e.g. `placement`, or other positioning options.
  const tooltip = useTooltip(options);

  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
};

export const TooltipTrigger = React.forwardRef(
  ({ children, asChild = false, ...props }, propRef) => {
    const context = useTooltipContext();
    const childrenRef = children.ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    const triggerProps = context.getReferenceProps({
      ref,
      ...props,
      ...children.props,
      'data-state': context.open ? 'open' : 'closed',
    });

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && React.isValidElement(children)) {
      return React.cloneElement(children, triggerProps);
    }

    return (
      <button
        ref={ref}
        data-state={context.open ? 'open' : 'closed'}
        {...context.getReferenceProps(props)}
      >
        {children}
      </button>
    );
  },
);

TooltipTrigger.displayName = 'TooltipTrigger';

const Arrow = ({ arrowRef, ...props }) => {
  const context = useTooltipContext();

  return (
    <div
      ref={arrowRef}
      style={{
        position: context.strategy,
        display: 'inline-block',
        bottom: '-7px',
        left: context?.context?.middlewareData?.arrow?.x,
        visibility: context.x == null ? 'hidden' : 'visible',
        ...props.style,
        ...context.styleOverride,
      }}
      className="tooltip-arrow w-2 h-2"
    />
  );
};
Arrow.displayName = 'Arrow';

export const TooltipContent = React.forwardRef((props, propRef) => {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  const floatingProps = context.getFloatingProps(props);

  return (
    <FloatingPortal>
      {context.open && (
        <div
          data-state={context.open ? 'open' : 'closed'}
          ref={ref}
          style={{
            position: context.strategy,
            top: context.y ?? 0,
            left: context.x ?? 0,
            visibility: context.x == null ? 'hidden' : 'visible',
            ...props.style,
            ...context.styleOverride,
          }}
          {...context.getFloatingProps(props)}
        >
          {floatingProps.children}
          <Arrow arrowRef={context.arrowRef} {...context} />
        </div>
      )}
    </FloatingPortal>
  );
});

TooltipContent.displayName = 'TooltipContent';
