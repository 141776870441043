import { Button } from 'reactstrap';
import { set } from 'lodash';

import { updateControls } from 'jsx/components/core/form/components/FormBuilder';
import { auditsForm as form } from 'jsx/components/modules/projects/forms/audits';
import GenericLsvAdapter from 'jsx/components/core/form/components/GenericLsvAdapter';
import { useCheckAccess } from 'jsx/lib/hooks';

import { addPropertyId } from '../../lib/util';

const AuditsLsv = ({
  property: { audits: rows, id: propertyId },
  setModal,
  removeRecord,
  auditTypes,
}) => {
  const formWithPropertyId = addPropertyId(form, propertyId);
  const auditTypeOptions = auditTypes.map(({ id: optionId, name }) => ({
    name,
    label: name,
    value: optionId,
  }));
  auditTypeOptions.unshift({ name: '-', label: '-', value: '' });
  const formWithOptions = set(formWithPropertyId, 'audit_type_id.options', auditTypeOptions);

  const hasWriteAccess = useCheckAccess('projectRegisterUpdate');
  const actions = !hasWriteAccess
    ? []
    : [
        {
          func: async (id) => {
            const foundData = rows?.find(({ id: dataId }) => dataId === id);
            const updatedFormWithId = updateControls(formWithPropertyId, foundData);

            setModal(updatedFormWithId, 'audit', 'edit');
          },
          iconName: 'pen-to-square',
          classes: 'text-primary ml-3',
        },
        {
          func: async (id) => {
            // eslint-disable-next-line no-alert
            const confirmed = window.confirm(
              'This will permanently delete the data. Are you sure?',
            );
            if (confirmed) {
              removeRecord('audit', id);
            }
          },
          iconName: 'trash',
          classes: 'text-danger ml-3',
        },
      ];

  return (
    <div>
      <div className="d-flex justify-content-between border-bottom align-items-end pb-1">
        <h5 className="text-corporate mb-0">Audits</h5>
        {hasWriteAccess && (
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              setModal(formWithOptions, 'audit', 'create');
            }}
          >
            Create New
          </Button>
        )}
      </div>
      {!rows?.length ? (
        <div>No audits</div>
      ) : (
        <GenericLsvAdapter actions={actions} rows={rows} controls={formWithOptions} />
      )}
    </div>
  );
};

export default AuditsLsv;
