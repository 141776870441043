import { Controls } from 'jsx/components/core/form/components/FormBuilder';

export type notesKeys =
  | 'id'
  | 'notes'
  | 'property_id'
  | 'createdby'
  | 'created'
  | 'updatedby'
  | 'updated';

export const notesForm: Controls<notesKeys> = {
  id: {
    name: 'id',
    path: 'id',
    value: '',
    type: 'hidden',
    validationRules: {},
    listview: {
      show: false,
    },
  },
  property_id: {
    name: 'property_id',
    path: 'property_id',
    value: '',
    type: 'hidden',
    validationRules: {},
  },
  notes: {
    name: 'notes',
    path: 'notes',
    caption: 'Notes',
    value: '',
    type: 'text',
    validationRules: {},
  },
  createdby: {
    name: 'createdby',
    path: 'createdby.name',
    caption: 'Created By',
    value: '',
    type: 'text',
    validationRules: {},
    modal: {
      show: false,
    },
  },
  created: {
    name: 'createdBy_date',
    path: 'created',
    caption: 'Created',
    value: '',
    type: 'date',
    validationRules: {},
    modal: {
      show: false,
    },
  },
  updatedby: {
    name: 'updatedby',
    path: 'updatedby.name',
    caption: 'Updated By',
    value: '',
    type: 'text',
    validationRules: {},
    modal: {
      show: false,
    },
  },
  updated: {
    name: 'updated',
    path: 'updated',
    caption: 'Updated',
    value: '',
    type: 'date',
    validationRules: {},
    modal: {
      show: false,
    },
  },
};
