import React from 'react';
import Listview from "../../../core/form/components/Listview";

const AssetsLsv = (props) => {
  const {
    rows,
    onClick
  } = props;

  const renderRows = (headers, record) => {
    let statusColour = {};
    switch (record.asset_status.tag) {
      case 'down':
        statusColour =  {backgroundColor: 'red', color: 'white'};
        break;

      case 'active':
        statusColour =  {backgroundColor: 'green', color: 'white'};
        break;

      case 'inactive':
        statusColour =  {backgroundColor: 'gray', color: 'white'};
        break;
      
      default: break;
    }

    const asset_type = record.asset_type?.name;
    const asset_category = record.asset_category?.name;
    const asset_owner = record.org?.name;
    const asset_status = record.asset_status?.name;

    let asset_meterunit = '-';
    if (record.asset_meterunit?.name && record.service_interval) {
      asset_meterunit = `${record.service_interval} ${record.asset_meterunit?.name}`;
    }

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'asset_status': return (
          <td key={index} className={header.classes} style={statusColour}>{asset_status}</td>
        )

        case 'asset_type': return (
          <td key={index} className={header.classes} >{asset_type}</td>
        )

        case 'asset_category': return (
          <td key={index} className={header.classes} >{asset_category}</td>
        )

        case 'asset_meterunit': return (
          <td key={index} className={header.classes} >{asset_meterunit}</td>
        )

        case 'asset_owner': return (
          <td key={index} className={header.classes} >{asset_owner}</td>
        )

        default: return (
          <td key={index} className={header.classes}>{record[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const iconName = 'engine';
  const emptyCaption = 'No Assets found';

  const headers = [
    { caption: 'Asset No', field: 'name', classes: 'text-left', style: {width: 100} },
    { caption: 'Description', field: 'description', classes: 'text-left' },
    { caption: 'Types', field: 'asset_type', classes: 'text-left', style: {width: 150} },
    { caption: 'Category', field: 'asset_category', classes: 'text-left', style: {width: 150} },
    { caption: 'Owner', field: 'asset_owner', classes: 'text-left', style: {width: 150} },
    { caption: 'Service every', field: 'asset_meterunit', classes: 'text-right', style: {width: 150} },
    { caption: 'Status', field: 'asset_status', classes: 'text-right', style: {width: 100} },
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={header.style}>{header.caption}</th>
  )

  let tableBodyTr = (<tr></tr>);
  let rowsCount;

  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => onClick(true, 'register', row.id)}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  return (
    <div className="mt-2">
      <Listview rows={rows} rowsCount={rowsCount} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </div>
  );
}

export default AssetsLsv;
