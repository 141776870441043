
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';

import { fetchAnalysisPeriods } from 'jsx/components/manage/actions/analysis_periods';
import { fetchAnalysisGroups } from 'jsx/components/manage/actions/analysis_groups';

import FormBase from 'jsx/components/core/form/components/FormBase';
import FormTab from '../../../../core/form/components/FormTab';
import PageTitle from '../../../../core/form/components/PageTitle';
import AdminScheduledJobsLsv from '../../components/admin/AdminScheduledJobsLsv';
import AdminScheduledJobsToolbar from '../../components/admin/AdminScheduledJobsToolbar';
import AdminWarehouseProbesLsv from '../../components/admin/AdminWarehouseProbesLsv';
import AdminWarehouseToolbar from '../../components/admin/AdminWarehouseToolbar';
import AdminProbeModal from './AdminProbeModal';
import AdminProbeSchedulerModal from './AdminProbeSchedulerModal';
import AdminProbeTemplate from './AdminProbeTemplate';

import AdminBenchmarkGroupsLsv from '../../components/admin/AdminBenchmarkGroupsLsv';
import AdminBenchmarkGroupsToolbar from '../../components/admin/AdminBenchmarkGroupsToolbar';
import AdminProbeMetricsPanel from './AdminProbeMetricsPanel';
import AdminTrendAnalysis from '../trendanalysis/TrendAnalysis';

import { BenchmarkChart } from '../../components/benchmarking/BenchmarkChart';

import { controls } from '../../forms/admin/probe_filters';

import {
  fetchWarehouseProbes,
  fetchWarehouseGroups,
  fetchWarehouseMetrics,
  removeProbe,
  fetchGroupsForProbe,
  updateWarehouseGroup,
  fetchWarehouseGroupAssocs
} from '../../actions/warehouse';

import {
  fetchStats,
  fetchLogs,
  fetchBatches,
  terminateTasks,
  scheduleGroup1year,
  scheduleTop20
} from '../../actions/admin/scheduler';

import {
  fetchProbeTemplateByTag
} from '../../actions/admin/probe';

class AdminBenchmarking extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      activeTab: 'probes',
      isProbeModalOpen: false,
      isSchedularModalOpen: false,
      isChartOpen: false,
      chartData: [],
      chartTitle: '',
      chartExtra: {},
      probeData: {},
      modalData: null,
      enterprises: null,
      animal_classes: null,
      switchChecked: true,
      params: {errors_only: 'true'},
      probeFilterControls: controls,
      probeParams: {},
      metricParams: {},
      schedulerProperty: {},
      schedulerPeriodId: null,
      schedulerInterval: 20000,
      schedulerTimer: null
    };

    this.onClickProbe = this.onClickProbe.bind(this);
    this.onClickBatch = this.onClickBatch.bind(this);
    this.setProbeModal = this.setProbeModal.bind(this);
    this.setSchedularModal = this.setSchedularModal.bind(this);
    this.setChartModal = this.setChartModal.bind(this);
    this.refreshStats = this.refreshStats.bind(this);
    this.onHandleSwitchChange = this.onHandleSwitchChange.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.loadProbes = this.loadProbes.bind(this);
    this.loadGroups = this.loadGroups.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.onTerminate = this.onTerminate.bind(this);
    this.onDeleteProbe = this.onDeleteProbe.bind(this);
    this.onRerunProbe = this.onRerunProbe.bind(this);
    this.publishGroup = this.publishGroup.bind(this);
    this.getSubProbes = this.getSubProbes.bind(this);
  }

  async componentDidMount() {
    const { probeFilterControls } = this.state;
    const { filters } = this.props.warehouse;

    this.props.dispatch(fetchAnalysisPeriods());
    this.props.dispatch(fetchAnalysisGroups());

    probeFilterControls.period_id.value = (filters.period_id.length > 0 ? probeFilterControls.period_id.value = filters.period_id[0] : null);
    this.loadProbes();

    
  }

  toggleTab(tab) {
    const { probeFilterControls } = this.state;
    const { filters } = this.props.warehouse;

    this.clearSchedulerTimer();

    switch (tab) {
      case 'scheduler':
        this.props.dispatch(fetchStats());
        this.props.dispatch(fetchBatches());
        this.setSchedulerTimer();
        break;
      case 'metrics':
        this.props.dispatch(fetchWarehouseMetrics({}, filters));
        break;
      case 'probes':
        probeFilterControls.period_id.value = (filters.period_id.length > 0 ? probeFilterControls.period_id.value = filters.period_id[0] : null);
        this.loadProbes();
        break;
      case 'template':
        this.props.dispatch(fetchProbeTemplateByTag('standard_probe'));
        break;
      case 'groups':
        this.loadGroups();
        break;
      default: break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        selectedBatchRow: {},
        probeFilterControls
      });
    }
  }

  clearSchedulerTimer() {
    const { schedulerTimer } = this.state;
    clearInterval(schedulerTimer);
    this.setState({schedulerTimer});
  }

  setSchedulerTimer() {
    let { schedulerTimer } = this.state;
    const {params, schedulerInterval, switchChecked} = this.state;

    if (switchChecked) {
      // Fetch stats in intervals. Should be socketio here
      schedulerTimer = setInterval(() => this.refreshStats(params), schedulerInterval);
      this.setState({schedulerTimer});
    }
  }

  loadProbes() {
    const { filters } = this.props.warehouse;
    this.props.dispatch(fetchWarehouseProbes({period_id: filters.period_id}));
  }

  loadGroups() {
    this.props.dispatch(fetchWarehouseGroups());
  }

  refreshStats() {
    this.props.dispatch(fetchStats());
    this.props.dispatch(fetchBatches()); 
  };

  onClickProbe(row) {
    this.setState({probeData: row});
    this.setProbeModal();
  }

  handleFilterChange(event) {
    const { probeFilterControls } = this.state;
    const { filters } = this.props.warehouse;

    // Set filter
    probeFilterControls[event.target.name].value = event.target.value;

    // Apply filter
    const params = {period_id: event.target.value};
    this.props.dispatch(fetchWarehouseProbes(params));

    this.setState({probeFilterControls, probeParams: params});
    filters.period_id = [params.period_id];
    this.props.dispatch({type: 'SET_WAREHOUSE_FILTER_FULFILLED', payload: filters});
  }

  onClickBatch(row) {
    this.setState({selectedBatchRow: row});
    this.props.dispatch(fetchLogs({batch_id: row.id}));
  }

  setProbeModal() {
    this.setState({isProbeModalOpen: !this.state.isProbeModalOpen});
  }

  setSchedularModal() {
    this.setState({isSchedularModalOpen: !this.state.isSchedularModalOpen});
  }

  setChartModal(isChartOpen) {
    this.setState({isChartOpen});
  }

  onHandleSwitchChange(event) {
    const switchChecked = event.target.checked;
    this.setState({switchChecked}, () => {
      if (switchChecked) {
        this.setSchedulerTimer();
        this.refreshStats();
      } else {
        this.clearSchedulerTimer();
      }
    });
  }

  async onTerminate() {
    const {params} = this.state;

    const ok = confirm('This will terminate all scheduled warehouse tasks. Continue?');
    if (ok) await this.props.dispatch(terminateTasks());

    this.refreshStats(params);
  }

  async onDeleteProbe (event, row) {
    event.preventDefault();
    event.stopPropagation();

    const ok = confirm(`Remove probe for ${row.property.name} and all its submetrics for ${row.period.description}. You can rerun this probe in scheduler to reinstate.\n Please also note that Benchmarking will be automatically be rerun on remaining probes.\n Continue?`);
    if (ok) {
      const success = await this.props.dispatch(removeProbe(row.property.id, row.period.id));

      if (success) {
        // Reschedule benchmarking for groups probe is associated with in warehouse
        const groups = await this.props.dispatch(fetchGroupsForProbe(row.id, row.period.id));

        await groups.map(group => {
          const params = {
            period_id: row.period.id,
            group_id: group.group_id
          };

          this.props.dispatch(scheduleTop20(params));
          this.props.dispatch(scheduleGroup1year(params));
        });
      }

      this.loadProbes();
    }
  }

  onRerunProbe(event, row) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({schedulerProperty: row.property, schedulerPeriodId: row.period.id}, () => this.setSchedularModal());
  }

  async publishGroup(event, row) {
    const data = {
      is_published: event.target.checked,
      id: row.id
    };

    await this.props.dispatch(updateWarehouseGroup(data));
    this.props.dispatch(fetchWarehouseGroups());
  }

  async getSubProbes(column_tag, group ) {
    const subProbes = await this.props.dispatch(fetchWarehouseGroupAssocs(group?.id, column_tag));

    const chartData = subProbes.map(subProbe => ({
      name: subProbe?.metric?.property?.name,
      // value: subProbe?.metric?.client_entered?.data?.value,
      value: subProbe?.metric?.client_benchmark_values?.data?.value,
    }));

    const chartExtra = {};
    chartExtra.unit_attributes = group?.metric?.unit_attributes;
    chartExtra.metric_name = group?.metric?.name;
    chartExtra.column_tag = column_tag;
    chartExtra.period_description = group?.period?.probe_period;

    const chartTitle = `${chartExtra.metric_name} for ${column_tag} for ${chartExtra.period_description}`;

    this.setState({chartData, chartTitle, chartExtra}, () => {
      this.setChartModal(true);
    });
  }

  render() {
    const {
      activeTab,
      isProbeModalOpen,
      isSchedularModalOpen,
      schedulerProperty,
      schedulerPeriodId,
      switchChecked,
      params,
      selectedBatchRow,
      probeData,
      probeFilterControls,
      isChartOpen,
      chartData,
      chartTitle,
      chartExtra
    } = this.state;

    const { stats, logs, batches } = this.props.scheduler;
    const { periods } = this.props.analysis_periods;
    const { probes, groups } = this.props.warehouse;

    const title = 'Benchmarking';
    const iconName = 'magnifying-glass-chart';

    return (
      <div className="p-3 h-100">
        <PageTitle title={title} iconName={iconName}/>

        <AdminProbeModal
          probeData={probeData}
          isOpen={isProbeModalOpen}
          setModal={this.setProbeModal}
        />

        <AdminProbeSchedulerModal
          isOpen={isSchedularModalOpen}
          setModal={this.setSchedularModal}
          refreshStats={() => this.refreshStats(params)}
          schedulerProperty={schedulerProperty}
          schedulerPeriodId={schedulerPeriodId}
        />

        <BenchmarkChart
          setModal={this.setChartModal}
          isOpen={isChartOpen}
          chartData={chartData}
          chartTitle={chartTitle}
          chartExtra={chartExtra}
        />

        <Nav tabs className="mt-2">
          <FormTab
            caption="Probes"
            tabId="probes"
            activeTab={activeTab}
            toggle={this.toggleTab}
            disabled={!this.checkAccess('benchmarkProbesTab')}
          />
          <FormTab
            caption="Probe Metrics"
            tabId="metrics"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Scheduled Jobs"
            tabId="scheduler"
            activeTab={activeTab}
            toggle={this.toggleTab}
            disabled={!this.checkAccess('benchmarkJobsTab')}
          />
          <FormTab
            caption="Probe Template"
            tabId="template"
            activeTab={activeTab}
            toggle={this.toggleTab}
            disabled={!this.checkAccess('benchmarkTemplateTab')}
          />

          <FormTab
            caption="Groups"
            tabId="groups"
            activeTab={activeTab}
            toggle={this.toggleTab}
            disabled={!this.checkAccess('benchmarkGroupsTab')}
          />
          <FormTab
            caption="Trend Analysis"
            tabId="trendanalysis"
            activeTab={activeTab}
            toggle={this.toggleTab}
            disabled={!this.checkAccess('benchmarkGroupsTab')}
          />
        </Nav>

        <TabContent activeTab={activeTab} className="h-100">
          <TabPane tabId="scheduler" className="mb-2 p-1">
            <AdminScheduledJobsToolbar
              switchChecked={switchChecked}
              onHandleChange={this.onHandleSwitchChange}
              onAdd={this.setSchedularModal}
              stats={stats} 
              onRefresh={this.refreshStats}
              onTerminate={this.onTerminate}
            />
            <AdminScheduledJobsLsv
              rows={batches}
              selectedRow={selectedBatchRow}
              onClick={this.onClickBatch} 
              logs={logs}
            />
          </TabPane>

          <TabPane tabId="template" className="mb-2 p-1 h-100">
            <AdminProbeTemplate />
          </TabPane>

          <TabPane tabId="probes" className="mb-2 p-1 h-100">
            <AdminWarehouseToolbar
              rows={probes}
              onRefresh={this.loadProbes}
              periods={periods}
              handleFilterChange={this.handleFilterChange}
              probeFilterControls={probeFilterControls}
              probes={probes.length}
            />
            <AdminWarehouseProbesLsv
              rows={probes}
              onClick={this.onClickProbe}
              onDelete={this.onDeleteProbe}
              onRerun={this.onRerunProbe}
            />
          </TabPane>

          <TabPane tabId="metrics" className="mb-2 p-1 h-100">
            <AdminProbeMetricsPanel />
          </TabPane>

          <TabPane tabId="groups" className="mb-2 p-1 h-100">
            <AdminBenchmarkGroupsToolbar periods={periods} groups={groups.length} onRefresh={this.loadGroups}/>
            <AdminBenchmarkGroupsLsv
              rows={groups}
              handleChange={this.publishGroup}
              getSubProbes={this.getSubProbes}
            />
          </TabPane>

          <TabPane tabId="trendanalysis" className="mb-2 p-1 h-100" style={{position: 'relative'}}>
            <AdminTrendAnalysis isAdmin />
          </TabPane>

        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = (store) => ({
  scheduler: store.scheduler,
  analysis_periods: store.analysis_periods,
  warehouse: store.warehouse,
  realm: store.realm
});

export default connect(mapStoreToProps)(withContainerError(AdminBenchmarking));
