import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Project Name',
    description: 'Required Field',
    validationRules: {
      isRequired: true,
    },
  },
  description: {
    ...controlDefaults,
    placeholder: '',
    name: 'description',
    type: 'textarea',
    caption: 'Project Description',
    textRows: 5,
    validationRules: {},
  },
  crm_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'crm_id',
    type: 'select',
    caption: 'Operational/Financial Entity',
    description:
      'HubSpot CRM Company. If entity does not exist in list, add it as a company to HubSpot, associate its contacts and reopen/reload this page.',
    validationRules: {},
    options: [],
  },
  project_job_no: {
    ...controlDefaults,
    placeholder: '',
    name: 'project_job_no',
    type: 'text',
    caption: 'Carbonlink Project Job Number',
    description: 'Internal CLO Project No',
    validationRules: {},
  },
  assignedto_id: {
    ...controlDefaults,
    name: 'assignedto_id',
    type: 'select',
    caption: 'CLO Project Officer',
    options: [],
    validationRules: {},
    disabled: false,
    description: 'Assigned Project Officer',
  },
  methodology_original: {
    ...controlDefaults,
    name: 'methodology_original',
    type: 'select',
    caption: 'Methodology Original',
    disabled: true,
    options: [],
    description: 'Lookup list of methodologies not available yet',
    validationRules: {},
  },
  permanence_period: {
    ...controlDefaults,
    name: 'permanence_period',
    type: 'number',
    caption: 'Permanence Period (Years)',
    description: 'Total duration of the project in years',
    validationRules: {},
  },
  project_area_description: {
    ...controlDefaults,
    placeholder: '',
    name: 'project_area_description',
    disabled: true,
    type: 'label',
    caption: 'Project Area Description',
    description: 'Sum of CEA hectares.',
    validationRules: {},
  },
  participant: {
    ...controlDefaults,
    placeholder: 'Participant',
    name: 'participant',
    type: 'text',
    caption: 'Scheme Participant',
    validationRules: {},
    description: 'Registered client participants against the project.',
  },
  participant_id: {
    ...controlDefaults,
    placeholder: 'Participant ID',
    name: 'participant_id',
    type: 'text',
    caption: 'Participant ID',
    validationRules: {},
    description:
      'Free type field for the moment. Should this field be attached to a financial entity?',
  },
  project_status_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'project_status_id',
    fieldName: 'project_status_id',
    type: 'select',
    caption: 'Project Status',
    validationRules: {},
  },
  nominee: {
    ...controlDefaults,
    name: 'nominee',
    type: 'text',
    caption: 'ERF Nominated Nominee',
    description:
      'Nominee nominated by participants (out of list participants) that gets issued the ACCU’s',
    validationRules: {},
  },
  anreu_account: {
    ...controlDefaults,
    name: 'anreu_account',
    type: 'text',
    caption: 'ANREU Account',
    description: 'ANREU = Australian National Registry of Emission Units',
    validationRules: {},
  },
  anreu_client_number: {
    ...controlDefaults,
    name: 'anreu_client_number',
    type: 'text',
    caption: 'ANREU Client Number',
    validationRules: {},
  },
  erf_project_no: {
    ...controlDefaults,
    placeholder: 'ERF Project Number',
    name: 'erf_project_no',
    type: 'text',
    caption: 'ERF Project Number',
    description: '',
    validationRules: {},
  },
  declaration_date: {
    ...controlDefaults,
    placeholder: 'Declaration Created',
    name: 'declaration_date',
    type: 'date',
    caption: 'Declaration Created',
    validationRules: {},
  },
  created: {
    ...controlDefaults,
    placeholder: 'Created',
    name: 'created',
    type: 'date',
    caption: 'Creation Date',
    validationRules: {},
  },
  jira_key: {
    ...controlDefaults,
    placeholder: '',
    name: 'jira_key',
    type: 'text',
    caption: 'Jira Key',
    description: 'Link to Jira Task management ... ie CLP-****',
    validationRules: {},
  },
};
