import React from 'react';
import { connect } from "react-redux";
import {
  Row,
  Col
} from 'reactstrap';

import ChartLayersSocCeaLsv from '../components/ChartLayersSocCeaLsv';
import Icon from 'jsx/components/core/icons/Icon';

import mapboxgl from 'mapbox-gl';
import { accessToken } from '../../../../constants/map.js';

import {
  zoomToBounds,
  getBoundary,
  getCollectionBoundary,
  buildFeatureCollection,
  buildFeature,
  getDefaultLayer
} from "../../projects/lib/mapster.js";

import {
  fetchAreasSync
} from '../../areas/actions';

import {
  fetchCarbonStockPropertyRound
} from '../actions';

mapboxgl.accessToken = accessToken

class ChartLayersCeas extends React.Component {
  constructor(props) {
    super(props)

    this.mapRef = React.createRef();
    this.state = ({
      specLink: 'https://carbonlink.atlassian.net/wiki/spaces/OP/pages/628424705/Spec+-+Calculate+carbon+stock+for+CEA',
      widget: {
        title: 'Carbon Stock Calculations for each CEA Layer'
      },
      data: [],
      fetching: false,
      lng: 150.7333,
      lat: -23.1333,
      zoom: 8,
      map: null,
      mapSources: [],
      mapIsLoaded: false,
      mapStyle: 'mapbox://styles/mapbox/outdoors-v11',
    })
  }

  componentDidMount() {
    const {
      mapStyle,
      zoom
    } = this.state;

    this.resetGeometry()

    const propertyRoundId = this.props.filter.property_id;
    this.loadPropertyMaps(propertyRoundId)

    const map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: mapStyle,
      center: [this.state.lng, this.state.lat],
      zoom: zoom,
      attributionControl: false
    });

    this.setState({
      map
    })

    map.once("load", () => {
      this.loadSources(map);
      this.setState({ mapIsLoaded: true, map});
    });
  }

  componentDidUpdate(prevProps) {
    const {
      selectedTemplate,
      filter
    } = this.props;

    const {
      fetching,
      data,
      mapSources,
      map,
      mapIsLoaded
    } = this.state;

    if (selectedTemplate.id && filter.property_round_id && !fetching && (filter.property_round_id !== prevProps.filter.property_round_id || selectedTemplate.id !== prevProps.selectedTemplate.id || data.length === 0)) {
      this.load();
    }

    // Reload stratas and sites if area round changes
    if (filter.property_round_id && filter.property_round_id !== prevProps.filter.property_round_id ) {
      this.loadPropertyMaps(filter.property_round_id);
    }

    // this.setAreaMaps();
    this.state.map.resize();

    if (mapIsLoaded) {
      this.updateSources(map, mapSources)
    }
  }

  async load() {
    const {
      selectedTemplate,
      filter
    } = this.props;

    this.setState(
      {fetching: true},
      async () => {
        const soc = await this.props.dispatch(fetchCarbonStockPropertyRound(filter.property_round_id, selectedTemplate.id));
        this.setState({data: soc, fetching: false})
      }
    )
  }

  async loadPropertyMaps(propertyRoundId) {
    await this.props.dispatch(fetchAreasSync(propertyRoundId));

    this.setAreaMaps();
    this.state.map.resize();
  }

  loadSources(map) {
    const mapSources = this.state.mapSources;
    mapSources.forEach(mapSource => {
      map.addSource(mapSource.id, {
        type: 'geojson',
        data: {'type': 'Feature'}
      });

      const layer = getDefaultLayer(mapSource.id, mapSource.style)
      map.addLayer(layer);

    })
    this.setState({ mapIsLoaded: true, map});
  }

  updateSources(map) {
    const {
      mapSources
    } = this.state;

    let bounds = []
    mapSources.forEach(mapSource => {
      if (map.getSource(mapSource.id)) {
        map.getSource(mapSource.id).setData(mapSource.source);
        if (mapSource.source.type === 'Feature') {
          bounds = bounds.concat(getBoundary(mapSource.source));
        }
        if (mapSource.source.type === 'FeatureCollection') {
          bounds = bounds.concat(getCollectionBoundary(mapSource.source));
        }
      }
    })

    if (bounds.length > 0) {
      zoomToBounds(map, bounds);
    }
  }

  resetGeometry() {
    const mapSources = [
      { id: 'areas', visible: true, source: { 'type': 'FeatureCollection', features: [] }, load: true, title: 'Areas', count: 0, style: "Polygon" }
    ]
    this.setState({ mapSources })
  }

  setAreaMaps() {
    const areas = this.props.areas.currentAreas;
    const mapSources = this.state.mapSources;
    const areaColours = this.props.mapster.colours.areas;

    if (areas.length > 0) {
      const idx = mapSources.findIndex(source => source.id === 'areas');
      const featureCollection = buildFeatureCollection();

      areas.forEach(area => {
        const areaColour = areaColours[parseInt(area.name)]
        if (area.geom) {
          const feature = buildFeature(area.geom, { title: `CEA${area.name}`, colour: areaColour, outline: '#000000' })
          featureCollection.features.push(feature)
        }
      })

      if (mapSources[idx].load && featureCollection.features.length > 0) {
        mapSources[idx].load = false;
        if (mapSources[idx].visible === true) {
          mapSources[idx].source = featureCollection;
        } else {
          mapSources[idx].source = buildFeatureCollection();
        }

        mapSources[idx].count = featureCollection.features.length;
        this.setState({ mapSources })
      }
    }
  }

  render() {
    const {
      selectedTemplate,
      filter
    } = this.props;

    const {
      data,
      specLink,
      widget
    } = this.state;

    const areaColours = this.props.mapster.colours.areas;
    const specCaption = `Spec Sheet for ${widget.title}`;

    return(
      <div>
        <Row className="m-1 bg-white rounded border border-secondary">
          <Col sm={2} className="p-0">
            <div ref={this.mapRef} className="h-100 rounded-left" style={{marginBottom: '-5px', borderRight: '1px solid #cccccc'}} />
          </Col>
          <Col sm={10} className="p-2">
            <div className="d-flex justify-content-between p-1 border-bottom border-lightgray mb-1">
              <div>
                <Icon name="leaf" className="text-corporate mr-2" />
                <span>{widget.title}</span>
              </div>
              <div>
                <div style={{fontWeight: 'bolder'}}>Avg = Average SOC ktC/ha, Var = Variance SOC ktC/ha<sup>2</sup></div>
              </div>
            </div>
            <ChartLayersSocCeaLsv calculationStyle="supplement" selectedTemplate={selectedTemplate} propertyRoundId={filter.property_round_id} namePrefix="CEA " data={data} colours={areaColours}/>
            <ChartLayersSocCeaLsv calculationStyle="percentage" selectedTemplate={selectedTemplate} propertyRoundId={filter.property_round_id} namePrefix="CEA " data={data} colours={areaColours}/>

            {/* <div className="d-flex justify-content-start p-1 border-bottom border-lightgray mb-1">
              <Icon name="leaf" className="text-corporate mr-2" />
              <div>Gravel Percent for each CEA Layer</div>
            </div>
            <ChartLayersCeaGravelLsv data={data} colours={areaColours} /> */}
            <div className="text-right mr-3">
              <small><a target="_blank" rel="noopener noreferrer" href={specLink}>{specCaption}</a></small>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    analytics: store.analytics,
    areas: store.areas,
    mapster: store.mapster
  }
}

export default connect(mapStoreToProps)(ChartLayersCeas);
