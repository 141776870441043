import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import Mapster from './Mapster.jsx';
import {
  buildFeatureCollection,
  buildFeature,
  buildReconHtmlPopup,
} from '../../projects/lib/mapster.js';

import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import FormBase from '../../../core/form/components/FormBase';
import { controls } from '../forms/project.js';
import ReconTripsCells from '../components/ReconTripsCells';

import { fetchReconTrips, fetchSites } from '../actions/recon';

import { withContainerError } from 'jsx/components/core/errors/ContainerError.jsx';

class Recon extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      activeTab: '1',
      controls: controls,
      mapSources: [],
      data: {},
    };

    this.handleSourceVisibility = this.handleSourceVisibility.bind(this);
    this.selectSites = this.selectSites.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchReconTrips());
    this.resetGeometry();
  }

  resetGeometry() {
    const mapSources = [
      {
        id: 'recon_sites',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Recon Trips',
        count: 0,
        style: 'Point',
      },
    ];
    this.setState({ mapSources });
  }

  selectSites(trip) {
    this.props.dispatch(fetchSites(trip));
  }

  setSiteMaps() {
    const { sites } = this.props.recon;

    const { mapSources } = this.state;

    if (sites.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'recon_sites');
      const featureCollection = buildFeatureCollection();

      sites.forEach((site) => {
        const geom = { type: 'Point', coordinates: [site.lng, site.lat] };

        // Site colour
        let featureColour = this.props.mapster.colours.site_recon;
        let notes = site.notes;
        if (!notes) {
          notes = `None Available`;
        }

        // Build a popup
        const html = buildReconHtmlPopup(site);

        let properties = {
          id: site.id,
          html: html,
          colour: featureColour.fill,
          outline: featureColour.outline,
        };
        const feature = buildFeature(geom, properties);
        featureCollection.features.push(feature);
      });

      if (
        mapSources[idx].source.features.length !== sites.length &&
        featureCollection.features.length > 0
      ) {
        mapSources[idx].load = false;
        mapSources[idx].source = featureCollection;
        mapSources[idx].count = featureCollection.features.length;
        this.setState({ mapSources });
      }
    }
  }

  handleSourceVisibility(event) {
    let { mapSources } = this.state;

    const idx = event.target.value;

    mapSources[idx].visible = !mapSources[idx].visible;
    mapSources[idx].load = true;

    this.setState({
      mapSources,
    });
  }

  render() {
    const { mapSources } = this.state;

    const { trips, authorised, authResponseMessage } = this.props.recon;

    if (mapSources) {
      this.setSiteMaps();
    }
    const lngLat = [150.7333, -23.1333];

    return (
      <Row className="m-0 p-0 h-100">
        <ResponseMessage responseMessage={authResponseMessage} />
        {authorised && (
          <>
            <Col>
              <Mapster
                handleSourceVisibility={this.handleSourceVisibility}
                expandMap={true}
                lngLatCenter={lngLat}
                toggleMap={() => {}}
                mapSources={mapSources}
              />
            </Col>
            <Col className="listview">
              <ReconTripsCells rows={trips} selectSites={this.selectSites} />
            </Col>
          </>
        )}
      </Row>
    );
  }
}

const mapStoreToProps = ({ mapster, recon }) => ({
  mapster,
  recon,
});

export default connect(mapStoreToProps)(withContainerError(Recon));
