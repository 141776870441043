import React from 'react';

const AreaSectionOther = ({ name, area_type, area_ha }) => {
  return (
    <>
      <div className="px-2 d-flex justify-content-between bg-warning mb-2 text-white p-1">
        <h5 className="m-0">
          {area_type} - <small>{name}</small>
        </h5>
        <div>
          <span>Property {area_type} (ha)</span>
          <span
            style={{ minWidth: '70px' }}
            className={`d-inline-block text-right px-2 bg-white text-black ml-2 rounded`}
          >
            {area_ha}
          </span>
        </div>
      </div>
    </>
  );
};

export default AreaSectionOther;
