import React, { useState } from 'react';
import { isEmpty, omit } from 'lodash';
import { Button } from 'reactstrap';
import Listview from '../../../core/form/components/Listview';
import FormInput from '../../../core/form/components/FormInput';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import { Tooltip, TooltipContent, TooltipTrigger } from 'jsx/components/core/popovers/Popover';

const EnterpriseAllocationPropertiesLsv = ({
  onAddProperty = noop,
  rows = [],
  properties = [],
  allocationControls,
}) => {
  const [changed, setChanged] = useState(false);

  const renderEditRows = (headers, row, rowCount) => {
    const tableTd = [];
    if (isEmpty(headers)) return null;

    const controlKeys = Object.keys(headers);
    controlKeys.forEach((key, index) => {
      let control = headers[key];
      control = {
        ...control,
        id: `${index}-${control.fieldName}`,
        value: row[key],
      };
      control = omit(control, ['caption']);

      tableTd.push(
        <td key={index} className={control.classes}>
          <FormInput handleChange={(event) => handleChange(event, row)} control={control} />
        </td>
      );
    });

    const deleteButton = (
      <Button
        color="danger"
        onClick={() => {
          if (rowCount >= 1) removeRow(row);
        }}
      >
        <Icon name="xmark" />
      </Button>
    );

    tableTd.push(
      <td key={`delete-${row.property_id}`} className="text-center position-relative">
        {rowCount > 1 && deleteButton}
        {rowCount <= 1 && (
          <Tooltip styleOverride={{ zIndex: '1060' }}>
            <TooltipTrigger className="btn btn-danger">
              <Icon name="xmark" />
            </TooltipTrigger>
            <TooltipContent>
              <div className="bg-white p-2 rounded border drop-shadow-sm">
                You must have at least one property association
              </div>
            </TooltipContent>
          </Tooltip>
        )}
      </td>
    );

    return tableTd;
  };

  const handleChange = (event, row) => {
    const { name, value } = event.target;

    row[name] = value;
    setChanged(!changed);
  };

  const removeRow = (target) => {
    const idx = rows.findIndex((row) => row === target);
    if (idx > -1) {
      rows.splice(idx, 1);
    }
    setChanged(!changed);
  };

  if ((!rows || rows.length === 0) && properties?.length > 0) {
    return null;
  }

  const [templateControl] = allocationControls;
  const keys = Object.keys(templateControl);
  const tableHeadTh = [];

  keys.forEach((key, index) =>
    tableHeadTh.push(
      <th key={index} className={templateControl[key]?.classes}>
        {templateControl[key]?.caption}
      </th>
    )
  );

  // Add extra column for delete button
  tableHeadTh.push(<th key="delete" className="text-center"></th>);

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    const rowCount = rows.length;
    tableBodyTr = rows.map((row, index) => (
      <tr key={index}>{renderEditRows(templateControl, row, rowCount)}</tr>
    ));
  }

  tableBodyTr.push(
    <tr key="add-property" className="w-100">
      <td colSpan={6}>
        <Button onClick={() => onAddProperty()}>
          <Icon name="plus" className="mr-2" />
          Add property
        </Button>
      </td>
    </tr>
  );

  const iconName = 'draw-polygon';
  const emptyCaption = 'No Estimation Areas found';

  return (
    <Listview
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default EnterpriseAllocationPropertiesLsv;
