import React from 'react';
import { Nav } from 'reactstrap';
import FormTab from '../../../core/form/components/FormTab';

const AreaRoundTabs = (props) => {
  let { activeTab, toggleTab, pipeline, subsampleTabDisabled } = props;

  if (!pipeline) {
    pipeline = {};
  }
  if (!pipeline.subsampling) {
    pipeline.subsampling = {};
  }
  if (!pipeline.lab) {
    pipeline.lab = {};
  }
  if (!pipeline.carboncalcs) {
    pipeline.carboncalcs = {};
  }
  return (
    <Nav tabs className="mt-2">
      <FormTab
        caption="Profile"
        tabTag="profile"
        tabId="profile"
        activeTab={activeTab}
        toggle={toggleTab}
      />

      <FormTab
        caption="Stratas"
        tabTag="stratas"
        tabId="stratas"
        activeTab={activeTab}
        toggle={toggleTab}
      />

      <FormTab caption="Sites" tabId="sites" activeTab={activeTab} toggle={toggleTab} />

      <FormTab
        caption="Sub-Sample Selection"
        tabTag="sub-sample-selection"
        tabId="sub-sample-selection"
        disabled={subsampleTabDisabled}
        activeTab={activeTab}
        toggle={toggleTab}
        iconRightName={pipeline.subsampling.statusIcon}
        iconRightClass={pipeline.subsampling.statusClass}
        isSpinning={pipeline.subsampling.isSpinning}
      />

      <FormTab
        caption="Lab Analysis"
        tabTag="lab-analysis"
        tabId="lab-analysis"
        activeTab={activeTab}
        toggle={toggleTab}
        disabled={subsampleTabDisabled}
        isSpinning={pipeline.lab.isSpinning}
        iconRightName={pipeline.lab.statusIcon}
        iconRightClass={pipeline.lab.statusClass}
      />

      <FormTab
        caption="Lab Register"
        tabTag="lab-register"
        tabId="lab-register"
        activeTab={activeTab}
        toggle={toggleTab}
        disabled={subsampleTabDisabled}
      />

      <FormTab
        caption="Pipelines"
        tabTag="pipelines"
        tabId="pipelines"
        activeTab={activeTab}
        toggle={toggleTab}
        disabled={subsampleTabDisabled}
      />

      <FormTab
        caption="Pipeline Files"
        tabTag="pipeline-files"
        tabId="pipeline-files"
        activeTab={activeTab}
        toggle={toggleTab}
        disabled={subsampleTabDisabled}
      />

      <FormTab
        iconName="file"
        tabTag="pipeline-docs"
        caption="Pipeline Docs"
        tabId="pipeline-docs"
        disabled={subsampleTabDisabled}
        activeTab={activeTab}
        toggle={toggleTab}
      />

      <FormTab
        iconName="comment"
        tabTag="activity"
        caption="Activity"
        tabId="activity"
        activeTab={activeTab}
        toggle={toggleTab}
      />
    </Nav>
  );
};

export default AreaRoundTabs;
