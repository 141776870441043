import moment from 'moment';
import { Input } from 'reactstrap';
import Listview from '../../../core/form/components/Listview';
import TimeFormat from '../../office/lib/timeFormat';

const DaysheetApprovalsLsv = ({ rows, createApproval }) => {
  const format = new TimeFormat();

  const renderRows = (headers, record) => {
    const jobName = record.job?.name;
    const assetName = record.asset?.name;
    const from_date = moment(record.daysheet?.from_datetime).format(format.day);
    const userName = record?.daysheet?.user
      ? `${record.daysheet.user.firstname} ${record.daysheet.user.lastname}`
      : '';

    const onCheckedChanged = (level) => (event) => {
      const { checked } = event.target;
      createApproval({ id: record.id, level, approved: checked });
    };

    const tableTd = headers.map(({ classes, style, field }, index) => {
      const simpleRow = (value) => (
        <td key={index} className={classes} style={style}>
          {value}
        </td>
      );
      let disabled;
      let checked;
      switch (field) {
        case 'approve_level1':
          disabled = !record.can_approve_level1;
          checked = record.is_approved_level1;
          return (
            <td key={index} className="text-center">
              <Input
                type="checkbox"
                className="m-0 mt-1 position-relative"
                onChange={onCheckedChanged(1)}
                checked={checked}
                disabled={disabled}
              />
            </td>
          );
        case 'approve_level2':
          disabled = !record.can_approve_level2;
          checked = record.is_approved_level2;
          return (
            <td key={index} className="text-center">
              <Input
                type="checkbox"
                className="m-0 mt-1"
                onChange={onCheckedChanged(2)}
                checked={checked}
                disabled={disabled}
              />
            </td>
          );
        case 'from_date':
          return simpleRow(from_date);
        case 'activity':
          return simpleRow(jobName);
        case 'asset_name':
          return simpleRow(assetName);
        case 'name':
          return simpleRow(userName);

        default:
          return simpleRow(record[field]);
      }
    });

    return tableTd;
  };

  const iconName = 'calendar';
  const emptyCaption = 'No timesheet site activity found for this week';

  const upperHeadTh = [
    { caption: null, cells: 6, classes: 'border-bottom-0' },
    {
      caption: 'Asset',
      cells: 4,
      classes: 'text-center border',
    },
    {
      caption: 'Approve',
      cells: 2,
      classes: 'text-center border',
    },
  ].map((upperHeader, index) => (
    <th key={index} colSpan={upperHeader.cells} className={upperHeader.classes}>
      {upperHeader.caption}
    </th>
  ));

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left', style: { width: 100 } },
    { caption: 'From Date', field: 'from_date', classes: 'text-left', style: { width: 100 } },
    {
      caption: 'Project/Phasecode',
      field: 'project_phasecode',
      classes: 'text-left',
      style: { width: 100 },
    },
    { caption: 'Work Activity', field: 'activity', classes: 'text-left', style: { width: 150 } },
    {
      caption: 'Work Description',
      field: 'description',
      classes: 'text-left',
      style: { width: 100 },
    },
    {
      caption: 'Labour Hours',
      field: 'labour_hours',
      classes: 'text-left',
      style: { width: 40 },
    },
    { caption: 'Asset #', field: 'asset_name', classes: 'text-left', style: { width: 40 } },
    { caption: 'SMU Start', field: 'smu_start', classes: 'text-left', style: { width: 40 } },
    { caption: 'SMU End', field: 'smu_end', classes: 'text-left', style: { width: 40 } },

    { caption: 'Asset Hours', field: 'asset_hours', classes: 'text-left', style: { width: 40 } },
    { caption: 'Level 1', field: 'approve_level1', classes: 'text-center w-20', level: 1 },
    { caption: 'Level 2', field: 'approve_level2', classes: 'text-center w-20', level: 2 },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  let rowsCount;

  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  return (
    <div className="mt-2">
      <Listview
        rows={rows}
        rowsCount={rowsCount}
        tableHeadTh={tableHeadTh}
        upperHeadTh={upperHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </div>
  );
};

export default DaysheetApprovalsLsv;
