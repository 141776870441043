import { columnWidths } from 'jsx/components/modules/portrait/constants/listviews';
import { controlDefaults } from '../../core/form/lib/validateForm';

export const baseControls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  analysis_group_id: {
    ...controlDefaults,
    name: 'group.name',
    type: 'text',
    caption: 'Analysis Group',
    showInListview: true,
    width: `${columnWidths.common * 2}px`,
  },
  is_active: {
    ...controlDefaults,
    name: 'group.is_active',
    field_name: 'group.is_active',
    controlType: 'switch',
    type: 'checkbox',
    caption: 'Active',
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    validationRules: {
      isRequired: true,
    },
    showInListview: true,
  },
};

export const controls = {
  ...baseControls,
  org_id: {
    ...controlDefaults,
    type: 'text',
    name: 'property.project.org.name',
    caption: 'Organisation',
    showInListview: true,
    width: `${columnWidths.common * 2}px`,
  },
  property_id: {
    ...controlDefaults,
    name: 'property.name',
    type: 'text',
    caption: 'Property',
    showInListview: true,
  },
  dnc: {
    ...controlDefaults,
    name: 'property.dncLabel',
    type: 'text',
    caption: 'DNC',
    showInListview: true,
  },
};
