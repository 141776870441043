import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import CoresLsv from '../components/CoresLsv';
import CoreArchives from './CoreArchives';
import CoreArchiveSamples from './CoreArchiveSamples';
import CoresToolbar from '../components/CoresToolbar';
import FormTab from '../../../core/form/components/FormTab';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import FormBase from '../../../core/form/components/FormBase';

import Downloads from '../lib/downloads';

import { Nav, TabPane, TabContent } from 'reactstrap';

import {
  fetchSites,
  fetchSiteStatuses,
  updateSite,
  fetchScanOperators,
  fetchSiteSamples,
} from '../actions';

import Icon from 'jsx/components/core/icons/Icon';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';

class Cores extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      coreSelection: [],
      modifyDisabled: true,
      searchValue: '',
      currentSite: {},
      limit: 30,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.updateSelected = this.updateSelected.bind(this);
    this.refresh = this.refresh.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.fetchSamples = this.fetchSamples.bind(this);
  }

  componentDidMount() {
    const { filters } = this.props.sites;

    this.downloads = new Downloads();
    this.downloads.set(this.props.dispatch);

    const { limit } = this.state;

    const params = { scans_only: true, limit: limit };
    this.props.dispatch(fetchSites(params, filters));
    this.props.dispatch(fetchSiteStatuses());
    this.props.dispatch(fetchScanOperators());
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_STRATAS' });
    this.props.dispatch({ type: 'UNSET_SITES' });
    this.props.dispatch({ type: 'UNSET_ROUND' });
  }

  handleSelect(event) {
    let { coreSelection, modifyDisabled } = this.state;

    const state = event.target.checked;
    const id = event.target.value;

    if (state) {
      coreSelection.push(id);
    } else {
      const idx = coreSelection.findIndex((coreId) => coreId === id);
      if (idx > -1) {
        coreSelection.splice(idx, 1);
      }
    }

    if (coreSelection.length > 0) {
      modifyDisabled = false;
    }

    this.setState({
      coreSelection,
      modifyDisabled,
    });
  }

  async updateSelected(fieldName, value) {
    const { coreSelection, limit } = this.state;

    const { params, filters } = this.props.sites;

    await Promise.all(
      coreSelection.map(async (selected_id) => {
        let formData = new FormData();
        formData.append('id', selected_id);
        formData.append(fieldName, value);
        await this.props.dispatch(updateSite(formData));
      })
    );

    this.props.dispatch(fetchSites(params, { ...filters, limit: limit }));
  }

  refresh(new_limit) {
    const { params, filters } = this.props.sites;

    const { limit } = this.state;

    this.props.dispatch(fetchSites({ ...params, limit: new_limit || limit }, filters));
  }

  onSearch(event) {
    event.preventDefault();
    const { limit } = this.state;
    this.props.dispatch(fetchSites({ search_value: this.state.searchValue, limit: limit }, null));
  }

  handleSearchChange(event) {
    const { limit } = this.state;
    this.setState({ searchValue: event.target.value });
    // Force search on enter
    if (event.keyCode === 13) {
      const params = { search_value: event.target.value, limit: limit };
      this.props.dispatch(fetchSites(params, null));
    }

    // Bring back all the scans
    if (!event.target.value) {
      const params = { scans_only: true, limit: limit };
      this.props.dispatch(fetchSites(params, null));
    }
  }

  fetchSamples(site) {
    this.props.dispatch(fetchSiteSamples({ site_id: site.id }));
    this.setState({ currentSite: site });
  }

  loadMore() {
    let { limit } = this.state;

    limit += 30;

    this.refresh(limit);
    this.setState({ limit });
  }

  render() {
    const {
      sites,
      authorised,
      authResponseMessage,
      statuses,
      scanValids,
      samples,
      operators_scan,
    } = this.props.sites;

    const { modifyDisabled, coreSelection, searchValue, currentSite } = this.state;

    const iconName = 'vial';

    return (
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Cores</h3>
          </div>
        </div>

        <ResponseMessage responseMessage={authResponseMessage} />
        {authorised && (
          <Fragment>
            <Nav tabs className="mt-2">
              <FormTab
                caption="Recent Scans"
                tabId="1"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Core Archives"
                tabId="2"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Core Archive Samples"
                tabId="3"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
              />
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1" className="mb-2 p-1">
                <CoresToolbar
                  modifyDisabled={modifyDisabled}
                  refresh={this.refresh}
                  statuses={statuses}
                  updateSelected={this.updateSelected}
                  scanValids={scanValids}
                  operators_scan={operators_scan}
                  onSearch={this.onSearch}
                  handleSearchChange={this.handleSearchChange}
                  searchValue={searchValue}
                  checkAccess={this.checkAccess}
                />
                <CoresLsv
                  handleSelect={this.handleSelect}
                  handleDownload={(id, event) => this.downloads.handleSiteDownload(id, event)}
                  rows={sites}
                  pagination={true}
                  onLoadMore={this.loadMore}
                  history={this.props.history}
                  selection={coreSelection}
                  fetchSamples={this.fetchSamples}
                  samples={samples}
                  currentSite={currentSite}
                />
              </TabPane>
              <TabPane tabId="2" className="mb-2 p-1">
                <CoreArchives />
              </TabPane>
              <TabPane tabId="3" className="mb-2 p-1">
                <CoreArchiveSamples />
              </TabPane>
            </TabContent>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ sites, realm }) => {
  return {
    sites,
    realm,
  };
};

export default connect(mapStoreToProps)(withContainerError(Cores));
