import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import { updateControls, saveFormControls } from '../../../core/form/lib/validateForm';
import { controls } from '../forms/site';
import SiteProfile from '../components/SiteProfile';
import SiteSamplesLsv from '../components/SiteSamplesLsv';
import SiteSamplesChartGamma from '../components/SiteSamplesChartGamma';

import SiteSamplesChartSpectraNm from '../components/SiteSamplesChartSpectraNm';
import SiteSamplesColours from '../components/SiteSamplesColours';
import SiteSamplesImages from '../components/SiteSamplesImages';
import SiteScansLsv from '../components/SiteScansLsv';

import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import ActivitiesLsv from '../../../core/activity/components/ActivitiesLsv';
import ActivityModal from '../../../core/activity/containers/ActivityModal';

import { fetchActivities } from '../../../core/activity/actions';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import BreadcrumbsRoute from 'jsx/components/core/form/components/BreadcrumbsRoute';

import {
  fetchSite,
  updateSite,
  uploadSamples,
  fetchSiteSamples,
  fetchSiteScans,
  fetchSiteStatuses,
  fetchDrillOperators,
  fetchScanOperators,
  clearScan,
  moveScan,
  removeSiteScan,
} from '../actions';

class Site extends FormBase {
  constructor(props) {
    super(props);

    const { project_id, property_id, area_id, round_id, tab_id } = this.props.match.params;

    const backlink = `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${round_id}`;

    this.state = {
      activeTab: tab_id,
      controls: controls,
      data: {},
      coreId: null,
      isModalOpen: false,
      selectedPosition: 0,
      selectedSpectra: 0,
      backlink,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.handleSamplesUpload = this.handleSamplesUpload.bind(this);
    this.setModal = this.setModal.bind(this);
    this.onClearScan = this.onClearScan.bind(this);
    this.onSelectPosition = this.onSelectPosition.bind(this);
    this.onDeleteScan = this.onDeleteScan.bind(this);
    this.onMoveScan = this.onMoveScan.bind(this);
  }

  async componentDidMount() {
    let { data, controls } = this.state;

    const id = this.props.match.params.id;

    data = await this.props.dispatch(fetchSite(id));
    controls = updateControls(controls, data);

    this.props.dispatch(fetchSiteStatuses());
    this.props.dispatch(fetchDrillOperators());
    this.props.dispatch(fetchScanOperators());

    this.setState({
      data,
      controls,
    });
  }

  toggleTab(tab, tag) {
    const id = this.props.match.params.id;

    switch (tag) {
      case 'samples':
        this.props.dispatch(fetchSiteSamples({ site_id: id }));
        break;
      case 'scans':
        this.props.dispatch(fetchSiteScans({ site_id: id }));
        break;
      case 'activity':
        this.props.dispatch(
          fetchActivities({ associate_id: id, associate_with: 'site', show_dependencies: true })
        );
        break;
      default:
        break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  setModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  async onSave() {
    const { data, controls } = this.state;

    const { statuses } = this.props.sites;

    // Ensure reason is provided if status is ignored.
    const status = statuses.find((status) => status.id === controls.status_id.value);
    if (status.tag === 'ignore' && data.notes === controls.notes.value) {
      alert(
        'Warning: A comment is required for setting a site to ignored, please add a comment in the field below and try again.'
      );
      return;
    }

    const formData = saveFormControls(controls, data);

    const success = await this.props.dispatch(updateSite(formData));

    if (success) this.props.history.goBack();
  }

  async onRemove() {
    // const {
    //   data
    // } = this.state;
    // const confirmed = window.confirm(`Removing the ${data.name.toUpperCase()} property permanently with all its associated data. Continue?`);
    // if (confirmed) {
    //   const success = await this.props.dispatch(removeProperty(data.id));
    //   this.props.history.push(this.state.backlink);
    // }
  }

  async onClearScan() {
    const confirmed = window.confirm(
      `This will permanently remove scan result (samples, gamma_cal, spectra_cal and params). Continue?`
    );
    if (confirmed) {
      await this.props.dispatch(clearScan(this.state.data.id));
      this.props.history.push(this.state.backlink);
    }
  }

  async handleSamplesUpload(event) {
    if (event.target.files.length === 0) return;

    const file = event.target.files[0];

    let formData = new FormData();
    formData.append('csv', file);
    formData.append('site_id', this.state.data.id);

    await this.props.dispatch(uploadSamples(formData));
    this.props.dispatch(fetchSiteSamples({ site_id: this.state.data.id }));
  }

  onSelectPosition(position) {
    this.setState({ selectedPosition: position });
  }

  async onDeleteScan(id) {
    const { data } = this.state;

    const confirmed = window.confirm(
      `Removing archived scan permanently with all its associated data and images. Continue?`
    );
    if (confirmed) {
      await this.props.dispatch(removeSiteScan(id, data.id));
    }
  }

  onMoveScan() {
    const { data } = this.state;

    const destination_core_id = prompt(`Core ID to move this site scan files to:`);
    if (destination_core_id) {
      const confirm = prompt(
        `Permanently moving scan files for core ${data.core_id} to core ${destination_core_id}. Type MOVE to confirm action`
      );
      if (confirm === 'MOVE') {
        this.props.dispatch(moveScan(data.id, destination_core_id));
      } else {
        alert('Invalid confirmation text');
      }
    }
  }

  render() {
    const { controls, data, isModalOpen, selectedPosition } = this.state;

    const { activities } = this.props.activities;

    const { responseMessage, samples, scans, statuses, operators_drill, operators_scan } =
      this.props.sites;

    const iconName = 'vial';

    return (
      <div>
        <div className="p-4">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row">
              <Icon size="2x" name={iconName} className="appForeTint mr-3" />
              <h3>Site: {data.core_key}</h3>
            </div>
          </div>

          <BreadcrumbsRoute match={this.props.match} />

          <Nav tabs className="mt-2">
            <FormTab
              caption="Profile"
              tabId="1"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
            />
            <FormTab
              caption="Samples"
              tabTag="samples"
              tabId="2"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
            />
            <FormTab
              caption="Scan Archive"
              tabTag="scans"
              tabId="3"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
            />
            <FormTab
              iconName="comment"
              tabTag="activity"
              caption="Activity"
              tabId="4"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
            />
          </Nav>

          <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
            <TabPane tabId="1" className="mb-2 mt-2">
              <SiteProfile
                statuses={statuses}
                operators_drill={operators_drill}
                operators_scan={operators_scan}
                controls={controls}
                handleChange={this.handleChange}
                onRemove={this.onRemove}
                onCancel={this.onCancel}
                onSave={this.onSave}
                onClearScan={this.onClearScan}
                setModal={this.setModal}
                checkAccess={this.checkAccess}
                onMoveScan={this.onMoveScan}
              />
              <ResponseMessage responseMessage={responseMessage} />
            </TabPane>

            <TabPane tabId="2" className="mb-2 p-1">
              {samples && samples.length > 0 && (
                <div>
                  <SiteSamplesChartGamma samples={samples} />
                  <SiteSamplesChartSpectraNm
                    samples={samples}
                    onSelectPosition={this.onSelectPosition}
                    selectedPosition={selectedPosition}
                  />
                  <SiteSamplesColours
                    samples={samples}
                    onSelectPosition={this.onSelectPosition}
                    selectedPosition={selectedPosition}
                  />
                  <SiteSamplesImages
                    samples={samples}
                    onSelectPosition={this.onSelectPosition}
                    selectedPosition={selectedPosition}
                  />
                </div>
              )}

              <SiteSamplesLsv
                history={this.props.history}
                match={this.props.match}
                rows={samples || []}
              />
            </TabPane>

            <TabPane tabId="3" className="mb-2 p-1">
              <SiteScansLsv
                checkAccess={this.checkAccess}
                samples={samples}
                rows={scans || []}
                currentSite={data}
                onDelete={this.onDeleteScan}
              />
            </TabPane>

            <TabPane tabId="4" className="mb-2 p-1">
              <ActivityModal setModal={this.setModal} isOpen={isModalOpen} />
              <ActivitiesLsv rows={activities || []} />
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

const mapStoreToProps = ({ sites, activities, realm }) => ({
  sites,
  activities,
  realm,
});

export default connect(mapStoreToProps)(withContainerError(Site));
