import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Row, Col, Input } from 'reactstrap';
import FormBase from 'jsx/components/core/form/components/FormBase';
import FormInput from 'jsx/components/core/form/components/FormInput';
import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';
import Pill from 'jsx/components/core/form/components/Pill';

import {
  updateControls,
  saveControls,
  initControls
} from 'jsx/components/core/form/lib/validateForm';

import Icon from 'jsx/components/core/icons/Icon';
import { cloneDeep } from 'lodash';
import { controls as templateControls } from '../../forms/admin/template_metric';

import AdminProbeSectionGroupEditor from '../../components/admin/AdminProbeSectionGroupEditor';
import { controls as groupControls } from '../../forms/admin/template_group';

import { fetchLookupMetrics } from '../../actions/reports';
import { fetchAttributes } from '../../actions/attributes';
import { 
  fetchProbeGroupRow,
  createProbeGroupRow,
  updateProbeGroupRow,
  removeProbeGroupRow,
  createProbeSectionGroup,
  updateProbeSectionGroup,
  fetchProbeTemplateByTag
} from '../../actions/admin/probe';

class AdminProbeTemplateModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
        isValid: true,
        isOpen: false,
        data: {},
        id: null,
        isNew: false,
        isGroupNew: true,
        title: '',
        setModal: null,
        controls: cloneDeep(templateControls),
        kpiGroupControls: groupControls,
        filterAttributes: {divisions: [], enterprise_types: [], aggregation: null},
        showAddNewGroup: false
    };

    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onHandleFilterChange = this.onHandleFilterChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.toggleAddGroup = this.toggleAddGroup.bind(this);
    this.loadGroups = this.loadGroups.bind(this);
    this.onHandleGroupChange = this.onHandleGroupChange.bind(this);
    this.onGroupSave = this.onGroupSave.bind(this);
  }

  async componentDidMount() {
    const { lookupMetrics } = this.props.reports;
    const { enterprise_types, divisions } = this.props.attributes;

    if (lookupMetrics.length === 0) await this.props.dispatch(fetchLookupMetrics());
    if (enterprise_types.length === 0) await this.props.dispatch(fetchAttributes({type: 'enterprise_types'}));
    if (divisions.length === 0) await this.props.dispatch(fetchAttributes({type: 'divisions'}));
  }

  async componentDidUpdate() {
    const { isOpen } = this.state;
    let { title, data, controls, isNew, filterAttributes } = this.state;

    const { lookupMetrics } = this.props.reports;
    const { enterprise_types, divisions } = this.props.attributes;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      this.setState({isOpen: this.props.isOpen});
      title = 'Edit Template Metric';
      data = {};
      isNew = true;

      controls = initControls(controls);

      controls.group_id.options = this.loadGroups();
      controls.metric_id.options = [{id: null, name: 'Select to Filter'}].concat(lookupMetrics.map(lookupMetric => ({id: lookupMetric.id, name: lookupMetric.name})));
      controls.filter_division_id.options = [{id: null, name: 'Select to Filter'}].concat(divisions);

      const groupOptions = divisions.map(division => {
        const options = enterprise_types.filter( enterprise_type => enterprise_type.parent_id === division.id);
        return {groupLabel: division.name, options};
      });

      controls.filter_enterprise_type_id.options = groupOptions;
      controls.filter_enterprise_type_id.disabled = true;

      if (!controls.iteration_type_tag.value) controls.iteration_type_tag.value = 'single';

      const { id } = this.props;
      if (id) {
        isNew = false;
        data = await this.props.dispatch(fetchProbeGroupRow(id));
        controls = updateControls(controls, data);
        
        filterAttributes = data.attributes?.filter || {divisions: [], enterprise_types: [], aggregation: null};
      }

      this.setState({
        title,
        setModal: this.props.setModal,
        data,
        id,
        isNew,
        filterAttributes
      });
    }
  }


  loadGroups() {
    const {
      probeTemplate
    } = this.props.probe;

    const kpigroups = [{id: null, name: 'None Selected'}];
    const probeSections = probeTemplate.sections.filter(section => section.id === this.props.section.id);
    probeSections.map(section => section.groups.map(group => (kpigroups.push({id: group.id, name: `${section.caption} - ${group.caption}`}))));

    return kpigroups;
  }

  onHandleFilterChange(event) {
    const { filterAttributes, controls } = this.state;
    const { enterprise_types } = this.props.attributes;

    switch (event.target.name) {
      case 'filter_division':
        // Clear filter
        filterAttributes.divisions = [];
        filterAttributes.enterprise_types = [];
        
        // Set filter Attributes
        const division_tag = controls.filter_division_id.options[event.target.options.selectedIndex].name;
        filterAttributes?.divisions?.push({id: event.target.value, name: division_tag});
        controls.filter_enterprise_type_id.disabled = false;

        // Filter enterprise combo
        const options = enterprise_types.filter( enterprise_type => enterprise_type.parent_id === event.target.value);
        const groupOptions = {groupLabel: division_tag, options};
        controls.filter_enterprise_type_id.options = [groupOptions];
      break;

      case 'filter_enterprise_type':
        filterAttributes?.enterprise_types?.push({id: event.target.value, name: event.target.label});
        controls.filter_aggregation.disabled = false;
      break;

      case 'filter_aggregation':
        const aggregation_tag = controls.filter_aggregation.options[event.target.options.selectedIndex].name;
        filterAttributes.aggregation = {id: event.target.value, name: aggregation_tag};
      break;

      default: break;
    }

    this.setState({filterAttributes, controls});
  }

  async onDelete() {
    const { data } = this.state;

    const confirmed = window.confirm('Removing Probe row permanently. Continue?');
    if (confirmed) {
      const success = await this.props.dispatch(removeProbeGroupRow(data.id));
      if (success) {
        this.props.dispatch(fetchProbeTemplateByTag(this.props.probe_tag));
        this.onClose(true);
      }
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onClose) this.props.onClose();
    this.state.setModal(false);
    this.clearFilters();
    this.setState({
      isOpen: false,
    });
  }

  async onSave() {
    const { controls, isNew, data, filterAttributes } = this.state;

    const unsavedData = saveControls(controls, data);

    delete unsavedData.filter_division_id;
    delete unsavedData.filter_enterprise_type_id;
    delete unsavedData.filter_aggregation;
    unsavedData.attributes = {filter: filterAttributes, section_id: this.props.section.id};

    if (unsavedData.iteration_type_tag === 'by_enterprise_types' && filterAttributes?.divisions.length !== 1) {
      alert('Must select exactly 1 division for this iteration type');
      return;
    }

    if (unsavedData.iteration_type_tag === 'by_enterprise_types' && !filterAttributes?.aggregation?.id) {
      alert('Must select an aggregation');
      return;
    }

    let success;
    if (isNew) {
      delete unsavedData.id;
      success = await this.props.dispatch(createProbeGroupRow(unsavedData));
    } else {
      success = await this.props.dispatch(updateProbeGroupRow(unsavedData));
    }

    if (success) {
      this.props.dispatch(fetchProbeTemplateByTag(this.props.probe_tag));
      this.onClose(true);
    }
  }

  clearFilters() {
    this.setState({filterAttributes: {divisions: [], enterprise_types: [], aggregation: null}});
  }

  toggleAddGroup(open) {
    this.setState({showAddNewGroup: open});
  }

  onHandleGroupChange (event) {
    const { kpiGroupControls } = this.state;
    kpiGroupControls[event.target.name].value = event.target.value;
    this.setState({kpiGroupControls});
  }

  async onGroupSave() {
    const { controls, isGroupNew } = this.state;
    let { kpiGroupControls } = this.state;

    const unsavedData = {
      caption: kpiGroupControls.name.value,
      section_id: this.props.section.id,
      sequence: kpiGroupControls.sequence.value || 0
    };

    let success;
    if (isGroupNew) {
      success = await this.props.dispatch(createProbeSectionGroup(unsavedData));
    } else {
      // success = await this.props.dispatch(updateProbeSectionGroup(unsavedData));
    }

    if (success) {
      await this.props.dispatch(fetchProbeTemplateByTag(this.props.probe_tag));
      controls.group_id.options = await this.loadGroups();

      kpiGroupControls = initControls(kpiGroupControls);

      this.setState({controls, kpiGroupControls});
      this.toggleAddGroup(false);
    }
  }

  renderFilters() {
    const filters = [];
    const { filterAttributes } = this.state;

    filterAttributes?.divisions.map((division, index) => {
      const name = `Division: ${ division.name }`;
      filters.push(<Pill classes="bg-success" key={`${division.id} - ${index}`} caption={name} />);
    });

    filterAttributes?.enterprise_types.map((enterprise_type, index) => {
      const name = `Enterprise Type: ${ enterprise_type.name }`;
      filters.push(<Pill classes="bg-pink" key={`${enterprise_type.id} - ${index}`} caption={name} />);
    });

    if (filterAttributes.aggregation) {
      const name = `Aggregation: ${ filterAttributes.aggregation?.name }`;
      filters.push(<Pill classes="bg-orange" key={filterAttributes.aggregation?.name} caption={name} />);
    }

    return filters;
  }

  render() {
    const { title, isOpen, controls, kpiGroupControls, showAddNewGroup, isNew } = this.state;
    const { iconName } = this.props;
    const { responseMessage } = this.props.probe;

    return (
      <Modal isOpen={isOpen} >
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody className="m-2 p-2">
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <div className="border border-gray-40 p-2 rounded mb-2 bg-green-20">
              Section: {this.props.section?.caption}
            </div>

            <small className="ml-2 text-corporate">Group and Metric</small>
            <div className="border border-gray-40 p-2 rounded">
              {/* <FormInputSelect
                handleChange={({ value, label }) =>
                  this.handleChange({target: {value, label, name: 'iteration_type_tag'}  })
                }
                control={controls.iteration_type_tag}
              /> */}

              {!showAddNewGroup && controls.iteration_type_tag.value === 'single' && (
                <>
                  <FormInput handleChange={this.handleChange} control={controls.group_id} />
                  <Button onClick={this.toggleAddGroup} className="ml-1 p-0" color="link"><small>Add New KPI Group</small></Button>
                </>
              )}

              {showAddNewGroup && (
                <AdminProbeSectionGroupEditor
                  controls={kpiGroupControls}
                  onSave={this.onGroupSave}
                  toggleClose={this.toggleAddGroup}
                  onHandleChange={this.onHandleGroupChange}
                />
              )}

              <Row>
                <Col>
                  <FormInputSelect
                    handleChange={({ value, label }) =>
                      this.handleChange({target: {value, label, name: 'metric_id'}  })
                    }
                    control={controls.metric_id}
                    groupLabel="Select to Filter"
                  />
                </Col>
                <Col sm={3}>
                  <FormInput handleChange={this.handleChange} control={controls.sequence} />
                </Col>
              </Row>
            </div>
            <div className="mt-2">
              <small className="ml-2 text-corporate">Filters</small>
              <div className="border border-gray-40 p-2 rounded">
                <Row>
                  <Col><FormInput handleChange={this.onHandleFilterChange} control={controls.filter_division_id} /></Col>
                  {controls.iteration_type_tag.value === 'single' && (
                    <Col>
                      <FormInputSelect
                        handleChange={({ value, label }) =>
                          this.onHandleFilterChange({target: {value, label, name: 'filter_enterprise_type'}  })
                        }
                        control={controls.filter_enterprise_type_id}
                        isGrouped
                        groupLabel="Select to Filter"
                      />
                    </Col>
                  )}
                  <Col><FormInput handleChange={this.onHandleFilterChange} control={controls.filter_aggregation} /></Col>
                </Row>

                <div className="d-flex float mt-2">{this.renderFilters()}</div>
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" className="mr-2" color="light" onClick={this.onClose}>
              Cancel
            </Button>
            {!isNew && (
              <Button size="sm" className="mr-2" color="danger" onClick={this.onDelete}>
                Delete
              </Button>
            )}

            <Button size="sm" color="primary" onClick={this.clearFilters}>
              Clear Filters
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ analysis_periods, analysis_groups, probe, reports, attributes }) => ({
  analysis_periods,
  analysis_groups,
  probe,
  reports,
  attributes
});

export default connect(mapStoreToProps)(AdminProbeTemplateModal);
