import { isEmpty, isString } from 'lodash';

export const isPercentage = (value) => {
  if (!isString(value)) return false;
  return /^\d+(\.\d+)?%$/.test(value.trim());
};

export const isCurrency = (value) => {
  if (!isString(value)) return false;

  const trimmedValue = value.trim();
  if (trimmedValue.charAt(0) === '$') return true;
  if (trimmedValue.charAt(0) === '-') return trimmedValue.charAt(1) === '$';
  return false;
};

export const isFormattedNumber = (value) => {
  if (!isString(value)) return false;
  let trimmedValue = value.trim();
  if (trimmedValue.charAt(0) === '-') {
    trimmedValue = trimmedValue.substring(1);
  }

  return /^\d+(\.\d+)?(,\d{3})*(\.\d+)?$/.test(trimmedValue);
};

/**
 * Compares two strings. The comparisons that are supported are: formatted numbers, percentages, and currencies.
 * Everything else is a string comparison
 * @param {string} keyA
 * @param {string} keyB
 * @returns
 */
export const compare = (keyA, keyB) => {
  try {
    if (isEmpty(keyA) || isEmpty(keyB)) {
      if (typeof keyA !== 'number' && isEmpty(keyA) && !isEmpty(keyB)) return -1;
      if (typeof keyB !== 'number' && isEmpty(keyB) && !isEmpty(keyA)) return 1;
      if (isEmpty(keyA) && isEmpty(keyB)) {
        // We consider a zero value to be bigger than an empty value
        if (typeof keyA === 'number' && (typeof keyB === 'object' || typeof keyB === 'undefined'))
          return 1;
        if (typeof keyB === 'number' && (typeof keyA === 'object' || typeof keyA === 'undefined'))
          return -1;
      }
    }

    if (isPercentage(keyA) && isPercentage(keyB)) {
      keyA = parseFloat(keyA);
      keyB = parseFloat(keyB);
    } else if (isCurrency(keyA) || isCurrency(keyB)) {
      // Remove $ and any other formatting
      keyA = Number(keyA.replace(/[$,]/g, ''));
      keyB = Number(keyB.replace(/[$,]/g, ''));

      // Convert to float
      keyA = parseFloat(keyA);
      keyB = parseFloat(keyB);
    } else if (isFormattedNumber(keyA) && isFormattedNumber(keyB)) {
      keyA = parseFloat(keyA.replace(/[,]/g, ''));
      keyB = parseFloat(keyB.replace(/[,]/g, ''));
    }

    // assume alpha string
    if (keyA > keyB) return 1;
    if (keyB > keyA) return -1;
    return 0;
  } catch {
    return 0;
  }
};
