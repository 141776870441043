import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

import FormBase from '../../core/form/components/FormBase';

class ReportMetricsAttributeModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: {},
      metric: {},
      title: 'Attributes',
    };
  }

  onCancel() {
    this.props.setModal(false);
  }

  render() {
    const { title } = this.state;
    const { isOpen } = this.props;
    const { responseMessage } = this.props.reports;
    const iconName = 'tags';

    return (
      <Modal isOpen={isOpen} size="lg">
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Row>
            <Col className="bg-light">
              Left hand side place holder for section/row/column attributes such as showHeader,
              showFooter etc
            </Col>
            <Col className="bg-light">
              Righ-hand side place holder for selection of style classes that can be added to a list
              to control the appearance of a section, fow or column..
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onCancel}>
              Save
            </Button>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ reports }) => {
  return {
    reports,
  };
};

export default connect(mapStoreToProps)(ReportMetricsAttributeModal);
