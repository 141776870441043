import Listview from 'jsx/components/core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';
import React from 'react';

const tableHeadTh = ['Approver', 'Level', ''].map((caption, index) => (
  <th key={index}>{caption}</th>
));
const ProjectApproverListview = ({ approvers, deleteApprover }) => {
  const rows = approvers.sort((a, b) => a.level - b.level);
  const tableBodyTr = rows.map(({ user, level }, index) => (
    <tr key={index}>
      <td className="align-middle">{user.name}</td>
      <td className="align-middle">{level}</td>
      <td>
        <div className="align-middle d-flex justify-content-end">
          <Icon
            name="trash"
            className="text-danger"
            pointer
            title="Delete approver"
            as="button"
            onClick={(e) => {
              e.preventDefault();
              deleteApprover(user.id, level);
            }}
          />
        </div>
      </td>
    </tr>
  ));
  return (
    <Listview
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      emptyCaption="No approvers defined"
    />
  );
};

export default ProjectApproverListview;
