import React, { Fragment } from 'react';

const PropertySearchAttribute = (props) => {
  const {
    attribute
  } = props;

  let source = attribute.source;
  let rows = attribute.features.map((feature, index) => {

    let properties = Object.entries(feature.properties).map((property, index) => (
      <div key={index} className="p-1">
        <div className="text-corporate">{property[0]}</div>
        <div className="text-white">{property[1]}</div>
      </div>
    ))

    return (
      <div key={index} style={{ backgroundColor: '#333333' }} className="border-bottom border-secondary p-1">
        <div className="p-1">
          <small className="text-corporate">ID: <span className="text-white">{feature.id}</span></small>
        </div>
        <div className="mt-1">
          {properties}
        </div>
      </div>
    )

  });


  return (
    <Fragment>
      <div className="m-1 border border-secondary rounded">
        <div className=" d-flex justify-content-between p-2">
          <div>{source.name}</div>
        </div>
        {rows}
      </div>
    </Fragment >
  );
}

export default PropertySearchAttribute;
