import React, { useState, useEffect } from 'react';
import { Button, Label, Collapse, Card, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import PillPicker from '../../../../core/form/components/PillPicker';
import PillPresenter from '../../../../core/form/components/PillPresenter';
import PillDateRange from '../../../../core/form/components/PillDateRange';
import Pill from '../../../../core/form/components/Pill';
import SingleSearch from '../../../../core/form/components/SingleSearch';
import { fetchProjectStatuses, setFilters, fetchProjectOfficers } from '../../actions/projects';

const ProjectsFilter = ({
  checkAccess,
  reload,
  projects,
  setModal,
  handleSearchChange,
  filterValue,
  rowsCount,
}) => {
  const {
    project_status_ids,
    filters,
    projectOfficers = [],
  } = useSelector((store) => store.projects);

  const dispatch = useDispatch();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [projectOfficerSelection, setProjectOfficerSelection] = useState(
    filters.assignedto_id || [],
  );
  const [searchTerm, setSearchTerm] = useState(filterValue);

  useEffect(() => {
    if (!isCollapseOpen) return;
    dispatch(fetchProjectStatuses(true));
    dispatch(fetchProjectOfficers(true));
  }, [isCollapseOpen, dispatch]);

  const statusSelection = filters.project_status_id;

  let filtersCount = statusSelection.length + filters.assignedto_id.length;

  let placeholder = '';
  let startDate = '';
  if (filters.from_created_date !== '') {
    startDate = new Date(Date.parse(filters.from_created_date));
    filtersCount += 1;
  } else {
    placeholder = 'Select a date';
  }

  const handleStatusChange = (event) => {
    const exists = filters.project_status_id.find((id) => id === event.target.value);
    if (!exists) {
      filters.project_status_id.push(event.target.value);
    }
    dispatch(setFilters(filters));
  };

  const handleStatusDelete = (id, reset = true) => {
    const idx = filters.project_status_id.findIndex((filterId) => filterId === id);
    filters.project_status_id.splice(idx, 1);
    if (reset) {
      dispatch(setFilters(filters));
    }
  };

  const handleDateChange = (date) => {
    const newFilters = { ...filters, from_created_date: moment(date).format('YYYY-MM-DD') };
    dispatch(setFilters(newFilters));
  };

  const handleDateDelete = () => {
    const newFilters = { ...filters, from_created_date: '' };
    dispatch(setFilters(newFilters));
  };

  const handleProjectOfficerChange = (event) => {
    const exists = filters.assignedto_id.find((id) => id === event.target.value);
    const newSelection = [...projectOfficerSelection];
    if (!exists) {
      newSelection.push(event.target.value);
    }
    const newFilters = { ...filters, assignedto_id: newSelection };
    setProjectOfficerSelection(newSelection);
    dispatch(setFilters(newFilters));
  };

  const handleProjectOfficerDelete = (id, reset = true) => {
    const filteredAssignedto = filters.assignedto_id.filter((currId) => currId !== id);
    const newFilters = { ...filters, assignedto_id: filteredAssignedto };
    if (reset) {
      setProjectOfficerSelection(filteredAssignedto);
      dispatch(setFilters(newFilters));
    }
  };

  return (
    <div className="bg-light p-1">
      <div className="m-1 d-flex justify-content-end ">
        <Label className="m-1 mr-2 text-secondary">
          Showing {projects.length} of {rowsCount} results
        </Label>
        <SingleSearch
          handleSearchChange={({ target, code }) => {
            const searchValue = target.value;
            setSearchTerm(searchValue);
            if (code === 'Enter' || code === 'NumpadEnter') {
              handleSearchChange(searchValue);
            }
          }}
          onSearch={() => {
            handleSearchChange(searchTerm);
          }}
          placeholder="Filter by keyword"
          value={searchTerm}
        />

        <Button
          aria-label="Refresh Projects"
          onClick={reload}
          size="sm"
          color="success"
          className="mr-2"
        >
          <Icon name="rotate" />
        </Button>

        <Button
          onClick={() => setModal(true)}
          disabled={!checkAccess('projectCreate')}
          size="sm"
          color="success"
        >
          Add New Project
        </Button>
        <Button
          size="sm"
          color="warning"
          className="text-white ml-2"
          onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        >
          Filter
          <Icon className="ml-1" name="filter" />
        </Button>
      </div>
      <Collapse isOpen={isCollapseOpen} className="m-1">
        <Card className="border border-corporate rounded mb-0">
          <Row className="m-0 p-0">
            <Col sm={3} className="m-1">
              <PillDateRange
                caption="Created after"
                handleChange={handleDateChange}
                startDate={startDate}
                disabled={false}
                placeholder={placeholder}
              />
            </Col>
            <Col sm={3} className="m-1">
              <PillPicker
                caption="Project Status"
                handleChange={handleStatusChange}
                rows={project_status_ids}
                selection={statusSelection}
                showPills={false}
              />
            </Col>
            <Col sm={3} className="m-1">
              <PillPicker
                caption="Project Officer"
                handleChange={handleProjectOfficerChange}
                rows={projectOfficers}
                selection={projectOfficerSelection}
                showPills={false}
              />
            </Col>
          </Row>
        </Card>
      </Collapse>

      {filtersCount > 0 && (
        <div className="bg-light d-flex rounded p-1">
          {filters.from_created_date !== '' && (
            <Pill
              onDelete={handleDateDelete}
              id={3}
              caption={`After ${filters.from_created_date}`}
              classes="bg-indigo d-flex float-left mt-1"
            />
          )}
          <PillPresenter
            caption="Statuses"
            handleDelete={handleStatusDelete}
            rows={project_status_ids}
            selection={statusSelection}
            colourField="colour"
          />
          <PillPresenter
            caption="Project Officer"
            handleDelete={handleProjectOfficerDelete}
            rows={projectOfficers}
            selection={projectOfficerSelection}
            colourField="colour"
          />
        </div>
      )}
    </div>
  );
};

export default ProjectsFilter;
