import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    fieldName: 'name',
    name: 'name',
    caption: 'Name',
    showInListview: true,
    type: 'text',
    validationRules: {
      isRequired: true,
    },
  },
  enabled: {
    ...controlDefaults,
    fieldName: 'enabled',
    name: 'enabled',
    caption: 'Enabled',
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    showInListview: false,
    type: 'checkbox',
    value: true,
  },
  gamma: {
    fieldName: 'plan.gamma-formatted',
    caption: 'Gamma',
    showInListview: true,
  },
  spectrometer: {
    fieldName: 'plan.spectrometer-formatted',
    caption: 'Spectrometer',
    showInListview: true,
    type: 'text',
  },
  camera: {
    fieldName: 'plan.camera-formatted',
    caption: 'Camera',
    showInListview: true,
    type: 'text',
  },
  scale: {
    fieldName: 'plan.scale-formatted',
    caption: 'Scale',
    showInListview: true,
    type: 'text',
  },
  enabled_listview: {
    ...controlDefaults,
    classes: 'text-center',
    fieldName: 'enabled',
    caption: 'Enabled',
    showInListview: true,
    type: 'pill',
    value: true,
  },
};
