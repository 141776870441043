import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import DaysheetActivities from './DaysheetActivities';
import DaysheetApprovals from './DaysheetApprovals';
import DaysheetSummaries from '../components/DaysheetSummaries';
import Shifts from './rosters/Shifts';
import { fetchShifts } from '../actions/rosters/shifts';

class Daysheets extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isNew: false,
      tabRefresh: false,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.setRefresh = this.setRefresh.bind(this);
  }

  toggleTab = (tab) => {
    if (this.props.assets?.activeTabAssets !== tab) {
      this.props.dispatch({ type: 'SET_TIMESHEETS_ACTIVE_TAB', payload: tab });
    }

    if (tab === 'shifts') {
      this.props.dispatch(fetchShifts());
    }

    this.setRefresh(true);
  };

  setRefresh(tabRefresh) {
    this.setState({ tabRefresh });
  }

  render() {
    const { tabRefresh } = this.state;
    const { activeTimesheetsTab } = this.props.wipstar;

    const iconName = 'wave-pulse';
    const canViewTeamSummary = this.checkAccess('teamSummary');

    return (
      <div className="p-2 h-100 d-flex flex-column">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row m-2">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Daysheets</h3>
          </div>
        </div>

        <Nav tabs className="mt-2">
          <FormTab
            iconRightClass="primary"
            iconRightName="list"
            caption="Timesheets"
            tabId="daysheets"
            tabTag="daysheets"
            activeTab={activeTimesheetsTab}
            toggle={this.toggleTab}
          />
          <FormTab
            iconRightClass="success"
            iconRightName="check"
            caption="My Approvals"
            tabId="myapprovals"
            tabTag="myapprovals"
            activeTab={activeTimesheetsTab}
            toggle={this.toggleTab}
          />
          <FormTab
            iconRightClass="success"
            iconRightName="check"
            caption="Team Summary"
            tabId="3"
            tabTag="teamsummary"
            activeTab={activeTimesheetsTab}
            toggle={this.toggleTab}
            disabled={!canViewTeamSummary}
          />
          <FormTab
            iconRightName="calendar"
            caption="Shifts"
            tabId="shifts"
            activeTab={activeTimesheetsTab}
            toggle={this.toggleTab}
          />
          <FormTab
            iconRightClass="success"
            iconRightName="calendar"
            caption="Rosters"
            tabId="4"
            tabTag="rosters"
            activeTab={activeTimesheetsTab}
            toggle={this.toggleTab}
            disabled
          />
          <FormTab
            caption="Daily Site Report"
            tabId="5"
            tabTag="dsr"
            activeTab={activeTimesheetsTab}
            toggle={this.toggleTab}
            disabled
          />
          <FormTab
            caption="Daily Activity Report"
            tabId="6"
            tabTag="das"
            activeTab={activeTimesheetsTab}
            toggle={this.toggleTab}
            disabled
          />
        </Nav>

        <TabContent activeTab={activeTimesheetsTab} className="d-flex flex-column flex-grow-1">
          <TabPane tabId="daysheets" className="mb-2 p-1">
            <DaysheetActivities forceRefresh={tabRefresh} setRefresh={this.setRefresh} />
          </TabPane>

          <TabPane tabId="myapprovals" className="mb-2 p-1 h-100">
            <DaysheetApprovals forceRefresh={tabRefresh} setRefresh={this.setRefresh} />
          </TabPane>

          <TabPane tabId="3" className="mb-2 p-1 h-100">
            {canViewTeamSummary && (
              <DaysheetSummaries forceRefresh={tabRefresh} setRefresh={this.setRefresh} />
            )}
          </TabPane>

          <TabPane tabId="shifts" className="mb-2 p-1 h-100">
            <Shifts />
          </TabPane>

          <TabPane tabId="5" className="mb-2 p-1 h-100">
            <p>
              Project Manager or Enginereer define a daily job/activity list for sites with
              priorities that can be selected in the timesheet.
            </p>
          </TabPane>

          <TabPane tabId="6" className="mb-2 p-1 h-100">
            <p>
              Team summary for viewing total timesheets entered as a cross check ensuring timesheets
              are not missed.
            </p>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ wipstar, realm }) => ({
  wipstar,
  realm,
});

export default connect(mapStoreToProps)(Daysheets);
