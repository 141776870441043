import React from 'react';
import {
  Row,
  Col
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';

const ReconTripsLsv = (props) => {
  const {
    rows,
    selectSites
  } = props;

  const cells = rows.map(row => {
    const created_at = moment.utc(row.created).local().fromNow()
    const createdby = `${created_at} by ${row.createdby.firstname} ${row.createdby.lastname}`;
    const site_count = row.sites.length;
    const siteLabel = (row.sites.length === 1 ? 'site' : 'sites');
    const name = row.name;

    return (
      <Row className="m-0 ml-2 p-1 cells " onClick={() => {selectSites(row.sites)}} >
        <Col sm={1} className="icon">
          <Icon name="chevron-left" />
        </Col>
        <Col className="m-0 p-0">
          <div className="title">{name}</div>
          <div className="subtitle-left">{createdby}</div>
        </Col>
        <Col sm={2} className="hero rounded">
          <div className="big text-center">{site_count}</div>
          <div className="small text-center">{siteLabel}</div>
        </Col>
      </Row>
    )
  })
 
  return (
    <div >
      <div className="section-header mt-3">
        <span className="section-title text-white">Reconnaissance Trips</span>
      </div>

      {cells}
    </div>
  )
}

export default ReconTripsLsv;
