import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    name: 'name',
    fieldName: 'name',
    caption: 'Name',
    type: 'text',
    validationRules: {
      isRequired: true,
    },
    showInListview: true,
    showInEditor: true,
  },
  enabled: {
    ...controlDefaults,
    placeholder: '',
    type: 'checkbox',
    controlType: 'switch',
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    name: 'enabled',
    fieldName: 'enabled',
    caption: 'Enabled',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  latitude: {
    ...controlDefaults,
    placeholder: '',
    name: 'latitude',
    fieldName: 'latitude',
    type: 'number',
    caption: 'Latitude',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: true,
  },
  longitude: {
    ...controlDefaults,
    placeholder: '',
    name: 'longitude',
    fieldName: 'longitude',
    type: 'number',
    caption: 'Longitude',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: true,
  },
  tag: {
    ...controlDefaults,
    name: 'tag',
    fieldName: 'tag',
    type: 'text',
    caption: 'Tag',
    placeholder: '',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: true,
  },
};
