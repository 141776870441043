import React, { useState } from 'react';
import {
  Button,
  CustomInput
} from 'reactstrap';

import ProjectGroupModal from '../../containers/groups/ProjectGroupModal';
import ProjectGroupStages from "../../containers/groups/ProjectGroupStages";
import Icon from 'jsx/components/core/icons/Icon';

const GroupsLsv = (props) => {
  let {
    history,
    rows,
    project_id,
    checkAccess,
    unlockTemplate,
    unlock
  } = props;

  const [isOpen, setModal] = useState(false);

  const edit = (row) => {
    const lnk = `/home/projects/${project_id}/groups/${row.id}`;
    history.push(lnk);
  }

  const openModal = () => {
    setModal(true);
  }

  const renderForm = (row) => {
    return (<ProjectGroupStages className="m-2" key={row.id} project_id={row.project_id} group_id={row.id} history={history} />);
  }

  const renderGroups = (rows) => {
    return rows.map((row, index) => {
      return (
        <React.Fragment key={index}>
          <div className="bg-corporate text-white group-toolbar d-flex justify-content-between mb-1">
            <h5 className="m-0 p-2">{row.name}</h5>
            <Button color="link" className="text-white" onClick={() => edit(row)}>Manage Group</Button>
          </div>
          {renderForm(row)}
        </React.Fragment>
      )
    })
  }

  const iconName = 'object-group';
  const emptyCaption = 'No groups found';

  return (
    <div className="document-requirements">
      <div className="d-flex justify-content-end">
        {rows.length > 0 && (
          <CustomInput
            type="switch"
            id="unlockSwitch"
            name="unlockSwitch"
            label="Unlock Template"
            onChange={unlockTemplate}
            checked={unlock}
            className="p-1 mr-2"
          />
        )} 
        <Button disabled={!checkAccess('projectUpdate')} className="mb-2" size="sm" color="success" onClick={() => openModal()}>Add New Group</Button>
      </div>

      { rows && rows.length === 0 && (
        <div className="p-5 text-center">
          <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
          <div className="mt-3">{emptyCaption}</div>
        </div>
      )}
      <ProjectGroupModal setModal={setModal} project_id={project_id} isOpen={isOpen} />

      {renderGroups(rows)}

    </div>
  );
}

export default GroupsLsv;
