import React, {Fragment} from 'react';
import {
  Row,
  Col
} from 'reactstrap';

import SectionFilterSelect from './SectionFilterSelect';

import WidgetLayerEsm from '../containers/WidgetLayerEsm';
import WidgetLayerCeas from '../containers/WidgetLayerCeas';
import WidgetLayerStratas from '../containers/WidgetLayerStratas';
import WidgetLayerCores from '../containers/WidgetLayerCores';
import WidgetSpline from '../containers/WidgetSpline';
import WidgetLayerSoilMassByCores from '../containers/WidgetLayerSoilMassByCores';
import WidgetScanImages from '../containers/WidgetScanImages';

import Icon from 'jsx/components/core/icons/Icon';
import Pill from "../../../core/form/components/Pill";

const SectionLayers = (props) => {
  let {
    soilTemplates,
    filter,
    selectSoilTemplate,
    selectedTemplate,
    setTopFilter,
    areaRounds,
    selections,
    onDeleteSelection
  } = props;

  const className = "m-1 bg-white rounded border border-secondary p-2"

  // Getting the site selection if any
  let selectedSites = (<div className="text-danger">No sites selected</div>)
  if (selections.length > 0) {
    selectedSites = selections.map((selection, index) => {
      return (<Pill key={index} caption={selection} classes="bg-success" onDelete={() => onDeleteSelection(selection, 'sites')} />)
    })
  }

  // Load template options
  const templateOptions = soilTemplates.map((soilTemplate, index) => {
    const name = soilTemplate.name;
    return <option key={index} value={soilTemplate.id} >{name}</option>
  })
  if (soilTemplates.length > 0 && !selectedTemplate.id) {
    selectSoilTemplate(soilTemplates[0].id, filter.area_round_id);
  }

  // Load Area Round options
  const areaRoundOptions = areaRounds.map((areaRound, index) => {
    const name = `CEA${areaRound.area.name}`
    return <option key={index} value={areaRound.id}>{name}</option>
  })

  const emptyCaption = `Select a CEA to show CEA level Analytics`;
  const iconName = 'chart-mixed';

  const ceaLayersEnabled = false;
  return (
    <React.Fragment>
      <Row className={className}>
        <Col sm="12" className="d-flex justify-content-between">
          <SectionFilterSelect 
            onChange={selectSoilTemplate}
            area_round_id={filter.area_round_id}
            selected={selectedTemplate.id}
            caption="Select Soil Layer Template"
            options={templateOptions}
            disabled={false}
          />
        </Col>
      </Row>
      <small className="text-danger ml-2">Cea Carbon calculations widget have been disabled and does not show on this screen while verification and validation of the calculations are being completed. </small>

      {ceaLayersEnabled && (
        <Fragment>
          <div className="section-header">
            <h3 className="title">All CEA's</h3>
          </div>
          <WidgetLayerCeas selectedTemplate={selectedTemplate} filter={filter} />
        </Fragment>
      )}


      <div className="section-header">
        <h3 className="title">Individual CEA</h3>
      </div>
      <Row className={className}>
        <Col sm="6" className="d-flex justify-content-between">
          <SectionFilterSelect 
            onChange={(value) => setTopFilter('area_round_id', value)}
            area_round_id={filter.area_round_id}
            selected={filter.area_round_id}
            caption="Select CEA"
            options={areaRoundOptions}
            disabled={false}
            allowEmpty={true}
          />
        </Col>
        <Col sm="6">
          <div>
            <div className="text-right"><small>Selected Sites</small></div>
            <div className="d-flex flex-row float-right">
              {selectedSites}
            </div>
          </div>
        </Col>
      </Row>

      {!filter.area_round_id && (
        <div className="p-5 text-center h-100">
          <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
          <div className="mt-3">{emptyCaption}</div>
        </div>
      )}

      {filter.area_round_id && (
        <div>
          <WidgetLayerStratas selectedTemplate={selectedTemplate} area_round_id={filter.area_round_id}/>

          <div className="section-header">
            <h3 className="title">Cores for CEA</h3>
          </div>

          <Row className="m-0 p-0">
            <Col sm={9} className="p-1">
              <WidgetLayerCores selectedTemplate={selectedTemplate} area_round_id={filter.area_round_id} />
            </Col>
            <Col sm={3} className="p-1">
              <WidgetSpline />
            </Col>
          </Row>

          <WidgetScanImages />

          <WidgetLayerSoilMassByCores selectedTemplate={selectedTemplate} area_round_id={filter.area_round_id} />

          <Row>
            <Col>
              <WidgetLayerEsm
                selectedTemplate={selectedTemplate}
                area_round_id={filter.area_round_id}
              />
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
}

export default SectionLayers;
