import { controlDefaults } from 'jsx/components/core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  iteration_type_tag: {
    ...controlDefaults,
    name: 'iteration_type_tag',
    fieldName: 'iteration_type_tag',
    type: 'select',
    caption: 'Iteration',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    options: [
      {id: 'single', name: 'Single Row'},
      {id: 'by_enterprise_types', name: 'Iterate over Enterprise Types for Division'}
    ],
    showInEditor: true,
    showInListview: true,
  },
  group_id: {
    ...controlDefaults,
    name: 'group_id',
    fieldName: 'group_id',
    type: 'select',
    caption: 'KPI Group',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    options: [
      {id: null, name: 'None Selected'}
    ],
    showInEditor: true,
    showInListview: true,
  },
  metric_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'metric_id',
    name: 'metric_id',
    type: 'select',
    caption: 'Metric',
    validationRules: {
      isRequired: true,
    },
    options: [
      {id: null, name: 'None Selected'}
    ],
    showInEditor: true,
    showInListview: true,
  },
  sequence: {
    ...controlDefaults,
    name: 'sequence',
    fieldName: 'sequence',
    type: 'number',
    caption: 'Order',
    showInEditor: true,
    showInListview: true
  },
  filter_division_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'filter_division',
    name: 'filter_division',
    type: 'select',
    caption: 'Division Filter',
    validationRules: {
      isRequired: true,
    },
    options: [
      {id: null, name: 'None Selected'}
    ],
    showInEditor: true,
    showInListview: true,
    col: 2,
  },
  filter_enterprise_type_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'filter_enterprise_type',
    name: 'filter_enterprise_type',
    type: 'select',
    caption: 'Enterprise Type Filter',
    validationRules: {
      isRequired: true,
    },
    options: [
      {id: null, name: 'Select to Filter'}
    ],
    disabled: true,
    showInEditor: true,
    showInListview: true,
  },
  filter_aggregation: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'filter_aggregation',
    name: 'filter_aggregation',
    type: 'select',
    caption: 'Aggregation',
    validationRules: {
      isRequired: true,
    },
    disabled: false,
    options: [
      {id: null, name: 'Select to Filter'},
      // {id: 'each', name: 'Show Each Row'},
      {id: 'sum', name: 'Show Total Value'},
      {id: 'sumsubmetrics', name: 'Sum Sub-metrics individually before Divide'}
    ],
    showInEditor: true,
    showInListview: true,
  },
};
