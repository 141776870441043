import React from 'react';
import { 
  Button,
  Row,
  Col,
  Form
} from 'reactstrap';
import FormInput from "../../../core/form/components/FormInput";
import FormInputFile from "../../../core/form/components/FormInputFile";

const SiteProfile = (props) => {
  const {
    handleChange,
    controls,
    onCancel,
    onSave,
    onRemove,
    onMoveScan,
    onClearScan,
    statuses,
    operators_drill,
    operators_scan,
    setModal,
    checkAccess
  } = props;

  if (controls && controls['status_id']) {
    controls['status_id'].options = statuses;
  }

  if (controls && controls['collected_by']) {
    controls['collected_by'].options = operators_drill;
  }

  if (controls && controls['scannedby_id']) {
    controls['scannedby_id'].options = operators_scan;
  }

  return (
    <React.Fragment>
      <Form>
        <Row className="bg-light m-0 pb-2">
          <Col sm="3"><FormInput handleChange={handleChange} control={controls.name} disabled={true} /></Col>
          <Col sm="3"><FormInput handleChange={handleChange} control={controls.status_id} /></Col>
          <Col sm="3">
            <FormInput handleChange={handleChange} control={controls.id} />
            {/* <Icon name="copy" className="ml-3 text-secondary" onClick={() => copyToClipboard()} /> */}
          </Col>
        </Row>

        <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Collection</h5>
        <Row className="bg-light m-0 pb-2">
          <Col sm="3"><FormInput handleChange={handleChange} control={controls.target_latitude} /></Col>
          <Col sm="3"><FormInput handleChange={handleChange} control={controls.target_longitude} /></Col>
          <Col sm="3"><FormInput handleChange={handleChange} control={controls.actual_latitude} /></Col>
          <Col sm="3"><FormInput handleChange={handleChange} control={controls.actual_longitude} /></Col>
        </Row>
        <Row className="bg-light m-0 pb-2">
          <Col sm="12"><FormInput handleChange={handleChange} control={controls.notes} /></Col>
        </Row>
        <Row className="bg-light m-0 pb-2">
          <Col sm="2"><FormInput handleChange={handleChange} control={controls.collected_at} /></Col>
          <Col sm="2"><FormInput handleChange={handleChange} control={controls.target_depth} /></Col>
          <Col sm="2"><FormInput handleChange={handleChange} control={controls.actual_depth} /></Col>
          <Col sm="2"><FormInput handleChange={handleChange} control={controls.stickup_height} /></Col>
          <Col sm="4"><FormInput handleChange={handleChange} control={controls.area_core_cutting_radius} />
          </Col>
        </Row>

        <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Land Description</h5>
        <Row className="bg-light m-0 pb-2">
          <Col sm="4"><FormInput handleChange={handleChange} control={controls.vegetation} /></Col>
          <Col sm="4"><FormInput handleChange={handleChange} control={controls.landform} /></Col>
          <Col sm="4"><FormInput handleChange={handleChange} control={controls.condition} /></Col>
        </Row>
        <Row className="bg-light m-0 pb-2">
          <Col sm="4"><FormInput handleChange={handleChange} control={controls.soil_type_asc} /></Col>
          <Col sm="4"><FormInput handleChange={handleChange} control={controls.soil_type_gsg} /></Col>
          <Col sm="4"><FormInput handleChange={handleChange} control={controls.soil_series} /></Col>
        </Row>

        <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Core Scanning</h5>
        <Row className="bg-light m-0 pb-2">
          <Col sm="3"><FormInput handleChange={handleChange} control={controls.scanned_at} /></Col>
          <Col sm="3"><FormInput handleChange={handleChange} control={controls.scannedby_id} /></Col>
          <Col sm="3"><FormInput handleChange={handleChange} control={controls.scan_valid} /></Col>
          <Col sm="3"><FormInput handleChange={handleChange} control={controls.scan_valid_tag} /></Col>
        </Row>
        <Row className="bg-light m-0 pb-2">
          <Col sm="3"><FormInputFile handleChange={handleChange} control={controls.upload_scan_gamma_cal} /></Col>
          <Col sm="3"><FormInputFile handleChange={handleChange} control={controls.upload_scan_spec_cal} /></Col>
          <Col sm="3"><FormInputFile handleChange={handleChange} control={controls.upload_scan_params} /></Col>
          <Col sm="3"><FormInputFile handleChange={handleChange} control={controls.upload_scan_samples} /></Col>
        </Row>

        <div className="p-2 d-flex justify-content-center mt-2">
          <Button disabled={!checkAccess('siteUpdate')} color="success" className="m-1" onClick={onSave}>Save</Button>
          <Button color="light" className="m-1" onClick={onCancel}>Cancel</Button>
          <Button color="danger" className="m-1" onClick={onRemove} disabled={true}>Delete</Button>
          <Button disabled={!checkAccess('siteMove')} color="warning" className="m-1" onClick={onMoveScan}>Move Scan</Button>
          <Button disabled={!checkAccess('siteUpdate')} color="warning" className="m-1" onClick={onClearScan}>Clear Scan</Button>
          <Button disabled={!checkAccess('siteUpdate')} color="secondary" className="m-1" onClick={setModal}>Add Note</Button>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default SiteProfile;
