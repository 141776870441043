import Listview from 'jsx/components/core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';
import { Link } from 'react-router-dom';

export type Header<R> = {
  field: keyof R;
  classes: string;
  style?: Record<string, unknown>;
  caption: string;
};
export type DaysheetSummaryDetailRecord = {
  asset_id: string;
  job_id: string;
  project_phasecode: string;
  description: string;
  asset_hours: number;
  labour_hours: number;
  smu_start: number;
  smu_end: number;
  is_approved_level1: boolean;
  approvers_level1: string;
  is_approved_level2: boolean;
  approvers_level2: string;
  project_id: string;
};

type DaysheetSummaryDetailRecordHeader = Header<DaysheetSummaryDetailRecord>;

const renderTds = (
  headers: Array<DaysheetSummaryDetailRecordHeader>,
  record: DaysheetSummaryDetailRecord,
) => {
  const tableTds = headers.map((header: DaysheetSummaryDetailRecordHeader, index: number) => {
    if (['is_approved_level1', 'is_approved_level2'].includes(header.field)) {
      const approved = record[header.field];

      return (
        <td
          key={index}
          className={header.classes + (approved ? '' : ' error-cell')}
          style={header.style}
        >
          {approved ? (
            <Icon name="check" title="Approved" />
          ) : (
            <Icon name="xmark" title="Needs approvals" />
          )}
        </td>
      );
    } else if (['approvers_level1', 'approvers_level2'].includes(header.field)) {
      const approvers = record[header.field];
      const hasApprovers = approvers !== '';
      const approverText = hasApprovers ? approvers : 'None';
      const selectedProject = record.project_id;
      return (
        <td
          key={index}
          className={header.classes + (hasApprovers ? '' : ' error-cell')}
          style={header.style}
        >
          <Link
            className="light-link"
            to={{ pathname: '/home/plant-projects', search: `?selected=${selectedProject}` }}
          >
            {approverText}
          </Link>
        </td>
      );
    }
    const value = record[header.field];

    return (
      <td key={index} className={header.classes} style={header.style}>
        {value}
      </td>
    );
  });
  return tableTds;
};
const headers: DaysheetSummaryDetailRecordHeader[] = [
  { caption: 'Asset #', field: 'asset_id', classes: 'text-left' },
  { caption: 'Work Activity', field: 'job_id', classes: 'text-left' },
  { caption: 'Project/Phasecode', field: 'project_phasecode', classes: 'text-left' },
  { caption: 'Work Description', field: 'description', classes: 'text-left' },
  { caption: 'Asset Hours', field: 'asset_hours', classes: 'text-right' },
  { caption: 'Labour Hours', field: 'labour_hours', classes: 'text-right' },
  { caption: 'SMU Start', field: 'smu_start', classes: 'text-right' },
  { caption: 'SMU End', field: 'smu_end', classes: 'text-right' },
  { caption: 'Level 1 Approved', field: 'is_approved_level1', classes: 'text-left' },
  { caption: 'Approvers', field: 'approvers_level1', classes: 'text-left' },
  { caption: 'Level 2 Approved', field: 'is_approved_level2', classes: 'text-left' },
  { caption: 'Approvers', field: 'approvers_level2', classes: 'text-left' },
];

const tableHeadTh = headers.map(({ classes, caption }, index) => (
  <th key={index} className={classes}>
    {caption}
  </th>
));

type DaysheetSummariesDetailLsvProps = {
  rows: Array<DaysheetSummaryDetailRecord>;
};

const DaysheetSummariesDetailLsv = ({ rows }: DaysheetSummariesDetailLsvProps) => {
  const haveRows = rows && rows.length > 0;
  const tableBodyTr = haveRows
    ? rows.map((row, index: number) => <tr key={index}>{renderTds(headers, row)}</tr>)
    : [];
  return (
    <Listview
      darkTable
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      rowsCount={rows.length}
      iconName="comment"
      emptyCaption="No activities found"
    />
  );
};

export default DaysheetSummariesDetailLsv;
