export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    zoomCoords: [],
    styles: {
      // streets: 'mapbox://styles/mapbox/streets-v11',
      outdoors: 'mapbox://styles/mapbox/outdoors-v11',
      // light: 'mapbox://styles/mapbox/light-v10',
      // dark: 'mapbox://styles/mapbox/dark-v10',
      satellite: 'mapbox://styles/mapbox/satellite-v9',
      // satelliteStreets: 'mapbox://styles/mapbox/satellite-streets-v11',
      // previewDay: 'mapbox://styles/mapbox/navigation-preview-day-v4',
      // previewNight: 'mapbox://styles/mapbox/navigation-preview-night-v4',
      // guidanceDay: 'mapbox://styles/mapbox/navigation-guidance-day-v4',
      // guidanceNight: 'mapbox://styles/mapbox/navigation-guidance-night-v4'
    },
    spare: ['#BD978F'],
    colours: {
      project: {
        fill: '#999999',
        outline: 'black',
      },
      property: {
        fill: '#865C95',
        outline: '#000000',
      },
      properties: [
        '#3490dc',
        '#f6993f',
        '#9561e2',
        '#f66D9b',
        '#e3342f',
        '#ffed4a',
        '#6574cd',
        '#38c172',
        '#4dc0b5',
        '#6cb2eb',
      ],
      lot: {
        fill: '#D0B553',
        outline: '#000000',
      },
      insights: {
        inclusion: '#93ca00',
        exclusion: '#E1E1E1',
        inclusions: '#93ca00',
        exclusions: '#E1E1E1',
        'exclusions-forest': '#E1E1E1',
        'exclusions-slope': '#0000FF',
        'exclusions-forest-slope': '#FF0000',
        opacity: 0.7,
      },
      area: {
        outline: '#000000',
      },
      areas: [
        '#3490dc',
        '#f6993f',
        '#9561e2',
        '#f66D9b',
        '#e3342f',
        '#ffed4a',
        '#6574cd',
        '#38c172',
        '#4dc0b5',
        '#6cb2eb',
      ],
      round: {
        fill: '#999999',
        outline: 'white',
      },
      strata: {
        fill: '#D0B553',
        outline: '#000000',
      },
      stratas: [
        '#6cb2eb',
        '#4dc0b5',
        '#38c172',
        '#6574cd',
        '#ffed4a',
        '#e3342f',
        '#f66D9b',
        '#9561e2',
        '#f6993f',
        '#3490dc',
        '#000000',
      ],
      site_target: {
        fill: 'green',
        outline: 'lightgreen',
      },
      site_reserve: {
        fill: 'orange',
        outline: 'red',
      },
      site_actual: {
        fill: 'blue',
        outline: 'black',
        label: 'black',
      },
      site_actual_outside: {
        fill: 'red',
        outline: 'red',
        label: 'red',
      },
      site_scanned: {
        fill: 'blue',
        outline: 'black',
      },
      site_recon: {
        fill: 'green',
        outline: 'black',
      },
      site_selected: {
        fill: 'red',
        outline: 'black',
      },
    },
  },
  action
) {
  switch (action.type) {
    case 'SET_ZOOM_COORDS': {
      return {
        ...state,
        fetching: true,
        fetched: false,
        zoomCoords: action.payload,
      };
    }
    default:
      break;
  }
  return state;
}
