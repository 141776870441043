import React from 'react';
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

import {
  initControls,
  saveControls
} from "../../../../core/form/lib/validateForm";

import { controls } from "../../forms/projectDocumentGroup";
import FormBase from "../../../../core/form/components/FormBase";
import Icon from 'jsx/components/core/icons/Icon';

import {
  createDocumentGroup,
  fetchDocumentGroups
} from '../../actions/document_groups';
import ProjectGroupProfile from './ProjectGroupProfile';

class ProjectGroupModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isValid: true,
      isOpen: false,
      title: 'Project Document Group',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls,
      project_id: null
    })

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async componentDidUpdate() {
    let {
      isOpen,
      title
    } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      title = `New Document Group`;
      this.setState({
        controls: initControls(this.state.controls),
        isOpen: this.props.isOpen,
        title,
        project_id: this.props.project_id,
        setModal: this.props.setModal,
      })
    }
  }

  async onSave() {
    let {
      data,
      controls,
      project_id
    } = this.state;

    data = saveControls(controls, data)
    data.project_id = project_id;
    delete data.id;

    let success = await this.props.dispatch(createDocumentGroup(data));
    if (success) {
      this.props.dispatch(fetchDocumentGroups({ project_id }));

      controls['from_group_id'].options = [];
      controls['from_group_id'].disabled = true;
      controls['from_group_id'].value = null;

      this.state.setModal(false);
      this.setState({ isOpen: false, controls: initControls(controls) });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  render() {
    const {
      controls,
      isOpen,
      title,
      project_id
    } = this.state;

    const iconName = 'object-group';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          <ProjectGroupProfile isOpen={isOpen} controls={controls} project_id={project_id}/>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Cancel</Button>
            <Button size="sm" color="success" onClick={this.onSave}>Save</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    properties: store.properties,
    document_groups: store.document_groups,
  }
}

export default connect(mapStoreToProps)(ProjectGroupModal);
