import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  // eslint-disable-next-line default-param-last
  state = {
    fetching: false,
    fetched: false,
    error: null,
    responseMessage: null,
    projectRegisters: [],
    currentProjectRegister: null,
    statuses: [],
    officers: [],
    filters: {
      projectStatus: [],
      projectOfficer: [],
    },
    attributes: {
      periodType: [],
      auditTypes: [],
    },
    formStates: {
      projectRegisterProject: [],
      projectRegisterProperty: [],
    },
  },
  action,
) {
  const types = [
    'PROJECT_PROPERTY_REGISTER',
    'PROJECT_REGISTER_PROJECT',
    'PROJECT_REGISTER_STATUSES',
    'PROJECT_REGISTER_OFFICERS',
    'PROJECT_REGISTER_FILTER',
    'PROJECT_REGISTER_VARIATION',
    'PROJECT_REGISTER_NOTE',
    'PROJECT_REGISTER_PERIOD',
    'PROJECT_REGISTER_AUDIT',
    'PROJECT_REGISTER_ATTRIBUTES',
    'PROJECT_REGISTER_PROPERTY',
  ];

  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'GET_PROJECT_REGISTER_PROJECTS_FULFILLED': {
      return {
        ...updatedState,
        projectRegisters: action.payload,
      };
    }
    case 'GET_PROJECT_REGISTER_PROJECT_PENDING':
    case 'UPDATE_PROJECT_REGISTER_PROJECT_PENDING': {
      return {
        ...updatedState,
        formStates: {
          ...updatedState.formStates,
          projectRegisterProject: null,
        },
      };
    }
    case 'GET_PROJECT_REGISTER_PROJECT_FULFILLED': {
      return {
        ...updatedState,
        currentProjectRegister: action.payload,
      };
    }
    case 'UPDATE_PROJECT_REGISTER_PROJECT_REJECTED': {
      return {
        ...updatedState,
        formStates: {
          ...updatedState.formStates,
          projectRegisterProject: action.payload,
        },
      };
    }
    case 'GET_PROJECT_REGISTER_PROJECT_REJECTED': {
      return {
        ...updatedState,
        currentProjectRegister: null,
      };
    }
    case 'GET_PROJECT_PROPERTY_REGISTER_PENDING':
    case 'UPDATE_PROJECT_PROPERTY_REGISTER_PENDING': {
      return {
        ...updatedState,
        formStates: {
          ...updatedState.formStates,
          projectRegisterProperty: null,
        },
      };
    }
    case 'GET_PROJECT_PROPERTY_REGISTER_REJECTED':
    case 'UPDATE_PROJECT_PROPERTY_REGISTER_REJECTED': {
      return {
        ...updatedState,
        formStates: {
          ...updatedState.formStates,
          projectRegisterProperty: action.payload,
        },
      };
    }
    case 'GET_PROJECT_REGISTER_STATUSES_FULFILLED': {
      const statuses = action.payload || [];
      const mappedStatuses = statuses.map(({ name, id }) => ({ name, id }));

      return {
        ...updatedState,
        statuses: mappedStatuses,
      };
    }

    case 'GET_PROJECT_REGISTER_OFFICERS_FULFILLED': {
      const officers = action.payload || [];
      const mappedOfficers = officers.map(({ name, id }) => ({ name, id }));

      return {
        ...updatedState,
        officers: mappedOfficers,
      };
    }

    case 'SET_PROJECT_REGISTER_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      };
    }

    case 'SET_PROJECT_REGISTER_FILTER': {
      const updatedFilters = { ...state.filters };
      const { filterToUpdate, value } = action.payload;

      // Check if the filter already exists
      const exists = updatedFilters[filterToUpdate].find((filter) => filter === value);
      if (exists) return updatedState;

      updatedFilters[filterToUpdate].push(value);

      return {
        ...updatedState,
        filters: updatedFilters,
      };
    }

    case 'UNSET_PROJECT_REGISTER_FILTER': {
      const updatedFilters = { ...state.filters };
      const { filterToUpdate, value } = action.payload;

      updatedFilters[filterToUpdate] = updatedFilters[filterToUpdate].filter(
        (filter) => filter !== value,
      );

      return {
        ...updatedState,
        filters: updatedFilters,
      };
    }
    case 'FETCH_PROJECT_REGISTER_ATTRIBUTES_PERIOD_TYPES_FULFILLED': {
      return {
        ...updatedState,
        attributes: {
          ...updatedState.attributes,
          periodType: action.payload?.rows || [],
        },
      };
    }
    case 'FETCH_PROJECT_REGISTER_ATTRIBUTES_AUDIT_TYPES_FULFILLED': {
      return {
        ...updatedState,
        attributes: {
          ...updatedState.attributes,
          auditTypes: action.payload?.rows || [],
        },
      };
    }
    default:
      break;
  }
  return updatedState;
}
