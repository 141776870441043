import React from 'react';
import Listview from "../../../core/form/components/Listview";
import moment from 'moment';
import TimeFormat from '../lib/timeFormat';

const TimekeeperLeaveLsv = (props) => {
  const {
    rows,
    selectedDate,
    onEdit
  } = props;

  const format = new TimeFormat();

  const renderRows = (headers, record) => {
    const leave_date = moment(record.leave_date).format('YYYY-MM-DD');
    const leave_type = record.leavecode.name;
    const leave_code = record.leavecode.code;

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'leave_code': return (
          <td key={index} className={header.classes} style={header.style}>{leave_code}</td>
        )
        case 'leave_type': return (
          <td key={index} className={header.classes} style={header.style}>{leave_type}</td>
        )
        case 'leave_date': return (
          <td key={index} className={header.classes} style={header.style}>{leave_date}</td>
        )
        default: return (
          <td key={index} className={header.classes} style={header.style}>{record[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const iconName = 'house-person-leave';
  const emptyCaption = `No Leave for this week period`;

  const headers = [
    { caption: 'Leave Date', field: 'leave_date', classes: 'text-left', style: {width: 100} },
    { caption: 'Hours', field: 'leave_hours', classes: 'text-right', style: {width: 50} },
    { caption: 'Leave Type', field: 'leave_type', classes: 'text-left' },
    { caption: 'Code', field: 'leave_code', classes: 'text-left', style: {width: 80}},
    { caption: 'Approved By', field: 'approved_by', classes: 'text-left' },
    { caption: 'Approved At', field: 'approved_at', classes: 'text-left', style: {width: 120}},
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  )

  let tableBodyTr = (<tr></tr>);
  let rowsCount;

  const haveRows = (rows && rows.length > 0);
  let daySheets = rows;
  let weekHours = 0;

  if (haveRows) {
    daySheets = rows.filter(row => moment(row.from_date).format('yyyy-MM-DD') === moment(selectedDate).format('yyyy-MM-DD'));
    tableBodyTr = daySheets.map((row, index) => {
      weekHours += row.leave_hours;
      return (
        <tr key={index} onClick={() => onEdit(row.id)}>
          {renderRows(headers, row)}
        </tr>
      )
    }
    );
  }

  return (
    <div className="b">
      <Listview rows={daySheets} rowsCount={rowsCount} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />

      <div className="d-flex justify-content-end p-2 mt-2">
        <h3 className="text-success">Leave Week Total: {format.asString(weekHours)} hrs</h3>
      </div>

    </div>
  );
}

export default TimekeeperLeaveLsv;
