import React from 'react';
import { noop } from 'lodash';
import BreakPill from './BreakPill';

const BreakPills = ({ rows = [], daysheet = {}, loadActivities = noop }) => {
  const pills = rows.map((row, index) => (
    <BreakPill key={index} row={row} daysheet={daysheet} loadActivities={loadActivities} />
  ));

  return <div className="d-flex justify-content-start bg-light">{pills}</div>;
};

export default BreakPills;
