import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UncontrolledAlert, Input } from 'reactstrap';
import moment from 'moment';
import { noop } from 'lodash';

import Icon from 'jsx/components/core/icons/Icon';
import Listview from '../../../core/form/components/Listview';
import UploadDots from '../../../core/form/components/UploadDots';
import SiteSampleChartModal from './SiteSampleChartModal';
import DirectDownload from '../../../core/form/components/DirectDownload';
import { scanValidity } from '../lib/scan_status';
import { fetchSiteImages, fetchFieldTripSites } from '../actions/index';
import ImagePreviewModal from '../../../core/modals/ImagePreviewModal';
import { convertBufferToImageSource } from '../../../../lib/image';
import FieldTripsButton from './FieldTripsButton';

const SitesLsv = ({
  rows = [],
  history,
  match,
  statusMessage,
  coreSelection = [],
  handleSelect,
  handleSelectAll,
  handleDownload,
  disableCheckboxes,
  samples,
  fetchSamples,
  currentSite = null,
  filterColumns,
  onLoadMore = noop,
  rowsCount,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [siteImages, setSiteImages] = useState([]);
  const [selectedSiteImageIndex, setSelectedSiteImageIndex] = useState(0);
  const [selectedSite, setSelectedSite] = useState(null);
  const [fieldTripPopoverSelectedSite, setFieldTripPopoverSelectedSite] = useState(null);
  const [fieldTrips, setFieldTrips] = useState([]);
  const [isLoadingFieldTrips, setIsLoadingFieldTrips] = useState(null);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const openChart = (site) => {
    fetchSamples(site);
    toggle();
  };

  useEffect(() => {
    const fetchFieldTrips = async () => {
      if (fieldTripPopoverSelectedSite === null) return;
      setIsLoadingFieldTrips(true);
      setFieldTrips([]);
      const fieldtrips = await dispatch(
        fetchFieldTripSites({ site_id: fieldTripPopoverSelectedSite }, true),
      );
      const fieldTripNames = fieldtrips.map(({ fieldtrip }) => ({
        name: fieldtrip.name,
        key: fieldtrip.id,
      }));
      setFieldTrips(fieldTripNames);
      setIsLoadingFieldTrips(false);
    };
    fetchFieldTrips();
  }, [fieldTripPopoverSelectedSite, dispatch]);

  const edit = ({ strata_id, site_id, ...site }, tabId = '1') => {
    let { project_id, property_id, area_id, id: round_id } = match.params;
    if (!project_id) {
      project_id = site?.strata?.round?.area?.property?.project?.id;
    }
    if (!property_id) {
      property_id = site?.strata?.round?.area?.property?.id;
    }
    if (!area_id) {
      area_id = site?.strata?.round?.area?.id;
    }
    const siteId = site_id ?? site?.id;
    if (!round_id) {
      round_id = site?.strata?.round?.id;
    }
    const lnk = `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${round_id}/stratas/${strata_id}/sites/${siteId}/${tabId}`;

    history.push(lnk);
  };

  const onFieldTripSites = ({ id }) => {
    if (id === fieldTripPopoverSelectedSite) return;
    setFieldTripPopoverSelectedSite(id);
  };

  const decodeImageToComponent = (image) => {
    if (image.length === 0) return <span>Image not found</span>;

    if (image[selectedSiteImageIndex]) {
      const imgSrc = convertBufferToImageSource(image[selectedSiteImageIndex].image.data);
      return (
        <div className="d-flex justify-content-center">
          <img
            src={imgSrc}
            alt={`${selectedSite?.core_key || ''} taken from site`}
            style={{ width: 'auto', maxWidth: '100%', maxHeight: '1000px' }}
          />
        </div>
      );
    }
    return <span>Image not found</span>;
  };

  const viewImage = async (site) => {
    setIsImageModalOpen(true);
    setSelectedSite(site);
    const imageList = await dispatch(fetchSiteImages(site.id));
    setSiteImages(imageList);
  };

  const renderRows = (headers, site) => {
    let download = <div className="text-secondary">--</div>;
    if (site.scanned_at) {
      download = (
        <DirectDownload
          classNames="p-0"
          buttonType="primary"
          handleDownload={(onProgressChange) => handleDownload(site.id, onProgressChange)}
          showLabel
          showIcon
        />
      );
    }
    const spectra = site.scan_samples_exists ? (
      <button className="btn btn-light btn-sm ml-1 border-0 bg-transparent" aria-disabled disabled>
        <Icon name="chart-line" className="text-success" onClick={() => openChart(site)} />
      </button>
    ) : (
      <div />
    );

    const checked = !!coreSelection.find((id) => id === site.id);

    // Check file uploads
    const files = [
      { name: 'scan_samples', id: 'ss', exists: site.scan_samples_exists },
      { name: 'scan_params', id: 'pa', exists: site.scan_params_exists },
      { name: 'scan_spec_cal', id: 'sc', exists: site.scan_spec_cal_exists },
      { name: 'scan_gamma_cal', id: 'gc', exists: site.scan_gamma_cal_exists },
    ];

    const scan_validity = scanValidity(site, edit);

    // Set class styles for compression ratio
    let compressionClassName = 'text-success';
    const { compression_ratio, fieldtrip_sites_exists } = site;
    if (site.compression_ratio < 0.85) {
      compressionClassName = 'text-warning';
    }
    if (site.compression_ratio < 0.45) {
      compressionClassName = 'text-light bg-warning';
    }
    if (site.compression_ratio > 1.02) {
      compressionClassName = 'text-danger';
    }
    let { stickup_height } = site;
    let stickup_height_bg = '';
    if (stickup_height === null) {
      stickup_height = 'Missing';
      stickup_height_bg = 'bg-danger text-white';
    }

    const tableTd = headers.map(({ classes, field }, index) => {
      if (filterColumns?.length > 0) {
        const matchFilter = filterColumns.find((curr) => field === curr);
        if (!matchFilter && field !== 'checkbox') {
          return null;
        }
      }
      const typeClassName = `${classes} ${site.type_key === 'T' ? 'text-success' : 'text-warning'}`;
      const geomClassName = `${classes} ${site.geom ? 'text-success' : 'text-danger'}`;
      const scannedClassName = `${classes} ${site.scanned_at ? 'text-success' : 'text-danger'}`;

      switch (field) {
        case 'checkbox':
          return (
            <td key={index}>
              <Input
                type="checkbox"
                checked={checked}
                className="m-0 mt-1"
                onChange={handleSelect}
                value={site.id}
                disabled={disableCheckboxes}
              />
            </td>
          );
        case 'core_key':
          return (
            <td key={index} className={classes}>
              {site.core_key}
            </td>
          );
        case 'type':
          return (
            <td key={index} className={typeClassName}>
              {site.type_key === 'T' ? 'Target' : 'Reserve'}
            </td>
          );
        case 'haveGeom':
          return (
            <td key={index} className={geomClassName}>
              {site.geom ? 'Yes' : 'No'}
            </td>
          );
        case 'status_id':
          return (
            <td key={index} style={{ color: site.status.colour }}>
              {site.status.name}
            </td>
          );
        case 'upload_status':
          return (
            <td key={index}>
              <UploadDots files={files} />
            </td>
          );
        case 'scanned_at':
          return (
            <td key={index} className={scannedClassName}>
              {site.scanned_at ? 'Yes' : 'No'}
            </td>
          );
        case 'corescanner_name':
          return <td key={index}>{site.corescanner?.name ?? '-'}</td>;
        case 'scan_valid':
          return <td key={index}>{scan_validity.message}</td>;
        case 'actual_depth':
        case 'core_length':
          return (
            <td key={index} className={`${classes} ${scan_validity.classes}`}>
              {site[field]}
            </td>
          );
        case 'stickup_height':
          return (
            <td key={index} className={`${classes} ${stickup_height_bg}`}>
              {stickup_height}
            </td>
          );
        case 'strata':
          return (
            <td key={index} className={classes}>
              {site.strata.name}
            </td>
          );
        case 'scan_result':
          return (
            <td key={index} className={classes} style={{ cursor: 'pointer' }}>
              {download}
            </td>
          );
        case 'compression_ratio':
          return (
            <td key={index} className={`${classes} ${compressionClassName}`}>
              {compression_ratio}
            </td>
          );
        case 'collected_at':
          return (
            <td key={index} className={`${classes}`}>
              {site[field] ? moment(site[field]).format('YYYY-MM-DD') : '--'}
            </td>
          );
        case 'fieldtrip_sites_exists':
          return (
            <td key={index} className={`${classes}`}>
              {fieldtrip_sites_exists && (
                <FieldTripsButton
                  site={site}
                  fieldTrips={fieldTrips}
                  onClick={() => onFieldTripSites(site)}
                  onToggle={() => setFieldTripPopoverSelectedSite(null)}
                  isOpen={fieldTripPopoverSelectedSite === site.id}
                  isLoading={fieldTripPopoverSelectedSite === site.id && isLoadingFieldTrips}
                />
              )}
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {site[field]}
            </td>
          );
      }
    });

    tableTd.push(
      <td key={headers.length + 1} className="d-flex justify-content-end">
        {spectra}
        {site.images_exists && (
          <button
            className="btn btn-light btn-sm ml-1 border-0 bg-transparent text-primary"
            onClick={() => viewImage(site)}
          >
            <Icon name="magnifying-glass" />
          </button>
        )}
        <a
          href="/"
          onClick={(event) => {
            event.preventDefault();
            edit(site);
          }}
          className="btn btn-light btn-sm ml-1 border-0 bg-transparent text-primary"
        >
          <Icon name="pen-to-square" />
        </a>
      </td>,
    );

    return tableTd;
  };

  let headers = [
    { caption: '-', field: 'checkbox', classes: 'text-left' },
    { caption: 'Core Key', field: 'core_key', classes: 'text-left' },
    { caption: 'Name', field: 'name', classes: 'text-right' },
    { caption: 'Strata', field: 'strata', classes: 'text-right' },
    { caption: 'Type', field: 'type', classes: 'text-left' },
    { caption: 'Status', field: 'status_id', classes: 'text-left' },
    { caption: 'Geometry', field: 'haveGeom', classes: 'text-center' },
    { caption: 'Scanned', field: 'scanned_at', classes: 'text-center' },
    { caption: 'Scanner', field: 'corescanner_name', classes: 'text-left' },
    { caption: 'Upload Status', field: 'upload_status', classes: 'text-center' },
    { caption: 'Validation', field: 'scan_valid', classes: 'text-left' },
    { caption: 'T.Depth', field: 'target_depth', classes: 'text-right' },
    { caption: 'Stickup', field: 'stickup_height', classes: 'text-right' },
    { caption: 'A.Depth', field: 'actual_depth', classes: 'text-right' },
    { caption: 'C.Length', field: 'core_length', classes: 'text-right' },
    { caption: 'C.Ratio', field: 'compression_ratio', classes: 'text-right' },
    { caption: 'Scan Result', field: 'scan_result', classes: 'text-center' },
    { caption: 'Collected At', field: 'collected_at', classes: 'text-center' },
    { caption: 'Field Trips', field: 'fieldtrip_sites_exists', classes: 'text-center' },
  ];

  if (filterColumns?.length > 0) {
    headers = headers.filter(
      (curr) => curr.field === 'checkbox' || filterColumns.some((filter) => filter === curr.field),
    );
  }

  const tableHeadTh = headers.map(({ classes, caption, field }, index) => {
    switch (field) {
      case 'checkbox':
        return (
          <th key={index} className={classes}>
            <input
              type="checkbox"
              disabled={disableCheckboxes}
              className="m-0 mt-1"
              onChange={(event) => handleSelectAll(event, rows)}
            />
          </th>
        );
      default:
        break;
    }
    return (
      <th key={index} className={classes}>
        {caption}
      </th>
    );
  });
  tableHeadTh.push(<th key={headers.length + 1} />);

  let tableBodyTr = <tr />;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  const iconName = 'vial';
  const emptyCaption = 'No Sites found';

  return (
    <>
      {statusMessage && <UncontrolledAlert color="warning">{statusMessage}</UncontrolledAlert>}
      {currentSite !== null && (
        <SiteSampleChartModal
          toggle={toggle}
          isOpen={isOpen}
          samples={samples}
          fetchSamples={fetchSamples}
          rows={rows}
          currentSite={currentSite}
        />
      )}
      <ImagePreviewModal
        imgSrc={decodeImageToComponent(siteImages)}
        title={selectedSite?.core_key || 'Selected core'}
        toggle={() => setIsImageModalOpen(!isImageModalOpen)}
        hasNext={selectedSiteImageIndex < siteImages.length - 1}
        viewNext={() => setSelectedSiteImageIndex(selectedSiteImageIndex + 1)}
        hasPrev={selectedSiteImageIndex !== 0}
        viewPrev={() => setSelectedSiteImageIndex(selectedSiteImageIndex - 1)}
        isOpen={isImageModalOpen}
        onClickOutside={() => setIsImageModalOpen(false)}
      />
      <Listview
        rows={rows}
        rowsCount={rowsCount}
        onLoadMore={onLoadMore}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </>
  );
};

export default SitesLsv;
