import React from 'react';
import { connect } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import MapboxGeocoder from 'mapbox-gl-geocoder';
import { accessToken } from '../../../../constants/map.js';
import logo from '../../../../../images/carbonizer/logo_horizontal_negative.png';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import PageMessage from '../../../core/form/components/PageMessage';
import { controls } from '../../projects/forms/portalPropertySearch.js';
import { HalfCircleSpinner } from 'react-epic-spinners';

import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { saveControls } from '../../../core/form/lib/validateForm';

import {
  fetchLibrarySources,
  fetchWMSObject,
  convertGeometry,
} from '../../projects/actions/properties';

import { createPropertyLot, removePropertyLot } from '../../projects/actions/property_lots';

import {
  loadSource,
  buildGetFeatureInfoParams,
  splitUrl,
  setSelectedLots,
  getSelectedLots,
  formatSelectedLot,
  toggleSelectedLot,
} from '../../projects/lib/propertySearchUtils';

import {
  zoomToBounds,
  buildFeatureCollection,
  buildFeature,
  getDefaultLayer,
  getCollectionBoundary,
} from '../../projects/lib/mapster.js';

import { fetchHashLink, updateProponentProperty } from '../actions';

mapboxgl.accessToken = accessToken;

class MapBoard extends FormBase {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
    this.geocoderRef = React.createRef();

    this.state = {
      lng: 150.7333,
      lat: -23.1333,
      zoom: 8,
      map: null,
      geocoder: null,
      draw: null,
      selected: [],
      controls: controls,
      data: {},
      property_address: null,
      property_name: null,
      propertySource: 'property_selection',
      searchCenter: [],
      mapStylePath: 'mapbox://styles/mapbox',
      mapStyles: [
        { id: 'outdoors-v11', name: 'Outdoors' },
        { id: 'satellite-v9', name: 'Satellite' },
        { id: 'dark-v10', name: 'Dark' },
        { id: 'light-v10', name: 'Light' },
      ],
      currentStyle: 'satellite-v9',
      currentSource: null,
      isModalOpen: false,
      hashlink: null,
      searchCompleted: false,
      mapDataLoading: true,
    };

    this.loadDefaultSource = this.loadDefaultSource.bind(this);
    this.selectFeature = this.selectFeature.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.loadProperties = this.loadProperties.bind(this);
    this.loadPropertySource = this.loadPropertySource.bind(this);
    this.getTotalHa = this.getTotalHa.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  async componentDidMount() {
    const { mapStylePath, currentStyle, lat, lng, zoom } = this.state;

    const hash_key = this.props.match.params.hash_key;
    const hashlink = await this.props.dispatch(fetchHashLink(hash_key));

    if (hashlink) {
      const property_name = hashlink.attributes.property_name;
      const property_address = hashlink.attributes.property_address;

      axios.defaults.headers.common['hash-key'] = hashlink.hash_key;

      // Load Map
      const map = new mapboxgl.Map({
        container: this.mapRef.current,
        style: `${mapStylePath}/${currentStyle}`,
        center: [lng, lat],
        zoom: zoom,
      });

      // Set Navigation
      const nav = new mapboxgl.NavigationControl({
        showZoom: true,
      });
      map.addControl(nav);

      // Set Geocoder
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      });
      geocoder.onAdd(map);

      // Geocode onResult
      geocoder.on('result', async (e) => {
        if (
          e.result.center &&
          JSON.stringify(e.result.center) !== JSON.stringify(this.state.searchCenter)
        ) {
          this.setState(
            {
              searchCenter: e.result.center,
            },
            () => {
              // Set address marker
              new mapboxgl.Marker().setLngLat(e.result.center).addTo(map);
            }
          );
        }
      });

      // Hardcoded geocoder query
      if (geocoder) {
        geocoder.query(property_address);
      }

      const selected = getSelectedLots();
      this.setState({ map, geocoder, property_name, property_address, selected, hashlink });

      // Map onLoad
      map.on('load', async () => {
        await this.loadDefaultSource(map);
        await this.loadPropertySource();
        this.loadProperties();

        this.setState({ mapDataLoading: false });
      });

      // Map Click
      map.on('click', async (e) => this.selectFeature(e));
    }
  }

  async loadPropertySource() {
    const { map, propertySource } = this.state;

    // // Remove property source if already set
    if (map.getSource(propertySource)) {
      map.removeSource(propertySource);
    }

    // Load Blank Property Source
    map.addSource(propertySource, {
      type: 'geojson',
      data: { type: 'Feature' },
    });

    // Add lot polygons
    const boundary_layer = getDefaultLayer(propertySource, 'MultiPolygon');
    map.addLayer(boundary_layer);

    const label_layer = getDefaultLayer(propertySource, 'PolyLabel');
    map.addLayer(label_layer);
  }

  async loadProperties(zoomExtent = false) {
    const { selected, opacity, propertySource, map } = this.state;

    if (!map) return;

    map.getSource(propertySource).setData(buildFeatureCollection());
    if (selected.length > 0) {
      const featureCollection = buildFeatureCollection();

      selected.forEach((property) => {
        const colour = 'yellow';
        const outline = '#000000';
        const fillOpacity = opacity / 100;

        const feature = buildFeature(property.geometry, {
          id: property.id,
          lotplan: property.properties.lotplan,
          ha: property.ha,
          geom: property.geometry,
          colour: colour,
          outline: outline,
          fillOpacity: fillOpacity,
        });

        featureCollection.features.push(feature);
      });

      // Set properties
      map.getSource(propertySource).setData(featureCollection);

      // Optionally zoom to extent of radius
      if (zoomExtent) {
        zoomToBounds(map, getCollectionBoundary(featureCollection));
      }
    }
  }

  async loadDefaultSource(map) {
    const { hashlink } = this.state;

    axios.defaults.headers.common['hash-key'] = hashlink.hash_key;
    const librarySources = await this.props.dispatch(fetchLibrarySources());

    const source = librarySources.find(
      (librarySource) => librarySource.layer === 'carbonlink:australia_dcdb_lotplan'
    );
    source.styles = ['boundaries-only'];

    loadSource(map, source);

    this.setState({ currentSource: source });
  }

  setSelected(property) {
    let { selected } = this.state;
    const { hashlink } = this.state;

    // Immediately update lot selection in db
    if (selected.find((s) => s.properties.lotplan === property.properties.lotplan)) {
      this.props.dispatch(
        removePropertyLot(hashlink.attributes.property_id, property.properties.lotplan)
      );
    } else {
      this.props.dispatch(
        createPropertyLot({
          property_id: hashlink.attributes.property_id,
          lotplan: property.properties.lotplan,
          geom: property.geometry,
          ha: property.properties.ha,
        })
      );
    }

    // Format and toggle selected lots
    property = formatSelectedLot(property);
    selected = toggleSelectedLot(property);

    // Set state
    this.setState({ selected });
    this.loadProperties();
  }

  async clearSelected() {
    // Clear Selected
    await this.setState({
      selected: setSelectedLots(null),
    });
    this.loadProperties();
  }

  async selectFeature(event) {
    const { currentSource, map, hashlink } = this.state;

    axios.defaults.headers.common['hash-key'] = hashlink.hash_key;

    const mapSource = map.getSource(currentSource.id);
    if (!mapSource) return false;

    const urlSource = splitUrl(mapSource.tiles[0]);
    const url = currentSource.url;
    const params = {
      ...urlSource.params,
      layers: currentSource.layer,
    };

    // Get Feature
    const featureInfoParams = buildGetFeatureInfoParams(params, event.lngLat);
    this.props.dispatch(fetchWMSObject(url, {}, featureInfoParams)).then((response) => {
      const clickedFeature = response.features[0];
      if (clickedFeature && featureInfoParams.crs !== `EPSG:4326` && clickedFeature.geometry) {
        const epsg = featureInfoParams.crs.split(':')[1];
        this.props
          .dispatch(convertGeometry({ geom: clickedFeature.geometry, epsg }))
          .then((converted) => {
            clickedFeature.geometry = converted.geom;
            clickedFeature.properties = {
              ...clickedFeature.properties,
              ha: converted.ha,
            };
            this.setSelected(clickedFeature);
          });
      } else {
        window.alert(
          'Unable to select geometry. Contact your administrator. There may not be cadastre information for this area.'
        );
      }
    });
  }

  getTotalHa() {
    let ha = 0;
    this.state.selected.map((property) => {
      ha = ha + parseFloat(property.properties?.ha);
      return true;
    });
    return ha.toFixed(2);
  }

  async onSave() {
    const {controls, hashlink } = this.state;
    let { data, searchCompleted } = this.state;

    data = saveControls(controls, data);
    const success = await this.props.dispatch(updateProponentProperty(hashlink.id, data));
    if (success) {
      searchCompleted = true;
      this.clearSelected();
      this.setState({ isModalOpen: false, searchCompleted });
    }
  }

  onCancel() {
    this.setState({ isModalOpen: false });
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  render() {
    const {
      property_address,
      property_name,
      selected,
      isModalOpen,
      controls,
      searchCompleted,
      mapDataLoading,
    } = this.state;
    const { responseMessage } = this.props.hashlinks;

    const title = 'Welcome to the Property Map';

    const ha = this.getTotalHa();
    const totalHa = `${selected.length} Lots/${ha} ha`;

    const completeMessage = `Thank You. The property identification process have been completed, land parcels (lots) has been associated with the property and project officer have been notified. We will get back to you shortly!`;
    return (
      <div className="m-0 p-0 h-100">
        {responseMessage && <PageMessage message={responseMessage} textClass="text-danger" />}

        {searchCompleted && <PageMessage message={completeMessage} textClass="text-black" />}

        {!responseMessage && !searchCompleted && (
          <Row className="m-0 p-0 h-100">
            <Col className="listview">
              <div className="header text-center border-bottom py-2">
                <img src={logo} alt="Carbonizer" width={200} className="p-1" />
              </div>

              <div className="text-center">
                <h5 className="text-corporate mt-4">{title}</h5>
                <div className="text-whitee p-2">
                  This space enables you to identify the lots making up your property boundaries by
                  just clicking on lots or areas on the map.
                </div>
              </div>

              <div className="mt-3">
                <div className="ml-4 text-corporate">Property Name:</div>
                <small className="ml-4 text-white">{property_name}</small>
              </div>

              <div className="mt-3">
                <div className="ml-4 text-corporate">Property Address:</div>
                <small className="ml-4 text-white">{property_address}</small>
              </div>

              <div className="border-top m-1 mt-3 p-2 border-corporate">
                Once you have selected all the lots for your property, make sure you click the
                button to 'Save Selected'
              </div>
              <div className="d-flex justify-content-center m-2 p-2">
                <Button
                  disabled={selected.length === 0}
                  size="sm"
                  color="danger"
                  className="ml-2"
                  onClick={() => this.clearSelected()}
                >
                  Clear Lots
                </Button>
                <Button
                  disabled={selected.length === 0}
                  size="sm"
                  color="success"
                  className="ml-2"
                  onClick={() => this.openModal()}
                >
                  Save Selected
                </Button>
              </div>

              <h3 className="text-center bg-corporate text-white p-2 mt-3">{totalHa}</h3>
            </Col>
            <Col style={{ position: 'relative' }}>
              <div ref={this.mapRef} className="mapster" />
              {/* <div style={{position: 'absolute', right: 60, top: 10}} className="bg-corporate text-white p-2 rounded">
                Click on the map to select your lots. Use these tools to Zoom and Pan around the map 
                <Icon size="1x" icon={'arrow-right'} className="mr-1 ml-1"/>
              </div> */}
              {mapDataLoading && (
                <div
                  style={{ position: 'absolute', right: 60, top: 10 }}
                  className="d-flex justify-content-end pt-3"
                >
                  <HalfCircleSpinner size={20} color="#ffffff" />
                </div>
              )}
            </Col>
          </Row>
        )}

        <Modal isOpen={isModalOpen}>
          <ModalHeader className="bg-corporate text-white">
            <Icon size="1x" name="draw-polygon" className="mr-2" />
            Save Property Lots
          </ModalHeader>
          <ModalBody>
            <FormInput handleChange={this.handleChange} control={controls.primary_practice} />
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button size="sm" color="success" onClick={() => this.onSave()}>
              Save Property
            </Button>
            <Button size="sm" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    mapster: store.mapster,
    profile: store.profile,
    hashlinks: store.hashlinks,
  };
};

export default connect(mapStoreToProps)(MapBoard);
