import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';
import { cloneDeep, omit, isEmpty } from 'lodash';
import InputMask from 'react-input-mask';

import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';

import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import FormSearch from '../../../core/form/components/FormSearch';
import TimeFormat from '../lib/timeFormat';
import { controls as timekeeperControls } from '../forms/timekeeper';

import {
  fetchTimesheets,
  fetchTimesheet,
  createTimesheet,
  removeTimesheet,
  updateTimesheet,
} from '../actions';

import { fetchJiraIssue } from '../../projects/actions/jira';
import { fetchCostcodes } from '../../../manage/actions';

const jiraKeyMinLength = 4;

class TimekeeperTimerModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Timesheet',
      data: {},
      id: null,
      isNew: false,
      controls: cloneDeep(timekeeperControls),
      codeSelected: false,
      jiraKey: null,
      showRecentOnly: false,
    };

    this.format = new TimeFormat();
    this.minCostCodeLength = 3;

    this.mask = '12:34';
    this.formatChars = {
      1: '[0-2]',
      2: '[0-9]',
      3: '[0-5]',
      4: '[0-9]',
    };

    this.onChecked = this.onChecked.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCodeSelect = this.onCodeSelect.bind(this);
    this.getJiraIssue = this.getJiraIssue.bind(this);
    this.handleTimeBlur = this.handleTimeBlur.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.outsideCurrentWeek = this.outsideCurrentWeek.bind(this);
    this.updatePrevious = this.updatePrevious.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.renderCodeSearchValue = this.renderCodeSearchValue.bind(this);
    this.renderAttributes = this.renderAttributes.bind(this);
    this.renderDisabledAttributes = this.renderDisabledAttributes.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { selectedDate, defaultTimesheetOrgId } = this.props.office;
      const { costcodes } = this.props.manage;

      // Initialize controls
      let updatedControls = cloneDeep(initControls(timekeeperControls));
      updatedControls.from_date.value = selectedDate;

      // Set default org id new record
      if (defaultTimesheetOrgId) updatedControls.client_org_id.value = defaultTimesheetOrgId;

      // Base state
      let updatedState = {
        controls: updatedControls,
        data: {},
        id: null,
        isNew: true,
        isOpen: this.props.isOpen,
        title: `New Timesheet`,
      };

      if (this.props.id) {
        const { id } = this.props;
        let title = 'Edit Timesheet';

        const timesheet = await this.props.dispatch(fetchTimesheet(id));

        // Set from/to time (hours/minutes)
        timesheet.from_time = moment(timesheet.from_date).format(this.format.time);
        if (timesheet.to_date)
          timesheet.to_time = moment(timesheet.to_date).format(this.format.time);

        // Update title if read only
        if (timesheet.is_approved === true) title = `View Timesheet`;

        // Update controls with existing timesheet data
        updatedControls = cloneDeep(updateControls(updatedControls, timesheet));

        // Populate code search value if applicable (not handled in updateControls function)
        const { costcode_id, phasecode_id } = timesheet;
        if (costcode_id && phasecode_id && !updatedControls.code_search.value) {
          const costcode = costcodes.find((costcode) => costcode.id === costcode_id);

          if (costcode) {
            const phasecode = costcode.phasecodes.find(
              (phasecode) => phasecode.id === phasecode_id
            );
            if (phasecode)
              updatedControls.code_search.value = this.renderCodeSearchValue(costcode, phasecode);
          }
        }

        // Update state for existing timesheet only
        this.setState({
          ...updatedState,
          codeSelected: true,
          controls: updatedControls,
          data: timesheet,
          id,
          isNew: false,
          title,
        });
      }

      // Update state for new timesheets only
      if (this.props.isNew) this.setState(updatedState);
    }
  }

  async startTimer() {
    const { data, controls } = this.state;

    let { currentWeek } = this.props.office;

    let now = moment();

    const newData = {
      ...data,
      ...saveControls(controls, data),
      from_date: now.format(this.format.output),
      to_date: null,
    };

    // If timesheet is started from a previous week, set it to current week
    if (moment(currentWeek.to_date) < now) this.props.setWeek(null);
    this.props.dispatch({ type: 'SET_SELECTED_DATE', payload: now });

    delete newData.id;
    delete newData.created;
    delete newData.updated;
    const success = await this.props.dispatch(createTimesheet(newData));
    if (success) {
      await this.props.handleStopAllTimers(success?.id);
      this.onClose(true);
    }
  }

  stopTimer() {
    let { controls } = this.state;

    controls.to_time.value = moment().format(this.format.time);
    this.setState({ controls }, () => this.onSave());
  }

  async updatePrevious() {
    const { timesheets } = this.props.office;

    const previous = timesheets.rows.find((timesheet) => !timesheet.to_date);

    if (previous) {
      previous.to_date = moment().format(this.format.output);
      await this.props.dispatch(updateTimesheet(previous));
    }
  }

  loadTimesheets() {
    const { currentWeek } = this.props.office;
    const { currentUser } = this.props.profile;

    this.props.dispatch(
      fetchTimesheets({
        ...currentWeek,
        user_id: currentUser.id,
      })
    );
  }

  handleTimeBlur(event) {
    this.handleChange(event);
  }

  async onChecked() {
    const { showRecentOnly } = this.state;

    // Fetch recent cost codes
    await this.props.dispatch(fetchCostcodes({ show_recent_only: !showRecentOnly }));

    // Update state
    this.setState({ showRecentOnly: !showRecentOnly });
  }

  onChange(event) {
    let { controls } = this.state;
    const { from_date, to_date } = this.props.office.currentWeek;

    this.handleChange(event);

    const { name, value } = event.target;
    switch (name) {
      case 'code_search': {
        let { controls } = this.state;
        controls.costcode_id.value = null;
        controls.phasecode_id.value = null;

        this.setState({
          controls,
          codeSelected: false,
        });
        break;
      }
      case 'from_date': {
        const setDateTimeControls = (controls, boolean, message) => {
          controls.from_date.warning = boolean;
          controls.from_date.message = message;
          controls.from_time.validationRules.isRequired = boolean;
          controls.to_time.validationRules.isRequired = boolean;
        };

        controls[name].value = value;
        if (from_date && to_date) {
          setDateTimeControls(controls, false, '');
          if (this.outsideCurrentWeek(value))
            setDateTimeControls(controls, true, 'Date selected is not within the current week.');
        }
        this.setState({ controls });
        break;
      }
      default: {
        break;
      }
    }
  }

  async getJiraIssue(value) {
    const { controls } = this.state;
    controls.jira_key.loading = true;

    this.setState({ controls, jiraKey: value });

    // Fetch Jira issue
    const issue = await this.props.dispatch(fetchJiraIssue(value));

    const { jiraKey } = this.state;
    if (value === jiraKey) {
      if (
        issue?.fields?.summary &&
        (controls.comments.value === null || controls.comments.value === '')
      )
        controls.comments.value = issue.fields.summary;

      controls.jira_key.loading = false;
      this.setState({
        jiraKey: null,
        controls,
      });
    }
  }

  handleKeyDown(event) {
    const { comments, jira_key } = this.state.controls;
    const { keyCode, target } = event;
    const { name } = target;

    if (
      name === 'jira_key' &&
      keyCode === 13 &&
      jira_key?.value.length >= jiraKeyMinLength &&
      (comments.value?.length === 0 || comments.value === null)
    ) {
      this.getJiraIssue(jira_key.value);
    }
  }

  handleBlur(event) {
    const { comments } = this.state.controls;
    const { name, value } = event.target;

    if (
      name === 'jira_key' &&
      value.length >= jiraKeyMinLength &&
      (comments.value?.length === 0 || comments.value === null)
    ) {
      this.getJiraIssue(value);
    }
  }

  async onSave() {
    let { data, controls, isNew } = this.state;

    let saveData = cloneDeep(data);
    saveData = omit(saveData, ['from_time', 'to_time']);

    // Build From Date/Time. Set NOW if no from_time
    const now = moment();
    const from_date = moment(controls.from_date.value);

    let from_time = controls.from_time.value;
    let to_time = controls.to_time.value;

    // Remove '_' before saving to DB
    if (from_time) from_time = from_time.replaceAll('_', '0');
    if (to_time) to_time = to_time.replaceAll('_', '0');

    // If from date is not today's date, update to_time control to be required
    if (!from_date.isSame(now, 'day')) {
      controls.to_time.validationRules = {
        ...controls.to_time.validationRules,
        isRequired: true,
      };
    }

    // Assume to_date is the same as from_date. This handles previous date timesheets.
    controls.from_date.value = `${from_date.format(this.format.day)} ${
      isEmpty(from_time) ? now.format(this.format.time) : from_time
    }`;
    controls.to_date.value = to_time ? `${from_date.format(this.format.day)} ${to_time}` : null;

    // Check and apply default values if applicable
    if (controls.break_hours.value === '')
      controls.break_hours.value = controls.break_hours.defaultValue;

    saveData = saveControls(controls, saveData);

    let validation = await validateFormFieldControls(saveData, controls);
    let { isValid, updatedControls } = validation;

    if (to_time && from_time >= to_time) {
      updatedControls.to_time = {
        ...updatedControls.to_time,
        valid: false,
        message: 'To Time should be later than the From Time.',
      };
      isValid = false;
    }

    // Initialise as true for all cases
    let confirmed = true;
    let updatedFromDate = updatedControls.from_date;

    if (!isValid) {
      // Validate from date control
      if (updatedFromDate?.warning) {
        /**
         * Raise confirmation window if from date is outside of the current week
         * Note: use original from_date to display in confirmation window
         */
        confirmed = window.confirm(
          `${from_date.format('Do MMMM YYYY')} is not within the current week. Continue?`
        );
        if (confirmed) {
          updatedFromDate.warning = false;
        } else {
          // Set value back to original user input
          updatedControls.from_date.value = from_date;
        }

        validation = await validateFormFieldControls(saveData, updatedControls);
        isValid = validation.isValid;
        updatedControls = validation.updatedControls;
      }
    }

    if (isValid && confirmed) {
      let success;
      if (isNew) {
        saveData = omit(saveData, ['id']);
        success = await this.props.dispatch(createTimesheet(saveData));
        await this.props.handleStopAllTimers(success?.id);
      } else {
        success = await this.props.dispatch(updateTimesheet(saveData));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      if (!updatedControls.costcode_id.valid) {
        updatedControls.code_search = {
          ...updatedControls.code_search,
          valid: false,
          message: 'Please choose a cost code from the search list.',
        };
      }

      /**
       * Handles the following cases for from date control:
       *  - Set warning message if user confirms saving a date outside the current week however, other invalid fields exist.
       *  - Reset valid property to true.
       */
      if (this.outsideCurrentWeek(updatedControls.from_date) || !updatedControls.from_date.valid) {
        updatedControls.from_date = {
          ...updatedControls.from_date,
          warning: true,
          message: 'Date selected is not within the current week.',
          valid: true,
        };
      }

      this.setState({
        controls: updatedControls,
      });
    }
  }

  outsideCurrentWeek(value) {
    const { from_date, to_date } = this.props.office.currentWeek;
    return (
      (moment(value).isBefore(moment(from_date)) || moment(value) > moment(to_date)) &&
      !isEmpty(value)
    );
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { id } = this.state;

    const confirmed = window.confirm(`Removing timesheet permanently. Continue?`);
    if (confirmed) {
      await this.props.dispatch(removeTimesheet(id));
      this.onClose(true);
    }
  }

  onCodeSelect(option) {
    let { controls, showRecentOnly } = this.state;

    controls.costcode_id.value = option.costcode_id;
    controls.phasecode_id.value = option.phasecode_id;
    controls.code_search.value = option.name;

    let updateState = { codeSelected: true, controls };
    if (showRecentOnly) updateState = { ...updateState, showRecentOnly: !showRecentOnly };

    this.setState(updateState);
  }

  renderCodeSearchValue(costcode, phasecode) {
    let name = `${costcode.jobno}`;
    if (phasecode) {
      name += `-${phasecode.name} ${costcode.name}`;
      if (phasecode.description !== null) {
        name += `/${phasecode.description}`;
      }
    } else name += `-${costcode.phasecode} ${costcode.name}`;

    return name;
  }

  renderAttributes(controls) {
    const { costcodes, orgs } = this.props.manage;

    controls = updateControlOptions(controls, 'client_org_id', orgs);

    // Load code_search options
    let code_search_options = [];
    costcodes
      .filter((costcode) => costcode.enabled)
      .forEach((costcode) => {
        const option = {
          costcode_id: costcode.id,
          phasecode_id: null,
        };
        costcode.phasecodes
          ?.filter((phasecode) => phasecode.enabled)
          .forEach((phasecode) => {
            code_search_options.push({
              ...option,
              phasecode_id: phasecode.id,
              name: this.renderCodeSearchValue(costcode, phasecode),
            });
          });
      });

    controls.code_search.options = code_search_options;

    return controls;
  }

  renderDisabledAttributes(data, controls) {
    const { costcodes } = this.props.manage;

    const costcode = costcodes.find((costcode) => costcode.id === data.costcode_id);
    const phasecode = costcode.phasecodes.find((phasecode) => phasecode.id === data.phasecode_id);

    controls.code_search.value = this.renderCodeSearchValue(costcode, phasecode);

    for (let key in controls) {
      controls[key].disabled = true;
    }

    return controls;
  }

  render() {
    let { codeSelected, controls, data, isOpen, title, isNew, showRecentOnly } = this.state;
    const { responseMessage } = this.props.office;

    const iconName = 'stopwatch';

    const locked = data.is_approved === true;
    controls = locked
      ? this.renderDisabledAttributes(data, controls)
      : this.renderAttributes(controls);

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormGroup check={true} inline={true} className="mt-0 mb-0">
              <Input
                checked={showRecentOnly}
                id="checkbox"
                onChange={this.onChecked}
                type="checkbox"
              />
              <Label check={true} className="font-weight-bold">
                Show Recent Cost Codes
              </Label>
            </FormGroup>
            <Row>
              <Col>
                <FormSearch
                  forceOptions={showRecentOnly}
                  handleChange={this.onChange}
                  handleSelect={this.onCodeSelect}
                  minimumLength={
                    controls.code_search.validationRules?.minLength ?? this.minCostCodeLength
                  }
                  control={controls.code_search}
                  selected={codeSelected}
                />
              </Col>
            </Row>
            <FormInput
              handleBlur={this.handleBlur}
              handleChange={this.handleChange}
              handleKeyDown={this.handleKeyDown}
              control={controls.jira_key}
            />
            <FormInput handleChange={this.handleChange} control={controls.comments} />
            <Row>
              <Col sm={4}>
                <FormInput handleChange={this.onChange} control={controls.from_date} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label
                  className="font-weight-bold"
                  style={{ paddingTop: 7, paddingBottom: 7, margin: 0 }}
                >
                  From Time
                </Label>
                <InputMask
                  mask={this.mask}
                  onChange={this.handleChange}
                  formatChars={this.formatChars}
                  className="form-control"
                  name="from_time"
                  value={controls.from_time.value}
                />
                {!controls.from_time.valid && (
                  <small className="text-danger">
                    {controls.from_time.message}
                    {controls.from_time.message && <br />}
                  </small>
                )}
                <small className="text-success mr-2">
                  24 hour clock. Leave blank to automatically set current date/time
                </small>
              </Col>
              <Col>
                <Label
                  className="font-weight-bold"
                  style={{ paddingTop: 7, paddingBottom: 7, margin: 0 }}
                >
                  To Time
                </Label>
                <InputMask
                  mask={this.mask}
                  onChange={this.handleChange}
                  formatChars={this.formatChars}
                  className="form-control"
                  name="to_time"
                  value={controls.to_time.value}
                />
                {!controls.to_time.valid && (
                  <small className="text-danger">
                    {controls.to_time.message}
                    {controls.to_time.message && <br />}
                  </small>
                )}
                <small className="text-success mr-2">
                  24 hour clock. Leave blank to start a timer
                </small>
              </Col>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.break_hours} />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            {!locked && (
              <Button size="sm mr-2" color="success" onClick={this.onSave}>
                Save
              </Button>
            )}

            <Button size="sm" className="mr-2" color="light" onClick={this.onClose}>
              {!locked ? 'Cancel' : 'Close'}
            </Button>
            {!locked && (
              <Button disabled={isNew} size="sm" color="danger" onClick={this.onRemove}>
                Delete
              </Button>
            )}

            {!isNew && !locked && !controls.to_date.value && (
              <Button className="ml-5" size="sm" color="danger" onClick={this.stopTimer}>
                <Icon name="stop" className="mr-2" />
                Stop Timer
              </Button>
            )}

            {!isNew && !locked && controls.to_date.value && (
              <Button className="ml-5" size="sm" color="success" onClick={this.startTimer}>
                <Icon name="play" className="mr-2" />
                Copy/Start Timer
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    office: store.office,
    manage: store.manage,
    profile: store.profile,
  };
};

export default connect(mapStoreToProps)(TimekeeperTimerModal);
