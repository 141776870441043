import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { noop } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import { hectareNumberFormat } from 'jsx/lib/generic';
import Listview from '../../../../core/form/components/Listview';
import ProjectPropertyNew from '../../containers/ProjectPropertyNew';
import { normaliseStates } from '../../lib/util';

const PropertiesLsv = ({
  project_id,
  rows,
  history,
  checkAccess = noop,
  hideHeader,
  hideNew,
  darkTable,
  rowsCount,
  onLoadMore,
  filterColumns = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);

  const createNew = (newPropertyId) => {
    setId(newPropertyId);
    setIsOpen(true);
  };

  const edit = (newPropertyId, projectId) => {
    // If PropertiesLsv is rendering as a child of a Project, the projectId will be supplied from
    // the parent component. Otherwise, we get it from the row data
    let propertyLink = '';
    if (projectId) {
      propertyLink = `/home/projects/${projectId}/properties`;
    } else {
      propertyLink = `/home/projects/${this.props.projectId}/properties`;
    }

    const lnk = `${propertyLink}/${newPropertyId}`;
    history.push(lnk);
  };

  const renderRows = (headers, property) => {
    const tableTd = headers.map(({ classes, field }, index) => {
      const ceaArea = property.stats?.areas.find((curr) => curr.area_type === 'CEA');
      switch (field) {
        case 'area_ha':
          return (
            <td key={index} className={classes}>
              {hectareNumberFormat(property?.stats?.lots?.area_ha.value)}
            </td>
          );
        case 'project':
          return (
            <td key={index} className={classes}>
              {property?.project?.name}
            </td>
          );
        case 'total_cea_area':
          return (
            <td key={index} className={classes}>
              {hectareNumberFormat(ceaArea?.area_ha)}
            </td>
          );
        case 'total_cea_count':
          return (
            <td key={index} className={classes}>
              {ceaArea?.count || 0}
            </td>
          );
        case 'total_lot_count':
          return (
            <td key={index} className={classes}>
              {property.stats?.lots?.count.value || 0}
            </td>
          );
        case 'total_round_count':
          return (
            <td key={index} className={classes}>
              {property.stats?.property_rounds?.count || 0}
            </td>
          );

        case 'state':
          return (
            <td key={index} className={classes}>
              {normaliseStates(property.state)}
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {property[field]}
            </td>
          );
      }
    });

    tableTd.push(
      <td key={headers.length + 1} className="d-flex justify-content-center">
        <Icon name="pen-to-square" className="text-primary" />
      </td>,
    );
    return tableTd;
  };

  let upperHeaders = [];
  if (!filterColumns.length) {
    // for simplicity, exclude the upper headers when custom columns are provided
    upperHeaders = [
      { caption: null, cells: 6, classes: 'border-bottom-0' },
      { caption: 'CEA', cells: 2, classes: 'text-center bg-lightgray border border-lightgray' },
    ];
  }

  let headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Project', field: 'project', classes: '' },
    { caption: 'Idx', field: 'idx', classes: 'text-left' },
    { caption: 'Property Lots (ha)', field: 'area_ha', classes: 'text-right' },
    { caption: 'Address', field: 'address', classes: 'text-left' },
    { caption: 'State', field: 'state', classes: 'text-left' },
    { caption: 'Property CEA (ha)', field: 'total_cea_area', classes: 'text-right border-left' },
    { caption: 'Total', field: 'total_cea_count', classes: 'text-right border-right' },
    { caption: 'Total Lots', field: 'total_lot_count', classes: 'text-right' },
    { caption: 'Total Rounds', field: 'total_round_count', classes: 'text-right' },
  ];

  if (filterColumns.length) {
    headers = headers.filter((curr) => filterColumns.some((filter) => filter === curr.field));
  }

  const upperHeadTh = upperHeaders.map(({ cells, caption, classes }, index) => (
    <th key={index} colSpan={cells} className={classes}>
      {caption}
    </th>
  ));
  const tableHeadTh = headers.map(({ classes, caption }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));
  tableHeadTh.push(<th key={headers.length + 1}></th>);

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      const { id: projectId } = row.project;
      return (
        <tr
          key={index}
          onClick={() => {
            edit(row.id, projectId);
          }}
          style={{ cursor: 'pointer' }}
        >
          {renderRows(headers, row)}
        </tr>
      );
    });
  }

  const iconName = 'farm';
  const emptyCaption = 'No Properties found';

  return (
    <>
      {!hideNew && (
        <div className="d-flex justify-content-end">
          <Button
            disabled={!checkAccess('propertyUpdate')}
            size="sm"
            color="success"
            onClick={() => createNew(null)}
          >
            Add New Property
          </Button>
          <ProjectPropertyNew
            setModal={setIsOpen}
            project_id={project_id}
            id={id}
            isOpen={isOpen}
          />
        </div>
      )}

      <Listview
        darkTable={darkTable}
        upperHeadTh={upperHeadTh}
        hideHeader={hideHeader}
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
        rowsCount={rowsCount}
        onLoadMore={onLoadMore}
      />
    </>
  );
};

export default PropertiesLsv;
