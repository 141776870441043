import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';

const PageTitle = (props) => {
  const {
    title,
    description,
    iconName
  } = props;

  return (
    <div>
      <div className="d-flex flex-row">
        {iconName && (<Icon size="2x" name={iconName} className="appForeTint mr-3" />)}
        <h3 className="text-black">{title}</h3>
      </div>
      {description && (
        <div>{description}</div>
      )}
    </div>
  )
}

export default PageTitle;
