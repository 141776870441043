import React from 'react';
import { Button, CustomInput, Label } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const AdminScheduledJobsToolbar = (props) => {
  const {
    onAdd = noop,
    onRefresh = noop,
    onHandleChange = noop,
    switchChecked = true,
    onTerminate = noop,
    stats = {}
  } = props;

  let statsMessage = 'Warehouse Stats - ';
  const statKeys = Object.keys(stats);
  statKeys.map(statKey => {
    statsMessage += `${statKey}: ${stats[statKey]}, `;
  });

  return (
    <div className="d-flex justify-content-between bg-light rounded">
      <div className="m-2">
        {statsMessage}
      </div>
      
      <div className="m-1 p-1 d-flex justify-content-end">
        <Label className="m-1">Auto Load</Label>
        <CustomInput
          type="switch"
          role="switch"
          id={'autoload'}
          name={'autoload'}
          checked={switchChecked}
          onChange={onHandleChange}
          className="m-1"
        />
        <Button onClick={onAdd} size="sm" color="primary" className="ml-1">
          Add Job
        </Button>

        <Button onClick={onTerminate} size="sm" color="warning" className="ml-1">
          Terminate
        </Button>

        <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
          <Icon name="rotate" />
        </Button>
      </div>
    </div>

  );
};

export default AdminScheduledJobsToolbar;
