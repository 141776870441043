import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'reactstrap';

import {
  updateControls,
  initControls,
  controlDefaults,
} from '../../../../core/form/lib/validateForm';

import { controls } from '../../forms/projectDocumentGroup';
import FormInput from '../../../../core/form/components/FormInput';
import FormBase from '../../../../core/form/components/FormBase';

import { fetchProjects } from '../../actions/projects';
import { fetchProperties } from '../../actions/properties';
import { fetchDocumentGroup, fetchDocumentTemplates } from '../../actions/document_groups';
import { fetchPeriods } from '../../actions/periods';

class ProjectGroupProfile extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Project Document Group',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      project_id: null,
      controls,
      template_controls: {
        project_id: {
          ...controlDefaults,
          placeholder: '',
          name: 'project_id',
          type: 'select',
          caption: 'Project',
          options: [],
          validationRules: {
            minLength: 1,
            isRequired: true,
          },
        },
      },
    };

    this.bindOptions = this.bindOptions.bind(this);
    this.handleTemplateChange = this.handleTemplateChange.bind(this);
  }

  componentDidMount() {
    if (this.props.controls) {
      this.setState({ controls: this.props.controls });
    }
  }

  async componentDidUpdate() {
    let { isOpen, id, controls } = this.state;

    let project_id = this.props.project_id;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      this.setState({
        controls: initControls(this.state.controls),
        template_controls: initControls(this.state.template_controls),
      });

      this.props.dispatch(fetchProjects());
      this.props.dispatch(fetchProperties({ project_id }));
      this.props.dispatch(fetchPeriods(project_id));

      if (this.props.id) {
        id = this.props.id;
        this.props.dispatch(fetchDocumentGroup(id)).then((data) => {
          controls = updateControls(controls, data);
          this.setState({ controls, data });
        });
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        project_id,
      });
    }
    this.bindOptions();
  }

  bindOptions() {
    let { controls, template_controls } = this.state;
    const { properties } = this.props.properties;
    const { rows: projects } = this.props.projects.projects;
    const { periods } = this.props.periods;

    if (!controls['property_id'].options && properties.length > 0) {
      const emptyValue = '-';
      const emptyOption = [{ id: null, name: emptyValue }];
      const options = emptyOption.concat(properties);
      controls['property_id'].options = options;
      this.setState({ controls });

      if (controls['property_id'].value === emptyValue) {
        controls['property_id'].value = null;
        this.setState({ controls });
      }
    }

    if (!controls['period_id'].options && periods.length > 0) {
      const emptyValue = '-';
      const emptyOption = [{ id: null, name: emptyValue }];
      const options = emptyOption.concat(periods);
      controls['period_id'].options = options;
      this.setState({ controls });

      if (controls['period_id'].value === emptyValue) {
        controls['period_id'].value = null;
        this.setState({ controls });
      }
    }

    if (projects.length > 0 && template_controls['project_id'].options.length === 0) {
      const emptyValue = '-';
      const emptyOption = [{ id: null, name: emptyValue }];

      const options = emptyOption.concat(projects);

      template_controls['project_id'].options = options;
      this.setState({ template_controls });
    }
  }

  async handleTemplateChange(event) {
    let { controls } = this.state;
    let { template_controls } = this.state;
    let value = event.target.value;

    template_controls.project_id.value = value;

    // Reset controls
    controls['from_group_id'].options = [];
    controls['from_group_id'].disabled = true;
    controls['from_group_id'].value = null;

    // Load group templates if exists
    if (value !== '-') {
      await this.props.dispatch(fetchDocumentTemplates({ project_id: value })).then((options) => {
        const emptyValue = '-';
        const emptyOption = [{ id: '', name: emptyValue }];
        options = emptyOption.concat(options);
        controls['from_group_id'].options = options;
        controls['from_group_id'].disabled = false;
        controls['from_group_id'].value = null;
      });
    }

    this.setState({ template_controls });
    this.setState({ controls });
  }

  render() {
    const { template_controls, controls, data, id } = this.state;

    const { onSave, onCancel, onRemove } = this.props;

    const { responseMessage } = this.props.document_groups;

    return (
      <React.Fragment>
        {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
        <Form>
          <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Details</h5>
          <Row className="bg-light m-0 pb-2">
            <Col>
              <FormInput handleChange={this.handleChange} control={controls.name} />
            </Col>
            <Col>
              <FormInput handleChange={this.handleChange} control={controls.property_id} />
            </Col>
            <Col>
              <FormInput handleChange={this.handleChange} control={controls.period_id} />
            </Col>
          </Row>

          {!id && (
            <React.Fragment>
              <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">
                Clone from another project
              </h5>
              <Row className="bg-light m-0 pb-2">
                <Col>
                  <FormInput
                    handleChange={this.handleTemplateChange}
                    control={template_controls.project_id}
                  />
                </Col>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.from_group_id} />
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Form>
        {onSave && (
          <div className="p-2 d-flex justify-content-center mt-2">
            <Button
              disabled={!this.checkAccess('projectUpdate')}
              color="success"
              className="m-1"
              onClick={() => {
                onSave(controls, data);
              }}
            >
              Save
            </Button>
            <Button color="light" className="m-1" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              disabled={!this.checkAccess('projectUpdate')}
              color="danger"
              className="m-1"
              onClick={onRemove}
            >
              Delete
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    projects: store.projects,
    properties: store.properties,
    periods: store.periods,
    document_groups: store.document_groups,
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(ProjectGroupProfile);
