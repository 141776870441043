import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  user_id: {
    ...controlDefaults,
    name: 'user_id',
    type: 'select',
    caption: 'User',
    options: [],
    includeEmptyOption: true,
    validationRules: {
      isRequired: true,
    },
  },
  location_id: {
    ...controlDefaults,
    name: 'location_id',
    type: 'select',
    caption: 'Location',
    options: [],
    description: '',
    validationRules: {},
  },
  project_id: {
    ...controlDefaults,
    name: 'project_id',
    type: 'select',
    caption: 'Project',
    options: [],
  },
  from_datetime: {
    ...controlDefaults,
    placeholder: 'Date',
    name: 'from_datetime',
    type: 'datetime-local',
    caption: 'From Date/Time',
    validationRules: {
      isRequired: true,
    },
    col: 1,
  },
  to_datetime: {
    ...controlDefaults,
    placeholder: 'Date',
    name: 'to_datetime',
    type: 'datetime-local',
    caption: 'To Date/Time',
    validationRules: {
      isRequired: true,
    },
  },
  shift_id: {
    ...controlDefaults,
    name: 'shift_id',
    type: 'select',
    caption: 'Roster/Shift',
    disabled: true,
    options: [],
    includeEmptyOption: true,
    validationRules: { isRequired: true },
  },
  shift_start: {
    ...controlDefaults,
    placeholder: 'Date',
    name: 'shift_start',
    type: 'date',
    caption: 'Shift Start',
  },
};
