import React from 'react';
import { connect } from "react-redux";

import DirectUpload from '../../../core/form/components/DirectUpload';
import FormBase from "../../../core/form/components/FormBase";

import LabRegisterRecordDocumentsLsv from '../components/LabRegisterRecordDocumentsLsv';


import {
  fetchLabRegisterDocuments,
  downloadLabRegisterDocument,
  uploadLabRegisterDocument,
  removeLabRegisterDocument
} from '../actions';


class LabRegisterRecordDocuments extends FormBase {
  constructor(props) {
    super(props)

    this.onUpload = this.onUpload.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  async onUpload(event, onProgressChange) {
    const { currentRegisterRecord } = this.props.lab;

    if (event.target.files.length === 0) return false;

    const file = event.target.files[0];

    let formData = new FormData();
    formData.append('document', file);
    formData.append('register_id', currentRegisterRecord.id);

    const success = await this.props.dispatch(uploadLabRegisterDocument(formData, onProgressChange))
    if (success) this.props.dispatch(fetchLabRegisterDocuments(currentRegisterRecord.id));
  }

  async onDownload(id, onProgressChange) {
    return await this.props.dispatch(downloadLabRegisterDocument(id, onProgressChange));
  }

  async onDelete(id) {
    const { currentRegisterRecord } = this.props.lab;
    let success = await this.props.dispatch(removeLabRegisterDocument(id));
    if (success) this.props.dispatch(fetchLabRegisterDocuments(currentRegisterRecord.id));
  }

  render() {

    const {
      documents
    } = this.props.lab;

    return (
      <div>
        <div className="d-flex justify-content-end bg-light p-1 mb-2 rounded">
          <div className="mt-1">
            <DirectUpload
              handleUpload={(event, onProgressChange) => this.onUpload(event, onProgressChange)}
              caption="Upload Document"
              size="sm"
              showLabel={false}
            />
          </div>
        </div>
        <LabRegisterRecordDocumentsLsv
          checkAccess={this.checkAccess}
          rows={documents || []}
          handleDownload={this.onDownload}
          handleDelete={this.onDelete}
        />
      </div>
    )
  }
}
const mapStoreToProps = (store) => {
  return {
    lab: store.lab,
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(LabRegisterRecordDocuments);
