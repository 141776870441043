import { defaults, allowed } from '../../../../lib/genericReducer';

import { fakerMaintenanceItems, fakerFaults } from '../lib/faker';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    activeTabAssets: 'register',
    activeTabJobs: 'workactivities',
    activeTimesheetsTab: 'daysheets',
    workActivityActiveTab: 'activity_tab',
    locationManagmentActiveTab: 'location_management',
    activeTabDaysheetInlineEditor: 'activity',
    assets: {
      count: 0,
      rows: [],
    },
    projectAssetAssociations: [],
    locationProjectAssociations: [],
    jobs: [],
    jobComments: [],
    locations: [],
    params: {
      locationsSearchTerm: '',
      locations: [],
      projects: [],
    },
    readings: [],
    selected_asset_readings: [],
    faults: fakerFaults(200),
    maintenanceItems: fakerMaintenanceItems(50),
    daysheetActivities: [],
    daysheetBreaks: [],
    daysheets: [],
    isDaysheetModalOpen: false,
  },
  action,
) {
  const types = [
    'ASSETS_PARAMS',
    'LOOKUP_LOCATION',
    'LOCATION_PROJECT',
    'PLANT',
    'JOB',
    'WORKACTIVITY',
    'DAYSHEET',
    'TIMESHEET',
    'LOCATION_MANAGEMENT',
  ];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_LOOKUP_LOCATIONS_FULFILLED': {
      return {
        ...state,
        locations: action.payload,
      };
    }
    case 'FETCH_LOOKUP_LOCATION_PROJECTS_FULFILLED': {
      return {
        ...state,
        locationProjectAssociations: action.payload,
      };
    }
    case 'FETCH_PLANT_ASSETS_FULFILLED': {
      return {
        ...state,
        assets: action.payload,
      };
    }
    case 'FETCH_ORG_PLANT_ASSET_PROJECTS_FULFILLED': {
      return {
        ...state,
        projectAssetAssociations: action.payload,
      };
    }
    case 'FETCH_JOBS_FULFILLED': {
      return {
        ...state,
        jobs: action.payload,
      };
    }

    case 'FETCH_JOB_COMMENTS_FULFILLED': {
      return {
        ...state,
        jobComments: action.payload,
      };
    }
    case 'FETCH_PLANT_ASSET_READINGS_FULFILLED': {
      return {
        ...state,
        readings: action.payload,
      };
    }
    case 'FETCH_DAYSHEETS_FULFILLED': {
      return {
        ...state,
        daysheets: action.payload,
      };
    }
    case 'FETCH_DAYSHEET_ACTIVITIES_FULFILLED': {
      return {
        ...state,
        daysheetActivities: action.payload,
      };
    }
    case 'FETCH_DAYSHEET_BREAKS_FULFILLED': {
      return {
        ...state,
        daysheetBreaks: action.payload,
      };
    }
    case 'UNSET_DAYSHEET_ACTIVITIES': {
      return {
        ...state,
        daysheetActivities: [],
      };
    }
    case 'FETCH_PLANT_ASSET_ASSOCIATED_READINGS_FULFILLED': {
      return {
        ...state,
        selected_asset_readings: action.payload,
      };
    }
    case 'UNSET_DAYSHEET_BREAKS': {
      return {
        ...state,
        daysheetBreaks: [],
      };
    }
    case 'SET_PLANT_ASSETS_TAB': {
      return {
        ...state,
        activeTabAssets: action.payload,
      };
    }
    case 'SET_JOBS_TAB': {
      return {
        ...state,
        activeTabJobs: action.payload,
      };
    }
    case 'SET_TIMESHEETS_ACTIVE_TAB': {
      return {
        ...state,
        activeTimesheetsTab: action.payload,
      };
    }
    case 'SET_WORKACTIVITY_TAB': {
      return {
        ...state,
        workActivityActiveTab: action.payload,
      };
    }
    case 'SET_DAYSHEET_INLINE_EDITOR_TAB': {
      return {
        ...state,
        activeTabDaysheetInlineEditor: action.payload,
      };
    }
    case 'SET_LOCATION_MANAGEMENT_TAB': {
      return {
        ...state,
        locationManagmentActiveTab: action.payload,
      };
    }
    case 'SET_ASSETS_PARAMS': {
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      };
    }
    case 'UNSET_LOCATION_PROJECT_ASSOCIATIONS': {
      return {
        ...state,
        locationProjectAssociations: [],
      };
    }
    case 'SET_DAYSHEET_MODAL_OPEN': {
      return {
        ...state,
        isDaysheetModalOpen: action.payload,
      };
    }
    case 'SET_DAYSHEET_FILTERS': {
      return {
        ...state,
        daysheetFilters: action.payload,
      };
    }
    case 'SET_DAYSHEET_LOCATION_FILTERS': {
      const currentLocations = [...state.params.locations];

      if (currentLocations.includes(action.payload)) return state;
      currentLocations.push(action.payload);

      return {
        ...state,
        params: {
          ...state.params,
          locations: currentLocations,
        },
      };
    }
    case 'REMOVE_DAYSHEET_LOCATION_FILTER': {
      const currentLocations = [...state.params.locations];
      const filteredLocations = currentLocations.filter((curr) => curr !== action.payload);
      return {
        ...state,
        params: {
          ...state.params,
          locations: filteredLocations,
        },
      };
    }
    case 'SET_DAYSHEET_PROJECT_FILTERS': {
      const currentProjects = [...state.params.projects];
      if (currentProjects.includes(action.payload)) return state;

      currentProjects.push(action.payload);
      return {
        ...state,
        params: {
          ...state.params,
          projects: currentProjects,
        },
      };
    }
    case 'REMOVE_DAYSHEET_PROJECT_FILTER': {
      const currentProjects = [...state.params.projects];
      const filteredProjects = currentProjects.filter((curr) => curr !== action.payload);
      return {
        ...state,
        params: {
          ...state.params,
          projects: filteredProjects,
        },
      };
    }
    case 'CLEAR_DAYSHEET_MODAL_FILTERS': {
      return {
        ...state,
        params: {
          ...state.params,
          projects: [],
          locations: [],
        },
      };
    }
    case 'CLEAR_WORK_ACTIVITY_MODAL_FILTERS': {
      return {
        ...state,
        params: {
          ...state.params,
          assets: [],
          locations: [],
        },
      };
    }
    default:
      break;
  }

  return state;
}
