import { columnWidths } from 'jsx/components/modules/portrait/constants/listviews';
import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    name: 'name',
    type: 'text',
    caption: 'Name',
    width: `${columnWidths.common}px`,
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  division_id: {
    ...controlDefaults,
    name: 'division_id',
    type: 'select',
    fieldName: 'type.parent.name',
    includeEmptyOption: false,
    caption: 'Division',
    showInEditor: true,
    showInListview: true,
  },
  use_average_values: {
    ...controlDefaults,
    name: 'use_average_values',
    field_name: 'use_average_values',
    controlType: 'switch',
    type: 'checkbox',
    caption: 'Use Average Values',
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    validationRules: {
      isRequired: true,
    },
    defaultValue: true,
    showInEditor: true,
    showInListview: true
  },
  type_id: {
    ...controlDefaults,
    name: 'type_id',
    type: 'select',
    fieldName: 'type.name',
    caption: 'Type',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  }
};
