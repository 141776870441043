import React, { useState }  from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

import SiteSamplesChartSpectraNm from '../components/SiteSamplesChartSpectraNm';
import SiteSamplesChartGamma from '../components/SiteSamplesChartGamma';
import SiteSamplesImages from '../components/SiteSamplesImages';

const SiteSampleChartModal = (props) => {
  const {
    // title,
    isOpen,
    toggle,
    // onNext,
    // onPrev,
    samples,
    fetchSamples,
    rows,
    currentSite
    // onSelectPosition,
    // selectedPosition
  } = props;

  const title = `Samples for core ${currentSite.core_key}`;

  const [selectedPosition, onSelectPosition] = useState(0);

  const viewNextChart = () => {
    const scannedSites = rows.filter(site => site.scan_samples_exists === true)
    const currentIdx = scannedSites.findIndex(site => site.id === currentSite.id);

    let nextIdx = currentIdx + 1;
    if (nextIdx >= scannedSites.length) {
      nextIdx = 0
    }

    const nextSite = scannedSites[nextIdx]
    fetchSamples(nextSite);
  }

  const viewPrevChart = () => {
    const scannedSites = rows.filter(site => site.scan_samples_exists === true)
    const currentIdx = scannedSites.findIndex(site => site.id === currentSite.id);

    let prevIdx = currentIdx - 1;
    if (prevIdx < 0 ) {
      prevIdx = scannedSites.length - 1;
    }

    const prevSite = scannedSites[prevIdx]
    fetchSamples(prevSite);
  }

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} className="chart-modal">
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name="image" className="mr-2"/>
          {title}
        </ModalHeader>
        <ModalBody>
          <SiteSamplesChartGamma samples={samples} />
          <SiteSamplesChartSpectraNm samples={samples} height={400} />
          <SiteSamplesImages samples={samples} onSelectPosition={onSelectPosition} selectedPosition={selectedPosition}/>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button size="sm" color="primary" onClick={viewPrevChart}>Previous</Button>
          <Button size="sm" color="light" onClick={toggle}>Close</Button>
          <Button size="sm" color="primary" onClick={viewNextChart}>Next</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default SiteSampleChartModal;
