import React, { PureComponent } from 'react';
import {
  Bar,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

// eslint-disable-next-line no-undef
const { faker } = require('@faker-js/faker');

class CattleAnalyticsSales extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      years: [
        { name: '2016', months: [1, 2, 6, 7, 8] },
        { name: '2017', months: [1, 3, 4, 7, 8, 9, 12] },
        { name: '2018', months: [3, 5, 6, 7, 9, 11] },
        { name: '2019', months: [2, 6, 7, 9, 10] },
      ],
    };
  }

  componentDidMount() {
    this.generateRandomSales();
  }

  generateRandomSales() {
    const { years } = this.state;

    let data = [];
    const doubleDigits = 10;
    years.map((year) => {
      year.months.map((month) => {
        const dtString =
          month < doubleDigits ? `${year.name}-0${month}-01` : `${year.name}-${month}-01`;
        const sales_count = faker.datatype.number({ min: 500, max: 3000 });
        const sales_amount = sales_count * faker.datatype.number({ min: 2, max: 4 });

        data.push({
          name: moment(dtString).valueOf(),
          transfers: faker.datatype.number({ min: 0, max: 100 }),
          sales: sales_count,
          purchases: faker.datatype.number({ min: 500, max: 3000 }),
          amount: sales_amount,
        });

        return false;
      });
      return false;
    });

    this.setState({ data });
  }

  render() {
    const { data } = this.state;

    const seriesA = 'left';
    const seriesB = 'right';

    return (
      <div>
        <h5 className="text-center mt-2">{this.props.title}</h5>
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            title="Some chart"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tickFormatter={(unixTime) => moment(unixTime).format('MMM-YYYY')}
              type="number"
              domain={[moment('2015-12-01').valueOf(), moment('2020-01-01').valueOf()]}
              allowDataOverflow={false}
            />
            <YAxis name="Head Count" yAxisId={seriesA} dataKey="sales" orientation={seriesA} />
            <YAxis yAxisId={seriesB} dataKey="amount" orientation={seriesB} />
            <Tooltip />
            <Legend />
            <Bar name="Sales" yAxisId={seriesA} dataKey="sales" fill="#8884d8" />
            <Bar name="Purchases" yAxisId={seriesA} dataKey="purchases" fill="#82ca9d" />
            <Bar name="Transfers" yAxisId={seriesA} dataKey="transfers" fill="orange" />
            <Line name="Sales Amount" yAxisId={seriesB} dataKey="amount" fill="red" stroke="red" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default CattleAnalyticsSales;
