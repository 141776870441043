import React from 'react';
import { 
  Button,
  Row,
  Col,
  Form
} from 'reactstrap';
import FormInput from "../../../core/form/components/FormInput";

const FieldBookForm = (props) => {
  const {
    handleChange,
    controls,
    onSave,
    onCancel,
    disabled,
    dateRef
  } = props;

  const actual_depth_value = (controls.stickup_height.value ? controls.target_depth.value - controls.stickup_height.value : null);

  const actualDepthControl = {
    type: 'text',
    caption: 'Actual Depth (mm)',
    persist: false,
    value: actual_depth_value,
    validationRules: {},
  }

  return (
    <React.Fragment>
      <Form onSubmit={e => { e.preventDefault(); }} >
        <Row className="bg-light m-0 pb-2">
          <Col sm="3"><FormInput innerRef={dateRef} disabled={disabled} handleChange={handleChange} control={controls.collected_at} /></Col>
          <Col sm="3"><FormInput disabled={disabled} handleChange={handleChange} control={controls.target_depth} /></Col>
          <Col sm="3"><FormInput disabled={disabled} handleChange={handleChange} control={controls.stickup_height} /></Col>
          <Col sm="3"><FormInput disabled={true} handleChange={handleChange} control={actualDepthControl} /></Col>
        </Row>

        <Row className="bg-light m-0 pb-2">
          <Col sm="12"><FormInput disabled={disabled} handleChange={handleChange} control={controls.notes} /></Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button disabled={disabled} size="sm" color="success" className="m-2" onClick={onSave} onKeyPress={onSave}>Save</Button>
          <Button size="sm" color="light" className="m-2" onKeyDown={onCancel} onClick={onCancel}>Cancel</Button>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default FieldBookForm;
