import React from 'react';
import { connect } from 'react-redux';
import { Card, TabContent, TabPane } from 'reactstrap';

import { updateControls, saveControls } from '../../../core/form/lib/validateForm';
import { areaRoundControls } from '../forms/area_rounds';

import AreaRoundStratasLsv from '../components/AreaRoundStratasLsv';
import AreaRoundTitle from '../components/AreaRoundTitle';
import AreaRoundTabs from '../components/AreaRoundTabs';
import AreaRoundProfile from '../components/AreaRoundProfile';
import AreaRoundPanelSites from './AreaRoundPanelSites';
import AreaRoundPanelLab from './AreaRoundPanelLab';
import AreaRoundPanelActivities from './AreaRoundPanelActivities';
import LabRegisterPanel from '../../lab/containers/LabRegisterPanel';

import PipelinesPanel from '../../../core/pipeline/containers/PipelinesPanel';
import PipelinePanelFiles from '../../../core/pipeline/containers/PipelinePanelFiles';
import PipelineDocsLsv from '../../../core/pipeline/components/PipelineDocsLsv';
import PipelinePanelSubsampling from '../../../core/pipeline/containers/PipelinePanelSubsampling';

import SiteUploadModal from '../../sites/containers/SiteUploadModal';

import FormBase from '../../../core/form/components/FormBase';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import AreaRoundMap from './AreaRoundMap';

import Uploads from '../lib/uploads';
import Downloads from '../lib/downloads';

import {
  fetchAreaRound,
  fetchAreaRoundStratas,
  updateAreaRound,
  uploadSites,
  generateSites,
} from '../actions';

import { fetchSites, fetchSiteScripts } from '../../sites/actions';

import { fetchPropertyRounds } from '../../projects/actions/property_rounds';

import { fetchActivities } from '../../../core/activity/actions';
import { fetchPeriods } from '../../projects/actions/periods';

import { fetchLabRegisterSamplesByRound, setLabRegisterParams } from '../../lab/actions';

import {
  clearPipelines,
  downloadPipelineDocument,
  fetchPipelines,
  fetchPipeline,
  fetchPipelineFiles,
  fetchPipelineDocuments,
  removePipelineDocument,
} from '../../../core/pipeline/actions';
import BreadcrumbsRoute from '../../../core/form/components/BreadcrumbsRoute';

export class AreaRound extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      expandMap: false,
      showMiniMap: false,
      showMap: false,
      controls: areaRoundControls,
      backlink: '/home/projects',
      isValid: true,
      data: {},
      project_id: null,
      id: null,
      sitesUploadIsOpen: false,
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);

    // Class for all upload functions
    this.uploads = new Uploads();
    this.downloads = new Downloads();

    this.toggleMap = this.toggleMap.bind(this);
    this.toggleMiniMap = this.toggleMiniMap.bind(this);
    this.toggleTab = this.toggleTab.bind(this);

    this.openSiteUpload = this.openSiteUpload.bind(this);
    this.handleSiteUpload = this.handleSiteUpload.bind(this);
    this.handleSiteGenerate = this.handleSiteGenerate.bind(this);

    this.handleRemovePipelineDocument = this.handleRemovePipelineDocument.bind(this);

    this.clearPipelines = this.clearPipelines.bind(this);
  }

  async componentDidMount() {
    let { data, controls } = this.state;
    const { id, property_id, project_id, area_id } = this.props.match.params;
    const round_id = this.props.match.params.id;
    const backlink = `/home/projects/${project_id}/properties/${property_id}`;
    const homeLink = `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${round_id}`;

    if (id !== 'new') {
      data = await this.props.dispatch(fetchAreaRound(id));
      controls = updateControls(controls, data);

      // this.props.dispatch(fetchAreaRoundStratas(id))
      this.props.dispatch(fetchPeriods(project_id));
      this.props.dispatch(fetchPropertyRounds(property_id));

      if (data?.property_round?.subsample_type === 'area_round') {
        this.props.dispatch(fetchPipelines({ area_round_id: id }));
      }
    }

    // Set defaults for upload functions.
    this.uploads.set(data.id, this.props.dispatch);

    // Set defaults for download functions.
    this.downloads.set(data.id, this.props.dispatch);

    this.setState({
      data,
      controls,
      backlink,
      homeLink,
      id,
    });

    const tab_id = this.props.match.params.tab_id ?? this.props.areas.activeTab;

    if (tab_id) this.toggleTab(tab_id);
  }

  async componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_STRATAS' });
    this.props.dispatch({ type: 'UNSET_SITES' });
    this.props.dispatch({ type: 'UNSET_ROUND' });
    this.props.dispatch({ type: 'UNSET_RESPONSE_MESSAGES' });
    this.props.dispatch({ type: 'UNSET_LAB_REGISTER' });
    this.props.dispatch({ type: 'UNSET_LAB_REGISTER_PARAMS' });
    this.props.dispatch({ type: 'UNSET_LAB_REGISTER_SAMPLES' });
    this.props.dispatch({ type: 'UNSET_ACTIVITIES' });
    this.props.dispatch({ type: 'UNSET_PIPELINES' });
  }

  componentDidUpdate() {
    const { controls } = this.state;
    const { periods } = this.props.periods;
    const { propertyRounds } = this.props.properties;
    const { scripts } = this.props.sites;

    if (periods.length > 0 && !controls.project_period_id.options) {
      controls.project_period_id.options = periods;
      this.setState({ controls });
    }

    const emptyValue = '-';
    if (propertyRounds.length > 0) {
      if (!controls.property_round_id.options) {
        const emptyOption = [{ id: null, name: emptyValue }];
        controls.property_round_id.options = emptyOption.concat(propertyRounds);
        this.setState({ controls });
      }

      if (controls.property_round_id.value === emptyValue) {
        controls.property_round_id.value = null;
        this.setState({ controls });
      }
    }

    if (scripts.length > 0) {
      if (!controls.site_script_id.options) {
        const emptyOption = [{ id: null, name: emptyValue }];
        controls.site_script_id.options = emptyOption.concat(scripts);
        this.setState({ controls });
      }

      if (controls.site_script_id.value === emptyValue) {
        controls.site_script_id.value = null;
        this.setState({ controls });
      }
    }
  }

  toggleTab(tab) {
    const { data, showMap, showMiniMap } = this.state;
    const { filters } = this.props.activities;
    const { params } = this.props.lab;
    const area_round_id = this.props.match.params.id;

    switch (tab) {
      case 'profile':
        this.props.dispatch(fetchSiteScripts());
        break;
      case 'stratas':
        this.props.dispatch(fetchAreaRoundStratas(area_round_id, showMap || showMiniMap));
        break;
      case 'sub-sample-selection':
        this.props.dispatch(fetchPipeline({ area_round_id: data.id, key: 'subsampling' }));
        break;
      case 'lab-analysis':
        this.props.dispatch(fetchLabRegisterSamplesByRound(data.id));
        break;
      case 'lab-register':
        this.props.dispatch(
          setLabRegisterParams(
            {
              ...params,
              area_round_id: data.id,
              property_round_id: null,
              limit: 30,
            },
            this.props.lab.filters
          )
        );
        break;
      case 'pipeline-files':
        this.props.dispatch(fetchPipelineFiles({ area_round_id: data.id }));
        break;
      case 'pipeline-docs':
        this.props.dispatch(fetchPipelineDocuments({ area_round_id: data.id }));
        break;
      case 'activity':
        this.props.dispatch(
          fetchActivities(
            { associate_id: data.id, associate_with: 'round', show_dependencies: true, limit: 30 },
            filters
          )
        );
        break;
      default:
        break;
    }

    if (this.props.areas.activeTab !== tab) {
      this.props.dispatch({ type: 'SET_AREAROUND_ACTIVE_TAB', payload: tab });

      // Update the URL with the tab id
      const { project_id, property_id, area_id, id } = this.props.match.params;
      this.props.history.replace(
        `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${id}/${tab}`
      );
    }
  }

  async onSave() {
    let { data } = this.state;
    const { controls, backlink } = this.state;

    data = saveControls(controls, data);

    const success = await this.props.dispatch(updateAreaRound(data));
    if (success) this.props.history.push(backlink);
  }

  onCancel() {
    this.props.history.push(this.state.backlink);
  }

  toggleMap() {
    this.setState({
      expandMap: !this.state.expandMap,
    });
  }

  toggleMiniMap() {
    this.setState({
      showMiniMap: !this.state.showMiniMap,
    });
  }

  validatePipeline(pipeline) {
    if (pipeline) return pipeline;

    return {
      subsampling: {},
      lab: {},
      carboncalcs: {},
    };
  }

  async clearPipelines() {
    const { data } = this.state;

    const confirmed = window.confirm(
      `Clearing pipelines and removing related documents permanently for this round. Continue?`
    );
    if (confirmed) {
      await this.props.dispatch(clearPipelines({ area_round_id: data.id }));
      this.props.dispatch(fetchPipelines({ area_round_id: data.id }));
    }
  }

  async openSiteUpload() {
    // Open SiteUploadModal with response.
    this.setState({
      sitesUploadIsOpen: true,
    });
  }

  async handleSiteUpload(files, onProgressChange, site_script_id) {
    if (files.length === 0) return false;

    const file = files[0];
    const round_id = this.state.data.id;

    const formData = new FormData();
    formData.append('geom', file);
    formData.append('round_id', round_id);
    formData.append('site_script_id', site_script_id);

    const success = await this.props.dispatch(uploadSites(formData, onProgressChange));
    if (success) {
      this.setState({
        sitesUploadIsOpen: false,
      });
      this.props.dispatch(fetchAreaRoundStratas(round_id));
      this.props.dispatch(fetchSites({ round_id }));
      const data = await this.props.dispatch(fetchAreaRound(round_id));
      this.setState({
        data,
      });
    }

    return success;
  }

  async handleSiteGenerate() {
    const confirmed = window.confirm(
      `WARNING: This will overwrite all previous site information and re-generate using Carbonizer. Continue?`
    );
    if (confirmed) {
      const round_id = this.state.data.id;
      const success = await this.props.dispatch(generateSites(round_id));
      if (success) this.props.dispatch(fetchSites({ round_id }));
    }
  }

  async handleRemovePipelineDocument(document) {
    const { data } = this.state;

    const confirmed = window.confirm(
      `Removing the ${document.key.toUpperCase()} document permanently. Continue?`
    );
    if (confirmed) {
      await this.props.dispatch(removePipelineDocument(document.id));
      this.props.dispatch(fetchPipelineDocuments({ area_round_id: data.id }));
    }
  }

  render() {
    const { controls, data, expandMap, showMiniMap, sitesUploadIsOpen } = this.state;

    const {
      stratas,
      strataResponseMessage,
      siteResponseMessage,
      labAnalysisResponseMessage,
      responseMessage,
      activeTab,
    } = this.props.areas;

    const { pipelines, documents } = this.props.pipelines;

    const { filters } = this.props.sites;

    const pipeline = {};
    const currentPipelines = pipelines.filter(
      (currentPipeline) =>
        currentPipeline.area_rounds?.length > 0 &&
        currentPipeline.area_rounds[0].area_round_id === data.id
    );
    currentPipelines.map((currentPipeline) => {
      pipeline[currentPipeline.key] = currentPipeline;
      return false;
    });

    const subsample_type = data?.property_round?.subsample_type;

    let subtitle;
    switch (subsample_type) {
      case 'area_round':
        subtitle = 'CEA';
        break;
      case 'property_round':
        subtitle = 'Round';
        break;
      default:
        subtitle = '-';
        break;
    }

    const tabDisabled = subsample_type !== 'area_round';

    return (
      <div>
        <SiteUploadModal
          isOpen={sitesUploadIsOpen}
          onSave={this.handleSiteUpload}
          setModal={(toggle) => {
            this.setState({ sitesUploadIsOpen: toggle });
          }}
        />
        {!this.state.expandMap && (
          <div className="p-3">
            <AreaRoundTitle
              data={data}
              subtitle={subtitle}
              strataResponseMessage={strataResponseMessage}
              siteResponseMessage={siteResponseMessage}
              labAnalysisResponseMessage={labAnalysisResponseMessage}
              toggleMiniMap={this.toggleMiniMap}
              showMiniMap={showMiniMap}
              openSiteUpload={this.openSiteUpload}
              handleSiteGenerate={this.handleSiteGenerate}
              uploads={this.uploads}
              downloads={this.downloads}
              filters={filters}
              pipelinesDisabled={tabDisabled}
              clearPipelines={this.clearPipelines}
              checkAccess={this.checkAccess}
            />

            <BreadcrumbsRoute match={this.props.match} />

            {this.state.showMiniMap && (
              <Card className="border rounded mt-2" style={{ minHeight: '300px' }}>
                <AreaRoundMap
                  expandMap={expandMap}
                  toggleMap={this.toggleMap}
                  toggleChart={this.toggleChart}
                />
              </Card>
            )}

            <ResponseMessage responseMessage={responseMessage} />

            <AreaRoundTabs
              pipeline={pipeline}
              activeTab={activeTab}
              toggleTab={this.toggleTab}
              subsampleTabDisabled={tabDisabled}
            />

            <TabContent activeTab={activeTab} className="border-bottom border-primary">
              <TabPane tabId="profile" className="p-2">
                <AreaRoundProfile
                  handleChange={this.handleChange}
                  controls={controls}
                  onSave={this.onSave}
                  onCancel={this.onCancel}
                  checkAccess={this.checkAccess}
                />
              </TabPane>

              <TabPane tabId="stratas">
                {activeTab === 'stratas' && (
                  <AreaRoundStratasLsv
                    match={this.props.match}
                    history={this.props.history}
                    rows={stratas || []}
                  />
                )}
              </TabPane>

              <TabPane tabId="sites" className="mb-2 p-3">
                {activeTab === 'sites' && (
                  <AreaRoundPanelSites
                    round_id={data.id}
                    match={this.props.match}
                    history={this.props.history}
                  />
                )}
              </TabPane>

              <TabPane tabId="sub-sample-selection" className="mb-2 p-3">
                {activeTab === 'sub-sample-selection' && (
                  <PipelinePanelSubsampling
                    pipeline={pipeline}
                    executePipelineTag="roundExecuteSubsampling"
                  />
                )}
              </TabPane>

              <TabPane tabId="lab-analysis" className="mb-2 p-3">
                {activeTab === 'lab-analysis' && <AreaRoundPanelLab round={data} />}
              </TabPane>

              <TabPane tabId="lab-register" className="mb-2 p-3">
                {activeTab === 'lab-register' && (
                  <LabRegisterPanel history={this.props.history} area_round_id={data.id} />
                )}
              </TabPane>

              <TabPane tabId="pipelines" className="mb-2 p-3">
                {activeTab === 'pipelines' && <PipelinesPanel pipeline={pipeline} />}
              </TabPane>

              <TabPane tabId="pipeline-files" className="mb-2 p-3">
                {activeTab === 'pipeline-files' && <PipelinePanelFiles />}
              </TabPane>

              <TabPane tabId="pipeline-docs" className="mb-2 p-3">
                {activeTab === 'pipeline-docs' && (
                  <PipelineDocsLsv
                    checkAccess={this.checkAccess}
                    onRemove={this.handleRemovePipelineDocument}
                    rows={documents || []}
                    handleDownload={async (id, event) =>
                      this.props.dispatch(downloadPipelineDocument(id, event))
                    }
                  />
                )}
              </TabPane>

              <TabPane tabId="activity" className="mb-2 p-3">
                {activeTab === 'activity' && <AreaRoundPanelActivities round_id={data.id} />}
              </TabPane>
            </TabContent>
          </div>
        )}

        {this.state.expandMap && <AreaRoundMap expandMap={expandMap} toggleMap={this.toggleMap} />}
      </div>
    );
  }
}

const mapStoreToProps = (store) => ({
  areas: store.areas,
  pipelines: store.pipelines,
  sites: store.sites,
  periods: store.periods,
  activities: store.activities,
  properties: store.properties,
  realm: store.realm,
  lab: store.lab,
});

export default connect(mapStoreToProps)(AreaRound);
