import { controlDefaults } from "../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  table_name: {
    ...controlDefaults,
    name: 'table_name',
    type: 'select',
    caption: 'Table Name',
    options: [
      { id: 'orgs', name: 'Organisations' },
      { id: 'users', name: 'Users' },
      { id: 'lookup_costcodes', name: 'Cost Codes' },
    ],
    disabled: false,
    validationRules: {
      isRequired: true
    },
  },
  owner_id: {
    ...controlDefaults,
    name: 'owner_id',
    type: 'select',
    caption: 'Owner',
    disabled: false,
    options: [],
    validationRules: {
      isRequired: true
    },
  },
  definition: {
    ...controlDefaults,
    name: 'definition',
    type: 'json',
    caption: 'Definition',
    disabled: false,
    validationRules: {
      isRequired: true,
      isArray: true
    },
  }
}