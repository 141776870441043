import React from 'react';

const ResponseMessageTab = (props) => {
  const { responseMessage = null, colour = 'text-danger', className = '' } = props;
  const classNames = `p-2 ${colour} text-center ${className}`;

  return (
    <>
      {responseMessage && (
        <div className={classNames} data-testid="response-message-tab">
          {responseMessage}
        </div>
      )}
    </>
  );
};

export default ResponseMessageTab;
