import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Row,
  Col,
  Label,
} from 'reactstrap';

import { controls } from '../forms/propertySearchMigrate';
import ProjectNewModal from './ProjectNewModal';
import ProjectPropertyNew from './ProjectPropertyNew';

import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import Icon from 'jsx/components/core/icons/Icon';

import { fetchProjects } from '../actions/projects';
import { fetchProperties } from '../actions/properties';
import { fetchPropertyLots, uploadLotGeom } from '../actions/property_lots';

import { buildFeatureCollection, buildFeature } from '../lib/mapster.js';

class PropertySearchMigrateModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Migrate Lots to Project',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls,
      project_id: null,
      isProjectModalOpen: false,
      isPropertyModalOpen: false,
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handlePropertyChange = this.handlePropertyChange.bind(this);
    this.setProjectModal = this.setProjectModal.bind(this);
    this.setPropertyModal = this.setPropertyModal.bind(this);
  }

  async componentDidMount() {
    const { controls } = this.state;

    if (this.props.project_id && this.props.property_id) {
      controls.project_id.value = this.props.project_id;
      controls.property_id.value = this.props.property_id;

      const params = { project_id: controls.project_id.value };

      const { rows = [] } = await this.props.dispatch(fetchProperties(params));
      controls.property_id.options = rows;

      this.setState({ controls });
    }
  }

  async componentDidUpdate() {
    const { isOpen, id, isNew, title, controls } = this.state;

    const { currentProject } = this.props.projects;

    const { currentProperty, fetching } = this.props.properties;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      this.props.dispatch(fetchProjects());

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        property_id: this.props.property_id,
        setModal: this.props.setModal,
      });
    }

    // Set projectID to currentProject if project was just created
    if (
      !fetching &&
      currentProject &&
      currentProject.id &&
      controls &&
      controls.project_id &&
      currentProject.id !== controls.project_id.value
    ) {
      controls.project_id.value = currentProject.id;

      const params = { project_id: currentProject.id };
      const properties = await this.props.dispatch(fetchProperties(params));
      controls.property_id.options = properties?.rows ?? [];

      this.setState({ controls });
    }

    // Set propertyid if property was just created
    if (
      !fetching &&
      currentProperty &&
      currentProperty.id &&
      controls &&
      controls.property_id &&
      currentProperty.id !== controls.property_id.value
    ) {
      controls.property_id.value = currentProperty.id;

      const params = { project_id: currentProperty.project_id };
      const properties = await this.props.dispatch(fetchProperties(params));
      controls.property_id.options = properties?.rows ?? [];

      const lots = await this.props.dispatch(fetchPropertyLots(controls.property_id.value));
      if (lots.length > 0) {
        controls.property_id.message = `This property already have ${lots.length} lots associated with it.`;
        controls.property_id.isValid = false;
      } else {
        controls.property_id.description = `This property have ${lots.length} properties associated with it. Good to go.`;
      }

      this.setState({ controls });
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_PROJECT' });
    this.props.dispatch({ type: 'UNSET_PROPERTY' });
  }

  setProjectModal(isProjectModalOpen) {
    this.setState({ isProjectModalOpen });
  }

  setPropertyModal(isPropertyModalOpen) {
    this.setState({ isPropertyModalOpen });
  }

  async onSave() {
    const { controls } = this.state;

    const property_id = controls.property_id.value;
    const project_id = controls.project_id.value;
    const properties = this.props.selected;
    const lnk = `/home/projects/${project_id}/properties/${property_id}`;

    // Check for existing lots
    const lots = await this.props.dispatch(fetchPropertyLots(property_id));
    if (lots.length > 0) {
      const ok = window.confirm(
        `This property already have ${lots.length} existing lots associated with it. These lots will be replaced with current selection of ${properties.length} lots. Continue?`
      );
      if (!ok) return;
    }

    const featureCollection = buildFeatureCollection();

    properties.forEach((property) => {
      if (property.geometry) {
        const feature = buildFeature(property.geometry, property.properties);
        featureCollection.features.push(feature);
      }
    });

    const data = { property_id: property_id, geom: featureCollection };

    // Upload lots for property
    const success = await this.props.dispatch(uploadLotGeom(data));
    if (success) {
      controls.project_id.value = null;
      controls.property_id.value = null;

      this.state.setModal(false, true);
      this.setState({ controls, isOpen: false });

      this.props.history.push(lnk);
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async handleProjectChange(event) {
    const { controls } = this.state;

    // Record change
    this.handleChange(event);

    // If selected
    if (event.target.value) {
      const project_id = event.target.value;

      // Get the properties for project
      const params = { project_id };
      const properties = await this.props.dispatch(fetchProperties(params));
      controls.property_id.options = properties?.rows ?? [];
      controls.property_id.value = properties?.rows[0]?.id ?? null;

      this.setState({ controls, project_id });
    }
  }

  async handlePropertyChange(event) {
    const { controls } = this.state;

    // Record change
    this.handleChange(event);

    // If selected
    if (event.target.value) {
      const properties = controls.property_id.options;
      const currentProperty = properties.find((property) => property.id === event.target.value);

      this.props.dispatch({ type: 'FETCH_PROPERTY_PENDING' });
      this.props.dispatch({ type: 'FETCH_PROPERTY_FULFILLED', payload: currentProperty });
    }
  }

  render() {
    const { isOpen, title, isProjectModalOpen, isPropertyModalOpen } = this.state;
    const { responseMessage } = this.props.properties;
    const { rows: projects } = this.props.projects.projects;

    if (projects && projects.length > 0) {
      const options = [{ id: '', name: 'Select Project' }];
      controls.project_id.options = options.concat(projects);
    }

    // Need to have project_id, property_id and some lots selected
    const saveDisabled =
      !controls.project_id.value || !controls.property_id.value || this.props.selected.length === 0;

    const iconName = 'diagram-project';
    return (
      <React.Fragment>
        <Modal isOpen={isOpen}>
          <ModalHeader className="bg-corporate text-white">
            <Icon size="1x" name={iconName} className="mr-2" />
            {title}
          </ModalHeader>
          <ModalBody>
            {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
            <Form>
              <Row>
                <Col sm={7}>
                  <FormInput
                    handleChange={this.handleProjectChange}
                    control={controls.project_id}
                  />
                </Col>
                <Col sm={4} className="d-flex align-items-end m-1">
                  <Label className="m-1 mr-3">OR</Label>
                  <Button
                    size="sm"
                    className="mr-2"
                    color="primary"
                    onClick={() => this.setProjectModal(true)}
                  >
                    Create New Project
                  </Button>
                </Col>
              </Row>
              {controls.project_id.value && (
                <Row>
                  <Col sm={7}>
                    <FormInput
                      handleChange={this.handlePropertyChange}
                      control={controls.property_id}
                      disabled={controls.property_id.options.length === 0}
                    />
                  </Col>
                  <Col sm={4} className="d-flex align-items-end m-1">
                    <Label className="m-1 mr-3">OR</Label>
                    <Button
                      size="sm"
                      className="mr-2"
                      color="primary"
                      onClick={() => this.setPropertyModal(true)}
                    >
                      Create New Property
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
            <Button size="sm" color="success" disabled={saveDisabled} onClick={this.onSave}>
              Save to Project
            </Button>
          </ModalFooter>
        </Modal>

        <ProjectNewModal setModal={this.setProjectModal} isOpen={isProjectModalOpen} setProjectId />
        <ProjectPropertyNew
          setModal={this.setPropertyModal}
          project_id={controls.project_id.value}
          isOpen={isPropertyModalOpen}
        />
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    properties: store.properties,
    projects: store.projects,
  };
};

export default connect(mapStoreToProps)(PropertySearchMigrateModal);
