import { defaults, allowed } from '../../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    probeTemplate: {}
  },
  action,
) {
  const types = ['PROBE'];
  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_PROBE_TEMPLATE_FULFILLED': {
      return {
        ...state,
        probeTemplate: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
