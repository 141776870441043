import React, { useState } from 'react';
import Listview from '../../core/form/components/Listview';
import FormInput from '../../core/form/components/FormInput';

const AttributesLsv = (props) => {
  const { ignoreFields, owners } = props;
  let { rows } = props;

  const [changed, setChanged] = useState(false);
  const [editRows, setEditRows] = useState({});

  const renderRows = (headers, row, row_index) => {
    const tableTd = headers.map((header, index) => {
      const id = `${row_index}-${index}-${header.field}`;
      const control = {
        id,
        name: header.field,
        value: row[header.field],
      };

      switch (header.field) {
        case 'owner': {
          let name = '-';
          if (owners?.length > 0) {
            const owner = owners.find((owner) => owner.id === row.owner_id);
            if (owner) name = owner.name;
          }
          return (
            <td key={index} className={header.classes}>
              {name}
            </td>
          );
        }
        case 'type':
          return (
            <td key={index} className={header.classes}>
              {row[header.field] ? row[header.field] : 'string'}
            </td>
          );
        default: {
          let td = (
            <td key={index} className={header.classes}>
              {row[header.field]}
            </td>
          );
          if (header.editable) {
            if (editRows[id]) {
              td = (
                <td key={index} className={`header.classes editing`}>
                  {
                    <FormInput
                      autoFocus={true}
                      handleBlur={() => setEditMode(id, false)}
                      handleKeyDown={(event) => handleKeyDown(event, id)}
                      handleChange={(event) => handleChange(event, row)}
                      control={control}
                    />
                  }
                </td>
              );
            } else
              td = (
                <td
                  key={index}
                  className={`${header.classes} editable`}
                  onClick={() => setEditMode(id, true)}
                >
                  {row[header.field]}
                </td>
              );
          }
          return td;
        }
      }
    });

    return tableTd;
  };

  const handleChange = (event, row) => {
    const { name, value } = event.target;
    row[name] = value;
    setChanged(!changed);
  };

  const setEditMode = (id, value) => {
    editRows[id] = value;
    setEditRows(editRows);
    setChanged(!changed);
  };

  const handleKeyDown = (event, id) => {
    if (event.keyCode === 13) setEditMode(id, false);
  };

  let headers = [
    { caption: 'Owner', field: 'owner', classes: 'text-left' },
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Type', field: 'type', classes: 'text-left' },
    { caption: 'Value', field: 'value', classes: 'text-left w-50', editable: true },
  ];

  if (ignoreFields?.length > 0)
    headers = headers.filter((header) => !ignoreFields.includes(header.field));

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  // Filter out rows with orgs that aren't within the realm
  if (owners) {
    const owner_ids = owners.map((owner) => owner.id);
    rows = rows.filter(({ owner_id }) => owner_ids.includes(owner_id));
  }

  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      return (
        <tr key={index} style={{ cursor: 'pointer' }}>
          {renderRows(headers, row, index)}
        </tr>
      );
    });
  }

  const iconName = 'clipboard-list';
  const emptyCaption = 'No attributes found';

  return (
    <React.Fragment>
      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default AttributesLsv;
