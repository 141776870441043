import { apiDaysheets } from 'jsx/constants/api';
import { get, download } from 'jsx/lib/genericAction';
import { Dispatch } from 'redux';

export function fetchDaysheetSummaries(params: Record<string, string>) {
  return async (dispatch: Dispatch) =>
    await get(
      dispatch,
      'FETCH_DAYSHEET_SUMMARIES',
      `${apiDaysheets}/report/summaries`,
      params as unknown as null,
    );
}

export function downloadCsv(params: Record<string, string>, onProgressChange: any) {
  return async (dispatch: Dispatch) =>
    download(
      dispatch,
      'DOWNLOAD_DAYSHEET_CSV',
      `${apiDaysheets}/report/export`,
      onProgressChange,
      params as unknown as null,
    );
}
