import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  area_name: {
    ...controlDefaults,
    fieldName: 'area.name',
    name: 'area_name',
    type: 'text',
    caption: 'CEA',
    showInListview: true,
  },
  layer_no: {
    ...controlDefaults,
    fieldName: 'layers.layer_no',
    name: 'area_name',
    type: 'text',
    caption: 'Layer',
    showInListview: true,
  },
  total_area: {
    ...controlDefaults,
    fieldName: 'total_area',
    name: 'total_area',
    type: 'number',
    caption: 'Area (ha)',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  baseline_round: {
    ...controlDefaults,
    group: 'baseline',
    fieldName: 'baseline_round.name',
    name: 'baseline_round',
    type: 'text',
    caption: 'Round',
    classes: 'text-center border-left',
    showInListview: true,
  },
  baseline_count_sites: {
    ...controlDefaults,
    group: 'baseline',
    fieldName: 'baseline_round.count_sites',
    name: 'baseline_count_sites',
    type: 'number',
    caption: 'Sites (#)',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
    },
  },
  baseline_avg_sample_date: {
    ...controlDefaults,
    group: 'baseline',
    fieldName: 'baseline_round.avg_sample_date',
    name: 'baseline_avg_sample_date',
    type: 'date',
    caption: 'Avg Sample Date',
    classes: 'text-center',
    showInListview: true,
  },
  baseline_avg_whole_soc: {
    ...controlDefaults,
    group: 'baseline',
    fieldName: 'layers.baseline.avg_whole_soc',
    name: 'baseline_avg_whole_soc',
    type: 'number',
    caption: 'Avg SOC (%)',
    classes: 'text-center',
    showInListview: true,
    formattingRules: {
      asPercentage: true,
      includeDecimals: true,
    },
  },
  property_round_name: {
    ...controlDefaults,
    group: 'compare',
    fieldName: 'property_round.name',
    name: 'property_round_name',
    type: 'text',
    caption: 'Round',
    classes: 'text-center border-left',
    showInListview: true,
  },
  compare_count_sites: {
    ...controlDefaults,
    group: 'compare',
    fieldName: 'count_sites',
    name: 'count_sites',
    type: 'number',
    caption: 'Sites (#)',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
    },
  },
  property_round_avg_sample_date: {
    ...controlDefaults,
    group: 'compare',
    fieldName: 'avg_sample_date',
    name: 'property_round_avg_sample_date',
    type: 'date',
    caption: 'Avg Sample Date',
    classes: 'text-center',
    showInListview: true,
  },
  property_round_avg_whole_soc: {
    ...controlDefaults,
    group: 'compare',
    fieldName: 'layers.results.avg_whole_soc',
    name: 'property_round_avg_whole_soc',
    type: 'number',
    caption: 'Avg SOC (%)',
    classes: 'text-center',
    showInListview: true,
    formattingRules: {
      asPercentage: true,
      includeDecimals: true,
    },
  },
  round_duration: {
    ...controlDefaults,
    fieldName: 'layers.compare.duration_round',
    name: 'round_duration',
    type: 'number',
    caption: 'Duration (y)',
    showInListview: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  layer_lower_depth: {
    ...controlDefaults,
    fieldName: 'layers.lower_depth',
    name: 'layer_lower_depth',
    type: 'number',
    caption: 'Target Field Depth (cm)',
    showInListview: true,
  },
  mass_depth: {
    ...controlDefaults,
    fieldName: 'layers.mass_depth',
    name: 'mass_depth',
    type: 'number',
    caption: 'ESM (t/Soil)',
    showInListview: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  avg_delta_whole_soc: {
    ...controlDefaults,
    fieldName: 'layers.compare.avg_delta_whole_soc',
    name: 'avg_delta_whole_soc',
    type: 'number',
    caption: 'Avg Change SOC (%)',
    classes: 'text-center',
    showInListview: true,
    formattingRules: {
      asPercentage: true,
      includeDecimals: true,
    },
  },
  delta_whole_carbon_mass: {
    ...controlDefaults,
    fieldName: 'layers.compare.delta_whole_carbon_mass',
    name: 'delta_whole_carbon_mass',
    type: 'number',
    classes: 'text-center',
    caption: 'Mean Stock Change (tC)',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  ste_whole_carbon_mass: {
    ...controlDefaults,
    fieldName: 'layers.compare.ste_whole_carbon_mass',
    name: 'ste_whole_carbon_mass',
    type: 'number',
    caption: 'Std Error (tC)',
    showInListview: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  delta_60_whole_carbon_mass: {
    ...controlDefaults,
    fieldName: 'layers.compare.delta_60_whole_carbon_mass',
    name: 'delta_whole_carbon_mass',
    type: 'number',
    classes: 'text-center',
    caption: 'Creditable Stock Change - 60% PoE (tC)',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  sequestration_rate: {
    ...controlDefaults,
    fieldName: 'layers.compare.sequestration_rate',
    name: 'sequestration_rate',
    type: 'number',
    caption: 'Creditable Seq. Rate (tC/ha/yr)',
    showInListview: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  temporary_discount: {
    ...controlDefaults,
    fieldName: 'layers.compare.temporary_discount',
    name: 'temporary_discount',
    type: 'number',
    caption: 'Temp. Discount Applied (%)',
    classes: 'text-center',
    showInListview: true,
    formattingRules: {
      asPercentage: true,
    },
  },
  project_emissions: {
    ...controlDefaults,
    fieldName: 'layers.compare.project_emissions',
    name: 'project_emissions',
    type: 'number',
    classes: 'text-center',
    caption: 'Project Emissions (tCO2e)',
    showInListview: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  gross_abatement: {
    ...controlDefaults,
    group: 'abatement',
    fieldName: 'layers.compare.gross_abatement_whole_carbon_mass',
    name: 'gross_abatement',
    type: 'number',
    classes: 'border-left text-center',
    caption: 'Gross (tCO2e)',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  net_abatement: {
    ...controlDefaults,
    group: 'abatement',
    fieldName: 'layers.compare.net_abatement',
    name: 'net_abatement',
    type: 'number',
    classes: 'text-center',
    caption: 'Net (tCO2e)',
    showInListview: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  accus_whole_carbon_mass: {
    ...controlDefaults,
    group: 'abatement',
    fieldName: 'layers.compare.accus',
    name: 'accus_whole_carbon_mass',
    type: 'number',
    classes: 'text-center',
    caption: 'Disc. Net (tCO2e)',
    showInListview: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
};
