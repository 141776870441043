import React from 'react';
import { connect } from "react-redux";
import {
  Button,
  Nav,
  TabContent,
  TabPane
} from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormTab from "../../../core/form/components/FormTab";
import { validateChange } from "../../../core/form/lib/validateForm";
import { controls } from '../../projects/forms/projectArea';
import ProjectAreaProfile from "../../projects/components/projects/ProjectAreaProfile";

class ProjectArea extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      errorMessage: null,
      activeTab: '1',
      formChanged: false,
      controls: controls,
      data :{}
    })

    this.handleChange = this.handleChange.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  handleChange(event){
    const controls = validateChange(event, this.state.controls)
    this.setState({
      controls: controls,
      formChanged: true
    });
  }

  render() {
    const {
      controls
    } = this.state;

    const iconName = 'layer-group';

    return (
      <div>
        <div className="p-4">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row">
              <Icon size="2x" name={iconName} className="appForeTint mr-3" />
              <h3>Lot 1</h3>
            </div>
            <div className="">
              <Button className="" size="sm" color="success">Save Changes</Button>
              <Button className="" size="sm" color="light">Cancel</Button>
            </div>
          </div>
          <p>Strata's under an area can have multiple versions for any given Sampling Round.</p>
          <Nav tabs className="mt-2">
            <FormTab caption='Profile' tabId='1' activeTab={this.state.activeTab} toggle={this.toggleTab} />
            <FormTab caption='Strata Versions' tabId='2' activeTab={this.state.activeTab} toggle={this.toggleTab} />
            <FormTab caption='Sampling Rounds' tabId='2' activeTab={this.state.activeTab} toggle={this.toggleTab} />
          </Nav>

          <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
            <TabPane tabId="1" className="mb-2 p-3">
              <ProjectAreaProfile controls={controls} handleChange={this.handleChange}/>
            </TabPane>
             <TabPane tabId="2" className="mb-2 p-3">
              <div className="text-center p-3">Placeholder - TODO</div>
            </TabPane>
          </TabContent>
        </div>
      </div>

    )
  }
}

const mapStoreToProps = (store) => {
  return {
    stages: store.stages
  }
}

export default connect(mapStoreToProps)(ProjectArea);
