/* Notes on these formatters.
 * We already have value formatters to display values to users in a few places in the UI. These could be merged
 * for consistency with further refactoring. Existing examples are:
 * src/jsx/components/core/form/lib/metricFormat.ts - focuses on displaying the value of a metric, which includes suffix/prefix handling
 * src/jsx/components/core/form/lib/fieldFormat.js - Uses our form API to format values for display in an LSV / form
 */

type NumberFormatter = (value: number) => string;

export const moneyFormatter: NumberFormatter = (value) =>
  Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'AUD',
    currencyDisplay: 'narrowSymbol',
  }).format(value);

export const decimalNumberFormatter: NumberFormatter = (value) =>
  value === 0
    ? '0'
    : Intl.NumberFormat('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);

export const wholeNumberFormatter: NumberFormatter = (value) =>
  Intl.NumberFormat('en', { maximumFractionDigits: 0 }).format(value);
