/* eslint-disable no-alert */
import { connect } from 'react-redux';
import { cloneDeep, isNil } from 'lodash';
import { Alert } from 'reactstrap';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import { fetchProperties } from 'jsx/components/modules/projects/actions/properties';
import { controls as controlsTemplate } from '../forms/analysis_group_members';
import FormBase from '../../core/form/components/FormBase';
import { fetchAnalysisGroups, setAnalysisGroupMemberParams } from '../actions/analysis_groups';
import { fetchOrgs } from '../actions';
import {
  createAnalysisGroupMember,
  removeAnalysisGroupMember,
} from '../actions/analysis_group_members';
import MembersToolbar from '../components/analysis-groups/MembersToolbar';
import MembersSearchBar from '../components/analysis-groups/MembersSearchBar';

class AnalysisGroupMembers extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(controlsTemplate),
      analysisGroup: null,
      org: null,
      property: null,
      searchValue: '',
    };
  }

  async componentDidMount() {
    await this.props.dispatch(fetchAnalysisGroups());
    await this.props.dispatch(fetchOrgs());
    await this.props.dispatch(fetchProperties());
  }

  onAddMember = async () => {
    const { analysisGroup, property } = this.state;
    if (!analysisGroup?.value || !property?.value) return;

    const payload = {
      analysis_group_id: analysisGroup.value,
      property_id: property.value,
    };
    const success = await this.props.dispatch(createAnalysisGroupMember(payload));
    if (success) this.props.onRefresh();
  };

  onAnalysisGroupChange = (analysisGroup) => {
    this.setState({ analysisGroup });
  };

  onOrgChange = (org) => {
    this.setState({ org, property: null });
  };

  onPropertyChange = (property) => {
    this.setState({ property });
  };

  onSearchChange = () => {
    const { searchValue } = this.state;
    this.props.dispatch(setAnalysisGroupMemberParams({ search_value: searchValue }));
  };

  handleSearchChange = (event) => {
    // Update search value
    const { value: searchValue } = event.target;
    this.setState({ searchValue });

    const enterButtonCode = 13;
    if (event.keyCode === enterButtonCode) this.onSearchChange();
  };

  onAlertDismiss = () =>
    this.props.dispatch({ type: 'SET_ANALYSIS_GROUP_MEMBER_RESPONSE_MESSAGE', payload: null });

  onRemove = async (id) => {
    const { members } = this.props.analysis_groups;
    const { group, property } = members.rows.find(({ id: memberId }) => memberId === id);
    const confirmed = window.confirm(
      `Removing ${property.name} from ${group.name} permanently. Continue?`,
    );
    if (!confirmed) return;

    const success = await this.props.dispatch(removeAnalysisGroupMember(id));
    if (success) this.props.onRefresh();
  };

  render() {
    const { analysisGroup, org, property, searchValue, controls } = this.state;
    const { members, responseMessage } = this.props.analysis_groups;

    return (
      <>
        <div className="mt-4 ml-2">
          <Alert
            isOpen={!isNil(responseMessage)}
            color="danger"
            className="py-4 text-center"
            toggle={this.onAlertDismiss}
          >
            <strong className="align-middle">{responseMessage}</strong>
          </Alert>
          <MembersToolbar
            onAddMember={this.onAddMember}
            onAnalysisGroupChange={this.onAnalysisGroupChange}
            onOrgChange={this.onOrgChange}
            onPropertyChange={this.onPropertyChange}
            selectedAnalysisGroup={analysisGroup}
            selectedOrg={org}
            selectedProperty={property}
          />
        </div>
        <div className="border-corporate border-top mt-5" />
        <div className="mt-4">
          <MembersSearchBar
            members={members}
            searchValue={searchValue}
            onChange={this.onSearchChange}
            handleSearchChange={this.handleSearchChange}
          />
          <GenericLsv
            actions={[
              { func: (id) => this.onRemove(id), iconName: 'trash', classes: 'text-danger mr-1' },
            ]}
            controls={controls}
            emptyCaption="No Member associations found"
            iconName="people-group"
            rows={members.rows}
          />
        </div>
      </>
    );
  }
}

const mapStoreToProps = ({ analysis_groups, manage, properties }) => ({
  analysis_groups,
  manage,
  properties,
});

export default connect(mapStoreToProps)(AnalysisGroupMembers);
