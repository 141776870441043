import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import {
  updateControls,
  initControls,
  saveControls,
  validateChange,
} from '../../../core/form/lib/validateForm';

import StageRequirementProfile from '../components/stages/StageRequirementProfile';
import ActivitiesLsv from '../../../core/activity/components/ActivitiesLsv';

import { controls } from '../forms/projectStageRequirements';
import Downloads from '../lib/downloads';

import {
  fetchRequirementStatuses,
  createRequirement,
  updateRequirement,
  fetchRequirement,
  deleteRequirement,
  fetchRequirementItems,
} from '../actions/stages';

import { fetchActivities } from '../../../core/activity/actions';
import { DEFAULT_PAGINATION } from '../constants';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
class StageRequirement extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      activeTab: '1',
      formChanged: false,
      controls: controls,
      backlink: null,
      isNew: false,
      data: {},
      stage: {},
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);

    this.toggleTab = this.toggleTab.bind(this);
    this.loadMoreActivities = this.loadMoreActivities.bind(this);
  }

  async componentDidMount() {
    let { controls, isNew, data, stage } = this.state;

    this.downloads = new Downloads();
    this.downloads.set(this.props.dispatch);

    this.setState({ controls: initControls(this.state.controls) });
    this.props.dispatch(fetchRequirementStatuses());

    if (this.props.match.params.id === 'new') {
      isNew = true;
    } else {
      isNew = false;
      this.props.dispatch(fetchRequirementItems({ requirement_id: this.props.match.params.id }));
      data = await this.props.dispatch(fetchRequirement(this.props.match.params.id));
      controls = updateControls(controls, data);
    }

    this.setState({
      data,
      isNew,
      stage,
      controls,
    });
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_REQUIREMENT_ITEMS' });
  }

  toggleTab(tab, tag) {
    switch (tag) {
      case 'activity':
        this.props.dispatch(
          fetchActivities({
            associate_with: 'project_stage_requirement',
            associate_id: this.props.match.params.id,
            show_dependencies: true,
            limit: DEFAULT_PAGINATION,
          })
        );
        break;
      default:
        break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  loadMoreActivities() {
    const { params } = this.props.activities;

    let new_params = {
      ...params,
      limit: params.limit + DEFAULT_PAGINATION,
    };

    this.props.dispatch(fetchActivities(new_params));
  }

  async onSave() {
    let { data, controls, isNew } = this.state;

    data = saveControls(controls, data);

    let success;
    if (isNew) {
      delete data.id;
      data.stage_id = this.props.match.params.stage_id;
      success = await this.props.dispatch(createRequirement(data));
    } else {
      success = await this.props.dispatch(updateRequirement(data));
    }

    if (success) {
      this.props.history.goBack();
    }
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing the Requirement for this stage. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(deleteRequirement(data.id));
      if (success) {
        this.props.history.goBack();
      }
    }
  }

  onClose() {
    this.props.history.goBack();
  }

  handleChange(event) {
    const controls = validateChange(event, this.state.controls);
    this.setState({
      controls: controls,
      formChanged: true,
    });
  }

  render() {
    const { controls, isNew, data } = this.state;

    const { requirementMessage, requirementStatuses, requirementItems, unlockTemplate } =
      this.props.stages;

    const { activities } = this.props.activities;

    const iconName = 'layer-group';
    const stageName = data.stage ? data.stage.name : '...';

    return (
      <div>
        <div className="p-4">
          <div className="d-flex flex-row ">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Stage: {stageName}</h3>
          </div>
          <p>{controls.name.value}</p>
          <Nav tabs className="mt-2">
            <FormTab
              caption="Requirements"
              tabId="1"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
            />
            <FormTab
              caption="Activity"
              tabTag="activity"
              tabId="2"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
              disabled={isNew}
            />
          </Nav>

          <TabContent activeTab={this.state.activeTab} className="">
            <TabPane tabId="1" className="mb-2">
              <StageRequirementProfile
                className="text-center p-3"
                controls={controls}
                handleChange={this.handleChange}
                handleDownload={(id, event) =>
                  this.downloads.handleStageRequirementItemDownload(id, event)
                }
                onSave={this.onSave}
                onRemove={this.onRemove}
                onClose={this.onClose}
                responseMessage={requirementMessage}
                statuses={requirementStatuses}
                isNew={isNew}
                requirement_id={data.id}
                rows={requirementItems}
                unlockTemplate={unlockTemplate}
              />
            </TabPane>
            <TabPane tabId="2" className="mb-2 p-1">
              <ActivitiesLsv
                rows={activities || []}
                pagination={true}
                onLoadMore={this.loadMoreActivities}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    activities: store.activities,
    stages: store.stages,
  };
};

export default connect(mapStoreToProps)(withContainerError(StageRequirement));
