import React from 'react';
import Listview from "../../../../core/form/components/Listview";

const ProjectOverviewContactsLsv = (props) => {
  const {
    rows,
    selectSites
  } = props;

  const renderRows = (headers, row) => {
    let iconColour = "mr-3 "

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        default: return  (
          <td className={`${iconColour} ${header.classes}`} key={index}>{row[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const iconName = 'users';
  const emptyCaption = "No Contacts found. Ensure contacts are associated with the company in HubSpot CRM.";

  const headers = [
    {caption: 'Name', field: 'name', classes:"text-left"},
    {caption: 'Mobile', field: 'mobilephone', classes:"text-left"},
    {caption: 'Phone', field: 'phone', classes:"text-right"},
    {caption: 'Role', field: 'jobtitle', classes:"text-right"}
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => {selectSites(row.sites)}} style={{cursor: "pointer"}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  return (
    <div >
      {rows.length > 0 && (
        <div className="p-2">Add and associate contacts using <a rel="noopener noreferrer" target="_blank" href="https://hubspot.com">HubSpot CRM</a></div>
      )}
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </div>
  )
}

export default ProjectOverviewContactsLsv;
