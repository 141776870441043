import { downloadProjectStatusMap } from '../actions/projects';

import { downloadPropertyMapPack, downloadPropertyReportingPack } from '../actions/properties';

import {
  downloadPropertyRoundComparison,
  downloadPropertyRoundReportingPack,
  downloadPropertyRoundLocationsPack,
  downloadPropertyRoundSites,
  downloadPropertyRoundSelectionScans,
} from '../actions/property_rounds';

import { downloadStageRequirementItem } from '../actions/stages';

export default class Downloads {
  set(dispatch) {
    this.dispatch = dispatch;
  }

  async handleProjectStatusMapDownload(onProgressChange, showPoints) {
    return this.dispatch(downloadProjectStatusMap(onProgressChange, { show_points: showPoints }));
  }

  async handleRoundComparisonDownload(onProgressChange, params) {
    return this.dispatch(downloadPropertyRoundComparison(onProgressChange, params));
  }

  async handlePropertyMapPackDownload(params) {
    return this.dispatch(downloadPropertyMapPack(params));
  }

  async handlePropertyReportingPackDownload(params) {
    return this.dispatch(downloadPropertyReportingPack(params));
  }

  async handlePropertyRoundLocationsPackDownload(property_round_id, onProgressChange) {
    return this.dispatch(downloadPropertyRoundLocationsPack(property_round_id, onProgressChange));
  }

  async handlePropertyRoundReportingPackDownload(property_round_id, onProgressChange) {
    return this.dispatch(downloadPropertyRoundReportingPack(property_round_id, onProgressChange));
  }

  async handlePropertyRoundSitesDownload(property_round_id, onProgressChange) {
    return this.dispatch(downloadPropertyRoundSites(property_round_id, onProgressChange));
  }

  async handlePropertyRoundSelectionScansDownload(property_round_id, onProgressChange) {
    return this.dispatch(downloadPropertyRoundSelectionScans(property_round_id, onProgressChange));
  }

  async handleStageRequirementItemDownload(item_id, onProgressChange) {
    return this.dispatch(downloadStageRequirementItem(item_id, onProgressChange));
  }
}
