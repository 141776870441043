import React from 'react';
import Listview from "../../../core/form/components/Listview";
import Icon from 'jsx/components/core/icons/Icon';

import {
  Input,
  Button
} from 'reactstrap';

import ConfigControlDatasets from './ConfigControlDatasets';
import ConfigControlColumns from './ConfigControlColumns';

const HorizontalChartConfigLsv = (props) => {
  let {
    widget,
    availableDatasets,
    onChangeWidgetDataset,
    onUpdateWidgetDataset,
    onAddWidgetDataset
  } = props;

  // Render Rows
  const renderRows = (headers, dataset) => {
    let rowColour = "text-success mr-3"

    // Set dataset control
    const datasetControl = ( 
      <ConfigControlDatasets
        availableDatasets={availableDatasets}
        onChangeWidgetDataset={onChangeWidgetDataset}
        dataset={dataset} 
      />
    );
    
    // Set Columns controls
    const columnControl = (
      <ConfigControlColumns 
        name="selected"
        onUpdateWidgetDataset={onUpdateWidgetDataset}
        availableDatasets={availableDatasets}
        dataset={dataset}
      />
    );

    // Load series control
    const seriesControl = (
      <Input name="series" onChange={(e) => onUpdateWidgetDataset(e, dataset)} type="select" bsSize="sm" style={{width: '100%'}} value={dataset.series}>
        <option value='left'>Left</option>
        <option value='right'>Right</option>
      </Input>
    );
  
    // Load series control
    const typeControl = (
      <Input name="type" onChange={(e) => onUpdateWidgetDataset(e, dataset)} type="select" bsSize="sm" style={{width: '100%'}} value={dataset.type}>
        <option value='bar'>Bar</option>
        <option value='line'>Line</option>
        <option value='stack'>Stack</option>
      </Input>
    );

    // Set colour combo
    const colourControl = (
      <Input name="colour"
        onChange={(e) => onUpdateWidgetDataset(e, dataset)}
        value={dataset.colour}
        type="color"
        bsSize="sm"
        style={{width: '100%'}}
      />
    )

    const tableTd = headers.map((header, index) => {
      const classes = `${rowColour} ${header.classes}`;

      switch (header.field) {
        case 'datasets': return  (
          <td key={index} className={classes}>{datasetControl}</td>
        )
        case 'column': return  (
          <td key={index} className={classes}>{columnControl}</td>
        )
        case 'series': return  (
          <td key={index} className={classes}>{seriesControl}</td>
        )
        case 'colour': return  (
          <td key={index} className={classes}>{colourControl}</td>
        )
        case 'type': return  (
          <td key={index} className={classes}>{typeControl}</td>
        )
        default: return  (
          <td key={index} className={classes}>{dataset[header.field]} asdf</td>
        )
      }
    });

    tableTd.push(
      <td key={headers.length + 1} className="d-flex justify-content-center" >
        <Icon name="trash" className="text-secondary mt-1 mr-2" />
        <Icon name="download" className="text-secondary mt-1 mr-2 " />
        <Icon name="info-circle" className="text-secondary mt-1 mr-2" />
      </td>
    )

    return tableTd;
  }

  // Headers
  const headers = [
    {caption: 'Dataset', field: 'datasets', classes:'text-left'},
    {caption: 'Column', field: 'column', classes:'text-left'},
    {caption: 'Axis Series', field: 'series', classes:'text-left'},
    {caption: 'Chart Type', field: 'type', classes:'text-left'},
    {caption: 'Colour', field: 'colour', classes:'text-left'},
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );
  tableHeadTh.push(<th style={{width: 40}} key={headers.length + 1}></th>);

  let tableBodyTr = (<tr></tr>);
  if (widget && widget.datasets && widget.datasets.length > 0) {
    tableBodyTr = widget.datasets.map((dataset, index) =>
      <tr key={index} >
        {renderRows(headers, dataset)}
      </tr>
    );
  }

  const iconName='chart-mixed';
  const emptyCaption='No datasets available for this group.';

  return (
    <React.Fragment>
      {!widget.fixedDatasets && (
        <span className="d-flex justify-content-end bg-light">
          <Button size="sm" color="link" onClick={onAddWidgetDataset}>Add Data Row</Button>
        </span>
      )}

      <Listview rows={widget.datasets} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  )
}

export default HorizontalChartConfigLsv;
