import { Button, Label } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const AdminBenchmarkGroupsToolbar = (props) => {
  const {
    onRefresh = noop,
    groups = 0
  } = props;

//   const noneSelected = [{id: '', name: 'None Selected'}];
//   probeFilterControls.period_id.options = noneSelected.concat(periods?.rows?.map(period => ({id: period?.id, name: period?.description})));

  const groupCount = `${groups} Group(s)`;
  return (
    <div className="m-1 p-1 bg-light d-flex justify-content-between rounded">
      <Label className="m-1 text-nowrap">{groupCount}</Label>
      <Button onClick={onRefresh} size="sm" color="primary" className="">
        <Icon name="rotate" />
      </Button>
    </div>
  );
};

export default AdminBenchmarkGroupsToolbar;
