import { Button, Label, UncontrolledPopover, PopoverBody } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';
import TrendAxisPropertiesLsv from './TrendAxisPropertiesLsv';
import TrendAxisGroupsLsv from './TrendAxisGroupsLsv';


const TrendAxisPopover = (props) => {
  const {
    controls = [],
    handleMetricChange = noop,
    updateField = noop,
    title = '',
    orientation = 'leftAxis',
    flattenedProbeRows = [],
    axis = {}
  } = props;

  const properties = [];
  axis?.properties?.map(property => {
    property.fields.map(field => {
      properties.push(
        {
          id: uuidv4(),
          orientation,
          property_id: property.id,
          property_name: property.name,
          dataKey: field.dataKey,
          caption: field.name,
          chart: field.chart,
          colour: field.colour
        }
      );
    });
  });

  const groups = [];
  axis?.groups?.map(group => {
    group.fields.map(field => {
      groups.push(
        {
          id: uuidv4(),
          orientation,
          group_id: group.id,
          group_name: group.name,
          dataKey: field.dataKey,
          chart: field.chart,
          colour: field.colour,
          caption: field.name
        }
      );
    });
  });

  if (flattenedProbeRows.length > 0) {
    controls.metric_id.options = flattenedProbeRows?.map(row => ({id: row?.id, name: `${row?.group?.caption} - ${row?.metric?.name}`}));
    controls.metric_id.value = axis.row?.id;
  }

  const handleChange = (event) => {
    const selected_row = flattenedProbeRows.find(row => row.id === event.value);
    handleMetricChange(selected_row);
  };

  const controlId = `${orientation}_popover`;

  return (
    <div>
      <Button
        id={controlId}
        type="button"
        color="link"
        size="sm"
      >
        {orientation === 'rightAxis' && (<>{title}</>)}
        <Icon
          id="gear"
          as="button"
          name="gear"
          title="Load in Top Chart"
          className="ml-2 mr-2 p-0"
        />
        {orientation === 'leftAxis' && (<>{title}</>)}
      </Button>

      <UncontrolledPopover
        placement="bottom"
        target={controlId}
        trigger="legacy"
      >
        <PopoverBody className="bg-gray-20" >
          { flattenedProbeRows.length > 0 && (
            <div className="d-flex justify-content-start" >
              <Label className="m-0 mt-1 ml-2 mr-2 " style={{fontSize: 14}}>Metric</Label>
              <FormInputSelect selectFirst={false} className="mr-2" size="30px" handleChange={handleChange} control={controls.metric_id} />
            </div>
          )}

          <div className="mt-2 border-bottom border-green d-flex justify-content-between text-indigo">
            <small>Properties</small>
            <small>+</small>
          </div>
          <TrendAxisPropertiesLsv
            rows={properties}
            updateField={updateField}
            allowColourEdit={false}
          />

          <div className="mt-2 border-bottom border-green d-flex justify-content-between text-green">
            <small>Benchmark Groups</small>
            <small>+</small>
          </div>
          <TrendAxisGroupsLsv
            rows={groups}
            updateField={updateField}
            allowColourEdit={false}
          />
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

export default TrendAxisPopover;
