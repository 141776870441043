import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import { HalfCircleSpinner } from 'react-epic-spinners';

const PipelineProgress = (props) => {
  const {
    caption,
    status,
    last_message
  } = props;

  let iconName;
  let iconColour;
  let icon = null;
  switch (status.toLowerCase()) {
    case "completed":
      iconColour = '#38c172';
      iconName = 'check';
    break;
    case "scheduled":
      iconColour = '#8ab5f4';
      iconName = 'rotate';
      icon = <HalfCircleSpinner color="#ffffff" size={14}/>
    break;
    case "processing":
      iconColour = '#4285f4';
      iconName = 'rotate';
      icon = <HalfCircleSpinner color="#ffffff" size={14}/>
    break;
    case "fail":
      iconColour = '#ea4335';
      iconName = 'xmark';
    break;
    case "disabled":
      iconColour = '#343a40';
      iconName = 'ellipsis';
    break;
    default:
      iconColour = '#bbbbbb';
      iconName = 'ellipsis';
    break;
  }

  // Set default icon
  if(!icon) {
    icon = (<Icon name={iconName} style={{backgroundColor: iconColour}} className="mt-1 text-white mr-1" />);
  }

  return (
    <React.Fragment>
      <span title={last_message ?? status} className="round-progress rounded p-1 d-flex flex-row ml-2 text-white justify-content-between" style={{backgroundColor: iconColour}}>
        <span className="ml-1 mr-2">{caption}</span>
        {icon}
      </span>

    </React.Fragment>
  );
}

export default PipelineProgress;
