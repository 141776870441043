import React from 'react';
import { connect } from "react-redux";
import {
  Card,
  Alert
} from 'reactstrap';

import { Redirect } from 'react-router-dom';
import Icon from 'jsx/components/core/icons/Icon';

class Maintenance extends React.Component {
  render() {
    const {
      currentApp,
      mode
    } = this.props.realm;

    if (mode !== 'maintenance') {
      return <Redirect to='/home' />;
    }

    return (
      <div className="container-fluid p-0 h-100 bg-light row align-items-center d-flex justify-content-center">
        <Card className="p-2 card-border-color border-corporate login text-center">
          <Icon size="4x" name={currentApp.icon} className="text-corporate m-3" />
          <h3 className="text-secondary mt-2">{currentApp.title}</h3>
          <div className="m-4">
            <Alert color="danger">
              <Icon color='warning' size="3x" name="snowplow" className="m-3" />
              <div>{currentApp.title} is currently under Maintenance. It will be back before you know it!</div>
            </Alert>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    auth: store.auth,
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(Maintenance);
