import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const AgistmentMenu = (props) => {
  const { setModal = noop } = props;

  return (
    <div>
      <UncontrolledButtonDropdown>
        <DropdownToggle caret color="success" size="sm">
          <Icon name="plus" className="mr-1" />
          Action
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem toggle={true} onClick={() => setModal(true, 'agistment_income')}>
            Add Income
          </DropdownItem>
          <DropdownItem toggle={true} onClick={() => setModal(true, 'agistment_expense')}>
            Add Expense
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
};

export default AgistmentMenu;
