import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  source_enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'source_enterprise.name',
    name: 'source_enterprise_id',
    group: 'source',
    type: 'select',
    caption: 'Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  source_property_id: {
    ...controlDefaults,
    group: 'source',
    fieldName: 'source_property.name',
    name: 'source_property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  source_animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'source_animal_class.name',
    name: 'source_animal_class_id',
    group: 'source',
    type: 'select',
    caption: 'Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  target_enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'target_enterprise.name',
    name: 'target_enterprise_id',
    group: 'target',
    type: 'select',
    caption: 'Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  target_property_id: {
    ...controlDefaults,
    group: 'target',
    fieldName: 'target_property.name',
    name: 'target_property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  target_animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'target_animal_class.name',
    name: 'target_animal_class_id',
    group: 'target',
    type: 'select',
    caption: 'Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    type: 'date',
    caption: 'Transfer Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    sortColumn: 'transaction_date',
  },
  quantity: {
    ...controlDefaults,
    placeholder: '',
    name: 'quantity',
    type: 'number',
    caption: '# of Head',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  weight: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'weight',
    unitType: 'kg',
    name: 'weight',
    type: 'number',
    caption: 'Weight (kg/head)',
    showRules: {
      mismatchingFields: [
        ['source_enterprise_id', 'target_enterprise_id'],
        ['source_property_id', 'target_property_id'],
      ],
    },
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  value: {
    ...controlDefaults,
    placeholder: '',
    name: 'value',
    type: 'number',
    caption: 'Value ($/head)',
    showRules: {
      mismatchingFields: [
        ['source_enterprise_id', 'target_enterprise_id'],
        ['source_property_id', 'target_property_id'],
      ],
    },
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
      includeDollarSign: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  value_per_kg: {
    ...controlDefaults,
    placeholder: '',
    name: 'value_per_kg',
    disabled: true,
    formula: '##value## / ##weight##',
    formula_priority: 0,
    type: 'number',
    caption: 'Value ($/kg)',
    formattingRules: {
      includeCommas: true,
      includeDollarSign: true,
      includeDecimals: true,
    },
    showRules: {
      mismatchingFields: [
        ['source_enterprise_id', 'target_enterprise_id'],
        ['source_property_id', 'target_property_id'],
      ],
    },
    showInEditor: true,
    showInListview: true,
    user_entry: false,
  },
};
