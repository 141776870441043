import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    name: 'name',
    type: 'text',
    caption: 'Name',
    disabled: false,
    validationRules: {
      isRequired: true,
    },
  },

  attributes: {
    ...controlDefaults,
    name: 'attributes',
    type: 'json',
    caption: 'Attributes',
    disabled: false,
    validationRules: {
      isRequired: true,
      isArray: true,
    },
  },
};
