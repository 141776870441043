import React from 'react';
import SingleSearch from "../../../core/form/components/SingleSearch";
import PipelinesMenu from './PipelinesMenu';

const PipelinesToolbar = (props) => {
  const {
    onSearch,
    searchPlaceholder,
    handleSearchChange,
    searchValue,
    queueSubsampling,
    queueCarbonCalcs,
    checkAccess
  } = props;

  return (
    <React.Fragment>
      <div className="bg-light p-2 mb-2 d-flex justify-content-end">
        <SingleSearch handleSearchChange={handleSearchChange} onSearch={onSearch} placeholder={searchPlaceholder || 'Search'} value={searchValue} />
        <PipelinesMenu
          queueSubsampling={queueSubsampling}
          queueCarbonCalcs={queueCarbonCalcs}
          checkAccess={checkAccess}
        />
      </div>
    </React.Fragment>
  );
}

export default PipelinesToolbar;
