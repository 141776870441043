import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import FormBase from '../../../core/form/components/FormBase';

import DaysheetApprovalsLsv from '../components/DaysheetApprovalsLsv';
import DaysheetApprovalsToolbar from '../components/DaysheetApprovalsToolbar';
import {
  approveActivities,
  fetchDaysheetActivityApprovals,
} from '../actions/daysheet_activity_approvals';
import TimekeeperTimerBar from '../../office/components/TimekeeperTimerBar';

class DaysheetApprovals extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      approvalChanges: [],
      filterValues: {
        showAlreadyApproved: false,
        showAwaitingApproval: true,
      },
    };
  }

  componentDidMount() {
    this.loadAllApprovals();
  }

  componentDidUpdate(prevProps) {
    const { selectedDate } = this.props.office;
    if (selectedDate && selectedDate !== prevProps.office.selectedDate) {
      this.loadAllApprovals();
    }

    const { forceRefresh, setRefresh } = this.props;
    if (forceRefresh && setRefresh) {
      this.loadAllApprovals();
      if (setRefresh) setRefresh(false);
    }
  }

  loadAllApprovals = async () => {
    const { filterValues } = this.state;
    const { currentWeek } = this.props.office;
    const { rows } = await this.props.dispatch(
      fetchDaysheetActivityApprovals({
        ...currentWeek,
        include_already_approved: filterValues.showAlreadyApproved,
        include_awaiting_approval: filterValues.showAwaitingApproval,
      }),
    );
    this.setState({ rows });
  };

  createApproval = (newApproval) => {
    this.setState({ approvalChanges: [newApproval] }, this.onApprove);
  };

  onApprove = async () => {
    const { approvalChanges } = this.state;
    await this.props.dispatch(approveActivities({ approvals: approvalChanges }));
    this.setState({ approvalChanges: [] });
    this.loadAllApprovals();
  };

  setWeek = async (increment) => {
    await this.props.dispatch({ type: 'UPDATE_CURRENT_WEEK', payload: increment });
  };

  setFilter = (newFilterValues) => {
    const { filterValues } = this.state;

    this.setState(
      {
        filterValues: {
          ...filterValues,
          ...newFilterValues,
        },
      },
      this.loadAllApprovals,
    );
  };

  selectDate = (selectedDate) => {
    this.props.dispatch({ type: 'SET_SELECTED_DATE', payload: selectedDate });
  };

  filteredDaysheets = (rows, selectedDate) => {
    const day = moment(selectedDate);

    return rows.filter(({ daysheet }) => moment(daysheet.from_datetime).isSame(day, 'day'));
  };

  render() {
    const { rows, filterValues } = this.state;
    const { currentWeek, selectedDate } = this.props.office;

    const filteredRows = this.filteredDaysheets(rows, selectedDate);
    // special data format just for TimekeeperTimerBar
    const daysheets = rows.map((row) => ({
      ...row.daysheet,
      total_labour_hours: row.labour_hours,
    }));

    return (
      <div className="d-flex flex-column h-100">
        <DaysheetApprovalsToolbar setFilter={this.setFilter} filterValues={filterValues} />
        <TimekeeperTimerBar
          selectDate={this.selectDate}
          selectedDate={selectedDate}
          setWeek={this.setWeek}
          week={currentWeek}
          reload={this.loadAllApprovals}
          daysheets={{ rows: daysheets }}
        />
        <DaysheetApprovalsLsv rows={filteredRows} createApproval={this.createApproval} />
      </div>
    );
  }
}

const mapStoreToProps = ({ office, wipstar }) => ({ office, wipstar });

export default connect(mapStoreToProps)(DaysheetApprovals);
