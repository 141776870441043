import React from 'react';
import logoIcon from '../../../../../images/carbonizer/logo_icon.png';
import { Card } from 'reactstrap';

const PageMessage = (props) => {
  const { message, textClass } = props;
  const className = `${textClass} m-5 pl-5 pr-5`;

  return (
    <div className="container-fluid p-0 h-100 bg-light row align-items-center d-flex justify-content-center">
      <Card className="p-2 card-border-color border-corporate login text-center">
        <img src={logoIcon} alt="Carbonlink Logo" />
        <h3 className="text-secondary mt-2">Carbonlink</h3>
        <div className={className}>{message}</div>
      </Card>
    </div>
  );
};

export default PageMessage;
