import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';

const ProbesLsv = () => {
  const iconName= 'ufo-beam';
  const title = 'Profit Probe';
  const introduction = (
    <>
      <p>
        Thank you for your interest in Profit Probe. At first glance this input requirement may seem like a big and complicated
        task. But, taken step by step the process is achievable and the result will definitely make your effort worth while.
      </p>
      <p>
        Please fill out each section that applies to your business. Work through each of the side menu tabs in order top to bottom.
      </p>
      <p>
        The tabs for your relevant enterprises are displayed only after you complete the "Enterprise Allocation" tab. It is important that you provide accurate information. The more accurate
        the data, the more useful the report.
      </p>

      <h3>Versioning</h3>
      <p>This probe maintains a Draft and Published version of the Probe. Draft is your working copy enabling you to continue to insert and update information into the Probe
        without it being submitted to RCS.
      </p> 
      <p>
        By Publishing a new probe or making a change to an existing probe, the application will create a new Published version of the probe
        which can then be used to generate reports on the probe data.
      </p>
    </>
  );

  return (
    <>
      <div className="p-5 text-center">
        <div><Icon size="4x" name={iconName} className="text-corporate"/></div>
        <h1 className="mt-3">{title}</h1>
        <div className="mt-2">
          {introduction}
        </div>
      </div>
    </>
  );
}

export default ProbesLsv;
