import React from 'react';
import Listview from "../../../core/form/components/Listview";

const ChartLayersSocLsv = (props) => {
  let {
    data,
    colours,
    namePrefix
  } = props;


  // Render Rows
  const renderRows = (headers, dataset) => {
    let rowColour = "mr-3"

    const tableTd = headers.map((header, index) => {
      const classes = `${rowColour} ${header.classes}`;
      const nameClasses = `${classes} text-white`;
      const bgColour = colours[dataset.name];

      const name = `${namePrefix}${dataset[header.field]}`;

      switch (header.field) {
        case 'name': return  (
          <td key={index} className={nameClasses} style={{backgroundColor: bgColour, borderRight: '1px solid #cccccc'}}>{name}</td>
        )
        default: return  (
          <td key={index} className={classes} style={{borderRight: '1px solid #cccccc'}}>{dataset[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  // Upper Headers
  const upperHeaders = [
    {caption: null, cells: 1, classes: 'border-bottom-0'},
    {caption: "Summary", cells: 2, classes: 'text-center text-white bg-primary border border-secondary'}
  ]

  // Lower Headers
  const headers = [
    {caption: 'Name', field: 'name', classes:'text-left'},
    {caption: 'Avg', field: 'avg_carbon_mass', classes:'text-right'},
    {caption: 'Var', field: 'var_carbon_mass', classes:'text-right'},
  ]

  // Build pivot headers and data
  let rows = [];
  if (data.length > 0) {
    rows = data.map((row, idx) => {
      const newRow = {
        name: row.name,
        avg_carbon_mass: (row.esm.avg_carbon_mass ? row.esm.avg_carbon_mass.toFixed(2) : 0 ),
        var_carbon_mass: (row.esm.var_carbon_mass ? row.esm.var_carbon_mass.toFixed(2) : 0 )
      }

      row.layers.map(layer => {
        const column = `${layer.upper_depth}-${layer.lower_depth}`;
  
        // Avg soc
        const avgsocFieldName = `${column}_avg_soc`;
        const avgsocCaption = `Avg`;
        newRow[avgsocFieldName] = layer.esm.avg_carbon_mass.toFixed(2);
  
        // Avg soc
        const varianceFieldName = `${column}_var_soc`;
        const varianceCaption = `Var`;
        newRow[varianceFieldName] = layer.esm.var_carbon_mass.toFixed(2);
  
        // Add headers (once)
        if (idx === 0) {
          headers.push({caption: avgsocCaption, field: avgsocFieldName, classes:'text-right'});
          headers.push({caption: varianceCaption, field: varianceFieldName, classes:'text-right'});
  
          const upperCaption = `${column}`
          upperHeaders.push(
            {caption: upperCaption, cells: 2, classes: 'text-center bg-lightgray border border-secondary'}
          )
        }
        return false;
      }) 
      return (newRow)
    })
  }

  // Upper header cells
  const upperHeadTh = upperHeaders.map((upperHeader, index) =>
    <th key={index} colSpan={upperHeader.cells} className={upperHeader.classes}>{upperHeader.caption}</th>
  );

  // Lower header cells
  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes} style={{borderRight: '1px solid #cccccc'}}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  if (rows && rows.length > 0) {
    tableBodyTr = rows.map((dataset, index) =>
      <tr key={index} >
        {renderRows(headers, dataset)}
      </tr>
    );
  }

  const iconName='leaf';
  const emptyCaption=`No ${namePrefix}'s for this round found...`;

  return (
    <React.Fragment>
      <Listview rows={rows} upperHeadTh={upperHeadTh} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  )
}

export default ChartLayersSocLsv;
