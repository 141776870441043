import { noop } from 'lodash';
import Listview from 'jsx/components/core/form/components/Listview';
import ChartItemToolbar from './ChartItemToolbar';

const TrendAxisPropertiesLsv = ({ rows, onClick = noop, updateField = noop, allowColourEdit = true }) => {
  const renderRows = (headers, record) => {
    const tableTd = headers.map(({ field, classes, sortColumn }, index) => {
      switch (field) {
        case 'chart': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>
              <ChartItemToolbar allowColourEdit={allowColourEdit} key={index} record={{...record}} updateField={updateField} />
            </td>
          );
        }
        case 'dataKey': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>
              {record.caption}
            </td>
          );
        }
        default:
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>
              {record[field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const iconName = 'chart-line';
  const emptyCaption = 'No Properties defined';

  const headers = [
    { caption: 'Property', field: 'property_name', classes: 'text-left' },
    { caption: 'Field', field: 'dataKey', classes: 'text-left' },
    { caption: 'Chart/Colour', field: 'chart', classes: 'text-center' },
  ];

  const tableHeadTh = headers.map(({ caption, classes }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = [];
  let finalRows = rows;
  if (rows?.rows) finalRows = rows.rows;

  const haveRows = finalRows && finalRows.length > 0;
  if (haveRows) {
    tableBodyTr = finalRows.map((row) => (
      <tr key={row.id} onClick={() => onClick(row.id)} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={finalRows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default TrendAxisPropertiesLsv;
