import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiOrgAssets, apiEnterprises } from '../../../../constants/api';

const apiOrgAssetTransactions = `${apiOrgAssets}/transactions`;

export function fetchAssets(params, filters) {
  return async (dispatch) => await get(dispatch, `FETCH_ASSETS`, `${apiOrgAssets}`, params);
}

export function fetchAsset(id) {
  return async (dispatch) => await get(dispatch, `FETCH_ASSET`, `${apiOrgAssets}/${id}`);
}

export function createAsset(data) {
  return async (dispatch) => await post(dispatch, `CREATE_ASSET`, `${apiOrgAssets}`, data);
}

export function updateAsset(data) {
  return async (dispatch) =>
    await put(dispatch, `UPDATE_ASSET`, `${apiOrgAssets}/${data.id}`, data);
}

export function removeAsset(id) {
  return async (dispatch) => await remove(dispatch, `REMOVE_ASSET`, `${apiOrgAssets}/${id}`);
}

export function fetchAssetflows(params, filters) {
  return async (dispatch) => {
    // Store params in reducer
    if (exists(params)) dispatch({ type: 'SET_ASSET_PARAMS', payload: params });

    // Handle filters
    if (filters) params = addFiltersToParams(params, filters);

    // Retrieve and store assetflows in reducer
    return await get(dispatch, `FETCH_ASSETFLOWS`, `${apiOrgAssets}/flows`, params);
  };
}

export function fetchAssetTransactions(params, inBackground = false) {
  return async (dispatch) =>
    await get(
      dispatch,
      `FETCH_ASSET_TRANSACTIONS`,
      `${apiOrgAssetTransactions}`,
      params,
      null,
      inBackground
    );
}

export function fetchAssetTransaction(id) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_ASSET_TRANSACTION`, `${apiOrgAssetTransactions}/${id}`);
}

export function createAssetTransaction(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_ASSET_TRANSACTION`, `${apiOrgAssetTransactions}`, data);
}

export function updateAssetTransaction(data) {
  return async (dispatch) =>
    await put(dispatch, `UPDATE_ASSET_TRANSACTION`, `${apiOrgAssetTransactions}/${data.id}`, data);
}

export function removeAssetTransaction(id) {
  return async (dispatch) =>
    await remove(dispatch, `REMOVE_ASSET_TRANSACTION`, `${apiOrgAssetTransactions}/${id}`);
}

export function setAssetflowFilters(params, filters) {
  return async (dispatch) => {
    // Store filters in reducer
    dispatch({ type: 'SET_ASSETFLOW_FILTERS', payload: filters });

    // Retrieve and store filtered assets
    dispatch(fetchAssetflows(params, filters));
  };
}

export function fetchEnterprisesByAssetType(params) {
  return async (dispatch) => {
    get(dispatch, 'FETCH_ENTERPRISES_BY_ASSET_TYPE', apiEnterprises, params);
  };
}

const addFiltersToParams = (params, filters) => {
  Object.keys(filters).forEach((key) => (params[`filter_${key}`] = filters[key]));
  return params;
};

const exists = (data) => Object.keys(data).length > 0;
