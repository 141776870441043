/* eslint-disable id-length */
import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  code: {
    ...controlDefaults,
    name: 'code',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    placeholder: 'Activity Name...',
    name: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    group: 'Activity',
    cols: {
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  workorder_no: {
    ...controlDefaults,
    placeholder: 'Work Order #',
    name: 'workorder_no',
    type: 'text',
    caption: 'Work Order #',
    showInEditor: false,
    defaultValue: 'Annual Maintenance',
    disabled: true,
    group: 'Activity',
  },
  description: {
    ...controlDefaults,
    placeholder: 'Activity Description...',
    name: 'description',
    type: 'textarea',
    caption: 'Description',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    group: 'Activity',
    cols: {
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  priority_id: {
    ...controlDefaults,
    name: 'priority_id',
    type: 'select',
    caption: 'Priority',
    options: [],
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    group: 'Attributes',
  },
  status_id: {
    ...controlDefaults,
    name: 'status_id',
    type: 'select',
    caption: 'Status',
    options: [],
    includeEmptyOption: true,
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    group: 'Attributes',
  },
  assignedto_id: {
    ...controlDefaults,
    name: 'assignedto_id',
    type: 'select',
    caption: 'Assigned To',
    includeEmptyOption: true,
    options: [],
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    group: 'Attributes',
  },
  type_id: {
    ...controlDefaults,
    name: 'type_id',
    type: 'select',
    caption: 'Type',
    options: [],
    disabled: true,
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    group: 'Attributes',
    includeEmptyOption: false,
  },
  project_id: {
    ...controlDefaults,
    name: 'project_id',
    type: 'reactselect',

    caption: 'Project #',
    options: [],
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    group: 'Attributes',
  },
  phasecode_id: {
    ...controlDefaults,
    name: 'phasecode_id',
    type: 'reactselect',
    caption: 'Phasecode',
    options: [],
    validationRules: {
      isRequired: true,
    },
    excludeFromSetOptions: true,
    showInEditor: true,
    group: 'Attributes',
  },
  comments: {
    ...controlDefaults,
    placeholder: 'This task is...',
    name: 'comments',
    type: 'textarea',
    caption: 'Add Comment',
    validationRules: {
      isRequired: false,
    },

    showInEditor: true,
    group: 'Comments',
    cols: {
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
};
