import { defaults } from '../../../../lib/genericReducer';
export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    authorised: true,
    activeTab: 'profile',
    authResponseMessage: null,
    allceas: [],
    params: {},
    dashboard_stats: [],
    currentArea: {},
    currentAreas: [],
    currentRound: {},
    stratas: [],
    currentStrata: {},
    subsamples: [],
    responseMessage: null,
    roundResponseMessage: null,
    strataResponseMessage: null,
    siteResponseMessage: null,
    labAnalysisResponseMessage: null,
    documents: [],
  },
  action
) {
  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'SET_ALLCEAS_PARAMS': {
      return {
        ...state,
        params: action.payload,
      };
    }
    case 'UNSET_ALLCEAS_PARAMS': {
      return {
        ...state,
        params: {},
      };
    }
    case 'SET_AREAROUND_ACTIVE_TAB': {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case 'UNSET_RESPONSE_MESSAGES':
      return {
        ...state,
        roundResponseMessage: null,
        strataResponseMessage: null,
        siteResponseMessage: null,
        labAnalysisResponseMessage: null,
        manualWorkflowResponseMessage: null,
      };
    case 'UNSET_AREAS': {
      return {
        ...state,
        currentAreas: [],
      };
    }
    case 'UNSET_STRATA': {
      return {
        ...state,
        currentStrata: {},
      };
    }
    case 'UNSET_ROUND': {
      return {
        ...state,
        currentRound: {},
      };
    }
    case 'UNSET_STRATAS': {
      return {
        ...state,
        stratas: [],
      };
    }
    case 'FETCH_DASHBOARD_STATS_BY_AREAS_FULFILLED': {
      return {
        ...state,
        dashboard_stats: action.payload,
      };
    }
    case 'CREATE_AREA_ROUND_FULFILLED': {
      return {
        ...state,
        roundResponseMessage: null,
        currentRound: action.payload,
      };
    }
    case 'CREATE_AREA_ROUND_REJECTED': {
      return {
        ...state,
        roundResponseMessage: action.payload,
      };
    }
    case 'UPLOAD_AREA_ROUND_STRATAS_PENDING': {
      return {
        ...state,
        strataResponseMessage: null,
      };
    }
    case 'UPLOAD_AREA_ROUND_STRATAS_FULFILLED': {
      return {
        ...state,
        strataResponseMessage: null,
        stratas: action.payload,
      };
    }
    case 'UPLOAD_AREA_ROUND_STRATAS_REJECTED': {
      return {
        ...state,
        strataResponseMessage: action.payload,
      };
    }
    case 'UPLOAD_AREA_ROUND_LABANALYSIS_PENDING': {
      return {
        ...state,
        labAnalysisResponseMessage: null,
      };
    }
    case 'UPLOAD_AREA_ROUND_LABANALYSIS_FULFILLED': {
      return {
        ...state,
        labAnalysisResponseMessage: null,
      };
    }
    case 'UPLOAD_AREA_ROUND_LABANALYSIS_REJECTED': {
      return {
        ...state,
        labAnalysisResponseMessage: action.payload,
      };
    }
    case 'UPLOAD_AREA_ROUND_MANUAL_WORKFLOW_PENDING': {
      return {
        ...state,
        manualWorkflowResponseMessage: null,
      };
    }
    case 'UPLOAD_AREA_ROUND_MANUAL_WORKFLOW_FULFILLED': {
      return {
        ...state,
        manualWorkflowResponseMessage: null,
      };
    }
    case 'UPLOAD_AREA_ROUND_MANUAL_WORKFLOW_REJECTED': {
      return {
        ...state,
        manualWorkflowResponseMessage: action.payload,
      };
    }
    case 'UPLOAD_SITES_PENDING': {
      return {
        ...state,
        siteResponseMessage: null,
      };
    }
    case 'UPLOAD_SITES_FULFILLED': {
      return {
        ...state,
        siteResponseMessage: null,
        sites: action.payload,
      };
    }
    case 'UPLOAD_SITES_REJECTED': {
      return {
        ...state,
        siteResponseMessage: `Site Geometry problems - ${action.payload}`,
      };
    }
    case 'FETCH_SUBSAMPLES_FULFILLED': {
      return {
        ...state,
        subsamples: action.payload,
      };
    }
    case 'FETCH_AREA_ROUND_STRATAS_FULFILLED': {
      return {
        ...state,
        stratas: action.payload,
      };
    }
    case 'FETCH_AREA_ROUND_STRATA_FULFILLED': {
      return {
        ...state,
        currentStrata: action.payload,
      };
    }
    case 'FETCH_AREAS_FULFILLED': {
      return {
        ...state,
        currentAreas: action.payload,
      };
    }
    case 'FETCH_ALLCEAS_FULFILLED': {
      return {
        ...state,
        allceas: action.payload,
      };
    }
    case 'FETCH_AREA_ROUND_FULFILLED': {
      return {
        ...state,
        currentRound: action.payload,
      };
    }
    case 'UPLOAD_AREAS_FULFILLED': {
      return {
        ...state,
        currentAreas: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
