import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Progress } from 'reactstrap';
import { initControls, saveControls, controlDefaults } from '../../../core/form/lib/validateForm';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import Icon from 'jsx/components/core/icons/Icon';

import { fetchSiteScripts } from '../actions';

class SiteUploadModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      title: 'Upload Sites Details',
      data: {},
      fileName: null,
      controls: {
        site_script_id: {
          ...controlDefaults,
          placeholder: '',
          name: 'site_script_id',
          type: 'select',
          caption: 'Generation Method',
          validationRules: {
            isRequired: true,
          },
        },
      },
    };

    this.inputFileRef = React.createRef(null);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.handleFileChoice = this.handleFileChoice.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      this.setState({
        controls: initControls(this.state.controls),
        isOpen: this.props.isOpen,
      });

      this.props.dispatch(fetchSiteScripts()).then((scripts) => {
        const { controls } = this.state;
        controls['site_script_id'].options = scripts;
        controls['site_script_id'].value = scripts[0].id;
        this.setState({ controls });
      });
    }
  }

  async onSave() {
    let { data } = this.state;

    const { controls } = this.state;

    const { onSave } = this.props;

    this.setState({
      isUploading: true,
    });

    data = saveControls(controls, data);

    const success = await onSave(
      this.inputFileRef.current.files,
      this.updateProgress,
      data.site_script_id
    );
    if (!success) {
      this.setState({
        isUploading: false,
      });
    } else {
      this.onCancel();
    }
  }

  onCancel() {
    this.props.setModal(false);
    this.setState({ isOpen: false });
  }

  updateProgress(value) {
    let { fileName, isUploading } = this.state;

    if (value === 100) {
      this.inputFileRef.current.value = null;
      isUploading = false;
      value = 0;
      fileName = null;
    }

    this.setState({
      progressValue: value,
      isUploading: isUploading,
      fileName: fileName,
    });
  }

  handleBtnClick() {
    this.inputFileRef.current.click();
  }

  handleFileChoice() {
    this.setState({
      fileName: this.inputFileRef.current.files[0].name,
    });
  }

  render() {
    const { isOpen, title, controls, isUploading, progressValue, fileName } = this.state;

    const { responseMessage } = this.props.sites;

    const iconName = 'circle-notch';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          <div className="mb-2">
            <FormInput handleChange={this.handleChange} control={controls.site_script_id} />
          </div>

          <div className="d-flex justify-content-between">
            {isUploading && (
              <Progress
                style={{ width: 450 }}
                className={'m-2 text-center'}
                animated
                value={progressValue}
              >
                {progressValue}%
              </Progress>
            )}
            {!isUploading && (
              <Button id="caret" color="primary" onClick={this.handleBtnClick}>
                Choose File
              </Button>
            )}
            {fileName && <div className="mt-2">{fileName}</div>}
            <input
              style={{ display: 'none' }}
              onChange={this.handleFileChoice}
              type="file"
              ref={this.inputFileRef}
            />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <ResponseMessage responseMessage={responseMessage} />
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
            <Button size="sm" color="success" onClick={this.onSave}>
              Confirm
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    sites: store.sites,
  };
};

export default connect(mapStoreToProps)(SiteUploadModal);
