import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import moment from 'moment';
import { cloneDeep, omit } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../../core/form/components/FormInput';
import FormBase from '../../../../core/form/components/FormBase';
import { controls as adjustmentControls } from '../../forms/croppingAdjustments';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../../core/form/lib/validateForm';
import {
  fetchCroppingTransaction,
  updateCroppingTransaction,
  createCroppingTransaction,
  removeCroppingTransaction,
} from '../../actions/cropping';
import { fetchAttributes } from '../../actions/attributes';

class CroppingAdjustmentModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(adjustmentControls),
      data: {},
      id: null,
      isNew: false,
      showOpeningValueFields: false,
      title: 'Adjustment',
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchAttributes({ type: 'crop_adjustment_type' }));
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const controls = initControls(cloneDeep(adjustmentControls));
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        showOpeningValueFields: this.props.showOpeningValueFields,
        title: 'New Adjustment',
      };

      if (this.props.id) {
        const { id } = this.props;
        const data = await this.props.dispatch(fetchCroppingTransaction(id));
        const showOpeningValueFields = data.adjustment_type?.tag === 'opening_units';

        updatedState = {
          ...updatedState,
          controls: updateControls(controls, data),
          data,
          id,
          isNew: false,
          showOpeningValueFields,
          title: 'Edit Adjustment',
        };
      }

      this.setState(updatedState);
    }
  }

  getEnterpriseProperties = (enterprise_id) => {
    if (!enterprise_id) return [];
    // Only display properties that have been previously allocated to the enterprise
    const { enterprises } = this.props.enterprises;
    const enterprise = enterprises?.rows.find(({ id }) => id === enterprise_id);
    if (!enterprise || enterprises.allocations?.length === 0) return [];

    return enterprise.allocations.map(({ open_date, property }) => ({ ...property, open_date }));
  };

  getOpeningDate = (property_id, enterprise_properties = []) => {
    const { selectedRanges } = this.props.enterprises;
    const defaultOpeningDate = this.formatOpeningTransactionDateString(selectedRanges.from_date);

    const selectedProperty = enterprise_properties.find(({ id }) => id === property_id);

    return selectedProperty
      ? this.formatOpeningTransactionDateString(selectedProperty.open_date)
      : defaultOpeningDate;
  };

  formatOpeningTransactionDateString = (date) =>
    moment(date).subtract(1, 'day').format('YYYY-MM-DD');

  onAdjustmentTypeChange = (event) => {
    const { value: adjustment_type_id } = event.target;
    const { cropping_adjustment_types } = this.props.attributes;
    const { controls } = this.state;
    const updatedControls = cloneDeep(this.state.controls);
    updatedControls.adjustment_type_id.value = adjustment_type_id;

    // Conditionally render value field for 'Opening Units' adjustments only.
    const openingUnitsType = cropping_adjustment_types.filter(
      (type) => type.tag === 'opening_units',
    );
    const showOpeningValueFields = openingUnitsType[0]?.id === adjustment_type_id;

    if (!showOpeningValueFields) {
      this.setState({ controls: updatedControls, showOpeningValueFields });
      return;
    }

    const enterpriseProperties = this.getEnterpriseProperties(controls.enterprise_id.value);
    if (enterpriseProperties.length === 0) return;

    // Set transaction date to start of selected from date
    updatedControls.transaction_date.value = this.getOpeningDate(
      updatedControls.property_id.value,
      enterpriseProperties,
    );
    this.setState({ controls: updatedControls, showOpeningValueFields });
  };

  onEnterpriseChange = (event) => {
    const { value: enterprise_id } = event.target;
    const { controls, showOpeningValueFields } = this.state;
    const updatedControls = cloneDeep(controls);
    updatedControls.enterprise_id.value = enterprise_id;

    if (!showOpeningValueFields) {
      this.setState({ controls: updatedControls });
      return;
    }

    const enterpriseProperties = this.getEnterpriseProperties(enterprise_id);
    if (enterpriseProperties.length === 0) return;

    // Update opening date to the first property of the selected enterprise
    const [{ open_date }] = enterpriseProperties;
    const openingDate = this.formatOpeningTransactionDateString(open_date);

    updatedControls.transaction_date.value = openingDate;

    this.setState({ controls: updatedControls });
  };

  onPropertyChange = (event) => {
    const { value: property_id } = event.target;
    const { controls, showOpeningValueFields } = this.state;
    const updatedControls = cloneDeep(controls);
    updatedControls.property_id.value = property_id;

    if (!showOpeningValueFields) {
      this.setState({ controls: updatedControls });
      return;
    }

    const enterpriseProperties = this.getEnterpriseProperties(controls.enterprise_id.value);
    if (enterpriseProperties.length === 0) return;

    const openingDate = this.getOpeningDate(property_id, enterpriseProperties);

    updatedControls.transaction_date.value = openingDate;
    this.setState({ controls: updatedControls });
  };

  onClose = (refresh = false) => {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
  };

  onSave = async () => {
    const { data, controls, isNew } = this.state;

    let saveData = saveControls(controls, data);
    const { isValid, updatedControls } = await validateFormFieldControls(saveData, controls);

    if (isValid) {
      let saveMethod = updateCroppingTransaction;

      if (isNew) {
        saveData = omit(saveData, ['id']);
        saveMethod = createCroppingTransaction;
      }

      const success = await this.props.dispatch(saveMethod(saveData));

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  };

  onRemove = async () => {
    const { data } = this.state;

    const confirmed = window.confirm('Removing Adjustment permanently. Continue?');
    if (confirmed) {
      const success = await this.props.dispatch(removeCroppingTransaction(data.id));
      if (success) this.onClose(true);
    }
  };

  render() {
    let { controls } = this.state;
    const { title, isNew, showOpeningValueFields } = this.state;
    const { division_id, isOpen } = this.props;
    const { cropping_adjustment_types, cropping_product_types, transaction_types } =
      this.props.attributes;
    const { responseMessage } = this.props.cropping;
    const { enterprises } = this.props.enterprises;
    const iconName = 'clipboard-list';

    // Conditionally disable and filter adjustment types for adjustment type form field
    const filtered_adjustment_types = showOpeningValueFields
      ? cropping_adjustment_types.filter(({ tag }) => tag === 'opening_units')
      : cropping_adjustment_types.filter(({ tag }) => tag !== 'opening_units');

    controls.adjustment_type_id.disabled = showOpeningValueFields;
    controls.transaction_date.disabled = showOpeningValueFields;
    controls.notes.description =
      showOpeningValueFields && !isNew ? 'Edit Opening Values in the Values tab.' : null;

    const division_enterprises = enterprises?.rows
      ? enterprises.rows.filter((enterprise) => enterprise.division_id === division_id)
      : [];

    // Set control options
    const typeOptions = {
      adjustment_type_id: filtered_adjustment_types,
      enterprise_id: division_enterprises,
      product_id: cropping_product_types,
      transaction_type_id: transaction_types.filter((type) => type.tag === 'adjustment'),
    };

    // Update control options
    Object.entries(typeOptions).forEach(([key, options]) => {
      controls = updateControlOptions(controls, key, options);
    });

    // Conditionally render area_ha field for planted/harvested adjustment types only
    const applicableTransactionTypes = cropping_adjustment_types.filter(
      (type) => type.tag === 'planted' || type.tag === 'harvested',
    );
    const showAreaField = applicableTransactionTypes.find(
      (type) => type.id === controls.adjustment_type_id.value,
    );

    const enterprise_id = controls?.enterprise_id?.value ?? null;
    const filteredProperties = this.getEnterpriseProperties(enterprise_id);
    controls = updateControlOptions(controls, 'property_id', filteredProperties);
    controls.property_id.showInEditor = filteredProperties.length > 1;

    // Set transaction date to opening date of property if opening adjustment
    if (showOpeningValueFields && !controls.transaction_date.value && controls.property_id.value) {
      const openingDate = this.getOpeningDate(controls.property_id.value, filteredProperties);
      controls.transaction_date.value = openingDate;
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.onEnterpriseChange} control={controls.enterprise_id} />
            {filteredProperties?.length > 1 && (
              <FormInput handleChange={this.onPropertyChange} control={controls.property_id} />
            )}
            <FormInput handleChange={this.handleChange} control={controls.product_id} />
            <FormInput control={controls.transaction_type_id} />
            <FormInput
              handleChange={(event) => this.onAdjustmentTypeChange(event)}
              control={controls.adjustment_type_id}
            />
            <FormInput handleChange={this.handleChange} control={controls.transaction_date} />
            {showAreaField && (
              <FormInput handleChange={this.handleChange} control={controls.area_ha} />
            )}
            <FormInput handleChange={this.handleChange} control={controls.quantity_kg} />
            {showOpeningValueFields && isNew && (
              <FormInput handleChange={this.handleChange} control={controls.value} />
            )}
            <FormInput handleChange={this.handleChange} control={controls.notes} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, cropping, enterprises }) => ({
  attributes,
  cropping,
  enterprises,
});

export default connect(mapStoreToProps)(CroppingAdjustmentModal);
