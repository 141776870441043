import React, { useState } from 'react';
import Listview from '../../../../core/form/components/Listview';
import ProjectsTasksLsv from './ProjectsTasksLsv';
import ProjectsCommentsLsv from './ProjectsCommentsLsv';

import { Progress } from 'reactstrap';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import PropertiesLsv from '../properties/PropertiesLsv';

const TaskManagementLsv = (props) => {
  const {
    projects,
    onClick,
    history,
    getJiraComments,
    getLastComment,
    getSubTaskStatuses,
    getProperties,
    checkAccess,
  } = props;

  const [tasksOpen, setOpenTasks] = useState([]);

  // For some reason, useState does not re-render when updating an array so have to hack with this.
  const [renderState, setRenderState] = useState(false);

  const openTasks = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    const idx = tasksOpen.findIndex((projectId) => projectId === id);
    if (idx > -1) {
      tasksOpen.splice(idx, 1);
    } else {
      tasksOpen.push(id);
    }

    setOpenTasks(tasksOpen);
    setRenderState(!renderState);
  };

  const isTaskOpen = (id) => {
    const idx = tasksOpen.findIndex((projectId) => projectId === id);

    if (idx > -1) {
      return true;
    }
    return false;
  };

  const [commentsOpen, setOpenComments] = useState([]);
  const [comments, setComments] = useState({});

  const openComments = async (e, id, jira_key) => {
    e.preventDefault();
    e.stopPropagation();

    const idx = commentsOpen.findIndex((projectId) => projectId === id);
    if (idx > -1) {
      commentsOpen.splice(idx, 1);
    } else {
      commentsOpen.push(id);
    }

    const comment = await getJiraComments(jira_key);
    comments[jira_key] = comment;
    setComments(comments);

    setOpenComments(commentsOpen);
    setRenderState(!renderState);
  };

  const isCommentOpen = (id) => {
    const idx = commentsOpen.findIndex((projectId) => projectId === id);

    if (idx > -1) {
      return true;
    }
    return false;
  };

  const [propertiesOpen, setOpenProperties] = useState([]);
  const [properties, setProperties] = useState({});

  const openProperties = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    const idx = propertiesOpen.findIndex((projectId) => projectId === id);
    if (idx > -1) {
      propertiesOpen.splice(idx, 1);
    } else {
      propertiesOpen.push(id);
    }

    const property = await getProperties(id);
    properties[id] = property;
    setProperties(properties);

    setOpenProperties(propertiesOpen);
    setRenderState(!renderState);
  };

  const isPropertyOpen = (id) => {
    const idx = propertiesOpen.findIndex((projectId) => projectId === id);

    if (idx > -1) {
      return true;
    }
    return false;
  };

  const renderTasks = (project) => {
    if (!project.jira_issues) return <div />;
    const tasks = project.jira_issues.subtasks;
    return (
      <ProjectsTasksLsv
        titleKey={project.jira_key}
        darkTable={true}
        hideHeader={true}
        history={history}
        rows={tasks}
      />
    );
  };

  const renderComments = (project) => {
    return (
      <ProjectsCommentsLsv
        titleKey={project.jira_key}
        darkTable={true}
        hideHeader={true}
        history={history}
        rows={comments[project.jira_key]}
      />
    );
  };

  const renderProperties = (project) => {
    return (
      <PropertiesLsv
        checkAccess={checkAccess}
        darkTable={true}
        hideNew={true}
        hideHeader={false}
        history={history}
        rows={properties[project.id]}
      />
    );
  };

  const renderRows = (headers, project) => {
    // Get last comment
    let lastComment = getLastComment(project.jira_issues);

    // Set progress bar
    let progressValue = 0;
    if (project.jira_issues && project.jira_issues.stats.total > 0) {
      progressValue = parseInt(
        (project.jira_issues.stats.done / project.jira_issues.stats.total) * 100
      );
    }

    // Get stage text
    let stageText = '';
    if (project.jira_issues && project.jira_issues.status) {
      stageText = `${project.jira_issues.status.name}`;
    }

    // Set active subtasks
    let statusPills = <div />;
    if (project.jira_issues && project.jira_issues.subtasks) {
      const statuses = getSubTaskStatuses(project.jira_issues);

      // Build task list
      const keys = Object.keys(statuses);
      statusPills = keys.map((key, index) => {
        const className = `bg-${statuses[key].colour} text-white m-1 p-1 rounded text-nowrap`;
        return <small key={index} className={className}>{`${statuses[key].count} x ${key}`}</small>;
      });
    }

    // Build total tasks/completed
    let totalText = '';
    if (project.jira_issues && project.jira_issues.stats.total > 0) {
      totalText = `${project.jira_issues.stats.done}/${project.jira_issues.stats.total} (${progressValue}%)`;
    }

    // Set updated colour to show alerts
    let updated = '';
    let updatedColour = 'green';
    if (project.jira_issues) {
      updated = moment(project.jira_issues.updated).fromNow();

      const diff = moment().diff(project.jira_issues.updated, 'days');
      if (diff > 5) updatedColour = 'red';
    }

    // Set action toolbar
    const actions = (
      <div className="text-nowrap">
        {project.jira_issues && (
          <Icon
            onClick={(e) => openTasks(e, project.id)}
            title="Jira Tasks"
            name="tasks"
            className="text-primary mr-2"
          />
        )}
        {project.jira_issues && (
          <Icon
            onClick={(e) => openComments(e, project.id, project.jira_key)}
            title="Jira Comments"
            name="comments"
            className="text-success mr-2"
          />
        )}
        <Icon
          onClick={(e) => openProperties(e, project.id)}
          title="Project Properties"
          name="draw-polygon"
          className="text-primary mr-2"
        />
      </div>
    );

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'last_comment':
          return <td key={index}>{lastComment}</td>;
        case 'jira_progress':
          return (
            <td key={index}>{project.jira_key ? <Progress value={progressValue} /> : <div />}</td>
          );
        case 'jira_subtasks':
          return <td key={index}>{project.jira_key ? totalText : <div />}</td>;
        case 'jira_status':
          return <td key={index}>{statusPills}</td>;
        case 'jira_stage':
          return (
            <td key={index} style={{ width: 120 }}>
              {stageText}
            </td>
          );
        case 'jira_updated':
          return (
            <td key={index} style={{ color: updatedColour }}>
              {updated}
            </td>
          );
        case 'actions':
          return (
            <td key={index} className={header.classes}>
              {actions}
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {project[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: 'Name', field: 'name' },
    { caption: 'Jira Key', field: 'jira_key' },
    { caption: 'Progress', field: 'jira_progress' },
    { caption: 'Sub tasks', field: 'jira_subtasks' },
    { caption: 'Stage', field: 'jira_stage' },
    { caption: 'Tasks', field: 'jira_status' },
    { caption: 'Last Comment', field: 'last_comment' },
    { caption: 'Updated', field: 'jira_updated' },
    { caption: 'Actions', field: 'actions', classes: 'text-right' },
  ];

  const tableHeadTh = headers.map((header, index) => <th key={index}>{header.caption}</th>);

  let tableBodyTr = <tr></tr>;
  const haveRows = projects && projects.length > 0;

  if (haveRows) {
    tableBodyTr = projects.map((project, index) => (
      <React.Fragment key={index}>
        <tr onClick={() => onClick(project)} style={{ cursor: 'pointer' }}>
          {renderRows(headers, project)}
        </tr>
        {isTaskOpen(project.id) && (
          <tr>
            <td className="bg-white" colSpan={headers.length}>
              {renderTasks(project)}
            </td>
          </tr>
        )}
        {isCommentOpen(project.id) && (
          <tr>
            <td className="bg-white" colSpan={headers.length}>
              {renderComments(project)}
            </td>
          </tr>
        )}
        {isPropertyOpen(project.id) && (
          <tr>
            <td className="bg-white" colSpan={headers.length}>
              {renderProperties(project)}
            </td>
          </tr>
        )}
      </React.Fragment>
    ));
  }

  const iconName = 'diagram-project';
  const emptyCaption = 'No Projects found';

  return (
    <React.Fragment>
      <Listview
        rows={projects}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default TaskManagementLsv;
