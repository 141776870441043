/**
 * This function is used to ensure we do not try and reauthenticate the masquerading user.
 * We always want to expire the access token/is-masquerade cookie logic.
 */
export const invalidateMasqueradUser = () => {
  // Check if access token exists for masquerade user in cookies
  const cookies = document.cookie.split(';');
  const xAccessToken = cookies.find((cookie) => cookie.includes('x-access-token'));
  const isMasquerading = cookies.find((cookie) => cookie.includes('is-masquerade'));

  if (xAccessToken && isMasquerading) {
    // Expire xAccessToken and isMasquerading cookies
    document.cookie = 'x-access-token=; Max-Age=0; path=/';
    document.cookie = 'is-masquerade=; Max-Age=0; path=/';
  }
};
