import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'text',
    caption: 'Site UUID',
    disabled: true,
    validationRules: {}
  },
  strata_id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  name: {
    ...controlDefaults,
    placeholder: 'Site No',
    name: 'name',
    type: 'text',
    caption: 'Site No',
    validationRules: {
      minLength: 1,
      isRequired: true
    },
  },
  status_id: {
    ...controlDefaults,
    name: 'status_id',
    type: 'select',
    caption: 'Status'
  },
  target_latitude: {
    ...controlDefaults,
    name: 'target_latitude',
    type: 'text',
    caption: 'Target Latitude',
    disabled: true,
  },
  target_longitude: {
    ...controlDefaults,
    name: 'target_longitude',
    type: 'text',
    caption: 'Target Longitude',
    disabled: true,
  },
  actual_latitude: {
    ...controlDefaults,
    name: 'actual_latitude',
    type: 'text',
    caption: 'Actual Latitude',
    disabled: true,
  },
  actual_longitude: {
    ...controlDefaults,
    name: 'actual_longitude',
    type: 'text',
    caption: 'Actual Longitude',
    disabled: true,
  },
  collected_at: {
    ...controlDefaults,
    name: 'collected_at',
    type: 'date',
    caption: 'Collected At',
    disabled: false,
  },
  collected_by: {
    ...controlDefaults,
    name: 'collected_by',
    type: 'select',
    caption: 'Collected By',
    disabled: false,
  },
  vegetation: {
    ...controlDefaults,
    name: 'vegetation',
    type: 'text',
    caption: 'Vegetation',
    disabled: false,
  },
  landform: {
    ...controlDefaults,
    name: 'landform',
    type: 'text',
    caption: 'Land form',
    disabled: false,
  },
  condition: {
    ...controlDefaults,
    name: 'condition',
    type: 'text',
    caption: 'Land form',
    disabled: false,
  },
  soil_type_asc: {
    ...controlDefaults,
    name: 'soil_type_asc',
    type: 'select',
    caption: 'Australian Soil Classification',
    options: [
      {id: true, name: 'Need lookup data'},
    ],
  },
  soil_type_gsg: {
    ...controlDefaults,
    name: 'soil_type_gsg',
    type: 'select',
    caption: 'Great Soil Group',
    options: [
      {id: true, name: 'Need lookup data'},
    ],
  },
  soil_series: {
    ...controlDefaults,
    name: 'soil_series',
    type: 'select',
    caption: 'Soil Series',
    options: [
      {id: true, name: 'Need lookup data'},
    ],
  },
  target_depth: {
    ...controlDefaults,
    name: 'target_depth',
    type: 'text',
    caption: 'Target Depth',
    disabled: false,
    validationRules: {},
  },
  actual_depth: {
    ...controlDefaults,
    name: 'actual_depth',
    type: 'text',
    caption: 'Actual Depth',
    disabled: false,
    validationRules: {},
  },
  stickup_height: {
    ...controlDefaults,
    name: 'stickup_height',
    type: 'text',
    caption: 'Stickup Height',
    disabled: false,
    validationRules: {}
  },
  area_core_cutting_radius: {
    ...controlDefaults,
    placeholder: '',
    name: 'area_core_cutting_radius',
    type: 'text',
    caption: 'Radius of core cutting device',
    description: 'Radius of cutting coring device (cm)',
    validationRules: {
      isRequired: false
    },
  },
  notes: {
    ...controlDefaults,
    placeholder: 'Notes on site location and any changes...',
    name: 'notes',
    type: 'textarea',
    textRows: 5,
    caption: 'Notes/Comment',
  },
  scanned_at: {
    ...controlDefaults,
    name: 'scanned_at',
    type: 'date',
    caption: 'Core Scanned At',
    disabled: false,
  },
  scannedby_id: {
    ...controlDefaults,
    name: 'scannedby_id',
    type: 'select',
    caption: 'Scanned By',
    disabled: false,
  },
  upload_scan_gamma_cal:{
    ...controlDefaults,
    placeholder: 'Upload...',
    name: 'upload_scan_gamma_cal',
    type: 'file',
    caption: 'Gamma Calibrations',
    fileUploaded: true,
    validationRules: {},
  },
  upload_scan_spec_cal:{
    ...controlDefaults,
    placeholder: 'Upload...',
    name: 'upload_scan_spec_cal',
    type: 'file',
    caption: 'Spectra Calibrations',
    fileUploaded: false,
    validationRules: {},
  },
  upload_scan_samples:{
    ...controlDefaults,
    placeholder: 'Upload...',
    name: 'upload_scan_samples',
    type: 'file',
    caption: 'Samples',
    fileUploaded: false,
    validationRules: {},
  },
  upload_scan_params:{
    ...controlDefaults,
    placeholder: 'Upload...',
    name: 'upload_scan_params',
    type: 'file',
    caption: 'Scanner Parameters',
    fileUploaded: false,
    validationRules: {},
  },
  scan_valid: {
    ...controlDefaults,
    name: 'scan_valid',
    type: 'select',
    caption: 'Scan Validation',
    disabled: true,
    validationRules: {},
    options: [
      {id: null, name: 'Not Validated'},
      {id: false, name: 'Failed'},
      {id: true, name: 'Ok'}
    ]
  },
  scan_valid_tag: {
    ...controlDefaults,
    name: 'scan_valid_tag',
    type: 'text',
    caption: 'Scan Validation Status',
    disabled: true,
    validationRules: {}
  },
}