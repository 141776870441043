import { apiProjectPhasecodes } from 'jsx/constants/api';
import { get, post, put, remove } from '../../../../lib/genericAction';
const name = 'PROJECT_PHASECODE';

export function fetchProjectPhasecodes(params) {
  return (dispatch) => get(dispatch, `FETCH_${name}S`, `${apiProjectPhasecodes}`, params);
}

export function fetchProjectPhasecode(params) {
  return (dispatch) =>
    get(dispatch, `FETCH_${name}`, `${apiProjectPhasecodes}/${params.id}`, params);
}

export function createProjectPhasecode(data) {
  return (dispatch) => post(dispatch, `CREATE_${name}`, `${apiProjectPhasecodes}`, data);
}

export function updateProjectPhasecode(data) {
  return (dispatch) => put(dispatch, `UPDATE_${name}`, `${apiProjectPhasecodes}/${data.id}`, data);
}

export function removeProjectPhasecode(id) {
  return (dispatch) => remove(dispatch, `REMOVE_${name}`, `${apiProjectPhasecodes}/${id}`);
}
