import React from 'react';
import Listview from "../../../core/form/components/Listview";

const FieldUploadsLsv = (props) => {
  const {
    rows,
    clickRow,
    max_distance
  } = props;

  const setYesNoColour = (value) => {
    return (value === 'Yes' ? 'text-success' : 'text-danger');
  }

  const renderRows = (headers, site) => {

    const target = (site.target_latitude ? 'Yes': 'No');
    const actual = (site.actual_latitude ? 'Yes': 'No');
    const distance = `${site.distance.toFixed(1)}m`;
    const inside_strata = (site.inside_strata ? 'Yes': 'No');
    let collected_tz = '';
    if (site.collected_tz.length > 0) {
      collected_tz = site.collected_tz[0];
    }

    const tableTd = headers.map((header, index) => {
      let classes = header.classes;

      switch (header.field) {
        case 'timezone': 
          return  (
            <td key={index} className={classes}>{collected_tz}</td>
          )
        case 'target': 
          classes += ` ${setYesNoColour(target)}`;
          return  (
            <td key={index} className={classes}>{target}</td>
          )
        case 'actual': 
          classes += ` ${setYesNoColour(actual)}`;
          return  (
            <td key={index} className={classes}>{actual}</td>
          )
        case 'distance': 
          classes += ` ${(site.distance > max_distance ? 'text-danger' : 'text-success')}`;
          return  (
            <td key={index} className={classes}>{distance}</td>
          )
        case 'inside_strata': 
          classes += ` ${setYesNoColour(inside_strata)}`;
          return  (
            <td key={index} className={classes}>{inside_strata}</td>
          )
        default: return  (
          <td key={index} className={classes}>{site[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const headers = [
    {caption: 'Core ID', field: 'core_id', classes:'text-left'},
    {caption: 'CEA-Strata', field: 'cea_strata_name', classes:'text-left'},
    {caption: 'Collect Time Zone', field: 'timezone', classes:'text-left'},
    {caption: 'Collected at', field: 'collected_at_tz', classes:'text-left'},
    {caption: 'Collected GMT', field: 'collected_at', classes:'text-left'},
    {caption: 'Target Coordinates (Green)', field: 'target', classes:'text-center'},
    {caption: 'Actual Coordinates (Blue)', field: 'actual', classes:'text-center'},
    {caption: 'Target/Actual Distance', field: 'distance', classes:'text-right'},
    {caption: 'Inside Strata', field: 'inside_strata', classes:'text-right'},
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} style={{cursor: 'pointer'}} onClick={() => clickRow(row)}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='location-pin';
  const emptyCaption='No Sites associated with this instrument upload found';

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
}

export default FieldUploadsLsv;
