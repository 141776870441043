import { defaults } from '../../../../lib/genericReducer';

export default function reducer(
  // eslint-disable-next-line default-param-last
  state = {
    fetching: false,
    fetched: false,
    failedMessage: null,
    downloadFileMeta: {},
    downloadFile: {},
  },
  action,
) {
  const copiedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_REPORT_META_FULFILLED': {
      return {
        ...state,
        downloadFileMeta: action.payload,
      };
    }
    case 'FETCH_REPORT_META_REJECTED': {
      return {
        ...state,
        failedMessage: action.payload,
      };
    }
    case 'DOWNLOAD_REPORT_FULFILLED': {
      return {
        ...state,
        downloadFile: action.payload,
      };
    }
    default:
      break;
  }

  return copiedState;
}
