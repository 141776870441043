import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    activeTab: '1',
    distributions: {},
    fetching: false,
    fetched: false,
    filters: {
      groups: [],
    },
    overheads: {},
    overheadflows: [],
    params: {},
    responseMessage: null,
  },
  action
) {
  // Set expected reducer types
  const types = ['OVERHEAD'];

  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_OVERHEADS_FULFILLED': {
      return {
        ...state,
        overheads: action.payload,
      };
    }
    case 'FETCH_OVERHEAD_DISTRIBUTIONS_FULFILLED': {
      return {
        ...state,
        distributions: action.payload,
      };
    }
    case 'FETCH_OVERHEADFLOWS_FULFILLED': {
      return {
        ...state,
        overheadflows: action.payload,
      };
    }
    case 'SET_OVERHEAD_FLOW_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case 'SET_OVERHEAD_PARAMS': {
      return {
        ...state,
        params: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
