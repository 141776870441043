import React, { useState } from 'react';
import {
  Button
} from 'reactstrap';
import Listview from "../../../../core/form/components/Listview";
import ResponseMessage from "../../../../core/form/components/ResponseMessageTab";

import PropertyRoundModal from '../../containers/property_rounds/PropertyRoundModal';

const PropertyRoundsLsv = (props) => {
  const {
    match,
    rows,
    history,
    checkAccess,
    responseMessage
  } = props;

  const [isOpen, setModal] = useState(false);
  const [id, setId] = useState(null);
  const property_id = match.params.id;
  const project_id = match.params.project_id;

  const edit = (id) => {
    setId(id);
    setModal(true);
  }

  const editRound = (round) => {
    const lnk = `/home/projects/${project_id}/properties/${property_id}/rounds/${round.id}`;
    history.push(lnk);
  }

  const renderRows = (headers, round) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'data_preparation_name': return (
          <td className={header.classes} key={index}>{round.data_preparation?.name || '-'}</td>
        )
        case 'methodology_name': return (
          <td className={header.classes} key={index}>{round.methodology?.name || '-'}</td>
        )
        default: return (
          <td className={header.classes} key={index}>{round[header.field]}</td>
        )
      }
    });
    return tableTd;
  }

  const headers = [
    { caption: 'Round', field: 'name', classes: 'text-left' },
    { caption: 'Data Preparation', field: 'data_preparation_name', classes: 'text-left' },
    { caption: 'Methodology', field: 'methodology_name', classes: 'text-left' },
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  const iconName = 'farm';
  const emptyCaption = 'No Rounds found';

  let tableBodyTr = (<tr></tr>);
  if (rows.length > 0) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => editRound(row)} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    )
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-end border-bottom border-light p-2" >
        <ResponseMessage responseMessage={responseMessage} />
        <Button disabled={!checkAccess('roundCreate')} color="success" size="sm" onClick={() => edit(null)}>Add Property Round</Button>
      </div>
      <PropertyRoundModal setModal={setModal} property_id={property_id} id={id} isOpen={isOpen} />
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </React.Fragment>
  );
}

export default PropertyRoundsLsv;
