import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import { cloneDeep, isEmpty, isEqual, merge, set } from 'lodash';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';
import Icon from 'jsx/components/core/icons/Icon';
import { fetchUsers } from 'jsx/components/manage/actions';
import {
  initControls,
  saveControls,
  updateControlFormulas,
  updateControlOptions,
  updateControls,
  validateChange,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';

import { baseActivityControls as blueprintActivityControls } from '../forms/daysheet_activity';
import { breakControls as blueprintBreakControls } from '../forms/daysheet_break';
import { controls as blueprintTimesheetControls } from '../forms/daysheet_timesheet';

import FormBase from '../../../core/form/components/FormBase';
import FormInput from '../../../core/form/components/FormInput';
import DaysheetInlineEntry from '../components/DaysheetInlineEntry';
import DaysheetFilterModal from './DaysheetFilterModal';

import { fetchProjects } from '../../projects/actions/projects';
import { fetchPlantAssets } from '../actions/assets';
import {
  createDaysheet,
  deleteDaysheet,
  fetchDaysheet,
  updateDaysheet,
} from '../actions/daysheets';
import { fetchJobs } from '../actions/jobs';
import { fetchOrgPlantAssetProjects } from '../actions/locations';

import {
  createDaysheetActivity,
  deleteDaysheetActivity,
  fetchDaysheetActivities,
  fetchDaysheetActivityByUser,
  updateDaysheetActivity,
} from '../actions/daysheet_activities';

import { createDaysheetBreaks, fetchDaysheetBreaks } from '../actions/daysheet_breaks';

import TimeFormat from '../../office/lib/timeFormat';
import { fetchShift, fetchShifts } from '../actions/rosters/shifts';
import ActivitiesSubLsv from '../components/ActivitiesSubLsv';

import BreakPills from '../components/BreakPills';
import DaysheetStatus from '../components/DaysheetStatus';
import DaysheetInlineEntryModal from './DaysheetInlineEntryModal';
import { normaliseNumbers } from '../lib/normalise';

class DaysheetActivityModal extends FormBase {
  constructor(props) {
    super(props);

    this.assetFieldReference = React.createRef();
    this.format = new TimeFormat();

    this.state = {
      activityControls: cloneDeep(blueprintActivityControls),
      breakControls: cloneDeep(blueprintBreakControls),
      controls: cloneDeep(blueprintTimesheetControls),
      activityCount: 0,
      breakCount: 0,
      activitiesInitialized: false,
      breaksInitialized: false,
      data: {},
      daysheet: {},
      daysheetId: null,
      isAssetSelected: false,
      isNew: false,
      selectedUser: null,
      title: 'Daysheet',
      activityModalOpen: false,
      breakModalOpen: false,
      activityData: {},
      filterModalOpen: false,
      activities: [],
    };

    this.clearActivity = this.clearActivity.bind(this);
    this.createBreaksFromShift = this.createBreaksFromShift.bind(this);

    this.formatDateTimeString = this.formatDateTimeString.bind(this);

    this.onActivityCreate = this.onActivityCreate.bind(this);
    this.onActivityEdit = this.onActivityEdit.bind(this);
    this.onActivityUpdate = this.onActivityUpdate.bind(this);
    this.onActivityRemove = this.onActivityRemove.bind(this);

    this.onChange = this.onChange.bind(this);
    this.onLiveChange = this.onLiveChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onShiftChange = this.onShiftChange.bind(this);
    this.onUserChange = this.onUserChange.bind(this);

    this.setActivityModal = this.setActivityModal.bind(this);
    this.loadActivities = this.loadActivities.bind(this);

    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.renderShiftFields = this.renderShiftFields.bind(this);

    this.updateControlsFromShift = this.updateControlsFromShift.bind(this);

    this.setFilterModal = this.setFilterModal.bind(this);
    this.onClearModalFilters = this.onClearModalFilters.bind(this);
  }

  async componentDidUpdate(prevProps) {
    const { isOpen, daysheetId } = this.props;
    if (prevProps.isOpen !== isOpen && isOpen) {
      const activityControls = initControls(cloneDeep(blueprintActivityControls));
      const controls = initControls(cloneDeep(blueprintTimesheetControls));
      const breakControls = initControls(cloneDeep(blueprintBreakControls));

      // Set default shift start date.
      const { selectedDate } = this.props.office;
      controls.shift_start.value = selectedDate.format(this.format.day);

      /**
       * Disable greaterThanZero validation rule by default.
       * Once asset field changes, evaluate and set rule.
       */
      activityControls.asset_hours.validationRules.greaterThanZero = false;

      let updatedState = {
        activityCount: 0,
        breakCount: 0,
        activityControls,
        breakControls,
        controls,
        daysheet: {},
        daysheetId: null,
        isNew: true,
        title: 'New Daysheet',
      };

      if (daysheetId) {
        const daysheet = await this.props.dispatch(fetchDaysheet(daysheetId));
        const updatedControls = updateControls(controls, daysheet);

        // Get shift and update shift state variables
        updatedState = {
          ...updatedState,
          activityCount: daysheet?.activities?.length,
          breakCount: daysheet?.breaks?.length,
          controls: updatedControls,
          daysheet,
          daysheetId,
          isAssetSelected: false,
          isNew: false,
          title: 'Edit Daysheet',
        };

        this.loadActivities();
        this.props.dispatch(fetchDaysheetBreaks(daysheetId));
        this.setState({ activitiesInitialized: true, breaksInitialized: true });
      }

      this.setState({ ...updatedState }, () =>
        Promise.all([this.populateActivityDropdowns(), this.populateInitialDropdowns()].flat()),
      );
    }

    // Fetch plant assets if filters change
    if (!isEqual(prevProps.wipstar.params, this.props.wipstar.params)) {
      this.populateActivityDropdowns();
    }
  }

  clearActivity() {
    const activityControls = initControls(cloneDeep(blueprintActivityControls));
    activityControls.asset_hours.validationRules.greaterThanZero = false;
    this.setState({ activityControls, isAssetSelected: false });
  }

  // This should probably be happening on API. If we change, we have to adjust native environment as well
  async createBreaksFromShift(daysheet_id = null, shift = null) {
    if (!daysheet_id || !shift) return;

    const { roster_shift_slots } = shift;
    const filteredSlots = roster_shift_slots.filter(({ type }) => type.tag === 'break');
    if (filteredSlots.length === 0) return;

    const breaks = filteredSlots.map(({ caption, start_time, end_time, is_paid }) => {
      const startTime = moment(start_time, this.format.time);
      const endTime = moment(end_time, this.format.time);

      const hours = endTime.diff(startTime, 'hours', true);
      return { caption, hrs: parseFloat(hours), is_paid };
    });

    await this.props.dispatch(createDaysheetBreaks({ breaks, daysheet_id }));
  }

  formatDateTimeString({ dateString, timeString, days = 0, isAddition = true }) {
    let date = moment(dateString);
    const time = moment(timeString, this.format.time);

    // Handle adding/subtracting days to get the final date.
    if (days !== 0) {
      date = isAddition ? date.add(days, 'days') : date.subtract(days, 'days');
    }

    // Set time to date.
    date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second'),
    });

    return date;
  }

  async populateInitialDropdowns() {
    const { dispatch } = this.props;

    return [dispatch(fetchShifts({ enabled: true })), dispatch(fetchUsers())];
  }

  async populateActivityDropdowns() {
    const { dispatch, wipstar } = this.props;
    const { locations, projects } = wipstar.params;
    const { controls } = this.state;
    const commonFilters = {
      filter_projects: projects,
      filter_locations: locations,
    };

    return [
      dispatch(
        fetchJobs({
          ...commonFilters,
          rules: { isActive: true },
        }),
      ),
      dispatch(
        fetchPlantAssets({
          ...commonFilters,
          // REVISIT: Is this firing before daysheet details are loaded?
          from_date: controls.from_datetime.value,
          to_date: controls.to_datetime.value,
        }),
      ),
      dispatch(fetchOrgPlantAssetProjects(commonFilters)),
      dispatch(fetchProjects()),
    ];
  }

  setActivityModal(activityModalOpen, activityData = null) {
    this.setState({
      activityModalOpen,
      activityData,
    });
  }

  async loadActivities() {
    const daysheetId = this.state.daysheetId || this.props.daysheetId;
    if (!daysheetId) return;

    const { rows } = await this.props.dispatch(fetchDaysheetActivities(daysheetId));
    this.setState({ activities: rows });
  }

  async onActivityCreate() {
    const { activityControls, daysheetId } = this.state;
    let data = saveControls(activityControls, {});
    const { isValid, updatedControls } = await validateFormFieldControls(data, activityControls);

    if (!isValid) {
      this.setState({
        activityControls: updatedControls,
      });
      return;
    }

    data = normaliseNumbers(data, activityControls);

    const success = await this.props.dispatch(createDaysheetActivity(data, daysheetId));

    if (!success) {
      return;
    }

    this.loadActivities();
    this.clearActivity();
  }

  async onActivityEdit(activityData) {
    this.setActivityModal(true, activityData);
  }

  async onActivityUpdate(data) {
    const { daysheetId, activityControls } = this.state;
    const dataToSave = normaliseNumbers(data, activityControls);

    const success = await this.props.dispatch(updateDaysheetActivity(dataToSave, daysheetId));

    return success;
  }

  async onActivityRemove(id, includeConfirmation = false) {
    if (includeConfirmation) {
      // eslint-disable-next-line no-alert
      const confirmed = window.confirm('Removing activity permanently. Continue?');
      if (!confirmed) return false;
    }

    const success = await this.props.dispatch(deleteDaysheetActivity(id));
    await this.loadActivities();

    return success;
  }

  async onSave(close = true) {
    let { controls } = this.state;
    const { isNew } = this.state;
    let { data } = this.state;

    let shift;

    if (isNew) {
      shift = await this.props.dispatch(fetchShift({ id: controls.shift_id.value }));
      controls = this.updateControlsFromShift(controls, controls.shift_start.value, shift);
    }

    data = saveControls(controls, data);

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (!isValid) {
      this.setState({
        controls: updatedControls,
      });
      return;
    }

    let success;
    if (isNew) {
      delete data.id;

      success = await this.props.dispatch(createDaysheet(data));
      const id = success?.id;
      if (id) {
        await this.createBreaksFromShift(id, shift);
        if (close) this.onClose(false, true, id);
      }
    } else {
      success = await this.props.dispatch(updateDaysheet(data));
      if (success && close) this.onClose(true);
    }
  }

  onClose(refresh = true, reopen = false, id = null) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();

    this.props.setModal(false);
    this.resetState();
    this.props.dispatch({ type: 'UNSET_DAYSHEET_ACTIVITIES' });
    this.props.dispatch({ type: 'UNSET_DAYSHEET_BREAKS' });

    if (reopen) {
      this.setState({ isOpen: false }, () => this.props.setModal(true, id));
    }
  }

  onClearModalFilters() {
    this.props.dispatch({ type: 'CLEAR_DAYSHEET_MODAL_FILTERS' });
    this.setFilterModal(false);
  }

  async onRemove() {
    const { daysheetId } = this.state;

    // eslint-disable-next-line no-alert
    const confirmed = window.confirm('Removing daysheet permanently. Continue?');
    if (!confirmed) return;

    const success = await this.props.dispatch(deleteDaysheet(daysheetId));
    if (success) this.onClose(true);
  }

  async onLiveChange(event) {
    if (!event.target.value) return;

    const { daysheetId } = this.state;
    const controls = validateChange(event, this.state.controls);

    this.setState({ controls }, async () => {
      await this.onSave(false);

      const daysheet = await this.props.dispatch(fetchDaysheet(daysheetId));
      this.setState({ daysheet });

      this.loadActivities(daysheetId);
    });
  }

  onChange(event) {
    const { value, name } = event.target;
    let updatedActivityControls = cloneDeep(this.state.activityControls);
    updatedActivityControls[name].value = value;

    // Evaluate formula and update controls
    if (['smu_start', 'smu_end'].includes(name)) {
      updatedActivityControls = updateControlFormulas(updatedActivityControls);
    }

    this.setState({ activityControls: updatedActivityControls });
  }

  onSelectChange(event) {
    const { value, label: field } = event;

    const { isAssetSelected: isCurrentAssetSelected } = this.state;

    const { changes, isAssetSelected } = this.getChanges(field, value, isCurrentAssetSelected);

    // Merge changes into activityControls
    const updatedControls = merge(cloneDeep(this.state.activityControls), changes);
    let updatedState = {
      activityControls: updatedControls,
    };

    if (isAssetSelected !== isCurrentAssetSelected) {
      updatedState = {
        ...updatedState,
        isAssetSelected,
      };
    }

    this.setState(updatedState);
  }

  getChanges = (field, value, stateIsAssetSelected) => {
    let isAssetSelected = stateIsAssetSelected;
    let changes = { [field]: { value } };

    if (field === 'asset_id') {
      isAssetSelected = !isEmpty(value);
      changes.asset_hours = { validationRules: { greaterThanZero: isAssetSelected } };

      // Clear asset_hours/smu_start/smu_end/load_count if no asset is selected.
      if (!isAssetSelected) {
        changes = {
          ...changes,
          asset_hours: { ...changes.asset_hours, value: 0 },
          smu_start: { value: null },
          smu_end: { value: null },
          load_count: { value: 0 },
        };
      }
    }

    if (field === 'job_id') {
      // Read description from selected job
      changes.description = {};
      if (!isEmpty(value)) {
        const job = this.props.wipstar.jobs.rows.find(({ id }) => id === value);
        changes.description.value = job.description;
      } else {
        // Clear description field
        changes.description.value = '';
      }
    }

    return { changes, isAssetSelected };
  };

  async onShiftChange({ value }) {
    let { controls } = this.state;
    const { isNew } = this.state;
    if (!isNew) {
      // eslint-disable-next-line no-alert
      const confirm = window.confirm(
        'Warning: Changing the shift will clear shift times to match new template. Breaks and activities are not affected. Continue?',
      );

      if (confirm) {
        controls.shift_id.value = value;
        const shift = await this.props.dispatch(fetchShift({ id: value }));
        controls = this.updateControlsFromShift(controls, controls.from_datetime.value, shift);

        this.setState({ controls }, () => this.onSave(false));
      }
    } else {
      controls.shift_id.value = value;
      this.setState({ controls });
    }
  }

  onUserChange({ value }) {
    const { controls, isNew } = this.state;
    controls.user_id.value = value;

    this.setState({ controls, selectedUser: value }, () => {
      if (!isNew) this.onSave(false);
    });
  }

  renderActionButtons(closeDisabled) {
    const { isNew } = this.state;
    return (
      <>
        {isNew && (
          <Button size="sm mr-2" color="success" onClick={this.onSave}>
            Save/Continue
          </Button>
        )}
        {!isNew && (
          <Button
            size="sm mr-2"
            color="primary"
            onClick={() => this.onClose(true, true)}
            disabled={closeDisabled}
          >
            Close & Add New
          </Button>
        )}
        <Button
          size="sm"
          className="mr-2"
          color="light"
          onClick={this.onClose}
          disabled={closeDisabled}
        >
          {isNew ? 'Cancel' : 'Close'}
        </Button>
        {!isNew && (
          <Button size="sm" color="danger" onClick={this.onRemove}>
            Delete
          </Button>
        )}
      </>
    );
  }

  renderShiftFields(controls, isNew) {
    return (
      <>
        {isNew ? (
          <>
            <Col sm={4}>
              <FormInputSelect control={controls.user_id} handleChange={this.onUserChange} />
            </Col>
            <Col sm={4}>
              <FormInputSelect control={controls.shift_id} handleChange={this.onShiftChange} />
            </Col>
            <Col sm={4}>
              <FormInput control={controls.shift_start} handleChange={this.handleChange} />
            </Col>
          </>
        ) : (
          <>
            <Col sm={3}>
              <FormInputSelect control={controls.user_id} handleChange={this.onUserChange} />
            </Col>
            <Col sm={3}>
              <FormInputSelect control={controls.shift_id} handleChange={this.onShiftChange} />
            </Col>
            <Col sm={3}>
              <FormInput handleChange={this.onLiveChange} control={controls.from_datetime} />
            </Col>

            <Col sm={3}>
              <FormInput handleChange={this.onLiveChange} control={controls.to_datetime} />
            </Col>
          </>
        )}
      </>
    );
  }

  resetState() {
    this.setState({
      activitiesInitialized: false,
      breaksInitialized: false,
      isAssetSelected: false,
      selectedUser: null,
      activities: [],
      daysheetId: null,
    });
  }

  setControlOptions = (controls, controlOptions) => {
    let updatedControls = cloneDeep(controls);
    Object.entries(controlOptions).forEach(([key, options]) => {
      updatedControls = updateControlOptions(controls, key, options);
    });

    return updatedControls;
  };

  async setFilterModal(state) {
    this.setState({ filterModalOpen: state });
  }

  updateControlsFromShift(controls, start_date, shift = null) {
    const updatedControls = cloneDeep(controls);
    if (!shift) return false;

    // Extract target fields from shift/slots/allocations.
    const { roster_shift_slots = [] } = shift;
    if (roster_shift_slots.length === 0) return false;

    const { value: shiftStartDate } = controls.shift_start;
    // Derive start/end date times from first/last shift slots.
    const [{ start_day, start_time }] = roster_shift_slots;
    const [{ end_day, end_time }] = roster_shift_slots.slice(-1);

    const fromDateTime = this.formatDateTimeString({
      dateString: shiftStartDate,
      timeString: start_time,
      days: start_day,
      isAddition: start_day >= 0,
    });
    const toDateTime = this.formatDateTimeString({
      dateString: shiftStartDate,
      timeString: end_time,
      days: end_day,
    });

    updatedControls.from_datetime.value = fromDateTime;
    updatedControls.to_datetime.value = toDateTime;

    return updatedControls;
  }

  onFilter = async (type, property, payload) => {
    if (isEmpty(payload)) {
      return;
    }

    this.clearActivity();
    await this.props.onFilter(type, property, payload);
    this.populateActivityDropdowns();
  };

  hasData = (controls) =>
    Object.entries(controls).filter(
      ([, control]) => control.value !== null && control.value !== '' && control.value !== 0,
    ).length > 0;

  getSelectedUsersLastActivityDetails = async () => {
    const { value: user_id } = this.state.controls.user_id;

    // Get last activity details
    const activity = await this.props.dispatch(fetchDaysheetActivityByUser(user_id));
    if (!activity) return;

    // Update activity controls and set into state
    const { activityControls } = this.state;
    const updatedActivityControls = updateControls(activityControls, activity);
    this.setState({ activityControls: updatedActivityControls });
  };

  render() {
    let { activityControls, controls } = this.state;
    const {
      breakControls,
      isAssetSelected,
      isNew,
      selectedUser,
      title,
      activityData,
      activityModalOpen,
      filterModalOpen,
      activities,
      daysheet,
    } = this.state;

    const { isOpen } = this.props;
    const { users } = this.props.manage;
    const { currentUser } = this.props.profile;
    const { responseMessage: rostersResponseMessage, shifts } = this.props.rosters;
    const {
      daysheetBreaks,
      responseMessage: wipstarResponseMessage,
      jobs,
      assets,
      params,
    } = this.props.wipstar;

    const iconName = 'person-digging';

    const activityControlOptions = { job_id: jobs?.rows, asset_id: assets?.rows };
    activityControls = this.setControlOptions(activityControls, activityControlOptions);

    const controlOptions = { shift_id: shifts.rows, user_id: users };
    controls = this.setControlOptions(controls, controlOptions);

    // Set current user as default user when creating new daysheets
    if (isNew) {
      if (controls.user_id.value === null) controls.user_id.value = selectedUser || currentUser.id;
    }

    const hasActivityData = this.hasData(activityControls);
    const hasBreakData = this.hasData(breakControls);

    const isFilterSet = params.locations.length > 0 || params.projects.length;

    return (
      <Modal isOpen={isOpen} className="w80-modal">
        <ModalHeader className="bg-corporate text-white">
          <Icon name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {wipstarResponseMessage && (
            <div className="text-center text-danger">{wipstarResponseMessage}</div>
          )}
          {rostersResponseMessage && (
            <div className="text-center text-danger">{rostersResponseMessage}</div>
          )}
          <DaysheetInlineEntryModal
            controls={activityControls}
            modalTitle="Activity"
            setModal={this.setActivityModal}
            data={activityData}
            isAssetSelected={isAssetSelected}
            isOpen={activityModalOpen}
            iconName={iconName}
            onChange={this.onSelectChange}
            onSave={this.onActivityUpdate}
            onRemove={this.onActivityRemove}
            onClose={this.loadActivities}
            getChanges={this.getChanges}
            responseMessage={wipstarResponseMessage}
          />
          <Form>
            <Row>{this.renderShiftFields(controls, isNew)}</Row>
            {!isNew && (
              <>
                <div className="border-bottom border-corporate mt-1 text-corporate bg-light rounded-top">
                  <small className="ml-1">Activity</small>
                </div>

                <DaysheetInlineEntry
                  assetFieldReference={this.assetFieldReference}
                  controls={activityControls}
                  handleChange={this.onChange}
                  handleSelectChange={this.onSelectChange}
                  isAssetSelected={isAssetSelected}
                  onCopy={this.getSelectedUsersLastActivityDetails}
                  onSave={this.onActivityCreate}
                  onActivityClear={this.clearActivity}
                  addDisabled={!hasActivityData}
                  setFilterModal={this.setFilterModal}
                  isFilterSet={isFilterSet}
                />

                <DaysheetFilterModal
                  setModal={this.setFilterModal}
                  isModalOpen={filterModalOpen}
                  controls={controls}
                  clearActivity={this.clearActivity}
                  onFilter={this.props.onFilter}
                  onClearModalFilters={this.onClearModalFilters}
                />

                <div className="mt-2">
                  <ActivitiesSubLsv
                    removeAction={{
                      onClick: this.onActivityRemove,
                      iconName: 'trash',
                      classes: 'text-danger mt-1 cursor-pointer text-center pl-1 pr-1',
                    }}
                    editAction={{
                      onClick: this.onActivityEdit,
                      iconName: 'pen-to-square',
                      classes: 'text-success mt-1 cursor-pointer text-center pl-1 pr-1',
                    }}
                    rows={activities}
                  />
                </div>

                <div className="border-bottom border-corporate mt-1 text-corporate bg-light rounded-top">
                  <small className="ml-1">Breaks</small>
                </div>
                <BreakPills
                  rows={daysheetBreaks?.rows}
                  daysheet={daysheet}
                  loadActivities={this.loadActivities}
                />

                <div className="border-bottom border-corporate mt-3 text-corporate bg-light rounded-top">
                  <small className="ml-1">Status</small>
                </div>
                <DaysheetStatus
                  daysheet={this.state.daysheet}
                  activities={activities}
                  breaks={daysheetBreaks}
                />
              </>
            )}
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          {this.renderActionButtons(hasActivityData || hasBreakData)}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ manage, office, profile, projects, rosters, wipstar }) => ({
  manage,
  office,
  profile,
  projects,
  rosters,
  wipstar,
});

export default connect(mapStoreToProps)(DaysheetActivityModal);
