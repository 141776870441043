import React, { Fragment, useState } from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import FormInputSmall from '../../../../core/form/components/FormInputSmall';
import FormInput from '../../../../core/form/components/FormInput';
import Switch from 'react-switch';
import FormSlider from '../../../../core/form/components/FormSlider';

import { Collapse } from 'reactstrap';

const PropertySearchSourceLayer = (props) => {
  const { layer, setLayerProperty, setLayerZoomRange } = props;

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const name = layer.name || layer.type;

  let attributes = [];
  switch (layer.type.toLowerCase()) {
    case 'fill':
      attributes = [
        {
          value: layer['layout']['visibility'],
          name: 'Visibility',
          field: 'visibility',
          group: 'layout',
          type: 'toggle',
          options: ['visible', 'none'],
        },
        {
          value: layer['paint']['fill-color'],
          name: 'Fill Colour',
          field: 'fill-color',
          group: 'paint',
          type: 'colour',
        },
        {
          value: layer['paint']['fill-opacity'],
          name: 'Fill Transparency',
          field: 'fill-opacity',
          group: 'paint',
          type: 'percent',
        },
        {
          value: layer['paint']['fill-outline-color'],
          name: 'Fill Outline Colour',
          field: 'fill-outline-color',
          group: 'paint',
          type: 'colour',
        },
      ];
      break;
    case 'line':
      attributes = [
        {
          value: layer['layout']['visibility'],
          name: 'Visibility',
          field: 'visibility',
          group: 'layout',
          type: 'toggle',
          options: ['visible', 'none'],
        },
        {
          value: layer['paint']['line-color'],
          name: 'Line Colour',
          field: 'line-color',
          group: 'paint',
          type: 'colour',
        },
        {
          value: layer['paint']['line-opacity'],
          name: 'Line Transparency',
          field: 'line-opacity',
          group: 'paint',
          type: 'percent',
        },
        {
          value: layer['paint']['line-width'],
          name: 'Line Width',
          field: 'line-width',
          group: 'paint',
          type: 'number',
        },
      ];
      break;
    case 'symbol':
      attributes = [
        {
          value: layer['layout']['visibility'],
          name: 'Visibility',
          field: 'visibility',
          group: 'layout',
          type: 'toggle',
          options: ['visible', 'none'],
        },
        {
          value: layer['layout']['text-size'],
          name: 'Text Size',
          field: 'text-size',
          group: 'layout',
          type: 'number',
        },
        {
          value: layer['paint']['text-color'],
          name: 'Text Colour',
          field: 'text-color',
          group: 'paint',
          type: 'colour',
        },
        {
          value: layer['paint']['text-halo-color'],
          name: 'Text Halo Colour',
          field: 'text-halo-color',
          group: 'paint',
          type: 'colour',
        },
        {
          value: layer['paint']['text-halo-width'],
          name: 'Text Halo Width',
          field: 'text-halo-width',
          group: 'paint',
          type: 'number',
        },
      ];
      break;
    case 'raster':
      attributes = [
        {
          value: layer['layout']['visibility'],
          name: 'Visibility',
          field: 'visibility',
          group: 'layout',
          type: 'toggle',
          options: ['visible', 'none'],
        },
        {
          value: layer['paint']['raster-opacity'],
          name: 'Raster Transparency',
          field: 'raster-opacity',
          group: 'paint',
          type: 'percent',
        },
      ];
      if (layer['styles']) {
        attributes.push({
          value: layer['styles']['value'] ?? layer['styles']['options'][0],
          name: 'Styles',
          field: 'value',
          group: 'styles',
          type: 'select',
          options: layer['styles']['options'],
        });
      }
      break;
    case 'circle':
      attributes = [
        {
          value: layer['layout']['visibility'],
          name: 'Visibility',
          field: 'visibility',
          group: 'layout',
          type: 'toggle',
          options: ['none', 'visible'],
        },
        {
          value: layer['paint']['circle-color'],
          name: 'Circle Colour',
          field: 'circle-color',
          group: 'paint',
          type: 'colour',
        },
        {
          value: layer['paint']['circle-opacity'],
          name: 'Circle Transparency',
          field: 'circle-opacity',
          group: 'paint',
          type: 'percent',
        },
        {
          value: layer['paint']['circle-radius'],
          name: 'Circle Radius',
          field: 'circle-radius',
          group: 'paint',
          type: 'number',
        },
        {
          value: layer['paint']['circle-stroke-color'],
          name: 'Circle Stroke Colour',
          field: 'circle-stroke-color',
          group: 'paint',
          type: 'number',
        },
        {
          value: layer['paint']['circle-stroke-width'],
          name: 'Circle Stroke Width',
          field: 'circle-stroke-width',
          group: 'paint',
          type: 'number',
        },
      ];
      break;
    case 'styles':
      attributes = [];
      break;
    default:
      break;
  }

  const handleToggleChange = (checked, { field, group, options }) => {
    const value = checked ? options[0] : options[1];
    setLayerProperty(layer, group, field, value);
  };

  const handlePercentChange = (value, { field, group }) => {
    setLayerProperty(layer, group, field, value / 100);
  };

  const handleZoomChange = (value) => {
    setLayerZoomRange(layer, value[0], value[1]);
  };

  const handleInputChange = (event, { field, group }) => {
    const { value } = event.target;
    setLayerProperty(layer, group, field, parseInt(value));
  };

  const handleChange = (event, { field, group }) => {
    const { value } = event.target;
    setLayerProperty(layer, group, field, value);
  };

  const selectControl = (attribute) => {
    const { name, options, value, type } = attribute;

    switch (type) {
      case 'number':
        return (
          <FormInputSmall
            control={{ name, type, value }}
            handleChange={(event) => handleInputChange(event, attribute)}
          />
        );
      case 'toggle':
        const isChecked =
          attribute.options.findIndex((option) => option === value) === 0 ? true : false;
        return (
          <Switch
            height={18}
            width={42}
            onChange={(checked) => handleToggleChange(checked, attribute)}
            checked={isChecked}
          />
        );
      case 'percent':
        return (
          <FormSlider
            className="mt-1"
            max={100}
            min={0}
            value={attribute.value * 100}
            onChange={(value) => handlePercentChange(value, attribute)}
            onAfterChange={() => {}}
            style={{ railbackground: '#333333' }}
          />
        );
      case 'select':
        const select_control = {
          name,
          type,
          value,
          options,
        };
        return (
          <FormInput
            handleChange={(event) => handleChange(event, attribute)}
            control={select_control}
          />
        );
      default:
        break;
    }
  };

  const attributeRows = attributes.map((attribute, index) => {
    return (
      <div key={index} className="d-flex justify-content-between m-2">
        <small style={{ width: '60%' }}>{attribute.name}</small>
        <small className="d-flex justify-content-end" style={{ width: '40%' }}>
          {selectControl(attribute)}
        </small>
      </div>
    );
  });

  const zoomLabel = `Zoom Levels (${layer.minzoom} to ${layer.maxzoom}):`;

  return (
    <>
      <div
        className="d-flex justify-content-between p-1"
        style={{ borderBottom: '1px solid black' }}
      >
        <div className="d-flex justify-content-start mt-1">
          <small className="ml-1"> Layer: {name.toUpperCase()}</small>
        </div>
        <Icon onClick={toggle} name="gear" className="m-1" />
      </div>
      <Collapse isOpen={isOpen}>
        <div style={{ backgroundColor: '#111111' }} className="p-1">
          {attributeRows}

          <div className="m-2">
            <small>{zoomLabel}</small>
            <FormSlider
              className="mt-1"
              max={25}
              min={0}
              value={[layer.minzoom, layer.maxzoom]}
              onChange={(value) => handleZoomChange(value)}
              onAfterChange={() => {}}
              style={{ railbackground: '#333333' }}
            />
          </div>
        </div>
      </Collapse>
      {layer.legendUrl && (
        <div className="bg-white p-2">
          <img alt="legend" src={layer.legendUrl} />
        </div>
      )}
    </>
  );
};

export default PropertySearchSourceLayer;
