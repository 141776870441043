import Listview from 'jsx/components/core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';
import { columnWidths } from 'jsx/components/modules/portrait/constants/listviews';
import { getLastChildValue } from 'jsx/lib/generic';
import { noop } from 'lodash';
import React from 'react';

const headers = [
  { caption: 'Caption', field: 'caption', classes: 'text-left' },
  { caption: 'Type', field: 'type.name', classes: 'text-left' },
  {
    caption: 'Paid',
    field: 'is_paid',
    classes: 'text-left',
    style: { width: columnWidths.icon },
  },
  {
    caption: 'Start Day/Time',
    field: 'start_day_time',
    classes: 'text-left',
    style: { width: columnWidths.small },
  },
  {
    caption: 'End Day/Time',
    field: 'end_day_time',
    classes: 'text-left',
    style: { width: columnWidths.small },
  },
  {
    field: 'actions',
    classes: 'p-0',
    style: { width: columnWidths.icon },
  },
];
const tableHeadTh = headers.map(({ caption, classes, style }, index) => (
  <th key={index} className={classes} style={style}>
    {caption}
  </th>
));

const iconName = 'business-time';
const emptyCaption = 'No Shift Slots found';

const ShiftSlotLsv = ({ rows = { count: 0, rows: [] }, onEdit = noop, onDelete = noop }) => {
  const renderRows = (headerRow, row) => {
    const { id } = row;
    const tableTd = headerRow.map(({ classes, field }, index) => {
      const caption = field?.includes('.') ? getLastChildValue(field, row) : row[field];
      switch (field) {
        case 'is_paid':
          return (
            <td key={index} className={classes}>
              {caption ? 'Yes' : 'No'}
            </td>
          );
        case 'actions':
          return (
            <td key={index} className={classes}>
              <div className="d-flex">
                <Icon
                  name="pen-to-square"
                  as="button"
                  className="text-success"
                  onClick={() => onEdit(true, id)}
                  title="Edit Shift Slot"
                />
                <Icon
                  name="trash"
                  as="button"
                  className="text-danger"
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete(id);
                  }}
                  title="Delete Shift Slot"
                />
              </div>
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {caption}
            </td>
          );
      }
    });

    return tableTd;
  };

  let tableBodyTr = [];
  const { count, rows: shiftSlots = [] } = rows;
  const haveRows = shiftSlots.length > 0;

  if (haveRows) {
    tableBodyTr = shiftSlots.map((row, index) => (
      <tr key={index} onClick={() => onEdit(true, row.id)}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={shiftSlots}
      rowsCount={count}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default ShiftSlotLsv;
