import React from 'react';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';

import Listview from "../../../../core/form/components/Listview";
import DirectDownload from '../../../../core/form/components/DirectDownload';
import StageProgress from './StageProgress'
import Pill from '../../../../core/form/components/Pill';


const StageRequirementsLsv = (props) => {
  const {
    rows,
    history,
    project_id,
    handleDownload
  } = props;

  const edit = (row) => {
    const lnk = `/home/projects/${project_id}/stage/${row.stage_id}/requirements/${row.id}`;
    history.push(lnk);
  }

  const renderRows = (headers, row) => {

    // Check due date
    let dueDate;
    let dueClass;
    if (row.due) {
      dueDate = moment(row.due).fromNow();
    }

    // Count requirements and validated item documents
    let requirementItems = 0;
    let itemsValidated = 0;

    // Count categories of items
    let categories = [];
    if (row.items && row.items.length > 0) {
      requirementItems = row.items.length;
      row.items.map(item => {

        if (item.category) {
          const idx = categories.findIndex(category => category.id === item.category.id);
          if (idx === -1) categories.push({ ...item.category, count: 1 })
          else categories[idx].count += 1
        }

        if (item.validatedby_id) {
          itemsValidated += 1;
        }
        return false;
      })
    }

    const docs = `${itemsValidated} of ${requirementItems}`;
    const docClass = (itemsValidated < requirementItems || requirementItems === 0 ? 'text-danger' : 'text-success');

    // Count categories of items
    categories = categories.sort((a,b) => a.sequence - b.sequence);
    let pills = categories.map((category, index) =>
    (<Pill
      key={index}
      id={category.id}
      caption={`${category.name}: ${category.count}`}
      classes={`d-flex float-left bg-${category.colour}`}
    />)
    );


    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case "docs": return (
          <td className={`${header.classes} ${docClass}`} key={index}>{docs}</td>
        )
        case "due": return (
          <td className={`${header.classes} ${dueClass}`} key={index}>{dueDate}</td>
        )
        case "status": return (
          <td key={index}><StageProgress status={row.status} /></td>
        )
        case "name": return (
          <td style={{ minWidth: '250px' }} className={`${header.classes}`} key={index}>{row[header.field]}</td>
        )
        case "categories": return (
          <td style={{ width: '400px' }} className={`${header.classes}`} key={index}>{pills}</td>
        )
        case "download": return (
          <td className={`${header.classes}`} key={index}><DirectDownload
            classNames="p-0"
            buttonType="primary"
            handleDownload={(onProgressChange) => handleDownload(row.id, onProgressChange)}
            showLabel={true}
            showIcon={true}
          /></td>
        )
        case 'edit': return (
          <td key={index} className="d-flex justify-content-center" onClick={() => edit(row)} ><Icon name="pen-to-square" className="text-primary" /></td>
        )
        default: return (
          <td className={`${header.classes}`} key={index}>{row[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const iconName = 'tasks';
  const emptyCaption = "No Requirements defined for this stage."

  const headers = [
    { caption: 'Requirements', field: 'name', classes: "text-left" },
    { caption: 'Status', field: 'status', classes: "text-left" },
    { caption: 'Deadline', field: 'due', classes: "text-center" },
    { caption: 'Documents', field: 'categories', classes: 'text-left' },
    { caption: 'Validated', field: 'docs', classes: "text-center" },
    { caption: '', field: 'download', classes: 'text-center' },
    { caption: '', field: 'edit', classes: 'text-center' }
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} style={{ cursor: "pointer" }}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  return (
    <div>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </div>
  )
}

export default StageRequirementsLsv;
