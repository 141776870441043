import React from 'react';
import { useFeatureFlag } from 'jsx/lib/featureFlags';

/**
 * Inject the status of a feature flag into a class based component. This HOC would be nice to
 * upgrade to TypeScript but that's very hard when the prop name is controllable / dynamic
 * @date 07/05/2023 - 12:11:45
 *
 * @param {string} featureKey
 * @param {string} propName
 * @param {ReactComponent} Component
 * @returns {ReactComponent} Component that will accept an additional prop
 */
const withFeatureFlagCheck = (featureKey, propName, Component) => {
  const WithFeatureFlagCheck = (props) => {
    const [isFeatureEnabled] = useFeatureFlag(featureKey);
    return <Component {...props} {...{ [propName]: isFeatureEnabled }} />;
  };

  return WithFeatureFlagCheck;
};

export default withFeatureFlagCheck;
