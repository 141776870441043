import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Row, Col } from 'reactstrap';
import {
  initControls,
  saveControls,
  updateControlOptions,
} from '../../../core/form/lib/validateForm';
import { controls as locationProjectControls } from '../forms/location_project.js';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import Icon from 'jsx/components/core/icons/Icon';

import GenericLsv from '../../../core/form/components/GenericLsv';

import {
  createLocationProjectAssociation,
  fetchLocationProjectAssociation,
  fetchLookupLocations,
  removeLocationProjectAssociation,
} from '../actions/locations';
import { cloneDeep } from 'lodash';
import { fetchProjects } from '../../projects/actions/projects';

class LocationProjectAssociationModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(locationProjectControls),
      data: {},
      title: 'Location/Project Association',
    };

    this.onClose = this.onClose.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.onProjectChange = this.onProjectChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.refreshLocationProjectAssociations = this.refreshLocationProjectAssociations.bind(this);
    this.setControlOptions = this.setControlOptions.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.dispatch(fetchLookupLocations());
      this.setState({
        controls: initControls(cloneDeep(locationProjectControls)),
      });
    }
  }

  onClose() {
    this.props.setModal(false);
  }

  onLocationChange(event) {
    this.handleChange(event);

    const location_id = event.target.value;
    this.refreshLocationProjectAssociations(location_id);
  }

  async onProjectChange(event) {
    const { controls } = this.state;
    let { data } = this.state;

    const project_id = event.target.value;

    // Update project_id control value
    controls.project_id.value = project_id;

    // Save data
    data = saveControls(controls, data);

    // Create association
    const success = await this.props.dispatch(createLocationProjectAssociation(data));

    if (success) {
      const { value: location_id } = controls.location_id;
      this.refreshLocationProjectAssociations(location_id);
    }

    // Reset project id
    controls.project_id.value = null;
    this.setState({ controls });
  }

  async onRemove(id) {
    const confirmed = window.confirm(`Removing project association permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeLocationProjectAssociation(id));
      if (success) {
        const { value: location_id } = this.state.controls.location_id;
        this.refreshLocationProjectAssociations(location_id);
      }
    }
  }

  async refreshLocationProjectAssociations(location_id) {
    // Base case - clear out location project associations
    if (location_id === '-') {
      this.props.dispatch({ type: 'UNSET_LOCATION_PROJECT_ASSOCIATIONS' });
      return;
    }

    // Fetch project associations to selected location
    const associations = await this.props.dispatch(
      fetchLocationProjectAssociation({ location_id }),
    );

    if (associations) {
      // Update projects reducer exluding associated projects.
      const exclude_ids = associations.map(({ project_id }) => project_id);
      this.props.dispatch(fetchProjects({ exclude_ids }));
    }
  }

  setControlOptions(controls) {
    const { projects } = this.props.projects;
    const { locations } = this.props.wipstar;

    const adjustedProjectRows = projects?.rows.map((row) => ({
      ...row,
      name: `${row.name} (${row.project_job_no})`,
    }));

    // Set control options
    const typeOptions = {
      location_id: locations,
      project_id: adjustedProjectRows?.length > 0 ? adjustedProjectRows : [{ id: null, name: '-' }],
    };

    let updatedControls = cloneDeep(controls);

    // Update control options
    Object.entries(typeOptions).forEach(
      ([key, options]) => (updatedControls = updateControlOptions(updatedControls, key, options)),
    );

    return updatedControls;
  }

  render() {
    let { controls } = this.state;
    const { title } = this.state;
    const { locationProjectAssociations, responseMessage } = this.props.wipstar;
    const iconName = 'location-dot';

    controls = this.setControlOptions(controls);

    return (
      <Modal isOpen={this.props.isOpen} className="xl">
        <ModalHeader className="bg-corporate text-white">
          <Icon name={iconName} className="text-white mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form style={{ fontSize: 12 }}>
            <Row>
              <Col sm="6">
                <FormInput handleChange={this.onLocationChange} control={controls.location_id} />
              </Col>
              <Col sm="6">
                <FormInput
                  handleChange={this.onProjectChange}
                  control={controls.project_id}
                  disabled={controls.location_id.value ? false : true}
                />
              </Col>
            </Row>

            {controls.location_id.value && (
              <div className="mt-3 border border-corporate rounded bg-light">
                <div className="p-1 border-bottom border-corporate text-white bg-corporate">
                  Associated Projects
                </div>
                <GenericLsv
                  controls={controls}
                  darkTable={false}
                  emptyCaption="No projects associated to selected location"
                  hideHeader={false}
                  iconName="diagram-project"
                  actions={[{ func: this.onRemove, iconName: 'trash' }]}
                  rows={locationProjectAssociations}
                />
              </div>
            )}
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button size="sm" className="mr-2" color="light" onClick={this.onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ projects, wipstar }) => ({
  projects,
  wipstar,
});

export default connect(mapStoreToProps)(LocationProjectAssociationModal);
