import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import { GenericModal } from '../../../core/form/components/GenericModal';
import { updateControlOptions } from '../../../core/form/lib/validateForm';
import { fetchAnimalClasses } from '../actions/animal_classes';

class AgistmentModal extends GenericModal {
  onChange = (event) => {
    const { name, value } = event.target;
    const updatedControls = cloneDeep(this.state.controls);

    switch (name) {
      case 'enterprise_id': {
        if (value !== updatedControls.enterprise_id.value) {
          const { division_id } =
            updatedControls[name]?.options?.find(({ id }) => id === value) ?? null;
          if (division_id) {
            this.loadAnimalClasses(division_id);
          }
        }
        updatedControls.enterprise_id.value = value;
        break;
      }
      default: {
        updatedControls[name].value = value;
        break;
      }
    }

    this.setState({ controls: updatedControls });

    this.handleChange(event);
  };

  loadAnimalClasses = (division_id) => {
    this.props.dispatch(fetchAnimalClasses({ division_id }));
  };

  renderInputs = (controls) => {
    const updatedControls = cloneDeep(controls);
    const { open_date, close_date } = updatedControls;

    if (open_date?.value) {
      const startDate = moment(open_date.value);
      const endDate = close_date.value ? moment(close_date.value) : moment();
      const numberOfWeeks = endDate.diff(startDate, 'weeks');

      updatedControls.total_weeks_count.value = numberOfWeeks;

      const { total_weeks_count, quantity_head, value_per_head_week } = updatedControls;
      const totalValue = total_weeks_count.value * quantity_head.value * value_per_head_week.value;
      updatedControls.total_value.value = totalValue.toFixed(2);
    }

    // Force the width of a selected input field (eg. comments).
    const forcedWidth = { comments: { md: 12, lg: 12 } };

    return super.renderInputs(updatedControls, this.onChange, forcedWidth);
  };

  filterProperties = (enterprise_id) => {
    const { enterprises } = this.props.enterprises;
    // Only display properties that have been previously allocated to the enterprise
    const { allocations = [] } = enterprises?.rows?.find(
      (enterprise) => enterprise.id === enterprise_id,
    ) ?? { allocations: [] };

    if (allocations.length === 0) return [];

    return allocations.map(({ property }) => ({ ...property }));
  };

  updateEnterpriseProperties = (controls) => {
    let updatedControls = cloneDeep(controls);
    const enterprise_id = updatedControls.enterprise_id.value ?? null;
    if (enterprise_id) {
      const properties = this.filterProperties(enterprise_id);
      updatedControls = updateControlOptions(controls, 'property_id', properties);
      updatedControls.property_id.showInEditor = properties.length > 1;
    }

    return updatedControls;
  };

  getFilteredAnimalClassesByDivision = (division_id) => {
    const { animal_classes = [] } = this.props.animal_classes;
    if (animal_classes.length === 0) return [];

    return animal_classes.filter(({ type }) => type.parent_id === division_id);
  };

  updateAnimalClasses = (controls) => {
    let updatedControls = cloneDeep(controls);
    const { value: enterprise_id } = updatedControls.enterprise_id;

    const targetEnterprise = this.props.enterprises.enterprises.rows.find(
      ({ id }) => id === enterprise_id,
    );
    if (targetEnterprise) {
      const { division_id } = targetEnterprise;
      const animalClasses = this.getFilteredAnimalClassesByDivision(division_id);
      updatedControls = updateControlOptions(controls, 'animal_class_id', animalClasses);
    }

    return updatedControls;
  };

  render() {
    const { title, isNew, isOpen, description } = this.state;
    let { controls } = this.state;
    const { iconName, responseMessage } = this.props;

    controls = this.setOptions(controls);
    controls = this.updateEnterpriseProperties(controls);
    controls = this.updateAnimalClasses(controls);

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody>
          {description && <p>{description}</p>}
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>{this.renderInputs(controls)}</Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ animal_classes, enterprises }) => ({
  animal_classes,
  enterprises,
});

export default connect(mapStoreToProps)(AgistmentModal);
