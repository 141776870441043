import React from 'react';

import moment from 'moment';
import { capitalizeFirstLetter } from '../../form/lib/various';

import Listview from '../../../core/form/components/Listview';
import ActivityComment from './ActivityComment';

const ActivitiesLsv = (props) => {
  let { rows, pagination, onLoadMore, customFields } = props;

  const levelColour = (level) => {
    switch (level) {
      case 'warning':
        return 'text-warning';
      case 'info':
        return 'text-primary';
      case 'important':
        return 'text-success';
      case 'error':
        return 'text-danger';
      case 'completed':
        return 'bg-success text-white';
      case 'fail':
        return 'bg-danger text-white';
      default:
        return '';
    }
  };

  const renderRows = (headers, activity) => {
    const createdby = `${activity['createdby.firstname']} ${activity['createdby.lastname']}`;
    const created = moment.utc(activity.created).local().fromNow();
    const created_datetime = moment.utc(activity.created).local().format('DD/MM/YYYY HH:mm::ss');
    const associate_with = capitalizeFirstLetter(activity.associate_with);
    const action = capitalizeFirstLetter(activity.action);
    const level = capitalizeFirstLetter(activity.level);
    const org_name = activity['org.name'];

    const tableTd = headers.map((header, index) => {
      const headerClasses = `${header.classes} ${levelColour(activity.level)}`;
      switch (header.field) {
        case 'comment':
          return (
            <td key={index} className={headerClasses}>
              <ActivityComment className={headerClasses} activity={activity} />
            </td>
          );
        case 'createdby':
          return (
            <td key={index} className={headerClasses}>
              {createdby}
            </td>
          );
        case 'created':
          return (
            <td key={index} className={headerClasses}>
              {created}
            </td>
          );
        case 'created_datetime':
          return (
            <td key={index} className={headerClasses}>
              {created_datetime}
            </td>
          );
        case 'associate_with':
          return (
            <td key={index} className={headerClasses}>
              {associate_with}
            </td>
          );
        case 'action':
          return (
            <td key={index} className={headerClasses}>
              {action}
            </td>
          );
        case 'level':
          return (
            <td key={index} className={headerClasses}>
              {level}
            </td>
          );
        case 'org.name':
          return (
            <td key={index} className={headerClasses}>
              {org_name}
            </td>
          );
        default:
          return (
            <td key={index} className={headerClasses}>
              {activity[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  let headers = [
    { caption: 'Type', field: 'associate_with', classes: 'text-left' },
    { caption: 'Created', field: 'created', classes: 'text-left' },
    { caption: 'Action', field: 'action', classes: 'text-left' },
    { caption: 'Comment', field: 'comment', classes: 'text-left' },
    { caption: 'Created By', field: 'createdby', classes: 'text-left' },
    { caption: 'Level', field: 'level', classes: 'text-center' },
    { caption: 'Items', field: 'items', classes: 'text-center' },
    { caption: 'Created Date/Time', field: 'created_datetime', classes: 'text-left' },
    { caption: 'Client', field: 'org.name', classes: 'text-left' },
  ];

  const standardHeaders = [
    'associate_with',
    'created',
    'action',
    'comment',
    'createdby',
    'level',
    'items',
  ];

  if (customFields?.length > 0) {
    headers = customFields.map((field) => {
      return headers.find((header) => header.field === field);
    });
  } else {
    headers = standardHeaders.map((field) => {
      return headers.find((header) => header.field === field);
    });
  }

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  let rowsCount;
  if (rows && pagination) {
    rowsCount = rows.count;
    rows = rows.rows;
  }
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  const iconName = 'comment';
  const emptyCaption = 'No Activity found';

  return (
    <>
      <Listview
        rows={rows}
        rowsCount={rowsCount}
        onLoadMore={onLoadMore}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </>
  );
};

export default ActivitiesLsv;
