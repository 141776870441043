import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  enterprise_id: {
    ...controlDefaults,
    name: 'enterprise_id',
    type: 'hidden',
    isPrimary: true,
  },
  enterprise: {
    ...controlDefaults,
    placeholder: '',
    name: 'enterprise',
    fieldName: 'enterprise.name',
    type: 'text',
    caption: 'Enterprise',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
    showInListview: true,
  },
  division: {
    ...controlDefaults,
    placeholder: '',
    name: 'division',
    fieldName: 'enterprise.division.name',
    type: 'text',
    caption: 'Division',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
    showInListview: true,
  },
  estimated_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'estimated_ha',
    fieldName: 'estimated_ha',
    type: 'number',
    caption: 'Estimated Area (ha)',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
    },
    showInListview: true,
  },
  open_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'open_date',
    fieldName: 'open_date',
    type: 'date',
    caption: 'Open Date',
    validationRules: {
      isRequired: false,
    },
    showInListview: true,
  },
  close_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'close_date',
    fieldName: 'close_date',
    type: 'date',
    caption: 'Close Date',
    validationRules: {
      isRequired: false,
    },
    showInListview: true,
  },
};
