import React, { useState } from 'react';
import moment from 'moment';
import { noop } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import ActivitiesSubLsv from './ActivitiesSubLsv';
import TimeFormat from '../../office/lib/timeFormat';
import Listview from '../../../core/form/components/Listview';

const DaysheetsLsv = ({ rows, onDelete = noop, onEdit = noop }) => {
  const format = new TimeFormat();

  const renderDaysheetActivities = (activities) => (
    <ActivitiesSubLsv
      darkTable
      hideNew
      hideHeader={false}
      rows={activities}
      filterColumns={['asset_id', 'job_id']}
    />
  );

  const [openDaysheets, setOpenDaysheets] = useState(null);

  const isDaysheetOpen = (daysheet_id) => openDaysheets === daysheet_id;

  const handleOpenDaysheets = async (event, daysheet_id) => {
    event.preventDefault();
    event.stopPropagation();

    if (isDaysheetOpen(daysheet_id)) {
      setOpenDaysheets(null);
    } else {
      setOpenDaysheets(daysheet_id);
    }
  };

  const calculateTotalShiftHours = ({ from_datetime, to_datetime, activities, breaks }) => {
    const startTime = moment(from_datetime);
    const endTime = moment(to_datetime);

    const duration = moment.duration(endTime.diff(startTime));
    const expectedShiftHours = duration.asHours();

    const labourHours = activities.reduce((total, next) => total + next.labour_hours, 0);

    const unpaidBreaks = breaks
      .filter(({ is_paid }) => !is_paid)
      .reduce((total, next) => total + next.hrs, 0);

    const actualShiftHours = labourHours + unpaidBreaks;
    const isEqual = expectedShiftHours === actualShiftHours;

    return { expectedShiftHours, actualShiftHours, isEqual };
  };

  const renderRows = (headers, row) => {
    const { id } = row;
    const tableTd = headers.map(({ field, classes }, index) => {
      let value = row[field];
      let style = { cursor: 'pointer' };
      let title = null;

      const actions = (
        <div className="text-nowrap">
          <button
            onClick={(event) => handleOpenDaysheets(event, id)}
            title="Daysheet Activities"
            className="text-corporate btn btn-sm btn-link cursor-pointer"
          >
            Activities
          </button>
          <Icon
            name="pen-to-square"
            as="button"
            className="text-success"
            onClick={() => onEdit(id)}
            title="Edit Daysheet"
          />
          <Icon
            name="trash"
            as="button"
            className="text-danger"
            onClick={() => onDelete(id)}
            title="Delete Daysheet"
          />
        </div>
      );

      switch (field) {
        case 'to_datetime':
        case 'from_datetime':
          value = moment(value).format('YYYY-MM-DD hh:mm A');
          break;
        case 'week_day':
          value = moment(row.from_datetime).format(format.week);
          break;
        case 'total_shift_hours':
          title = calculateTotalShiftHours(row).isEqual
            ? null
            : 'Total Shift Hours differs from Total Labour Hours + Total Break Hours';
          style = calculateTotalShiftHours(row).isEqual
            ? style
            : { ...style, backgroundColor: 'red', color: 'white' };
          value = calculateTotalShiftHours(row).expectedShiftHours;
          value = value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);
          break;
        case 'user_id':
          value = `${row.user.firstname} ${row.user.lastname}`;
          break;
        case 'actions':
          return (
            <td key={index} className={classes}>
              {actions}
            </td>
          );
        default:
          break;
      }

      return (
        <td
          key={index}
          className={classes}
          style={style}
          title={title}
          role="presentation"
          onClick={() => onEdit(id)}
        >
          {value}
        </td>
      );
    });

    return tableTd;
  };

  const iconName = 'calendar';
  const emptyCaption = 'No daysheets found for this week';

  const headers = [
    {
      caption: 'User',
      field: 'user_id',
      classes: 'text-left',
    },
    {
      caption: 'From Date/Time',
      field: 'from_datetime',
      classes: 'text-left',
    },
    {
      caption: 'To Date/Time',
      field: 'to_datetime',
      classes: 'text-left',
    },
    {
      caption: 'Week Day',
      field: 'week_day',
      classes: 'text-left',
    },
    {
      caption: 'Total Shift Hours',
      field: 'total_shift_hours',
      classes: 'text-right',
    },
    {
      caption: 'Total Labour Hrs',
      field: 'total_labour_hours',
      classes: 'text-right',
    },
    {
      caption: 'Total Asset Hrs',
      field: 'total_asset_hours',
      classes: 'text-right',
    },
    {
      caption: 'Total Break Hrs (inc. Paid)',
      field: 'total_break_hours',
      classes: 'text-right',
    },
    { caption: '', field: 'actions', classes: 'text-right p-0' },
  ];

  const tableHeadTh = headers.map(({ classes, caption }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  let rowsCount;

  const haveRows = rows && rows.length > 0;

  if (haveRows) {
    tableBodyTr = rows.map((daysheet, index) => (
      <React.Fragment key={index}>
        <tr>{renderRows(headers, daysheet)}</tr>
        {isDaysheetOpen(daysheet.id) && (
          <tr>
            <td className="bg-light" colSpan={12}>
              {renderDaysheetActivities(daysheet.activities)}
            </td>
          </tr>
        )}
      </React.Fragment>
    ));
  }

  return (
    <div className="mt-2">
      <Listview
        rows={rows}
        rowsCount={rowsCount}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </div>
  );
};

export default DaysheetsLsv;
