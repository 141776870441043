import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import { bulkUpdateFieldTrip } from '../actions';

const FieldTripAssociateModal = ({
  isOpen,
  title,
  iconName = 'truck-pickup',
  selectedSites = [],
  onCancel = noop,
  onSave = noop,
}) => {
  const dispatch = useDispatch();
  const [selectedFieldTrip, setSelectedFieldTrip] = useState(null);
  const fieldtrips = useSelector(({ sites }) => sites.fieldtrips);
  fieldtrips.sort((a, b) => a.name > b.name);

  const handleSave = async () => {
    let success = await dispatch(
      bulkUpdateFieldTrip({
        fieldtrip_id: selectedFieldTrip,
        site_ids: selectedSites,
      })
    );

    if (success) {
      onSave(selectedFieldTrip);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader className="bg-corporate text-white">
        <Icon size="1x" name={iconName} className="mr-2" />
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="py-2">Select an existing field trip to associate these sites to:</div>
        <FormGroup tag="fieldset" className="list-group overflow-auto vh-75">
          {fieldtrips.map(({ crew, name, drillrig, id }) => (
            <Label for={id} key={id} className="list-group-item d-flex mb-0 pl-5 py-1">
              <Input
                onChange={(e) => setSelectedFieldTrip(e.target.value)}
                className="form-check-input flex-shrink-0"
                type="radio"
                name="field-trip-sites"
                value={id}
                id={id}
              />{' '}
              <span className="pl-2">
                {name}
                <small className="d-block text-muted">
                  {crew} - {drillrig}
                </small>
              </span>
            </Label>
          ))}
        </FormGroup>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <div>
          <Button size="sm" className="mr-2" color="light" onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={false} size="sm" color="success" onClick={(props) => handleSave(props)}>
            Save
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default FieldTripAssociateModal;
