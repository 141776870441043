import axios from 'axios';
import errorResponse from '../../../../lib/errorResponse.js';
import { apiAnalytics } from '../../../../constants/api.js';
import { get } from '../../../../lib/genericAction.js';

export function updateWidgetDataset(widgetId, group, widgetDatasetId, selectedDataset) {
  return function (dispatch) {
    const payload = { widgetId, group, widgetDatasetId, selectedDataset };
    dispatch({ type: 'UPDATE_ANALYTICS_WIDGET_DATASET_FULFILLED', payload: payload });
  };
}

export function selectChartCell(name, selectionKey, singleSelection = false) {
  return function (dispatch) {
    dispatch({
      type: 'SELECT_CHART_CELL_FULFILLED',
      payload: { name, selectionKey, singleSelection },
    });
  };
}

export function fillDataset(key, group, dataset) {
  return function (dispatch) {
    dispatch({ type: 'FILL_ANALYTICS_DATASET_FULFILLED', payload: { key, group, dataset } });
  };
}

export function fetchResultsByProperty(params) {
  params = {
    type: 'mass',
    key: 'round',
    ...params,
  };
  return async (dispatch) =>
    await get(
      dispatch,
      `FETCH_PROPERTY_${params.type.toUpperCase()}_${params.key.toUpperCase()}`,
      `${apiAnalytics}/properties/${params.property_id}/${params.type}/${params.key}`,
      params
    );
}

export function fetchComparisonsByProperty(params) {
  return async (dispatch) =>
    await get(
      dispatch,
      `FETCH_PROPERTY_COMPARISON`,
      `${apiAnalytics}/properties/rounds/${params.baseline_id}/compare`,
      params
    );
}

export function fetchSoilMass(area_round_id, template_id) {
  return async (dispatch) => {
    dispatch({ type: 'FETCH_SOILMASS_PENDING' });

    try {
      const response = await axios({
        method: 'GET',
        url: `${apiAnalytics}/rounds/${area_round_id}/soil_mass/core?method=2018&template_id=${template_id}`,
      });

      dispatch({ type: 'FETCH_SOILMASS_FULFILLED', payload: response.data.result });
      return response.data.result;
    } catch (err) {
      errorResponse(dispatch, err, 'FETCH_SOILMASS_REJECTED');
      return false;
    }
  };
}

export function fetchEsm(area_round_id, template_id) {
  return async (dispatch) => {
    dispatch({ type: 'FETCH_ESM_PENDING' });

    try {
      const response = await axios({
        method: 'GET',
        url: `${apiAnalytics}/rounds/${area_round_id}/equivalent_soil_mass/layer?method=2018&template_id=${template_id}`,
      });

      dispatch({ type: 'FETCH_ESM_FULFILLED', payload: response.data.result });
      return response.data.result;
    } catch (err) {
      errorResponse(dispatch, err, 'FETCH_ESM_REJECTED');
      return false;
    }
  };
}

export function fetchCarbonStock(key, area_round_id, template_id) {
  return async (dispatch) => {
    dispatch({ type: 'FETCH_SOC_PENDING' });

    let url = `${apiAnalytics}/rounds/${area_round_id}/carbon_stock/${key}?method=2018&template_id=${template_id}`;

    try {
      const response = await axios({
        method: 'GET',
        url: url,
      });

      dispatch({ type: 'FETCH_SOC_FULFILLED', payload: response.data.result });
      return response.data.result;
    } catch (err) {
      errorResponse(dispatch, err, 'FETCH_SOC_REJECTED');
      return false;
    }
  };
}

export function fetchCarbonStockPropertyRound(property_round_id, template_id) {
  return async (dispatch) => {
    dispatch({ type: 'FETCH_PROPERTY_ROUND_SOC_PENDING' });

    let url = `${apiAnalytics}/properties/rounds/${property_round_id}/carbon_stock/round?method=2018&template_id=${template_id}`;

    try {
      const response = await axios({
        method: 'GET',
        url: url,
      });

      dispatch({ type: 'FETCH_PROPERTY_ROUND_SOC_FULFILLED', payload: response.data.result });
      return response.data.result;
    } catch (err) {
      errorResponse(dispatch, err, 'FETCH_PROPERTY_ROUND_SOC_REJECTED');
      return false;
    }
  };
}
