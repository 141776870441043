import { isObject } from 'lodash';

/**
 * Return entry based on the given rules
 * @param {Object} control containing copy value structure/rules
 * @param {Object} data can be in the form of a controls/rows
 * @returns key value object
 */
export const getCopyValueEntry = (control, data) => {
  const { copyValue, name } = control;

  // Check if field exists in data
  if (Object.keys(data).includes(name)) {
    // Key is determined if copy value contains to or from property
    let key = copyValue?.to ? copyValue.to : name;

    /**
     * Determine if copying value is to or from.
     * Handle possible nesting when setting value.
     */
    let destination = copyValue?.to ? name : copyValue.from;
    const isObjectStructure = isObject(data[destination]);

    let value = isObjectStructure ? data[destination].value : data[destination];

    // Check and apply rules
    if (copyValue?.rules) {
      const { isEmpty } = copyValue.rules;

      if (isEmpty) {
        // Check against current data value
        const currentDataValue = isObjectStructure ? data[key].value : data[key];

        // Do not copy value if current data value is not null, empty string, or 0.
        if (!isBlank(currentDataValue)) return null;
      }
    }

    return { key, value };
  }
};

const isBlank = (value) => !value || value === '' || value === 0;
