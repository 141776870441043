/* eslint-disable no-restricted-syntax, guard-for-in */
import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';
import { Button } from 'reactstrap';
import { noop } from 'lodash';

import TimeFormat from '../lib/timeFormat';

const TimekeeperTimerBar = (props) => {
  const {
    onEdit,
    reload = noop,
    selectedDate = noop,
    selectDate = noop,
    setWeek = noop,
    timesheets,
    daysheets,
    hideBanner,
    week,
  } = props;

  const format = new TimeFormat();
  const now = moment().format(format.day);

  let bannerLabel = selectedDate.format('dddd, DD MMM');
  let bannerClassName = 'bg-light border border-secondary text-secondary rounded p-1 text-center';
  if (selectedDate.format(format.day) === now) {
    bannerLabel += ' (Today)';
    bannerClassName += ' border-corporate text-corporate';
  }

  const dateButtons = [];
  const totals = {};

  if (timesheets?.rows) {
    for (const index in timesheets.rows) {
      const timesheet = timesheets.rows[index];
      const week_day = moment(timesheet.from_date).format(format.week);
      if (!totals[week_day]) totals[week_day] = 0;

      totals[week_day] += timesheet.net_hours;
    }
  }

  if (daysheets?.rows) {
    for (const index in daysheets.rows) {
      const daysheet = daysheets.rows[index];
      const week_day = moment(daysheet.from_datetime).format(format.week);
      if (!totals[week_day]) totals[week_day] = 0;

      totals[week_day] += daysheet.total_labour_hours;
    }
  }

  if (week.from_date) {
    for (let weekday = 0; weekday < 7; weekday += 1) {
      const day = moment(week.from_date).add(weekday, 'days');

      let colour = 'secondary';
      if (selectedDate.format(format.day) === day.format(format.day)) {
        colour = 'warning';
        if (selectedDate.format(format.day) === now) {
          colour = 'success';
        }
      }

      let total = 0;
      if (totals && totals[day.format(format.week)]) {
        total = totals[day.format(format.week)];
      }

      const className = 'ml-1 mr-1';
      dateButtons.push(
        <Button
          className={className}
          key={weekday}
          onClick={() => selectDate(day)}
          size="sm"
          color={colour}
        >
          <span>{day.format('ddd Do')}</span>
          <br />
          <span>{`${format.asString(total)}`}</span>
        </Button>,
      );
    }
  }

  return (
    <>
      {!hideBanner && <h3 className={bannerClassName}>{bannerLabel}</h3>}

      <div className="mt-1 bg-light border-bottom border-primary p-2 d-flex justify-content-between text-white">
        <span>
          <Button size="sm" color="primary" onClick={() => setWeek(-1)}>
            <Icon name="arrow-left" className="mr-1" />
            Prev Week
          </Button>
        </span>

        <span>{dateButtons}</span>

        <span>
          <Button size="sm" color="warning text-white" className="mr-2" onClick={() => reload()}>
            <Icon name="rotate" title="Refresh" />
          </Button>
          {onEdit && (
            <Button size="sm" color="success" className="mr-2" onClick={() => onEdit(null, true)}>
              <Icon name="plus" title="Add" />
            </Button>
          )}
          <Button size="sm" color="primary" onClick={() => setWeek(+1)}>
            Next Week
            <Icon name="arrow-right" className="ml-1" />
          </Button>
        </span>
      </div>
    </>
  );
};

export default TimekeeperTimerBar;
