import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';

import FormBase from '../../core/form/components/FormBase';
import FormTab from '../../core/form/components/FormTab';

import { fetchOrgs, fetchAttributeDefs } from '../actions';

import AttributeDefsLsv from '../components/AttributeDefsLsv';

class CustomAttributes extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
    };
  }

  async componentDidMount() {
    this.props.dispatch(fetchOrgs({ user_orgs: true }));
    this.props.dispatch(fetchAttributeDefs());
  }

  render() {
    const { attributeDefs } = this.props.manage;

    return (
      <>
        <div className="p-4">
          <Nav tabs className="mt-2">
            <FormTab
              tabTag="custom_attributes"
              caption="Custom Attributes"
              tabId="1"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
              disabled={false}
            />
          </Nav>

          <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
            <TabPane tabId="1" className="mb-2 mt-2">
              <AttributeDefsLsv rows={attributeDefs} />
            </TabPane>
          </TabContent>
        </div>
      </>
    );
  }
}

const mapStoreToProps = ({ manage }) => ({
  manage,
});

export default connect(mapStoreToProps)(CustomAttributes);
