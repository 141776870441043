import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import { cloneDeep, omit } from 'lodash';
import { connect } from 'react-redux';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { GenericModal } from 'jsx/components/core/form/components/GenericModal';
import { fetchDocumentDefs, fetchOrgs } from 'jsx/components/manage/actions';
import {
  initControls,
  saveControls,
  updateControlOptions,
} from '../../../core/form/lib/validateForm';
import { controls as labRegisterRecordControls } from '../forms/labRegisterRecord';
import { fetchAllCeas } from '../../areas/actions';
import { fetchAllPropertyRounds } from '../../projects/actions/property_rounds';
import {
  createLabRegisterRecord,
  fetchLabContacts,
  fetchLabRegister,
  fetchLabStatuses,
} from '../actions';

class LabRegisterRecordModal extends GenericModal {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      controls: cloneDeep(labRegisterRecordControls),
    };

    this.onSave = this.onSave.bind(this);
    this.renderInputs = this.renderInputs.bind(this);
    this.setOptions = this.setOptions.bind(this);
  }

  componentDidMount() {
    /**
     * Fetching all CEAs is a slow request, and is needed for a modal. Run it in the background
     * so the loading spinner doesn't block any UI interactions
     */
    this.props.dispatch(fetchAllCeas({}, true));

    this.props.dispatch(fetchAllPropertyRounds());
    this.props.dispatch(fetchLabStatuses());
    this.props.dispatch(fetchDocumentDefs({ group_key: 'lab_register' }));
    this.props.dispatch(fetchLabContacts());
  }

  async componentDidUpdate() {
    let { controls, title } = this.state;
    const { isOpen } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      const { record_type, record_round_id } = this.props;
      title = 'New Lab Register Record';
      controls = initControls(cloneDeep(labRegisterRecordControls));

      controls.record_type.value = record_type;
      switch (record_type) {
        case 'area_round_id':
          controls.area_round_id.value = record_round_id;
          break;
        case 'property_round_id':
          controls.property_round_id.value = record_round_id;
          break;
        default:
          break;
      }

      this.props
        .dispatch(fetchOrgs({ category_tag: 'lab' }))
        .then((lab_orgs) => (controls.lab_orgs = lab_orgs));

      this.props
        .dispatch(fetchOrgs({ category_tag: 'transport' }))
        .then((transport_orgs) => (controls.transport_orgs = transport_orgs));

      this.setState({
        isOpen: this.props.isOpen,
        title,
        controls,
        setModal: this.props.setModal,
      });
    }
  }

  async onSave() {
    const { data, controls } = this.state;
    const { params } = this.props.lab;

    let unsavedData = saveControls(controls, data);

    switch (unsavedData.record_type) {
      case 'area_round_id':
        unsavedData = omit(unsavedData, 'property_round_id');
        break;
      case 'property_round_id':
        unsavedData = omit(unsavedData, 'area_round_id');
        break;
      default:
        break;
    }

    const success = await this.props.dispatch(createLabRegisterRecord(unsavedData));
    if (success) {
      this.props.dispatch(fetchLabRegister(params));
      this.onClose();
    }
  }

  setOptions(controls) {
    const { allceas } = this.props.areas;
    const { statuses, contacts } = this.props.lab;
    const { documentDefs } = this.props.manage;
    const { propertyRounds } = this.props.properties;
    const { transport_orgs, lab_orgs } = controls;

    const namedAreaRounds = allceas.map((round) => ({
      id: round.id,
      name: `${round.area.property.idx}-${round.area.name}-${round.name}`,
    }));

    const namedPropertyRounds = propertyRounds.map((round) => ({
      id: round.id,
      name: `${round.property.idx}-${round.name}`,
    }));

    const options = [
      {
        key: 'record_type',
        values: controls['record_type'].options,
      },
      {
        key: 'status_id',
        values: statuses,
      },
      {
        key: 'area_round_id',
        values: namedAreaRounds,
      },
      {
        key: 'property_round_id',
        values: namedPropertyRounds,
      },
      {
        key: 'transport_org_id',
        values: transport_orgs,
      },
      {
        key: 'sample_type_id',
        values: documentDefs,
      },
      {
        key: 'lab_org_id',
        values: lab_orgs,
      },
      {
        key: 'contact_user_id',
        values: contacts,
      },
    ];

    options.forEach(({ key, values }) => (controls = updateControlOptions(controls, key, values)));

    return controls;
  }

  renderInputs(controls) {
    const { currentRegisterRecord } = this.props.lab;

    let sampled_by = '';
    let sampled_at;
    if (currentRegisterRecord.lab_register_samples) {
      const samples = currentRegisterRecord.lab_register_samples.filter(
        (sample) => sample.sampled_user !== null,
      );

      const sample_users = samples.map(
        (sample) => `${sample.sampled_user.firstname.charAt(0)} ${sample.sampled_user.lastname}`,
      );

      const unique_users = [...new Set(sample_users)];
      sampled_by = unique_users.join(', ');

      const sample_dates = samples.map((sample) => sample.sampled_at).filter(Boolean);
      if (sample_dates.length > 0) sampled_at = sample_dates[sample_dates.length - 1];
    }

    controls.sampled_by.value = sampled_by;
    controls.sampled_at.value = sampled_at;

    const record_type = controls.record_type.value;
    switch (record_type) {
      default:
      case 'area_round_id':
        controls.area_round_id.showInEditor = true;
        controls.property_round_id.showInEditor = false;
        break;
      case 'property_round_id':
        controls.area_round_id.showInEditor = false;
        controls.property_round_id.showInEditor = true;

        break;
    }

    return super.renderInputs(controls);
  }

  render() {
    let { controls } = this.state;
    const { isOpen, title } = this.state;

    const { responseMessage } = this.props.lab;

    controls = this.setOptions(controls);

    const iconName = 'circle-notch';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>{this.renderInputs(controls)}</Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onClose}>
              Cancel
            </Button>
            <Button
              disabled={!this.checkAccess('labRecordCreate')}
              size="sm"
              color="success"
              onClick={this.onSave}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    areas: store.areas,
    lab: store.lab,
    manage: store.manage,
    properties: store.properties,
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(LabRegisterRecordModal);
