import React from 'react';
import Listview from '../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const AssetTransactionsLsv = (props) => {
  const {
    columnWidths,
    darkTable,
    editAsset = noop,
    editTransaction = noop,
    hideHeader,
    rows,
  } = props;

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      let { classes, field, formattingRules, type, width } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (let key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }

      if (formattingRules) {
        caption = numberRuleFormat(caption, formattingRules);
      }

      switch (type) {
        case 'editAsset':
          return (
            <td
              key={index}
              className={classes}
              onClick={() => editAsset(row.asset_id)}
              width={width}
            >
              {caption}
              <Icon name="pen-to-square" className="white ml-3 cursor-pointer" />
            </td>
          );
        case 'editTransaction':
          return (
            <td
              key={index}
              className={classes}
              onClick={() => editTransaction(row.id)}
              width={width}
            >
              {caption}
              <Icon name={'pen-to-square'} className="white ml-2 cursor-pointer" />
            </td>
          );
        case 'date':
          return (
            <td key={index} className={classes} width={width}>
              {caption ? moment(caption).format('Do MMM YYYY') : ''}
            </td>
          );
        case 'date-month':
          return (
            <td key={index} className={classes} width={width}>
              {caption ? moment(caption).format('MMMM YYYY') : ''}
            </td>
          );
        default:
          return (
            <td key={index} className={classes} width={width}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const { common, icon, offset } = columnWidths;
  let headers = [
    {
      caption: 'Asset Item',
      field: 'asset.name',
      type: 'editAsset',
      classes: 'text-left border-right border-corporate border-top-0',
      width: `${common * 3 - offset}px`,
    },
    {
      caption: 'Transaction Month',
      field: 'transaction_date',
      classes: 'text-left border-left border-corporate border-top-0',
      type: 'date-month',
    },
    {
      caption: 'Transaction Type',
      field: 'transaction_type.name',
      classes: 'text-left',
    },
    {
      caption: 'Value',
      field: 'amount',
      classes: 'text-right',
      totals: true,
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
      width: `${common}px`,
    },
    {
      caption: '',
      type: 'editTransaction',
      classes: 'text-center',
      width: `${icon - offset}px`,
    },
  ];

  // Get unique distribution headers
  const toEnd = 1;
  const headerPosition = 1;
  rows.forEach((row) => {
    // Sort distributions by division name alphabetically
    row?.asset?.distributions?.sort((a, b) =>
      a?.division?.name ? a.division.name.localeCompare(b.division.name) : toEnd
    );
    row?.asset?.distributions?.forEach((distribution, idx) => {
      // Only add to headers if unique
      let caption = `${distribution.division.name} (%)`;
      let headers_idx = headers.findIndex((existing_header) => existing_header.caption === caption);

      if (headers_idx === -1) {
        // Add idx to position number when splicing to keep alphabetical order of division names
        headers.splice(headerPosition + idx, 0, {
          caption,
          field: `asset.distributions.${idx}.distribution_pcnt`,
          classes: 'text-right',
          formattingRules: {
            includeDecimals: true,
            includePercentageSign: true,
            asPercentage: true,
          },
          width: `${common}px`,
        });
      }
    });
  });

  const tableHeadTh = headers.map(({ caption, classes }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row) => <tr key={row.id}>{renderRows(headers, row)}</tr>);
  }

  const iconName = 'farm';
  const emptyCaption = 'No Asset Transactions found';

  return (
    <Listview
      darkTable={darkTable}
      hideHeader={hideHeader}
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default AssetTransactionsLsv;
