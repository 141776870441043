import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    divisions: [],
    enterprise_types: [],
    production_units: [],
    reporting_units: [],
    animal_class_types: [],
    transaction_intervals: [],
    transaction_types: [],
    livestock_adjustment_types: [],
    breeding_periods: [],
    overhead_groups: [],
    overhead_types: [],
    capex_categories: [],
    labour_categories: [],
    labour_groups: [],
    liability_categories: [],
    asset_categories: [],
    asset_types: [],
    asset_adjustment_types: [],
    category_tags: [],
    cost_categories: [],
    off_farm_income_types: [],
    cost_types: [],
    cropping_product_types: [],
    cropping_adjustment_types: [],
    property_usages: [],
    property_usage_types: [],
    income_categories: [],
    wool_adjustment_types: [],
    plant_asset_types: [],
    plant_asset_categories: [],
    plant_asset_statuses: [],
    plant_asset_meterunits: [],
    plant_asset_project_statuses: [],
    job_types: [],
    job_priorities: [],
    job_statuses: [],
    roster_shift_slot_types: [],
    subscriptions: [],
    property_statuses: [],

    analysis_period_client_statuses: [],
    analysis_period_rcs_statuses: [],
  },
  action,
) {
  // Set expected reducer types
  const types = ['ATTRIBUTE'];
  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'UNSET_ENTERPRISE_ATTRIBUTES': {
      return {
        ...updatedState,
        divisions: [],
        enterprise_types: [],
        production_units: [],
        reporting_units: [],
      };
    }

    case 'UNSET_ASSET_ATTRIBUTES': {
      return {
        ...updatedState,
        asset_categories: [],
        asset_types: [],
      };
    }

    case 'UNSET_OVERHEAD_ATTRIBUTES': {
      return {
        ...updatedState,
        overhead_groups: [],
        overhead_types: [],
      };
    }

    case 'UNSET_OVERHEAD_TYPES_ATTRIBUTES': {
      return {
        ...updatedState,
        overhead_types: [],
      };
    }

    case 'UNSET_LIABILITIES_ATTRIBUTES': {
      return {
        ...updatedState,
        liability_categories: [],
      };
    }

    case 'UNSET_CAPITAL_EXPENDITURES_ATTRIBUTES': {
      return {
        ...updatedState,
        capex_categories: [],
      };
    }

    case 'UNSET_OTHER_INCOMES_ATTRIBUTES': {
      return {
        ...updatedState,
        income_categories: [],
      };
    }

    case 'UNSET_DIRECT_COST_ATTRIBUTES': {
      return {
        ...updatedState,
        cost_categories: [],
        divisions: [],
      };
    }

    case 'UNSET_LABOUR_ATTRIBUTES': {
      return {
        ...updatedState,
        labour_categories: [],
      };
    }

    case 'UNSET_ASSET_TYPES_ATTRIBUTES': {
      return {
        ...updatedState,
        asset_types: [],
      };
    }

    case 'UNSET_COST_TYPES_ATTRIBUTES': {
      return {
        ...updatedState,
        cost_types: [],
      };
    }
    case 'UNSET_ANIMAL_CLASS_TYPES_ATTRIBUTES': {
      return {
        ...updatedState,
        animal_class_types: [],
      };
    }

    case 'FETCH_ATTRIBUTES_DIVISIONS_FULFILLED': {
      return {
        ...updatedState,
        divisions: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_CATEGORY_TAGS_FULFILLED': {
      return {
        ...updatedState,
        category_tags: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_ENTERPRISE_TYPES_FULFILLED': {
      return {
        ...updatedState,
        enterprise_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_PRODUCTION_UNITS_FULFILLED': {
      return {
        ...updatedState,
        production_units: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_REPORTING_UNITS_FULFILLED': {
      return {
        ...updatedState,
        reporting_units: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_ANIMAL_CLASS_TYPES_FULFILLED': {
      return {
        ...updatedState,
        animal_class_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_TRANSACTION_TYPES_FULFILLED': {
      return {
        ...updatedState,
        transaction_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_TRANSACTION_INTERVALS_FULFILLED': {
      return {
        ...updatedState,
        transaction_intervals: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_LIVESTOCK_ADJUSTMENT_TYPES_FULFILLED': {
      return {
        ...updatedState,
        livestock_adjustment_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_BREEDING_PERIODS_FULFILLED': {
      return {
        ...updatedState,
        breeding_periods: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_OVERHEAD_GROUPS_FULFILLED': {
      return {
        ...updatedState,
        overhead_groups: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_OVERHEAD_TYPES_FULFILLED': {
      return {
        ...updatedState,
        overhead_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_CAPEX_CATEGORIES_FULFILLED': {
      return {
        ...updatedState,
        capex_categories: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_LIABILITY_CATEGORIES_FULFILLED': {
      return {
        ...updatedState,
        liability_categories: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_LIABILITY_TRANSACTION_TYPES_FULFILLED': {
      return {
        ...updatedState,
        liability_transaction_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_ASSET_TYPES_FULFILLED': {
      return {
        ...updatedState,
        asset_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_ASSET_ADJUSTMENT_TYPES_FULFILLED': {
      return {
        ...updatedState,
        asset_adjustment_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_LABOUR_CATEGORIES_FULFILLED': {
      return {
        ...updatedState,
        labour_categories: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_LABOUR_GROUPS_FULFILLED': {
      return {
        ...updatedState,
        labour_groups: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_COST_CATEGORIES_FULFILLED': {
      return {
        ...updatedState,
        cost_categories: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_COST_TYPES_FULFILLED': {
      return {
        ...updatedState,
        cost_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_ASSET_CATEGORIES_FULFILLED': {
      return {
        ...updatedState,
        asset_categories: action.payload.rows,
      };
    }
    case 'UNSET_PROPERTY_USAGES_ATTRIBUTES': {
      return {
        ...updatedState,
        property_usages: [],
      };
    }

    case 'FETCH_ATTRIBUTES_PROPERTY_USAGE_TYPES_FULFILLED': {
      return {
        ...updatedState,
        property_usage_types: action.payload.rows,
      };
    }

    case 'FETCH_ATTRIBUTES_INCOME_CATEGORIES_FULFILLED': {
      return {
        ...updatedState,
        income_categories: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_CROP_PRODUCT_TYPE_FULFILLED': {
      return {
        ...updatedState,
        cropping_product_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_CROP_ADJUSTMENT_TYPE_FULFILLED': {
      return {
        ...updatedState,
        cropping_adjustment_types: action.payload.rows,
      };
    }
    case 'UNSET_INCOME_CATGORIES_ATTRIBUTES': {
      return {
        ...updatedState,
        income_categories: [],
      };
    }
    case 'FETCH_ATTRIBUTES_WOOL_ADJUSTMENT_TYPES_FULFILLED': {
      return {
        ...updatedState,
        wool_adjustment_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_PLANT_ASSET_TYPES_FULFILLED': {
      return {
        ...updatedState,
        plant_asset_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_PLANT_ASSET_CATEGORIES_FULFILLED': {
      return {
        ...updatedState,
        plant_asset_categories: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_PLANT_ASSET_STATUSES_FULFILLED': {
      return {
        ...updatedState,
        plant_asset_statuses: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_JOB_STATUSES_FULFILLED': {
      return {
        ...updatedState,
        job_statuses: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_JOB_TYPES_FULFILLED': {
      return {
        ...updatedState,
        job_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_JOB_PRIORITIES_FULFILLED': {
      return {
        ...updatedState,
        job_priorities: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_PLANT_ASSET_METERUNITS_FULFILLED': {
      return {
        ...updatedState,
        plant_asset_meterunits: action.payload.rows,
      };
    }

    case 'FETCH_ATTRIBUTES_OFF_FARM_INCOME_TYPES_FULFILLED': {
      return {
        ...updatedState,
        off_farm_income_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_PLANT_ASSET_PROJECT_STATUSES_FULFILLED': {
      return {
        ...updatedState,
        plant_asset_project_statuses: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_SUBSCRIPTIONS_FULFILLED': {
      return {
        ...updatedState,
        subscriptions: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_ROSTER_SHIFT_SLOT_TYPES_FULFILLED': {
      return {
        ...updatedState,
        roster_shift_slot_types: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_PROPERTY_STATUSES_FULFILLED': {
      return {
        ...updatedState,
        property_statuses: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_ANALYSIS_PERIOD_CLIENT_STATUSES_FULFILLED': {
      return {
        ...updatedState,
        analysis_period_client_statuses: action.payload.rows,
      };
    }
    case 'FETCH_ATTRIBUTES_ANALYSIS_PERIOD_RCS_STATUSES_FULFILLED': {
      return {
        ...updatedState,
        analysis_period_rcs_statuses: action.payload.rows,
      };
    }
    default:
      break;
  }

  return updatedState;
}
