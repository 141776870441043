import { allowed, defaults } from '../../../lib/genericReducer';
export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    responseMessage: null,
    reports: [],
    client_id: null,
  },
  action
) {
  // Set expected reducer types
  const types = ['REPORTS', 'PREVIEW'];

  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'SET_PREVIEW_CLIENT_FULFILLED': {
      return {
        ...state,
        client_id: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
