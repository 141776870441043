import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const EnterprisesCard = (props) => {
  const {
    iconName,
    title,
    description,
    colourClass,
    history,
    toggleEnterprisesTab,
    tab,
    disabled,
  } = props;

  let className = `bg-${colourClass} p-2`;

  let edit = (tabId) => {
    toggleEnterprisesTab(tabId);
    const lnk = '/home/enterprises';
    history.push(lnk);
  };

  // Set disabled to gray
  if (disabled) {
    className = 'bg-gray p-2';
    edit = noop;
  }

  return (
    <Card
      onClick={() => edit(tab)}
      sm={2}
      className={className}
      role={disabled ? undefined : 'button'}
    >
      <Row className="text-white p-0 m-0 cursor-pointer">
        <Col sm={1} className="p-0 m-0 d-flex align-items-center mr-1">
          <Icon size="2x" name={iconName} className="ml-2" />
        </Col>
        <Col className="ml-1">
          <h5>{title}</h5>
          <div className="text-lightgray">{description}</div>
        </Col>
        <Col sm={1} className="p-0 m-0 d-flex align-items-center justify-content-end mr-1">
          <Icon size="2x" name="chevron-right" />
        </Col>
      </Row>
    </Card>
  );
};

export default EnterprisesCard;
