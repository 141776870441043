import React, { useState } from 'react';
import moment from 'moment';

const TextDuration = ({ className = null, start = null, end = null, interval = null }) => {
  const [duration, setDuration] = useState('');
  let [intervalID, setIntervalID] = useState(null);

  const calcDuration = () => {
    const started = moment(start);
    const ended = end ? moment(end) : moment.now();
    const diff = moment.duration(started.diff(ended));
    return humanize(diff);
  };

  const humanize = (diff) => {
    const seconds = diff.seconds() * -1;
    const minutes = diff.minutes() * -1;
    const hours = diff.hours() * -1;
    const days = diff.days() * -1;

    let message = '';
    if (days > 0) message += `${days}d`;
    if (hours > 0) message += ` ${hours}h`;
    if (minutes > 0) message += ` ${minutes}m`;
    if (seconds > 0) message += ` ${seconds}s`;
    return message;
  };

  if (start) {
    if (end) {
      let diff = calcDuration();
      if (duration !== diff) setDuration(diff);

      if (intervalID !== null) {
        clearInterval(intervalID);
        setIntervalID(null);
      }
    } else {
      if (intervalID === null) {
        intervalID = setInterval(() => {
          setDuration(calcDuration());
        }, interval || 1000);

        setIntervalID(intervalID);
      }
    }
  }

  return (
    <>
      <span className={className}>{duration}</span>
    </>
  );
};

export default TextDuration;
