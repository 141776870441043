import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';

import FormBase from '../../../core/form/components/FormBase';
import { controls } from '../forms/propertyAllocations';
import Mapster from '../../projects/containers/Mapster';
import GenericLsv from '../../../core/form/components/GenericLsv';
import FormInput from '../../../core/form/components/FormInput';
import { controlDefaults } from '../../../core/form/lib/validateForm';
import EnterpriseAllocationModal from './EnterpriseAllocationModal';

class PropertyAllocations extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: controls,
      isEnterpriseModalOpen: false,
      mapSources: [],
      map: null,
      selectedEnterprise: null,
    };

    this.selectEnterprise = this.selectEnterprise.bind(this);
    this.loadEnterpriseOptions = this.loadEnterpriseOptions.bind(this);
    this.setEnterpriseModal = this.setEnterpriseModal.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }

  componentDidUpdate() {
    let { map } = this.state;

    // Force a map redraw
    if (map) map.resize();
  }

  onRefresh() {
    this.props.toggleTab('allocations');
  }

  selectEnterprise(event) {
    this.setState({ selectedEnterprise: event.target.value });
  }

  loadEnterpriseOptions() {
    const { enterprises } = this.props.enterprises;

    let options = [{ id: null, name: 'Select Enterprise' }];
    if (enterprises.rows) options = options.concat(enterprises.rows);
    return options;
  }

  setEnterpriseModal(isEnterpriseModalOpen, selectedEnterprise = null) {
    this.setState({ isEnterpriseModalOpen, selectedEnterprise });
  }

  render() {
    const { controls, mapSources, selectedEnterprise, isEnterpriseModalOpen } = this.state;
    const { allocations } = this.props.enterprises;

    let lngLat = [150.7333, -23.1333];
    let iconName = 'draw-polygon';
    let emptyCaption = 'No Enterprise Allocations found';

    const options = this.loadEnterpriseOptions();
    const isSelectedEnterprise =
      selectedEnterprise !== null && selectedEnterprise !== options[0].name;

    const enterprise_control = {
      ...controlDefaults,
      type: 'select',
      value: selectedEnterprise,
      options,
    };

    // Filter allocations by current property/selected enterprise
    let filtered_allocations = [];
    if (allocations?.rows?.length > 0) {
      filtered_allocations = isSelectedEnterprise
        ? allocations.rows.filter(
            (row) =>
              row.enterprise_id === selectedEnterprise && row.property_id === this.props.property_id
          )
        : allocations.rows.filter((row) => row.property_id === this.props.property_id);
    }

    return (
      <Row className="h-100">
        <EnterpriseAllocationModal
          onRefresh={this.onRefresh}
          setModal={this.setEnterpriseModal}
          id={selectedEnterprise}
          isOpen={isEnterpriseModalOpen}
          isNew={selectedEnterprise === null}
        />
        <Col sm={5} className="m-0 p-2">
          <div className="d-flex justify-content-between border-bottom border-lightgray pb-2">
            <h5>Allocation History</h5>
            <div className="d-flex justify-content-end">
              <FormInput
                handleChange={(event) => this.selectEnterprise(event)}
                control={enterprise_control}
              />
              <Button
                className="ml-2"
                size="sm"
                disabled={!isSelectedEnterprise}
                color="success"
                onClick={() => this.setEnterpriseModal(true, selectedEnterprise)}
              >
                Add Allocation
              </Button>
              <Button
                className="ml-2"
                size="sm"
                color="success"
                onClick={() => this.setEnterpriseModal(true)}
              >
                Add Enterprise
              </Button>
            </div>
          </div>
          <GenericLsv
            controls={controls}
            iconName={iconName}
            emptyCaption={emptyCaption}
            onClick={(id) => {
              this.setEnterpriseModal(true, id);
            }}
            rows={filtered_allocations}
          />
        </Col>
        <Col sm={7} className="border border-secondary m-0 mb-2 p-2 h-10">
          <Mapster
            handleSourceVisibility={() => {}}
            expandMap={false}
            lngLatCenter={lngLat}
            toggleMap={() => {}}
            mapSources={mapSources}
            onMapLoad={this.onMapLoad}
          />
        </Col>
      </Row>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    manage: store.manage,
    enterprises: store.enterprises,
  };
};

export default connect(mapStoreToProps)(PropertyAllocations);
