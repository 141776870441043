import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';

const ChartTitle = (props) => {
  const {
    title,
    togglePane,
    isPaneOpen,
    className,
    disableConfig
  } = props;

  const editIconName = (isPaneOpen ? 'xmark' : 'gear')
  const classes = `d-flex flex-rows justify-content-between p-1 ${className}`;
  return (
    <React.Fragment>
      <div className={classes}>
        <div className="m-2">{title}</div>
        {!disableConfig && (
          <div className="d-flex flex-rows justify-content-end">
            <Icon className="analytics-icon m-2 ml-2" name={editIconName} onClick={togglePane} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default ChartTitle;
