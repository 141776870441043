import { apiAnalysisGroupMembers } from 'jsx/constants/api';
import { get, post, put, remove } from 'jsx/lib/genericAction';

export function fetchAnalysisGroupMembers(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ANALYSIS_GROUP_MEMBERS', `${apiAnalysisGroupMembers}`, params);
}

export function fetchAnalysisGroupMember(params) {
  return (dispatch) =>
    get(dispatch, 'FETCH_ANALYSIS_GROUP_MEMBER', `${apiAnalysisGroupMembers}/${params.id}`, params);
}

export function updateAnalysisGroupMember(data) {
  return (dispatch) =>
    put(dispatch, 'UPDATE_ANALYSIS_GROUP_MEMBER', `${apiAnalysisGroupMembers}/${data.id}`, data);
}

export function createAnalysisGroupMember(data) {
  return (dispatch) =>
    post(dispatch, 'CREATE_ANALYSIS_GROUP_MEMBER', `${apiAnalysisGroupMembers}`, data);
}

export function removeAnalysisGroupMember(id) {
  return (dispatch) =>
    remove(dispatch, 'REMOVE_ANALYSIS_GROUP_MEMBER', `${apiAnalysisGroupMembers}/${id}`);
}
