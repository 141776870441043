import {
  uploadStratas,
  uploadSites
} from '../actions/properties';

export default class Uploads {
  set(property_id, dispatch) {
    this.property_id = property_id;
    this.dispatch = dispatch;
  }

  async handleSiteUpload(event, onProgressChange) {
    if (event.target.files.length === 0 ) return;

    const file = event.target.files[0];

    let formData = new FormData();
    formData.append('geom', file);
    formData.append('property_id', this.property_id);

    await this.dispatch(uploadSites(formData, onProgressChange));
  }

  async handleStrataUpload(event, onProgressChange) {
    if (event.target.files.length === 0 ) return;

    const file = event.target.files[0];

    let formData = new FormData();
    formData.append('geom', file);
    formData.append('property_id', this.property_id);

    await this.dispatch(uploadStratas(formData, onProgressChange));
  }

}