import { allowed, defaults } from '../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    responseMessage: null,
    periods: { count: 0, rows: [] },
    propertyPeriods: { count: 0, rows: [] },
    params: {
      search_value: '',
    },
    selectedPropertyAnalysisYear: null,
  },
  action,
) {
  // Set expected reducer types
  const types = ['ANALYSIS_PERIOD'];

  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_ANALYSIS_PERIODS_FULFILLED': {
      return {
        ...updatedState,
        periods: action.payload,
      };
    }
    case 'FETCH_PROJECT_PROPERTY_ANALYSIS_PERIODS_FULFILLED': {
      return {
        ...updatedState,
        propertyPeriods: action.payload,
      };
    }
    case 'SET_ANALYSIS_PERIOD_PARAMS': {
      return {
        ...updatedState,
        params: action.payload,
      };
    }
    case 'SET_PROJECT_PROPERTY_ANALYSIS_PERIOD_PARAMS': {
      return {
        ...updatedState,
        params: action.payload,
      };
    }
    case 'SET_ANALYSIS_PERIOD_RESPONSE_MESSAGE': {
      return {
        ...updatedState,
        responseMessage: action.payload,
      };
    }
    case 'SET_SELECTED_PROPERTY_ANALYSIS_YEAR': {
      return {
        ...updatedState,
        selectedPropertyAnalysisYear: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
