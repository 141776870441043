import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import DirectUpload from '../../../core/form/components/DirectUpload';
import DirectDownload from '../../../core/form/components/DirectDownload';

const AreaRoundMenu = (props) => {
  const {
    handleSiteUpload,
    handleSiteGenerate,
    handleStrataUpload,
    handleRoundManifestDownload,
    handleLocationsPackDownload,
    handleRoundScansDownload,
    handleResultsPackDownload,
    handleAuditPackDownload,
    handleReportingPackDownload,
    handleSelectionScansDownload,
    handleExportSitesDownload,
    toggleMiniMap,
    pipelinesDisabled,
    clearPipelines,
    checkAccess,
  } = props;

  return (
    <React.Fragment>
      <div className="mt-1">
        <UncontrolledButtonDropdown>
          <DropdownToggle caret color="primary" size="sm">
            Action{' '}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header className="bg-light">
              Map Actions
            </DropdownItem>
            <DropdownItem toggle={true} onClick={toggleMiniMap}>
              Toggle Mini Map
            </DropdownItem>

            <DropdownItem header className="bg-light">
              Download Actions
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(onProgressChange) => handleRoundManifestDownload(onProgressChange)}
                caption="Download Drill Manifest for Round"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(onProgressChange) => handleLocationsPackDownload(onProgressChange)}
                caption="Download Round Locations Pack"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(onProgressChange) => handleRoundScansDownload(onProgressChange)}
                caption="Download ALL Round Scans"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(onProgressChange) => handleResultsPackDownload(onProgressChange)}
                caption="Download Round Results Documents"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(onProgressChange) => handleAuditPackDownload(onProgressChange)}
                caption="Download Round Audit Pack"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(onProgressChange) => handleReportingPackDownload(onProgressChange)}
                caption="Download Round Reporting Pack"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(onProgressChange) =>
                  handleSelectionScansDownload(onProgressChange)
                }
                caption="Download Lab Selection Scans Report"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(onProgressChange) => handleExportSitesDownload(onProgressChange)}
                caption="Export ALL Sites to CSV"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem divider />
            <DropdownItem header className="bg-light">
              Upload Actions
            </DropdownItem>

            <DropdownItem toggle={false} disabled={!checkAccess('roundMapUpdate')}>
              <DirectUpload
                handleUpload={handleStrataUpload}
                caption="Upload Strata Geometry for Round"
                size="sm"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem
              toggle={true}
              onClick={handleSiteUpload}
              disabled={!checkAccess('roundMapUpdate')}
            >
              Upload Site Geometry for all Stratas
            </DropdownItem>

            <DropdownItem
              toggle={true}
              onClick={handleSiteGenerate}
              disabled={!checkAccess('roundMapUpdate')}
            >
              Generate Site Geometry for all Stratas
            </DropdownItem>

            {/* <DropdownItem toggle={false} disabled={!checkAccess('roundMapUpdate')}>
              <DirectUpload
                handleUpload={handleSiteUpload}
                caption="Upload Site Geometry for all Stratas"
                size="sm"
                showLabel={true}
              />
            </DropdownItem> */}

            <DropdownItem divider />
            <DropdownItem header className="bg-light">
              Pipeline Actions
            </DropdownItem>

            <DropdownItem
              toggle={true}
              onClick={clearPipelines}
              disabled={!checkAccess('roundUpdate') || pipelinesDisabled}
            >
              Clear Pipelines
            </DropdownItem>

            {/* <DropdownItem toggle={true} onClick={queueAllCalcs} disabled={true}>
              Run All Calcs (Full Pipeline)
            </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </React.Fragment>
  );
};

export default AreaRoundMenu;
