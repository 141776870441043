import { controlDefaults } from '../../../core/form/lib/validateForm';
import { columnWidths } from '../../portrait/constants/listviews';

export const controls = {
  location_id: {
    ...controlDefaults,
    name: 'location_id',
    fieldName: 'location.name',
    type: 'select',
    caption: 'Location',
    showInEditor: true,
    showInListview: true,
    width: `${columnWidths.common}px`,
  },
  project_id: {
    ...controlDefaults,
    name: 'project_id',
    fieldName: 'project.name',
    type: 'select',
    caption: 'Add Project to Location',
    showInEditor: true,
    showInListview: true,
  },
};
