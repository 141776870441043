import React from 'react';
import Listview from 'jsx/components/core/form/components/Listview';
import FormInput from 'jsx/components/core/form/components/FormInput';
import { noop } from 'lodash';
import { columnWidths } from 'jsx/components/modules/portrait/constants/listviews';
import { keycodes } from 'jsx/constants/keycodes';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const SamplingPlanCellsLsv = ({
  category = '',
  rows = [],
  focusAboveCell = noop,
  focusNextCell = noop,
  focusNextColumn = noop,
  focusPreviousColumn = noop,
  onCellChange = noop,
  removeRow = noop,
  setEditMode = noop,
}) => {
  const renderRows = (headers, row, rowIndex) =>
    headers.map(({ classes, field }, headerIndex) => {
      const cell = row[field];

      const control = {
        id: `${field}-${headerIndex}`,
        isEdit: cell?.isEdit || false,
        name: field,
        type: 'number',
        value: cell?.value || null,
      };

      if (control.isEdit)
        return (
          <td
            key={`td-input-${headerIndex}`}
            className={classes}
            width={`${columnWidths.common}px`}
          >
            {
              <FormInput
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={true}
                className={'text-center p-0 slim'}
                handleChange={(event) => onCellChange(event, rowIndex)}
                handleKeyDown={(event) =>
                  handleKeyDown(event, field, rowIndex, headerIndex, headers)
                }
                handleBlur={() => handleBlur(field, rowIndex)}
                control={control}
              />
            }
          </td>
        );

      // Add remove button to all rows except first row
      if (field === 'remove')
        return (
          <td key={`td-remove-${headerIndex}`} className={classes} width={`${columnWidths.icon}`}>
            <Button
              className="btn btn-danger"
              disabled={rows.length === 1}
              onClick={() => removeRow(rowIndex)}
              size="sm"
            >
              <Icon name={'trash'} />
            </Button>
          </td>
        );

      return (
        <td
          role="presentation"
          key={`td-${headerIndex}`}
          className={classes}
          onClick={() => setEditMode(field, rowIndex, true)}
          style={{ cursor: 'pointer' }}
          width={`${columnWidths.common}px`}
        >
          {control.value}
        </td>
      );
    });

  const handleKeyDown = async (event, field, rowIndex, columnIndex, headers) => {
    const { down, enter, tab, up } = keycodes;
    const { keyCode, shiftKey } = event;

    switch (keyCode) {
      case down:
      case enter: {
        // Unfocus current element
        await handleBlur(field, rowIndex);

        focusNextCell(field, rowIndex);
        break;
      }
      case tab: {
        // Prevent default functionality for tab key.
        event.preventDefault();
        await handleBlur(field, rowIndex);

        if (shiftKey) {
          focusPreviousColumn(field, rowIndex, columnIndex, headers);
        } else {
          focusNextColumn(field, rowIndex, columnIndex, headers);
        }
        break;
      }
      case up: {
        // Unfocus current element
        await handleBlur(field, rowIndex);

        focusAboveCell(field, rowIndex);
        break;
      }
      default: {
        break;
      }
    }
  };

  // On unfocus, turn off edit mode
  const handleBlur = (field, index) => {
    setEditMode(field, index, false);
  };

  const headers = [
    {
      caption: 'Gamma',
      field: 'gamma',
      classes: 'text-center',
    },
    {
      caption: 'Spectrometer',
      field: 'spectrometer',
      classes: 'text-center',
    },
    {
      caption: 'Camera',
      field: 'camera',
      classes: 'text-center',
    },
    {
      caption: 'Scale',
      field: 'scale',
      classes: 'text-center',
    },
    {
      caption: null,
      field: 'remove',
      classes: 'text-center',
    },
  ];

  const tableHeadTh = headers.map(({ caption, classes, field }) => {
    return (
      <th key={`header-${field}`} className={classes}>
        {caption}
      </th>
    );
  });

  const tableBodyTr =
    rows.length > 0 ? (
      rows.map((row, index) => <tr key={`tr-${index}`}>{renderRows(headers, row, index)}</tr>)
    ) : (
      <tr></tr>
    );

  return <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} />;
};

export default SamplingPlanCellsLsv;
