import React from 'react';
import Listview from '../../../core/form/components/Listview';

const ProbesLsv = (props) => {
  const { rows, history } = props;

  const edit = (row) => {
    const lnk = `/home/probes/${row.id}`;
    history.push(lnk);
  };

  const renderRows = (headers, record) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        default:
          return (
            <td key={index} className={header.classes}>
              {record[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const iconName = 'ufo-beam';
  const emptyCaption = 'No Probes found';

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Analysis Period', field: 'period', classes: 'text-left' },
    { caption: 'Month Span', field: 'months', classes: 'text-center' },
    { caption: 'Property', field: 'property', classes: 'text-right' },
    { caption: 'Available Land', field: 'available_land', classes: 'text-right' },
    { caption: 'Code', field: 'code', classes: 'text-right' },
    { caption: 'Status', field: 'status', classes: 'text-right' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  let rowsCount;

  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => (
      <tr key={index} onClick={() => edit(row)} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <div className="b">
      <Listview
        rows={rows}
        rowsCount={rowsCount}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </div>
  );
};

export default ProbesLsv;
