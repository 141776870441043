import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  name: {
    ...controlDefaults,
    placeholder: 'Enter Report Name...',
    name: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  requiresSubscription: {
    ...controlDefaults,
    name: 'requires_subscription',
    type: 'checkbox',
    caption: 'Requires subscription',
    showInEditor: false,
    showInListview: true,
  },
};
