import React from 'react';

const InlineCell = ({
  label = '',
  value = '',
  labelClassName = '',
  valueClassName = '',
  wrapperClassName = '',
  isValid = true,
  message = '',
}) => {
  let className = 'p-1 px-3 ml-2 rounded';

  if (!isValid) className += ' border-0 bg-danger text-white';
  else className += ' bg-white text-black';

  return (
    <div className={wrapperClassName}>
      <span className={labelClassName}>{label}</span>
      <span className={`${className} ${valueClassName}`} title={!isValid ? message : null}>
        {value}
      </span>
    </div>
  );
};

export default InlineCell;
