import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import Pill from 'jsx/components/core/form/components/Pill';
import PillPicker from 'jsx/components/core/form/components/PillPicker';

/**
 * Maps the selected ids (sourceIds) to the full list of ids with additional data (idsToMatch)
 */
const mapSelectedIdsToData = (sourceIds, idsToMatch) =>
  idsToMatch.reduce((acc, curr) => {
    const matched = sourceIds.find((sids) => sids.id === curr);
    if (matched) {
      acc.push(matched);
    }
    return acc;
  }, []);

export const DaysheetComboFilter = ({ onFilter }) => {
  const locationRecords = useSelector(({ wipstar }) => wipstar.locations);
  const projectRecords = useSelector(({ projects }) =>
    projects.projects?.rows?.map(({ id, name, project_job_no }) => ({
      id,
      name: `${name} (${project_job_no})`,
    })),
  );

  let { locations: selectedLocations, projects: selectedProjects } = useSelector(
    ({ wipstar }) => wipstar.params,
  );

  selectedLocations = React.useMemo(
    () => mapSelectedIdsToData(locationRecords, selectedLocations),
    [locationRecords, selectedLocations],
  );
  selectedProjects = React.useMemo(
    () => mapSelectedIdsToData(projectRecords, selectedProjects),
    [projectRecords, selectedProjects],
  );

  const activeFilterCount = selectedLocations.length + selectedProjects.length;

  return (
    <>
      <Row className="align-items-top py-2">
        <Col>
          <PillPicker
            caption="Locations"
            handleChange={(event) => onFilter('change', 'locations', event.target.value)}
            rows={locationRecords}
            selection={selectedLocations}
            showPills={false}
            name="locations"
            inputSearch
          />
        </Col>
        <Col>
          <PillPicker
            caption="Projects"
            handleChange={(event) => onFilter('change', 'projects', event.target.value)}
            rows={projectRecords}
            selection={selectedProjects}
            showPills={false}
            name="projects"
            inputSearch
          />
        </Col>

      </Row>
      {activeFilterCount > 0 && (
        <Row className="ml-1">
          {selectedProjects.length > 0 && (
            <div className="p-1 d-flex mr-2 align-items-center">
              <small className="pr-2">Projects:</small>
              {selectedProjects.map((project) => (
                <Pill
                  size="sm"
                  key={project.id}
                  caption={project.name}
                  classes="bg-blue"
                  onDelete={() => onFilter('delete', 'projects', project.id)}
                />
              ))}
            </div>
          )}
          {selectedLocations.length > 0 && (
            <div className="p-1 d-flex mr-2 align-items-center">
              <small className="pr-2">Locations:</small>
              {selectedLocations.map((location) => (
                <Pill
                  size="sm"
                  key={location.id}
                  caption={location.name}
                  classes="bg-blue"
                  onDelete={() => onFilter('delete', 'locations', location.id)}
                />
              ))}
            </div>
          )}
        </Row>
      )}
    </>
  );
};
