import React from 'react';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import AdminProbeSectionEditor from './AdminProbeSectionEditor';

const AdminProbeTemplateToolbar = (props) => {
  const {
    onRefresh = noop,
    showSectionEditor = false,
    onSectionSave = noop,
    toggleSectionClose = noop,
    sectionControls = noop,
    onHandleSectionChange = noop,
    template = {}
  } = props;

  const description = `${template?.probe?.name} - Version ${template?.version}`;
  return (
    <div className="d-flex justify-content-between bg-light rounded">
      <div className="m-2">
        {description}
      </div>
      
      <div className="m-1 p-1 d-flex justify-content-end">
        {!showSectionEditor && (
          <Button onClick={() => toggleSectionClose(true)} size="sm" color="pink" className="ml-1 text-white">
            Add Section
          </Button>
        )}
        {showSectionEditor && (
          <AdminProbeSectionEditor onSave={onSectionSave} toggleSectionClose={toggleSectionClose} controls={sectionControls} onHandleChange={onHandleSectionChange}/>
        )}

        <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
          <Icon name="rotate" />
        </Button>
      </div>
    </div>
  );
};

export default AdminProbeTemplateToolbar;
