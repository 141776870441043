/* eslint-disable react/display-name, @typescript-eslint/no-empty-function */
import React, { forwardRef } from 'react';
import { Input } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useSelector } from 'react-redux';

const FilterIntervalDatePicker = (props) => {
  const {
    intervalOptions = [],
    interval,
    transaction_date,
    onIntervalChange,
    onIntervalDateChange,
    popperPlacement = 'bottom-start',
  } = props;

  let options = {
    className: 'form-control',
    dateFormat: 'dd/MM/yyyy',
  };

  const tag = intervalOptions?.find((option) => option?.id === interval?.id)?.tag ?? 'day';

  let selectedInterval = { value: interval?.id, label: interval?.name };
  if (selectedInterval === undefined) {
    selectedInterval = { value: null, label: '-' };
  }

  const { selectedRanges } = useSelector((state) => state.enterprises);

  const date_value = transaction_date ? new Date(transaction_date) : null;
  switch (tag) {
    case 'all_time': {
      const RangeInput = forwardRef(({ startDate, endDate, onClick }, ref) => (
        <Input
          onClick={onClick}
          ref={ref}
          onChange={() => {}}
          value={`${startDate} - ${endDate}`}
          disabled
        />
      ));

      const startDate = new Date(selectedRanges.from_date);
      const endDate = date_value;

      const dateFormat = 'MMM YY';
      options = {
        ...options,
        startDate,
        endDate,
        showMonthYearPicker: true,
        showFullMonthYearPicker: true,
        showTwoColumnMonthYearPicker: true,
        customInput: (
          <RangeInput
            startDate={moment(startDate).format(dateFormat)}
            endDate={moment(endDate).format(dateFormat)}
            disabled
          />
        ),
      };
      break;
    }
    case 'year':
    case 'half_year': {
      // Create custom input to display dates in a range
      const RangeInput = forwardRef(({ startDate, endDate, onClick }, ref) => (
        <Input
          onClick={onClick}
          ref={ref}
          onChange={() => {}}
          value={`${startDate} - ${endDate}`}
        />
      ));

      const startDate = new Date(
        moment(date_value)
          .subtract(tag === 'year' ? 11 : 5, 'months')
          .format('YYYY-MM-DD'),
      );
      const endDate = date_value;
      const dateFormat = 'MMM YY';
      options = {
        ...options,
        startDate,
        endDate,
        showMonthYearPicker: true,
        showFullMonthYearPicker: true,
        showTwoColumnMonthYearPicker: true,
        customInput: (
          <RangeInput
            startDate={moment(startDate).format(dateFormat)}
            endDate={moment(endDate).format(dateFormat)}
          />
        ),
      };
      break;
    }
    case 'quarter': {
      options = {
        ...options,
        dateFormat: 'yyyy, Qo (MMM)',
        showQuarterYearPicker: true,
      };
      break;
    }
    case 'month': {
      options = {
        ...options,
        dateFormat: 'MMM yy',
        showMonthYearPicker: true,
        showFullMonthYearPicker: true,
        showTwoColumnMonthYearPicker: true,
      };
      break;
    }
    case 'day':
    default: {
      break;
    }
  }

  return (
    <div className="d-flex align-items-center" style={{ height: '20px' }}>
      <span className="pr-1">Interval: </span>
      <Select
        value={selectedInterval}
        defaultValue={[]}
        placeholder="Select Interval"
        id="interval-select"
        options={intervalOptions || []}
        className="basic-select"
        classNamePrefix="select"
        onChange={(event) => onIntervalChange(event, transaction_date, intervalOptions)}
      />

      <span className="pl-3 pr-1">Date: </span>
      <DatePicker
        caption="Transaction Date"
        selected={date_value}
        onChange={(date) => onIntervalDateChange(date)}
        id="transaction-date"
        placeholderText="-"
        popperPlacement={popperPlacement}
        {...options}
      />
    </div>
  );
};

export default FilterIntervalDatePicker;
