import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { GenericModal } from '../../../../core/form/components/GenericModal';
import {
  initControls,
  updateControls,
  updateControlOptions,
} from '../../../../core/form/lib/validateForm';
import { cloneDeep, isEmpty } from 'lodash';

class WoolAdjustmentModal extends GenericModal {
  constructor(props) {
    super(props);

    this.loadProperties = this.loadProperties.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { modalTitle } = this.props;
      const controls = initControls(cloneDeep(this.props.controls));

      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        isOpen: this.props.isOpen,
        setModal: this.props.setModal,
        title: `New ${modalTitle}`,
      };

      if (this.props.data?.id) {
        const { data } = this.props;
        updatedState = {
          ...updatedState,
          controls: updateControls(controls, data),
          data,
          id: data.id,
          isNew: false,
          title: `Edit ${modalTitle}`,
        };
      }

      this.setState(updatedState);
    }
  }

  loadProperties(enterprise_id) {
    const { enterprises } = this.props.enterprises;

    // Only display properties that have been previously allocated to the enterprise
    const { allocations = [] } = enterprises?.rows.find(
      (enterprise) => enterprise.id === enterprise_id
    );

    return allocations.map((allocation) => ({ ...allocation.property }));
  }

  render() {
    const { title, isNew, isOpen, description } = this.state;
    let { controls } = this.state;
    const { iconName, responseMessage } = this.props;

    controls = this.setOptions(controls);

    const enterprise_id = controls?.enterprise_id?.value ?? null;
    if (enterprise_id) {
      const filteredProperties = this.loadProperties(enterprise_id);
      controls = updateControlOptions(controls, 'property_id', filteredProperties);
      controls.property_id.showInEditor = filteredProperties.length > 1;
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody>
          {description && <p>{description}</p>}
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>{!isEmpty(controls) ? this.renderInputs(controls) : null}</Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    enterprises: store.enterprises,
  };
};

export default connect(mapStoreToProps)(WoolAdjustmentModal);
