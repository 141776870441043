import React from 'react';
import Listview from '../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import { columnWidths } from '../constants/listviews';

const LiabilityTransactionsLsv = (props) => {
  const { darkTable, editTransaction, hideHeader, rows } = props;

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      let { classes, field, formattingRules, type, width } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (let key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }

      if (formattingRules) {
        caption = numberRuleFormat(caption, formattingRules);
      }

      switch (type) {
        case 'editTransaction':
          return (
            <td
              key={index}
              className={classes}
              onClick={() => editTransaction(row.id)}
              width={width}
            >
              {caption}
              <Icon name="pen-to-square" className="white ml-2 cursor-pointer " />
            </td>
          );
        case 'date':
          return (
            <td key={index} className={classes} width={width}>
              {caption ? moment(caption).format('Do MMM YYYY') : ''}
            </td>
          );
        case 'date-month':
          return (
            <td key={index} className={classes} width={width}>
              {caption ? moment(caption).format('MMMM YYYY') : ''}
            </td>
          );
        default:
          return (
            <td key={index} className={classes} width={width}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const { common, icon, offset } = columnWidths;
  let headers = [
    {
      caption: 'Month',
      field: 'transaction_date',
      classes: 'text-left border-right border-corporate border-top-0',
      type: 'date-month',
      width: `${common * 3 - offset}px`,
      sortColumn: 'created',
    },
    {
      caption: 'Transaction Type',
      field: 'transaction_type.name',
      classes: 'text-left border-right border-corporate border-top-0',
      width: `${common * 4}px`,
    },
    {
      caption: 'Amount',
      field: 'amount',
      classes: 'text-right',
      totals: true,
      formattingRules: { includeCommas: true, includeDecimals: false, includeDollarSign: true },
    },
    {
      caption: '',
      type: 'editTransaction',
      classes: 'text-center',
      width: `${icon - offset}px`,
    },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  const iconName = 'farm';
  const emptyCaption = 'No Asset Transactions found';

  return (
    <Listview
      darkTable={darkTable}
      hideHeader={hideHeader}
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default LiabilityTransactionsLsv;
