import { apiActivities } from '../../../../constants/api.js';

import { get, post } from '../../../../lib/genericAction';

export function setFilters(params, filters, org_search = false) {
  return function (dispatch) {
    dispatch({ type: 'SET_ACTIVITY_FILTERS', payload: filters });
    dispatch(fetchActivities(params, filters, org_search));
  };
}

export function fetchOrgActivityTypes() {
  return async (dispatch) =>
    await get(dispatch, `FETCH_ORG_ACTIVITY_TYPES`, `${apiActivities}/orgs/types`);
}

export function fetchActivities(params, filters, org_search = false) {
  return async (dispatch) => {
    // Handle params
    if (Object.keys(params)?.length > 0) dispatch({ type: 'SET_ACTIVITY_PARAMS', payload: params });

    // Handle filters
    if (filters) Object.keys(filters).forEach((key) => (params[`filter_${key}`] = filters[key]));

    // Define conditional route
    const route = org_search ? `${apiActivities}/orgs` : `${apiActivities}`;

    // Fetch activities from API
    return get(dispatch, 'FETCH_ACTIVITIES', `${route}`, params);
  };
}

export function createActivity(data) {
  const dispatch_prefix = 'CREATE_ACTIVITY';
  return async (dispatch) => {
    await post(dispatch, dispatch_prefix, `${apiActivities}`, data);
  };
}
