import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    placeholder: 'Brief upload description',
    name: 'name',
    type: 'text',
    caption: 'Name',
    description:
      'Basic identification of upload batch group. Groups together all core locations in upload file.',
    validationRules: {
      isRequired: true,
    },
  },
  instrument_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'instrument_id',
    type: 'select',
    caption: 'Instrument',
    description: 'Identifies which file type is expected and translates fields',
    validationRules: {
      isRequired: true,
    },
  },
  property_search: {
    ...controlDefaults,
    placeholder: 'Type your property to start searching',
    name: 'property_search',
    type: 'search',
    caption: 'Property',
    description: 'Property to which the CORE IDs in the upload file refer to.',
    validationRules: {
      isRequired: true,
      minLength: 3,
    },
  },
  property_id: {
    ...controlDefaults,
    name: 'property_id',
    type: 'hidden',
    options: [],
    validationRules: {
      isRequired: true,
    },
  },
  logfile: {
    ...controlDefaults,
    placeholder: 'Field Instrument Upload file...',
    name: 'logfile',
    type: 'file',
    caption: 'Upload Instrument File',
    description:
      'A Sqlite file for Guidex normally named guidex_project_db_log.sqlite. If this file does not exist in your instrument downloaded zip, look for file suffixed *_log.sqlite',
    fileUploaded: true,
    validationRules: {},
  },
};
