import { controlDefaults } from '../../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    caption: 'Name',
    name: 'name',
    showInEditor: true,
    showInListview: true,
    type: 'text',
    validationRules: {
      isRequired: true,
    },
  },
  enabled: {
    ...controlDefaults,
    caption: 'Enabled',
    defaultValue: true,
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    name: 'enabled',
    showInEditor: true,
    showInListview: true,
    type: 'checkbox',
    validationRules: {
      isRequired: true,
    },
  },
};
