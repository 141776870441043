import React from 'react';
import { Input } from 'reactstrap';
import Listview from '../../../core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';

const FieldBookLsv = ({ rows, handleSelect, onRemove, checkAccess }) => {
  const renderRows = (headers, site) => {
    let strata = `CEA${site.trip_site.strata.round.area.name}/${site.trip_site.strata.round.name}/${site.trip_site.strata.name}`;

    let notes = '--';
    if (site.trip_site.notes) {
      notes = `${site.trip_site.notes.substring(0, 30)}...`;
    }

    let collected_at = '';
    if (site.trip_site.collected_at) {
      collected_at = moment(site.trip_site.collected_at).format('YYYY-MM-DD hh:mm A');
    }

    let stickup_height = site.trip_site.stickup_height;
    let stickup_height_bg = '';
    if (stickup_height === null) {
      stickup_height = 'Missing';
      stickup_height_bg = 'bg-danger text-white';
    }

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'checkbox':
          return (
            <td key={index}>
              <Input type="checkbox" className="m-0 mt-1" onChange={handleSelect} value={site.id} />
            </td>
          );
        case 'strata':
          return (
            <td key={index} className={header.classes}>
              {strata}
            </td>
          );
        case 'core_key':
          return (
            <td key={index} className={header.classes}>
              {site.core_key}
            </td>
          );
        case 'notes':
          return (
            <td key={index} className={header.classes}>
              {notes}
            </td>
          );
        case 'collected_at':
          return (
            <td key={index} className={header.classes}>
              {collected_at}
            </td>
          );
        case 'stickup_height':
          return (
            <td key={index} className={`${header.classes} ${stickup_height_bg}`}>
              {stickup_height}
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {site.trip_site[header.field]}
            </td>
          );
      }
    });

    tableTd.push(
      <td
        key={headers.length + 1}
        className="d-flex justify-content-center"
        style={{ cursor: 'pointer' }}
      >
        {checkAccess('fieldBookDelete') && (
          <Icon name="trash" className="text-primary" onClick={() => onRemove(site.id)} />
        )}
      </td>
    );
    return tableTd;
  };

  const headers = [
    { caption: '-', field: 'checkbox', classes: 'text-left' },
    { caption: 'Core Key', field: 'core_key', classes: 'text-left' },
    { caption: 'CEA/Round/Strata', field: 'strata', classes: 'text-left' },
    { caption: 'Date/Time', field: 'collected_at', classes: 'text-left' },
    { caption: 'Target Depth', field: 'target_depth', classes: 'text-right' },
    { caption: 'Stickup Height', field: 'stickup_height', classes: 'text-right' },
    { caption: 'Actual Depth', field: 'actual_depth', classes: 'text-right' },
    { caption: 'Notes', field: 'notes', classes: 'text-left' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));
  tableHeadTh.push(<th key={headers.length + 1}></th>);

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  const iconName = 'bars';
  const emptyCaption = 'No Fieldbook entries found';

  return (
    <Listview
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default FieldBookLsv;
