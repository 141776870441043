import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import {
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import DirectDownload from '../../../core/form/components/DirectDownload';

const CoreArchivesToolbar = (props) => {
  let {
    handleDownload,
    refresh
  } = props;

  return (
    <div className="bg-light p-2 d-flex justify-content-end">
        <Button id="scanArchiveToggler" size="sm" color="warning" className="text-white ml-2 mr-2">
          <Icon name="filter" className="mr-2" />
            Filter
        </Button>

        <Button color='success' onClick={refresh} className="mr-2" size="sm">
          <Icon name="rotate" />
        </Button>

        <UncontrolledButtonDropdown>
          <DropdownToggle caret color='primary' size="sm">Action</DropdownToggle>
          <DropdownMenu right>
            <DropdownItem toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(onProgressChange) => handleDownload(onProgressChange)}
                caption="Download Selected"
                showLabel={true}
              />
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
    </div>
  );
}

export default CoreArchivesToolbar;
