import React from 'react';
import { connect } from 'react-redux';
import FormTab from '../../core/form/components/FormTab';
import FormBase from '../../core/form/components/FormBase';
import { Nav, TabContent, TabPane } from 'reactstrap';
import GenericLsv from '../../core/form/components/GenericLsv';
import { controls as metricControls } from '../forms/metrics';
import { cloneDeep } from 'lodash';
import Reports from './Reports';

import { fetchLookupMetrics, fetchReports } from '../../modules/portrait/actions/reports';
import MetricsToolbar from '../components/metrics/MetricsToolbar';

class ReportMetrics extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      metricEditorUrl: '/home/metrics/editor',
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.onAddMetric = this.onAddMetric.bind(this);
    this.onEditMetric = this.onEditMetric.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchLookupMetrics());
    this.props.dispatch(fetchReports());
  }

  toggleTab(tab) {
    const { activeTab } = this.props.reports;
    if (activeTab !== tab) this.props.dispatch({ type: 'SET_METRICS_ACTIVE_TAB', payload: tab });

    switch (tab) {
      case 'metrics': {
        this.props.dispatch(fetchLookupMetrics());
        break;
      }
      case 'groups': {
        this.props.dispatch(fetchReports());
        break;
      }
      default: {
        break;
      }
    }
  }

  onAddMetric() {
    const { metricEditorUrl } = this.state;
    this.props.history.push(metricEditorUrl);
  }

  onEditMetric(id) {
    const { metricEditorUrl } = this.state;
    this.props.history.push(`${metricEditorUrl}/${id}`);
  }

  render() {
    const { activeTab, lookupMetrics } = this.props.reports;

    return (
      <>
        <Nav tabs className="mt-3">
          <FormTab
            caption="Metrics"
            tabId="metrics"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Reports"
            tabId="reports"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="metrics">
            <MetricsToolbar onAddMetric={this.onAddMetric} />
            <GenericLsv
              controls={cloneDeep(metricControls)}
              darkTable={false}
              emptyCaption={'No Metrics Definitions found'}
              hideHeader={false}
              iconName={'ruler'}
              onClick={(id) => {
                this.onEditMetric(id);
              }}
              rows={lookupMetrics}
            />
          </TabPane>
          <TabPane tabId="reports">
            <Reports history={this.props.history} />
          </TabPane>
        </TabContent>
      </>
    );
  }
}

const mapStoreToProps = ({ reports }) => {
  return {
    reports,
  };
};

export default connect(mapStoreToProps)(ReportMetrics);
