import React, { Fragment, useState } from 'react';
import { Collapse } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import PropertySearchSourceLayer from './PropertySearchSourceLayer';

const PropertySearchSource = (props) => {
  const {
    source,
    removeSelectedSource,
    setSourceVisibility,
    setLayerProperty,
    setLayerZoomRange,
    sortSources,
    selectedLength,
    index,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [isSourceVisible, toggleSourceVisible] = useState(true);
  const toggleVisible = () => {
    setSourceVisibility(source, !isSourceVisible);
    toggleSourceVisible(!isSourceVisible);
  };
  let layerRows;
  if (source.layers?.length > 0) {
    layerRows = source.layers.map((layer, index) => {
      return (
        <PropertySearchSourceLayer
          key={index}
          layer={layer}
          setLayerProperty={setLayerProperty}
          setLayerZoomRange={setLayerZoomRange}
        />
      );
    });
  }

  const visibleIcon = isSourceVisible ? 'eye' : 'eye-slash';
  const expandedIcon = isOpen ? 'chevron-up' : 'chevron-down';

  return (
    <Fragment>
      <div className="m-1 border border-secondary rounded">
        <div className=" d-flex justify-content-between p-2">
          <div className="d-flex">
            <div
              className={`text-white d-flex justify-content-${
                selectedLength > 1 && index === selectedLength - 1 ? 'start' : 'end'
              } mr-2`}
              style={{ width: '50px' }}
            >
              {selectedLength > 1 && index !== 0 && (
                <Icon
                  onClick={() => sortSources(source.id, 'up')}
                  name="arrow-up"
                  className="m-1 mr-2"
                />
              )}

              {selectedLength > 1 && index !== selectedLength - 1 && (
                <Icon
                  onClick={() => sortSources(source.id, 'down')}
                  name="arrow-down"
                  className="m-1 mr-2"
                />
              )}
            </div>
            <div className="d-flex justify-content-start">
              <div>{source.name}</div>
            </div>
          </div>
          <div className="text-white d-flex justify-content-end">
            <Icon onClick={toggle} name={expandedIcon} className="m-1" />
          </div>
        </div>

        <Collapse isOpen={isOpen}>
          <div style={{ backgroundColor: '#333333' }} className="p-1">
            <div className="p-1">
              <small className="text-corporate">
                Source: <span className="text-white">{source.name}</span>
              </small>
            </div>
            <div className="p-1 pt-0">
              <small className="text-corporate">
                Type: <span className="text-white">{source.service}</span>
              </small>
            </div>
            <div className="p-1 pt-0">
              <small className="text-corporate">
                Locality: <span className="text-white">{source.locality_tag}</span>
              </small>
            </div>

            <div className="border-top border-secondary mt-2">{layerRows}</div>

            <div className="text-white d-flex mt-2 justify-content-end border-top border-secondary p-2">
              <Icon name={visibleIcon} className="mr-3" onClick={() => toggleVisible()} />
              <Icon name="info" className="mr-3" />
              <Icon
                name="trash"
                className="text-danger mr-2"
                onClick={() => removeSelectedSource(source)}
              />
            </div>
          </div>
        </Collapse>
      </div>
    </Fragment>
  );
};

export default PropertySearchSource;
