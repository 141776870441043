import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Nav,
  TabContent,
  TabPane,
} from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormBase from 'jsx/components/core/form/components/FormBase';
import FormTab from 'jsx/components/core/form/components/FormTab';

import { controls } from '../forms/asset_reading';

import ModalAssetReadingLsv from '../components/ModalAssetReadingsLsv';
import { cloneDeep } from 'lodash';

class AssetReadingsModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      isValid: true,
      data: {},
      setModal: null,
      controls: cloneDeep(controls),
    };
  }

  onCancel() {
    this.props.setAssetReadingModal(false);
  }

  render() {
    const { isModalOpen, readings, onDelete, row } = this.props;
    const name = this.props?.row?.plant_asset?.name || '';
    const description = this.props?.row?.plant_asset?.description || '';

    const iconName = 'tag';
    return (
      <Modal isOpen={isModalOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          Asset No: {name} ({description})
        </ModalHeader>
        <ModalBody>
          <Nav tabs className="mt-3">
            <FormTab
              tabTag="profile"
              caption="Readings"
              tabId="1"
              activeTab={this.state.activeTab}
            />
          </Nav>
          <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
            <TabPane tabId="1" className="mb-2 p-1">
              <ModalAssetReadingLsv rows={readings || []} onDelete={onDelete} row={row} />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AssetReadingsModal;
