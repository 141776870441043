import React from 'react';
import {
  Input,
  Label,
  Row,
  Col
} from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';

const SectionHeader = (props) => {
  const {
    setTopFilter,
    properties,
    propertyRounds,
    topFilter
  } = props;

  // Load options for properties combo
  const propertyOptions = properties.map((property, index) => {
    const name = `${property.project.name}/${property.name}(${property.idx})`;
    return <option key={index} value={property.id}>{name}</option>
  })

  if (!topFilter.property_id) {
    propertyOptions.unshift(<option key={properties.length + 1} value="">Select Property...</option>)
  }

  // Load options for property rounds
  const propertyRoundOptions = propertyRounds.map((propertyRound, index) => {
    return <option key={index} value={propertyRound.id}>{propertyRound.name}</option>
  })
  propertyRoundOptions.unshift(<option key={propertyOptions.length + 1} value="">Select Property Round...</option>)

  // Load options for area rounds (comes with the properties fetch)
  let propertyRound = [];
  let areaRoundOptions = [];
  if (topFilter.property_round_id && propertyRounds.length > 0) {
    propertyRound = propertyRounds.find(propertyRound => propertyRound.id === topFilter.property_round_id);

    areaRoundOptions = propertyRound.area_rounds.map((areaRound, index) => {
      const name = `CEA${areaRound.area.name}`
      return <option key={index} value={areaRound.id}>{name}</option>
    })
  }

  // Decide which combos should be disabled
  const propertyRoundsDisabled = (topFilter.property_id ? false : true);
  const areaRoundsDisabled = (topFilter.property_round_id ? false : true);
  
  const downloadDatasets = () => {
    alert("TODO - Download all datasets for each widget on this page as individual tabs in a xlsx file")
  }

  return (
    <React.Fragment>
      <Row className="bg-white m-0 p-0 border-bottom">
        <Col>
          <div className="d-flex flex-rows justify-content-start bg-white p-2 pb-0 ">
            <Icon size="2x" name="chart-mixed" className="text-corporate" />
            <h2 className="ml-2 text-corporate">Analytics</h2>
          </div>
        </Col>
        <Col>
          <div className="d-flex flex-rows justify-content-center m-1 mt-3 ">
            <Label className="p-1" >Project/Property:</Label>
            <Input type="select" bsSize="sm" style={{width: 450}} value={topFilter.property_id} onChange={(e) => setTopFilter('property_id', e.target.value)}>
              {propertyOptions}
            </Input>

            <Label className="p-1 ml-2" >Round:</Label>
            <Input disabled={propertyRoundsDisabled} type="select" bsSize="sm" style={{width: 200}} value={topFilter.property_round_id} onChange={(e) => setTopFilter('property_round_id', e.target.value)} >
              {propertyRoundOptions}
            </Input>

            <Label className="p-1 ml-2" >CEA's:</Label>
            <Input disabled={areaRoundsDisabled} type="select" bsSize="sm" style={{width: 200}} value={topFilter.area_round_id} onChange={(e) => setTopFilter('area_round_id', e.target.value)}>
              <option value="">Select CEA...</option>
              {areaRoundOptions}
            </Input>
          </div>
        </Col>
        <Col className="text-right m-3" style={{fontSize: 18}}>
          {topFilter.property_id && topFilter.property_round_id && (
            <Icon title="Download Datasets..." name="download" className="text-corporate" onClick={downloadDatasets} />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default SectionHeader;
