import { isArray } from 'lodash';

export const matchFieldPairs = (isMatch, controls, expr) => {
  const matchFieldPairs = extractFieldPairs(controls, expr);

  const results = matchFieldPairs.map((pair) => {
    let matchValue;
    let result = true;

    pair.forEach((field) => {
      if (!matchValue) matchValue = field;

      result = result && field === matchValue;
    });

    return result;
  });

  if (!isMatch) return results.filter((result) => !result).length > 0;

  return results.every(Boolean);
};

export const checkOptionsLength = (options, minLength) => {
  return isArray(options) && options?.length >= minLength;
};

export const populatedFields = (controls, expr) => {
  const populatedFields = extractFields(controls, expr);

  let result = true;
  populatedFields.forEach((field) => {
    if (!field || field.length === 0) {
      result = result && false;
    }
  });
  return result;
};

export const extractFields = (controls, expr) => {
  const fields = [];

  const keys = expr;
  if (keys.length > 0) {
    keys.forEach((key) => {
      let value = 0;
      if (controls[key].value) {
        ({ value } = controls[key]);
      }
      fields.push(value);
    });
  }
  return fields;
};

export const extractFieldPairs = (controls, expr) => {
  const fieldPairs = [];

  const keyPairs = expr;
  if (keyPairs.length === 0) return fieldPairs;

  keyPairs.forEach((pair) => {
    const fields = [];
    pair.forEach((key) => {
      let value = 0;
      if (controls[key].value) {
        ({ value } = controls[key]);
      }

      fields.push(value);
    });

    fieldPairs.push(fields);
  });

  return fieldPairs;
};

export const checkDependencies = (controls, rules) => {
  return Object.entries(rules).every(([controlName, requiredValue]) => {
    const target = controls[controlName];
    if (target.options) {
      const selectedOption = target.options.find(
        (option) => option.id === target.value
      );
      return selectedOption?.name === requiredValue;
    } else {
      return target.value === requiredValue;
    }
  });
};

export const isFieldShown = (controls, control) => {
  if (!control.showRules) return true;

  if (control.showRules.mismatchingFields)
    return matchFieldPairs(
      false,
      controls,
      control.showRules.mismatchingFields
    );

  if (control.showRules.populatedFields)
    return populatedFields(controls, control.showRules.populatedFields);

  if (control.showRules.minimumOptionsLength) {
    return checkOptionsLength(
      control.options,
      control.showRules.minimumOptionsLength
    );
  }

  if (control.showRules.dependsOn) {
    return checkDependencies(controls, control.showRules.dependsOn);
  }

  return true;
};
