import React from 'react';
import { connect } from "react-redux";

import VerticalChart from '../components/VerticalChart';
import Icon from 'jsx/components/core/icons/Icon';
import { v4 as uuidv4 } from 'uuid';

import {
  fetchSiteSplinesByCoreId
} from '../../sites/actions';

class WidgetSpline extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      data: [],
      fetching: false,
      height: 400,
      datasets: [],
      siteId: null,
      widget: {
        id: 'splines_pred_carbon',
        title: 'Predicted Carbon for Splines',
        group: 'samples',
        datasets: [
          {
            id: uuidv4(),
            sourceId: 'splines_pred_carbon',
            series: 'left',
            type: 'line',
            colour: '#cc0000',
            rowName: 'site.core_id',
            selected: {name: 'soc', caption: 'Carbon (%)', unit: '%', colour: 'red'}
          }
        ]
      },
    })
  }

  componentDidUpdate() {
    const {
      fetching,
    } = this.state;

    const selections = this.props.analytics.selections.sites;
    let siteId = null;
    if (selections.length > 0) {
      siteId = selections[0];
    }

    if (siteId && siteId !== this.state.siteId && !fetching) {
      this.load(siteId);
      this.setState({siteId})
    }
  }

  async load(siteId) {
    let datasets = [];
    this.setState(
      {fetching: true},
      async () => {
        const splines = await this.props.dispatch(fetchSiteSplinesByCoreId(siteId));

        // Get defaults for dataset model
        let datasetSamples = this.props.analytics.datasets.samples;
        let dataset = datasetSamples.find(datasetSample => datasetSample.id === 'splines_pred_carbon');

        // Set dataset
        dataset.data = splines;

        datasets.push(dataset);
        this.setState({datasets, fetching: false});
      }
    )
  }

  render() {
    const {
      height,
      datasets,
      widget,
      specLink,
      specCaption
    } = this.state;

    // If widget is empty
    const emptyCaption = `Click on a core on the Strata Map, Core Carbon Stocks or Local/Global Model Performance charts to show a splined core profile.`;
    const iconName = 'chart-mixed';

    const className = "bg-white rounded border border-secondary p-2"
    const checksum = new Date().getTime();

    return(
      <div className="h-100">
        {datasets.length === 0 && (
          <div className="p-5 text-center bg-white rounded border border-secondary h-100">
            <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
            <div className="mt-3 text-danger">{emptyCaption}</div>
          </div>
        )}

        {datasets.length > 0 && (
          <div className={className}>
            <VerticalChart
              datasets={datasets}
              checksum={checksum}
              group="samples"
              widget={widget}
              selectionKey="sites"
              height={height}
              margin={{top: 20, right: 0, left: 20, bottom: 5}}
            />

            <div className="text-right mr-3">
              {specLink && (
                <small><a target="_blank" rel="noopener noreferrer" href={specLink}>{specCaption}</a></small>
              )}
              {!specLink && (
                <small className="text-secondary">Spec sheet not available for this widget</small>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    analytics: store.analytics,
    mapster: store.mapster
  }
}

export default connect(mapStoreToProps)(WidgetSpline);
