/* eslint-disable no-param-reassign, no-restricted-syntax */
import { useState } from 'react';
import { cloneDeep, isEmpty, isNil, omit } from 'lodash';
import { useSelector } from 'react-redux';

import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import CellIntervalDatePicker from 'jsx/components/core/form/components/CellIntervalDatePicker';
import FormInput from '../../../core/form/components/FormInput';
import Listview from '../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';
import { columnWidths } from '../constants/listviews';

import { allAreTrue, getValidationChecks } from '../lib/distributions';
import { validateFormFieldControls } from '../../../core/form/lib/validateForm';

const OverheadsLsvFeature = (props) => {
  const {
    darkTable,
    emptyCaption,
    hideHeader,
    iconName,
    onClick,
    responsive = true,
    rows,
    overheadListControls,
    onOverheadSave,
    onOverheadDelete,
    overhead_groups,
    overhead_types,
  } = props;

  const [changed, setChanged] = useState(false);
  const [snapshot, setSnapshot] = useState(null);
  const { selectedRanges } = useSelector((state) => state.enterprises);

  const getOverheadType = (id) => overhead_types.find((type) => type.id === id);

  const getOverheadTypesForGroup = (id) => {
    const types = overhead_types.filter((type) => type.parent_id === id);
    return types;
  };

  const handleChange = (event, row) => {
    const { name, value } = event.target;
    row[name] = value;

    // reset the type selected, based on the new group selected.
    if (name === 'group_id') {
      row.type_id = null;
    }

    setChanged(!changed);
  };

  const handleDistributionChange = (event, row, distributionIndex) => {
    const { value } = event.target;

    row.distributions[distributionIndex].distribution_value = 0;
    row.distributions[distributionIndex].distribution_pcnt = 0;

    // check we've got a valid value, and update the row.
    if (allAreTrue(getValidationChecks(parseFloat(value)))) {
      row.distributions[distributionIndex].distribution_value = value;
    }

    // calculate the total amount
    const totalValue = row.distributions
      .map((distribution) =>
        distribution.distribution_value ? parseFloat(distribution.distribution_value) : 0,
      )
      .reduce((current, next) => current + next);

    if (row.total_amount !== totalValue) row.total_amount = totalValue;

    // recalculate the distribution percentages from the new total amount
    row.distributions = row.distributions.map((distribution) => {
      const distribution_pcnt = distribution.distribution_value / totalValue;
      return { ...distribution, distribution_pcnt };
    });
    setChanged(!changed);
  };

  // eslint-disable-next-line
  const setEditMode = (row, value) => {
    if (value) {
      // Take snapeshot of row to revert to if user cancels
      setSnapshot(cloneDeep(row));
    } else if (snapshot) {
      // Reset the row to the snapshot
      Object.keys(snapshot).forEach((key) => {
        row[key] = snapshot[key];
      });
    }

    row.editMode = value;
    setChanged(!changed);
  };

  const onCancel = (row) => setEditMode(row, false);

  const onClear = (row) => {
    row.total_amount = 0;
    row.distributions.forEach((distribution) => {
      distribution.distribution_pcnt = 0;
      distribution.distribution_value = 0;
    });

    setChanged(!changed);
  };

  const onSave = async (row, controls) => {
    const { isValid } = validateFormFieldControls(row, controls);
    if (isValid) onOverheadSave(row);
    else row.isValid = false;

    if (row.id === null) {
      // Handle clearing new row editor
      onClear(row);
    } else {
      setEditMode(row, false);
    }
  };

  const renderEditRows = (headers, row, index) => {
    const tableTd = [];
    const controls = {};

    //  Set the overhead group from the overhead type for existing data
    if (row.type_id) {
      const overhead_type = getOverheadType(row.type_id);
      if (overhead_type?.parent_id) {
        row.group_id = overhead_type.parent_id;
      }
    }

    // Check if we need distributions for the group selected
    const targetGroup = overhead_groups.find(({ id }) => row.group_id === id);
    // Disable distributions and enable total amount field for groups which do not allow distributions
    const isDistributable = targetGroup?.rules?.allowDistributions ?? true;

    if (isEmpty(headers)) return null;

    // Using our template control, assign controls to each row.
    const controlKeys = Object.keys(headers);
    controlKeys.forEach((key, keyIndex) => {
      let control = headers[key];
      control = {
        ...control,
        id: `${keyIndex}-${control.fieldName}`,
        value: row[key],
      };

      if (row.id === null) {
        control = omit(control, ['description']);
      } else {
        control = omit(control, ['caption']);
      }

      // Populate the overhead types from the group selected
      if (key === 'type_id' && row.group_id) {
        const overheadTypeOptions = getOverheadTypesForGroup(row.group_id);
        control = {
          ...control,
          options: overheadTypeOptions,
        };
      }

      if (key === 'total_amount') control = { ...control, disabled: isDistributable };

      if (key === 'distributions') {
        // Only show the distribution fields if the group selected rules allow.
        control.value?.forEach((distribution, distributionsKeyIndex) => {
          const { division, distribution_value } = distribution;
          control = {
            ...control,
            id: `${index}-${division.name}`,
            value: distribution_value,
            disabled: !isDistributable,
          };

          if (row.id === null) {
            control.caption = division.name;
          } else {
            control.description = division.name;
          }

          tableTd.push(
            <td key={control.id} className={control.classes}>
              <FormInput
                handleChange={(event) =>
                  handleDistributionChange(event, row, distributionsKeyIndex)
                }
                control={control}
              />
            </td>,
          );
        });
      } else if (key === 'transaction_interval_id') {
        if (controls?.transaction_date && row.id !== null) {
          controls.transaction_date.description = 'Transaction Date';
        }

        tableTd.push(
          <CellIntervalDatePicker
            key={`${index}-${key}`}
            index={index}
            className={control.classes}
            handleChange={handleChange}
            controls={controls}
            intervals={control.options}
            intervalKey="transaction_interval_id"
            dateKey="transaction_date"
            row={row}
          />,
        );
      } else if (key === 'transaction_date') {
        // Intentionally skip transaction date control
      } else {
        tableTd.push(
          <td key={control.id} className={control.classes}>
            <FormInput handleChange={(event) => handleChange(event, row)} control={control} />
          </td>,
        );
      }
      // Store controls as object
      controls[control.name] = control;
    });

    if (row.id === null) {
      // Handle new row editor actions
      tableTd.push(
        <td key={`${index}-new-clear`} className="align-bottom">
          <Icon
            as="button"
            className="text-white font-weight-bold bg-primary"
            name="rotate-right"
            title="Clear"
            pointer
            onClick={() => {
              onClear(row);
            }}
          />
        </td>,
      );
      tableTd.push(
        <td key={`${index}-new-save`} className="align-bottom">
          <Icon
            as="button"
            name="check"
            title="Save"
            className="text-white font-weight-bold bg-success"
            pointer
            onClick={() => {
              onSave(row, controls);
            }}
          />
        </td>,
      );
    } else {
      // Handle inline editor actions
      tableTd.push(
        <td key={`${index}-inline-cancel`} className="">
          <Icon
            as="button"
            name="x-mark"
            title="Cancel"
            className="text-white bg-danger"
            pointer
            onClick={() => {
              onCancel(row);
            }}
          />
        </td>,
      );
      tableTd.push(
        <td key={`${index}-inline-save`} className="">
          <Icon
            as="button"
            name="check"
            title="Save"
            className="text-white bg-success"
            pointer
            onClick={() => {
              onSave(row, controls);
            }}
          />
        </td>,
      );
    }

    if (row.id === null && controls?.transaction_date && isNil(controls?.transaction_date?.value)) {
      // Set the transaction date to the selected date range if not set
      controls.transaction_date.value = selectedRanges.to_date;
      row.transaction_date = selectedRanges.to_date;
    }

    return tableTd;
  };

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const { classes, field, formattingRules, width } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (const key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }

      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      switch (field) {
        case 'date':
          return (
            <td key={index} className={classes} width={width}>
              {caption ? moment(caption).format('Do MMM YYYY') : ''}
            </td>
          );
        case 'transaction_date':
          return (
            <td key={index} className={classes} width={width}>
              {caption ? moment(caption).format('MMMM YYYY') : ''}
            </td>
          );
        case 'edit':
          return (
            <td key={index} className={classes}>
              {row?.type?.parent?.name !== 'Unpaid Labour' && (
                <Icon
                  name="pen-to-square"
                  title="Edit"
                  className="text-success"
                  pointer
                  onClick={() => {
                    setEditMode(row, true);
                  }}
                />
              )}
            </td>
          );
        case 'remove':
          return (
            <td key={index} className={classes}>
              {row?.type?.parent?.name !== 'Unpaid Labour' && (
                <Icon
                  name="trash"
                  title="Delete"
                  className="text-danger"
                  pointer
                  onClick={() => {
                    onOverheadDelete(row.id);
                  }}
                />
              )}
            </td>
          );
        default:
          return (
            <td key={index} className={classes} width={width}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const headers = [
    {
      caption: 'Month',
      field: 'transaction_date',
      type: 'date-month',
      classes: 'text-left align-middle',
      width: `${columnWidths.common}px`,
      sortColumn: 'created',
    },
    {
      caption: 'Description',
      field: 'type.name',
      classes: 'text-left align-middle',
    },
    {
      caption: 'Total',
      field: 'total_amount',
      classes: 'text-right align-middle',
      formattingRules: {
        includeCommas: true,
        includeDollarSign: true,
        includeDecimals: true,
      },
      width: `${columnWidths.common}px`,
    },
    { caption: '', field: 'edit', classes: 'text-right', width: `${columnWidths.icon}px` },
    { caption: '', field: 'remove', classes: 'text-right', width: `${columnWidths.icon}px` },
  ];

  // Get unique distribution headers
  const toEnd = -1;
  const headerPosition = 2;
  const deleteCount = 0;
  rows.forEach((row) => {
    // Sort distributions by division name alphabetically
    row.distributions?.sort((a, b) =>
      a?.division.name ? a.division.name.localeCompare(b.division.name) : toEnd,
    );

    row.distributions.forEach((distribution, idx) => {
      // Only add to headers if unique
      const caption = `${distribution.division.name}`;
      distribution.distribution_value = row.total_amount * distribution.distribution_pcnt;
      const headers_idx = headers.findIndex(
        (existing_header) => existing_header.caption === caption,
      );

      if (headers_idx === -1) {
        headers.splice(headerPosition + idx, deleteCount, {
          caption,
          field: `distributions.${idx}.distribution_value`,
          classes: 'text-right align-middle',
          formattingRules: {
            includeDecimals: true,
            includeDollarSign: true,
            includeCommas: true,
            blankIfZero: true,
          },
          width: `${columnWidths.common}px`,
        });
      }
    });
  });

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes} width={header.width}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      if (row.editMode) {
        const [templateControl] = cloneDeep(overheadListControls);

        return <tr key={index}>{renderEditRows(templateControl, row, index)}</tr>;
      } else {
        const properties = {
          key: index,
          onClick: () => onClick(row.id),
          style: { cursor: 'pointer' },
        };

        // Check and remove table row properties for converted Unpaid Labour rows
        if (row.removeOnClick) {
          delete properties.onClick;
          delete properties.style;
        }

        return <tr key={index}>{renderRows(headers, row)}</tr>;
      }
    });
  }

  return (
    <Listview
      darkTable={darkTable}
      hideHeader={hideHeader}
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      responsive={responsive}
      emptyCaption={emptyCaption}
    />
  );
};

export default OverheadsLsvFeature;
