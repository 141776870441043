// import { defaults } from '../../../../lib/genericReducer';

export default function reducer(state = {
  fetching: false,
  fetched: false,
  error: null,
  responseMessage: null,
  activeTab: '1',
}, action) {

  // state = {
  //   ...state,
  //   ...defaults(action)
  // }

  switch (action.type) {
    case "FETCH_HASHLINK_FULFILLED": {
      return {
        ...state,
        hashlink: action.payload
      }
    }

    case "FETCH_HASHLINK_REJECTED": {
      return {
        ...state,
        responseMessage: action.payload
      }
    }
    default: break;
  }
  return state;
}
