import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import Listview from '../../core/form/components/Listview';
import OrgModal from './OrgModal';
import PageTitleH5 from '../../core/form/components/PageTitleH5';
const OrgsLsv = (props) => {
  const { rows, checkAccess, onRefresh } = props;

  const [isModalOpen, setModal] = useState(false);
  const [id, setId] = useState(null);

  const openModal = (id) => {
    setId(id);
    setModal(true);
  };

  const renderCategories = ({ org_categories }) => {
    if (org_categories.length > 0) {
      return org_categories.map(({ category_tag }) => {
        const categoryName = category_tag.name.charAt(0).toUpperCase() + category_tag.name.slice(1);

        const { tag } = category_tag;

        let categoryBgColor = '';

        // Select the background color based on the category tags
        switch (tag) {
          case 'category_tag_lab':
            categoryBgColor = 'primary';
            break;
          case 'category_tag_transport':
            categoryBgColor = 'secondary';
            break;
          case 'category_tag_owners':
            categoryBgColor = 'success';
            break;
          default:
            categoryBgColor = 'primary';
        }

        return (
          <span
            key={categoryBgColor}
            className={`mr-1 pl-2 pr-2 pt-1 pb-1 text-light rounded bg-${categoryBgColor}`}
          >
            {categoryName}
          </span>
        );
      });
    }

    return '-';
  };

  const renderRows = (headers, row) => {
    let parent_name = '';
    if (row.parent_org) {
      parent_name = row.parent_org.name;
    }

    const tableTd = headers.map(({ classes, field }) => {
      switch (field) {
        case 'parent':
          return (
            <td key={`${row.id}-${field}`} className={classes}>
              {parent_name}
            </td>
          );
        case 'org_categories':
          return (
            <td key={`${row.id}-${field}`} className={classes}>
              {renderCategories(row)}
            </td>
          );

        case 'edit':
          return (
            <td key={`${row.id}-${field}`} className={classes}>
              <Icon name="pen-to-square" style={{ cursor: 'pointer' }} />
            </td>
          );
        default:
          return (
            <td key={`${row.id}-${field}`} className={classes}>
              {row[field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Parent', field: 'parent', classes: 'text-left' },
    { caption: 'Category Tags', field: 'org_categories', classes: 'text-left' },
  ];

  const tableHeadTh = headers.map(({ classes, caption }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;

  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => (
      <tr key={index} onClick={() => openModal(row.id)} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  const iconName = 'industry';
  const emptyCaption = 'No organisations found';
  const title = 'Organisations';

  return (
    <>
      <OrgModal setModal={setModal} onRefresh={onRefresh} id={id} isOpen={isModalOpen} />
      <div className="d-flex justify-content-between border-bottom border-corporate bg-light p-1 rounded">
        <PageTitleH5 iconName={iconName} title={title} />
        <div>
          <Button
            disabled={!checkAccess('orgUpdate')}
            color="success"
            size="sm"
            onClick={() => openModal(null)}
          >
            Add New Organisation
          </Button>
        </div>
      </div>

      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </>
  );
};

export default OrgsLsv;
