import { apiImportErrors, apiImportTransactions } from 'jsx/constants/api';
import { download, remove, upload } from '../../../../lib/genericAction';

export function importClientTransactions(transactions, onProgressChange) {
  return async (dispatch) =>
    upload(
      dispatch,
      'IMPORT_CLIENT_TRANSACTIONS',
      `${apiImportTransactions}/upload`,
      transactions,
      onProgressChange,
    );
}

export function downloadImportErrorFile(filename) {
  return async (dispatch) =>
    download(dispatch, 'DOWNLOAD_IMPORT_ERROR_FILE', `${apiImportErrors}/download`, null, {
      filename,
    });
}

export function removeImportErrorFile(filename) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_IMPORT_ERROR_FILE', `${apiImportErrors}/remove`, {
      filename,
    });
}
