import React, { useState } from 'react';
import Listview from '../../core/form/components/Listview';
import PageTitleH5 from '../../core/form/components/PageTitleH5';
import { Button } from 'reactstrap';
import CostcodeModal from './CostcodeModal';
import DirectUpload from '../../core/form/components/DirectUpload';

const CostcodesLsv = (props) => {
  const { rows, handleUpload } = props;

  const [isModalOpen, setModal] = useState(false);
  const [id, setId] = useState(null);

  const openModal = (id) => {
    setId(id);
    setModal(true);
  };

  const renderRows = (headers, costcode) => {
    const jobno_phase = `${costcode['jobno']}-${costcode['costcode'] ? costcode['costcode'] : ''}`;

    const enabledYesNo = costcode.enabled ? 'Yes' : 'No';
    const enabledClassName = costcode.enabled ? 'text-success' : 'text-danger';

    let org_name = 'None';
    if (costcode.client_org) {
      org_name = costcode.client_org.name;
    }

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'client_org':
          return (
            <td key={index} className={header.classes}>
              {org_name}
            </td>
          );
        case 'jobno_phase':
          return (
            <td key={index} className={header.classes}>
              {jobno_phase}
            </td>
          );
        case 'enabled':
          return (
            <td key={index} className={`${header.classes} ${enabledClassName}`}>
              {enabledYesNo}
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {costcode[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Client', field: 'client_org', classes: 'text-left' },
    { caption: 'Job No', field: 'jobno', classes: 'text-left' },
    { caption: 'Enabled', field: 'enabled', classes: 'text-right' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => (
      <tr key={index} onClick={() => openModal(row.id)}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  const iconName = 'tags';
  const emptyCaption = 'No Costcodes found';
  const title = 'Cost Codes';

  return (
    <React.Fragment>
      <CostcodeModal setModal={setModal} id={id} isOpen={isModalOpen} />
      <div className="d-flex justify-content-between border-bottom border-corporate bg-light p-1 rounded">
        <PageTitleH5 iconName={iconName} title={title} />
        <div className="d-flex p-1">
          <DirectUpload
            caption={'Upload Cost Codes'}
            size="sm"
            classNames="mb-1 mt-0 mr-2"
            handleUpload={handleUpload}
          />
          <Button className="mb-1 mt-0" color="success" size="sm" onClick={() => openModal(null)}>
            New Cost Code
          </Button>
        </div>
      </div>

      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default CostcodesLsv;
