import React from 'react';
import Listview from '../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';
import { columnWidths } from '../constants/listviews';

const FTESummary = (props) => {
  const { emptyCaption, iconName, rows } = props;

  // Render rows
  const renderRows = (headers, row) => {
    return headers.map((header, index) => {
      const { classes, field, formattingRules, width } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (let key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }

      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);
      return (
        <td key={index} className={classes} width={width}>
          {caption}
        </td>
      );
    });
  };

  // Set upper headers
  const upperHeaders = [
    { caption: null, cells: 2, classes: 'border-bottom-0', order: 0 },
    { caption: '1st FTE', cells: 2, classes: 'text-center bg-lightgray border' },
    { caption: 'Other FTE', cells: 2, classes: 'text-center bg-lightgray border' },
  ];

  const upperHeadTh = upperHeaders.map((upperHeader, index) => (
    <th key={index} colSpan={upperHeader.cells} className={upperHeader.classes}>
      {upperHeader.caption}
    </th>
  ));

  // Set headers
  const { common, icon } = columnWidths;
  let headers = [
    {
      caption: 'Open Date',
      classes: 'text-left',
      field: 'open_date',
    },
    {
      caption: 'Close Date',
      classes: 'text-left',
      field: 'close_date',
    },
    {
      caption: 'Amount',
      classes: 'text-right border-left',
      field: 'first_fte_rate.amount',
      formattingRules: { includeCommas: true, includeDecimals: true, includeDollarSign: true },
      width: `${common * 1.5}px`,
    },
    {
      caption: 'Weekly Average',
      classes: 'text-right border-right',
      field: 'first_fte_rate.weekly_average',
      formattingRules: { includeCommas: true, includeDecimals: true, includeDollarSign: true },
      width: `${common * 1.5}px`,
    },
    {
      caption: 'Amount',
      classes: 'text-right',
      field: 'other_fte_rate.amount',
      formattingRules: { includeCommas: true, includeDecimals: true, includeDollarSign: true },
      width: `${common * 1.5}px`,
    },
    {
      caption: 'Weekly Average',
      classes: 'text-right border-right',
      field: 'other_fte_rate.weekly_average',
      formattingRules: { includeCommas: true, includeDecimals: true, includeDollarSign: true },
      width: `${common * 1.5 + icon}px`,
    },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  if (rows.length > 0) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  return (
    <Listview
      rows={rows || []}
      upperHeadTh={upperHeadTh}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default FTESummary;
