import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import PageTitle from '../../../core/form/components/PageTitle';

import Toolbar from '../components/EnterpriseAllocationsToolbar';
import EnterpriseAllocationModal from './EnterpriseAllocationModal';
import GenericLsv from '../../../core/form/components/GenericLsv';
import AnimalClassModal from './AnimalClassModal';
import { controls as controlsAnimalClass } from '../forms/animalClasses';
import { controls as controlsEnterprise } from '../forms/enterprises';

import { fetchEnterpriseRanges, fetchEnterprises } from '../actions/enterprises';
import { fetchAnimalClasses } from '../actions/animal_classes';

class BusinessConfig extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      modalType: null,
      isModalOpen: false,
      modalData: null,
      isEnterpriseModalOpen: false,
      enterpriseId: null,
      isAnimalClassModalOpen: false,
      animalClassId: null,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }

  componentDidUpdate() {
    const { isActive, forceRefresh, setRefresh } = this.props;

    if (isActive && forceRefresh && setRefresh) {
      this.onRefresh();
      if (setRefresh) setRefresh(false);
    }
  }

  toggleTab(tab) {
    let { modalType } = this.state;

    this.props.dispatch(fetchEnterpriseRanges());

    switch (parseInt(tab)) {
      case 1: {
        this.props.dispatch(fetchEnterprises());
        modalType = 'enterprise';
        break;
      }
      case 2: {
        this.props.dispatch(fetchAnimalClasses());
        modalType = 'animal_class';
        break;
      }
      default: {
        break;
      }
    }

    if (this.props.enterprises.businessConfigActiveTab !== tab) {
      this.props.dispatch({ type: 'SET_BUSINESS_CONFIG_ACTIVE_TAB', payload: tab });
    }

    this.setState({
      modalType,
    });
  }

  onRefresh() {
    const tab_id = this.props.enterprises.businessConfigActiveTab;
    if (tab_id) this.toggleTab(tab_id);
  }

  async setModal(isModalOpen, modalType = null, modalId = null) {
    // Refresh dependant lookups based on type
    switch (modalType) {
      case 'enterprise': {
        this.setState({
          isEnterpriseModalOpen: isModalOpen,
          enterpriseId: modalId,
        });
        break;
      }
      case 'animal_class':
      default: {
        this.setState({
          isAnimalClassModalOpen: isModalOpen,
          animalClassId: modalId,
        });
        break;
      }
    }
  }

  setModalOptions = (type) => {
    let options;
    switch (type) {
      case 'enterprise': {
        options = {
          title: 'Enterprises',
          iconName: 'draw-polygon',
          emptyCaption: 'No enterprises found',
        };
        break;
      }
      case 'animal_class':
      default: {
        options = {
          title: 'Animal Classes',
          iconName: 'paw',
          emptyCaption: 'No classes found',
        };
        break;
      }
    }

    return options;
  };

  render() {
    const {
      isAnimalClassModalOpen,
      animalClassId,
      isEnterpriseModalOpen,
      enterpriseId,
      modalType,
    } = this.state;
    const { animal_classes } = this.props.animal_classes;
    const { enterprises, businessConfigActiveTab } = this.props.enterprises;

    const modalOptions = this.setModalOptions(modalType);

    const title = 'Business Configuration';
    const description =
      'If you can separate direct costs meaningfully, we recommend you create a new enterprise';

    return (
      <div className="p-0 h-100">
        <PageTitle title={title} description={description} />
        <Nav tabs className="mt-2">
          <FormTab
            caption="Enterprise Allocations"
            tabId="1"
            activeTab={businessConfigActiveTab}
            toggle={this.toggleTab}
            tabTag="allocations"
          />
          <FormTab
            caption="Animal Classes"
            tabId="2"
            activeTab={businessConfigActiveTab}
            toggle={this.toggleTab}
            tabTag="animal_classes"
          />
        </Nav>

        <TabContent activeTab={businessConfigActiveTab}>
          <TabPane tabId="1" className="mb-2 p-1">
            <Toolbar
              setModal={(isOpen) => this.setModal(isOpen, 'enterprise')}
              onRefresh={this.onRefresh}
            />
            <EnterpriseAllocationModal
              onRefresh={this.onRefresh}
              setModal={(isOpen) => this.setModal(isOpen, 'enterprise')}
              id={enterpriseId}
              isOpen={isEnterpriseModalOpen}
              isNew={enterpriseId === null}
            />
            <GenericLsv
              controls={controlsEnterprise}
              iconName={modalOptions.iconName}
              emptyCaption={modalOptions.emptyCaption}
              onClick={(id) => {
                this.setModal(true, 'enterprise', id);
              }}
              rows={enterprises.rows}
            />
          </TabPane>
          <TabPane tabId="2" className="mb-2 p-1">
            <Toolbar
              setModal={(isOpen) => this.setModal(isOpen, 'animal_class')}
              onRefresh={this.onRefresh}
            />
            <AnimalClassModal
              onRefresh={this.onRefresh}
              setModal={(isOpen) => this.setModal(isOpen, 'animal_class')}
              id={animalClassId}
              isOpen={isAnimalClassModalOpen}
              isNew={animalClassId === null}
            />
            <GenericLsv
              controls={controlsAnimalClass}
              iconName={modalOptions.iconName}
              emptyCaption={modalOptions.emptyCaption}
              onClick={(id) => {
                this.setModal(true, 'animal_class', id);
              }}
              rows={animal_classes}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ realm, animal_classes, enterprises }) => ({
  realm,
  animal_classes,
  enterprises,
});

export default connect(mapStoreToProps)(withContainerError(BusinessConfig));
