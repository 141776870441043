import React from 'react';
import Listview from "../../../../core/form/components/Listview";
import ResponseMessage from "../../../../core/form/components/ResponseMessageTab";

const PropertyLotsLsv = (props) => {
  const {
    responseMessage,
    // handleLotUpload,
    rows,
    // checkAccess
  } = props;

  const renderRows = (headers, property) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'ha':
          return (
            <td key={index}>{(property[header.field] === null ? '-' : property[header.field])}</td>
          )
        default: return (
          <td key={index}>{property[header.field]}</td>
        )
      }
    });
    return tableTd;
  }

  const headers = [
    { caption: 'Lot Plan', field: 'lotplan' },
    { caption: 'Hectares', field: 'ha' },
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  let total_area = 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} style={{ cursor: "pointer" }}>
        {renderRows(headers, row)}
      </tr>
    );
    total_area = rows.map(row => row.ha).reduce((a, b) => a + b, 0);
    total_area = Math.round(total_area * 100) / 100;
  }

  const iconName = 'draw-polygon';
  const emptyCaption = 'No Lots found but good news! You can upload multiple lots for a properaty with above Upload Property Lots Geometry';

  // const inputFileRef = useRef(null);

  // const handleBtnClick = () => {
  //   inputFileRef.current.click();
  // }

  // const handleUpload = (event) => {
  //   handleLotUpload(event);
  //   inputFileRef.current.value = null;
  // }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-start p-1 bg-light rounded mb-2">
        <ResponseMessage responseMessage={responseMessage} />
        {haveRows && (
          <div className='ml-2'>{rows.length} lot{rows.length > 1 ? 's' : ''} with a total of {total_area}ha</div>
        )}

        {/* <input style={{display:'none'}} onChange={handleUpload} type="file" ref={inputFileRef} />
        <Button disabled={!checkAccess('projectUpdate')} onClick={handleBtnClick} size="sm" color="success">Upload Property Lots Geometry</Button> */}
      </div>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </React.Fragment>
  );
}

export default PropertyLotsLsv;
