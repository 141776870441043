import React, { useState } from 'react';
import Listview from '../../../core/form/components/Listview';
import FormInput from '../../../core/form/components/FormInput';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';

const GenericDistributionsLsv = (props) => {
  const { rows, className } = props;

  const [changed, setChanged] = useState(false);

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const { classes, field, formattingRules } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (let key of field.split('.').values()) {
          parent = key && parent ? parent[key] : null;
          caption = parent;
        }
      }

      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      switch (field) {
        case 'type':
          return (
            <td key={index} className={classes}>
              {row[field] ? row[field] : 'string'}
            </td>
          );
        case 'distribution_pcnt':
          return (
            <td key={index} className={classes}>
              {
                <FormInput
                  handleChange={(event) => handleChange(event, row)}
                  control={{
                    id: `${index}-${field}`,
                    name: field,
                    value: caption,
                  }}
                />
              }
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const handleChange = (event, row) => {
    let name = event.target.name;
    let value = event.target.value;
    row[name] = value / 100;
    setChanged(!changed);
  };

  const setEditMode = (row, value) => {
    row.editMode = value;
    setChanged(!changed);
  };

  const headers = [
    { caption: 'Division', field: 'division.name', classes: 'text-left' },
    {
      caption: 'Distribution Percent',
      field: 'distribution_pcnt',
      classes: 'text-left',
      formattingRules: { asPercentage: true },
    },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  const tableBodyTr = rows.map((row, index) => {
    return (
      <tr
        key={index}
        onClick={() => {
          setEditMode(row, true);
        }}
        style={{ cursor: 'pointer' }}
      >
        {renderRows(headers, row)}
      </tr>
    );
  });

  return (
    <Listview
      className={className}
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
    />
  );
};

export default GenericDistributionsLsv;
