import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import FormInput from '../../../../core/form/components/FormInput';

const PropertyRoundProfile = ({
  handleChange,
  controls,
  onSave,
  onCancel,
  onRemove,
  checkAccess,
}) => (
  <>
    <Row>
      <Col>
        <FormInput handleChange={(event) => handleChange(event, 'name')} control={controls.name} />
      </Col>
      <Col>
        <FormInput
          handleChange={(event) => handleChange(event, 'previous_round_id')}
          control={controls.previous_round_id}
        />
      </Col>
      <Col>
        <FormInput
          handleChange={(event) => handleChange(event, 'override_mga_srid')}
          control={controls.override_mga_srid}
        />
      </Col>
      <Col>
        <FormInput
          handleChange={(event) => handleChange(event, 'subsample_type')}
          control={controls.subsample_type}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormInput
          handleChange={(event) => handleChange(event, 'selections_seed')}
          control={controls.selections_seed}
        />
      </Col>
      <Col>
        <FormInput
          handleChange={(event) => handleChange(event, 'selections_count')}
          control={controls.selections_count}
        />
      </Col>
      <Col>
        <FormInput
          handleChange={(event) => handleChange(event, 'backup_selections_count')}
          control={controls.backup_selections_count}
        />
      </Col>
      <Col>
        <FormInput
          handleChange={(event) => handleChange(event, 'sites_seed')}
          control={controls.sites_seed}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormInput
          handleChange={(event) => handleChange(event, 'data_preparation_id')}
          control={controls.data_preparation_id}
        />
      </Col>
      <Col>
        <FormInput
          handleChange={(event) => handleChange(event, 'soc_nir_round_type')}
          control={controls.soc_nir_round_type}
        />
      </Col>
      <Col>
        <FormInput
          handleChange={(event) => handleChange(event, 'subsample_script_id')}
          control={controls.subsample_script_id}
        />
      </Col>
      <Col>
        <FormInput
          handleChange={(event) => handleChange(event, 'augments_count')}
          control={controls.augments_count}
        />
      </Col>
    </Row>

    {onSave && (
      <div className="p-2 d-flex justify-content-center mt-2">
        <Button
          disabled={!checkAccess('roundUpdate')}
          color="success"
          className="m-1"
          onClick={onSave}
        >
          Save
        </Button>
        <Button color="light" className="m-1" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!checkAccess('roundUpdate')}
          color="danger"
          className="m-1"
          onClick={onRemove}
        >
          Delete
        </Button>
      </div>
    )}
  </>
);

export default PropertyRoundProfile;
