import React from 'react';
import { Input } from 'reactstrap';
import Listview from '../../form/components/Listview';
import PipelineProgress from './PipelineProgress';

const PipelinesByRoundLsv = ({
  rows,
  pipelines,
  pagination,
  onLoadMore,
  onEdit,
  handleSelect,
  rowSelection,
}) => {
  const iconName = 'layer-group';
  const emptyCaption = 'No rounds found';

  const renderRows = (headers, round) => {
    const rowColour = 'text-success mr-3';

    const isChecked = rowSelection ? rowSelection.findIndex((id) => id === round.id) > -1 : false;

    // Build Progress bar
    const pipeline_progress = round.pipelines.map((item, index) => (
      <PipelineProgress
        key={index}
        caption={item.short_name}
        status={item.status_key}
        last_message={item.last_message}
      />
    ));
    // Set colour for site_count
    let countClass = 'text-success';
    if (round.site_count === 0) {
      countClass = 'text-danger';
    }

    // Set round and site statuses
    const { site_status, round_status } = round;

    // Disable select if any status is invalid
    let disableSelect = false;
    const statuses = {
      ...round_status,
      ...site_status,
    };
    if (statuses) {
      for (let key in statuses) {
        if (!statuses[key].is_valid) disableSelect = true;
      }
    }

    let subsample_type;
    switch (round.subsample_type) {
      case 'property_round': {
        subsample_type = 'Round';
        break;
      }
      case 'area_round':
      default: {
        subsample_type = 'CEA';
        break;
      }
    }

    const tableTd = headers.map((header, index) => {
      const classes = `${rowColour} ${header.classes}`;
      const siteClasses = `${countClass} ${header.classes}`;
      switch (header.field) {
        case 'checkbox': {
          if (round.allowSelect) {
            return (
              <td
                key={index}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Input
                  type="checkbox"
                  className="m-0 mt-1"
                  onChange={handleSelect}
                  value={round.id}
                  checked={isChecked}
                  disabled={disableSelect}
                />
              </td>
            );
          }
          return <td key={`${round.id}-${header.field}`} />;
        }
        case 'project_name':
          return (
            <td key={`${round.id}-${header.field}`} className={classes}>
              {round.property.project.name}
            </td>
          );
        case 'property_name':
          return (
            <td key={`${round.id}-${header.field}`} className={classes}>
              {round.property.name}
            </td>
          );
        case 'project_period':
          return (
            <td key={`${round.id}-${header.field}`} className={classes}>
              {round.period.name}
            </td>
          );
        case 'site_count':
          return (
            <td key={`${round.id}-${header.field}`} className={siteClasses}>
              {round[header.field]}
            </td>
          );
        case 'subsample_type':
          return (
            <td key={`${round.id}-${header.field}`} className={classes}>
              {subsample_type}
            </td>
          );
        case 'site_status_required':
          return (
            <td
              key={`${round.id}-${header.field}`}
              className={`bg-dark text-white ${header.classes}`}
            >
              {site_status.required_sites.count}
            </td>
          );
        case 'site_status_ignored':
          return (
            <td
              key={`${round.id}-${header.field}`}
              className={`bg-dark text-white ${header.classes}`}
            >
              {site_status.ignored_sites.count}
            </td>
          );
        case 'site_status_active':
          return (
            <td
              key={`${round.id}-${header.field}`}
              className={`${getStatusClass(site_status, 'active_sites')} ${header.classes}`}
            >
              {site_status.active_sites.count}
            </td>
          );
        case 'site_status_field_data':
          return (
            <td
              key={`${round.id}-${header.field}`}
              className={`${getStatusClass(site_status, 'field_data')} ${header.classes}`}
            >
              {site_status.field_data.count}
            </td>
          );
        case 'site_status_scan_samples':
          return (
            <td
              key={`${round.id}-${header.field}`}
              className={`${getStatusClass(site_status, 'scan_samples')} ${header.classes}`}
            >
              {site_status.scan_samples.count}
            </td>
          );
        case 'site_status_scan_valid':
          return (
            <td
              key={`${round.id}-${header.field}`}
              className={`${getStatusClass(site_status, 'scan_valid')} ${header.classes}`}
            >
              {site_status.scan_valid.count}
            </td>
          );
        case 'property_round_seed_status':
          return (
            <td
              key={`${round.id}-${header.field}`}
              className={`${getStatusClass(round_status, 'seed_valid')} ${header.classes}`}
            >
              {round_status.seed_valid.count}
            </td>
          );
        case 'progress':
          return (
            <td
              key={`${round.id}-${header.field}`}
              className="d-flex flex-row p-1 justify-content-between"
            >
              {pipeline_progress}
            </td>
          );
        default:
          return (
            <td key={`${round.id}-${header.field}`} className={classes}>
              {round[header.field]}
            </td>
          );
      }
    });
    return tableTd;
  };

  const getStatusClass = (status, field) => {
    const success = 'bg-success text-white';
    const failure = 'bg-danger text-white';

    return status[field].is_valid ? success : failure;
  };

  const upperHeaders = [
    { caption: null, cells: 6, classes: 'border-bottom-0' },
    { caption: 'Status', cells: 7, classes: 'text-center bg-lightgray border border-lightgray' },
    { caption: 'Pipelines', cells: 2, classes: 'text-left border-bottom-0' },
  ];

  const headers = [
    { caption: '-', field: 'checkbox', classes: 'text-center w-20' },
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Project', field: 'project_name', classes: 'text-left' },
    { caption: 'Property', field: 'property_name', classes: 'text-left' },
    { caption: 'Hectares', field: 'area_ha', classes: 'text-left' },
    { caption: '# Sites', field: 'site_count', classes: 'text-center', totals: true },
    {
      caption: '# Required',
      field: 'site_status_required',
      classes: 'text-center border-left',
      totals: true,
    },
    { caption: '# Ignored', field: 'site_status_ignored', classes: 'text-center', totals: true },
    { caption: '# Active', field: 'site_status_active', classes: 'text-center', totals: true },
    { caption: '# Field', field: 'site_status_field_data', classes: 'text-center', totals: true },
    {
      caption: '# Scanned',
      field: 'site_status_scan_samples',
      classes: 'text-center',
      totals: true,
    },
    {
      caption: '# Valid',
      field: 'site_status_scan_valid',
      classes: 'text-center border-right',
      totals: true,
    },
    { caption: '# Seed', field: 'property_round_seed_status', classes: 'text-center border-right' },
    { caption: 'Type', field: 'subsample_type', classes: 'text-left' },
    { caption: 'Progress', field: 'progress', classes: 'text-left' },
  ];

  const upperHeadTh = upperHeaders.map((upperHeader, index) => (
    <th key={index} colSpan={upperHeader.cells} className={upperHeader.classes}>
      {upperHeader.caption}
    </th>
  ));

  const totals = [];
  const tableHeadTh = headers.map((header, index) => {
    if (header.totals) totals.push(index);
    return (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    );
  });

  let tableBodyTr = <tr />;
  let rowsCount;
  if (rows && rows.rows && pagination) {
    rowsCount = rows.count;
    rows = rows.rows;
  }
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    const filteredRows = rows.map((row) => ({
      ...row,
      pipelines: row.pipelines.map((roundPipeline) => {
        const updatedPipeline = pipelines.find((pipeline) => pipeline.id === roundPipeline.id);
        if (updatedPipeline) {
          return {
            ...roundPipeline,
            ...updatedPipeline,
          };
        }
        return roundPipeline;
      }),
    }));

    tableBodyTr = filteredRows.map((row) => (
      <tr key={row.id} style={{ cursor: 'pointer' }} onClick={() => onEdit(row)}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={rows}
      rowsCount={rowsCount}
      onLoadMore={onLoadMore}
      upperHeadTh={upperHeadTh}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      totals={totals}
      emptyCaption={emptyCaption}
    />
  );
};

export default PipelinesByRoundLsv;
