import React from 'react';
import {
  Button
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const PropertiesToolbar = (props) => {
  const {
    setModal
  } = props;

  return (
    <div className="d-flex justify-content-end">
      <Button disabled={true} onClick={() => {}} size="sm" color="success">Select/Update Properties</Button>
      <Button onClick={() => setModal(true, true)} size="sm" color="success" className="ml-1">
        <Icon name="plus" />
      </Button>
    </div>
  );
}

export default PropertiesToolbar
