import React from 'react';
import { Button, Alert } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const LiabilityToolbar = (props) => {
  const { onAddNew, onAddNewTransaction, alertText, filterClick } = props;
  const addNewButtonText = 'Add Liability';
  const addNewTransactionButtonText = 'Add Transaction';

  return (
    <Alert color="primary" className="d-flex justify-content-end p-2">
      <div>{alertText}</div>
      <Button onClick={onAddNew} size="sm" color="success" className={'mr-2'}>
        <Icon name="plus" className={'mr-2'} />
        {addNewButtonText}
      </Button>
      <Button onClick={onAddNewTransaction} size="sm" color="success">
        <Icon name="plus" className={'mr-2'} />
        {addNewTransactionButtonText}
      </Button>
      {filterClick && (
        <Button
          id="toggler"
          size="sm"
          color="warning"
          className="text-white ml-2"
          onClick={filterClick}
        >
          Filter
        </Button>
      )}
    </Alert>
  );
};

export default LiabilityToolbar;
