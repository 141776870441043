import { get } from '../../../../lib/genericAction';

import {
  apiJira,
} from '../../../../constants/api.js';

export function fetchJiraIssue(key) {
  return async dispatch => await get(
    dispatch,
    'FETCH_JIRA_ISSUE',
    `${apiJira}/issue/${key}`,
    null,
    null,
    true
  )
}

export function fetchJiraComments(key) {
  return async dispatch => await get(
    dispatch,
    'FETCH_JIRA_COMMENTS',
    `${apiJira}/allcomments/${key}`
  )
}
