import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    animal_classes: [],
    values: [],
    byMonth: {
      valueType: 'au_rating',
      months: [],
      rows: [],
    },
  },
  action,
) {
  // Set expected reducer types
  const types = ['ANIMAL_CLASS'];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_ANIMAL_CLASSES_PENDING': {
      return {
        ...state,
        animal_classes: [],
      };
    }
    case 'FETCH_ANIMAL_CLASSES_FULFILLED': {
      return {
        ...state,
        animal_classes: action.payload.rows,
      };
    }
    case 'FETCH_ANIMAL_CLASS_VALUES_FULFILLED': {
      return {
        ...state,
        values: action.payload.rows,
      };
    }
    case 'FETCH_ANIMAL_CLASS_VALUES_BY_MONTH_FULFILLED': {
      return {
        ...state,
        byMonth: {
          ...state.byMonth,
          ...action.payload,
        },
      };
    }
    case 'SET_ANIMAL_CLASS_VALUES_BY_MONTH_VALUE_TYPE': {
      return {
        ...state,
        byMonth: {
          ...state.byMonth,
          valueType: action.payload,
        },
      };
    }

    default:
      break;
  }

  return state;
}
