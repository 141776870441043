import { controlDefaults } from 'jsx/components/core/form/lib/validateForm';

export const controls = {
  period_id: {
    ...controlDefaults,
    placeholder: 'Analysis Period',
    name: 'period_id',
    fieldName: 'period_id',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    options: [],
    showInEditor: true,
    showInListview: true,
  },
};
