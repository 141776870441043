import React from 'react';
import { connect } from "react-redux";
import FormBase from "../../../core/form/components/FormBase";
import CoreArchivesLsv from '../components/CoreArchivesLsv';
import CoreArchivesToolbar from '../components/CoreArchivesToolbar';
import CoreArchivesFilter from '../components/CoreArchivesFilter';
import ResponseMessage from "../../../core/form/components/ResponseMessageTab";

import {
  fetchArchivedScans,
} from '../actions';

import {
  downloadArchivedScans,
} from '../actions/downloads';

class CoreArchives extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      scanSelection: [],
      responseMessage: null
    });

    this.handleSelect = this.handleSelect.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.clearSelected = this.clearSelected.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchArchivedScans());
  }

  refresh() {
    this.props.dispatch(fetchArchivedScans());
  }

  clearSelected() {
    this.setState({scanSelection: []})
  }

  handleSelect(event) {
    let {
      scanSelection
    } = this.state;

    const state = event.target.checked;
    const id = event.target.value;

    if (state) {
      scanSelection.push(id);
    } else {
      const idx = scanSelection.findIndex(scanId => scanId === id);
      if (idx > -1) {
        scanSelection.splice(idx, 1);
      }
    }

    this.setState({
      scanSelection
    })
  }

  async handleDownload(onProgressChange) {
    const {
      scanSelection
    } = this.state;

    // Check if scans are selected
    if (scanSelection.length === 0) {
      const responseMessage = 'Nothing selected for download!';
      this.setState({responseMessage});

      // Set timeout for message display
      window.setTimeout(() => {
        this.setState({responseMessage: null});
      }, 3000)

      return false;
    }

    // Download selected scans
    return await this.props.dispatch(downloadArchivedScans(onProgressChange, scanSelection));
  }

  render() {
    const {
      archivedScans
    } = this.props.sites;

    const {
      scanSelection,
      responseMessage
    } = this.state;

    return (
      <React.Fragment>
        <CoreArchivesToolbar handleDownload={this.handleDownload} refresh={this.refresh} />
        <CoreArchivesFilter clearSelected={this.clearSelected} />
        <ResponseMessage responseMessage={responseMessage} />
        <CoreArchivesLsv rows={archivedScans} handleSelect={this.handleSelect} scanSelection={scanSelection || []} />
      </React.Fragment>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    sites: store.sites
  }
}

export default connect(mapStoreToProps)(CoreArchives);
