import React, { useState } from 'react';
import Listview from '../../../core/form/components/Listview';
import FormInput from '../../../core/form/components/FormInput';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';

const LabourDistributionsLsv = (props) => {
  const { onDistributionChange, rows } = props;

  const [changed, setChanged] = useState(false);

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      let { classes, field, formattingRules } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (let key of field.split('.').values()) {
          parent = key && parent ? parent[key] : null;
          caption = parent;
        }
      }

      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      switch (field) {
        case 'weeks_count':
          return (
            <td key={index} className={classes}>
              {
                <FormInput
                  handleChange={(event) => handleChange(event, row)}
                  control={{
                    id: `${index}-${field}`,
                    name: field,
                    value: caption,
                  }}
                />
              }
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const handleChange = (event, row) => {
    let name = event.target.name;
    let value = event.target.value;
    row[name] = value;

    onDistributionChange();
    setChanged(!changed);
  };

  const setEditMode = (row, value) => {
    row.editMode = value;
    setChanged(!changed);
  };

  const headers = [
    { caption: 'Division', field: 'division.name', classes: 'text-left' },
    {
      caption: 'Number of Weeks',
      field: 'weeks_count',
      classes: 'text-left',
      totals: true,
    },
  ];

  let totals = [];
  const tableHeadTh = headers.map((header, index) => {
    if (header.totals) totals.push(index);
    return (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    );
  });

  const tableBodyTr = rows.map((row, index) => {
    return (
      <tr
        key={index}
        onClick={() => {
          setEditMode(row, true);
        }}
        style={{ cursor: 'pointer' }}
      >
        {renderRows(headers, row)}
      </tr>
    );
  });

  return (
    <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} totals={totals} />
  );
};

export default LabourDistributionsLsv;
