import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {FormGroup, Input, Label} from 'reactstrap';
import moment from 'moment';

import TimekeeperWeekBar from '../../office/components/TimekeeperWeekBar';
import DaysheetSummariesLsv, { DaysheetSummaryRecord } from './DaysheetSummariesLsv';
import { fetchDaysheetSummaries, downloadCsv } from '../actions/daysheet_summaries';

interface DaysheetSummariesProps {
  forceRefresh: boolean;
  setRefresh: (newValue: boolean) => void;
}
const DaysheetSummaries = ({ forceRefresh, setRefresh}: DaysheetSummariesProps) => {
  const { currentWeek } = useSelector((state: any) => state.office);
  const dispatch = useDispatch();
  const setWeek = useCallback(
    (increment: number) => dispatch({ type: 'UPDATE_CURRENT_WEEK', payload: increment }),
    [dispatch],
  );

  const [summaries, setSummaries] = useState<Array<DaysheetSummaryRecord>>([]);
  const [showMissingOnly, setShowMissingOnly] = useState(Boolean);
  const [exportEnabled, setExportEnabled] = useState(Boolean);
  const fetchData = useCallback(async () => {
    let { rows } = await fetchDaysheetSummaries(currentWeek)(dispatch);
    if (showMissingOnly) {
      rows = rows.filter((row: any) => ((row.unapproved > 0) || (!row.approverStatus)));
    }

    setExportEnabled(rows.filter((row: any) => ((row.unapproved > 0) || (!row.approverStatus))).length === 0);
    setSummaries(rows);
  }, [dispatch, currentWeek, showMissingOnly]);

  useEffect(() => {
    fetchData();
  }, [currentWeek, dispatch, fetchData]);

  useEffect(() => {
    if (forceRefresh) {
      fetchData();
      setRefresh(false);
    }
  }, [fetchData, forceRefresh, setRefresh]);

  const handleDownloadCsv = useCallback(async (onProgressChange: any) => {
    return downloadCsv(
      {
        timezone: moment().format('Z'),
        ...currentWeek,
      },
      onProgressChange,
    )(dispatch);
  }, [dispatch, currentWeek]);

  const filter = useCallback(async(value: boolean) => {
    setShowMissingOnly(value);
  }, [dispatch]);

  return (
    <>
      <TimekeeperWeekBar week={currentWeek} setWeek={setWeek} reload={fetchData} teamSummaryExports={true} handleDownloadCsv={handleDownloadCsv} teamSummaryExportEnabled={exportEnabled}>
      <FormGroup check inline className="text-black float-left">
            <Input
              id="filterMissingCheckbox"
              name="checkbox"
              type="checkbox"
              checked={showMissingOnly}
              onChange={(event) => filter(event.target.checked)}
            />
            <Label check for="filterMissingCheckbox">
              Show Missing Only
            </Label>
          </FormGroup>
        </TimekeeperWeekBar>
      <DaysheetSummariesLsv rows={summaries} />
    </>
  );
};

export default DaysheetSummaries;
