import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  source_enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'source_enterprise.name',
    name: 'source_enterprise_id',
    group: 'source',
    type: 'select',
    caption: 'Source Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  source_property_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'source_property.name',
    name: 'source_property_id',
    group: 'source',
    type: 'select',
    caption: 'Source Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  product_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'product.name',
    name: 'product_id',
    group: 'source',
    caption: 'Product',
    showInEditor: true,
    showInListview: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  target_enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'target_enterprise.name',
    name: 'target_enterprise_id',
    group: 'target',
    type: 'select',
    caption: 'Target Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  target_property_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'target_property.name',
    name: 'target_property_id',
    group: 'target',
    type: 'select',
    caption: 'Target Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  target_animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'animal_class.name',
    name: 'target_animal_class_id',
    group: 'target',
    type: 'select',
    caption: 'Animal Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  from_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'from_date',
    type: 'date',
    caption: 'From Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    sortColumn: 'from_date',
  },
  total_weeks_count: {
    ...controlDefaults,
    placeholder: '',
    name: 'total_weeks_count',
    type: 'number',
    caption: 'Total Weeks',
    formattingRules: {
      includeCommas: true,
    },
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  quantity: {
    ...controlDefaults,
    placeholder: '',
    name: 'quantity',
    type: 'number',
    caption: '# Head',
    formattingRules: {
      includeCommas: true,
    },
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  au_rating: {
    ...controlDefaults,
    placeholder: '',
    name: 'au_rating',
    type: 'number',
    caption: 'AU Rating',
    formattingRules: {
      includeCommas: true,
    },
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: true,
  },
  value_per_head_week: {
    ...controlDefaults,
    placeholder: '',
    name: 'value_per_head_week',
    type: 'number',
    caption: '$/head/week Agistment Rate',
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
      includeDollarSign: true,
    },
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
};
