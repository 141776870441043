import { useState } from 'react';
import { noop } from 'lodash';
import { SketchPicker } from 'react-color';
import { UncontrolledPopover, Button } from 'reactstrap';

const FormInputColourPicker = ({
  value,
  fieldName,
  onChange,
  allowEdit = true
}) => {

  const [colour, setColour] = useState(value);

  const onLocalChange = (pickerValue) => {
    setColour(pickerValue.hex);
    onChange(fieldName, pickerValue.hex);
  };

  return (
    <div className="">
      <Button
        id={fieldName}
        type="button"
        size="sm"
        style={{width: 100, height: 18, backgroundColor: colour}}
      >
      </Button>
      {allowEdit && (
        <UncontrolledPopover
          placement="bottom"
          target={fieldName}
          trigger="click"
          >
          <SketchPicker
            name="circlePicker"
            color={colour}
            onChange={onLocalChange}
            width="w-100"
          />
        </UncontrolledPopover>
      )}
    </div>
  );
};

export default FormInputColourPicker;
