import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducers from './jsx/reducers';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Keep reducers and middleware in separate variables so that they can be imported in tests
 * to maintain feature parity
 */
export const rootReducer = reducers;
export const middleware = [thunk];
const enableLogging =
  process.env.REACT_APP_ENABLE_REDUX_LOGGING === 'true' ||
  localStorage.getItem('enableReduxLogging') === 'true';
if (enableLogging) {
  middleware.push(logger);
}

export default createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));
