import React from 'react';
import { 
  Row,
  Col,
  Table
} from 'reactstrap';

import FormInput from "../../../../core/form/components/FormInput";

const ProjectOverview = (props) => {
  const {
    controls,
    handleChange,
  } = props;

  return (
    <React.Fragment>
      <Row>
        <Col sm={10}>
          <Row>
            <Col sm={6}>
              <FormInput handleChange={handleChange} control={controls.strataVersion} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Area</th>
            <th>Sample Locations</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Strata 1</td>
            <td>98.2ha</td>
            <td>34 Locations</td>
          </tr>
          <tr>
            <td>Strata 2</td>
            <td>198.2ha</td>
            <td>34 Locations</td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default ProjectOverview;
