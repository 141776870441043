import { get, post, put, remove } from '../../../../lib/genericAction';

import {
  apiAnimalClasses,
  apiAnimalClassValues,
  apiAnimalClassReproduction,
  apiAnimalClassValuesByMonth,
} from '../../../../constants/api';

export function fetchAnimalClasses(params) {
  return async (dispatch) => get(dispatch, 'FETCH_ANIMAL_CLASSES', `${apiAnimalClasses}`, params);
}

export function fetchAnimalClass(id) {
  return async (dispatch) => get(dispatch, 'FETCH_ANIMAL_CLASS', `${apiAnimalClasses}/${id}`);
}

export function createAnimalClass(data) {
  return async (dispatch) => post(dispatch, 'CREATE_ANIMAL_CLASS', `${apiAnimalClasses}`, data);
}

export function updateAnimalClass(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_ANIMAL_CLASS', `${apiAnimalClasses}/${data.id}`, data);
}

export function removeAnimalClass(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_ANIMAL_CLASS', `${apiAnimalClasses}/${id}`);
}

export function fetchAnimalClassValues(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ANIMAL_CLASS_VALUES', `${apiAnimalClassValues}`, params);
}

export function fetchAnimalClassValuesByMonth(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ANIMAL_CLASS_VALUES_BY_MONTH', `${apiAnimalClassValuesByMonth}`, params);
}

export function fetchAnimalClassValue(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ANIMAL_CLASS_VALUE', `${apiAnimalClassValues}/${id}`);
}

export function createAnimalClassValue(data, inBackground = false) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_ANIMAL_CLASS_VALUE', `${apiAnimalClassValues}`, data, inBackground);
}

export function updateAnimalClassValue(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_ANIMAL_CLASS_VALUE', `${apiAnimalClassValues}/${data.id}`, data);
}

export function removeAnimalClassValue(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_ANIMAL_CLASS_VALUE', `${apiAnimalClassValues}/${id}`);
}

export function fetchAnimalClassUsages(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ANIMAL_CLASS_USAGES', `${apiAnimalClasses}/${id}/has_usages`);
}

export function createAnimalClassReproduction(data) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_ANIMAL_CLASS_REPRODUCTION', `${apiAnimalClassReproduction}`, data);
}

export function updateAnimalClassReproduction(data) {
  return async (dispatch) =>
    put(
      dispatch,
      'UPDATE_ANIMAL_CLASS_REPRODUCTION',
      `${apiAnimalClassReproduction}/${data.id}`,
      data,
    );
}
