import React from 'react';

const Cell = ({ label, value, labelClassName = '', valueClassName = '' }) => {
  return (
    <div className="d-inline-block mr-3">
      <div className={labelClassName}>{label}</div>
      <div
        className={`p-1 px-3 border border-grey rounded h-auto ${valueClassName}`}
        style={{ minWidth: '100px' }}
      >
        {value}
      </div>
    </div>
  );
};

export default Cell;
