import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Row,
  Col
} from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from "../../core/form/components/FormInput";
import FormBase from "../../core/form/components/FormBase";
import { initControls, saveControls } from '../../core/form/lib/validateForm';
import { controls } from '../forms/org_test_code';

import {
  createOrgTestCode,
  fetchOrgTestCodes,
} from '../actions';

class OrgTestCodeModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isValid: true,
      isOpen: false,
      title: 'Link Test Code',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls
    })

    this.bindOptions = this.bindOptions.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen, id, data } = this.state;
    let { isNew,  controls, title } = this.state;

    const { orgs } = this.props.manage;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      controls = initControls(controls);

      const org_id = this.props.orgId;
      if (org_id) {
        controls.org_id.value = org_id;
        const org = orgs.find(org => org.id === org_id);
        title = `Link Test Code (${org.name})`
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        isNew,
        setModal: this.props.setModal,
        data,
        controls,
        title
      })
    }
    this.bindOptions()
  }

  bindOptions() {
    const { controls } = this.state;
    const { documentDefs, codes } = this.props.manage;

    if (codes.length > 0) {
      const options = codes.map(code => ({
        ...code,
        name: code.code
      }))
      controls['test_code_id'].options = options;
      if (!controls['test_code_id'].value) {
        controls['test_code_id'].value = options[0].id;
        this.setState({ controls })
      }
    }

    if (documentDefs.length > 0) {
      controls['sample_type_id'].options = documentDefs;
      if (!controls['sample_type_id'].value) {
        controls['sample_type_id'].value = documentDefs[0].id;
        this.setState({ controls })
      }
    }

    if (!controls['is_default'].value) {
      controls['is_default'].value = controls['is_default'].options[0].id
    }
  }

  async onSave() {
    let { data }  = this.state;
    const { controls, isNew } = this.state;

    data = saveControls(controls, data)

    let success;
    if (isNew) {
      delete data.id
      success = await this.props.dispatch(createOrgTestCode(data));
    }

    if (success) {
      const {
        type,
        orgId
      } = this.props;
      const params = {};
      if (type) params.type = type;
      this.props.dispatch(fetchOrgTestCodes(orgId, params));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  render() {
    const {
      controls,
      isOpen,
      title
    } = this.state;

    const {
      responseMessage
    } = this.props.manage;

    const iconName = 'laptop-code';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && (
            <div className="text-center text-danger">{responseMessage}</div>
          )}
          <Form>
            <Row>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.sample_type_id} />
              </Col>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.test_code_id} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.is_default} />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Cancel</Button>
            <Button size="sm" color="success" onClick={this.onSave}>Save</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }

}

const mapStoreToProps = (store) => {
  return {
    manage: store.manage
  }
}

export default connect(mapStoreToProps)(OrgTestCodeModal);