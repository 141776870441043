import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../core/form/components/FormInput';
import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';
import FormBase from '../../core/form/components/FormBase';
import FormTab from '../../core/form/components/FormTab';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
} from '../../core/form/lib/validateForm';
import { controls } from '../forms/costcode';

import {
  createCostcode,
  fetchCostcodes,
  fetchCostcode,
  updateCostcode,
  removeCostcode,
  createPhasecodes,
  fetchPhasecodes,
  removePhasecode,
} from '../actions';

import AttributesLsv from './AttributesLsv';
import PhasecodesLsv from './PhasecodesLsv';
import { cloneDeep } from 'lodash';

class CostcodeModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      isValid: true,
      isOpen: false,
      title: 'Cost Code',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: cloneDeep(controls),
    };

    this.onSave = this.onSave.bind(this);
    this.addPhasecodes = this.addPhasecodes.bind(this);
    this.onRemovePhasecode = this.onRemovePhasecode.bind(this);
  }

  async componentDidUpdate() {
    let { isOpen, id, isNew, title, controls, data } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = `New Cost Code`;
      controls = initControls(cloneDeep(controls));

      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = `Edit Cost Code`;

        this.props.dispatch(fetchCostcode(id)).then((result) => {
          controls = updateControls(controls, result);
          this.setState({
            controls,
            data: result,
          });
        });
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls,
      });
    }
  }

  toggleTab(tab, tag) {
    switch (tag) {
      case 'custom_attributes':
        break;
      default:
        break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  async onSave() {
    let { data, controls, isNew } = this.state;

    data = saveControls(controls, data);
    data.attributes = controls.attributes.value;
    data.phasecodes = controls.phasecodes.value;
    delete data.update_phasecodes;

    let success;
    if (isNew) {
      delete data.id;
      success = await this.props.dispatch(createCostcode(data));
    } else {
      success = await this.props.dispatch(updateCostcode(data));
    }

    if (success) {
      this.props.dispatch(fetchCostcodes());
      this.onCancel();
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false, data: {} });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      `Removing ${data.name.toUpperCase()} cost code permanently. Continue?`
    );
    if (confirmed) {
      const success = await this.props.dispatch(removeCostcode(data.id));
      if (success) {
        this.props.dispatch(fetchCostcodes());
        this.onCancel();
      }
    }
  }

  async addPhasecodes() {
    let { data, controls } = this.state;

    // Create one or more phasecodes
    const phasecodes = await this.props.dispatch(
      createPhasecodes({
        costcode_id: data.id,
        phasecodes: controls.update_phasecodes.value,
      })
    );

    // update data
    data.phasecodes = phasecodes;
    controls = updateControls(controls, data);
    controls.update_phasecodes.value = '';
    controls.costcode.value = null;

    this.setState({ data, controls });
  }

  async onRemovePhasecode(id) {
    let { data } = this.state;

    const confirmed = window.confirm(`Removing phase code permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removePhasecode(id));
      if (success) {
        const phasecodes = await this.props.dispatch(fetchPhasecodes({ costcode_id: data.id }));
        data.phasecodes = phasecodes;
        this.setState({ data });
      }
    }
  }

  render() {
    let { controls, isOpen, title, isNew } = this.state;

    const { responseMessage } = this.props.manage;

    const { orgs } = this.props.manage;

    controls = updateControlOptions(controls, 'client_org_id', orgs);

    const iconName = 'tag';

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Nav tabs className="mt-2">
            <FormTab
              tabTag="profile"
              caption="Profile"
              tabId="1"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
            />
            <FormTab
              tabTag="custom_attributes"
              caption="Custom Attributes"
              tabId="2"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
              disabled={false}
            />
          </Nav>
          <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
            <TabPane tabId="1" className="mb-2 p-1">
              <Form>
                <Row>
                  <Col sm={3}>
                    <FormInput handleChange={this.handleChange} control={controls.jobno} />
                  </Col>
                  <Col>
                    <FormInput handleChange={this.handleChange} control={controls.name} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormInput handleChange={this.handleChange} control={controls.client_org_id} />
                  </Col>
                  <Col>
                    <FormInputSwitch handleChange={this.handleChange} control={controls.enabled} />
                  </Col>
                </Row>

                {isNew && (
                  <Row>
                    <Col>
                      <FormInput handleChange={this.handleChange} control={controls.phasecodes} />
                    </Col>
                  </Row>
                )}

                {!isNew && (
                  <>
                    <div className="bg-light border-bottom border-corporate mt-2 mb-2">
                      <small>Phase Codes</small>
                    </div>
                    <Row className="p-0 m-0">
                      <Col className="p-0 m-0">
                        <FormInput
                          handleChange={this.handleChange}
                          control={controls.update_phasecodes}
                        />
                      </Col>
                      <Col sm={1}>
                        <Button color="link" onClick={this.addPhasecodes}>
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <PhasecodesLsv
                      rows={controls.phasecodes?.value || []}
                      onRemove={this.onRemovePhasecode}
                    />
                  </>
                )}
              </Form>
            </TabPane>

            <TabPane tabId="2" className="mb-2 p-1">
              <AttributesLsv rows={controls.attributes?.value || []} ignoreFields={['owner']} />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" color="success mr-2" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    manage: store.manage,
  };
};

export default connect(mapStoreToProps)(CostcodeModal);
