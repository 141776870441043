import { defaults } from '../../../../lib/genericReducer';

import moment from 'moment';

const fromDateFormat = 'YYYY-MM-DD 00:00:00ZZ';
const toDateFormat = 'YYYY-MM-DD 23:59:59ZZ';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    activeTabTimekeeper: '1',
    activeTabResources: '1',
    defaultTimesheetOrgId: null,
    currentWeek: {
      from_date: moment().startOf('week').format(fromDateFormat),
      to_date: moment().endOf('week').format(toDateFormat),
    },
    timesheets: [],
    daysheets: [],
    summaries: [],
    leaves: [],
    selectedDate: moment(),
  },
  action,
) {
  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'UNSET_TIMESHEETS': {
      return {
        ...state,
        timesheets: [],
      };
    }
    case 'SET_DEFAULT_TIMESHEET_ORGID': {
      return {
        ...state,
        defaultTimesheetOrgId: action.payload,
      };
    }
    case 'SET_ACTIVETAB_TIMEKEEPER': {
      return {
        ...state,
        activeTabTimekeeper: action.payload,
      };
    }
    case 'SET_ACTIVETAB_RESOURCES': {
      return {
        ...state,
        activeTabResources: action.payload,
      };
    }

    case 'SET_SELECTED_DATE': {
      let selectedDate = action.payload;
      if (!selectedDate) {
        selectedDate = moment();
      }

      return {
        ...state,
        selectedDate: selectedDate,
      };
    }
    case 'SET_CURRENT_WEEK': {
      const week = action.payload;

      return {
        ...state,
        currentWeek: {
          from_date: week.from_date.format(fromDateFormat),
          to_date: week.to_date.format(toDateFormat),
        },
      };
    }
    case 'UPDATE_CURRENT_WEEK': {
      const increment = action.payload;
      const { currentWeek } = state;

      let updatedWeek = {};
      let selectedDate = moment();

      // If no increment given, reset to this week.
      if (increment) {
        updatedWeek = {
          from_date: moment(currentWeek.from_date).add(increment, 'w'),
          to_date: moment(currentWeek.to_date).add(increment, 'w'),
        };
        selectedDate = increment > 0 ? updatedWeek.from_date : updatedWeek.to_date;
      } else {
        updatedWeek = {
          from_date: moment().startOf('week'),
          to_date: moment().endOf('week'),
        };
      }
      return {
        ...state,
        currentWeek: {
          from_date: updatedWeek.from_date.format(fromDateFormat),
          to_date: updatedWeek.to_date.format(toDateFormat),
        },
        selectedDate,
      };
    }
    case 'SET_RESPONSE_MESSAGE': {
      return {
        ...state,
        responseMessage: action.payload,
      };
    }
    case 'FETCH_TIMESHEETS_FULFILLED': {
      return {
        ...state,
        timesheets: action.payload,
      };
    }

    case 'FETCH_DAYSHEETS_FULFILLED': {
      return {
        ...state,
        daysheets: action.payload,
      };
    }

    case 'FETCH_TIMESHEET_SUMMARIES_FULFILLED': {
      return {
        ...state,
        summaries: action.payload,
      };
    }
    case 'FETCH_LEAVES_FULFILLED': {
      return {
        ...state,
        leaves: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
