import { controlDefaults } from '../../../core/form/lib/validateForm';
import { controls as livestockAdjustmentControls } from './livestockAdjustments';

export const controls = {
  ...livestockAdjustmentControls,
  weight: {
    ...controlDefaults,
    placeholder: '',
    name: 'weight.value',
    unitType: 'kg',
    type: 'number',
    caption: 'Weight (kg/head)',
    validationRules: {},
    showInEditor: true,
    showInListview: false,
  },
  value: {
    ...controlDefaults,
    placeholder: '',
    name: 'value',
    type: 'number',
    caption: 'Value ($/kg)',
    validationRules: {},
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
      includeDollarSign: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  au_rating: {
    ...controlDefaults,
    placeholder: '',
    name: 'au_rating.value',
    unitType: 'lsu',
    type: 'number',
    caption: 'AU Rating',
    validationRules: {},
    formattingRules: {
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: false,
  },
};
