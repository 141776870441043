import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Row, Col } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { initControls, saveControls, updateControls } from '../../../core/form/lib/validateForm';

import { controls } from '../forms/leave';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';

import { removeLeave, fetchLeaves, fetchLeave, updateLeave, createLeave } from '../actions';

import TimeFormat from '../lib/timeFormat';

class TimekeeperLeaveModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Leave',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls,
      jiraKeyTimeout: null,
      jiraKey: null,
    };

    this.format = new TimeFormat();

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.loadLeaves = this.loadLeaves.bind(this);
    this.loadLeavecodes = this.loadLeavecodes.bind(this);
  }

  async componentDidUpdate() {
    let { id, isNew, title } = this.state;
    const { isOpen } = this.state;

    // Open modal and find record
    if (isOpen !== this.props.isOpen && isOpen === false) {
      this.setState({ isOpen: true });
      this.props.dispatch({ type: 'SET_RESPONSE_MESSAGE', payload: null });

      isNew = true;
      title = 'New Leave Request';
      id = null;

      // Load Combos
      await this.loadLeavecodes();

      // Initialize
      let controls = initControls(this.state.controls);

      // Default leave hours
      controls.leave_hours.value = 8;

      if (this.props.id) {
        isNew = false;
        title = 'Edit Leave Request';
        id = this.props.id;

        // Fetch result
        const data = await this.props.dispatch(fetchLeave(id));
        controls = await updateControls(controls, data);
      }

      this.setState({
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        controls,
      });
    }
  }

  async onSave() {
    const { controls, isNew } = this.state;
    let { data } = this.state;

    data = saveControls(controls, data);

    // Create/Update;
    let success = false;
    if (isNew) {
      delete data.id;
      success = await this.props.dispatch(createLeave(data));
    } else {
      success = await this.props.dispatch(updateLeave(data));
    }

    if (success) {
      // Fetch timesheets
      this.loadLeaves();

      // Set modal state
      this.state.setModal(false);
      this.setState({ isOpen: false });
    } else {
      this.setState({ data: [] });
    }
  }

  loadLeaves() {
    const { currentWeek } = this.props.office;

    const { currentUser } = this.props.profile;

    this.props.dispatch(
      fetchLeaves({
        ...currentWeek,
        user_id: currentUser.id,
      }),
    );
  }

  handleTimeBlur(event) {
    this.handleChange(event);
  }

  async loadLeavecodes() {
    const { leavecodes } = this.props.manage;

    const { controls } = this.state;

    // Load Cost code options
    if (leavecodes.length !== controls.leavecode_id.options.length) {
      const enabledLeavecodes = leavecodes.filter((leavecode) => leavecode.enabled);
      controls.leavecode_id.options = enabledLeavecodes.map((leavecode) => {
        return { id: leavecode.id, name: leavecode.name };
      });

      controls.leavecode_id.options.push({
        id: '',
        name: 'Select Leave Code',
      });

      this.setState({ controls });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { id } = this.state;

    const confirmed = window.confirm(`Removing leave permanently. Continue?`);
    if (confirmed) {
      await this.props.dispatch(removeLeave(id));
      this.state.setModal(false);
      this.setState({ isOpen: false });
      this.loadLeaves();
    }
  }

  render() {
    const { controls, isOpen, title, isNew } = this.state;

    const { responseMessage } = this.props.office;

    const iconName = 'house-person-leave';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.leavecode_id} />
            {isNew && (
              <Row>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.from_date} />
                </Col>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.to_date} />
                </Col>
                <Col>
                  <FormInput handleChange={this.handleChange} control={controls.leave_hours} />
                </Col>
              </Row>
            )}
            {!isNew && (
              <React.Fragment>
                <FormInput handleChange={this.handleChange} control={controls.leave_date} />
                <FormInput handleChange={this.handleChange} control={controls.leave_hours} />
              </React.Fragment>
            )}
            <FormInput handleChange={this.handleChange} control={controls.comments} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
            <Button disabled={isNew} size="sm" color="danger" onClick={this.onRemove}>
              Delete
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    office: store.office,
    manage: store.manage,
    profile: store.profile,
  };
};

export default connect(mapStoreToProps)(TimekeeperLeaveModal);
