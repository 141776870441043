import React from 'react';
import { connect } from 'react-redux';
import FormBase from '../../../core/form/components/FormBase';
import GenericLsv from '../../../core/form/components/GenericLsv';
import PropertyResultsToolbar from '../components/properties/PropertyResultsToolbar';

import {
  initControls,
  saveControls,
  updateControlOptions,
} from '../../../core/form/lib/validateForm';
import { controls as controlsPropertyReportingToolbar } from '../forms/propertyReportingToolbar';
import { controls as controlsPropertyResults } from '../forms/propertyResults';
import { fetchResultsByProperty } from '../../analytics/actions';
import { Collapse } from 'reactstrap';

class ProjectPropertyResults extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: controlsPropertyReportingToolbar,
      collapsedSections: {},
    };

    this.onRefresh = this.onRefresh.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
    this.loadSections = this.loadSections.bind(this);
  }

  componentDidMount() {
    let { controls } = this.state;

    this.setState({
      controls: initControls(controls),
    });
  }

  onRefresh() {
    const { controls } = this.state;
    const { id } = this.props;
    let params = saveControls(controls, {});

    this.props.dispatch(
      fetchResultsByProperty({
        ...params,
        property_id: id,
      })
    );
  }

  toggleSection(id) {
    let { collapsedSections } = this.state;
    collapsedSections[id] = !collapsedSections[id];
    this.setState({
      collapsedSections,
    });
  }

  loadSections() {
    const { collapsedSections } = this.state;
    const { massResults } = this.props.properties;

    let sections = [];
    massResults.forEach((massResult) => {
      if (!sections.find((section) => section.id === massResult.id)) {
        sections.push({
          id: massResult.id,
          area: massResult.area.name,
          name: massResult.name,
        });
      }
    });
    sections = sections.sort((a, b) => a.name - b.name).sort((a, b) => a.area - b.area);

    return sections.map((section, s_idx) => {
      let rows = massResults.filter(
        (massResult) => massResult.area.name === section.area && massResult.name === section.name
      );
      rows = rows
        .map((row) => {
          return row.layers.map((layer) => ({
            ...row,
            layers: layer,
          }));
        })
        .flat(1);

      let title = `CEA${section.area}-${section.name}`;
      return (
        <div key={s_idx} className="border rounded bg-light mb-2">
          <div
            className="d-flex justify-content-between form-section border-bottom bg-success"
            onClick={() => this.toggleSection(section.id)}
          >
            <h5 className="m-0">{title}</h5>
            <div className="d-flex flex-row">
              <small className="mt-1 mr-2">
                <span style={{ cursor: 'position' }} className="text-white">
                  {collapsedSections[section.id] ? 'Hide' : 'Show'}
                </span>
              </small>
            </div>
          </div>
          <Collapse isOpen={collapsedSections[section.id]} className="p-2">
            <GenericLsv
              controls={controlsPropertyResults}
              iconName="chart-mixed"
              emptyCaption="No results found"
              rows={rows}
            />
          </Collapse>
        </div>
      );
    });
  }

  render() {
    const { methodologies, soilTemplates } = this.props.manage;
    const { propertyRounds } = this.props.properties;
    let { controls } = this.state;

    // Set filter bar options
    controls = updateControlOptions(
      controls,
      'method',
      methodologies.map((method) => ({ id: method.tag, name: method.name }))
    );
    controls = updateControlOptions(controls, 'baseline_id', propertyRounds);
    controls = updateControlOptions(controls, 'template_id', soilTemplates);

    // Load LSV results
    const sections = this.loadSections();

    return (
      <div>
        <PropertyResultsToolbar
          controls={controls}
          handleChange={this.handleChange}
          onClick={this.onRefresh}
        />
        {sections}
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    properties: store.properties,
    realm: store.realm,
    manage: store.manage,
  };
};

export default connect(mapStoreToProps)(ProjectPropertyResults);
