import React from 'react';
import { connect } from "react-redux";
import {
  Row,
  Col
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

import {
  fetchEsm
} from '../actions';

class ChartLayersEsm extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      specLink: 'https://carbonlink.atlassian.net/wiki/spaces/OP/pages/554303545/Spec+-+Calculate+ESM+for+each+layer+defined+for+CEA',
      widget: {
        title: 'ESM for Layers in Soil Template'
      },
      data: [],
      fetching: false,
      height: 300
    })
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    const {
      selectedTemplate,
      area_round_id
    } = this.props;

    const {
      fetching,
      data
    } = this.state;

    if (selectedTemplate.id && area_round_id && !fetching && (area_round_id !== prevProps.area_round_id || selectedTemplate.id !== prevProps.selectedTemplate.id || data.length === 0)) {
      this.load();
    }
  }

  async load() {
    const {
      selectedTemplate,
      area_round_id
    } = this.props;

    this.setState(
      {fetching: true},
      async () => {
        const esm = await this.props.dispatch(fetchEsm(area_round_id, selectedTemplate.id));
        this.setState({data: esm.layers, fetching: false})
      }
    )
  }

  renderTiles() {
    const {
      data
    } = this.state;

    const colours = this.props.analytics.colours;

    const tiles = data.map((esm, idx) => {
      const esm_value = esm.esm.value.toFixed(2);

        let className = "text-center text-white p-3 rounded mt-1"
        // if (esm_value <= 0) {
        //   className = `${className} text-danger`;
        // } else {
        //   className = `${className} text-white`;
        // }
        const layerCaption = `ESM for Layer ${esm.upper_depth} to ${esm.lower_depth}`;
        return (
          <Col key={idx} sm={2} >
            <div className={className} style={{backgroundColor: colours[idx]}}>
              <h1>{esm_value}</h1>
              <div>{layerCaption}</div>
            </div>
          </Col>
        )
      // }
    })
    return tiles;
  }

  render() {
    const {
      selectedTemplate,
      area_round_id
    } = this.props;

    const {
      data,
      height,
      specLink,
      widget
    } = this.state;

    // If widget is empty
    const emptyCaption = `No Chart data available for ${widget.title}`;
    const iconName = 'chart-mixed';
    const specCaption = `Spec Sheet for ${widget.title}`;

    let haveData = false;
    if (data) {
      haveData = true;
    }

    return(
      <div className="m-1">
        {!haveData && (
          <div className="p-5 text-center m-1 bg-white rounded border border-secondary" style={{height: height}}>
            <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
            <div className="mt-3">{emptyCaption}</div>
          </div>
        )}

        {selectedTemplate.id && area_round_id && (
          <div>
            <Row >
              {this.renderTiles()}
            </Row>
            <div className="text-right mr-3">
              <small><a target="_blank" rel="noopener noreferrer" href={specLink}>{specCaption}</a></small>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    analytics: store.analytics
  }
}

export default connect(mapStoreToProps)(ChartLayersEsm);
