import React from 'react';
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ResourceUsageHoursByEntities = (props) => {
  const {
    data,
    orgs
  } = props;

  const bars = orgs.map((org, index) => {
    return (
      <Bar key={index} dataKey={org.name} name={org.name} stackId="a" fill={org.colour} unit="hrs" yAxisId="left"/>
    )
  })

  const toHrs = (decimal) => `${decimal} hrs`;
  // const toPercent = (decimal, fixed = 0) => `${(decimal).toFixed(0)}%`;

  return (
    <div className="mt-2">
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="week" />

          <YAxis yAxisId="left" tickFormatter={toHrs} />
          <YAxis yAxisId="left" tickFormatter={toHrs} />
          {/* <YAxis yAxisId="right" tickFormatter={toPercent} orientation="right" label={{ value: 'Dev Capacity', angle: -90, position: 'right', offset: -40}}/> */}

          <Tooltip />
          <Legend />

          {bars}
          {/* <Line dataKey="capacity" name="Dev Capacity" yAxisId="right" stroke="red" unit="%" type="monotone"/> */}
          <Line dataKey="effective_storypoints" name="Capacity" yAxisId="left" stroke="blue" unit="hrs" type="monotone"/>
          <Line dataKey="total_hours" name="Actual" yAxisId="left" stroke="none" unit="hrs" type="monotone"/>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ResourceUsageHoursByEntities;
