import { controlDefaults } from '../../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  transaction_date_listview: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    type: 'date',
    caption: 'Date',
    showInListview: true,
    sortColumn: 'transaction_date',
  },
  enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'enterprise.name',
    name: 'enterprise_id',
    type: 'select',
    caption: 'Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  property_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'property.name',
    name: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'animal_class.name',
    name: 'animal_class_id',
    type: 'select',
    caption: 'Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_type_id',
    type: 'select',
    caption: 'Transaction Type',
    disabled: true,
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  adjustment_type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'adjustment_type_id',
    fieldName: 'adjustment_type.name',
    type: 'select',
    caption: 'Adjustment Type',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    type: 'date',
    caption: 'Transaction Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    sortColumn: 'transaction_date',
  },
  quantity_shorn: {
    ...controlDefaults,
    placeholder: '',
    name: 'quantity_shorn',
    type: 'number',
    caption: '# Shorn',
    description: 'Enter negative or positive as required',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  weight: {
    ...controlDefaults,
    placeholder: '',
    name: 'weight',
    type: 'number',
    caption: 'Total Weight (kg)',
    description: 'Enter negative or positive as required',
    unitType: 'kg',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  notes_list: {
    ...controlDefaults,
    placeholder: '',
    name: 'notes',
    caption: 'Comments',
    showInListview: true,
    displayLimit: 30,
  },
  notes: {
    ...controlDefaults,
    placeholder: '',
    name: 'notes',
    type: 'textarea',
    caption: 'Comments',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
};
