import React from 'react';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import OtherBusinessMenu from './OtherBusinessMenu';
import { noop } from 'lodash';

const OtherBusinessToolbar = (props) => {
  const {
    onRefresh = noop,
    setModal = noop,
    setDirectCostModal = noop,
    setBusinessIncomeModal = noop,
  } = props;

  return (
    <div className="d-flex justify-content-end">
      <OtherBusinessMenu
        setModal={setModal}
        setDirectCostModal={setDirectCostModal}
        setBusinessIncomeModal={setBusinessIncomeModal}
      />
      <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
        <Icon name="rotate" />
      </Button>
    </div>
  );
};

export default OtherBusinessToolbar;
