import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  leavecode_id: {
    ...controlDefaults,
    name: 'leavecode_id',
    type: 'select',
    caption: 'Leave Code',
    options: [],
    description: 'Type of Leave you are applying for',
    validationRules: {
      isRequired: true,
    },
  },
  leave_date: {
    ...controlDefaults,
    placeholder: 'Leave Date',
    name: 'leave_date',
    type: 'date',
    caption: 'Leave Date',
    validationRules: {
      isRequired: true,
    },
  },
  from_date: {
    ...controlDefaults,
    placeholder: 'From',
    name: 'from_date',
    type: 'date',
    caption: 'From Date',
    validationRules: {
      isRequired: true,
    },
  },
  to_date: {
    ...controlDefaults,
    placeholder: 'To',
    name: 'to_date',
    type: 'date',
    caption: 'To Date',
    validationRules: {
      isRequired: true,
    },
  },
  leave_hours: {
    ...controlDefaults,
    placeholder: 'Hours',
    name: 'leave_hours',
    type: 'number',
    caption: 'Hours Per Day',
    validationRules: {},
  },
  comments: {
    ...controlDefaults,
    placeholder: 'Comments',
    name: 'comments',
    type: 'textarea',
    caption: 'Optional leave comment',
    persist: false,
    description: 'Required field',
    validationRules: {
      isRequired: false,
    },
  },
};
