import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Nav, TabContent, TabPane } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import LabRegisterPanel from './LabRegisterPanel';
import OrgsLsv from '../../../manage/components/OrgsLsv';
import TestCodesLsv from '../../../manage/components/TestCodesLsv';
import ActivitiesLsv from '../../../core/activity/components/ActivitiesLsv';
import LabRegisterActivitiesFilter from '../components/LabRegisterActivitiesFilter';

import { setLabRegisterParams } from '../actions';
import { fetchOrgs, fetchTestCodes, fetchDocumentDefs } from '../../../manage/actions';
import { fetchActivities } from '../../../core/activity/actions';

class LabRegister extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: null,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.loadLabs = this.loadLabs.bind(this);
    this.loadMoreActivities = this.loadMoreActivities.bind(this);
  }

  componentDidMount() {
    this.toggleTab('1', 'register');
  }

  toggleTab(tab, tag) {
    const { params, filters } = this.props.lab;

    const isOpen = false;
    switch (tag) {
      case 'register':
        this.props.dispatch(
          setLabRegisterParams(
            {
              ...params,
              area_round_id: null,
              property_round_id: null,
              limit: 30,
            },
            filters
          )
        );
        break;
      case 'labs':
      case 'lab_codes':
        this.loadLabs();
        this.props.dispatch(fetchTestCodes({ type: 'lab' }));
        this.props.dispatch(fetchDocumentDefs({ group_key: 'lab_register' }));
        break;
      case 'activity':
        this.props.dispatch(
          fetchActivities(
            { associate_with: 'lab_register', show_dependencies: true, limit: 30 },
            this.props.activities.filters
          )
        );
        break;
      default:
        break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        isOpen,
      });
    }
  }

  loadLabs() {
    this.props.dispatch(fetchOrgs({ category_tag: 'lab' }));
  }

  loadMoreActivities() {
    const { params, filters } = this.props.activities;

    const new_params = {
      ...params,
      limit: params.limit + 30,
    };

    this.props.dispatch(fetchActivities(new_params, filters));
  }

  render() {
    const { authorised, authResponseMessage } = this.props.lab;

    const { activities } = this.props.activities;
    const { orgs, codes } = this.props.manage;

    const iconName = 'flask';

    return (
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Lab Register</h3>
          </div>
        </div>
        <ResponseMessage responseMessage={authResponseMessage} />
        {authorised && (
          <>
            <Nav tabs className="mt-2">
              <FormTab
                caption="Register"
                tabTag="register"
                tabId="1"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Labs"
                tabTag="labs"
                tabId="2"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Lab Codes"
                tabTag="lab_codes"
                tabId="3"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Activity"
                tabTag="activity"
                tabId="4"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
              />
            </Nav>
            <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
              <TabPane tabId="1" className="mb-2 p-1">
                <LabRegisterPanel history={this.props.history} checkAccess={this.checkAccess} />
              </TabPane>
              <TabPane tabId="2" className="mb-2 p-1">
                <OrgsLsv
                  checkAccess={this.checkAccess}
                  rows={orgs || []}
                  onRefresh={this.loadLabs}
                />
              </TabPane>
              <TabPane tabId="3" className="mb-2 p-1">
                <TestCodesLsv checkAccess={this.checkAccess} rows={codes || []} type="lab" />
              </TabPane>
              <TabPane tabId="4" className="mb-2 p-1">
                <div className="bg-light p-2 d-flex justify-content-end rounded mb-1">
                  <Button id="toggler" size="sm" color="warning" className="text-white ml-2">
                    Filters
                  </Button>
                </div>
                <LabRegisterActivitiesFilter toggler="#toggler" />
                <ActivitiesLsv
                  rows={activities || []}
                  pagination
                  onLoadMore={this.loadMoreActivities}
                />
              </TabPane>
            </TabContent>
          </>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ activities, lab, realm, manage }) => ({
  activities,
  lab,
  realm,
  manage,
});

export default connect(mapStoreToProps)(withContainerError(LabRegister));
