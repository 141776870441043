import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  transaction_interval_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_interval_id',
    fieldName: 'transaction_interval.name',
    type: 'select',
    caption: 'Transaction Interval',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    fieldName: 'transaction_date',
    caption: 'Transaction Date',
    type: 'date',
    validationRules: {
      isRequired: true,
    },
    showInListview: false,
    showInEditor: true,
  },
  transaction_month: {
    ...controlDefaults,
    name: 'transaction_month',
    fieldName: 'transaction_date',
    caption: 'Month',
    type: 'date-month',
    showInEditor: false,
    showInListview: true,
    sortColumn: 'created',
  },
  transaction_type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_type_id',
    fieldName: 'transaction_type_id',
    caption: 'Transaction Type',
    type: 'select',
    includeEmptyOption: true,
    validationRules: {
      isRequired: true,
    },
    showInListview: false,
    showInEditor: true,
  },
  liability_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'liability_id',
    fieldName: 'liability_id',
    caption: 'Liability',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    showInListview: false,
    showInEditor: true,
  },
  liability: {
    ...controlDefaults,
    name: 'liability',
    fieldName: 'liability.name',
    caption: 'liability',
    showInListview: true,
    showInEditor: false,
  },
  transaction_type: {
    ...controlDefaults,
    name: 'transaction_type',
    fieldName: 'transaction_type.name',
    caption: 'Transaction Type',
    showInListview: true,
    showInEditor: false,
  },
  notes: {
    ...controlDefaults,
    name: 'notes',
    fieldName: 'notes',
    caption: 'Notes',
    type: 'textarea',
    validationRules: {},
    showInListview: false,
    showInEdit: true,
  },
  amount: {
    ...controlDefaults,
    name: 'amount',
    fieldName: 'amount',
    caption: 'Amount',
    type: 'number',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeDecimals: true,
      includeDollarSign: true,
      includeCommas: true,
    },
    showInListview: true,
    showInEditor: false,
  },
};
