import { defaults } from '../../../../lib/genericReducer';
export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    activeTabFieldTrips: 'field-trip',
    authorised: true,
    authResponseMessage: null,
    sites: [],
    responseMessage: null,
    samples: [],
    statuses: [],
    tags: [],
    scripts: [],
    fieldtrip: {},
    fieldtrips: [],
    fielduploads: [],
    fieldupload: {},
    fieldupload_sites: [],
    archivedScans: [],
    archivedScanSamples: [],
    fieldtrip_sites: [],
    dashboard_stats: [],
    typeKeys: [
      { id: 'T', name: 'Target' },
      { id: 'R', name: 'Reserve' },
    ],
    scanValids: [
      { id: 'true', name: 'Scan OK' },
      { id: 'false', name: 'Scan Failed' },
      { id: 'null', name: 'Not yet validated' },
    ],
    scansOnly: [
      { id: 'notnull', name: 'Scanned Sites Only' },
      { id: 'null', name: 'Sites NOT scanned Only' },
    ],
    operators_drill: [],
    operators_scan: [],
    filters: {
      status_id: [],
      type_key: [],
      scan_valid: [],
      scan_samples: ['notnull'],
    },
    recentlyCollectedFilters: {
      limit: 30,
      from_collected_date: '',
      search_value: '',
      order: 'collected_at.asc',
    },
    scanFilters: {
      tag_id: [],
    },
    params: {},
  },
  action
) {
  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'RESET_RESPONSE_MESSAGE': {
      return {
        ...state,
        responseMessage: null,
      };
    }
    case 'UNSET_FIELDUPLOAD': {
      return {
        ...state,
        fieldupload: {},
      };
    }
    case 'SET_ACTIVETAB_FIELDTRIPS': {
      return {
        ...state,
        activeTabFieldTrips: action.payload,
      };
    }
    case 'SET_SITE_PARAMS': {
      return {
        ...state,
        params: action.payload,
      };
    }
    case 'SET_SITE_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case 'SET_SCAN_FILTERS': {
      return {
        ...state,
        scanFilters: action.payload,
      };
    }
    case 'UNSET_SITES': {
      return {
        ...state,
        sites: [],
      };
    }
    case 'FETCH_DASHBOARD_STATS_BYSITES_FULFILLED': {
      return {
        ...state,
        dashboard_stats: action.payload,
      };
    }
    case 'FETCH_FIELDUPLOAD_SITES_FULFILLED': {
      return {
        ...state,
        fieldupload_sites: action.payload,
      };
    }
    case 'FETCH_FIELDUPLOAD_FULFILLED': {
      return {
        ...state,
        fieldupload: action.payload,
      };
    }
    case 'FETCH_FIELDUPLOADS_FULFILLED': {
      return {
        ...state,
        fielduploads: action.payload,
      };
    }
    case 'FETCH_FIELDTRIP_SITES_FULFILLED': {
      return {
        ...state,
        fieldtrip_sites: action.payload,
      };
    }
    case 'FETCH_FIELDTRIPS_FULFILLED': {
      return {
        ...state,
        fieldtrips: action.payload,
      };
    }
    case 'FETCH_FIELDTRIP_FULFILLED': {
      return {
        ...state,
        fieldtrip: action.payload,
      };
    }
    case 'CREATE_FIELDTRIP_PENDING': {
      return {
        ...state,
        roundResponseMessage: null,
      };
    }
    case 'CREATE_FIELDTRIP_FULFILLED': {
      return {
        ...state,
        roundResponseMessage: null,
        currentRound: action.payload,
      };
    }
    case 'CREATE_FIELDTRIP_REJECTED': {
      return {
        ...state,
        roundResponseMessage: action.payload,
      };
    }
    case 'FETCH_OPERATORS_SCAN_FULFILLED': {
      return {
        ...state,
        operators_scan: action.payload,
      };
    }
    case 'FETCH_OPERATORS_DRILL_FULFILLED': {
      return {
        ...state,
        operators_drill: action.payload,
      };
    }
    case 'FETCH_SITE_STATUSES_FULFILLED': {
      return {
        ...state,
        statuses: action.payload,
      };
    }
    case 'FETCH_SITES_FULFILLED': {
      return {
        ...state,
        sites: action.payload,
      };
    }
    case 'FETCH_SITE_SAMPLES_FULFILLED': {
      return {
        ...state,
        samples: action.payload,
      };
    }
    case 'FETCH_SITE_SCANS_FULFILLED': {
      return {
        ...state,
        scans: action.payload,
      };
    }
    case 'FETCH_ARCHIVED_SCANS_FULFILLED': {
      return {
        ...state,
        archivedScans: action.payload,
      };
    }
    case 'FETCH_ARCHIVED_SCAN_SAMPLES_FULFILLED': {
      return {
        ...state,
        archivedScanSamples: action.payload,
      };
    }
    case 'FETCH_SCAN_TAGS_FULFILLED': {
      return {
        ...state,
        tags: action.payload,
      };
    }
    case 'FETCH_SITE_SCRIPTS_FULFILLED': {
      return {
        ...state,
        scripts: action.payload,
      };
    }
    case 'SET_FIELDTRIP_FILTERS': {
      return {
        ...state,
        recentlyCollectedFilters: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
