import React from 'react';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import OffFarmMenu from './OffFarmMenu';
import { noop } from 'lodash';

const OffFarmToolbar = ({ onRefresh = noop, setModal = noop }) => {
  return (
    <div className="d-flex justify-content-end">
      <OffFarmMenu setModal={setModal} setBusinessIncomeModal={setModal} />
      <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
        <Icon name="rotate" title="Refresh" />
      </Button>
    </div>
  );
};

export default OffFarmToolbar;
