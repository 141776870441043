import React, { useState } from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';

import SiteSampleChartModal from './SiteSampleChartModal';
import SiteSamplesChartSpectraNm from '../components/SiteSamplesChartSpectraNm';
import SiteSamplesChartGamma from '../components/SiteSamplesChartGamma';

import { Card, Row, Col } from 'reactstrap';

const SiteScansLsv = (props) => {
  const { rows, samples, currentSite, onDelete, checkAccess } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const renderCard = (samples, current, scan) => {
    // Format scanned_at
    let created = moment.utc(scan.created).local().format('ddd, YYYY-MM-DD HH:mm');
    if (created === 'Invalid date') {
      created = null;
    }

    // Format scanned_at
    let scanned_at = moment.utc(scan.scanned_at).local().format('ddd, YYYY-MM-DD HH:mm');
    if (scanned_at === 'Invalid date') {
      scanned_at = null;
    }

    const titleBg = current ? 'bg-success' : 'bg-danger';
    const titleClassName = `text-white m-0 p-1 ${titleBg}`;
    return (
      <Card className="border border-secondary bg-light">
        <Row className={titleClassName}>
          <Col sm="4">Created: {created}</Col>
          <Col sm="4">Scanned: {scanned_at}</Col>
          <Col sm="4" className="d-flex justify-content-end text-white">
            {!current && checkAccess('siteUpdate') && (
              <div>
                <Icon
                  name="trash"
                  onClick={() => onDelete(scan.id)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            )}
          </Col>
        </Row>
        <div className="bg-white p-1 ml-2">{scan.notes}</div>
        <Row>
          <Col>
            <SiteSamplesChartGamma samples={samples} height={200} />
          </Col>
          <Col>
            <SiteSamplesChartSpectraNm samples={samples} height={200} />
          </Col>
        </Row>
      </Card>
    );
  };

  const every_nth = (arr, nth) => {
    return arr.filter((e, i) => i % nth === nth - 1 && e.substring(0, 1) === 'R');
  };

  const iconName = 'shredder';
  const emptyCaption =
    'No archive scans found. Probably because the site was only core scanned once.';

  // Default if rows are empty
  let cards = (
    <div className="p-5 text-center">
      <div>
        <Icon size="3x" name={iconName} className="text-corporate" />
      </div>
      <div className="mt-3">{emptyCaption}</div>
    </div>
  );

  const haveRows = rows && rows.length > 0;
  const everyNth = 50;

  if (haveRows) {
    cards = rows.map((row, index) => {
      const scan = row.scan_samples.map((sample) => {
        sample.reading_gamma = sample.Cnts;
        sample.scan_spectra_raw = {};
        sample.position = sample.core_position;

        const attributes = every_nth(Object.keys(row.scan_samples[0]), everyNth);
        attributes.map((attribute) => {
          sample.scan_spectra_raw[attribute] = sample[attribute];
          return false;
        });
        return sample;
      });

      return <div key={index}>{renderCard(scan, false, row)}</div>;
    });
  }

  return (
    <React.Fragment>
      <SiteSampleChartModal
        toggle={toggle}
        isOpen={isOpen}
        samples={samples}
        rows={rows}
        currentSite={currentSite}
      />
      <small className="text-success">ACTIVE SCAN</small>
      {renderCard(samples, true, currentSite)}

      <small className="text-danger">SCAN ARCHIVE</small>
      {cards}
    </React.Fragment>
  );
};

export default SiteScansLsv;
