import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  org_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'org_id',
    type: 'select',
    caption: 'Sample Type',
    validationRules: {
      isRequired: true,
    },
  },
  sample_type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'sample_type_id',
    type: 'select',
    caption: 'Sample Type',
    validationRules: {
      minLength: 6,
      isRequired: true,
    },
  },
  test_code_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'test_code_id',
    type: 'select',
    caption: 'Test Code',
    validationRules: {
      minLength: 6,
      isRequired: true,
    },
  },
  is_default: {
    ...controlDefaults,
    name: 'is_default',
    type: 'select',
    caption: 'Default',
    validationRules: {},
    options: [
      { id: false, name: 'No. Only available in action menu' },
      { id: true, name: 'Yes. Automatically chosen' },
    ],
  },
};
