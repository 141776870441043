import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiOrgLiabilities } from '../../../../constants/api';
const apiOrgLiabilityTransactions = `${apiOrgLiabilities}/transactions`;

export function fetchLiabilities(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_LIABILITIES`, `${apiOrgLiabilities}`, params);
}

export function fetchLiability(id) {
  return async (dispatch) => await get(dispatch, 'FETCH_LIABILITY', `${apiOrgLiabilities}/${id}`);
}

export function createLiability(data) {
  return async (dispatch) => await post(dispatch, `CREATE_LIABILITY`, `${apiOrgLiabilities}`, data);
}

export function updateLiability(data) {
  return async (dispatch) =>
    await put(dispatch, 'UPDATE_LIABILITY', `${apiOrgLiabilities}/${data.id}`, data);
}

export function removeLiability(id) {
  return async (dispatch) =>
    await remove(dispatch, 'REMOVE_LIABILITY', `${apiOrgLiabilities}/${id}`);
}

export function fetchLiabilityflows(params, filters) {
  return async (dispatch) => {
    // Store params in reducer
    if (exists(params)) dispatch({ type: 'SET_LIABILITY_PARAMS', payload: params });

    // Handle filters
    if (filters.categories.length > 0) {
      Object.keys(filters).forEach((key) => (params[`filter_${key}`] = filters[key]));
    }

    // Retrieve and store assetflows in reducer
    return await get(dispatch, `FETCH_LIABILITYFLOWS`, `${apiOrgLiabilities}/flows`, params);
  };
}

export function fetchLiabilityTransactions(params, inBackground = false) {
  return async (dispatch) =>
    await get(
      dispatch,
      `FETCH_LIABILITY_TRANSACTIONS`,
      `${apiOrgLiabilityTransactions}`,
      params,
      null,
      inBackground
    );
}

export function fetchLiabilityTransaction(id) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_LIABILITY_TRANSACTION`, `${apiOrgLiabilityTransactions}/${id}`);
}

export function createLiabilityTransaction(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_LIABILITY_TRANSACTION`, `${apiOrgLiabilityTransactions}`, data);
}

export function updateLiabilityTransaction(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      `UPDATE_LIABILITY_TRANSACTION`,
      `${apiOrgLiabilityTransactions}/${data.id}`,
      data
    );
}

export function removeLiabilityTransaction(id) {
  return async (dispatch) =>
    await remove(dispatch, `REMOVE_LIABILITY_TRANSACTION`, `${apiOrgLiabilityTransactions}/${id}`);
}

export function setLiabilityflowFilters(params, filters) {
  return async (dispatch) => {
    // Store filters in reducer
    dispatch({ type: 'SET_LIABILITYFLOW_FILTERS', payload: filters });

    // Retrieve and store filtered assets
    dispatch(fetchLiabilityflows(params, filters));
  };
}

const exists = (data) => Object.keys(data).length > 0;
