import React from 'react';
import { connect } from 'react-redux';
import { Card, Form, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { controls as forgotControls } from '../forms/forgot';
import FormInput from '../../form/components/FormInput';
import FormBase from '../../form/components/FormBase';

import { forgot } from '../actions';
import { cloneDeep } from 'lodash';

class Forgot extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      pwdMsg: '',
      showOrgSection: false,
      validationMessage: '',
      controls: cloneDeep(forgotControls),
      sent: false,
    };

    this.onSend = this.onSend.bind(this);
  }

  onSend() {
    const { controls } = this.state;
    const { value: email } = controls.email;

    this.props.dispatch(forgot(email));

    this.setState({
      sent: true,
    });
  }

  render() {
    const { controls, sent, pwdMsg } = this.state;
    const { currentApp } = this.props.realm;

    return (
      <div className="container-fluid p-0 h-100 bg-light row align-items-center d-flex justify-content-center">
        <Card className="p-2 card-border-color border-corporate login text-center">
          <Icon size="4x" name={currentApp.icon} className="text-corporate m-3" />
          <h3 className="text-secondary mt-2">{currentApp.title}</h3>
          {pwdMsg && <div className="text-danger text-center">{pwdMsg}</div>}
          {sent && (
            <div className="text-success text-center">
              Reset request sent to {controls.email.value}. Check your email box for the link.
            </div>
          )}
          {!sent && (
            <div>
              <p>
                Forgot your password? No problem! Enter your email address and we will send you a
                reset link.
              </p>
              <div className="m-4 text-left">
                <Form>
                  <FormInput handleChange={this.handleChange} control={controls.email} />
                </Form>
                <Button onClick={this.onSend} className="bg-corporate mt-3 w-100">
                  Send my Reset link
                </Button>
                <div className="m-2 d-flex justify-content-end">
                  <small>
                    <a href="/login">Login</a>
                  </small>
                </div>
              </div>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

const mapStoreToProps = ({ auth, realm }) => ({
  auth,
  realm,
});

export default connect(mapStoreToProps)(Forgot);
