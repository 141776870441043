import { noop } from 'lodash';
import FormInputColourPicker from 'jsx/components/core/form/components/FormInputColourPicker';

const ChartItemToolbar = (props) => {
  const {
    record = {},
    updateField = noop,
    charts = ['Bar', 'Line', 'Band', 'None'],
    allowColourEdit=true
  } = props;

  const toggleChart = () => {
    const idx = charts.findIndex(chart => chart === record.chart);
    
    let newIdx = idx + 1;
    if (newIdx > charts.length -1) newIdx = 0;
    updateField(record, 'chart', charts[newIdx]);
  };

  const handleColourChange = (hexValue) => {
    updateField(record, 'colour', hexValue);
  };

  return (
    <div className="d-flex justify-content-center">
      <span onClick={() => toggleChart(record)} className="mr-1">{record.chart}</span>
      {record.chart !== 'None' && (
        <FormInputColourPicker
          allowEdit={allowColourEdit}
          value={record.colour}
          fieldName={record.dataKey}
          onChange={(_, hexValue) => handleColourChange(hexValue)}
        />
      )}
  </div>
  );
};

export default ChartItemToolbar;
