import React, {Fragment, useState } from 'react';
import {
  Collapse,
} from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';

const PropertySearchSource = (props) => {
  const {
    source,
    addSource
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const expandedIcon = (isOpen ? 'chevron-double-up' : 'chevron-double-down');

  return (
    <Fragment>
      <div className="m-1 border border-secondary rounded">
        <div className=" d-flex justify-content-between p-2">
          <div>{source.name}</div>
          <div className="text-white">
            <Icon onClick={() => addSource(source.name)} name="plus" className="mr-3" style={{cursor: 'pointer'}}/>
            <Icon onClick={toggle} name={expandedIcon} className="mr-1" style={{cursor: 'pointer'}}/>
          </div>
        </div>

        <Collapse isOpen={isOpen}>
          <div style={{backgroundColor: '#333333'}} className="p-1">
            <div className="p-1">
              <small className="text-corporate">Source: <span className="text-white">{source.name}</span></small>
            </div>
            <div className="p-1 pt-0">
              <small className="text-corporate">Service: <span className="text-white">{source.service}</span></small>
            </div>
            <div className="p-1 pt-0">
              <small className="text-corporate">Locality: <span className="text-white">{source.locality_tag}</span></small>
            </div>
          </div>
        </Collapse>
      </div>
    </Fragment>
  );
}

export default PropertySearchSource;
