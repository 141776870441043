import { noop } from 'lodash';
import React from 'react';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { Button } from 'reactstrap';

type SpinnerButtonProps = {
  buttonColor?: string;
  caption: string;
  className?: string;
  disabled: boolean;
  isSpinning: boolean;
  onClick: () => void;
  size?: 'sm' | 'lg';
  spinnerColor?: string;
};

const SpinnerButton = ({
  buttonColor = 'corporate',
  caption,
  className,
  disabled,
  isSpinning,
  onClick = noop,
  size = 'sm',
  spinnerColor = '#ffffff',
}: SpinnerButtonProps) => (
  <Button
    size={size}
    color={buttonColor}
    className={className}
    onClick={onClick}
    disabled={disabled || isSpinning}
  >
    {isSpinning && (
      <HalfCircleSpinner className="align-self-center" color={spinnerColor} size={20} />
    )}
    {!isSpinning && <span>{caption}</span>}
  </Button>
);

export default SpinnerButton;
