import React from 'react';

import { Button } from 'reactstrap';
import SearchToolbar from 'jsx/components/core/form/components/SearchToolbar';
import { noop } from 'lodash';

const PlantProjectsToolbar = ({
  handleSearchChange = noop,
  onTransferPlant = noop,
  onReload = noop,
  onProjectAssociation = noop,
}) => (
  <div className="d-flex justify-content-end bg-light rounded">
    <SearchToolbar
      handleSearchChange={handleSearchChange}
      includePlusButton={false}
      includeReloadButton
      includeGoButton
      onReload={onReload}
    />
    <Button size="sm" color="success" className="my-2" onClick={() => onProjectAssociation(true)}>
      Associate Project
    </Button>
    <Button size="sm" color="primary" className="ml-2 my-2" onClick={() => onTransferPlant(true)}>
      Transfer Plant
    </Button>
  </div>
);

export default PlantProjectsToolbar;
