import React from 'react';
import { connect } from "react-redux";

import ResponseMessage from "../../../core/form/components/ResponseMessageTab";
import FormBase from "../../../core/form/components/FormBase";
import LabRegisterToolbar from '../components/LabRegisterToolbar';
import LabRegisterLsv from '../components/LabRegisterLsv';
import LabRegisterRecordModal from './LabRegisterRecordModal';
import LabRegisterFilter from '../components/LabRegisterFilter';

import {
  fetchLabRegister,
  setLabRegisterParams,
  bulkDownloadLabRegisterCustodyRecord
} from '../actions';
import LabRegisterBulkUpdateModal from './LabRegisterBulkUpdateModal';

class LabRegisterPanel extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      activeTab: '1',
      recordSelection: [],
      isOpen: false,
      isBulkOpen: false,
      id: null
    })

    this.refresh = this.refresh.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleDownloadCustodyChains = this.handleDownloadCustodyChains.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.edit = this.edit.bind(this);
  }

  refresh() {
    const { params, filters } = this.props.lab;
    this.props.dispatch(fetchLabRegister(params, filters));
  }

  onSelect(event) {
    const { recordSelection } = this.state;

    const state = event.target.checked;
    const id = event.target.value;
    if (state) {
      recordSelection.push(id);
    } else {
      const idx = recordSelection.findIndex(round_id => round_id === id);
      if (idx > -1) {
        recordSelection.splice(idx, 1);
      }
    }

    this.setState({
      recordSelection
    })
  }

  onSearch(event) {
    if (event) event.preventDefault();

    const {
      params,
      filters,
      searchValue
    } = this.props.lab;

    const searchParams = { ...params }
    if (searchValue) searchParams.search_value = searchValue
    else delete searchParams.search_value;

    this.props.dispatch(setLabRegisterParams(searchParams, filters));
  }

  handleSearchChange(event) {
    this.props.dispatch({ type: 'SET_LAB_REGISTER_SEARCH_VALUE', payload: event.target.value })
    if (event.keyCode === 13) {
      this.onSearch(event)
    }
  }

  async handleDownloadCustodyChains(onProgressChange) {
    const {
      recordSelection
    } = this.state;

    return await this.props.dispatch(bulkDownloadLabRegisterCustodyRecord({ records: recordSelection }, onProgressChange));
  }

  loadMore() {
    const {
      params,
      filters
    } = this.props.lab;
    this.props.dispatch(setLabRegisterParams({ ...params, limit: (params.limit ? params.limit + 30 : 30) }, filters));
  }

  edit(id) {
    this.setState({
      isOpen: true,
      id: id
    })
  }

  render() {
    const {
      isOpen,
      isBulkOpen,
      id,
      recordSelection
    } = this.state;

    const {
      register,
      responseMessage,
      searchValue
    } = this.props.lab;

    const {
      area_round_id,
      property_round_id
    } = this.props;

    const menuDisabled = recordSelection.length === 0;

    let record_type = null;
    let record_round_id = null;
    if (area_round_id) {
      record_type = 'area_round_id';
      record_round_id = area_round_id;
    } else if (property_round_id) {
      record_type = 'property_round_id';
      record_round_id = property_round_id;
    }

    return (
      <React.Fragment>
        <ResponseMessage responseMessage={responseMessage} />
        <LabRegisterRecordModal
          isOpen={isOpen}
          id={id}
          record_type={record_type}
          record_round_id={record_round_id}
          setModal={(toggle) => { this.setState({ isOpen: toggle }) }}
        />
        <LabRegisterBulkUpdateModal
          isOpen={isBulkOpen}
          recordSelection={recordSelection}
          setModal={(toggle) => { this.setState({ isBulkOpen: toggle }) }}
        />
        <LabRegisterToolbar
          refresh={this.refresh}
          onCreate={() => { this.edit(null) }}
          onSearch={this.onSearch}
          handleSearchChange={this.handleSearchChange}
          handleDownloadCustodyChains={(event) => this.handleDownloadCustodyChains(event)}
          handleUpdateRecords={() => { this.setState({ isBulkOpen: true }) }}
          searchValue={searchValue}
          menuDisabled={menuDisabled}
          checkAccess={this.checkAccess}
        />
        <LabRegisterFilter toggler="#toggler" />
        <LabRegisterLsv
          history={this.props.history}
          rows={register}
          pagination={true}
          onLoadMore={this.loadMore}
          handleSelect={this.onSelect}
          recordSelection={recordSelection}
        />
      </React.Fragment>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    lab: store.lab,
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(LabRegisterPanel);
