import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  enable_goal: {
    ...controlDefaults,
    name: 'enable_goal',
    field_name: 'enable_goal',
    type: 'select',
    caption: 'Enable Custom Goals',
    disabled: true,
    options: [ 
      { id: 'true', name: 'Yes' },
      { id: 'false', name: 'No' },
    ]
  },
  goal_value: {
    ...controlDefaults,
    name: 'goal_value',
    field_name: 'goal_value',
    type: 'text',
    caption: 'Goal Value',
    disabled: true,
    options: [ 
      { id: 'true', name: 'Yes' },
      { id: 'false', name: 'No' },
    ]
  },
  enable_bm: {
    ...controlDefaults,
    name: 'enable_bm',
    field_name: 'enable_bm',
    type: 'select',
    caption: 'Enable Benchmarking',
    disabled: true,
    options: [ 
      { id: 'true', name: 'Yes' },
      { id: 'false', name: 'No' },
    ]
  },
  bm_group_id: {
    ...controlDefaults,
    name: 'bm_group_id',
    field_name: 'bm_group_id',
    type: 'select',
    caption: 'Benchmark Group',
    disabled: true,
    options: []
  }
}
