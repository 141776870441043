import React from 'react';
import moment from 'moment';

const DaysheetStatus = ({ daysheet = {}, activities = [], breaks = [] }) => {
  // Check shift/labour hours
  const shiftHours = moment(daysheet.to_datetime).diff(daysheet.from_datetime, 'hour');

  const labourHours = activities.reduce((total, { labour_hours }) => total + labour_hours, 0);

  const unpaidBreaks = breaks.rows
    ?.filter(({ is_paid }) => !is_paid)
    .reduce((total, { hrs }) => total + hrs, 0);

  const checkHours =
    shiftHours !== labourHours + unpaidBreaks
      ? `Shift hours does not match sum of labour and break hours. Shift Hours = ${shiftHours} hrs. Labour Hours = ${labourHours} hrs. Unpaid Breaks = ${unpaidBreaks} hrs`
      : null;

  // Check break times
  const breaksApplied = breaks.rows?.filter(({ from_datetime }) => !from_datetime).length === 0;
  const checkBreaks = !breaksApplied ? 'All breaks must have a time assigned.' : null;

  const successMessage = `All Good! - Total Shift Hours = ${shiftHours} hrs. Total Labour Hours = ${labourHours} hrs. Unpaid Breaks = ${unpaidBreaks} hrs`;

  return (
    <ul className="bg-light">
      {checkBreaks && <li className="text-danger">{checkBreaks}</li>}
      {checkHours && <li className="text-danger">{checkHours}</li>}
      {!checkHours && !checkBreaks && <li className="text-success">{successMessage}</li>}
    </ul>
  );
};

export default DaysheetStatus;
