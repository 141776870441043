import { useState } from 'react';
import { Button, Label, Collapse, Card, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import PillPicker from 'jsx/components/core/form/components/PillPicker';
import PillPresenter from 'jsx/components/core/form/components/PillPresenter';
import SingleSearch from 'jsx/components/core/form/components/SingleSearch';
import Icon from 'jsx/components/core/icons/Icon';

const ProjectRegisterFilter = ({
  handleSearchChange = () => undefined,
  handleSearch = () => undefined,
  handleFilterChange = () => undefined,
  handleRemoveFilter = () => undefined,
  searchValue,
  projectCount = 0,
  projectTotal = 0,
  onRefresh = () => undefined,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const {
    statuses,
    officers,
    filters: {
      projectOfficer: projectOfficerSelection = [],
      projectStatus: projectStatusSelection = [],
    },
  } = useSelector(({ project_register }) => project_register);

  const filtersCount = projectOfficerSelection.length + projectStatusSelection.length;

  return (
    <div className="bg-light p-1">
      <div className="m-1 d-flex justify-content-end ">
        <Label className="m-1 mr-2 text-secondary">
          Showing {projectCount} of {projectTotal} results
        </Label>
        <SingleSearch
          handleSearchChange={handleSearchChange}
          onSearch={handleSearch}
          placeholder="Filter by keyword"
          value={searchValue}
        />
        <Button
          aria-label="Refresh Projects"
          onClick={onRefresh}
          size="sm"
          color="success"
          className="mr-1"
        >
          <Icon name="rotate" />
        </Button>
        <Button
          size="sm"
          color="primary"
          className="text-white ml-1"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          Filter
          <Icon className="ml-1" name="filter" />
        </Button>
      </div>
      <Collapse isOpen={!isCollapsed} className="m-1">
        <Card className="border border-corporate rounded mb-0">
          <Row className="m-0 p-2">
            <Col sm={4} className="m-1">
              <PillPicker
                caption="Project Status"
                handleChange={(event) => handleFilterChange(event, 'projectStatus')}
                rows={statuses}
                selection={projectStatusSelection}
                showPills={false}
              />
            </Col>
            <Col sm={4} className="m-1">
              <PillPicker
                caption="Project Officer"
                handleChange={(event) => handleFilterChange(event, 'projectOfficer')}
                rows={officers}
                selection={projectOfficerSelection}
                showPills={false}
              />
            </Col>
          </Row>
        </Card>
      </Collapse>
      {filtersCount > 0 && (
        <div className="bg-light d-flex rounded p-1">
          <PillPresenter
            caption="Statuses"
            handleDelete={(event) => handleRemoveFilter(event, 'projectStatus')}
            rows={statuses}
            selection={projectStatusSelection}
            colourField="colour"
          />
          <PillPresenter
            caption="Project Officer"
            handleDelete={(event) => handleRemoveFilter(event, 'projectOfficer')}
            rows={officers}
            selection={projectOfficerSelection}
            colourField="colour"
          />
        </div>
      )}
    </div>
  );
};

export default ProjectRegisterFilter;
