/* eslint-disable no-alert */
import { cloneDeep, isEmpty } from 'lodash';
import {
  createProjectPropertyAnalysisPeriod,
  fetchProjectPropertyAnalysisPeriod,
  removeProjectPropertyAnalysisPeriod,
  updateProjectPropertyAnalysisPeriod,
} from 'jsx/components/manage/actions/project_property_analysis_periods';
import { fetchAnalysisPeriods } from 'jsx/components/manage/actions/analysis_periods';
import FormBase from 'jsx/components/core/form/components/FormBase';
import { checkUserHasSomeRole } from 'jsx/lib/userAccess';
import GenericModal from 'jsx/components/core/form/components/GenericModal';
import { Button, Col, Row } from 'reactstrap';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import { connect } from 'react-redux';

import {
  clientControls,
  adminControls,
} from '../../../manage/forms/project_property_analysis_periods';
import Mapster from '../../projects/containers/Mapster';
import { fetchAttributes } from '../actions/attributes';

const lngLat = [150.7333, -23.1333];

class PropertyAnalysisPeriods extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      mapSources: [],
      controls: cloneDeep(clientControls),
      controlsTemplate: {},
      data: {},
      isOpen: false,
      isAdminOrAdvisor: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchAnalysisPeriods());
    this.props.dispatch(fetchAttributes({ type: 'analysis_period_client_statuses' }));
    this.props.dispatch(fetchAttributes({ type: 'analysis_period_rcs_statuses' }));

    // Set controls for admin/advisor or client
    const isAdminOrAdvisor = checkUserHasSomeRole(this.props, ['admin', 'advisor']);

    const controlsTemplate = isAdminOrAdvisor
      ? cloneDeep(adminControls)
      : cloneDeep(clientControls);
    this.setState({ isAdminOrAdvisor, controls: controlsTemplate, controlsTemplate });
  }

  componentDidUpdate() {
    const { map } = this.state;

    // Force a map redraw
    if (map) map.resize();
  }

  onClose = () => {
    // Reset controls, data, and refetch rows
    const { controlsTemplate } = this.state;
    this.setState({ isOpen: false, controls: controlsTemplate, data: {} });
    this.props.onRefresh();
  };

  onEdit = async (id) => {
    const data = await this.props.dispatch(fetchProjectPropertyAnalysisPeriod({ id }));
    if (!data?.id) return;

    this.setState({ data, isOpen: true });
  };

  onRemove = (id) => this.props.dispatch(removeProjectPropertyAnalysisPeriod(id));

  onSave = async (data, isNew, changes) => {
    const updatedData = isNew ? data : changes;
    if (isNew) delete updatedData.id;

    const saveMethod = isNew
      ? createProjectPropertyAnalysisPeriod
      : updateProjectPropertyAnalysisPeriod;
    return this.props.dispatch(saveMethod({ ...updatedData, property_id: this.props.propertyId }));
  };

  setModal = (isOpen) => {
    const { controlsTemplate, data } = this.state;
    let updatedState = { isOpen };
    if (!isOpen && !isEmpty(data))
      updatedState = {
        ...updatedState,
        data: {},
        controls: cloneDeep(controlsTemplate),
      };

    this.setState(updatedState);
  };

  render() {
    const { controls, data, isAdminOrAdvisor, isOpen, mapSources } = this.state;
    const { periods, propertyPeriods, responseMessage } = this.props.analysis_periods;
    const { analysis_period_client_statuses, analysis_period_rcs_statuses } = this.props.attributes;

    return (
      <>
        <GenericModal
          controls={controls}
          controlOptions={{
            analysis_period_id: periods.rows.map((row) => ({ ...row, name: row.description })),
            client_status_id: analysis_period_client_statuses,
            rcs_status_id: analysis_period_rcs_statuses,
          }}
          modalTitle="Property Analysis Period"
          setModal={this.setModal}
          data={data}
          isOpen={isOpen}
          iconName="timeline"
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onClose}
          responseMessage={responseMessage}
        />

        <Row className="h-100">
          <Col xs={5}>
            <div className="d-flex justify-content-between border-bottom border-lightgray pb-2">
              <h5>Analysis Periods</h5>
              <div className="d-flex justify-content-end">
                {isAdminOrAdvisor && (
                  <Button
                    className="ml-2"
                    size="sm"
                    color="success"
                    onClick={() => this.setModal(true)}
                  >
                    Add
                  </Button>
                )}
              </div>
            </div>
            <GenericLsv
              controls={controls}
              emptyCaption="No associated analysis periods found"
              iconName="people-group"
              onClick={(id) => (isAdminOrAdvisor ? this.onEdit(id) : null)}
              rows={propertyPeriods.rows}
            />
          </Col>
          <Col sm={7} className="border border-secondary m-0 mb-2 p-2 h-10">
            <Mapster
              expandMap={false}
              lngLatCenter={lngLat}
              mapSources={mapSources}
              onMapLoad={this.onMapLoad}
            />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStoreToProps = ({ analysis_periods, attributes, auth, profile }) => ({
  analysis_periods,
  attributes,
  auth,
  profile,
});

export default connect(mapStoreToProps)(PropertyAnalysisPeriods);
