import React from 'react';
import Listview from '../../../core/form/components/Listview';
import { connect } from 'react-redux';

import { fetchReportDivisionMetrics } from '../actions/reports';

class WidgetDivisionLsv extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
    this.renderRows = this.renderRows.bind(this);
  }

  async componentDidMount() {
    const { selectedRanges } = this.props.enterprises;

    const data = await this.props.dispatch(fetchReportDivisionMetrics(selectedRanges));
    this.setState({ data });
  }

  renderRows(headers, record) {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        default:
          return (
            <td key={index} className={header.classes}>
              {record[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  }

  render() {
    const { data } = this.state;

    const iconName = 'chart';
    const emptyCaption = 'No Widgets found';

    let headers = [{ caption: 'Division', field: 'name', classes: 'text-left' }];

    // Pivot data to put division name as column
    let rows = [];
    data.map((row) => {
      headers.push({ caption: row.division_name, field: row.division_name, classes: 'text-right' });

      const dataRows = row.data;
      dataRows.map((dataRow) => {
        const value = `${dataRow.prefix || ''}${dataRow.value.toLocaleString(undefined, {
          minimumFractionDigits: 0,
        })}${dataRow.suffix || ''}`;

        // Insert row unless it exists already
        const idx = rows.findIndex((r) => r.name === dataRow.name);
        if (idx === -1) {
          let newRow = {};
          newRow.name = dataRow.name;
          newRow[row.division_name] = value;
          rows.push(newRow);
        } else {
          rows[idx][row.division_name] = value;
        }

        return false;
      });

      return false;
    });

    const tableHeadTh = headers.map((header, index) => (
      <th key={index} className={header.classes}>
        {header.caption}
      </th>
    ));

    let tableBodyTr = <tr></tr>;
    let rowsCount;

    const haveRows = rows && rows.length > 0;
    if (haveRows) {
      tableBodyTr = rows.map((row, index) => (
        <tr key={index} onClick={() => {}} style={{ cursor: 'pointer' }}>
          {this.renderRows(headers, row)}
        </tr>
      ));
    }

    return (
      <div className="b">
        <Listview
          rows={rows}
          rowsCount={rowsCount}
          tableHeadTh={tableHeadTh}
          tableBodyTr={tableBodyTr}
          iconName={iconName}
          emptyCaption={emptyCaption}
        />
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    enterprises: store.enterprises,
  };
};

export default connect(mapStoreToProps)(WidgetDivisionLsv);
