import { controlDefaults } from 'jsx/components/core/form/lib/validateForm';

export const controls = {
  property_id: {
    ...controlDefaults,
    placeholder: 'Property',
    name: 'property_id',
    fieldName: 'property_id',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    options: [],
    showInEditor: true,
    showInListview: true,
  },
  group_id: {
    ...controlDefaults,
    placeholder: 'Select Benchmark Group',
    fieldName: 'group_id',
    name: 'group_id',
    type: 'select',
    caption: 'Benchmark Group',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    includeEmptyOption: true
  }
};
