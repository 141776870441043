import React from 'react';
import { Button, Label } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import ReactSelect from 'react-select';
// import SearchToolbar from 'jsx/components/core/toolbars/SearchToolbar';
// import SingleSearch from 'jsx/components/core/form/components/SingleSearch';
import FormInput from 'jsx/components/core/form/components/FormInputSmall';

const AdminWarehouseToolbar = (props) => {
  const {
    onRefresh = noop,
    handleFilterChange = noop,
    periods = [],
    probeFilterControls = noop,
    probes = 0
  } = props;

  const noneSelected = [{id: '', name: 'None Selected'}];
  probeFilterControls.period_id.options = noneSelected.concat(periods?.rows?.map(period => ({id: period?.id, name: period?.description})));

  const probeCount = `${probes} Probes`
  return (
    <div className="m-1 p-1 d-flex justify-content-between bg-light rounded">
      <div className="d-flex justify-content-start">
        <Label className="mr-2 text-nowrap" style={{marginTop: 3}}>Filter Period:</Label>
        <FormInput handleChange={handleFilterChange} control={probeFilterControls.period_id} />
        <Label className="mt-1 ml-2 text-nowrap">{probeCount}</Label>
      </div>

      <div className="d-flex justify-content-end">
        <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
          <Icon name="rotate" />
        </Button>
      </div>
    </div>
  );
};

export default AdminWarehouseToolbar;
