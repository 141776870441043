import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    name: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  description: {
    ...controlDefaults,
    name: 'description',
    type: 'textarea',
    caption: 'Description',
    rows: 2,
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: true,
  },
  formula: {
    ...controlDefaults,
    name: 'formula',
    type: 'text',
    caption: 'Formula',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: true,
  },
  key: {
    ...controlDefaults,
    name: 'key',
    type: 'text',
    caption: 'Key',
    description: 'Unique key referenced in formulas in other metrics',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  default_widget_tag: {
    ...controlDefaults,
    name: 'default_widget_tag',
    type: 'select',
    caption: 'Default Widget tag',
    options: [
      { id: 'gauge', name: '% Gauge' },
      { id: 'tile', name: 'Tile' },
    ],
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
    value: 'gauge',
  },
  sequence: {
    ...controlDefaults,
    name: 'sequence',
    type: 'number',
    caption: 'Sequence',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
  },
  unit_attributes: {
    ...controlDefaults,
    name: 'unit_attributes',
    type: 'text',
    caption: 'Unit Attributes',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
  },
  filter_attributes: {
    ...controlDefaults,
    name: 'filter_attributes',
    type: 'text',
    caption: 'Exclude Filtering',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
  },
  ignore_parent_filtering: {
    ...controlDefaults,
    name: 'ignore_parent_filtering',
    type: 'checkbox',
    caption: 'Ignore Parent Filtering',
    showInEditor: true,
    showInListview: false,
  },
  prefix: {
    ...controlDefaults,
    name: 'prefix',
    type: 'text',
    caption: 'Prefix',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
  },
  suffix: {
    ...controlDefaults,
    name: 'suffix',
    type: 'text',
    caption: 'Suffix',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    value: ' %',
  },
  decimals: {
    ...controlDefaults,
    name: 'decimals',
    type: 'number',
    caption: 'Decimal Places',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    value: 2,
  },
  goal: {
    ...controlDefaults,
    name: 'goal',
    type: 'number',
    caption: 'Goal',
    description: 'Goal to measure against metric value.',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
  },
  widget_attributes: {
    ...controlDefaults,
    name: 'widget_attributes',
    type: 'text',
    caption: 'Widget Attributes',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
  },
  color: {
    ...controlDefaults,
    name: 'color',
    type: 'text',
    caption: 'Colour',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
  },
  client_search: {
    ...controlDefaults,
    name: 'client_search',
    type: 'search',
    placeholder: 'Type to start searching...',
    options: [],
    validationRules: {
      isRequired: false,
    },
  },
};
