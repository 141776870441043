import React from 'react';
import { Button, Input, Row, Col } from 'reactstrap';

const FieldBookSearch = ({ handleChange, onSearch, value, responseMessage, innerRef }) => (
  <Row className="bg-secondary m-0 mt-2 p-2 text-white rounded-top">
    <Col sm="4" className="p-0 m-0">
      <Input
        innerRef={innerRef}
        value={value}
        bsSize="sm"
        onChange={handleChange}
        onKeyDown={onSearch}
        placeholder="Search by Core ID unique numeric..."
      />
    </Col>
    <Col sm="1" className="p-0 m-0 ml-1">
      <Button size="sm" color="success" onKeyDown={onSearch} onClick={onSearch}>
        Go
      </Button>
    </Col>
    <Col className="text-right p-1">{responseMessage}</Col>
  </Row>
);

export default FieldBookSearch;
