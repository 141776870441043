import { getFarmEyePreferences } from 'jsx/cookies';
import { isEmpty } from 'lodash';
import { get, post, put, remove } from '../../../../lib/genericAction';
import { apiEnterprises, apiAttributes } from '../../../../constants/api';

export function fetchEnterprises(params) {
  return async (dispatch) => get(dispatch, 'FETCH_ENTERPRISES', `${apiEnterprises}`, params);
}

export function fetchEnterprise(id) {
  return async (dispatch) => get(dispatch, 'FETCH_ENTERPRISE', `${apiEnterprises}/${id}`);
}

export function fetchEnterpriseIntervalRanges() {
  return async (dispatch) => {
    const preferences = getFarmEyePreferences('interval');
    if (!isEmpty(preferences)) {
      const interval_id = preferences?.farmeye_interval_interval_id ?? null;
      let interval = null;
      if (interval_id !== null && interval_id !== 'all_time') {
        interval = await get(dispatch, 'FETCH_ATTRIBUTE', `${apiAttributes}/${interval_id}`);
      }

      if (interval_id === 'all_time') {
        interval = {
          id: 'all_time',
          value: 'all_time',
          tag: 'all_time',
          label: 'All Time',
          name: 'All Time',
        };
      }
      const payload = {
        interval,
        transaction_date: preferences?.farmeye_interval_transaction_date ?? null,
      };

      // Send the payload regardless with minimum of one preference
      await dispatch({
        type: 'FETCH_ENTERPRISE_INTERVAL_RANGES_FULFILLED',
        payload,
      });

      return true;
    }
    return get(dispatch, 'FETCH_ENTERPRISE_INTERVAL_RANGES', `${apiEnterprises}/ranges/intervals`);
  };
}

export function fetchEnterpriseRanges() {
  return async (dispatch) => {
    // Check if client has preferences in cookies
    const preferences = getFarmEyePreferences('ranges');
    if (!isEmpty(preferences)) {
      // We know at least one preference exists
      const min_date = preferences?.farmeye_ranges_from_date ?? null;
      const max_date = preferences?.farmeye_ranges_to_date ?? null;
      const payload = {};

      if (min_date) payload.min_date = min_date;
      if (max_date) payload.max_date = max_date;

      // Send the payload regardless with minimum of one preference
      await dispatch({
        type: 'FETCH_ENTERPRISE_RANGES_FULFILLED',
        payload,
      });

      /**
       * Check for missing preferences.
       * We will still need to hit the API to get the default range for the missing preference
       */
      if (!min_date) {
        return get(dispatch, 'FETCH_ENTERPRISE_RANGES', `${apiEnterprises}/ranges`, {
          min_date_only: true,
        });
      }

      if (!max_date) {
        return get(dispatch, 'FETCH_ENTERPRISE_RANGES', `${apiEnterprises}/ranges`, {
          max_date_only: true,
        });
      }

      // Resolve the promise
      return true;
    }

    return get(dispatch, 'FETCH_ENTERPRISE_RANGES', `${apiEnterprises}/ranges`);
  };
}

export function fetchEnterpriseTransactionRanges() {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ENTERPRISE_TRANSACTION_RANGES', `${apiEnterprises}/ranges/transactions`);
}

export function createEnterprise(data) {
  return async (dispatch) => post(dispatch, 'CREATE_ENTERPRISE', `${apiEnterprises}`, data);
}

export function updateEnterprise(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_ENTERPRISE', `${apiEnterprises}/${data.id}`, data);
}

export function removeEnterprise(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_ENTERPRISE', `${apiEnterprises}/${id}`);
}

export function fetchEnterpriseAllocations(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ENTERPRISE_ALLOCATIONS', `${apiEnterprises}/allocations`, params);
}

export function fetchEnterpriseAllocation(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ENTERPRISE_ALLOCATION', `${apiEnterprises}/allocations/${id}`);
}

export function fetchPropertyTotalArea(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_PROPERTY_TOTAL_AREA', `${apiEnterprises}/allocations/property/${id}/area`);
}

export function fetchEnterpriseDistributions(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ENTERPRISE_DISTRIBUTIONS', `${apiEnterprises}/distributions`, params);
}

export function createEnterpriseAllocation(data) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_ENTERPRISE_ALLOCATION', `${apiEnterprises}/allocations`, data);
}

export function updateEnterpriseAllocation(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_ENTERPRISE_ALLOCATION', `${apiEnterprises}/allocations/${data.id}`, data);
}

export function removeEnterpriseAllocation(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_ENTERPRISE_ALLOCATION', `${apiEnterprises}/allocations/${id}`);
}

export function fetchEnterpriseUsages(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ENTERPRISE_USAGES', `${apiEnterprises}/usages/${id}`);
}

export function fetchDistinctAssociatedDivisions() {
  return async (dispatch) =>
    get(dispatch, 'FETCH_DISTINCT_ASSOCIATED_DIVISIONS', `${apiEnterprises}/divisions/`);
}
