import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  collected_at: {
    ...controlDefaults,
    placeholder: '',
    name: 'collected_at',
    fieldName: 'collected_at',
    type: 'datetime',
    caption: 'Collected',
    validationRules: {
        minLength: 2,
        isRequired: true
    },
    showInListview: true
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    fieldName: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      minLength: 2,
      isRequired: true
    },
    showInListview: true
  },
  category_name: {
    ...controlDefaults,
    placeholder: '',
    name: 'category_name',
    fieldName: 'category_name',
    type: 'text',
    caption: 'Category',
    validationRules: {
      isRequired: false
    },
    showInListview: true
  },
  level: {
    ...controlDefaults,
    placeholder: '',
    name: 'level',
    fieldName: 'level',
    type: 'text',
    caption: 'Level',
    validationRules: {
      isRequired: false
    },
    showInListview: true
  },
  location: {
    ...controlDefaults,
    placeholder: '',
    name: 'location',
    fieldName: 'location',
    type: 'text',
    caption: 'Location (Lng/Lat)',
    validationRules: {
      isRequired: false
    },
    showInListview: true
  },
  measures: {
    ...controlDefaults,
    placeholder: '',
    name: 'measures',
    fieldName: 'measures',
    type: 'text',
    caption: 'Measures',
    validationRules: {
      isRequired: false
    },
    showInListview: true
  },
  acceptable_trend: {
    ...controlDefaults,
    placeholder: '',
    name: 'acceptable_trend',
    fieldName: 'acceptable_trend',
    type: 'text',
    caption: 'Acceptable Trend',
    validationRules: {
      isRequired: false
    },
    showInListview: true
  },
  value: {
    ...controlDefaults,
    placeholder: '',
    name: 'value',
    fieldName: 'value',
    type: 'text',
    caption: 'Value',
    validationRules: {
      isRequired: false
    },
    showInListview: true
  },
}
