import React from 'react';
import { connect } from "react-redux";
import {
  Button,
  Collapse,
} from "reactstrap";

import Icon from 'jsx/components/core/icons/Icon';
import FormBase from "../../core/form/components/FormBase";
import ResponseMessage from "../../core/form/components/ResponseMessageTab";
import SoilTemplateModal from './SoilTemplateModal';
import SoilTemplateLayersLsv from './SoilTemplateLayersLsv';
import PageTitleH5 from "../../core/form/components/PageTitleH5";
import {
  fetchSoilTemplates,
  createSoilTemplateLayer,
  removeSoilTemplateLayer,
  updateSoilTemplateLayer
} from '../actions';

class SoilTemplates extends FormBase {

  constructor(props) {
    super(props);

    this.state = ({
      isOpen: false,
      sections: {}
    })

    this.edit = this.edit.bind(this);
    this.setModal = this.setModal.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
    this.onSaveLayer = this.onSaveLayer.bind(this);
    this.onRemoveLayer = this.onRemoveLayer.bind(this);
    this.renderTemplate = this.renderTemplate.bind(this);
  }

  componentDidUpdate() {
    const { sections } = this.state;

    const { soilTemplates } = this.props.manage;

    soilTemplates.map(soilTemplate => {
      if (!sections[soilTemplate.id]) sections[soilTemplate.id] = false;
      return false;
    })
  }

  edit(id) {
    this.setState({
      id,
      isOpen: true
    })
  }

  setModal(isOpen) {
    this.setState({
      isOpen
    })
  }

  toggleSection(id) {
    const { sections } = this.state;
    sections[id] = !sections[id];
    this.setState({
      sections
    });
  }

  async onSaveLayer(data) {
    if (data.editMode !== undefined) {
      delete data.editMode;
    }

    let success;
    if (data.id) {
      success = await this.props.dispatch(updateSoilTemplateLayer(data))
    } else {
      success = await this.props.dispatch(createSoilTemplateLayer(data));
    }
    if (success) {
      this.props.dispatch(fetchSoilTemplates());
    }
    return success;
  }

  async onRemoveLayer(id) {
    const success = await this.props.dispatch(removeSoilTemplateLayer(id));
    if (success) {
      this.props.dispatch(fetchSoilTemplates());
    }
  }

  renderTemplate(soilTemplate) {
    const { sections } = this.state;
    return (<div key={soilTemplate.id} className="mt-4 border rounded bg-light">
      <div className="d-flex justify-content-between form-section" onClick={() => this.toggleSection(soilTemplate.id)}>
        <div className="d-flex flex-row">
          <h5>{`${soilTemplate.name} (${soilTemplate.type})`}</h5>
          <Icon onClick={() => { this.edit(soilTemplate.id) }} name="pen-to-square" style={{ cursor: 'pointer' }} className="text-white ml-2 mt-1" />
        </div>
        <div className="d-flex flex-row">
          <small className="mt-1 mr-2"><span style={{cursor: 'position'}} className="text-white">{sections[soilTemplate.id] ? 'Hide' : 'Show'}</span></small>
        </div>

      </div>
      <Collapse isOpen={sections[soilTemplate.id]} className="p-2">
        <SoilTemplateLayersLsv rows={soilTemplate.soil_template_layers || []} template_id={soilTemplate.id} template_type={soilTemplate.type} onSave={this.onSaveLayer} onRemove={this.onRemoveLayer} />
      </Collapse>
    </div>)
  }

  render() {
    const {
      soilTemplates,
      responseMessage
    } = this.props.manage;

    const {
      id,
      isOpen
    } = this.state;

    const content = soilTemplates.map(soilTemplate => {
      return (this.renderTemplate(soilTemplate))
    })

    const title = 'Soil Layers';
    const iconName = 'flask';

    return (
      <React.Fragment>
        <SoilTemplateModal setModal={this.setModal} id={id} isOpen={isOpen} />
        <div className="d-flex justify-content-between border-bottom border-corporate bg-light p-1 rounded">
          <PageTitleH5 iconName={iconName} title={title} />
          <div>
            <ResponseMessage responseMessage={responseMessage} />
            <div className="d-flex justify-content-end">
              <Button color="success" size="sm" onClick={() => this.edit(null)}>Add New Template</Button>
            </div>
          </div>
        </div>

        { soilTemplates.length > 0 && content}
        { soilTemplates.length === 0 && (
          <div className="p-5 text-center">
            <div><Icon size="3x" name="flask" className="text-corporate" /></div>
            <div className="mt-3">No layers found</div>
          </div>
        )}
      </React.Fragment>
    )

  }
}

const mapStoreToProps = (store) => {
  return {
    manage: store.manage
  }
}

export default connect(mapStoreToProps)(SoilTemplates);
