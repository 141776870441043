import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  transaction_month: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'transaction_date',
    name: 'transaction_month',
    type: 'date-month',
    caption: 'Month',
    showInEditor: false,
    showInListview: true,
    sortColumn: 'transaction_date',
  },
  enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'enterprise',
    fieldName: 'enterprise.name',
    name: 'enterprise_id',
    type: 'select',
    caption: 'Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  property_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'enterprise',
    fieldName: 'property.name',
    name: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'enterprise',
    fieldName: 'animal_class.name',
    name: 'animal_class_id',
    type: 'select',
    caption: 'Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_type_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'transaction',
    name: 'transaction_type_id',
    type: 'select',
    caption: 'Transaction Type',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    group: 'transaction',
    name: 'transaction_date',
    type: 'date',
    caption: 'Transaction Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  quantity: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    name: 'quantity',
    type: 'number',
    caption: '# of Head',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    user_entry: false,
    totals: true,
    resetValue: null,
  },
  weight: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    fieldName: 'weight',
    name: 'weight',
    unitType: 'kg',
    formula: '##quantity## * ##weight_per_kg_head##',
    formula_priority: 1,
    type: 'number',
    caption: 'Total Weight (kg)',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
      includeDecimals: false,
    },
    showInEditor: true,
    showInListview: true,
    tracking: ['weight_per_kg_head'],
    user_entry: false,
    totals: true,
    resetValue: null,
  },
  amount: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    name: 'amount',
    formula: '##quantity## * ##value_per_head##',
    formula_priority: 1,
    type: 'number',
    caption: 'Total Amount ($)',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
      includeDollarSign: true,
      includeDecimals: false,
    },
    showInEditor: true,
    showInListview: true,
    tracking: ['value_per_head'],
    user_entry: false,
    totals: true,
    resetValue: null,
  },
  dressing_pcnt: {
    ...controlDefaults,
    group: 'value',
    name: 'dressing_pcnt',
    disabled: false,
    type: 'number',
    caption: 'Dressing %',
    formattingRules: {
      includeDecimals: false,
    },
    showRules: {
      dependsOn: {
        transaction_type_id: 'Sale',
      },
    },
    showInEditor: true,
    showInListview: true,
    user_entry: false,
    resetValue: null,
  },
  weight_per_kg_head: {
    ...controlDefaults,
    group: 'checks',
    name: 'weight_per_kg_head',
    formula: '##weight## / ##quantity##',
    formula_priority: 0,
    type: 'number',
    caption: 'Weight (kg/head)',
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
    tracking: ['weight'],
    user_entry: false,
    resetValue: null,
  },
  live_weight_per_kg_head: {
    ...controlDefaults,
    group: 'checks',
    name: 'live_weight_per_kg_head',
    formula: '(##weight## / ##quantity##) / (##dressing_pcnt## / 100)',
    formula_priority: 0,
    type: 'number',
    caption: 'Live Weight (kg/head)',
    copyValue: {
      from: 'weight_per_kg_head',
      rules: {
        isEmpty: true,
      },
    },
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    showRules: {
      dependsOn: {
        transaction_type_id: 'Sale',
      },
    },
    disabled: true,
    showInEditor: true,
    showInListview: true,
    resetValue: null,
  },
  value_per_head: {
    ...controlDefaults,
    group: 'checks',
    name: 'value_per_head',
    formula: '##amount## / ##quantity##',
    formula_priority: 0,
    type: 'number',
    caption: 'Value ($/head)',
    formattingRules: {
      includeCommas: true,
      includeDollarSign: true,
      includeDecimals: false,
    },
    showInEditor: true,
    showInListview: true,
    tracking: ['amount'],
    user_entry: false,
    resetValue: null,
  },
  value_per_kg: {
    ...controlDefaults,
    group: 'checks',
    name: 'value_per_kg',
    disabled: true,
    formula: '##amount## / ##weight##',
    formula_priority: 0,
    type: 'number',
    caption: 'Value ($/kg)',
    formattingRules: {
      includeCommas: true,
      includeDollarSign: true,
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
    user_entry: false,
    resetValue: null,
  },
};
