import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, isNil } from 'lodash';
import { reauthenticate } from 'jsx/components/core/authentication/actions';
import SessionRefreshModal from 'jsx/components/core/authentication/components/SessionRefreshModal';
import { msalConfig } from '../components/core/authentication/constants/authConfigMicrosoft';
import RealmLoader from '../components/core/loader/components/RealmLoader';
import SocketReducer from '../lib/socketReducer';
import AppRoutes from './AppRoutes';

import { fetchMode, setRealm } from '../actions';

const msalInstance = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient();

const farmeyeRealmIds = ['farmportrait', 'farmportrait_portal'];

class App extends Component {
  componentDidMount() {
    this.refreshRealm();

    // Start socket client for updating reducers
    if (process.env.REACT_APP_DISABLE_SOCKET_CONNECTION !== 'true') {
      this.socketReducer = new SocketReducer(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    const { realm } = this.props;
    const { authenticated } = this.props.auth;
    // Refresh realm if reducer has been reset.
    if (prevProps.realm.currentApp.id && !this.props.realm.currentApp.id) this.refreshRealm();

    /**
     * Ensure we do not reauthenticate when the user is masquerading.
     * Logic for invalidating the masquerade user is in PrivateRoute.jsx
     */
    const cookies = document.cookie.split(';');
    const masqueradeCookieExists = cookies.find((cookie) => cookie.includes('is-masquerade'));
    const isMasqueradeUrl = window.location.href.includes('masquerade');
    const isMasquerading = masqueradeCookieExists || isMasqueradeUrl;

    // Check application is FarmEye Portal or Farmeye Admin and the client is not authenticated
    if (
      prevProps.realm.currentApp.id === null &&
      farmeyeRealmIds.includes(realm.currentApp.id) &&
      !authenticated &&
      !isMasquerading
    ) {
      // Check if access token exists
      const xAccessToken = cookies.find((cookie) => cookie.includes('x-access-token'));

      if (xAccessToken) {
        // Query API to check if access token is valid
        /* eslint-disable-next-line */
        const token = xAccessToken.split('=')[1];
        if (!isNil(token) && !isEmpty(token)) {
          this.props.dispatch(reauthenticate({ token }));
        }
      }
    }
  }

  refreshRealm() {
    this.props.dispatch(setRealm());
    this.props.dispatch(fetchMode());
  }

  render() {
    const { loading, auth, realm } = this.props;
    const { authenticated } = auth;
    const realmFound = realm.currentApp.id !== null;

    return (
      <QueryClientProvider client={queryClient}>
        <RealmLoader found={realmFound} />
        <SessionRefreshModal isAuthenticated={authenticated} />
        {realmFound && (
          <MsalProvider instance={msalInstance}>
            <AppRoutes authenticated={authenticated} loading={loading} />
          </MsalProvider>
        )}
      </QueryClientProvider>
    );
  }
}

const mapStoreToProps = ({ ajaxRequests, auth, realm }) => ({
  loading: ajaxRequests.calls > 0,
  auth,
  realm,
});

export default connect(mapStoreToProps)(App);
