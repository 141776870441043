import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane, Row, Col } from 'reactstrap';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import { fetchAttributes } from 'jsx/components/modules/portrait/actions/attributes';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import GenericToolbar from 'jsx/components/modules/portrait/components/GenericToolbar';
import FilterIntervalDatePicker from 'jsx/components/modules/portrait/components/FilterIntervalDatePicker';
import { fetchEnterpriseIntervalRanges } from 'jsx/components/modules/portrait/actions/enterprises';
import { isEmpty } from 'lodash';
import FormTab from '../../core/form/components/FormTab';
import FormBase from '../../core/form/components/FormBase';
import ResponseMessage from '../../core/form/components/ResponseMessageTab';
import UsersLsv from '../components/UsersLsv';
import RolesLsv from '../components/RolesLsv';
import InvitationsLsv from '../components/InvitationsLsv';
import DocumentDefsLsv from '../components/DocumentDefsLsv';
import OrgsLsv from '../components/OrgsLsv';
import SoilTemplates from '../components/SoilTemplates';
import CostcodesLsv from '../components/CostcodesLsv';
import ReportMetrics from './ReportMetrics';
import CustomAttributes from './CustomAttributes';
import PageTitleH5 from '../../core/form/components/PageTitleH5';
import ActivitiesLsv from '../../core/activity/components/ActivitiesLsv';
import { fetchActivities } from '../../core/activity/actions';
import AdminBenchmarking from 'jsx/components/modules/portrait/containers/admin/AdminBenchmarking';

import {
  fetchUsers,
  fetchRoles,
  fetchInvitations,
  fetchDocumentDefs,
  fetchOutputMappings,
  removeInvite,
  fetchOrgs,
  fetchSoilTemplates,
  fetchCostcodes,
  setUserParams,
} from '../actions';
import OutputMappingsLsv from '../components/OutputMappingsLsv';
import UsersToolbar from '../components/UsersToolbar';
import Invitation from './Invitation';
import UsersFilter from '../components/UsersFilter';
import { fetchSamplingPlans } from '../actions/sampling_plan';
import { controls as samplingPlanControls } from '../forms/sampling_plans';
import AnalysisGroups from './AnalysisGroups';
import AnalysisPeriods from './AnalysisPeriods';
import LivestockAssumptions from './LivestockAssumptions';
import {
  fetchAssumedAnimalClassValueRanges,
  fetchAssumedAnimalClasses,
} from '../actions/livestock_assumptions';

class Settings extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: null,
      activities: [],
      isFilterOpen: false,
      isInvitationModalOpen: false,
      searchValue: '',
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.loadMoreActivities = this.loadMoreActivities.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.removeInvitation = this.removeInvitation.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.setInvitationModal = this.setInvitationModal.bind(this);
    this.setSearchValue = this.setSearchValue.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleDefaultTab = this.toggleDefaultTab.bind(this);
  }

  async componentDidMount() {
    const { manage } = this.props.realm.currentApp;
    if (manage?.length > 0) this.toggleDefaultTab();

    await this.props.dispatch(fetchAttributes({ type: 'transaction_intervals' }));
    await this.props.dispatch(fetchEnterpriseIntervalRanges());
  }

  componentDidUpdate() {
    const { activeSettingsTab } = this.props.manage;
    const { dispatch } = this.props;
    const { userResponseMessage } = this.props.manage;
    const { manage } = this.props.realm.currentApp;

    if (activeSettingsTab === null && manage?.length > 0) this.toggleDefaultTab();

    if (userResponseMessage) {
      setTimeout(() => {
        dispatch({ type: 'RESET_USER_RESPONSE_MESSAGE' });
      }, 3000);
    }
  }

  setSearchValue(searchValue) {
    this.setState({ searchValue });
  }

  loadMoreActivities() {
    const { params } = this.props.activities;

    let limit = 30;
    if (params.limit) {
      limit = params.limit + limit;
    }

    const new_params = {
      associate_with: 'user',
      show_dependencies: false,
      limit,
    };
    this.props.dispatch(fetchActivities(new_params, null));
  }

  async toggleTab(tab, tag) {
    switch (tag) {
      case 'users':
        const { filters, params } = this.props.manage;
        let userParams = {};

        // Handle search value
        if (params?.search_value) {
          const { search_value } = params;
          userParams = { search_value };
          this.setState({ searchValue: search_value });
        }

        // Handle filters
        this.props.dispatch(fetchUsers(userParams, filters));
        break;
      case 'invites':
        this.props.dispatch(fetchInvitations());
        break;
      case 'roles':
        this.props.dispatch(fetchRoles());
        break;
      case 'document_defs':
        this.props.dispatch(fetchDocumentDefs());
        break;
      case 'soil_templates':
        this.props.dispatch(fetchSoilTemplates());
        break;
      case 'costcodes':
        this.props.dispatch(fetchOrgs({ userorgs: true }));
        this.props.dispatch(fetchCostcodes());
        break;
      case 'orgs':
        this.props.dispatch(fetchOrgs());
        this.props.dispatch(fetchAttributes({ type: 'category_tags' }));
        break;
      case 'activity':
        this.loadMoreActivities();
        break;
      case 'output_mappings':
        this.props.dispatch(fetchOutputMappings());
        break;
      case 'report_metrics':
        // this.props.dispatch(fetchOutputMappings());
        break;
      case 'sampling_plans': {
        this.props.dispatch(fetchSamplingPlans());
        break;
      }
      case 'livestock_assumptions': {
        this.props.dispatch(fetchAssumedAnimalClasses());
        this.props.dispatch(fetchAttributes({ type: 'divisions' }));
        break;
      }
      default: {
        break;
      }
    }

    if (this.props.manage.activeSettingsTab !== tab) {
      await this.props.dispatch({ type: 'SET_SETTINGS_ACTIVE_TAB', payload: tab });
    }
  }

  toggleDefaultTab() {
    const { manage } = this.props.realm.currentApp;

    // Find Settings config
    const { tabs } = manage.find(({ tag }) => tag === 'settings');
    if (tabs?.length > 0) {
      const { activeSettingsTab } = this.props.manage;
      const activeTab = tabs.find(({ tabId }) => tabId === activeSettingsTab) || tabs[0];
      const { tabId, tabTag } = activeTab;

      this.toggleTab(tabId, tabTag);
    }
  }

  removeInvitation(id) {
    this.props.dispatch(removeInvite(id));
    this.props.dispatch(fetchInvitations());
  }

  renderTabs() {
    const { currentApp } = this.props.realm;

    // Look for the settings tab
    const settings = currentApp.manage.find(({ tag }) => tag === 'settings');

    // Add only tabs for realm settings tabs
    if (settings) {
      return settings.tabs.map(({ caption, iconName, tabId, tabTag }) => (
        <FormTab
          key={tabTag}
          iconName={iconName}
          tabTag={tabTag}
          caption={caption}
          tabId={tabId}
          activeTab={this.props.manage.activeSettingsTab}
          toggle={this.toggleTab}
        />
      ));
    }

    return [];
  }

  setInvitationModal(value) {
    this.setState({ isInvitationModalOpen: value });
  }

  onAdd(tag) {
    if (tag === 'sampling_plans') this.props.history.push('/home/settings/samplingplans/editor');
  }

  onChange() {
    const { searchValue } = this.state;
    const { filters } = this.props;
    const params = { search_value: searchValue };

    this.props.dispatch(setUserParams(params)).then(({ payload }) => {
      this.props.dispatch(fetchUsers(payload, filters));
    });
  }

  onEdit(tag, id) {
    if (tag === 'sampling_plans')
      this.props.history.push(`/home/settings/samplingplans/editor/${id}`);
  }

  handleSearchChange(event) {
    // Update search value
    const searchValue = event.target.value;
    this.setState({ searchValue });

    const enter_button_code = 13;
    if (event.keyCode === enter_button_code) this.onChange();
  }

  toggleFilter() {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  }

  onIntervalDateChange = async (value) => {
    const { interval } = this.props.enterprises.selectedInterval;
    this.onIntervalChange(interval, value);
  };

  onIntervalChange = async (interval, date_value) => {
    let date;
    let intervalSelection;

    if (interval?.tag === 'all_time') {
      const allTimeRange = await this.props.dispatch(fetchAssumedAnimalClassValueRanges());
      date = moment(allTimeRange.to_date).endOf('month').format('YYYY-MM-DD');
      intervalSelection = { from_date: allTimeRange.from_date, transaction_date: date, interval };
    } else {
      date = this.getDateValue(interval?.tag, date_value);
      intervalSelection = { transaction_date: date, interval };
    }

    await this.props.dispatch({
      type: 'SET_ENTERPRISE_SELECTED_INTERVAL',
      payload: intervalSelection,
    });
  };

  getDateValue = (tag, value) => {
    let unitType;
    switch (tag) {
      case 'year':
      case 'half_year':
      case 'quarter': {
        unitType = 'quarter';
        break;
      }
      case 'month':
      default: {
        unitType = 'month';
      }
    }

    return moment(value).endOf(unitType).format('YYYY-MM-DD');
  };

  render() {
    const { isFilterOpen, isInvitationModalOpen, searchValue } = this.state;
    const {
      users,
      roles,
      authorised,
      authResponseMessage,
      userResponseMessage,
      responseMessage,
      invitations,
      documentDefs,
      outputMappings,
      orgs,
      costcodes,
      sampling_plans,
      activeSettingsTab,
    } = this.props.manage;
    const { activities } = this.props.activities;
    const { transaction_intervals } = this.props.attributes;
    const { selectedInterval } = this.props.enterprises;
    const intervalOptions = isEmpty(transaction_intervals)
      ? []
      : transaction_intervals
          .filter(({ tag }) => tag !== 'day')
          .map((interval) => ({
            ...interval,
            value: interval.id,
            label: interval.name,
            tag: interval.tag,
          }));

    // Add All time option to intervals
    if (!isEmpty(intervalOptions))
      intervalOptions.push({
        id: 'all_time',
        value: 'all_time',
        tag: 'all_time',
        label: 'All Time',
        name: 'All Time',
      });

    return (
      <div className="p-0 h-100 border-bottom border-corporate overflow-auto">
        <div className="bg-light p-0 h-100">
          <ResponseMessage responseMessage={authResponseMessage} />

          {authorised && (
            <Row className="p-0 m-0 h-100">
              <Col className="p-0 m-0 h-100 verticalnav settings">
                <Nav vertical className="mt-2">
                  {this.renderTabs()}
                </Nav>
              </Col>
              <Col className="h-100 overflow-auto">
                <TabContent
                  activeTab={activeSettingsTab}
                  className="border-bottom border-corporate"
                >
                  <TabPane tabId="1" className="mb-2 mt-2">
                    <ResponseMessage responseMessage={userResponseMessage} colour="text-success" />
                    <UsersToolbar
                      filterClick={this.toggleFilter}
                      handleSearchChange={this.handleSearchChange}
                      openModal={this.setInvitationModal}
                      searchValue={searchValue}
                      users={users || []}
                    />
                    <UsersFilter isFilterOpen={isFilterOpen} />
                    <Invitation setModal={this.setInvitationModal} isOpen={isInvitationModalOpen} />
                    <UsersLsv
                      history={this.props.history}
                      responseMessage={userResponseMessage}
                      rows={users || []}
                    />
                  </TabPane>

                  <TabPane tabId="2" className="mb-2 p-1">
                    <InvitationsLsv
                      removeInvitation={this.removeInvitation}
                      rows={invitations || []}
                    />
                  </TabPane>

                  <TabPane tabId="3" className="mb-2 p-1">
                    <RolesLsv rows={roles || []} />
                  </TabPane>

                  <TabPane tabId="4" className="mb-2 p-1">
                    <div className="text-center p-3">todo</div>
                  </TabPane>

                  <TabPane tabId="5" className="mb-2 p-1">
                    <SoilTemplates />
                  </TabPane>

                  <TabPane tabId="6" className="mb-2 p-1">
                    <OrgsLsv
                      checkAccess={this.checkAccess}
                      rows={orgs || []}
                      onRefresh={() => {
                        this.props.dispatch(fetchOrgs());
                      }}
                    />
                  </TabPane>

                  <TabPane tabId="7" className="mb-2 p-1">
                    <DocumentDefsLsv rows={documentDefs || []} />
                  </TabPane>

                  <TabPane tabId="8" className="mb-2 p-1">
                    <OutputMappingsLsv rows={outputMappings || []} />
                  </TabPane>

                  <TabPane tabId="9" className="mb-2 p-1">
                    <CostcodesLsv rows={costcodes || []} />
                  </TabPane>

                  <TabPane tabId="10" className="mb-2 p-1">
                    <div className="border-bottom border-corporate bg-light p-1 rounded">
                      <PageTitleH5 iconName="comment" title="Activity" />
                    </div>
                    <ActivitiesLsv
                      rows={activities || []}
                      pagination
                      onLoadMore={this.loadMoreActivities}
                    />
                  </TabPane>

                  <TabPane tabId="11" className="mb-2 p-1">
                    <div className="border-bottom border-corporate bg-light p-1 rounded">
                      <PageTitleH5 iconName="table" title="Report Metrics" />
                    </div>
                    <ReportMetrics history={this.props.history} />
                  </TabPane>

                  <TabPane tabId="12" className="mb-2 p-1">
                    <div className="border-bottom border-corporate bg-light p-1 rounded">
                      <PageTitleH5 iconName="chart-scatter-3d" title="Sampling Plans" />
                    </div>
                    {responseMessage && <ResponseMessage responseMessage={responseMessage} />}
                    <GenericToolbar
                      onAddNew={() => this.onAdd('sampling_plans')}
                      addButtonText="Add Sampling Plan"
                    />
                    <GenericLsv
                      actions={[
                        { func: (id) => this.onEdit('sampling_plans', id), iconName: 'edit' },
                      ]}
                      controls={samplingPlanControls}
                      iconName="chart-scatter-3d"
                      emptyCaption="No Sampling Plans found"
                      onClick={(id) => this.onEdit('sampling_plans', id)}
                      rows={sampling_plans}
                    />
                  </TabPane>
                  <TabPane tabId="13" className="mb-2 p-1">
                    <div className="border-bottom border-corporate bg-light p-1 rounded">
                      <PageTitleH5 iconName="table" title="Custom Attributes" />
                    </div>
                    <CustomAttributes />
                  </TabPane>
                  <TabPane tabId="17" className="mb-2 p-1">
                    <AdminBenchmarking />
                  </TabPane>
                  <TabPane tabId="14" className="mb-2 p-1">
                    <div className="border-bottom border-corporate bg-light p-1 rounded mt-2 d-flex justify-content-between">
                      <PageTitleH5 iconName="paw" title="Benchmark Values" />
                      <div className="mt-1">
                        <FilterIntervalDatePicker
                          intervalOptions={intervalOptions}
                          interval={selectedInterval.interval}
                          transaction_date={selectedInterval.transaction_date}
                          onIntervalDateChange={this.onIntervalDateChange}
                          onIntervalChange={this.onIntervalChange}
                          popperPlacement="bottom-end"
                        />
                      </div>
                    </div>
                    <LivestockAssumptions />
                  </TabPane>
                  <TabPane tabId="15" className="mb-2 p-1">
                    <div className="border-bottom border-corporate bg-light p-1 rounded">
                      <PageTitleH5 iconName="people-group" title="Analysis Groups" />
                    </div>
                    <AnalysisGroups />
                  </TabPane>
                  <TabPane tabId="16" className="mb-2 p-1">
                    <div className="border-bottom border-corporate bg-light p-1 rounded">
                      <PageTitleH5 iconName="timeline" title="Analysis Periods" />
                    </div>
                    <AnalysisPeriods />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

const mapStoreToProps = ({ attributes, activities, enterprises, manage, realm, office }) => ({
  attributes,
  activities,
  enterprises,
  manage,
  realm,
  office,
});

export default connect(mapStoreToProps)(withContainerError(Settings));
