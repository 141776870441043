import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';
import Icon from 'jsx/components/core/icons/Icon';
import ChartBase from './ChartBase';

class TrendAnalysisChart extends ChartBase {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      settings: {}
    };
  }

  componentDidMount() {
    this.buildChartData();
  }

  componentDidUpdate() {
    const { row } = this.props;
    const { settings } = this.state;

    const newSettings = this.buildChartSettings(row);

    const changed = (JSON.stringify(settings) !== JSON.stringify(newSettings));
    if (changed) {
      this.setState({settings: newSettings});
      this.buildChartData(newSettings);
    }
  }

  render() {
    const chartFontSize = 12;
    const { title, config } = this.props;
    const { chartData, settings } = this.state;

    return (
      <div style={{height: '100%', width: '100%', fontSize: chartFontSize}} className="p-1 m-1 border border-lightgray rounded bg-light">
        <div className="text-center">
          {title}
          <Icon
            id="gear"
            as="button"
            name="upload"
            title="Load in Top Chart"
            className="ml-2 p-0"
            onClick={() => this.props.setMainChart(settings)}
          />
        </div>
        <ResponsiveContainer width="100%" height="95%" key={uuidv4()}  minWidth={400} minHeight={300}> 
          <ComposedChart
            // width={500}
            height={200}
            data={chartData}
            syncId={(config.syncTip ? 'anyId' : null)}
            key={uuidv4()}
            margin={{
              top: 5,
              right: 5,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              style={{fontSize: chartFontSize}}
            />

            <YAxis
              yAxisId="left"
              style={{fontSize: chartFontSize}}
              domain={['auto', 'auto']}
              tickFormatter={(value) => this.tickFormatter(value,settings?.leftAxis?.unit_attributes)}
            />

            <YAxis orientation="right" yAxisId="right" style={{fontSize: chartFontSize}}/>

            {config.showTooltip && (
              <Tooltip
                style={{fontSize: chartFontSize}}  
                formatter={(value) => this.tickFormatter(value,settings?.leftAxis?.unit_attributes)}
              />
            )}

            <Legend wrapperStyle={{fontSize: chartFontSize}}/>

            {this.renderChart()}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapStoreToProps = ({ trend_analysis, warehouse, analysis_groups, probe }) => ({
  warehouse,
  trend_analysis,
  analysis_groups,
  probe
});

export default connect(mapStoreToProps)(TrendAnalysisChart);
