import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';

import FormBase from '../../../../core/form/components/FormBase';
import Listview from '../../../../core/form/components/Listview';

import TextDuration from '../../../../core/form/components/TextDuration';


class PropertyRoundPanelBatches extends FormBase {
  constructor(props) {
    super(props)

    this.renderHeaders = this.renderHeaders.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  renderHeaders(headers) {
    return headers.map((header, index) =>
      <th key={index} className={header.classes}>{header.caption}</th>
    );
  }

  renderRow(headers, batch) {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'started':
        case 'completed': return (
          <td className={header.classes} key={index}>
            {(batch[header.field] ? moment(batch[header.field]).format('YYYY-MM-DD hh:mm') : '-')}
          </td>
        )
        case 'duration': return (
          <td className={header.classes} key={index}><TextDuration
            start={batch.started}
            end={batch.completed}
          /></td>
        )
        case 'createdby_name': return (
          <td className={header.classes} key={index}>{batch.createdby.name}</td>
        )
        default: return (
          <td className={header.classes} key={index}>{batch[header.field]}</td>
        )
      }
    });
    return tableTd;
  }

  render() {
    const {
      batches
    } = this.props.properties;

    const {
      currentPropertyRound
    } = this.props.properties;

    const headers = [
      { caption: 'Key', field: 'key', classes: 'text-left' },
      { caption: 'Status', field: 'last_message', classes: 'text-left' },
      { caption: 'Version', field: 'version', classes: 'text-left' },
      { caption: 'Start Date', field: 'started', classes: 'text-center' },
      { caption: 'End Date', field: 'completed', classes: 'text-center' },
      { caption: 'Duration', field: 'duration', classes: 'text-center' },
      { caption: 'Created By', field: 'createdby_name', classes: 'text-center' },
    ];

    const tableHeadTh = this.renderHeaders(headers);

    let tableBodyTr = (<tr></tr>);
    let rows = batches.filter(batch => batch.property_round_id === currentPropertyRound.id);
    if (rows?.length > 0) {
      tableBodyTr = rows.map((round, index) =>
        <tr key={index} style={{ cursor: 'pointer' }}>
          {this.renderRow(headers, round)}
        </tr>
      );
    }

    const iconName = 'layer-group';
    const emptyCaption = 'No batches found for property round.';

    return (
      <React.Fragment>
        <Listview
          rows={rows}
          tableHeadTh={tableHeadTh}
          tableBodyTr={tableBodyTr}
          iconName={iconName}
          emptyCaption={emptyCaption}
        />
      </React.Fragment>
    )
  }

}


const mapStoreToProps = (store) => {
  return {
    properties: store.properties,
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(PropertyRoundPanelBatches);