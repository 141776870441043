import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { Button } from 'reactstrap';
import moment from 'moment';

import FormBase from '../../form/components/FormBase';
import Listview from "../../form/components/Listview";
import TextDuration from '../../form/components/TextDuration';
import SpinnerButton from '../../form/components/SpinnerButton';
import DirectDownload from '../../form/components/DirectDownload';
import PipelineProgress from '../components/PipelineProgress';


import {
  executePipeline,
  downloadPipelinePack,
  removePipelineDocuments,
  updatePipeline
} from '../actions';


class PipelinesPanel extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      data: {}
    })

    this.executePipeline = this.executePipeline.bind(this);
    this.resetPipeline = this.resetPipeline.bind(this);
    this.downloadPipelinePack = this.downloadPipelinePack.bind(this);

    this.renderHeaders = this.renderHeaders.bind(this);
    this.renderRows = this.renderRows.bind(this);
  }

  componentDidUpdate() {
  }

  async executePipeline(pipeline_id) {
    const confirmed = window.confirm(`This will remove all previous results permanently and rerun the script. Continue?`);
    if (confirmed) {
      // Remove all related documents
      await this.props.dispatch(removePipelineDocuments(pipeline_id));
      this.props.dispatch({ type: 'REMOVE_PIPELINE_DOCUMENTS_FULFILLED', payload: { pipeline_id } });
      this.props.dispatch(executePipeline(pipeline_id));
    }
  }

  async resetPipeline(pipeline_id) {
    const confirmed = window.confirm(`WARNING: This will reset the pipeline. Only reset the pipeline if you believe the service has failed or is not running. Continue?`);
    if (confirmed) {
      this.props.dispatch(updatePipeline(pipeline_id, {
        status_key: 'new',
        last_message: 'Pipeline reset manually'
      }));
    }
  }

  async downloadPipelinePack(pipeline_id, type, onProgressChange) {
    return await this.props.dispatch(downloadPipelinePack(pipeline_id, type, onProgressChange))
  }

  renderHeaders() {
    const headers = [
      { caption: 'Name', field: 'name', classes: 'text-left' },
      { caption: 'Progress', field: 'progress', classes: 'text-center' },
      { caption: 'Status', field: 'status_key', classes: 'text-left' },
      { caption: 'Last Message', field: 'last_message', classes: 'text-left' },
      { caption: 'Started', field: 'started', classes: 'text-center text-nowrap' },
      { caption: 'Duration', field: 'duration', classes: 'text-center text-nowrap' },
      { caption: 'Completed', field: 'completed', classes: 'text-center text-nowrap' },
      { caption: 'Actions', field: 'actions', classes: 'text-center' },
      { caption: 'Downloads', field: 'downloads', classes: 'text-center text-nowrap' },
    ]

    return {
      headers,
      tableHeadTh: headers.map((header, index) =>
        <th key={index} className={header.classes}>{header.caption}</th>
      )
    }
  }

  renderRows(headers, pipeline, actions, downloads) {

    let actions_value = (<div className="text-secondary">-</div>)
    if (actions?.length > 0) {
      actions_value = actions.map((action, index) => {
        switch (action.buttonType) {
          case 'spinner':
            return (<SpinnerButton
              buttonColor={action.color ?? 'success'}
              className="ml-2 mr-2"
              key={index}
              onClick={() => action.onClick(pipeline.id)}
              isSpinning={pipeline.isSpinning}
              caption={action.caption}
              disabled={action.disabled}
            />)
          default:
            return (<Button
              key={index}
              color={action.color ?? 'success'}
              onClick={() => action.onClick(pipeline.id)}
              className="ml-2 mr-2"
              size="sm"
              disabled={!pipeline.isSpinning}
            >{action.caption}</Button>)
        }
      })
    }

    let downloads_value = (<div className="text-secondary">-</div>)
    if (downloads?.length > 0) {
      downloads_value = downloads.map((download, index) => (
        <div className='mr-2' key={index}>
          <DirectDownload
            classNames="p-0"
            buttonType="primary"
            size='sm'
            width={100}
            caption={download.caption}
            handleDownload={(onProgressChange) => download.onClick(pipeline.id, onProgressChange)}
            showLabel={false}
            showIcon={true}
          />
        </div>
      ))
    }

    const tableTd = headers.map((header, index) => {
      const classes = `${header.classes}`;
      switch (header.field) {
        case 'started':
        case 'completed': return (
          <td className={header.classes} key={index}>
            {(pipeline[header.field] ? moment(pipeline[header.field]).format('YYYY-MM-DD hh:mm') : '-')}
          </td>
        )
        case 'duration': return (
          <td className={header.classes} key={index}><TextDuration
            start={pipeline.started}
            end={pipeline.completed}
          /></td>
        )
        case 'progress': return (
          
          <td key={index} className={classes}>{<div className='d-flex'><PipelineProgress key={index} caption={pipeline.short_name} status={pipeline.status_key} /></div>}</td>
        )
        case 'actions': return (
          <td key={index} className={classes}><div className='d-flex justify-content-between'>{actions_value}</div></td>
        )
        case 'downloads': return (
          <td key={index} className={classes}><div className='d-flex justify-content-between'>{downloads_value}</div></td>
        )
        default: return (
          <td key={index} className={classes}>{pipeline[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  render() {
    const {
      pipeline
    } = this.props;

    const {
      headers,
      tableHeadTh
    } = this.renderHeaders();

    const actions = [
      { caption: 'Execute', buttonType: 'spinner', color: 'success', onClick: (id) => { this.executePipeline(id) }, disabled: !this.checkAccess('roundExecuteSubsampling') },
      { caption: 'Reset', color: 'danger', onClick: (id) => { this.resetPipeline(id) }, disabled: !this.checkAccess('roundExecuteSubsampling') }
    ]

    const downloads = [
      { caption: 'Input Pack', onClick: (id, onProgressChange) => this.downloadPipelinePack(id, 'scans', onProgressChange) },
      { caption: 'Output Pack', onClick: (id, onProgressChange) => this.downloadPipelinePack(id, 'results', onProgressChange) },
      { caption: 'VWC Pack', onClick: (id, onProgressChange) => this.downloadPipelinePack(id, 'vwc', onProgressChange) }
    ]

    const haveRows = (pipeline && Object.keys(pipeline).length > 0);
    let tableBodyTr = (<tr></tr>);
    let pipelineRows = []
    if (haveRows) {
      pipelineRows = Object.keys(pipeline).map(key => pipeline[key]).filter(row => row?.id).sort((a, b) => a.sequence - b.sequence)
      tableBodyTr = pipelineRows.map((row, index) =>
        <tr key={index} style={{ cursor: "pointer" }}>
          {this.renderRows(headers, row, actions, downloads)}
        </tr>
      );
    }

    const iconName = 'bezier-curve';
    const emptyCaption = 'No pipelines found for this round.';

    return (
      <Fragment>
        <Listview
          rows={pipelineRows}
          tableHeadTh={tableHeadTh}
          tableBodyTr={tableBodyTr}
          iconName={iconName}
          emptyCaption={emptyCaption}
        />
      </Fragment>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(PipelinesPanel);
