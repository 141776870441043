import { allowed, defaults } from '../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    mode: 'normal',
    breadcrumbs: [],
    routeNames: {},
    currentApp: {
      id: null,
      features: [],
    },
    colours: [
      '#9561e2',
      '#3490dc',
      '#4dc0b5',
      '#f6993f',
      '#38c172',
      '#6574cd',
      '#ffed4a',
      '#e3342f',
      '#f66D9b',
      '#9561e2',
      '#000000',
    ],
  },
  action
) {
  // Set expected reducer types
  const types = ['REALM', 'MODE', 'BREADCRUMB', 'ROUTE'];

  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'SET_REALM_FULFILLED': {
      return {
        ...state,
        currentApp: action.payload,
      };
    }

    case 'FETCH_MODE_FULFILLED': {
      return {
        ...state,
        mode: action.payload.mode,
      };
    }
    case 'SET_BREADCRUMB_FULFILLED': {
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    }
    case 'APPEND_BREADCRUMB_FULFILLED': {
      let { breadcrumbs } = state;

      // If top level, replace the whole thing
      if (action.payload.isRoot) {
        breadcrumbs = [];
        breadcrumbs.push(action.payload);
      }

      // Ensure same crumb is not added twice
      const lastIdx = breadcrumbs.length - 1;
      if (breadcrumbs.length > 0 && breadcrumbs[lastIdx].caption !== action.payload.caption) {
        breadcrumbs.push(action.payload);
      }

      return {
        ...state,
        breadcrumbs,
      };
    }
    case 'FETCH_ROUTE_NAMES_FULFILLED':
      return {
        ...state,
        routeNames: action.payload,
      };
    default:
      break;
  }
  return state;
}
