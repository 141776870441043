import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';

import FormBase from '../../../core/form/components/FormBase';
import Icon from 'jsx/components/core/icons/Icon';
import PropertySearchSource from '../components/properties/PropertySearchSource';

import { joinUrl, splitUrl } from '../lib/propertySearchUtils';

class PropertySearchSources extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      map: null,
      mapStyles: [
        { id: 'mapbox/outdoors-v11', name: 'Outdoors' },
        { id: 'mapbox/satellite-v9', name: 'Satellite' },
        { id: 'mapbox/dark-v10', name: 'Dark' },
        { id: 'mapbox/light-v10', name: 'Light' },
        { id: 'mapbox-map-design/ckhqrf2tz0dt119ny6azh975y', name: '3D Terrain' },
      ],
      currentStyle: '',
    };

    this.setMapStyle = this.setMapStyle.bind(this);
    this.setSourceVisibility = this.setSourceVisibility.bind(this);
    this.setLayerProperty = this.setLayerProperty.bind(this);
    this.setLayerZoomRange = this.setLayerZoomRange.bind(this);
    this.selectSource = this.selectSource.bind(this);
    this.sortSources = this.sortSources.bind(this);
  }

  componentDidMount() {
    this.setState({ currentStyle: this.props.defaultMapStyle });
  }

  componentDidUpdate() {
    if (this.props.map && !this.state.map) {
      this.setState({ map: this.props.map });
    }
  }

  setMapStyle(event) {
    const { map } = this.state;

    var layerId = event.target.value;
    map.setStyle(`${this.props.mapStylePath}/${layerId}`);

    this.setState({
      currentStyle: layerId,
    });
  }

  setSourceVisibility(source, show) {
    source.layers.map((layer) => {
      this.setLayerProperty(layer, 'layout', 'visibility', show ? 'visible' : 'none');
      return false;
    });
  }

  setLayerProperty(layer, group, property, value) {
    const { map } = this.state;

    const { selectedSources } = this.props.properties;

    switch (group) {
      case 'layout':
        map.setLayoutProperty(layer.id, property, value);
        break;
      case 'paint':
        map.setPaintProperty(layer.id, property, value);
        break;
      case 'styles':
        const source = map.getSource(layer.source);

        const tile_source = splitUrl(source.tiles[0]);
        source.tiles[0] = joinUrl(tile_source.url, {
          ...tile_source.params,
          styles: value,
        });

        // let sourceCacheId = `other:${layer.source}`
        const sourceCacheId = layer.source;
        const sourceCache = map.style.sourceCaches[sourceCacheId];
        if (sourceCache) {
          sourceCache.clearTiles();
          sourceCache.update(map.transform);
        }
        map.triggerRepaint();

        break;

      default:
        break;
    }

    const currentSourceIdx = selectedSources.findIndex((source) => source.id === layer.source);

    // Check if we can find a source
    if (currentSourceIdx > -1) {
      const currentSource = selectedSources[currentSourceIdx];

      // Check if we can find the layer
      const currentLayerIdx = currentSource.layers.findIndex((l) => l.id === layer.id);
      if (currentLayerIdx > -1) {
        // Updated the reducer
        selectedSources[currentSourceIdx].layers[currentLayerIdx][group][property] = value;
        this.props.dispatch({ type: 'SET_SELECTED_SOURCES', payload: selectedSources });
      }
    }
  }

  setLayerZoomRange(layer, min, max) {
    const { selectedSources } = this.props.properties;

    // Set zoom range for map
    this.state.map.setLayerZoomRange(layer.id, min, max);

    const currentSourceIdx = selectedSources.findIndex((source) => source.id === layer.source);

    // Check if we can find a source
    if (currentSourceIdx > -1) {
      const currentSource = selectedSources[currentSourceIdx];

      // Check if we can find the layer
      const currentLayerIdx = currentSource.layers.findIndex((l) => l.id === layer.id);
      if (currentLayerIdx > -1) {
        // Updated the reducer
        selectedSources[currentSourceIdx].layers[currentLayerIdx]['minzoom'] = min;
        selectedSources[currentSourceIdx].layers[currentLayerIdx]['maxzoom'] = max;

        this.props.dispatch({ type: 'SET_SELECTED_SOURCES', payload: selectedSources });
      }
    }
  }

  selectSource(selectedSource) {
    this.props.dispatch({
      type: 'SET_SELECTED_SOURCE',
      payload: selectedSource.replace(/ /gi, '_').toLowerCase(),
    });
  }

  async sortSources(source_id, direction) {
    let { selectedSources } = this.props.properties;

    const idx = selectedSources.findIndex((source) => source.id === source_id);

    if (direction === 'up') {
      selectedSources[idx].sequence -= 3;
    } else {
      selectedSources[idx].sequence += 3;
    }

    selectedSources.sort((a, b) => a.sequence - b.sequence);
    selectedSources = selectedSources.map((source, index) => ({
      ...source,
      sequence: index * 2,
    }));

    await this.props.dispatch({ type: 'SET_SELECTED_SOURCES', payload: selectedSources });

    // // Clear and reload maps
    // this.props.loadSources(this.props.map, true);
    this.props.moveLayers();
  }

  render() {
    const { currentStyle, mapStyles } = this.state;

    const { selectedSources } = this.props.properties;

    let sources = (
      <div className="p-5 text-center text-corporate">
        <div>
          <Icon size="3x" name="map-marker-alt" className="text-corporate" />
        </div>
        <div className="mt-3">
          No Layers selected. Goto Library tab and select layers using the plus icon.
        </div>
      </div>
    );

    if (selectedSources.length > 0) {
      sources = selectedSources.map((source, index) => {
        return (
          <PropertySearchSource
            key={index}
            source={source}
            removeSelectedSource={this.props.removeSelectedSource}
            setSourceVisibility={this.setSourceVisibility}
            setLayerProperty={this.setLayerProperty}
            setLayerZoomRange={this.setLayerZoomRange}
            sortSources={this.sortSources}
            index={index}
            selectedLength={selectedSources.length}
          />
        );
      });
    }

    const backgroundOptions = mapStyles.map((mapStyle, index) => {
      return (
        <option key={index} value={mapStyle.id}>
          {mapStyle.name}
        </option>
      );
    });

    return (
      <Fragment>
        <div className="bg-corporate text-white d-flex justify-content-end">
          <Icon name="plus" className="m-2 mr-3" />
        </div>

        <div className="mt-1">
          <small className="ml-4 text-corporate">Background:</small>
          <div className="ml-3 mt-1 mr-3">
            <Input
              type="select"
              className="rounded"
              onChange={this.setMapStyle}
              value={currentStyle}
            >
              {backgroundOptions}
            </Input>
          </div>
        </div>

        <div className="mt-2">{sources}</div>
      </Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    properties: store.properties,
  };
};

export default connect(mapStoreToProps)(PropertySearchSources);
