export const downloadBlob = (blobData, filename) => {
  // Create a URL for the Blob
  const url = URL.createObjectURL(blobData);

  // Create a temporary anchor tag
  const a = document.createElement('a');
  a.href = url;
  a.download = filename; // Specify the file name here

  // Append anchor to body, trigger click, and then remove it
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // Clean up by revoking the Blob URL
  URL.revokeObjectURL(url);
};
