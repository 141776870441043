import React from 'react';

import {
  XAxis,
  Label,
  CartesianGrid,
  Tooltip,
  Scatter,
  YAxis,
  ZAxis,
  ScatterChart,
  Legend,
  Cell,
  ResponsiveContainer
} from 'recharts';

const ChartScatter = (props) => {
  const {
    widget,
    height,
    margin,
    onSelect,
    onSelectPosition,
    filterSelections
  } = props;

  const ToolTipRows = (attributes) => {
    return attributes.rows.map((row, index) => {
      return (<div key={index} className="label">{row.name}: <span className="value">{row.value.toFixed(2)}%</span></div>)
    })
  }

  const ToolTip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="scatter-tooltip">
          <div className="label">Core ID: <span className="value">{payload[0].payload.core_id}</span></div>
          <div className="label">Core Depth: <span className="value">{payload[0].payload.core_position}mm</span></div>
          <div className="label">Model: <span className="value">{payload[0].payload.Model}</span></div>
          <ToolTipRows rows={payload} />
        </div>
      );
    }
    return null;
  };

  const renderYAxis = () => {
    return widget.datasets.map((dataset, index) => {
      return (
        <YAxis
          key={index}
          yAxisId={dataset.series}
          type="number"
          dataKey={widget.dataKeyY}
          name={widget.labelY}
          unit={widget.unitY}
          stroke={dataset.colour}
        >
          <Label angle={-90} value={widget.labelY} position='insideLeft' style={{textAnchor: 'middle'}} />
        </YAxis>
      )
    })
  }

  const renderScatter = () => {
    return widget.datasets.map((dataset,index) => {
      return (
        <Scatter
          key={index}
          yAxisId={dataset.series}
          name={dataset.name}
          data={dataset.data}
          fill={dataset.colour}
          onClick={(e) => {onSelect(parseInt(e.payload.core_id)); onSelectPosition(e.payload.core_position)}} 
        >
          {
            dataset.data.map((entry, index) => (
              <Cell key={index} stroke={filterSelections.includes(parseInt(dataset.data[index].core_id)) ? 'red' : dataset.colour }/>
            ))
          }
        </Scatter>
      )
    })
  }

  return (
    <React.Fragment>
      <ResponsiveContainer height={height}>
        <ScatterChart margin={margin}>
          <ZAxis dataKey="z" name="Core ID" />
          <XAxis type="number" dataKey={widget.dataKeyX} name={widget.labelX} unit={widget.unitX}>
            <Label value={widget.labelX} position='bottom' style={{textAnchor: 'middle'}} />
          </XAxis>
          {renderYAxis()}
          {renderScatter()}
          <Tooltip cursor={{ strokeDasharray: '3 3', stroke: 'red', strokeWidth: 1}} content={<ToolTip />}/>
          <Legend align="left" verticalAlign="bottom" />
          <CartesianGrid strokeDasharray="3 3" />
        </ScatterChart>
      </ResponsiveContainer>
    </React.Fragment>
  )
}

export default ChartScatter;
