import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  client_org_id: {
    ...controlDefaults,
    name: 'client_org_id',
    type: 'select',
    caption: 'Client',
    disabled: false,
    options: [],
    validationRules: {
      isRequired: true,
    },
  },
  name: {
    ...controlDefaults,
    name: 'name',
    type: 'text',
    caption: 'Name',
    disabled: false,
    validationRules: {
      isRequired: true,
    },
  },
  enabled: {
    ...controlDefaults,
    name: 'enabled',
    type: 'select',
    caption: 'Enabled',
    validationRules: {},
    defaultValue: true,
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
  },
  jobno: {
    ...controlDefaults,
    name: 'jobno',
    type: 'text',
    caption: 'Job No',
    disabled: false,
    validationRules: {
      isRequired: true,
    },
  },
  costcode: {
    ...controlDefaults,
    name: 'costcode',
    type: 'hidden',
    caption: 'Phase Code',
    disabled: false,
    description: 'Leave this field blank to enable the Timesheet Phase Code lookup combo',
    validationRules: {
      isRequired: true,
    },
  },
  phasecodes: {
    ...controlDefaults,
    name: 'phasecodes',
    type: 'text',
    caption: 'Phase Codes',
    disabled: false,
    description:
      'List of comma-delimited phase codes for this cost code. Requires atleast 1 phase code. Whitespaces will be removed.',
    validationRules: {
      isRequired: true,
    },
  },
  update_phasecodes: {
    ...controlDefaults,
    name: 'update_phasecodes',
    type: 'text',
    placeholder: 'Add Phase Codes',
    disabled: false,
    description:
      'List of comma-delimited phase codes for this cost code. Whitespaces will be removed.',
    validationRules: {
      isRequired: true,
    },
  },
  attributes: {
    ...controlDefaults,
    name: 'attributes',
    type: 'json',
    caption: 'Attributes',
    disabled: false,
    validationRules: {
      isRequired: true,
      isArray: true,
    },
  },
};
