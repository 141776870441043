import { controlDefaults } from 'jsx/components/core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  period_id: {
    ...controlDefaults,
    placeholder: 'Analysis Period',
    name: 'period_id',
    fieldName: 'period_id',
    type: 'select',
    caption: 'Analysis Period',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    options: [],
    showInEditor: true,
    showInListview: true,
  },
  type_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'type_id',
    name: 'type_id',
    type: 'select',
    caption: 'Schedule type',
    validationRules: {
      isRequired: true,
    },
    options: [
      {id: 'all_properties', name: 'All metrics in Standard Profit Probe for ALL Properties with status Analysis Finalised'},
      {id: 'selected_properties', name: 'All metrics for Selected Eligible Properties with status Analysis Finalised'},
      {id: 'missing_probes', name: 'All metrics for Eligible Properties with MISSING probes for selected period'},
      {id: 'selective_group', name: 'Benchmark Group Specific'}
    ],
    showInEditor: true,
    showInListview: true,
  },
  group_id: {
    ...controlDefaults,
    name: 'group_id',
    fieldName: 'group_id',
    type: 'select',
    caption: 'Benchmark Group',
    showInEditor: true,
    showInListview: true,
    options: []
  },
  property_id: {
    ...controlDefaults,
    name: 'property_id',
    fieldName: 'property_id',
    type: 'select',
    caption: 'Eligible Properties',
    showInEditor: true,
    showInListview: true,
    options: []
  },
  ignore_cache: {
    ...controlDefaults,
    name: 'ignore_cache',
    fieldName: 'ignore_cache',
    type: 'switch',
    showInEditor: true,
    showInListview: true,
    options: [],
    value: false
  }
};
