import { defaults } from '../../../../lib/genericReducer';
export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    subscriptions: [],
    responseMessage: null,
  },
  action,
) {
  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_ORG_SUBSCRIPTIONS_FULFILLED':
    case 'UPDATE_ORG_SUBSCRIPTIONS_FULFILLED': {
      return {
        ...state,
        subscriptions: action.payload,
      };
    }
    default:
      break;
  }
  return state;
}
