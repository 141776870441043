import React from 'react';
import { Button } from 'reactstrap';
import FormInput from '../../../../core/form/components/FormInput';

const PropertyResultsToolbar = (props) => {
  const { controls, handleChange, onClick } = props;

  return (
    <React.Fragment>
      <div className="bg-light p-2 mb-2 d-flex justify-content-end">
        <FormInput className="mr-2" handleChange={handleChange} control={controls.method} />
        <FormInput className="mr-2" handleChange={handleChange} control={controls.template_id} />
        <FormInput className="mr-2" handleChange={handleChange} control={controls.baseline_id} />
        <Button className="ml-2" size="sm" color="success" onClick={onClick}>
          Get Results
        </Button>
      </div>
    </React.Fragment>
  );
};

export default PropertyResultsToolbar;
