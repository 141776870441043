import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Row, Col } from 'reactstrap';
import Switch from 'react-switch';
import Icon from 'jsx/components/core/icons/Icon';
import { fetchAttributes } from 'jsx/components/modules/portrait/actions/attributes';

import FormBase from '../../core/form/components/FormBase';

import { updateReportVersion } from '../../modules/portrait/actions/reports';

class ReportFilterAttributeModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      title: 'Filter Attributes',
    };
  }

  async componentDidMount() {
    await this.props.dispatch(fetchAttributes({ type: 'divisions' }));
  }

  async handleFilteringChange(filterType, checked) {
    const { report_id, version } = this.props.reportVersion;

    const data = { filter_attributes: this.props.reportVersion?.filter_attributes ?? {} };
    data.filter_attributes[filterType] = checked;

    await this.props.dispatch(updateReportVersion(report_id, version, data));
    this.props.loadReportVersion();
  }

  onCancel() {
    this.props.setModal(false);
  }

  render() {
    const { title } = this.state;
    const { filter_attributes } = this.props.reportVersion;
    const { isOpen } = this.props;
    const { divisions } = this.props.attributes;
    const { responseMessage } = this.props.reports;
    const iconName = 'filter';

    const filteredDivisions = divisions.filter(
      ({ tag }) => tag !== 'horticulture' && tag !== 'sugar',
    );

    return (
      <Modal isOpen={isOpen} size="lg" onClosed={this.props.onModalClose}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <h4 className="text-corporate">Exclusions</h4>
          <Row>
            <Col
              xs={3}
              className="d-flex pt-2 pb-1 justify-content-between border-bottom border-right"
            >
              <Label className="mr-2 font-weight-bold">Enterprise:</Label>
              <Switch
                className="mt-1"
                height={18}
                width={42}
                onChange={(checked) => this.handleFilteringChange('excludeEnterprise', checked)}
                checked={filter_attributes?.excludeEnterprise ?? false}
              />
            </Col>
            <Col
              xs={3}
              className="d-flex pt-2 pb-1 justify-content-between border-bottom border-right"
            >
              <Label className="mr-2 font-weight-bold">Division:</Label>
              <Switch
                className="mt-1"
                height={18}
                width={42}
                onChange={(checked) => this.handleFilteringChange('excludeDivision', checked)}
                checked={filter_attributes?.excludeDivision ?? false}
              />
            </Col>
          </Row>
          {!isEmpty(filteredDivisions) && (
            <>
              <h4 className="text-corporate mt-4">Mandatory Divisions</h4>
              <Row>
                {filteredDivisions.map(({ id, name }) => (
                  <Col
                    key={id}
                    xs={3}
                    className="d-flex pt-2 pb-1 justify-content-between border-bottom border-right"
                  >
                    <Label className="mr-2 font-weight-bold">{name}:</Label>
                    <Switch
                      className="mt-1"
                      height={18}
                      width={42}
                      onChange={(checked) =>
                        this.handleFilteringChange(`mandatoryDivision${name}`, checked)
                      }
                      checked={
                        (filter_attributes && filter_attributes[`mandatoryDivision${name}`]) ||
                        false
                      }
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, reports }) => ({
  attributes,
  reports,
});

export default connect(mapStoreToProps)(ReportFilterAttributeModal);
