import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    authorised: true,
    authResponseMessage: null,
    fetching: false,
    fetched: false,
    responseMessage: null,
    errorResponseMessage: null,
    error: {
      type: null,
      message: null,
    },
    filename: null,
  },
  action,
) {
  // Set expected reducer types
  const types = ['IMPORT'];
  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'IMPORT_CLIENT_TRANSACTIONS_FULFILLED': {
      const updateItems = {};
      if (action.payload.data.message) {
        const { message } = action.payload.data;
        updateItems.responseMessage = message;
      }

      return {
        ...updatedState,
        ...updateItems,
      };
    }
    case 'IMPORT_CLIENT_TRANSACTIONS_REJECTED': {
      const updateItems = { responseMessage: action.payload, errorResponseMessage: action.payload };
      if (action?.data?.filename) updateItems.filename = action.data.filename;

      if (action?.data?.error) updateItems.error = action.data.error;
      else updateItems.error = { type: null, message: action.payload };

      return {
        ...updatedState,
        ...updateItems,
      };
    }
    case 'SET_IMPORT_RESPONSE_MESSAGE': {
      return {
        ...updatedState,
        responseMessage: action.payload,
      };
    }
    case 'CLEAR_IMPORT_RESPONSE_MESSAGE': {
      return {
        ...updatedState,
        responseMessage: null,
      };
    }
    case 'CLEAR_IMPORT_ERROR_RESPONSE_MESSAGE': {
      return {
        ...updatedState,
        errorResponseMessage: null,
      };
    }
    case 'CLEAR_IMPORT_FILENAME': {
      return {
        ...updatedState,
        filename: null,
      };
    }
    case 'CLEAR_IMPORT_ERROR': {
      return {
        ...updatedState,
        error: {
          type: null,
          message: null,
        },
      };
    }
    default:
      break;
  }

  return updatedState;
}
