import Select from 'react-select';
import { Button, Col, Row, Label } from 'reactstrap';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';

const MembersToolbar = ({
  onAddMember = noop,
  onAnalysisGroupChange = noop,
  onOrgChange = noop,
  onPropertyChange = noop,
  selectedAnalysisGroup = null,
  selectedOrg = null,
  selectedProperty = null,
}) => {
  const groups = useSelector((state) => state.analysis_groups.groups.rows) ?? [];
  const filteredGroups = groups.filter(({ is_active }) => is_active);
  /* eslint-disable-next-line */
  const properties = useSelector((state) => state.properties.properties.rows) ?? [];
  const orgs = useSelector((state) => state.manage.orgs) ?? [];
  const analysisGroupOptions = filteredGroups.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const orgOptions = orgs.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const filteredProperties = properties.filter(
    ({ project }) => project.org_id === selectedOrg?.value,
  );
  const propertyOptions = filteredProperties.map(({ id, name, dnc }) => ({
    value: id,
    label: dnc ? `${name} (DNC)` : name,
  }));

  return (
    <Row className="align-items-end">
      <Col xs={3}>
        <Label className="font-weight-bold">Analysis Group</Label>
        <Select
          options={analysisGroupOptions}
          placeholder="Select Analysis Group"
          onChange={onAnalysisGroupChange}
          value={selectedAnalysisGroup}
          isClearable
        />
      </Col>
      <Col xs={3}>
        <Label className="font-weight-bold">Organisation</Label>
        <Select
          options={orgOptions}
          placeholder="Select Organisation"
          onChange={onOrgChange}
          value={selectedOrg}
          isClearable
        />
      </Col>
      <Col xs={3}>
        <Label className="font-weight-bold">Property</Label>
        <Select
          placeholder="Select Property"
          options={propertyOptions}
          onChange={onPropertyChange}
          value={selectedProperty}
          isClearable
        />
      </Col>
      <Col xs={3}>
        <Button color="corporate" onClick={onAddMember}>
          Add Member
        </Button>
      </Col>
    </Row>
  );
};

export default MembersToolbar;
