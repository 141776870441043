import React from 'react';
import ReactDOM from 'react-dom';

import App from './jsx/containers/App.jsx';
import { Provider } from 'react-redux';
import store from './store';

const app = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  app
);
