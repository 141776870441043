import React, { useRef, useState } from 'react';
import { Button, Label, Progress } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const DirectUpload = ({
  caption = 'Upload',
  handleUpload = noop,
  classNames = '',
  width = 450,
  disabled = false,
  showLabel = false,
  schemaType = null,
}) => {
  const inputFileRef = useRef(null);
  const [progressValue, setProgressValue] = useState(0);
  const [uploading, setUploading] = useState(false);

  const handleClick = () => inputFileRef.current.click();

  const handleChange = async (event) => {
    setUploading(true);
    await handleUpload(event, setProgressValue, schemaType);
    inputFileRef.current.value = null;
    setUploading(false);
    setProgressValue(0);
  };

  return (
    <div className={classNames}>
      <input
        style={{ display: 'none' }}
        onChange={handleChange}
        type="file"
        ref={inputFileRef}
        data-testid="file-input" // Use test id as input is hidden. The user interacts via button/label
      />
      {uploading && (
        <Progress style={{ width }} className="m-2 text-center" animated value={progressValue}>
          {progressValue}%
        </Progress>
      )}
      {!showLabel && !uploading && (
        <Button size="sm" color="primary" disabled={disabled} onClick={handleClick}>
          <Icon name="upload" className="mr-2" />
          {caption}
        </Button>
      )}
      {showLabel && (
        <Label
          onClick={handleClick}
          style={{ outline: 'none', border: 'none', background: 'none', padding: 0 }}
          tag="button"
        >
          {caption}
        </Label>
      )}
    </div>
  );
};

export default DirectUpload;
