import { get, put, post, remove } from '../../../../lib/genericAction';

import { apiPropertyUsages } from '../../../../constants/api';

export function fetchPropertyUsages(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_PROPERTY_USAGES`, `${apiPropertyUsages}`, params);
}

export function fetchPropertyUsage(id) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_PROPERTY_USAGE`, `${apiPropertyUsages}/${id}`);
}

export function createPropertyUsage(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_PROPERTY_USAGE`, `${apiPropertyUsages}`, data);
}

export function updatePropertyUsage(data) {
  return async (dispatch) =>
    await put(dispatch, `UPDATE_PROPERTY_USAGE`, `${apiPropertyUsages}/${data.id}`, data);
}

export function removePropertyUsage(id) {
  return async (dispatch) =>
    await remove(dispatch, `REMOVE_PROPERTY_USAGE`, `${apiPropertyUsages}/${id}`);
}
