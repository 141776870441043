import { FormGroup, Input, Label, Row } from 'reactstrap';

const DaysheetApprovalsToolbar = ({ setFilter, filterValues }) => {
  const { showAlreadyApproved, showAwaitingApproval } = filterValues;

  return (
    <div>
      <div className="d-flex justify-content-end bg-light p-2 rounded">
        <Row className="m-1">
          <FormGroup check inline>
            <Input
              id="exampleCheckbox"
              name="checkbox"
              type="checkbox"
              checked={showAwaitingApproval}
              onChange={(event) => setFilter({ showAwaitingApproval: event.target.checked })}
            />
            <Label check for="exampleCheckbox">
              Awaiting approval
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Input
              id="exampleCheckbox"
              name="checkbox"
              type="checkbox"
              checked={showAlreadyApproved}
              onChange={(event) => setFilter({ showAlreadyApproved: event.target.checked })}
            />
            <Label check for="exampleCheckbox">
              Already approved
            </Label>
          </FormGroup>
        </Row>
      </div>
    </div>
  );
};

export default DaysheetApprovalsToolbar;
