import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';

type File = {
  exists: boolean;
  name: string;
};

type UploadDotsProps = {
  files: File[];
};

const UploadDots = ({ files }: UploadDotsProps) => {
  const iconName = 'circle-dot';
  const className = 'd-flex justify-content-center mt-1';

  const icons = files.map((file, index: number) => {
    let iconClass = 'text-danger ml-1 mr-1 rounded-circle border border-danger';
    if (file.exists) {
      iconClass = 'text-success ml-1 mr-1 rounded-circle border border-success';
    }

    return (
      <Icon
        key={index}
        name={iconName}
        className={iconClass}
        style={{ fontSize: 10 }}
        title={file.name}
      />
    );
  });
  return <div className={className}>{icons}</div>;
};

export default UploadDots;
