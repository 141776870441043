import { get, post, put, remove } from '../../../../lib/genericAction';
import {
  apiLookupLocations,
  apiLookupLocationProjects,
  apiOrgPlantAssetProjects,
} from '../../../../constants/api';

// Lookup Locations
export function fetchLookupLocations(params) {
  return (dispatch) => get(dispatch, 'FETCH_LOOKUP_LOCATIONS', `${apiLookupLocations}`, params);
}

export function fetchLookupLocation(id) {
  return (dispatch) => get(dispatch, 'FETCH_LOOKUP_LOCATION', `${apiLookupLocations}/${id}`);
}

export function fetchLookupLocationUsages(id) {
  return (dispatch) => get(dispatch, 'FETCH_LOOKUP_LOCATION_USAGES', `${apiLookupLocations}/usages/${id}`);
}

export function createLookupLocation(data) {
  return (dispatch) => post(dispatch, 'CREATE_LOOKUP_LOCATION', `${apiLookupLocations}`, data);
}

export function updateLookupLocation(data) {
  return (dispatch) =>
    put(dispatch, 'UPDATE_LOOKUP_LOCATION', `${apiLookupLocations}/${data.id}`, data);
}

export function removeLookupLocation(id) {
  return (dispatch) => remove(dispatch, 'REMOVE_LOOKUP_LOCATION', `${apiLookupLocations}/${id}`);
}

// Lookup Location Projects
export function fetchLocationProjectAssociation(params) {
  return (dispatch) =>
    get(dispatch, `FETCH_LOOKUP_LOCATION_PROJECTS`, `${apiLookupLocationProjects}`, params);
}

export function createLocationProjectAssociation(data) {
  return (dispatch) =>
    post(dispatch, `CREATE_LOOKUP_LOCATION_PROJECT`, `${apiLookupLocationProjects}`, data);
}

export function removeLocationProjectAssociation(id) {
  return (dispatch) =>
    remove(dispatch, `REMOVE_LOOKUP_LOCATION_PROJECT`, `${apiLookupLocationProjects}/${id}`);
}

// Org Plant Asset Projects
export function fetchOrgPlantAssetProjects(params) {
  return (dispatch) => {
    if (Object.keys(params).length > 0) {
      dispatch({
        type: 'SET_ASSETS_PARAMS',
        payload: { ...params, locationsSearchTerm: params?.search_value ?? '' },
      });
    }

    return get(dispatch, `FETCH_ORG_PLANT_ASSET_PROJECTS`, `${apiOrgPlantAssetProjects}`, params);
  };
}

export function fetchOrgPlantAssetProject(params) {
  return (dispatch) =>
    get(
      dispatch,
      `FETCH_ORG_PLANT_ASSET_PROJECT`,
      `${apiOrgPlantAssetProjects}/${params.id}`,
      params,
    );
}

export function createOrgPlantAssetProject(data) {
  return (dispatch) =>
    post(dispatch, `CREATE_ORG_PLANT_ASSET_PROJECT`, `${apiOrgPlantAssetProjects}`, data);
}

export function updateOrgPlantAssetProject(data) {
  return (dispatch) =>
    put(dispatch, `UPDATE_ORG_PLANT_ASSET_PROJECT`, `${apiOrgPlantAssetProjects}/${data.id}`, data);
}

export function removeOrgPlantAssetProject(id) {
  return (dispatch) =>
    remove(dispatch, `REMOVE_ORG_PLANT_ASSET_PROJECT`, `${apiOrgPlantAssetProjects}/${id}`);
}
