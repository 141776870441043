import React, { useState } from 'react';
import Listview from '../../core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';

import FormInput from '../../core/form/components/FormInput';
import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';

const PhasecodesLsv = (props) => {
  const { rows, onRemove } = props;

  const [changed, setChanged] = useState(false);

  const renderRows = (headers, row) => {
    return headers.map((header, index) => {
      switch (header.field) {
        case 'edit':
          return (
            <td key={index} className={header.classes}>
              <Icon
                name="pen-to-square"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setEditMode(row, true);
                }}
              />
            </td>
          );
        case 'enabled':
          return (
            <td key={index} className={header.classes}>
              {row.enabled === true ? 'Yes' : 'No'}
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {row[header.field]}
            </td>
          );
      }
    });
  };

  const renderEditRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      let control = {
        id: `${index}-${header.field}`,
        name: header.field,
        value: row[header.field],
      };
      switch (header.field) {
        case 'description':
          control.type = 'text';
          return (
            <td key={index} className={header.classes}>
              {<FormInput handleChange={(event) => handleChange(event, row)} control={control} />}
            </td>
          );
        case 'enabled':
          control.type = 'switch';
          control.descriptionStatus = { switchOn: 'Yes', switchOff: 'No' };
          return (
            <td key={index} className={header.classes}>
              {
                <FormInputSwitch
                  handleChange={(event) => handleSwitchChange(event, row)}
                  control={control}
                />
              }
            </td>
          );
        case 'remove':
          return (
            <td key={index} className={header.classes}>
              <Icon
                name="trash"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onRemove(row.id);
                }}
              />
            </td>
          );
        case 'edit':
          return (
            <td key={index} className={`${header.classes} pt-3`}>
              <Icon
                name="check"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setEditMode(row, false);
                }}
              />
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {row[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const handleChange = (event, row) => {
    const { name, value } = event.target;
    row[name] = value;
    setChanged(!changed);
  };

  const handleSwitchChange = (event, row) => {
    const { name } = event.target;
    row[name] = !row[name];

    setChanged(!changed);
  };

  const setEditMode = (row, value) => {
    row.editMode = value;
    setChanged(!changed);
  };

  const headers = [
    { caption: 'Phase Code', field: 'phase_code', classes: 'text-left' },
    { caption: 'Description', field: 'description', classes: 'text-left' },
    { caption: 'Enabled', field: 'enabled', classes: 'text-right' },
    { caption: '', field: 'remove', classes: 'text-center text-danger pt-3' },
    { caption: '', field: 'edit', classes: 'text-right text-success' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      if (row.editMode) {
        return <tr key={index}>{renderEditRows(headers, row)}</tr>;
      } else {
        return (
          <tr
            key={index}
            onClick={() => {
              setEditMode(row, true);
            }}
            style={{ cursor: 'pointer' }}
          >
            {renderRows(headers, row)}
          </tr>
        );
      }
    });
  }

  const iconName = 'tags';
  const emptyCaption = 'No Phase Codes found for this cost code';

  return (
    <React.Fragment>
      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default PhasecodesLsv;
