import React from 'react';
import { Card, CardTitle, Button, Row, Col, Form } from 'reactstrap';
import FormInput from '../../../../core/form/components/FormInput';
import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';
import DirectDownload from '../../../../core/form/components/DirectDownload';

const PropertyProfile = ({
  onRemove,
  onSave,
  onCancel,
  controls,
  handleChange,
  isValid,
  disableExport,
  openGeometry,
  exportGeoJson,
  openEmailPropertySearch,
  placeMarker,
  propertyRounds,
  propertyAreas,
}) => {
  const cantDeleteProperty = propertyAreas.length > 0 || propertyRounds.length > 0;
  controls.idx.disabled = true;

  return (
    <>
      <Form>
        <Row>
          <Col>
            <FormInput handleChange={handleChange} control={controls.name} />
          </Col>
          <Col sm={2}>
            <FormInput handleChange={handleChange} control={controls.idx} />
          </Col>
          <Col sm={2}>
            <FormInput handleChange={handleChange} control={controls.area_ha} />
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <FormInputSwitch handleChange={handleChange} control={controls.included} />
          </Col>
        </Row>

        <div className="mt-4 border rounded bg-light">
          <div className="d-flex justify-content-between form-section">
            <h5 className="m-0">Locality Information</h5>
          </div>
          <Row className="p-2">
            <Col sm={4}>
              <FormInput handleChange={handleChange} control={controls.address} />
              <small>
                <a onClick={placeMarker} href="/#">
                  Show Property Address Marker on Map
                </a>
              </small>
            </Col>
            <Col sm={2}>
              <FormInput handleChange={handleChange} control={controls.state} />
            </Col>
            <Col sm={2}>
              <FormInput handleChange={handleChange} control={controls.nrm_region} />
            </Col>
            <Col sm={2}>
              <FormInput handleChange={handleChange} control={controls.lga} />
            </Col>
            <Col sm={2}>
              <FormInput handleChange={handleChange} control={controls.nearest_town} />
            </Col>
          </Row>
        </div>

        <div className="mt-4 border rounded bg-light">
          <div className="d-flex justify-content-between form-section">
            <h5 className="m-0">Insights Information</h5>
          </div>
          <div className="p-2">
            <Row>
              <Col sm={12}>
                <FormInput handleChange={handleChange} control={controls.primary_practice} />
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <FormInput handleChange={handleChange} control={controls.avg_rainfall} />
              </Col>
              <Col sm={2}>
                <FormInput handleChange={handleChange} control={controls.soc_sequestration_rate} />
              </Col>
              <Col sm={2}>
                <FormInput handleChange={handleChange} control={controls.net_income_ha} />
              </Col>
              <Col sm={2}>
                <FormInput handleChange={handleChange} control={controls.cost_ha} />
              </Col>
              <Col sm={2}>
                <FormInput handleChange={handleChange} control={controls.baseline_cost_ha} />
              </Col>
            </Row>
          </div>
        </div>

        <Card className="border rounded border-danger bg-light mt-4">
          <CardTitle className="m-0 p-2 bg-danger text-white">Delete Property</CardTitle>
          {cantDeleteProperty && (
            <div className="alert alert-warning mx-3 mt-3 mb-0" role="alert">
              You cannot delete a property that has areas or rounds associated with it.
            </div>
          )}
          <div className="d-flex justify-content-between p-3">
            <div>Deleting a property is permanent and can not be undone. Proceed with caution.</div>
            <Button size="sm" color="danger" onClick={onRemove} disabled={cantDeleteProperty}>
              Delete Property
            </Button>
          </div>
        </Card>

        <div className="d-flex flex-row justify-content-center mt-1">
          <Button disabled={!isValid} className="m-2" size="sm" color="success" onClick={onSave}>
            Save Changes
          </Button>
          <Button className="m-2 border" size="sm" color="light" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="m-2" size="sm" color="primary" onClick={openGeometry}>
            Open in Property Search
          </Button>
          <DirectDownload
            classNames="m-2"
            handleDownload={(onProgressChange) => exportGeoJson(onProgressChange)}
            caption="Download Geometry GeoJson"
            size="sm"
            disabled={disableExport}
            showLabel={false}
            buttonType="primary"
            showIcon={true}
          />
          <Button className="m-2" size="sm" color="secondary" onClick={openEmailPropertySearch}>
            Email Property Search
          </Button>
        </div>
      </Form>
    </>
  );
};

export default PropertyProfile;
