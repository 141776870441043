import { controlDefaults } from 'jsx/components/core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    name: 'name',
    fieldName: 'name',
    type: 'text',
    caption: 'Group Name',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  sequence: {
    ...controlDefaults,
    name: 'sequence',
    fieldName: 'sequence',
    type: 'number',
    caption: 'Sequence',
    validationRules: {
      isRequired: true,
    },
    placeholder: 'Sequence',
    showInEditor: true,
    showInListview: true,
    defaultValue: 0
  },
};
