import React, { useState } from 'react';
import { cloneDeep, noop } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import { Button } from 'reactstrap';
import ReportSection from './ReportSection';
import FormInput from '../../../../core/form/components/FormInputSmall';
import { controls } from '../../forms/report_section';

const Report = (props) => {
  const {
    data,
    attributes = {},
    createSection = noop,
    functions = {},
    currentReloadValue = null,
  } = props;

  const [showCreateSection, setShowCreateSection] = useState(false);
  const [sectionControls, setSectionControls] = useState(controls);

  const renderSections = () => {
    if (data.sections.length === 0)
      return (
        <div className="p-5 text-center">
          <div>
            <Icon size="3x" name="code" className="text-corporate" />
          </div>
          <div className="mt-3">No Report Sections found. You are going to need to add some...</div>
        </div>
      );

    const sections = data.sections.sort((previous, next) => previous.sequence - next.sequence);
    return sections?.map((section) => (
      <ReportSection
        report_id={data.report_id}
        key={`section-${section.id}`}
        headers={data.headers}
        section={section}
        attributes={attributes}
        functions={functions}
        currentReloadValue={currentReloadValue}
      />
    ));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedSectionControls = cloneDeep(sectionControls);
    updatedSectionControls[name].value = value;
    setSectionControls(updatedSectionControls);
  };

  const createLocalSection = async () => {
    await createSection(sectionControls);
    setShowCreateSection(false);
  };

  return (
    <>
      {attributes.reportEditable && (
        <div className="d-flex justify-content-end bg-light rounded border border-lightgray p-1 m-1">
          {showCreateSection && (
            <div className="d-flex">
              <FormInput
                handleChange={handleChange}
                control={sectionControls.caption}
                hideCaption
                className="mr-1"
                style={{ minWidth: 300 }}
              />
              <FormInput
                handleChange={handleChange}
                control={sectionControls.row_type}
                hideCaption
                className="mr-2"
              />
              <FormInput
                handleChange={handleChange}
                control={sectionControls.column_count}
                hideCaption
                className="mr-2"
              />

              <Button color="success" size="sm" onClick={createLocalSection}>
                Save
              </Button>
              <Button color="link" size="sm" onClick={() => setShowCreateSection(false)}>
                Cancel
              </Button>
            </div>
          )}

          {!showCreateSection && (
            <Button color="link" size="sm" onClick={() => setShowCreateSection(true)}>
              Add Section
            </Button>
          )}
        </div>
      )}

      {data.id && <>{renderSections()}</>}
    </>
  );
};

export default Report;
