import React from 'react';
import Pill from './Pill';

type PillRow = {
  id: string;
  name: string;
  colour: string;
};

export interface PillPresenterProps {
  classes?: string | undefined;
  rows: PillRow[] | null;
  selection: string[];
  caption?: string;
  handleDelete?: (id: string) => void;
  colourField?: 'colour';
}

const PillPresenter = ({
  classes,
  rows,
  selection,
  caption,
  handleDelete,
  colourField,
}: PillPresenterProps) => {
  let pills = [] as React.ReactNode[];

  if (rows != null) {
    pills = selection
      .map((item, index) => {
        const pillItem = rows.find((row) => row.id === item);
        if (!pillItem) return false;

        const displayValue = `${caption ? `${caption}: ` : ''}${pillItem.name}`;

        let colour = 'pink';
        if (colourField && pillItem[colourField]) {
          colour = pillItem[colourField];
        }

        const baseClasses = 'd-flex float-left mt-1';
        const finalClasses = classes ? `bg-${colour} ${classes}` : `bg-${colour} ${baseClasses}`;

        return (
          <Pill
            onDelete={handleDelete}
            key={index}
            id={pillItem.id}
            caption={displayValue}
            classes={finalClasses}
          />
        );
      })
      .filter(Boolean);
  }

  return <>{pills}</>;
};

export default PillPresenter;
