import axios from 'axios';
import { get, put, remove, post } from '../../../../lib/genericAction';

import { apiProperties } from '../../../../constants/api.js';
import errorResponse from '../../../../lib/errorResponse.js';

export function removePropertyLot(property_id, lotplan) {
  return (dispatch) =>
    remove(
      dispatch,
      'REMOVE_PROPERTY_LOT',
      `${apiProperties}/${property_id}/lotplan/${encodeURIComponent(lotplan)}`,
      null,
      true
    );
}

export function createPropertyLot(data) {
  return (dispatch) =>
    post(
      dispatch,
      'CREATE_PROPERTY_LOT',
      `${apiProperties}/${data.property_id}/lotplan`,
      data,
      true
    );
}

export function fetchPropertyLots(property_id, inBackground = false) {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_PROPERTY_LOTS',
      `${apiProperties}/${property_id}/lots`,
      null,
      null,
      inBackground
    );
}

export function uploadLotGeom(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      'UPLOAD_PROPERTY_LOT_GEOM',
      `${apiProperties}/${data.property_id}/lots/upload/geom`,
      data
    );
}

export function uploadLots(formData) {
  return async (dispatch) => {
    dispatch({ type: 'UPLOAD_PROPERTY_LOTS_PENDING' });
    try {
      const success = await axios({
        method: 'PUT',
        url: `${apiProperties}/${formData.get('property_id')}/lots/upload`,
        data: formData,
      });

      dispatch({ type: 'UPLOAD_PROPERTY_LOTS_FULFILLED', payload: success.data.result });
      return true;
    } catch (err) {
      errorResponse(dispatch, err, 'UPLOAD_PROPERTY_LOTS_REJECTED');
      return false;
    }
  };
}
