import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiOrgOverheads } from '../../../../constants/api';

export function fetchOverheads(params) {
  return async (dispatch) => await get(dispatch, `FETCH_OVERHEADS`, `${apiOrgOverheads}`, params);
}

export function fetchOverhead(id) {
  return async (dispatch) => await get(dispatch, 'FETCH_OVERHEAD', `${apiOrgOverheads}/${id}`);
}

export function createOverhead(data) {
  return async (dispatch) => await post(dispatch, `CREATE_OVERHEAD`, `${apiOrgOverheads}`, data);
}

export function updateOverhead(data) {
  return async (dispatch) =>
    await put(dispatch, 'UPDATE_OVERHEAD', `${apiOrgOverheads}/${data.id}`, data);
}

export function removeOverhead(id) {
  return async (dispatch) => await remove(dispatch, 'REMOVE_OVERHEAD', `${apiOrgOverheads}/${id}`);
}

export function fetchOverheadDistributions(params) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_OVERHEAD_DISTRIBUTIONS', `${apiOrgOverheads}/distributions`, params);
}

export function fetchOverheadDistribution(id) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_OVERHEAD_DISTRIBUTION', `${apiOrgOverheads}/distributions/${id}`);
}

export function fetchOverheadflows(params, filters) {
  return async (dispatch) => {
    // Store params in reducer
    if (exists(params)) dispatch({ type: 'SET_OVERHEAD_PARAMS', payload: params });

    // Handle filters
    if (filters) params = addFiltersToParams(params, filters);

    // Retrieve and store assetflows in reducer
    return await get(dispatch, `FETCH_OVERHEADFLOWS`, `${apiOrgOverheads}/flows`, params);
  };
}

export function createOverheadDistribution(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_OVERHEAD_DISTRIBUTION`, `${apiOrgOverheads}/distributions`, data);
}

export function updateOverheadDistribution(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      'UPDATE_OVERHEAD_DISTRIBUTION',
      `${apiOrgOverheads}/distributions/${data.id}`,
      data
    );
}

export function removeOverheadDistribution(id) {
  return async (dispatch) =>
    await remove(
      dispatch,
      'REMOVE_OVERHEAD_DISTRIBUTION',
      `${apiOrgOverheads}/distributions/${id}`
    );
}

export function setOverheadflowFilters(params, filters) {
  return async (dispatch) => {
    // Store filters in reducer
    dispatch({ type: 'SET_OVERHEADFLOW_FILTERS', payload: filters });

    // Retrieve and store filtered assets
    dispatch(fetchOverheadflows(params, filters));
  };
}

const addFiltersToParams = (params, filters) => {
  Object.keys(filters).forEach((key) => (params[`filter_${key}`] = filters[key]));
  return params;
};

const exists = (data) => Object.keys(data).length > 0;
