import { defaults } from '../../../../lib/genericReducer';
import { DEFAULT_PAGINATION } from '../constants';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    authorised: true,
    authResponseMessage: null,
    dashboard_stats: [],
    currentProject: {},
    projects: {
      rows: [],
      count: 0,
    },
    project_properties: [],
    round: {},
    responseMessage: null,
    activeTab: 'register',
    projectOfficers: [],
    projectStatuses: [],
    project_job_nos: [
      { id: 'notnull', name: 'Active Projects Only' },
      { id: 'null', name: 'EOI Only' },
    ],
    project_status_ids: [],
    phasecodes: [],
    filters: {
      project_job_no: [],
      project_status_id: [],
      from_created_date: '',
      limit: DEFAULT_PAGINATION,
      assignedto_id: [],
    },
    subscriptions: [],
  },
  action,
) {
  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_PROJECT_STATUSES_FULFILLED': {
      return {
        ...state,
        projectStatuses: action.payload,
        project_status_ids: action.payload,
      };
    }
    case 'SET_PROJECT_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case 'SET_DRILL_LOCATION': {
      const { drill } = state;
      drill.status.lngLat = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        drill,
      };
    }
    case 'UNSET_PROJECT': {
      return {
        ...state,
        currentProject: {},
      };
    }
    case 'UNSET_PROJECTS': {
      return {
        ...state,
        projects: {
          rows: [],
          count: 0,
        },
      };
    }
    case 'FETCH_DASHBOARD_STATS_BYPROJECTS_FULFILLED': {
      return {
        ...state,
        dashboard_stats: action.payload,
      };
    }
    case 'FETCH_PROJECT_OFFICERS_FULFILLED': {
      return {
        ...state,
        projectOfficers: action.payload,
      };
    }
    case 'FETCH_PROJECTS_FULFILLED': {
      return {
        ...state,
        projects: action.payload,
      };
    }
    case 'FETCH_PROJECT_FULFILLED': {
      return {
        ...state,
        currentProject: action.payload,
      };
    }
    case 'REMOVE_PROJECT_FULFILLED': {
      return {
        ...state,
        currentProject: {},
      };
    }
    case 'UPDATE_PROJECT_FULFILLED': {
      return {
        ...state,
        currentProject: {},
      };
    }
    case 'CREATE_PROJECT_FULFILLED': {
      return {
        ...state,
        currentProject: action.payload,
      };
    }
    case 'CREATE_PROJECT_REJECTED': {
      return {
        ...state,
        responseMessage: action.payload,
      };
    }
    case 'NAVIGATION_SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload.name,
      };
    case 'FETCH_PROJECT_CENTROIDS_FULFILLED': {
      return {
        ...state,
        project_properties: action.payload,
      };
    }
    case 'FETCH_PROJECT_PHASECODES_FULFILLED': {
      return {
        ...state,
        phasecodes: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
