import React from 'react';
import { connect } from "react-redux";

import HomeVertical from './HomeVertical';
import HomeHorizontal from './HomeHorizontal';

class Home extends React.Component {
  constructor() {
    super();

    this.state = ({
      narrowSidebar: false,
      logo: null
    })
    this.renderHome = this.renderHome.bind(this);
  }

  renderHome() {
    const {
      currentUser
    } = this.props.profile;

    const {
      currentApp
    } = this.props.realm;

    const {
      match,
      location
    } = this.props;

    const loggedInAs = `Logged in as ${currentUser.name}`;

    switch (currentApp.structure) {
      case 'vertical':
        return <HomeVertical loggedInAs={loggedInAs} match={match} location={location} />
      case 'horizontal':
        return <HomeHorizontal loggedInAs={loggedInAs} match={match} location={location} />
      default: return <div />;
    }
  }

  render() {
    return (
      <>
        {this.renderHome()}
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    loading: store.ajaxRequests.calls > 0,
    realm: store.realm,
    manage: store.manage,
    profile: store.profile
  }
}

export default connect(mapStoreToProps)(Home);
