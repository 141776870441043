import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const LivestockEnterpriseMenu = ({
  setDirectCostModal = noop,
  setLivestockIncomeModal = noop,
  setLivestockTransactionModal = noop,
  setLivestockOpeningModal = noop,
  setLivestockAdjustmentModal = noop,
  setLivestockBreedingModal = noop,
  setTransferModal = noop,
}) => (
  <div>
    <UncontrolledButtonDropdown>
      <DropdownToggle caret color="success" size="sm">
        <Icon name="plus" className="mr-1" />
        Action
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem toggle onClick={() => setLivestockTransactionModal(true)}>
          Add Sale/Purchase
        </DropdownItem>
        <DropdownItem toggle onClick={() => setLivestockAdjustmentModal(true)}>
          Add Adjustment
        </DropdownItem>
        <DropdownItem toggle onClick={() => setLivestockOpeningModal(true)}>
          Add Opening Value
        </DropdownItem>
        <DropdownItem toggle onClick={() => setLivestockBreedingModal(true)}>
          Add Breeding
        </DropdownItem>
        <DropdownItem toggle onClick={() => setTransferModal(true)}>
          Add Transfer
        </DropdownItem>
        <DropdownItem toggle onClick={() => setDirectCostModal(true)}>
          Add Direct Cost
        </DropdownItem>
        <DropdownItem toggle onClick={() => setLivestockIncomeModal(true)}>
          Add Other Income
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  </div>
);

export default LivestockEnterpriseMenu;
