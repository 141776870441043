import { defaults } from '../../../../lib/genericReducer';
export default function reducer(state = {
  fetching: false,
  fetched: false,
  error: null,
  responseMessage: null,
  company: {},
  companies: []
}, action) {

  state = {
    ...state,
    ...defaults(action)
  }

  switch (action.type) {
    case "FETCH_CRM_COMPANIES_FULFILLED": {
      return {
          ...state,
          companies: action.payload
      }
    }
    case "FETCH_CRM_COMPANY_FULFILLED": {
      return {
        ...state,
        company: action.payload
      }
    }
    default: break;
  }
  return state;
}
