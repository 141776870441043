import { controlDefaults } from "../../form/lib/validateForm";

export const controls = {
  email: {
    ...controlDefaults,
    placeholder: 'Email',
    name: 'email',
    type: 'text',
    disabled: false,
    caption: "Your email address",
    validationRules: {
      minLength: 2,
      isRequired: true
    },
  }
}