import React from 'react';
import { connect } from 'react-redux';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import PageTitle from '../../../core/form/components/PageTitle';

import Placeholder from '../components/Placeholder';

import { Nav, TabContent, TabPane } from 'reactstrap';

class SugarEnterprises extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      activeTab: '2',
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  toggleTab(tab, tag) {
    this.setState({ activeTab: tab });

    switch (tag) {
      default:
        break;
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { activeTab } = this.state;

    const title = 'Other Livestock Enterprises';

    return (
      <div className="p-0 h-100">
        <PageTitle title={title} />
        <Nav tabs className="mt-2">
          <FormTab
            caption="Production and Areas"
            tabId="1"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="purchases"
          />
          <FormTab
            caption="Direct Costs and Other Income"
            tabId="2"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="costs"
          />
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="mb-2 p-1">
            <Placeholder rows={[]} title="Sugar Production and Areas" />
          </TabPane>
          <TabPane tabId="3" className="mb-2 p-1">
            <Placeholder rows={[]} title="Direct Costs and Other Income" />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(SugarEnterprises);
