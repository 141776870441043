import React from 'react';
import {
  Button,
  Row,
  Col
} from 'reactstrap';
import FormInput from "../../../core/form/components/FormInput";

const AreaRoundProfile = (props) => {
  const {
    handleChange,
    controls,
    onSave,
    onCancel,
    checkAccess
  } = props;

  return (
    <React.Fragment>
      <Row>
        <Col sm="6"><FormInput handleChange={handleChange} control={controls.name} disabled={true} /></Col>
      </Row>
      <Row>
        <Col><FormInput handleChange={handleChange} control={controls.project_period_id} /></Col>
        <Col><FormInput handleChange={handleChange} control={controls.start_date} /></Col>
        <Col><FormInput handleChange={handleChange} control={controls.end_date} /></Col>
      </Row>
      <Row>
        <Col><FormInput handleChange={handleChange} control={controls.property_round_id} /></Col>
        <Col><FormInput handleChange={handleChange} control={controls.accu_relinquished} /></Col>
        <Col><FormInput handleChange={handleChange} control={controls.site_script_id} /></Col>
      </Row>
      <div className="p-2 d-flex justify-content-center mt-2">
        <Button disabled={!checkAccess('roundUpdate')} color="success" className="m-1" onClick={onSave} >Save</Button>
        <Button color="light" className="m-1" onClick={onCancel}>Cancel</Button>
        <Button color="danger" className="m-1" disabled={true}>Delete</Button>
      </div>
    </React.Fragment>
  );
}

export default AreaRoundProfile;
