import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import ResponseMessage from "../../../core/form/components/ResponseMessageTab";
import AreaRoundMenu from '../components/AreaRoundMenu';

import {
  Button
} from 'reactstrap';

const AreaRoundTitle = (props) => {
  const {
    strataResponseMessage,
    siteResponseMessage,
    labAnalysisResponseMessage,
    openSiteUpload,
    handleSiteGenerate,
    uploads,
    downloads,
    toggleMiniMap,
    showMiniMap,
    data,
    subtitle,
    filters,
    pipelinesDisabled,
    clearPipelines,
    checkAccess
  } = props;

  const iconName = 'border-none';
  let roundName = '-';
  let area_name = '-';
  let propertyKey = '';
  if (data.id) {
    propertyKey = data.area.property.idx;
    area_name = data.area.name;
    roundName = data.name;
  }

  return (
    <React.Fragment>
      <div className="d-flex flex-row justify-content-between">
        <h3><Icon name={iconName} className="appForeTint mr-2" />{propertyKey}-CEA{area_name}-{roundName}</h3>
        <ResponseMessage responseMessage={strataResponseMessage} />
        <ResponseMessage responseMessage={siteResponseMessage} />
        <ResponseMessage responseMessage={labAnalysisResponseMessage} />
        <div className="d-flex flex-row">
          <Button href="#" color="link" onClick={toggleMiniMap} className="mr-1">
            {showMiniMap ? 'Hide Map' : 'Show Map'}
            <Icon name="location-pin" className="text-primary ml-2" style={{ cursor: 'pointer' }} />
          </Button>
          <AreaRoundMenu
            handleSiteUpload={openSiteUpload}
            handleSiteGenerate={handleSiteGenerate}
            handleStrataUpload={(event) => uploads.handleStrataUpload(event)}
            handleRoundManifestDownload={(event) => downloads.handleRoundManifestDownload(event)}
            handleLocationsPackDownload={(event) => downloads.handleLocationsPackDownload(event)}
            handleRoundScansDownload={(event) => downloads.handleRoundScansDownload(event)}
            handleResultsPackDownload={(event) => downloads.handleResultsPackDownload(event)}
            handleAuditPackDownload={(event) => downloads.handleAuditPackDownload(event)}
            handleReportingPackDownload={(event) => downloads.handleReportingPackDownload(event)}
            handleSelectionScansDownload={(event) => downloads.handleSelectionScansDownload(event)}
            handleExportSitesDownload={(event) => downloads.handleExportSitesDownload(event, filters)}
            pipelinesDisabled={pipelinesDisabled}
            clearPipelines={clearPipelines}
            toggleMiniMap={toggleMiniMap}
            checkAccess={checkAccess}
          />
        </div>
      </div>
      {subtitle && (<div className="p-1 rounded">Subsample Type: <span className="text-corporate">{subtitle}</span></div>)}
    </React.Fragment>
  );
}

export default AreaRoundTitle;
