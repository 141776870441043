import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  property_id: {
    ...controlDefaults,
    name: 'property_id',
    type: 'hidden',
  },
  method: {
    ...controlDefaults,
    name: 'method',
    field_name: 'method',
    type: 'select',
    caption: 'Method',
    options: [],
    validationRules: {
      isRequired: true,
    },
  },
  baseline_id: {
    ...controlDefaults,
    name: 'baseline_id',
    field_name: 'baseline_id',
    type: 'select',
    caption: 'Baseline Property Round',
    options: [],
    validationRules: {},
  },
  template_id: {
    ...controlDefaults,
    name: 'template_id',
    field_name: 'template_id',
    type: 'select',
    caption: 'Soil Template',
    options: [],
    validationRules: {
      isRequired: true,
    },
  },
  audit: {
    ...controlDefaults,
    name: 'audit',
    field_name: 'audit',
    type: 'select',
    caption: 'Audit Mode',
    options: [
      { id: 'false', name: 'False' },
      { id: 'true', name: 'True' },
    ],
  },
  raw: {
    ...controlDefaults,
    name: 'raw',
    field_name: 'raw',
    type: 'select',
    caption: 'Include Document Mappings?',
    options: [
      // Purposefully the wrong way around. Raw Mode enabled/disabled
      { id: 'false', name: 'Yes' },
      { id: 'true', name: 'No' },
    ],
  },
  excluded_round_id: {
    ...controlDefaults,
    name: 'excluded_round_id',
    field_name: 'excluded_round_id',
    type: 'multiselect',
    caption: 'Rounds to exclude',
    options: [],
    validationRules: {},
    defaultValue: [],
    value: [],
  },
};
