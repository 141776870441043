import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    activeTab: '1',
    adjustments: [],
    fetching: false,
    fetched: false,
    responseMessage: null,
    sales: [],
    summaries: [],
    values: [],
    woolflows: [],
    selectedProperties: [],
  },
  action
) {
  // Set expected reducer types
  const types = ['WOOL'];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'SET_WOOL_ACTIVE_TAB': {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case 'FETCH_WOOL_ADJUSTMENTS_FULFILLED': {
      return {
        ...state,
        adjustments: action.payload,
      };
    }
    case 'FETCH_WOOL_SALES_FULFILLED': {
      return {
        ...state,
        sales: action.payload,
      };
    }
    case 'FETCH_WOOL_SUMMARIES_FULFILLED': {
      return {
        ...state,
        summaries: action.payload,
      };
    }
    case 'FETCH_WOOL_VALUES_FULFILLED': {
      return {
        ...state,
        values: action.payload,
      };
    }
    case 'FETCH_WOOLFLOWS_FULFILLED': {
      return {
        ...state,
        woolflows: action.payload,
      };
    }
    case 'SET_WOOL_ALLOCATION_FILTER': {
      return {
        ...state,
        selectedProperties: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
