import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  division_id: {
    ...controlDefaults,
    placeholder: '-',
    name: 'division_id',
    fieldName: 'type.parent.name',
    type: 'select',
    caption: 'Division',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    options: [],
    showInEditor: true,
    showInListview: true,
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'name',
    name: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  type_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'type.name',
    name: 'type_id',
    type: 'select',
    caption: 'Animal Class Type',
    validationRules: {
      isRequired: true,
    },
    validationOptions: {
      override: {
        disabled: true,
      },
    },
    showInEditor: true,
    showInListview: true,
  },
  assumed_type_id: {
    ...controlDefaults,
    name: 'assumed_type_id',
    fieldName: 'assumed_type.name',
    includeEmptyOption: true,
    type: 'select',
    caption: 'Benchmark Class',
    showInEditor: true,
    showInListview: true,
  },
  valid: {
    ...controlDefaults,
    name: 'valid',
    fieldName: 'valid',
    type: 'checkbox',
    caption: 'Active',
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    validationRules: {
      isRequired: false,
    },
    defaultValue: true,
    showInEditor: true,
    showInListview: true,
  },
};
