import React from 'react';
import { connect } from 'react-redux';
import { Collapse, Card, Col, Row } from 'reactstrap';
import PillPicker from '../../../core/form/components/PillPicker';
import PillPresenter from '../../../core/form/components/PillPresenter';
import { setAssetflowFilters } from '../actions/assets';

class AssetsFilter extends React.Component {
  constructor(props) {
    super(props);

    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleCategoryDelete = this.handleCategoryDelete.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleTypeDelete = this.handleTypeDelete.bind(this);
  }

  handleCategoryChange(event) {
    const { value } = event.target;
    let { filters, params } = this.props.assets;

    // Add filter to categories
    const exists = filters.categories.find((id) => id === value);
    if (!exists) filters.categories.push(value);

    // Update reducer
    this.props.dispatch(setAssetflowFilters(params, filters));
  }

  handleCategoryDelete(id) {
    let { filters, params } = this.props.assets;

    // Remove filter from categories
    const idx = filters.categories.findIndex((filterId) => filterId === id);
    if (idx !== -1) filters.categories.splice(idx, 1);

    // Update reducer
    this.props.dispatch(setAssetflowFilters(params, filters));
  }

  handleTypeChange(event) {
    const { value } = event.target;
    let { filters, params } = this.props.assets;

    // Check if user selection is not in current filters
    const exists = filters.types.find((id) => id === value);
    if (!exists) filters.types.push(value);

    // Update reducer
    this.props.dispatch(setAssetflowFilters(params, filters));
  }

  handleTypeDelete(id) {
    let { filters, params } = this.props.assets;

    // Remove filter from types
    const idx = filters.types.findIndex((filterId) => filterId === id);
    if (idx !== -1) filters.types.splice(idx, 1);

    // Update reducer
    this.props.dispatch(setAssetflowFilters(params, filters));
  }

  render() {
    const { filterOpen, categories } = this.props;
    const { filters } = this.props.assets;
    let { types } = this.props;

    // Set custom colour for type filters
    types.map((type) => (type.colour = 'orange'));

    const categorySelection = filters.categories;
    const categoryCaption = 'Category';

    const typeSelection = filters.types;
    const typeCaption = 'Type';

    const filtersCount = Object.keys(filters)
      .map((key) => filters[key].length)
      .reduce((a, b) => a + b);

    return (
      <React.Fragment>
        <Collapse isOpen={filterOpen}>
          <Card className="border border-corporate rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col sm={3} className="bg-light m-1">
                <PillPicker
                  caption={categoryCaption}
                  handleChange={this.handleCategoryChange}
                  handleDelete={this.handleCategoryDelete}
                  rows={categories}
                  selection={categorySelection}
                  showPills={false}
                />
              </Col>
              <Col sm={3} className="bg-light m-1">
                <PillPicker
                  caption={typeCaption}
                  handleChange={this.handleTypeChange}
                  handleDelete={this.handleTypeDelete}
                  rows={types}
                  selection={typeSelection}
                  showPills={false}
                />
              </Col>
            </Row>
          </Card>
        </Collapse>
        {filtersCount > 0 && (
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption={categoryCaption}
              handleDelete={this.handleCategoryDelete}
              rows={categories}
              selection={categorySelection}
              colourField="colour"
            />
            <PillPresenter
              caption={typeCaption}
              handleDelete={this.handleTypeDelete}
              rows={types}
              selection={typeSelection}
              colourField="colour"
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    assets: store.assets,
  };
};

export default connect(mapStoreToProps)(AssetsFilter);
