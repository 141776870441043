import React from 'react';
import { 
  Row,
  Col,
} from 'reactstrap';

const FieldBookHeader = (props) => {
  const {
    fieldtrip
  } = props;

  return (
    <React.Fragment>
      <Row className="bg-light m-0 p-1 rounded border bg-corporate text-white">
        <Col><span className="text-warning">Crew: </span><b>{fieldtrip.crew}</b></Col>
        <Col><span className="text-warning">Drill Rig: </span><b>{fieldtrip.drillrig}</b></Col>
        <Col><span className="text-warning">Contractor: </span><b>{fieldtrip.subcontractor}</b></Col>
      </Row>
    </React.Fragment>
  );
}

export default FieldBookHeader;
