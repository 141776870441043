import axios from 'axios';
import errorResponse from '../../../../lib/errorResponse.js';
import {
  apiAnalytics,
  apiRounds,
  apiRoundDocuments
} from '../../../../constants/api.js';


export function downloadRoundManifest(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_AREA_ROUND_MANIFEST';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiRounds}/${id}/drillmanifest/download`,
      onProgressChange
    )
  }
}

export function downloadGpsLocations(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_AREA_ROUND_GPS_LOCATIONS';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiRounds}/${id}/sites/download`,
      onProgressChange
    )
  }
}

export function downloadLocationsPack(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_AREA_ROUND_LOCATIONS_PACK';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiRounds}/${id}/locations/download`,
      onProgressChange
    )
  }
}

export function downloadRoundScans(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_AREA_ROUND_SCANS';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiRounds}/${id}/scans/download`,
      onProgressChange
    )
  }
}

export function downloadResultsPack(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_AREA_ROUND_RESULTS_PACK';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiRounds}/${id}/results/download`,
      onProgressChange
    )
  }
}

export function downloadSelectionScans(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_AREA_ROUND_SELECTION_SCANS';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiRounds}/${id}/selections/download`,
      onProgressChange
    )
  }
}


export function downloadAuditPack(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_AREA_ROUND_AUDIT_PACK';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiAnalytics}/rounds/${id}/reporting?audit=true`,
      onProgressChange
    )
  }
}

export function downloadReportingPack(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_AREA_ROUND_REPORTING_PACK';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiAnalytics}/rounds/${id}/reporting`,
      onProgressChange
    )
  }
}

export function downloadFakeLab(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_AREA_ROUND_FAKE_LAB';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiRounds}/${id}/fakelab/download`,
      onProgressChange
    )
  }
}

export function downloadExportSites(id, onProgressChange, filters) {
  const dispatch_prefix = 'DOWNLOAD_AREA_ROUND_EXPORT_SITES';
  return async dispatch => {

    // Hate this so much
    let queryString = '';
    if (filters) {
      const filterKeys = Object.keys(filters);
      let params = [];
      filterKeys.forEach(key => {
        if (filters[key].length > 0) {
          params.push(`filter_${key}[]=${filters[key]}`);
        }
      })
      if (params.length > 0) {
        queryString = `?${params.join('&')}`;
      }
    }

    return await download(
      dispatch_prefix,
      dispatch,
      `${apiRounds}/${id}/export/sites${queryString}`,
      onProgressChange
    )
  }
}

export function downloadWorkflowDocument(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_AREA_ROUND_WORKFLOW_DOCUMENT';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiRoundDocuments}/${id}/download`,
      onProgressChange
    )
  }
}

async function download(dispatch_prefix, dispatch, url, onProgressChange) {
  dispatch({ type: `${dispatch_prefix}_DOWNLOADING` });
  try {
    const file = await axios.get(
      url,
      {
        headers: { "Access-Control-Expose-Headers": "Content-Disposition" },
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => { onProgressChange(calculateProgress(progressEvent)) }
      }
    )

    dispatch({ type: `${dispatch_prefix}_FULFILLED` });
    return file;
  } catch (err) {
    errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
    return null
  }
}

function calculateProgress(progressEvent) {
  let value;
  let suffix;
  if (progressEvent.lengthComputable) {
    value = `${Math.round((progressEvent.loaded * 100) / progressEvent.total)}`;
    suffix = '%';
  } else {
    value = progressEvent.loaded;
    suffix = 'b';
    // Kilobytes
    if (value > 1000) {
      value /= 1000;
      suffix = 'kb';
    }

    // Megabytes
    if (value > 1000) {
      value /= 1000;
      suffix = 'mb';
    }

  }
  
  value = Math.round(value * 100) / 100;
  return `${value}${suffix}`;
}