import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Col, Row, Button } from 'reactstrap';

import {
  RenderInput,
  prepareForSubmission,
  removeUntouchedFields,
  useUpdateControls,
} from 'jsx/components/core/form/components/FormBuilder';
import FormInput from 'jsx/components/core/form/components/FormInput';
import ResponseMessage from 'jsx/components/core/form/components/ResponseMessageTab';
import { useCheckAccess } from 'jsx/lib/hooks';

import { fromPairs } from 'lodash';
import { updateProjectRegisterProject } from '../../actions/project_register';
import { projectRegisterMetaForm } from '../../forms/project_register_meta';

const RenderForm = ({ form, isDirty, onCancel, isReadOnly = false }) => (
  <>
    <Row>
      <RenderInput control={form.project_id} disabled={isReadOnly} />
      <Col sm={3}>
        <RenderInput control={form.erf_project_no} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={form.orig_methodology} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={form.tx_methodology} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={form.permanence_period} disabled={isReadOnly} />
      </Col>
    </Row>
    <Row>
      <Col sm={3}>
        <RenderInput control={form.participant} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={form.eihc_required} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={form.anreu_holder} />
      </Col>
      <Col sm={3}>
        <RenderInput control={form.anreu_ref} disabled={isReadOnly} />
      </Col>
    </Row>
    <Row>
      <Col sm={3}>
        <RenderInput control={form.anreu_primary_rep} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={form.anreu_secondary_rep} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={form.anreu_tertiary_rep} disabled={isReadOnly} />
      </Col>
      <Col sm={3}>
        <RenderInput control={form.anreu_client_no} disabled={isReadOnly} />
      </Col>
    </Row>
    {!isReadOnly && (
      <Row>
        <Col sm={3}>
          <Button className="mt-3" type="submit" color="primary" disabled={!isDirty}>
            Save changes
          </Button>
          {isDirty && (
            <Button type="button" color="secondary" className="ml-2 mt-3" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Col>
      </Row>
    )}
  </>
);

const ProjectRegisterForm = ({ registerId, refresh }) => {
  const reactHookForm = useForm();
  const dispatch = useDispatch();
  const handleInvalid = () => undefined;
  const {
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = reactHookForm;
  const {
    currentProjectRegister,
    formStates: { projectRegisterProject },
  } = useSelector((state) => state.project_register);

  const form = useUpdateControls(projectRegisterMetaForm, currentProjectRegister);

  const onCancel = () => {
    reactHookForm.reset();
  };

  const onSubmit = async (data) => {
    const hiddenFields = fromPairs(
      Object.entries(form).map(([key, value]) => {
        if (value.type === 'hidden') {
          return [key, true];
        }
        return [key, false];
      }),
    );
    const strippedData = removeUntouchedFields(data, {
      ...hiddenFields,
      ...dirtyFields,
    });

    const dataToSubmit = prepareForSubmission(strippedData, form);

    const results = await dispatch(updateProjectRegisterProject(registerId, dataToSubmit));

    if (results) {
      reactHookForm.reset(results);
      refresh();
    }
  };

  const hasWriteAccess = useCheckAccess('projectRegisterUpdate');

  return (
    <>
      <Row>
        <Col sm={3}>
          <FormInput disabled control={form.projectName} />
        </Col>
        <Col sm={3}>
          <FormInput disabled control={form.assignedTo} />
        </Col>
        <Col sm={6}>
          <FormInput disabled control={form.description} />
        </Col>
      </Row>
      <div className="d-flex justify-content-between border-bottom align-items-end pb-1 mt-4">
        <h5 className="text-corporate mb-0">Register</h5>
      </div>
      <FormProvider {...reactHookForm}>
        <ResponseMessage responseMessage={projectRegisterProject} />
        <form onSubmit={handleSubmit(onSubmit, handleInvalid)}>
          <RenderForm
            form={form}
            isDirty={isDirty}
            onCancel={onCancel}
            isReadOnly={!hasWriteAccess}
          />
        </form>
      </FormProvider>
    </>
  );
};
export default ProjectRegisterForm;
