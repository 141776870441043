import React from 'react';
import { connect } from 'react-redux';
import WidgetDivision from './WidgetDivision';

import { Col } from 'reactstrap';

import { fetchReportDivisionMetrics } from '../actions/reports';

class WidgetDivisions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  async componentDidMount() {
    const { selectedRanges } = this.props.enterprises;

    const rows = await this.props.dispatch(fetchReportDivisionMetrics(selectedRanges));
    this.setState({ rows });
  }

  render() {
    const { rows } = this.state;

    const divisions = rows
      ? rows.map((row, index) => {
          return (
            <Col sm={6} className="p-1" key={index}>
              <WidgetDivision row={row} />
            </Col>
          );
        })
      : [];

    return <>{divisions}</>;
  }
}

const mapStoreToProps = (store) => {
  return {
    enterprises: store.enterprises,
  };
};

export default connect(mapStoreToProps)(WidgetDivisions);
