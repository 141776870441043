import { allowed, defaults } from '../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    responseMessage: null,
    byMonth: {
      valueType: 'value',
      months: [],
      rows: [],
    },
    classes: {
      count: 0,
      rows: [],
    },
    values: {
      count: 0,
      rows: [],
    },
  },
  action,
) {
  const types = ['ASSUMED'];

  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_ASSUMED_ANIMAL_CLASSES_FULFILLED': {
      return { ...updatedState, classes: action.payload };
    }
    case 'FETCH_ASSUMED_ANIMAL_CLASS_VALUES_FULFILLED': {
      return { ...updatedState, values: action.payload };
    }
    case 'FETCH_ASSUMED_ANIMAL_CLASS_VALUES_BY_MONTH_FULFILLED': {
      return {
        ...state,
        byMonth: {
          ...state.byMonth,
          ...action.payload,
        },
      };
    }
    case 'SET_ANIMAL_CLASS_ASSUMED_VALUES_BY_MONTH_VALUE_TYPE': {
      return {
        ...state,
        byMonth: {
          ...state.byMonth,
          valueType: action.payload,
        },
      };
    }
    default:
      break;
  }

  return updatedState;
}
