import React from 'react';
import Listview from "../../core/form/components/Listview";
import PageTitleH5 from "../../core/form/components/PageTitleH5";
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';

const InvitationsLsv = (props) => {
  const {
    inviteMessage,
    rows,
    removeInvitation,
    hideHeader,
    message
  } = props;

  const renderRows = (headers, user) => {
    const created = moment(user.created).fromNow();
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'created': return  (
          <td key={index} className={header.classes}>{created}</td>
        )
        default: return  (
          <td key={index} className={header.classes}>{user[header.field]}</td>
        )
      }
    });

    tableTd.push(
      <td key={headers.length + 1} className="d-flex justify-content-end">
          <Icon 
            name="trash"
            className="text-primary"
            onClick={() => removeInvitation(user.id)}
            style={{cursor: 'pointer'}}
          />
        </td>
    )
    return tableTd;
  }

  const headers = [
    {caption: 'First Name', field: 'firstname', classes:'text-left'},
    {caption: 'Last Name', field: 'lastname', classes:'text-left'},
    {caption: 'Email', field: 'email', classes:'text-left'},
    {caption: 'Created', field: 'created', classes:'text-right'},
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );
  tableHeadTh.push(<th key={headers.length + 1}></th>);

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} >
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='user-clock';
  const title = 'User Invitations';
  const emptyCaption= message || 'No Invitations found';

  return (
    <React.Fragment>
      {!hideHeader && (
        <div className="d-flex justify-content-start border-bottom border-corporate bg-light p-1 rounded">
          <PageTitleH5 iconName={iconName} title={title} />
        </div>
      )}

      {inviteMessage && (
        <div className="text-danger m-3">{inviteMessage}</div>
      )} 
      
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  )
}

export default InvitationsLsv;
