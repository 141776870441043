import { controlDefaults } from 'jsx/components/core/form/lib/validateForm';

export const leftAxis = {
  metric_id: {
    ...controlDefaults,
    placeholder: 'Select Metric',
    fieldName: 'metric_id',
    name: 'metric_id',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    options: [
      {id: null, name: 'None Selected'}
    ],
    showInEditor: true,
    showInListview: true,
  }
};

export const rightAxis = {
  metric_id: {
    ...controlDefaults,
    placeholder: 'Select Metric',
    fieldName: 'metric_id',
    name: 'metric_id',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    options: [
      {id: null, name: 'None Selected'}
    ],
    showInEditor: true,
    showInListview: true,
  }

};
