import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  name: {
    ...controlDefaults,
    placeholder: 'Brief trip description',
    name: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      isRequired: true
    },
  },
  drillrig: {
    ...controlDefaults,
    placeholder: '',
    name: 'drillrig',
    type: 'text',
    caption: 'Drill Rig',
    validationRules: {
      isRequired: true
    },
  },
  subcontractor: {
    ...controlDefaults,
    placeholder: '',
    name: 'subcontractor',
    type: 'text',
    caption: 'Sub Contractor',
    validationRules: {
      isRequired: true
    },
  },
  crew: {
    ...controlDefaults,
    placeholder: 'Open text field for crew',
    name: 'crew',
    type: 'textarea',
    caption: 'Crew',
    validationRules: {
      isRequired: true
    },
  }
}