import React from 'react';

const ListviewCheckbox = (props) => {
  const { control, className, handleChange } = props;
  return (
    <React.Fragment>
      <input
        type="checkbox"
        id={control.id ? control.id : control.name}
        name={control.name}
        checked={control.value}
        className={className}
        onChange={handleChange}
      />
    </React.Fragment>
  );
};

export default ListviewCheckbox;
