import React from 'react';
import { connect } from "react-redux";
import {
  UncontrolledCollapse,
  Card,
  Row,
  Col
} from 'reactstrap';
import PillPicker from '../../../core/form/components/PillPicker';
import PillPresenter from '../../../core/form/components/PillPresenter';

import {
  fetchScanTags,
  setScanFilters
} from '../actions';

class CoreArchivesFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      toggler: '#scanArchiveToggler',
    })

    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleTagDelete = this.handleTagDelete.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchScanTags());
  }

  handleTagChange(event) {
    let {
      scanFilters
    } = this.props.sites;

    const params = {};
    const exists = scanFilters.tag_id.find(id => id === event.target.value);
    if (!exists) {
      scanFilters.tag_id.push(event.target.value)
    }

    this.props.clearSelected();
    this.props.dispatch(setScanFilters(params, scanFilters));
  }

  handleTagDelete(id) {
    let {
      scanFilters
    } = this.props.sites;

    const params = {};
    const idx = scanFilters.tag_id.findIndex(filterId => filterId === id)
    scanFilters.tag_id.splice(idx, 1)

    this.props.dispatch(setScanFilters(params, scanFilters));
  }

  render() {
    const {
      toggler
    } = this.state;

    const {
      tags,
      scanFilters
    } = this.props.sites;

    const tagSelection = scanFilters.tag_id;

    let filtersCount = 0;
    filtersCount += tagSelection.length;

    return (
      <React.Fragment>
        <UncontrolledCollapse toggler={toggler}>
          <Card className="border border-warning rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col sm={2} className="bg-light m-1">
                <PillPicker
                  caption="Tags"
                  handleChange={this.handleTagChange}
                  rows={tags}
                  selection={tagSelection}
                  showPills={false}
                />
              </Col>
            </Row>
          </Card>
        </UncontrolledCollapse>
        {(filtersCount > 0 &&
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption="Tags"
              handleDelete={this.handleTagDelete}
              rows={tags}
              selection={tagSelection}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    sites: store.sites
  }
}

export default connect(mapStoreToProps)(CoreArchivesFilter);
