import React from 'react';
import { connect } from "react-redux";
import {
  Row,
  Col
} from 'reactstrap';

import SiteSampleImages from '../../sites/components/SiteSamplesImages';

import {
  fetchMultiSiteSamples
} from '../../sites/actions';

class WidgetScanImages extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      data: [],
      fetching: false,
      height: 400,
      datasets: [],
      siteSelections: [],
      selectedPosition: 0,
      siteId: null,
      samples: []
    })

    this.onSelectPosition = this.onSelectPosition.bind(this);
  }

  componentDidMount() {
    this.props.dispatch({type: 'SET_DEPTH_FULLFILLED', payload: {}});
  }

  componentDidUpdate() {
    const {
      fetching
    } = this.state;

    const selections = this.props.analytics.selections.sites;
    let siteId = null;
    if (selections.length > 0) {
      siteId = selections[0];
    }
    
    if (!fetching && siteId && siteId !== this.state.siteId && !fetching) {
      this.load(siteId);
      this.setState({siteId})
    }
  }

  async load(siteId) {
    this.setState(
      {fetching: true},
      async () => {
        const formData = new FormData();
        formData.append('core_id[]', siteId);
        const samples = await this.props.dispatch(fetchMultiSiteSamples(formData));
        this.setState({fetching: false, samples: samples});
      }
    )
  }

  onSelectPosition(position) {
    this.setState({selectedPosition: position});

    const depth = {
      upper: (position / 10) - 2.5,
      lower: (position / 10) + 2.5
    }

    this.props.dispatch({type: 'SET_DEPTH_FULLFILLED', payload: depth});
  }

  render() {
    const {
      samples,
      selectedPosition,
      siteId
    } = this.state;

    let filteredSamples = [];
    let selectedSiteCores = (<div className="text-danger m-4 p-2 text-center">No Site selected. Click on a core on the Strata Map, Core Carbon Stocks or Local/Global Model Performance charts to visualise core imagery</div>);
    if (samples.length > 0) {
      filteredSamples = samples.filter(sample => sample['site.core_id'] === siteId)
      selectedSiteCores = (
        <SiteSampleImages
          caption={siteId}
          samples={filteredSamples}
          onSelectPosition={this.onSelectPosition}
          selectedPosition={selectedPosition}
        />
      )
    }


    const className = "m-1 bg-white rounded border border-secondary"

    return(
      <Row className="m-0 p-0">
        <Col sm={12} className="p-0">
            <div className={className}>
              {selectedSiteCores}
            </div>
        </Col>
      </Row>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    analytics: store.analytics,
    mapster: store.mapster
  }
}

export default connect(mapStoreToProps)(WidgetScanImages);
