import axios from 'axios';
import { get } from '../../../../lib/genericAction';
import { apiProperties, apiStatuses, apiProjects } from '../../../../constants/api.js';
import errorResponse from '../../../../lib/errorResponse.js';

const FEATURE_PREFIX = 'SAMPLING_PLAN';

export function fetchProjectStatuses({ inBackground = true }) {
  return (dispatch) =>
    get(
      dispatch,
      `${FEATURE_PREFIX}/FETCH_PROJECT_STATUSES`,
      `${apiStatuses}?type=project`,
      null,
      null,
      inBackground
    );
}

export function fetchProperties({ params, inBackground = true }) {
  return (dispatch) =>
    get(dispatch, `${FEATURE_PREFIX}/FETCH_PROPERTIES`, apiProperties, params, null, inBackground);
}

export function fetchPropertiesStats({ params, inBackground = true }) {
  return (dispatch) =>
    get(
      dispatch,
      `${FEATURE_PREFIX}/FETCH_PROPERTIES_STATS`,
      `${apiProperties}/stats`,
      params,
      null,
      inBackground
    );
}

export function fetchProject({ id, include_geom }) {
  return async (dispatch) => {
    dispatch({ type: `${FEATURE_PREFIX}/FETCH_PROJECT_PENDING`, meta: { inBackground: true } });

    try {
      const response = await axios({
        method: 'GET',
        url: `${apiProjects}/${id}`,
        params: { include_geom },
      });

      dispatch({
        type: `${FEATURE_PREFIX}/FETCH_PROJECT_FULFILLED`,
        payload: response.data.result,
        meta: { inBackground: true },
      });

      return response.data.result;
    } catch (err) {
      console.error(err);
      errorResponse(dispatch, err, `${FEATURE_PREFIX}/FETCH_PROJECT_REJECTED`);
      return false;
    }
  };
}

export function fetchProjects({ params, inBackground = true }) {
  return (dispatch) =>
    get(dispatch, `${FEATURE_PREFIX}/FETCH_PROJECTS`, apiProjects, params, null, inBackground);
}

export function fetchPropertyLots({ id, params }) {
  return async (dispatch, getState) => {
    const propertyData = getState().sampling_plan.properties.data.rows;
    const property = propertyData.find((property) => property.id === id);
    if (property.lots.fetched) {
      console.info('Property lots already fetched, skipping');
      return;
    }

    dispatch({
      type: `${FEATURE_PREFIX}/FETCH_PROPERTY_LOTS_PENDING`,
      meta: { inBackground: true, propertyId: id },
    });

    try {
      const response = await axios({
        method: 'GET',
        url: `${apiProperties}/${id}/lots`,
        params,
      });

      dispatch({
        type: `${FEATURE_PREFIX}/FETCH_PROPERTY_LOTS_FULFILLED`,
        payload: response.data.result,
        meta: { inBackground: true, propertyId: id },
      });

      return response.data.result;
    } catch (err) {
      console.error(err);
      errorResponse(dispatch, err, `${FEATURE_PREFIX}/FETCH_PROPERTY_LOTS_REJECTED`);
      return false;
    }
  };
}

export function fetchPropertyAreas({ id }) {
  return async (dispatch, getState) => {
    const propertyData = getState().sampling_plan.properties.data.rows;
    const property = propertyData.find((property) => property.id === id);
    if (property?.areas?.fetched) {
      console.info('Property areas already fetched, skipping');
      return;
    }

    dispatch({
      type: `${FEATURE_PREFIX}/FETCH_PROPERTY_AREAS_PENDING`,
      meta: { inBackground: true, propertyId: id },
    });

    try {
      const response = await axios({
        method: 'GET',
        url: `${apiProperties}/${id}/areas`,
      });

      dispatch({
        type: `${FEATURE_PREFIX}/FETCH_PROPERTY_AREAS_FULFILLED`,
        payload: response.data.result,
        meta: { inBackground: true, propertyId: id },
      });

      return response.data.result;
    } catch (err) {
      console.error(err);
      errorResponse(dispatch, err, `${FEATURE_PREFIX}/FETCH_PROPERTY_AREAS_REJECTED`);
      return false;
    }
  };
}

export function fetchRoundStats({ id, params }) {
  return async (dispatch, getState) => {
    const propertyData = getState().sampling_plan.properties.data.rows;
    const property = propertyData.find((property) => property.id === id);
    if (property.rounds.fetched) {
      console.info('Property rounds already fetched, skipping');
      return;
    }

    dispatch({
      type: `${FEATURE_PREFIX}/FETCH_ROUND_STATS_PENDING`,
      meta: { inBackground: true, propertyId: id },
    });

    try {
      const response = await axios({
        method: 'GET',
        url: `${apiProperties}/${id}/rounds/stats`,
        params,
      });

      dispatch({
        type: `${FEATURE_PREFIX}/FETCH_ROUND_STATS_FULFILLED`,
        payload: response.data.result,
        meta: { inBackground: true, propertyId: id },
      });

      return response.data.result;
    } catch (err) {
      console.error(err);
      errorResponse(dispatch, err, `${FEATURE_PREFIX}/FETCH_ROUND_STATS_REJECTED`);
      return false;
    }
  };
}

export function fetchProjectCentroid(params) {
  return async (dispatch) => {
    dispatch({
      type: `${FEATURE_PREFIX}/FETCH_PROJECT_CENTROID_PENDING`,
      meta: { inBackground: true, projectId: params.project_id },
    });

    try {
      const response = await axios({
        method: 'GET',
        url: `${apiProjects}/centroids`,
        params,
      });

      dispatch({
        type: `${FEATURE_PREFIX}/FETCH_PROJECT_CENTROID_FULFILLED`,
        payload: response.data.result,
        meta: { inBackground: true, projectId: params?.project_id },
      });

      return response.data.result;
    } catch (err) {
      console.error(err);
      errorResponse(dispatch, err, `${FEATURE_PREFIX}/FETCH_PROJECT_CENTROID_REJECTED`);
      return false;
    }
  };
}

export function fetchProjectStats(params) {
  return async (dispatch) => {
    dispatch({
      type: `${FEATURE_PREFIX}/FETCH_PROJECT_STATS_PENDING`,
      meta: { inBackground: true },
    });

    try {
      const response = await axios({
        method: 'GET',
        url: `${apiProjects}/stats`,
        params,
      });

      dispatch({
        type: `${FEATURE_PREFIX}/FETCH_PROJECT_STATS_FULFILLED`,
        payload: response.data.result,
        meta: { inBackground: true },
      });

      return response.data.result;
    } catch (err) {
      console.error(err);
      errorResponse(dispatch, err, `${FEATURE_PREFIX}/FETCH_PROJECT_STATS_REJECTED`);
      return false;
    }
  };
}
