import React from 'react';
import { connect } from "react-redux";
import {
  UncontrolledCollapse,
  Card,
  Row,
  Col
} from 'reactstrap';
import PillPicker from '../../../core/form/components/PillPicker';
import PillPresenter from '../../../core/form/components/PillPresenter';

import { setLabRegisterFilters } from '../actions';
import { fetchDocumentDefs } from '../../../manage/actions';

class LabRegisterFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      toggler: '#toggler',
    })

    this.handleSystemChange = this.handleSystemChange.bind(this);
    this.handleSystemDelete = this.handleSystemDelete.bind(this);

  }

  componentDidMount() {
    this.props.dispatch(fetchDocumentDefs({ group_key: 'lab_register' }))
      .then(defs => {
        let {
          filters,
          params
        } = this.props.lab;

        if (filters && defs) {
          filters.sample_type_id = defs
            .filter(def =>
              def.key === 'lr_subsample_selection'
              || def.key === 'lr_gravel_selection'
            )
            .map(def => def.id);

          this.props.dispatch(setLabRegisterFilters(params, filters));
        }

      });
  }

  handleSystemChange(event) {
    let {
      filters,
      params
    } = this.props.lab;

    const exists = filters.sample_type_id.find(id => id === event.target.value);
    if (!exists) {
      filters.sample_type_id.push(event.target.value)
    }

    this.props.dispatch(setLabRegisterFilters(params, filters));
  }

  handleSystemDelete(id) {
    let {
      filters,
      params
    } = this.props.lab;

    const idx = filters.sample_type_id.findIndex(fid => fid === id)
    filters.sample_type_id.splice(idx, 1)

    this.props.dispatch(setLabRegisterFilters(params, filters));
  }

  render() {
    const {
      toggler
    } = this.state;

    const {
      filters
    } = this.props.lab;

    const { documentDefs } = this.props.manage;

    const sampleTypeSelection = filters.sample_type_id;

    let filtersCount = 0;
    filtersCount += sampleTypeSelection.length;

    return (
      <React.Fragment>
        <UncontrolledCollapse toggler={toggler}>
          <Card className="border border-warning rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col className="bg-light m-1">
                <PillPicker
                  caption="Sample Type"
                  handleChange={this.handleSystemChange}
                  rows={documentDefs}
                  selection={sampleTypeSelection}
                  showPills={false}
                />
              </Col>
              <Col className="bg-light m-1">
              </Col>
              <Col className="bg-light m-1">
              </Col>
            </Row>
          </Card>
        </UncontrolledCollapse>
        {(filtersCount > 0 &&
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption="Sample Type"
              handleDelete={this.handleSystemDelete}
              rows={documentDefs}
              selection={sampleTypeSelection}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    lab: store.lab,
    manage: store.manage
  }
}

export default connect(mapStoreToProps)(LabRegisterFilter);
