import Icon from 'jsx/components/core/icons/Icon';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fetchUserProjects } from '../actions/user_projects';
import { GenericModal } from 'jsx/components/core/form/components/GenericModal';

class UserProjectsModal extends GenericModal {
  constructor(props) {
    super(props);

    this.areDateRangesOverlapping = this.areDateRangesOverlapping.bind(this);
    this.checkMultiProjectOkBeforeSave = this.checkMultiProjectOkBeforeSave.bind(this);
    this.dateIsBetweenRange = this.dateIsAtOrBetweenRange.bind(this);
    this.filterUserAssociations = this.filterUserAssociations.bind(this);
    this.getUserConfirmation = this.getUserConfirmation.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidUpdate(prevProps) {
    /**
     * This is required to refresh user projects in the reducer once the model opens.
     * Reducer may have filtered user projects via search filtering.
     */
    if (!prevProps.isOpen && this.props.isOpen) this.props.dispatch(fetchUserProjects());
    super.componentDidUpdate(prevProps);
  }

  async onSave() {
    super.onSave({ middlewareCheck: this.checkMultiProjectOkBeforeSave });
  }

  async checkMultiProjectOkBeforeSave() {
    const { userProjects } = this.props.user_projects;
    const { controls } = this.state;

    const { value: user_id, options: userOptions } = controls.user_id;
    const { value: from_date } = controls.from_date;
    const { value: to_date } = controls.to_date;
    const { name } = userOptions.find(({ id }) => id === user_id);
    const id = this.props.data ? this.props.data.id : null;

    if (userProjects.length === 0) return true;

    const associations = this.filterUserAssociations({
      associations: userProjects,
      from_date,
      to_date,
      id,
      user_id,
    });

    return associations.length > 0 ? this.getUserConfirmation(user_id, name) : true;
  }

  filterUserAssociations({ associations, from_date, to_date, id = null, user_id }) {
    return associations
      .filter((row) => {
        /** Exclude current row */
        if (id && row.id === id) return false;

        if (row.user_id !== user_id) return false;

        const ranges = [
          { start: from_date, end: to_date } /** Target dates */,
          { start: row.from_date, end: row.to_date } /** Existing dates */,
        ];
        const checks = [row.user_id === user_id, this.areDateRangesOverlapping(ranges)];

        const shouldFilter = checks.every((check) => check);

        return shouldFilter ? row : false;
      })
      .filter(Boolean);
  }

  /**
   * Check for overlapping dates within target/exsiting date ranges.
   * @param {Array} ranges two sets of date ranges: target/existing
   * @returns Array<Boolean>
   */
  areDateRangesOverlapping(ranges) {
    const checks = ranges.map((range, index) => {
      return Object.keys(range)
        .map((key) => {
          const opposite = index === 0 ? 1 : 0;

          if (!range[key]) return false;

          return !range[key]
            ? false
            : this.dateIsAtOrBetweenRange(
                moment(range[key]),
                moment(ranges[opposite].start),
                moment(ranges[opposite].end),
              );
        })
        .filter(Boolean);
    });

    return checks.flat().length !== 0;
  }

  dateIsAtOrBetweenRange(date, start, end) {
    return date.isBetween(start, end) || date.isSame(start) || date.isSame(end);
  }

  async getUserConfirmation(user_id, name) {
    return window.confirm(
      `Warning: ${name} is already associated with a project between the selected dates. Continue?`,
    );
  }

  render() {
    let { controls } = this.state;
    const { isOpen, title, isNew, description } = this.state;
    const { iconName, responseMessage } = this.props;

    controls = this.setOptions(controls);

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon size="1x" name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody>
          {description && <p>{description}</p>}
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>{this.renderInputs(controls)}</Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && this.props.onRemove && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ user_projects }) => ({
  user_projects,
});

export default connect(mapStoreToProps)(UserProjectsModal);
