import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import { connect } from 'react-redux';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { GenericModal } from 'jsx/components/core/form/components/GenericModal';
import { updateControlOptions } from 'jsx/components/core/form/lib/validateForm';

class TransferModal extends GenericModal {
  constructor(props) {
    super(props);

    this.filterProperties = this.filterProperties.bind(this);
  }

  filterProperties(enterprise_id) {
    const { enterprises } = this.props.enterprises;
    const enterprise = enterprises?.rows?.filter(({ id }) => id === enterprise_id)[0] ?? null;

    if (!enterprise || enterprise.allocations.length === 0) return [{ id: null, value: '-' }];

    return enterprise.allocations.map(({ property }) => property);
  }

  updateEnterpriseProperties(controls) {
    const keys = ['source', 'target'];

    keys.forEach((key) => {
      const enterprise_id = controls[`${key}_enterprise_id`]?.value ?? null;

      if (enterprise_id) {
        const properties = this.filterProperties(enterprise_id);
        controls = updateControlOptions(controls, `${key}_property_id`, properties);
      }
    });

    return controls;
  }

  render() {
    let { controls } = this.state;
    const { title, isNew, description } = this.state;
    const { isOpen, iconName } = this.props;

    controls = this.setOptions(controls);
    controls = this.updateEnterpriseProperties(controls);

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon size="1x" name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody>
          {description && <p>{description}</p>}
          {this.props.responseMessage && (
            <div className="text-center text-danger">{this.props.responseMessage}</div>
          )}
          <Form>{this.renderInputs(controls)}</Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && this.props.onRemove && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ enterprises, manage }) => ({
  enterprises,
  manage,
});
export default connect(mapStoreToProps)(TransferModal);
