import React from 'react';
import { connect } from 'react-redux';
import { UncontrolledCollapse, Card, Row, Col } from 'reactstrap';
import PillPicker from '../../../core/form/components/PillPicker';
import PillPresenter from '../../../core/form/components/PillPresenter';

import { setFilters } from '../../../core/activity/actions';

class ClientActivitiesFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggler: '#toggler',
    };

    this.handleSystemChange = this.handleSystemChange.bind(this);
    this.handleSystemDelete = this.handleSystemDelete.bind(this);
    this.handleTypeDelete = this.handleTypeDelete.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleOrgDelete = this.handleOrgDelete.bind(this);
    this.handleOrgChange = this.handleOrgChange.bind(this);
  }

  handleSystemChange(event) {
    let { filters, params } = this.props.activities;

    const exists = filters.system.find((id) => id === event.target.value);
    if (!exists) {
      filters.system.push(event.target.value);
    }

    this.props.dispatch(setFilters(params, filters, true));
  }

  handleSystemDelete(id) {
    let { filters, params } = this.props.activities;

    const idx = filters.system.findIndex((fid) => fid === id);
    filters.system.splice(idx, 1);

    this.props.dispatch(setFilters(params, filters, true));
  }

  handleTypeChange(event) {
    let { filters, params } = this.props.activities;

    const exists = filters.associate_with.find((id) => id === event.target.value);
    if (!exists) {
      filters.associate_with.push(event.target.value);
    }

    this.props.dispatch(setFilters(params, filters, true));
  }

  handleTypeDelete(id) {
    let { filters, params } = this.props.activities;

    const idx = filters.associate_with.findIndex((fid) => fid === id);
    filters.associate_with.splice(idx, 1);

    this.props.dispatch(setFilters(params, filters, true));
  }

  handleOrgChange(event) {
    let { filters, params } = this.props.activities;

    const exists = filters.org_id.find((id) => id === event.target.value);
    if (!exists) {
      filters.org_id.push(event.target.value);
    }

    this.props.dispatch(setFilters(params, filters, true));
  }

  handleOrgDelete(id) {
    let { filters, params } = this.props.activities;

    const idx = filters.org_id.findIndex((fid) => fid === id);
    filters.org_id.splice(idx, 1);

    this.props.dispatch(setFilters(params, filters, true));
  }

  render() {
    const { toggler } = this.state;
    const { orgs } = this.props.manage;

    const client_orgs = orgs.map((org) => {
      return { id: org.id, name: org.name };
    });

    const { filters, system_types, org_activity_types } = this.props.activities;

    const systemSelection = filters.system;
    const orgActivityTypeSelection = filters.associate_with;
    const orgSelection = filters.org_id;

    // Set custom colour for type filters
    org_activity_types.map((type) => (type.colour = 'orange'));

    const filtersCount = Object.keys(filters)
      .map((key) => filters[key].length)
      .reduce((a, b) => a + b);

    return (
      <>
        <UncontrolledCollapse toggler={toggler}>
          <Card className="border border-warning rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col className="bg-light m-1">
                <PillPicker
                  caption="System Logs"
                  handleChange={this.handleSystemChange}
                  rows={system_types}
                  selection={systemSelection}
                  showPills={false}
                />
              </Col>
              <Col className="bg-light m-1">
                <PillPicker
                  caption="Types"
                  handleChange={this.handleTypeChange}
                  rows={org_activity_types}
                  selection={orgActivityTypeSelection}
                  showPills={false}
                />
              </Col>
              <Col className="bg-light m-1">
                <PillPicker
                  caption="Orgs"
                  handleChange={this.handleOrgChange}
                  rows={client_orgs}
                  selection={orgSelection}
                  showPills={false}
                />
              </Col>
            </Row>
          </Card>
        </UncontrolledCollapse>
        {filtersCount > 0 && (
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption="System Logs"
              handleDelete={this.handleSystemDelete}
              rows={system_types}
              selection={systemSelection}
            />
            <PillPresenter
              caption="Types"
              handleDelete={this.handleTypeDelete}
              rows={org_activity_types}
              selection={orgActivityTypeSelection}
              colourField="colour"
            />
            <PillPresenter
              caption="Orgs"
              handleDelete={this.handleOrgDelete}
              rows={client_orgs}
              selection={orgSelection}
              colourField="colour"
            />
          </div>
        )}
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    areas: store.areas,
    activities: store.activities,
    manage: store.manage,
  };
};

export default connect(mapStoreToProps)(ClientActivitiesFilter);
