import React from 'react';
import { connect } from 'react-redux';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import SearchToolbar from 'jsx/components/core/form/components/SearchToolbar';
import FormBase from '../../../../core/form/components/FormBase';
import { fetchShifts } from '../../actions/rosters/shifts';
import { controls as shiftControls } from '../../forms/rosters/shift';
import ShiftModal from './ShiftModal';

class Shifts extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      isModalOpen: false,
    };
  }

  componentDidUpdate() {
    const { forceRefresh, setRefresh } = this.props;

    if (forceRefresh && setRefresh) {
      this.loadShifts();
      if (setRefresh) setRefresh(false);
    }
  }

  handleSearchChange = async (value) => {
    await this.props.dispatch({ type: 'SET_ROSTER_SHIFT_SEARCH_VALUE', payload: value });
    this.loadShifts();
  };

  loadShifts = () => {
    const { search_value } = this.props.rosters.params;
    this.props.dispatch(fetchShifts({ search_value }));
  };

  onRefresh = () => {
    this.loadShifts();
  };

  setModal = (isModalOpen, id) => {
    this.setState({ isModalOpen, id });
  };

  render() {
    const { id, isModalOpen } = this.state;
    const { responseMessage, shifts } = this.props.rosters;

    return (
      <div className="d-flex flex-column h-100">
        <SearchToolbar
          handleSearchChange={this.handleSearchChange}
          onToggleAdd={() => this.setModal(true)}
          onReload={() => this.loadShifts()}
        />
        <ShiftModal
          id={id}
          isNew={id === null}
          isOpen={isModalOpen}
          onRefresh={this.onRefresh}
          responseMessage={responseMessage}
          setModal={this.setModal}
        />
        <GenericLsv
          controls={shiftControls}
          emptyCaption="No Shifts found"
          iconName="calendar"
          onClick={(targetId) => this.setModal(true, targetId)}
          rows={shifts}
        />
      </div>
    );
  }
}

const mapStoreToProps = ({ rosters }) => ({ rosters });
export default connect(mapStoreToProps)(Shifts);
