import React from 'react';
import { connect } from "react-redux";

import DashboardCarbonizer from './DashboardCarbonizer';
import DashboardFarmportrait from './DashboardFarmportrait';
import DashboardWipstar from './DashboardWipstar';

class Dashboard extends React.Component {
  constructor() {
    super();

    this.renderDashboard = this.renderDashboard.bind(this);
  }

  renderDashboard(app) {
    switch (app.id) {
      case 'carbonizer':
        return (<DashboardCarbonizer />);
      case 'farmmate':
        return (<DashboardFarmportrait />);
      case 'wipstar':
        return (<DashboardWipstar />);
      default: break;
    }
  }

  render() {
    const {
      currentApp
    } = this.props.realm

    const app = (currentApp ? currentApp : {});

    return (
      <>
        {this.renderDashboard(app)}
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
    sites: store.sites,
    projects: store.projects,
    areas: store.areas
  }
}

export default connect(mapStoreToProps)(Dashboard);
