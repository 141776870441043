import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  project_id: {
    ...controlDefaults,
    name: 'project_id',
    type: 'hidden',
  },
  property_id:{
    ...controlDefaults,
    placeholder: '',
    name: 'property_id',
    type: 'select',
    caption: 'Associated Property',
    validationRules: {
      minLength: 1,
      isRequired: false
    },
  },
  period_id:{
    ...controlDefaults,
    placeholder: '',
    name: 'period_id',
    type: 'select',
    caption: 'Audit Period',
    validationRules: {},
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Group Name',
    validationRules: {
      minLength: 3,
      isRequired: true
    },
  },
  from_group_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'from_group_id',
    type: 'select',
    caption: 'Template Group',
    disabled: true,
    validationRules: {
      minLength: 1,
      isRequired: true
    },
  }
}
