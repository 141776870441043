import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../core/form/components/FormInput';
import FormBase from '../../core/form/components/FormBase';
import { initControls, saveControls, updateControls } from '../../core/form/lib/validateForm';
import { controls as attributeDefControls } from '../forms/attribute_def';
import AttributeDefDefinitionLsv from './AttributeDefDefinitionLsv';

import {
  createAttributeDef,
  fetchAttributeDefs,
  updateAttributeDef,
  removeAttributeDef,
} from '../actions';

class DocumentDefModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Attribute Definition',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: cloneDeep(attributeDefControls),
    };

    this.onSave = this.onSave.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen } = this.state;
    let { id, isNew, title, controls, data } = this.state;

    const { attributeDefs, orgs } = this.props.manage;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = 'New Attribute Definition';
      controls = initControls(cloneDeep(attributeDefControls));
      controls.definition.value = [];
      id = null;
      data = {};

      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = 'Edit Attribute Definition';

        data = attributeDefs.find((def) => def.id === id);
        controls = updateControls(controls, data);
      }

      if (!controls.table_name.value) controls.table_name.value = controls.table_name.options[0].id;

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls,
      });
    }

    // Load owner orgs
    if (orgs.length > 0) {
      controls.owner_id.options = orgs.map((org) => {
        return { id: org.id, name: org.name };
      });

      // Auto-Select if only a single value in options
      if (controls.owner_id.options.length > 1) {
        controls.owner_id.options.push({
          id: null,
          name: 'Select Organisation',
        });
      }

      if (!controls.owner_id.value) {
        controls.owner_id.value = controls.owner_id.options[0].id;
        this.setState({
          controls,
        });
      }
    }
  }

  async onSave() {
    const { controls, isNew, data } = this.state;

    const formData = saveControls(controls, data);
    formData.definition = controls.definition.value;

    if (formData.definition) {
      formData.definition.map((row) => {
        if (row.editMode !== undefined) {
          delete row.editMode;
        }
        return row;
      });
    }

    let success;
    if (isNew) {
      delete formData.id;
      success = await this.props.dispatch(createAttributeDef(formData));
    } else {
      success = await this.props.dispatch(updateAttributeDef(formData));
    }

    if (success) {
      this.props.dispatch(fetchAttributeDefs());
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      `Removing ${data.table_name} definition permanently. Continue?`,
    );
    if (confirmed) {
      const success = await this.props.dispatch(removeAttributeDef(data.id));
      if (success) {
        this.props.dispatch(fetchAttributeDefs());
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  render() {
    const { controls, isOpen, title, isNew } = this.state;

    const { responseMessage } = this.props.manage;

    const iconName = 'clipboard-list';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.table_name} />
            <FormInput handleChange={this.handleChange} control={controls.owner_id} />
          </Form>
          <AttributeDefDefinitionLsv rows={controls.definition.value || []} />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ manage }) => ({
  manage,
});

export default connect(mapStoreToProps)(DocumentDefModal);
