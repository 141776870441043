import React from 'react';
import { connect } from 'react-redux';

import { Card, CardTitle, Row, Col } from 'reactstrap';

import { cardFormat, numberRuleFormat } from '../../../core/form/lib/fieldFormat';

class LivestockEnterpriseCharts extends React.Component {
  render() {
    const { summaries } = this.props.livestocks;
    let headchange_class;
    if (summaries.head_change < 0) {
      headchange_class = 'text-danger';
    }

    return (
      <React.Fragment>
        <Row>
          <Col sm={2}>
            <Card className="border border-success rounded text-center">
              <CardTitle className="bg-success text-white">Opening Valuation</CardTitle>
              <h1>{cardFormat(summaries.opening)}</h1>
            </Card>
          </Col>
          <Col sm={2}>
            <Card className="border border-success rounded text-center">
              <CardTitle className="bg-success text-white">Purchase Value</CardTitle>
              <h1>{cardFormat(summaries.purchases)}</h1>
            </Card>
          </Col>
          <Col sm={2}>
            <Card className="border border-primary rounded text-center">
              <CardTitle className="bg-primary text-white">Sale Value</CardTitle>
              <h1>{cardFormat(summaries.sales)}</h1>
            </Card>
          </Col>
          <Col sm={2}>
            <Card className="border border-success rounded text-center">
              <CardTitle className="bg-success text-white">Closing Valuation</CardTitle>
              <h1>{cardFormat(summaries.closing)}</h1>
            </Card>
          </Col>
          <Col sm={2}>
            <Card className="border border-primary rounded text-center">
              <CardTitle className="bg-primary text-white">Gross Product</CardTitle>
              <h1>{cardFormat(summaries.gross_product)}</h1>
            </Card>
          </Col>
          <Col sm={2}>
            <Card className="border border-warning rounded text-center">
              <CardTitle className="bg-warning text-white">Head Change</CardTitle>
              <h1 className={headchange_class}>
                {numberRuleFormat(summaries.head_change, { includeCommas: true })}
              </h1>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
    livestocks: store.livestocks,
  };
};

export default connect(mapStoreToProps)(LivestockEnterpriseCharts);
