import React  from 'react';
import Listview from "../../../core/form/components/Listview";

const ProfileContactsLsv = (props) => {
  const {
    rows,
    onClick
  } = props;

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'role': return (
          <td key={index} className={header.classes}>Read/Write Access to all data</td>
        )
        default: return  (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const headers = [
    {caption: 'Name', field: 'name'},
    {caption: 'Mobile', field: 'mobile'},
    {caption: 'Email', field: 'email', classes: 'text-left'},
    {caption: 'Role', field: 'role', classes: 'text-left'}
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);

  if (haveRows) {
    tableBodyTr = rows.map((project, index) =>
      <React.Fragment key={index} >
        <tr onClick={() => onClick(project)} style={{cursor: "pointer"}}>
          {renderRows(headers, project)}
        </tr>
      </React.Fragment>
    );
  }

  const iconName='users';
  const emptyCaption='No Contacts found';

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
}

export default ProfileContactsLsv;
