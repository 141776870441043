import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';

const SiteSampleChartSpectraNm = (props) => {
  let {
    samples,
    subTitle,
    height
  } = props;

  // Default chart height
  if (!height) {
    height = 300;
  }

  const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const translateKey = (spectraKey) => {
    const nm = parseInt(spectraKey.substring(1));
    return `${nm}nm`;
  }

  const spectraSamples = samples.filter(sample => sample.reading_gamma > 0);

  let spectraKeys = [];
  let chartData = [];
  if (spectraSamples.length > 0) {
    spectraKeys = Object.keys(spectraSamples[0].scan_spectra_raw);
    chartData = spectraKeys.map(spectraKey => { 
      let dataKey = {name: translateKey(spectraKey)};
      spectraSamples.map(sample => {
        dataKey[`Position_${sample.position}`] = parseFloat(sample.scan_spectra_raw[spectraKey]).toFixed(2);
        return false;
      })
      return dataKey;
    })
  }

  // Build Line for chart
  const lines = spectraSamples.map((sample, index) => {
    const dataKey = `Position_${sample.position}`;
    return (<Line key={index} dot={false} dataKey={dataKey} stroke={getRandomColor()} strokeWidth="2" type='monotone' />)
  })

  return (
    <React.Fragment>
      <div>
        <div>
          <div className="d-flex justify-content-center bg-light p-2">
            <div>{subTitle} Spectra Reading (Reflectance by Wavelength)</div>
          </div>
        </div>

        <ResponsiveContainer height={height}>
          <LineChart title="Spectra for Position" data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            {lines}
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <ReferenceLine y={1} label="Reflectance Outlier Above" stroke="red" strokeWidth="1" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </React.Fragment>
  );
}

export default SiteSampleChartSpectraNm;
