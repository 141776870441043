import React from 'react';
import { connect } from "react-redux";
import FormBase from "../../../core/form/components/FormBase";

import PipelinesToolbar from '../components/PipelinesToolbar';
import PipelinesByRoundLsv from '../components/PipelinesByRoundLsv';

import {
  setTabParams,
  executePipeline
} from '../actions';


class PipelinesPanelAreaRounds extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      activeTab: '1',
      roundSelection: [],
      searchValue: '',
      modifyDisabled: true
    })

    this.onSearch = this.onSearch.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);

    this.queueSubsampling = this.queueSubsampling.bind(this);
    this.queueCarbonCalcs = this.queueCarbonCalcs.bind(this);
    this.loadMore = this.loadMore.bind(this);

  }

  componentDidMount() {
    const { params } = this.props.areas;
    if (params.search_value) this.setState({ searchValue: params.search_value })
  }

  onSearch(event) {
    event.preventDefault();
    const { searchValue } = this.state;
    const {
      tabParams,
      activeTab
    } = this.props.pipelines;

    const params = tabParams[activeTab];

    let searchParams = {
      ...params
    }

    if (searchValue) {
      searchParams.search_value = searchValue
    } else {
      delete searchParams.search_value;
    }


    this.props.dispatch(setTabParams(activeTab, searchParams))
  }

  onSelect(event) {
    let {
      roundSelection
    } = this.state;

    const state = event.target.checked;
    const id = event.target.value;
    if (state) {
      roundSelection.push(id);
    } else {
      const idx = roundSelection.findIndex(round_id => round_id === id);
      if (idx > -1) {
        roundSelection.splice(idx, 1);
      }
    }

    this.setState({
      roundSelection
    })
  }

  onEdit(round) {
    const project_id = round.property.project.id;
    const property_id = round.property.id;

    const lnk = `/home/projects/${project_id}/properties/${property_id}/rounds/${round.id}`;
    this.props.history.push(lnk);
  }

  handleSearchChange(event) {
    this.setState({ searchValue: event.target.value })
    if (event.keyCode === 13) {
      this.onSearch(event)
    }
  }

  loadMore() {
    const {
      tabParams,
      activeTab
    } = this.props.pipelines;
    const params = tabParams[activeTab];

    this.props.dispatch(setTabParams(activeTab, { ...params, limit: (params.limit ? params.limit + 30 : 30) }))
  }

  async queueSubsampling() {
    let { roundSelection } = this.state;
    const { rounds } = this.props.pipelines;

    if (roundSelection.length === 0) window.alert('No rounds selected. Aborting');
    else {
      const confirmed = window.confirm('This will remove all previous subsampling results permanently and execute sub-selection scripts for ALL selected rounds. Are you sure you wish to proceed?')
      if (confirmed) {
        roundSelection.map(round_id => {
          const round = rounds.rows.find(round => round.id === round_id)
          if (round) {
            const pipeline = round.pipelines.find(pipeline => pipeline.key === 'subsampling')
            if (pipeline) this.props.dispatch(executePipeline(pipeline.id))
            else window.alert(`Cannot find pipeline for ${round.name}. Contact administration`)
          }
          else window.alert('Invalid round selections. Contact administration');
          return true;
        })
        this.setState({ roundSelection: [] })
      }
    }
  }

  async queueCarbonCalcs() {
    let { roundSelection } = this.state;
    const { rounds } = this.props.pipelines;

    if (roundSelection.length === 0) window.alert('No rounds selected. Aborting');
    else {

      const confirmed = window.confirm('This will execute carbon calculations for ALL selected rounds. Are you sure you wish to proceed?')
      if (confirmed) {
        roundSelection.map(round_id => {
          const round = rounds.rows.find(round => round.id === round_id)
          if (round) {
            const pipeline = round.pipelines.find(pipeline => pipeline.key === 'carboncalcs')
            if (pipeline) this.props.dispatch(executePipeline(pipeline.id))
            else window.alert(`Cannot find pipeline for ${round.name}. Contact administration`)
          }
          else window.alert('Invalid round selections. Contact administration');
          return true;
        })
        this.setState({ roundSelection: [] })
      }
    }
  }

  render() {
    const {
      searchValue,
      roundSelection
    } = this.state;

    let {
      pipelines,
      rounds
    } = this.props.pipelines;

    if (rounds.rows) rounds.rows = rounds.rows.map(round => {
      return {
        ...round,
        allowSelect: round.subsample_type === 'property_round'
      }
    })

    return (
      <div className="p-3">
        <PipelinesToolbar
          handleSearchChange={this.handleSearchChange}
          searchPlaceholder='Search Rounds'
          searchValue={searchValue}
          queueSubsampling={this.queueSubsampling}
          queueCarbonCalcs={this.queueCarbonCalcs}
          checkAccess={this.checkAccess}
        />
        <PipelinesByRoundLsv
          rows={rounds || []}
          pipelines={pipelines}
          pagination={true}
          onEdit={this.onEdit}
          onLoadMore={this.loadMore}
          rowSelection={roundSelection}
          handleSelect={this.onSelect}
        />
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    areas: store.areas,
    properties: store.properties,
    pipelines: store.pipelines,
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(PipelinesPanelAreaRounds);
