import React from 'react';
import { Button, Label } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import SingleSearch from '../../../../core/form/components/SingleSearch';

const TaskManagementToolbar = (props) => {
  const { checkAccess, reload, filterValue, handleSearchChange, projects, setModal } = props;

  const projectCount = `${projects.length} projects`;

  return (
    <div className="m-1 d-flex justify-content-end">
      <Label className="m-1 mr-2 text-secondary">{projectCount}</Label>
      <SingleSearch
        handleSearchChange={handleSearchChange}
        placeholder="Filter by keyword"
        value={filterValue}
      />
      <Button onClick={reload} size="sm" color="success" className="mr-2">
        <Icon name="rotate" />
      </Button>

      <Button
        onClick={() => setModal(true)}
        disabled={!checkAccess('projectCreate')}
        size="sm"
        color="success"
      >
        Add New Project
      </Button>
    </div>
  );
};

export default TaskManagementToolbar;
