import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { updateControls, initControls, saveControls } from '../../../core/form/lib/validateForm';
import { areaRoundControls } from '../forms/area_rounds.js';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import Icon from 'jsx/components/core/icons/Icon';

import { createAreaRound, fetchAreas, fetchAreaRound, updateAreaRound } from '../actions';

class AreaRoundModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Area Round',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: areaRoundControls,
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async componentDidUpdate() {
    let { id, isNew, title, controls } = this.state;
    const { isOpen } = this.state;
    const { periods } = this.props.periods;
    const { propertyRounds } = this.props.properties;
    const area = this.props.area;

    if (periods) {
      controls['project_period_id'].options = periods;
      if (!controls['project_period_id'].value) {
        controls['project_period_id'].value = periods[0].id;
        this.setState({ controls });
      }
    }

    if (propertyRounds) {
      const emptyValue = '-';
      const emptyOption = [{ id: null, name: emptyValue }];

      controls['property_round_id'].options = emptyOption.concat(propertyRounds);
      if (controls['property_round_id'].value === emptyValue) {
        controls['property_round_id'].value = null;
        this.setState({ controls });
      }
    }

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = `New Round for CEA${area.name}`;
      this.setState({ controls: initControls(this.state.controls) });

      if (this.props.id) {
        isNew = false;
        title = `Round for CEA${area.name}`;
        id = this.props.id;

        this.props.dispatch(fetchAreaRound(id)).then((data) => {
          controls = updateControls(controls, data);
          this.setState({ controls });
        });
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        area,
        title,
        isNew,
        setModal: this.props.setModal,
      });
    }
  }

  async onSave() {
    let { data } = this.state;
    const { controls, isNew, area } = this.state;

    data = saveControls(controls, data);

    let success;
    if (isNew) {
      delete data.id;
      data.area_id = area.id;
      success = await this.props.dispatch(createAreaRound(data));
    } else {
      success = await this.props.dispatch(updateAreaRound(data));
    }

    if (success) {
      this.props.dispatch(fetchAreas(area.property_id));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      `Removing ${data.name.toUpperCase()} period permanently. Continue?`
    );
    if (confirmed) {
      // const success = await this.props.dispatch(removePeriod(data.id));
      // if (success) {
      //   // this.props.dispatch(fetchPeriods(data.project_id));
      //   this.state.setModal(false);
      //   this.setState({isOpen: false});
      // };
    }
  }

  render() {
    const { controls, isOpen, title, isNew } = this.state;

    const { responseMessage } = this.props.areas;

    const iconName = 'circle-notch';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput handleChange={this.handleChange} control={controls.start_date} />
            <FormInput handleChange={this.handleChange} control={controls.end_date} />
            <FormInput handleChange={this.handleChange} control={controls.project_period_id} />
            <FormInput handleChange={this.handleChange} control={controls.property_round_id} />
            <FormInput handleChange={this.handleChange} control={controls.soc_tph} />
            <FormInput handleChange={this.handleChange} control={controls.sequence} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={true}>
              Delete
            </Button>
          )}
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
            <Button size="sm" color="success" onClick={this.onSave}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    areas: store.areas,
    periods: store.periods,
    properties: store.properties,
  };
};

export default connect(mapStoreToProps)(AreaRoundModal);
