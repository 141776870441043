import * as React from 'react';
import { useFormContext, FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { fromPairs, isNil } from 'lodash';
import { Controls } from '.';

export type OnInvalid = (
  errors: Record<string, any>,
  e?: React.BaseSyntheticEvent<Record<string, any>, Record<string, any>, Record<string, any>>,
) => void;
export type OnSubmit = (
  data: Record<string, unknown>,
  dirtyFields?: Record<string, unknown>,
  e?: React.BaseSyntheticEvent<Record<string, any>, Record<string, any>, Record<string, any>>,
) => void;

// Future enhancement - this should be typed based on the controls passed in
type ControlString = string;

interface FormContextState<T extends string> {
  controls: Controls<T>;
  setControls: React.Dispatch<React.SetStateAction<Controls<string>>>;
}

const FormContext = React.createContext<FormContextState<ControlString>>({
  controls: {},
  setControls: () => undefined,
});

interface CarbonizerFormProviderProps {
  controls: Controls<ControlString>;
  children: React.ReactNode;
}

/**
 * Provides form state to form inputs
 * @returns
 */
const CarbonizerFormProvider = ({
  controls: initialControlValue,
  children,
}: CarbonizerFormProviderProps) => {
  const [controls, setControls] = React.useState(initialControlValue);

  // This is inefficient, but it allows options to be added to a select after the form is rendered
  React.useEffect(() => {
    setControls(initialControlValue);
  }, [initialControlValue]);

  return (
    <FormProvider {...useForm()}>
      <FormContext.Provider value={{ controls, setControls }}>{children}</FormContext.Provider>
    </FormProvider>
  );
};

/**
 * For internal use only.
 *
 * useCarbonizerForm is a hook that provides access to the form context.
 *
 * @returns {FormContextState} The form context state
 */
function useCarbonizerForm() {
  const context = React.useContext(FormContext);
  if (context === undefined) {
    throw new Error('useCarbonizerForm must be used within a CarbonizerFormProvider');
  }

  return context;
}

interface FormConsumerProps {
  children: (context: UseFormReturn) => React.ReactNode;
  isOpen: boolean;
}

export const selectDefaultForControls = (controls: Controls<string>) =>
  isNil(controls)
    ? {}
    : fromPairs(
        Object.values(controls).map((control) => {
          const { name, value, type } = control;
          if (isNil(value)) {
            if (type === 'select') {
              return [name, control.options?.[0]?.value];
            }
          }
          return [name, value];
        }),
      );

const FormConsumer = ({ children, isOpen }: FormConsumerProps) => {
  const { controls } = React.useContext(FormContext);
  const valuesFromControls = React.useMemo(() => selectDefaultForControls(controls), [controls]);

  const formContext = useFormContext();

  const { reset } = formContext;

  React.useEffect(() => {
    // Reset form when modal is changes between open and closed
    reset(valuesFromControls);
  }, [isOpen, reset, valuesFromControls]);

  return children(formContext);
};

export { CarbonizerFormProvider, useCarbonizerForm, FormConsumer };
