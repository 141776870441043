import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Nav, TabContent, TabPane } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import MonthPicker from '../../../core/form/components/MonthPicker';

import ResourceUsagePercentByEntities from '../components/ResourceUsagePercentByEntities';
import ResourceUsageHoursByEntities from '../components/ResourceUsageHoursByEntities';
import ResourceUsageHoursTiles from '../components/ResourceUsageHoursTiles';
import ResourceUsageDevTiles from '../components/ResourceUsageDevTiles';
// import ResourceCostsByEntities from "../components/ResourceCostsByEntities";
// import ResourceCostsTiles from "../components/ResourceCostsTiles";

import { controls } from '../forms/timekeeper';

import { fetchResourceUsage, fetchResourceUsageSummary } from '../actions';

import { fetchOrgs } from '../../../manage/actions';

class Resources extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: controls,
      isNew: false,
      percentUsages: [],
      hourUsages: [],
      costUsages: [],
      orgs: [],
      hourTiles: {},
      devTiles: {},
      costTiles: {},
      allbyweek: null,
      isSummaryModalOpen: false,
      fromSelected: new Date('2021-12-01'),
      toSelected: new Date(),
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.setSummaryModal = this.setSummaryModal.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.loadResourceUsage = this.loadResourceUsage.bind(this);
  }

  componentDidMount() {
    this.loadResourceUsage();
  }

  async loadResourceUsage() {
    const { colours } = this.props.realm;

    const { fromSelected, toSelected } = this.state;

    // Get valid orgs and assign colour
    const orgs = await this.props.dispatch(fetchOrgs());

    const derivedOrgs = orgs.map((org, index) => {
      org.colour = colours[index];
      return org;
    });

    const rows = await this.props.dispatch(
      fetchResourceUsage({ fromDate: fromSelected, toDate: toSelected })
    );
    const summaries = await this.props.dispatch(
      fetchResourceUsageSummary({ fromDate: fromSelected, toDate: toSelected })
    );

    // Build Resource Usage percent
    let percentUsages = [];
    let hourUsages = [];
    let hourTiles = [];
    let devTiles = [];
    let costUsages = [];
    let costTiles = [];
    let idx;

    rows.map((row) => {
      // Remove this week cause it is rarely a complete reservation of the whole week
      const thisWeek = moment().format('YYYY-W');
      if (thisWeek === row.year_week) return false;

      // Find summary
      const summary = summaries.find((summary) => summary.year_week === row.year_week);

      // Percent Usage
      if (summary) {
        idx = percentUsages.findIndex((usage) => usage.week === row.year_week);
        if (idx > -1) {
          percentUsages[idx][row.name] = row.pct;
        } else {
          let percentUsage = { week: row.year_week };
          percentUsage[row.name] = row.pct;
          percentUsage['capacity'] = summary.capacity;
          percentUsages.push(percentUsage);
        }

        // Hour/Cost tiles
        const org = orgs.find((org) => org.name === row.name);
        if (org) {
          hourTiles[org.name] = (hourTiles[org.name] || 0) + row.hours;
          devTiles[org.name] = (devTiles[org.name] || 0) + row.hours / 40;
          costTiles[org.name] = (costTiles[org.name] || 0) + row.rate;

          // Hour usage
          idx = hourUsages.findIndex((usage) => usage.week === row.year_week);
          if (idx > -1) {
            hourUsages[idx][row.name]
              ? (hourUsages[idx][row.name] += row.hours)
              : (hourUsages[idx][row.name] = row.hours);
          } else {
            let hourUsage = { week: row.year_week };
            hourUsage[row.name] = row.hours;
            hourUsage['capacity'] = summary.capacity;
            hourUsage['effective_storypoints'] = summary.effective_storypoints;
            hourUsage['total_hours'] = summary.hours;
            hourUsages.push(hourUsage);
          }

          // Cost usage
          idx = costUsages.findIndex((usage) => usage.week === row.year_week);
          if (idx > -1) {
            costUsages[idx][row.name] = row.rate;
          } else {
            let costUsage = { week: row.year_week };
            costUsage[row.name] = row.rate;
            costUsage['capacity'] = summary.capacity;
            costUsages.push(costUsage);
          }
        }
      }
      return false;
    });

    this.setState({
      percentUsages,
      orgs: derivedOrgs,
      hourUsages,
      costUsages,
      hourTiles,
      devTiles,
      costTiles,
    });
  }

  setSummaryModal(isSummaryModalOpen) {
    this.setState({ isSummaryModalOpen });
  }

  handleMonthChange(field, value) {
    if (field === 'from_date') {
      this.setState({ fromSelected: value }, () => this.loadResourceUsage());
    } else {
      this.setState({ toSelected: value }, () => this.loadResourceUsage());
    }
  }

  render() {
    const { activeTabResources } = this.props.office;

    const {
      percentUsages,
      hourUsages,
      hourTiles,
      devTiles,
      // costUsages,
      // costTiles,
      orgs,
      fromSelected,
      toSelected,
    } = this.state;

    const iconName = 'people-group';

    return (
      <div className="p-2">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row m-2">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Resources</h3>
          </div>
        </div>

        <Nav tabs className="mt-2">
          <FormTab
            iconRightClass="primary"
            iconRightName="stopwatch"
            caption="Resource Usage"
            tabId="1"
            tabTag="usage"
            activeTab={activeTabResources}
            toggle={this.toggleTab}
          />
          <FormTab
            iconRightClass="primary"
            iconRightName="binoculars"
            caption="Forecast"
            tabId="2"
            tabTag="forecast"
            activeTab={activeTabResources}
            toggle={this.toggleTab}
            disabled={true}
          />
          <FormTab
            iconRightClass="primary"
            iconRightName="calendar-days"
            caption="Planning"
            tabId="3"
            tabTag="planning"
            activeTab={activeTabResources}
            toggle={this.toggleTab}
            disabled={true}
          />
        </Nav>

        <TabContent activeTab={activeTabResources}>
          <TabPane tabId="1" className="mb-2 p-3">
            <div className="bg-light border-bottom border-secondary d-flex justify-content-start p-1">
              <MonthPicker
                className="m-1"
                id="from_month"
                caption="From:"
                minimum="2021-12-01"
                selected={fromSelected}
                handleChange={(value) => this.handleMonthChange('from_date', value)}
              />
              <MonthPicker
                className="m-1"
                id="to_month"
                caption="To:"
                selected={toSelected}
                handleChange={(value) => this.handleMonthChange('to_date', value)}
              />
            </div>

            <h5 className="text-center mt-2">Average Developer per Entity</h5>
            <ResourceUsageDevTiles data={devTiles} orgs={orgs} count={hourUsages.length} />

            <h5 className="text-center mt-2">Percent Usage by Entity</h5>
            <div className="text-center">
              <small>Grouped by week</small>
            </div>
            <ResourceUsagePercentByEntities data={percentUsages} orgs={orgs} />

            <h5 className="text-center mt-4">Hour Usage by Entity</h5>
            <div className="text-center">
              <small>Grouped by week</small>
            </div>
            <ResourceUsageHoursTiles data={hourTiles} orgs={orgs} />
            <ResourceUsageHoursByEntities data={hourUsages} orgs={orgs} />

            {/* <h5 className="text-center mt-4">Resource Costs by Entity</h5>
            <div className="text-center"><small>Grouped by week</small></div>
            <ResourceCostsTiles data={costTiles} orgs={orgs}/>
            <ResourceCostsByEntities data={costUsages} orgs={orgs} /> */}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    office: store.office,
    profile: store.profile,
    manage: store.manage,
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(Resources);
