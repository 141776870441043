import { get, put } from '../../../../lib/genericAction';

import { apiOrgs } from '../../../../constants/api.js';

export function updateOrgSubscriptions(orgId, data) {
  return async (dispatch) =>
    await put(dispatch, 'UPDATE_ORG_SUBSCRIPTIONS', `${apiOrgs}/${orgId}/subscriptions`, data);
}

export function fetchOrgSubscriptions(orgId) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_ORG_SUBSCRIPTIONS', `${apiOrgs}/${orgId}/subscriptions`);
}
