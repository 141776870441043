import { get, post, put, remove, upload } from '../../../../lib/genericAction';
import {
  apiSites,
  apiSamples,
  apiStratas,
  apiStatuses,
  apiUsers,
  apiFieldTrips,
  apiFieldTripSites,
  apiFieldUploads,
  apiScans,
  apiScanSamples,
  apiScripts,
  apiScanTags,
  apiFieldInstruments,
} from '../../../../constants/api.js';

export function fetchFieldInstruments() {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_FIELD_INSTRUMENTS', `${apiFieldInstruments}`);
}

export function fetchFieldUpload(id, max_distance) {
  const params = { max_distance };
  return async (dispatch) =>
    await get(dispatch, 'FETCH_FIELDUPLOAD', `${apiFieldUploads}/${id}`, params);
}

export function fetchFieldUploadSites(id, max_distance) {
  const params = { max_distance };

  return async (dispatch) =>
    await get(dispatch, 'FETCH_FIELDUPLOAD_SITES', `${apiFieldUploads}/${id}/sites`, params);
}

export function fetchFieldUploads() {
  return async (dispatch) => await get(dispatch, 'FETCH_FIELDUPLOADS', `${apiFieldUploads}`);
}

export function removeFieldUpload(id) {
  return async (dispatch) =>
    await remove(dispatch, 'REMOVE_FIELDUPLOAD', `${apiFieldUploads}/${id}`);
}

export function createFieldUpload(formData) {
  return async (dispatch) =>
    await post(dispatch, 'CREATE_FIELDUPLOAD', `${apiFieldUploads}`, formData);
}

export function fetchFieldTripSites(params, inBackground = false) {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_FIELDTRIP_SITES',
      `${apiFieldTripSites}/sites`,
      params,
      null,
      inBackground
    );
}

export function updateFieldTripSite(data) {
  return async (dispatch) =>
    await put(dispatch, 'UPDATE_FIELDTRIP_SITE', `${apiFieldTripSites}/${data.id}`, data);
}

export function createFieldTripSite(data) {
  return async (dispatch) =>
    await post(dispatch, 'CREATE_FIELDTRIP_SITE', `${apiFieldTripSites}/sites`, data);
}

export function removeFieldTripSite(id) {
  return async (dispatch) =>
    await remove(dispatch, 'REMOVE_FIELDTRIP_SITES', `${apiFieldTripSites}/sites/${id}`);
}

export function fetchFieldTrips() {
  return async (dispatch) => await get(dispatch, 'FETCH_FIELDTRIPS', `${apiFieldTrips}`);
}

export function fetchFieldTrip(id) {
  return async (dispatch) => await get(dispatch, 'FETCH_FIELDTRIP', `${apiFieldTrips}/${id}`);
}

export function createFieldTrip(data) {
  return async (dispatch) => await post(dispatch, 'CREATE_FIELDTRIP', `${apiFieldTrips}`, data);
}

export function updateFieldTrip(data) {
  return async (dispatch) =>
    await put(dispatch, 'UPDATE_FIELDTRIP', `${apiFieldTrips}/${data.id}`, data);
}

export function bulkUpdateFieldTrip(data) {
  return async (dispatch) =>
    await post(dispatch, 'CREATE_BULK_FIELDTRIP', `${apiFieldTripSites}/sites/bulk`, data);
}

export function removeFieldTrip(id) {
  return async (dispatch) => await remove(dispatch, 'REMOVE_FIELDTRIP', `${apiFieldTrips}/${id}`);
}

export function fetchScanOperators() {
  return async (dispatch) => await get(dispatch, 'FETCH_OPERATORS_SCAN', `${apiUsers}/roles/so`);
}

export function fetchDrillOperators() {
  return async (dispatch) => await get(dispatch, 'FETCH_OPERATORS_DRILL', `${apiUsers}/roles/do`);
}

export function fetchSiteStatuses() {
  return async (dispatch) => await get(dispatch, 'FETCH_SITE_STATUSES', `${apiStatuses}?type=site`);
}

export function fetchStrataSites(strata_id) {
  return async (dispatch) => await get(dispatch, 'FETCH_SITES', `${apiStratas}/${strata_id}/sites`);
}

export function setFilters(params, filters) {
  return function (dispatch) {
    dispatch({ type: 'SET_SITE_FILTERS', payload: filters });
    dispatch(fetchSites(params, filters));
  };
}

export function setFieldtripFilters(params, filters) {
  return async function (dispatch) {
    dispatch({ type: 'SET_FIELDTRIP_FILTERS', payload: params });
    if (filters) {
      const filterKeys = Object.keys(filters);
      filterKeys.forEach((key) => {
        params[`filter_${key}`] = filters[key];
      });
    }

    return await get(dispatch, 'FETCH_SITES', `${apiSites}`, params);
  };
}

export function fetchSites(params, filters) {
  return async (dispatch) => {
    if (params !== {}) {
      dispatch({ type: 'SET_SITE_PARAMS', payload: params });
    }

    if (filters) {
      const filterKeys = Object.keys(filters);
      filterKeys.forEach((key) => {
        params[`filter_${key}`] = filters[key];
      });
    }

    return await get(dispatch, 'FETCH_SITES', `${apiSites}`, params);
  };
}

// Syncronous fetch
export function fetchSitesSync(params, filters) {
  return async (dispatch) => {
    if (filters) {
      const filterKeys = Object.keys(filters);
      filterKeys.forEach((key) => {
        params[`filter_${key}`] = filters[key];
      });
    }

    return await get(dispatch, 'FETCH_SITES_SYNC', `${apiSites}`, params);
  };
}

export function clearScan(id) {
  return async (dispatch) => await put(dispatch, 'CLEAR_SCAN', `${apiSites}/${id}/clearscan`);
}

export function moveScan(source_id, destination_core_id) {
  return async (dispatch) =>
    await put(dispatch, 'MOVE_SCAN', `${apiSites}/${source_id}/move/${destination_core_id}`);
}

export function updateSite(formData) {
  return async (dispatch) =>
    await put(dispatch, 'UPDATE_SITE', `${apiSites}/${formData.get('id')}`, formData);
}

// Synchronous site fetch
export function fetchSite(id) {
  return async (dispatch) => await get(dispatch, 'FETCH_SITE', `${apiSites}/${id}`);
}

export function uploadSamples(formData) {
  return async (dispatch) =>
    await upload(
      dispatch,
      'UPLOAD_SITE_SAMPLES',
      `${apiSites}/${formData.get('site_id')}/samples/upload`,
      formData
    );
}

export function fetchSiteSamples(params) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_SITE_SAMPLES', `${apiSites}/${params.site_id}/samples`);
}

export function fetchMultiSiteSamples(formData) {
  return async (dispatch) =>
    await post(dispatch, 'FETCH_MULTISITE_SAMPLES', `${apiSamples}`, formData);
}

export function fetchSiteSample(id) {
  return async (dispatch) => await get(dispatch, 'FETCH_SITE_SAMPLE', `${apiSamples}/${id}`);
}

export function updateSiteSample(data) {
  return async (dispatch) =>
    await put(dispatch, 'UPDATE_SITE_SAMPLE', `${apiSamples}/${data.id}`, data);
}

export function fetchSiteScans(params) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_SITE_SCANS', `${apiSites}/${params.site_id}/scans`);
}

export function fetchScanTags(params) {
  return async (dispatch) => await get(dispatch, 'FETCH_SCAN_TAGS', `${apiScanTags}`, params);
}

export function fetchArchivedScans(params, filters) {
  return async (dispatch) => {
    if (filters) {
      const filterKeys = Object.keys(filters);
      filterKeys.forEach((key) => {
        params[`filter_${key}`] = filters[key];
      });
    }

    return await get(dispatch, 'FETCH_ARCHIVED_SCANS', `${apiScans}`, params);
  };
}

export function fetchArchivedScanSamples(params) {
  return async (dispatch) => {
    return await get(dispatch, 'FETCH_ARCHIVED_SCAN_SAMPLES', `${apiScanSamples}`, params);
  };
}

export function uploadArchivedScanSampleAttributes(formData, onProgressChange) {
  return async (dispatch) =>
    await upload(
      dispatch,
      'UPLOAD_SCAN_SAMPLE_ATTRIBUTES',
      `${apiScanSamples}/upload`,
      formData,
      onProgressChange
    );
}

export function setScanFilters(params, filters) {
  return function (dispatch) {
    dispatch({ type: 'SET_SCAN_FILTERS', payload: filters });
    dispatch(fetchArchivedScans(params, filters));
  };
}

export function removeSiteScan(id, site_id) {
  return async (dispatch) => {
    const result = await remove(dispatch, 'REMOVE_SITE_SCAN', `${apiScans}/${id}`);
    dispatch(fetchSiteScans({ site_id: site_id }));
    return result;
  };
}

// Synchronous site spline fetch
export function fetchSiteSplinesByCoreId(core_id) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_SITE_SPLINES', `${apiSites}/null/splines?core_id=${core_id}`);
}

export function fetchSiteScripts() {
  return async (dispatch) => await get(dispatch, 'FETCH_SITE_SCRIPTS', `${apiScripts}?type=site`);
}

export function fetchDashboardStatsBySites() {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_DASHBOARD_STATS_BYSITES',
      `${apiSites}/statistics/dashboard`,
      null,
      null,
      true
    );
}

export function fetchSiteImages(siteId) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_SITE_IMAGES', `${apiSites}/${siteId}/images`, null, null, true);
}
