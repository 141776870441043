import React from 'react';
import { Row } from 'reactstrap';
import WidgetCell from './WidgetCell';

const ReportSectionWidgets = (props) => {
  const { row, attributes, functions, currentReloadValue = null, report_id } = props;

  const renderColumns = () => {
    const cols = row.cols.sort((previous, next) => previous.sequence - next.sequence);
    return cols.map((col) => (
      <WidgetCell
        report_id={report_id}
        key={`widget-column-${col.id}`}
        cell={col}
        attributes={attributes}
        functions={functions}
        currentReloadValue={currentReloadValue}
      />
    ));
  };

  const className = `p-0 m-0 ${row.className}`;
  return <Row className={className}>{renderColumns()}</Row>;
};

export default ReportSectionWidgets;
