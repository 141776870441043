import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { HalfCircleSpinner } from 'react-epic-spinners';
import Icon from 'jsx/components/core/icons/Icon';

const PropertyMenu = (props) => {
  const {
    handleMapPackDownload,
    handleInsightsGenerate,
    handleReportingPackDownload,
    handleComparisonDownload,
    isInsightsGenerating,
    toggleMiniMap,
    showMiniMap,
  } = props;

  return (
    <React.Fragment>
      <div className="mt-1">
        <UncontrolledButtonDropdown>
          <DropdownToggle caret color="primary" size="sm">
            Action{' '}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header className="bg-light">
              View Actions
            </DropdownItem>
            <DropdownItem toggle={false} onClick={toggleMiniMap} className="ml-2 mr-2">
              <span>{showMiniMap ? 'Hide Map' : 'Show Map'}</span>
              <Icon name="location-pin" className="ml-2 mr-2" />
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              toggle={false}
              onClick={() => {
                handleInsightsGenerate('five_years');
              }}
              className="ml-2 mr-2 d-flex"
              disabled={isInsightsGenerating}
            >
              {isInsightsGenerating && (
                <HalfCircleSpinner size={15} color="grey" className="p-1 mr-3" />
              )}
              <span>Generate Insights - 5 years</span>
            </DropdownItem>

            <DropdownItem
              toggle={false}
              onClick={() => {
                handleInsightsGenerate('seven_years');
              }}
              className="ml-2 mr-2 d-flex"
              disabled={isInsightsGenerating}
            >
              {isInsightsGenerating && (
                <HalfCircleSpinner size={15} color="grey" className="p-1 mr-3" />
              )}
              <span>Generate Insights - 7 years</span>
            </DropdownItem>

            <DropdownItem divider />
            <DropdownItem header className="bg-light">
              Download Actions
            </DropdownItem>

            <DropdownItem
              toggle={false}
              onClick={handleReportingPackDownload}
              className="ml-2 mr-2"
            >
              <span>Download Reporting Pack</span>
            </DropdownItem>

            <DropdownItem toggle={false} onClick={handleComparisonDownload} className="ml-2 mr-2">
              <span>Download Round Comparison</span>
            </DropdownItem>

            <DropdownItem toggle={false} onClick={handleMapPackDownload} className="ml-2 mr-2">
              <span>Download Map Pack</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </React.Fragment>
  );
};

export default PropertyMenu;
