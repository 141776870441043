import React from 'react';

import { FormGroup, Button, Input } from 'reactstrap';

const LoginLocal = (props) => {
  const { loginMessage, handleChange, login, onLogin, staging } = props;

  // Set environment link
  const stagingPrefix = 'staging-';
  let environment;
  let environmentUrl;
  if (staging) {
    environment = 'Production Environment';
    const prod_hostname = window.location.hostname.substring(stagingPrefix.length);
    environmentUrl = `${window.location.origin.replace(
      window.location.hostname,
      prod_hostname
    )}/login`;
  } else {
    environment = 'Staging Environment';
    const staging_hostname = `${stagingPrefix}${window.location.hostname}`;
    environmentUrl = `${window.location.origin.replace(
      window.location.hostname,
      staging_hostname
    )}/login`;
  }

  return (
    <div>
      {!loginMessage && (
        <small className="text-secondary">Please enter your user information</small>
      )}
      {loginMessage && <p className="text-danger">{loginMessage}</p>}

      <FormGroup>
        <Input
          type="email"
          id="email"
          name="email"
          value={login.email}
          placeholder="Email Address"
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="password"
          id="password"
          name="password"
          value={login.password}
          placeholder="Password"
          onChange={handleChange}
        />
      </FormGroup>

      <Button onClick={onLogin} className="bg-corporate border-corporate mt-3 w-100">
        Login
      </Button>
      <div className="m-2 d-flex justify-content-between">
        <small>
          <a href="/forgot">Forgot Password?</a>
        </small>
        <small>
          <a href={environmentUrl}>{environment}</a>
        </small>
      </div>
    </div>
  );
};

export default LoginLocal;
