/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import { Button, ButtonGroup, Nav, TabContent, TabPane } from 'reactstrap';

import FormTab from 'jsx/components/core/form/components/FormTab';
import { controls as controlsAnimalClassValue } from '../forms/animalClassValues';
import { controls as controlsAssumptionValues } from '../../../manage/forms/livestock_assumption_values';
import { LivestockMonthlyGrid } from './LivestockMonthlyGrid';

const possibleValueTypes = [
  { caption: 'AU Rating', value: 'au_rating' },
  { caption: 'Values ($/kg)', value: 'value' },
  { caption: 'Weights (kg/head)', value: 'weight' },
];
interface LivestockValueLsvProps {
  divisionTag: string;
  isAssumedValuesToggled: boolean;
  showToggle: boolean;
  isAdmin: boolean;
  onClick: (recordId: string | null) => void;
  onRefresh: () => void;
}

type LivestockValueLsvTabs = 'list' | 'byMonth';

export const LivestockValueLsv = ({
  onClick,
  onRefresh,
  isAdmin = false,
}: LivestockValueLsvProps) => {
  const { values, byMonth } = useSelector((state: any) => state.animal_classes);
  const { rows: assumedValues } = useSelector((state: any) => state.livestock_assumptions.values);
  const { byMonth: byMonthAssumptions } = useSelector((state: any) => state.livestock_assumptions);
  const { isLivestockAssumptionsToggled } = useSelector((state: any) => state.livestocks);

  const valueType = isAdmin ? byMonthAssumptions.valueType : byMonth.valueType;
  const [activeTab, setActiveTab] = useState<LivestockValueLsvTabs>('byMonth');

  const dispatch = useDispatch();
  const changeValueType = useCallback(
    async (newValueType: string) => {
      const type = isAdmin
        ? 'SET_ANIMAL_CLASS_ASSUMED_VALUES_BY_MONTH_VALUE_TYPE'
        : 'SET_ANIMAL_CLASS_VALUES_BY_MONTH_VALUE_TYPE';
      await dispatch({
        type,
        payload: newValueType,
      });
      onRefresh();
    },
    [dispatch, isAdmin, onRefresh],
  );

  const buttons = possibleValueTypes
    .map(({ caption, value }, index) => {
      const chosen = valueType === value;
      if (isAdmin && value !== 'value') return false;

      return (
        <Button
          key={index}
          color="primary"
          className="py-2"
          outline={!chosen}
          onClick={() => changeValueType(value)}
        >
          {caption}
        </Button>
      );
    })
    .filter(Boolean);

  const listviewOptions = { rows: values, emptyCaption: 'No values found' };
  if (isAdmin) {
    listviewOptions.rows = assumedValues;
    listviewOptions.emptyCaption = 'No Benchmark Values found';
  }

  if (isLivestockAssumptionsToggled) {
    listviewOptions.rows = [];
    listviewOptions.emptyCaption = 'Please view Benchmarking Values in By Month tab';
  }

  return (
    <>
      <div className="d-flex flex-row justify-content-between">
        <Nav pills className="mt-2">
          <FormTab caption="By month" tabId="byMonth" activeTab={activeTab} toggle={setActiveTab} />
          <FormTab caption="List" tabId="list" activeTab={activeTab} toggle={setActiveTab} />
        </Nav>
        {activeTab === 'byMonth' && <ButtonGroup className="my-2">{buttons}</ButtonGroup>}
      </div>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="byMonth" className="mb-2 p-1">
          <LivestockMonthlyGrid
            isAdmin={isAdmin}
            isAssumedValuesToggled={isLivestockAssumptionsToggled}
            onRefresh={onRefresh}
            valueType={valueType}
          />
        </TabPane>
        <TabPane tabId="list" className="mb-2 p-1">
          <GenericLsv
            controls={isAdmin ? controlsAssumptionValues : controlsAnimalClassValue}
            iconName="paw"
            emptyCaption={listviewOptions.emptyCaption}
            onClick={onClick}
            rows={listviewOptions.rows}
          />
        </TabPane>
      </TabContent>
    </>
  );
};
