import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  description: {
    ...controlDefaults,
    name: 'description',
    type: 'text',
    caption: 'Period',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  probe_period: {
    ...controlDefaults,
    name: 'probe_period',
    type: 'text',
    caption: 'Probe Period',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_interval_id: {
    ...controlDefaults,
    name: 'transaction_interval_id',
    controlType: 'interval_date',
    linkedWith: 'date',
    type: 'select',
    caption: 'Interval',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
    disabled: true,
  },
  date: {
    ...controlDefaults,
    name: 'date',
    caption: 'Quarter',
    type: 'date',
    validationRules: {
      isRequired: true,
    },
    showInListview: false,
    showInEditor: false,
  },
  is_closed: {
    ...controlDefaults,
    name: 'is_closed',
    field_name: 'is_closed',
    controlType: 'switch',
    type: 'checkbox',
    caption: 'Closed',
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    validationRules: {
      isRequired: false,
    },
    defaultValue: false,
    showInEditor: true,
    showInListview: false,
  },
};
