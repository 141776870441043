import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    business_incomes: [],
    activeOtherBusinessTab: '9',
  },
  action
) {
  // Set expected reducer types
  const types = ['BUSINESS'];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_BUSINESS_INCOMES_FULFILLED': {
      return {
        ...state,
        business_incomes: action.payload,
      };
    }

    case 'SET_BUSINESS_OTHER_BUSINESS_ACTIVE_TAB': {
      return {
        ...state,
        activeOtherBusinessTab: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
