import { cloneDeep } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Collapse, Card, Col, Row } from 'reactstrap';
import PillPicker from '../../core/form/components/PillPicker';
import PillPresenter from '../../core/form/components/PillPresenter';
import { fetchUsers, setUserFilters } from '../actions';

class UsersFilter extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  async handleChange(event) {
    const { value } = event.target;
    const { filters, params } = this.props.manage;
    let updatedFilters = cloneDeep(filters);

    // Add filter to users
    const exists = filters.users.find((id) => id === value);
    if (!exists) updatedFilters.users.push(value);

    // Update reducer
    this.props.dispatch(setUserFilters(updatedFilters)).then(({ payload }) => {
      this.props.dispatch(fetchUsers(params, payload));
    });
  }

  handleDelete(id) {
    const { filters, params } = this.props.manage;
    let updatedFilters = cloneDeep(filters);

    // Remove filter from categories
    const idx = filters.users.findIndex((filterId) => filterId === id);
    if (idx !== -1) updatedFilters.users.splice(idx, 1);

    // Update reducer
    this.props.dispatch(setUserFilters(updatedFilters)).then(({ payload }) => {
      this.props.dispatch(fetchUsers(params, payload));
    });
  }

  render() {
    const { isFilterOpen } = this.props;
    const { filters } = this.props.manage;

    const caption = 'User';
    const selection = filters.users;

    const filtersCount = Object.keys(filters)
      .map((key) => filters[key].length)
      .reduce((a, b) => a + b);

    const filterTypes = [
      { id: 'enabled', name: 'Enabled' },
      { id: 'is_locked', name: 'Locked' },
    ];

    return (
      <>
        <Collapse isOpen={isFilterOpen}>
          <Card className="border border-corporate rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col sm={4} className="bg-light m-1">
                <PillPicker
                  caption={caption}
                  handleChange={this.handleChange}
                  handleDelete={this.handleDelete}
                  rows={filterTypes}
                  selection={selection}
                  showPills={false}
                />
              </Col>
            </Row>
          </Card>
        </Collapse>
        {filtersCount > 0 && (
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption={caption}
              handleDelete={this.handleDelete}
              rows={filterTypes}
              selection={selection}
              colourField="colour"
            />
          </div>
        )}
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    manage: store.manage,
  };
};

export default connect(mapStoreToProps)(UsersFilter);
