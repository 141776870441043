import { download, get, post, put, remove } from '../../../../lib/genericAction';

import {
  apiProjects,
  apiDocumentGroups
} from '../../../../constants/api.js';

export function createDocumentGroup(data) {
  return async dispatch => await post(
    dispatch,
    'CREATE_DOCUMENT_GROUP',
    `${apiProjects}/${data.project_id}/document_groups`,
    data
  )
}

export function fetchDocumentGroups(params) {
  return async dispatch => await get(
    dispatch,
    'FETCH_DOCUMENT_GROUPS',
    `${apiProjects}/${params.project_id}/document_groups`,
    params
  )
}

export function fetchDocumentTemplates(params) {
  return async dispatch => await get(
    dispatch,
    'FETCH_DOCUMENT_TEMPLATES',
    `${apiProjects}/${params.project_id}/document_groups`,
    params
  )
}

export function fetchDocumentGroup(id) {
  return async dispatch => await get(
    dispatch,
    'FETCH_DOCUMENT_GROUP',
    `${apiDocumentGroups}/${id}`
  )
}

export function updateDocumentGroup(data) {
  return async dispatch => await put(
    dispatch,
    'UPDATE_DOCUMENT_GROUP',
    `${apiDocumentGroups}/${data.id}`,
    data
  )
}

export function removeDocumentGroup(id) {
  return async dispatch => await remove(
    dispatch,
    'REMOVE_DOCUMENT_GROUP',
    `${apiDocumentGroups}/${id}`
  )
}

export function downloadGroupItems(id, onProgressChange) {
  return async dispatch => await download(
    dispatch,
    'DOWNLOAD_DOCUMENT_GROUP_ITEMS',
    `${apiDocumentGroups}/${id}/download`,
    onProgressChange
  )
}

