import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { apiFeatureFlags } from 'jsx/constants/api';

/**
 * Look up whether a particular feature flag is enabled or disabled. This leans heavily on react-query
 * to manage caching, to avoid spamming the API. If an error occurs lookingup the flag, it defaults to false
 * @date 07/05/2023 - 12:03:45
 *
 * @param {string} featureName
 * @returns {Array} [isEnabled, isLoading]
 */
export const useFeatureFlag = (featureName: string) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['feature-flag', featureName],
    queryFn: () =>
      axios({
        method: 'GET',
        url: `${apiFeatureFlags}/${featureName}`,
      }).then((response) => response?.data?.result),
  });

  return [isError ? false : Boolean(data), isLoading];
};
