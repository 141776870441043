import React, { useState } from 'react';
import Listview from "../../core/form/components/Listview";
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../core/form/components/FormInput';

const SoilTemplateLayersLsv = (props) => {
  const {
    rows,
    template_id,
    template_type,
    onSave,
    onRemove
  } = props;

  const [changed, setChanged] = useState(false);

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'edit': return (
          <td key={index} className={header.classes}><Icon name="pen-to-square" style={{ cursor: 'pointer' }} onClick={() => { setEditMode(row, true) }} /></td>
        )
        case 'type': return (
          <td key={index} className={header.classes}>{(row[header.field] ? row[header.field] : 'string')}</td>
        )
        default: return (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const renderEditRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      let control = {
        id: `${index}-${header.field}`,
        name: header.field,
        value: row[header.field]
      }
      switch (header.field) {
        case 'layer_no':
        case 'upper_depth':
        case 'lower_depth':
        case 'mass_depth':
        case 'percentile':
          control.type = 'number';
          row[header.field] = parseInt(row[header.field])
          return (
            <td key={index} className={header.classes}>{<FormInput handleChange={((event) => handleChange(event, row))} control={control} />}</td>
          )
        case 'remove': return (
          <td key={index} className={header.classes}><Icon name="trash" style={{ cursor: 'pointer' }} onClick={() => { onRowRemove(row) }} /></td>
        )
        case 'edit': return (
          <td key={index} className={`${header.classes} pt-3`}><Icon name="check" style={{ cursor: 'pointer' }} onClick={() => { setEditMode(row, false) }} /></td>
        )
        default: return (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        )
      }
    })

    return tableTd;
  };

  const handleChange = (event, row) => {
    let name = event.target.name;
    let value = event.target.value;
    row[name] = value;
    setChanged(!changed);
  }

  const onRowRemove = (target) => {
    if (target.id) {
      onRemove(target.id)
    } else {
      let idx = rows.findIndex(row => row === target)
      if (idx > -1) {
        rows.splice(idx, 1);
      }
      setChanged(!changed);
    }
  }

  const setEditMode = async (row, value) => {
    if (!value) {
      const success = await onSave(row);
      row.editMode = !success;
      setChanged(!changed);
    } else {
      row.editMode = value;
      setChanged(!changed);
    }

  }

  const addRow = () => {
    rows.push({ template_id, layer_no: rows.length + 1, upper_depth: null, lower_depth: null, editMode: true })
    setChanged(!changed);
  }

  const headers = [
    { caption: 'Layer No', field: 'layer_no', classes: 'text-left' },
    { caption: 'Upper Depth', field: 'upper_depth', classes: 'text-left' },
    { caption: 'Lower Depth', field: 'lower_depth', classes: 'text-left' },
    { caption: 'Percentile', field: 'percentile', classes: 'text-left' },
    { caption: 'Mass Depth', field: 'mass_depth', classes: 'text-left' },
    { caption: '', field: 'remove', classes: 'text-center text-danger pt-3' },
    { caption: '', field: 'edit', classes: 'text-right text-success' }
  ]

  if (template_type === 'mass') headers.splice(1, 3);
  else if (template_type === 'percentile') headers.splice(4, 1);
  else headers.splice(3, 2);


  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      if (row.editMode) {
        return (<tr key={index}>
          {renderEditRows(headers, row)}
        </tr>)
      } else {
        return (<tr key={index}>
          {renderRows(headers, row)}
        </tr>)
      }
    });
  }

  const iconName = 'layer-group';
  const emptyCaption = 'No layers found';

  return (
    <React.Fragment>
      <div className="d-flex justify-content-end pt-1">
        <small style={{ cursor: 'pointer' }} className="text-primary" onClick={addRow}>
          <Icon name="plus" className="mr-2" />Add New Layer
        </small>
      </div>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </React.Fragment>
  );
}

export default SoilTemplateLayersLsv;
