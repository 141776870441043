import React from 'react';
import { Col } from 'reactstrap';
import AnimatedNumber from 'animated-number-react';

const StatsTile = (props) => {
  let { title, value, unit, bottomClasses, size, prefix, bottomStyles } = props;

  const formatValue = (value) => value.toLocaleString(undefined, { minimumFractionDigits: 0 });
  const bottomClassName = `text-white ${bottomClasses}`;

  let tileValue = value;
  if (typeof value !== 'string') {
    tileValue = <AnimatedNumber value={value} formatValue={formatValue} />;
  }

  if (!size) {
    size = 2;
  }

  return (
    <Col sm={size} className="bg-light border border-secondary rounded p-0 m-1">
      <h2 className="p-1">
        {prefix}
        {tileValue}
        {unit}
      </h2>
      <div className={bottomClassName} style={bottomStyles}>
        {title}
      </div>
    </Col>
  );
};

export default StatsTile;
