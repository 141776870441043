import { controlDefaults } from '../../core/form/lib/validateForm';

export const clientControls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  analysis_period_id: {
    ...controlDefaults,
    name: 'analysis_period_id',
    fieldName: 'period.description',
    type: 'select',
    caption: 'Period',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  client_status_id: {
    ...controlDefaults,
    name: 'client_status_id',
    fieldName: 'client_status.name',
    type: 'select',
    caption: 'Client Status',
    classesFromRowPath: 'client_status.classes',
    validationRules: {
      isRequired: false,
    },
    showInListview: true,
    showInEditor: true,
  },
};

export const adminControls = {
  ...clientControls,
  rcs_status_id: {
    ...controlDefaults,
    name: 'rcs_status_id',
    fieldName: 'rcs_status.name',
    type: 'select',
    caption: 'RCS Status',
    validationRules: {
      isRequired: false,
    },
    showInListview: true,
    showInEditor: true,
  },
  percentage_completed: {
    ...controlDefaults,
    placeholder: '',
    name: 'percentage_completed',
    fieldName: 'percentage_completed',
    caption: 'Percentage Completed',
    type: 'number',
    validationRules: {
      isRequired: true,
    },
    showInListview: true,
    showInEditor: true,
  },
};
