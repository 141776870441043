import React from 'react';
import { connect } from 'react-redux';

import { Card, CardTitle, Row, Col } from 'reactstrap';
import { cardFormat, numberRuleFormat } from '../../../../core/form/lib/fieldFormat';

class WoolCharts extends React.Component {
  render() {
    const { summaries } = this.props.wools;
    return (
      <>
        <Row>
          <Col sm={2}>
            <Card className="border border-success rounded text-center">
              <CardTitle className="bg-success text-white">Opening Valuation</CardTitle>
              <h1>{cardFormat(summaries?.opening ?? 0)}</h1>
            </Card>
          </Col>
          <Col sm={2}>
            <Card className="border border-success rounded text-center">
              <CardTitle className="bg-success text-white">Closing Valuation</CardTitle>
              <h1>{cardFormat(summaries?.closing ?? 0)}</h1>
            </Card>
          </Col>
          <Col sm={2}>
            <Card className="border border-primary rounded text-center">
              <CardTitle className="bg-primary text-white">Total Sales</CardTitle>
              <h1>{cardFormat(summaries?.sales ?? 0)}</h1>
            </Card>
          </Col>
          <Col sm={2}>
            <Card className="border border-primary rounded text-center">
              <CardTitle className="bg-primary text-white">Total Shorn</CardTitle>
              <h1>{numberRuleFormat(summaries?.shorn ?? 0, { includeCommas: true })}</h1>
            </Card>
          </Col>
          <Col sm={2}>
            <Card className="border border-primary rounded text-center">
              <CardTitle className="bg-primary text-white">Total Wool Collected</CardTitle>
              <h1>{numberRuleFormat(summaries?.collected ?? 0, { includeCommas: true })}</h1>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    wools: store.wools,
  };
};

export default connect(mapStoreToProps)(WoolCharts);
