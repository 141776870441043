import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  property_id: {
    ...controlDefaults,
    name: 'property_id',
    type: 'hidden',
  },
  transaction_interval_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_interval_id',
    fieldName: 'transaction_interval.name',
    controlType: 'interval_date',
    linkedWith: 'transaction_date',
    type: 'select',
    caption: 'Transaction Interval',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    fieldName: 'transaction_date',
    type: 'date',
    caption: 'Transaction Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: false,
    showInListview: true,
  },

  rainfall_mm: {
    ...controlDefaults,
    name: 'rainfall_mm',
    fieldName: 'rainfall_mm',
    type: 'number',
    caption: 'Rainfall (mm)',
    placeholder: '',
    validationRules: {
      isRequired: true,
      greaterThanZero: true,
    },
    formattingRules: {
      includeDecimals: true,
      includeCommas: true,
    },
    showInEditor: true,
    showInListview: true,
    totals: false,
  },
};
