import React from 'react';
import { connect } from "react-redux";
import {
  Button
} from 'reactstrap';
import ActivitiesLsv from '../../../core/activity/components/ActivitiesLsv';
import AreaRoundActivitiesFilter from '../components/AreaRoundActivitiesFilter';

import { fetchActivities } from '../../../core/activity/actions';

class AreaRoundPanelActivities extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      activitiesRowLimit: 30
    })

    this.loadMoreActivities = this.loadMoreActivities.bind(this);
  }

  loadMoreActivities() {
    const {
      params,
      filters
    } = this.props.activities;

    let new_params = {
      ...params,
      limit: params.limit + 30
    }

    this.props.dispatch(fetchActivities(new_params, filters));
  }

  render() {
    const {
      activities
    } = this.props.activities;

    return (
      <div>
        <div className="bg-light p-2 d-flex justify-content-end rounded mb-1">
          <Button id="toggler" size="sm" color="warning" className="text-white ml-2">Filters</Button>
        </div>
        <AreaRoundActivitiesFilter toggler="#toggler" />
        <ActivitiesLsv rows={activities || []} pagination={true} onLoadMore={this.loadMoreActivities} />
      </div>
    )
  }
}
const mapStoreToProps = (store) => {
  return {
    activities: store.activities,
  }
}

export default connect(mapStoreToProps)(AreaRoundPanelActivities);
