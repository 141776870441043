/* eslint-disable no-param-reassign */
import { useState } from 'react';
import { isEmpty } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';
import Listview from '../../../core/form/components/Listview';
import FormInput from '../../../core/form/components/FormInput';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';

const LiabilityRatesLsv = (props) => {
  const { rows } = props;
  const [changed, setChanged] = useState(false);
  const handleChange = (event, row) => {
    const { name, value } = event.target;
    row[name] = value;
    setChanged(!changed);
  };

  const handleRateChange = (event, row) => {
    const { name, value } = event.target;
    if (isEmpty(value)) {
      row[name] = '';
      setChanged(!changed);
      return;
    }

    row[name] = value / 100;
    setChanged(!changed);
  };

  const setEditMode = (row, value) => {
    row.editMode = value;
    setChanged(!changed);
  };

  const onRowRemove = (target) => {
    const idx = rows.findIndex((row) => row === target);
    if (idx > -1) {
      rows.splice(idx, 1);
    }
    setChanged(!changed);
  };

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const { classes, field, formattingRules } = header;
      let caption = row[field];

      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      switch (field) {
        case 'open_date':
          return (
            <td key={index} className={classes}>
              {row[field] ? moment(row[field]).format('Do MMM YYYY') : ''}
            </td>
          );
        case 'rate_pcnt':
          return (
            <td key={index} className={classes}>
              {caption}
            </td>
          );
        case 'edit':
          return (
            <td key={index} className={classes}>
              <Icon
                name="pen-to-square"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setEditMode(row, true);
                }}
              />
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {caption}
            </td>
          );
      }
    });

    return tableTd;
  };

  const renderEditRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const { classes, field, formattingRules } = header;
      let caption = row[field];

      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      const control = {
        id: `${index}-${field}`,
        name: field,
        value: caption,
      };

      switch (field) {
        case 'open_date':
          control.type = 'date';
          return (
            <td key={index} className={classes}>
              <FormInput handleChange={(event) => handleChange(event, row)} control={control} />
            </td>
          );

        case 'rate_pcnt':
          control.type = 'number';
          return (
            <td key={index} className={classes}>
              <FormInput handleChange={(event) => handleRateChange(event, row)} control={control} />
            </td>
          );
        case 'remove':
          return (
            <td key={index} className={classes}>
              <Icon
                name="trash"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onRowRemove(row);
                }}
              />
            </td>
          );
        case 'edit':
          return (
            <td key={index} className={`${classes} pt-3`}>
              <Icon
                name="check"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setEditMode(row, false);
                }}
              />
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {row[field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const addRow = () => {
    rows.push({ open_date: null, close_date: null, rate_pcnt: null, editMode: true });
    setChanged(!changed);
  };

  const headers = [
    { caption: 'Open Date', field: 'open_date', classes: 'text-left' },
    {
      caption: 'Interest Rate (%)',
      field: 'rate_pcnt',
      classes: 'text-left',
      formattingRules: { asPercentage: true, includeDecimals: 'default' }, // don't format the decimal places as the user is typing them in.
    },
    { caption: '', field: 'remove', classes: 'text-center text-danger pt-3' },
    { caption: '', field: 'edit', classes: 'text-right text-success' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      if (row.editMode) {
        return <tr key={index}>{renderEditRows(headers, row)}</tr>;
      } else {
        return (
          <tr
            key={index}
            onClick={() => {
              setEditMode(row, true);
            }}
            style={{ cursor: 'pointer' }}
          >
            {renderRows(headers, row)}
          </tr>
        );
      }
    });
  }

  const iconName = 'clipboard-list';
  const emptyCaption = 'No interest rates found';

  return (
    <>
      <div className="d-flex justify-content-end pt-1">
        <button style={{ outline: 'none', border: 'none', background: 'none' }} onClick={addRow}>
          <small className="text-primary">
            <Icon name="plus" className="mr-2" />
            Add New Row
          </small>
        </button>
      </div>
      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </>
  );
};

export default LiabilityRatesLsv;
