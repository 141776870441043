import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import Listview from '../../../core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';
import AreaRoundModal from '../containers/AreaRoundModal';
import moment from 'moment';

const AreaSection = (props) => {
  const { section, history, match, periodId, mapsterColours, checkAccess } = props;

  const defaultOpen = section.area_type.name === 'CEA' ? true : false;
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isModalOpen, setModal] = useState(false);
  const [id, setId] = useState(null);

  const openModal = (id) => {
    setId(id);
    setModal(true);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const renderRows = (headers, round) => {
    let periodName;
    if (round.period) {
      periodName = round.period.name;
    }

    let interval_years = '-';
    if (round.round_interval_years) {
      interval_years = `${round.round_interval_years.toFixed(2)} years`;
    }

    let project_duration_years = '-';
    if (round.project_duration_years) {
      project_duration_years = `${round.project_duration_years.toFixed(2)} years`;
    }

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'start_date':
        case 'end_date':
          return (
            <td className={header.classes} key={index}>
              {round[header.field] ? moment(round[header.field]).format('YYYY-MM-DD') : '-'}
            </td>
          );
        case 'project_period_id':
          return (
            <td className={header.classes} key={index}>
              {periodName}
            </td>
          );
        case 'duration_days':
          return (
            <td className={header.classes} key={index}>
              {round[header.field]} days
            </td>
          );
        case 'round_interval_years':
          return (
            <td className={header.classes} key={index}>
              {interval_years}
            </td>
          );
        case 'project_duration_years':
          return (
            <td className={header.classes} key={index}>
              {project_duration_years}
            </td>
          );
        default:
          return (
            <td className={header.classes} key={index}>
              {round[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Start Date', field: 'start_date', classes: 'text-center' },
    { caption: 'End Date', field: 'end_date', classes: 'text-center' },
    { caption: 'Reporting Period', field: 'project_period_id', classes: 'text-left' },
    { caption: 'Stock (t/ha)', field: 'soc_tph', classes: 'text-center' },
    { caption: 'Sampling Duration', field: 'duration_days', classes: 'text-center' },
    { caption: 'Project Duration', field: 'project_duration_years', classes: 'text-center' },
    { caption: 'Previous Round Interval', field: 'round_interval_years', classes: 'text-center' },
    { caption: 'Sequence', field: 'sequence', classes: 'text-center' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));
  tableHeadTh.push(<th key={headers.length + 1} />);
  tableHeadTh.push(<th key={headers.length + 2} />);

  let tableBodyTr = <tr></tr>;
  let totalDuration = 0;
  const haveRows = section.area_rounds && section.area_rounds.length > 0;
  if (haveRows) {
    tableBodyTr = section.area_rounds.map((round, index) => {
      if (round.start_date && round.end_date) {
        totalDuration += moment(round.end_date).diff(moment(round.start_date), 'days');
      }

      return (
        <tr key={index} style={{ cursor: 'pointer' }}>
          {renderRows(headers, round)}
          <td>
            {checkAccess('roundUpdate') && (
              <Icon
                name="pen-to-square"
                className="text-success"
                onClick={() => openModal(round.id)}
              />
            )}
          </td>
          <td>
            <Icon
              name="chevron-right"
              className="text-primary"
              onClick={() => edit(round, section.id)}
            />
          </td>
        </tr>
      );
    });
  }

  let description = `${section.area_type.name}`;
  let banner = (
    <div className="d-flex justify-content-between form-section bg-warning">
      <h5 className="m-0">{description}</h5>
    </div>
  );
  if (section.area_type.name === 'CEA') {
    description = `${section.area_type.name}${section.name}`;
    const areaColour = mapsterColours.areas[parseInt(section.name)];
    banner = (
      <div
        className="px-2 d-flex justify-content-between form-section"
        style={{ backgroundColor: areaColour }}
        onClick={toggle}
      >
        <span className="d-flex align-items-center">
          <Icon name={isOpen ? 'circle-minus' : 'circle-plus'} className="mx-2 text-white" />
          <h5 className="d-inline-block m-0">{description}</h5>
        </span>
        <div>
          Property CEA (ha)
          <span
            style={{ minWidth: '70px' }}
            className={`d-inline-block text-right px-2 bg-white text-black ml-2 rounded`}
          >
            {section.area_ha}
          </span>
        </div>
      </div>
    );
  }

  const edit = (round, area_id) => {
    const project_id = match.params.project_id;
    const property_id = match.params.id;

    const lnk = `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${round.id}`;
    history.push(lnk);
  };

  const roundIconName = 'circle-notch';
  const roundEmptyCaption = 'No Rounds found for this area.';

  return (
    <>
      <small className="ml-1 text-secondary">
        Click to expand/collapse area section to list sample rounds.
      </small>
      <div className="mb-2">
        {banner}
        <Collapse isOpen={isOpen} className="p-2 border border-grey">
          <div className="bg-white d-flex justify-content-between p-2 border-bottom">
            <div className="d-flex justify-content-between">
              <small className="mr-4">
                Area: <span className="text-success">{section.area_ha} ha</span>
              </small>
              <small className="mr-4">
                Project Sampling Duration:{' '}
                <span className="text-success">{totalDuration} days</span>
              </small>
              <small className="mr-4">
                Average Project Duration (CEA): <span className="text-success">0 years</span>
              </small>
            </div>
            {checkAccess('roundCreate') && (
              <small
                style={{ cursor: 'pointer' }}
                className="text-primary"
                onClick={() => openModal(null)}
              >
                <Icon name="plus" className="mr-2" />
                Add New Round
              </small>
            )}
          </div>

          <AreaRoundModal
            setModal={setModal}
            reporting_period={periodId}
            area={section}
            id={id}
            isOpen={isModalOpen}
          />
          <Listview
            rows={section.area_rounds}
            tableHeadTh={tableHeadTh}
            tableBodyTr={tableBodyTr}
            iconName={roundIconName}
            emptyCaption={roundEmptyCaption}
          />
        </Collapse>
      </div>
    </>
  );
};

export default AreaSection;
