import SingleSearch from 'jsx/components/core/form/components/SingleSearch';
import { noop } from 'lodash';
import { Button, Label } from 'reactstrap';

const MembersSearchBar = ({
  members = [],
  searchValue = '',
  onChange = noop,
  handleSearchChange = noop,
}) => {
  const count = `${members.count} members`;
  return (
    <div className="m-1 d-flex justify-content-end">
      <Label className="m-1 mr-2 text-secondary">{count}</Label>
      <SingleSearch
        handleSearchChange={handleSearchChange}
        placeholder="Filter by keyword"
        value={searchValue}
      />
      <Button onClick={onChange} size="sm" color="success" className="mr-2">
        Go
      </Button>
    </div>
  );
};

export default MembersSearchBar;
