import axios from 'axios';
import errorResponse from '../../../../lib/errorResponse.js';
import {
  apiSites,
  apiFieldTrips,
  apiScans,
  apiFieldUploads
} from '../../../../constants/api.js';

export function downloadFieldUploadSites(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_FIELD_UPLOAD_SITES';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiFieldUploads}/${id}/sites/download`,
      onProgressChange
    )
  }
}

export function downloadScan(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_SCAN';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiSites}/${id}/download`,
      onProgressChange
    )
  }
}

export function downloadSite(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_SITE';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiSites}/${id}/download`,
      onProgressChange
    )
  }
}

export function downloadFieldTrip(id, onProgressChange) {
  const dispatch_prefix = 'DOWNLOAD_FIELD_TRIP';
  return async dispatch => {
    return await download(
      dispatch_prefix,
      dispatch,
      `${apiFieldTrips}/${id}/download`,
      onProgressChange
    )
  }
}

export function downloadArchivedScans(onProgressChange, filters) {
  const dispatch_prefix = 'DOWNLOAD_ARCHIVED_SCANS';
  return async dispatch => {

    // Build the query string
    let queryString = '';
    let params = [];
    filters.map(id => {
      params.push(`filter_id[]=${id}`);
      return false;
    })

    if (params.length > 0) {
      queryString = `?${params.join('&')}`;
    }

    return await download(
      dispatch_prefix,
      dispatch,
      `${apiScans}/download/selected${queryString}`,
      onProgressChange
    )
  }
}

async function download(dispatch_prefix, dispatch, url, onProgressChange) {
  dispatch({ type: `${dispatch_prefix}_DOWNLOADING` });
  try {
    const file = await axios.get(
      url,
      {
        headers: { "Access-Control-Expose-Headers": "Content-Disposition" },
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => { onProgressChange(calculateProgress(progressEvent)) }
      }
    )

    dispatch({ type: `${dispatch_prefix}_FULFILLED` });
    return file;
  } catch (err) {
    errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
    return null
  }
}

function calculateProgress(progressEvent) {
  let value;
  let suffix;
  if (progressEvent.lengthComputable) {
    value = `${Math.round((progressEvent.loaded * 100) / progressEvent.total)}`;
    suffix = '%';
  } else {
    value = progressEvent.loaded;
    suffix = 'b';
    // Kilobytes
    if (value > 1000) {
      value /= 1000;
      suffix = 'kb';
    }

    // Megabytes
    if (value > 1000) {
      value /= 1000;
      suffix = 'mb';
    }

  }
  
  value = Math.round(value * 100) / 100;
  return `${value}${suffix}`;
}