import React from 'react';
import {
  Button,
  Row,
  Col
} from 'reactstrap';
import FormInput from "../../../core/form/components/FormInput";

const TimekeeperTimesheetNew = (props) => {
  const {
    controls,
    handleChange,
    onSave,
    costcodes
  } = props;

  controls.costcode_id.options = costcodes.map(costcode => {
    const name = `${costcode.jobno}-${costcode.costcode} ${costcode.name}`;
    return (
      {id: costcode.id, name: name}
    )
  })

  return (
    <div className="bg-light border border-gray rounded p-2">
      <Row className="">
        <Col><FormInput handleChange={handleChange} control={controls.costcode_id} className="m-1"/></Col>
        <Col sm={3}><FormInput handleChange={handleChange} control={controls.from_date} /></Col>
        <Col sm={3}><FormInput handleChange={handleChange} control={controls.to_date} /></Col>
        <Col sm={1}><FormInput handleChange={handleChange} control={controls.break_hours} /></Col>
      </Row>
      <Row className="mt-1">
        <Col><FormInput handleChange={handleChange} control={controls.comments} /></Col>
      </Row>
      <div className="mt-1 d-flex justify-content-end">
        <span className="p-2">Total (Hrs): 4.5</span>
        <Button size="sm" color="success" title="Save" onClick={onSave}>Add/Update Timesheet</Button>
      </div>
    </div>
  );
}

export default TimekeeperTimesheetNew;
