/* eslint-disable implicit-arrow-linebreak */
import { Dispatch } from 'redux';

import { apiDownloads } from 'jsx/constants/api';
import { get, download } from '../../../../lib/genericAction';

export function fetchDownload(id: string) {
  return async (dispatch: Dispatch) =>
    get(dispatch, 'FETCH_REPORT_META', `${apiDownloads}/details/${id}`);
}

export function downloadFile(id: string, onProgressChange: () => string) {
  return async (dispatch: Dispatch) =>
    download(dispatch, 'DOWNLOAD_GENERATED_REPORT', `${apiDownloads}/${id}`, onProgressChange);
}
