/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericModal from 'jsx/components/core/form/components/GenericModal';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import { Nav, TabContent, TabPane } from 'reactstrap';
import FormTab from 'jsx/components/core/form/components/FormTab';
import { LivestockValueLsv } from 'jsx/components/modules/portrait/components/LivestockValueLsv';
import { fetchAttributes } from 'jsx/components/modules/portrait/actions/attributes';
import LivestockAssumptionsToolbar from '../components/LivestockAssumptionsToolbar';
import { controls } from '../forms/livestock_assumptions';
import { controls as valueControls } from '../forms/livestock_assumption_values';
import {
  createAssumedAnimalClassValue,
  fetchAssumedAnimalClassValue,
  fetchAssumedAnimalClassValues,
  fetchAssumedAnimalClassValuesByMonth,
  fetchAssumedAnimalClasses,
  removeAssumedAnimalClassValue,
  updateAssumedAnimalClassValue,
} from '../actions/livestock_assumptions';
import LivestockAssumptionsClassesModal from './LivestockAssumptionsClassesModal';

const LivestockAssumptions = () => {
  const dispatch = useDispatch();
  const { animal_class_types: animalClassTypes } = useSelector(({ attributes }) => attributes);
  const { byMonth } = useSelector(({ livestock_assumptions }) => livestock_assumptions);
  const selectedRanges = useSelector(({ enterprises }) => enterprises.selectedRanges);
  const responseMessage = useSelector(
    ({ livestock_assumptions }) => livestock_assumptions.responseMessage,
  );
  const classes = useSelector(({ livestock_assumptions }) => livestock_assumptions.classes.rows);
  const values = useSelector(({ livestock_assumptions }) => livestock_assumptions.values.rows);
  const [open, setOpen] = useState(false);
  const [classId, setClassId] = useState(null);

  const [valueModalOpen, setValueModalOpen] = useState(false);
  const [valueModalData, setValueModalData] = useState({});
  const [activeTab, setActiveTab] = useState('classes');
  const [refresh, setRefresh] = useState(false);

  const setClassModal = async (isOpen, id = null) => {
    setOpen(isOpen);
    setClassId(id);
  };
  const setValueModal = async (isOpen, valueId) => {
    if (!isOpen) {
      setValueModalData({});
      setValueModalOpen(false);
      return;
    }

    const selectedRowData = await dispatch(fetchAssumedAnimalClassValue(valueId));
    setValueModalData(selectedRowData);
    setValueModalOpen(isOpen);
  };
  const onRefresh = () => setRefresh(!refresh);
  const onValueSave = async (data, isNew) => {
    const updateBody = { ...data };
    if (isNew) delete updateBody.id;

    const saveMethod = isNew ? createAssumedAnimalClassValue : updateAssumedAnimalClassValue;
    return dispatch(saveMethod(updateBody));
  };
  const onValueClose = (shouldRefresh = false) => {
    if (shouldRefresh) onRefresh();

    setValueModal(false);
  };
  const onValueRemove = async (target) => dispatch(removeAssumedAnimalClassValue(target));

  useEffect(() => {
    switch (activeTab) {
      case 'classes': {
        dispatch(fetchAttributes({ type: 'animal_class_types' }));
        dispatch(fetchAssumedAnimalClasses());
        break;
      }
      case 'values': {
        dispatch(fetchAssumedAnimalClassValues({ ...selectedRanges }));
        dispatch(
          fetchAssumedAnimalClassValuesByMonth({
            ...selectedRanges,
            valueType: byMonth.valueType,
          }),
        );
        break;
      }
      default:
        break;
    }
  }, [activeTab, byMonth.valueType, selectedRanges, refresh]);

  return (
    <>
      <LivestockAssumptionsClassesModal
        onRefresh={onRefresh}
        setModal={setClassModal}
        id={classId}
        isOpen={open}
        isNew={classId === null}
      />
      <GenericModal
        controls={valueControls}
        controlOptions={{ type_id: animalClassTypes }}
        modalTitle="Benchmark Value"
        setModal={setValueModal}
        data={valueModalData}
        isOpen={valueModalOpen}
        iconName="paw"
        onSave={onValueSave}
        onRemove={onValueRemove}
        onClose={() => onValueClose(true)}
        responseMessage={responseMessage}
      />
      <Nav tabs className="mt-4">
        <FormTab caption="Classes" tabId="classes" activeTab={activeTab} toggle={setActiveTab} />
        <FormTab caption="Values" tabId="values" activeTab={activeTab} toggle={setActiveTab} />
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="classes">
          <LivestockAssumptionsToolbar onAdd={() => setOpen(true)} />
          <GenericLsv
            controls={controls}
            darkTable={false}
            emptyCaption="No Benchmark Classes found"
            hideHeader={false}
            iconName="paw"
            onClick={(typeId) => setClassModal(true, typeId)}
            rows={classes}
          />
        </TabPane>
        <TabPane tabId="values">
          <div className="mt-2">
            <LivestockValueLsv
              isAdmin
              onClick={(valueId) => setValueModal(true, valueId)}
              showToggle={false}
              onRefresh={onRefresh}
              rows={values}
            />
          </div>
        </TabPane>
      </TabContent>
    </>
  );
};

export default LivestockAssumptions;
