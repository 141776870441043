import React from 'react';
import { Input } from 'reactstrap';
import Listview from '../../../core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';

const FieldBookLsv = ({ rows, history, handleSelect, onRemove, onEdit, checkAccess }) => {
  const open = (id) => {
    if (checkAccess('fieldBookView')) {
      const lnk = `/home/fieldtrips/fieldbook/${id}`;
      history.push(lnk);
    } else {
      alert('No permission to view FieldTrips. Check with your administrator');
    }
  };

  const renderRows = (headers, site) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'checkbox':
          return (
            <td key={index}>
              <Input type="checkbox" className="m-0 mt-1" onChange={handleSelect} value={site.id} />
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {site[header.field]}
            </td>
          );
      }
    });

    tableTd.push(
      <td key={headers.length + 1} className="d-flex justify-content-end">
        {checkAccess('fieldTripUpdate') && (
          <Icon
            name="pen-to-square"
            className="text-primary mr-3"
            onClick={(e) => onEdit(e, site.id)}
            style={{ cursor: 'pointer' }}
          />
        )}
        {checkAccess('fieldTripDelete') && (
          <Icon
            name="trash"
            className="text-primary mr-2"
            onClick={(e) => onRemove(e, site.id)}
            style={{ cursor: 'pointer' }}
          />
        )}
      </td>
    );
    return tableTd;
  };

  const headers = [
    { caption: '-', field: 'checkbox', classes: 'text-left' },
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Rig', field: 'drillrig', classes: 'text-left' },
    { caption: 'Sub-contractor', field: 'subcontractor', classes: 'text-left' },
    { caption: 'Crew', field: 'crew', classes: 'text-left' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));
  tableHeadTh.push(<th key={headers.length + 1}></th>);

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => (
      <tr
        key={index}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          open(row.id);
        }}
      >
        {renderRows(headers, row)}
      </tr>
    ));
  }

  const iconName = 'truck-pickup';
  const emptyCaption = 'No Field Trips found';

  return (
    <React.Fragment>
      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default FieldBookLsv;
