import { Controls } from 'jsx/components/core/form/components/FormBuilder';
import { regexValidators } from 'jsx/lib/regex';

export type ClientKeys =
  | 'id'
  | 'property_id'
  | 'is_primary'
  | 'first_name'
  | 'last_name'
  | 'company_name'
  | 'phone'
  | 'email';

export const projectPropertyClientForm: Controls<ClientKeys> = {
  id: {
    name: 'id',
    path: 'id',
    value: '',
    type: 'hidden',
    validationRules: {},
    listview: {
      show: false,
    },
  },
  property_id: {
    name: 'property_id',
    path: 'property_id',
    value: '',
    type: 'hidden',
    validationRules: {},
    listview: {
      show: false,
    },
  },
  company_name: {
    name: 'company_name',
    path: 'company_name',
    caption: 'Company Name',
    value: '',
    type: 'text',
    validationRules: {},
  },
  first_name: {
    name: 'first_name',
    path: 'first_name',
    caption: 'First Name',
    value: '',
    type: 'text',
    validationRules: {},
  },
  last_name: {
    name: 'last_name',
    path: 'last_name',
    caption: 'Last Name',
    value: '',
    type: 'text',
    validationRules: {},
  },
  phone: {
    name: 'phone',
    path: 'phone',
    caption: 'Phone',
    value: '',
    type: 'text',
    validationRules: {
      pattern: { value: /^[0-9+\s]+$/g, message: 'Must contain only numbers' },
      minLength: { value: 8, message: 'Must contain at least 8 digits' },
      maxLength: { value: 15, message: 'Invalid phone number' },
    },
  },
  email: {
    name: 'email',
    path: 'email',
    caption: 'Email',
    value: '',
    type: 'text',
    validationRules: {
      pattern: { value: regexValidators.email, message: 'Invalid email' },
    },
  },
  is_primary: {
    name: 'is_primary',
    path: 'is_primary',
    caption: 'Is Primary Client',
    type: 'switch',
    switchLabel: { enabled: 'Yes', disabled: 'No' },
    validationRules: {},
  },
};
