export type SequenceItem = {
  id: string;
  sequence: number;
};
export const insertItemInSequence = (
  fromSequence: number,
  toSequence: number,
  prevSequences: Array<SequenceItem>,
) => {
  if (prevSequences.length === 0) {
    return [];
  }
  // Make sure items are sorted before beginning
  const prevSorted = [...prevSequences].sort((left, right) => left.sequence - right.sequence);
  const firstSequence = prevSorted[0].sequence;

  const fromIndex = prevSorted.findIndex(({ sequence }) => sequence === fromSequence);
  const toIndex = prevSorted.findIndex(({ sequence }) => sequence === toSequence);

  const moving = prevSorted[fromIndex];
  const firstHead = prevSorted.slice(0, fromIndex);
  const firstTail = prevSorted.slice(fromIndex + 1);

  const patched = firstHead.concat(firstTail);
  const secondHead = patched.slice(0, toIndex);
  const secondTail = patched.slice(toIndex);

  const allRecords = secondHead.concat([moving]).concat(secondTail);

  return allRecords.map(({ id }, index) => ({ id, sequence: index + firstSequence }));
};
