import React, { useCallback, useState } from 'react';
import Listview from '../../../core/form/components/Listview';
import DaysheetSummariesDetailLsv, {
  DaysheetSummaryDetailRecord,
  Header,
} from './DaysheetSummariesDetailLsv';

export interface DaysheetSummaryRecord {
  approverStatus: boolean;
  unapproved: number;
  id: string;
  name: string;
  email: string;
  activityHours: number;
  totalBreakHours: number;
  activities: Array<DaysheetSummaryDetailRecord>;
}
interface DaysheetSummariesLsvProps {
  rows: Array<DaysheetSummaryRecord>;
}

type DaysheetSummaryRecordHeader = Header<DaysheetSummaryRecord>;

const DaysheetSummariesLsv = ({ rows }: DaysheetSummariesLsvProps) => {
  const renderTds = (
    headers: Array<DaysheetSummaryRecordHeader>,
    record: DaysheetSummaryRecord,
  ) => {
    const tableTds = headers.map((header: DaysheetSummaryRecordHeader, index: number) => {
      if (header.field === 'unapproved') {
        const { unapproved } = record;
        return (
          <td
            key={index}
            className={header.classes + (unapproved > 0 ? ' error-cell' : '')}
            style={header.style}
          >
            {unapproved}
          </td>
        );
      } else if (header.field === 'approverStatus') {
        const { approverStatus } = record;

        return (
          <td
            key={index}
            className={header.classes + (approverStatus ? '' : ' error-cell')}
            style={header.style}
          >
            {approverStatus ? 'No' : 'Yes'}
          </td>
        );
      } else if (header.field !== 'activities') {
        const value = record[header.field];

        return (
          <td key={index} className={header.classes} style={header.style}>
            {value}
          </td>
        );
      }
      return null;
    });

    return tableTds;
  };

  const iconName = 'user-clock';
  const emptyCaption = 'No Summaries found for this week';

  const headers: Array<DaysheetSummaryRecordHeader> = [
    { caption: 'User Name', field: 'name', classes: 'text-left', style: { width: 200 } },
    { caption: 'Email', field: 'email', classes: 'text-left' },
    { caption: 'Unapproved', field: 'unapproved', classes: 'text-left' },
    { caption: 'Orphans', field: 'approverStatus', classes: 'text-left' },
    {
      caption: 'Activity (Hrs)',
      field: 'activityHours',
      classes: 'text-right',
      style: { width: 100 },
    },
    {
      caption: 'Break (Hrs)',
      field: 'totalBreakHours',
      classes: 'text-right',
      style: { width: 100 },
    },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = [<tr></tr>];

  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const onView = useCallback(
    (rowToExpand: string) => {
      if (selectedRowId === rowToExpand) {
        setSelectedRowId(null);
      } else {
        setSelectedRowId(rowToExpand);
      }
    },
    [selectedRowId],
  );

  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row: DaysheetSummaryRecord, index: number) => (
      <React.Fragment key={index}>
        <tr onClick={() => onView(row.id)}>{renderTds(headers, row)}</tr>
        <tr>
          {selectedRowId === row.id && (
            <td className="bg-light" colSpan={12}>
              <DaysheetSummariesDetailLsv rows={row.activities} />
            </td>
          )}
        </tr>
      </React.Fragment>
    ));
  }

  return (
    <div className="b">
      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        rowsCount={rows.length}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </div>
  );
};

export default DaysheetSummariesLsv;
