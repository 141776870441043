import { controlDefaults } from '../../../../core/form/lib/validateForm';

export const controls = {
  group_id: {
    ...controlDefaults,
    placeholder: 'Select Benchmark Group',
    fieldName: 'group_id',
    name: 'group_id',
    type: 'select',
    caption: 'Benchmark Group',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    includeEmptyOption: true
  },
  probe_id: {
    ...controlDefaults,
    placeholder: 'Select Benchmark Period',
    fieldName: 'period_id',
    name: 'period_id',
    type: 'select',
    caption: 'Benchmark Period',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    includeEmptyOption: true
  },
  property: {
    ...controlDefaults,
    placeholder: 'Select Property',
    fieldName: 'property_id',
    name: 'property_id',
    type: 'text',
    caption: 'Property',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    includeEmptyOption: true
  },
};
