import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  name: {
    ...controlDefaults,
    placeholder: 'Your property name',
    name: 'name',
    fieldName: 'name',
    type: 'text',
    caption: 'Property Name',
    validationRules: {},
  },
  primary_practice: {
    ...controlDefaults,
    placeholder: 'Describe the primary farming/grazing activity on this property',
    name: 'primary_practice',
    fieldName: 'primary_practice',
    type: 'textarea',
    rows: 3,
    caption: 'Primary Acricultural Practice',
    validationRules: {},
  }
}
