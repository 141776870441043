import React from 'react';
import { connect } from 'react-redux';
import { Collapse, Card, Col, Row } from 'reactstrap';
import PillPicker from '../../../core/form/components/PillPicker';
import PillPresenter from '../../../core/form/components/PillPresenter';
import { setLabourGroupFilters, setLabourCategoryFilters } from '../actions/labours';

class LaboursFilter extends React.Component {
  constructor(props) {
    super(props);

    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleGroupDelete = this.handleGroupDelete.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleCategoryDelete = this.handleCategoryDelete.bind(this);
  }

  handleGroupChange({ target }) {
    const { value } = target;
    let { filters, params } = this.props.labours;

    // Add filter to categories
    const exists = filters.groups.find((id) => id === value);
    if (!exists) filters.groups.push(value);

    // Update reducer
    this.props.dispatch(setLabourGroupFilters(params, filters));
  }

  handleGroupDelete(id) {
    let { filters, params } = this.props.labours;

    // Remove filter from categories
    const idx = filters.groups.findIndex((filterId) => filterId === id);
    if (idx !== -1) filters.groups.splice(idx, 1);

    // Update reducer
    this.props.dispatch(setLabourGroupFilters(params, filters));
  }

  handleCategoryChange({ target }) {
    const { value } = target;
    let { filters, params } = this.props.labours;

    // Check if user selection is not in current filters
    const exists = filters.categories.find((id) => id === value);
    if (!exists) filters.categories.push(value);

    // Update reducer
    this.props.dispatch(setLabourCategoryFilters(params, filters));
  }

  handleCategoryDelete(id) {
    let { filters, params } = this.props.labours;

    // Remove filter from types
    const idx = filters.categories.findIndex((filterId) => filterId === id);
    if (idx !== -1) filters.categories.splice(idx, 1);

    // Update reducer
    this.props.dispatch(setLabourCategoryFilters(params, filters));
  }

  render() {
    const { filterOpen, groups } = this.props;
    const { filters } = this.props.labours;
    let { categories } = this.props;

    // Set custom colour for type filters
    categories.forEach((category) => (category.colour = 'orange'));

    const groupCaption = 'Group';
    const categoryCaption = 'Category';

    const filtersCount = Object.keys(filters)
      .map((key) => filters[key].length)
      .reduce((a, b) => a + b);

    return (
      <>
        <Collapse isOpen={filterOpen}>
          <Card className="border border-corporate rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col sm={3} className="bg-light m-1">
                <PillPicker
                  caption={groupCaption}
                  handleChange={this.handleGroupChange}
                  handleDelete={this.handleGroupDelete}
                  rows={groups}
                  selection={filters.groups}
                  showPills={false}
                />
              </Col>
              <Col sm={3} className="bg-light m-1">
                <PillPicker
                  caption={categoryCaption}
                  handleChange={this.handleCategoryChange}
                  handleDelete={this.handleCategoryDelete}
                  rows={categories}
                  selection={filters.categories}
                  showPills={false}
                />
              </Col>
            </Row>
          </Card>
        </Collapse>
        {filtersCount > 0 && (
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption={groupCaption}
              handleDelete={this.handleGroupDelete}
              rows={groups}
              selection={filters.groups}
              colourField="colour"
            />
            <PillPresenter
              caption={categoryCaption}
              handleDelete={this.handleCategoryDelete}
              rows={categories}
              selection={filters.categories}
              colourField="colour"
            />
          </div>
        )}
      </>
    );
  }
}

const mapStoreToProps = ({ labours }) => {
  return {
    labours,
  };
};

export default connect(mapStoreToProps)(LaboursFilter);
