import React, { useRef } from 'react';
import { useClickOutside } from '../../../../lib/hooks';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { Popover, PopoverBody } from 'reactstrap';

const FieldTripsButton = ({ onToggle, onClick, site, fieldTrips, isOpen, isLoading }) => {
  const popoverRef = useRef();
  useClickOutside(popoverRef, () => onToggle());

  return (
    <>
      <button
        type="button"
        style={{ width: '100px' }}
        id={site.core_key}
        className="btn btn-sm border border-primary text-primary d-inline-flex justify-content-center"
        onClick={onClick}
        disabled={isLoading}
      >
        {!isLoading && 'View Fieldtrip'}
        {isLoading && <HalfCircleSpinner size={19} color="#4285f4" />}
      </button>
      {!isLoading && (
        <Popover
          ref={popoverRef}
          placement="bottom"
          isOpen={isOpen}
          target={site.core_key}
          toggle={onToggle}
        >
          <PopoverBody>
            {fieldTrips.map(({ name, key }, i) => (
              <div key={`${key}-${i}`}>{name}</div>
            ))}
          </PopoverBody>
        </Popover>
      )}
    </>
  );
};

export default FieldTripsButton;
