import { apiUserProjects } from 'jsx/constants/api';
import { get, post, put, remove } from '../../../../lib/genericAction';

export function fetchUserProjects(params) {
  return (dispatch) => {
    if (params && Object.keys(params).length > 0)
      dispatch({ type: 'SET_USER_PROJECTS_PARAMS', payload: { ...params } });

    return get(dispatch, `FETCH_USER_PROJECTS`, `${apiUserProjects}`, params);
  };
}

export function fetchUserProject(id) {
  return (dispatch) => get(dispatch, `FETCH_USER_PROJECT`, `${apiUserProjects}/${id}`);
}

export function createUserProject(data) {
  return (dispatch) => post(dispatch, `CREATE_USER_PROJECT`, `${apiUserProjects}`, data);
}

export function updateUserProject(data) {
  return (dispatch) => put(dispatch, `UPDATE_USER_PROJECT`, `${apiUserProjects}/${data.id}`, data);
}

export function removeUserProject(id) {
  return (dispatch) => remove(dispatch, `REMOVE_USER_PROJECT`, `${apiUserProjects}/${id}`);
}
