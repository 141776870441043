import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import FormBase from '../../../core/form/components/FormBase';
import TimeFormat from '../lib/timeFormat';

import { fetchTimesheets } from '../actions';
import TimekeeperTimesheetsLsv from '../components/TimekeeperTimesheetsLsv';

class TimekeeperSummaryModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      title: 'Timesheets',
      id: null,
      setModal: null,
    };

    this.format = new TimeFormat();

    this.onAction = this.onAction.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async componentDidUpdate() {
    let { isOpen, id } = this.state;

    const { currentWeek } = this.props.office;

    if (
      isOpen !== this.props.isOpen &&
      isOpen === false &&
      (this.props.id || this.props.allbyweek)
    ) {
      this.props.dispatch({ type: 'UNSET_TIMESHEETS' });
      id = this.props.id;

      // Fetch result by User
      if (this.props.id) {
        this.props
          .dispatch(
            fetchTimesheets({
              ...currentWeek,
              user_id: id,
            })
          )
          .then((result) => {
            if (result?.rows?.length > 0)
              this.setState({
                title: `Timesheets - ${result.rows[0].user.name}`,
              });
          });
      }

      // Fetch all results by all users for a week
      if (this.props.allbyweek) {
        this.props
          .dispatch(
            fetchTimesheets({
              allbyweek: this.props.allbyweek,
            })
          )
          .then((result) => {
            if (result?.rows?.length > 0)
              this.setState({
                title: `Timesheets for ${this.props.allbyweek}`,
              });
          });
      }

      this.setState({
        id,
        isOpen: true,
        setModal: this.props.setModal,
      });
    }
  }

  async onAction(is_approved) {
    let success = await this.props.handleTimesheetAction(
      is_approved,
      this.state.id
    );
    if (success) this.onCancel();
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen, title } = this.state;

    const { responseMessage, timesheets } = this.props.office;

    const budgetWeek = `Budget Week: ${this.format.asString(
      this.props.budgetHours
    )} hrs`;
    const totalWeek = `Week Total:  ${this.format.asString(
      this.props.totalHours
    )} hrs`;

    const extraClass =
      this.props.totalHours < this.props.budgetHours
        ? 'text-danger'
        : 'text-success';
    const baseClassName = `d-flex justify-content-end p-2 text-right ${extraClass}`;

    const iconName = 'stopwatch';
    return (
      <Modal isOpen={isOpen} size='xl' className='w80-modal'>
        <ModalHeader className='bg-corporate text-white'>
          <Icon
            size='1x'
            name={iconName}
            className='mr-2'
          />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && (
            <div className='text-center text-danger'>{responseMessage}</div>
          )}
          <div className={baseClassName}>
            <span className='mr-2'>{budgetWeek} - </span>
            <span>{totalWeek}</span>
          </div>

          <TimekeeperTimesheetsLsv
            rows={timesheets.rows}
            ignoreFields={['status_indicator', 'jira_key']}
          />
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center'>
          <div>
            <Button
              size='sm'
              className='mr-2'
              color='success'
              onClick={() => this.onAction(true)}
            >
              Approve
            </Button>
            <Button
              size='sm'
              className='mr-2'
              color='danger'
              onClick={() => this.onAction(false)}
            >
              Reject
            </Button>
            <Button
              size='sm'
              className='mr-2'
              color='light'
              onClick={this.onCancel}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    office: store.office,
  };
};

export default connect(mapStoreToProps)(TimekeeperSummaryModal);
