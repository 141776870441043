import React from 'react';
import { connect } from 'react-redux';

import SitesLsv from '../../sites/components/SitesLsv';
import SitesFilter from '../../sites/components/SitesFilter';
import SitesToolbar from '../../sites/components/SitesToolbar';
import FormBase from '../../../core/form/components/FormBase';

import Downloads from '../../sites/lib/downloads';

import { fetchSites, updateSite, fetchSiteSamples } from '../../sites/actions';

class AreaRoundPanelSites extends FormBase {
  constructor(props) {
    super(props);

    const { property_id, project_id, area_id, round_id } = this.props.match.params;

    const backlink = `/home/projects/${project_id}/properties/${property_id}`;
    const homeLink = `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${round_id}`;

    this.downloads = new Downloads();

    this.state = {
      modifyDisabled: true,
      coreSelection: [],
      searchValue: '',
      data: {},
      currentSite: {},
      backlink,
      homeLink,
    };

    this.refresh = this.refresh.bind(this);
    this.updateSelectedSites = this.updateSelectedSites.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.checkSites = this.checkSites.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.fetchSamples = this.fetchSamples.bind(this);
  }

  componentDidUpdate() {
    let { data } = this.state;

    const { filters } = this.props.sites;

    if (data.id !== this.props.round_id) {
      data.id = this.props.round_id;

      this.downloads.set(this.props.dispatch);

      this.props.dispatch(fetchSites({ round_id: data.id }, filters));

      this.setState({
        data,
      });
    }
  }

  refresh() {
    const { params, filters } = this.props.sites;

    this.props.dispatch(fetchSites(params, filters));
  }

  async updateSelectedSites(fieldName, value) {
    const { coreSelection } = this.state;

    const { params, filters } = this.props.sites;

    await Promise.all(
      coreSelection.map(async (selected_id) => {
        let formData = new FormData();
        formData.append('id', selected_id);
        formData.append(fieldName, value);
        await this.props.dispatch(updateSite(formData));
      })
    );

    this.props.dispatch(fetchSites(params, filters));
  }

  handleSearchChange(event) {
    const round_id = this.props.match.params.id;
    let params = this.props.sites.params;

    this.setState({ searchValue: event.target.value });

    // Force search on enter
    if (event.keyCode === 13) {
      const filter = { core_id: event.target.value };
      this.props.dispatch(fetchSites(params, filter));
    }

    // Bring back all the scans
    if (!event.target.value) {
      params = { round_id: round_id };
      this.props.dispatch(fetchSites(params, null));
    }
  }

  onSearch(event) {
    event.preventDefault();
    this.props.dispatch(fetchSites({ core_id: this.state.searchValue }, null));
  }

  checkSites() {
    const { sites } = this.props.sites;

    if (sites.length === 0) return null;

    // let site_cnt = 0;
    // sites.forEach(site => {
    //   if (site.status.tag === 'active') {
    //     if (!site.scan_validated) {
    //       site_cnt +=1;
    //     }
    //   }
    // })

    return null;
  }

  handleSelectAll(event, rows) {
    let { coreSelection, modifyDisabled } = this.state;

    const state = event.target.checked;
    if (state) {
      rows.forEach((row) => {
        coreSelection.push(row.id);
      });
    } else {
      coreSelection = [];
    }

    if (coreSelection.length > 0) {
      modifyDisabled = false;
    }

    this.setState({
      coreSelection,
      modifyDisabled,
    });
  }

  handleSelect(event) {
    let { coreSelection, modifyDisabled } = this.state;

    const state = event.target.checked;
    const selected_id = event.target.value;

    if (state) {
      coreSelection.push(selected_id);
    } else {
      const idx = coreSelection.findIndex((id) => id === selected_id);
      if (idx > -1) {
        coreSelection.splice(idx, 1);
      }
    }

    if (coreSelection.length > 0) {
      modifyDisabled = false;
    }

    this.setState({
      coreSelection,
      modifyDisabled,
    });
  }

  fetchSamples(site) {
    this.props.dispatch(fetchSiteSamples({ site_id: site.id }));
    this.setState({ currentSite: site });
  }

  render() {
    const { sites, statuses, scanValids, samples } = this.props.sites;

    const { modifyDisabled, coreSelection, searchValue, homeLink, currentSite } = this.state;

    return (
      <div>
        <SitesToolbar
          modifyDisabled={modifyDisabled}
          refresh={this.refresh}
          statuses={statuses}
          updateSelected={this.updateSelectedSites}
          handleSearchChange={this.handleSearchChange}
          searchValue={searchValue}
          coreSelection={coreSelection}
          onSearch={this.onSearch}
          sites={sites}
          checkAccess={this.checkAccess}
        />
        <SitesFilter toggler="#toggler" />
        <SitesLsv
          match={this.props.match}
          history={this.props.history}
          homeLink={homeLink}
          rows={sites || []}
          statusMessage={this.checkSites()}
          statuses={statuses}
          updateSelected={this.updateSelectedSites}
          scanValids={scanValids}
          coreSelection={coreSelection || []}
          handleSelect={this.handleSelect}
          handleSelectAll={this.handleSelectAll}
          handleDownload={(id, event) => this.downloads.handleSiteDownload(id, event)}
          fetchSamples={this.fetchSamples}
          samples={samples}
          currentSite={currentSite}
        />
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    sites: store.sites,
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(AreaRoundPanelSites);
