import { Controls } from 'jsx/components/core/form/components/FormBuilder';

export type projectRegisterMetaKeys =
  | 'project_id'
  | 'projectName'
  | 'assignedTo'
  | 'description'
  | 'erf_project_no'
  | 'orig_methodology'
  | 'tx_methodology'
  | 'permanence_period'
  | 'participant'
  | 'eihc_required'
  | 'anreu_holder'
  | 'anreu_ref'
  | 'anreu_primary_rep'
  | 'anreu_secondary_rep'
  | 'anreu_tertiary_rep'
  | 'anreu_client_no';

export const projectRegisterMetaForm: Controls<projectRegisterMetaKeys> = {
  project_id: {
    name: 'project_id',
    path: 'project_id',
    value: '',
    type: 'hidden',
    validationRules: {},
  },
  projectName: {
    name: 'projectName',
    path: 'project.name',
    caption: 'Project Name',
    value: '',
    type: 'text',
    validationRules: {
      required: 'Project name is required',
    },
  },
  assignedTo: {
    name: 'assignedTo',
    path: 'project.assignedto.name',
    caption: 'Assigned To',
    value: '',
    type: 'text',
    validationRules: {},
  },
  description: {
    name: 'projectDescription',
    path: 'project.description',
    caption: 'Description',
    value: '',
    type: 'text',
    validationRules: { required: 'Description is required' },
  },
  erf_project_no: {
    name: 'erf_project_no',
    path: 'erf_project_no',
    caption: 'ERF Project No',
    value: '',
    type: 'text',
    validationRules: {},
  },
  orig_methodology: {
    name: 'orig_methodology',
    path: 'orig_methodology',
    caption: 'Original Methodology',
    value: '',
    type: 'number',
    validationRules: {},
  },
  tx_methodology: {
    name: 'tx_methodology',
    path: 'tx_methodology',
    caption: 'Current Methodology',
    value: '',
    type: 'number',
    validationRules: {},
  },
  permanence_period: {
    name: 'permanence_period',
    path: 'permanence_period',
    caption: 'Permanence Period',
    value: '',
    type: 'number',
    validationRules: {},
  },
  participant: {
    name: 'participant',
    path: 'participant',
    caption: 'Participant',
    value: '',
    type: 'text',
    validationRules: {},
  },
  eihc_required: {
    name: 'eihc_required',
    path: 'eihc_required',
    caption: 'EIHC Required',
    value: '',
    type: 'text',
    validationRules: {},
  },
  anreu_holder: {
    name: 'anreu_holder',
    path: 'anreu_holder',
    caption: 'ANREU Holder',
    value: '',
    type: 'text',
    validationRules: {},
  },
  anreu_ref: {
    name: 'anreu_ref',
    path: 'anreu_ref',
    caption: 'ANREU Account Number',
    value: '',
    type: 'text',
    validationRules: {},
  },
  anreu_primary_rep: {
    name: 'anreu_primary_rep',
    path: 'anreu_primary_rep',
    caption: 'ANREU Primary Rep',
    value: '',
    type: 'text',
    validationRules: {},
  },
  anreu_secondary_rep: {
    name: 'anreu_secondary_rep',
    path: 'anreu_secondary_rep',
    caption: 'ANREU Secondary Rep',
    value: '',
    type: 'text',
    validationRules: {},
  },
  anreu_tertiary_rep: {
    name: 'anreu_tertiary_rep',
    path: 'anreu_tertiary_rep',
    caption: 'ANREU Tertiary Rep',
    value: '',
    type: 'text',
    validationRules: {},
  },
  anreu_client_no: {
    name: 'anreu_client_no',
    path: 'anreu_client_no',
    caption: 'ANREU Client No',
    value: '',
    type: 'text',
    validationRules: {},
  },
};
