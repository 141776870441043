import React from 'react';
import { connect } from "react-redux";

import HorizontalChart from '../components/HorizontalChart';
import Icon from 'jsx/components/core/icons/Icon';
import { v4 as uuidv4 } from 'uuid';

import {
  fetchCarbonStock
} from '../actions';

class WidgetLayerCores extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      specLink: 'https://carbonlink.atlassian.net/wiki/spaces/OP/pages/554237980/Spec+-+Calculate+carbon+stock+for+layers+in+each+core',
      data: [],
      fetching: false,
      height: 400,
      datasets: [],
      widget: {
        id: 'core_layers',
        title: 'Core Carbon Stocks',
        dataKeyX: 'name',
        group: 'layers',
        fixedDatasets: true,
        inverted: true,
        datasets: [
          {
            id: uuidv4(),
            sourceId: 'core_layers',
            series: 'left',
            type: 'bar',
            colour: '#8884d8',
            rowName: 'core_id',
            sortSelected: true,
            decimals: 2,
            selected: { calculation_type: 'esm', name: 'carbon_mass', caption: 'SOC tC/ha', unit: ' t/ha', colour: '#8884d8' },
          }
        ]
      },
    })
  }

  componentDidUpdate(prevProps) {
    const {
      selectedTemplate,
      area_round_id
    } = this.props;

    const {
      fetching,
      datasets,
      mapSources,
      map,
      mapIsLoaded
    } = this.state;

    if (selectedTemplate.id && area_round_id && !fetching && (area_round_id !== prevProps.area_round_id || selectedTemplate.id !== prevProps.selectedTemplate.id || datasets.length === 0)) {
      this.load();
    }

    if (mapIsLoaded) {
      this.updateSources(map, mapSources)
    }
  }

  async load() {
    const {
      selectedTemplate,
      area_round_id
    } = this.props;

    const datasets = [];
    this.setState(
      { fetching: true },
      async () => {
        const soc = await this.props.dispatch(fetchCarbonStock("core", area_round_id, selectedTemplate.id));

        // Get defaults for dataset model
        const datasetLayers = this.props.analytics.datasets.layers;
        const dataset = datasetLayers.find(datasetLayer => datasetLayer.id === 'core_layers');

        // Set dataset
        dataset.data = soc;
        dataset.sortSelected = true;
        datasets.push(dataset);
        this.setState({ datasets, fetching: false });
      }
    )
  }

  render() {
    const {
      height,
      datasets,
      widget,
      specLink
    } = this.state;

    // If widget is empty
    const emptyCaption = `No Chart data available for ${widget.title}`;
    const iconName = 'chart-mixed';
    const specCaption = `Spec Sheet for ${widget.title}`;

    const checksum = new Date().getTime();

    return (
      <div>
        {datasets.length === 0 && (
          <div className="p-5 text-center bg-white rounded border border-secondary" style={{ height: height }}>
            <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
            <div className="mt-3">{emptyCaption}</div>
          </div>
        )}

        {datasets.length > 0 && (
          <div className="p-2 text-center bg-white rounded border border-secondary">
            <HorizontalChart
              datasets={datasets}
              checksum={checksum}
              group="layers"
              widget={widget}
              selectionKey="sites"
              height={height}
              margin={{ top: 20, right: 0, left: 20, bottom: 5 }}
              singleSelection={true}
            />
            <div className="text-right mr-3">
              <small><a target="_blank" rel="noopener noreferrer" href={specLink}>{specCaption}</a></small>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    analytics: store.analytics,
    mapster: store.mapster
  }
}

export default connect(mapStoreToProps)(WidgetLayerCores);
