import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Card, CardTitle } from 'reactstrap';
import FormInput from '../../../core/form/components/FormInput';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import Icon from 'jsx/components/core/icons/Icon';
import {
  validateChange,
  initControls,
  saveControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { controls } from '../forms/project.js';
import { createProject } from '../actions/projects';

class Project extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      activeTab: '1',
      project: {},
      controls: initControls(controls),
      backlink: '/home/projects',
      isValid: false,
      data: {},
    };

    this.onCreate = this.onCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  updateControls() {
    const { currentProject } = this.props.projects;

    let { controls } = this.state;

    if (currentProject && this.state.data.id !== currentProject.id) {
      const keys = Object.keys(controls);
      keys.forEach((key) => {
        const fieldName = controls[key].name;
        controls[key].value = currentProject[fieldName];
      });

      this.setState({
        data: currentProject,
        controls,
      });
    }
  }

  async onCreate() {
    let { data, controls, backlink } = this.state;

    const keys = Object.keys(controls);
    keys.forEach((key) => {
      data[key] = controls[key].value;
    });

    const success = await this.props.dispatch(createProject(data));

    if (success) this.props.history.push(backlink);
  }

  onCancel() {
    this.props.history.push(this.state.backlink);
  }

  handleChange(event) {
    const controls = validateChange(event, this.state.controls);
    const changes = saveControls(controls, this.state.data);
    const { isValid } = validateFormFieldControls(changes, controls);

    this.setState({
      controls,
      formChanged: true,
      isValid,
    });
  }

  render() {
    const { responseMessage } = this.props.projects;

    const { controls, isValid } = this.state;

    const iconName = 'diagram-project';
    return (
      <Card className="m-5 border rounded">
        <CardTitle className="m-0 bg-corporate p-2 text-white">
          <Icon size="1x" name={iconName} className=" mr-2" />
          New Project: {controls.name.value}
        </CardTitle>
        <div className="p-2">
          <ResponseMessage responseMessage={responseMessage} />
          <Row>
            <Col sm={10}>
              <Row>
                <Col sm={4}>
                  <FormInput handleChange={this.handleChange} control={controls.name} />
                </Col>
                <Col sm={4}>
                  <FormInput handleChange={this.handleChange} control={controls.jira_key} />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormInput handleChange={this.handleChange} control={controls.description} />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <FormInput handleChange={this.handleChange} control={controls.project_job_no} />
                </Col>
              </Row>
            </Col>
            <Col>
              <div className="d-flex flex-column justify-content-end">
                <Button
                  disabled={!isValid}
                  className="mt-4"
                  size="sm"
                  color="success"
                  onClick={() => this.onCreate()}
                >
                  Create Project
                </Button>
                <Button className="mt-2" size="sm" color="light" onClick={() => this.onCancel()}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    projects: store.projects,
  };
};

export default connect(mapStoreToProps)(Project);
