import React from 'react';
import Listview from '../../../core/form/components/Listview';
import moment from 'moment';

import { Input } from 'reactstrap';

const CoreArchivesLsv = (props) => {
  const { pagination, onLoadMore, handleSelect, scanSelection } = props;
  let { rows } = props;

  const iconName = 'shredder';
  const emptyCaption = 'No archive scans found.';

  const renderRows = (headers, scan) => {
    // Build Core Key
    const core_key = `${scan.scan.site.strata.round.area.property.idx}-${scan.scan.site.core_id}`;

    // Build cea/round/strata name
    const strata = `CEA${scan.scan.site.strata.round.area.name}/${scan.scan.site.strata.round.name}/${scan.scan.site.strata.name}`;

    // Build scanned at
    const scanned_ago = moment.utc(scan.scan.scanned_at).local().fromNow();
    const scanned_at = moment.utc(scan.scan.scanned_at).local().format('YYYY-MM-DD HH:mm');
    const scanned_text = `${scanned_at} - ( ${scanned_ago})`;

    const checked = scanSelection.find((id) => id === scan.site_scan_id) ? true : false;

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'checkbox':
          return (
            <td key={index}>
              <Input
                type="checkbox"
                checked={checked}
                className="m-0 mt-1"
                onChange={handleSelect}
                value={scan.site_scan_id}
              />
            </td>
          );
        case 'scanned_at':
          return (
            <td key={index} className={header.classes}>
              {scanned_text}
            </td>
          );
        case 'scannedby':
          return (
            <td key={index} className={header.classes}>
              {scan['scan']['scannedby']['name']}
            </td>
          );
        case 'tag':
          return (
            <td key={index} className={header.classes}>
              {scan.lookup_scan_tag.name}
            </td>
          );
        case 'strata':
          return (
            <td key={index} className={header.classes}>
              {strata}
            </td>
          );
        case 'core_key':
          return (
            <td key={index} className={header.classes}>
              {core_key}
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {scan[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: '-', field: 'checkbox', classes: 'text-left' },
    { caption: 'Core Key', field: 'core_key', classes: 'text-left' },
    { caption: 'Cea/Round/Strata', field: 'strata', classes: 'text-left' },
    { caption: 'Archive Tag', field: 'tag', classes: 'text-left' },
    { caption: 'Scanned By', field: 'scannedby', classes: 'text-left' },
    { caption: 'Scanned', field: 'scanned_at', classes: 'text-right' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  let rowsCount;
  if (rows && pagination) {
    rowsCount = rows.count;
    rows = rows.rows;
  }
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  return (
    <Listview
      rows={rows}
      rowsCount={rowsCount}
      onLoadMore={onLoadMore}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default CoreArchivesLsv;
