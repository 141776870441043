import React from 'react';
import Listview from "../../../core/form/components/Listview";

const AreaRoundStratasLsv = (props) => {
  const {
    history,
    match,
    rows
  } = props;

  const renderRows = (headers, strata) => {
    let rowColour = "text-success mr-3"
    if (!strata.enabled) {
      rowColour = "text-danger mr-3"
    }

    // Site counts
    let target_sites = 0;
    let reserve_sites = 0;
    let abandoned_sites = 0;
    let scanned_sites = 0;
    let scan_pass = 0;
    let scan_fail = 0;
    let unvalidated = 0;

    strata.sites.map(site => {
      // Target sites
      if (site.type_key === 'T') {
        target_sites++
      }

      // Reserve Sites
      if (site.type_key === 'R') {
        reserve_sites++
      }

      // Abandoned Sites
      if (site.type_key === 'A') {
        abandoned_sites++
      }

      // Scanned sites
      if (site.scan_samples_exists === true) {
        scanned_sites++
      }

      // Scanned passed
      if (site.scan_valid === true) {
        scan_pass++
      }

      // Scanned fail
      if (site.scan_valid === false) {
        scan_fail++
      }

      // Scanned fail
      if (site.scan_valid === null && site.scan_samples_exists) {
        unvalidated++
      }

      return false;
    })

    const tableTd = headers.map((header, index) => {
      const classes = `${rowColour} ${header.classes}`;
      const geomClassName = `${header.classes} ${(strata.geom ? "text-success" : "text-danger")}`;
      switch (header.field) {
        case 'enabled': return (
          <td key={index} className={classes}>{strata[header.field].toString()}</td>
        )
        case 'haveGeom': return (
          <td key={index} className={geomClassName}>{(strata.geom ? 'Yes' : 'No')}</td>
        )
        case 'targets': return (
          <td key={index} className={classes}>{target_sites}</td>
        )
        case 'reserves': return (
          <td key={index} className={`${classes} text-warning`}>{reserve_sites}</td>
        )
        case 'abandoned': return (
          <td key={index} className={`${classes} text-danger`}>{abandoned_sites}</td>
        )
        case 'scanned': return (
          <td key={index} className={classes}>{scanned_sites}</td>
        )
        case 'pass': return (
          <td key={index} className={classes}>{scan_pass}</td>
        )
        case 'fail': return (
          <td key={index} className={`${classes} text-danger`}>{scan_fail}</td>
        )
        case 'unvalidated': return (
          <td key={index} className={classes}>{unvalidated}</td>
        )
        default: return (
          <td key={index} className={classes}>{strata[header.field]}</td>
        )
      }
    });
    return tableTd;
  }

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Area (ha)', field: 'area_ha', classes: 'text-right' },
    { caption: 'Geometry', field: 'haveGeom', classes: 'text-center' },
    { caption: 'Enabled', field: 'enabled', classes: 'text-center' },
    { caption: 'Carbon (t/ha)', field: 'carbon_tph', classes: 'text-right' },
    { caption: '# Targets', field: 'targets', classes: 'text-center', totals: true },
    { caption: '# Reserves', field: 'reserves', classes: 'text-center', totals: true },
    { caption: '# Abandoned', field: 'abandoned', classes: 'text-center', totals: true },
    { caption: '# Scanned', field: 'scanned', classes: 'text-center', totals: true },
    { caption: '# Pass', field: 'pass', classes: 'text-center', totals: true },
    { caption: '# Fail', field: 'fail', classes: 'text-center', totals: true },
    { caption: '# Not Validated', field: 'unvalidated', classes: 'text-center', totals: true },
  ]

  let totals = [];
  const tableHeadTh = headers.map((header, index) => {
    if (header.totals) totals.push(index);
    return (<th key={index} className={header.classes}>{header.caption}</th>)
  });

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => edit(row.id)} style={{ cursor: "pointer" }}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const edit = (strata_id) => {
    const project_id = match.params.project_id;
    const property_id = match.params.property_id;
    const area_id = match.params.area_id;
    const round_id = match.params.id;

    const lnk = `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${round_id}/stratas/${strata_id}`;
    history.push(lnk);
  }

  const iconName = 'border-none';
  const emptyCaption = 'No Stratas found for this round.';

  return (
    <React.Fragment>
      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        totals={totals}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
}

export default AreaRoundStratasLsv;
