import { get, put, post, remove } from '../../../../lib/genericAction';

import { apiPropertyRainfalls } from '../../../../constants/api';

export function fetchPropertyRainfalls(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_PROPERTY_RAINFALLS`, `${apiPropertyRainfalls}`, params);
}

export function fetchPropertyRainfall(id) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_PROPERTY_RAINFALL`, `${apiPropertyRainfalls}/${id}`);
}

export function createPropertyRainfall(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_PROPERTY_RAINFALL`, `${apiPropertyRainfalls}`, data);
}

export function updatePropertyRainfall(data) {
  return async (dispatch) =>
    await put(dispatch, `UPDATE_PROPERTY_RAINFALL`, `${apiPropertyRainfalls}/${data.id}`, data);
}

export function removePropertyRainfall(id) {
  return async (dispatch) =>
    await remove(dispatch, `REMOVE_PROPERTY_RAINFALL`, `${apiPropertyRainfalls}/${id}`);
}
