import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    liabilities: {},
    liabilityflows: [],
    transactions: {},
    distributions: {},
    activeTab: '1',
    filters: {
      categories: [],
    },
  },
  action
) {
  // Set expected reducer types
  const types = ['LIABILITY', 'LIABILITIES'];

  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'UNSET_LIABILITIES': {
      return {
        ...state,
        liabilities: {},
      };
    }
    case 'UNSET_LIABILITY_TRANSACTIONS': {
      return {
        ...state,
        transactions: {},
      };
    }
    case 'FETCH_LIABILITIES_FULFILLED': {
      return {
        ...state,
        liabilities: action.payload,
      };
    }
    case 'FETCH_LIABILITY_TRANSACTIONS_FULFILLED': {
      return {
        ...state,
        transactions: action.payload,
      };
    }
    case 'FETCH_LIABILITYFLOWS_FULFILLED': {
      return {
        ...state,
        liabilityflows: action.payload,
      };
    }
    case 'SET_LIABILITYFLOW_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case 'SET_LIABILITY_PARAMS': {
      return {
        ...state,
        params: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
