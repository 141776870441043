import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import { Col } from 'reactstrap';

export const ReportsDraggableRow = ({
  isDragging,
  draggableProps,
  innerRef,
  dragHandleProps,
  row,
  index,
  onRowClick = noop,
}) => {
  const bgColour = index % 2 === 0 ? 'bg-lightgray' : 'bg-light';

  return (
    <div
      {...draggableProps}
      ref={innerRef}
      key={row.id}
      className={
        isDragging
          ? 'd-flex border-primary opacity-100 row-dragging border py-1'
          : `${bgColour} d-flex opacity-100 py-1`
      }
    >
      <div {...dragHandleProps}>
        <Icon name="grip-dots" title="Reorder row"></Icon>
      </div>
      <Col onClick={() => onRowClick(row.id)} style={{ cursor: 'pointer' }}>
        {row.name}
      </Col>
      <Col onClick={() => onRowClick(row.id)} style={{ cursor: 'pointer' }}>
        {row.requires_subscription ? 'Yes' : 'No'}
      </Col>
    </div>
  );
};
