import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  records: {
    ...controlDefaults,
    name: 'records',
    type: 'hidden'
  },
  job_no: {
    ...controlDefaults,
    placeholder: '',
    name: 'job_no',
    type: 'text',
    caption: 'Job No',
    validationRules: {
      isRequired: false
    },
  },
  purchase_order_no:{
    ...controlDefaults,
    placeholder: '',
    name: 'purchase_order_no',
    type: 'text',
    caption: 'Purchase Order No',
    validationRules: {
      isRequired: false
    },
  },
  transport_booking_no:{
    ...controlDefaults,
    placeholder: '',
    name: 'transport_booking_no',
    type: 'text',
    caption: 'Transport Booking No',
    validationRules: {
      isRequired: false
    },
  },
  lab_quote_no:{
    ...controlDefaults,
    placeholder: '',
    name: 'lab_quote_no',
    type: 'text',
    caption: 'Lab Quote No',
    validationRules: {
      isRequired: false
    },
  },
}