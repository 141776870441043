import React from 'react';
import AnimatedNumber from "animated-number-react";
import Icon from 'jsx/components/core/icons/Icon';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const StatsChart = (props) => {
  const {
    title,
    value,
    unit,
    bottomClasses,
    data,
    iconName,
    lineColour
  } = props;

  const formatValue = (value) => value.toLocaleString(undefined, { minimumFractionDigits: 0} );
  const bottomClassName = `text-white ${bottomClasses}`;

  let tileValue = value;
  if (typeof value !== 'string') {
    tileValue = (<AnimatedNumber value={value} formatValue={formatValue} />);
  }

  const className = `text-center bg-light text-${lineColour}`
  return (
    <div className="bg-light border border-secondary rounded p-0 m-1">
      <div className="p-1 text-center">
        <ResponsiveContainer width="100%" height={80}>
          <LineChart
            data={data}
            width={500}
            height={80}
            dataKey="name"
          >
            <Line type="monotone" unit="%" name="Tree Cover" dataKey="value" stroke={lineColour} strokeWidth={1} />
            <Tooltip />
            <CartesianGrid />
            <XAxis
              dataKey="collectedAt"
              hide={true}
            />
          </LineChart>
        </ResponsiveContainer>

        <h3 className={className}> 
          {tileValue}
          {unit}
          <Icon name={iconName} className="ml-2" />
        </h3>
      </div>

      <div className={bottomClassName}>{title}</div>
    </div>
  )
}

export default StatsChart;
