import React from 'react';
import DatePicker from 'react-datepicker';

const PillDateRange = (props) => {
  let { disabled, startDate, handleChange, caption, placeholder } = props;

  return (
    <div className="mb-2">
      <small className="p-1">{caption}</small>
      <div className="pill-date-range">
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          dateFormat="yyyy-MM-dd"
          className="form-control"
          disabled={disabled}
          placeholderText={placeholder}
        />
      </div>
    </div>
  );
};

export default PillDateRange;
