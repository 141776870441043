import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import DirectDownload from '../../../core/form/components/DirectDownload';

const LabRegisterMenu = (props) => {
  const {
    handleDownloadCustodyChains,
    handleUpdateRecords,
    disabled
  } = props;

  return (
    <React.Fragment>
      <div>
        <UncontrolledButtonDropdown>
          <DropdownToggle disabled={disabled} caret color='primary' size="sm">Action </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                handleDownload={(onProgressChange) => handleDownloadCustodyChains(onProgressChange)}
                caption="Download Custody Records"
                width={200}
                showLabel={true}
              />
            </DropdownItem>
            <DropdownItem toggle={false} onClick={handleUpdateRecords} className="ml-2 mr-2">
              <span>Update Records</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </React.Fragment>
  );
}

export default LabRegisterMenu;
