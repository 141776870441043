import React, { useState } from 'react';
import { Button, Label, Progress } from 'reactstrap';
import { noop } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';

const DirectDownload = (props) => {
  const {
    buttonType = '',
    caption = 'Download',
    classNames = '',
    disabled = false,
    handleDownload = noop,
    showLabel = false,
    showIcon = false,
    size = 'md',
    width = undefined
  } = props;

  let {
    filename = 'file.csv'
  } = props;

  const [progressValue, setProgressValue] = useState(0);
  const [progressText, setProgressText] = useState('');
  const [downloading, setDownloading] = useState(false);

  const updateProgressValue = (value) => {
    const valueAsString = String(value);
    setProgressText(valueAsString);
    if (value.endsWith('%')) {
      const valueAsFloat = parseFloat(value.slice(0, -1));
      setProgressValue(valueAsFloat);
    } else {
      setProgressValue(100);
    }
  };

  const handleChange = async () => {
    setProgressValue(100);
    setDownloading(true);
    const response = await handleDownload(updateProgressValue);

    if (response) {
      const contentDisposition = response.headers['content-disposition'];
      if (contentDisposition) {
        filename = contentDisposition.split('=')[1];
        console.log('Using contentDisposition filename', contentDisposition.split('=')[1] );
      }

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      /* When this control is in a menu, clicking the link collapses the menu and unmounts
       * this component. Need to get state updates done before this happens
       */
      setDownloading(false);
      setProgressValue(0);
      link.click();
      link.remove();
    } else {
      setDownloading(false);
      setProgressValue(0);
    }
  };

  return (
    <>
      <div className={classNames}>
        {downloading && (
          <Progress
            style={width && { width }}
            className="m-1 mt-2 text-center"
            animated
            value={progressValue}
            barClassName="p-1"
            barStyle={{minWidth: 100}}
          >{`${progressText}`}</Progress>
        )}
        {!showLabel && !downloading && (
          <Button size={size} color={buttonType} disabled={disabled} onClick={handleChange}>
            {showIcon && <Icon name="download" className="mr-2" />}
            {caption}
          </Button>
        )}
        {showLabel && !downloading && (
          <>
            {showIcon && <Icon name="download" className={`mr-2 text-${buttonType}`} />}
            <Label
              className={`text-${buttonType} mb-0`}
              style={{ outline: 'none', border: 'none', background: 'none' }}
              onClick={handleChange}
              tag="button"
              disabled={disabled}
            >
              {caption}
            </Label>
          </>
        )}
      </div>
    </>
  );
};

export default DirectDownload;
