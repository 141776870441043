import { get, remove, download, put, post } from "../../../../lib/genericAction";

import {
  apiPipelines
} from "../../../../constants/api";

export function setTabParams(tab, params) {
  return dispatch => {
    dispatch({ type: 'SET_PIPELINE_TAB_PARAMS', payload: { tab, params } });
    dispatch(fetchPipelineRounds(params));
  }
}

export function fetchPipelineRounds(params) {
  return async dispatch => await get(
    dispatch,
    'FETCH_PIPELINE_ROUNDS',
    `${apiPipelines}/rounds/${params.type}`,
    params
  )
}

export function fetchPipelines(params) {
  return async dispatch => await get(
    dispatch,
    'FETCH_PIPELINES',
    `${apiPipelines}`,
    params
  )
}

export function fetchPipeline(params) {
  return async dispatch => await get(
    dispatch,
    'FETCH_PIPELINE',
    `${apiPipelines}/${params.key}`,
    params
  )
}

export function updatePipeline(id, data) {
  return async dispatch => await put(
    dispatch,
    'UPDATE_PIPELINE',
    `${apiPipelines}/${id}`,
    data
  )
}

export function executePipeline(id) {
  return async dispatch => await post(
    dispatch,
    'EXECUTE_PIPELINE',
    `${apiPipelines}/${id}/execute`,
    null,
    true
  )
}

export function clearPipelines(params) {
  return async dispatch => await remove(
    dispatch,
    'REMOVE_PIPELINES',
    apiPipelines,
    params
  )
}

export function clearPipeline(key) {
  return dispatch => dispatch({
    type: 'FETCH_PIPELINE_CLEAR', payload: key
  })
}

export function fetchPipelineFiles(params) {
  return async dispatch => await get(
    dispatch,
    'FETCH_PIPELINE_FILES',
    `${apiPipelines}/files/byrounds`,
    params
  )
}

export function fetchPipelineDocuments(params) {
  return async dispatch => await get(
    dispatch,
    'FETCH_PIPELINE_DOCUMENTS',
    `${apiPipelines}/documents/byrounds`,
    params
  )
}

export function fetchPipelineDocument(pipeline_id, key) {
  return async dispatch => await get(
    dispatch,
    'FETCH_PIPELINE_DOCUMENT',
    `${apiPipelines}/${pipeline_id}/documents/${key}`
  )
}

export function removePipelineDocument(id) {
  return async dispatch => await remove(
    dispatch,
    'REMOVE_PIPELINE_DOCUMENT',
    `${apiPipelines}/documents/${id}`
  )
}

export function removePipelineDocuments(id) {
  return async dispatch => await remove(
    dispatch,
    'REMOVE_PIPELINE_DOCUMENTS',
    `${apiPipelines}/${id}/documents`
  )
}

export function downloadPipelineFile(id, onProgressChange) {
  return async dispatch => await download(
    dispatch,
    'DOWNLOAD_PIPELINE_FILE',
    `${apiPipelines}/files/${id}/download`,
    onProgressChange
  )
}

export function downloadPipelinePack(id, type, onProgressChange) {
  return async dispatch => await download(
    dispatch,
    'DOWNLOAD_PIPELINE_PACK',
    `${apiPipelines}/${id}/${type}/download`,
    onProgressChange
  )
}

export function downloadPipelineDocument(id, onProgressChange) {
  return async dispatch => await download(
    dispatch,
    'DOWNLOAD_PIPELINE_DOCUMENT',
    `${apiPipelines}/documents/${id}/download`,
    onProgressChange
  )
}
