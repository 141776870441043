import { uniq } from 'lodash';
import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },

  // Modal Controls
  name: {
    ...controlDefaults,
    caption: 'Name',
    group: 'project',
    name: 'name',
    showInEditor: true,
    disabled: true,
    type: 'text',
    validationRules: {
      isRequired: true,
    },
  },
  project_job_no: {
    ...controlDefaults,
    caption: 'Job No.',
    group: 'project',
    name: 'project_job_no',
    showInEditor: true,
    disabled: true,
    type: 'text',
    validationRules: {
      isRequired: true,
    },
  },
  org_name: {
    ...controlDefaults,
    caption: 'Organisation',
    group: 'association',
    name: 'org_name',
    showInEditor: true,
    disabled: true,
    type: 'text',
  },
  assignedto_id: {
    ...controlDefaults,
    caption: 'Project Manager',
    group: 'association',
    name: 'assignedto_id',
    showInEditor: true,
    type: 'select',
    includeEmptyOption: true,
    validationRules: {
      isRequired: true,
    },
  },
  approvers: {
    ...controlDefaults,
    caption: 'New approver',
    group: 'approvers',
    name: 'approvers',
    showInEditor: true,
    type: 'select',
    validationRules: {
      isRequired: false,
    },
  },

  // Listview Controls
  name_listview: {
    ...controlDefaults,
    caption: 'Project Name',
    fieldName: 'name',
    showInListview: true,
  },
  project_job_no_listview: {
    ...controlDefaults,
    caption: 'Job No.',
    fieldName: 'project_job_no',
    showInListview: true,
  },
  org_id_listview: {
    ...controlDefaults,
    caption: 'Organisation',
    fieldName: 'org.name',
    showInListview: true,
  },
  assignedto_id_listview: {
    ...controlDefaults,
    caption: 'Project Manager',
    fieldName: 'assignedto.name',
    showInListview: true,
  },
  approvers_listview: {
    ...controlDefaults,
    caption: 'Approvers',
    fieldName: 'approvers',
    showInListview: true,
    width: 250,
    type: 'custom',
    formatter: (approverList = []) => {
      const hasLevel1 = approverList.some((approver) => approver.level === 1);
      const hasLevel2 = approverList.some((approver) => approver.level === 2);
      const sortedNames = uniq(approverList.map((approver) => approver.user.name))
        .sort((left, right) => left.localeCompare(right))
        .join(', ');
      if (!hasLevel1 && !hasLevel2) {
        return <span className="text-danger">Missing 2</span>;
      } else if (!hasLevel1 || !hasLevel2) {
        return <span className="text-danger">{`${sortedNames} (Missing 1)`}</span>;
      }
      return <span>{sortedNames}</span>;
    },
  },
};
