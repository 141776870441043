import { Button } from 'reactstrap';

import { updateControls } from 'jsx/components/core/form/components/FormBuilder';
import GenericLsvAdapter from 'jsx/components/core/form/components/GenericLsvAdapter';
import { projectPropertyClientForm as form } from 'jsx/components/modules/projects/forms/project_property_client';
import { addPropertyId } from '../../lib/util';

const ProjectPropertyClientLsv = ({
  property: { id: propertyId, clients: rows },
  setModal,
  removeRecord,
}) => {
  const actions = [
    {
      func: async (id) => {
        const foundData = rows?.find(({ id: dataId }) => dataId === id);
        const formWithPropertyId = addPropertyId(form, propertyId);
        const updatedFormWithId = updateControls(formWithPropertyId, foundData);

        setModal(updatedFormWithId, 'client', 'edit');
      },
      iconName: 'pen-to-square',
      classes: 'text-primary ml-3',
    },
    {
      func: async (id) => {
        // eslint-disable-next-line no-alert
        const confirmed = window.confirm('This will permanently delete the data. Are you sure?');
        if (confirmed) {
          removeRecord('client', id);
        }
      },
      iconName: 'trash',
      classes: 'text-danger ml-3',
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between border-bottom align-items-end pb-1">
        <h5 className="text-corporate mb-0">Clients</h5>
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            const formWithPropertyId = addPropertyId(form, propertyId);
            setModal(formWithPropertyId, 'client', 'create');
          }}
        >
          Create New
        </Button>
      </div>
      {!rows?.length ? (
        <div>No property clients</div>
      ) : (
        <GenericLsvAdapter actions={actions} rows={rows} controls={form} />
      )}
    </div>
  );
};
export default ProjectPropertyClientLsv;
