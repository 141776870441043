import { get, post, remove, put } from '../../../../lib/genericAction';

import { apiDaysheets } from '../../../../constants/api';
import { createParamsFromFilters } from 'jsx/lib/actions';

export function fetchDaysheetBreaks(daysheet_id, filters) {
  const paramsWithFilters = createParamsFromFilters(filters);

  return async (dispatch) =>
    await get(
      dispatch,
      `FETCH_DAYSHEET_BREAKS`,
      `${apiDaysheets}/${daysheet_id}/breaks`,
      paramsWithFilters,
    );
}

export function fetchDaysheetBreak(id) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_DAYSHEET_BREAK`, `${apiDaysheets}/breaks/${id}`);
}

export function createDaysheetBreak(data, daysheet_id) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_DAYSHEET_BREAK`, `${apiDaysheets}/${daysheet_id}/breaks`, data);
}

export function createDaysheetBreaks(data) {
  return async (dispatch) =>
    await post(
      dispatch,
      `CREATE_DAYSHEET_BREAKS`,
      `${apiDaysheets}/${data.daysheet_id}/bulk-breaks`,
      data,
    );
}

export function updateDaysheetBreak(data, id) {
  return async (dispatch) =>
    await put(dispatch, `UPDATE_DAYSHEET_BREAK`, `${apiDaysheets}/breaks/${id}`, data);
}

export function deleteDaysheetBreak(id) {
  return async (dispatch) =>
    await remove(dispatch, `DELETE_DAYSHEET_BREAK`, `${apiDaysheets}/breaks/${id}`);
}
