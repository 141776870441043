import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    activeTab: '1',
    distributions: {},
    fetching: false,
    fetched: false,
    filters: {
      groups: [],
      categories: [],
    },
    fteSummary: {},
    labourflows: {
      paid: {},
      unpaid: {},
    },
    labours: {
      paid: {},
      unpaid: {},
    },
    params: {},
    responseMessage: null,
  },
  action
) {
  // Set expected reducer types
  const types = ['LABOUR', 'FTE'];

  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_FTE_SUMMARY_FULFILLED': {
      return {
        ...state,
        fteSummary: action.payload,
      };
    }
    case 'FETCH_LABOURS_FULFILLED': {
      return {
        ...state,
        labours: action.payload,
      };
    }
    case 'FETCH_LABOUR_DISTRIBUTIONS_FULFILLED': {
      return {
        ...state,
        distributions: action.payload,
      };
    }
    case 'FETCH_LABOURFLOWS_FULFILLED': {
      return {
        ...state,
        labourflows: action.payload,
      };
    }
    case 'SET_LABOUR_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case 'SET_LABOUR_PARAMS': {
      return {
        ...state,
        params: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
