import React from 'react';
import Listview from "../../../core/form/components/Listview";
import Icon from 'jsx/components/core/icons/Icon';

const SitesLsv = (props) => {
  const {
    rows,
    history,
    match
  } = props;

  const edit = (id) => {
    const project_id = match.params.project_id;
    const property_id = match.params.property_id;
    const area_id = match.params.area_id;
    const round_id = match.params.round_id;
    const strata_id = match.params.strata_id;
    const site_id = match.params.id;

    const lnk = `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${round_id}/stratas/${strata_id}/sites/${site_id}/samples/${id}`;
    history.push(lnk);
  }

  const renderRows = (headers, site) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        default: return  (
          <td key={index} className={header.classes}>{site[header.field]}</td>
        )
      }
    });

    tableTd.push(
      <td key={headers.length + 1} className="d-flex justify-content-center"><Icon name="pen-to-square" className="text-primary" /></td>
    )
    return tableTd;
  }

  const headers = [
    {caption: 'Position', field: 'position', classes:'text-center'},
    {caption: 'Upper Depth', field: 'upper_depth_mm', classes:'text-left'},
    {caption: 'Lower Depth', field: 'lower_depth_mm', classes:'text-left'},
    {caption: 'Red', field: 'reading_red', classes:'text-center'},
    {caption: 'Green', field: 'reading_green', classes:'text-center'},
    {caption: 'Blue', field: 'reading_blue', classes:'text-center'},
    {caption: 'Gamma', field: 'reading_gamma', classes:'text-right'},
    {caption: 'Texture', field: 'texture', classes:'text-center'},
    {caption: 'Colour', field: 'colour', classes:'text-center'},
    {caption: 'Munsell', field: 'munsell_colour', classes:'text-center'},
    {caption: 'Ph', field: 'ph', classes:'text-center'},
    {caption: 'Water Content', field: 'water_content', classes:'text-center'},
    {caption: 'Structure', field: 'structure', classes:'text-center'},
    {caption: 'Gravel', field: 'gravel_content', classes:'text-center'},
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );
  tableHeadTh.push(<th key={headers.length + 1}></th>);

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => edit(row.id)} style={{cursor: "pointer"}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='vials';
  const emptyCaption='No Samples found';

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </React.Fragment>
  );
}

export default SitesLsv;
