import {
  apiAssumedAnimalClassValues,
  apiAssumedAnimalClassValuesByMonth,
  apiAssumedAnimalClassValuesRanges,
  apiAssumedAnimalClasses,
} from 'jsx/constants/api';
import { get, post, put, remove } from 'jsx/lib/genericAction';

export function fetchAssumedAnimalClasses(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ASSUMED_ANIMAL_CLASSES', `${apiAssumedAnimalClasses}`, params);
}

export function fetchAssumedAnimalClass(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ASSUMED_ANIMAL_CLASS', `${apiAssumedAnimalClasses}/${id}`);
}

export function createAssumedAnimalClass(data) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_ASSUMED_ANIMAL_CLASS', `${apiAssumedAnimalClasses}`, data);
}

export function updateAssumedAnimalClass(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_ASSUMED_ANIMAL_CLASS', `${apiAssumedAnimalClasses}/${data.id}`, data);
}

export function removeAssumedAnimalClass(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_ASSUMED_ANIMAL_CLASS', `${apiAssumedAnimalClasses}/${id}`);
}

export function fetchAssumedAnimalClassValues(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ASSUMED_ANIMAL_CLASS_VALUES', `${apiAssumedAnimalClassValues}`, params);
}

export function fetchAssumedAnimalClassValuesByMonth(params) {
  return async (dispatch) =>
    get(
      dispatch,
      'FETCH_ASSUMED_ANIMAL_CLASS_VALUES_BY_MONTH',
      `${apiAssumedAnimalClassValuesByMonth}`,
      params,
    );
}

export function fetchAssumedAnimalClassValue(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ASSUMED_ANIMAL_CLASS_VALUE', `${apiAssumedAnimalClassValues}/${id}`);
}

export function createAssumedAnimalClassValue(data, inBackground = false) {
  return async (dispatch) =>
    post(
      dispatch,
      'CREATE_ASSUMED_ANIMAL_CLASS_VALUE',
      `${apiAssumedAnimalClassValues}`,
      data,
      inBackground,
    );
}

export function updateAssumedAnimalClassValue(data) {
  return async (dispatch) =>
    put(
      dispatch,
      'UPDATE_ASSUMED_ANIMAL_CLASS_VALUE',
      `${apiAssumedAnimalClassValues}/${data.id}`,
      data,
    );
}

export function removeAssumedAnimalClassValue(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_ASSUMED_ANIMAL_CLASS_VALUE', `${apiAssumedAnimalClasses}/${id}`);
}

export function fetchAssumedAnimalClassValueRanges() {
  return async (dispatch) =>
    get(
      dispatch,
      'FETCH_ASSUMED_ANIMAL_CLASS_VALUE_RANGES',
      `${apiAssumedAnimalClassValuesRanges}`,
    );
}
