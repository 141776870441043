import React, { Fragment } from 'react';
import { noop } from 'lodash';
import { Alert, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const ReportsToolbar = ({
  isFilterButtonDisabled = false,
  hasFiltersChanged = false,
  isFilterOpen = false,
  displayFilterButton = true,
  selectedReportId = null,
  onFilterClick = noop,
  onExportClick = noop,
}) => (
  <>
    <Alert color="primary" className="d-flex justify-content-between p-0">
      <div className="d-flex m-2">
        <Button size="sm" color="primary" onClick={() => onExportClick(selectedReportId)}>
          Export Report to PDF - BETA
        </Button>
      </div>
      <div className="d-flex justify-content-end m-2">
        {hasFiltersChanged && (
          <Alert color="light" className="m-0 px-2 py-1 fs-14px border-0">
            * Apply new filter values
          </Alert>
        )}
        {displayFilterButton && (
          <Button
            size="sm"
            color="warning"
            className="text-white ml-2"
            onClick={onFilterClick}
            disabled={isFilterButtonDisabled}
          >
            Edit Filters
            <Icon name={isFilterOpen ? 'chevron-up' : 'chevron-down'} className="ml-1" />
          </Button>
        )}
      </div>
    </Alert>
  </>
);

export default ReportsToolbar;
