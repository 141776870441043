import { defaults } from '../../../../lib/genericReducer';
export default function reducer(state = {
  fetching: false,
  fetched: false,
  error: null,
  authorised: true,
  authResponseMessage: null,
  responseMessage: null,
  trips: [],
  sites: []
}, action) {

  state = {
    ...state,
    ...defaults(action)
  }

  switch (action.type) {
    case "FETCH_RECON_SITES_FULFILLED": {
      return {
        ...state,
        sites: action.payload
      }
    }
    case "FETCH_RECON_TRIPS_FULFILLED": {
      return {
        ...state,
        trips: action.payload
      }
    }
    default: break;
  }
  return state;
}
