import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    adjustments: [],
    activeTab: 'cropflows',
    fetching: false,
    fetched: false,
    purchases: [],
    responseMessage: null,
    sales: [],
    summaries: {},
    cropflows: [],
    transfers: [],
    grazings: [],
    values: [],
    selectedProperties: [],
  },
  action
) {
  // Set expected reducer types
  const types = ['CROPPING', 'CROPFLOWS'];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_CROPPING_PURCHASES_FULFILLED': {
      return {
        ...state,
        purchases: action.payload,
      };
    }
    case 'FETCH_CROPPING_SALES_FULFILLED': {
      return {
        ...state,
        sales: action.payload,
      };
    }
    case 'FETCH_CROPPING_ADJUSTMENTS_FULFILLED': {
      return {
        ...state,
        adjustments: action.payload,
      };
    }
    case 'FETCH_CROPPING_TRANSFERS_FULFILLED': {
      return {
        ...state,
        transfers: action.payload,
      };
    }
    case 'FETCH_CROPPING_GRAZINGS_FULFILLED': {
      return {
        ...state,
        grazings: action.payload,
      };
    }
    case 'FETCH_CROPPING_VALUES_FULFILLED': {
      return {
        ...state,
        values: action.payload,
      };
    }
    case 'FETCH_CROPPING_SUMMARIES_FULFILLED': {
      return {
        ...state,
        summaries: action.payload,
      };
    }
    case 'FETCH_CROPFLOWS_FULFILLED': {
      return {
        ...state,
        cropflows: action.payload,
      };
    }
    case 'SET_CROPPING_ACTIVE_TAB': {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case 'SET_CROPPING_ALLOCATION_FILTER': {
      return {
        ...state,
        selectedProperties: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
