import React from 'react';
import {
  Input
} from 'reactstrap';
import Listview from "../../../core/form/components/Listview";
import Icon from 'jsx/components/core/icons/Icon';

import TimeFormat from '../lib/timeFormat';

const TimekeeperSummariesLsv = (props) => {
  const {
    rows,
    handleSelect,
    rowSelection,
    onView,
    currentUser
  } = props;

  const format = new TimeFormat();

  const renderRows = (headers, record) => {
    const isChecked = rowSelection ? (rowSelection.findIndex(id => id === record.id) > -1) : false

    const balance = format.asString(record['balance']);
    const extraClass = (record['balance'] < 0 ? 'text-danger' : 'text-success');

    const tableTd = headers.map((header, index) => {
      const name = `${record.firstname} ${record.lastname}`;

      switch (header.field) {
        case 'checkbox': {
          if (currentUser.id === record.id) return <td key={index} />;
          else return (
            <td key={index} onClick={(event) => { event.stopPropagation() }}><Input type="checkbox" className="m-0 mt-1" onChange={handleSelect} value={record.id} checked={isChecked} /></td>
          )
        }

        case 'leave_hours':
        case 'break_hours':
        case 'approved_hours':
        case 'rejected_hours':
        case 'pending_hours':
        case 'budget_hours':
        case 'net_hours': return (
          <td key={index} className={`${header.classes}`} style={header.style}>{format.asString(record[header.field])}</td>
        )

        case 'team_lead': return (
          <td key={index} className={header.classes} style={header.style}>{record.team_lead?.name ?? '-'}</td>
        )

        case 'balance': return (
          <td key={index} className={`${header.classes} ${extraClass}`} style={header.style}>{balance}</td>
        )

        case 'name': return (
          <td key={index} className={header.classes} style={header.style}>
            <Icon name="user" className={`${header.classes} mr-2`} />
            {name}
          </td>
        )
        default: return (
          <td key={index} className={header.classes} style={header.style}>{record[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const iconName = 'user-clock';
  const emptyCaption = 'No Summaries found for this week';

  const headers = [
    { caption: '-', field: 'checkbox', classes: 'text-center w-20' },
    { caption: 'User Name', field: 'name', classes: 'text-left', style: { width: 200 } },
    { caption: 'Email', field: 'email', classes: 'text-left' },
    { caption: 'Team Lead', field: 'team_lead', classes: 'text-left' },
    { caption: 'Budget (Hrs)', field: 'budget_hours', classes: 'text-right', style: { width: 100 } },
    { caption: 'Actual (Hrs)', field: 'net_hours', classes: 'text-right', style: { width: 100 } },
    { caption: 'Leave (Hrs)', field: 'leave_hours', classes: 'text-right', style: { width: 100 } },
    { caption: 'Break (Hrs)', field: 'break_hours', classes: 'text-right', style: { width: 100 } },
    { caption: 'Balance', field: 'balance', classes: 'text-right', style: { width: 100 } },
    { caption: 'Approved', field: 'approved_hours', classes: 'text-right', style: { width: 100 } },
    { caption: 'Rejected', field: 'rejected_hours', classes: 'text-right', style: { width: 100 } },
    { caption: 'Pending', field: 'pending_hours', classes: 'text-right', style: { width: 100 } },
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  )

  let tableBodyTr = (<tr></tr>);
  let rowsCount;

  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => onView(row.id)}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  return (
    <div className="b">
      <Listview rows={rows} rowsCount={rowsCount} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </div>
  );
}

export default TimekeeperSummariesLsv;
