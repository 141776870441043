/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import FormBase from 'jsx/components/core/form/components/FormBase';
import FormTab from 'jsx/components/core/form/components/FormTab';
import PageTitle from 'jsx/components/core/form/components/PageTitle';
import BenchmarksLsv from '../../components/benchmarking/BenchmarksLsv';
import Benchmark from './Benchmark';
import TrendAnalysis from '../trendanalysis/TrendAnalysis';

import { fetchWarehouseUserProbes, fetchWarehouseDistinctProperties } from '../../actions/warehouse';

import subscriptionImage from '../../../../../../images/farmeye/subscription_panel.png';

class Benchmarks extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      showLsv: false,
      probe_id: null,
      period_id: null,
      property_id: null,
      period_description: null,
      property_name: null,
      activeTab: 'probereports'
    };

    this.onClick = this.onClick.bind(this);
    this.goBack = this.goBack.bind(this);
    this.setDefaultProperty = this.setDefaultProperty.bind(this);
    this.getSubscription = this.getSubscription.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchWarehouseUserProbes());
    this.props.dispatch(fetchWarehouseDistinctProperties());
  }

  componentDidUpdate() {
    const { property_id } = this.state;
    const { userProbes } = this.props.warehouse;

    if (!property_id && userProbes.length > 0) {
      this.setDefaultProperty(userProbes);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setDefaultProperty(userProbes) {
    // Sort probes by date
    userProbes.sort((left, right) => new Date(left.period.date) - new Date(right.period.date));

    // Find last probe
    const probeIndex = userProbes.length -1;
    const probe = userProbes[probeIndex];

    this.setState({probe_id: probe.id, property_name: probe?.property?.name, period_id: probe.period_id, property_id: probe.property_id, period_description: probe.period.description});
  }

  onClick(row) {
    const period_todate = moment(row.period.date).format('MMM-YY');
    const period_fromdate = moment(row.period.date).subtract(1, 'year').add(1, 'month').format('MMM-YY');
    const period_description = `${period_fromdate} to ${period_todate}`;

    this.setState({probe_id: row.id, property_name: row?.property?.name, period_id: row.period_id, property_id: row.property_id, showLsv: false, period_description});
  }

  goBack() {
    this.setState({showLsv: true});
  }

  getSubscription() {
    const { subscriptions } = this.props.profile;

    let isSubscribed = false;
    const subscription = subscriptions.find(sub => sub.subscription_type.tag === 'benchmarks' && sub.enabled);

    // If expiry_on is null then no expiry
    if (subscription && !subscription.expires_on) isSubscribed = true;

    // If expiry is set and is still valid
    if (subscription && subscription.expires_on && subscription.expires_on > new Date()) isSubscribed = true;

    return isSubscribed;
  }

  render() {
    const { userProbes } = this.props.warehouse;
    const { currentApp } = this.props.realm;

    const { period_id, property_id, showLsv, period_description, property_name, probe_id, activeTab, selectedPropertyId } = this.state;
    const title = 'Benchmarking';
    const { isTrendAnalysisFeatureEnabled } = this.props;

    const isSubscribed = this.getSubscription();

    console.log("currentApp", currentApp)
    return (
      <div className="h-100">
        {isSubscribed && (
          <>
            <PageTitle title={title} />

            <Nav tabs className="mt-2">
              <FormTab
                caption="Probe Reports"
                tabId="probereports"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
              {isTrendAnalysisFeatureEnabled && (
                <FormTab
                  caption="Trend Analysis"
                  tabId="trendanalysis"
                  activeTab={activeTab}
                  toggle={this.toggleTab}
                />
              )}

            </Nav>
            <TabContent activeTab={activeTab} className="h-100">
              <TabPane tabId="probereports" className="mb-2 p-1">
                {showLsv && (
                  <>
                    <BenchmarksLsv
                      onClick={this.onClick}
                      rows={userProbes}
                    />
                  </>
                )}

                {property_id && (
                  <>
                    {/* <small className="m-1"><a href="#" onClick={this.goBack}>Back to Probes List</a></small> */}
                    <Benchmark showProbeSelect probe_id={probe_id} period_id={period_id} property_id={property_id} period_description={period_description} property_name={property_name} />
                  </>
                )}
              </TabPane>
              {isTrendAnalysisFeatureEnabled && (
                <TabPane tabId="trendanalysis" className="mb-2 h-100" style={{position: 'relative'}}>
                  <TrendAnalysis selectedPropertyId={property_id}/>
                </TabPane>
              )}
            </TabContent>
          </>
        )}

        {!isSubscribed && (
          <div className="text-center mt-2">
            <Icon
              name="tag"
              size="3x"
              className="text-corporate mt-3"
            />
            <h1>
              Subscribe to Benchmarking
            </h1>
            <div>
              Subscribe to ProfitProbe Essentials or Professional for Benchmarks access. More information here: <a href="https://www.rcsaustralia.com.au/tools" target="_blank">https://www.rcsaustralia.com.au/tools</a>
            </div>

            <div className="m-3">
              <div>Phone: {currentApp.help.contact.phone}
                <Icon
                  name="phone"
                  className="text-corporate ml-2"
                />
              </div>
              <div>Email: 
                <a className="ml-1" href={`mailto:${currentApp.help.contact.email}`}>{currentApp.help.contact.email}</a>
                <Icon
                  name="envelope"
                  className="text-corporate ml-2"
                />
                </div>
              <div>Website: 
                <a className="ml-1" href="https://www.rcsaustralia.com.au/tools" target="_blank">https://www.rcsaustralia.com.au/tools</a>
                <Icon
                  name="globe"
                  className="text-corporate ml-2"
                />
              </div>

            </div>
            <img src={subscriptionImage} className="mt-3" style={{maxWidth: '50%'}} alt="Subscription Panel"/>
          </div>
        )}
      </div>
    );
  };
}

const mapStoreToProps = ({ warehouse, profile, realm }) => ({
  warehouse,
  profile,
  realm
});

export default connect(mapStoreToProps)(Benchmarks);
