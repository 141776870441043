import { Button } from 'reactstrap';

import { updateControls } from 'jsx/components/core/form/components/FormBuilder';
import { notesForm as form } from 'jsx/components/modules/projects/forms/notes';
import GenericLsvAdapter from 'jsx/components/core/form/components/GenericLsvAdapter';
import { useCheckAccess } from 'jsx/lib/hooks';

import { addPropertyId } from '../../lib/util';

const NotesLsv = ({ property: { notes: rows, id: propertyId }, setModal, removeRecord }) => {
  const hasWriteAccess = useCheckAccess('projectRegisterUpdate');
  const actions = !hasWriteAccess
    ? []
    : [
        {
          func: async (id) => {
            const foundData = rows?.find(({ id: dataId }) => dataId === id);
            const formWithPropertyId = addPropertyId(form, propertyId);
            const updatedFormWithId = updateControls(formWithPropertyId, foundData);

            setModal(updatedFormWithId, 'note', 'edit');
          },
          iconName: 'pen-to-square',
          classes: 'text-primary ml-3',
        },
        {
          func: async (id) => {
            // eslint-disable-next-line no-alert
            const confirmed = window.confirm(
              'This will permanently delete the data. Are you sure?',
            );
            if (confirmed) {
              removeRecord('note', id);
            }
          },
          iconName: 'trash',
          classes: 'text-danger ml-3',
        },
      ];

  return (
    <div>
      <div className="d-flex justify-content-between border-bottom align-items-end pb-1">
        <h5 className="text-corporate mb-0">Notes</h5>
        {hasWriteAccess && (
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              const formWithPropertyId = addPropertyId(form, propertyId);
              setModal(formWithPropertyId, 'note', 'create');
            }}
          >
            Create New
          </Button>
        )}
      </div>
      {!rows?.length ? (
        <div>No notes</div>
      ) : (
        <GenericLsvAdapter actions={actions} rows={rows} controls={form} />
      )}
    </div>
  );
};

export default NotesLsv;
