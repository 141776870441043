/* eslint-disable id-length */
import { controlDefaults } from '../../../core/form/lib/validateForm';

/**
 * Base activity controls are for Daysheet Activities.
 * Order is necessary.
 */
export const baseActivityControls = {
  job_id: {
    ...controlDefaults,
    name: 'job_id',
    controlType: 'reactselect',
    type: 'reactselect',
    caption: 'Work Activity',
    group: 'Work Activity',
    options: [],
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    showInListview: true,
    showInEditor: true,
    col: 4,
    cols: { sm: 4 },
  },
  description: {
    ...controlDefaults,
    name: 'description',
    type: 'text',
    caption: 'Work Description',
    group: 'Work Activity',
    disabled: true,
    persist: false,
    showInListview: true,
    showInEditor: false,
    row2: true,
    col: 12,
    cols: { sm: 4 },
  },
  notes: {
    ...controlDefaults,
    name: 'notes',
    type: 'text',
    caption: 'Notes',
    group: 'Work Activity',
    persist: false,
    showInListview: true,
    showInEditor: true,
    row2: true,
    col: 10,
    cols: { sm: 4 },
  },
  labour_hours: {
    ...controlDefaults,
    name: 'labour_hours',
    type: 'number',
    caption: 'Labour Hrs',
    defaultValue: 0,
    description: 'Include breaks in hours',
    group: 'Work Activity',
    validationRules: {
      isRequired: true,
      greaterThanZero: true,
    },
    showInListview: true,
    showInEditor: true,
    col: 2,
    cols: { sm: 4 },
  },
  asset_id: {
    ...controlDefaults,
    includeInputReference: true,
    name: 'asset_id',
    controlType: 'reactselect',
    type: 'reactselect',
    caption: 'Asset #',
    group: 'Asset',
    options: [],
    validationRules: {
      isRequired: false,
    },
    showInListview: true,
    showInEditor: true,
    col: 2,
    cols: { sm: 4 },
  },
  smu_start: {
    ...controlDefaults,
    name: 'smu_start',
    type: 'number',
    caption: 'SMU Start',
    group: 'Asset',
    validationRules: {
      isRequired: false,
    },
    showInListview: true,
    showInEditor: true,
    col: 1,
    cols: { sm: 4 },
  },
  smu_end: {
    ...controlDefaults,
    name: 'smu_end',
    type: 'number',
    caption: 'SMU End',
    group: 'Asset',
    validationRules: {
      isRequired: false,
      compareFields: ['smu_start', 'smu_end'],
      compareMessage: 'Must be greater than SMU Start',
    },
    showInListview: true,
    showInEditor: true,
    col: 1,
    cols: { sm: 4 },
  },
  asset_hours: {
    ...controlDefaults,
    name: 'asset_hours',
    type: 'number',
    caption: 'Asset Hrs',
    group: 'Asset',
    defaultValue: 0,
    formula: '##smu_end## - ##smu_start##',
    formula_priority: 0,
    showInListview: true,
    showInEditor: true,
    disabled: true,
    validationRules: {
      correspondingFields: ['asset_id', 'asset_hours'],
      correspondingMessage: 'Asset Hours must be provided if Asset selected',
      greaterThanZero: true,
    },
    col: 1,
    cols: { sm: 4 },
  },
  load_count: {
    ...controlDefaults,
    name: 'load_count',
    type: 'number',
    caption: 'Load Count',
    group: 'Asset',
    defaultValue: 0,
    showInListview: true,
    showInEditor: true,
    col: 1,
    cols: { sm: 4 },
  },
};

/**
 * Extension of Daysheet activity controls used in Work Activities.
 * Order is not necessary.
 */
export const activityControls = {
  ...baseActivityControls,
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  phasecode_id: {
    ...controlDefaults,
    name: 'phasecode_id',
    type: 'select',
    caption: 'Phase',
    options: [],
    validationRules: {
      isRequired: false,
    },
    showInListview: false,
    showInEditor: false,
    col: 1,
  },
  jobphase: {
    ...controlDefaults,
    name: 'jobphase',
    type: 'text',
    caption: 'Project/Phase',
    validationRules: {
      isRequired: false,
    },
    showInListview: false,
  },
  load_count: {
    ...controlDefaults,
    name: 'load_count',
    type: 'number',
    caption: 'Load Count',
    defaultValue: 0,
    validationRules: {},
    showInListview: false,
    showInEditor: false,
    disabled: true,
    col: 1,
  },
  material_type_id: {
    ...controlDefaults,
    name: 'material_type_id',
    type: 'reactselect',
    options: [],
    caption: 'Material Type',
    defaultValue: 0,
    disabled: true,
    validationRules: {},
    showInListview: false,
    showInEditor: false,
    col: 2,
  },
  material_type: {
    ...controlDefaults,
    name: 'material_type',
    type: 'text',
    caption: 'Material Type',
    defaultValue: 0,
    validationRules: {},
    showInListview: false,
    disabled: true,
  },
};
