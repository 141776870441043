import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';

const ExternalLink = (props) => {
  const { divClasses, hasIcon, href, icon, iconClasses, title } = props;
  return (
    <div className={divClasses}>
      {hasIcon && <Icon icon={icon} className={iconClasses} />}
      <a href={href} target={'_blank'} rel={'noopener noreferrer'}>
        {title}
      </a>
    </div>
  );
};

export default ExternalLink;
