import {
  downloadSite,
  downloadFieldTrip
} from '../actions/downloads';

export default class Downloads {

  set(dispatch) {
    this.dispatch = dispatch;
  }

  async handleSiteDownload(site_id, onProgressChange) {
    return await this.dispatch(downloadSite(site_id, onProgressChange));
  }

  async handleFieldTripDownload(fieldtrip_id, onProgressChange) {
    return await this.dispatch(downloadFieldTrip(fieldtrip_id, onProgressChange));
  }

  
}