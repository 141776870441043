/* eslint-disable id-length */
import SamplingPlanEditor from 'jsx/components/manage/components/sampling-plans/SamplingPlanEditor';
import PlantProjects from 'jsx/components/modules/wipstar/containers/PlantProjects';
import Locations from 'jsx/components/modules/wipstar/containers/Locations';
import Home from '../containers/Home';
import Dashboard from '../containers/Dashboard';
import PropertySearch from '../components/modules/projects/containers/PropertySearch';
import Probes from '../components/modules/portrait/containers/Probes';
import Clients from '../components/modules/projects/containers/Clients';
import Client from '../components/modules/projects/containers/Client';
import SamplingPlans from '../components/modules/projects/containers/SamplingPlans';
import Projects from '../components/modules/projects/containers/Projects';
import Project from '../components/modules/projects/containers/Project';
import ProjectNew from '../components/modules/projects/containers/ProjectNew';
import Area from '../components/modules/areas/containers/Area';
import AreaRound from '../components/modules/areas/containers/AreaRound';
import AreaRoundStrata from '../components/modules/areas/containers/AreaRoundStrata';
import Site from '../components/modules/sites/containers/Site';
import Cores from '../components/modules/sites/containers/Cores';
import LabRegister from '../components/modules/lab/containers/LabRegister';
import LabRegisterRecord from '../components/modules/lab/containers/LabRegisterRecord';
import SiteSample from '../components/modules/sites/containers/SiteSample';
import ProjectProperty from '../components/modules/projects/containers/ProjectProperty';
import ProjectGroup from '../components/modules/projects/containers/groups/ProjectGroup';
import StageRequirement from '../components/modules/projects/containers/StageRequirement';
import DownloadAsyncReport from '../components/modules/projects/containers/DownloadAsyncReport';

import Timekeeper from '../components/modules/office/containers/Timekeeper';
import Resources from '../components/modules/office/containers/Resources';

import Pipelines from '../components/core/pipeline/containers/Pipelines';
import FieldBook from '../components/modules/sites/containers/FieldBook';
import FieldTrips from '../components/modules/sites/containers/FieldTrips';
import FieldUpload from '../components/modules/sites/containers/FieldUpload';

import Recon from '../components/modules/projects/containers/Recon';
import Analytics from '../components/modules/analytics/containers/Analytics';

import User from '../components/manage/containers/User';
import Invite from '../components/core/authentication/containers/Invite';
import Settings from '../components/manage/containers/Settings';
import Maintenance from '../components/core/authentication/containers/Maintenance';
import Masquerade from '../components/core/authentication/containers/Masquerade';
import Login from '../components/core/authentication/containers/Login';
import Logout from '../components/core/authentication/containers/Logout';
import ResetPassword from '../components/core/authentication/containers/ResetPassword';
import Forgot from '../components/core/authentication/containers/Forgot';
import Download from '../containers/Download';
import About from '../components/manage/containers/About';
import Probe from '../components/modules/portrait/containers/Probe';
import Enterprises from '../components/modules/portrait/containers/Enterprises';
import Assets from '../components/modules/wipstar/containers/Assets';
import WorkActivities from '../components/modules/wipstar/containers/Daysheets';
import Jobs from '../components/modules/wipstar/containers/Jobs';
import Report from '../components/manage/containers/ReportPreview';
import BenchmarkingAdmin from '../components/modules/portrait/containers/admin/AdminBenchmarking';
import Benchmark from '../components/modules/portrait/containers/benchmarking/Benchmark';

// Portals
import MapBoard from '../components/modules/portal/containers/MapBoard';
import FarmPortraitPortal from '../components/modules/portal/containers/FarmPortrait';
import PropertyRound from '../components/modules/projects/containers/property_rounds/PropertyRound';
import PortraitProperty from '../components/modules/portrait/containers/Property';
import MetricsEditor from '../components/manage/components/metrics/MetricsEditor';
import ReportAdmin from 'jsx/components/modules/portrait/containers/admin/ReportAdmin';
import AnalysisGroupAdmin from 'jsx/components/modules/portrait/containers/admin/AnalysisGroupAdmin';
import BenchmarkValues from 'jsx/components/modules/portrait/containers/admin/BenchmarkValues';

export const routes = [
  {
    path: '/',
    component: Dashboard,
    title: 'Dashboard',
    exact: true,
    authRequired: true,
  },
  {
    path: '/reports/:id',
    component: Report,
    title: 'Report',
    exact: true,
    authRequired: true,
  },
  {
    path: '/benchmarkvalues',
    component: BenchmarkValues,
    title: 'Benchmark Values',
    exact: true,
    authRequired: true,
  },
  {
    path: '/analysisgroupadmin',
    component: AnalysisGroupAdmin,
    title: 'Analysis Group/Periods',
    exact: true,
    authRequired: true,
  },
  {
    path: '/analytics',
    component: Analytics,
    title: 'Analytics',
    exact: true,
    authRequired: true,
  },
  {
    path: '/reportadmin',
    component: ReportAdmin,
    title: 'Report Metrics',
    exact: true,
    authRequired: true,
  },
  {
    path: '/benchmarking',
    component: BenchmarkingAdmin,
    title: 'Benchmarking',
    exact: true,
    authRequired: true,
  },
  {
    path: '/benchmark/:period_id/:property_id',
    component: Benchmark,
    title: 'Benchmark',
    exact: true,
    authRequired: true,
  },
  {
    path: '/jobs',
    component: Jobs,
    title: 'Work Activities/Work Orders',
    exact: true,
    authRequired: true,
  },
  {
    path: '/assets',
    component: Assets,
    title: 'Plant',
    exact: true,
    authRequired: true,
  },
  {
    path: '/workactivities',
    component: WorkActivities,
    title: 'WorkActivities',
    exact: true,
    authRequired: true,
  },
  {
    path: '/plant-projects',
    component: PlantProjects,
    title: 'Projects',
    exact: true,
    authRequired: true,
  },
  {
    path: '/locations',
    component: Locations,
    title: 'Locations',
    exact: true,
    authRequired: true,
  },
  {
    path: '/timekeeper',
    component: Timekeeper,
    title: 'Timekeeper',
    exact: true,
    authRequired: true,
  },
  {
    path: '/resources',
    component: Resources,
    title: 'Resources',
    exact: true,
    authRequired: true,
  },
  {
    path: '/recon',
    component: Recon,
    title: 'Recon',
    exact: true,
    authRequired: true,
  },
  {
    path: '/cores',
    component: Cores,
    title: 'Cores',
    exact: true,
    authRequired: true,
  },
  {
    path: '/lab',
    component: LabRegister,
    title: 'Lab Register',
    exact: true,
    authRequired: true,
  },
  {
    path: '/lab/:id',
    component: LabRegisterRecord,
    title: 'Lab Register Record',
    exact: true,
    authRequired: true,
  },
  {
    path: '/propertysearch',
    component: PropertySearch,
    title: 'Property Search',
    exact: true,
    authRequired: true,
  },
  {
    path: '/propertysearch/:project_id/:property_id',
    component: PropertySearch,
    title: 'Property Search',
    exact: true,
    authRequired: true,
  },
  {
    path: '/probes',
    component: Probes,
    title: 'Probes',
    exact: true,
    authRequired: true,
  },
  {
    path: '/probes/:id',
    component: Probe,
    title: 'Probe',
    exact: true,
    authRequired: true,
  },
  {
    path: '/enterprises',
    component: Enterprises,
    title: 'Enterprises',
    exact: true,
    authRequired: true,
  },
  {
    path: '/portal',
    component: FarmPortraitPortal,
    title: 'FarmPortraitPortal',
    exact: true,
    authRequired: true,
  },
  {
    path: '/portrait/property/:id',
    component: PortraitProperty,
    title: 'PortraitProperty',
    exact: true,
    authRequired: true,
  },
  {
    path: '/clients',
    component: Clients,
    title: 'Clients',
    exact: true,
    authRequired: true,
  },
  {
    path: '/clients/:id',
    component: Client,
    title: 'Client',
    exact: true,
    authRequired: true,
  },
  {
    path: '/samplingplans',
    component: SamplingPlans,
    title: 'SamplingPlans',
    exact: true,
    authRequired: true,
  },
  {
    path: '/settings/samplingplans/editor',
    component: SamplingPlanEditor,
    title: 'Sampling Plans Editor',
    exact: true,
    authRequired: true,
  },
  {
    path: '/settings/samplingplans/editor/:id',
    component: SamplingPlanEditor,
    title: 'Sampling Plans Editor',
    exact: true,
    authRequired: true,
  },
  {
    path: '/projects',
    component: Projects,
    title: 'Projects',
    exact: true,
    authRequired: true,
    tabs: ['register', 'project-properties', 'task-management', 'project-register'],
  },
  {
    path: '/projects/:id',
    component: Project,
    title: 'Project',
    exact: true,
    authRequired: true,
    tabs: [
      'register',
      'details',
      'properties',
      'document-requirements',
      'reporting-periods',
      'comment',
      'advanced',
    ],
  },
  {
    path: '/project/new',
    component: ProjectNew,
    title: 'ProjectNew',
    exact: false,
    authRequired: true,
  },
  {
    path: '/projects/:project_id/properties/:id',
    component: ProjectProperty,
    title: 'ProjectProperty',
    exact: true,
    authRequired: true,
    tabs: ['profile', 'rounds', 'areas', 'lots', 'round-results', 'round-comparison'],
  },
  {
    path: '/projects/:project_id/properties/:property_id/rounds/:id',
    component: PropertyRound,
    title: 'PropertyRound',
    exact: true,
    authRequired: true,
    tabs: ['profile', 'area-rounds', 'batch-scripts'],
  },
  {
    path: '/projects/:project_id/properties/:property_id/rounds/:id/:tab_id',
    component: PropertyRound,
    title: 'PropertyRound',
    exact: true,
    authRequired: true,
  },
  {
    path: '/projects/:project_id/properties/:property_id/areas/:area_id/rounds/:id',
    component: AreaRound,
    title: 'AreaRound',
    exact: true,
    authRequired: true,
    tabs: [
      'profile',
      'stratas',
      'sites',
      'sub-sample-selection',
      'lab-analysis',
      'lab-register',
      'pipelines',
      'pipeline-files',
      'pipeline-docs',
      'activity',
    ],
  },
  {
    path: '/projects/:project_id/properties/:property_id/areas/:area_id/rounds/:round_id/stratas/:id',
    component: AreaRoundStrata,
    title: 'AreaRoundStrata',
    exact: true,
    authRequired: true,
  },
  {
    path: '/projects/:project_id/properties/:property_id/areas/:area_id/rounds/:round_id/stratas/:strata_id/sites/:id/:tab_id',
    component: Site,
    title: 'Site',
    exact: false,
    authRequired: true,
  },
  {
    path: '/projects/:project_id/properties/:property_id/areas/:area_id/rounds/:round_id/stratas/:strata_id/sites/:site_id/samples/:id',
    component: SiteSample,
    title: 'SiteSample',
    exact: true,
    authRequired: true,
  },
  {
    path: '/projects/:project_id/groups/:id',
    component: ProjectGroup,
    title: 'Project Document Group',
    exact: true,
    authRequired: true,
  },
  {
    path: '/projects/:project_id/stage/:stage_id/requirements/:id',
    component: StageRequirement,
    title: 'StageRequirement',
    exact: true,
    authRequired: true,
  },
  {
    path: '/projects/:project_id/area/:area_id',
    component: Area,
    title: 'Area',
    exact: true,
    authRequired: true,
  },
  {
    path: '/fieldtrips',
    component: FieldTrips,
    title: 'FieldTrips',
    exact: true,
    authRequired: true,
    tabs: ['field-trip', 'field-uploads', 'recently-collected'],
  },
  {
    path: '/fieldtrips/fieldbook/:id',
    component: FieldBook,
    title: 'FieldBook',
    exact: true,
    authRequired: true,
  },
  {
    path: '/fieldtrips/field-uploads/:id',
    component: FieldUpload,
    title: 'Field Upload',
    exact: true,
    authRequired: true,
  },
  {
    path: '/pipelines',
    component: Pipelines,
    title: 'Pipelines',
    exact: true,
    authRequired: true,
  },
  {
    path: '/users/:id',
    component: User,
    title: 'User',
    exact: true,
    authRequired: true,
  },
  {
    path: '/settings',
    component: Settings,
    title: 'Settings',
    exact: true,
    authRequired: true,
  },
  {
    path: '/about',
    component: About,
    title: 'About',
    exact: true,
    authRequired: true,
  },
  {
    path: '/metrics/editor',
    component: MetricsEditor,
    title: 'Metrics Editor',
    exact: true,
    authRequired: true,
  },
  {
    path: '/metrics/editor/:id',
    component: MetricsEditor,
    title: 'Metrics Editor',
    exact: true,
    authRequired: true,
  },
  {
    path: '/download/:id',
    component: DownloadAsyncReport,
    title: 'Download your report',
    exact: true,
    authRequired: true,
  },
];

export const rootRoutes = [
  {
    path: '/',
    exact: true,
    component: Home,
    title: 'Login',
    authRequired: true,
  },
  {
    path: '/mapboard/:hash_key',
    exact: true,
    component: MapBoard,
    title: 'MapBoard',
    authRequired: false,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    title: 'Login',
    authRequired: false,
  },
  {
    path: '/masquerade',
    exact: true,
    component: Masquerade,
    title: 'Masquerade',
    authRequired: false,
  },
  {
    path: '/logout',
    component: Logout,
    title: 'Logout',
    exact: true,
    authRequired: true,
  },
  {
    path: '/maintenance',
    exact: true,
    component: Maintenance,
    title: 'Maintenance',
    authRequired: false,
  },
  {
    path: '/reset/:hash',
    exact: true,
    component: ResetPassword,
    title: 'ResetPassword',
    authRequired: false,
  },
  {
    path: '/forgot',
    exact: true,
    component: Forgot,
    title: 'Forgot Password',
    authRequired: false,
  },
  {
    path: '/invite/:id',
    exact: true,
    component: Invite,
    title: 'Invite',
    authRequired: false,
  },
  {
    path: '/home',
    component: Home,
    title: 'Home',
    exact: false,
    authRequired: true,
  },
  {
    path: '/download/projects/properties/areas/rounds/:round_id/:tag',
    component: Download,
    title: 'Download',
    exact: true,
    authRequired: true,
  },
];

export const rootRedirects = [
  {
    from: '/home/fieldtrips/fieldbook',
    to: '/home/fieldtrips',
    exact: true,
  },
  {
    from: '/home/projects/:project_id/properties/:property_id/areas/:area_id',
    to: '/home/projects/:project_id/properties/:property_id/areas',
    exact: true,
  },
  {
    from: '/home/projects/:project_id/properties/:property_id/areas/:area_id/rounds',
    to: '/home/projects/:project_id/properties/:property_id/rounds',
    exact: true,
  },
  {
    from: '/home/projects/:project_id/properties/:property_id/areas/:area_id/rounds/:round_id/stratas/:id/sites',
    to: '/home/projects/:project_id/properties/:property_id/areas/:area_id/rounds/:round_id/stratas/:id',
    exact: true,
  },
  {
    from: '/home/projects/:project_id/properties/:property_id/areas/:area_id/rounds/:round_id/stratas/:strata_id/sites/:id',
    to: '/home/projects/:project_id/properties/:property_id/areas/:area_id/rounds/:round_id/stratas/:strata_id',
    exact: true,
  },
];
