import React, { Fragment } from 'react';
import { Nav } from 'reactstrap';

import FormTab from '../../../../core/form/components/FormTab';

const PropertyRoundTabs = (props) => {
  let {
    activeTab,
    toggleTab,
    pipeline,
    tabDisabled
  } = props;

  return (
    <Fragment>
      <Nav tabs className="mt-2">
        <FormTab
          caption='Profile'
          tabTag='profile'
          tabId='1'
          activeTab={activeTab}
          toggle={toggleTab}
        />
        <FormTab
          caption='Area Rounds'
          tabTag='rounds'
          tabId='2'
          activeTab={activeTab}
          toggle={toggleTab}
        />
        <FormTab
          caption='Subsampling'
          tabTag='subsampling'
          tabId='3'
          activeTab={activeTab}
          toggle={toggleTab}
          disabled={tabDisabled}
          iconRightName={pipeline.subsampling?.statusIcon ?? null}
          iconRightClass={pipeline.subsampling?.statusClass ?? null}
          isSpinning={pipeline.subsampling?.isSpinning ?? null}
        />
        <FormTab
          caption='Lab Register'
          tabTag='lab_register'
          tabId='4'
          activeTab={activeTab}
          toggle={toggleTab}
          disabled={tabDisabled}
          isSpinning={pipeline.lab?.isSpinning ?? null}
          iconRightName={pipeline.lab?.statusIcon ?? null}
          iconRightClass={pipeline.lab?.statusClass ?? null}
        />
        <FormTab
          caption='Pipelines'
          tabTag="pipelines"
          tabId='5'
          activeTab={activeTab}
          toggle={toggleTab}
          disabled={tabDisabled}
        />

        <FormTab
          caption='Pipeline Files'
          tabTag="pipeline_files"
          tabId='6'
          activeTab={activeTab}
          toggle={toggleTab}
          disabled={tabDisabled}
        />

        <FormTab
          iconName='file'
          tabTag="docs"
          caption='Pipeline Docs'
          tabId='7'
          disabled={tabDisabled}
          activeTab={activeTab}
          toggle={toggleTab}
        />
        <FormTab
          caption='Batch Scripts'
          tabTag='batches'
          tabId='8'
          activeTab={activeTab}
          toggle={toggleTab}
        />
        <FormTab
          disabled={true}
          caption='Activity'
          tabTag='activities'
          tabId='9'
          activeTab={activeTab}
          toggle={toggleTab}
        />
      </Nav>
    </Fragment>
  );
}

export default PropertyRoundTabs;
