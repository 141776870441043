import { allowed, defaults } from '../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    responseMessage: null,
    activeTab: 'groups',
    groups: { count: 0, rows: [] },
    members: { count: 0, rows: [] },
    params: {
      search_value: '',
    },
  },
  action,
) {
  // Set expected reducer types
  const types = ['ANALYSIS_GROUP'];

  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_ANALYSIS_GROUPS_FULFILLED': {
      return {
        ...updatedState,
        groups: action.payload,
      };
    }
    case 'FETCH_ANALYSIS_GROUP_MEMBERS_FULFILLED': {
      return {
        ...updatedState,
        members: action.payload,
      };
    }
    case 'SET_ANALYSIS_GROUPS_ACTIVE_TAB': {
      return {
        ...updatedState,
        activeTab: action.payload,
      };
    }
    case 'SET_ANALYSIS_GROUP_PARAMS': {
      return {
        ...updatedState,
        params: action.payload,
      };
    }
    case 'SET_ANALYSIS_GROUP_MEMBER_PARAMS': {
      return {
        ...updatedState,
        params: action.payload,
      };
    }
    case 'SET_ANALYSIS_GROUP_MEMBER_RESPONSE_MESSAGE': {
      return {
        ...updatedState,
        responseMessage: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
