import React from 'react';
import { connect } from 'react-redux';
import { Collapse, Card, Col, Row } from 'reactstrap';
import PillPicker from '../../../core/form/components/PillPicker';
import PillPresenter from '../../../core/form/components/PillPresenter';
import { setOverheadflowFilters } from '../actions/overheads';

class OverheadsFilter extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleChange(event) {
    const { value } = event.target;
    let { filters, params } = this.props.overheads;

    // Add filter to categories
    const exists = filters.groups.find((id) => id === value);
    if (!exists) filters.groups.push(value);

    // Update reducer
    this.props.dispatch(setOverheadflowFilters(params, filters));
  }

  handleDelete(id) {
    let { filters, params } = this.props.overheads;

    // Remove filter from categories
    const idx = filters.groups.findIndex((filterId) => filterId === id);
    if (idx !== -1) filters.groups.splice(idx, 1);

    // Update reducer
    this.props.dispatch(setOverheadflowFilters(params, filters));
  }

  render() {
    const { filterOpen, groups } = this.props;
    const { filters } = this.props.overheads;

    const selection = filters.groups;
    const caption = 'Group';

    const filtersCount = Object.keys(filters)
      .map((key) => filters[key].length)
      .reduce((a, b) => a + b);

    return (
      <React.Fragment>
        <Collapse isOpen={filterOpen}>
          <Card className="border border-corporate rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col sm={3} className="bg-light m-1">
                <PillPicker
                  caption={caption}
                  handleChange={this.handleChange}
                  handleDelete={this.handleDelete}
                  rows={groups}
                  selection={selection}
                  showPills={false}
                />
              </Col>
            </Row>
          </Card>
        </Collapse>
        {filtersCount > 0 && (
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption={caption}
              handleDelete={this.handleDelete}
              rows={groups}
              selection={selection}
              colourField="colour"
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    overheads: store.overheads,
  };
};

export default connect(mapStoreToProps)(OverheadsFilter);
