import { cloneDeep, lowerCase } from 'lodash';

/**
 * @param {*} state - Australian state
 * @returns QLD, NSW, ACT, VIC, TAS, SA, WA, NT, ''
 */
export const normaliseStates = (state: string) => {
  switch (lowerCase(state)) {
    case 'nsw':
    case 'new south wales':
      return 'NSW';
    case 'sa':
    case 'south australia':
      return 'SA';
    case 'qld':
    case 'queensland':
      return 'QLD';
    case 'tas':
    case 'tasmania':
      return 'TAS';
    case 'wa':
    case 'western australia':
      return 'WA';
    case 'vic':
    case 'victoria':
      return 'VIC';
    case 'act':
    case 'australian capital territory':
      return 'ACT';
    case 'nt':
    case 'northern territory':
      return 'NT';
    case 'null':
      // some values have a string value of 'null' (not the null type), which we want to be empty
      return '';
    default:
      return state;
  }
};

/**
 * If the form is new or existing, it should always contain the property_id
 * @param form
 * @param propertyId
 * @returns
 */
export const addPropertyId = (
  form: Record<string, { value: string; [key: string]: unknown }>,
  propertyId: string,
) => {
  const updatedForm = cloneDeep(form);
  updatedForm.property_id.value = propertyId;

  return updatedForm;
};
