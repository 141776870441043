import React, { useState } from 'react';
import WidgetGauge from './WidgetGauge';
import WidgetLsv from './WidgetLsv';
import WidgetTile from './WidgetTile';
import WidgetDivisions from './WidgetDivisions';
import WidgetDivisionLsv from './WidgetDivisionLsv';
import Icon from 'jsx/components/core/icons/Icon';

import { Row } from 'reactstrap';

const WidgetGroup = (props) => {
  const { group, onEditMetric } = props;

  const [showLsv, toggleLsv] = useState(false);
  const [showGroup, toggleGroup] = useState(true);

  const renderMetrics = (group) => {
    return group.metrics.map((metric, index) => {
      switch (metric.default_widget_tag) {
        case 'division_grid':
          return (
            <WidgetDivisions key={index} metric={metric} onEdit={onEditMetric} showLsv={showLsv} />
          );
        case 'gauge':
          return (
            <WidgetGauge
              key={index}
              metric={metric}
              onEdit={() => onEditMetric(metric)}
              colSize={3}
            />
          );
        case 'tile':
          return (
            <WidgetTile
              key={index}
              metric={metric}
              onEdit={() => onEditMetric(metric)}
              colSize={3}
            />
          );
        default:
          return (
            <WidgetTile
              key={index}
              metric={metric}
              onEdit={() => onEditMetric(metric)}
              colSize={3}
            />
          );
      }
    });
  };

  const renderLsv = (group) => {
    const rows = group.metrics.map((metric) => {
      let value = '-';
      if (metric.data.value) {
        value = `${metric.unit_attributes.prefix || ''} ${metric.data.value.toFixed(2)} ${
          metric.unit_attributes.suffix || ''
        }`;
      }

      return {
        name: metric.name,
        value: value,
      };
    });

    return <WidgetLsv rows={rows} />;
  };

  const renderDivisionLsv = () => {
    return <WidgetDivisionLsv />;
  };

  return (
    <div>
      <div className="">
        <div className="d-flex justify-content-between bg-light border-bottom border-corporate">
          <h3 className="m-1 p-1">{group.name}</h3>
          <div>
            <Icon
              name="bars"
              className="mt-3 mr-3"
              onClick={() => toggleLsv(!showLsv)}
            />
            <Icon
              name={showGroup ? 'eye' : 'eye-slash'}
              className="mt-3 mr-3"
              onClick={() => toggleGroup(!showGroup)}
            />
          </div>
        </div>

        {!showLsv && showGroup && <Row className="m-0 mb-3">{renderMetrics(group)}</Row>}
        {showLsv && group.tag !== 'level5' && showGroup && <>{renderLsv(group)}</>}
        {showLsv && group.tag === 'level5' && showGroup && <>{renderDivisionLsv()}</>}
      </div>
    </div>
  );
};

export default WidgetGroup;
