import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane, Card } from 'reactstrap';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import Mapster from './Mapster';
import { buildFeature, buildFeatureCollection } from '../lib/mapster';
import Downloads from '../lib/downloads';

import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import PageTitle from '../../../core/form/components/PageTitle';
import ProjectsLsv from '../components/projects/ProjectsLsv';
import BreadcrumbsRoute from '../../../core/form/components/BreadcrumbsRoute';
import TaskManagementLsv from '../components/projects/TaskManagementLsv';
import ProjectsFilter from '../components/projects/ProjectsFilter';
import TaskManagementToolbar from '../components/projects/TaskManagementToolbar';
import ProjectNewModal from './ProjectNewModal';
import ProjectProperties from './ProjectsProperties';
import ProjectRegister from './ProjectRegister';

import {
  fetchProjects,
  fetchProjectStatuses,
  fetchProjectCentroids,
  setFilters,
} from '../actions/projects';
import { fetchJiraComments } from '../actions/jira';
import { fetchProperties } from '../actions/properties';
import { DEFAULT_PAGINATION } from '../constants';

import ProjectsMenu from '../components/projects/ProjectsMenu';

class Projects extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      activeTab: this.getActiveTabFromURL(),
      expandMap: false,
      showMiniMap: false,
      mapSources: [],
      filterValue: '',
      taskSearch: '',
      isProjectModalOpen: false,
      filteredProjects: [],
    };

    this.downloads = new Downloads();

    this.edit = this.edit.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.toggleMiniMap = this.toggleMiniMap.bind(this);
    this.getJiraComments = this.getJiraComments.bind(this);
    this.loadProjects = this.loadProjects.bind(this);
    this.loadTaskManagement = this.loadTaskManagement.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.getStatusOf = this.getStatusOf.bind(this);
    this.getProperties = this.getProperties.bind(this);
    this.setProjectModal = this.setProjectModal.bind(this);
    this.handleTaskManagementSearchChange = this.handleTaskManagementSearchChange.bind(this);
    this.filterTaskManagementProjects = this.filterTaskManagementProjects.bind(this);

    this.resetGeometry = this.resetGeometry.bind(this);
    this.setProjectStatusMaps = this.setProjectStatusMaps.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
  }

  componentDidMount() {
    const { filters } = this.props.projects;
    this.downloads.set(this.props.dispatch);
    this.resetGeometry();

    this.props.dispatch(fetchProjects({ limit: filters.limit }, filters));
  }

  componentDidUpdate() {
    this.setProjectStatusMaps();
  }

  onLoadMore() {
    const { filters } = this.props.projects;
    const { filterValue } = this.state;
    const newFilters = { ...filters, limit: filters.limit + DEFAULT_PAGINATION };
    this.props.dispatch(setFilters(newFilters));
    this.props.dispatch(
      fetchProjects(
        {
          limit: filters.limit,
          search_value: filterValue,
        },
        filters,
      ),
    );
  }

  getActiveTabFromURL() {
    const {
      match: { path = '' },
      tabRoutes = [],
    } = this.props;

    const isActiveTab = tabRoutes.find((curr) => {
      const subPaths = path.split('/');
      return subPaths[subPaths.length - 1] === curr;
    });

    return isActiveTab || 'register';
  }

  setProjectModal(isProjectModalOpen) {
    this.setState({ isProjectModalOpen });
  }

  edit(project) {
    const { id } = project;
    this.props.history.push(`/home/projects/${id}/register`);
  }

  resetGeometry() {
    const mapSources = [
      {
        id: 'project_statuses',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Status',
        count: 0,
        style: 'Point',
        showMarker: true,
      },
    ];
    this.setState({ mapSources });
  }

  toggleTab(tab) {
    const { filters } = this.props.projects;

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      this.props.history.replace(`/home/projects/${tab}`);
    }

    switch (tab) {
      case 'register':
        this.props.dispatch(fetchProjects({}, filters));
        break;
      case 'task-management':
        this.props.dispatch(fetchProjects({ include_jira: true }));
        break;
      case 'project-properties':
        this.props.dispatch(fetchProperties({ limit: DEFAULT_PAGINATION }));
        break;
      default:
        break;
    }
  }

  toggleMap() {
    this.setState({
      expandMap: !this.state.expandMap,
    });
  }

  async toggleMiniMap() {
    const { showMiniMap } = this.state;

    this.setState({
      showMiniMap: !showMiniMap,
    });

    // Load pins
    if (!showMiniMap) {
      this.props.dispatch(fetchProjectStatuses());
      await this.props.dispatch(fetchProjectCentroids());
    }
  }

  setProjectStatusMaps() {
    if (!this.state.showMiniMap) return;

    const { project_status_ids = [] } = this.props.projects;
    const { project_properties = [] } = this.props.projects;
    const { mapSources = [] } = this.state;
    const mapSource = mapSources.find((source) => source.id === 'project_statuses');
    const featureCollection = buildFeatureCollection();

    project_properties.forEach(({ project_status_id, properties, name: projectName }) => {
      const colour =
        project_status_ids.find((status) => status.id === project_status_id)?.colour ?? 'black';
      if (properties?.length > 0) {
        properties.forEach(({ name: propertyName, centroid }) => {
          if (centroid) {
            const popupText = `${propertyName.toUpperCase()} property part of ${projectName.toUpperCase()} project`;
            const feature = buildFeature(centroid, { colour, popupText });
            featureCollection.features.push(feature);
          }
        });
      }
    });

    if (mapSource.load || featureCollection.features.length !== mapSource.count) {
      mapSource.source = featureCollection;
      mapSource.legends = project_status_ids.map((status, index) => {
        const colour = status.id !== '' ? status.colour : 'black';
        const name = status.id !== '' ? status.name : 'No status';
        return (
          <div key={index} className="d-flex">
            <div className="m-1 p-2" style={{ backgroundColor: colour }} />
            <div>{`${name}`}</div>
          </div>
        );
      });
      mapSource.load = false;
      mapSource.count = featureCollection.features.length;
      this.setState({ mapSources });
    }
  }

  async getProperties(id, params) {
    if (id) {
      return this.props.dispatch(fetchProperties({ project_id: id }));
    }
    return this.props.dispatch(fetchProperties(params));
  }

  async getJiraComments(key) {
    return this.props.dispatch(fetchJiraComments(key));
  }

  loadProjects() {
    this.props.dispatch(fetchProjects({ include_properties: this.state.showMiniMap }));
  }

  loadTaskManagement() {
    this.props.dispatch(fetchProjects({ include_jira: true }));
  }

  handleSearchChange(filterValue) {
    const { filters } = this.props.projects;
    this.setState({ filterValue });
    this.props.dispatch(
      fetchProjects(
        { search_value: filterValue, include_properties: this.state.showMiniMap },
        filters,
      ),
    );
  }

  handleTaskManagementSearchChange(event) {
    const filterValue = event.target.value;
    this.setState({ taskSearch: filterValue });
    this.filterTaskManagementProjects(filterValue);
  }

  filterTaskManagementProjects(filterValue) {
    const { rows: projects } = this.props.projects.projects;
    let filteredRows = projects;

    if (filterValue) {
      const value = filterValue.toLowerCase();
      filteredRows = projects.filter((project) => {
        if (project.jira_key && project.jira_key.includes(filterValue)) return true;
        if (
          project.jira_issues &&
          (project.jira_issues.status.name.toLowerCase().indexOf(value) > -1 ||
            project.jira_issues.key.toLowerCase().indexOf(value) > -1 ||
            this.getLastComment(project.jira_issues).toLowerCase().indexOf(value) > -1 ||
            this.getStatusOf(project.jira_issues, value))
        )
          return true;
        if (project.name.toLowerCase().indexOf(value) > -1) return true;
        return false;
      });
    }

    this.setState({
      filteredProjects: filteredRows,
    });
  }

  getLastComment = (issues) => {
    let comments = [];

    if (issues && issues.comments.length > 0) {
      comments = issues.comments;
      comments.sort((left, right) => new Date(right.updated) - new Date(left.updated));
      return comments[0].body;
    }

    return '-';
  };

  getStatusOf(issues, value) {
    if (issues) {
      const statuses = this.getSubTaskStatuses(issues);
      const keys = Object.keys(statuses);
      return keys.filter((key) => key.toLowerCase().indexOf(value) > -1).length > 0;
    }
    return false;
  }

  static getSubTaskStatuses(issues) {
    // group statuses
    const statuses = {};
    const inProgress = issues.subtasks.filter(
      (subtask) => subtask.fields.status.statusCategory.key !== 'done',
    );
    inProgress.map((subtask) => {
      const { name } = subtask.fields.status.statusCategory;
      if (statuses[name]) {
        statuses[name].count += 1;
      } else {
        statuses[name] = { count: 1, colour: subtask.fields.status.statusCategory.colorName };
      }
      return false;
    });
    return statuses;
  }

  canViewProjectRegister() {
    return this.checkAccess('projectRegisterView') || this.checkAccess('projectRegisterUpdate');
  }

  render() {
    const { authResponseMessage } = this.props.projects;
    const { rows: properties, count: propertiesRowsCount } = this.props.properties.properties;
    const { rows: projects, count: projectsRowsCount } = this.props.projects.projects;
    const {
      filterValue,
      isProjectModalOpen,
      mapSources,
      expandMap,
      showMiniMap,
      filteredProjects: filteredProjectsFromState,
      taskSearch,
    } = this.state;

    const iconName = 'diagram-project';
    const title = 'Projects';
    const description =
      'Manage and monitor progress for carbon projects including dashboarding for connected Jira issues';
    const lngLat = [150.7333, -23.1333];
    const filteredProjects = filteredProjectsFromState.length
      ? filteredProjectsFromState
      : projects;

    return (
      <div>
        {!expandMap && (
          <div className="p-3">
            <div className="d-flex flex-row justify-content-between">
              <PageTitle title={title} description={description} iconName={iconName} />
              <div className="d-flex">
                <ProjectsMenu
                  handleProjectStatusMap={(event, showPoints = false) =>
                    this.downloads.handleProjectStatusMapDownload(event, showPoints)
                  }
                  toggleMiniMap={this.toggleMiniMap}
                  showMiniMap={showMiniMap}
                />
              </div>
            </div>

            <ResponseMessage responseMessage={authResponseMessage} />

            {this.state.showMiniMap && (
              <Card className="border rounded mt-2" style={{ minHeight: '300px' }}>
                <Mapster
                  handleSourceVisibility={this.handleSourceVisibility}
                  expandMap={expandMap}
                  lngLatCenter={lngLat}
                  toggleMap={this.toggleMap}
                  mapSources={mapSources}
                />
              </Card>
            )}

            <BreadcrumbsRoute match={this.props.match} />

            <Nav tabs className="mt-2">
              <FormTab
                caption="Projects"
                tabId="register"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
                tabTag="register"
              />
              <FormTab
                caption="Properties"
                tabId="project-properties"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
                tabTag="project-properties"
              />
              <FormTab
                caption="Task Management"
                tabId="task-management"
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
                tabTag="task-management"
              />
              {this.canViewProjectRegister() && (
                <FormTab
                  caption="Project Register"
                  tabId="project-register"
                  activeTab={this.state.activeTab}
                  toggle={this.toggleTab}
                  tabTag="project-register"
                />
              )}
            </Nav>

            <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
              <TabPane tabId="register" className="mb-2 p-3">
                {this.state.activeTab === 'register' && (
                  <>
                    <ProjectsFilter
                      filterValue={filterValue}
                      checkAccess={this.checkAccess}
                      reload={this.loadProjects}
                      handleSearchChange={this.handleSearchChange}
                      projects={projects}
                      setModal={this.setProjectModal}
                      rowsCount={projectsRowsCount}
                    />
                    <ProjectsLsv
                      getJiraComments={this.getJiraComments}
                      getProperties={this.getProperties}
                      history={this.props.history}
                      projects={projects}
                      onClick={this.edit}
                      getSubTaskStatuses={this.getSubTaskStatuses}
                      checkAccess={this.checkAccess}
                      onLoadMore={this.onLoadMore}
                      rowsCount={projectsRowsCount}
                    />
                    <ProjectNewModal setModal={this.setProjectModal} isOpen={isProjectModalOpen} />
                  </>
                )}
              </TabPane>
              <TabPane tabId="project-properties" className="mb-2 p-3">
                {this.state.activeTab === 'project-properties' && (
                  <ProjectProperties
                    rowsCount={propertiesRowsCount}
                    properties={properties}
                    getProperties={this.getProperties}
                    history={this.props.history}
                  />
                )}
              </TabPane>
              <TabPane tabId="task-management" className="mb-2 p-3">
                {this.state.activeTab === 'task-management' && (
                  <>
                    <TaskManagementToolbar
                      filterValue={taskSearch}
                      checkAccess={this.checkAccess}
                      reload={this.loadTaskManagement}
                      handleSearchChange={this.handleTaskManagementSearchChange}
                      projects={filteredProjects}
                      setModal={this.setProjectModal}
                    />
                    <TaskManagementLsv
                      getJiraComments={this.getJiraComments}
                      getProperties={this.getProperties}
                      history={this.props.history}
                      projects={filteredProjects}
                      onClick={this.edit}
                      getLastComment={this.getLastComment}
                      getSubTaskStatuses={this.getSubTaskStatuses}
                      checkAccess={this.checkAccess}
                    />
                  </>
                )}
              </TabPane>
              <TabPane tabId="project-register" className="mb-2 p-3">
                {this.state.activeTab === 'project-register' && this.canViewProjectRegister() && (
                  <ProjectRegister />
                )}
              </TabPane>
            </TabContent>
          </div>
        )}
        {this.state.expandMap && (
          <Mapster
            handleSourceVisibility={this.handleSourceVisibility}
            expandMap={expandMap}
            lngLatCenter={lngLat}
            toggleMap={this.toggleMap}
            mapSources={mapSources}
          />
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ projects, properties, realm, stages }) => ({
  projects,
  properties,
  realm,
  stages,
});

export default connect(mapStoreToProps)(withContainerError(Projects));
