import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Button, Alert, Collapse } from 'reactstrap';
import { upperFirst, omit, isEmpty, fromPairs, merge } from 'lodash';

import { GenericFormModal } from 'jsx/components/core/form/components/GenericFormModal';
import FormInput from 'jsx/components/core/form/components/FormInput';
import {
  useUpdateControls,
  removeUntouchedFields,
  prepareForSubmission,
} from 'jsx/components/core/form/components/FormBuilder';

import { useCheckAccess } from 'jsx/lib/hooks';
import { projectRegisterPropertyForm } from '../../forms/project_register_property';

import {
  createProjectProperty,
  getProject,
  createProject,
  createVariation,
  updateVariation,
  removeVariation,
  createNote,
  updateNote,
  removeNote,
  createPeriod,
  updatePeriod,
  removePeriod,
  createAudit,
  updateAudit,
  removeAudit,
  fetchAttributes,
  getProjectOfficers,
  updateClient,
  createClient,
  removeClient,
} from '../../actions/project_register';

import VariationsLsv from './VariationsLsv';
import NotesLsv from './NotesLsv';
import PeriodsLsv from './PeriodsLsv';
import AuditsLsv from './AuditsLsv';
import ProjectRegisterPropertyForm from './RegisterOverviewProjectPropertyForm';
import ProjectRegisterProjectForm from './RegisterOverviewProjectForm';
import ProjectPropertyClientLsv from './ProjectPropertyClientLsv';

const PropertyForm = ({ form, property }) => {
  const propertyForm = useUpdateControls(form, property);

  return (
    <Row>
      <Col sm={2}>
        <FormInput disabled control={propertyForm.idx} />
      </Col>
      <Col sm={4}>
        <FormInput disabled control={propertyForm.propertyName} />
      </Col>
      <Col sm={6}>
        <FormInput disabled control={propertyForm.address} />
      </Col>
      <Col sm={2} className="d-flex align-items-end"></Col>
    </Row>
  );
};

/**
 * Returns the create / update / delete actions for the given modal type
 * @param {string} type
 * @returns
 */
const mapTypeToActions = (type) => {
  switch (type) {
    case 'variation':
      return [updateVariation, createVariation, removeVariation];
    case 'note':
      return [updateNote, createNote, removeNote];
    case 'period':
      return [updatePeriod, createPeriod, removePeriod];
    case 'audit':
      return [updateAudit, createAudit, removeAudit];
    case 'client':
      return [updateClient, createClient, removeClient];
    default:
      return [];
  }
};

const RegisterOverview = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [modalType, setModalType] = useState({ type: null, mode: null });
  const { id: projectId } = useParams();
  const {
    responseMessage,
    currentProjectRegister,
    attributes,
    officers = [],
  } = useSelector((state) => state.project_register);
  const { type: modalName, mode: modalMode } = modalType;
  const [updateAction, createAction] = mapTypeToActions(modalName);
  const isProjectRegisterCreated = !isEmpty(currentProjectRegister);

  const getData = useCallback(() => {
    // Fetch all data required for reload
    dispatch(getProject(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    // Fetch all data required for initial load
    getData();
    dispatch(fetchAttributes({ type: 'period_types' }));
    dispatch(fetchAttributes({ type: 'audit_types' }));
    dispatch(getProjectOfficers());
  }, [projectId, getData, dispatch]);

  const setModal = (form, type, mode) => {
    setCurrentForm(form);
    setIsOpen(true);
    setModalType({ type, mode });
  };

  const onModalSubmit = async (data, dirtyFields) => {
    const { id } = data;
    let response;
    if (modalType.mode === 'edit') {
      /**
       * By default, we want to retain the 'hidden' fields as these often contain
       * ids that are required for the backend to process the request.
       */
      const hiddenFields = fromPairs(
        Object.entries(currentForm).map(([key, value]) => {
          if (value.type === 'hidden') {
            return [key, true];
          }
          return [key, false];
        }),
      );
      const dataWithUntouchedFields = removeUntouchedFields(data, {
        ...hiddenFields,
        ...dirtyFields,
      });
      const dataWithDefaults = prepareForSubmission(dataWithUntouchedFields, currentForm);
      response = await dispatch(updateAction({ id, data: dataWithDefaults }));
    } else {
      const dataWithDefaults = prepareForSubmission(data, currentForm);
      response = await dispatch(createAction({ data: omit(dataWithDefaults, ['id']) }));
    }

    if (response) {
      setIsOpen(false);
      getData(projectId);
    }
  };

  const removeRecord = async (type, id) => {
    const [, , deleteAction] = mapTypeToActions(type);
    await dispatch(deleteAction(id));
    getData(projectId);
  };

  const createProjectRegisterProject = async () => {
    await dispatch(createProject(projectId));
    getData(projectId);
  };

  const createProjectRegisterProperty = async (propertyId) => {
    await dispatch(createProjectProperty(propertyId));
    getData(projectId);
  };

  const hasWriteAccess = useCheckAccess('projectRegisterUpdate');

  const [sections, setSections] = useState({ project: { show: true } });

  useEffect(() => {
    let updatedSections = { project: { show: true } };
    if (currentProjectRegister?.project?.properties?.length > 0) {
      const propertySections = currentProjectRegister.project.properties.map((property) => ({
        [`property-section-${property.id}`]: { show: true },
      }));
      // Merge all property sections into the initial state
      propertySections.forEach((section) => {
        updatedSections = merge(updatedSections, section);
      });
      setSections(updatedSections);
    }
  }, [currentProjectRegister?.project?.properties]);

  const toggleSection = (name) => {
    const show = !sections[name]?.show;

    if (sections[name]?.show !== show) {
      const updatedSections = { ...sections, [name]: { show } };
      setSections(updatedSections);
    }
  };

  return (
    <>
      <GenericFormModal
        errorMessage={responseMessage}
        title={`${upperFirst(modalMode)} ${upperFirst(modalName)}`}
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(!isOpen);
          if (isOpen) {
            setCurrentForm(null);
          }
        }}
        onSubmit={onModalSubmit}
        controls={currentForm}
      />

      <Card className="bg-light border">
        <CardHeader className="bg-corporate text-white px-2 py-1">
          <div className="d-flex justify-content-between align-items-center">
            {currentProjectRegister?.project?.name}
            <Button outline={false} color="corporate" onClick={() => toggleSection('project')}>
              {sections?.project?.show ? 'Hide' : 'Show'}
            </Button>
          </div>
        </CardHeader>
        <Collapse isOpen={sections?.project?.show}>
          <CardBody>
            {isProjectRegisterCreated && (
              <ProjectRegisterProjectForm
                refresh={() => getData()}
                registerId={currentProjectRegister?.project_id}
              />
            )}
            {!isProjectRegisterCreated && hasWriteAccess && (
              <Alert
                color="info"
                className="d-flex justify-content-between align-items-center mt-4"
              >
                <div>
                  A project register has not been created for this project. Would you like to create
                  one now?
                </div>
                <div className="d-flex justify-content-end">
                  <Button color="primary" onClick={createProjectRegisterProject}>
                    Create project register
                  </Button>
                </div>
              </Alert>
            )}
          </CardBody>
        </Collapse>
      </Card>

      {currentProjectRegister?.project?.properties.map((property) => (
        <Card key={property.id} className="bg-light border">
          <CardHeader className="bg-corporate text-white px-2 py-1">
            <div className="d-flex justify-content-between align-items-center">
              {property?.name}
              <Button
                outline={false}
                color="corporate"
                onClick={() => toggleSection(`property-section-${property.id}`)}
              >
                {sections[`property-section-${property.id}`]?.show ? 'Hide' : 'Show'}
              </Button>
            </div>
          </CardHeader>
          <Collapse isOpen={sections[`property-section-${property.id}`]?.show}>
            <CardBody>
              <PropertyForm form={projectRegisterPropertyForm} property={property} />
              {!property?.register && hasWriteAccess && (
                <Alert
                  color="info"
                  className="d-flex justify-content-between align-items-center mt-4"
                >
                  <div>
                    A property register has not been created for this property. Would you like to
                    create one now?
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      color="primary"
                      onClick={() => createProjectRegisterProperty(property?.id)}
                    >
                      Create property register
                    </Button>
                  </div>
                </Alert>
              )}
              {property?.register && (
                <>
                  <ProjectRegisterPropertyForm
                    register={property?.register}
                    propertyId={property?.id}
                    refresh={() => getData(projectId)}
                    officers={officers}
                  />
                  <div className="mt-4">
                    <ProjectPropertyClientLsv
                      property={property}
                      refresh={() => getData(projectId)}
                      setModal={setModal}
                      removeRecord={removeRecord}
                    />
                  </div>
                  <div className="mt-4">
                    <VariationsLsv
                      property={property}
                      refresh={() => getData(projectId)}
                      setModal={setModal}
                      removeRecord={removeRecord}
                    />
                  </div>
                  <div className="mt-3">
                    <NotesLsv
                      property={property}
                      refresh={() => getData(projectId)}
                      setModal={setModal}
                      removeRecord={removeRecord}
                    />
                  </div>
                  <div className="mt-3">
                    <PeriodsLsv
                      property={property}
                      refresh={() => getData(projectId)}
                      setModal={setModal}
                      removeRecord={removeRecord}
                      periodType={attributes?.periodType}
                    />
                  </div>
                  <div className="mt-3">
                    <AuditsLsv
                      property={property}
                      refresh={() => getData(projectId)}
                      setModal={setModal}
                      removeRecord={removeRecord}
                      auditTypes={attributes?.auditTypes}
                    />
                  </div>
                </>
              )}
            </CardBody>
          </Collapse>
        </Card>
      ))}
    </>
  );
};

export default RegisterOverview;
