import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const OffFarmMenu = ({ setModal = noop }) => {
  return (
    <div>
      <UncontrolledButtonDropdown>
        <DropdownToggle caret color="success" size="sm">
          <Icon name="plus" className="mr-1" />
          Action
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem toggle={true} onClick={() => setModal(true)}>
            Add Income
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
};

export default OffFarmMenu;
