import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import {
  validateChange,
  initControls,
  saveControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { controls as projectPropertiesControls } from '../forms/projectProperties';
import FormInput from '../../../core/form/components/FormInput';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import { createProperty, fetchProperties } from '../actions/properties';

class ProjectPropertyNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      controls: projectPropertiesControls,
      isValid: true,
      isOpen: false,
      title: 'New Property',
      data: {},
      id: null,
      isNew: false,
      project_id: null,
      setModal: null,
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    let { isNew, title } = this.state;
    const { isOpen, id } = this.state;

    if (isOpen !== this.props.isOpen) {
      isNew = true;
      title = 'New Property';
      this.setState({ controls: initControls(this.state.controls) });

      this.setState({
        isOpen: this.props.isOpen,
        id,
        project_id: this.props.project_id,
        title,
        isNew,
        setModal: this.props.setModal,
      });
    }
  }

  async onSave() {
    const { data, controls, project_id } = this.state;

    controls.project_id.value = project_id;

    const keys = Object.keys(controls);
    keys.forEach((key) => {
      data[key] = controls[key].value;
    });
    const { isValid } = validateFormFieldControls(data, controls);

    if (isValid) {
      delete data.id;

      const success = await this.props.dispatch(createProperty(data));
      if (success) {
        this.props.dispatch(fetchProperties({ project_id: data.project_id }));
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  handleChange(event) {
    const controls = validateChange(event, this.state.controls);
    const changes = saveControls(controls, this.state.data);
    const { isValid } = validateFormFieldControls(changes, controls);

    this.setState({
      controls,
      formChanged: true,
      isValid,
    });
  }

  render() {
    const { controls, isOpen, title } = this.state;

    const { responseMessage } = this.props.properties;

    if (controls.included.value === null) controls.included.value = true;

    const iconName = 'farm';
    controls.idx.disabled = false;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          <ResponseMessage responseMessage={responseMessage} />
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput handleChange={this.handleChange} control={controls.idx} />
            <FormInput handleChange={this.handleChange} control={controls.included} />
            <FormInput handleChange={this.handleChange} control={controls.address} />
            <FormInput handleChange={this.handleChange} control={controls.nearest_town} />
            <FormInput handleChange={this.handleChange} control={controls.state} />
            <FormInput handleChange={this.handleChange} control={controls.nrm_region} />
            <FormInput handleChange={this.handleChange} control={controls.lga} />
            <FormInput handleChange={this.handleChange} control={controls.primary_practice} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
            Cancel
          </Button>
          <Button size="sm" color="success" onClick={this.onSave}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => ({
  properties: store.properties,
});

export default connect(mapStoreToProps)(ProjectPropertyNew);
