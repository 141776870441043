import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Row, Col } from 'reactstrap';
import { cloneDeep, isNil } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import { controls as projectControls } from '../forms/project';
import {
  createProject,
  fetchProject,
  removeProject,
  updateProject,
} from '../../projects/actions/projects';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import ProjectApproverListview from '../components/ProjectApproverListview';

class ProjectModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(projectControls),
      data: {},
      id: null,
      isNew: true,
      title: 'Project',
      newApprover: {},
      newApproverLevel: 1,
      approvers: [],
    };

    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
    this.selectApprover = this.selectApprover.bind(this);
    this.selectApproverLevel = this.selectApproverLevel.bind(this);
    this.onCreateApprover = this.onCreateApprover.bind(this);
    this.deleteApprover = this.deleteApprover.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { users } = this.props.manage;

      const controls = initControls(cloneDeep(projectControls));
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        approvers: [],
        title: 'Project',
      };

      updateControlOptions(controls, 'assignedto_id', users);
      updateControlOptions(controls, 'approvers', users);

      if (this.props.id) {
        const { id } = this.props;

        const data = await this.props.dispatch(fetchProject(id));

        if (data && data.org) {
          data.org_name = data.org.name;
        }

        updatedState = {
          ...updatedState,
          controls: updateControls(controls, data),
          data,
          approvers: data.approvers || [],
          id,
          isNew: false,
          title: 'Edit Project',
        };
      }

      this.setState(updatedState);
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.refresh) this.props.refresh();
    this.props.setModal(false);
    this.setState({ data: {}, approvers: [] });
  }

  async onSave() {
    const { controls, data, isNew, approvers } = this.state;

    const controlData = saveControls(controls, data);
    const saveData = {
      ...controlData,
      approvers,
    };

    const { isValid, updatedControls } = await validateFormFieldControls(saveData, controls);

    if (isValid) {
      let saveMethod = updateProject;

      if (isNew) {
        delete saveData.id;
        saveMethod = createProject;
      }

      const success = await this.props.dispatch(saveMethod(saveData));

      if (success) this.onClose(true);

      return;
    }

    // Update controls state to display messages to the user
    this.setState({
      controls: updatedControls,
    });
  }

  selectApprover(selection) {
    const { users } = this.props.manage;
    const selectedUser = users.find(({ id }) => id === selection.value);
    this.setState({
      newApprover: selectedUser,
    });
  }

  selectApproverLevel(event) {
    this.setState({
      newApproverLevel: parseInt(event.target.value, 10),
    });
  }

  async onCreateApprover() {
    const { newApprover, newApproverLevel, approvers } = this.state;
    approvers.push({ level: newApproverLevel, user: newApprover, user_id: newApprover.id });
    this.setState({ approvers, newApprover: {}, newApproverLevel: 1 });
  }

  async deleteApprover(userId, level) {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm('Are you sure you want to remove this approver?');

    if (confirmed) {
      const { approvers } = this.state;
      const filteredApprovers = approvers.filter(
        ({ user, level: approverLevel }) => user.id !== userId || approverLevel !== level,
      );
      this.setState({ approvers: filteredApprovers });
    }
  }

  approverValidationProblem() {
    const approvers = this.state.approvers ?? [];
    const approverLevel1 = approvers.find(({ level }) => level === 1);
    const approverLevel2 = approvers.find(({ level }) => level === 2);

    if (isNil(approverLevel1) || isNil(approverLevel2)) {
      return 'Projects require a level 1 and level 2 approver';
    }

    return null;
  }

  canAddApprover() {
    const { newApproverLevel, newApprover } = this.state;

    return newApproverLevel && newApprover?.id;
  }

  render() {
    const { controls, approvers } = this.state;
    const { title, newApprover, newApproverLevel } = this.state;
    const { responseMessage } = this.props.projects;
    const iconName = 'location-dot';

    const approverProblem = this.approverValidationProblem();

    return (
      <Modal isOpen={this.props.isOpen} className="xl">
        <ModalHeader className="bg-corporate text-white">
          <Icon name={iconName} className="text-white mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form style={{ fontSize: 12 }}>
            <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Project</h5>
            <Row className="bg-light m-0">
              <Col className="pb-2">
                <FormInput control={controls.name} handleChange={this.handleChange} />
              </Col>
              <Col className="pb-2">
                <FormInput control={controls.project_job_no} handleChange={this.handleChange} />
              </Col>
            </Row>
            <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Association</h5>
            <Row className="bg-light m-0">
              <Col className="pb-2">
                <FormInput control={controls.org_name} handleChange={this.handleChange} />
              </Col>
              <Col className="pb-2">
                <FormInput control={controls.assignedto_id} handleChange={this.handleChange} />
              </Col>
            </Row>
            <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Approvers</h5>
            <Row className="bg-light m-0">
              <Col sm={6} className="pb-2">
                <FormInputSelect
                  control={{ ...controls.approvers, value: newApprover?.id }}
                  handleChange={this.selectApprover}
                />
              </Col>
              <Col sm={2} className="pb-2">
                <FormInput
                  handleChange={this.selectApproverLevel}
                  control={{
                    value: newApproverLevel,
                    caption: 'Level',
                    type: 'select',
                    options: [
                      { id: 1, name: 1 },
                      { id: 2, name: 2 },
                    ],
                  }}
                />
              </Col>
              <Col sm={2} className="pt-4 d-flex justify-content-start align-self-center">
                <Button
                  className="mr-2"
                  color="success"
                  onClick={this.onCreateApprover}
                  disabled={!this.canAddApprover()}
                >
                  Add
                </Button>
              </Col>
            </Row>
            <Row className="bg-light m-0">
              <Col>
                <ProjectApproverListview
                  approvers={approvers}
                  deleteApprover={this.deleteApprover}
                />
                {approverProblem && <span className="text-warning">{approverProblem}</span>}
                {!approverProblem && (
                  <span className="text-success">Approvers configured correctly</span>
                )}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button color="success" onClick={this.onSave}>
            Save
          </Button>
          <Button color="light" onClick={this.onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, manage, projects, wipstar }) => ({
  attributes,
  manage,
  projects,
  wipstar,
});

export default connect(mapStoreToProps)(ProjectModal);
