import React, { useState } from 'react';
import { CustomInput, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { useDispatch } from 'react-redux';
import { updateListviewConfiguration } from 'jsx/components/manage/actions/listview_configuration';

const ListviewConfiguration = ({
  headers,
  columns = [],
  instanceName,
  rememberUserState = false,
}) => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onUpdateConfiguration = (updatedColumn) => {
    let newColumns = [...columns];
    const isExistingConfigOption = columns.find(({ field }) => field === updatedColumn.field);
    if (isExistingConfigOption) {
      newColumns = columns.map((curr) => {
        if (curr.field === updatedColumn.field) {
          return updatedColumn;
        }
        return curr;
      });
    } else {
      newColumns = [...columns, updatedColumn];
    }
    dispatch(updateListviewConfiguration(instanceName, { columns: newColumns }, rememberUserState));
  };

  return (
    <div className="mt-2 d-flex justify-content-end">
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className="d-flex justify-content-end min-w-200px"
      >
        <DropdownMenu right className="w-100">
          <DropdownItem header className="px-2">
            Show / Hide columns
          </DropdownItem>
          {headers?.map(({ caption, field }) => {
            // Empty captions are often used for actions and are not configurable
            if (!caption) return null;

            const matchedColumn = columns.find((column) => column.field === field);

            return (
              <div key={field} className="d-flex px-2 align-items-baseline justify-content-between">
                <label className="small" htmlFor={field}>
                  {caption}
                </label>
                <CustomInput
                  type="switch"
                  role="switch"
                  id={field}
                  name={field}
                  checked={!matchedColumn?.hidden}
                  onChange={() => {
                    onUpdateConfiguration({ field, hidden: !matchedColumn?.hidden });
                  }}
                />
              </div>
            );
          })}
        </DropdownMenu>
        <DropdownToggle
          className="btn-link border-0 bg-transparent"
          data-toggle="dropdown"
          tag="button"
        >
          <small>Column Customisation</small>
          <Icon className="ml-2" name="chevron-down" />
        </DropdownToggle>
      </Dropdown>
    </div>
  );
};

export default ListviewConfiguration;
