import React from 'react';
import Listview from "../../../../core/form/components/Listview";
import { jira_issue_link } from '../../../../../constants/jira.js';

const ProjectsTasksLsv = (props) => {
  const {
    rows,
    hideHeader,
    darkTable,
    titleKey
  } = props;

  const renderRows = (headers, task) => {
    const statusClasses = `rounded text-white p-1 m-1 bg-${task.fields.status.statusCategory.colorName}`;
    let link = '#';
    if (jira_issue_link) link = `${jira_issue_link}/${task.key}`;

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'summary': return  (
          <td key={index} className={header.classes}>{task.fields.summary}</td>
        )
        case 'status': return  (
          <td key={index} className={header.classes} >
            <small className={statusClasses} >{task.fields.status.name}</small>
          </td>
        )
        case 'link': return  (
          <td key={index} className={header.classes} >
            <a rel="noopener noreferrer" target="_blank" href={link} >Jira Issue</a>
          </td>
        )
        default: return  (
          <td key={index} className={header.classes}>{task[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const headers = [
    {caption: 'Jira Key', field: 'key', classes:'text-left'},
    {caption: 'Summary', field: 'summary', classes:'text-left'},
    {caption: 'Status', field: 'status', classes:'text-left'},
    {caption: 'Assignee', field: 'assignee', classes:'text-left'},
    {caption: 'Last Comment', field: 'comment', classes:'text-left'},
    {caption: 'Jira Link', field: 'link', classes:'text-right'}
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => {}} style={{cursor: "pointer"}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName='tasks';
  const emptyCaption='No Tasks found';
  const title = `${titleKey} - PROJECT TASKS`;

  return (
    <div className="bg-white">
      <div className="bg-corporate text-white p-1">
        <small>{title}</small>
      </div>
      <Listview darkTable={darkTable} hideHeader={hideHeader} rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </div>
  );
}

export default ProjectsTasksLsv;
