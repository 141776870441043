import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep, isEmpty, sum } from 'lodash';

import Icon from 'jsx/components/core/icons/Icon';
import {
  initListviewControls,
  updateListviewControlOptions,
} from 'jsx/components/core/form/lib/validateListview';

import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { controls as incomeControls } from '../forms/livestockIncome';
import { controls as distributionBlueprintControls } from '../forms/livestockIncomeDistributions';
import {
  createLivestockIncome,
  fetchLivestockIncome,
  removeLivestockIncome,
  updateLivestockIncome,
} from '../actions/livestocks';
import { fetchEnterpriseDistributions } from '../actions/enterprises';
import FormIntervalDatePicker from '../../../core/form/components/FormIntervalDatePicker';
import ResponseMessageTab from '../../../core/form/components/ResponseMessageTab';
import {
  allAreTrue,
  deriveDollarAmount,
  derivePercentage,
  getValidationChecks,
} from '../lib/distributions';
import LivestockIncomeDistributionsLsv from '../components/LivestockIncomeDistributionsLsv';

class LivestockIncomeModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(incomeControls),
      distributionControls: cloneDeep(distributionBlueprintControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Livestock Other Income',
    };
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { id, division_id } = this.props;
      let controls = initControls(cloneDeep(incomeControls));
      controls.distributions.value = [this.getDefaultDistribution()];

      let distributionControls = initListviewControls(
        controls.distributions.value,
        cloneDeep(distributionBlueprintControls),
      );

      let updatedState = {
        controls,
        distributionControls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Livestock Other Income',
      };

      this.props.dispatch(fetchEnterpriseDistributions({ type: 'enterprise', division_id }));

      if (id) {
        const income = await this.props.dispatch(fetchLivestockIncome({ id, division_id }));
        if (income) {
          controls = updateControls(controls, income);

          if (income?.distributions?.length > 0) {
            const { distributions } = income;

            distributionControls = [];

            // Merge existing data into blueprint controls
            distributions.forEach((distribution, index) => {
              distributionControls.push(cloneDeep(distributionBlueprintControls));

              distributionControls[index] = updateControls(
                distributionControls[index],
                distribution,
              );

              // Derive $ and % values
              const amount = deriveDollarAmount(income.value, distribution.distribution_pcnt);
              distributionControls[index].distribution_amount.value = amount;
              controls.distributions.value[index].distribution_amount = amount;

              const percentage = derivePercentage(income.value, amount);
              distributionControls[index].distribution_pcnt.value = percentage;
              controls.distributions.value[index].distribution_pcnt = percentage;
            });
          }

          updatedState = {
            ...updatedState,
            controls,
            data: income,
            distributionControls,
            id,
            isNew: false,
            title: 'Edit Livestock Other Income',
          };
        }
      }

      this.setState(updatedState);
    }
  }

  onDistributionAdd = () => {
    const { controls, distributionControls } = this.state;

    controls.distributions.value.push(this.getDefaultDistribution());
    distributionControls.push(cloneDeep(distributionBlueprintControls));
    this.setState({ controls, distributionControls });
  };

  deriveTotalValue = (distributions) => {
    if (isEmpty(distributions)) return 0;

    const values = distributions.map(({ distribution_amount }) =>
      distribution_amount ? parseFloat(distribution_amount) : 0,
    );
    return sum(values);
  };

  onDistributionChange = (event, index) => {
    const { name, value } = event.target;
    const { controls, distributionControls } = this.state;

    switch (name) {
      case 'distribution_amount': {
        // Display no values if input is empty for both amount/percent
        controls.distributions.value[index][name] = null;
        distributionControls[index][name].value = null;

        controls.distributions.value[index].distribution_pcnt = null;
        distributionControls[index].distribution_pcnt.value = null;

        if (allAreTrue(getValidationChecks(value))) {
          controls.distributions.value[index][name] = value;
          distributionControls[index][name].value = value;
        }

        const totalValue = this.deriveTotalValue(controls.distributions.value);
        controls.value.value = totalValue;
        break;
      }
      case 'enterprise_id': {
        // Update value of enterprise id
        controls.distributions.value[index][name] = value;
        distributionControls[index][name].value = value;

        // Update property_id controls to the first associated property of the selected enterprise
        const associatedProperties = this.filterProperties(value);
        controls.distributions.value[index].property_id = associatedProperties[0]?.id ?? null;
        distributionControls[index].property_id.value = associatedProperties[0]?.id ?? null;
        break;
      }
      default: {
        controls.distributions.value[index][name] = value;
        distributionControls[index][name].value = value;
        break;
      }
    }

    this.setState({ controls, distributionControls });
  };

  onDistributionRemove = (index) => {
    const { controls, distributionControls } = this.state;
    const row = controls.distributions.value[index] ?? null;
    if (row) controls.distributions.value.splice(index, 1);

    const control = distributionControls[index] ?? null;
    if (control) distributionControls.splice(index, 1);

    // Recalculate total value
    const totalValue = this.deriveTotalValue(controls.distributions.value);
    controls.value.value = totalValue;

    this.setState({ controls, distributionControls });
  };

  getDefaultDistribution = () => ({
    enterprise_id: null,
    property_id: null,
    distribution_amount: 0,
    distribution_pcnt: 0,
  });

  onSave = async () => {
    const { data, controls, isNew } = this.state;

    const updatedData = saveControls(controls, data);
    // Convert distribution_pcnt into decimal form
    updatedData.distributions = controls.distributions.value.map((distribution) => {
      const distribution_pcnt = distribution.distribution_pcnt / 100;
      return {
        ...distribution,
        distribution_pcnt,
      };
    });

    const { isValid, updatedControls } = validateFormFieldControls(updatedData, controls);

    // All form fields are valid
    if (isValid) {
      let success;
      if (isNew) {
        delete updatedData.id;
        success = await this.props.dispatch(createLivestockIncome(updatedData));
      } else {
        success = await this.props.dispatch(updateLivestockIncome(updatedData));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  };

  onClose = (refresh = false) => {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_LIVESTOCK_INCOME_ATTRIBUTES' });
    this.props.dispatch({ type: 'UNSET_ENTERPRISE_DISTRIBUTIONS' });
  };

  onRemove = async () => {
    const { data } = this.state;

    const confirmed = window.confirm('Removing other income permanently. Continue?');
    if (confirmed) {
      const success = await this.props.dispatch(removeLivestockIncome(data.id));
      if (success) this.onClose(true);
    }
  };

  filterProperties = (enterprise_id) => {
    const { enterprises } = this.props.enterprises;
    if (isEmpty(enterprises?.rows)) return [];

    // Only display properties that have been previously allocated to the enterprise
    const { allocations = [] } = enterprises.rows.find(
      (enterprise) => enterprise.id === enterprise_id,
    );

    return allocations.map((allocation) => ({ ...allocation.property }));
  };

  render() {
    let { distributionControls } = this.state;
    const { controls, title, isNew } = this.state;
    const { isOpen, division_id } = this.props;
    const { transaction_intervals } = this.props.attributes;
    const { enterprises } = this.props.enterprises;
    const { responseMessage } = this.props.livestocks;
    const iconName = 'clipboard-list';

    if (distributionControls?.length > 0) {
      const filteredEnterprises = enterprises?.rows
        ? enterprises.rows.filter((enterprise) => enterprise.division_id === division_id)
        : [];

      distributionControls = updateListviewControlOptions(
        distributionControls,
        'enterprise_id',
        filteredEnterprises,
      );

      distributionControls.forEach((control, index) => {
        const enterprise_id = control?.enterprise_id?.value ?? null;
        if (enterprise_id) {
          const filteredProperties = this.filterProperties(enterprise_id);
          distributionControls[index] = updateControlOptions(
            control,
            'property_id',
            filteredProperties,
          );
        }

        const { value: distribution_amount } = control.distribution_amount;
        const { value: distribution_pcnt } = control.distribution_pcnt;
        const { value: totalValue } = controls.value;

        // Derive distribution %
        if (
          allAreTrue(getValidationChecks(distribution_amount)) &&
          allAreTrue(getValidationChecks(totalValue))
        ) {
          const percentage = derivePercentage(totalValue, distribution_amount);
          distributionControls[index].distribution_pcnt.value = percentage;
          controls.distributions.value[index].distribution_pcnt = percentage;
        }

        // Derive distribution $
        if (distribution_pcnt > 0 && !distribution_amount) {
          if (
            allAreTrue(getValidationChecks(distribution_pcnt)) &&
            allAreTrue(getValidationChecks(totalValue))
          ) {
            const amount = deriveDollarAmount(totalValue, distribution_pcnt);
            distributionControls[index].distribution_amount.value = amount;
            controls.distributions.value[index].distribution_amount = amount;
          }
        }
      });
    }

    // Copy default option from distributionControls if distribution value is null.
    if (controls.distributions.value?.length > 0) {
      controls.distributions.value.forEach((distribution, index) => {
        Object.keys(distribution).forEach((key) => {
          if (distribution[key] === null && distributionControls[index][key].value) {
            controls.distributions.value[index][key] = distributionControls[index][key].value;
          }
        });
      });
    }

    return (
      <Modal isOpen={isOpen} className="modal-xl">
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <ResponseMessageTab responseMessage={responseMessage} />}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.category} />
            <FormIntervalDatePicker
              handleChange={this.handleChange}
              controls={controls}
              intervals={transaction_intervals}
              intervalKey="transaction_interval_id"
              dateKey="transaction_date"
            />
            <FormInput handleChange={this.handleChange} control={controls.value} />
          </Form>
          <LivestockIncomeDistributionsLsv
            controls={distributionControls}
            onAdd={this.onDistributionAdd}
            onChange={this.onDistributionChange}
            onRemove={this.onDistributionRemove}
            enterprises={enterprises?.rows || []}
            rows={controls.distributions.value || []}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, enterprises, livestocks }) => ({
  attributes,
  enterprises,
  livestocks,
});

export default connect(mapStoreToProps)(LivestockIncomeModal);
