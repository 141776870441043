import React from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'reactstrap';

import FormBase from 'jsx/components/core/form/components/FormBase';
import { cloneDeep, isEmpty } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import ReportMatrixMetricCell from './ReportMatrixMetricCell';
import { fetchReportMatrix } from '../../actions/reports';

class ReportMatrix extends FormBase {
  constructor(props) {
    super(props);

    this.baseMatrix = { org: {}, properties: [], divisions: [], enterprises: [], metrics: [] };
    this.state = {
      initialLoad: true,
      matrix: cloneDeep(this.baseMatrix),
    };
    this.colSpans = {
      enterprise: 1,
      prefixColumn: 1,
    };
    this.borderBottomOverride = 'border border-bottom border-lightgray';
    this.classNames = {
      headers: {
        org: {
          row: 'bg-light border border-lightgray',
          prefix: `${this.borderBottomOverride} text-left`,
          cell: `${this.borderBottomOverride} text-center text-nowrap`,
        },
        property: {
          row: 'bg-white border border-lightgray',
          prefix: `${this.borderBottomOverride} text-left`,
          cell: `${this.borderBottomOverride} text-center text-nowrap`,
        },
        division: {
          row: 'bg-light',
          prefix: `${this.borderBottomOverride} text-left`,
          cell: `${this.borderBottomOverride} text-center text-nowrap`,
        },
        enterprise: {
          row: 'bg-white',
          prefix: 'text-left',
          cell: 'text-center text-nowrap',
        },
      },
      metrics: {
        row: 'text-nowrap',
        prefix: '',
        cell: 'text-center',
      },
    };
    this.colours = ['green', 'orange', 'pink', 'cyan', 'yellow'];
  }

  async componentDidMount() {
    const { selectedRanges } = this.props.enterprises;
    await this.props.dispatch(fetchReportMatrix({ ...selectedRanges }));

    if (!this.props.currentReloadValue) {
      const matrix = this.buildMatrixFromLayout();
      this.setState({ matrix });
    }
  }

  componentDidUpdate() {
    const { activeTab } = this.props;
    const { initialLoad } = this.state;

    // Handle initial load
    if (initialLoad && activeTab === 'matrix') {
      const matrix = this.buildMatrixFromLayout();
      this.props.reloadMatrix('matrix', false);
      this.setState({ matrix, initialLoad: false });
    }
  }

  buildMatrixFromLayout = () => {
    const { matrix: layout } = this.props.reports;
    const matrix = cloneDeep(this.baseMatrix);

    if (isEmpty(layout.associations?.org?.properties)) return matrix;

    matrix.org = { ...layout.associations.org, col: 0, colour: 'light', row: 0 };

    layout.associations.org.properties.forEach((property, propertyIndex) => {
      if (isEmpty(property?.divisions)) return;

      let propertyColSpan = 0;
      const propertyCell = {
        col: propertyIndex,
        colour: 'lightgray',
        id: property.id,
        name: property.name,
        row: 1,
      };
      property.divisions.forEach((division, divisionIndex) => {
        if (isEmpty(division?.enterprises)) return;

        const divisionColSpan = division.enterprises.length * this.colSpans.enterprise;
        propertyColSpan += divisionColSpan;
        matrix.divisions.push({
          col: divisionIndex,
          colSpan: divisionColSpan,
          colour: 'light',
          id: division.id,
          name: division.name,
          row: 2,
          uniqueKey: `${property.id}-${division.id}`,
        });
        division.enterprises.forEach((enterprise, enterpriseIndex) => {
          matrix.enterprises.push({
            col: enterpriseIndex,
            colour: this.getColour(divisionIndex),
            colSpan: this.colSpans.enterprise,
            filters: {
              property_ids: [property.id],
              division_ids: [division.id],
              enterprise_ids: [enterprise.id],
            },
            id: enterprise.id,
            name: enterprise.name,
            row: 3,
            uniqueKey: `${property.id}-${division.id}-${enterprise.id}`,
            value: null,
          });
        });
      });

      matrix.properties.push({ ...propertyCell, colSpan: propertyColSpan });
    });

    return matrix;
  };

  // Merge metric classNames with existing classNames
  mergeMatrixAttributesClassNames = (existingClassNames, additionalClassNames = {}) => {
    if (isEmpty(additionalClassNames)) return existingClassNames;

    const { row: baseRow, prefix: basePrefix, cell: baseCell } = existingClassNames;
    const { row = '', prefix = '', cell = '' } = additionalClassNames;
    return {
      row: isEmpty(row) ? baseRow : `${baseRow} ${row}`,
      prefix: isEmpty(prefix) ? basePrefix : `${basePrefix} ${prefix}`,
      cell: isEmpty(cell) ? baseCell : `${baseCell} ${cell}`,
    };
  };

  buildTableBody = (metrics, enterprises) => (
    <tbody>
      {metrics.map(({ id, name, matrix_attributes }) => {
        const { row, prefix, cell } = this.mergeMatrixAttributesClassNames(
          this.classNames.metrics,
          matrix_attributes?.classNames,
        );
        const cells = enterprises.map(({ filters, uniqueKey }) => (
          <ReportMatrixMetricCell
            className={cell}
            key={`${id}-${uniqueKey}`}
            colSpan={this.colSpans.enterprise}
            currentReloadValue={this.props.currentReloadValue}
            filters={filters}
            metricId={id}
          />
        ));

        return (
          <tr className={row} key={`metric-row-${id}`}>
            <td className={prefix} colSpan={this.colSpans.prefixColumn}>
              {name}
            </td>
            {cells}
          </tr>
        );
      })}
    </tbody>
  );

  buildTableHeaders = (matrix, totalColSpan) => (
    <thead>
      {this.withStyledRowWrapper([{ ...matrix.org, colSpan: totalColSpan }], 'org', 'Organisation')}
      {this.withStyledRowWrapper(matrix.properties, 'property', 'Property')}
      {this.withStyledRowWrapper(matrix.divisions, 'division', 'Division')}
      <tr key={this.classNames.headers.enterprise.row}>
        <th
          className={this.classNames.headers.enterprise.prefix}
          colSpan={this.colSpans.prefixColumn}
          style={{ width: '250px' }}
        >
          Enterprise
        </th>
        {matrix.enterprises.map(({ name, uniqueKey, colSpan }) => (
          <th
            className={this.classNames.headers.enterprise.cell}
            key={`enterprise-${uniqueKey}`}
            colSpan={colSpan}
          >
            {name}
          </th>
        ))}
      </tr>
    </thead>
  );

  calculateTotalColSpan = ({ properties, divisions, enterprises }) => {
    const orgOffset = 1;
    return (
      this.increment(properties.length) +
      this.increment(divisions.length) +
      this.increment(enterprises.length) +
      orgOffset
    );
  };

  getColour = (index) =>
    index < this.colours.length ? this.colours[index] : this.colours[index % this.colours.length];

  getEmptyOptionComponent = () => (
    <div className="p-5 text-center">
      <div>
        <Icon size="3x" name="" className="text-corporate" />
      </div>
      <div className="mt-3">No Properties associated with Organisation</div>
    </div>
  );

  increment = (value) => value + 1;

  withStyledRowWrapper = (elements, key, prefixLabel) => {
    const { row, prefix, cell } = this.classNames.headers[key];

    return (
      <tr className={row} key={`${key}-row`}>
        <th className={prefix} colSpan={this.colSpans.prefixColumn} style={{ width: '250px' }}>
          {prefixLabel}
        </th>
        {elements.map(({ id, name, colSpan, uniqueKey = null }) => (
          <th key={`${key}-${uniqueKey ?? id}`} colSpan={colSpan} className={cell}>
            {name}
          </th>
        ))}
      </tr>
    );
  };

  refresh = async (resetCache = false) => {
    if (resetCache) await this.props.clearCache();

    const { selectedRanges } = this.props.enterprises;
    await this.props.dispatch(fetchReportMatrix({ ...selectedRanges }));
    this.props.updatePreviousFilters();
    this.props.refreshCurrentReloadValues();
    this.props.reloadMatrix('matrix', false);
  };

  render() {
    const { matrix } = this.state;
    const { hasFiltersChanged } = this.props;
    const { matrix: layout } = this.props.reports;

    if (isEmpty(matrix.org?.properties)) return this.getEmptyOptionComponent();

    // Calculate total colSpan for the org row
    const totalColSpan = this.calculateTotalColSpan(matrix) || 0;
    return (
      <>
        <div className="d-flex justify-content-end bg-light border border-lightgray rounded p-1 mt-1">
          <div className="align-items-center">
            <Icon
              name="info-circle"
              className={hasFiltersChanged ? 'text-warning mr-1' : 'text-primary mr-1'}
            />
            <small>
              If any data has been added or edited, click <u>Recalculate to update the metrics</u>
            </small>
          </div>
          <Button size="sm" className="mx-2" color="primary" onClick={() => this.refresh(true)}>
            Recalculate
          </Button>
          <Button
            size="sm"
            color={hasFiltersChanged ? 'danger' : 'primary'}
            disabled={!hasFiltersChanged}
            onClick={() => this.refresh()}
          >
            Apply Filters
          </Button>
        </div>
        <Table bordered striped hover className="mt-2" responsive>
          {this.buildTableHeaders(matrix, totalColSpan)}
          {this.buildTableBody(layout.metrics, matrix.enterprises)}
        </Table>
      </>
    );
  }
}

const mapStoreToProps = ({
  enterprises,
  manage,
  farmportrait_portal,
  property_usages,
  reports,
}) => ({
  enterprises,
  manage,
  portal: farmportrait_portal,
  property_usages,
  reports,
});

export default connect(mapStoreToProps)(ReportMatrix);
