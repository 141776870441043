import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  project_id: {
    ...controlDefaults,
    name: 'project_id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Project Period Name',
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  },
  start_date: {
    ...controlDefaults,
    placeholder: 'Start Date',
    name: 'start_date',
    fieldName: 'start_date',
    type: 'date',
    caption: 'Start Date',
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  },
  end_date: {
    ...controlDefaults,
    placeholder: 'End Date',
    name: 'end_date',
    fieldName: 'end_date',
    type: 'date',
    caption: 'End Date',
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  }
}