import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiJobs } from '../../../../constants/api';

export function fetchComments(job_id) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_JOB_COMMENTS`, `${apiJobs}/${job_id}/comments`);
}

export function fetchComment(job_id, id) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_JOB_COMMENT`, `${apiJobs}/${job_id}/comments/${id}`);
}

export function createComment(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_JOB_COMMENT`, `${apiJobs}/${data.job_id}/comments`, data);
}

export function updateComment(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      `UPDATE_JOB_COMMENT`,
      `${apiJobs}/${data.job_id}/comments/${data.id}`,
      data
    );
}

export function removeComment(job_id, id) {
  return async (dispatch) =>
    await remove(dispatch, `REMOVE_JOB_COMMENT`, `${apiJobs}/${job_id}/comments/${id}`);
}
