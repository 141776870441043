import React from 'react';
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Row,
  Col
} from 'reactstrap';

import {
  updateControls,
  initControls,
  saveControls
} from "../../../core/form/lib/validateForm";

import { controls } from "../forms/projectStage.js";
import FormInput from "../../../core/form/components/FormInput";
import FormBase from "../../../core/form/components/FormBase";
import Icon from 'jsx/components/core/icons/Icon';

import {
  fetchStage,
  createStage,
  updateStage,
  removeStage
} from '../actions/stages';

class ProjectStageModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isValid: true,
      isOpen: false,
      title: 'Project Stage',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls,
      group_id: null
    })

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async componentDidUpdate() {
    let {
      isOpen,
      id,
      isNew,
      title,
      controls
    } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = `New Stage`;
      this.setState({ controls: initControls(this.state.controls) })

      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = `Edit Stage`;
        const result = this.props.dispatch(fetchStage(id));
        result.then(data => {
          controls = updateControls(controls, data);
          this.setState({ controls, data })
        })
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        group_id: this.props.group_id,
        setModal: this.props.setModal,
      })
    }
  }

  async onSave() {
    let {
      data,
      controls,
      isNew,
      group_id
    } = this.state;

    data = saveControls(controls, data)

    let success;
    if (isNew) {
      delete data.id
      data.group_id = group_id;
      success = await this.props.dispatch(createStage(data));
    } else {
      success = await this.props.dispatch(updateStage(data));
    }

    if (success) {
      this.props.fetchStages()
      // this.props.dispatch(fetchStages({ group_id }));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const {
      data
    } = this.state;

    const confirmed = window.confirm(`Removing this stage and all associated requirements permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeStage(data.id));
      if (success) {
        // this.props.dispatch(fetchStages({ group_id }));
        this.props.fetchStages()
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  render() {
    const {
      controls,
      isOpen,
      title,
      isNew
    } = this.state;

    const {
      responseMessage,
      unlockTemplate
    } = this.props.stages;

    const iconName = 'layer-group';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && (
            <div className="text-center text-danger">{responseMessage}</div>
          )}
          <Form>
            <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Stage</h5>
            <Row className="bg-light m-0 pb-2">
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.name} />
              </Col>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.sequence} />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          {!isNew && unlockTemplate && (<Button size="sm" color="danger" onClick={this.onRemove} >Delete</Button>)}
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Cancel</Button>
            <Button size="sm" color="success" onClick={this.onSave}>Save</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    stages: store.stages,
  }
}

export default connect(mapStoreToProps)(ProjectStageModal);
