import React, { useState } from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import { Button } from 'reactstrap';
import { noop } from 'lodash';
import SingleSearch from './SingleSearch';

interface SearchToolbarProps {
  backgroundClasses?: string;
  handleSearchChange?: (value: string) => void;
  onReload?: () => void;
  onToggleAdd?: () => void;
  searchPlaceholder?: string;
  includeGoButton?: boolean;
  includePlusButton?: boolean;
  includeReloadButton?: boolean;
}

const SearchToolbar = ({
  backgroundClasses = 'bg-light p-2',
  handleSearchChange = noop,
  onReload = noop,
  onToggleAdd = noop,
  searchPlaceholder = 'Keyword Search',
  includeGoButton = true,
  includePlusButton = true,
  includeReloadButton = true,
}: SearchToolbarProps) => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <div className={`d-flex justify-content-end rounded ${backgroundClasses}`}>
      <SingleSearch
        inputGroupClassName="mr-0"
        placeholder={searchPlaceholder}
        handleSearchChange={({ target, keyCode }) => {
          const { value } = target;
          setSearchValue(value);
          if (keyCode === 13) handleSearchChange(value);
        }}
        onSearch={includeGoButton ? () => handleSearchChange(searchValue) : null}
        value={searchValue}
      />
      {includePlusButton && (
        <Button size="sm" color="success" className="ml-1" onClick={onToggleAdd}>
          <Icon name="plus" className="text-white" />
        </Button>
      )}
      {includeReloadButton && (
        <Button size="sm" color="primary" className="mx-1" onClick={onReload}>
          <Icon name="rotate-right" className="text-white" />
        </Button>
      )}
    </div>
  );
};

export default SearchToolbar;
