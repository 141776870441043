import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  caption: {
    ...controlDefaults,
    placeholder: 'Enter row caption...',
    name: 'caption',
    type: 'text',
    validationRules: {}
  },
}
