/* eslint-disable no-alert */
import { connect } from 'react-redux';
import { Row, Col, Button, Label } from 'reactstrap';
import { cloneDeep } from 'lodash';
import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';
import { checkUserIsAdmin, checkUserHasSomeRole } from 'jsx/lib/userAccess';
import { fetchAdminAdvisors } from 'jsx/components/manage/actions/admin';
import ReactSelect from 'react-select';

import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import { saveFormControls, updateControls } from '../../../core/form/lib/validateForm';
import { adminControls as controlsTemplate } from '../forms/property';
import Mapster from '../../projects/containers/Mapster';

import { fetchProperties, updateProperty } from '../../projects/actions/properties';
import { fetchPropertyTotalArea } from '../actions/enterprises';

const lngLat = [150.7333, -23.1333];

class PropertyProfile extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      controls: cloneDeep(controlsTemplate),
      mapSources: [],
      map: null,
      advisor: null,
      advisorOptions: [],
      isAdmin: false
    };
  }

  async componentDidMount() {
    let updatedState = {};
    // const isAdmin = checkUserIsAdmin(this.props);
    const isAdmin = checkUserHasSomeRole(this.props, ['admin', 'advisor']);
    if (isAdmin) updatedState = { ...updatedState, isAdmin };

    // Fetch Advisors
    const advisors = await this.props.dispatch(fetchAdminAdvisors());
    if (advisors?.count > 0) {
      const advisorOptions = advisors.rows.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      updatedState = { ...updatedState, advisorOptions };
    }

    this.setState({ ...updatedState });
  }

  async componentDidUpdate() {
    const { data, controls, map } = this.state;

    if (this.props.property.id !== data.id) {
      const { property } = this.props;

      // Update data with selected property
      const updatedData = cloneDeep(property);

      // Get total property area from associated enterprise allocations
      this.props.dispatch(fetchPropertyTotalArea(property.id)).then((totalAreaData) => {
        if (totalAreaData?.area_ha) updatedData.area_ha = totalAreaData.area_ha;

        // Update controls with fetched data
        const updatedControls = cloneDeep(updateControls(controls, updatedData));

        this.setState({
          data: updatedData,
          controls: updatedControls,
        });
      });
    }

    if (this.props.property?.advisor_id && !this.state.advisor) {
      const { property } = this.props;
      const { advisors } = this.props.admin;

      const targetAdvisor = advisors.rows.find(({ id }) => id === property.advisor_id);
      const advisor = { value: targetAdvisor?.id, label: targetAdvisor?.name };
      this.setState({ advisor });
    }

    // Force a map redraw
    if (map) map.resize();
  }

  onSave = async () => {
    const { data, controls, isAdmin } = this.state;
    const { current_client_org, project } = this.props.portal;

    // If user is admin, check if there are any changes to the admin configuration fields
    if (isAdmin) {
      const { advisor } = this.state;
      if (advisor) controls.advisor_id.value = advisor.value;
    }

    const formData = saveFormControls(controls, data);

    const success = await this.props.dispatch(updateProperty(formData));

    if (success) {
      const org_id = current_client_org?.id ?? project?.org_id;
      this.props.dispatch(fetchProperties({ filter_org_id: org_id }));
    }
  };

  onRemove = async () => {
    const { data } = this.state;

    window.confirm(`Removing ${data.table_name} definition permanently. Continue?`);
  };

  onAdvisorChange = (advisor) => {
    this.setState({ advisor });
  };

  render() {
    const { controls, mapSources, advisor, advisorOptions, isAdmin } = this.state;

    return (
      <Row className="h-100">
        <Col sm={5} className="m-0 p-2">
          <FormInput handleChange={this.handleChange} control={controls.name} />
          <FormInput handleChange={this.handleChange} control={controls.address} />
          <Row>
            <Col>
              <FormInput handleChange={this.handleChange} control={controls.state} />
            </Col>
            <Col>
              <FormInput handleChange={this.handleChange} control={controls.lga} />
            </Col>
            <Col>
              <FormInput handleChange={this.handleChange} control={controls.nearest_town} />
            </Col>
          </Row>
          <div className="border-bottom border-corporate mt-4 bg-light">
            <small className="text-corporate ">AREA</small>
          </div>
          <FormInput handleChange={this.handleChange} control={controls.area_ha} />
          {isAdmin && (
            <>
              <div className="border-bottom border-corporate mt-4 bg-light">
                <small className="text-corporate ">ADMIN CONFIGURATION</small>
              </div>
              <Row className="mt-2">
                <Col>
                  <Label className="font-weight-bold">Advisor</Label>
                  <ReactSelect
                    options={advisorOptions}
                    placeholder="Select Advisor"
                    onChange={this.onAdvisorChange}
                    value={advisor}
                  />
                </Col>
                <Col>
                  <FormInputSwitch control={controls.dnc} handleChange={this.handleChange} />
                </Col>
              </Row>
            </>
          )}
          <div className="d-flex justify-content-center mt-2">
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
          </div>
        </Col>
        <Col sm={7} className="border border-secondary m-0 mb-2 p-2 h-10">
          <Mapster
            expandMap={false}
            lngLatCenter={lngLat}
            mapSources={mapSources}
            onMapLoad={this.onMapLoad}
          />
        </Col>
      </Row>
    );
  }
}

const mapStoreToProps = ({ admin, auth, attributes, farmportrait_portal, manage, profile }) => ({
  admin,
  auth,
  attributes,
  manage,
  portal: farmportrait_portal,
  profile,
});

export default connect(mapStoreToProps)(PropertyProfile);
