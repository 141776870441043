import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';

import {
  Card,
  CardTitle
} from 'reactstrap';

const LandindicatorAnalyticsCell = (props) => {
  const {
    colour,
    fromText,
    toText,
    title,
    description
  } = props;

  return (
    <div className="">
      <Card className={`border border-${colour} rounded text-center`}>
        <CardTitle className={`bg-${colour} text-white m-0`}>{title}</CardTitle>
        <div className={`text-black p-1`}>
          <small>{description}</small>
          <div className={`d-flex justify-content-between p-1 text-${colour}`}>
            <div>{fromText}</div>
            <Icon size="2x" name="arrow-right" className="mr-2" />
            <div>{toText}</div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default LandindicatorAnalyticsCell;
