import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Row,
  Col
} from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from "../../core/form/components/FormInput";
import FormBase from "../../core/form/components/FormBase";
import { initControls, saveControls, updateControls } from '../../core/form/lib/validateForm';
import { controls } from '../forms/test_code';

import {
  createTestCode,
  updateTestCode,
  fetchTestCodes,
  removeTestCode
} from '../actions';
import { Fragment } from 'react';

class TestCodeModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isValid: true,
      isOpen: false,
      title: 'Organisation',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls
    })

    this.onSave = this.onSave.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen } = this.state;
    let {
      id,
      isNew,
      title,
      controls,
      data,
    } = this.state;

    const { codes } = this.props.manage;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = `New Code`;
      controls = initControls(controls);

      const type = this.props.type;
      if (type) controls.type.value = type;

      if (this.props.id) {
        isNew = false;
        id = this.props.id;
        title = `Edit Code`;

        data = codes.find(code => code.id === id);
        controls = updateControls(controls, data);
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls
      })
    }
    this.bindOptions()
  }

  bindOptions() {
    const { controls } = this.state;
    const { documentDefs, orgs } = this.props.manage;

    if (orgs.length > 0) {
      const emptyValue = '-';
      const emptyOption = [{ id: null, name: emptyValue }];
      controls['org_id'].options = emptyOption.concat(orgs);
      if (controls['org_id'].value === emptyValue) {
        controls['org_id'].value = null;
        this.setState({ controls })
      }
    }

    if (documentDefs.length > 0) {
      const emptyValue = '-';
      const emptyOption = [{ id: null, name: emptyValue }];
      controls['sample_type_id'].options = emptyOption.concat(documentDefs);
      if (controls['sample_type_id'].value === emptyValue) {
        controls['sample_type_id'].value = null;
        this.setState({ controls })
      }
    }
  }

  async onSave() {
    let { data } = this.state;
    const {
      controls,
      isNew
    } = this.state;

    data = saveControls(controls, data)
    const type = this.props.type;
    if (type) data.type = type;

    let success;
    if (isNew) {
      delete data.id
      success = await this.props.dispatch(createTestCode(data));
    } else {
      success = await this.props.dispatch(updateTestCode(data));
    }

    if (success) {
      const { type } = this.props;
      const params = {};
      if (type) params.type = type;
      this.props.dispatch(fetchTestCodes(params));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const {
      data
    } = this.state;

    const confirmed = window.confirm(`Removing ${data.name.toUpperCase()} code permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeTestCode(data.id));
      if (success) {
        const { type } = this.props;
        const params = {};
        if (type) params.type = type;
        this.props.dispatch(fetchTestCodes(params));
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  render() {
    const {
      controls,
      isOpen,
      title,
      isNew
    } = this.state;

    const {
      responseMessage
    } = this.props.manage;

    const iconName = 'laptop-code';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && (
            <div className="text-center text-danger">{responseMessage}</div>
          )}
          <Form>
            <Row>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.type} />
              </Col>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.code} />
              </Col>
            </Row>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput handleChange={this.handleChange} control={controls.description} />
            {isNew && (
              <Fragment>
                <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Link to</h5>
                <Row xs="2">
                  <Col>
                    <FormInput handleChange={this.handleChange} control={controls.org_id} />
                  </Col>
                  <Col>
                    <FormInput handleChange={this.handleChange} control={controls.sample_type_id} />
                  </Col>
                </Row>
              </Fragment>
            )}

          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          {!isNew && (<Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>Delete</Button>)}
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Cancel</Button>
            <Button size="sm" color="success" onClick={this.onSave}>Save</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    manage: store.manage
  }
}

export default connect(mapStoreToProps)(TestCodeModal);
