import React from 'react';
import { connect } from "react-redux";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Button,
    Row,
    Col
  } from 'reactstrap';
import { controls } from "../forms/activities.js";
import FormInput from "../../../core/form/components/FormInput";
import FormInputFile from "../../../core/form/components/FormInputFile";
import FormBase from "../../../core/form/components/FormBase";
import Icon from 'jsx/components/core/icons/Icon';

class ActivityModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isValid: true,
      isOpen: false,
      title: 'Message/Comment Thread',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls
    })

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async componentDidUpdate() {
    let {
      isOpen,
      id,
      isNew,
      title,
    } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      this.setState({
        isOpen: this.props.isOpen,
        id,
        // area,
        title,
        isNew,
        setModal: this.props.setModal,
      })
    }
  }

  async onSave() {
    // let {
    //   data,
    //   controls
    // } = this.state;

    // data = saveControls(controls, data)

    // let success;
    // if (isNew) {
    //   delete data.id
    //   data.area_id = area.id;
    //   success = await this.props.dispatch(createAreaRound(data));
    // } else {
    //   success = await this.props.dispatch(updateAreaRound(data));
    // }

    // if (success) {
    // //   this.props.dispatch(fetchAreas(area.property_id));
    //   this.state.setModal(false);
    //   this.setState({isOpen: false});
    // };
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({isOpen: false});
  }

  async onRemove() {
    const {
      data
    } = this.state;

    const confirmed = window.confirm(`Removing ${data.name.toUpperCase()} period permanently. Continue?`);
    if (confirmed) {
      // const success = await this.props.dispatch(removePeriod(data.id));
      // if (success) {
      //   // this.props.dispatch(fetchPeriods(data.project_id));
      //   this.state.setModal(false);
      //   this.setState({isOpen: false});
      // };
    }
  }

  render() {
    const {
      controls,
      isOpen,
      title
    } = this.state;

    const {
      responseMessage
    } = this.props.areas;

    const iconName = 'comment';

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name={iconName} className="mr-2"/>
          {title}
        </ModalHeader>
        <ModalBody>
            { responseMessage && (
            <div className="text-center text-danger">{responseMessage}</div>
            )}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.comment} />
            <Row>
              <Col><FormInput handleChange={this.handleChange} control={controls.level} /></Col>
              <Col><FormInput handleChange={this.handleChange} control={controls.notify} /></Col>
            </Row>
            <FormInputFile handleChange={this.handleChange} control={controls.files} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Cancel</Button>
            <Button size="sm" color="success" onClick={this.onSave}>Save</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    areas: store.areas,
    periods: store.periods
  }
}

export default connect(mapStoreToProps)(ActivityModal);
