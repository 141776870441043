import React from 'react';
import { connect } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import { updateReportSectionRowCol } from '../../actions/reports';
import FormInput from '../../../../core/form/components/FormInputSmall';

class ReportHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
      showEdit: false,
      controls: {
        caption: {
          name: 'caption',
          placeHolder: 'Caption',
          type: 'text',
        },
      },
    };

    this.onSave = this.onSave.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  async onSave() {
    const { value: caption } = this.state.controls.caption;
    await this.props.dispatch(updateReportSectionRowCol(this.props.cell.id, { caption }));

    this.props.functions.loadReportVersion();
    this.toggle(false);
  }

  onEdit() {
    const { controls } = this.state;
    controls.caption.value = this.props.cell.caption;
    this.toggle();
  }

  toggle() {
    this.setState({ showEdit: !this.state.showEdit });
  }

  handleChange(event) {
    const { controls } = this.state;
    const { name, value } = event.target;

    controls[name].value = value;
    this.setState({ controls });
  }

  render() {
    const { cell, reports } = this.props;
    const { showEdit, controls } = this.state;

    return (
      <div>
        {reports.reportEditable && (
          <>
            {!showEdit && (
              <div onClick={this.onEdit} style={{ cursor: 'pointer' }} className="mr-3">
                {cell.caption}
              </div>
            )}

            {showEdit && (
              <div className="d-flex justify-content-start">
                <FormInput
                  handleChange={this.handleChange}
                  control={controls.caption}
                  className="mr-2"
                />
                <Icon name="check" className="text-success mt-2" onClick={this.onSave} />
              </div>
            )}
          </>
        )}

        {!reports.reportEditable && <div className="mr-3">{cell.caption}</div>}
      </div>
    );
  }
}

const mapStoreToProps = ({ report_preview, reports }) => {
  return {
    reports,
    report_preview,
  };
};

export default connect(mapStoreToProps)(ReportHeaderCell);
