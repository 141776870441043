import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    off_farm_incomes: [],
    activeOffFarmTab: 'income',
  },
  action
) {
  // Set expected reducer types
  const types = ['FARM'];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_OFF_FARM_INCOMES_FULFILLED': {
      return {
        ...state,
        off_farm_incomes: action.payload,
      };
    }

    case 'SET_OFF_FARM_ACTIVE_TAB': {
      return {
        ...state,
        activeOffFarmTab: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
