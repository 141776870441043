import moment from 'moment';
import React from 'react';
import { connect } from "react-redux";

import FormBase from '../../../../core/form/components/FormBase';
import Listview from '../../../../core/form/components/Listview';


class PropertyRoundPanelAreaRounds extends FormBase {
  constructor(props) {
    super(props)

    this.editAreaRound = this.editAreaRound.bind(this);
    this.renderHeaders = this.renderHeaders.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  editAreaRound(round) {

    const {
      match,
      history
    } = this.props;

    const area_id = round.area.id;
    const property_id = match.params.property_id;
    const project_id = match.params.project_id;

    const lnk = `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${round.id}`;
    history.push(lnk);
  }

  renderHeaders(headers) {
    return headers.map((header, index) =>
      <th key={index} className={header.classes}>{header.caption}</th>
    );
  }

  renderRow(headers, round) {
    const tableTd = headers.map((header, index) => {
      let periodName;
      if (round.period) {
        periodName = round.period.name;
      }

      let areaRoundName = `${round.area.area_type.name}${round.area.name}-${round.name}`;

      switch (header.field) {
        case 'name': return (
          <td className={header.classes} key={index}>{areaRoundName}</td>
        )
        case 'start_date':
        case 'end_date': return (
          <td className={header.classes} key={index}>
            {(round[header.field] ? moment(round[header.field]).format('YYYY-MM-DD') : '-')}
          </td>
        )
        case 'project_period_id': return (
          <td className={header.classes} key={index}>{periodName}</td>
        )
        case 'duration_days': return (
          <td className={header.classes} key={index}>{round[header.field]} days</td>
        )
        case 'project_duration_years':
        case 'round_interval_years': return (
          <td className={header.classes} key={index}>
            {(round[header.field] ? `${round[header.field].toFixed(2)} years` : '-')}
          </td>
        )
        default: return (
          <td className={header.classes} key={index}>{round[header.field]}</td>
        )
      }
    });
    return tableTd;
  }

  render() {
    const {
      currentPropertyRound
    } = this.props.properties;

    const headers = [
      { caption: 'Area Round', field: 'name', classes: 'text-left' },
      { caption: 'Start Date', field: 'start_date', classes: 'text-center' },
      { caption: 'End Date', field: 'end_date', classes: 'text-center' },
      { caption: 'Sampling Duration', field: 'duration_days', classes: 'text-center' },
      { caption: 'Sequence', field: 'sequence', classes: 'text-center' }
    ];

    const tableHeadTh = this.renderHeaders(headers);

    let tableBodyTr = (<tr></tr>);
    let rows = currentPropertyRound?.area_rounds;
    if (rows?.length > 0) {
      tableBodyTr = rows.map((round, index) =>
        <tr key={index} onClick={() => this.editAreaRound(round)} style={{ cursor: 'pointer' }}>
          {this.renderRow(headers, round)}
        </tr>
      );
    }

    const iconName = 'layer-group';
    const emptyCaption = 'No Area rounds found for property round.';

    return (
      <React.Fragment>
        <Listview
          rows={rows}
          tableHeadTh={tableHeadTh}
          tableBodyTr={tableBodyTr}
          iconName={iconName}
          emptyCaption={emptyCaption}
        />
      </React.Fragment>
    )
  }

}


const mapStoreToProps = (store) => {
  return {
    properties: store.properties,
  }
}

export default connect(mapStoreToProps)(PropertyRoundPanelAreaRounds);