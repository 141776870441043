import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  transaction_month: {
    ...controlDefaults,
    name: 'transaction_month',
    fieldName: 'transaction_date',
    caption: 'Month',
    type: 'date-month',
    showInListview: true,
    showInEditor: false,
    sortColumn: 'created',
  },
  category: {
    ...controlDefaults,
    placeholder: '',
    type: 'text',
    name: 'category',
    fieldName: 'category',
    caption: 'Description',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_interval_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_interval_id',
    fieldName: 'transaction_interval.name',
    controlType: 'interval_date',
    linkedWith: 'transaction_date',
    type: 'select',
    caption: 'Transaction Interval',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    fieldName: 'transaction_date',
    type: 'date',
    caption: 'Transaction Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: false,
    showInListview: false,
    sortColumn: 'transaction_date',
  },
  value: {
    ...controlDefaults,
    disabled: true,
    name: 'value',
    fieldName: 'value',
    type: 'number',
    caption: 'Value',
    placeholder: '',
    validationRules: {
      isRequired: true,
      greaterThanZero: true,
    },
    formattingRules: {
      includeDecimals: true,
      includeDollarSign: true,
      includeCommas: true,
    },
    showInEditor: true,
    showInListview: true,
    totals: true,
  },
  distributions: {
    ...controlDefaults,
    name: 'distributions',
    type: 'json',
    caption: 'Distributions',
    validationRules: {
      isArray: true,
    },
    showInListView: false,
    showInEditor: true,
  },
  distribution_default: {
    ...controlDefaults,
    placeholder: '',
    name: 'Distribution',
    fieldName: '',
    type: 'number',
    caption: 'Distribution #',
    disabled: true,
    validationRules: {},
    formattingRules: {
      includeDecimals: true,
    },
    showInListview: false,
    showInEditor: false,
  },
};
