import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  method: {
    ...controlDefaults,
    name: 'method',
    type: 'select',
    placeholder: 'Select Method',
    options: [],
    validationRules: {},
  },
  baseline_id: {
    ...controlDefaults,
    name: 'baseline_id',
    type: 'select',
    placeholder: 'Select Baseline',
    options: [],
    validationRules: {},
  },
  template_id: {
    ...controlDefaults,
    name: 'template_id',
    type: 'select',
    placeholder: 'Select Template',
    options: [],
    validationRules: {},
  },
};
