import Icon from 'jsx/components/core/icons/Icon';
import { connect } from 'react-redux';
import { TabContent, TabPane } from 'reactstrap';
import { cloneDeep } from 'lodash';

import FormBase from '../../../../core/form/components/FormBase';
import ResponseMessage from '../../../../core/form/components/ResponseMessageTab';

import PipelineDocsLsv from '../../../../core/pipeline/components/PipelineDocsLsv';
import PipelinePanelFiles from '../../../../core/pipeline/containers/PipelinePanelFiles';
import PipelinePanelSubsampling from '../../../../core/pipeline/containers/PipelinePanelSubsampling';
import PipelinesPanel from '../../../../core/pipeline/containers/PipelinesPanel';
import LabRegisterPanel from '../../../lab/containers/LabRegisterPanel';
import PropertyRoundMenu from '../../components/property_rounds/PropertyRoundMenu';
import PropertyRoundProfile from '../../components/property_rounds/PropertyRoundProfile';
import PropertyRoundTabs from '../../components/property_rounds/PropertyRoundTabs';
import PropertyRoundPanelAreaRounds from './PropertyRoundPanelAreaRounds';
import PropertyRoundPanelBatches from './PropertyRoundPanelBatches';

import Downloads from '../../lib/downloads';

import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../../core/form/lib/validateForm';
import { controls as propertyRoundControls } from '../../forms/projectPropertyRound';

import {
  fetchPropertyRound,
  fetchPropertyRoundBatches,
  fetchPropertyRounds,
  generateSites,
  removePropertyRound,
  updatePropertyRound,
} from '../../actions/property_rounds';

import {
  fetchDataPreparations,
  fetchSocNirTypeScripts,
  fetchSubsampleScripts,
} from '../../../../manage/actions';
import { setLabRegisterParams } from '../../../lab/actions';

import BreadcrumbsRoute from '../../../../core/form/components/BreadcrumbsRoute';
import {
  downloadPipelineDocument,
  fetchPipeline,
  fetchPipelineDocuments,
  fetchPipelineFiles,
  fetchPipelines,
  removePipelineDocument,
} from '../../../../core/pipeline/actions';
import { DEFAULT_PAGINATION } from '../../constants';

class PropertyRound extends FormBase {
  constructor(props) {
    super(props);

    const { project_id, property_id } = this.props.match.params;
    const backlink = `/home/projects/${project_id}/properties/${property_id}`;

    this.state = {
      id: null,
      backlink,
      data: {},
      controls: cloneDeep(propertyRoundControls),
      activeTab: '',
    };

    this.downloads = new Downloads();

    this.bindOptions = this.bindOptions.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleSiteGenerate = this.handleSiteGenerate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    const { id, property_id } = this.props.match.params;

    let controls = initControls(cloneDeep(propertyRoundControls));

    const data = await this.props.dispatch(fetchPropertyRound(id));
    controls = updateControls(controls, data);

    await this.props.dispatch(fetchPropertyRounds(property_id));

    controls.subsample_type.disabled = !data.subsample_type_editable;
    this.downloads.set(this.props.dispatch);

    await this.props.dispatch(fetchSubsampleScripts());
    this.props.dispatch(fetchDataPreparations());
    this.props.dispatch(fetchSocNirTypeScripts());
    if (data?.subsample_type === 'property_round') {
      this.props.dispatch(fetchPipelines({ property_round_id: id }));
    }

    controls = this.handleSubsampleSelection(controls);

    this.setState({
      id,
      controls,
      data,
    });

    const tab_id = this.props.match.params.tab_id ?? this.props.properties.activeRoundTab;
    if (tab_id) this.toggleTab(tab_id);
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_PROPERTY_ROUND' });
    this.props.dispatch({ type: 'UNSET_RESPONSE_MESSAGES' });
    this.props.dispatch({ type: 'UNSET_LAB_REGISTER' });
    this.props.dispatch({ type: 'UNSET_LAB_REGISTER_PARAMS' });
    this.props.dispatch({ type: 'UNSET_LAB_REGISTER_SAMPLES' });
    this.props.dispatch({ type: 'UNSET_PIPELINES' });
  }

  componentDidUpdate() {
    this.bindOptions();
  }

  onChange = (event, key) => {
    const { value } = event.target;
    const { controls } = this.state;

    if (key === 'subsample_script_id') {
      controls.augments_count.disabled = false;
      const selectedSubSampleOption = (controls.subsample_script_id.options || []).find(
        ({ id }) => id === value,
      );
      if (selectedSubSampleOption?.name === 'Cross Validation, First Subsampling') {
        controls.augments_count.disabled = true;
        controls.augments_count.value = undefined;
      } else if (controls.augments_count.value === undefined) {
        // If Augment Samples is not disabled and undefined set default value to 10
        controls.augments_count.value = 10;
      }

      this.setState({ controls });
    }

    this.handleChange(event);
  };

  getActiveTabFromURL = () => {
    const {
      match: { path = '' },
      tabRoutes = [],
    } = this.props;
    const isActiveTab = tabRoutes.find((curr) => path.includes(curr));
    return isActiveTab || 'profile';
  };

  handleSubsampleSelection = (controls) => {
    const updatedControls = cloneDeep(controls);
    const { subsample_scripts = [] } = this.props.manage;
    if (!updatedControls.subsample_script_id.value) return updatedControls;

    const { value: subsample_script_id } = updatedControls.subsample_script_id;
    const selectedSubSampleOption = subsample_scripts.find(({ id }) => id === subsample_script_id);
    if (!selectedSubSampleOption) return updatedControls;

    // Disable Augment Samples unless Random validation is chosen
    updatedControls.augments_count.disabled = false;
    if (selectedSubSampleOption.tag === 'cross_val_first_subsampling') {
      updatedControls.augments_count.disabled = true;
      updatedControls.augments_count.value = undefined;
    } else if (updatedControls.augments_count.value === undefined) {
      // If Augment Samples is not disabled and undefined set default value to 10
      updatedControls.augments_count.value = 10;
    }

    return updatedControls;
  };

  bindOptions = () => {
    let { controls } = this.state;
    let isStateUpdated = false;

    [
      { reducerKey: 'data_preparations', controlKey: 'data_preparation_id' },
      { reducerKey: 'subsample_scripts', controlKey: 'subsample_script_id' },
      { reducerKey: 'soc_nir_type_scripts', controlKey: 'soc_nir_round_type' },
    ].forEach(({ reducerKey, controlKey }) => {
      const data = this.props.manage[reducerKey];
      const array = controls[controlKey].includeEmptyOption
        ? [{ id: null, name: '-' }, ...data]
        : data;

      if (array.length > 0 && controls[controlKey]?.options?.length !== array.length) {
        controls[controlKey].options = array;
        isStateUpdated = true;
      }
    });

    if (isStateUpdated) this.setState({ controls });

    // update the options for previous round
    const { currentPropertyRound, propertyRounds } = this.props.properties;
    if (currentPropertyRound) {
      const propertyRoundsExceptCurrentRound = propertyRounds.filter(
        ({ id }) => id !== currentPropertyRound?.id,
      );
      controls = updateControlOptions(
        controls,
        'previous_round_id',
        propertyRoundsExceptCurrentRound,
      );
    }
  };

  toggleTab(tab) {
    const { id } = this.state;
    const { params, filters } = this.props.lab;

    switch (parseInt(tab, 10)) {
      case 1:
        break;
      case 3:
        // subsampling
        this.props.dispatch(fetchPipeline({ property_round_id: id, key: 'subsampling' }));
        break;
      case 4:
        // lab register
        this.props.dispatch(
          setLabRegisterParams(
            {
              ...params,
              area_round_id: null,
              property_round_id: id,
              limit: DEFAULT_PAGINATION,
            },
            filters,
          ),
        );
        break;
      case 6:
        // pipeline files
        this.props.dispatch(fetchPipelineFiles({ property_round_id: id }));
        break;
      case 7:
        // pipeline documents
        this.props.dispatch(fetchPipelineDocuments({ property_round_id: id }));
        break;
      case 8:
        // batches
        this.props.dispatch(fetchPropertyRoundBatches(id));
        break;
      default:
        break;
    }

    if (this.props.properties.activeRoundTab !== tab) {
      this.props.dispatch({ type: 'SET_PROPERTY_ROUND_ACTIVE_TAB', payload: tab });
    }
  }

  async onSave() {
    const { data } = this.state;
    const { controls } = this.state;

    const saveData = saveControls(controls, data);
    const { isValid, updatedControls } = await validateFormFieldControls(saveData, controls);

    if (!isValid) {
      this.setState({ controls: updatedControls });
      return;
    }

    const success = await this.props.dispatch(updatePropertyRound(saveData));
    if (success) {
      this.onCancel();
    }
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      'Removing this round and all associated requirements permanently. Continue?',
    );
    if (confirmed) {
      const success = await this.props.dispatch(removePropertyRound(data.id));
      if (success) this.onCancel();
    }
  }

  onCancel() {
    this.props.history.push(this.state.backlink);
  }

  async handleSiteGenerate() {
    const confirmed = window.confirm(
      'WARNING: This will overwrite all previous site information and re-generate using Carbonizer. Continue?',
    );
    if (confirmed) {
      await this.props.dispatch(generateSites(this.state.id));
    }
  }

  async handleRemovePipelineDocument(document) {
    const { id } = this.state;

    const confirmed = window.confirm(
      `Removing the ${document.key.toUpperCase()} document permanently. Continue?`,
    );
    if (confirmed) {
      await this.props.dispatch(removePipelineDocument(document.id));
      this.props.dispatch(fetchPipelineDocuments({ property_round_id: id }));
    }
  }

  addScriptDescriptions = () => {
    const { controls } = this.state;
    const { subsample_scripts = [], soc_nir_type_scripts = [] } = this.props.manage;
    const subsample_script = subsample_scripts.find(
      ({ id: subsampleScriptId }) => subsampleScriptId === controls.subsample_script_id.value,
    );

    if (subsample_script) {
      controls.subsample_script_id.description = subsample_script.description;
    }

    const selectedSocNirTypeScript = soc_nir_type_scripts.find(
      ({ id: socNirTypeScriptId }) => socNirTypeScriptId === controls.soc_nir_round_type.value,
    );

    if (selectedSocNirTypeScript)
      controls.soc_nir_round_type.description = selectedSocNirTypeScript.description;

    return controls;
  };

  render() {
    const { currentPropertyRound, responseMessage, activeRoundTab } = this.props.properties;
    const { id } = this.state;
    let { controls } = this.state;
    const { pipelines, documents } = this.props.pipelines;
    const pipelineResponseMessage = this.props.pipelines.responseMessage;

    controls = this.addScriptDescriptions();

    const pipeline = {};
    const currentPipelines = pipelines.filter(
      ({ property_rounds }) =>
        property_rounds?.length > 0 && property_rounds[0].property_round_id === id,
    );
    currentPipelines.map((currentPipeline) => {
      pipeline[currentPipeline.key] = currentPipeline;
      return false;
    });

    const iconName = 'farm';
    let title = '-';
    if (currentPropertyRound?.id)
      title = `${currentPropertyRound.property.idx}-${currentPropertyRound.name}`;

    const subsample_type = currentPropertyRound?.subsample_type;
    let subtitle;
    switch (subsample_type) {
      case 'area_round':
        subtitle = 'CEA';
        break;
      case 'property_round':
        subtitle = 'Round';
        break;
      default:
        subtitle = '-';
        break;
    }

    const tabDisabled = subsample_type !== 'property_round';

    return (
      <div>
        <div className="p-3">
          <div className="d-flex flex-row justify-content-between">
            <h3>
              <Icon name={iconName} className="appForeTint mr-2" />
              {title}
            </h3>
            <PropertyRoundMenu
              checkAccess={this.checkAccess}
              allowSite={currentPropertyRound?.area_rounds?.length > 0}
              handleReportingPackDownload={(event) =>
                this.downloads.handlePropertyRoundReportingPackDownload(id, event)
              }
              handleLocationsDownload={(event) =>
                this.downloads.handlePropertyRoundLocationsPackDownload(id, event)
              }
              handleSitesDownload={(event) =>
                this.downloads.handlePropertyRoundSitesDownload(id, event)
              }
              handleSiteGenerate={() => this.handleSiteGenerate(id)}
              handleSelectionScansDownload={(event) =>
                this.downloads.handlePropertyRoundSelectionScansDownload(id, event)
              }
            />
          </div>

          <div className="p-1 rounded">
            Subsample Type: <span className="text-corporate">{subtitle}</span>
          </div>
          <BreadcrumbsRoute match={this.props.match} />
          <ResponseMessage responseMessage={responseMessage || pipelineResponseMessage} />

          <PropertyRoundTabs
            pipeline={pipeline}
            activeTab={activeRoundTab}
            toggleTab={this.toggleTab}
            tabDisabled={tabDisabled}
          />

          <TabContent activeTab={activeRoundTab} className="border-bottom border-primary">
            <TabPane tabId="1" className="p-2">
              <PropertyRoundProfile
                handleChange={this.onChange}
                controls={controls}
                onSave={this.onSave}
                onCancel={this.onCancel}
                onRemove={this.onRemove}
                checkAccess={this.checkAccess}
              />
            </TabPane>
            <TabPane tabId="2" className="p-2">
              <PropertyRoundPanelAreaRounds match={this.props.match} history={this.props.history} />
            </TabPane>
            <TabPane tabId="3" className="p-2">
              <PipelinePanelSubsampling
                pipeline={pipeline}
                executePipelineTag="roundExecuteSubsampling"
              />
            </TabPane>
            <TabPane tabId="4" className="p-2">
              <LabRegisterPanel history={this.props.history} property_round_id={id} />
            </TabPane>
            <TabPane tabId="5" className="mb-2 p-3">
              <PipelinesPanel pipeline={pipeline} />
            </TabPane>
            <TabPane tabId="6" className="mb-2 p-3">
              <PipelinePanelFiles />
            </TabPane>
            <TabPane tabId="7" className="p-2">
              <PipelineDocsLsv
                checkAccess={this.checkAccess}
                onRemove={this.handleRemovePipelineDocument}
                rows={documents || []}
                handleDownload={async (targetId, event) =>
                  this.props.dispatch(downloadPipelineDocument(targetId, event))
                }
              />
            </TabPane>
            <TabPane tabId="8" className="p-2">
              <PropertyRoundPanelBatches />
            </TabPane>
            <TabPane tabId="9" className="p-2" />
          </TabContent>
        </div>
      </div>
    );
  }
}

const mapStoreToProps = ({ properties, pipelines, lab, manage, realm }) => ({
  properties,
  pipelines,
  lab,
  manage,
  realm,
});

export default connect(mapStoreToProps)(PropertyRound);
