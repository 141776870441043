import React from 'react';
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Row,
  Col
} from 'reactstrap';

import { controls } from "../forms/propertySearchMigrate";

import FormInput from "../../../core/form/components/FormInput";
import FormBase from "../../../core/form/components/FormBase";
import Icon from 'jsx/components/core/icons/Icon';


class PropertySearchLibraryModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isValid: true,
      isOpen: false,
      title: 'Migrate Lots to Project',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls,
      project_id: null,
      isProjectModalOpen: false,
      isPropertyModalOpen: false
    })
  }

  async componentDidMount() {
  }

  async componentDidUpdate() {
    let {
      isOpen,
      id,
      title,
    } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        setModal: this.props.setModal,
      })
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  render() {
    const {
      isOpen,
      title
    } = this.state;

    const iconName = 'diagram-project';
    return (
      <React.Fragment>
        <Modal isOpen={isOpen}>
          <ModalHeader className="bg-corporate text-white" >
            <Icon size="1x" name={iconName} className="mr-2" />
            {title}
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col sm={2}>
                  <FormInput handleChange={this.handleProjectChange} control={controls.project_id} />
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Close</Button>
            <Button size="sm" className="mr-2" color="primary" onClick={() => {}}>Add Layer to Library</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    projects: store.projects
  }
}

export default connect(mapStoreToProps)(PropertySearchLibraryModal);
