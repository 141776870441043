import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  group_id: {
    ...controlDefaults,
    name: 'group_id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      minLength: 3,
      isRequired: true
    },
  },
  sequence: {
    ...controlDefaults,
    placeholder: 'Numeric value to sort stages',
    name: 'sequence',
    fieldName: 'sequence',
    type: 'text',
    caption: 'Sorting Sequence',
    validationRules: {},
  }
}
