import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  password: {
    ...controlDefaults,
    placeholder: 'Password',
    name: 'password',
    type: 'password',
    caption: 'Reset Your Password',
    validationRules: {
      minLength: 8,
      isRequired: true,
    },
  },
  password2: {
    ...controlDefaults,
    placeholder: 'Re-type Password',
    name: 'password2',
    type: 'password',
    description: 'Password must be at least 8 characters',
    validationRules: {
      minLength: 8,
      isRequired: true,
    },
  },
};
