import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    fieldName: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      minLength: 2,
      isRequired: true
    },
    showInListview: true
  },
  area_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'area_ha',
    fieldName: 'area_ha',
    type: 'text',
    caption: 'Area (ha)',
    validationRules: {
      minLength: 2,
      isRequired: true
    },
    showInListview: true
  },
  open_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'open_date',
    fieldName: 'open_date',
    type: 'date',
    caption: 'Open Date',
    validationRules: {
      isRequired: false
    },
    showInListview: true
  },
  close_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'close_date',
    fieldName: 'close_date',
    type: 'date',
    caption: 'Close Date',
    validationRules: {
      isRequired: false
    },
    showInListview: true
  }
}
