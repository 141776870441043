import React from 'react';
import PillPicker from 'jsx/components/core/form/components/PillPicker';
import PillPresenter from 'jsx/components/core/form/components/PillPresenter';
import { cloneDeep, isEmpty, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { Collapse, Card, Col, Row } from 'reactstrap';

class ReportsFilter extends React.Component {
  handleEnterprisesChange = (filters, enterpriseId) => {
    // Check if selected enterprise's associated division is already selected
    const updatedFilters = cloneDeep(filters);
    const { rows: enterprises } = this.props.storedEnterprises.enterprises;
    const { division_id } = enterprises.find(({ id }) => id === enterpriseId);
    const divisionAlreadySelected = filters.divisions.includes(division_id);

    // If division is not selected, add it to filters
    if (!divisionAlreadySelected) {
      updatedFilters.divisions = [...filters.divisions, division_id];
    }

    return updatedFilters;
  };

  handleEnterpriseTypesChange = (filters, typeId) => {
    let updatedFilters = cloneDeep(filters);

    // Find all enterprises with the same type id
    const { rows: enterprises } = this.props.storedEnterprises.enterprises;
    const enterpriseIds = enterprises
      .filter(({ type_id }) => type_id === typeId)
      .map(({ id }) => id);
    updatedFilters.enterprises = [...filters.enterprises, ...enterpriseIds];

    /**
     * Each enterprise by type will be of the same division.
     * We want to add the division to the filters if it doesn't already exist.
     * Use the first enterprise id as a template.
     */
    const [enterpriseId] = enterpriseIds;
    updatedFilters = this.handleEnterprisesChange(updatedFilters, enterpriseId);

    return updatedFilters;
  };

  handleChange = (event, category) => {
    const { value } = event.target;
    const { filters } = this.props.reports;
    const target = filters[category].find((id) => id === value);

    // Base check - If target exists, do nothing
    if (target) return;

    let updatedFilters = cloneDeep(filters);

    switch (category) {
      case 'enterprises': {
        updatedFilters = this.handleEnterprisesChange(updatedFilters, value);
        break;
      }
      case 'enterpriseTypes': {
        updatedFilters = this.handleEnterpriseTypesChange(updatedFilters, value);
        break;
      }
      case 'divisions':
      default: {
        break;
      }
    }

    updatedFilters[category] = [...filters[category], value];
    this.updateFilters(updatedFilters);
  };

  handleDivisionsDelete = (filters, enterpriseId) => {
    // Remove all enterprises associated with target division
    const updatedFilters = cloneDeep(filters);
    const { rows: enterprises } = this.props.storedEnterprises.enterprises;
    // Filter reducer stored enterprises by selected enterprise filters and target division
    const filteredEnterprises = enterprises.filter(
      ({ id, division_id }) =>
        updatedFilters.enterprises.includes(id) && division_id === enterpriseId,
    );

    if (isEmpty(filteredEnterprises)) return updatedFilters;

    const targetEnterpriseIds = filteredEnterprises.map(({ id }) => id) ?? [];
    updatedFilters.enterprises = updatedFilters.enterprises.filter(
      (id) => !targetEnterpriseIds.includes(id),
    );

    const enterpriseTypeIds = [...new Set(filteredEnterprises.map(({ type_id }) => type_id))];
    updatedFilters.enterpriseTypes = updatedFilters.enterpriseTypes.filter(
      (id) => !enterpriseTypeIds.includes(id),
    );

    return updatedFilters;
  };

  handleEnterpriseTypesDelete = (filters, typeId) => {
    // Remove associated enterprises from filters
    const updatedFilters = cloneDeep(filters);

    // Find all enterprises with the same type id
    const { rows: enterprises } = this.props.storedEnterprises.enterprises;
    const enterpriseIds = enterprises
      .filter(({ type_id }) => type_id === typeId)
      .map(({ id }) => id);

    updatedFilters.enterprises = updatedFilters.enterprises.filter(
      (id) => !enterpriseIds.includes(id),
    );

    return updatedFilters;
  };

  handleDelete = (targetId, category) => {
    const { filters } = this.props.reports;
    const target = filters[category].find((id) => id === targetId);
    // Base check - Ensure there is a target to remove
    if (!target) return;

    let updatedFilters = cloneDeep(filters);

    switch (category) {
      case 'enterpriseTypes': {
        updatedFilters = this.handleEnterpriseTypesDelete(updatedFilters, targetId);
        break;
      }
      case 'divisions': {
        updatedFilters = this.handleDivisionsDelete(updatedFilters, targetId);
        break;
      }
      case 'enterprises':
      default: {
        break;
      }
    }

    updatedFilters[category] = updatedFilters[category].filter((id) => id !== targetId);

    this.updateFilters(updatedFilters);
  };

  updateFilters = (filters = []) => {
    this.props.dispatch({ type: 'SET_REPORT_FILTERS', payload: filters });
  };

  render() {
    const { filterOpen, divisions = [], enterprises = [], mandatoryFilters } = this.props;
    const { filters } = this.props.reports;

    const divisionAttributes = {
      caption: 'Division',
      filterCount: filters.divisions.length,
      selection: filters.divisions,
    };

    const enterpriseAttributes = {
      caption: 'Enterprise',
      filterCount: filters?.enterprises?.length,
      selection: filters.enterprises,
    };

    // Filter enterprises by selected divisions
    const filteredEnterprises =
      divisionAttributes.filterCount > 0
        ? enterprises.filter(({ division_id }) =>
            divisionAttributes.selection.includes(division_id),
          )
        : enterprises;

    // Derive enterprise types from filterered enterprises
    const enterpriseTypeGroupOptions = [];

    filteredEnterprises.forEach(({ division, type }) => {
      const groupLabel = division.name;
      const groupExists = enterpriseTypeGroupOptions.findIndex(
        (option) => option?.groupLabel === groupLabel,
      );

      // Create group if it doesn't exist
      if (groupExists === -1) {
        enterpriseTypeGroupOptions.push({ groupLabel, options: [type] });
        return;
      }

      // Check if type already exists
      const typeExists = enterpriseTypeGroupOptions[groupExists].options.find(
        (option) => option.id === type.id,
      );
      if (typeExists) return;

      // Push type as option to existing group
      enterpriseTypeGroupOptions[groupExists].options.push(type);
    });

    const sortedEnterpriseTypeGroupOptions = sortBy(enterpriseTypeGroupOptions, 'groupLabel');
    const enterpriseTypePresenterRows = filteredEnterprises.map(({ type }) => type);

    const enterpriseTypeAttributes = {
      caption: 'Enterprise Type',
      filterCount: filters?.enterpriseTypes?.length,
      selection: filters?.enterpriseTypes,
    };

    return (
      <>
        <Collapse isOpen={filterOpen}>
          <Card className="border border-corporate rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col sm={3} className="border border-light rounded bg-light m-1">
                <PillPicker
                  caption={divisionAttributes.caption}
                  handleChange={(event) => this.handleChange(event, 'divisions')}
                  handleDelete={(id) => this.handleDelete(id, 'divisions')}
                  rows={divisions}
                  selection={divisionAttributes.selection}
                  showPills={false}
                  isDisabled={!isEmpty(mandatoryFilters.divisions)}
                />
              </Col>
              <Col sm={3} className="border border-light rounded bg-light m-1">
                <PillPicker
                  caption={enterpriseAttributes.caption}
                  handleChange={(event) => this.handleChange(event, 'enterprises')}
                  handleDelete={(id) => this.handleDelete(id, 'enterprises')}
                  rows={filteredEnterprises}
                  selection={enterpriseAttributes.selection}
                  showPills={false}
                  isDisabled={!isEmpty(mandatoryFilters.enterprises)}
                />
              </Col>
              <Col sm={3} className="border border-light rounded bg-light m-1">
                <PillPicker
                  caption={enterpriseTypeAttributes.caption}
                  handleChange={(event) => this.handleChange(event, 'enterpriseTypes')}
                  handleDelete={(id) => this.handleDelete(id, 'enterpriseTypes')}
                  rows={sortedEnterpriseTypeGroupOptions}
                  selection={enterpriseTypeAttributes.selection}
                  showPills={false}
                  inputSearch
                  isGrouped
                  groupLabel="Select to Filter"
                  isDisabled={!isEmpty(mandatoryFilters.enterpriseTypes)}
                />
              </Col>
            </Row>
          </Card>
        </Collapse>
        {(divisionAttributes.filterCount > 0 ||
          enterpriseAttributes.filterCount > 0 ||
          enterpriseTypeAttributes.filterCount > 0) && (
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption={divisionAttributes.caption}
              handleDelete={
                isEmpty(mandatoryFilters.divisions)
                  ? (id) => this.handleDelete(id, 'divisions')
                  : undefined
              }
              rows={divisions}
              selection={divisionAttributes.selection}
              colourField="colour"
              category="divisions"
            />
            <PillPresenter
              caption={enterpriseTypeAttributes.caption}
              handleDelete={
                isEmpty(mandatoryFilters.enterpriseTypes)
                  ? (id) => this.handleDelete(id, 'enterpriseTypes')
                  : undefined
              }
              rows={enterpriseTypePresenterRows}
              selection={enterpriseTypeAttributes.selection}
              colourField="colour"
              category="enterpriseTypes"
            />
            <PillPresenter
              caption={enterpriseAttributes.caption}
              handleDelete={
                isEmpty(mandatoryFilters.enterprises)
                  ? (id) => this.handleDelete(id, 'enterprises')
                  : undefined
              }
              rows={filteredEnterprises}
              selection={enterpriseAttributes.selection}
              colourField="colour"
              category="enterprises"
            />
          </div>
        )}
      </>
    );
  }
}

const mapStoreToProps = ({ enterprises: storedEnterprises, reports }) => ({
  storedEnterprises,
  reports,
});

export default connect(mapStoreToProps)(ReportsFilter);
