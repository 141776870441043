import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

const PipelinesMenu = (props) => {
  const {
    queueSubsampling,
    queueCarbonCalcs,
    checkAccess
  } = props;

  return (
    <React.Fragment>
      <div>
        <UncontrolledButtonDropdown>
          <DropdownToggle caret color='primary' size="sm">Action </DropdownToggle>
          <DropdownMenu right>

            <DropdownItem header className="bg-light">Workflow Actions</DropdownItem>
            <DropdownItem
              toggle={true}
              onClick={queueSubsampling}
              disabled={!checkAccess('roundExecuteSubsampling')}
            >
              Execute Sub-Sample Selection
            </DropdownItem>

            <DropdownItem
              toggle={true}
              onClick={queueCarbonCalcs}
              disabled={!checkAccess('roundExecuteCarboncalcs')}
            >
              Execute Carbon Calculations
            </DropdownItem>

          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </React.Fragment>
  );
}

export default PipelinesMenu;
