import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { cloneDeep, isEqual, isMatch } from 'lodash';
import { controls as samplingPlanControls } from '../../forms/sampling_plans';
import { validateFormFieldControls } from 'jsx/components/core/form/lib/validateForm';
import {
  createSamplingPlan,
  fetchSamplingPlan,
  removeSamplingPlan,
  updateSamplingPlan,
} from '../../actions/sampling_plan';
import PageTitle from 'jsx/components/core/form/components/PageTitle';
import FormBase from 'jsx/components/core/form/components/FormBase';
import FormInput from 'jsx/components/core/form/components/FormInput';
import SamplingPlanCellsLsv from './SamplingPlanCellsLsv';
import Icon from 'jsx/components/core/icons/Icon';
import ResponseMessageTab from 'jsx/components/core/form/components/ResponseMessageTab';
import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';

class SamplingPlanEditor extends FormBase {
  constructor(props) {
    super(props);

    this.defaultCellProps = { change: false, isEdit: false, value: '-' };
    this.defaultRow = {
      gamma: { ...this.defaultCellProps },
      spectrometer: { ...this.defaultCellProps },
      camera: { ...this.defaultCellProps },
      scale: { ...this.defaultCellProps },
    };
    this.defaultSamplingPlan = {
      name: '',
      enabled: true,
      plan: [this.defaultRow],
    };

    this.unsavedChangesPrompt = 'There are unsaved changes in the Sampling Plan.';

    this.state = {
      controls: cloneDeep(samplingPlanControls),
      id: null,
      isNew: true,
      initialSamplingPlan: cloneDeep(this.defaultSamplingPlan),
      plan: cloneDeep(this.defaultSamplingPlan.plan),
      title: 'New Sampling Plan',
    };

    this.addRow = this.addRow.bind(this);
    this.constructSamplingPlan = this.constructSamplingPlan.bind(this);
    this.focusAboveCell = this.focusAboveCell.bind(this);
    this.focusNextCell = this.focusNextCell.bind(this);
    this.focusNextColumn = this.focusNextColumn.bind(this);
    this.focusPreviousColumn = this.focusPreviousColumn.bind(this);
    this.getChanges = this.getChanges.bind(this);
    this.onCellChange = this.onCellChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.setEditMode = this.setEditMode.bind(this);
    this.setSamplingPlan = this.setSamplingPlan.bind(this);
  }

  async componentDidMount() {
    const controls = cloneDeep(samplingPlanControls);

    let updatedState = {
      controls,
    };

    if (this.props?.match?.params?.id) {
      const { id } = this.props.match.params;
      updatedState = { ...updatedState, id, isNew: false, title: 'Edit Sampling Plan' };

      this.setSamplingPlan(controls, id);
    }

    this.setState(updatedState);
  }

  addRow() {
    const updatedPlan = cloneDeep(this.state.plan);
    updatedPlan.push(cloneDeep(this.defaultRow));

    this.setState({ plan: updatedPlan });
  }

  constructSamplingPlan(controls, plan) {
    const { value: name } = controls.name;
    const { value: enabled } = controls.enabled;

    return { name, enabled, plan };
  }

  focusAboveCell(field, index) {
    const { plan } = this.state;
    const targetIndex = index - 1;

    // Set next index to above row or end row if at start.
    const nextIndex = !plan[targetIndex] ? plan.length - 1 : targetIndex;

    this.setEditMode(field, nextIndex, true);
  }

  focusNextCell(field, index) {
    const { plan } = this.state;
    const nextIndex = index + 1;

    // Check if new row is required.
    if (!plan[nextIndex]) this.addRow();

    this.setEditMode(field, nextIndex, true);
  }

  focusNextColumn(field, rowIndex, columnIndex, headers) {
    const nextColumnIndex = columnIndex + 1;

    let nextColumnField = headers[nextColumnIndex]?.field;
    const isLastColumn = !nextColumnField || nextColumnField === 'remove';

    // Reset to first column index if at end of the array.
    if (isLastColumn) nextColumnField = headers[0].field;

    const { plan } = this.state;
    const notFirstRow = rowIndex > 0;
    const isLastRow = rowIndex + 1 >= plan.length;

    let nextRowIndex = rowIndex;

    // Move to next row if at last column.
    if (isLastColumn && !isLastRow) nextRowIndex += 1;

    // Reset to first row if at last row.
    if (notFirstRow && isLastColumn && isLastRow) nextRowIndex = 0;

    this.setEditMode(nextColumnField, nextRowIndex, true);
  }

  focusPreviousColumn(field, rowIndex, columnIndex, headers) {
    const previousColumnIndex = columnIndex - 1;

    let previousColumnField = headers[previousColumnIndex]?.field;
    const isFirstColumn = !previousColumnField;

    if (isFirstColumn) previousColumnField = headers[headers.length - 2].field; // Skip remove column

    const { plan } = this.state;
    const isFirstRow = rowIndex === 0;

    let previousRowIndex = rowIndex;

    // Move to previous row if at first column
    if (isFirstColumn && !isFirstRow) previousRowIndex -= 1;

    // Cycle to last row, last column - skipping remove column
    if (isFirstColumn && isFirstRow) previousRowIndex = plan.length - 1;

    this.setEditMode(previousColumnField, previousRowIndex, true);
  }

  getChanges(blueprint, data) {
    // Return new object with only changes
    const changes = {};
    Object.entries(data).forEach(([key, values]) => {
      if (!isEqual(blueprint[key], values)) changes[key] = values;
    });

    return changes;
  }

  onCancel() {
    const { controls, initialSamplingPlan, plan } = this.state;
    const data = this.constructSamplingPlan(controls, plan);

    if (!isMatch(initialSamplingPlan, data)) {
      const confirmed = window.confirm(`${this.unsavedChangesPrompt} Do you wish to cancel?`);

      if (!confirmed) return;
    }

    this.onClose();
  }

  onClose() {
    this.props.history.goBack();
  }

  async onRemove() {
    const { id, controls } = this.state;
    const { value: name } = controls.name;

    const confirmed = window.confirm(`Removing ${name} permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeSamplingPlan(id));
      if (success) this.onClose();
    }
  }

  async onSave() {
    const { controls, id, isNew, initialSamplingPlan, plan } = this.state;
    const data = this.constructSamplingPlan(controls, plan);

    // Identify changes for existing data
    let saveData = isNew ? data : this.getChanges(initialSamplingPlan, data);
    let saveMethod = createSamplingPlan;

    if (!isNew && id) {
      saveData = { ...saveData, id };
      saveMethod = updateSamplingPlan;
    }

    const { isValid, updatedControls } = await validateFormFieldControls(saveData, controls);
    if (isValid) {
      const success = await this.props.dispatch(saveMethod(saveData));
      if (success) this.onClose();
    } else {
      this.setState({ controls: updatedControls });
    }
  }

  onCellChange(event, index) {
    const updatedPlan = cloneDeep(this.state.plan);
    const { name, value } = event.target;

    updatedPlan[index][name] = { ...updatedPlan[index][name], value };

    this.setState({ plan: updatedPlan });
  }

  removeRow(targetIndex) {
    const updatedPlan = cloneDeep(this.state.plan)
      .map((row, index) => (targetIndex !== index ? row : false))
      .filter(Boolean);

    this.setState({ plan: updatedPlan });
  }

  setEditMode(field, index, isEdit = false) {
    const updatedPlan = cloneDeep(this.state.plan);
    updatedPlan[index][field] = { ...updatedPlan[index][field], isEdit };

    this.setState({ plan: updatedPlan });
  }

  async setSamplingPlan(controls, id) {
    const result = await this.props.dispatch(fetchSamplingPlan({ id }));

    controls.name = { ...controls.name, value: result.name };
    controls.enabled = { ...controls.enabled, value: result.enabled };

    const { name, enabled, plan } = result;

    const initialSamplingPlan = {
      name,
      enabled,
      plan,
    };

    this.setState({
      controls,
      plan,
      initialSamplingPlan,
    });
  }

  render() {
    const { controls, isNew, initialSamplingPlan, plan, title } = this.state;
    const { responseMessage } = this.props.manage;
    const data = this.constructSamplingPlan(controls, plan);

    // Base check -- Check if changes exist.
    const hasUnsavedChanges = !isEqual(data, initialSamplingPlan);

    return (
      <div className="my-4">
        <Row className="d-flex justify-content-between mx-4">
          <PageTitle title={title} iconName="chart-scatter-3d" />
          <Button onClick={this.onCancel} size="sm" className="btn btn-success">
            <Icon name="arrow-left" className="mr-1" />
            Back
          </Button>
        </Row>
        <ResponseMessageTab responseMessage={responseMessage} />
        <Row xs="2" className="mt-2 mb-4 mx-4">
          <FormInput handleChange={this.handleChange} control={controls.name} />
          <FormInputSwitch
            groupClassName="ml-2"
            control={controls.enabled}
            handleChange={this.handleChange}
          />
        </Row>
        <Row xs="1" className="mx-4">
          <SamplingPlanCellsLsv
            rows={plan}
            focusAboveCell={this.focusAboveCell}
            focusNextCell={this.focusNextCell}
            focusNextColumn={this.focusNextColumn}
            focusPreviousColumn={this.focusPreviousColumn}
            onCellChange={this.onCellChange}
            removeRow={this.removeRow}
            setEditMode={this.setEditMode}
          />
        </Row>
        <Row xs="2" className="mx-4">
          <Col className="p-0" /> {/* Intentional empty column for alignment */}
          <Col className="p-0">
            <div className="d-flex justify-content-between">
              <Button size="sm" className="btn btn-success" onClick={this.addRow}>
                <Icon name="plus" className="mr-1" />
                Add Row
              </Button>
              <div>
                <Button
                  onClick={this.onSave}
                  size="sm"
                  className="btn btn-success mr-1"
                  disabled={!hasUnsavedChanges}
                >
                  Save
                </Button>
                <Button onClick={this.onCancel} size="sm" className="btn btn-secondary mr-1">
                  Cancel
                </Button>
                {!isNew && (
                  <Button onClick={this.onRemove} size="sm" className="btn btn-danger">
                    Delete
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStoreToProps = ({ manage }) => ({
  manage,
});

export default connect(mapStoreToProps)(SamplingPlanEditor);
