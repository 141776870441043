import React from 'react';
import { connect } from "react-redux";
import Icon from 'jsx/components/core/icons/Icon';

import SectionHeader from '../components/SectionHeader';
// import SectionSubsampling from '../components/SectionSubsampling';
import SectionLayers from '../components/SectionLayers';

import FormTab from "../../../core/form/components/FormTab";

import { 
  Nav,
  TabContent,
  TabPane
} from 'reactstrap';

import {
  selectChartCell
} from '../actions';

import {
  fetchProperties
} from '../../projects/actions/properties';

import {
  fetchPropertyRounds
} from '../../projects/actions/property_rounds';

import {
  fetchSoilTemplates
} from '../../../manage/actions';

class Analytics extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      editable: true,
      activeTab: '1',
      topFilter: {
        property_id: null,
        property_round_id: null,
        area_round_id: null
      },
      selectedTemplate: {},
      selectedTemplateLayer: {},
      selectionChecksum: null,
      layerCores: {},
      esmLayer: {}
    })

    this.onDeleteSelection = this.onDeleteSelection.bind(this);
    this.setTopFilter = this.setTopFilter.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.selectSoilTemplate = this.selectSoilTemplate.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchProperties({}));
    this.props.dispatch(fetchSoilTemplates());
  }

  // Select Soil template
  selectSoilTemplate(id) {
    const {
      soilTemplates
    } = this.props.manage;

    // Find the specific template
    const selectedTemplate = soilTemplates.find(soilTemplate => soilTemplate.id === id);

    this.setState({
      selectedTemplate
    })
  }


  // Setting top filter in the header and reduce the critiera of data
  setTopFilter(name, value) {
    const {
      topFilter
    } = this.props.analytics;

    // If null, dont try to set the filter
    if (!value) {
      return
    }

    switch (name) {
      case "property_id":
        topFilter.property_id = value;
        topFilter.property_round_id = null;
        topFilter.area_round_id = null;

        this.props.dispatch(fetchPropertyRounds(topFilter.property_id));
        break;
      case "property_round_id":
        topFilter.property_round_id = value;
        topFilter.area_round_id = null;
        break;
      case "area_round_id":
        topFilter.area_round_id = value;
        break;
      default: break;
    }

    this.props.dispatch({ type: 'UNSET_SELECTIONS' });
    this.props.dispatch({ type: 'SET_TOPFILTER_FULFILLED', payload: topFilter });
  }

  onDeleteSelection(selection, selectionKey) {
    this.props.dispatch(selectChartCell(selection, selectionKey))
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const {
      editable,
      selectedTemplate
    } = this.state;

    const {
      topFilter,
      selections
    } = this.props.analytics;

    const {
      soilTemplates
    } = this.props.manage;

    const {
      properties,
      propertyRounds
    } = this.props.properties;

    const emptyCaption = `Set the top-level filters above to load data for the Analytics page`;
    const iconName = 'chart-mixed';

    // Find area rounds for selected property round id
    const propertyRound = propertyRounds.find(propertyRound => propertyRound.id === topFilter.property_round_id);
    let areaRounds = [];
    if (propertyRound) {
      areaRounds = propertyRound.area_rounds;
    }

    return (
      <div className="analytics h-100" style={{backgroundColor: '#efefef'}}>
        <SectionHeader
          properties={properties}
          propertyRounds={propertyRounds}
          topFilter={topFilter}
          setTopFilter={this.setTopFilter}
        />

        {!topFilter.property_round_id && (
          <div className="p-5 text-center h-100">
            <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
            <div className="mt-3">{emptyCaption}</div>
          </div>
        )}

        {topFilter.property_round_id && (
          <div className="p-2">
            <Nav tabs className="mt-2">
              <FormTab
                caption='Layers'
                tabId='1'
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption='Lab Analysis'
                tabId='2'
                activeTab={this.state.activeTab}
                toggle={this.toggleTab}
                disabled={true}
              />
            </Nav>
            <TabContent activeTab={this.state.activeTab} className="bg-light">
              <TabPane tabId="1" className="mb-2 p-3">
                <SectionLayers
                  editable={editable}
                  soilTemplates={soilTemplates}
                  filter={topFilter}
                  selectSoilTemplate={this.selectSoilTemplate}
                  selectedTemplate={selectedTemplate}
                  setTopFilter={this.setTopFilter}
                  areaRounds={areaRounds}
                  selections={selections.sites}
                  onDeleteSelection={this.onDeleteSelection}
                />
              </TabPane>
              <TabPane tabId="2" className="mb-2 p-3">
                {/* <SectionSubsampling
                  editable={editable}
                  areaRounds={areaRounds}
                  filter={topFilter}
                  setTopFilter={this.setTopFilter}
                  selectedTemplate={selectedTemplate}
                /> */}
              </TabPane>
            </TabContent>
          </div>
        )}
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    analytics: store.analytics,
    properties: store.properties,
    areas: store.areas,
    sites: store.sites,
    manage: store.manage
  }
}

export default connect(mapStoreToProps)(Analytics);
