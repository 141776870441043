import React from 'react';
import { connect } from "react-redux";
import Icon from 'jsx/components/core/icons/Icon';

class DashboardFarmmate extends React.Component {
  render() {
    const {
      currentApp
    } = this.props.realm

    const app = (currentApp ? currentApp : {});

    return (
      <div className="p-5 text-center">
        <div><Icon size="4x" name={app.icon} className="text-corporate"/></div>
        <h1 className="mt-3">{app.title}</h1>
        <p>{app.description}</p>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(DashboardFarmmate);
