import { download, get, post, put, remove, upload } from '../../../../lib/genericAction';

import {
  apiLabRegister,
  apiStatuses,
  apiRounds,
  apiUsers
} from '../../../../constants/api.js';

export function setLabRegisterParams(params, filters) {
  return function (dispatch) {
    dispatch({ type: 'SET_LAB_REGISTER_PARAMS', payload: params });
    dispatch(fetchLabRegister(params, filters));
  }
}

export function setLabRegisterFilters(params, filters) {
  return function (dispatch) {
    dispatch({ type: 'SET_LAB_REGISTER_FILTERS', payload: filters });
    dispatch(fetchLabRegister(params, filters));
  }
}

export function fetchLabRegister(params, filters) {
  if (filters) {
    const filterKeys = Object.keys(filters);
    filterKeys.forEach(key => {
      params[`filter_${key}`] = filters[key];
    })
  }

  return async dispatch => await get(
    dispatch,
    'FETCH_LAB_REGISTER',
    `${apiLabRegister}`,
    params
  )
}

export function fetchLabRegisterRecord(id) {
  return async dispatch => await get(
    dispatch,
    'FETCH_LAB_REGISTER_RECORD',
    `${apiLabRegister}/${id}`
  )
}

export function bulkUpdateLabRegisterRecords(data) {
  return async dispatch => await put(
    dispatch,
    'UPDATE_BULK_LAB_REGISTER_RECORDS',
    `${apiLabRegister}`,
    data
  )
}

export function updateLabRegisterRecord(data) {
  return async dispatch => await put(
    dispatch,
    'UPDATE_LAB_REGISTER_RECORD',
    `${apiLabRegister}/${data.id}`,
    data
  )
}

export function removeLabRegisterRecord(id) {
  return async dispatch => await remove(
    dispatch,
    'REMOVE_LAB_REGISTER_RECORD',
    `${apiLabRegister}/${id}`
  )
}

export function createLabRegisterRecord(data) {
  return async dispatch => await post(
    dispatch,
    'CREATE_LAB_REGISTER_RECORD',
    `${apiLabRegister}`,
    data
  )
}

export function importLabRegisterSamples(id) {
  return async dispatch => await get(
    dispatch,
    'IMPORT_LAB_REGISTER_SAMPLES',
    `${apiLabRegister}/${id}/samples/import`
  )
}

export function updateLabRegisterSample(formData) {
  return async dispatch => await put(
    dispatch,
    'UPDATE_LAB_REGISTER_SAMPLE',
    `${apiLabRegister}/samples/${formData.get('id')}`,
    formData
  )
}

export function fetchLabRegisterSampleImage(params) {
  return async dispatch => await get(
    dispatch,
    'FETCH_LAB_REGISTER_SAMPLE_IMAGE',
    `${apiLabRegister}/samples/download/image`,
    params
  )
}

export function bulkUpdateLabRegisterSamples(id, formData) {
  return async dispatch => await put(
    dispatch,
    'UPDATE_BULK_LAB_REGISTER_SAMPLES',
    `${apiLabRegister}/${id}/samples/bulk`,
    formData
  )
}


export function uploadLabRegisterResults(formData, onProgressChange) {
  return async dispatch => await upload(
    dispatch,
    'UPLOAD_LAB_REGISTER_RESULTS',
    `${apiLabRegister}/${formData.get('register_id')}/samples/upload_lab`,
    formData,
    onProgressChange
  )
}

export function bulkDownloadLabRegisterCustodyRecord(formData, onProgressChange) {
  return async dispatch => await download(
    dispatch,
    'DOWNLOAD_BULK_LAB_REGISTER_CUSTODY_RECORD',
    `${apiLabRegister}/download/custody`,
    onProgressChange,
    formData
  )
}

export function downloadLabRegisterCustodyRecord(id, onProgressChange) {
  return async dispatch => await download(
    dispatch,
    'DOWNLOAD_LAB_REGISTER_CUSTODY_RECORD',
    `${apiLabRegister}/${id}/download/custody`,
    onProgressChange
  )
}

export function downloadLabRegisterLabels(id, onProgressChange) {
  return async dispatch => await download(
    dispatch,
    'DOWNLOAD_LAB_REGISTER_LABELS',
    `${apiLabRegister}/${id}/download/labels`,
    onProgressChange
  )
}

export function fetchLabStatuses() {
  return async dispatch => await get(
    dispatch,
    'FETCH_LAB_STATUSES',
    `${apiStatuses}?type=lab`
  )
}

export function fetchLabContacts() {
  return async dispatch => await get(
    dispatch,
    'FETCH_LAB_CONTACTS',
    `${apiUsers}/roles/lc`
  )
}

export function fetchLabRegisterSamplesByRound(id) {
  return async dispatch => await get(
    dispatch,
    'FETCH_LAB_REGISTER_SAMPLES',
    `${apiRounds}/${id}/lab_register/samples`
  )
}

export function fetchLabRegisterDocuments(id) {
  return async dispatch => await get(
    dispatch,
    'FETCH_LAB_REGISTER_DOCUMENTS',
    `${apiLabRegister}/${id}/documents`
  )
}

export function removeLabRegisterDocument(id) {
  return async dispatch => await remove(
    dispatch,
    'REMOVE_LAB_REGISTER_DOCUMENT',
    `${apiLabRegister}/documents/${id}`
  )
}

export function uploadLabRegisterDocument(formData, onProgressChange) {
  return async dispatch => await upload(
    dispatch,
    'UPLOAD_LAB_REGISTER_DOCUMENT',
    `${apiLabRegister}/${formData.get('register_id')}/documents/upload`,
    formData,
    onProgressChange
  )
}

export function downloadLabRegisterDocument(id, onProgressChange) {
  return async dispatch => await download(
    dispatch,
    'DOWNLOAD_LAB_REGISTER_DOCUMENT',
    `${apiLabRegister}/documents/${id}/download`
  )
}

export function fetchDashboardStatsBySamples() {
  return async dispatch => await get(
    dispatch,
    'FETCH_DASHBOARD_STATS_BYSAMPLES',
    `${apiLabRegister}/statistics/dashboard`,
    null,
    null,
    true
  )
}
