import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Icon from 'jsx/components/core/icons/Icon';
import { startCase } from 'lodash';
import { fetchRouteNames } from '../../../../actions';

class BreadcrumbsRoute extends React.Component {
  constructor(props) {
    super(props);

    this.renderCrumbs = this.renderCrumbs.bind(this);
  }

  componentDidMount() {
    const { path, url } = this.props.match;
    this.props.dispatch(fetchRouteNames({ path, url }));
  }

  renderCrumbs() {
    const { path, params, url } = this.props.match;
    const { routeNames } = this.props.realm;

    const crumbs = path.split('/');

    return crumbs
      .map((crumb, index) => {
        if (crumb.length === 0) return false;
        const pathname = url
          .split('/')
          .slice(0, index + 1)
          .join('/');

        let option;
        switch (true) {
          case crumb === 'home': {
            // Force home to be a home icon
            option = (
              <Link to={pathname}>
                <Icon name="house" className="ml-2" />
              </Link>
            );
            break;
          }
          case crumb.startsWith(':'): {
            // Derive crumb name from params or route reducer
            let value = crumb.substring(1);
            if (routeNames[value]) value = routeNames[value];
            else if (params[value]) value = params[value];

            option = (
              <Link to={pathname} className="truncate max-w-225px d-inline-block">
                {value}
              </Link>
            );
            break;
          }
          default: {
            // Plain route names, show as titlised version
            option = (
              <Link to={pathname} className="text-capitalize truncate max-w-225px d-inline-block">
                {startCase(crumb)}
              </Link>
            );
            break;
          }
        }

        return (
          <div className="d-flex align-items-center" key={`breadcrumb-${crumb}`}>
            {option}
            {crumbs.length - 1 !== index && <Icon name="chevron-right" className="ml-2 mr-2" />}
          </div>
        );
      })
      .filter(Boolean);
  }

  render() {
    const crumbs = this.renderCrumbs();
    return <div className="d-flex align-items-center mb-0 p-1">{crumbs}</div>;
  }
}

const mapStoreToProps = ({ realm }) => ({ realm });

export default connect(mapStoreToProps)(BreadcrumbsRoute);
