import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  area_round_id: {
    ...controlDefaults,
    name: 'area_round_id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Strata Name',
    validationRules: {
      minLength: 1,
      isRequired: true
    },
  },
  area_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'area_ha',
    type: 'text',
    caption: 'Area (ha)',
    validationRules: {
      minLength: 1,
      isRequired: true
    },
  }
}
