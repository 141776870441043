import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  transaction_interval_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_interval_id',
    fieldName: 'transaction_interval.name',
    type: 'select',
    caption: 'Transaction Interval',
    controlType: 'interval_date',
    linkedWith: 'transaction_date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  transaction_date: {
    ...controlDefaults,
    name: 'transaction_date',
    fieldName: 'transaction_date',
    type: 'date',
    validationRules: {
      isRequired: true,
    },
    caption: 'Transaction Date',
    placeholder: '',
    showInEditor: false,
    showInListview: false,
    sortColumn: 'transaction_date',
  },
  transaction_month: {
    ...controlDefaults,
    name: 'transaction_month',
    fieldName: 'transaction_date',
    caption: 'Transaction Date',
    type: 'date-month',
    showInListview: true,
    showInEditor: false,
    sortColumn: 'transaction_date',
  },

  type_id: {
    ...controlDefaults,
    name: 'type_id',
    fieldName: 'income_type.name',
    caption: 'Type',
    placeholder: '',
    type: 'select',
    disabled: true,
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },

  amount: {
    ...controlDefaults,
    name: 'amount',
    fieldName: 'amount',
    caption: 'Amount',
    type: 'number',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
      includeDollarSign: true,
    },
    totals: true,
    showInEditor: true,
    showInListview: true,
    listviewOrder: 2,
  },
  description: {
    ...controlDefaults,
    placeholder: '',
    name: 'description',
    type: 'textarea',
    caption: 'Description',
    textRows: 5,
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: true,
    listviewOrder: 1,
  },
};
