import React from 'react';
import { Button, Label } from 'reactstrap';

import SingleSearch from '../../../../core/form/components/SingleSearch';

const ClientsToolbar = (props) => {
  const { checkAccess, clients, searchValue, onChange, handleSearchChange, setModal } = props;

  const clientCount = `${clients.length} clients`;

  return (
    <div className="m-1 d-flex justify-content-end">
      <Label className="m-1 mr-2 text-secondary">{clientCount}</Label>
      <SingleSearch
        handleSearchChange={handleSearchChange}
        placeholder="Filter by keyword"
        value={searchValue}
      />
      <Button onClick={onChange} size="sm" color="success" className="mr-2">
        Go
      </Button>
      <Button
        onClick={() => setModal(true)}
        disabled={!checkAccess('clientCreate')}
        size="sm"
        color="success"
      >
        Add New Client
      </Button>
    </div>
  );
};

export default ClientsToolbar;
