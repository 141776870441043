import React from 'react';
import { Button, Badge } from 'reactstrap';
import { useSelector } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import SingleSearch from '../../../core/form/components/SingleSearch';
import { DaysheetComboFilter } from './DaysheetComboFilter';

export interface DaysheetActivitesToolbarProps {
  onToggleWeekbar: () => void;
  onFilter: (type: 'change' | 'delete', property: string, value: string) => void;
}

/**
 * Maps the selected ids (sourceIds) to the full list of ids with additional data (idsToMatch)
 */
const mapSelectedIdsToData = (sourceIds: Array<Record<string, any>>, idsToMatch: string[]) =>
  idsToMatch.reduce((acc: Array<Record<string, any>>, curr: string) => {
    const matched = sourceIds.find((sourceId) => sourceId.id === curr);
    if (matched) {
      acc.push(matched);
    }
    return acc;
  }, []);

const DaysheetActivitesToolbar = ({ onToggleWeekbar, onFilter }: DaysheetActivitesToolbarProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const locationRecords = useSelector(({ wipstar }: any) => wipstar.locations);
  const projectRecords = useSelector(({ projects }: any) =>
    projects.projects?.rows?.map(({ id, name, project_job_no }: any) => ({
      id,
      name: `${name} (${project_job_no})`,
    })),
  );

  let { locations: selectedLocations, projects: selectedProjects } = useSelector(
    ({ wipstar }: any) => wipstar.params,
  );

  selectedLocations = mapSelectedIdsToData(locationRecords, selectedLocations);
  selectedProjects = mapSelectedIdsToData(projectRecords, selectedProjects);

  const activeFilterCount = selectedLocations.length + selectedProjects.length;

  return (
    <div className="bg-light p-2 rounded">
      <div className="d-flex justify-content-end">
        <SingleSearch placeholder="Keyword Search" onSearch={undefined} value={undefined} />
        <Button size="sm" color="blue" onClick={() => setIsOpen(!isOpen)}>
          Filter <Badge color="light">{activeFilterCount}</Badge>
        </Button>
        <Button size="sm" color="success" className="ml-2" onClick={onToggleWeekbar} disabled>
          <Icon name="calendar" className="text-white" />
        </Button>
      </div>
      {isOpen && <DaysheetComboFilter onFilter={onFilter} />}
    </div>
  );
};

export default DaysheetActivitesToolbar;
