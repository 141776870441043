import FormInput from 'jsx/components/core/form/components/FormInput';
import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';
import { GenericModal } from 'jsx/components/core/form/components/GenericModal';
import Icon from 'jsx/components/core/icons/Icon';
import { cloneDeep, isEmpty, merge } from 'lodash';
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

class DaysheetInlineEntryModal extends GenericModal {
  onChange = (event) => {
    const { value, name: field } = event;
    const isCurrentAssetSelected = this.isAssetSelected();
    const { changes, isAssetSelected } = this.props.getChanges(
      field,
      value,
      isCurrentAssetSelected,
    );

    // Merge changes into controls
    const updatedControls = merge(cloneDeep(this.state.controls), changes);
    let updatedState = {
      controls: updatedControls,
    };
    // Determine if updating isAssetSelected
    if (isAssetSelected !== isCurrentAssetSelected) {
      updatedState = {
        ...updatedState,
        isAssetSelected,
      };
    }

    this.setState(updatedState);
  };

  isAssetSelected = () => !isEmpty(this.state.controls.asset_id.value);

  render() {
    let { controls } = this.state;
    const { isOpen, title, isNew, description } = this.state;
    const { iconName } = this.props;

    controls = this.setOptions(controls);

    const displayWorkActivityDescription = !isEmpty(controls.description.value);

    // Disable/enable asset related fields
    ['smu_end', 'smu_start', 'load_count'].forEach((target) => {
      controls[target].disabled = !this.isAssetSelected();
    });

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon size="1x" name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody>
          {description && <p>{description}</p>}
          {this.props.responseMessage && (
            <div className="text-center text-danger">{this.props.responseMessage}</div>
          )}
          <Form>
            <h5 className="m-0 ml-1 text-corporate border-bottom">Work Activity</h5>
            <Row className="bg-light m-0 pb-3">
              <Col sm={12}>
                <Row>
                  <Col sm={8}>
                    <FormInputSelect
                      handleChange={({ value, label }) =>
                        this.onChange({ value, label, name: 'job_id' })
                      }
                      control={controls.job_id}
                      isDisabled={controls.job_id?.disabled ?? false}
                    />
                    {displayWorkActivityDescription && (
                      <small className="text-corporate">
                        <span className="font-weight-bold">Work Description: </span>
                        {controls.description.value}
                      </small>
                    )}
                  </Col>
                  <Col sm={4}>
                    <FormInput handleChange={this.handleChange} control={controls.labour_hours} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormInput control={controls.notes} handleChange={this.handleChange} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Asset</h5>
            <Row className="bg-light m-0 pb-3">
              <Col sm={12}>
                <Row>
                  <Col sm={4}>
                    <FormInputSelect
                      handleChange={({ value, label }) =>
                        this.onChange({ value, label, name: 'asset_id' })
                      }
                      control={controls.asset_id}
                      isDisabled={controls.asset_id?.disabled ?? false}
                    />
                  </Col>
                  <Col sm={2}>
                    <FormInput
                      handleChange={this.handleChange}
                      control={controls.smu_start}
                      isDisabled={controls.smu_start?.disabled ?? false}
                    />
                  </Col>
                  <Col sm={2}>
                    <FormInput
                      handleChange={this.handleChange}
                      control={controls.smu_end}
                      isDisabled={controls.smu_end?.disabled ?? false}
                    />
                  </Col>
                  <Col sm={2}>
                    <FormInput
                      handleChange={this.handleChange}
                      control={controls.asset_hours}
                      isDisabled
                    />
                  </Col>
                  <Col sm={2}>
                    <FormInput
                      handleChange={this.handleChange}
                      control={controls.load_count}
                      isDisabled={controls.load_count?.disabled ?? false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && this.props.onRemove && (
            <Button
              size="sm"
              color="danger"
              onClick={this.onRemove}
              disabled={this.props.removeDisabled || false}
            >
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export default DaysheetInlineEntryModal;
