import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import { Form, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { cloneDeep, isEmpty } from 'lodash';
import FormBase from '../../../core/form/components/FormBase';
import FormInput from '../../../core/form/components/FormInput';
import {
  initControls,
  removeIsRequired,
  saveControls,
  setIsRequired,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { fetchAttribute, fetchAttributes } from '../actions/attributes';
import { controls as transactionControls } from '../forms/assetTransactions';
import {
  createAssetTransaction,
  fetchAssets,
  fetchAssetTransaction,
  fetchEnterprisesByAssetType,
  removeAssetTransaction,
  updateAssetTransaction,
} from '../actions/assets';
import FormIntervalDatePicker from '../../../core/form/components/FormIntervalDatePicker';

class AssetTransactionModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(transactionControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Asset Transaction',
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.removeByTag = this.filterOutByTag.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.showFormFields = this.showFormFields.bind(this);
  }

  componentDidMount() {
    // Get all enterprises by default.
    this.props.dispatch(fetchEnterprisesByAssetType());
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      let controls = initControls(cloneDeep(transactionControls));
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Asset Transaction',
      };

      this.props.dispatch(fetchAttributes({ type: 'transaction_types' }));
      this.props.dispatch(fetchAttributes({ type: 'asset_categories' }));
      this.props.dispatch(fetchAttributes({ type: 'asset_adjustment_types' }));

      if (this.props.id) {
        const { id } = this.props;
        updatedState = {
          id,
          isNew: false,
          title: 'Edit Asset Transaction',
        };
        this.props.dispatch(fetchAssetTransaction(id)).then((data) => {
          const { tag } = data.transaction_type;

          // Set control requirements based on transaction type
          switch (tag) {
            case 'adjustment': {
              const removeFields = ['target_enterprise_id', 'target_direct_cost_type_id'];
              removeIsRequired(controls, removeFields);

              setIsRequired(controls, ['adjustment_type_id']);
              break;
            }
            case 'transfer': {
              const { tag } = data.asset.asset_type;
              const { target_enterprise } = data;

              // Fetch associated enterprises
              this.props.dispatch(fetchEnterprisesByAssetType({ asset_type: tag }));

              // Fetch associated direct cost types
              this.props.dispatch(
                fetchAttributes({
                  type: 'cost_types',
                  asset_type: { tag, associated_division_id: target_enterprise?.division_id },
                }),
              );

              const requiredFields = ['target_enterprise_id', 'target_direct_cost_type_id'];
              setIsRequired(controls, requiredFields);

              removeIsRequired(controls, ['adjustment_type_id']);
              break;
            }
            default: {
              const optionalFields = [
                'adjustment_type_id',
                'target_enterprise_id',
                'target_direct_cost_type_id',
              ];
              removeIsRequired(controls, optionalFields);
              break;
            }
          }

          this.setState({
            data,
            controls: updateControls(controls, data),
          });
        });
      }

      this.setState(updatedState);
    }
  }

  async onSave() {
    let { data, controls, isNew } = this.state;

    let saveData = saveControls(controls, data);
    const { isValid, updatedControls } = await validateFormFieldControls(saveData, controls);

    if (isValid) {
      let success;
      if (isNew) {
        delete saveData.id;
        success = await this.props.dispatch(createAssetTransaction(saveData));
      } else {
        success = await this.props.dispatch(updateAssetTransaction(saveData));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing transaction permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeAssetTransaction(data.id));
      if (success) this.onClose(true);
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.dispatch({ type: 'UNSET_COST_TYPES_ATTRIBUTES' });
    this.props.setModal(false);
  }

  async onChange(event) {
    let { controls } = this.state;
    const { name, value } = event.target;
    const { transaction_types } = this.props.attributes;
    const { assets, enterprises } = this.props.assets;

    this.handleChange(event);

    const assetTypeTag = assets.rows.find((asset) => asset.id === controls.asset_id.value)
      ?.asset_type?.tag;

    switch (name) {
      case 'transaction_type_id': {
        let transaction_type = transaction_types.find((type) => type.id === value);

        // Handle subset of form inputs depending on the chosen transaction type
        switch (transaction_type?.tag) {
          case 'adjustment': {
            // Reset optional fields for other transaction types
            const optionalFields = ['target_enterprise_id', 'target_direct_cost_type_id'];
            removeIsRequired(controls, optionalFields);

            // Set additional required form fields
            setIsRequired(controls, ['adjustment_type_id']);

            // Reset reducer with all associated assets
            this.props.dispatch(fetchAssets());
            break;
          }
          case 'transfer': {
            // Reset optional fields for other transaction types (adjustment only for now)
            removeIsRequired(controls, ['adjustment_type_id']);

            // Set additional required form fields
            const requiredFields = ['target_enterprise_id', 'target_direct_cost_type_id'];
            setIsRequired(controls, requiredFields);

            // Load assets belonging to the Inputs category
            const inputsCategoryRow = await this.props.dispatch(
              fetchAttribute({ type: 'asset_categories', tag: 'inputs' }),
            );
            this.props.dispatch(fetchAssets({ asset_category_id: inputsCategoryRow.id }));
            break;
          }
          default: {
            // Remove required rule for adjustment/target enterprise/direct cost fields
            const optionalFields = [
              'adjustment_type_id',
              'target_enterprise_id',
              'target_direct_cost_type_id',
            ];
            removeIsRequired(controls, optionalFields);

            // Reset reducer with all associated assets
            this.props.dispatch(fetchAssets());

            break;
          }
        }
        break;
      }
      case 'asset_id': {
        // Check if current transaction type is a transfer
        const transferTransactionType = transaction_types.find(
          (type) => type.id === controls.transaction_type_id.value,
        );
        if (transferTransactionType?.tag === 'transfer') {
          if (!value || value === '-') {
            // Unset reducer enterprises/attributes and set control values to null
            this.props.dispatch({ type: 'UNSET_ASSET_ENTERPRISES' });
            this.props.dispatch({ type: 'UNSET_COST_TYPES_ATTRIBUTES' });

            controls.target_enterprise_id.value = null;
            controls.target_direct_cost_type_id.value = null;
            break;
          }

          // Update enterprises/cost types
          await this.props.dispatch(fetchEnterprisesByAssetType({ asset_type: assetTypeTag }));
        }
        break;
      }
      case 'target_enterprise_id': {
        if (!value || value === '-') {
          // Unset cost types and set control value to null
          this.props.dispatch({ type: 'UNSET_COST_TYPES_ATTRIBUTES' });
          controls.target_direct_cost_type_id.value = null;
          break;
        }

        const targetEnterprise = enterprises?.rows?.find(({ id }) => id === value);
        if (targetEnterprise?.division_id) {
          const { division_id } = targetEnterprise;
          this.props.dispatch(
            fetchAttributes({
              type: 'cost_types',
              asset_type: {
                tag: assetTypeTag,
                associated_division_id: division_id,
              },
            }),
          );
        }
        break;
      }
      default: {
        break;
      }
    }

    this.setState({ controls });
  }

  setOptions(controls) {
    const { isNew } = this.state;
    const { assets, enterprises } = this.props.assets;
    const {
      asset_categories,
      asset_adjustment_types,
      cost_types,
      transaction_intervals,
      transaction_types,
    } = this.props.attributes;

    let asset = assets.rows?.find((asset) => asset.id === controls.asset_id.value);
    let category_id = asset?.asset_type?.parent_id;
    let category = asset_categories.find((category) => category.id === category_id);
    let filtered_transaction_types = transaction_types.filter(
      ({ tag }) => tag !== 'agistment_expense' && tag !== 'agistment_income',
    );
    let filtered_adjustment_types = asset_adjustment_types;
    let filtered_cost_types = cost_types;

    if (category) {
      const { rules } = category;

      // Filter transaction types by Asset Category rules
      filtered_transaction_types = transaction_types.filter(
        (transaction_type) => !category.rules?.denyTransactionTypes?.includes(transaction_type.tag),
      );

      // Handle adjustment type options
      const categoryRules = [
        {
          rule: 'allowAppreciation',
          tag: 'appreciation',
        },
        {
          rule: 'allowDepreciation',
          tag: 'depreciation',
        },
        {
          rule: 'allowAccountBalance',
          tag: 'account_balance',
        },
        {
          rule: 'allowRevaluation',
          tag: 'revaluation',
        },
      ];

      categoryRules.forEach(({ rule, tag }) => {
        if (rules[rule] === false)
          filtered_adjustment_types = this.filterOutByTag(asset_adjustment_types, tag);
      });
    }

    // Sort selections alphabetically
    const toEnd = 1;
    assets?.rows?.sort((a, b) => (a?.name ? a.name.localeCompare(b.name) : toEnd));
    filtered_transaction_types.sort((a, b) => (a?.tag ? a.tag.localeCompare(b.tag) : toEnd));

    // Set disabled fields
    controls.asset_id.disabled = isNew ? false : true;

    // Check if current transaction type is a transfer
    const isTransferTransactionType =
      controls.transaction_type_id.value ===
      transaction_types.find((type) => type.tag === 'transfer')?.id;

    if (isTransferTransactionType) {
      // Disable direct cost field if no value populated in asset/target enterprise form fields
      controls.target_direct_cost_type_id.disabled =
        !controls.asset_id.value || !controls.target_enterprise_id.value ? true : false;

      // Disable and set daily transaction interval
      controls.transaction_interval_id.disabled = true;
      controls.transaction_interval_id.value = transaction_intervals.find(
        (interval) => interval.tag === 'day',
      )?.id;

      // Update asset id caption
      controls.asset_id.caption = 'Source Asset';

      // Filter cost types by rules if parent is Growing Costs
      if (cost_types[0]?.parent?.tag === 'growing_costs')
        filtered_cost_types = cost_types.filter((type) => type?.rules?.allowAssetTransfers);
    } else {
      controls.transaction_interval_id.disabled = false;
      controls.asset_id.caption = 'Asset';
    }

    // Load options for applicable controls
    const typeOptions = [
      { key: 'group_id', data: asset_categories },
      { key: 'asset_id', data: assets?.rows },
      { key: 'transaction_type_id', data: filtered_transaction_types },
      { key: 'adjustment_type_id', data: filtered_adjustment_types },
      { key: 'target_enterprise_id', data: enterprises.rows },
      { key: 'target_direct_cost_type_id', data: filtered_cost_types },
    ];

    typeOptions.forEach(({ key, data }) => (controls = updateControlOptions(controls, key, data)));

    return controls;
  }

  filterOutByTag(array, tag) {
    return array.filter((element) => element.tag !== tag);
  }

  showFormFields(transaction_type_id, tag) {
    const { transaction_types } = this.props.attributes;

    return transaction_type_id === transaction_types.find((type) => type.tag === tag)?.id;
  }

  render() {
    let { title, isNew, controls } = this.state;
    const { isOpen } = this.props;
    const { responseMessage } = this.props.assets;
    const { transaction_intervals } = this.props.attributes;

    controls = this.setOptions(controls);
    const isTransactionTypeChosen = isEmpty(controls.transaction_type_id.value) ? false : true;
    const isAssetChosen = isEmpty(controls.asset_id.value) ? false : true;

    const iconName = 'clipboard-list';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.onChange} control={controls.transaction_type_id} />
            {isTransactionTypeChosen && (
              <>
                <FormIntervalDatePicker
                  handleChange={this.handleChange}
                  controls={controls}
                  intervals={transaction_intervals}
                  intervalKey={'transaction_interval_id'}
                  dateKey={'transaction_date'}
                />
                <FormInput handleChange={this.onChange} control={controls.asset_id} />
                {this.showFormFields(controls.transaction_type_id.value, 'adjustment') && (
                  <FormInput handleChange={this.onChange} control={controls.adjustment_type_id} />
                )}
                {this.showFormFields(controls.transaction_type_id.value, 'transfer') &&
                  isAssetChosen && (
                    <>
                      <FormInput
                        handleChange={this.onChange}
                        control={controls.target_enterprise_id}
                      />
                      <FormInput
                        handleChange={this.onChange}
                        control={controls.target_direct_cost_type_id}
                      />
                    </>
                  )}
                <FormInput handleChange={this.handleChange} control={controls.notes} />
                <FormInput handleChange={this.handleChange} control={controls.amount} />
              </>
            )}
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ assets, attributes, properties }) => ({
  assets,
  attributes,
  properties,
});

export default connect(mapStoreToProps)(AssetTransactionModal);
