import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  breeder_enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'breeder_enterprise.name',
    name: 'breeder_enterprise_id',
    group: 'breeder',
    type: 'select',
    caption: 'Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    copyValue: {
      to: 'offspring_enterprise_id',
    },
  },
  breeder_property_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'breeder',
    fieldName: 'breeder_property.name',
    name: 'breeder_property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  breeder_animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'breeder_animal_class.name',
    name: 'breeder_animal_class_id',
    group: 'breeder',
    type: 'select',
    caption: 'Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  offspring_enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'offspring_enterprise.name',
    name: 'offspring_enterprise_id',
    group: 'offspring',
    type: 'select',
    caption: 'Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    disabled: true,
  },
  offspring_property_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'offspring',
    fieldName: 'offspring_property.name',
    name: 'offspring_property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  offspring_animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'offspring_animal_class.name',
    name: 'offspring_animal_class_id',
    group: 'offspring',
    type: 'select',
    caption: 'Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  joined_female_quantity: {
    ...controlDefaults,
    placeholder: '',
    name: 'joined_female_quantity',
    group: 'joined',
    type: 'number',
    caption: '# Joined Females',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  joined_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'joined_date',
    group: 'joined',
    type: 'date',
    caption: 'Joined Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  joined_weight: {
    ...controlDefaults,
    placeholder: '',
    name: 'joined_weight',
    unitType: 'kg',
    group: 'joined',
    type: 'number',
    caption: 'Avg Join Weight (kg)',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  joined_male_quantity: {
    ...controlDefaults,
    placeholder: '',
    name: 'joined_male_quantity',
    group: 'joined',
    type: 'number',
    caption: '# Joined Males',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  joined_male_pcnt: {
    ...controlDefaults,
    placeholder: '',
    name: 'joined_male_pcnt',
    group: 'joined',
    type: 'number',
    caption: 'Joined Males (%)',
    validationRules: {},
    formula: '##joined_male_quantity## / ##joined_female_quantity## * 100',
    showInEditor: true,
    disabled: true,
  },
  joined_condition_score: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'joined_condition_score.name',
    name: 'joined_condition_score',
    group: 'joined',
    type: 'select',
    caption: 'Condition Score',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  bought_pregnant_quantity: {
    ...controlDefaults,
    placeholder: '',
    name: 'bought_pregnant_quantity',
    group: 'joined',
    type: 'number',
    caption: '# Pregnant Bought',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  breeding_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'breeding_date',
    group: 'breeding',
    type: 'date',
    caption: 'Breeding Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    sortColumn: 'breeding_date',
  },
  breeding_period_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'breeding_period_id',
    group: 'breeding',
    type: 'select',
    caption: 'Breeding Period',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  branded_own_quantity: {
    ...controlDefaults,
    placeholder: '',
    name: 'branded_own_quantity',
    group: 'branding',
    type: 'number',
    caption: '# Branded from Own',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  branded_pregnant_quantity: {
    ...controlDefaults,
    placeholder: '',
    name: 'branded_pregnant_quantity',
    group: 'branding',
    type: 'number',
    caption: '# Branded from Pregnant Bought',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  weaned_quantity: {
    ...controlDefaults,
    placeholder: '',
    name: 'weaned_quantity',
    group: 'weaning',
    type: 'number',
    caption: '# Weaned',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  weaned_weight: {
    ...controlDefaults,
    placeholder: '',
    name: 'weaned_weight',
    unitType: 'kg',
    group: 'weaning',
    type: 'number',
    caption: 'Avg Weaning Weight (kg/head)',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  weaned_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'weaned_date',
    group: 'weaning',
    type: 'date',
    caption: 'Weaning Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
};
