import React from 'react';
import { connect } from "react-redux";

import FormBase from "../../../../core/form/components/FormBase";
import FormInput from '../../../../core/form/components/FormInput';
import { validateChange } from "../../../../core/form/lib/validateForm";
import DirectDownload from '../../../../core/form/components/DirectDownload';
import StagesLsv from "../../components/stages/StagesLsv";

import {
  fetchDocumentGroup,
  downloadGroupItems
} from '../../actions/document_groups';

import { fetchProject } from '../../actions/projects';
import {
  fetchStages,
  downloadStageItems,
  downloadStageRequirementItems,
  fetchRequirementItemCategories
} from '../../actions/stages';

class ProjectGroupStages extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      activeTab: null,
      group_id: null,
      isOpen: false,
      stages: [],
      controls: {
        categories: {
          name: 'categories',
          type: 'select'
        }
      }
    })

    this.handleChange = this.handleChange.bind(this);
    this.handleGroupDownload = this.handleGroupDownload.bind(this);
    this.fetchStages = this.fetchStages.bind(this);
  }

  async componentDidMount() {
    const group_id = this.props.group_id;
    const project_id = this.props.project_id;

    if (group_id && project_id) {
      const stages = await this.props.dispatch(fetchStages({ group_id: group_id }))
      this.props.dispatch(fetchProject(project_id));
      this.props.dispatch(fetchRequirementItemCategories())
      await this.props.dispatch(fetchDocumentGroup(group_id))

      this.setState({
        group_id,
        project_id,
        stages
      })
    }
  }

  async handleChange(event) {
    const controls = validateChange(event, this.state.controls);
    this.setState({ controls }, () => {this.fetchStages()})
  }

  async handleGroupDownload(onProgressChange) {
    let { id } = this.state;
    return await this.props.dispatch(downloadGroupItems(id, onProgressChange));
  }

  async handleStageDownload(stage_id, onProgressChange) {
    return await this.props.dispatch(downloadStageItems(stage_id, onProgressChange));
  }

  async handleRequirementDownload(requirement_id, onProgressChange) {
    return await this.props.dispatch(downloadStageRequirementItems(requirement_id, onProgressChange));
  }

  async fetchStages() {
    const { itemCategories } = this.props.stages;
    const {
      controls,
      group_id
    } = this.state;

    let filters = {}
    let idx = itemCategories.findIndex(category => category.id === controls.categories.value);
    if (idx > -1) filters.filter_category = controls.categories.value

    const stages = await this.props.dispatch(fetchStages({ group_id: group_id, ...filters }));
    this.setState({stages})
  }

  render() {
    let {
      group_id,
      project_id,
      controls,
      stages
    } = this.state;

    const {
      unlockTemplate,
      templates,
      itemCategories
    } = this.props.stages;

    if (itemCategories) {
      const emptyValue = 'Filter by Category';
      const options = [{ id: null, name: emptyValue }];
      itemCategories.map((category) => options.push({ id: category.id, name: category.name }))

      controls['categories'].options = options;
      if (controls['categories'].value === emptyValue) {
        controls['categories'].value = null;
      }
    }

    return (
      <div className={this.props.className}>
        {this.props.showFilters && (
          <div className="d-flex justify-content-start bg-light p-2 rounded mb-1">
            <div className="ml-2 mr-2">
              <FormInput handleChange={this.handleChange} control={controls.categories} />
            </div>
            <DirectDownload
              classNames="ml-2 mr-2 p-1"
              buttonType="primary"
              caption="Download Items"
              handleDownload={(onProgressChange) => this.handleGroupDownload(onProgressChange)}
              size="sm"
              showLabel={false}
              width={185}
              showIcon={true}
            />
          </div>
        )}

        <StagesLsv
          stages={stages}
          unlockTemplate={unlockTemplate}
          project_id={project_id}
          group_id={group_id}
          history={this.props.history}
          templates={templates}
          checkAccess={this.checkAccess}
          handleStageDownload={(id, onProgressChange) => this.handleStageDownload(id, onProgressChange)}
          handleRequirementDownload={(id, onProgressChange) => this.handleRequirementDownload(id, onProgressChange)}
          fetchStages={this.fetchStages}
        />
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    document_groups: store.document_groups,
    stages: store.stages,
    projects: store.projects,
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(ProjectGroupStages);
