import React from 'react';
import { connect } from "react-redux";
import {
  Button
} from 'reactstrap';

import { controlDefaults, initControls, validateChange, saveControls } from "../../../core/form/lib/validateForm";
import ActivitiesLsv from '../../../core/activity/components/ActivitiesLsv';
import LabRegisterActivitiesFilter from '../components/LabRegisterActivitiesFilter';

import { createActivity, fetchActivities } from '../../../core/activity/actions';
import LabRegisterRecordComment from '../components/LabRegisterRecordComment';

class LabRegisterRecordActivities extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      controls: {
        comment: {
          ...controlDefaults,
          placeholder: '',
          name: 'comment',
          type: 'textarea',
          textRows: 3,
          caption: 'New Comment',
          validationRules: {
            isRequired: true
          }
        }
      },
      data: {},
      activitiesRowLimit: 30
    })

    this.commentCancelRef = React.createRef(null);

    this.handleChange = this.handleChange.bind(this);
    this.onSaveComment = this.onSaveComment.bind(this);
    this.loadMoreActivities = this.loadMoreActivities.bind(this);
  }

  componentDidMount() {
    this.setState({ controls: initControls(this.state.controls) })
  }

  handleChange(event) {
    const controls = validateChange(event, this.state.controls)
    this.setState({
      controls: controls
    });
  }

  loadMoreActivities() {
    const {
      params,
      filters
    } = this.props.activities;

    let new_params = {
      ...params,
      limit: params.limit + 30
    }

    this.props.dispatch(fetchActivities(new_params, filters));
  }

  async onSaveComment() {
    let {
      data,
      controls
    } = this.state;

    const {
      filters,
      params
    } = this.props.activities;

    const { currentRegisterRecord } = this.props.lab;

    data = saveControls(controls, data)

    if (currentRegisterRecord) {
      const log = {
        associate_id: currentRegisterRecord.id,
        associate_with: 'lab_register',
        level: 'info',
        comment: data.comment
      }

      let success = await this.props.dispatch(createActivity(log));
      if (success) {
        this.props.dispatch(fetchActivities(params, filters));
             
      }
    }
  }

  render() {
    const { controls } = this.state;
    const { activities } = this.props.activities;

    return (
      <div>
        <div className="bg-light p-2 d-flex justify-content-end rounded mb-1">
          <Button id="comment_toggle" size="sm" color="success" className="text-white ml-2" >Comment</Button>
          <Button id="toggler" size="sm" color="warning" className="text-white ml-2">Filters</Button>
        </div>
        <LabRegisterRecordComment
          toggler="#comment_toggle"
          control={controls.comment}
          handleChange={this.handleChange}
          onSave={this.onSaveComment}
        />
        <LabRegisterActivitiesFilter toggler="#toggler" />
        <ActivitiesLsv rows={activities || []} pagination={true} onLoadMore={this.loadMoreActivities} />
      </div>
    )
  }
}
const mapStoreToProps = (store) => {
  return {
    activities: store.activities,
    lab: store.lab
  }
}

export default connect(mapStoreToProps)(LabRegisterRecordActivities);
