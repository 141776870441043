import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  requirement_id: {
    ...controlDefaults,
    name: 'requirement_id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      minLength: 3,
      isRequired: true
    },
  },
  notes: {
    ...controlDefaults,
    placeholder: 'Comments and Notes on progress and issues around this document...',
    name: 'notes',
    type: 'textarea',
    caption: 'Notes',
    validationRules: {},
  },
  due: {
    ...controlDefaults,
    placeholder: 'Due',
    name: 'due',
    type: 'date',
    caption: 'Due Date',
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  },
  type_key: {
    ...controlDefaults,
    name: 'type_key',
    field_name: 'type_key',
    type: 'select',
    caption: 'Item Type',
    value: 'pdf',
    options: [
      { id: 'pdf', name: 'PDF' },
      { id: 'xls', name: 'XLS/XLSX' },
      { id: 'csv', name: 'CSV' },
      { id: 'msg', name: 'Email (MSG)' },
      { id: 'png', name: 'PNG' },
      { id: 'jpg', name: 'JPG' },
      { id: 'txt', name: 'TXT' },
      { id: 'json', name: 'Map (JSON)' },
      { id: 'geotiff', name: 'Map (GeoTIFF)' }
    ],
    validationRules: {},
  },
  document: {
    ...controlDefaults,
    placeholder: 'Upload Document/File...',
    name: 'document',
    type: 'file',
    caption: 'Document/File',
    fileUploaded: true,
    validationRules: {},
  },
  status_id: {
    ...controlDefaults,
    name: 'status_id',
    fieldName: 'status_id',
    type: 'select',
    caption: 'Category'
  },
  validated: {
    ...controlDefaults,
    name: 'validated',
    type: 'date',
    caption: 'Validation Date',
    disabled: false,
  },
  validated_by: {
    ...controlDefaults,
    name: 'scanned_by',
    type: 'select',
    caption: 'Validated by',
    disabled: false,
  },
  sequence: {
    ...controlDefaults,
    placeholder: 'Numeric value to sort stages',
    name: 'sequence',
    fieldName: 'sequence',
    type: 'text',
    caption: 'Sorting Sequence',
    validationRules: {},
  }
}
