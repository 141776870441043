import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    activeTab: 'scheduler',
    stats: {},
    logs:[],
    batches: [],
    probeSummary: [
      {description: 'Total Property Probes (Non-DNC)', properties: 121, completed_probes: 45},
      {description: 'RCS Main', properties: 67, completed_probes: 45},
      {description: 'RCS Native Properties', properties: 12, completed_probes: 2}
    ]
  },
  action,
) {
  const types = ['SCHEDULE', 'WAREHOUSE', 'BATCH', 'LOG'];
  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_WAREHOUSE_STATS_FULFILLED': {
      return {
        ...state,
        stats: action.payload.stats,
      };
    }
    case 'FETCH_WAREHOUSE_BATCHES_FULFILLED': {
      return {
        ...state,
        batches: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_LOGS_FULFILLED': {
      return {
        ...state,
        logs: action.payload,
      };
    }
    case 'FETCH_SCHEDULE_JOBS_FULFILLED': {
      return {
        ...updatedState,
        matrix: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
