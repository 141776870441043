import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

import FormBase from '../../core/form/components/FormBase';
import FormInput from '../../core/form/components/FormInput';

import { updateReportSectionRowCol } from '../../modules/portrait/actions/reports';

class ReportMetricsSelectModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: {
        metric: {
          name: 'metric',
          caption: 'Metric',
          options: [],
          type: 'select',
          value: null,
          includeEmptyOption: true,
        },
      },
      metric: {},
      title: 'Metric',
    };

    this.handleMetricChange = this.handleMetricChange.bind(this);
    this.onAssign = this.onAssign.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { controls } = this.state;
      const { lookupMetrics } = this.props.reports;
      const { metric_id } = this.props;

      const options = lookupMetrics.map(({ id, name }) => ({ id, name }));

      controls.metric.options.push({ id: '', name: 'Select Metric' });
      controls.metric.options = [...options];
      controls.metric.value = metric_id;

      const metric = metric_id
        ? lookupMetrics.find(({ id }) => id === metric_id)
        : lookupMetrics[0];

      this.setState({
        controls,
        metric,
      });
    }
  }

  handleMetricChange(event) {
    const { lookupMetrics } = this.props.reports;

    const metric_id = event.target.value;
    this.handleChange(event);

    const metric = lookupMetrics.find((lookupMetric) => lookupMetric.id === metric_id);

    this.setState({ metric });
  }

  onCancel() {
    this.props.setModal(false);
  }

  async onAssign() {
    const { metric } = this.state;

    const data = { metric_id: metric.id };
    await this.props.dispatch(updateReportSectionRowCol(this.props.col_id, data));
    this.props.loadReportVersion();
    this.props.setModal(false);
  }

  render() {
    const { title, controls, metric } = this.state;
    const { isOpen } = this.props;
    const { responseMessage } = this.props.office;
    const iconName = 'gauge-circle-bolt';

    return (
      <Modal isOpen={isOpen} size="lg">
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}

          <FormInput
            handleChange={this.handleMetricChange}
            control={controls.metric}
            hideCaption={true}
          />
          {metric.id && (
            <>
              <div className="mt-2 bg-light border-bottom border-corporate">Description</div>
              <div className="p-1">{metric.description}</div>

              <div className="mt-2 bg-light border-bottom border-corporate">Formula</div>
              <div className="p-1 text-primary">{metric?.formula?.replaceAll('##', '')}</div>
            </>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={() => this.onAssign()}>
              Assign
            </Button>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ office, reports }) => {
  return {
    office,
    reports,
  };
};

export default connect(mapStoreToProps)(ReportMetricsSelectModal);
