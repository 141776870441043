export const loadWmsSource = (map, source) => {
  const url = buildWmsUrl(source);

  // Load the source
  map.addSource(source.id, {
    type: 'raster',
    tiles: [url],
    tileSize: 256,
  });

  // // Load layers and default style
  if (source.layers?.length > 0) {
    source.layers.map((layer) => {
      layer['id'] = `${source.id}-${layer.type}`;
      layer['source'] = source.id;

      map.addLayer(layer);

      return false;
    });
  }

  return map;
};

export const removeSource = (map, source) => {
  const { id } = source;
  if (!map.getSource(id)) return;

  // Remove layers before source
  if (source.layers?.length > 0) {
    source.layers.map((layer) => {
      map.removeLayer(layer.id);
      return false;
    });
  }

  // Remove markers before source
  if (source.markers?.length > 0) {
    source.markers.forEach((marker) => marker.remove());
  }

  // Remove sources
  map.removeSource(id);
};

export const loadSource = (map, source) => {
  switch (source.service.toLowerCase()) {
    case 'wms':
      loadWmsSource(map, source);
      break;
    default:
      break;
  }
};

const buildWmsUrl = (source) => {
  const { url } = source;
  const params = source.url_params;
  const epsg = 3857;
  const styles = params?.style ?? source.styles?.length > 0 ? source.styles[0] : '';

  const url_params = {
    ...params,
    request: 'GetMap',
    service: 'WMS',
    layers: source.layer ?? params?.layers,
    version: params?.version ?? '1.3.0',
    crs: `EPSG:${epsg}`,
    format: params?.format ?? 'image/png',
    styles,
    width: 256,
    height: 256,
    bbox: `{bbox-epsg-${epsg}}`,
  };

  return `${url}?${Object.entries(url_params)
    .map((param) => `${param[0]}=${param[1]}`)
    .join('&')}`;
};

export const buildGetFeatureParams = (field, value) => {
  const params = {
    service: 'WFS',
    request: 'GetFeature',
    version: '2.0.0',
    outputFormat: 'application/json',
    srsName: 'EPSG:4326',
  };
  params[field] = value;
  return params;
};

export const buildGetFeatureInfoParams = (params, coordinates) => {
  const epsg = 3857;

  const r = 6378137 * Math.PI * 2;
  const sin = Math.sin((coordinates.lat * Math.PI) / 180);
  const x = (coordinates.lng / 360) * r;
  const y = ((0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI) * r;
  // x, y = the Web Mercator 3857 coordinates of the click position

  return {
    service: 'WMS',
    version: params.version ?? '1.3.0',
    request: 'GetFeatureInfo',
    layers: params.layers,
    query_layers: params.layers,
    crs: `EPSG:${epsg}`,
    info_format: 'application/json',
    bbox: `${x - 50},${y - 50},${x + 50},${y + 50}`,
    width: params.width,
    height: params.height,
    i: 128,
    j: 128,
  };
};

export const splitUrl = (source_url) => {
  const parts = source_url.split('?');
  const [url] = parts;
  const params = {};
  if (parts.length > 1) {
    parts[1].split('&').map((part) => {
      const param = part.split('=');
      // eslint-disable-next-line prefer-destructuring
      params[param[0].toLowerCase()] = param[1];
      return false;
    });
  }

  return {
    url,
    params,
  };
};

export const joinUrl = (url, params) => {
  return `${url}?${Object.entries(params)
    .map((param) => `${param[0]}=${param[1]}`)
    .join('&')}`;
};

export const getLocalityTag = (context) => {
  const region = context?.find((row) => row.id.startsWith('region'));
  if (region?.short_code.startsWith('AU-')) return region.short_code.split('-')[1].toLowerCase();
  else return null;
};

const localStorage_selectedLots = 'selectedLots_beta';

export const getSelectedLots = () => {
  let selected = [];
  try {
    selected = JSON.parse(localStorage.getItem(localStorage_selectedLots));
    selected.map((property) => {
      if (
        !property.id ||
        !property.geometry ||
        !property.properties.lotplan ||
        Number.isNaN(property.properties.ha)
      ) {
        throw new Error();
      }
      return false;
    });
  } catch (_) {
    // Incorrectly formatted data or corrupt storage data. Needs to be cleared.
    localStorage.setItem(localStorage_selectedLots, null);
    selected = [];
  }
  return selected;
};

export const setSelectedLots = (lots) => {
  localStorage.setItem(
    localStorage_selectedLots,
    Array.isArray(lots) ? JSON.stringify(lots) : null
  );
  return Array.isArray(lots) ? lots : [];
};

export const formatSelectedLot = (lot) => {
  if ((!lot.geometry && !lot.geom) || (!lot.lotplan && !lot.properties.lotplan)) return false;

  const lotplan = lot.lotplan ?? lot.properties.lotplan;

  const formatted = {
    ...lot,
    id: lotplan,
    geometry: lot.geometry ?? lot.geom,
    type: lot.type ?? 'Feature',
    properties: {
      ...lot.properties,
      ha: lot.properties?.ha ?? lot.ha ?? 0.0,
      lotplan,
    },
  };

  // Remove trash fields
  delete formatted.geom;
  delete formatted.property_id;

  return formatted;
};

export const toggleSelectedLot = (lot) => {
  const lots = getSelectedLots();
  const idx = lots.findIndex((l) => l.id === lot.id);
  if (idx === -1) lots.push(lot);
  else lots.splice(idx, 1);
  setSelectedLots(lots);
  return lots;
};

export const addSelectedLot = (lot) => {
  const formatted = formatSelectedLot(lot);
  if (!formatted) return;

  const lots = getSelectedLots();
  lots.push(formatted);
  setSelectedLots(lots);
  return formatted;
};
