import React from 'react';
import { isArray, isEmpty, noop, omit } from 'lodash';
import { Button } from 'reactstrap';
import { Tooltip, TooltipContent, TooltipTrigger } from 'jsx/components/core/popovers/Popover';
import Icon from 'jsx/components/core/icons/Icon';
import Listview from '../../../core/form/components/Listview';
import FormInput from '../../../core/form/components/FormInput';

const OtherBusinessIncomeDistributionsLsv = ({
  controls = [],
  onAdd = noop,
  onChange = noop,
  onRemove = noop,
  enterprises = [],
  rows = [],
}) => {
  const renderEditRows = (headers, row, rowCount, index) => {
    const tableTd = [];
    if (isEmpty(headers)) return null;

    const controlKeys = Object.keys(headers);
    controlKeys.forEach((key, controlIndex) => {
      let control = headers[key];
      control = {
        ...control,
        id: `${controlIndex}-${control.fieldName}`,
        value: row[key],
      };
      control = omit(control, ['caption']);

      tableTd.push(
        <td key={controlIndex} className={control.classes}>
          <FormInput handleChange={(event) => onChange(event, index)} control={control} />
        </td>,
      );
    });

    const deleteButton = (
      <Button
        color="danger"
        onClick={() => {
          if (rowCount >= 1) onRemove(index);
        }}
      >
        <Icon name="xmark" />
      </Button>
    );

    tableTd.push(
      <td key={`delete-${row.property_id}`} className="text-center position-relative">
        {rowCount > 1 && deleteButton}
        {rowCount <= 1 && (
          <Tooltip styleOverride={{ zIndex: '1060' }}>
            <TooltipTrigger className="btn btn-danger">
              <Icon name="xmark" />
            </TooltipTrigger>
            <TooltipContent>
              <div className="bg-white p-2 rounded border drop-shadow-sm">
                You must have at least one distribution
              </div>
            </TooltipContent>
          </Tooltip>
        )}
      </td>,
    );

    return tableTd;
  };

  if ((!rows || rows.length === 0) && enterprises?.length > 0) {
    return null;
  }

  const [templateControl] = controls;
  const tableHeadTh = Object.keys(templateControl).map((key, index) => (
    <th key={index} className={templateControl[key]?.classes}>
      {templateControl[key]?.caption}
    </th>
  ));
  // Add extra column for delete button
  tableHeadTh.push(<th key="delete" className="text-center"></th>);

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    const rowCount = rows.length;
    tableBodyTr = rows.map((row, index) => (
      <tr key={index}>{renderEditRows(controls[index], row, rowCount, index)}</tr>
    ));
  }

  if (isArray(tableBodyTr)) {
    tableBodyTr.push(
      <tr key="add-distribution" className="w-100">
        <td colSpan={6}>
          <Button onClick={() => onAdd()}>
            <Icon name="plus" className="mr-2" />
            Add distribution
          </Button>
        </td>
      </tr>,
    );
  }

  return <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} />;
};

export default OtherBusinessIncomeDistributionsLsv;
