import React, { useState } from 'react';
import ImagePreviewModal from '../../../core/modals/ImagePreviewModal';
import { convertBufferToImageSource } from '../../../../lib/image';

const SiteSampleImages = (props) => {
  const { samples, selectedPosition, onSelectPosition, caption, colour } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const sampleImages = samples.filter((sample) => sample.scan_image !== null);

  const viewImage = (position) => {
    onSelectPosition(position);
    toggle();
  };

  const viewPrev = () => {
    const currentIdx = sampleImages.findIndex((sample) => sample.position === selectedPosition);
    let prevIdx = currentIdx - 1;
    if (prevIdx < 0) {
      prevIdx = sampleImages.length - 1;
    }

    onSelectPosition(sampleImages[prevIdx].position);
  };

  const viewNext = () => {
    const currentIdx = sampleImages.findIndex((sample) => sample.position === selectedPosition);
    let nextIdx = currentIdx + 1;
    if (nextIdx >= sampleImages.length) {
      nextIdx = 0;
    }

    onSelectPosition(sampleImages[nextIdx].position);
  };

  const getImg = (index, scan_image) => {
    const imgSrc = convertBufferToImageSource(scan_image.data);
    return <img alt="Sample scan" key={index} src={imgSrc} style={{ width: '100%' }} />;
  };

  let images = sampleImages.map(({ position, scan_image }, index) => {
    let className = 'border border-light';
    if (selectedPosition === position) {
      className = 'border border-primary';
    }
    return (
      <td
        key={index}
        className={className}
        onClick={() => viewImage(position)}
        style={{ cursor: 'pointer' }}
      >
        <div className="w-100 text-center bg-warning text-white" style={{ fontSize: '10px' }}>
          {position}
        </div>
        {getImg(index, scan_image)}
      </td>
    );
  });

  if (sampleImages.length === 0) {
    images = <td className="text-center text-danger mt-2">No Scan Images found</td>;
  }

  const selectedSample = samples.find((sample) => sample.position === selectedPosition);
  let imgSrc = null;
  if (selectedSample && selectedSample.scan_image) {
    imgSrc = getImg(1, selectedSample.scan_image);
  }

  const title = `Image for Position ${selectedPosition}`;
  const coreTitle = `Core Images for core_id: ${caption} - (Click to enlarge)`;

  let style = {};
  if (colour) {
    style = { color: colour };
  }

  return (
    <>
      <div className="core-viewer">
        <div className="d-flex justify-content-between bg-light p-2" style={style}>
          <small className="text-secondary">Top of Core</small>
          <div>{coreTitle}</div>
          <small className="text-secondary">Bottom of Core</small>
        </div>
        <div className="p-2 border border-light">
          <table>
            <tbody>
              <tr>{images}</tr>
            </tbody>
          </table>
        </div>
      </div>
      <ImagePreviewModal
        imgSrc={imgSrc}
        title={title}
        toggle={toggle}
        viewNext={viewNext}
        viewPrev={viewPrev}
        isOpen={isOpen}
        onClickOutside={() => setIsOpen(false)}
      />
    </>
  );
};

export default SiteSampleImages;
