import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    name: 'name',
    type: 'text',
    disabled: true,
    caption: 'Asset #',
    validationRules: {
      isRequired: true,
    },
    showInListview: true,
    showInEditor: true,
    cols: {
      sm: 4,
      md: 4,
      lg: 4,
    },
    group: 'Profile',
  },
  org_id: {
    ...controlDefaults,
    name: 'org_id',
    type: 'select',
    caption: 'Owner',
    options: [],
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    group: 'Profile',
  },
  description: {
    ...controlDefaults,
    placeholder: 'Asset Description...',
    name: 'description',
    type: 'text',
    caption: 'Description',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    group: 'Profile',
    cols: {
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  type_id: {
    ...controlDefaults,
    name: 'type_id',
    type: 'select',
    caption: 'Asset Type',
    options: [],
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    group: 'Grouping/Status',
  },
  category_id: {
    ...controlDefaults,
    name: 'category_id',
    type: 'select',
    caption: 'Asset Category',
    options: [],
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    group: 'Grouping/Status',
  },
  status_id: {
    ...controlDefaults,
    name: 'status_id',
    type: 'select',
    caption: 'Status',
    options: [],
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    group: 'Grouping/Status',
  },
  service_interval: {
    ...controlDefaults,
    name: 'service_interval',
    type: 'number',
    caption: 'Unit Interval',
    options: [],
    validationRules: {},
    showInEditor: true,
    group: 'Service',
    cols: {
      sm: 3,
      md: 3,
      lg: 3,
    },
  },
  meterunit_id: {
    ...controlDefaults,
    name: 'meterunit_id',
    type: 'select',
    caption: 'Meter Units',
    options: [],
    validationRules: {},
    showInEditor: true,
    group: 'Service',
  },
  ext_ref: {
    ...controlDefaults,
    name: 'ext_ref',
    type: 'text',
    caption: 'External Reference',
    description:
      'External reference to third-party software. Only change this if you know what you are doing :)',
    validationRules: {},
    showInEditor: true,
    group: 'Third-Party Integration',
    cols: {
      sm: 8,
      md: 8,
      lg: 8,
    },
  },
};
