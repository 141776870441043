import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(state = {
  fetching: false,
  fetched: false,
  responseMessage: null,
  capital_expenditures: {},
  activeTab: '1'
}, action) {

  // Set expected reducer types
  const types = [
    'CAPITAL_EXPENDITURE',
  ]
  
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action)
  }

  switch (action.type) {

    case 'FETCH_CAPITAL_EXPENDITURES_FULFILLED': {
        return {
        ...state,
        capital_expenditures: action.payload
      }
    }
    default: 
    break;
  }

  return state;
}
