// Create RCS-1115 https://elementree.atlassian.net/browse/RCS-1115 to handle the following:
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { getLastChildValue } from 'jsx/lib/generic';
import Listview from '../../../core/form/components/Listview';
import FormInput from '../../../core/form/components/FormInput';
import {
  allAreTrue,
  deriveDollarAmount,
  derivePercentage,
  getValidationChecks,
} from '../lib/distributions';

const OverheadDistributionsLsv = (props) => {
  const { rows, onDistributionChange } = props;
  const { totalValue } = props;
  const [changed, setChanged] = useState(false);

  const handleChange = ({ target }, row) => {
    const { name, value } = target;

    // Set default values: display no values if input is empty
    row.distribution_amount = 0;
    row.distribution_pcnt = 0;

    // Validate value
    if (allAreTrue(getValidationChecks(parseFloat(value)))) {
      // Display user input
      row[name] = value;
    }
    onDistributionChange();
    setChanged(!changed);
  };

  const setEditMode = (row, value) => {
    row.editMode = value;
    setChanged(!changed);
  };

  const getFormInputDataCell = (cellAttributes, inputAttributes) => {
    const { index, classes, row, isHandlerEnabled } = cellAttributes;
    let updatedInputAttributes = { ...inputAttributes };

    if (isHandlerEnabled)
      updatedInputAttributes = {
        ...updatedInputAttributes,
        handleChange: (event) => handleChange(event, row),
      };

    return (
      <td key={`td-${index}`} className={classes}>
        <FormInput {...updatedInputAttributes} />
      </td>
    );
  };

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const { field, classes } = header;
      let caption = field.includes('.') ? getLastChildValue(field, row) : row[field];

      const cellAttributes = {
        index,
        classes,
        row,
        isHandlerEnabled: true,
      };

      let inputAttributes = {
        control: {
          id: `${index}-${field}`,
          name: field,
          value: caption,
        },
        disabled: false,
      };

      switch (field) {
        case 'type':
          return (
            <td key={`td-${index}`} className={classes}>
              {caption || ''}
            </td>
          );
        case 'distribution_pcnt':
          // Derive and update % amount
          const { distribution_amount } = row;
          if (
            allAreTrue(getValidationChecks(distribution_amount)) &&
            allAreTrue(getValidationChecks(totalValue))
          ) {
            caption = derivePercentage(totalValue, distribution_amount);
            inputAttributes = { ...inputAttributes, control: { value: caption } };
            row[field] = caption;
          }

          return getFormInputDataCell(
            { ...cellAttributes, isHandlerEnabled: false },
            { ...inputAttributes, disabled: true },
          );
        case 'distribution_amount':
          if (!row[field]) {
            // Derive and update $ amount
            const { distribution_pcnt } = row;
            if (
              allAreTrue(getValidationChecks(distribution_pcnt)) &&
              allAreTrue(getValidationChecks(totalValue))
            ) {
              /**
               * Add derived dollar amount to:
               *   - form field control to populate value on existing overheads
               *   - row to handle input changes
               */
              inputAttributes.control.value = deriveDollarAmount(totalValue, distribution_pcnt);
              row[field] = deriveDollarAmount(totalValue, distribution_pcnt);
            }
          }

          return getFormInputDataCell(cellAttributes, inputAttributes);
        default:
          return (
            <td key={`td-${index}`} className={classes}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const headers = [
    { caption: 'Division', field: 'division.name', classes: 'text-left' },
    {
      caption: '$ Distribution',
      field: 'distribution_amount',
      classes: 'text-left',
    },
    {
      caption: '% Distribution',
      field: 'distribution_pcnt',
      classes: 'text-left',
    },
  ];

  const tableHeadTh = headers.map(({ caption, classes, field }, index) => (
    <th key={`tableHeadTh-${field}-${index}`} className={classes}>
      {caption}
    </th>
  ));

  const tableBodyTr =
    rows.map((row) => (
      <tr
        key={`tableBodyTr-${row.division_id}`}
        onClick={() => {
          setEditMode(row, true);
        }}
        style={{ cursor: 'pointer' }}
      >
        {renderRows(headers, row)}
      </tr>
    )) ?? [];

  return <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} />;
};

export default OverheadDistributionsLsv;
