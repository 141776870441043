import { evaluate } from 'mathjs';

export const allAreTrue = (validations) => validations.every((element) => element === true);

export const deriveDollarAmount = (total_amount, percentage) =>
  evaluate(`${total_amount} * ${percentage}`);

export const derivePercentage = (total_amount, value) =>
  evaluate(`${value} / ${total_amount} * 100`).toFixed(2);

export const getValidationChecks = (value) => [value !== null, !value <= 0, !Number.isNaN(value)];
