import { Controls } from 'jsx/components/core/form/components/FormBuilder';

export type variationKeys =
  | 'id'
  | 'property_id'
  | 'crediting_start_date'
  | 'decision_date'
  | 'description'
  | 'effective_date'
  | 'lodged_date';

export const variationForm: Controls<variationKeys> = {
  id: {
    name: 'id',
    path: 'id',
    value: '',
    type: 'hidden',
    validationRules: {},
    listview: {
      show: false,
    },
  },
  property_id: {
    name: 'property_id',
    path: 'property_id',
    value: '',
    type: 'hidden',
    validationRules: {},
    listview: {
      show: false,
    },
  },
  description: {
    name: 'description',
    path: 'description',
    caption: 'Description',
    value: '',
    type: 'text',
    validationRules: {},
  },
  crediting_start_date: {
    name: 'crediting_start_date',
    path: 'crediting_start_date',
    caption: 'Crediting Start Date',
    value: '',
    type: 'date',
    validationRules: {},
  },
  decision_date: {
    name: 'decision_date',
    path: 'decision_date',
    caption: 'Decision Date',
    value: '',
    type: 'date',
    validationRules: {},
  },
  effective_date: {
    name: 'effective_date',
    path: 'effective_date',
    caption: 'Effective Date',
    value: '',
    type: 'date',
    validationRules: {},
  },
  lodged_date: {
    name: 'lodged_date',
    path: 'lodged_date',
    caption: 'Lodged Date',
    value: '',
    type: 'date',
    validationRules: {},
  },
};
