import React from 'react';
import { connect } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import componentsImg from '../../../../images/carbonizer_components.png';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';

class About extends React.Component {
  render() {
    const { currentApp } = this.props.realm;
    const app = currentApp ? currentApp : {};
    return (
      <div className="p-5 text-center">
        <div>
          <Icon size="4x" name={app.icon} style={{ color: '#83a326' }} />
        </div>
        <h1 className="mt-3">{app.title}</h1>
        <p className="mt-3">
          Managing the CarbonLink production pipeline from discovery to completion.
        </p>

        <h3 className="mt-5">Carbonizer Service Components</h3>
        <img alt="Components" src={componentsImg} style={{ maxWidth: 800 }} />
      </div>
    );
  }
}

const mapStoreToProps = ({ realm }) => ({ realm });
export default connect(mapStoreToProps)(withContainerError(About));
