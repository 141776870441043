import React from 'react';
import { connect } from "react-redux";
import {
  Row,
  Col
} from 'reactstrap';
import HorizontalChart from '../components/HorizontalChart';
import Icon from 'jsx/components/core/icons/Icon';
import { v4 as uuidv4 } from 'uuid';

import {
  fetchSoilMass
} from '../actions';

class WidgetLayerSoilMassByCores extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      specLink: 'https://carbonlink.atlassian.net/wiki/spaces/OP/pages/518225943/Spec+-+Calculate+mass+of+soil+for+layers+in+each+core+Ma',
      data: [],
      fetching: false,
      height: 400,
      datasets: [],
      widget: {
        id: 'soilmass',
        title: 'Soil Mass by Core/Layer',
        group: 'layers',
        fixedDatasets: true,
        datasets: [
          {
            id: uuidv4(),
            sourceId: 'soilmass',
            series: 'left',
            type: 'stack',
            colour: '#8884d8',
            rowName: 'core_id',
            stackId: 'a',
            stackField: 'layers',
            sortSelected: true,
            selected: { calculation_type: 'compressed', name: 'carbon_mass', caption: 'Carbon Mass t/ha', unit: ' t/ha', colour: '#8884d8' },
          }
        ]
      },
    })
  }

  componentDidMount() {
    // this.load();
  }

  componentDidUpdate(prevProps) {
    const {
      selectedTemplate,
      area_round_id
    } = this.props;

    const {
      fetching,
      datasets,
      mapSources,
      map,
      mapIsLoaded
    } = this.state;

    if (selectedTemplate.id && area_round_id && !fetching && (area_round_id !== prevProps.area_round_id || selectedTemplate.id !== prevProps.selectedTemplate.id || datasets.length === 0)) {
      this.load();
    }

    if (mapIsLoaded) {
      this.updateSources(map, mapSources)
    }
  }

  async load() {
    const {
      selectedTemplate,
      area_round_id
    } = this.props;

    let datasets = [];
    this.setState(
      { fetching: true },
      async () => {
        const soilmass = await this.props.dispatch(fetchSoilMass(area_round_id, selectedTemplate.id));

        // Get defaults for dataset model
        let datasetLayers = this.props.analytics.datasets.layers;
        let dataset = datasetLayers.find(datasetLayer => datasetLayer.id === 'soilmass');

        // Set dataset
        dataset.data = soilmass;
        datasets.push(dataset);
        this.setState({ datasets, fetching: false });
      }
    )
  }

  render() {
    const {
      height,
      datasets,
      widget,
      specLink,
    } = this.state;

    const specCaption = `Spec Sheet for ${widget.title}`;

    // If widget is empty
    const emptyCaption = `No Chart data available for ${widget.title}`;
    const iconName = 'chart-mixed';

    const checksum = new Date().getTime();

    return (
      <div>
        {datasets.length === 0 && (
          <div className="p-5 text-center m-1 bg-white rounded border border-secondary" style={{ height: height }}>
            <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
            <div className="mt-3">{emptyCaption}</div>
          </div>
        )}

        {datasets.length > 0 && (
          <Row className="text-center m-1 bg-white rounded border border-secondary p-2">
            <Col sm="12">
              <HorizontalChart
                datasets={datasets}
                checksum={checksum}
                group="layers"
                widget={widget}
                selectionKey="sites"
                height={height}
                margin={{ top: 20, right: 0, left: 20, bottom: 5, }}
                singleSelection={true}
              />
              <div className="text-right mr-3">
                <small><a target="_blank" rel="noopener noreferrer" href={specLink}>{specCaption}</a></small>
              </div>
            </Col>
          </Row>
        )}
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    analytics: store.analytics,
    mapster: store.mapster
  }
}

export default connect(mapStoreToProps)(WidgetLayerSoilMassByCores);
