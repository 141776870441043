import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import {
  validateChange,
  initControls,
  validateFormControls,
} from '../../../core/form/lib/validateForm';
import { controls } from '../forms/projectPeriod.js';
import FormInput from '../../../core/form/components/FormInput';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import Icon from 'jsx/components/core/icons/Icon';
import {
  fetchPeriod,
  createPeriod,
  updatePeriod,
  fetchPeriods,
  removePeriod,
} from '../actions/periods';

class ProjectPeriodModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      controls: initControls(controls),
      isValid: true,
      isOpen: false,
      title: 'Project Period',
      data: {},
      id: null,
      isNew: false,
      project_id: null,
      setModal: null,
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    let { isOpen, id, isNew, title } = this.state;

    if (isOpen !== this.props.isOpen) {
      isNew = true;
      title = 'New Project Period';
      this.setState({ controls: initControls(this.state.controls) });

      if (this.props.id) {
        isNew = false;
        title = 'Project Period';
        id = this.props.id;
        this.props.dispatch(fetchPeriod(id));
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        project_id: this.props.project_id,
        title,
        isNew,
        setModal: this.props.setModal,
      });
    }

    this.updateControls();
  }

  updateControls() {
    const { currentPeriod } = this.props.periods;

    let { controls } = this.state;

    if (currentPeriod && this.state.data.id !== currentPeriod.id) {
      const keys = Object.keys(controls);
      keys.forEach((key) => {
        const fieldName = controls[key].name;
        controls[key].value = currentPeriod[fieldName];
      });

      this.setState({
        data: currentPeriod,
        controls,
      });
    }
  }

  async onSave() {
    let { data, isNew, controls, project_id } = this.state;

    const keys = Object.keys(controls);
    keys.forEach((key) => {
      data[key] = controls[key].value;
    });

    let success;
    if (isNew) {
      delete data.id;
      data.project_id = project_id;
      success = await this.props.dispatch(createPeriod(data));
    } else {
      success = await this.props.dispatch(updatePeriod(data));
    }

    if (success) {
      this.props.dispatch(fetchPeriods(data.project_id));
      this.state.setModal(false);
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      `Removing ${data.name.toUpperCase()} period permanently. Continue?`
    );
    if (confirmed) {
      const success = await this.props.dispatch(removePeriod(data.id));
      if (success) {
        this.props.dispatch(fetchPeriods(data.project_id));
        this.state.setModal(false);
        this.setState({ isOpen: false });
      }
    }
  }

  handleChange(event) {
    const controls = validateChange(event, this.state.controls);
    const isValid = validateFormControls(controls);

    this.setState({
      controls,
      formChanged: true,
      isValid,
    });
  }

  render() {
    const { controls, isOpen, title, isNew } = this.state;

    const { responseMessage } = this.props.periods;

    const iconName = 'clock';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          <ResponseMessage responseMessage={responseMessage} />
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput handleChange={this.handleChange} control={controls.start_date} />
            <FormInput handleChange={this.handleChange} control={controls.end_date} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          {!isNew && (
            <Button disabled={false} size="sm" color="danger" onClick={this.onRemove}>
              Delete
            </Button>
          )}
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
            <Button size="sm" color="success" onClick={this.onSave}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    projects: store.projects,
    periods: store.periods,
  };
};

export default connect(mapStoreToProps)(ProjectPeriodModal);
