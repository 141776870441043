import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import Listview from '../../core/form/components/Listview';
import AttributeDefModal from './AttributeDefModal';

const AttributeDefsLsv = (props) => {
  const { rows } = props;

  const [isModalOpen, setModal] = useState(false);
  const [id, setId] = useState(null);

  const openModal = (id) => {
    setId(id);
    setModal(true);
  };

  const renderRows = (headers, row) => {
    const org_name = row.org.name;
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'org':
          return (
            <td key={index} className={header.classes}>
              {org_name}
            </td>
          );
        case 'edit':
          return (
            <td key={index} className={header.classes}>
              <Icon
                name="pen-to-square"
                style={{ cursor: 'pointer' }}
                onClick={() => openModal(row.id)}
              />
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {row[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: 'Table Name', field: 'table_name', classes: 'text-left' },
    { caption: 'Organisation', field: 'org', classes: 'text-left' },
    { caption: '', field: 'edit', classes: 'text-right text-success' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => (
      <tr key={index} onClick={() => openModal(row.id)} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  const iconName = 'clipboard-list';
  const emptyCaption = 'No definitions found';

  return (
    <React.Fragment>
      <AttributeDefModal setModal={setModal} id={id} isOpen={isModalOpen} />
      <div className="d-flex justify-content-end">
        <Button color="success" size="sm" onClick={() => openModal(null)}>
          Add New Attribute
        </Button>
      </div>
      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default AttributeDefsLsv;
