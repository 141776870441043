import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Row, Col } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { controls as assetProjectControls } from '../forms/location_asset';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';

import {
  createOrgPlantAssetProject,
  fetchLocationProjectAssociation,
  fetchLookupLocations,
  fetchOrgPlantAssetProject,
  removeOrgPlantAssetProject,
  updateOrgPlantAssetProject,
} from '../actions/locations';

import { fetchProjects } from '../../projects/actions/projects';
import { fetchPlantAssets } from '../actions/assets';

class AssetProjectAssociationModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(assetProjectControls),
      data: {},
      id: null,
      isNew: true,
      title: 'Transfer Plant',
    };

    this.onClose = this.onClose.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.onProjectChange = this.onProjectChange.bind(this);
    this.onAssetChange = this.onAssetChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.refreshLocationProjectAssociations = this.refreshLocationProjectAssociations.bind(this);
    this.resetLocationProjects = this.resetLocationProjects.bind(this);
    this.resetSelection = this.resetSelection.bind(this);
    this.setControlOptions = this.setControlOptions.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.dispatch(fetchLookupLocations());
      this.props.dispatch(fetchPlantAssets());

      const controls = initControls(cloneDeep(assetProjectControls));
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'Transfer Plant',
      };

      if (this.props.id) {
        const { id } = this.props;

        const data = await this.props.dispatch(fetchOrgPlantAssetProject({ id }));

        const { location_id } = data;
        this.refreshLocationProjectAssociations(location_id);

        updatedState = {
          ...updatedState,
          controls: updateControls(controls, data),
          data,
          id,
          isNew: false,
          title: 'Transfer Plant',
        };
      }

      this.setState(updatedState);
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.refresh) this.props.refresh();
    this.props.setModal(false);
  }

  onLocationChange({ value }) {
    this.handleChange({
      target: {
        name: 'location_id',
        value,
      },
    });

    this.refreshLocationProjectAssociations(value);

    if (value === '-') this.resetSelection('asset_id');
  }

  onProjectChange({ value }) {
    this.handleChange({
      target: {
        name: 'project_id',
        value,
      },
    });

    // Reset asset default option if no project is selected
    if (value === '-') this.resetSelection('asset_id');
  }

  onAssetChange({ value }) {
    this.handleChange({
      target: {
        name: 'asset_id',
        value,
      },
    });
  }

  async onRemove() {
    const { id } = this.state;

    // eslint-disable-next-line no-alert
    const confirmed = window.confirm('Removing project association permanently. Continue?');
    if (confirmed) {
      const success = await this.props.dispatch(removeOrgPlantAssetProject(id));
      if (success) this.onClose(true);
    }
  }

  async onSave() {
    const { controls, data, isNew } = this.state;

    const saveData = saveControls(controls, data);
    const { isValid, updatedControls } = await validateFormFieldControls(saveData, controls);

    if (isValid) {
      let saveMethod = updateOrgPlantAssetProject;

      if (isNew) {
        delete saveData.id;
        saveMethod = createOrgPlantAssetProject;
      }

      const success = await this.props.dispatch(saveMethod(saveData));

      if (success) this.onClose(true);

      return;
    }

    // Update controls state to display messages to the user
    this.setState({
      controls: updatedControls,
    });
  }

  async refreshLocationProjectAssociations(location_id) {
    // Base case - clear out location project associations
    if (location_id === '-') {
      this.resetLocationProjects();
      return;
    }

    // Fetch project associations to selected location
    const associations = await this.props.dispatch(
      fetchLocationProjectAssociation({ location_id }),
    );

    if (!associations || associations.length === 0) {
      this.resetLocationProjects();
      return;
    }

    // Update projects reducer including associated projects.
    const include_ids = associations.map(({ project_id }) => project_id);
    this.props.dispatch(fetchProjects({ include_ids }));
  }

  resetLocationProjects() {
    this.props.dispatch({ type: 'UNSET_LOCATION_PROJECT_ASSOCIATIONS' });
    this.props.dispatch({ type: 'UNSET_PROJECTS' });
  }

  resetSelection(name) {
    const { controls } = this.state;
    controls[name].value = null;

    this.setState({ controls });
  }

  setControlOptions(controls) {
    const { plant_asset_project_statuses } = this.props.attributes;
    const { users } = this.props.manage;
    const { projects } = this.props.projects;
    const { assets, locations } = this.props.wipstar;

    const adjustedProjectRows = projects?.rows.map((row) => ({
      ...row,
      name: `${row.name} (${row.project_job_no})`,
    }));

    // Set control options
    const typeOptions = {
      asset_id: assets.rows,
      contact_id: users,
      location_id: locations,
      project_id: adjustedProjectRows?.length > 0 ? adjustedProjectRows : [{ id: null, name: '-' }],
      status_id: plant_asset_project_statuses,
    };

    let updatedControls = cloneDeep(controls);

    // Update control options
    Object.entries(typeOptions).forEach(([key, options]) => {
      updatedControls = updateControlOptions(updatedControls, key, options);
    });

    return updatedControls;
  }

  render() {
    let { controls } = this.state;
    const { isNew, title } = this.state;
    const { responseMessage } = this.props.wipstar;
    const iconName = 'location-dot';

    controls = this.setControlOptions(controls);

    return (
      <Modal isOpen={this.props.isOpen} className="xl">
        <ModalHeader className="bg-corporate text-white">
          <Icon name={iconName} className="text-white mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form style={{ fontSize: 12 }}>
            <Row>
              <Col sm="4">
                <FormInputSelect
                  handleChange={this.onLocationChange}
                  control={controls.location_id}
                />
              </Col>
              <Col sm="4">
                <FormInputSelect
                  handleChange={this.onProjectChange}
                  control={controls.project_id}
                  isDisabled={!controls.location_id.value}
                />
              </Col>
              <Col sm="4">
                <FormInputSelect handleChange={this.onAssetChange} control={controls.asset_id} />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormInput handleChange={this.handleChange} control={controls.from_date} />
              </Col>
              <Col sm="6">
                <FormInput handleChange={this.handleChange} control={controls.to_date} />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormInput handleChange={this.handleChange} control={controls.status_id} />
              </Col>
              <Col sm="6">
                <FormInput handleChange={this.handleChange} control={controls.contact_id} />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button size="sm" color="success" onClick={this.onSave}>
            Save
          </Button>
          <Button size="sm" color="light" onClick={this.onClose}>
            Close
          </Button>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, manage, projects, wipstar }) => ({
  attributes,
  manage,
  projects,
  wipstar,
});

export default connect(mapStoreToProps)(AssetProjectAssociationModal);
