import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { HalfCircleSpinner } from 'react-epic-spinners';
import usePortal from 'react-useportal';

import '../styles/Loader.css';

const Loader = ({ loading }) => {
  const { Portal } = usePortal();
  return (
    <Portal>
      <div className={ClassNames('spinner-holder', { 'd-none': !loading })}>
        <HalfCircleSpinner color="#4285f4" />
      </div>
    </Portal>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loader;
