import React from 'react';
import { connect } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { debounce, cloneDeep, isEmpty } from 'lodash';
import { HalfCircleSpinner } from 'react-epic-spinners';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import { hectareNumberFormat } from 'jsx/lib/generic';
import Mapster from './Mapster';
import {
  fetchProperties,
  fetchPropertiesStats,
  fetchProject,
  fetchProjectStatuses,
  fetchProjects,
  fetchPropertyLots,
  fetchPropertyAreas,
  fetchRoundStats,
  fetchProjectCentroid,
  fetchProjectStats,
} from '../actions/sampling_plan';
import SamplingPlanProperty from '../components/sampling_plans/SamplingPlanProperty';
import Cell from '../components/sampling_plans/Cell';
import InlineCell from '../components/sampling_plans/InlineCell';

import { buildFeatureCollection, buildFeature } from '../lib/mapster';

import FormSearch from '../../../core/form/components/FormSearch';

const DEFAULT_FORM = {
  name: 'code_search',
  type: 'text',
  caption: '',
  value: '',
  placeholder: 'Search for properties or projects',
  description: '',
  options: [],
  validationRules: {
    minLength: 3,
  },
};

const HEIGHT_OF_HEADER = '85px';
class SamplingPlans extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapSources: [],
      selectedProjectId: '',
      loadingSearchResults: false,
      formControls: DEFAULT_FORM,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.searchProperties = debounce(this.searchProperties.bind(this), 300);
    this.handleSearchSelect = this.handleSearchSelect.bind(this);
    this.loadProject = this.loadProject.bind(this);
    this.setPropertiesMap = this.setPropertiesMap.bind(this);
    this.getPropertiesStats = this.getPropertiesStats.bind(this);
    this.handleFetchLots = this.handleFetchLots.bind(this);
    this.handleFetchAreas = this.handleFetchAreas.bind(this);
    this.handleFetchRoundStats = this.handleFetchRoundStats.bind(this);
  }

  componentDidMount() {
    const { selectedProjectId } = this.state;
    const { data: currentProject } = this.props.sampling_plan.project;

    this.resetGeometry();

    /**
     * Project statuses seldom chage, so we can load them once (regardless of active project) and
     * cache them
     */
    const hasProjectStatusBeenFetched = this.props.sampling_plan.projectStatuses.fetched;
    if (!hasProjectStatusBeenFetched) {
      this.props.dispatch(fetchProjectStatuses({}));
    }

    if (!selectedProjectId && currentProject.id !== selectedProjectId) {
      this.setState({ selectedProjectId: currentProject.id });
    }

    this.loadProject();
  }

  componentDidUpdate() {
    const { data: currentProject } = this.props.sampling_plan.project;
    const { selectedProjectId } = this.state;

    if (!selectedProjectId && currentProject.id !== selectedProjectId) {
      this.setState({ selectedProjectId: currentProject.id });
    }
    this.setPropertiesMap();
  }

  async loadProject(projectId) {
    const selectedProjectId = projectId || this.state.selectedProjectId;

    if (selectedProjectId) {
      this.props.dispatch(fetchProject({ id: selectedProjectId, include_geom: true }));
      this.props.dispatch(fetchProjectStats({ project_id: [selectedProjectId] }));
      await this.props.dispatch(
        fetchProperties({
          params: { project_id: selectedProjectId, include_geom: true },
        }),
      );

      // project centroid depends on the fetchProperties call to be complete
      await this.props.dispatch(fetchProjectCentroid({ project_id: selectedProjectId }));
      this.setPropertiesMap();
    }
  }

  resetGeometry() {
    const mapSources = [
      {
        id: 'properties',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Status',
        count: 0,
        style: 'Point',
        showMarker: true,
      },
    ];
    this.setState({ mapSources });
  }

  static handleSourceVisibility() {}

  setPropertiesMap() {
    const {
      data: { rows: properties },
    } = this.props.sampling_plan.properties;
    const { mapSources = [] } = this.state;
    if (!mapSources.length) return;
    const featureColours = this.props.mapster.colours.properties;
    const mapSource = mapSources.find((source) => source.id === 'properties');
    const featureCollection = buildFeatureCollection();

    properties.forEach(({ name, centroid }, index) => {
      if (centroid?.fetched && !isEmpty(centroid?.data)) {
        const popupText = `${name.toUpperCase()} property`;
        const feature = buildFeature(centroid?.data, {
          colour: featureColours[index],
          popupText,
        });
        featureCollection.features.push(feature);
      }
    });
    if (mapSource.load || featureCollection.features.length !== mapSource.count) {
      mapSource.source = featureCollection;
      mapSource.load = false;
      mapSource.count = featureCollection.features.length;
      this.setState({ mapSources });
    }
  }

  async searchProperties(searchTerm) {
    const formControls = cloneDeep(this.state.formControls);
    const { rows } = await this.props.dispatch(
      fetchProjects({
        params: { search_value: searchTerm },
        inBackground: true,
      }),
    );
    formControls.options = rows.map(({ name, id }) => ({ name, id }));
    this.setState({ formControls, loadingSearchResults: false });
  }

  getPropertiesStats(propertyIds) {
    this.props.dispatch(fetchPropertiesStats({ property_id: [propertyIds] }));
  }

  handleSearch(event) {
    const { value } = event.target;
    const formControls = cloneDeep(this.state.formControls);
    formControls.value = value;
    if (value.length >= 3) {
      this.searchProperties(value);
    }
    this.setState({ formControls, loadingSearchResults: true });
  }

  handleSearchSelect({ id }) {
    // TODO - this needs an action to unset the project and properties (future MR)
    this.setState({ selectedProjectId: id, formControls: DEFAULT_FORM });
    this.loadProject(id);
  }

  handleFetchLots(propertyId) {
    this.props.dispatch(fetchPropertyLots({ id: propertyId }));
  }

  handleFetchAreas(propertyId) {
    this.props.dispatch(fetchPropertyAreas({ id: propertyId }));
  }

  handleFetchRoundStats(propertyId) {
    this.props.dispatch(fetchRoundStats({ id: propertyId }));
  }

  render() {
    const { mapSources, selectedProjectId, loadingSearchResults, formControls } = this.state;
    const lngLat = [150.7333, -23.1333];
    const { data: currentProject } = this.props.sampling_plan.project;
    const { data: projectStats } = this.props.sampling_plan.projectStats;

    const {
      data: { rows: properties },
      fetching: propertyFetching,
      fetched: propertyFetched,
    } = this.props.sampling_plan.properties;

    const projectStatuses = this.props.sampling_plan?.projectStatuses?.data || [];
    const projectStatus =
      projectStatuses.find((curr) => curr.id === currentProject?.project_status_id) || {};

    return (
      <div className="h-100" style={{ backgroundColor: '#efefef' }}>
        <Row style={{ height: HEIGHT_OF_HEADER }} className="bg-white m-0 border-bottom flex-0">
          <div className="p-3 pt-4 d-flex justify-content-between bg-white w-100">
            <Col>
              <Icon size="2x" name="chart-scatter-3d" className="text-corporate" />
              <h2 className="m-0 p-0 ml-2 text-corporate d-inline">Sampling Plans</h2>
              <small className="text-corporate ml-2">- {currentProject?.name}</small>
            </Col>

            <Col>
              <FormSearch
                control={formControls}
                handleChange={this.handleSearch}
                handleSelect={this.handleSearchSelect}
                minimumLength={3}
                dark={false}
                isLoading={loadingSearchResults}
                selected={!!selectedProjectId && !formControls.value}
              />
            </Col>
          </div>
        </Row>
        {!selectedProjectId && (
          <Card className="p-5 text-center m-3">
            <div>
              <Icon size="3x" name="diagram-project" className="text-corporate" />
            </div>
            <div className="mt-3">
              No project selected. Search for a project or property to begin.
            </div>
          </Card>
        )}
        {selectedProjectId && !!properties && (
          <Row className="m-0 p-0">
            <Col
              className="overflow-scroll"
              sm={8}
              style={{
                maxWidth: '75%',
                height: `calc(100vh - ${HEIGHT_OF_HEADER})`,
              }}
            >
              <Card className="my-4">
                <CardHeader className="bg-corporate text-white d-flex justify-content-between align-items-center">
                  <span>
                    <Icon name="diagram-project" className="mr-2" />
                    {currentProject?.name}
                  </span>
                  <span>
                    <InlineCell
                      wrapperClassName="d-inline-block mr-2"
                      label="Project CEA (ha)"
                      value={hectareNumberFormat(
                        projectStats?.areas?.find(({ area_type }) => area_type === 'CEA')?.area_ha,
                      )}
                    />
                    <InlineCell
                      wrapperClassName="d-inline-block"
                      label="Project Lots (ha)"
                      value={hectareNumberFormat(projectStats?.lots?.area_ha)}
                    />
                  </span>
                </CardHeader>
                <CardBody>
                  <div className="d-flex">
                    <Cell label="Created" value={currentProject?.createdDate} />
                    <Cell label="Status" value={projectStatus?.name || 'N/A'} />
                    <Cell label="Job No" value={currentProject?.project_job_no || 'N/A'} />
                    <Cell label="Project Officer" value={currentProject?.assignedTo} />
                  </div>
                </CardBody>
              </Card>
              {propertyFetching && (
                <Card className="p-2">
                  Fetching properties
                  <HalfCircleSpinner size={20} color="grey" className="ml-2 d-inline-block" />
                </Card>
              )}
              {propertyFetched && properties.length === 0 && (
                <Card className="p-2">No properties associated with this project</Card>
              )}
              {!propertyFetching &&
                properties &&
                properties.map((property, index) => (
                  <SamplingPlanProperty
                    key={`sampling-property-${property.id}-${index}`}
                    property={property}
                    index={index}
                    featureColours={this.props.mapster.colours.properties}
                    getLots={this.handleFetchLots}
                    getAreas={this.handleFetchAreas}
                    getRounds={this.handleFetchRoundStats}
                  />
                ))}
            </Col>
            <Col sm={4}>
              <Mapster
                handleSourceVisibility={this.handleSourceVisibility}
                expandMap={true}
                lngLatCenter={lngLat}
                toggleMap={this.toggleMap}
                mapSources={mapSources}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ sampling_plan, mapster }) => ({
  sampling_plan,
  mapster,
});

export default connect(mapStoreToProps)(withContainerError(SamplingPlans));
