import React from 'react';
import { Input } from 'reactstrap';
import Listview from '../../../core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';

const FieldUploadsLsv = ({ rows, history, handleSelect, onRemove, checkAccess }) => {
  const open = (id) => {
    if (checkAccess('fieldBookView')) {
      const lnk = `/home/fieldtrips/field-uploads/${id}`;
      history.push(lnk);
    } else {
      alert('No permission to view Instrument Uploads. Check with your administrator');
    }
  };

  const renderRows = (headers, site) => {
    const tableTd = headers.map(({ classes, field }, index) => {
      let caption = site[field];

      if (field?.includes('.')) {
        let parent = site;
        for (let key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }
      const { firstname, lastname } = site['uploadedby'];
      const fullname = `${firstname} ${lastname}`;
      switch (field) {
        case 'created':
          return (
            <td key={index} className={classes}>
              {moment(caption).format('Do MMM YYYY')}
            </td>
          );
        case 'uploadby':
          return (
            <td key={index} className={classes}>
              {fullname}
            </td>
          );
        case 'checkbox':
          return (
            <td key={index}>
              <Input type="checkbox" className="m-0 mt-1" onChange={handleSelect} value={site.id} />
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {caption}
            </td>
          );
      }
    });

    tableTd.push(
      <td key={headers.length + 1} className="d-flex justify-content-end">
        {checkAccess('fieldUploadUpdate') && (
          <Icon
            name="pen-to-square"
            className="text-primary mr-3"
            onClick={() => open(site.id)}
            style={{ cursor: 'pointer' }}
          />
        )}
        {checkAccess('fieldUploadDelete') && (
          <Icon
            name="trash"
            className="text-primary mr-2"
            onClick={(e) => onRemove(e, site.id)}
            style={{ cursor: 'pointer' }}
          />
        )}
      </td>
    );
    return tableTd;
  };

  const headers = [
    { caption: '-', field: 'checkbox', classes: 'text-left' },
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Property', field: 'property.name', classes: 'text-left' },
    { caption: 'Instrument', field: 'instrument.name', classes: 'text-left' },
    { caption: 'Upload By', field: 'uploadby', classes: 'text-left' },
    { caption: 'Uploaded At', field: 'created', classes: 'text-left' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));
  tableHeadTh.push(<th key={headers.length + 1}></th>);

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => (
      <tr
        key={index}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          open(row.id);
        }}
      >
        {renderRows(headers, row)}
      </tr>
    ));
  }

  const iconName = 'upload';
  const emptyCaption = 'No Instrument Uploads found';

  return (
    <>
      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </>
  );
};

export default FieldUploadsLsv;
