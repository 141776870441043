import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import { HalfCircleSpinner } from 'react-epic-spinners';

const StageProgress = (props) => {
  const {
    status
  } = props;

  let iconName = 'ellipsis';
  let iconColour = 'dark';
  let icon = null;
  let caption = 'Unset'
  if (status) {
    caption = status.name;
    iconColour = status.colour;
    switch (status.tag) {
      case "outstanding":
        iconName = 'exclamation';
        break;
      case "na":
        iconName = 'snooze';
        break;
      case "inprogress":
        iconName = 'rotate';
        icon = <HalfCircleSpinner color="#ffffff" size={14} />
        break;
      case "complete-unsent":
      case "complete-sent":
      case "complete-internal":
        iconName = 'check';
        break;
      default:
        iconName = 'ellipsis';
        break;
    }
  }

  if (!icon) {
    icon = (<Icon name={iconName} className={`stage-icon text-white mr-1 bg-${iconColour}`} />);
  }

  return (
    <React.Fragment>
      <div className={`stage-progress rounded p-1 d-flex flex-row ml-2 text-white justify-content-between bg-${iconColour}`}>
        <span className="ml-1 mr-2">{caption}</span>
        {icon}
      </div>

    </React.Fragment>
  );
}

export default StageProgress;
