import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiEnterpriseLivestocks, apiEnterpriseAgistments } from '../../../../constants/api';

const apiEnterpriseLivestockBreedings = `${apiEnterpriseLivestocks}/breedings`;
const apiEnterpriseLivestockTransfers = `${apiEnterpriseLivestocks}/transfers`;
const apiEnterpriseLivestockOpeningAdjustments = `${apiEnterpriseLivestocks}/opening_adjustments`;
const apiEnterpriseLivestockIncomes = `${apiEnterpriseLivestocks}/incomes`;

export function fetchLivestockSummaries(params) {
  return async (dispatch) =>
    await get(
      dispatch,
      `FETCH_LIVESTOCK_SUMMARIES`,
      `${apiEnterpriseLivestocks}/summaries`,
      params
    );
}

export function fetchLivestockStockflows(params, inBackground = false) {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_LIVESTOCK_STOCKFLOWS',
      `${apiEnterpriseLivestocks}/stockflows`,
      params,
      null,
      inBackground
    );
}

export function fetchLivestockTransactions(type, params) {
  return async (dispatch) =>
    await get(
      dispatch,
      `FETCH_LIVESTOCK_${type.toUpperCase()}`,
      `${apiEnterpriseLivestocks}`,
      params
    );
}

export function fetchLivestockTransaction(id) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_LIVESTOCK_TRANSACTION', `${apiEnterpriseLivestocks}/${id}`);
}

export function createLivestockTransaction(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_LIVESTOCK_TRANSACTION`, `${apiEnterpriseLivestocks}`, data);
}

export function updateLivestockTransaction(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      'UPDATE_LIVESTOCK_TRANSACTION',
      `${apiEnterpriseLivestocks}/${data.id}`,
      data
    );
}

export function removeLivestockTransaction(id) {
  return async (dispatch) =>
    await remove(dispatch, 'REMOVE_LIVESTOCK_TRANSACTION', `${apiEnterpriseLivestocks}/${id}`);
}

export function fetchLivestockBreedings(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_LIVESTOCK_BREEDINGS`, apiEnterpriseLivestockBreedings, params);
}

export function fetchLivestockBreeding(id) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_LIVESTOCK_BREEDING', `${apiEnterpriseLivestockBreedings}/${id}`);
}

export function createLivestockBreeding(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_LIVESTOCK_BREEDING`, apiEnterpriseLivestockBreedings, data);
}

export function updateLivestockBreeding(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      'UPDATE_LIVESTOCK_BREEDING',
      `${apiEnterpriseLivestockBreedings}/${data.id}`,
      data
    );
}

export function removeLivestockBreeding(id) {
  return async (dispatch) =>
    await remove(dispatch, 'REMOVE_LIVESTOCK_BREEDING', `${apiEnterpriseLivestockBreedings}/${id}`);
}

export function fetchLivestockTransfers(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_LIVESTOCK_TRANSFERS`, apiEnterpriseLivestockTransfers, params);
}

export function fetchLivestockTransfer(id) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_LIVESTOCK_TRANSFER', `${apiEnterpriseLivestockTransfers}/${id}`);
}

export function createLivestockTransfer(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_LIVESTOCK_TRANSFER`, apiEnterpriseLivestockTransfers, data);
}

export function updateLivestockTransfer(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      'UPDATE_LIVESTOCK_TRANSFER',
      `${apiEnterpriseLivestockTransfers}/${data.id}`,
      data
    );
}

export function removeLivestockTransfer(id) {
  return async (dispatch) =>
    await remove(dispatch, 'REMOVE_LIVESTOCK_TRANSFER', `${apiEnterpriseLivestockTransfers}/${id}`);
}

export function fetchAgistments(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_LIVESTOCK_AGISTMENTS`, apiEnterpriseAgistments, params);
}

export function fetchAgistment(id) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_LIVESTOCK_AGISTMENT', `${apiEnterpriseAgistments}/${id}`);
}

export function createAgistment(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_LIVESTOCK_AGISTMENT`, apiEnterpriseAgistments, data);
}

export function updateAgistment(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      'UPDATE_LIVESTOCK_AGISTMENT',
      `${apiEnterpriseAgistments}/${data.id}`,
      data
    );
}

export function removeAgistment(id) {
  return async (dispatch) =>
    await remove(dispatch, 'REMOVE_LIVESTOCK_AGISTMENT', `${apiEnterpriseAgistments}/${id}`);
}

export function createLivestockOpeningAdjustment(data) {
  return async (dispatch) =>
    await post(
      dispatch,
      `CREATE_LIVESTOCK_OPENING_ADJUSTMENT`,
      `${apiEnterpriseLivestockOpeningAdjustments}`,
      data
    );
}

export function removeLivestockOpeningAdjustment(id) {
  return async (dispatch) =>
    await remove(
      dispatch,
      `REMOVE_LIVESTOCK_OPENING_ADJUSTMENT`,
      `${apiEnterpriseLivestockOpeningAdjustments}/${id}`
    );
}

export function fetchLivestockIncomes(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_LIVESTOCK_INCOMES`, `${apiEnterpriseLivestockIncomes}`, params);
}

export function fetchLivestockIncome(params) {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_LIVESTOCK_INCOME',
      `${apiEnterpriseLivestockIncomes}/${params.id}`,
      params
    );
}

export function createLivestockIncome(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_LIVESTOCK_INCOME`, `${apiEnterpriseLivestockIncomes}`, data);
}

export function updateLivestockIncome(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      `UPDATE_LIVESTOCK_INCOME`,
      `${apiEnterpriseLivestockIncomes}/${data.id}`,
      data
    );
}

export function removeLivestockIncome(id) {
  return async (dispatch) =>
    await remove(dispatch, `REMOVE_LIVESTOCK_INCOME`, `${apiEnterpriseLivestockIncomes}/${id}`);
}
