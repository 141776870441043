import { cloneDeep, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import SearchToolbar from 'jsx/components/core/toolbars/SearchToolbar';
import { fetchAttributes } from 'jsx/components/modules/portrait/actions/attributes';
import { updateControlOptions } from 'jsx/components/core/form/lib/validateForm';

import GenericModal from 'jsx/components/core/form/components/GenericModal';
import FormBase from '../../core/form/components/FormBase';
import GenericLsv from '../../core/form/components/GenericLsv';
import { controls as controlsTemplate } from '../forms/analysis_periods';
import {
  createAnalysisPeriod,
  fetchAnalysisPeriod,
  fetchAnalysisPeriods,
  removeAnalysisPeriod,
  setAnalysisPeriodParams,
  updateAnalysisPeriod,
} from '../actions/analysis_periods';

class AnalysisPeriods extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(controlsTemplate),
      data: {},
      isOpen: false,
      searchValue: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchAnalysisPeriods());
    this.props.dispatch(fetchAttributes({ type: 'transaction_intervals' }));
  }

  setModal = (isOpen) => {
    const { data } = this.state;
    let updatedState = { isOpen };
    if (!isOpen && !isEmpty(data))
      updatedState = { ...updatedState, data: {}, controls: cloneDeep(controlsTemplate) };

    this.setState(updatedState);
  };

  handleSearchChange = (event) => {
    // Update search value
    const { value: searchValue } = event.target;
    this.setState({ searchValue });

    const enterButtonCode = 13;
    if (event.keyCode === enterButtonCode) this.onChange();
  };

  onChange = () => {
    const { searchValue } = this.state;
    this.props.dispatch(setAnalysisPeriodParams({ search_value: searchValue }));
  };

  onClose = () => {
    // Reset group properties and refetch groups
    this.setState({ isOpen: false, controls: cloneDeep(controlsTemplate), data: {} });
    this.onRefresh();
  };

  onEdit = async (id) => {
    const data = await this.props.dispatch(fetchAnalysisPeriod({ id }));
    if (!data?.id) return;

    this.setState({ data, isOpen: true });
  };

  onSave = async (data, isNew, changes) => {
    const updatedData = isNew ? data : changes;
    if (isNew) delete updatedData.id;

    const saveMethod = isNew ? createAnalysisPeriod : updateAnalysisPeriod;
    return this.props.dispatch(saveMethod(updatedData));
  };

  onRemove = (id) => this.props.dispatch(removeAnalysisPeriod(id));

  onRefresh = () => this.props.dispatch(fetchAnalysisPeriods());

  render() {
    let { controls } = this.state;
    const { data, isOpen, searchValue } = this.state;
    const { periods, responseMessage } = this.props.analysis_periods;
    const { transaction_intervals } = this.props.attributes;

    controls = updateControlOptions(
      controls,
      'transaction_interval_id',
      transaction_intervals.filter((interval) => interval.tag === 'quarter'),
    );

    return (
      <>
        <GenericModal
          controls={controls}
          modalTitle="Analysis Period"
          setModal={this.setModal}
          data={data}
          isOpen={isOpen}
          iconName="timeline"
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onClose}
          responseMessage={responseMessage}
        />
        <SearchToolbar
          rows={periods}
          searchValue={searchValue}
          onChange={this.onChange}
          handleSearchChange={this.handleSearchChange}
          setModal={this.setModal}
          countLabel="periods"
          buttonLabel="Add"
        />
        <GenericLsv
          controls={controls}
          emptyCaption="No Analysis Periods found"
          iconName="timeline"
          onClick={(id) => {
            this.onEdit(id);
          }}
          rows={periods}
        />
      </>
    );
  }
}

const mapStoreToProps = ({ analysis_periods, attributes }) => ({
  analysis_periods,
  attributes,
});

export default connect(mapStoreToProps)(AnalysisPeriods);
