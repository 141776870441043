import { Controls } from 'jsx/components/core/form/components/FormBuilder';

export type projectRegisterPropertyKeys =
  | 'id'
  | 'propertyName'
  | 'idx'
  | 'address'
  | 'lga'
  | 'participant_client_no'
  | 'nearest_town'
  | 'nrm_region';

export const projectRegisterPropertyForm: Controls<projectRegisterPropertyKeys> = {
  id: {
    name: 'id',
    path: 'id',
    value: '',
    type: 'hidden',
    validationRules: {},
  },
  propertyName: {
    name: 'propertyName',
    path: 'name',
    caption: 'Property Name',
    value: '',
    type: 'text',
    validationRules: {},
  },
  idx: {
    name: 'idx',
    path: 'idx',
    caption: 'IDX',
    value: '',
    type: 'text',
    validationRules: {},
  },
  address: {
    name: 'address',
    path: 'address',
    caption: 'Address',
    value: '',
    type: 'text',
    validationRules: {},
  },
  lga: {
    name: 'lga',
    path: 'lga',
    caption: 'LGA',
    value: '',
    type: 'text',
    validationRules: {},
  },
  participant_client_no: {
    name: 'participant_client_no',
    path: 'participant_client_no',
    caption: 'Participant Client No',
    value: '',
    type: 'text',
    validationRules: {},
  },
  nearest_town: {
    name: 'nearest_town',
    path: 'nearest_town',
    caption: 'Nearest Town',
    value: '',
    type: 'text',
    validationRules: {},
  },
  nrm_region: {
    name: 'nrm_region',
    path: 'nrm_region',
    caption: 'NRM Region',
    value: '',
    type: 'text',
    validationRules: {},
  },
};
