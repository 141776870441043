import { controlDefaults } from '../../form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  firstname: {
    ...controlDefaults,
    placeholder: 'First Name',
    name: 'firstname',
    type: 'text',
    caption: 'First Name',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
  },
  lastname: {
    ...controlDefaults,
    placeholder: 'Last Name',
    name: 'lastname',
    type: 'text',
    caption: 'Last Name',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
  },
  email: {
    ...controlDefaults,
    placeholder: 'Email',
    name: 'email',
    type: 'text',
    caption: 'Email',
    disabled: true,
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
  },
  mobile: {
    ...controlDefaults,
    placeholder: 'Mobile',
    name: 'mobile',
    type: 'text',
    caption: 'Mobile',
    disabled: true,
    validationRules: {},
  },
  password: {
    ...controlDefaults,
    placeholder: 'Password',
    name: 'password',
    type: 'password',
    caption: 'Set Password',
    validationRules: {
      minLength: 8,
      isRequired: true,
    },
  },
  password2: {
    ...controlDefaults,
    placeholder: 'Re-type Password',
    name: 'password2',
    type: 'password',
    description: 'Password must be at least 8 characters',
    validationRules: {
      minLength: 8,
      isRequired: true,
    },
  },
};
