import { createParamsFromFilters } from 'jsx/lib/actions';
import { get, post, remove, put } from '../../../../lib/genericAction';

import { apiDaysheets } from '../../../../constants/api';

export function fetchDaysheetActivities(daysheet_id, filters) {
  const paramsWithFilters = createParamsFromFilters(filters);

  return async (dispatch) =>
    get(
      dispatch,
      'FETCH_DAYSHEET_ACTIVITIES',
      `${apiDaysheets}/${daysheet_id}/activities`,
      paramsWithFilters,
    );
}

export function fetchDaysheetActivity(id) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_DAYSHEET_ACTIVITY', `${apiDaysheets}/activities/${id}`);
}

export function fetchDaysheetActivityByUser(user_id = null) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_USER_DAYSHEET_ACTIVITY', `${apiDaysheets}/activities/user`, { user_id });
}

export function createDaysheetActivity(data, daysheet_id) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_DAYSHEET_ACTIVITY', `${apiDaysheets}/${daysheet_id}/activities`, data);
}

export function updateDaysheetActivity(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_DAYSHEET_ACTIVITY', `${apiDaysheets}/activities/${data.id}`, data);
}

export function deleteDaysheetActivity(id) {
  return async (dispatch) =>
    remove(dispatch, 'DELETE_DAYSHEET_ACTIVITY', `${apiDaysheets}/activities/${id}`);
}
