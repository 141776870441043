import {
  downloadRoundManifest,
  downloadGpsLocations,
  downloadRoundScans,
  downloadLocationsPack,
  downloadResultsPack,
  downloadAuditPack,
  downloadReportingPack,
  downloadSelectionScans,
  downloadFakeLab,
  downloadExportSites,
  downloadWorkflowDocument
} from '../actions/downloads';

export default class Downloads {
  set(round_id, dispatch) {
    this.round_id = round_id;
    this.dispatch = dispatch;
  }

  async handleRoundManifestDownload(onProgressChange) {
    return await this.dispatch(downloadRoundManifest(this.round_id, onProgressChange));
  }

  async handleLocationsPackDownload(onProgressChange) {
    return await this.dispatch(downloadLocationsPack(this.round_id, onProgressChange));
  }

  async handleRoundGpsLocationsDownload(onProgressChange) {
    return await this.dispatch(downloadGpsLocations(this.round_id, onProgressChange));
  }

  async handleRoundScansDownload(onProgressChange) {
    return await this.dispatch(downloadRoundScans(this.round_id, onProgressChange));
  }

  async handleResultsPackDownload(onProgressChange) {
    return await this.dispatch(downloadResultsPack(this.round_id, onProgressChange));
  }

  async handleAuditPackDownload(onProgressChange) {
    return await this.dispatch(downloadAuditPack(this.round_id, onProgressChange));
  }

  async handleReportingPackDownload(onProgressChange) {
    return await this.dispatch(downloadReportingPack(this.round_id, onProgressChange));
  }

  async handleSelectionScansDownload(onProgressChange) {
    return await this.dispatch(downloadSelectionScans(this.round_id, onProgressChange));
  }

  async handleFakeLabDownload(onProgressChange) {
    return await this.dispatch(downloadFakeLab(this.round_id, onProgressChange));
  }

  async handleExportSitesDownload(onProgressChange, filters) {
    return await this.dispatch(downloadExportSites(this.round_id, onProgressChange, filters));
  }

  async handleWorkflowDocumentDownload(doc_id, onProgressChange) {
    return await this.dispatch(downloadWorkflowDocument(doc_id, onProgressChange));
  }
}
