import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button, Label, FormGroup } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import { updateControls, saveControls } from '../../../core/form/lib/validateForm';
import { controls } from '../forms/siteSample.js';

import { fetchSiteSample, updateSiteSample } from '../actions';

class SiteSample extends FormBase {
  constructor(props) {
    super(props);

    const { project_id, property_id, area_id, round_id, strata_id, site_id } =
      this.props.match.params;

    const backlink = `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${round_id}/stratas/${strata_id}/sites/${site_id}`;

    this.state = {
      activeTab: '1',
      controls: controls,
      backlink,
    };
  }

  async componentDidMount() {
    let { data, controls } = this.state;

    const id = this.props.match.params.id;

    controls = updateControls(controls, data);

    this.props.dispatch(fetchSiteSample(id));

    this.setState({
      data,
      controls,
    });
  }

  async onSave() {
    let { data, controls, backlink } = this.state;

    data = saveControls(controls, data);

    let success;
    success = await this.props.dispatch(updateSiteSample(data));

    if (success) this.props.history.push(backlink);
  }

  render() {
    const { controls, data } = this.state;

    const { responseMessage } = this.props.sites;

    const iconName = 'vials';
    let rgb;
    if (data) {
      rgb = `rgb( ${data.reading_red}, ${data.reading_green}, ${data.reading_blue})`;
    }

    return (
      <div>
        <div className="p-4">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row">
              <Icon size="2x" name={iconName} className="appForeTint mr-3" />
              <h3>Sample</h3>
            </div>
          </div>

          <Form>
            <Row className="bg-light m-0 pb-2">
              <Col sm="4">
                <FormInput
                  handleChange={this.handleChange}
                  control={controls.position}
                  disabled={true}
                />
              </Col>
              <Col sm="4">
                <FormInput handleChange={this.handleChange} control={controls.upper_depth_mm} />
              </Col>
              <Col sm="4">
                <FormInput handleChange={this.handleChange} control={controls.lower_depth_mm} />
              </Col>
            </Row>

            <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Scanner Readings</h5>
            <Row className="bg-light m-0 pb-2">
              <Col sm="2">
                <FormInput
                  handleChange={this.handleChange}
                  control={controls.reading_red}
                  disabled={true}
                />
              </Col>
              <Col sm="2">
                <FormInput
                  handleChange={this.handleChange}
                  control={controls.reading_green}
                  disabled={true}
                />
              </Col>
              <Col sm="2">
                <FormInput
                  handleChange={this.handleChange}
                  control={controls.reading_blue}
                  disabled={true}
                />
              </Col>
              <Col sm="3">
                <FormGroup row className="mt-0 mb-0">
                  <Label sm={12} className="font-weight-bold">
                    Colour
                  </Label>
                  <Col sm={12}>
                    <div
                      style={{ width: 36, height: 36, backgroundColor: rgb }}
                      className="border border-secondary rounded"
                    ></div>
                  </Col>
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormInput
                  handleChange={this.handleChange}
                  control={controls.reading_gamma}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row className="bg-light m-0 pb-2">
              <Col sm="2">
                <FormInput
                  handleChange={this.handleChange}
                  control={controls.scan_bulk_density}
                  disabled={true}
                />
              </Col>
              <Col sm="2">
                <FormInput
                  handleChange={this.handleChange}
                  control={controls.scan_water_content}
                  disabled={true}
                />
              </Col>
            </Row>

            <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Collection/Observations</h5>
            <Row className="bg-light m-0 pb-2">
              <Col sm="6">
                <FormInput handleChange={this.handleChange} control={controls.texture} />
              </Col>
              <Col sm="3">
                <FormInput handleChange={this.handleChange} control={controls.colour} />
              </Col>
              <Col sm="3">
                <FormInput handleChange={this.handleChange} control={controls.munsell_colour} />
              </Col>
            </Row>
            <Row className="bg-light m-0 pb-2">
              <Col sm="3">
                <FormInput handleChange={this.handleChange} control={controls.ph} />
              </Col>
              <Col sm="3">
                <FormInput handleChange={this.handleChange} control={controls.water_content} />
              </Col>
              <Col sm="3">
                <FormInput handleChange={this.handleChange} control={controls.structure} />
              </Col>
              <Col sm="3">
                <FormInput handleChange={this.handleChange} control={controls.gravel_content} />
              </Col>
            </Row>

            <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Lab Analysis</h5>
            <Row className="bg-light m-0 pb-2">
              <Col sm="3">
                <FormInput handleChange={this.handleChange} control={controls.lab_sample_code} />
              </Col>
              <Col sm="3">
                <FormInput handleChange={this.handleChange} control={controls.lab_soc_pcnt} />
              </Col>
              <Col sm="3">
                <FormInput handleChange={this.handleChange} control={controls.lab_gravel_content} />
              </Col>
              <Col sm="3">
                <FormInput handleChange={this.handleChange} control={controls.lab_water_content} />
              </Col>
            </Row>

            {responseMessage && (
              <div className="p-2 text-danger text-center">{responseMessage}</div>
            )}
            <div className="p-2 d-flex justify-content-center mt-2">
              <Button
                disabled={this.checkAccess('siteUpdate')}
                color="success"
                className="m-1"
                onClick={this.onSave}
              >
                Save
              </Button>
              <Button color="light" className="m-1" onClick={this.onCancel}>
                Cancel
              </Button>
              <Button color="danger" className="m-1" onClick={this.onRemove} disabled={true}>
                Delete
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    sites: store.sites,
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(SiteSample);
