import { controlDefaults } from "../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      isRequired: true
    },
  },
  type: {
    ...controlDefaults,
    placeholder: '',
    name: 'type',
    type: 'select',
    caption: 'Type',
    validationRules: {
      isRequired: true
    },
    options: [
      { id: 'cm', name: 'Depth by cm' },
      { id: 'mass', name: 'Depth by mass' },
      { id: 'percentile', name: 'Depth by percentile' }
    ]
  }
}