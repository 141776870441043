import { defaults } from '../../../../lib/genericReducer';
export default function reducer(state = {
  fetching: false,
  fetched: false,
  error: null,
  authorised: true,
  authResponseMessage: null,
  searchValue: '',
  params: {},
  filters: {
    sample_type_id: [],
  },
  register: [],
  currentRegisterRecord: {},
  samples: [],
  statuses: [],
  contacts: [],
  documents: [],
  dashboard_stats:[],
  responseMessage: null
}, action) {

  state = {
    ...state,
    ...defaults(action)
  }

  // Account for specific types
  switch (action.type) {
    case "FETCH_DASHBOARD_STATS_BYSAMPLES_FULFILLED": {
      return {
        ...state,
        dashboard_stats: action.payload
      }
    }
    case "SET_LAB_REGISTER_SEARCH_VALUE":
      {
        return {
          ...state,
          searchValue: action.payload,
        }
      }
    case "SET_LAB_REGISTER_PARAMS":
      {
        return {
          ...state,
          params: action.payload,
        }
      }
    case "SET_ACTIVITY_FILTERS": {
      return {
        ...state,
        filters: action.payload
      }
    }
    case "UNSET_LAB_REGISTER_PARAMS":
      {
        return {
          ...state,
          params: {},
        }
      }
    case "FETCH_LAB_REGISTER_PENDING":
    case "UNSET_LAB_REGISTER":
      {
        return {
          ...state,
          register: [],
        }
      }

    case "FETCH_LAB_REGISTER_FULFILLED":
      {
        return {
          ...state,
          register: action.payload
        }
      }

    case "FETCH_LAB_REGISTER_RECORD_PENDING":
    case "UNSET_LAB_REGISTER_RECORD":
      {
        return {
          ...state,
          currentRegisterRecord: {},
        }
      }

    case "FETCH_LAB_REGISTER_RECORD_FULFILLED":
      {
        return {
          ...state,
          currentRegisterRecord: action.payload
        }
      }

    case "FETCH_LAB_REGISTER_DOCUMENTS_PENDING":
      {
        return {
          ...state,
          documents: [],
        }
      }
    case "FETCH_LAB_REGISTER_DOCUMENTS_FULFILLED":
      {
        return {
          ...state,
          documents: action.payload,
        }
      }

    case "FETCH_LAB_STATUSES_PENDING":
      {
        return {
          ...state,
          statuses: [],
        }
      }
    case "FETCH_LAB_STATUSES_FULFILLED":
      {
        return {
          ...state,
          statuses: action.payload,
        }
      }
    case "FETCH_LAB_CONTACTS_PENDING":
      {
        return {
          ...state,
          contacts: [],
        }
      }
    case "FETCH_LAB_CONTACTS_FULFILLED":
      {
        return {
          ...state,
          contacts: action.payload,
        }
      }
    case "FETCH_LAB_REGISTER_SAMPLES_FULFILLED": {
      return {
        ...state,
        samples: action.payload,
      }
    }
    case "UNSET_LAB_REGISTER_SAMPLES": {
      return {
        ...state,
        samples: [],
      }
    }
    case "IMPORT_LAB_REGISTER_SAMPLES_FULFILLED":
      {
        state.currentRegisterRecord.lab_register_samples = action.payload.data.result;
        return {
          ...state,
          responseMessage: action.payload.data.message
        }
      }
    case "UPLOAD_LAB_REGISTER_RESULTS_FULFILLED":
      {
        return {
          ...state,
          responseMessage: action.payload.data.message
        }
      }
    default: break;
  }
  return state;
}
