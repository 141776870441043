export const isCorrespondingValueProvided = (controls, expr) => {
  let isValid = true;
  const [fieldA, fieldB] = expr;
  const fieldBCaption = controls[fieldB].caption;

  const message = `${fieldBCaption} must be provided`;

  if (
    controls[fieldA].value &&
    (controls[fieldB].value === 0 || !controls[fieldB].value || controls[fieldB].value === '')
  ) {
    isValid = false;
    return { isValid, message };
  }

  return { isValid, message: '' };
};

export const isFieldBGreaterThanA = (controls, targetFields) => {
  let isValid = false;
  let message = '';
  const comparisonValues = extractFields(controls, targetFields);

  if (
    Array.isArray(targetFields) &&
    Array.isArray(comparisonValues) &&
    comparisonValues.length === 2
  ) {
    // Base check. Ignore validation if both fields are null.
    const [valueOne, valueTwo] = comparisonValues;

    if (valueOne === null && valueTwo === null) {
      return { isValid: true, message };
    }

    isValid = parseFloat(valueOne) <= parseFloat(valueTwo);

    const [targetOne, targetTwo] = targetFields;
    message = `${controls[targetOne].caption} must be greater than ${controls[targetTwo].caption}`;
  } else {
    console.error('Error: Controls expression is not valid');
  }

  return { isValid, message };
};

export const extractFields = (controls, expr) => {
  if (!Array.isArray(expr)) return;
  const fields = [];
  const keys = expr;
  if (keys.length > 0) {
    keys.forEach((key) => {
      let value = null;
      if (controls[key].value) ({ value } = controls[key]);
      fields.push(value);
    });
  }
  return fields;
};

export const compareFields = (controls, control) => {
  if (!control.validationRules) return true;

  if (control.validationRules.compareFields) {
    return isFieldBGreaterThanA(controls, control.validationRules.compareFields);
  }

  if (control.validationRules.correspondingFields) {
    return isCorrespondingValueProvided(controls, control.validationRules.correspondingFields);
  }

  return true;
};
