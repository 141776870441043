import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  client_id: {
    ...controlDefaults,
    placeholder: 'Type to start searching...',
    name: 'client_id',
    type: 'search',
    options: [],
    validationRules: {},
  },
  from_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'from_date',
    type: 'date',
    caption: '',
    validationRules: {},
    value: '2022-07-01',
  },
  to_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'to_date',
    type: 'date',
    caption: '',
    validationRules: {},
    value: '2023-06-30',
  },
};
