import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label
} from 'reactstrap';

import DirectDownload from '../../../core/form/components/DirectDownload';
import FormInput from "../../../core/form/components/FormInput";
import { controlDefaults } from "../../../core/form/lib/validateForm";

const FieldUploadSitesToolbar = (props) => {
  let {
    handleDownload,
    max_distance,
    setMaxDistance
  } = props;

  const control = {
    ...controlDefaults,
    name: 'max_distance',
    type: 'text',
    value: max_distance,
    validationRules: {},
  }

  return (
    <div className="bg-light p-2 d-flex justify-content-end border-bottom border-gray">
      <div className="mr-2 d-flex justify-end" >
        <Label className="m-2">Max Distance</Label>
        <div style={{width:80}} >
          <FormInput handleChange={setMaxDistance} control={control} />
        </div>
      </div>

      <UncontrolledButtonDropdown>
        <DropdownToggle caret color='primary' size="sm">Action</DropdownToggle>
        <DropdownMenu right>
          <DropdownItem toggle={false}>
            <DirectDownload
              classNames="ml-2 mr-2"
              handleDownload={(onProgressChange) => handleDownload(onProgressChange)}
              caption="Download Target/Actual Comparison"
              showLabel={true}
            />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
}

export default FieldUploadSitesToolbar;
