import React from 'react';
import { connect } from 'react-redux';
import { UncontrolledCollapse, Card, Row, Col } from 'reactstrap';
import PillPicker from '../../../core/form/components/PillPicker';
import PillPresenter from '../../../core/form/components/PillPresenter';

import { setFilters } from '../../../core/activity/actions';

class LabRegisterActivitiesFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggler: '#toggler',
    };

    this.handleSystemChange = this.handleSystemChange.bind(this);
    this.handleSystemDelete = this.handleSystemDelete.bind(this);
  }

  handleSystemChange(event) {
    const { filters, params } = this.props.activities;

    const exists = filters.system.find((id) => id === event.target.value);
    if (!exists) {
      filters.system.push(event.target.value);
    }

    this.props.dispatch(setFilters(params, filters));
  }

  handleSystemDelete(id) {
    const { filters, params } = this.props.activities;

    const idx = filters.system.findIndex((fid) => fid === id);
    filters.system.splice(idx, 1);

    this.props.dispatch(setFilters(params, filters));
  }

  render() {
    const { toggler } = this.state;

    const { filters, system_types } = this.props.activities;

    const systemSelection = filters.system;

    let filtersCount = 0;
    filtersCount += systemSelection.length;

    return (
      <React.Fragment>
        <UncontrolledCollapse toggler={toggler}>
          <Card className="border border-warning rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col className="bg-light m-1">
                <PillPicker
                  caption="System Logs"
                  handleChange={this.handleSystemChange}
                  rows={system_types}
                  selection={systemSelection}
                  showPills={false}
                />
              </Col>
              <Col className="bg-light m-1"></Col>
              <Col className="bg-light m-1"></Col>
            </Row>
          </Card>
        </UncontrolledCollapse>
        {filtersCount > 0 && (
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption="System Logs"
              handleDelete={this.handleSystemDelete}
              rows={system_types}
              selection={systemSelection}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    areas: store.areas,
    activities: store.activities,
  };
};

export default connect(mapStoreToProps)(LabRegisterActivitiesFilter);
