import React from 'react';
import moment from 'moment';
import Listview from "../../form/components/Listview";
import Icon from 'jsx/components/core/icons/Icon';
import DirectDownload from '../../form/components/DirectDownload';

const PipelineDocsLsv = (props) => {
  const {
    rows,
    onRemove,
    handleDownload,
    checkAccess
  } = props;

  const iconName = 'file';
  const emptyCaption = 'No pipeline documents found for this round.';

  const renderRows = (headers, doc) => {
    let rowColour = "text-success mr-3"

    const tableTd = headers.map((header, index) => {
      const classes = `${rowColour} ${header.classes}`;
      switch (header.field) {
        case 'pipeline_key': return (
          <td key={index} className={classes}>{doc.pipeline?.name}</td>
        )
        case "download": return (
          <td key={index} className={header.classes} style={{ cursor: 'pointer' }}>
            <DirectDownload
              classNames="p-0"
              buttonType="primary"
              handleDownload={(onProgressChange) => handleDownload(doc.id, onProgressChange)}
              showLabel={true}
              showIcon={true}
            />
          </td>
        )
        case 'created': return (
          <td key={index} className={classes}>{moment.utc(doc.created).local().fromNow()}</td>
        )
        default: return (
          <td key={index} className={classes}>{doc[header.field]}</td>
        )
      }
    });

    tableTd.push(
      <td key={headers.length + 1} className="d-flex justify-content-center" onClick={() => onRemove(doc)} style={{ cursor: 'pointer' }}>
        {checkAccess('roundWorkflowUpdate') && (<Icon name="trash" className="text-danger mt-1" />)}
      </td>
    )

    return tableTd;
  }

  const headers = [
    { caption: 'Name', field: 'key', classes: 'text-left' },
    { caption: 'Pipeline', field: 'pipeline_key', classes: 'text-left' },
    { caption: 'Script Group', field: 'group_key', classes: 'text-left' },
    { caption: 'Download', field: 'download', classes: 'text-center' },
    { caption: 'Uploaded', field: 'created', classes: 'text-center' },
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );
  tableHeadTh.push(<th key={headers.length + 1}></th>);

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} >
        {renderRows(headers, row)}
      </tr>
    );
  }

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </React.Fragment>
  );
}

export default PipelineDocsLsv;
