import React from 'react';
import { connect } from "react-redux";
import {
  Nav,
  TabContent,
  TabPane,
  CustomInput
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

import { saveControls } from "../../../../core/form/lib/validateForm";
import FormTab from "../../../../core/form/components/FormTab";
import FormBase from "../../../../core/form/components/FormBase";
import ProjectGroupProfile from './ProjectGroupProfile';

import ProjectGroupStages from "./ProjectGroupStages";

import {
  fetchDocumentGroup,
  removeDocumentGroup,
  updateDocumentGroup,
  fetchDocumentGroups
} from '../../actions/document_groups';

import { fetchProject } from '../../actions/projects';
import {
  fetchRequirementItemCategories
} from '../../actions/stages';


class ProjectGroup extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      activeTab: null,
      id: null,
      isOpen: false,
      controls: {
        categories: {
          name: 'categories',
          type: 'select'
        }
      }
    })

    this.toggleTab = this.toggleTab.bind(this);

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);

    this.unlockTemplate = this.unlockTemplate.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.handleGroupDownload = this.handleGroupDownload.bind(this);
  }

  async componentDidMount() {
    const id = this.props.match.params.id;
    const project_id = this.props.match.params.project_id;

    this.props.dispatch(fetchProject(project_id));
    this.props.dispatch(fetchRequirementItemCategories())
    await this.props.dispatch(fetchDocumentGroup(id))
    this.toggleTab('2', 'stages');

    this.setState({
      id,
      project_id
    })
  }

  toggleTab(tab, tag) {

    let isOpen = false;
    switch (tag) {
      case 'profile':
        isOpen = true;
        break;
      default: break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        isOpen
      });
    }
  }

  async onSave(controls, data) {
    const {
      id,
      project_id
    } = this.state;

    data = saveControls(controls, data)
    data.id = id;

    let success = await this.props.dispatch(updateDocumentGroup(data));
    if (success) {
      this.props.dispatch(fetchDocumentGroups({project_id: project_id}))
    } this.props.history.goBack();
  }

  onCancel() {
    this.props.history.goBack();
  }

  async onRemove() {
    const { id, project_id } = this.state;
    const { currentGroup } = this.props.document_groups;

    const confirm_text = `DELETE GROUP ${currentGroup.name.toUpperCase()}`;
    let confirm_display = `Removing document group '${currentGroup.name.toUpperCase()}' PERMANENTLY including all underlying components.`
    confirm_display += `This action can not be reversed. If you are sure, please confirm this action by typing '${confirm_text}' in the input field below and press OK`;

    const confirmation = prompt(confirm_display);
    if (confirmation === confirm_text) {
      await this.props.dispatch(removeDocumentGroup(id));
      this.props.dispatch(fetchDocumentGroups({project_id: project_id}))
      this.props.history.goBack();
    } else {
      alert("Invalid confirmation, delete aborted")
    }
  }

  unlockTemplate(e) {
    this.props.dispatch({ type: "SET_UNLOCK_TEMPLATE", payload: e.target.checked })
  }

  render() {
    let {
      id,
      isOpen,
      project_id,
      controls
    } = this.state;

    const { currentProject } = this.props.projects;
    const { currentGroup } = this.props.document_groups;
    const {
      stages,
      unlockTemplate,
      itemCategories
    } = this.props.stages;

    if (itemCategories) {
      const emptyValue = 'Filter by Category';
      const options = [{ id: null, name: emptyValue }];
      itemCategories.map((category) => options.push({ id: category.id, name: category.name }))

      controls['categories'].options = options;
      if (controls['categories'].value === emptyValue) {
        controls['categories'].value = null;
      }
    }

    const iconName = 'object-group';
    return (
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>{`${currentProject.name} - Document Group (${currentGroup.name})`}</h3>
          </div>
        </div>

        <Nav tabs className="mt-2">
          <FormTab caption='Profile' tabId='1' tabTag="profile" activeTab={this.state.activeTab} toggle={this.toggleTab} />
          <FormTab caption='Stages' tabId='2' tabTag="stages" activeTab={this.state.activeTab} toggle={this.toggleTab} />
        </Nav>

        <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
          <TabPane tabId="1" className="mb-2 p-1">
            <ProjectGroupProfile
              isOpen={isOpen}
              id={id}
              project_id={project_id}
              onSave={this.onSave}
              onCancel={this.onCancel}
              onRemove={this.onRemove}
            />
          </TabPane>
          <TabPane tabId="2" className="mb-2 p-3">
            <ProjectGroupStages
              project_id={this.props.match.params.project_id}
              group_id={this.props.match.params.id}
              showFilters={true}
              history={this.props.history}
              showAddNewStage={false}
            />

            {/* <div className="d-flex justify-content-start bg-light p-2 rounded mb-1">
              <div className="ml-2 mr-2">
                <FormInput handleChange={this.handleChange} control={controls.categories} />
              </div>
              <DirectDownload
                classNames="ml-2 mr-2 p-1"
                buttonType="primary"
                caption="Download Items"
                handleDownload={(onProgressChange) => this.handleGroupDownload(onProgressChange)}
                size="sm"
                showLabel={false}
                width={185}
                showIcon={true}
              />
            </div>
            <StagesLsv
              stages={stages}
              unlockTemplate={unlockTemplate}
              project_id={project_id}
              group_id={id}
              history={this.props.history}
              templates={templates}
              checkAccess={this.checkAccess}
              handleStageDownload={(id, onProgressChange) => this.handleStageDownload(id, onProgressChange)}
              handleRequirementDownload={(id, onProgressChange) => this.handleRequirementDownload(id, onProgressChange)}
            /> */}
            {stages.length > 0 && (
              <CustomInput
                type="switch"
                id="unlockSwitch"
                name="unlockSwitch"
                label="Unlock Template"
                onChange={this.unlockTemplate}
                checked={unlockTemplate}
              />
            )} 

          </TabPane>
        </TabContent>

      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    document_groups: store.document_groups,
    stages: store.stages,
    projects: store.projects,
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(ProjectGroup);
