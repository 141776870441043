import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  property_id: {
    ...controlDefaults,
    name: 'property_id',
    type: 'hidden',
  },
  output_type: {
    ...controlDefaults,
    name: 'output_type',
    field_name: 'output_type',
    type: 'select',
    caption: 'Format',
    options: [
      { id: 'json', name: 'GeoJson' },
      { id: 'kml', name: 'Kml' },
    ],
    validationRules: {
      isRequired: true,
    },
  },
  output_srid: {
    ...controlDefaults,
    name: 'output_srid',
    field_name: 'output_srid',
    type: 'select',
    caption: 'CRS',
    options: [
      { id: 4283, name: 'GDA94' },
      { id: 4326, name: 'WGS84' },
    ],
    validationRules: {
      isRequired: true,
    },
  },
};
