import Icon from 'jsx/components/core/icons/Icon';
import { cloneDeep } from 'lodash';
import React from 'react';
import { useState } from 'react';
import GenericLsv from '../../../core/form/components/GenericLsv';
import Listview from '../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';
import { evalFormula } from '../../../core/form/lib/formula';
import { columnWidths } from '../constants/listviews';

const LabourflowLsv = (props) => {
  let {
    controls,
    emptyCaption,
    iconName,
    isPaid,
    labours,
    rows,
    setLabourModal,
    totalFormattingRules,
  } = props;

  const [renderState, setRenderState] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({});

  const openLabours = async (event, id) => {
    event.preventDefault();
    event.stopPropagation();

    collapsedSections[id] = !collapsedSections[id];
    setCollapsedSections(collapsedSections);

    setRenderState(!renderState);
  };

  const getMaxListOrder = (controls) =>
    Math.max(
      ...Object.keys(controls).map((key) =>
        controls[key]?.listviewOrder ? controls[key].listviewOrder : 0
      )
    ) + 1;

  const renderLabours = (group_id) => {
    // Replace category name with date, remove totals, and adjust column widths
    const corporateBorderClass = ' border-corporate border-top-0';
    let labourControls = cloneDeep(controls);

    const distributionsTemplate = rows[0]?.distributions;
    distributionsTemplate?.forEach(({ division }, index) => {
      const divisionWeeks = `${division.name.toString().toLowerCase()}_distribution_weeks_count`;
      const divisionValue = `${division.name.toString().toLowerCase()}_distribution_value`;

      labourControls[divisionWeeks] = {
        ...labourControls[divisionWeeks],
        classes: `${labourControls[divisionWeeks]?.classes}${corporateBorderClass}`,
        totals: null,
        group: null,
        width: `${columnWidths.small - 15}px`,
      };

      labourControls[divisionValue] = {
        ...labourControls[divisionValue],
        classes: 'border-0',
      };
    });

    labourControls = {
      ...labourControls,
      group: {
        ...labourControls.group,
        showInListview: false,
      },
      attribute: {
        ...labourControls.attribute,
        showInListview: true,
        name: 'attribute.name',
      },
      from_date: {
        ...labourControls.from_date,
        showInListview: true,
        listviewOrder: 0,
        width: `${columnWidths.small}px`,
      },
      total_weeks_count: {
        ...labourControls.total_weeks_count,
        classes: 'text-right border-right border-corporate border-top-0',
        totals: false,
        width: `${columnWidths.small}px`,
      },
      empty_column: {
        caption: '',
        classes: 'border-0',
        listviewOrder: getMaxListOrder(labourControls),
        showInListview: true,
        width: `${columnWidths.icon - columnWidths.offset}px`,
      },
    };

    // Remove total value borders
    labourControls.total_value.classes = `${labourControls.total_value.classes} border-0`;

    // Filter transactions by type
    const filtered_labours = labours?.rows?.filter(
      ({ attribute }) => attribute.parent_id === group_id
    );

    return (
      <GenericLsv
        allowTotals={false}
        controls={labourControls}
        darkTable={true}
        emptyCaption={emptyCaption}
        hideHeader={true}
        iconName={iconName}
        onClick={setLabourModal}
        rows={filtered_labours}
      />
    );
  };

  const isLabourOpen = (id) => collapsedSections[id];

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const { classes, field, formattingRules, formula, type, width } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (let key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }

      if (formula) caption = evalFormula(controls, formula, row);
      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      switch (type) {
        case 'toggle':
          return (
            <td
              key={index}
              className={classes}
              onClick={(event) => openLabours(event, row.attribute.parent_id)}
              width={width}
            >
              <small className="mt-1 mr-2">
                <Icon
                  name={collapsedSections[row.attribute.parent_id] ? 'chevron-up' : 'chevron-down'}
                  className="text-corporate"
                />
              </small>
            </td>
          );
        default:
          return (
            <td key={index} className={classes} width={width}>
              {caption}
            </td>
          );
      }
    });
    return tableTd;
  };

  const getUpperHeadTh = (upperHeaders) =>
    upperHeaders.map((upperHeader, index) => (
      <th key={index} colSpan={upperHeader.cells} className={upperHeader.classes}>
        {upperHeader.caption}
      </th>
    ));

  /**
   *  Get length of unique distributions
   *   - We can assume the first row as all default distributions are added from the API
   * */
  const firstRow = 0;
  let distributionsLength = 1; // Start at 1 to include totals column

  // Validate rows
  if (rows?.rows) rows = rows.rows;
  const haveRows = rows && rows.length > 0;
  if (haveRows) distributionsLength += rows[firstRow]?.distributions.length;

  const upperHeaders = [
    { caption: null, cells: 2, classes: 'border-bottom-0', order: 0 },
    {
      caption: 'Weeks',
      cells: distributionsLength,
      classes: 'text-center bg-lightgray border',
      order: 3,
    },
  ];

  // Insert FTE and sort by order
  let upperHeadersWithValue = [
    ...upperHeaders,
    {
      caption: 'Value',
      cells: distributionsLength,
      classes: 'text-center bg-lightgray border',
      order: 1,
    },
    { caption: null, cells: 1, classes: 'border-bottom-0', order: 2 },
  ].sort((a, b) => a.order - b.order);

  const upperHeadTh = isPaid ? getUpperHeadTh(upperHeaders) : getUpperHeadTh(upperHeadersWithValue);

  let headers = [];
  if (controls) {
    const keys = Object.keys(controls);
    headers = keys
      .map((key) => {
        let control = controls[key];
        if (control.showInListview) {
          const endPosition = -1;
          const listviewOrder = control?.listviewOrder ? control.listviewOrder : endPosition;

          return {
            caption: control.caption,
            classes: control?.classes ?? '',
            totals: control.totals,
            field: control?.fieldName ?? control.name,
            formattingRules: control?.formattingRules ?? null,
            formula: control?.formula ?? null,
            listviewOrder,
            type: control.type,
            width: control?.width ?? null,
          };
        }
        return false;
      })
      .filter(Boolean);
  }

  // Sort headers by listview order
  headers.sort((a, b) => {
    if (a.listviewOrder < b.listviewOrder) return -1;
    if (a.listviewOrder > b.listviewOrder) return 1;
    return 0;
  });

  // Push chevron header column to the end
  headers.push({
    caption: '',
    classes: 'cursor-pointer text-center',
    type: 'toggle',
    width: `${columnWidths.icon}px`,
  });

  let totals = [];
  const tableHeadTh = headers.map(({ caption, classes, totals: headerTotals }, index) => {
    if (headerTotals) totals.push(index);
    return (
      <th key={index} className={classes}>
        {caption}
      </th>
    );
  });

  let tableBodyTr = <tr></tr>;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => {
      let classes = '';
      let title = null;

      if (row?.isExcluded) {
        classes = 'bg-gray';
        title = 'Excluded from reporting.';
      }

      return (
        <React.Fragment key={index}>
          <tr key={row.id} className={classes} title={title}>
            {renderRows(headers, row)}
          </tr>
          {isLabourOpen(row.attribute.parent_id) && (
            <tr>
              <td colSpan={headers.length + 1}>{renderLabours(row.attribute.parent_id)}</td>
            </tr>
          )}
        </React.Fragment>
      );
    });
  }

  return (
    <Listview
      rows={rows || []}
      headers={headers}
      upperHeadTh={upperHeadTh}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
      totals={totals}
      totalFormattingRules={totalFormattingRules}
    />
  );
};

export default LabourflowLsv;
