import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiDaysheets } from '../../../../constants/api';

export function fetchDaysheets(params) {
  return async (dispatch) => get(dispatch, 'FETCH_DAYSHEETS', `${apiDaysheets}`, params);
}

export function fetchDaysheet(id) {
  return async (dispatch) => get(dispatch, 'FETCH_DAYSHEET', `${apiDaysheets}/${id}`);
}

export function createDaysheet(data) {
  return async (dispatch) => post(dispatch, 'CREATE_DAYSHEET', `${apiDaysheets}`, data);
}

export function updateDaysheet(data) {
  return async (dispatch) => put(dispatch, 'UPDATE_DAYSHEET', `${apiDaysheets}/${data.id}`, data);
}

export function deleteDaysheet(id) {
  return async (dispatch) => remove(dispatch, 'DELETE_DAYSHEET', `${apiDaysheets}/${id}`);
}
