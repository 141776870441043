import React from 'react';
import { Button } from 'reactstrap';
import { noop } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import OtherLivestockMenu from './OtherLivestockMenu';

const OtherLivestockToolbar = ({
  onRefresh = noop,
  setModal = noop,
  setDirectCostModal = noop,
  setLivestockAdjustmentModal = noop,
  setLivestockOpeningModal = noop,
}) => (
  <div className="d-flex justify-content-end">
    <OtherLivestockMenu
      setModal={setModal}
      setDirectCostModal={setDirectCostModal}
      setLivestockAdjustmentModal={setLivestockAdjustmentModal}
      setLivestockOpeningModal={setLivestockOpeningModal}
    />
    <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
      <Icon name="rotate" />
    </Button>
  </div>
);

export default OtherLivestockToolbar;
