import React from 'react';
import moment from 'moment';
import { isNil } from 'lodash';
import Listview from '../../../../core/form/components/Listview';

const ClientsLsv = (props) => {
  const {
    rows,
    onClick,
    // history
  } = props;

  const renderRows = (headers, row) => {
    const users = row.user_orgs.length;

    const tableTd = headers.map(({ classes, field }, index) => {
      switch (field) {
        case 'users':
          return (
            <td key={index} className={classes}>
              {users}
            </td>
          );
        case 'subscriptions':
          return (
            <td key={index} className={classes}>
              {row.org_subscriptions
                .filter(
                  ({ enabled, expires_on }) =>
                    enabled && (isNil(expires_on) || moment().isBefore(expires_on)),
                )
                .map(({ subscription_type }) => subscription_type?.name)
                .sort()
                .join(', ')}
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {row[field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: 'Name', field: 'name' },
    { caption: 'Property', field: 'property_name' },
    { caption: 'Client', field: 'client' },
    { caption: '# Users', field: 'users' },
    { caption: 'Subscriptions', field: 'subscriptions' },
    { caption: '', field: 'actions', classes: 'text-right' },
  ];

  const tableHeadTh = headers.map(({ caption, classes }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((project) => (
      <tr
        key={`client-${project.id}`}
        onClick={() => onClick(project)}
        style={{ cursor: 'pointer' }}
      >
        {renderRows(headers, project)}
      </tr>
    ));
  }

  const iconName = 'restroom';
  const emptyCaption = 'No Clients found';

  return (
    <Listview
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default ClientsLsv;
