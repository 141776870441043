import React from 'react';
import { Row, Col } from 'reactstrap';
import ReportHeaderCell from './ReportHeaderCell';

const ReportSectionHeader = (props) => {
  const { header, attributes, functions } = props;

  const renderColumns = () => {
    const cols = header.cols.sort((a, b) => a.sequence - b.sequence);
    return cols.map((col) => {
      return (
        <Col
          key={`header-column-${col.id}`}
          className="header text-right p-0 mr-1"
          sm={1}
          style={{ minWidth: 160 }}
        >
          <ReportHeaderCell cell={col} attributes={attributes} functions={functions} />
        </Col>
      );
    });
  };

  return (
    <Row className="p-0 m-0 pt-2 pr-1 report-headers">
      <Col></Col> {/* add empty column for alignment */}
      {renderColumns()}
    </Row>
  );
};

export default ReportSectionHeader;
