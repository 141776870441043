import {
    apiHashLink,
} from '../../../../constants/api.js';
import { get, put, post} from '../../../../lib/genericAction';

export function fetchHashLink(hash_key) {
  return async dispatch => await get(
    dispatch,
    'FETCH_HASHLINK',
    `${apiHashLink}/${hash_key}`
  )
}

export function updateProponentProperty(id, data) {
  return async dispatch => await put(
    dispatch,
    'UPDATE_PROPONENT_PROPERTY',
    `${apiHashLink}/propertysearch/${id}`,
    data
  )
}

export function sendHashLinkEmail(linkType, data) {
  return async dispatch => await post(
    dispatch,
    'SEND_HASHLINK_EMAIL',
    `${apiHashLink}/${linkType}`,
    data
  )
}
