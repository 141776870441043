import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

const TimekeeperSummaryMenu = (props) => {
  const {
    classNames,
    handleTimesheetsApprove
  } = props;

  return (
    <React.Fragment>
      <div className={classNames}>
        <UncontrolledButtonDropdown>
          <DropdownToggle caret color='primary' size="sm">Action </DropdownToggle>
          <DropdownMenu right>

            <DropdownItem header className="bg-light">Team Lead Actions</DropdownItem>
            <DropdownItem
              toggle={true}
              onClick={()=> handleTimesheetsApprove(true)}
            >
              Approve
            </DropdownItem>

            <DropdownItem
              toggle={true}
              onClick={()=> handleTimesheetsApprove(false)}
            >
              Reject
            </DropdownItem>

          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </React.Fragment>
  );
}

export default TimekeeperSummaryMenu;
