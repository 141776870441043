import React from 'react';
import { connect } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';

class DashboardWipstar extends React.Component {
  constructor() {
    super();

    this.state = {
      dashboard_sites: [],
      dashboard_projects: [],
      dashboard_areas: [],
      dashboard_samples: [],
    };
  }

  componentDidMount() {}

  render() {
    const { currentApp } = this.props.realm;
    const app = currentApp ? currentApp : {};

    return (
      <div className="p-5 text-center">
        <div>
          <Icon size="4x" name={'wave-pulse'} className="text-corporate"/>
        </div>
        <h1 className="mt-3">{app.title} Vitals</h1>
        <p>
          Operations Management including Work In Progress, Timesheeting, Safety, Compliance and Incident management.
        </p>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
    sites: store.sites,
    projects: store.projects,
    areas: store.areas,
    lab: store.lab,
  };
};

export default connect(mapStoreToProps)(DashboardWipstar);
