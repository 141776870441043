import { noop } from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
import FormInput from '../../core/form/components/FormInput';

const ReportsToolbar = (props) => {
  const {
    controls,
    addNew = noop,
    handleChange = noop,
    showAddNew = noop,
    toggleAddNew = noop,
  } = props;

  return (
    <div className="m-1 d-flex justify-content-end bg-light rounded p-1">
      {showAddNew && (
        <div className="d-flex">
          <FormInput handleChange={handleChange} control={controls.name} hideCaption={true} />
          <Button color="success" size="sm" onClick={addNew} className="mr-1 ml-1">
            Save
          </Button>
          <Button color="link" size="sm" onClick={toggleAddNew}>
            Cancel
          </Button>
        </div>
      )}

      {!showAddNew && (
        <Button color="success" size="sm" onClick={toggleAddNew}>
          Add Report
        </Button>
      )}
    </div>
  );
};

export default ReportsToolbar;
