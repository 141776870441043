import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import FormTab from '../../form/components/FormTab';
import FormBase from '../../form/components/FormBase';

import PipelinesPanelAreaRounds from './PipelinesPanelAreaRounds';
import PipelinesPanelPropertyRounds from './PipelinesPanelPropertyRounds';

import { setTabParams, fetchPipelineRounds } from '../actions';

import { withContainerError } from '../../errors/ContainerError';
class Pipelines extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      modifyDisabled: true,
    };
  }

  componentDidMount() {
    const tab_id = this.props.match.params.tab_id ?? this.props.pipelines.activeTab;
    if (tab_id) this.toggleTab(tab_id);
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_PIPELINE_ROUNDS' });
  }

  toggleTab(tab) {
    const { tabParams } = this.props.pipelines;
    const params = tabParams[tab];

    if (!params.limit) this.props.dispatch(setTabParams(tab, { ...params, limit: 30 }));
    else this.props.dispatch(fetchPipelineRounds(params));

    if (this.props.pipelines.activeTab !== tab) {
      this.props.dispatch({ type: 'SET_PIPELINES_ACTIVE_TAB', payload: tab });
    }
  }

  render() {
    const { activeTab } = this.props.pipelines;

    const iconName = 'leaf';

    return (
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Pipelines</h3>
          </div>
        </div>

        <Nav tabs className="mt-2">
          <FormTab caption="CEA Rounds" tabId="1" activeTab={activeTab} toggle={this.toggleTab} />
          <FormTab
            caption="Property Rounds"
            tabId="2"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="mb-2 p-1">
            <PipelinesPanelAreaRounds history={this.props.history} />
          </TabPane>

          <TabPane tabId="2" className="mb-2 p-1">
            <PipelinesPanelPropertyRounds history={this.props.history} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ areas, pipelines, realm }) => ({
  areas,
  pipelines,
  realm,
});

export default connect(mapStoreToProps)(withContainerError(Pipelines));
