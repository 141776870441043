import { download, get, post, put, remove } from '../../../../lib/genericAction';
import { apiAnalytics, apiProperties } from '../../../../constants/api.js';

export function fetchAllPropertyRounds() {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_PROPERTY_ROUNDS', `${apiProperties}/rounds`);
}

export function fetchPropertyRounds(property_id) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_PROPERTY_ROUNDS', `${apiProperties}/${property_id}/rounds`);
}

export function fetchRoundStats(property_id) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_ROUND_STATS', `${apiProperties}/${property_id}/rounds/stats`);
}

export function fetchPropertyRound(id) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_PROPERTY_ROUND', `${apiProperties}/rounds/${id}`);
}

export function updatePropertyRound(data) {
  return async (dispatch) =>
    await put(dispatch, 'UPDATE_PROPERTY_ROUND', `${apiProperties}/rounds/${data.id}`, data);
}

export function createPropertyRound(property_round) {
  return async (dispatch) =>
    await post(
      dispatch,
      'CREATE_PROPERTY_ROUND',
      `${apiProperties}/${property_round.property_id}/rounds`,
      property_round
    );
}

export function removePropertyRound(id) {
  return async (dispatch) =>
    await remove(dispatch, 'REMOVE_PROPERTY', `${apiProperties}/rounds/${id}`);
}

export function fetchPropertyRoundBatches(id) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_PROPERTY_ROUND_BATCHES', `${apiProperties}/rounds/${id}/batches`);
}

export function updatePropertyRoundBatch(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      'UPDATE_PROPERTY_ROUND_BATCH',
      `${apiProperties}/rounds/batches/${data.id}`,
      data
    );
}

export function downloadPropertyRoundComparison(onProgressChange, params) {
  return async (dispatch) =>
    await download(
      dispatch,
      'DOWNLOAD_PROPERTY_ROUND_COMPARISON',
      `${apiAnalytics}/properties/rounds/${params.baseline_id}/compare/download`,
      onProgressChange,
      params
    );
}

export function downloadPropertyRoundReportingPack(id, onProgressChange) {
  return async (dispatch) =>
    await download(
      dispatch,
      'DOWNLOAD_PROPERTY_ROUND_REPORTING_PACK',
      `${apiAnalytics}/properties/rounds/${id}/reporting`,
      onProgressChange
    );
}

export function downloadPropertyRoundLocationsPack(id, onProgressChange) {
  return async (dispatch) =>
    await download(
      dispatch,
      'DOWNLOAD_PROPERTY_ROUND_LOCATIONS_PACK',
      `${apiProperties}/rounds/${id}/locations/download`,
      onProgressChange
    );
}

export function downloadPropertyRoundSites(id, onProgressChange) {
  return async (dispatch) =>
    await download(
      dispatch,
      'DOWNLOAD_PROPERTY_ROUND_SITES',
      `${apiProperties}/rounds/${id}/sites/download`,
      onProgressChange
    );
}

export function downloadPropertyRoundSelectionScans(id, onProgressChange) {
  return async (dispatch) =>
    await download(
      dispatch,
      'DOWNLOAD_PROPERTY_ROUND_SITES',
      `${apiProperties}/rounds/${id}/selections/download`,
      onProgressChange
    );
}

export function generateSites(id) {
  return async (dispatch) =>
    await post(
      dispatch,
      'GENERATE_PROPERTY_ROUND_SITES',
      `${apiProperties}/rounds/${id}/sites/generate`
    );
}
