import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  type_id: {
    ...controlDefaults,
    name: 'type_id',
    type: 'select',
    fieldName: 'assumed_animal_class.name',
    caption: 'Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  division_id: {
    ...controlDefaults,
    name: 'division_id',
    type: 'select',
    fieldName: 'assumed_animal_class.type.parent.name',
    includeEmptyOption: false,
    caption: 'Division',
    showInEditor: false,
    showInListview: true,
  },
  date: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'date',
    name: 'date',
    type: 'date',
    caption: 'Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  value: {
    ...controlDefaults,
    name: 'value',
    fieldName: 'value',
    type: 'number',
    caption: 'Value ($/kg)',
    showInEditor: true,
    showInListview: true,
  },
};
