/* eslint-disable no-alert */
import { isEmpty, isEqual, sortBy } from 'lodash';
import moment from 'moment';

import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import Icon from 'jsx/components/core/icons/Icon';
import { connect } from 'react-redux';
import { Button, Nav, TabContent, TabPane } from 'reactstrap';

import FormBase from '../../../core/form/components/FormBase';

import FormTab from '../../../core/form/components/FormTab';
import Report from '../components/analytics/Report';
import WidgetGroups from '../components/WidgetGroups';
import MetricModal from './MetricModal';

import {
  fetchReports,
  clearCache as clearCacheAction,
  exportReports,
  exportEnterpriseAnalysis,
} from '../actions/reports';
import ReportsFilter from '../components/analytics/ReportsFilter';
import ReportsToolbar from '../components/analytics/ReportsToolbar';
import { fetchDistinctAssociatedDivisions, fetchEnterpriseRanges } from '../actions/enterprises';
import ReportMatrix from '../components/analytics/ReportMatrix';

class EnterpriseDashboard extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: null,
      errorMessage: null,
      filterOpen: false,
      groups: [],
      currentReloadValues: {},
      isMetricModalOpen: false,
      modalMetric: {},
      reportId: {},
      mandatoryFilters: {
        divisions: [],
        enterprises: [],
        enterpriseTypes: [],
      },
    };
  }

  async componentDidMount() {
    // Ensure we have a date range.
    const { selectedRanges } = this.props.enterprises;
    if (!selectedRanges.from_date || !selectedRanges.to_date) {
      await this.props.dispatch(fetchEnterpriseRanges());
    }

    this.props.dispatch(fetchDistinctAssociatedDivisions());
    this.updatePreviousFilters();
  }

  componentDidUpdate() {
    const { isActive, forceRefresh, setRefresh } = this.props;

    if (isActive && forceRefresh && setRefresh) {
      if (!this.state.activeTab) this.initialiseActiveTab();

      this.onRefresh();
      if (setRefresh) setRefresh(false);
    }

    const hasFiltersChanged = this.haveFiltersChanged();
    if (isActive && !isEqual(hasFiltersChanged, this.props.reports.hasFiltersChanged))
      this.props.dispatch({ type: 'SET_HAS_REPORT_FILTERS_CHANGED', payload: hasFiltersChanged });
  }

  initialiseActiveTab = async () => {
    const { activeTab } = this.state;
    if (activeTab) return;

    const reports = await this.props.dispatch(
      fetchReports({ enabled: true, includePublished: true }, true),
    );

    const initialTab = reports.length > 0 ? reports[0].id : 'matrix';
    this.setState({ activeTab: initialTab });
  };

  refreshCurrentReloadValues = () => {
    const { currentReloadValues } = this.state;
    const keys = Object.keys(currentReloadValues);
    if (keys.length > 0) {
      keys.forEach((key) => this.updateReloadValue(key));
    }
  };

  applyFilters = async () => {
    await this.updatePreviousFilters();
    this.refreshCurrentReloadValues();
  };

  getPreviousFiltersFromProps = () => {
    const { from_date, to_date } = this.props.enterprises.selectedRanges;
    const { isLivestockAssumptionsToggled } = this.props.livestocks;
    const { divisions: division_ids = [], enterprises: enterprise_ids = [] } =
      this.props.reports.filters;
    const {
      selectedProperties: property_ids = [],
      local_timezone,
      includeOrganisationPropertyOption,
    } = this.props.enterprises;

    return {
      from_date,
      to_date,
      local_timezone,
      division_ids,
      property_ids,
      enterprise_ids,
      includeOrganisationPropertyOption,
      isLivestockAssumptionsToggled,
    };
  };

  haveFiltersChanged = () => {
    const {
      selectedProperties = [],
      selectedRanges,
      includeOrganisationPropertyOption,
    } = this.props.enterprises;
    const { isLivestockAssumptionsToggled } = this.props.livestocks;
    const { previousFilters, filters: reportFilters } = this.props.reports;

    if (Object.keys(previousFilters).length === 0) return false;

    const { divisions = [], enterprises = [] } = reportFilters;
    const { from_date, to_date } = selectedRanges;
    const filters = [
      { previous: previousFilters.from_date, current: from_date },
      { previous: previousFilters.to_date, current: to_date },
      { previous: previousFilters.division_ids, current: divisions },
      { previous: previousFilters.enterprise_ids, current: enterprises },
      { previous: previousFilters.property_ids, current: selectedProperties },
      {
        previous: previousFilters.isLivestockAssumptionsToggled,
        current: isLivestockAssumptionsToggled,
      },
      {
        previous: previousFilters.includeOrganisationPropertyOption,
        current: includeOrganisationPropertyOption,
      },
    ];

    return filters.some(({ previous, current }) => !isEqual(previous, current));
  };

  resetReportFilters = async () =>
    this.props.dispatch({
      type: 'SET_REPORT_FILTERS',
      payload: { divisions: [], enterprises: [], enterpriseTypes: [] },
    });

  addMandatoryFilters = async (reportId, resetAppliedFilters) => {
    if (resetAppliedFilters) {
      await this.resetReportFilters();
      await this.applyFilters();
    }

    const { reports } = this.props.reports;
    const report = reports.find((targetReport) => targetReport.id === reportId);
    if (!report || !report?.published_version?.filter_attributes) return;

    // Check if we need to apply mandatory filters from report version
    const { filter_attributes } = report.published_version;

    const { divisions: divisionAttributes } = this.props.attributes;
    const { filters } = this.props.reports;

    // Handle mandatory divisions
    const divisionFilterIds = divisionAttributes
      .map(({ id: divisionId, name }) => {
        // Find the division in filter_attributes using mandatory division prefix
        const filterAttributesDivisionKey = `mandatoryDivision${name}`;
        return filter_attributes[filterAttributesDivisionKey] ? divisionId : false;
      })
      .filter(Boolean);
    if (isEmpty(divisionFilterIds)) return;

    const updatedFilters = {
      ...filters,
      divisions: divisionFilterIds,
    };
    // Set and apply filters
    await this.props.dispatch({ type: 'SET_REPORT_FILTERS', payload: updatedFilters });
    await this.applyFilters();

    // Reset hasFiltersChanged prop
    this.props.dispatch({ type: 'SET_HAS_REPORT_FILTERS_CHANGED', payload: false });

    // Track mandatory filters
    const { mandatoryFilters } = this.state;

    // Only track the mandatory filter ids, not any existing filters
    this.setState({ mandatoryFilters: { ...mandatoryFilters, divisions: divisionFilterIds } });
  };

  loadReport = async (id) => {
    const { reports } = this.props.reports;
    const report = reports.find((targetReport) => targetReport.id === id);
    if (!report) return;

    this.updateReloadValue(id);
  };

  onEditMetric = (currentMetric) => {
    this.setState({ currentMetric });
    this.setMetricModal(true);
  };

  onRefresh = async () => {
    const { currentReloadValues } = this.state;
    const { activeTab } = this.state;

    const reports = await this.props.dispatch(
      fetchReports({ enabled: true, includePublished: true }, true),
    );

    if (reports && reports.length > 0) {
      reports.forEach(({ id }) => {
        if (!currentReloadValues[id]) this.updateReloadValue(id);
      });
    }

    if (activeTab) this.toggleTab(activeTab);
  };

  /**
   *  Leaving this unused function in the container to demonstrate WidgetDivisions
   *  which is not yet supported in Report but a nice layout for viewing Divisions.
   */
  renderGroups = () => {
    const { groups } = this.state;
    return <WidgetGroups groups={groups} onEditMetric={this.onEditMetric} />;
  };

  renderReportNavs = () => {
    const { reports } = this.props.reports;
    const { activeTab } = this.state;

    return reports.map(({ id, name }, index) => (
      <FormTab
        key={index}
        caption={name}
        tabId={id}
        activeTab={activeTab}
        toggle={this.toggleTab}
      />
    ));
  };

  clearCache = async () =>
    this.props.dispatch(
      clearCacheAction({ org_id: this.props.profile.currentUser.user_orgs[0].org.id }),
    );

  clearAndReload = async (id) => {
    await this.clearCache();
    this.reloadReport(id);
  };

  renderReportTabs = () => {
    const { activeTab, currentReloadValues } = this.state;
    const { reports, hasFiltersChanged } = this.props.reports;

    // Set report functions
    const functions = {
      setMetricModal: this.setMetricModal,
    };

    return reports.map(({ id, published_version }) => (
      <TabPane key={`tabPane-${id}`} tabId={id} className="p-0 h-100">
        <div className="d-flex justify-content-end bg-light border border-lightgray rounded p-1 mt-1">
          <div className="align-items-center mr-3">
            <Icon
              name="info-circle"
              className={hasFiltersChanged ? 'text-warning mr-1' : 'text-primary mr-1'}
            />
            <small className="mr-2">
              If any data has been added or edited, click <u>Recalculate to update the metrics</u>
            </small>
            <small>Report Version: {published_version.version}</small>
          </div>
          <Button
            size="sm"
            className="text-white mr-2"
            color="primary"
            disabled={hasFiltersChanged}
            onClick={() => this.clearAndReload(id)}
          >
            Recalculate
          </Button>
          <Button
            size="sm"
            color={hasFiltersChanged ? 'danger' : 'primary'}
            disabled={!hasFiltersChanged}
            onClick={() => this.reloadReport(id)}
          >
            Apply Filters
          </Button>
        </div>
        <Report
          data={published_version}
          functions={functions}
          currentReloadValue={activeTab === id ? currentReloadValues[id] ?? null : null}
        />
      </TabPane>
    ));
  };

  setMetricModal = (isMetricModalOpen, modalMetric, reportId) => {
    this.setState({
      isMetricModalOpen,
      modalMetric,
      reportId,
    });
  };

  resetMandatoryFilters = async () =>
    this.setState({ mandatoryFilters: { divisions: [], enterprises: [], enterpriseTypes: [] } });

  checkClearAppliedFilters = () => {
    // Check if a mandatory filter has been previously applied
    const { mandatoryFilters } = this.state;
    return Object.values(mandatoryFilters).some((filter) => !isEmpty(filter));
  };

  toggleTab = async (tab, tag = null) => {
    const shouldClearAppliedFilters = this.checkClearAppliedFilters();
    await this.resetMandatoryFilters();
    await this.addMandatoryFilters(tab, shouldClearAppliedFilters);

    if (tag) this.loadReport(tag);

    this.setState({ activeTab: tab });
  };

  toggleFilterBox = () => {
    const { filterOpen } = this.state;
    this.setState({ filterOpen: !filterOpen });
  };

  reloadReport = (reportId, applyFilters = true) => {
    if (applyFilters) this.applyFilters();
    this.updateReloadValue(reportId);
  };

  updateReloadValue = (id) => {
    const { currentReloadValues } = this.state;

    currentReloadValues[id] = moment().valueOf();

    this.setState(currentReloadValues);
  };

  updatePreviousFilters = async () =>
    this.props.dispatch({
      type: 'SET_PREVIOUS_REPORT_FILTERS',
      payload: this.getPreviousFiltersFromProps(),
    });

  onExportReport = async (reportId) => {
    const { filters, hasFiltersChanged, previousFilters } = this.props.reports;
    const {
      selectedProperties: property_ids = [],
      includeOrganisationPropertyOption,
      selectedRanges,
      local_timezone,
    } = this.props.enterprises;
    const { divisions: division_ids = [], enterprises: enterprise_ids = [] } = filters;

    const { from_date, to_date } = selectedRanges;
    const { isLivestockAssumptionsToggled } = this.props.livestocks;

    // Send request to export reports
    const params = hasFiltersChanged
      ? previousFilters
      : {
          from_date,
          to_date,
          local_timezone,
          property_ids,
          includeOrganisationPropertyOption,
          division_ids,
          enterprise_ids,
          isLivestockAssumptionsToggled,
        };

    const response = await this.props.dispatch(
      exportReports({ ...params, report_ids: [reportId] }),
    );
    if (!response?.data) return;

    // Handle the response containing the PDF file
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = window.URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');
  };

  onExportEnterpriseAnalysis = async () => {
    const { matrix } = this.props.reports;
    const { local_timezone, selectedRanges } = this.props.enterprises;
    const { isLivestockAssumptionsToggled } = this.props.livestocks;
    const { from_date, to_date } = selectedRanges;

    // Strip out irrelevant information to keep query params size down.
    const strippedMetrics = matrix.metrics.map(
      ({ id, name, matrix_attributes, unit_attributes }) => ({
        id,
        name,
        matrix_attributes,
        unit_attributes,
      }),
    );
    const strippedMatrix = { ...matrix, metrics: strippedMetrics };
    const queryParams = {
      ...strippedMatrix,
      from_date,
      to_date,
      local_timezone,
      isLivestockAssumptionsToggled,
    };

    const response = await this.props.dispatch(exportEnterpriseAnalysis(queryParams));
    if (!response?.data) return;

    // Handle the response containing the PDF file
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = window.URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');
  };

  render() {
    const {
      activeTab,
      currentReloadValues,
      filterOpen,
      mandatoryFilters,
      isMetricModalOpen,
      modalMetric,
      reportId,
    } = this.state;
    const { distinctAssociatedDivisions, enterprises } = this.props.enterprises;
    const { hasFiltersChanged, reports } = this.props.reports;
    const emptyCaption = 'No Published Analytics/Reports found';
    const iconName = 'file-chart-column';

    const enterprisesForReportsFilter =
      sortBy(
        enterprises?.rows?.map(({ division_id, id, name, division, type }) => ({
          colour: 'blue',
          division_id,
          id,
          name,
          division,
          type: { ...type, colour: 'corporate' },
        })),
        ['name'],
      ) ?? [];

    return (
      <div className="p-0 h-100">
        <ReportsToolbar
          hasFiltersChanged={hasFiltersChanged}
          onFilterClick={this.toggleFilterBox}
          isFilterOpen={filterOpen}
          displayFilterButton={activeTab !== 'matrix'}
          onExportClick={
            activeTab === 'matrix' ? this.onExportEnterpriseAnalysis : this.onExportReport
          }
          selectedReportId={activeTab}
        />
        <ReportsFilter
          filterOpen={filterOpen}
          divisions={distinctAssociatedDivisions}
          enterprises={enterprisesForReportsFilter}
          mandatoryFilters={mandatoryFilters}
          refresh={this.onRefresh}
        />
        {reports.length > 0 && (
          <>
            <Nav tabs className="mt-2">
              {this.renderReportNavs()}
              <FormTab
                caption="Enterprise Analysis"
                tabId="matrix"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
            </Nav>
            <TabContent activeTab={activeTab} className="h-100">
              {this.renderReportTabs()}
              <TabPane tabId="matrix" className="p-0 h-100">
                <ReportMatrix
                  activeTab={activeTab}
                  hasFiltersChanged={hasFiltersChanged}
                  updatePreviousFilters={this.updatePreviousFilters}
                  toggleTab={this.toggleTab}
                  clearCache={this.clearCache}
                  currentReloadValue={
                    activeTab === 'matrix' ? currentReloadValues.matrix ?? null : null
                  }
                  refreshCurrentReloadValues={this.refreshCurrentReloadValues}
                  reloadMatrix={this.reloadReport}
                />
              </TabPane>
            </TabContent>
            <MetricModal
              report_id={reportId}
              setModal={this.setMetricModal}
              id={modalMetric?.id}
              metric={modalMetric}
              isOpen={isMetricModalOpen}
              isNew={false}
            />
          </>
        )}

        {reports.length === 0 && (
          <div className="p-5 text-center">
            <Icon size="3x" name={iconName} className="text-corporate" />
            <div className="mt-3">{emptyCaption}</div>
          </div>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ attributes, enterprises, profile, realm, reports, livestocks }) => ({
  attributes,
  enterprises,
  profile,
  realm,
  reports,
  livestocks,
});

export default connect(mapStoreToProps)(withContainerError(EnterpriseDashboard));
