import { controlDefaults } from "../../../core/form/lib/validateForm";

export const areaRoundControls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    placeholder: 'Round Name',
    name: 'name',
    fieldName: 'name',
    type: 'text',
    caption: 'Round Name',
    validationRules: {
      minLength: 2,
      isRequired: true
    },
  },
  project_period_id: {
    ...controlDefaults,
    placeholder: 'Project Period',
    fieldName: 'project_period_id',
    name: 'project_period_id',
    type: 'select',
    caption: 'Project Period',
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  },
  property_round_id: {
    ...controlDefaults,
    placeholder: 'Property Round',
    fieldName: 'property_round_id',
    name: 'property_round_id',
    type: 'select',
    caption: 'Property Round',
    validationRules: {}
  },
  start_date: {
    ...controlDefaults,
    placeholder: 'Start Date',
    name: 'start_date',
    fieldName: 'start_date',
    type: 'date',
    caption: 'Start Date',
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  },
  end_date: {
    ...controlDefaults,
    placeholder: 'End Date',
    name: 'end_date',
    fieldName: 'end_date',
    type: 'date',
    caption: 'End Date',
    validationRules: {
      minLength: 6,
      isRequired: false
    },
  },
  sequence: {
    ...controlDefaults,
    name: 'sequence',
    fieldName: 'sequence',
    type: 'text',
    caption: 'Round Sequence',
    description: 'Sequence/order of rounds in a reporting period. Leave blank if not active round in soil calcs.',
    validationRules: {
      isRequired: true
    },
  },
  soc_tph: {
    ...controlDefaults,
    name: 'soc_tph',
    type: 'text',
    caption: 'SOC t/ha',
    validationRules: {
      minLength: 1
    },
  },
  accu_relinquished: {
    ...controlDefaults,
    name: 'accu_relinquished',
    type: 'text',
    caption: 'ACCU Generated',
    description: 'ACCU generated after discounts',
    validationRules: {}
  },
  discount: {
    ...controlDefaults,
    name: 'discount',
    type: 'text',
    caption: 'Discounts',
    description: 'Discounts for Permanence and Risk of Reversal',
    validationRules: {}
  },
  site_script_id: {
    ...controlDefaults,
    placeholder: 'Site Generation Method',
    fieldName: 'site_script_id',
    name: 'site_script_id',
    type: 'select',
    caption: 'Site Generation Method',
    disabled: true,
    validationRules: {}
  },
}