import React from 'react';
import { Row, Col, Button, Input, Nav, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import { HalfCircleSpinner } from 'react-epic-spinners';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from 'mapbox-gl-geocoder';

import PropertySearchMigrateModal from './PropertySearchMigrateModal';
import PropertySearchLotLsvBeta from '../components/properties/PropertySearchLotLsvBeta';
import PropertySearchLibraryModal from './PropertySearchLibraryModal';
import PropertySearchSources from './PropertySearchSources';
import PropertySearchLibrarySources from '../components/properties/PropertySearchLibrarySources';

import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import FormTab from '../../../core/form/components/FormTab';

import {
  removeSource,
  splitUrl,
  buildGetFeatureInfoParams,
  loadSource,
  getSelectedLots,
  toggleSelectedLot,
  setSelectedLots,
  formatSelectedLot,
} from '../lib/propertySearchUtils';

import {
  zoomToBounds,
  getDefaultLayer,
  buildFeatureCollection,
  buildFeature,
  getCollectionBoundary,
} from '../lib/mapster.js';

import { accessToken, geoserverUrl } from '../../../../constants/map.js';

import {
  fetchLotPlanListBeta,
  fetchLibrarySources,
  fetchWMSObject,
  convertGeometry,
  fetchLotPlan,
} from '../actions/properties';

import { fetchPropertyLots } from '../actions/property_lots';
import PropertySearchAttributes from '../components/properties/PropertySearchAttributes';
import { isEmpty } from 'lodash';

mapboxgl.accessToken = accessToken;

class PropertySearch extends React.Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
    this.geocoderRef = React.createRef();
    this.lotplanLabel = React.createRef();

    this.state = {
      lng: 134.1993,
      lat: -24.8566,
      zoom: 4,
      map: null,
      marker: null,
      mapStylePath: 'mapbox://styles',
      defaultMapStyle: 'mapbox/satellite-v9',
      propertySource: 'property_selection',
      properties: [],
      opacity: 80,
      mapDataLoading: false,
      searchCenter: [],
      selected: [],
      geocoder: null,
      geoserver_auth: null,
      isOpen: false,
      isLibraryOpen: false,
      searchLotplans: [],
      searchLotPlansLoading: false,
      lotplanResult: '',
      lotplanValue: '',
      librarySources: [],
      selectedSources: [],
      selectedAttributes: [],
    };

    this.promptClearSelected = this.promptClearSelected.bind(this);
    this.clearSelected = this.clearSelected.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.clearAttributes = this.clearAttributes.bind(this);
    this.addAttributes = this.addAttributes.bind(this);
    this.selectFeature = this.selectFeature.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setMarker = this.setMarker.bind(this);
    this.clearMarker = this.clearMarker.bind(this);
    this.zoomToLot = this.zoomToLot.bind(this);
    this.addLotplan = this.addLotplan.bind(this);
    this.searchLotplan = this.searchLotplan.bind(this);
    this.onLotSearchKeyup = this.onLotSearchKeyup.bind(this);
    this.setLibraryModal = this.setLibraryModal.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.addLibrarySource = this.addLibrarySource.bind(this);
    this.removeSelectedSource = this.removeSelectedSource.bind(this);
    this.loadMap = this.loadMap.bind(this);
    this.loadProperties = this.loadProperties.bind(this);
    this.loadPropertySource = this.loadPropertySource.bind(this);
    this.loadProjectProperty = this.loadProjectProperty.bind(this);
    this.moveLayers = this.moveLayers.bind(this);
    this.reloadSources = this.reloadSources.bind(this);
  }

  componentDidMount() {
    this.setState({
      selected: getSelectedLots(),
    });
  }

  async componentDidUpdate() {
    const { geoserver_auth } = this.state;

    const { geoserver_authorization } = this.props.profile.currentUser;

    if (geoserver_authorization && geoserver_auth !== geoserver_authorization) {
      this.loadMap();

      this.setState({
        geoserver_auth: geoserver_authorization,
      });
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_SELECTED_SOURCES' });
  }

  async loadMap() {
    const { mapStylePath, defaultMapStyle, lat, lng, zoom } = this.state;

    const { geoserver_authorization } = this.props.profile.currentUser;

    const map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: `${mapStylePath}/${defaultMapStyle}`,
      center: [lng, lat],
      zoom: zoom,
      hash: true,
      transformRequest: (url) => {
        if (url.startsWith(geoserverUrl)) {
          return {
            url,
            headers: { Authorization: `Basic ${geoserver_authorization}` },
            credentials: 'include', // Include cookies for cross-origin requests
          };
        }
      },
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });
    this.geocoderRef.current.appendChild(geocoder.onAdd(map));

    geocoder.on('result', async (e) => {
      if (e.result.center) {
        this.setState({
          searchCenter: e.result.center,
        });
      }
    });

    // Add Geocoder to map and state
    this.setState({ geocoder });

    const nav = new mapboxgl.NavigationControl({
      visualizePitch: true,
      showZoom: true,
      showCompass: true,
    });
    map.addControl(nav, 'bottom-right');

    map.on('click', async (e) => this.selectFeature(e));
    map.on('load', () => {
      this.loadDefaultSources();
      this.loadPropertySource();
      this.loadProperties(true);
    });

    map.on('style.load', () => {
      if (this.state.map !== null) this.reloadSources();
    });

    map.on('render', () => {
      if (!this.state.mapDataLoading)
        this.setState({
          mapDataLoading: true,
        });
    });

    map.on('idle', () => {
      this.setState({
        mapDataLoading: false,
      });
    });

    this.setState({
      map,
    });

    await this.props.dispatch(fetchLibrarySources());
  }

  setModal(isOpen, clearSelected = false) {
    if (clearSelected) {
      this.clearSelected();
    }

    this.setState({ isOpen });
  }

  setMarker(coordinates) {
    const { map } = this.state;
    const marker = new mapboxgl.Marker().setLngLat(coordinates).addTo(map);

    this.setState({
      marker,
    });
  }

  clearMarker() {
    const { marker } = this.state;
    if (marker) marker.remove();
    this.setState({
      marker: null,
    });
  }

  setLibraryModal(isLibraryOpen) {
    this.setState({ isLibraryOpen });
  }

  setSelected(property) {
    let { selected } = this.state;

    property = formatSelectedLot(property);
    selected = toggleSelectedLot(property);

    // Select Search tab
    this.props.dispatch({ type: 'SET_PROPERTY_SEARCH_ACTIVE_TAB', payload: '1' });

    // // Set state
    this.setState({ selected });
    this.loadProperties();
  }

  async promptClearSelected() {
    const confirm_text = `DELETE NOW`;
    let confirm_display = 'Are you sure you want to clear all selected lots?';
    confirm_display += `This action can not be reversed. If you are sure, please confirm this action by typing '${confirm_text}' in the input field below and press OK`;

    const confirmation = prompt(confirm_display);

    if (confirmation === confirm_text) {
      await this.clearSelected();
    } else {
      alert('Invalid confirmation, delete aborted');
    }
  }

  async clearSelected() {
    // Clear Selected
    await this.setState({
      selected: setSelectedLots(null),
    });
    this.loadProperties();
  }

  addAttributes(source, features) {
    const { selectedAttributes } = this.state;

    if (!features) return;

    const idx = selectedAttributes.findIndex((selected) => selected.id === source.id);
    if (idx > -1) selectedAttributes.slice(idx, 1);

    selectedAttributes.push({
      id: source.id,
      source,
      features,
    });

    this.setState({
      selectedAttributes,
    });
  }

  clearAttributes() {
    this.setState({
      selectedAttributes: [],
    });
  }

  getTotalHa() {
    let ha = 0;
    this.state.selected.map((property) => {
      ha = ha + parseFloat(property.properties?.ha);
      return true;
    });
    return ha.toFixed(2);
  }

  removeSelectedSource(selectedSource) {
    const { librarySources, selectedSources } = this.props.properties;

    // Add source back into library
    librarySources.push(selectedSource);
    this.props.dispatch({ type: 'SET_LIBRARY_SOURCES', payload: librarySources });

    // Remove layers and source from map
    removeSource(this.state.map, selectedSource);

    // Remove source from selected
    const idx = selectedSources.findIndex((source) => source.id === selectedSource.id);
    selectedSources.splice(idx, 1);
    this.props.dispatch({ type: 'SET_SELECTED_SOURCES', payload: selectedSources });
  }

  addLibrarySource(name) {
    const { librarySources, selectedSources } = this.props.properties;

    const librarySource = librarySources.find((source) => source.name === name);
    librarySource.sequence = selectedSources.length * 2;

    selectedSources.push(librarySource);

    this.props.dispatch({ type: 'SET_SELECTED_SOURCES', payload: selectedSources });
    this.props.dispatch({ type: 'SET_PROPERTY_SEARCH_ACTIVE_TAB', payload: '2' });

    loadSource(this.state.map, librarySource);

    this.moveLayers();

    const idx = librarySources.findIndex((source) => source.name === librarySource.name);
    librarySources.splice(idx, 1);
    this.props.dispatch({ type: 'SET_LIBRARY_SOURCES', payload: librarySources });
  }

  async loadProjectProperty() {
    // Property ids if passed from project property page
    const { property_id } = this.props.match.params;
    if (property_id) {
      // Fetch lots from project property
      const results = await this.props.dispatch(fetchPropertyLots(property_id));
      results.map((row) => {
        this.setSelected({
          ...row,
          geometry: row.geom,
        });
        return false;
      });
    }
  }

  loadPropertySource() {
    const { map, propertySource } = this.state;

    // // Remove property source if already set
    if (map.getSource(propertySource)) {
      map.removeSource(propertySource);
    }

    // Load Blank Property Source
    map.addSource(propertySource, {
      type: 'geojson',
      data: { type: 'Feature' },
    });

    // Add lot polygons
    const boundary_layer = getDefaultLayer(propertySource, 'MultiPolygon');
    map.addLayer(boundary_layer);

    const label_layer = getDefaultLayer(propertySource, 'PolyLabel');
    map.addLayer(label_layer);
  }

  async loadProperties(zoomExtent = false) {
    const { selected, opacity, propertySource, map } = this.state;

    if (!map) return;

    map.getSource(propertySource).setData(buildFeatureCollection());
    if (selected.length > 0) {
      const featureCollection = buildFeatureCollection();

      selected.forEach((property) => {
        const colour = 'yellow';
        const outline = '#000000';
        const fillOpacity = opacity / 100;

        const feature = buildFeature(property.geometry, {
          id: property.id,
          lotplan: property.properties.lotplan,
          ha: property.ha,
          geom: property.geometry,
          colour: colour,
          outline: outline,
          fillOpacity: fillOpacity,
        });

        featureCollection.features.push(feature);
      });

      // Set properties
      map.getSource(propertySource).setData(featureCollection);

      // Optionally zoom to extent of radius
      if (zoomExtent) {
        zoomToBounds(map, getCollectionBoundary(featureCollection));
      }
    }
  }

  async loadDefaultSources() {
    const { selectedSources } = this.props.properties;
    let { librarySources } = this.props.properties;

    const { map } = this.state;

    librarySources = librarySources
      .map((source) => {
        if (source.url.startsWith(geoserverUrl))
          selectedSources.push({
            ...source,
            sequence: selectedSources.length * 2,
          });
        else return source;
        return false;
      })
      .filter(Boolean);

    for (let i = selectedSources.length - 1; i >= 0; i--) {
      const source = selectedSources[i];
      loadSource(map, source);
    }

    this.props.dispatch({ type: 'SET_SELECTED_SOURCES', payload: selectedSources });
    this.props.dispatch({ type: 'SET_LIBRARY_SOURCES', payload: librarySources });
  }

  reloadSources() {
    const { selectedSources } = this.props.properties;

    const { map } = this.state;

    selectedSources.map((source) => {
      removeSource(map, source);
      return false;
    });

    selectedSources.map((source) => {
      loadSource(map, source);
      return false;
    });
  }

  moveLayers() {
    const { map } = this.state;

    const { selectedSources } = this.props.properties;

    // Find all current layers
    let { layers } = map.getStyle();
    layers = layers
      .map((layer, index) => {
        // Build sequence of layer based on current position and source sequence to retain layer position within a source group.
        const source = selectedSources.find((selected) => selected.id === layer.source);
        if (!source) return false;
        return {
          ...layer,
          sequence: source.sequence * 10 + index,
        };
      })
      .filter(Boolean);

    // Sort layers on ascending sequence order
    layers.sort((a, b) => a.sequence - b.sequence);
    for (let i = 0; i < layers.length - 1; i++) {
      // Move layer to intended position
      const beforeLayer = layers[i + 1];
      const layer = layers[i];
      map.moveLayer(beforeLayer.id, layer.id);
    }
  }

  async selectFeature(event) {
    const { map } = this.state;
    const { geoserver_authorization } = this.props.profile.currentUser;
    const { activePropertySearchTab, selectedSources } = this.props.properties;

    if (!event.lngLat) return;

    this.clearAttributes();
    this.clearMarker();

    if (activePropertySearchTab === '4') this.setMarker(event.lngLat);

    const { propertySource } = this.state;

    map.moveLayer(`${propertySource}-boundary`);
    map.moveLayer(`${propertySource}-labels`);

    const querySources = selectedSources.filter(
      (source) => source.is_queryable && source.service === 'wms',
    );
    querySources.map((source) => {
      const mapSource = map.getSource(source.id);
      if (!mapSource) return false;

      const urlSource = splitUrl(mapSource.tiles[0]);
      const { url } = source;
      const params = {
        ...urlSource.params,
        layers: source.layer,
      };

      const featureInfoParams = buildGetFeatureInfoParams(params, event.lngLat);
      this.props
        .dispatch(
          fetchWMSObject(
            url,
            {
              Authorization: `Basic ${geoserver_authorization}`,
            },
            featureInfoParams,
          ),
        )
        .then((response) => {
          if (!response || response?.features?.length === 0) return false;
          switch (parseInt(activePropertySearchTab)) {
            case 1:
            default:
              const clickedFeature = response.features[0];
              if (featureInfoParams.crs !== `EPSG:4326` && clickedFeature.geometry) {
                const epsg = featureInfoParams.crs.split(':')[1];
                this.props
                  .dispatch(convertGeometry({ geom: clickedFeature.geometry, epsg }))
                  .then((converted) => {
                    clickedFeature.geometry = converted.geom;
                    clickedFeature.properties = {
                      ...clickedFeature.properties,
                      ha: converted.ha,
                    };
                    this.setSelected(clickedFeature);
                  });
              }
              break;
            case 4:
              this.addAttributes(source, response.features);
              break;
          }
        });
      return true;
    });
  }

  async onLotSearchKeyup(event) {
    const { value } = event.target;
    let updateState = { lotplanValue: value, lotplanResult: '' };

    if (value.length <= 3) updateState = { ...updateState, searchLotplans: [] };

    this.setState(updateState);

    if (value.length >= 3) {
      this.setState({ searchLotPlansLoading: true });

      this.props.dispatch(fetchLotPlanListBeta(value)).then((searchLotplans) => {
        if (value === this.state.lotplanValue) {
          if (searchLotplans.length === 0)
            searchLotplans.push({ id: '-', lotplan: 'No lotplans found.', locality: '' });

          this.setState({
            searchLotplans,
            searchLotPlansLoading: false,
          });
        }
      });
    }
  }

  addLotplan(property) {
    if (property) {
      this.setSelected(property);

      this.setState({
        searchLotplans: [],
        lotplanValue: '',
        lotplanResult: `Lotplan ${property.lotplan} added to selection`,
      });
    } else {
      this.setState({ lotplanResult: 'Property not found' });
    }
  }

  zoomToLot(property, padding = 30) {
    const { map } = this.state;

    const featureCollection = buildFeatureCollection();

    // Ensure geometry is JSON
    let geom = property.geometry;
    if (typeof geom === 'string') {
      geom = JSON.parse(property.geometry);
    }

    const feature = buildFeature(geom, {
      id: property.id,
      lotplan: property.lotplan,
      ha: property.ha,
      geom: geom,
    });
    featureCollection.features.push(feature);

    zoomToBounds(map, getCollectionBoundary(featureCollection), padding);
  }

  async searchLotplan(property, select = true) {
    if (select) {
      const data = await this.props.dispatch(fetchLotPlan(property.id));
      this.setSelected({
        ...data,
        geometry: data.geom,
        properties: {
          ha: data.ha,
        },
        searchLotplans: [],
      });
    }
    this.loadProperties(true);
  }

  renderLotPlanLookup(onClickAction) {
    const { searchLotplans, searchLotPlansLoading } = this.state;

    let callback = this.addLotplan;
    if (onClickAction === 'magnifying-glass') {
      callback = this.searchLotplan;
    }

    if (searchLotPlansLoading)
      return [
        <div
          key={0}
          style={{ backgroundColor: '#333333', margin: 2, padding: 5, cursor: 'pointer' }}
        >
          <HalfCircleSpinner size={10} color="#ffffff" />
        </div>,
      ];

    return searchLotplans.map((property) => {
      let option = property.lotplan;

      // Add locality if applicable
      if (!isEmpty(property.locality)) option += `(${property.locality.toUpperCase()})`;

      // Handle conditional style/onClick function
      let style = {
        backgroundColor: '#333333',
        margin: 2,
        padding: 5,
        cursor: 'pointer',
      };
      let onClick = () => callback(property);

      if (property.id === '-') {
        onClick = () => {};
        style = { ...style, cursor: null, textAlign: 'center' };
      }

      return (
        <button
          className="btn btn-link w-100 m-0 rounded-0 text-white text-left"
          key={property?.id}
          style={style}
          onClick={onClick}
        >
          {option}
        </button>
      );
    });
  }

  toggleTab(tab) {
    if (this.props.properties.activePropertySearchTab !== tab) {
      this.props.dispatch({ type: 'SET_PROPERTY_SEARCH_ACTIVE_TAB', payload: tab });
    }
  }

  render() {
    const {
      selected,
      selectedAttributes,
      properties,
      isOpen,
      isLibraryOpen,
      lotplanValue,
      mapStylePath,
      defaultMapStyle,
      map,
      mapDataLoading,
    } = this.state;

    const { responseMessage, activePropertySearchTab, librarySources } = this.props.properties;

    const ha = this.getTotalHa();
    const totalHa = `${selected.length} Lots over ${ha} ha`;

    // Project and property ids if passed from project property page
    const { project_id } = this.props.match.params;
    const { property_id } = this.props.match.params;

    return (
      <Row className="m-0 p-0 h-100">
        <Col style={{ position: 'relative' }}>
          <div ref={this.mapRef} className="mapster" />
          {mapDataLoading && (
            <div className="d-flex justify-content-end pt-3">
              <HalfCircleSpinner size={20} color="#ffffff" />
            </div>
          )}
        </Col>
        <Col className="sidepanel">
          <Nav tabs className="mt-2">
            <FormTab
              caption="Search"
              tabId="1"
              activeTab={activePropertySearchTab}
              toggle={this.toggleTab}
              tabTag="search"
            />
            <FormTab
              caption="Layers"
              tabId="2"
              activeTab={activePropertySearchTab}
              toggle={this.toggleTab}
              tabTag="layers"
            />
            <FormTab
              caption="Library"
              tabId="3"
              activeTab={activePropertySearchTab}
              toggle={this.toggleTab}
              tabTag="library"
            />
            <FormTab
              caption="Attributes"
              tabId="4"
              activeTab={activePropertySearchTab}
              toggle={this.toggleTab}
              tabTag="attributes"
            />
          </Nav>

          <TabContent activeTab={activePropertySearchTab}>
            <TabPane tabId="1">
              <ResponseMessage className="p-2" responseMessage={responseMessage} />

              <div className="mt-3">
                <small className="ml-4 text-corporate">Property Address Search</small>
                <div className="ml-3 mr-3 mt-0" ref={this.geocoderRef}></div>
              </div>

              <div className="mt-3">
                <small className="ml-4 text-corporate">Lot Plan Search</small>
                <div className="ml-3 mt-1 mr-3">
                  <Input
                    placeholder="Type in your lotplan"
                    className="rounded"
                    onChange={this.onLotSearchKeyup}
                    value={lotplanValue}
                  />
                  <div>{this.renderLotPlanLookup('magnifying-glass')}</div>
                </div>
              </div>

              {properties.length > 0 && (
                <div className="d-flex justify-content-start m-4">
                  <small className="text-corporate">
                    Lot Plan Hover:{' '}
                    <span className="text-white" ref={this.lotplanLabel}>
                      -
                    </span>
                  </small>
                </div>
              )}

              <h3 className="text-center bg-corporate text-white p-2 mt-3">{totalHa}</h3>

              <div className="d-flex justify-content-center m-2 p-2">
                <Button
                  size="sm"
                  disabled={selected.length === 0}
                  color="danger"
                  className="ml-2"
                  onClick={this.promptClearSelected}
                >
                  Clear Lots
                </Button>
                {selected.length > 0 && (
                  <Button
                    size="sm"
                    color="success"
                    className="ml-2"
                    onClick={() => this.setModal(true)}
                  >
                    Save to Property
                  </Button>
                )}
              </div>

              <PropertySearchLotLsvBeta selected={selected} zoomToLot={this.zoomToLot} />

              <PropertySearchMigrateModal
                project_id={project_id}
                property_id={property_id}
                history={this.props.history}
                setModal={this.setModal}
                isOpen={isOpen}
                selected={selected}
              />

              <PropertySearchLibraryModal setModal={this.setLibraryModal} isOpen={isLibraryOpen} />
            </TabPane>
            <TabPane tabId="2">
              <PropertySearchSources
                mapStylePath={mapStylePath}
                defaultMapStyle={defaultMapStyle}
                removeSelectedSource={this.removeSelectedSource}
                moveLayers={this.moveLayers}
                map={map}
              />
            </TabPane>
            <TabPane tabId="3">
              <PropertySearchLibrarySources
                addSource={this.addLibrarySource}
                sources={librarySources}
              />
            </TabPane>
            <TabPane tabId="4">
              <PropertySearchAttributes attributes={selectedAttributes} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    mapster: store.mapster,
    properties: store.properties,
    profile: store.profile,
  };
};

export default connect(mapStoreToProps)(PropertySearch);
