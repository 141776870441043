import React from 'react';
import Plot from 'react-plotly.js';

class CattleAnalyticsStockflow extends React.Component {
  render() {
    var trace1 = {
  
      x: ['2015', '2016', '2017', '2018', '2019', '2020'], 
      
      close: [2912866, 4912866, 900000, 2912866, 500000,200000], 
      
      decreasing: {line: {color: 'red'}}, 
      
      high: [3572877, 4912866, 5112866, 2912866, 2912866, 500000], 
      
      increasing: {line: {color: 'green'}}, 
      
      line: {color: 'rgba(31,119,180,1)'}, 
      
      low: [2912866, 2112866, 900000, 900000,500000, 200000], 
      
      open: [3272877, 2912866, 4912866, 900000, 2912866, 500000], 
      
      type: 'candlestick', 
      xaxis: 'x', 
      yaxis: 'y'
    };
    
    var data = [trace1];
    
    var layout = {
      dragmode: 'zoom', 
      margin: {
        r: 10, 
        t: 25, 
        b: 40, 
        l: 60
      }, 
      showlegend: false, 
      xaxis: {
        autorange: true, 
        domain: [0, 1], 
        range: ['2017-01-03 12:00', '2017-02-15 12:00'], 
        rangeslider: {visible: false}, 
        title: 'Date', 
        type: 'category'
      }, 
      yaxis: {
        autorange: true, 
        domain: [0, 1], 
        range: [114.609999778, 137.410004222], 
        type: 'linear'
      }
    };
    return (
      <React.Fragment>
        <h5 className="text-center mt-3">Cattle Stockflow - Open/Close Valuations</h5>
        <Plot
          data={data}
          layout={layout}
          config={{displayModeBar: false}}
          className="w-100"
        />
      </React.Fragment>

    );
  }
}

export default CattleAnalyticsStockflow;



