import React from 'react';
import Listview from '../../../core/form/components/Listview';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const AssetReadingsLsv = ({ readings, onDelete = noop, setAssetReadingModal = noop }) => {
  const deleteIcon = 'trash';
  const viewIcon = 'eye';

  const renderRows = (headers, row) => {
    let statusColour = { backgroundColor: 'green', color: 'white' };
    if (row['dueNumeric'] < 0) {
      statusColour = { backgroundColor: 'red', color: 'white' };
    }

    const tableTd = headers.map(({ field, classes }, index) => {
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (const key of field.split('.').values()) parent = key && parent ? parent[key] : null;
        caption = parent;
      }
      switch (field) {
        case 'diff':
        case 'due':
          return (
            <td key={index} className={classes} style={statusColour}>
              {caption}
            </td>
          );
        case 'reading_date':
          return (
            <td key={index} className={classes}>
              {caption ? moment(caption).format('YYYY-MM-DD') : ''}
            </td>
          );
        case 'previous_readings':
          return (
            <td
              key={index}
              className={classes}
              style={{ cursor: 'pointer' }}
              onClick={() => onDelete(row)}
              onKeyDown={() => onDelete(row)}
              role="presentation"
            >
              <Icon name={viewIcon} style={{ color: 'green' }} />
            </td>
          );

        case 'delete':
          return (
            <td
              key={index}
              className={classes}
              style={{ cursor: 'pointer' }}
              onClick={() => onDelete(row)}
              onKeyDown={() => onDelete(row)}
              role="presentation"
            >
              <Icon name={deleteIcon} />
            </td>
          );
        case 'location':
          return (
            <td key={index} className={classes}>
              ?
            </td>
          );
        case 'code':
          return (
            <td key={index} className={classes}>
              ?
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {caption}
            </td>
          );
      }
    });

    return tableTd;
  };

  const iconName = 'barcode-read';
  const emptyCaption = 'No Readings found';

  const upperHeaders = [
    { caption: null, cells: 3, classes: 'border-bottom-0' },
    {
      caption: 'Last Reading',
      cells: 2,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    {
      caption: 'All Readings',
      cells: 1,
      classes: 'text-center bg-light border border-light',
    },
  ];

  const headers = [
    { caption: 'Plant No', field: 'plant_asset.name', classes: 'text-left' },
    { caption: 'Description', field: 'plant_asset.description', classes: 'text-left' },

    { caption: 'Owner', field: 'plant_asset.org.name', classes: 'text-left' },
    { caption: 'Date', field: 'reading_date', classes: 'text-left' },
    { caption: 'Reading', field: 'value', classes: 'text-right' },
    { caption: '', field: 'previous_readings', classes: 'text-center' },
  ];

  const upperHeadTh = upperHeaders.map(({ caption, classes, cells }, index) => (
    <th key={`${caption}-upperheader-${index}`} colSpan={cells} className={classes}>
      {caption}
    </th>
  ));

  const tableHeadTh = headers.map(({ caption, classes }, index) => (
    <th key={`${caption.toLowerCase()}-header-${index}`} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  let rowsCount;

  const haveRows = readings && readings.length > 0;
  if (haveRows) {
    tableBodyTr = readings.map((row, index) => (
      <tr key={index} onClick={() => setAssetReadingModal(true, row)} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <div className="mt-2">
      <Listview
        rows={readings}
        rowsCount={rowsCount}
        upperHeadTh={upperHeadTh}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </div>
  );
};

export default AssetReadingsLsv;
