import { apiAnalysisGroups } from 'jsx/constants/api';
import { get, post, put, remove } from 'jsx/lib/genericAction';
import { fetchAnalysisGroupMembers } from './analysis_group_members';

export function fetchAnalysisGroups(params) {
  return async (dispatch) => get(dispatch, 'FETCH_ANALYSIS_GROUPS', `${apiAnalysisGroups}`, params);
}

export function fetchAnalysisGroup(params) {
  return (dispatch) =>
    get(dispatch, 'FETCH_ANALYSIS_GROUP', `${apiAnalysisGroups}/${params.id}`, params);
}

export function updateAnalysisGroup(data) {
  return (dispatch) =>
    put(dispatch, 'UPDATE_ANALYSIS_GROUP', `${apiAnalysisGroups}/${data.id}`, data);
}

export function createAnalysisGroup(data) {
  return (dispatch) => post(dispatch, 'CREATE_ANALYSIS_GROUP', `${apiAnalysisGroups}`, data);
}

export function removeAnalysisGroup(id) {
  return (dispatch) => remove(dispatch, 'REMOVE_ANALYSIS_GROUP', `${apiAnalysisGroups}/${id}`);
}

export function setAnalysisGroupParams(params) {
  return async (dispatch) => {
    dispatch({ type: 'SET_ANALYSIS_GROUP_PARAMS', payload: params });
    return dispatch(fetchAnalysisGroups(params));
  };
}

export function setAnalysisGroupMemberParams(params) {
  return async (dispatch) => {
    dispatch({ type: 'SET_ANALYSIS_GROUP_MEMBER_PARAMS', payload: params });
    return dispatch(fetchAnalysisGroupMembers(params));
  };
}
