import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import AssetWorkActivities from './AssetWorkActivities';

class Jobs extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isNew: false,
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  async componentDidMount() {
    const { activeTabJobs } = this.props.wipstar;
    this.toggleTab(activeTabJobs);
  }

  toggleTab(tab) {
    const { activeTabJobs } = this.props.wipstar;
    if (activeTabJobs !== tab) {
      this.props.dispatch({ type: 'SET_JOBS_TAB', payload: tab });
    }
  }

  render() {
    const { activeTabJobs } = this.props.wipstar;
    const iconName = 'user-helmet';

    return (
      <div className="p-2 h-100 d-flex flex-column">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row m-2">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Work Activities/Work Orders</h3>
          </div>
        </div>

        <Nav tabs className="mt-2">
          <FormTab
            iconRightClass="primary"
            iconRightName="person-digging"
            caption="Work Activities"
            tabId="workactivities"
            tabTag="workactivities"
            activeTab={activeTabJobs}
            toggle={this.toggleTab}
          />
          <FormTab
            iconRightClass="primary"
            iconRightName="screwdriver-wrench"
            caption="Work Orders"
            tabId="workorders"
            tabTag="workorders"
            activeTab={activeTabJobs}
            toggle={this.toggleTab}
            disabled={true}
          />
        </Nav>

        <TabContent activeTab={activeTabJobs} className="d-flex flex-column flex-grow-1">
          <TabPane tabId="workactivities" className="mb-2 p-1 h-100">
            <AssetWorkActivities />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ wipstar, realm, attributes }) => {
  return {
    wipstar,
    realm,
    attributes,
  };
};

export default connect(mapStoreToProps)(Jobs);
