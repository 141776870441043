import React from 'react';
import { connect } from "react-redux";
import {
  UncontrolledCollapse,
  Card,
  Row,
  Col
} from 'reactstrap';
import PillPicker from '../../../core/form/components/PillPicker';
import PillPresenter from '../../../core/form/components/PillPresenter';

import {
  fetchSiteStatuses,
  setFilters
} from '../actions';

class SitesFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      toggler: '#toggler',
    })

    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleStatusDelete = this.handleStatusDelete.bind(this);
    this.handleTypeKeyChange = this.handleTypeKeyChange.bind(this);
    this.handleTypeKeyDelete = this.handleTypeKeyDelete.bind(this);
    this.handleScanValidChange = this.handleScanValidChange.bind(this);
    this.handleScanValidDelete = this.handleScanValidDelete.bind(this);
    this.handleScannedValidChange = this.handleScannedValidChange.bind(this);
    this.handleScannedValidDelete = this.handleScannedValidDelete.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchSiteStatuses());
  }

  handleStatusChange(event) {
    const {
      filters,
      params
    } = this.props.sites;

    const exists = filters.status_id.find(id => id === event.target.value);
    if (!exists) {
      filters.status_id.push(event.target.value)
    }

    this.props.dispatch(setFilters(params, filters));
  }

  handleStatusDelete(id) {
    const {
      filters,
      params
    } = this.props.sites;

    const idx = filters.status_id.findIndex(filterId => filterId === id)
    filters.status_id.splice(idx, 1)

    this.props.dispatch(setFilters(params, filters));
  }

  handleTypeKeyChange(event) {
    const {
      filters,
      params
    } = this.props.sites;

    // Allow only a single key
    if (filters.type_key.length > 0) {
      this.handleTypeKeyDelete(filters.type_key[0])
    }

    const exists = filters.type_key.find(id => id === event.target.value);
    if (!exists) {
      filters.type_key.push(event.target.value)
    }

    this.props.dispatch(setFilters(params, filters));
  }

  handleTypeKeyDelete(id) {
    const {
      filters,
      params
    } = this.props.sites;

    const idx = filters.type_key.findIndex(filterId => filterId === id)
    filters.type_key.splice(idx, 1)

    this.props.dispatch(setFilters(params, filters));
  }

  handleScanValidChange(event) {
    const {
      filters,
      params
    } = this.props.sites;

    // Allow only a single key
    if (filters.scan_valid.length > 0) {
      this.handleScanValidDelete(filters.scan_valid[0])
    }

    const exists = filters.scan_valid.find(id => id === event.target.value);
    if (!exists) {
      filters.scan_valid.push(event.target.value)
    }

    this.props.dispatch(setFilters(params, filters));
  }

  handleScanValidDelete(id) {
    const {
      filters,
      params
    } = this.props.sites;

    const idx = filters.scan_valid.findIndex(filterId => filterId === id)
    filters.scan_valid.splice(idx, 1)

    this.props.dispatch(setFilters(params, filters));
  }

  handleScannedValidChange(event) {
    const {
      filters,
      params
    } = this.props.sites;

    // Allow only a single key
    if (filters.scan_samples.length > 0) {
      this.handleScannedValidDelete(filters.scan_samples[0])
    }

    const exists = filters.scan_samples.find(id => id === event.target.value);
    if (!exists) {
      filters.scan_samples.push(event.target.value)
    }

    this.props.dispatch(setFilters(params, filters));
  }

  handleScannedValidDelete(id) {
    const {
      filters,
      params
    } = this.props.sites;

    const idx = filters.scan_samples.findIndex(filterId => filterId === id)
    filters.scan_samples.splice(idx, 1)

    this.props.dispatch(setFilters(params, filters));
  }

  render() {
    const {
      toggler
    } = this.state;

    const {
      statuses,
      filters,
      typeKeys,
      scanValids,
      scansOnly
    } = this.props.sites;

    
    const statusSelection = filters.status_id;
    const typeKeySelection = filters.type_key;
    const scanValidSelection = filters.scan_valid;
    const scannedValidSelection = filters.scan_samples;

    let filtersCount = 0;
    filtersCount += statusSelection.length;
    filtersCount += typeKeySelection.length;
    filtersCount += scanValidSelection.length;
    filtersCount += scannedValidSelection.length;

    return (
      <React.Fragment>
        <UncontrolledCollapse toggler={toggler}>
          <Card className="border border-warning rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col className="bg-light m-1">
                <PillPicker
                  caption="Statuses"
                  handleChange={this.handleStatusChange}
                  rows={statuses}
                  selection={statusSelection}
                  showPills={false}
                />
              </Col>
              <Col className="bg-light m-1">
                <PillPicker
                  caption="Site Types"
                  handleChange={this.handleTypeKeyChange}
                  rows={typeKeys}
                  selection={typeKeySelection}
                />
              </Col>
              <Col className="bg-light m-1">
                <PillPicker
                  caption="Scan State"
                  handleChange={this.handleScannedValidChange}
                  rows={scansOnly}
                  selection={scannedValidSelection}
                  showPills={false}
                />
              </Col>
              <Col className="bg-light m-1">
                <PillPicker
                  caption="Scan Valid"
                  handleChange={this.handleScanValidChange}
                  rows={scanValids}
                  selection={scanValidSelection}
                  showPills={false}
                />
              </Col>
            </Row>
          </Card>
        </UncontrolledCollapse>
        {(filtersCount > 0 &&
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption="Statuses"
              handleDelete={this.handleStatusDelete}
              rows={statuses}
              selection={statusSelection}
            />
            <PillPresenter
              caption="Site Types"
              handleDelete={this.handleTypeKeyDelete}
              rows={typeKeys}
              selection={typeKeySelection}
            />
            <PillPresenter
              caption="Scan State"
              handleDelete={this.handleScannedValidDelete}
              rows={scansOnly}
              selection={scannedValidSelection}
            />
            <PillPresenter
              caption="Scan Valid"
              handleDelete={this.handleScanValidDelete}
              rows={scanValids}
              selection={scanValidSelection}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    sites: store.sites
  }
}

export default connect(mapStoreToProps)(SitesFilter);
