import faker from '@faker-js/faker';
import moment from 'moment';

export const fakerFaults = (count) => {
  const rows = [];
  for (let i = 0; i < count; i++) {
    rows.push(fakerFault())
  }

  return rows;
}

export const fakerFault = () => {
  const description = getRandomRow(fakerFaultDescriptions);
  const owner = getRandomRow(fakerAssetOwners);
  const location = getRandomRow(fakerGenerateLocations);
  // const workorder = faker.datatype.number({min: 1000, max: 9000});

  const assetid = faker.datatype.number({min: 1, max: 9000 - 1});
  const asset_no = `${owner.prefix}-${assetid}`;
  // const assigned = faker.datatype.number({min: 1000, max: 9000});

  return {
    reported: getFakeLast3MonthsAgo(),
    asset_no,
    description: description.name,
    location: location.name,
    owner: owner.name,
    workorder: null,
    assigned: null,
    completed: null,
    comments: ''
  }
}

export const fakerTimesheetActivities = (count) => {
  const rows = [];
  for (let i = 0; i < count; i++) {
    rows.push(fakerTimesheetActivity())
  }

  return rows;
}

export const fakerTimesheetActivity = () => {
  const owner = getRandomRow(fakerAssetOwners);
  const comment = getRandomRow(fakerActivityComments);

  const assetid = faker.datatype.number({min: 1, max: 9000 - 1});
  const asset_no = `${owner.prefix}-${assetid}`;

  const jobno = faker.datatype.number({min: 1000, max: 9000});
  const phasecode = faker.datatype.number({min: 1000, max: 2000});
  const lhrs = faker.datatype.float({min: 0, max: 4});
  const ahrs = faker.datatype.float({min: 0, max: 8});
  const bhrs = faker.datatype.float({min: 0, max: 0.5});
  const loadCount = faker.datatype.float({min: 0, max: 15});

  return {
    asset_no,
    jobphase: `${jobno}/${phasecode}`,
    comments: comment.name,
    labour_hours: `${lhrs} hrs`,
    asset_hours: `${ahrs} hrs`,
    break_hours: `${bhrs} hrs`,
    load_count: loadCount,
    material_type: 'Clay Core'
  }
}

export const fakerActivityComments = () => {
  const rows = [
    {name: 'Moved stockpile to secondary site'},
    {name: 'Expanded NW pit'},
    {name: 'Moved office containers to new location'},
    {name: 'Moved AG-12 genset to office container block'},
    {name: 'Moved Clay to pit'},
  ]
  return rows;
}

export const fakerTimesheets = (count) => {
  const rows = [];
  for (let i = 0; i < count; i++) {
    rows.push(fakerTimesheet())
  }

  return rows;
}

export const fakerTimesheet = () => {
  const owner = getRandomRow(fakerAssetOwners);
  const location = getRandomRow(fakerGenerateLocations);

  const assetid = faker.datatype.number({min: 1, max: 9000 - 1});
  const asset_no = `${owner.prefix}-${assetid}`;
  const from_date = getFakeDateTimeAgo();

  const jobno = faker.datatype.number({min: 1000, max: 9000});
  const phasecode = faker.datatype.number({min: 1000, max: 2000});
  const lhrs = faker.datatype.float({min: 0, max: 8});
  const ahrs = faker.datatype.float({min: 0, max: 8});

  return {
    from_date,
    to_date: fakerTimesheetToDate(from_date),
    name: 'asdf',
    owner: owner.name,
    costcode: {client_org: {}},
    comments: [],
    user: {name: faker.name.findName()},
    asset_no,
    location: location.name,
    jobphase: `${jobno}/${phasecode}`,
    lhrs: `${lhrs} hrs`,
    ahrs: `${ahrs} hrs`,
    workorder: jobno
  }
}

export const fakerMaintenanceItems = (count) => {
  const rows = [];
  for (let i = 0; i < count; i++) {
    rows.push(fakerMaintenanceItem())
  }

  return rows;
}

export const fakerMaintenanceItem = () => {
  const priority = getRandomRow(fakerPriorities);
  const status = getRandomRow(fakerStatuses);
  const description = getRandomRow(fakerMaintenanceDescriptions);
  const owner = getRandomRow(fakerAssetOwners);

  const jobid = faker.datatype.number({min: 1, max: 9000 - 1});
  const job_no = `WO-${jobid}`;

  const assetid = faker.datatype.number({min: 1, max: 9000 - 1});
  const asset_no = `${owner.prefix}-${assetid}`;

  return {
    job_no,
    asset_no,
    description: description.name,
    assigned_to: faker.name.findName(),
    status: status.name,
    row_colour: status.colour,
    owner: owner.name,
    priority: priority.name,
    created: getFakeLast3MonthsAgo(),
    due: getFakeLast3MonthsAgo()
  }
}

export const fakerTimesheetToDate = (from_date) => {
  return moment().add(faker.datatype.number({min: 1, max: 12}), 'hours').format('YYYY-MM-DD HH:mm');
}

export const fakerPriorities = () => {
  const rows = [
    {name: 'Low', colour: 'gray'},
    {name: 'Medium', colour: 'success'},
    {name: 'High', colour: 'orange'},
    {name: 'Highest', colour: 'danger'}
  ]
  return rows;
}

export const fakerStatuses = () => {
  const rows = [
    {name: 'New', colour: 'gray'},
    {name: 'InProgress', colour: 'blue'},
    {name: 'Waiting for Parts', colour: 'orange'},
    {name: 'Completed', colour: 'green'},
    {name: 'Cancelled', colour: 'red'}
  ]
  return rows;
}

export const fakerFaultDescriptions = () => {
  const rows = [
    {name: 'Radiator leaking after hitting rock'},
    {name: 'Flat Battery. Wont start'},
    {name: 'Intermittant tarter motor issues'},
    {name: 'Severe damage to drivers door'},
    {name: 'Big hole in the front bumper'},
    {name: 'Leaking fuel from filter'},
    {name: 'Leaking oil'},
    {name: 'Can not change into 4th gear'},
  ]
  return rows;
}

export const fakerMaintenanceDescriptions = () => {
  const rows = [
    {name: '250Hr Maintenance'},
    {name: '500Hr Maintenance'},
    {name: 'Radiator leaking after hitting rock'},
    {name: 'Timing chain replacement'},
    {name: 'Intermittant tarter motor issues'},
    {name: 'Severe damage to drivers door'},
    {name: 'All-round tire replacements'},
    {name: 'Leaking fuel from filter'},
  ]
  return rows;
}

export const fakerReadings = (count) => {
  const rows = [];
  for (let i = 0; i < count; i++) {
    rows.push(fakerReading())
  }

  return rows;
}

export const fakerReading = () => {
  const asset = getRandomRow(generateAsset);
  const owner = getRandomRow(fakerAssetOwners);
  const location = getRandomRow(fakerGenerateLocations);

  const id = faker.datatype.number({min: 1, max: 9000 - 1});
  const idx = `${owner.prefix}-${id}`;

  const last = faker.datatype.number({min: 500, max: 5000 - 1});
  const increment = faker.datatype.number({min: 1, max: 350});
  const prev = (last - increment);

  const lastreading = `${last} hrs`;
  const prevreading = `${prev} hrs`;
  const interval = 250;
  const actual = last
  const serviceAt = prev + interval;
  const due = serviceAt - actual;
  const diff = last - prev;

  return {
    code: idx,
    description: asset.name,
    location: location.name,
    owner: owner.name,
    lastdate: getFakeLast3MonthsAgo(),
    lastreading,
    prevdate: getFakeLast3MonthsAgo(),
    prevreading,
    interval: `${interval}hrs`,
    unit: 'hrs',
    dueNumeric: due,
    due: `${due} hrs`,
    diff: `${diff} hrs`,
    estimated: getFakeLast3MonthsAgo()
  }
}

export const fakerLocations = (count) => {
  const rows = [];
  for (let i = 0; i < count; i++) {
    rows.push(fakerLocation())
  }

  return rows;
}

export const fakerLocation = () => {
  const project = faker.datatype.number({min: 1, max: 3000 - 1});
  const owner = getRandomRow(fakerAssetOwners);
  const id = faker.datatype.number({min: 1, max: 9000 - 1});
  const idx = `${owner.prefix}-${id}`;
  const location = getRandomRow(fakerGenerateLocations);

  return {
    code: idx,
    location: location.name,
    transfer: getFakeAgo(),
    enddate: getFakeAgo(),
    status: 'OnHire',
    project: project,
    contact: faker.name.findName(),
    mobile: faker.phone.phoneNumber('04## ######')
  }
}

export const fakerAssets = (count) => {
  const assets = [];
  for (let i = 0; i < count; i++) {
    assets.push(fakerAsset())
  }

  return assets;
}

export const fakerAsset = () => {
  const owner = getRandomRow(fakerAssetOwners);
  const asset = getRandomRow(generateAsset);
  const status = getRandomRow(fakerAssetStatuses);
  const location = getRandomRow(fakerGenerateLocations);

  const id = faker.datatype.number({min: 1, max: 9000 - 1});
  const idx = `${owner.prefix}-${id}`;

  return {
    code: idx,
    description: asset.name,
    type: asset.type,
    category: asset.category,
    status: status,
    owner: owner.name,
    location: location.name,
    coords: getFakeCoords()
  }
}

export const generateAsset = () => {
  const rows = [
    { name: 'Cat 140H', type: 'Major Plant', category: 'Graders', meter_units: 'Hrs', service_interval: 250},
    { name: 'Cat 972H', type: 'Major Plant', category: 'Loaders', meter_units: 'Hrs', service_interval: 250},
    { name: 'D6R LGP Dozer', type: 'Major Plant', category: 'Dozers', meter_units: 'Hrs', service_interval: 250},
    { name: 'D7R LGP Dozer', type: 'Major Plant', category: 'Dozers', meter_units: 'Hrs', service_interval: 250},
    { name: 'Cat 432F Backhoe', type: 'Major Plant', category: 'Backhoes', meter_units: 'Hrs', service_interval: 250},
    { name: '40T Moxy', type: 'Major Plant', category: 'Trucks', meter_units: 'Hrs', service_interval: 250},

    { name: '745 Moxy', type: 'ADT-45T', category: 'Trucks', meter_units: 'Hrs', service_interval: 250},
    { name: 'Komatsu PC300 Excavator', type: 'Major Plant', category: 'Excavators', meter_units: 'Hrs', service_interval: 250},
    { name: '4wD Dual Cab Utility', type: 'Light Vehicles', category: 'Light Vehicles 4WD', meter_units: 'Hrs', service_interval: 250},
    { name: 'Prado/ Hilux Ute', type: 'Light Vehicles', category: 'Light Vehicles 4WD', meter_units: 'Hrs', service_interval: 250},
    { name: '825C Compactor', type: 'Major Plant', category: 'Compactors', meter_units: 'Hrs', service_interval: 250},
    { name: '40foot Hose Container', type: 'Site Facilities', category: 'Sea Containers', meter_units: 'Hrs', service_interval: 250},
    { name: '20KVA Genset', type: 'Small Plant', category: 'Generators', meter_units: 'Hrs', service_interval: 250},
    { name: '10KVA Generator', type: 'Small Plant', category: 'Generators', meter_units: 'Hrs', service_interval: 250},
    { name: 'Crib Room', type: 'Site Facilities', category: 'Site Offices', meter_units: 'Hrs', service_interval: 250}
  ]

  return rows;
}

export const getRandomRow = (fn) => {
  const rows = fn();
  const idx = faker.datatype.number({min: 0, max: rows.length - 1});
  return rows[idx];
}

export const fakerAssetTypes = () => {
  const types = [
    'Light Vehicles',
    'Major Plant',
    'Small Plant',
    'Site Facilities/Storage',
    'Small Tools'
  ]

  return types;
}

export const fakerAssetCategories = () => {
  const rows = [
    'Generators',
    'Jack Hammer',
    'Trailers',
    'Site Offices',
    'Sea containers',
    'Trucks',
    'Dozers',
    'Graders'
  ];

  return rows;
}

export const fakerAssetStatuses = () => {
  const rows = [
    'Active',
    'InActive',
    'Down'
  ];

  return rows;
}

export const fakerAssetOwners = () => {
  const rows = [
    {name: 'McCosker Contracting', prefix: 'MC'},
    {name: 'BH Mining', prefix: 'BHM'},
    {name: 'Ag Equip', prefix: 'AG'},
    {name: 'Avis', prefix: 'AV'},
    {name: 'Bloomfields', prefix: 'BF'}
  ]

  return rows;
}

export const fakerGenerateLocations = () => {
  const rows = [
    {name: 'Awoonga' },
    {name: 'Gove'},
    {name: 'Gladstone Race Course'},
    {name: 'Gladstone Show Grounds'},
    {name: 'Garlock'},
    {name: 'Blackwater'},
    {name: 'Dysart'},
    {name: 'Mungungo Wier'},
    {name: 'Pine Creek'},
    {name: 'QAL RDA'},
  ]

  return rows;
}

const getFakeAgo = () => {
  const units = ['weeks', 'months', 'years'];
  const unit = units[faker.datatype.number({min: 0, max: units.length - 1})];
  return moment().subtract(faker.datatype.number({min: 1, max: 24}), unit).format('YYYY-MM-DD');
}

const getFakeLast3MonthsAgo = () => {
  return moment().subtract(faker.datatype.number({min: 1, max: 90}), 'days').format('YYYY-MM-DD');
}

const getFakeDateTimeAgo = () => {
  return moment().subtract(faker.datatype.number({min: 1, max: 90}), 'days').format('YYYY-MM-DD HH:mm');
}

const getFakeCoords = () => {
  return [faker.datatype.float({min: 149, max: 150}), faker.datatype.float({min: -23.5, max: -22.5})]
}
