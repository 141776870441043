import React from 'react';
import GaugeChart from 'react-gauge-chart';
import Icon from 'jsx/components/core/icons/Icon';
import { Col } from 'reactstrap';
import { HalfCircleSpinner } from 'react-epic-spinners';

const WidgetGauge = ({
  metric,
  onEdit,
  noHeader,
  colSize,
  isLoading,
  displayResyncWarning = false,
}) => {
  const target = metric?.data?.goal ? metric.data.goal / 100 : 0;
  const value = metric?.data?.value ? metric.data.value / 100 : 0;

  let style = {};
  let className = '';
  let widgetColour = '#38c172'; // green hex colour

  const useStyleProps = metric?.widget_attributes?.color && !displayResyncWarning;

  if (useStyleProps) {
    widgetColour = metric.widget_attributes.color;
    style = {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: widgetColour,
      borderRadius: '5px',
    };
  } else {
    if (displayResyncWarning) widgetColour = '#fbbc05'; // warning hex colour
    className = 'border border-warning border-radius-5';
  }

  const displayValue = isLoading ? (
    <HalfCircleSpinner size={100} color={widgetColour} className="mb-3" />
  ) : (
    <GaugeChart
      className="warning"
      id="gauge-chart"
      nrOfLevels={420}
      arcsLength={[target, 1 - target]}
      colors={['#cc0000', widgetColour]}
      percent={value}
      arcPadding={0.01}
      textColor="#000000"
      needleColor="#cccccc"
    />
  );

  return (
    <Col sm={colSize} className="p-1" title={metric.description}>
      <div className={className} style={style}>
        {(Number.isNaN(target) || Number.isNaN(value)) && (
          <div>Invalid Number for {metric?.name}</div>
        )}
        {!Number.isNaN(target) && !Number.isNaN(value) && (
          <>
            {!noHeader && (
              <>
                <div className="text-center p-2">{metric?.name}</div>
                <Icon
                  name="gear"
                  className="mr-1"
                  style={{ position: 'absolute', top: 15, right: 15 }}
                  onClick={onEdit}
                />
              </>
            )}
            {displayResyncWarning && (
              <Icon
                name="triangle-exclamation-solid"
                className="ml-1 text-warning"
                style={{ position: 'absolute', top: 10, left: 15 }}
                title="Apply filters to update metric value"
              />
            )}
            <div className="d-flex justify-content-center">{displayValue}</div>
          </>
        )}
      </div>
    </Col>
  );
};

export default WidgetGauge;
