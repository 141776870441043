import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';

import GenericLsvAdapter from 'jsx/components/core/form/components/GenericLsvAdapter';
import Icon from 'jsx/components/core/icons/Icon';
import { cloneDeep, noop } from 'lodash';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';

import GenericLsv from '../../../core/form/components/GenericLsv';

import {
  fetchLookupLocations,
  fetchLookupLocation,
  createLookupLocation,
  updateLookupLocation,
  removeLookupLocation,
  fetchLookupLocationUsages,
  fetchOrgPlantAssetProjects,
} from '../actions/locations';

import LocationProjectAssociationModal from './LocationProjectAssociationModal';
import AssetsLocationsToolbar from '../components/AssetLocationsToolbar';
import AssetProjectAssociationModal from './AssetProjectAssociationModal';
import LocationsToolbar from '../components/LocationsToolbar';
import GenericModal from '../../../core/form/components/GenericModal';
import { controls as siteLocationControls } from '../forms/locations';
import { controls as assetControls } from '../forms/asset_locations';
import { fetchAttributes } from '../../portrait/actions/attributes';
import { fetchUsers, fetchOrgs } from '../../../manage/actions/index';

class Locations extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      assetProjectAssociationId: null,
      isAssetProjectModalOpen: false,
      isLocationProjectModalOpen: false,
      isModalOpen: false,
      modalData: null,
      modalType: 'site_location',
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.loadAssetProjects = this.loadAssetProjects.bind(this);
    this.loadLocations = this.loadLocations.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.refresh = this.refresh.bind(this);
    this.setAssetProjectModal = this.setAssetProjectModal.bind(this);
    this.setLocationProjectModal = this.setLocationProjectModal.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.handleLocationManagementSearchChange =
      this.handleLocationManagementSearchChange.bind(this);
  }

  async setModal(isModalOpen, modalType = null, modalId = null) {
    let modalData;

    // Refresh dependant lookups based on type
    if (isModalOpen) {
      switch (modalType) {
        case 'site_location': {
          if (modalId) {
            modalData = await this.props.dispatch(fetchLookupLocation(modalId));
            this.props.dispatch(fetchLookupLocationUsages(modalId)).then((isModalDeleteDisabled) =>
              this.setState({
                isModalDeleteDisabled,
              }),
            );
          }
          break;
        }
        default:
          break;
      }
    }

    const state = {
      isModalOpen,
      modalData,
    };
    if (modalType) state.modalType = modalType;

    this.setState(state);
  }

  setModalOptions(type) {
    const { orgs } = this.props.manage;

    let options = {};
    switch (type) {
      case 'site_location':
        options = {
          title: 'Site Location',
          iconName: 'list',
          controls: cloneDeep(siteLocationControls),
          options: {
            owner_id: orgs,
          },
        };
        break;
      default:
        break;
    }

    return options;
  }

  onRemove(id) {
    const { modalType } = this.state;
    let removeMethod = noop;

    switch (modalType) {
      case 'site_location': {
        removeMethod = removeLookupLocation;
        break;
      }
      default: {
        break;
      }
    }

    if (removeMethod) return this.props.dispatch(removeMethod(id));
    return null;
  }

  onSave(data, isNew) {
    const { modalType } = this.state;

    const updatedData = cloneDeep(data);
    if (isNew) delete updatedData.id;

    let saveMethod;
    switch (modalType) {
      case 'site_location': {
        saveMethod = isNew ? createLookupLocation : updateLookupLocation;
        break;
      }
      default:
        break;
    }

    return this.props.dispatch(saveMethod(updatedData));
  }

  async toggleTab(tab) {
    const { dispatch } = this.props;

    switch (tab) {
      case 'site_location': {
        await this.loadLocations();
        await dispatch(fetchOrgs());
        break;
      }

      default:
        break;
    }
    if (this.props.wipstar?.locationManagmentActiveTab !== tab) {
      this.props.dispatch({ type: 'SET_LOCATION_MANAGEMENT_TAB', payload: tab });
    }
  }

  async componentDidMount() {
    Promise.all([
      this.loadLocations(),
      this.loadAssetProjects(),
      this.props.dispatch(fetchAttributes({ type: 'plant_asset_project_statuses' })),
      this.props.dispatch(fetchUsers()),
    ]);

    const { locationManagmentActiveTab } = this.props.wipstar;
    this.toggleTab(locationManagmentActiveTab);
  }

  handleSearchChange(search_value) {
    this.loadLocations({ search_value });
  }

  handleLocationManagementSearchChange(search_value) {
    this.loadAssetProjects({ search_value });
  }

  loadAssetProjects(params = {}) {
    this.props.dispatch(fetchOrgPlantAssetProjects(params));
  }

  async loadLocations(params = {}) {
    await this.props.dispatch(fetchLookupLocations(params));
  }

  onClose() {
    this.refresh();
  }

  refresh() {
    const { params } = this.props.wipstar;
    const { locationsSearchTerm: search_value } = params;
    this.loadAssetProjects({ search_value });

    const tab_id = this.props.wipstar.locationManagmentActiveTab;
    if (tab_id) this.toggleTab(tab_id);
  }

  setAssetProjectModal(isAssetProjectModalOpen, assetProjectAssociationId = null) {
    this.setState({ isAssetProjectModalOpen, assetProjectAssociationId });
  }

  setLocationProjectModal(isLocationProjectModalOpen) {
    this.setState({ isLocationProjectModalOpen });
  }

  render() {
    const {
      assetProjectAssociationId,
      isAssetProjectModalOpen,
      isLocationProjectModalOpen,
      isModalOpen,
      modalData,
      modalType,
      isModalDeleteDisabled,
    } = this.state;
    const { projectAssetAssociations, locationManagmentActiveTab, locations, responseMessage } =
      this.props.wipstar;
    const iconName = 'location-dot';

    const modalOptions = this.setModalOptions(modalType);

    return (
      <div className="p-2 h-100 d-flex flex-column">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row m-2">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Locations</h3>
          </div>
        </div>

        <GenericModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setModal}
          data={modalData}
          isOpen={isModalOpen}
          iconName={modalOptions.iconName}
          onClose={this.onClose}
          onSave={this.onSave}
          onRemove={this.onRemove}
          responseMessage={responseMessage}
          removeDisabled={isModalDeleteDisabled}
        />

        <Nav tabs className="mt-2">
          <FormTab
            iconRightClass="primary"
            caption="Location Management"
            tabId="location_management"
            activeTab={locationManagmentActiveTab}
            toggle={this.toggleTab}
          />
          <FormTab
            iconRightClass="success"
            caption="Site Location"
            tabId="site_location"
            activeTab={locationManagmentActiveTab}
            toggle={this.toggleTab}
          />
        </Nav>
        <TabContent
          activeTab={locationManagmentActiveTab}
          className="d-flex flex-column flex-grow-1"
        >
          <TabPane tabId="location_management" className="mb-2 p-1">
            <LocationProjectAssociationModal
              setModal={this.setLocationProjectModal}
              isOpen={isLocationProjectModalOpen}
            />
            <AssetProjectAssociationModal
              setModal={this.setAssetProjectModal}
              isOpen={isAssetProjectModalOpen}
              id={assetProjectAssociationId}
              refresh={this.refresh}
            />
            <AssetsLocationsToolbar
              handleSearchChange={this.handleLocationManagementSearchChange}
              onProjectAssociation={this.setLocationProjectModal}
              onTransferPlant={this.setAssetProjectModal}
            />
            <GenericLsvAdapter
              onClick={(id) => this.setAssetProjectModal(true, id)}
              controls={assetControls}
              rows={projectAssetAssociations}
              rowsCount={projectAssetAssociations.length}
              iconName={iconName}
              emptyCaption="No Asset Locations found"
            />
          </TabPane>
          <TabPane tabId="site_location" className="mb-2 p-1">
            <LocationsToolbar
              handleSearchChange={this.handleSearchChange}
              onAddLocation={this.setModal}
              onReload={() => this.loadLocations()}
            />
            <GenericLsv
              rows={locations}
              emptyCaption="No Site Location data found"
              iconName={iconName}
              controls={modalOptions.controls}
              onClick={(id) => this.setModal(true, 'site_location', id)}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ wipstar, manage }) => ({
  wipstar,
  manage,
});

export default connect(mapStoreToProps)(withContainerError(Locations));
