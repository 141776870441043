import React from 'react';
import moment from 'moment';
import { HollowDotsSpinner } from 'react-epic-spinners';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { noop } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import Listview from '../../../core/form/components/Listview';

import TimeFormat from '../lib/timeFormat';

const TimekeeperTimerLsv = ({
  rows,
  selectedDate,
  onEdit = noop,
  handleStartTimer = noop,
  handleStopAllTimers = noop,
}) => {
  const format = new TimeFormat();

  const copyTimer = async (
    event,
    {
      break_hours = null,
      comments = null,
      costcode = null,
      costcode_id,
      jira_key = null,
      location_latitude = null,
      location_longitude = null,
      local_timezone = null,
      phasecode = null,
      rate = null,
      user_id,
    }
  ) => {
    // The table row has a click handler on it, so we need to stop event propagation
    event.preventDefault();
    event.stopPropagation();

    const now = moment();
    const newData = {
      user_id,
      costcode_id,
      phasecode_id: phasecode?.id ?? null,
      from_date: now.format('YYYY-MM-DDTHH:mmZZ'),
      to_date: null,
      local_timezone,
      break_hours,
      location_latitude,
      location_longitude,
      jira_key,
      comments,
      rate,
      client_org_id: costcode?.client_org_id ?? null,
    };
    const response = await handleStartTimer(newData);

    if (response) handleStopAllTimers(response?.id);
  };

  const renderRows = (headers, record) => {
    const { comments, costcode, from_date, to_date = null, id, phasecode = null, user } = record;
    const phasecode_name = phasecode ? phasecode.description : costcode.phasecode;

    const { name: costcode_name, client_org } = costcode;
    const client_org_name = client_org.name;

    const from_time = moment(from_date).format(format.time);
    const to_time = to_date ? moment(to_date).format(format.time) : '';
    const time = `${from_time} - ${to_time}`;

    const statusIndicator = to_date ? (
      <Icon name="check" className="mr-2 text-success" />
    ) : (
      <HollowDotsSpinner className="mt-2" color="#cc0000" size={5} />
    );

    const tableTd = headers.map(({ classes, field, style }, index) => {
      let caption = record[field];
      switch (field) {
        case 'client':
          return (
            <td key={`client-${id}`} className={classes} style={style}>
              {client_org_name}
            </td>
          );
        case 'net_hours':
          return (
            <td key={`net_hours-${id}`} className={classes} style={style}>
              {format.asString(caption)}
            </td>
          );
        case 'time':
          return (
            <td key={`time-${id}`} className={classes} style={style}>
              {time}
            </td>
          );
        case 'status_indicator':
          return (
            <td key={`status_indicator-${id}`} className={classes} style={style}>
              {statusIndicator}
            </td>
          );
        case 'costcode_name':
          return (
            <td key={`costcode_name-${id}`} className={classes} style={style}>
              {costcode_name}
            </td>
          );
        case 'phasecode_name':
          return (
            <td key={`phasecode_name-${id}`} className={classes} style={style}>
              {phasecode_name}
            </td>
          );
        case 'comments':
          return (
            <td key={`comments-${id}`} className={classes} style={style}>
              {comments}
            </td>
          );
        case 'user_name':
          return (
            <td key={`user_name-${id}`} className={classes} style={style}>
              {user.name}
            </td>
          );
        case 'action':
          const label = 'Copy/Start Timer';
          return (
            <td key={`action-${id}`} style={style}>
              {to_date && (
                <>
                  <Button
                    aria-label={label}
                    id={`tooltip-${id}`}
                    key={index}
                    size="sm"
                    color="success"
                    onClick={(event) => copyTimer(event, record)}
                  >
                    <Icon name="play" />
                  </Button>
                  <UncontrolledTooltip target={`tooltip-${id}`}>{label}</UncontrolledTooltip>
                </>
              )}
            </td>
          );
        default:
          return (
            <td key={`${field}-${id}`} className={classes} style={style}>
              {caption}
            </td>
          );
      }
    });

    return tableTd;
  };

  const iconName = 'user-clock';
  const emptyCaption = `No Timesheets for ${moment(selectedDate).format('dddd Do')}`;

  const headers = [
    {
      caption: 'Client',
      field: 'client',
      classes: 'text-left',
      style: { width: 100 },
    },
    {
      caption: 'Cost Code',
      field: 'costcode_name',
      classes: 'text-left',
      style: { width: 300 },
    },
    {
      caption: 'Phase Code',
      field: 'phasecode_name',
      classes: 'text-left',
      style: { width: 300 },
    },
    {
      caption: 'User',
      field: 'user_name',
      classes: 'text-left',
      style: { width: 100 },
    },
    { caption: 'Comments', field: 'comments', classes: 'text-ellipsis' },
    {
      caption: 'Time',
      field: 'time',
      classes: 'text-left',
      style: { width: 120 },
    },
    {
      caption: 'Break Hrs',
      field: 'break_hours',
      classes: 'text-right',
      style: { width: 50 },
    },
    {
      caption: 'Jira Key',
      field: 'jira_key',
      classes: 'text-left',
      style: { width: 80 },
    },
    {
      caption: 'Hours',
      field: 'net_hours',
      classes: 'text-right',
      style: { width: 60 },
    },
    {
      caption: '#',
      field: 'status_indicator',
      classes: 'text-left',
      style: { width: 40 },
    },
    {
      caption: '',
      field: 'action',
      classes: '',
      style: { width: 40 },
    },
  ];

  const tableHeadTh = headers.map(({ caption, classes, field }) => (
    <th key={`${field}-th`} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  let rowsCount;

  const haveRows = rows && rows.length > 0;
  let daySheets = rows;
  let dayHours = 0;

  if (haveRows) {
    daySheets = rows.filter(
      (row) =>
        moment(row.from_date).format('yyyy-MM-DD') === moment(selectedDate).format('yyyy-MM-DD')
    );
    tableBodyTr = daySheets.map((row) => {
      dayHours += row.net_hours;
      return (
        <tr key={`${row.id}-tr`} onClick={() => onEdit(row.id, false)}>
          {renderRows(headers, row)}
        </tr>
      );
    });
  }

  return (
    <div className="b">
      <Listview
        rows={daySheets}
        rowsCount={rowsCount}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
      {daySheets && daySheets.length > 0 && (
        <div className="d-flex justify-content-end p-2 mt-2">
          <h3 className="text-success">Day Total: {format.asString(dayHours)} hrs</h3>
        </div>
      )}
    </div>
  );
};

export default TimekeeperTimerLsv;
