import React from 'react';
import { Alert, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { isEmpty } from 'lodash';
import {
  CollapsibleHeader,
  CollapsibleBody,
} from 'jsx/components/modules/projects/components/sampling_plans/Collapsible';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * This catches uncaught errors that occur in the render method of the children components.
 * It will display a generic error message to the user, and log the error to the console for debugging.
 */
class ContainerError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      isErrorOpen: false,
      isClipboardCopied: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // (future) TODO - this would be a perfect place to put error tracking, Sentry, Datadog, etc
    // You can capture user information and include it in the logs
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { hasError, error, errorInfo, isErrorOpen, isClipboardCopied } = this.state;
    const { text = '', realm } = this.props;

    const { contact } = realm?.currentApp?.help || {};
    let contactEmailInfo = '';

    if (!isEmpty(contact)) {
      contactEmailInfo = (
        <span>
          {' '}
          on{' '}
          <a href={`mailto:${contact?.email}?body=${errorInfo?.componentStack}`}>
            {contact?.email}
          </a>
        </span>
      );
    }

    const copyToClipboard = () => {
      navigator.clipboard.writeText(JSON.stringify(errorInfo?.componentStack));
      this.setState({ isClipboardCopied: true });

      setTimeout(() => {
        this.setState({ isClipboardCopied: false });
      }, 3000);
    };

    if (hasError) {
      return (
        <Alert color="danger" className="m-3">
          <div className="d-flex align-items-center">
            <Icon name="triangle-exclamation" className="mr-2 d-inline fa-3x" />
            <div className="pl-2">
              <div>
                <h4 className="d-inline alert-danger">Something went wrong.</h4>
                <p className="mb-0">
                  {!text &&
                    'The team are working to restore this functionality as soon as possible.'}
                  {!!text && text}
                </p>
              </div>
            </div>
          </div>
          <p className="pt-3">
            If this issue persists, please contact support{contactEmailInfo}. Include the following
            information in your message:
          </p>
          <div className="d-flex justify-space-between align-items-start">
            <div className="flex-grow-1 mr-3">
              <CollapsibleHeader
                toggle={() => this.setState({ isErrorOpen: !isErrorOpen })}
                label={`Error message - ${error && error.toString()}`}
              ></CollapsibleHeader>
            </div>
            <Button onClick={copyToClipboard} color="danger" outline style={{ minWidth: '180px' }}>
              <Icon name="clipboard-list" /> {!isClipboardCopied ? 'Copy to clipboard' : 'Copied'}
            </Button>
          </div>
          <CollapsibleBody isOpen={isErrorOpen}>
            <pre style={{ height: '300px', overflowY: 'scroll' }}>
              {errorInfo && errorInfo?.componentStack}
            </pre>
          </CollapsibleBody>
        </Alert>
      );
    }
    return this.props.children;
  }
}

/**
 * HOC that wraps the WrappedComponent and catches any errors that occur in the render
 * method of the children components. The component must be connected to the store in
 * order to access the realm object.
 * @param {*} WrappedComponent
 * @returns
 */
function withContainerError(WrappedComponent) {
  class WithContainerError extends React.Component {
    render() {
      return (
        <ContainerError {...this.props}>
          <WrappedComponent {...this.props} />
        </ContainerError>
      );
    }
  }
  WithContainerError.displayName = `WithContainerError(${getDisplayName(WrappedComponent)})`;

  return WithContainerError;
}

export default ContainerError;

export { withContainerError };
