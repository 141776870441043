import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import { insertItemInSequence } from 'jsx/lib/list';
import ReportSectionHeader from './ReportSectionHeader';
import ReportSectionRow from './ReportSectionRow';
import ReportSectionWidgets from './ReportSectionWidgets';
import FormInput from '../../../../core/form/components/FormInputSmall';
import { controls as controlsDefn } from '../../forms/report_section';

const ReportSection = (props) => {
  const { section, attributes, functions = {}, currentReloadValue = false, report_id } = props;

  const [showEdit, setShowEdit] = useState(false);
  const [sectionControls, setSectionControls] = useState(controlsDefn);

  const toggleEdit = () => {
    const controls = cloneDeep(sectionControls);
    controls.caption.value = section.caption;
    setSectionControls(controls);

    setShowEdit(true);
  };

  const onSave = () => {
    functions.updateSection(section.id, sectionControls);
    setShowEdit(false);
  };

  const onRemove = () => {
    /* eslint-disable-next-line no-alert */
    const confirmed = window.confirm('Remove section permanently?');
    if (confirmed) {
      functions.removeSection(section.id);
    }
  };

  const onAttribute = () => {
    functions.setAttributeModal(true, 'section', section.id);
  };

  const onCreateRow = () => {
    functions.createSectionRow(section.id);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const controls = cloneDeep(sectionControls);

    controls[name].value = value;
    setSectionControls(controls);
  };

  const renderSectionHeaders = () => {
    const headers = section.rows.filter(({ purpose }) => purpose === 'headers');
    return headers.map((header) => (
      <ReportSectionHeader
        key={`header-${header.id}`}
        header={header}
        attributes={attributes}
        functions={functions}
      />
    ));
  };

  const renderSectionRows = (onDragEnd) => {
    const filteredRows = section.rows.filter(({ purpose }) => purpose === 'data_row');
    const rows = filteredRows.sort((left, right) => left.sequence - right.sequence);
    if (attributes.reportEditable) {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(dropProvided) => (
              <div
                {...dropProvided.droppableProps}
                ref={dropProvided.innerRef}
                className="droppable-area"
              >
                {rows.map((row) => (
                  <Draggable
                    key={row.id}
                    draggableId={row.id}
                    index={row.sequence}
                    disableInteractiveElementBlocking={false}
                  >
                    {(dragProvided, snapshot) => (
                      <ReportSectionRow
                        report_id={report_id}
                        key={row.id}
                        isDragging={snapshot.isDragging}
                        draggableProps={dragProvided.draggableProps}
                        innerRef={dragProvided.innerRef}
                        dragHandleProps={dragProvided.dragHandleProps}
                        row={row}
                        attributes={attributes}
                        functions={functions}
                        currentReloadValue={currentReloadValue}
                      />
                    )}
                  </Draggable>
                ))}
                {dropProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
    return rows.map((row) => (
      <ReportSectionRow
        report_id={report_id}
        key={row.id}
        row={row}
        attributes={attributes}
        functions={functions}
        currentReloadValue={currentReloadValue}
      />
    ));
  };

  const renderSectionWidgets = () => {
    const rows = section.rows.sort((left, right) => left.sequence - right.sequence);
    return rows.map((row) => (
      <ReportSectionWidgets
        report_id={report_id}
        key={`widget-${row.id}`}
        row={row}
        attributes={attributes}
        functions={functions}
        currentReloadValue={currentReloadValue}
      />
    ));
  };
  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) {
      return;
    }
    const fromSequence = source.index;
    const toSequence = destination.index;

    if (fromSequence === toSequence) {
      return;
    }

    const prevSequences = section.rows
      .filter(({ purpose }) => purpose === 'data_row')
      .map(({ id, sequence }) => ({ id, sequence }));
    const updatedSequences = insertItemInSequence(fromSequence, toSequence, prevSequences);

    functions.reorderSectionRows(section.id, updatedSequences);
  };
  const renderSectionContent = () => {
    switch (section.row_type) {
      case 'widgets':
        return renderSectionWidgets();

      case 'rows':
      default:
        return renderSectionRows(onDragEnd);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between bg-light border-bottom border-corporate mt-2">
        {attributes.reportEditable && (
          <>
            <h5 className="m-1 p-1">
              {!showEdit && (
                <div onClick={toggleEdit} style={{ cursor: 'pointer' }}>
                  {section.caption}
                </div>
              )}

              {showEdit && (
                <div className="d-flex justify-content-start">
                  <FormInput
                    handleChange={handleChange}
                    control={sectionControls.caption}
                    className="mr-2"
                  />
                  <Icon name="check" className="text-success mr-3 mt-2" onClick={onSave} />
                  <Icon
                    name="x-mark"
                    className="text-danger mr-3 mt-2"
                    onClick={() => setShowEdit(false)}
                  />
                </div>
              )}
            </h5>
            {!showEdit && (
              <div className="m-2">
                <Icon
                  name="pen-to-square"
                  className="text-success mr-3"
                  onClick={() => toggleEdit()}
                  style={{ cursor: 'position' }}
                />

                {section.row_type === 'rows' && (
                  <Icon
                    title="Create a new section row"
                    name="plus"
                    className="text-success mr-3"
                    onClick={() => onCreateRow()}
                  />
                )}

                <Icon name="tags" className="text-primary mr-3 mt-2" onClick={onAttribute} />
                <Icon name="trash" className="text-danger mr-3" onClick={onRemove} />
              </div>
            )}
          </>
        )}

        {!attributes.reportEditable && (
          <h5 className="m-1 p-1">
            <div>{section.caption}</div>
          </h5>
        )}
      </div>

      {renderSectionHeaders()}
      {renderSectionContent(onDragEnd)}
    </>
  );
};

export default ReportSection;
