import React, { useState } from 'react';
import {
    Button
  } from 'reactstrap';
import Listview from "../../../core/form/components/Listview";
import ProjectPeriodModal from "../containers/ProjectPeriodModal";

const ProjectPeriodsLsv = (props) => {
  const {
    rows,
    project_id
  } = props;

  const iconName = 'clock';
  const emptyCaption = "No Reporting Periods found for this project"
  const [isOpen, setModal] = useState(false);
  const [id, setId] = useState(null);

  const edit = (id) => {
    setId(id);
    setModal(true);
  }

  const renderRows = (headers, row) => {
    let iconColour = "mr-3 "

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        default: return  (
          <td className={`${iconColour} ${header.classes}`} key={index}>{row[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const headers = [
    {caption: 'Name', field: 'name', classes:"text-left"},
    {caption: 'Start Date', field: 'start_date', classes:"text-left"},
    {caption: 'End Date', field: 'end_date', classes:"text-left"}
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => edit(row.id)} style={{cursor: "pointer"}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  return (
    <div>
      <div className="d-flex justify-content-end border-bottom border-light p-2" >
        <Button color="success" size="sm" onClick={() => edit(null)}>Add Period</Button>
      </div>
      <ProjectPeriodModal setModal={setModal} project_id={project_id} id={id} isOpen={isOpen}/>
      <Listview onClick={edit} rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption}/>
    </div>
  )
}

export default ProjectPeriodsLsv;
