import React from 'react';
import {
  Button,
  Collapse,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Row,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash';
import SingleSearch from '../../../core/form/components/SingleSearch';
import PillDateRange from '../../../core/form/components/PillDateRange';
import Pill from '../../../core/form/components/Pill';
import FieldTripModal from '../containers/FieldTripModal';
import FieldTripAssociateModal from '../containers/FieldTripAssociateModal';
import { bulkUpdateFieldTrip } from '../actions';

const FieldTripFilter = ({
  sites,
  filters,
  handleUpdateFilters,
  rowsCount,
  siteSelections = [],
  onSaveRedirect = noop,
}) => {
  const dispatch = useDispatch();
  const [toggleOpen, setToggleOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isNewFieldTripModalOpen, setIsNewFieldTripModalOpen] = React.useState(false);
  const [isExistingFieldTripModalOpen, setIsExistingFieldTripModalOpen] = React.useState(false);

  let startDate = '';
  let placeholder = 'Select a date';
  let filtersCount = 0;

  if (filters.from_collected_date !== '') {
    startDate = new Date(Date.parse(filters.from_collected_date));
    placeholder = '';
    filtersCount++;
  }

  return (
    <>
      <div className="bg-light p-2">
        <FieldTripModal
          isOpen={isNewFieldTripModalOpen}
          isNew={true}
          setModal={setIsNewFieldTripModalOpen}
          onSave={async (selectedFieldTrip) => {
            setIsNewFieldTripModalOpen(false);
            let success = await dispatch(
              bulkUpdateFieldTrip({
                fieldtrip_id: selectedFieldTrip,
                site_ids: siteSelections,
              })
            );
            if (success) {
              onSaveRedirect(selectedFieldTrip);
            }
          }}
        />
        <FieldTripAssociateModal
          isOpen={isExistingFieldTripModalOpen}
          onCancel={() => setIsExistingFieldTripModalOpen(false)}
          onSave={(selectedFieldTrip) => {
            setIsExistingFieldTripModalOpen(false);
            onSaveRedirect(selectedFieldTrip);
          }}
          selectedSites={siteSelections}
          title="Add to existing field trip"
        />
        <div className="d-flex justify-content-end rounded mb-1">
          <div className="d-flex justify-content-end">
            <div className="mr-2 input-group input-group-sm">
              <div className="mt-1 text-secondary mr-2 align-self-center">
                Showing {(sites.sites.rows || []).length} sites{' '}
                {rowsCount !== undefined && ` of ${rowsCount} results`}
              </div>
              <SingleSearch
                handleSearchChange={({ code, target }) => {
                  const searchTerm = target.value;
                  setSearchTerm(searchTerm);
                  if (code === 'Enter') {
                    handleUpdateFilters({ search_value: searchTerm });
                  }
                }}
                onSearch={() => handleUpdateFilters({ search_value: searchTerm })}
                placeholder="Search sites"
                value={searchTerm}
              />
              <Dropdown
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
                className="mr-2"
                disabled={siteSelections.length === 0}
              >
                <DropdownToggle caret>Add to field trip</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => setIsExistingFieldTripModalOpen(!isExistingFieldTripModalOpen)}
                  >
                    Add to existing field trip
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setIsNewFieldTripModalOpen(!isNewFieldTripModalOpen)}
                  >
                    Add to new field trip
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Button
                size="sm"
                color="warning"
                className="text-white"
                onClick={() => setToggleOpen(!toggleOpen)}
              >
                Filter
              </Button>
            </div>
          </div>
        </div>
        <Collapse isOpen={toggleOpen}>
          <Card className="p-1 pb-2 d-flex rounded mb-1">
            <Col sm={3} className="">
              <PillDateRange
                caption="Collected after"
                handleChange={(newDate) => handleUpdateFilters({ from_collected_date: newDate })}
                startDate={startDate}
                disabled={false}
                placeholder={placeholder}
              />
            </Col>
          </Card>
        </Collapse>
        {filtersCount !== 0 && (
          <div className="bg-light">
            <Row className="m-0 p-0">
              <Pill
                caption={filters.from_collected_date}
                onDelete={() => handleUpdateFilters({ from_collected_date: '' })}
                classes="bg-indigo d-flex float-left mt-1"
              />
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default FieldTripFilter;
