import { controlDefaults } from '../../../core/form/lib/validateForm';
import { columnWidths } from '../../portrait/constants/listviews';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  user_id: {
    ...controlDefaults,
    caption: 'Name',
    group: 'User',
    name: 'user_id',
    showInEditor: true,
    type: 'select',
    includeEmptyOption: true,
    validationRules: {
      isRequired: true,
    },
  },
  project_id: {
    ...controlDefaults,
    caption: 'Name',
    group: 'Project',
    name: 'project_id',
    showInEditor: true,
    type: 'select',
    includeEmptyOption: true,
    validationRules: {
      isRequired: true,
    },
  },
  from_date: {
    ...controlDefaults,
    caption: 'Start Date',
    group: 'Date Allocation',
    name: 'from_date',
    showInEditor: true,
    type: 'date',
    validationRules: {
      isRequired: true,
    },
  },
  to_date: {
    ...controlDefaults,
    caption: 'End Date',
    group: 'Date Allocation',
    name: 'to_date',
    showInEditor: true,
    type: 'date',
  },

  /** Listview controls */
  user: {
    ...controlDefaults,
    caption: 'User Name',
    fieldName: 'user.name',
    showInListview: true,
  },
  project_job_no: {
    ...controlDefaults,
    caption: 'Project Job No',
    fieldName: 'project.project_job_no',
    showInListview: true,
    width: `${columnWidths.common}px`,
  },
  project: {
    ...controlDefaults,
    caption: 'Project Name',
    fieldName: 'project.name',
    showInListview: true,
  },

  from_date_listview: {
    ...controlDefaults,
    caption: 'Start Date',
    fieldName: 'from_date',
    showInListview: true,
    type: 'date',
    width: `${columnWidths.common}px`,
    sortColumn: 'from_date',
  },
  to_date_listview: {
    ...controlDefaults,
    caption: 'End Date',
    fieldName: 'to_date',
    showInListview: true,
    type: 'date',
    width: `${columnWidths.common}px`,
    sortColumn: 'to_date',
  },
};
