import { connect } from 'react-redux';
import {
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
  List,
  PopoverHeader,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import Icon from 'jsx/components/core/icons/Icon';
import { isNil } from 'lodash';
import ReactSwitch from 'react-switch';

import withFeatureFlagCheck from 'jsx/components/core/features/withFeatureFlagCheck';
import EnterpriseDashboard from './EnterpriseDashboard';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';

import Business from './Business';
import BusinessConfig from './BusinessConfig';
import Agistments from './Agistments';
import SheepEnterprises from './SheepEnterprises';
import CattleEnterprises from './CattleEnterprises';
import SugarEnterprises from './SugarEnterprises';
import CroppingEnterprises from './cropping/CroppingEnterprises';
import OtherLivestockEnterprises from './OtherLivestockEnterprises';
import OtherBusinessEnterprises from './OtherBusinessEnterprises';
import OffFarm from './OffFarm';
import {
  fetchEnterpriseTransactionRanges,
  fetchEnterpriseIntervalRanges,
} from '../actions/enterprises';

import { fetchProperties } from '../../projects/actions/properties';
import WoolEnterprises from './wool/WoolEnterprises';
import FilterIntervalDatePicker from '../components/FilterIntervalDatePicker';
import { fetchAttributes } from '../actions/attributes';
import { fetchWarehouseUserProbes } from '../actions/warehouse';
import Benchmarks from './benchmarking/Benchmarks';

import { submitAnalysis } from '../../../manage/actions/admin';

class Enterprises extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      componentSelectedProperties: [],
      errorMessage: null,
      tabRefresh: false,
      intervalOptions: [],
      menuCollapsed: false
    };

    this.getDateValue = this.getDateValue.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.submitAnalysis = this.submitAnalysis.bind(this);
  }

  async componentDidMount() {
    await this.props.dispatch(fetchProperties());
    await this.props.dispatch(fetchAttributes({ type: 'transaction_intervals' }));
    await this.props.dispatch(fetchEnterpriseIntervalRanges());
    this.setRefresh(true);
  }

  goBack = () => {
    this.props.history.push('/home/portal');
  };

  toggleMenu() {
    this.setState({menuCollapsed: !this.state.menuCollapsed});
  }

  async submitAnalysis(currentOrg, selectedInterval) {
    await this.props.dispatch(submitAnalysis({org_name: currentOrg, transaction_date: selectedInterval?.transaction_date}));
    window.alert('Thank you - Your RCS analyst will be in touch once they have analysed your data');
  }

  onDateChange = (type, value) => {
    const range = {};
    // Check if the client has deleted the date, if so, do nothing.
    if (isNil(value)) return;

    let date = moment(value);
    switch (type) {
      case 'to_date': {
        date = date.endOf('month');
        break;
      }
      case 'from_date':
      default: {
        date = date.startOf('month');
        break;
      }
    }

    range[type] = date.format('YYYY-MM-DD');

    this.props.dispatch({ type: 'SET_ENTERPRISE_SELECTED_RANGES', payload: range });
    this.setRefresh(true);
  };

  onPropertyChange = (activePropertyFilters) => {
    /**
     * Filter out organisation, we do not want to store this in our selected properties array.
     * We want to store this as a separate reducer variable to be used in our API.
     */
    let propertyFilters = activePropertyFilters.map(({ value }) => value);
    const isOrganisationPropertySelected = propertyFilters.includes('organisation');
    if (isOrganisationPropertySelected) {
      propertyFilters = propertyFilters.filter((value) => value !== 'organisation');
    }

    this.setState({ componentSelectedProperties: activePropertyFilters }); // Keep active properties in component as is.
    this.props.dispatch({
      type: 'SET_ENTERPRISE_ORGANISATION_PROPERTY_SELECTED',
      payload: isOrganisationPropertySelected,
    });
    this.setSelectedProperties(propertyFilters);
    this.setRefresh(true);
  };

  getDateValue = (tag, value) => {
    let unitType;
    switch (tag) {
      case 'year':
      case 'half_year':
      case 'quarter': {
        unitType = 'quarter';
        break;
      }
      case 'month':
      default: {
        unitType = 'month';
      }
    }

    return moment(value).endOf(unitType).format('YYYY-MM-DD');
  };

  onIntervalDateChange = async (value) => {
    const { interval } = this.props.enterprises.selectedInterval;
    this.onIntervalChange(interval, value);
  };

  onIntervalChange = async (interval, date_value) => {
    let date;
    let intervalSelection;

    if (interval?.tag === 'all_time') {
      const allTimeRange = await this.props.dispatch(fetchEnterpriseTransactionRanges());
      date = moment(allTimeRange.to_date).endOf('month').format('YYYY-MM-DD');
      intervalSelection = { from_date: allTimeRange.from_date, transaction_date: date, interval };
    } else {
      date = this.getDateValue(interval?.tag, date_value);
      intervalSelection = { transaction_date: date, interval };
    }

    await this.props.dispatch({
      type: 'SET_ENTERPRISE_SELECTED_INTERVAL',
      payload: intervalSelection,
    });

    this.setRefresh(true);
  };

  setRefresh = (tabRefresh) => {
    this.setState({ tabRefresh });
  };

  setSelectedProperties = (payload) =>
    this.props.dispatch({
      type: 'SET_ENTERPRISE_SELECTED_PROPERTIES',
      payload,
    });

  toggleTab = async (tab) => {
    const { activeTab } = this.props.enterprises;

    if (activeTab !== tab) {
      await this.props.dispatch({ type: 'SET_ENTERPRISES_ACTIVE_TAB', payload: tab });

      // Clean up importing reducer if switching
      if (this.props.importing.filename) this.props.dispatch({ type: 'CLEAR_IMPORT_FILENAME' });

      if (this.props.importing.responseMessage)
        this.props.dispatch({ type: 'CLEAR_IMPORT_RESPONSE_MESSAGE' });

      if (this.props.importing.errorResponseMessage)
        this.props.dispatch({ type: 'CLEAR_IMPORT_ERROR_RESPONSE_MESSAGE' });
    }

    if (tab === 'benchmarks') {
      this.props.dispatch(fetchWarehouseUserProbes());
    }

    this.setRefresh(true);
  };

  setLivestockAssumptionToggle = (checked) => {
    this.props.dispatch({
      type: 'SET_LIVESTOCK_ASSUMPTION_TOGGLE',
      payload: checked,
    });

    this.setRefresh(true);
  };

  render() {
    const { tabRefresh, componentSelectedProperties, menuCollapsed } = this.state;
    const { isTrendAnalysisFeatureEnabled } = this.props;
    const { activeTab, authorised, authResponseMessage, enterprises, selectedInterval } =
      this.props.enterprises;
    const { isLivestockAssumptionsToggled } = this.props.livestocks;
    const { currentUser } = this.props.profile;

    const { transaction_intervals } = this.props.attributes;

    // let currentOrg = '';
    let title = '';
    let intervals = '';
    let currentOrg = '';
    if (currentUser.id) {
      currentOrg = `${currentUser.user_orgs[0].org.name}`;

      const properties = this.props?.properties?.properties?.rows ?? [];
      const enterpriseOptions =
        properties.length > 0
          ? [
              // Important! onPropertyChange is dependent on the value being 'organisation'.
              { id: 'organisation', value: 'organisation', label: 'Organisation' },
              ...properties.map((property) => ({
                ...property,
                value: property.id,
                label: property.name,
              })),
            ]
          : [];

      let intervalOptions =
        transaction_intervals.length > 0
          ? transaction_intervals
              .map((interval) => {
                if (interval.tag === 'day') return false;

                return {
                  ...interval,
                  value: interval.id,
                  label: interval.name,
                  tag: interval.tag,
                };
              })
              .filter(Boolean)
          : [];

      if (intervalOptions.length > 0) {
        intervalOptions.push({
          id: 'all_time',
          value: 'all_time',
          tag: 'all_time',
          label: 'All Time',
          name: 'All Time',
        });
      }

      // Handle benchmarks tab logic for intervals
      if (activeTab === 'benchmarks') {
        intervalOptions = intervalOptions.filter((interval) => interval.tag === 'year');
      }

      intervals = (
        <FilterIntervalDatePicker
          intervalOptions={intervalOptions}
          interval={selectedInterval.interval}
          transaction_date={selectedInterval.transaction_date}
          showAllTransactions={this.showAllTransactions}
          onIntervalDateChange={this.onIntervalDateChange}
          onIntervalChange={this.onIntervalChange}
          popperPlacement="bottom-end"
        />
      );
      title = (
        <div className="d-flex align-items-center" style={{ height: '20px' }}>
          <span className="pr-1">Enterprises for: </span>
          <Select
            value={componentSelectedProperties}
            defaultValue={[]}
            isMulti
            placeholder="Select properties to filter"
            name="property-select"
            options={enterpriseOptions || []}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={this.onPropertyChange}
          />
        </div>
      );
    }

    const submitMessage = `The data for ${currentOrg} for the selected date range will be submitted to your RCS Analyst for review.`;

    const showTab = [
      ...(new Set(enterprises.rows?.map((enterprise) => enterprise.division.tag)) ?? []),
    ];

    // Check if any livestock enterprises present
    const isLivestockEnterprise =
      showTab.includes('sheep') ||
      showTab.includes('cattle') ||
      showTab.includes('other_livestock');

    return (
      <div className="p-0 h-100">
        <ResponseMessage responseMessage={authResponseMessage} />

        {authorised && (
          <>
            <div className="bg-light p-2 border-bottom border-corporate d-flex justify-content-between">
              <div className="d-flex justify-content-start">
                <Button size="sm" color="success" onClick={this.goBack}>
                  <Icon size="1x" name="chevron-left" className="mr-2" />
                  Farm Profile
                </Button>

                <Button id="submitToggle" color="primary" size="sm" className="ml-2">
                  <Icon name="magnifying-glass-chart" className="mr-2" />
                  <span>Submit for Analysis</span>
                </Button>
                <UncontrolledPopover placement="bottom" target="submitToggle" trigger="focus">
                  <PopoverHeader className="text-center">Submit Probe for Analysis</PopoverHeader>
                  <PopoverBody>
                    <p className="p-0">
                      {submitMessage}
                    </p>
                    <div className="text-center">
                      <Button color="success" size="sm" onClick={() => this.submitAnalysis(currentOrg, selectedInterval)}>Submit Now</Button>
                    </div>
                    
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
              {activeTab !== 'benchmarks' && (
                <div className="d-flex justify-content-end">
                  <div className="d-flex mr-4 align-items-center">
                    <Icon
                      id="BenchmarkToggle"
                      size="1x"
                      name="info-circle"
                      className="p-0 mr-2"
                      title="Info"
                      as="button"
                    />
                    <h5 className="mt-2" style={{ fontSize: '16px' }}>
                      RCS Standardised Values:
                    </h5>
                    <UncontrolledPopover placement="bottom" target="BenchmarkToggle" trigger="hover">
                      <PopoverHeader className="text-center">RCS Standardised Values</PopoverHeader>
                      <PopoverBody>
                        <p className="p-0">
                          Cattle and Sheep Values ($/kg) will be substituted with the RCS Standardised Values ($/kg) when calculating Stockflow Valuations and Metrics within
                          Analytics/Reports.
                        </p>
                        <p>These can be seen in the following areas of FarmEye:</p>
                        <List type="unstyled" className="ml-2">
                          <li>
                            <Icon size="1x" name="chart-mixed" className="mr-1 text-corporate" />
                            Analytics/Reports
                          </li>
                          {showTab.includes('cattle') && (
                            <li>
                              <Icon size="1x" name="cow" className="mr-1 mt-2 text-corporate" />
                              Cattle Enterprises
                            </li>
                          )}
                          {showTab.includes('sheep') && (
                            <li className="font-bold">
                              <Icon size="1x" name="sheep" className="mr-1 mt-2 text-corporate" />
                              Sheep Enterprises
                            </li>
                          )}
                        </List>
                      </PopoverBody>
                    </UncontrolledPopover>
                    <ReactSwitch
                      className="ml-2"
                      height={18}
                      width={42}
                      onChange={(checked) => this.setLivestockAssumptionToggle(checked)}
                      checked={isLivestockAssumptionsToggled}
                    />
                  </div>
                  <h5 className="m-2 ml-2" style={{ fontSize: '16px' }}>
                    {title}
                  </h5>
                  <div className="d-flex justify-content-end">
                    <h5 className="m-2 ml-2" style={{ fontSize: '16px' }}>
                      {intervals}
                    </h5>
                  </div>
                </div>
              )}
            </div>
            <Row className="p-0 m-0" style={{ minHeight: '100vh' }}>
              {menuCollapsed && (
                <Col className="p-1 m-0 text-center" style={{maxWidth: 30, backgroundColor: '#eeeeee'}} onClick={this.toggleMenu}>
                  <Icon size="1x" name="chevron-right" className="mr-1 text-secondary" />
                </Col>
              )}
              
              {!menuCollapsed && (
                <Col className="p-0 m-0 verticalnav">
                  <Nav vertical className="mt-2">
                    <FormTab
                      caption="Business Configuration"
                      tabId="business_config"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                    />

                    {isLivestockEnterprise && (
                      <FormTab
                        caption="Agistments"
                        tabId="agistments"
                        activeTab={activeTab}
                        toggle={this.toggleTab}
                        disabled={false}
                      />
                    )}

                    {showTab.includes('sheep') && (
                      <>
                        <FormTab
                          caption="Sheep Enterprises"
                          tabId="sheep"
                          activeTab={activeTab}
                          toggle={this.toggleTab}
                          disabled={false}
                        />
                        <FormTab
                          caption="Wool Production and Inventory"
                          tabId="wool"
                          activeTab={activeTab}
                          toggle={this.toggleTab}
                          disabled={false}
                        />
                      </>
                    )}
                    {showTab.includes('cattle') && (
                      <FormTab
                        caption="Cattle Enterprises"
                        tabId="cattle"
                        activeTab={activeTab}
                        toggle={this.toggleTab}
                      />
                    )}
                    {showTab.includes('other_livestock') && (
                      <FormTab
                        caption="Other Livestock Enterprises"
                        tabId="other_livestock"
                        activeTab={activeTab}
                        toggle={this.toggleTab}
                      />
                    )}
                    {showTab.includes('sugar') && (
                      <FormTab
                        caption="Sugar Enterprises"
                        tabId="sugar"
                        activeTab={activeTab}
                        toggle={this.toggleTab}
                        disabled
                      />
                    )}
                    {showTab.includes('crop') && (
                      <FormTab
                        caption="Cropping Enterprises"
                        tabId="crop"
                        activeTab={activeTab}
                        toggle={this.toggleTab}
                      />
                    )}
                    {showTab.includes('other_business') && (
                      <FormTab
                        caption="Other Business Enterprises"
                        tabId="other_business"
                        activeTab={activeTab}
                        toggle={this.toggleTab}
                        disabled={false}
                      />
                    )}
                    <FormTab
                      caption="Off Farm Income"
                      tabId="off_farm"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      disabled={false}
                    />
                    <FormTab
                      caption="Business Information"
                      tabId="business_information"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      disabled={false}
                    />
                    <FormTab
                      caption="Analytics/Reports"
                      tabId="analytics"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      disabled={false}
                    />
                    <FormTab
                      caption="Benchmarks"
                      tabId="benchmarks"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                    />
                    <Button color="link" className="mt-2" style={{fontSize: 14}} onClick={this.toggleMenu}>
                      <Icon name="chevron-left" className="mr-2" />
                      Collapse
                    </Button>
                  </Nav>
                </Col>
              )}

              <Col className="h-100 verticalnav-tab-content">
                <TabContent activeTab={activeTab} className="h-100">
                  <TabPane tabId="business_config" className="p-1 h-100">
                    <BusinessConfig
                      isActive={activeTab === 'business_config'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="analytics" className="mb-2 p-2">
                    <EnterpriseDashboard
                      isActive={activeTab === 'analytics'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  {isLivestockEnterprise && (
                    <TabPane tabId="agistments" className="mb-2 p-2">
                      <Agistments
                        isActive={activeTab === 'agistments'}
                        forceRefresh={tabRefresh}
                        setRefresh={this.setRefresh}
                      />
                    </TabPane>
                  )}
                  <TabPane tabId="sheep" className="mb-2 p-2">
                    <SheepEnterprises
                      isActive={activeTab === 'sheep'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="cattle" className="mb-2 p-2">
                    <CattleEnterprises
                      isActive={activeTab === 'cattle'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="other_livestock" className="mb-2 p-2">
                    <OtherLivestockEnterprises
                      isActive={activeTab === 'other_livestock'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="sugar" className="mb-2 p-2">
                    <SugarEnterprises />
                  </TabPane>
                  <TabPane tabId="crop" className="mb-2 p-2">
                    <CroppingEnterprises
                      isActive={activeTab === 'crop'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="other_business" className="mb-2 p-2">
                    <OtherBusinessEnterprises
                      isActive={activeTab === 'other_business'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="off_farm" className="mb-2 p-2">
                    <OffFarm
                      isActive={activeTab === 'off_farm'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="business_information" className="mb-2 p-2">
                    <Business
                      isActive={activeTab === 'business_information'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                  <TabPane tabId="wool" className="mb-2 p-2">
                    <WoolEnterprises
                      isActive={activeTab === 'wool'}
                      forceRefresh={tabRefresh}
                      setRefresh={this.setRefresh}
                    />
                  </TabPane>
                    <TabPane tabId="benchmarks" className="mb-2 p-2 h-100">
                      <Benchmarks
                        isTrendAnalysisFeatureEnabled={isTrendAnalysisFeatureEnabled}
                        isActive={activeTab === 'benchmarks'}
                        forceRefresh={tabRefresh}
                        setRefresh={this.setRefresh}
                      />
                    </TabPane>
                </TabContent>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({
  attributes,
  clients,
  enterprises,
  farmportrait_portal,
  importing,
  livestocks,
  profile,
  properties,
  realm,
  warehouse
}) => ({
  attributes,
  clients,
  enterprises,
  importing,
  livestocks,
  portal: farmportrait_portal,
  profile,
  properties,
  realm,
  warehouse
});

const StoreConnectedComponent = connect(mapStoreToProps)(Enterprises);
const WithTrendAnalysisFeature = withFeatureFlagCheck(
  'trend-analysis',
  'isTrendAnalysisFeatureEnabled',
  StoreConnectedComponent,
);

export default WithTrendAnalysisFeature;
