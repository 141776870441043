import { KeyboardEvent } from 'react';

/**
 * @deprecated Use the `eventCodeEquals` function instead.
 * KeyboardEvent.keyCode is a deprecated browser property, and is not guaranteed to be reliable across browsers.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
 *
 * @type {{ enter: number; tab: number; up: number; down: number; }}
 */
export const keycodes = {
  enter: 13,
  tab: 9,
  up: 38,
  down: 40,
};

/**
 * Check if a keyboard event's code matches the desired user level action.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_code_values
 *
 * @param {string} desiredCode
 * @returns {<T>(event: any) => boolean}
 */
export const eventCodeEquals =
  (desiredCode: string) =>
  <T>(event: KeyboardEvent<T>) =>
    event.code === desiredCode;

export const eventCodeEqualsAny =
  (desiredCodes: string[]) =>
  <T>(event: KeyboardEvent<T>) =>
    desiredCodes.some((desiredCode) => eventCodeEquals(desiredCode)(event));

export const cancelledByUser = eventCodeEquals('Escape');
export const acceptedByUser = eventCodeEqualsAny(['Enter', 'NumpadEnter']);
