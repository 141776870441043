import { defaults } from '../../../../lib/genericReducer';
export default function reducer(state = {
  fetching: false,
  fetched: false,
  error: null,
  currentPeriod: {},
  periods: [],
  responseMessage: null
}, action) {

  state = {
    ...state,
    ...defaults(action)
  }

  switch (action.type) {
    case "FETCH_PERIODS_FULFILLED": {
      return {
        ...state,
        periods: action.payload
      }
    }
    case "CREATE_PERIOD_FULFILLED":
    case "UPDATE_PERIOD_FULFILLED":
    case "REMOVE_PERIOD_FULFILLED":
    case "FETCH_PERIOD_FULFILLED": {
      return {
        ...state,
        currentPeriod: action.payload
      }
    }
    default: break;
  }
  return state;
}
