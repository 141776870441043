import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Col,
  Row,
  Label,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import { cloneDeep, omit } from 'lodash';

import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from 'jsx/components/core/form/lib/validateForm';
import Icon from 'jsx/components/core/icons/Icon';
import FormBase from 'jsx/components/core/form/components/FormBase';
import FormInput from 'jsx/components/core/form/components/FormInput';
import FormInputSwitch from 'jsx/components/core/form/components/FormInputSwitch';
import TimeFormat from 'jsx/components/modules/office/lib/timeFormat';
import { fetchAttributes } from 'jsx/components/modules/portrait/actions/attributes';

import { controls as shiftSlotControls } from '../../forms/rosters/shift_slot';
import {
  createShiftSlot,
  fetchShiftSlot,
  removeShiftSlot,
  updateShiftSlot,
} from '../../actions/rosters/shiftSlots';

class ShiftSlotModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      id: null,
      isNew: false,
      isShiftSlotModalOpen: false,
      controls: cloneDeep(shiftSlotControls),
      shiftSlotId: null,
      title: 'Shift',
    };

    this.format = new TimeFormat();
    this.formatChars = {
      1: '[0-2]',
      2: '[0-9]',
      3: '[0-5]',
      4: '[0-9]',
    };
    this.mask = '12:34';
  }

  componentDidMount() {
    this.props.dispatch(fetchAttributes({ type: 'roster_shift_slot_types' }));
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const controls = initControls(cloneDeep(shiftSlotControls));
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Shift Slot',
      };

      if (this.props.id) {
        const { id } = this.props;
        const data = await this.props.dispatch(fetchShiftSlot({ id }));

        updatedState = {
          ...updatedState,
          controls: updateControls(controls, data),
          data,
          id,
          isNew: false,
          title: 'Edit Shift Slot',
        };
      }

      this.setState(updatedState);
    }
  }

  onClose = (refresh = true) => {
    this.props.setModal(false);
    if (refresh) {
      this.props.onRefresh();
    }
  };

  onRemove = async () => {
    const { data } = this.state;
    const confirmed = window.confirm('Removing Shift permanently. Continue?');
    if (!confirmed) return;

    const success = await this.props.dispatch(removeShiftSlot(data.id));
    if (!success) return;

    this.onClose();
  };

  validateTimes = (startDay, startTime, endDay, endTime) => {
    let message;
    let targetControl;

    if (startDay > endDay) {
      targetControl = 'end_day';
      message = 'End Day must be before Start Day';
    }

    if (startDay === endDay) {
      if (startTime >= endTime) {
        targetControl = 'end_time';
        message = 'End time should be later than the Start Time';
      }
    }

    /**
     * Handle out of 24 hr format
     * Do not need to handle start time as above case would trigger first
     */
    if (endTime > '24:00' || endTime < 0) {
      targetControl = 'end_time';
      message = 'End Time must be within 24 hour format';
    }

    return { message, targetControl };
  };

  onSave = async () => {
    const { controls, data, isNew } = this.state;
    let saveData = saveControls(controls, data);
    // Parse start/end day to integer
    if (saveData?.start_day) saveData.start_day = parseInt(saveData.start_day, 10);

    if (saveData?.end_day) saveData.end_day = parseInt(saveData.end_day, 10);

    const validation = await validateFormFieldControls(saveData, controls);
    let { isValid } = validation;
    const { updatedControls } = validation;

    // Validate days/times
    let startTime = updatedControls.start_time.value;
    let endTime = updatedControls.end_time.value;
    const startDay = updatedControls.start_day.value;
    const endDay = updatedControls.end_day.value;

    // Remove '_' before saving to DB
    if (startTime) startTime = startTime.replaceAll('_', '0');
    if (endTime) endTime = endTime.replaceAll('_', '0');

    // Validate start/end times
    const timeValidation = this.validateTimes(startDay, startTime, endDay, endTime);
    // Invalidate target control if message is present
    if (timeValidation.message) {
      isValid = false;
      updatedControls[timeValidation.targetControl] = {
        ...updatedControls[timeValidation.targetControl],
        valid: false,
        message: timeValidation.message,
      };
    }

    if (!isValid) {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
      return;
    }

    // Append shift_id to save data
    const { shiftId: shift_id } = this.props;
    saveData = { ...saveData, shift_id };

    let success;
    if (!isNew) {
      success = await this.props.dispatch(updateShiftSlot(saveData));
      if (success) {
        this.onClose();
        return;
      }
    }

    saveData = omit(saveData, ['id']);
    success = await this.props.dispatch(createShiftSlot(saveData));

    if (success) {
      this.onClose();
    }
  };

  setControlOptions = (controls) => {
    let updatedControls = cloneDeep(controls);
    const dayOptions = [
      { id: '0', name: '0' },
      { id: '1', name: '1' },
    ];
    const { roster_shift_slot_types } = this.props.attributes;
    const sortedShiftSlotTypes = roster_shift_slot_types.sort((current, next) => {
      if (current.name === 'Work') {
        return -1;
      }
      if (next.name === 'Work') {
        return 1;
      }
      return 0;
    });

    const typeOptions = {
      type_id: sortedShiftSlotTypes,
      start_day: dayOptions,
      end_day: dayOptions,
    };

    Object.entries(typeOptions).forEach(([key, options]) => {
      updatedControls = updateControlOptions(controls, key, options);
    });

    return updatedControls;
  };

  render() {
    let { controls } = this.state;
    const { isNew, title } = this.state;
    const { isOpen } = this.props;
    const { responseMessage } = this.props.rosters;
    const iconName = 'business-time';

    controls = this.setControlOptions(controls);

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <h5 className="my-2 text-corporate border-bottom">Attributes</h5>
            <Row className="bg-light m-0 pb-2">
              <Col sm={6}>
                <FormInput handleChange={this.handleChange} control={controls.caption} />
              </Col>
              <Col sm={3}>
                <FormInput handleChange={this.handleChange} control={controls.type_id} />
              </Col>
              <Col sm={3}>
                <FormInputSwitch
                  groupClassName="mt-1"
                  handleChange={this.handleChange}
                  control={controls.is_paid}
                />
              </Col>
            </Row>
            <h5 className="my-2 text-corporate border-bottom">Start/End</h5>
            <Row className="bg-light m-0 pb-2">
              <Col sm={3}>
                <FormInput handleChange={this.handleChange} control={controls.start_day} />
              </Col>
              <Col sm={3}>
                <Label
                  className="font-weight-bold"
                  style={{ paddingTop: 7, paddingBottom: 7, margin: 0 }}
                >
                  Start Time
                </Label>
                <InputMask
                  mask={this.mask}
                  onChange={this.handleChange}
                  formatChars={this.formatChars}
                  className="form-control"
                  name="start_time"
                  value={controls.start_time?.value ?? ''}
                />
                {!controls.start_time.valid && (
                  <small className="text-danger">
                    {controls.start_time.message}
                    {controls.start_time.message && <br />}
                  </small>
                )}
              </Col>
              <Col sm={3}>
                <FormInput handleChange={this.handleChange} control={controls.end_day} />
              </Col>
              <Col sm={3}>
                <Label
                  className="font-weight-bold"
                  style={{ paddingTop: 7, paddingBottom: 7, margin: 0 }}
                >
                  End Time
                </Label>
                <InputMask
                  mask={this.mask}
                  onChange={this.handleChange}
                  formatChars={this.formatChars}
                  className="form-control"
                  name="end_time"
                  value={controls.end_time?.value ?? ''}
                />
                {!controls.end_time.valid && (
                  <small className="text-danger">
                    {controls.end_time.message}
                    {controls.end_time.message && <br />}
                  </small>
                )}
              </Col>
            </Row>
            <Row className="bg-light m-0 pb-2"></Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={() => this.onClose(!isNew)}>
              {isNew ? 'Cancel' : 'Close'}
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, rosters }) => ({
  attributes,
  rosters,
});

export default connect(mapStoreToProps)(ShiftSlotModal);
