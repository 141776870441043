import { controlDefaults } from "../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  type: {
    ...controlDefaults,
    name: 'type',
    type: 'text',
    caption: 'Type',
    disabled: false,
    validationRules: {
      isRequired: true
    },
  },
  code: {
    ...controlDefaults,
    name: 'code',
    type: 'text',
    caption: 'Code',
    disabled: false,
    validationRules: {
      isRequired: true
    },
  },
  name: {
    ...controlDefaults,
    name: 'name',
    type: 'text',
    caption: 'Name',
    disabled: false,
    validationRules: {
      isRequired: true
    },
  },
  description: {
    ...controlDefaults,
    placeholder: '',
    name: 'description',
    type: 'textarea',
    textRows: 3,
    caption: 'Description',
    validationRules: {
      isRequired: false
    },
  },
  org_id:{
    ...controlDefaults,
    placeholder: '',
    name: 'org_id',
    type: 'select',
    caption: 'Org',
    validationRules: {
      isRequired: false
    },
  },
  sample_type_id:{
    ...controlDefaults,
    placeholder: '',
    name: 'sample_type_id',
    type: 'select',
    caption: 'Sample Type',
    validationRules: {
      isRequired: false
    },
  },
}