/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Icon from 'jsx/components/core/icons/Icon';

import ResponseMessageTab from '../../form/components/ResponseMessageTab';
import LoginMicrosoft from '../components/LoginMicrosoft';
import LoginLocal from '../components/LoginLocal';
import { login as loginAction, loginAzure as loginAzureAction } from '../actions';

import farmEyeLogo from '../../../../../images/farmeye/logo_icon_colour.png';
import carbonizerLogo from '../../../../../images/carbonizer/logo_icon.png';
import loginBackground from '../../../../../images/farmeye/login_background.jpg';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: {
        email: '',
        password: '',
      },
      enableAuthLocal: true,
      enableAuthAzure: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onAzureLogin = this.onAzureLogin.bind(this);
    this.onAzureError = this.onAzureError.bind(this);
    this.renderAppLogo = this.renderAppLogo.bind(this);
  }

  componentDidMount() {
    let { enableAuthLocal, enableAuthAzure } = this.state;

    // If set, this will allow us to configure multiple login preferences
    // eslint-disable-next-line no-undef
    const enabledAuthentication = process.env.REACT_APP_ENABLED_AUTHENTICATION;

    if (enabledAuthentication) {
      const enabled = enabledAuthentication.split(',');
      enableAuthLocal = enabled.includes('local') || null;
      enableAuthAzure = enabled.includes('azure') || null;
    } else {
      console.log('No authentication enabled');
    }

    this.setState({ enableAuthLocal, enableAuthAzure });
  }

  handleChange(element) {
    const { login } = this.state;
    login[element.target.id] = element.target.value;

    if (element.target) {
      this.setState({
        login,
      });
    }
  }

  renderAppLogo = ({ id, icon }) => {
    switch (id) {
      case 'farmportrait':
      case 'farmportrait_portal':
        return <img src={farmEyeLogo} alt="FarmEye" width={100} />;
      case 'carbonizer':
        return <img src={carbonizerLogo} alt="Carbonizer" width={50} />;
      case 'elementree':
      default:
        return <Icon size="5x" name={icon} className="text-corporate m-1" />;
    }
  };

  onLogin() {
    this.props.dispatch(loginAction(this.state.login));
  }

  async onAzureLogin(isAuthenticated, instance, accounts) {
    await this.props.dispatch(loginAzureAction(isAuthenticated, instance, accounts));
  }

  onAzureError(error) {
    this.props.dispatch({ type: 'SET_LOGIN_MESSAGE', payload: error });
  }

  render() {
    const { login, enableAuthAzure, enableAuthLocal } = this.state;
    const { loginMessage, authenticated, responseMessage } = this.props.auth;
    const { currentApp, mode } = this.props.realm;

    const pathname = this.props?.location?.state?.from || '/home';
    let from = { pathname };

    let { search } = window.location;
    search = search.substr(1).split('&');
    const params = {};
    if (search[0] !== '') {
      search.map((search_param) => {
        const param = search_param.split('=');
        // eslint-disable-next-line prefer-destructuring
        params[param[0]] = param[1];
        return false;
      });
    }

    const ignore_mode = params?.ignore_mode === 'true' || false;
    if (mode === 'maintenance' && window.location.pathname !== '/maintenance' && !ignore_mode) {
      return (
        <Redirect
          to={{
            pathname: '/maintenance',
            state: { from: window.location.pathname },
          }}
        />
      );
    }

    if (from.pathname === '/logout') from = { pathname: '/home' };
    if (
      (from.pathname === '/' || from.pathname === '/home') &&
      currentApp &&
      currentApp.defaultPage
    )
      from = { pathname: currentApp.defaultPage };

    if (authenticated && this.props.match.path === '/login') {
      return <Redirect to={from} />;
    }

    // Is this staging
    const showStagingLabel = JSON.parse(localStorage.getItem('staging'));

    return (
      <div
        className="container-fluid p-0 m-0 h-100 bg-light row align-items-center d-flex justify-content-center"
        style={{
          backgroundImage: `url(${loginBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Card className="p-0 login text-center border border-0">
          <CardHeader className="bg-corporate mb-4" />
          {this.renderAppLogo(currentApp)}
          <h3 className="text-secondary mt-2">{currentApp.title}</h3>
          {showStagingLabel && (
            <>
              <h3 className="text-danger">STAGING ENVIRONMENT</h3>
              <p className="text-danger">This is a testing environment only</p>
            </>
          )}

          {responseMessage && <ResponseMessageTab responseMessage={responseMessage} />}

          <div className="m-4">
            {enableAuthLocal && (
              <LoginLocal
                login={login}
                handleChange={this.handleChange}
                onLogin={this.onLogin}
                loginMessage={loginMessage}
                staging={showStagingLabel}
              />
            )}

            {enableAuthAzure && (
              <>
                {enableAuthLocal && <h5>----------- OR -----------</h5>}
                <LoginMicrosoft
                  onAzureError={this.onAzureError}
                  onAzureLogin={this.onAzureLogin}
                  authenticated={authenticated}
                />
              </>
            )}
          </div>
        </Card>
      </div>
    );
  }
}

const mapStoreToProps = ({ auth, realm }) => ({
  auth,
  realm,
});

export default connect(mapStoreToProps)(Login);
