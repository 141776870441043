import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'jsx/components/core/icons/Icon';

const HeaderMenuItem = (props) => {
  const {
    url,
    iconName,
    caption,
    location,
    iconsOnly,
    noIcons,
    classes
  } = props;

  let className = `d-flex justify-content-between app-header menu-item ${classes}`;
  if (location.pathname === url) {
    className += ' menu-item-active';
  }

  return (
    <React.Fragment>
      <Link to={url} className={className}>
        <span>
          {!noIcons && (
            <Icon name={iconName} className="mt-1 mr-4"/>
          )}
          {!iconsOnly && (
            <span>{caption}</span>
          )}
        </span>
      </Link>
    </React.Fragment>
  );
}

export default HeaderMenuItem;
