import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import DirectDownload from '../../../../core/form/components/DirectDownload';

const ProjectsMenu = (props) => {
  const { handleProjectStatusMap, toggleMiniMap, showMiniMap } = props;

  return (
    <React.Fragment>
      <div className="mt-1">
        <UncontrolledButtonDropdown>
          <DropdownToggle caret color="primary" size="sm">
            Action{' '}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="div" header className="bg-light">
              View Actions
            </DropdownItem>
            <DropdownItem tag="div" toggle={false} onClick={toggleMiniMap} className="ml-2 mr-2">
              <span>{showMiniMap ? 'Hide Map' : 'Show Map'}</span>
              <Icon name="location-pin" className="ml-2 mr-2" />
            </DropdownItem>

            <DropdownItem tag="div" divider />
            <DropdownItem tag="div" header className="bg-light">
              Download Actions
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                width={200}
                handleDownload={(onProgressChange) =>
                  handleProjectStatusMap(onProgressChange, true)
                }
                caption="Download Project Status Points"
                showLabel={true}
              />
            </DropdownItem>

            <DropdownItem tag="div" toggle={false}>
              <DirectDownload
                classNames="ml-2 mr-2"
                width={200}
                handleDownload={(onProgressChange) => handleProjectStatusMap(onProgressChange)}
                caption="Download Project Status Properties"
                showLabel={true}
              />
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </React.Fragment>
  );
};

export default ProjectsMenu;
