import React from 'react';
import {
  Label,
  Input
} from 'reactstrap';

const SectionFilterSelect = (props) => {
  let {
    selected,
    round_id,
    onChange,
    options,
    caption,
    disabled,
    allowEmpty
  } = props;

  if (allowEmpty === null) {
    allowEmpty = false
  }

  return (
    <React.Fragment>
        <div className="d-flex flex-rows justify-content-start m-1">
          <Label className="m-0 p-1" >{caption}:</Label>
          <Input
              type="select"
              bsSize="sm"
              style={{width: 150}}
              disabled={disabled}
              value={selected}
              onChange={(e) => onChange(e.target.value, round_id)}
            >
              {allowEmpty && (<option value="">{caption}</option>)}
              {options}
              
          </Input>
        </div>
    </React.Fragment>
  );
}

export default SectionFilterSelect;
