import Mapbox from 'mapbox-gl';

export const zoomToBounds = (map, coords, padding = 30) => {
  try {
    var bounds = coords.reduce(function (bounds, coord) {
      return bounds.extend(coord);
    }, new Mapbox.LngLatBounds(coords[0], coords[0]));

    map.fitBounds(bounds, {
      padding: padding,
    });
  } catch (error) {
    console.error(`Mapster Error: `);
    console.error(error);
  }
};

// Mapbox does not support React components so we have
// to build this silly thing.
export const buildReconHtmlPopup = (site) => {
  let images = site.images.map((image, index) => {
    const src = `data:image/jpg;base64,${image}`;
    return `<img src="${src}" style="width: 80px" />`;
  });
  images = `<div><div><b>Images: </b></div>${images}<div>`;

  let notes = 'No notes available';
  if (site.notes) {
    notes = `<div>${site.notes}</div>`;
  }
  notes = `<div><div><b>Notes: </b></div>${notes}<div>`;

  return `<div style="background-color: #eeeeee;">${notes}${images}</div>`;
};

export const drawLinesBetweenPoints = (coordinates) => {
  return {
    type: 'LineString',
    coordinates: coordinates,
  };
};

export const buildGeometryFromCoordinates = (coordinates) => {
  return {
    crs: {
      type: 'name',
      properties: {
        name: 'EPSG:4326',
      },
    },
    type: 'Point',
    coordinates: coordinates,
  };
};

export const buildFeature = (geometry, properties) => {
  return {
    type: 'Feature',
    geometry: geometry,
    properties: properties,
  };
};

export const buildFeatureCollection = () => {
  return {
    type: 'FeatureCollection',
    features: [],
  };
};

export const getDefaultLayer = (source_id, style, field) => {
  switch (style) {
    case 'MultiPolygon':
    case 'Polygon':
      return {
        id: `${source_id}-boundary`,
        type: 'fill',
        source: `${source_id}`,
        paint: {
          'fill-color': ['get', 'colour'],
          // eslint-disable-next-line no-constant-condition
          'fill-opacity': ['get', 'fillOpacity'] ? ['get', 'fillOpacity'] : 0.8,
          'fill-outline-color': ['get', 'outline'],
        },
        // 'filter': ['==', '$type', 'Polygon']
      };

    case 'PolyLabel':
      return {
        id: `${source_id}-labels`,
        type: 'symbol',
        source: `${source_id}`,
        minzoom: 10,
        layout: {
          'text-field': ['get', field ?? 'lotplan'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'text-size': 14,
        },
        paint: {
          'text-color': '#202',
          'text-halo-color': '#fff',
          'text-halo-width': 2,
        },
      };

    case 'Line':
      return {
        id: `${source_id}-lines`,
        type: 'line',
        source: `${source_id}`,
        paint: {
          'line-color': ['get', 'outline'],
          'line-opacity': 0.8,
          'line-width': 2,
        },
        // 'filter': ['==', '$type', 'Polygon']
      };

    case 'Point':
      return {
        id: `${source_id}-circle`,
        type: 'circle',
        source: `${source_id}`,
        paint: {
          'circle-stroke-color': ['get', 'outline'],
          'circle-stroke-width': ['get', 'strokeWidth'],
          'circle-radius': {
            base: 1.6,
            stops: [
              [20, 5],
              [22, 5],
            ],
          },
          'circle-color': ['get', 'colour'],
        },
      };

    case 'PointLabel':
      return {
        id: `${source_id}-circle-labels`,
        type: 'symbol',
        source: `${source_id}`,
        minzoom: 12,
        layout: {
          'text-field': ['get', 'labelCaption'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'text-size': 14,
        },
        paint: {
          'text-color': ['get', 'labelColour'],
          // "text-halo-color": "yellow",
          // "text-halo-width": 1
        },
      };

    case 'Heatmap':
      return {
        id: `${source_id}-heatmap`,
        type: 'heatmap',
        source: `${source_id}`,
        paint: {
          // increase weight as diameter breast height increases
          'heatmap-weight': {
            property: 'tonnes',
            type: 'exponential',
            stops: [
              [10, 0],
              [15, 1],
            ],
          },
          // increase intensity as zoom level increases
          'heatmap-intensity': {
            stops: [
              [11, 1],
              [20, 2],
            ],
          },
          // assign color values be applied to points depending on their density
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(236,222,239,0)',
            0.2,
            '#FFFC79',
            0.4,
            '#D4FB79',
            0.6,
            '#8EFA00',
            0.8,
            '#4F8F00',
          ],
          // increase radius as zoom increases
          'heatmap-radius': {
            stops: [
              [11, 15],
              [20, 110],
            ],
          },
          // decrease opacity to transition into the circle layer
          'heatmap-opacity': {
            default: 1,
            stops: [
              [14, 1],
              [15, 0],
            ],
          },
        },
      };

    default:
      console.log('No Layer found');
      break;
  }
};
export const getBoundary = (feature) => {
  const coords = [];

  if (!feature.geometry) return coords;

  if (feature.geometry.type === 'MultiPolygon' || feature.geometry.type === 'Polygon') {
    feature.geometry.coordinates.forEach((polygon) => {
      polygon[0].forEach((coord) => {
        coords.push(coord);
      });
    });
  }

  if (feature.geometry.type === 'Point') {
    coords.push(feature.geometry.coordinates);
  }

  return coords;
};

export const getCollectionBoundary = (featureCollection) => {
  let coords = [];

  featureCollection.features.forEach((feature) => {
    coords = coords.concat(getBoundary(feature));
  });
  return coords;
};

export const applyFilter = (map, features, sourceFilter) => {
  // Pickup map and original features from store

  const data = { type: 'FeatureCollection', features: [] };
  const coords = [];

  // Build feature selection
  data.features = features.filter(function (feature) {
    const { project_id } = feature.properties;
    let state = false;

    if (project_id === sourceFilter) {
      state = true;
    }

    return state;
  });

  data.features[0].geometry.coordinates.forEach((polygon) => {
    polygon[0].forEach((coord) => {
      coords.push(coord);
    });
  });

  // coords = data.features.map(feature => {
  //   return feature.geometry.coordinates;
  // })

  // Set filtered data directly on the map
  if (data.features.length > 0) {
    map.getSource('projects').setData(data);
  } else {
    // map.getSource('data').setData(store.state.map.geoJson);
  }

  // Zoom to bounds
  zoomToBounds(map, coords);
};
