import React from 'react';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import AreaSection from './AreaSection';
import AreaSectionOther from './AreaSectionOther';
import AreaMenu from './AreaMenu';
import Icon from 'jsx/components/core/icons/Icon';

const AreaLsv = (props) => {
  const {
    openAreaUpload,
    openSiteUpload,
    uploads,
    rows,
    history,
    match,
    periods,
    responseMessage,
    mapsterColours,
    checkAccess,
  } = props;

  let iconName = 'vector-square';
  let emptyCaption = 'No Areas found. Use the tools above to upload new areas.';
  if (periods.length === 0) {
    iconName = 'face-sad-tear';
    emptyCaption =
      'No Reporting Periods defined in this project. Need to first create reporting periods in Project page before creating a round.';
  }

  let ceaSections = <div />;
  let otherSections = <div />;
  const haveRows = rows && rows.length > 0 && periods.length > 0;
  if (haveRows) {
    ceaSections = rows
      .filter((row) => row.area_type.name === 'CEA')
      .map((row, index) => (
        <AreaSection
          match={match}
          history={history}
          key={index}
          section={row}
          mapsterColours={mapsterColours}
          checkAccess={checkAccess}
        />
      ));

    // Other sections are the EA and EAA sections
    otherSections = rows
      .filter((row) => row.area_type.name !== 'CEA')
      .map(({ name, area_type, area_ha }) => ({ name, area_type: area_type.name, area_ha }))
      .sort((a, b) => (a.area_type > b.area_type ? 1 : -1))
      .map((props, index) => <AreaSectionOther key={index} {...props} />);
  }

  return (
    <>
      <div className="d-flex justify-content-end p-1 bg-light rounded">
        <ResponseMessage responseMessage={responseMessage} />
        <AreaMenu
          handleAreaUpload={openAreaUpload}
          handleStrataUpload={(event, onProgressChange) =>
            uploads.handleStrataUpload(event, onProgressChange)
          }
          handleSiteUpload={openSiteUpload}
          allowStrata={haveRows}
          allowSite={haveRows}
          checkAccess={checkAccess}
        />
      </div>

      {ceaSections}

      {otherSections}

      {!haveRows && (
        <div className="p-5 text-center">
          <div>
            <Icon size="3x" name={iconName} className="text-corporate" />
          </div>
          <div className="mt-3">{emptyCaption}</div>
        </div>
      )}
    </>
  );
};

export default AreaLsv;
