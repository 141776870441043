import React from 'react';
import { connect } from "react-redux";
import FormBase from "../../../core/form/components/FormBase";
import CoreArchiveSamplesLsv from '../components/CoreArchiveSamplesLsv';
import CoreArchiveSamplesToolbar from '../components/CoreArchiveSamplesToolbar';
import ResponseMessage from "../../../core/form/components/ResponseMessageTab";

import {
    fetchArchivedScanSamples,
    uploadArchivedScanSampleAttributes
} from '../actions';

class CoreArchiveSamples extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      successMessage: null,
      limit: 30,
      coreId: null
    });

    this.refresh = this.refresh.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    const { limit } = this.state;
    this.props.dispatch(fetchArchivedScanSamples({ limit: limit }));
  }

  refresh(new_limit) {
    const { limit } = this.state;
    this.props.dispatch(fetchArchivedScanSamples({ limit: (new_limit || limit) }));
  }

  async handleUpload(event, onProgressChange) {
    // Reset message
    this.props.dispatch({ type: 'RESET_RESPONSE_MESSAGE'})

    if (event.target.files.length === 0) return false;

    const file = event.target.files[0];
    const { limit } = this.state;

    let formData = new FormData();
    formData.append('file', file);
    formData.append('file_type', 'internal_lab');

    const success = await this.props.dispatch(uploadArchivedScanSampleAttributes(formData, onProgressChange))
    if (success) {
      this.setState({successMessage: 'Uploaded CSV Successfully...'});
      this.props.dispatch(fetchArchivedScanSamples({ limit: limit }));

      // Hide success message after 5s
      window.setTimeout(() => {
        this.setState({successMessage: null});
      }, 3000)
    }
  }

  loadMore() {
    let { limit } = this.state;

    limit += 30;

    this.refresh(limit);
    this.setState({ limit })
  }

  onSearch(event) {
    event.preventDefault();
    const { limit } = this.state;
    this.props.dispatch(fetchArchivedScanSamples({ core_id: this.state.coreId, limit: limit }));
  }

  handleSearchChange(event) {
    const { limit } = this.state;

    this.setState({ coreId: event.target.value })

    // Force search on enter
    if (event.keyCode === 13) {
      const params = { core_id: event.target.value, limit: limit }
      this.props.dispatch(fetchArchivedScanSamples(params));
    }

    // Bring back all the scans
    if (!event.target.value) {
      const params = { limit: limit }
      this.props.dispatch(fetchArchivedScanSamples(params));
    }
  }

  render() {
    const {
      archivedScanSamples,
      responseMessage
    } = this.props.sites;

    const {
      successMessage
    } = this.state;

    return (
      <React.Fragment>
        <CoreArchiveSamplesToolbar
          handleUpload={this.handleUpload}
          refresh={this.refresh}
          onSearch={this.onSearch}
          handleSearchChange={this.handleSearchChange}
        />

        <ResponseMessage responseMessage={responseMessage} />
        <ResponseMessage responseMessage={successMessage} colour="text-success" />

        <CoreArchiveSamplesLsv
          rows={archivedScanSamples}
          onLoadMore={this.loadMore}
          pagination={true}
        />
      </React.Fragment>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    sites: store.sites
  }
}

export default connect(mapStoreToProps)(CoreArchiveSamples);
