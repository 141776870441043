import moment from 'moment';
import { useMemo } from 'react';
import { isEmpty, cloneDeep, get, toNumber } from 'lodash';
import { Controls, Option } from '.';

/**
 * Extracts the initial values from the controls and returns them as a key/value object
 * for use with the useForm hook.
 *
 * @param controls
 * @returns
 */
export const mapInitialValueToDefaultValue = (controls: Controls<string>) =>
  Object.entries(controls).reduce((acc: { [key: string]: string }, [key, value]) => {
    if (value.value) {
      acc[key] = value.value as string;
    }
    return acc;
  }, {});

/**
 * Zips an API response to the controls object. This is useful for when you want to quickly map initial values to the controls.
 * Wrapped in a Memo to avoid unnecessary re-renders.
 * @param controls
 * @param data
 * @returns
 */
export const useUpdateControls = (controls: Controls<string>, data?: Record<string, unknown>) =>
  useMemo(() => updateControls(controls, data), [controls, data]);

/**
 * Zips an API response to the controls object. This is useful for when you want to quickly map initial values to the controls.
 * @param controls
 * @param data
 * @returns
 */
export const updateControls = (controls: Controls<string>, data?: Record<string, unknown>) => {
  const mapped = cloneDeep(controls);

  // Set initial form values when projects are loaded
  if (!isEmpty(data)) {
    // Set initial values
    Object.keys(mapped).forEach((key) => {
      const mappedPath = mapped[key].path;
      if (mappedPath !== undefined) {
        const found = get(data, mappedPath);
        mapped[key].value = (found || mapped[key].value) as string;
      }
    });
  }

  return mapped;
};

/**
 * Helper that takes an object and maps it to the controls object
 * @param controls
 * @param mapToControls
 * @returns
 */
export function mapSelectOptionsToControls<T extends string>(
  controls: Controls<T>,
  mapToControls: any,
) {
  return Object.entries(controls).map(([id, control]) => {
    const castControl = control as any;
    if (castControl.input !== 'select') return control;
    return {
      ...castControl,
      options: mapToControls[id] || [],
    };
  });
}

/**
 * untouchedFields is a key value pair of the form { [key]: boolean } where the boolean indicates if the field has been touched.
 * If the field has not been touched, or there is no key for that field, it is stripped from the submit object
 * @param dirtyFields
 * @param data
 */
export const removeUntouchedFields = (
  data: Record<string, unknown>,
  untouchedFields: Record<string, boolean>,
) => {
  if (isEmpty(untouchedFields)) return data;

  return Object.entries(data).reduce((acc, [key, value]) => {
    if (!untouchedFields[key]) return acc;
    acc[key] = value;

    return acc;
  }, {} as Record<string, unknown>);
};

/**
 * By default react-hook-form will submit empty values as a key-value pair. This function strips untouched fields (using isDirty) from the data
 * object before submission. It handles defaulting values similarly to saveControls in validateForm.js
 * @param values
 * @returns
 */
export const prepareForSubmission = (values: Record<string, string>, form: Controls<string>) =>
  // REVISIT: we need to relate the types of the values via the key of the controls, using generics. Each case should know what type the value must be
  Object.entries(values).reduce((acc: Record<string, unknown>, [key, value]) => {
    const control = form[key];
    switch (control?.type) {
      case 'json': {
        acc[key] = JSON.stringify(value);
        break;
      }
      case 'number': {
        acc[key] = acc[key] === '' ? null : toNumber(value);
        break;
      }
      case 'date':
      case 'datetime':
      case 'datetime-local': {
        if (value === '' || value.toLowerCase() === 'invalid date') {
          acc[key] = null;
        } else if (value.length === 11) {
          const updatedDate = `${value.slice(0, 10)}T${moment().format('HH:mmZZ')}`;
          acc[key] = moment(updatedDate).format('YYYY-MM-DDTHH:mmZZ');
        } else {
          acc[key] = moment(value).format('YYYY-MM-DDTHH:mmZZ');
        }
        break;
      }
      case 'multiselect': {
        acc[key] = (value as unknown as Array<Option>).map((item) => item.value);
        break;
      }
      case 'select': {
        if (value === '-') {
          // a '-' value is used to indicate no selection in select controls
          acc[key] = null;
        } else {
          acc[key] = value;
        }
        break;
      }
      default: {
        acc[key] = value;
        break;
      }
    }
    return acc;
  }, {});
