import React, { useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { noop } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';

const ImagePreviewModal = ({
  imgSrc,
  title,
  isOpen,
  toggle,
  hasPrev = true,
  hasNext = true,
  viewPrev,
  viewNext,
  onClickOutside = noop,
}) => {
  const modalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef?.current?.contains(event.target)) {
        onClickOutside();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Modal isOpen={isOpen} size="lg">
      <div ref={modalRef}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name="image" className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>{imgSrc}</ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button size="sm" color="primary" onClick={viewPrev} disabled={!hasPrev}>
            Previous
          </Button>
          <Button size="sm" color="light" onClick={toggle}>
            Close
          </Button>
          <Button size="sm" color="primary" onClick={viewNext} disabled={!hasNext}>
            Next
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default ImagePreviewModal;
