import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  record_type: {
    ...controlDefaults,
    name: 'record_type',
    type: 'select',
    caption: 'Type',
    options: [
      { id: 'area_round_id', name: 'CEA' },
      { id: 'property_round_id', name: 'Round' },
    ],
    validationRules: {
      isRequired: true,
    },
    group: 'Type',
    showInEditor: true,
  },
  area_round_id: {
    ...controlDefaults,
    name: 'area_round_id',
    type: 'select',
    caption: 'CEA',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    group: 'Type',
    showInEditor: true,
  },
  property_round_id: {
    ...controlDefaults,
    name: 'property_round_id',
    type: 'select',
    caption: 'Round',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    group: 'Type',
    showInEditor: true,
  },
  status_id: {
    ...controlDefaults,
    name: 'status_id',
    type: 'select',
    caption: 'Status',
    validationRules: {
      isRequired: true,
    },
    group: 'Record',
    showInEditor: true,
  },
  job_no: {
    ...controlDefaults,
    name: 'job_no',
    type: 'text',
    caption: 'Job No',
    group: 'Record',
    showInEditor: true,
  },
  purchase_order_no: {
    ...controlDefaults,
    name: 'purchase_order_no',
    type: 'text',
    caption: 'Purchase Order No',
    group: 'Record',
    showInEditor: true,
  },
  transport_org_id: {
    ...controlDefaults,
    name: 'transport_org_id',
    type: 'select',
    caption: 'Transport',
    includeEmptyOption: true,
    group: 'Transport',
    showInEditor: true,
    description: `Create organisations in Settings >> Organisations. category_tag = 'transport'`,
  },
  transport_booking_no: {
    ...controlDefaults,
    name: 'transport_booking_no',
    type: 'text',
    caption: 'Transport Booking No',
    group: 'Transport',
    showInEditor: true,
  },
  sample_type_id: {
    ...controlDefaults,
    name: 'sample_type_id',
    type: 'select',
    caption: 'Sample Type',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    group: 'Sample',
    showInEditor: true,
  },
  sampled_by: {
    ...controlDefaults,
    name: 'sampled_by',
    type: 'text',
    caption: 'Sample Crew',
    group: 'Sample',
  },
  sampled_at: {
    ...controlDefaults,
    name: 'sampled_at',
    type: 'datetime-local',
    caption: 'Sample Date/Time',
    group: 'Sample',
  },
  test_requested_description: {
    ...controlDefaults,
    name: 'test_requested_description',
    type: 'textarea',
    textRows: 3,
    caption: 'Test Requested Description',
  },
  test_lab_code: {
    ...controlDefaults,
    name: 'test_lab_code',
    type: 'text',
    caption: 'Test Lab Code',
  },
  lab_org_id: {
    ...controlDefaults,
    name: 'lab_org_id',
    type: 'select',
    caption: 'Lab',
    includeEmptyOption: true,
    group: 'Lab',
    showInEditor: true,
    description: `Create organisations in Settings >> Organisations. category_tag = 'lab'`,
  },
  lab_quote_no: {
    ...controlDefaults,
    name: 'lab_quote_no',
    type: 'text',
    caption: 'Lab Quote No',
    group: 'Lab',
    showInEditor: true,
  },
  lab_job_no: {
    ...controlDefaults,
    name: 'lab_job_no',
    type: 'text',
    caption: 'Lab Job No',
    group: 'Lab',
    showInEditor: true,
  },
  contact_user_id: {
    ...controlDefaults,
    name: 'contact_user_id',
    type: 'select',
    caption: 'Lab Contact',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    group: 'Lab',
    showInEditor: true,
  },
  test_special_instructions: {
    ...controlDefaults,
    name: 'test_special_instructions',
    type: 'textarea',
    textRows: 3,
    caption: 'Special Instructions',
    group: 'Lab Extras',
    showInEditor: true,
  },
};
