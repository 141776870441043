/* eslint-disable id-length */
export const DEFAULT_LOCALE_STRING_FORMATTING = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const combineResults = (results, flatRows) => {
  let updatedResults = [];
  if (flatRows.length > 0) {
    flatRows.forEach((flatRow) => {
      if (results.length === 0) {
        updatedResults.push(flatRow);
      } else {
        updatedResults = updatedResults.concat(
          results.map((result) => ({ ...result, ...flatRow })),
        );
      }
    });
  } else {
    updatedResults = results;
  }
  return updatedResults;
};

const flattenRow = (row, prefix = null) => {
  let results = [];
  if (typeof row !== 'object' || row === null) return results;

  const keys = Object.keys(row);
  keys.forEach((i) => {
    const key = prefix === null ? i : `${prefix}.${i}`;
    let value = row[i];

    if (Array.isArray(value) && typeof value[0] === 'object' && value[0] !== null) {
      // Return flattened rows
      // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
      const flatRows = deepFlatten(value, key);

      // Expand current results table
      results = combineResults(results, flatRows);
    } else if (
      !Array.isArray(value) &&
      typeof value === 'object' &&
      value !== null &&
      !(value instanceof Date)
    ) {
      // Return flattened row (will be array)
      const flatRows = flattenRow(value, key);

      // Expand current results table
      results = combineResults(results, flatRows);
    } else {
      const outResult = {};
      if (Array.isArray(value)) {
        value = value.filter((n) => n);
        outResult[key] = value.join(',');
      } else outResult[key] = value;
      if (results.length === 0) results.push(outResult);
      else results = results.map((result) => ({ ...result, ...outResult }));
    }
  });

  return results;
};

/**
 * Rounds and formats a number to two decimal places using the default en locale.
 * Recommended for use with hectares for consistent formatting.
 */
export const hectareNumberFormat = (number) => {
  const formattedNumber = Intl.NumberFormat('en', DEFAULT_LOCALE_STRING_FORMATTING).format(number);
  if (formattedNumber === 'NaN') return '0';
  if (formattedNumber === '0.00') return '0'; // Special case for 0

  return formattedNumber;
};

// eslint-disable-next-line arrow-body-style
export const resolve = (path, obj) => {
  return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);
};

/**
 * Retrieves the value of the last child in the given path
 * @param {String} field  full path to value
 * @param {Object} row    Object containing all data
 * @returns
 */
export const getLastChildValue = (field, row) => {
  let parent = row;
  // eslint-disable-next-line no-restricted-syntax
  for (const key of field.split('.').values()) parent = key && parent ? parent[key] : null;
  return parent;
};

/**
 * Flattens an object into an array of objects
 */
export const deepFlatten = (rows, prefix = null) => {
  let results = [];
  if (Array.isArray(rows)) {
    rows.forEach((row) => {
      results = results.concat(flattenRow(row, prefix));
    });
  } else {
    results = results.concat(flattenRow(rows, prefix));
  }

  return results;
};
