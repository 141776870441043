import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  location_id: {
    ...controlDefaults,
    caption: 'Location',
    group: 'Asset Association',
    includeEmptyOption: true,
    name: 'location_id',
    showInEditor: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  project_id: {
    ...controlDefaults,
    caption: 'Project',
    group: 'Asset Association',
    includeEmptyOption: true,
    name: 'project_id',
    showInEditor: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  asset_id: {
    ...controlDefaults,
    caption: 'Asset',
    group: 'Asset Association',
    includeEmptyOption: true,
    name: 'asset_id',
    showInEditor: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  asset_no: {
    ...controlDefaults,
    placeholder: 'Asset #',
    name: 'asset_no',
    type: 'text',
    caption: 'Asset #',
    showInListview: true,
  },
  from_date: {
    ...controlDefaults,
    caption: 'Transfer Date',
    group: 'Date Allocation',
    name: 'from_date',
    showInEditor: true,
    showInListview: true,
    type: 'date',
    validationRules: {
      isRequired: true,
    },
  },
  to_date: {
    ...controlDefaults,
    caption: 'End Date',
    group: 'Date Allocation',
    name: 'to_date',
    showInEditor: true,
    showInListview: true,
    type: 'date',
  },
  status_id: {
    ...controlDefaults,
    caption: 'Status',
    group: 'Asset Contact and Status',
    includeEmptyOption: true,
    name: 'status_id',
    showInEditor: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  contact_id: {
    ...controlDefaults,
    caption: 'Contact',
    group: 'Asset Contact and Status',
    includeEmptyOption: true,
    name: 'contact_id',
    showInEditor: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
};
