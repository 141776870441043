import React from 'react';
import { UncontrolledCollapse, Card, Button } from 'reactstrap';
import FormInput from '../../../core/form/components/FormInput';

const LabRegisterRecordComment = (props) => {
  const { toggler, control, handleChange, onSave } = props;

  return (
    <UncontrolledCollapse toggler={toggler}>
      <Card className="border border-warning rounded p-1 pl-4 pr-4 mb-1">
        <FormInput handleChange={handleChange} control={control} />
        {onSave && (
          <div className="p-2 d-flex justify-content-center mt-2">
            <Button
              color="success"
              className="m-1"
              onClick={() => {
                onSave();
                control.value = '';
              }}
            >
              Save
            </Button>
          </div>
        )}
      </Card>
    </UncontrolledCollapse>
  );
};

export default LabRegisterRecordComment;
