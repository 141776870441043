import React from 'react';
import moment from 'moment';
import Listview from '../../../core/form/components/Listview';
import { name as phasecode_name } from '../lib/phasecode';

const AssetWorkActivitiesLsv = (props) => {
  const { rows, setModal } = props;

  const renderRows = (headers, row) => {
    const tableTd = headers.map(({ field, classes, style }, index) => {
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        Object.values(field.split('.')).forEach((key) => {
          parent = key && parent ? parent[key] : null;
        });
        caption = parent;
      }

      if (field === 'job_assignedto.firstname') {
        if (row?.job_assignedto?.firstname) {
          caption = `${row.job_assignedto.firstname} ${row.job_assignedto.lastname}`;
        } else {
          caption = '-';
        }
      }

      if (field === 'job_status.name') {
        if (row?.job_status?.name) {
          caption = `${row.job_status.name}`;
        } else {
          caption = '-';
        }
      }

      if (field === 'project_phasecode') {
        caption = phasecode_name(row.job_project, row.phasecode);
      }

      let priority;

      let updatedClasses = `${classes} text-light`;

      if (row.job_priority?.name) {
        switch (row.job_priority.name) {
          case 'Critical':
            updatedClasses += ' bg-danger ';
            priority = 5;
            break;
          case 'High':
            updatedClasses += ' bg-warning ';
            priority = 4;
            break;
          case 'Medium':
            updatedClasses += ' bg-success ';
            priority = 3;
            break;
          case 'Low':
            updatedClasses += ' bg-primary ';
            priority = 2;
            break;
          default:
            updatedClasses += ' bg-secondary ';
            priority = 1;
        }
      }

      switch (field) {
        case 'job_priority.name':
          return (
            <td
              role="presentation"
              key={index}
              className={updatedClasses}
              onClick={() => setModal(true, 'workactivities', row.id, row)}
              onKeyDown={() => setModal(true, 'workactivities', row.id, row)}
              data-sort-value={priority}
            >
              {caption}
            </td>
          );

        case 'created':
          return (
            <td
              role="presentation"
              key={index}
              className={classes}
              onClick={() => setModal(true, 'workactivities', row.id, row)}
              onKeyDown={() => setModal(true, 'workactivities', row.id, row)}
            >
              {caption ? moment(caption).format('YYYY-MM-DD') : ''}
            </td>
          );
        default:
          return (
            <td
              role="presentation"
              key={index}
              className={classes}
              style={style}
              onClick={() => setModal(true, 'workactivities', row.id, row)}
              onKeyDown={() => setModal(true, 'workactivities', row.id, row)}
            >
              {caption}
            </td>
          );
      }
    });

    return tableTd;
  };

  const iconName = 'engine';
  const emptyCaption = 'No Activities found';

  const headers = [
    { caption: 'Project/Phasecode', field: 'project_phasecode', classes: 'text-left' },
    { caption: 'Activity Name', field: 'name', classes: 'text-left' },
    { caption: 'Activity #', field: 'code', classes: 'text-left' },
    { caption: 'Created', field: 'created', classes: 'text-left' },
    { caption: 'Assignee', field: 'job_assignedto.firstname', classes: 'text-left' },
    { caption: 'Status', field: 'job_status.name', classes: 'text-left' },
    {
      caption: 'Priority',
      field: 'job_priority.name',
      classes: 'text-right',
    },
  ];

  const tableHeadTh = headers.map(({ classes, caption }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;

  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => (
      <tr key={index} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={rows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default AssetWorkActivitiesLsv;
