import { controlDefaults } from 'jsx/components/core/form/lib/validateForm';

export const controls = {
  period_id: {
    ...controlDefaults,
    placeholder: 'Analysis Period',
    name: 'period_id',
    fieldName: 'period_id',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    options: [],
    showInEditor: true,
    showInListview: true,
  },
  property_id: {
    ...controlDefaults,
    placeholder: 'Property',
    name: 'property_id',
    fieldName: 'property_id',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    options: [],
    showInEditor: true,
    showInListview: true,
  },
  group_id: {
    ...controlDefaults,
    placeholder: 'KPI Group',
    name: 'group_id',
    fieldName: 'group_id',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    options: [],
    showInEditor: true,
    showInListview: true,
  },
  metric_id: {
    ...controlDefaults,
    placeholder: 'Metric',
    name: 'metric_id',
    fieldName: 'metric_id',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    includeEmptyOption: true,
    options: [],
    showInEditor: true,
    showInListview: true,
  },
};
