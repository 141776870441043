import { Button } from 'reactstrap';
import SearchToolbar from 'jsx/components/core/form/components/SearchToolbar';
import { noop } from 'lodash';

const LocationsToolbar = ({ handleSearchChange = noop, onAddLocation = noop, onReload = noop }) => (
  <div className="d-flex justify-content-end bg-light rounded">
    <SearchToolbar
      handleSearchChange={handleSearchChange}
      includePlusButton={false}
      includeReloadButton
      includeGoButton
      onReload={onReload}
    />
    <Button size="sm" color="success" className="ml-2 my-2" onClick={() => onAddLocation(true)}>
      Add Location
    </Button>
  </div>
);

export default LocationsToolbar;
