import { get, post, put } from '../../../../lib/genericAction';

import { apiOrgPlantAssets } from '../../../../constants/api';

export function fetchPlantAssets(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_PLANT_ASSETS`, `${apiOrgPlantAssets}`, params);
}

export function fetchPlantAsset(id) {
  return async (dispatch) => await get(dispatch, `FETCH_PLANT_ASSET`, `${apiOrgPlantAssets}/${id}`);
}

export function createPlantAsset(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_PLANT_ASSET`, `${apiOrgPlantAssets}`, data);
}

export function updatePlantAsset(data) {
  return async (dispatch) =>
    await put(dispatch, `UPDATE_PLANT_ASSET`, `${apiOrgPlantAssets}/${data.id}`, data);
}

export function fetchPlantAssetAssociatedReadings(id) {
  return async (dispatch) =>
    await get(
      dispatch,
      `FETCH_PLANT_ASSET_ASSOCIATED_READINGS`,
      `${apiOrgPlantAssets}/${id}/readings`
    );
}
