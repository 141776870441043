import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { noop, isEmpty } from 'lodash';
import { HalfCircleSpinner } from 'react-epic-spinners';

import { hectareNumberFormat } from 'jsx/lib/generic';
import Icon from 'jsx/components/core/icons/Icon';
import RoundsLsv from 'jsx/components/modules/areas/components/RoundsLsv';
import { CollapsibleBody, CollapsibleHeader } from './Collapsible';
import InlineCell from './InlineCell';
import Pill from '../../../../core/form/components/Pill';

const findArea = (areas, filter) => {
  if (isEmpty(areas) || !areas.length) return 0;
  const area = areas.find(({ area_type }) => area_type === filter);
  return hectareNumberFormat(area?.area_ha);
};

const Loading = () => (
  <div className="p-2 d-flex">
    Loading <HalfCircleSpinner size={20} color="grey" className="ml-2 d-inline-block" />
  </div>
);

const LotCollapsible = ({
  lotId,
  toggle,
  getLots = noop,
  property,
  isOpen = noop,
  propertyStats,
}) => (
  <>
    <CollapsibleHeader
      id={lotId}
      toggle={toggle}
      getData={() => getLots(property.id)}
      propertyId={property.id}
      label="Lots"
      isOpen={isOpen(lotId)}
      summary={[
        {
          ...propertyStats?.lots?.count,
          label: 'Total Lots',
        },
        {
          ...propertyStats?.lots?.area_ha,
          label: 'Property Lots (ha)',
          value: hectareNumberFormat(propertyStats?.lots?.area_ha?.value),
        },
      ]}
    />
    <CollapsibleBody id={lotId} isOpen={isOpen(lotId)}>
      <div>
        {property?.lots?.fetching && <Loading />}
        {property?.lots?.fetched && !property?.lots?.data.length && <div>No lots found</div>}
        {property?.lots?.data.length > 0 && (
          <>
            {property?.lots?.fetching && <Loading />}
            {property?.lots?.data?.map(({ lotplan, area_ha, id }) => (
              <Pill
                key={id}
                caption={`${lotplan} (${area_ha} ha)`}
                classes="d-inline-flex bg-gray text-black m-1"
              />
            ))}
          </>
        )}
      </div>
    </CollapsibleBody>
  </>
);

const AreaCollapsible = ({
  areaId,
  toggle,
  getAreas = noop,
  property,
  isOpen = noop,
  propertyStats,
  mapsterColours,
}) => (
  <>
    <CollapsibleHeader
      id={areaId}
      toggle={toggle}
      propertyId={property.id}
      label="Area"
      getData={() => getAreas(property.id)}
      isOpen={isOpen(areaId)}
      summary={[
        { label: 'Property CEA (ha)', value: findArea(propertyStats?.areas, 'CEA') },
        { label: 'Property EA (ha)', value: findArea(propertyStats?.areas, 'EA') },
        { label: 'Property EAA (ha)', value: findArea(propertyStats?.areas, 'EAA') },
      ]}
    />
    <CollapsibleBody id={areaId} isOpen={isOpen(areaId)}>
      {property?.areas?.fetching && <Loading />}
      {property?.areas?.fetched && isEmpty(property?.areas?.data) && <div>No areas</div>}
      {propertyStats?.areas
        // It's more consistent with Area page to list CEA, EA, then EEA
        ?.sort(({ area_type: areaTypeA }, { area_type: areaTypeB }) => areaTypeA > areaTypeB)
        .map(({ area_type }) => (
          <div key={area_type}>
            {property?.areas?.data
              .filter(({ type }) => type === area_type)
              .map(({ id, name, type, area_ha }) => {
                let backgroundColor = mapsterColours.areas[parseInt(name, 10)];
                let textColour = 'text-white';
                if (area_type === 'EA' || area_type === 'EAA') {
                  backgroundColor = 'black';
                }
                if (backgroundColor === '#ffed4a') {
                  // increase legibility for yellow background
                  textColour = 'text-black';
                }
                return (
                  <Pill
                    key={id}
                    caption={`${type}-${name} (${area_ha} ha)`}
                    classes={`d-inline-flex m-1 ${textColour}`}
                    style={{ backgroundColor }}
                  />
                );
              })}
          </div>
        ))}
    </CollapsibleBody>
  </>
);

const RoundCollapsible = ({
  roundId,
  toggle,
  getRounds = noop,
  property,
  isOpen = noop,
  propertyStats = [],
}) => {
  const { areas } = propertyStats;
  return (
    <>
      <CollapsibleHeader
        id={roundId}
        toggle={toggle}
        propertyId={property.id}
        label="Rounds"
        getData={() => getRounds(property.id)}
        isOpen={isOpen(roundId)}
        summary={[{ label: 'Rounds', value: propertyStats?.property_rounds?.count }]}
      />
      <CollapsibleBody id={roundId} isOpen={isOpen(roundId)}>
        {property?.rounds?.fetching && <Loading />}
        {property?.rounds?.fetched && (
          <RoundsLsv rounds={property?.rounds?.data[0]} areas={areas} />
        )}
      </CollapsibleBody>
    </>
  );
};

const SamplingPlanProperty = ({
  property = {},
  index = 0,
  featureColours = {},
  getLots = noop,
  getAreas = noop,
  getRounds = noop,
}) => {
  const { colours: mapsterColours } = useSelector(({ mapster }) => mapster);
  const [openCollapsible, setOpenCollapsible] = useState([]);
  const toggle = (id) => {
    if (openCollapsible.includes(id)) {
      setOpenCollapsible(openCollapsible.filter((item) => item !== id));
    } else {
      setOpenCollapsible([...openCollapsible, id]);
    }
  };
  const propertyStats = property?.stats || {};
  const isOpen = (id) => openCollapsible.includes(id);

  // Increase legibility for yellow / light feature colours
  let textColourClass = 'text-white';
  if (featureColours[index] === '#ffed4a' || featureColours[index] === undefined) {
    textColourClass = 'text-black';
  }

  const lotId = `lots-${property.id}`;
  const areaId = `area-${property.id}`;
  const roundsId = `rounds-${property.id}`;

  return (
    <Card key={property.id} className="my-4">
      <CardHeader
        className={`${textColourClass} d-flex justify-content-between align-items-center`}
        style={{ backgroundColor: featureColours[index] }}
      >
        <div>
          <Icon name="farm" className="mr-2" />
          {property?.name}
          <small className="d-block">{property?.address}</small>
        </div>
        <div className="d-flex justify-content-end" style={{ minWidth: '250px' }}>
          <InlineCell
            label="Property Lots (ha)"
            value={hectareNumberFormat(propertyStats?.lots?.area_ha.value)}
            isValid={propertyStats?.lots?.area_ha.isValid}
            message={propertyStats?.lots?.area_ha.message}
          />
        </div>
      </CardHeader>
      <CardBody>
        <LotCollapsible
          lotId={lotId}
          toggle={toggle}
          getLots={getLots}
          property={property}
          isOpen={isOpen}
          propertyStats={propertyStats}
        />
        <AreaCollapsible
          areaId={areaId}
          toggle={toggle}
          getAreas={getAreas}
          property={property}
          isOpen={isOpen}
          propertyStats={propertyStats}
          mapsterColours={mapsterColours}
        />
        <RoundCollapsible
          id={roundsId}
          toggle={toggle}
          getRounds={getRounds}
          label="Rounds"
          property={property}
          isOpen={isOpen}
          propertyStats={propertyStats}
        />
      </CardBody>
    </Card>
  );
};

export default SamplingPlanProperty;
