import React, { useState } from 'react';
import {
  Row,
  Col,
  Button
} from 'reactstrap';
import FormInput from "../../../../core/form/components/FormInput";
import StageRequirementItemsLsv from "./StageRequirementItemsLsv";
import StageRequirementItemModal from "../../containers/StageRequirementItemModal";

const StageRequirementProfile = (props) => {
  const {
    controls,
    handleChange,
    handleDownload,
    onSave,
    onRemove,
    onClose,
    responseMessage,
    statuses,
    isNew,
    requirement_id,
    rows,
    unlockTemplate
  } = props;

  const [isOpen, setModal] = useState(false);
  const [id, setId] = useState(null);

  const editItem = (id) => {
    setId(id);
    setModal(true);
  }

  if (controls && controls['status_id']) {
    let blank = [{ id: null, name: 'Select Status' }]
    controls['status_id'].options = blank.concat(statuses);
  }

  controls.name.disabled = !unlockTemplate;
  controls.sequence.disabled = !unlockTemplate;

  return (
    <React.Fragment>
      <Row className="m-0 p-0 pb-2 border-bottom bg-light mt-1">
        <Col sm={12}>
          <FormInput handleChange={handleChange} control={controls.name} />
        </Col>
      </Row>

      <Row className="m-0 p-0 pb-2 border-bottom bg-light mt-1">
        <Col sm={3}>
          <FormInput handleChange={handleChange} control={controls.due} />
        </Col>
        <Col sm={3}>
          <FormInput handleChange={handleChange} control={controls.status_id} />
        </Col>
        <Col sm={3}>
          <FormInput handleChange={handleChange} control={controls.dependent_id} />
        </Col>
        <Col sm={3}>
          <FormInput handleChange={handleChange} control={controls.sequence} />
        </Col>
      </Row>

      <Row className="m-0 p-0 pb-2 border-bottom bg-light mt-1">
        <Col sm={12}>
          <FormInput handleChange={handleChange} control={controls.notes} />
        </Col>
      </Row>

      <div className="p-2 border-bottom bg-light mt-1">
        {!isNew && rows.length > 0 && (
          <div>
            <h5 className="mt-2 mb-0 text-corporate">Task/Document Status</h5>
            <p>Manage, append and alter these items in respective tabs. Each change is recorded in activity.</p>
          </div>
        )}

        <StageRequirementItemsLsv
          editItem={editItem}
          handleDownload={(id, event) => handleDownload(id, event)}
          rows={rows || []}
        />
      </div>

      {responseMessage && (
        <div className="text-danger text-right m-2">{responseMessage}</div>
      )}

      <div className="d-flex justify-content-between mt-2">
        <div className="d-flex justify-content-start">
          <StageRequirementItemModal
            setModal={setModal}
            requirement_id={requirement_id}
            id={id}
            isOpen={isOpen}
            statuses={statuses}
          />
          {!isNew && unlockTemplate && (
            <Button color="primary" size="sm" onClick={() => editItem(null)}>Add Requirement Item</Button>
          )}
        </div>
        <div className="d-flex justify-content-end">
          {!isNew && unlockTemplate && (<Button color="danger" size="sm" onClick={() => onRemove()}>Delete</Button>)}
          <Button className="ml-2" size="sm" color="light" onClick={() => onClose()}>Close</Button>
          <Button className="ml-2" size="sm" color="success" onClick={() => onSave()}>Save Changes</Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StageRequirementProfile;
