import React from 'react';
import { connect } from 'react-redux';
import { Collapse, Card, Col, Row } from 'reactstrap';
import PillPicker from '../../../core/form/components/PillPicker';
import PillPresenter from '../../../core/form/components/PillPresenter';
import { setLiabilityflowFilters } from '../actions/liabilities';

class LiabilityFilter extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleChange(event) {
    const { value } = event.target;
    let { filters, params } = this.props.liabilities;

    // Add filter to categories
    const exists = filters.categories.find((id) => id === value);
    if (!exists) filters.categories.push(value);

    // Update reducer
    this.props.dispatch(setLiabilityflowFilters(params, filters));
  }

  handleDelete(id) {
    let { filters, params } = this.props.liabilities;

    // Remove filter from categories
    const idx = filters.categories.findIndex((filterId) => filterId === id);
    if (idx !== -1) filters.categories.splice(idx, 1);

    // Update reducer
    this.props.dispatch(setLiabilityflowFilters(params, filters));
  }

  render() {
    const { filterOpen, categories } = this.props;
    const { filters } = this.props.liabilities;

    const caption = 'Category';
    const selection = filters.categories;

    const filtersCount = Object.keys(filters)
      .map((key) => filters[key].length)
      .reduce((a, b) => a + b);

    return (
      <React.Fragment>
        <Collapse isOpen={filterOpen}>
          <Card className="border border-corporate rounded p-1 mb-1">
            <Row className="m-0 p-0">
              <Col sm={4} className="bg-light m-1">
                <PillPicker
                  caption={caption}
                  handleChange={this.handleChange}
                  handleDelete={this.handleDelete}
                  rows={categories}
                  selection={selection}
                  showPills={false}
                />
              </Col>
            </Row>
          </Card>
        </Collapse>
        {filtersCount > 0 && (
          <div className="bg-light p-1 pb-2 d-flex rounded mb-1">
            <PillPresenter
              caption={caption}
              handleDelete={this.handleDelete}
              rows={categories}
              selection={selection}
              colourField="colour"
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    liabilities: store.liabilities,
  };
};

export default connect(mapStoreToProps)(LiabilityFilter);
