import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  project_id: {
    ...controlDefaults,
    placeholder: 'Existing Projects',
    name: 'project_id',
    type: 'select',
    caption: 'Select existing Project',
    options: [],
    validationRules: {},
  },
  property_id: {
    ...controlDefaults,
    placeholder: 'Existing Properties for Project',
    name: 'property_id',
    type: 'select',
    caption: 'Select existing Property',
    options: [],
    validationRules: {},
  },
  project_name: {
    ...controlDefaults,
    placeholder: 'New Project Name',
    name: 'project_name',
    type: 'text',
    caption: 'New Project',
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  },
  project_code: {
    ...controlDefaults,
    placeholder: 'Project Code',
    name: 'project_code',
    type: 'text',
    caption: 'Project Code',
    value: '2020-07-01',
    description: 'Unique abriviated project code for prefixing cores keys. Must be exactly 4 digits. Required Field',
    validationRules: {
      minLength: 4,
      maxLength: 4,
      isRequired: true
    },
  },
  client_id: {
    ...controlDefaults,
    placeholder: 'HubSpot CRM Client',
    name: 'client_id',
    type: 'select',
    caption: 'CRM Client',
    description: 'Clients are records in CRM Companies. Creating a new Client will add a record to CRM and associate with this project',
    options: [],
    validationRules: {
      isRequired: true
    },
  },
  client_name: {
    ...controlDefaults,
    placeholder: 'New Client Name',
    name: 'client_name',
    type: 'text',
    caption: 'New Client',
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  },
}