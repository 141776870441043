/* eslint-disable no-param-reassign */
import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    tabId: 1,
    activeTab: '1',
    activeRoundTab: '1',
    activePropertySearchTab: '1',
    analysisData: { count: 0, rows: [] },
    currentProperty: {},
    currentPropertyLots: [],
    currentPropertyRound: {},
    currentPropertyInsights: [],
    currentInsightsLot: null,
    batches: [],
    massResults: [],
    massComparisons: [],
    params: {
      search_value: '',
    },
    properties: [],
    propertyRounds: [],
    propertyStats: [],
    insightsMessage: null,
    responseMessage: null,
    selectedSource: null,
    selectedSources: [],
    librarySources: [],
    propertyAnalysis: {
      selectedAdvisor: null,
      selectedAnalysisPeriod: null,
      selectedRCSStatus: null,
    },
  },
  action,
) {
  // Set expected reducer types
  const types = [
    'PROPERTY',
    'PROPERTIES',
    'LIBRARY_SOURCE',
    'SELECTED_SOURCE',
    'PROPERTY_ANALYSIS',
  ];

  if (!allowed(types, action)) return state;
  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'UPDATE_PROPERTY_INSIGHTS_TOPIC': {
      const { payload } = action;
      if (state.currentProperty.id === payload.property_id) {
        return {
          ...state,
          currentInsightsStatus: payload.status,
          currentInsightsLot: payload.lotplan ?? null,
          insightsMessage: action.payload.message,
        };
      }
      break;
    }
    case 'GENERATE_PROPERTY_INSIGHTS_FULFILLED':
      return {
        ...state,
        responseMessage: action.payload.message,
      };

    case 'FETCH_PROPERTY_INSIGHTS_FULFILLED':
      return {
        ...state,
        currentPropertyInsights: action.payload,
      };

    case 'UNSET_PROPERTY_INSIGHTS':
      return {
        ...state,
        currentPropertyInsights: [],
      };

    case 'FETCH_LIBRARY_SOURCES_FULFILLED':
    case 'SET_LIBRARY_SOURCES': {
      let sources = [];
      if (action.payload?.length > 0)
        sources = action.payload.map((source) => {
          // Set layers based on defaults.
          if (source.defaults?.layers?.length > 0 && !source.layers) {
            source.layers = source.defaults.layers;
          }
          // Set available styles in raster layers.
          if (source.styles?.length > 0) {
            const idx = source.layers?.findIndex(
              (layer) => layer.type === 'raster' && layer.styles === undefined,
            );
            if (idx > -1) {
              source.layers[idx].styles = {
                value: source.styles[0],
                options: source.styles.map((style) => ({ id: style, name: style })),
              };
            }
          }
          return source;
        });

      return {
        ...state,
        librarySources: sources,
      };
    }
    case 'UNSET_SELECTED_SOURCES': {
      return {
        ...state,
        selectedSources: [],
      };
    }
    case 'SET_SELECTED_SOURCES': {
      return {
        ...state,
        selectedSources: action.payload,
      };
    }
    case 'SET_SELECTED_SOURCE': {
      return {
        ...state,
        selectedSource: action.payload,
      };
    }
    case 'SET_PROPERTY_SEARCH_ACTIVE_TAB': {
      return {
        ...state,
        activePropertySearchTab: action.payload,
      };
    }
    case 'SET_PROPERTY_ACTIVE_TAB': {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case 'SET_PROPERTY_ROUND_ACTIVE_TAB': {
      return {
        ...state,
        activeRoundTab: action.payload,
      };
    }

    case 'FETCH_PROPERTY_ROUND_BATCHES_FULFILLED': {
      return {
        ...state,
        batches: action.payload,
      };
    }

    case 'UPDATE_PROPERTY_ROUND_BATCH_TOPIC': {
      const batch = action.payload;

      const { id } = batch;
      const { batches } = state;
      const idx = batches.findIndex((b) => b.id === id);
      if (idx > -1) batches[idx] = batch;
      else batches.unshift(batch);
      return {
        ...state,
        batches,
      };
    }

    case 'UNSET_PROPERTY_LOTS': {
      return {
        ...state,
        currentPropertyLots: [],
      };
    }
    case 'FETCH_PROPERTIES_FULFILLED': {
      return {
        ...state,
        properties: action.payload,
      };
    }
    case 'FETCH_PROPERTY_FULFILLED': {
      return {
        ...state,
        currentProperty: action.payload,
      };
    }
    case 'UNSET_PROPERTY': {
      return {
        ...state,
        currentProperty: {},
      };
    }
    case 'CREATE_PROPERTY_FULFILLED':
      return {
        ...state,
        currentProperty: action.payload,
      };
    case 'UPDATE_PROPERTY_FULFILLED':
    case 'REMOVE_PROPERTY_FULFILLED': {
      return {
        ...state,
        currentProperty: {},
      };
    }
    case 'FETCH_PROPERTY_ROUNDS_FULFILLED': {
      return {
        ...state,
        propertyRounds: action.payload,
      };
    }
    case 'FETCH_PROPERTY_ROUND_FULFILLED': {
      return {
        ...state,
        currentPropertyRound: action.payload,
      };
    }
    case 'UNSET_PROPERTY_ROUND':
    case 'CREATE_PROPERTY_ROUND_FULFILLED':
    case 'UPDATE_PROPERTY_ROUND_FULFILLED':
    case 'REMOVE_PROPERTY_ROUND_FULFILLED': {
      return {
        ...state,
        currentPropertyRound: {},
      };
    }
    case 'UPLOAD_PROPERTY_LOTS_FULFILLED':
    case 'FETCH_PROPERTY_LOTS_FULFILLED': {
      return {
        ...state,
        currentPropertyLots: action.payload,
      };
    }
    case 'UNSET_PROPERTY_MASS_ROUND': {
      return {
        ...state,
        massResults: [],
      };
    }
    case 'UNSET_PROPERTY_COMPARISON': {
      return {
        ...state,
        massComparisons: [],
      };
    }
    case 'FETCH_PROPERTY_MASS_ROUND_FULFILLED': {
      return {
        ...state,
        massResults: action.payload,
      };
    }
    case 'FETCH_PROPERTY_COMPARISON_FULFILLED': {
      return {
        ...state,
        massComparisons: action.payload,
      };
    }
    case 'FETCH_PROPERTIES_STATS_FULFILLED': {
      return {
        ...state,
        propertyStats: action.payload,
      };
    }
    case 'DOWNLOAD_PROPERTY_MAP_PACK_FULFILLED': {
      return {
        ...state,
        responseMessage: action.payload.message,
      };
    }
    case 'DOWNLOAD_PROPERTY_REPORTING_PACK_FULFILLED': {
      return {
        ...state,
        responseMessage: action.payload.message,
      };
    }
    case 'FETCH_PROPERTY_ANALYSIS_FULFILLED': {
      return {
        ...state,
        analysisData: action.payload,
      };
    }
    case 'SET_PROPERTY_ANALYSIS_PARAMS': {
      return {
        ...state,
        params: action.payload,
      };
    }
    case 'SET_PROPERTY_ANALYSIS_SELECTED_ADVISOR': {
      return {
        ...state,
        propertyAnalysis: {
          ...state.propertyAnalysis,
          selectedAdvisor: action.payload,
        },
      };
    }
    case 'SET_PROPERTY_ANALYSIS_SELECTED_ANALYSIS_PERIOD': {
      return {
        ...state,
        propertyAnalysis: {
          ...state.propertyAnalysis,
          selectedAnalysisPeriod: action.payload,
        },
      };
    }
    case 'SET_PROPERTY_ANALYSIS_SELECTED_RCS_STATUS': {
      return {
        ...state,
        propertyAnalysis: {
          ...state.propertyAnalysis,
          selectedRCSStatus: action.payload,
        },
      };
    }
    case 'SET_PROPERTY_RESPONSE_MESSAGE': {
      return {
        ...state,
        responseMessage: action.payload,
      };
    }
    default:
      break;
  }

  return state;
}
