import { forwardRef } from 'react';
import { FormGroup, Col, Input, Label } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import FormInput from './FormInput';

const CellIntervalDatePicker = ({
  index,
  className,
  handleChange,
  controls,
  intervals,
  intervalKey,
  dateKey,
  row,
}) => {
  const interval = controls[intervalKey] ?? null;
  if (interval) {
    interval.options = intervals?.map((option) => ({
      id: option.id ?? option.tag,
      name: option.name,
    }));
    if (!interval.value && interval.options?.length > 0) interval.value = interval.options[0].id;
  }
  const date = controls[dateKey] ?? null;

  const getDateValue = (tag, value) => {
    let unitType;
    switch (tag) {
      case 'year':
      case 'half_year':
      case 'month': {
        unitType = 'month';
        break;
      }
      case 'quarter': {
        unitType = 'quarter';
        break;
      }
      case 'day':
      default: {
        unitType = 'day';
      }
    }

    return moment(value).endOf(unitType).format('YYYY-MM-DD');
  };

  const onDateChange = (tag, value) => {
    handleChange(
      {
        target: {
          name: dateKey,
          value: getDateValue(tag, value),
        },
      },
      row,
    );
  };

  const onIntervalChange = (event, date_value) => {
    handleChange(event, row);
    const tag = intervals?.find((option) => option.id === event.target.value)?.tag;
    onDateChange(tag, date_value);
  };

  let options = {
    className: 'form-control',
    dateFormat: 'dd/MM/yyyy',
  };
  const tag = intervals?.find((option) => option.id === interval.value)?.tag;
  const date_value = date.value ? new Date(date.value) : null;
  switch (tag) {
    case 'year':
    case 'half_year': {
      // Create custom input to display dates in a range
      // eslint-disable-next-line react/display-name
      const RangeInput = forwardRef(({ startDate, endDate, onClick }, ref) => (
        <Input
          onClick={onClick}
          ref={ref}
          // eslint-disable-next-line
          onChange={() => {}}
          value={`${startDate} - ${endDate}`}
        />
      ));

      const startDate = new Date(
        moment(date_value)
          .subtract(tag === 'year' ? 11 : 5, 'months')
          .format('YYYY-MM-DD'),
      );
      const endDate = date_value;
      const dateFormat = 'MMMM yyyy';
      options = {
        ...options,
        startDate,
        endDate,
        showMonthYearPicker: true,
        showFullMonthYearPicker: true,
        showTwoColumnMonthYearPicker: true,
        customInput: (
          <RangeInput
            startDate={moment(startDate).format(dateFormat)}
            endDate={moment(endDate).format(dateFormat)}
          />
        ),
      };
      break;
    }
    case 'quarter': {
      options = {
        ...options,
        dateFormat: 'yyyy, Qo (MMM)',
        showQuarterYearPicker: true,
      };
      break;
    }
    case 'month': {
      options = {
        ...options,
        dateFormat: 'MMMM yyyy',
        showMonthYearPicker: true,
        showFullMonthYearPicker: true,
        showTwoColumnMonthYearPicker: true,
      };
      break;
    }
    case 'day':
    default: {
      break;
    }
  }

  const intervalTableData = interval ? (
    <td key={`${index}-${intervalKey}-cell`} className={className}>
      <FormInput handleChange={(event) => onIntervalChange(event, date_value)} control={interval} />
    </td>
  ) : null;

  const dateTableData = date ? (
    <td key={`${index}-${dateKey}-cell`}>
      <FormGroup row className="mt-0 mb-0">
        {interval?.caption && (
          <Label for={date.key} sm={12} className="font-weight-bold">
            Date
          </Label>
        )}

        <Col sm={12} className="overflow-visible">
          <div className="d-flex">
            <DatePicker
              style={{ overflow: 'visible' }}
              selected={date_value}
              onChange={(event) => onDateChange(tag, event)}
              id={date.key}
              placeholderText="-"
              {...options}
            />
            {!date.valid && <small className="text-danger mt-1">{date.message}</small>}
          </div>
          {interval?.description && <small className="text-success mt-1">Date</small>}
        </Col>
      </FormGroup>
    </td>
  ) : null;

  return (
    <>
      {intervalTableData}
      {dateTableData}
    </>
  );
};

export default CellIntervalDatePicker;
