import { connect } from 'react-redux';
import { Nav, TabContent, TabPane, Alert } from 'reactstrap';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import { isNil } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import { downloadBlob } from 'jsx/lib/download';

import FormTab from '../../../core/form/components/FormTab';
import PageTitle from '../../../core/form/components/PageTitle';
import LivestockEnterprises from './LivestockEnterprises';
import Charts from '../components/LivestockEnterpriseCharts';
import Toolbar from '../components/LivestockEnterpriseToolbar';
import GenericLsv from '../../../core/form/components/GenericLsv';
import GenericModal from '../../../core/form/components/GenericModal';
import DirectCostModal from './DirectCostModal';
import LivestockIncomeModal from './LivestockIncomeModal';
import LivestockTransactionModal from './LivestockTransactionModal';
import LivestockOpeningModal from '../containers/LivestockOpeningModal';
import LivestockAdjustmentModal from './LivestockAdjustmentModal.jsx';
import LivestockBreedingModal from './LivestockBreedingModal';
import LivestockValueModal from './LivestockValueModal';
import CattleEnterpriseStockflowLsv from '../components/CattleEnterpriseStockflowLsv';
import { fetchAnimalClasses } from '../actions/animal_classes';
import ExternalLink from '../components/ExternalLink';
import { fetchLivestockTransaction } from '../actions/livestocks';
import TransferModal from './TransferModal';
import LivestockPurchasesLsv from './LivestockPurchasesLsv';
import { LivestockValueLsv } from '../components/LivestockValueLsv';
import { downloadImportErrorFile, importClientTransactions } from '../actions/importing';

const showImportButtonTabs = ['3', '4', '8'];

class CattleEnterprises extends LivestockEnterprises {
  constructor(props) {
    super(props);

    this.loadDivision = this.loadDivision.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.setLivestockTransactionModal = this.setLivestockTransactionModal.bind(this);

    this.state = {
      ...this.state,
      cattleBlobData: null,
    };
  }

  async componentDidMount() {
    this.loadDivision();
  }

  async componentDidUpdate(prevProps) {
    const { activeTab: prevEnterprisesActiveTab } = prevProps.enterprises;
    const { activeTab: enterprisesActiveTab } = this.props.enterprises;

    if (prevEnterprisesActiveTab !== 'cattle' && enterprisesActiveTab === 'cattle')
      this.loadDivision();

    if (!prevProps.importing.filename && this.props.importing.filename) {
      // Download file from server and update blob response data in state
      const { filename } = this.props.importing;
      const result = await this.props.dispatch(downloadImportErrorFile(filename));
      this.setState({ cattleBlobData: result.data });
    }

    super.componentDidUpdate(prevProps);
  }

  async loadDivision() {
    await super.loadDivision('cattle');
  }

  onRefresh() {
    const tab_id = this.props.livestocks.activeCattleTab;
    if (tab_id) this.toggleTab(tab_id);
  }

  async setLivestockTransactionModal(isLivestockTransactionModalOpen, id = null) {
    const { division_id } = this.state;
    this.props.dispatch(fetchAnimalClasses({ division_id }));

    let modalData;
    if (id) modalData = await this.props.dispatch(fetchLivestockTransaction(id));

    this.setState({
      isLivestockTransactionModalOpen,
      modalType: 'Cattle Sale/Purchase',
      id,
      modalData,
    });
  }

  setModalOptions(type) {
    const options = super.setModalOptions(type);
    options.title = `Cattle ${options.title}`;

    // Set breeding control captions
    if (options.title === 'Cattle Breeding') {
      options.controls.bought_pregnant_quantity.caption = '# Pregnant Cows Bought';
      options.controls.branded_pregnant_quantity.caption = '# Branded from Cows Pregnant Bought';
      options.controls.breeding_period_id.caption = 'Calving Period';
      options.controls.breeding_period_id.group = 'calving';
      options.controls.breeding_date.caption = 'Calving Date';
      options.controls.breeding_date.group = 'calving';
    }

    return options;
  }

  async toggleTab(tab) {
    const { division_id } = this.state;

    if (!division_id) {
      await this.loadDivision();
    }

    super.toggleTab(tab);

    if (this.props.livestocks.activeCattleTab !== tab)
      this.props.dispatch({
        type: 'SET_LIVESTOCK_CATTLE_ACTIVE_TAB',
        payload: tab,
      });
  }

  onImportAlertDismiss = () => this.props.dispatch({ type: 'CLEAR_IMPORT_RESPONSE_MESSAGE' });

  onImport = async (event, onProgressChange, schemaType) => {
    if (event.target.files.length === 0) return false;

    const [file] = event.target.files;
    const formData = new FormData();
    formData.append('document', file);
    formData.append('schemaType', schemaType);
    formData.append('division', 'cattle');

    // Append client timezone
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    formData.append('timeZone', timeZone);

    const success = await this.props.dispatch(importClientTransactions(formData, onProgressChange));

    if (!success) return false;

    return this.onRefresh();
  };

  getImportSchemaType = (activeTab) => {
    switch (activeTab) {
      case '3':
        return 'purchases';
      case '4':
        return 'sales';
      case '8':
        return 'costs';
      default:
        return '';
    }
  };

  downloadFile = () => {
    const { filename } = this.props.importing;
    const { cattleBlobData } = this.state;
    if (!cattleBlobData || !filename) return;

    downloadBlob(cattleBlobData, filename);
  };

  onImportSuccessAlertDismiss = () =>
    this.props.dispatch({ type: 'CLEAR_IMPORT_RESPONSE_MESSAGE' });

  onImportErrorAlertDismiss = () => {
    const { filename, responseMessage, errorResponseMessage, error } = this.props.importing;
    if (filename) {
      this.props.dispatch({ type: 'CLEAR_IMPORT_FILENAME' });
      this.setState({ cattleBlobData: null });
    }

    if (responseMessage) this.props.dispatch({ type: 'CLEAR_IMPORT_RESPONSE_MESSAGE' });

    if (errorResponseMessage) this.props.dispatch({ type: 'CLEAR_IMPORT_ERROR_RESPONSE_MESSAGE' });

    if (error.type || error.message) this.props.dispatch({ type: 'CLEAR_IMPORT_ERROR' });
  };

  onDownloadFileKeyDown = (event) => {
    // If enter key, download file
    if (event.key === 'Enter') this.downloadFile();
  };

  getStatus400ErrorMessage = () => {
    const { type, message } = this.props.importing.error;
    const phoneNumber = '07 4939 5255';
    const email = 'profitprobe@rcsaustralia.com.au';
    switch (type) {
      case 'file':
      case 'mimetype':
      case 's3':
      case 'schemaType': {
        return (
          <p className="m-0">
            {message} For further assistance, please contact a FarmEye specialist on
            <a href={`tel:${phoneNumber}`} className="mx-2">
              {phoneNumber}
            </a>
            or
            <a href={`mailto:${email}`} className="mx-2">
              {email}
            </a>
          </p>
        );
      }
      case 'limit': {
        return (
          <p className="m-0">
            There is a row import limit of 200, please split your transactions into another file. If
            this limit is prohibitive for you, please contact a FarmEye specialist on
            <a href={`tel:${phoneNumber}`} className="mx-2">
              {phoneNumber}
            </a>
            or
            <a href={`mailto:${email}`} className="mx-2">
              {email}
            </a>
          </p>
        );
      }
      case 'headers': {
        return (
          <p className="m-0">
            The import file is missing the following required row headings:
            <span className="ml-1">{message}.</span> For further assistance, please contact a
            FarmEye specialist on
            <a href={`tel:${phoneNumber}`} className="mx-2">
              {phoneNumber}
            </a>
            or
            <a href={`mailto:${email}`} className="mx-2">
              {email}
            </a>
          </p>
        );
      }
      case 'download':
        return (
          <p className="m-0">
            The import file contains invalid values. Please save and open the log file for more
            information:
            <a
              role="button"
              tabIndex={0}
              onKeyDown={this.onDownloadFileKeyDown}
              className="mx-2 cursor-pointer"
              onClick={this.downloadFile}
            >
              Click to Download
            </a>
            For further assistance, contact a FarmEye specialist on
            <a href={`tel:${phoneNumber}`} className="mx-2">
              {phoneNumber}
            </a>
            or
            <a href={`mailto:${email}`} className="mx-2">
              {email}
            </a>
          </p>
        );
      case 'database':
      default: {
        break;
      }
    }

    return (
      <p className="m-0">
        There was a problem uploading the file. Please contact a FarmEye specialist on
        <a href={`tel:${phoneNumber}`} className="mx-2">
          {phoneNumber}
        </a>
        or
        <a href={`mailto:${email}`} className="mx-2">
          {email}
        </a>
      </p>
    );
  };

  render() {
    const {
      id,
      modalType,
      isModalOpen,
      isDirectCostModalOpen,
      isLivestockIncomeModalOpen,
      isLivestockTransactionModalOpen,
      isLivestockOpeningModalOpen,
      isLivestockAdjustmentModalOpen,
      isLivestockBreedingModalOpen,
      isTransferModalOpen,
      isLivestockValueModalOpen,
      modalData,
      division_id,
    } = this.state;

    const {
      activeCattleTab,
      purchases,
      sales,
      adjustments,
      breedings,
      transfers,
      stockflows,
      livestock_incomes,
      responseMessage,
      isLivestockAssumptionsToggled,
    } = this.props.livestocks;

    const { selectedRanges } = this.props.enterprises;
    const { direct_costs } = this.props.direct_costs;
    const { values } = this.props.animal_classes;
    const {
      responseMessage: importResponseMessage,
      errorResponseMessage: importErrorResponseMessage,
    } = this.props.importing;
    const isImportingResponseMessageSuccessful =
      importResponseMessage && importResponseMessage === 'Import Transactions - Success';

    const modalOptions = this.setModalOptions(modalType);

    const title = 'Cattle Enterprises';
    const iconName = 'cow';

    const showImportButton = showImportButtonTabs.includes(activeCattleTab);
    const schemaType = showImportButton ? this.getImportSchemaType(activeCattleTab) : '';

    return (
      <div className="p-0 h-100">
        <PageTitle title={title} iconName={iconName} />
        <Charts />
        {/* Alert for successful import */}
        <Alert
          isOpen={isImportingResponseMessageSuccessful}
          color="success"
          className="m-2 d-flex justify-content-center align-items-center mb-4"
          toggle={this.onImportSuccessAlertDismiss}
          closeAriaLabel="Close"
        >
          <Icon name="check" className="mr-2" />
          {importResponseMessage}
        </Alert>
        {/* Alert for status 400 error responses */}
        <Alert
          isOpen={!isImportingResponseMessageSuccessful && !isNil(importErrorResponseMessage)}
          color="warning"
          className="m-2 d-flex justify-content-center align-items-center mb-4"
          toggle={this.onImportErrorAlertDismiss}
          closeAriaLabel="Close"
        >
          <Icon name="triangle-exclamation" className="mr-2" />
          {this.getStatus400ErrorMessage()}
        </Alert>
        <Toolbar
          setModal={this.setModal}
          onRefresh={this.onRefresh}
          setDirectCostModal={this.setDirectCostModal}
          setLivestockIncomeModal={this.setLivestockIncomeModal}
          setLivestockTransactionModal={this.setLivestockTransactionModal}
          setLivestockOpeningModal={this.setLivestockOpeningModal}
          setLivestockAdjustmentModal={this.setLivestockAdjustmentModal}
          setLivestockBreedingModal={this.setLivestockBreedingModal}
          setTransferModal={this.setTransferModal}
          onImport={this.onImport}
          showImportButton={showImportButton}
          schemaType={schemaType}
        />
        <DirectCostModal
          onRefresh={this.onRefresh}
          setModal={this.setDirectCostModal}
          id={id}
          isOpen={isDirectCostModalOpen}
          isNew={id === null}
          division_id={division_id}
        />
        <LivestockOpeningModal
          onRefresh={this.onRefresh}
          setModal={this.setLivestockOpeningModal}
          id={id}
          isOpen={isLivestockOpeningModalOpen}
          division_id={division_id}
        />
        <LivestockAdjustmentModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setLivestockAdjustmentModal}
          data={modalData}
          isOpen={isLivestockAdjustmentModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
        />
        <LivestockIncomeModal
          onRefresh={this.onRefresh}
          setModal={this.setLivestockIncomeModal}
          id={id}
          isOpen={isLivestockIncomeModalOpen}
          isNew={id === null}
          division_id={division_id}
        />
        <LivestockTransactionModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setLivestockTransactionModal}
          data={modalData}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          onRefresh={this.onRefresh}
          id={id}
          isOpen={isLivestockTransactionModalOpen}
          isNew={id === null}
          division_id={division_id}
        />

        <GenericModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setModal}
          data={modalData}
          isOpen={isModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />
        <LivestockBreedingModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setLivestockBreedingModal}
          data={modalData}
          isOpen={isLivestockBreedingModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />

        <TransferModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setTransferModal}
          data={modalData}
          isOpen={isTransferModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />

        <LivestockValueModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setLivestockValueModal}
          data={modalData}
          isOpen={isLivestockValueModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
        />

        <Nav tabs className="mt-2">
          <FormTab
            caption="Stockflow"
            tabId="1"
            activeTab={activeCattleTab}
            toggle={this.toggleTab}
            tabTag="stockflow"
          />
          <FormTab
            caption="Values"
            tabId="2"
            activeTab={activeCattleTab}
            toggle={this.toggleTab}
            disabled={false}
            tabTag="values"
          />
          <FormTab
            caption="Purchases"
            tabId="3"
            activeTab={activeCattleTab}
            toggle={this.toggleTab}
            tabTag="purchases"
          />
          <FormTab
            caption="Sales"
            tabId="4"
            activeTab={activeCattleTab}
            toggle={this.toggleTab}
            tabTag="sales"
          />
          <FormTab
            caption="Adjustments"
            tabId="5"
            activeTab={activeCattleTab}
            toggle={this.toggleTab}
            tabTag="adjustments"
          />
          <FormTab
            caption="Breeding Statistics"
            tabId="6"
            activeTab={activeCattleTab}
            toggle={this.toggleTab}
            tabTag="breeding"
          />
          <FormTab
            caption="Transfers"
            tabId="7"
            activeTab={activeCattleTab}
            toggle={this.toggleTab}
            tabTag="transfers"
          />
          <FormTab
            caption="Direct Costs"
            tabId="8"
            activeTab={activeCattleTab}
            toggle={this.toggleTab}
            tabTag="costs"
          />
          <FormTab
            caption="Other Income"
            tabId="9"
            activeTab={activeCattleTab}
            toggle={this.toggleTab}
            tabTag="livestock_income"
          />
        </Nav>

        <TabContent activeTab={activeCattleTab}>
          <TabPane tabId="1" className="mb-2 p-1">
            <div className="d-flex">
              <ExternalLink
                divClasses="m-2"
                hasIcon
                href="https://aucalc.rcsaustralia.com.au/cattle"
                name="calculator"
                iconClasses="mr-2 text-corporate"
                title="AU Rating Calculator"
              />
            </div>

            <CattleEnterpriseStockflowLsv
              rows={stockflows}
              onValuationChange={this.onValuationChange}
              onRIChange={this.onRIChange}
              selectedRanges={selectedRanges}
            />
          </TabPane>
          <TabPane tabId="2" className="mb-2 p-1">
            <LivestockValueLsv
              onClick={(recordId) => {
                this.setLivestockValueModal(true, recordId);
              }}
              rows={values}
              onRefresh={this.onRefresh}
              isAssumedValuesToggled={isLivestockAssumptionsToggled}
            />
          </TabPane>
          <TabPane tabId="3" className="mb-2 p-1">
            <Alert className="mt-1" color="primary">
              Information entered here will be consolidated into the stockflow page. You need to
              enter the enterprises and animal classes that are used on the stockflow page. Be
              consistent with enterprises and animal classes used on all cattle input tabs.
            </Alert>
            <LivestockPurchasesLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No cattle purchases found"
              onClick={(purchaseId) => {
                this.setLivestockTransactionModal(true, purchaseId);
              }}
              rows={purchases}
            />
          </TabPane>
          <TabPane tabId="4" className="mb-2 p-1">
            <Alert className="mt-1" color="primary">
              Information entered here will be consolidated into the stockflow page. You need to
              enter the enterprises and animal classes that are used on the stockflow page. Be
              consistent with enterprises and animal classes used on all cattle input tabs.
            </Alert>
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No cattle sales found"
              onClick={(saleId) => {
                this.setLivestockTransactionModal(true, saleId);
              }}
              rows={sales}
            />
          </TabPane>
          <TabPane tabId="5" className="mb-2 p-1">
            <Alert className="mt-1" color="primary">
              Information entered here will be consolidated into the stockflow page. You need to
              enter the enterprises and animal classes that are used on the stockflow page. Be
              consistent with enterprises and animal classes used on all cattle input tabs.
            </Alert>
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No cattle adjustments found"
              onClick={(adjustmentId) => {
                this.setLivestockAdjustmentModal(true, adjustmentId);
              }}
              rows={adjustments}
            />
          </TabPane>
          <TabPane tabId="6" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No cattle breedings found"
              onClick={(breedingId) => {
                this.setLivestockBreedingModal(true, breedingId);
              }}
              rows={breedings}
            />
          </TabPane>
          <TabPane tabId="7" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No cattle transfers found"
              onClick={(transferId) => {
                this.setTransferModal(true, transferId);
              }}
              rows={transfers}
            />
          </TabPane>
          <TabPane tabId="8" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No direct costs found"
              onClick={(directCostId) => {
                this.setDirectCostModal(true, directCostId);
              }}
              rows={direct_costs}
              totalFormattingRules={{
                includeDollarSign: true,
                includeCommas: true,
              }}
            />
          </TabPane>
          <TabPane tabId="9" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No other income found"
              onClick={(otherIncomeId) => {
                this.setLivestockIncomeModal(true, otherIncomeId);
              }}
              rows={livestock_incomes}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({
  animal_classes,
  attributes,
  direct_costs,
  enterprises,
  importing,
  livestocks,
  realm,
}) => ({
  animal_classes,
  attributes,
  direct_costs,
  enterprises,
  importing,
  livestocks,
  realm,
});

export default connect(mapStoreToProps)(withContainerError(CattleEnterprises));
