import React from 'react';
import Listview from "../../../core/form/components/Listview";

const BusinessLandcareIndicatorsLsv = (props) => {
  const {
    rows,
    // history
  } = props;

  const edit = () => {
    // const lnk = `/home/probes/${row.id}`;
    // history.push(lnk);
  }

  const renderRows = (headers, record) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        default: return (
          <td key={index} className={header.classes}>{record[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const iconName = 'heart-pulse';
  const emptyCaption = 'Placeholder for fixed row listview for Land Care indicators';

  const headers = [];

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  )

  let tableBodyTr = (<tr></tr>);
  let rowsCount;

  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => edit(row)} style={{cursor: "pointer"}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  return (
    <div className="b">
      <Listview rows={rows} rowsCount={rowsCount} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
      <div className="text-danger">Developers Note: This will be a fixed row count lsv that needs to support inline editing. See tab in profitprobe spreadsheet</div>

    </div>
  );
}

export default BusinessLandcareIndicatorsLsv;
