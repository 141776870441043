import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  transaction_month: {
    ...controlDefaults,
    caption: 'Month',
    fieldName: 'transaction_date',
    name: 'transaction_month',
    placeholder: '',
    type: 'date-month',
    showInEditor: false,
    showInListview: true,
    sortColumn: 'created',
  },
  enterprise_id: {
    ...controlDefaults,
    caption: 'Enterprise',
    fieldName: 'enterprise.name',
    group: 'enterprise',
    name: 'enterprise_id',
    placeholder: '',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  enterprise_type: {
    ...controlDefaults,
    caption: 'Type',
    fieldName: 'enterprise.type.name',
    group: 'enterprise',
    placeholder: '',
    showInListview: true,
  },
  property_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'enterprise',
    fieldName: 'property.name',
    name: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_type_id: {
    ...controlDefaults,
    caption: 'Transaction Type',
    group: 'transaction',
    name: 'transaction_type_id',
    placeholder: '',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  transaction_date: {
    ...controlDefaults,
    caption: 'Transaction Date',
    group: 'transaction',
    name: 'transaction_date',
    placeholder: '',
    type: 'date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  product_id: {
    ...controlDefaults,
    caption: 'Type',
    fieldName: 'product.name',
    group: 'product',
    placeholder: '',
    name: 'product_id',
    showInEditor: true,
    showInListview: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  production_unit_type_id: {
    ...controlDefaults,
    caption: 'Production Unit',
    disabled: true,
    fieldName: 'enterprise.production_units.name',
    group: 'product',
    name: 'production_unit_type_id',
    placeholder: '',
    showInEditor: false,
    showInListview: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
  },
  quantity_kg: {
    ...controlDefaults,
    caption: 'Quantity',
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    group: 'total',
    name: 'quantity_kg',
    placeholder: '',
    type: 'number',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    totals: true,
  },
  amount: {
    ...controlDefaults,
    caption: 'Total Amount ($)',
    formattingRules: {
      includeCommas: true,
      includeDollarSign: true,
      includeDecimals: true,
    },
    formula: '##quantity_kg## * ##amount_per_production_unit##',
    formula_priority: 1,
    group: 'total',
    name: 'amount',
    placeholder: '',
    tracking: ['amount_per_production_unit'],
    type: 'number',
    user_entry: false,
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    totals: true,
    totalFormattingRules: { includeCommas: true, includeDollarSign: true, includeDecimals: true },
  },
  amount_per_production_unit: {
    ...controlDefaults,
    caption: 'Amount ($/Production Unit)',
    formattingRules: {
      includeCommas: true,
      includeDollarSign: true,
      includeDecimals: true,
    },
    formula: '##amount## / ##quantity_kg##',
    formula_priority: 0,
    group: 'checks',
    name: 'amount_per_production_unit',
    placeholder: '',
    tracking: ['amount'],
    type: 'number',
    user_entry: false,
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
};
