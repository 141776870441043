import React from 'react';
import { connect } from "react-redux";
import {
  Row,
  Col
} from 'reactstrap';

import ChartLayersSocLsv from '../components/ChartLayersSocLsv';
import Icon from 'jsx/components/core/icons/Icon';

import mapboxgl from 'mapbox-gl';
import { accessToken } from '../../../../constants/map.js';

import {
  zoomToBounds,
  getBoundary,
  getCollectionBoundary,
  buildFeatureCollection,
  buildFeature,
  getDefaultLayer
} from "../../projects/lib/mapster.js";

import {
  fetchAreaRoundStratasSync
} from '../../areas/actions';

import {
  fetchCarbonStock
} from '../actions';

import {
  fetchSitesSync
} from '../../sites/actions';

import {
  selectChartCell
} from '../actions';

mapboxgl.accessToken = accessToken

class ChartLayersStratas extends React.Component {
  constructor(props) {
    super(props)

    this.mapRef = React.createRef();
    
    this.state = ({
      specLink: 'https://carbonlink.atlassian.net/wiki/spaces/OP/pages/607191041/Spec+-+Calculate+carbon+stock+for+strata+for+each+layer+definition',
      widget: {
        title: 'Carbon Stock Calculations for each Strata by Layer'
      },
      data: [],
      fetching: false,
      lng: 150.7333,
      lat: -23.1333,
      zoom: 8,
      map: null,
      mapSources: [],
      mapIsLoaded: false,
      mapStyle: 'mapbox://styles/mapbox/outdoors-v11',
      reloadMaps: false,
      height: 300,
      checksumSites: null,
      stratas: [],
      sites: []
    })
  }

  componentDidMount() {
    const {
      mapStyle,
      zoom
    } = this.state;

    const {
      area_round_id
    } = this.props;

    this.resetGeometry();
    this.loadRoundMaps(area_round_id);

    const map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: mapStyle,
      center: [this.state.lng, this.state.lat],
      zoom: zoom,
      attributionControl: false
    });

    this.setState({
      map
    })

    map.once("load", () => {
      this.loadSources(map);
      this.setState({ mapIsLoaded: true, map });
    });
  }

  componentDidUpdate(prevProps) {
    const {
      selectedTemplate,
      area_round_id
    } = this.props;

    const {
      fetching,
      data,
      mapSources,
      map,
      mapIsLoaded
    } = this.state;

    const {
      selections
    } = this.props.analytics;

    if (selectedTemplate.id && area_round_id && !fetching && (area_round_id !== prevProps.area_round_id || selectedTemplate.id !== prevProps.selectedTemplate.id || data.length === 0)) {
      this.load();
    }

    // Reload stratas and sites if area round changes
    if (area_round_id && area_round_id !== prevProps.area_round_id) {
      this.loadRoundMaps(area_round_id);
    }


    if (JSON.stringify(selections) !== this.state.checksumSites) {
      this.setSiteMaps(true);
      this.setState({ checksumSites: JSON.stringify(selections) })
    }
    if (mapIsLoaded) {
      this.updateSources(map, mapSources)
    }
  }

  async loadRoundMaps(area_round_id) {
    if (area_round_id) {
      const stratas = await this.props.dispatch(fetchAreaRoundStratasSync(area_round_id));
      this.setState({stratas}, () => {
        this.setStrataMaps(true);
      });

      const siteFilters = { scan_samples: ['notnull'] }
      const sites = await this.props.dispatch(fetchSitesSync({ round_id: area_round_id }, siteFilters));
      this.setState({sites}, () => {
        this.setSiteMaps(true);
      });

      this.state.map.resize();
    }
  }

  async load() {
    const {
      selectedTemplate,
      area_round_id
    } = this.props;

    this.setState(
      { fetching: true },
      async () => {
        const soc = await this.props.dispatch(fetchCarbonStock("strata", area_round_id, selectedTemplate.id));
        if (soc.length > 0) {
          soc.sort(function (a, b) { return a['name'] - b['name'] });
          this.setState({ data: soc, fetching: false })
        }

      }
    )
  }

  loadSources(map) {
    const mapSources = this.state.mapSources;
    const parentProps = this.props;

    mapSources.forEach(mapSource => {
      map.addSource(mapSource.id, {
        type: 'geojson',
        data: { 'type': 'Feature' }
      });

      const layer = getDefaultLayer(mapSource.id, mapSource.style)
      map.addLayer(layer);

      if (mapSource.id === 'sites') {
        // Set the click
        map.on('click', `${mapSource.id}-circle`, function (e) {
          const core_id = e.features[0].properties.core_id;
          parentProps.dispatch(selectChartCell(core_id, 'sites', true));
        })
      }
    })
    this.setState({ mapIsLoaded: true, map });
  }

  updateSources(map) {
    const {
      mapSources
    } = this.state;

    let bounds = []
    mapSources.forEach(mapSource => {
      if (map.getSource(mapSource.id)) {
        map.getSource(mapSource.id).setData(mapSource.source);
        if (mapSource.source.type === 'Feature') {
          bounds = bounds.concat(getBoundary(mapSource.source));
        }
        if (mapSource.source.type === 'FeatureCollection') {
          bounds = bounds.concat(getCollectionBoundary(mapSource.source));
        }
      }
    })

    if (bounds.length > 0) {
      zoomToBounds(map, bounds);
    }
  }

  resetGeometry() {
    const mapSources = [
      { id: 'stratas', visible: true, source: { 'type': 'FeatureCollection', features: [] }, load: true, title: 'Stratas', count: 0, style: "Polygon" },
      { id: 'sites', visible: true, source: { 'type': 'FeatureCollection', features: [] }, load: true, title: 'Sites', count: 0, style: "Point", onClick: this.toggleChart }
    ]
    this.setState({ mapSources })
  }

  setStrataMaps(force = false) {
    const stratas = this.state.stratas;
    const mapSources = this.state.mapSources;
    const featureColour = this.props.mapster.colours.strata;
    const strataColours = this.props.mapster.colours.stratas;

    if (stratas.length > 0) {
      const idx = mapSources.findIndex(source => source.id === 'stratas');
      const featureCollection = buildFeatureCollection();

      stratas.forEach(strata => {
        const strataColour = strataColours[parseInt(strata.name)]
        if (strata.geom) {
          const feature = buildFeature(strata.geom, { title: `STRATA ${strata.name}`, colour: strataColour, outline: featureColour.outline })
          featureCollection.features.push(feature)
        }
      })

      if ((mapSources[idx].load && featureCollection.features.length > 0) || force === true) {
        if (mapSources[idx].visible === true) {
          mapSources[idx].source = featureCollection;
        } else {
          mapSources[idx].source = buildFeatureCollection();
        }
        mapSources[idx].load = false;
        mapSources[idx].count = featureCollection.features.length;

        this.setState({ mapSources })
      }
    }
  }

  // Set Site locations 
  setSiteMaps(force = false) {
    const sites = this.state.sites;
    const mapSources = this.state.mapSources;
    const selected = this.props.analytics.selections.sites;

    const idx = mapSources.findIndex(source => source.id === 'sites');
    const featureCollection = buildFeatureCollection();

    sites.forEach(site => {
      if (site.geom) {
        // Target/Reserve colour
        let featureColour = this.props.mapster.colours.site_target;
        if (site.type_key === 'R') {
          featureColour = this.props.mapster.colours.site_reserve;
        }

        // colour if scanned
        if (site.scan_samples_exists) {
          featureColour = this.props.mapster.colours.site_scanned;
        }

        let featureStrokeWidth = 1;
        if (selected.includes(site.core_id)) {
          featureColour = this.props.mapster.colours.site_selected;
          featureStrokeWidth = 1
        }

        const feature = buildFeature(site.geom, { id: site.id, core_id: site.core_id, scan_samples_exists: site.scan_samples_exists, title: `SITE ${site.name}`, strokeWidth: featureStrokeWidth, colour: featureColour.fill, outline: featureColour.outline })
        featureCollection.features.push(feature)
      }
    })

    if ((mapSources[idx] && mapSources[idx].source.features.length !== sites.length && featureCollection.features.length > 0) || force === true) {
      mapSources[idx].load = false;
      mapSources[idx].source = featureCollection;
      mapSources[idx].count = featureCollection.features.length;
      this.setState({ mapSources })
    }
  }

  render() {
    const {
      data,
      height,
      specLink,
      widget
    } = this.state;

    const strataColours = this.props.mapster.colours.stratas;
    const specCaption = `Spec Sheet for ${widget.title}`;

    // If widget is empty
    const emptyCaption = `No Chart data available for ${widget.title}`;
    const iconName = 'chart-mixed';

    let haveData = false;
    if (data && data[0] && data[0].layers.length > 0) {
      haveData = true;
    }
    // Need to do this as map does not draw if initialised blank
    const displayStyle = (haveData ? 'flex' : 'none');

    return (
      <div>
        {!haveData && (
          <div className="p-5 text-center m-1 bg-white rounded border border-secondary" style={{ height: height }}>
            <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
            <div className="mt-3">{emptyCaption}</div>
          </div>
        )}

        <Row className="m-1 bg-white rounded border border-secondary" style={{ display: displayStyle }}>
          <Col sm={2} className="p-0">
            <div ref={this.mapRef} className="h-100 rounded-left" style={{ marginBottom: '-5px', borderRight: '1px solid #cccccc' }} />
          </Col>
          <Col sm={10} className="p-2">
            <div className="d-flex justify-content-between p-1 border-bottom border-lightgray mb-1">
              <div>
                <Icon name="leaf" className="text-corporate mr-2" />
                <span>{widget.title}</span>
              </div>
              <div>
                <div>Avg = Average SOC tC/ha, Var = Variance SOC tC/ha<sup>2</sup></div>
              </div>
            </div>
            <ChartLayersSocLsv namePrefix="Strata " data={data} colours={strataColours} />

            <div className="text-right mr-3">
              <small><a target="_blank" rel="noopener noreferrer" href={specLink}>{specCaption}</a></small>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    analytics: store.analytics,
    areas: store.areas,
    mapster: store.mapster,
    sites: store.sites
  }
}

export default connect(mapStoreToProps)(ChartLayersStratas);
