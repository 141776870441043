import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import FormBase from '../../../core/form/components/FormBase';

import DaysheetActivitesToolbar from '../components/DaysheetActivitesToolbar';
import DaysheetsLsv from '../components/DaysheetsLsv';
import DaysheetActivityModal from './DaysheetActivityModal';
import TimekeeperTimerBar from '../../office/components/TimekeeperTimerBar';

import { fetchDaysheets, deleteDaysheet } from '../actions/daysheets';
import { fetchLookupLocations } from '../actions/locations';
import { fetchProjects } from '../../projects/actions/projects';

class DaysheetActivities extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      isModalOpen: false,
      isNew: false,
      daysheetActivities: [],
      daysheetBreaks: [],
      daysheetsWithHours: [],
      daysheetId: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchLookupLocations());
    this.props.dispatch(fetchProjects());
    this.loadDaysheets();
  }

  componentDidUpdate(prevProps) {
    const { selectedDate } = this.props.office;
    if (selectedDate && selectedDate !== prevProps.office.selectedDate) {
      this.loadDaysheets();
    }

    const { forceRefresh, setRefresh } = this.props;
    if (forceRefresh && setRefresh) {
      this.loadDaysheets();
      if (setRefresh) setRefresh(false);
    } else if (!isEqual(prevProps.wipstar.params, this.props.wipstar.params)) {
      this.loadDaysheets();
    }
  }

  selectDate = (selectedDate) => {
    this.props.dispatch({ type: 'SET_SELECTED_DATE', payload: selectedDate });
  };

  setWeek = async (increment) => {
    await this.props.dispatch({ type: 'UPDATE_CURRENT_WEEK', payload: increment });
  };

  loadDaysheets = async () => {
    const { currentWeek } = this.props.office;
    const {
      params: { locations: filter_locations, projects: filter_projects },
    } = this.props.wipstar;
    const daysheetResponse = await this.props.dispatch(
      fetchDaysheets({ ...currentWeek, filter_projects, filter_locations }),
    );
    if (!daysheetResponse) return;

    const { rows: daysheets } = daysheetResponse;

    /* for every daysheet item for the current week:
        - get the daysheet break hours for that daysheet
          - populate daysheet.total_break_hours via sum
        - get the activities for the daysheet
          - daysheet.total_asset_hours via sum
          - populate daysheet.total_labour_hours via sum
    */
    const daysheetsWithHours = daysheets.map((daysheet) => {
      const total_break_hours = daysheet.breaks?.reduce((prev, curr) => prev + curr.hrs, 0);
      const total_asset_hours = daysheet.activities?.reduce(
        (prev, curr) => prev + curr.asset_hours,
        0,
      );
      const total_labour_hours = daysheet.activities?.reduce(
        (prev, curr) => prev + curr.labour_hours,
        0,
      );

      return {
        ...daysheet,
        total_break_hours,
        total_asset_hours,
        total_labour_hours,
      };
    });

    this.setState({ daysheetsWithHours });
  };

  deleteDaysheet = async (id) => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm('Removing daysheet permanently. Continue?');

    if (confirmed) {
      await this.props.dispatch(deleteDaysheet(id));
      this.loadDaysheets();
    }
  };

  filter = async (type, property, payload) => {
    const dispatchAction = { payload };
    if (type === 'change') {
      dispatchAction.type =
        property === 'locations' ? 'SET_DAYSHEET_LOCATION_FILTERS' : 'SET_DAYSHEET_PROJECT_FILTERS';
    } else {
      dispatchAction.type =
        property === 'locations'
          ? 'REMOVE_DAYSHEET_LOCATION_FILTER'
          : 'REMOVE_DAYSHEET_PROJECT_FILTER';
    }
    return this.props.dispatch(dispatchAction);
  };

  onFilter = async (type, property, payload) => {
    await this.filter(type, property, payload);
  };

  setModal = (isModalOpen, daysheetId = null) => {
    this.setState({
      isModalOpen,
      daysheetId,
    });
  };

  render() {
    const { currentWeek, selectedDate } = this.props.office;
    const { isModalOpen, daysheetId, daysheetsWithHours } = this.state;

    const selectedDay = moment(selectedDate);

    const daysheetsWithHoursForSelectedDay = daysheetsWithHours.filter(({ from_datetime }) =>
      moment(from_datetime).isSame(selectedDay, 'day'),
    );

    return (
      <div className="d-flex flex-column h-100">
        <DaysheetActivitesToolbar onFilter={this.onFilter} />
        <TimekeeperTimerBar
          selectDate={this.selectDate}
          selectedDate={selectedDate}
          setWeek={this.setWeek}
          week={currentWeek}
          onEdit={() => this.setModal(true)}
          reload={this.loadDaysheets}
          daysheets={{ rows: daysheetsWithHours }}
        />
        <DaysheetsLsv
          rows={daysheetsWithHoursForSelectedDay}
          onDelete={this.deleteDaysheet}
          onEdit={(id) => this.setModal(true, id)}
          loadDaysheets={this.loadDaysheets}
        />
        <DaysheetActivityModal
          onFilter={this.onFilter}
          daysheetId={daysheetId}
          setModal={this.setModal}
          isOpen={isModalOpen}
          isNew={daysheetId === null}
          onRefresh={this.loadDaysheets}
        />
      </div>
    );
  }
}

const mapStoreToProps = ({ wipstar, office }) => ({
  wipstar,
  office,
});

export default connect(mapStoreToProps)(DaysheetActivities);
