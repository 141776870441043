import { defaults, allowed } from '../../../../lib/genericReducer';
import moment from 'moment';

const FEATURE_PREFIX = 'SAMPLING_PLAN';

export default function reducer(
  state = {
    project: {
      fetching: false,
      fetched: false,
      error: null,
      data: {},
    },
    projectStats: {
      fetching: false,
      fetched: false,
      error: null,
      data: {},
    },
    properties: {
      fetching: false,
      fetched: false,
      error: null,
      data: {
        count: 0,
        rows: [],
      },
    },
    rounds: {
      fetching: false,
      fetched: false,
      error: null,
      data: [],
    },
    roundStats: {
      fetching: false,
      fetched: false,
      error: null,
      data: [],
    },
    projectStatuses: {
      fetching: false,
      fetched: false,
      error: null,
      data: [],
    },
  },
  action
) {
  const types = [FEATURE_PREFIX];

  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };
  switch (action.type) {
    case `${FEATURE_PREFIX}/FETCH_PROJECT_PENDING`:
      return {
        ...state,
        project: {
          ...state.project,
          fetching: true,
        },
      };

    case `${FEATURE_PREFIX}/FETCH_PROJECT_FULFILLED`:
      const filterProject = ({ assignedto, declaration_date, ...project }) => {
        const createdDate =
          declaration_date !== null ? moment(declaration_date).format('YYYY-MM-DD') : 'N/A';
        return {
          ...project,
          assignedTo: assignedto?.name || 'N/A',
          createdDate,
        };
      };
      return {
        ...state,
        project: {
          data: filterProject(action.payload),
          fetching: false,
          fetched: true,
        },
      };

    case `${FEATURE_PREFIX}/FETCH_PROPERTIES_PENDING`: {
      return {
        ...state,
        properties: {
          ...state.properties,
          fetching: true,
        },
      };
    }
    case `${FEATURE_PREFIX}/FETCH_PROPERTIES_FULFILLED`: {
      const filterProperties = ({ count, rows }) => {
        /* Pre-fill the lots, areas, and centroid properties with the unloaded state. */
        const filteredRows = rows.map(
          ({ id, project_id, address, stats, state, name, code, idx }) => ({
            id,
            project_id,
            address,
            stats,
            state,
            name,
            code,
            idx,
            lots: {
              fetching: false,
              fetched: false,
              error: null,
              data: [],
            },
            areas: {
              fetching: false,
              fetched: false,
              error: null,
              data: [],
            },
            rounds: {
              fetching: false,
              fetched: false,
              error: null,
              data: [],
            },
            centroid: {
              fetching: false,
              fetched: false,
              error: null,
              data: {},
            },
          })
        );
        return { count, rows: filteredRows };
      };

      return {
        ...state,
        properties: {
          ...state.properties,
          fetching: false,
          fetched: true,
          data: filterProperties(action.payload),
        },
      };
    }
    case `${FEATURE_PREFIX}/FETCH_PROJECT_CENTROID_PENDING`: {
      return {
        ...state,
        properties: {
          ...state.properties,
          data: {
            ...state.properties.data,
            rows: state.properties.data.rows.map((property) => {
              return {
                ...property,
                centroid: {
                  fetching: true,
                  fetched: false,
                  data: [],
                },
              };
            }),
          },
        },
      };
    }
    case `${FEATURE_PREFIX}/FETCH_PROJECT_CENTROID_FULFILLED`: {
      // This API usually returns an array, but since we've queried with a project_id we'll just take the first one.
      const { properties } = action.payload[0];
      const mapCentroidsToProperties = (property) => {
        const matchedProperty = properties.find(({ id }) => property.id === id);
        return {
          ...property,
          centroid: {
            fetching: false,
            fetched: true,
            data: matchedProperty?.centroid || [],
          },
        };
      };
      return {
        ...state,
        properties: {
          ...state.properties,
          data: {
            ...state.properties.data,
            rows: state.properties.data.rows.map(mapCentroidsToProperties),
          },
        },
      };
    }
    case `${FEATURE_PREFIX}/FETCH_PROJECT_STATUSES_PENDING`: {
      return {
        ...state,
        projectStatuses: {
          fetching: true,
          fetched: false,
        },
      };
    }
    case `${FEATURE_PREFIX}/FETCH_PROJECT_STATUSES_FULFILLED`: {
      return {
        ...state,
        projectStatuses: {
          fetching: false,
          fetched: true,
          data: action.payload,
        },
      };
    }

    case `${FEATURE_PREFIX}/FETCH_PROPERTY_LOTS_PENDING`: {
      const mapLotsToProperties = (property) => {
        if (property.id !== action.meta.propertyId) return property;

        return {
          ...property,
          lots: {
            ...property.lots,
            fetching: true,
            fetched: false,
            data: [],
          },
        };
      };

      return {
        ...state,
        properties: {
          ...state.properties,
          data: {
            ...state.properties.data,
            rows: state.properties.data.rows.map(mapLotsToProperties),
          },
        },
      };
    }
    case `${FEATURE_PREFIX}/FETCH_PROPERTY_LOTS_FULFILLED`: {
      const mapLotsToProperties = (property) => {
        if (property.id !== action.meta.propertyId) return property;
        const matchedLot = action.payload.filter(({ property_id }) => property_id === property.id);
        return {
          ...property,
          lots: {
            fetching: false,
            fetched: true,
            data: matchedLot || [],
          },
        };
      };
      return {
        ...state,
        properties: {
          ...state.properties,
          data: {
            ...state.properties.data,
            rows: state.properties.data.rows.map(mapLotsToProperties),
          },
        },
      };
    }
    case `${FEATURE_PREFIX}/FETCH_PROPERTY_AREAS_PENDING`: {
      const mapAreasToProperties = (property) => {
        if (property.id !== action.meta.propertyId) return property;

        return {
          ...property,
          areas: {
            ...property.areas,
            fetching: true,
            fetched: false,
            data: [],
          },
        };
      };

      return {
        ...state,
        properties: {
          ...state.properties,
          data: {
            ...state.properties.data,
            rows: state.properties.data.rows.map(mapAreasToProperties),
          },
        },
      };
    }
    case `${FEATURE_PREFIX}/FETCH_PROPERTY_AREAS_FULFILLED`: {
      const transformArea = (area) => {
        return {
          ...area,
          type: area?.area_type?.name,
        };
      };

      const mapAreasToProperties = (property) => {
        if (property.id !== action.meta.propertyId) return property;
        const matchedArea = action.payload.filter(({ property_id }) => property_id === property.id);
        return {
          ...property,
          areas: {
            fetching: false,
            fetched: true,
            data: matchedArea.map(transformArea) || [],
          },
        };
      };

      return {
        ...state,
        properties: {
          ...state.properties,
          data: {
            ...state.properties.data,
            rows: state.properties.data.rows.map(mapAreasToProperties),
          },
        },
      };
    }

    case `${FEATURE_PREFIX}/FETCH_PROJECT_STATS_PENDING`: {
      return {
        ...state,
        projectStats: {
          ...state.projectStats,
          fetching: true,
          fetched: false,
          data: [],
        },
      };
    }

    case `${FEATURE_PREFIX}/FETCH_PROJECT_STATS_FULFILLED`: {
      return {
        ...state,
        projectStats: {
          ...state.projectStats,
          fetching: false,
          fetched: true,
          // This API usually returns an array, but since we've queried with a project_id we'll just take the first one.
          data: {
            ...action.payload[0],
          },
        },
      };
    }

    case `${FEATURE_PREFIX}/FETCH_ROUND_STATS_PENDING`: {
      const mapLotsToProperties = (property) => {
        if (property.id !== action.meta.propertyId) return property;

        return {
          ...property,
          rounds: {
            ...property.lots,
            fetching: true,
            fetched: false,
            data: [],
          },
        };
      };

      return {
        ...state,
        properties: {
          ...state.properties,
          data: {
            ...state.properties.data,
            rows: state.properties.data.rows.map(mapLotsToProperties),
          },
        },
      };
    }
    case `${FEATURE_PREFIX}/FETCH_ROUND_STATS_FULFILLED`: {
      const mapRoundsToProperties = (property) => {
        if (property.id !== action.meta.propertyId) return property;
        const matchedRound = action.payload.filter(
          ({ property_id }) => property_id === property.id
        );
        return {
          ...property,
          rounds: {
            fetching: false,
            fetched: true,
            data: matchedRound || [],
          },
        };
      };
      return {
        ...state,
        properties: {
          ...state.properties,
          data: {
            ...state.properties.data,
            rows: state.properties.data.rows.map(mapRoundsToProperties),
          },
        },
      };
    }

    default:
      break;
  }

  return state;
}
