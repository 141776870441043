import React from 'react';
import StatsTile from '../../../core/form/components/StatsTile';
import {
  Row
} from 'reactstrap';

const ResourceUsageDevTiles = (props) => {
  const {
    data,
    orgs,
    count
  } = props;

  const keys = Object.keys(data);

  const tiles = keys.map((key, index) => {
    const colour = orgs.find(org => org.name === key).colour;
    const value = data[key] / count;
    return (
      <StatsTile key={index} bottomStyles={{backgroundColor: colour}} value={value} title={`${key} - Avg Devs`} />
    )
  })

  return (
    <div className="p-1 text-center">
      <Row className="d-flex justify-content-center bg-light p-2">
        {tiles}
      </Row>
    </div>
  );
}

export default ResourceUsageDevTiles;
