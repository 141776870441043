import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

import { saveControls } from '../../../core/form/lib/validateForm';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import LabRegisterRecordProfile from './LabRegisterRecordProfile';
import LabRegisterRecordSamples from './LabRegisterRecordSamples';
import LabRegisterRecordDocuments from './LabRegisterRecordDocuments';
import LabRegisterRecordActivities from './LabRegisterRecordActivities';

import {
  fetchLabRegisterDocuments,
  fetchLabRegisterRecord,
  removeLabRegisterRecord,
  updateLabRegisterRecord,
} from '../actions';
import { fetchActivities } from '../../../core/activity/actions';
import { fetchOrgTestCodes } from '../../../manage/actions';

class LabRegisterRecord extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: null,
      id: null,
      isOpen: false,
      data: {},
    };

    this.toggleTab = this.toggleTab.bind(this);

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async componentDidMount() {
    const id = this.props.match.params.id;
    const data = await this.props.dispatch(fetchLabRegisterRecord(id));

    this.setState({
      id,
      data,
    });
    this.toggleTab('2', 'samples');
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_LAB_REGISTER_RECORD' });
  }

  toggleTab(tab, tag) {
    const { id } = this.state;
    const { filters } = this.props.activities;
    const { currentRegisterRecord } = this.props.lab;

    let isOpen = false;
    switch (tag) {
      case 'profile':
        isOpen = true;
        break;
      case 'samples':
        const lab_org_id = currentRegisterRecord.lab_org_id;
        const sample_type_id = currentRegisterRecord.sample_type_id;
        if (lab_org_id)
          this.props.dispatch(fetchOrgTestCodes(lab_org_id, { sample_type_id, type: 'lab' }));
        break;
      case 'documents':
        this.props.dispatch(fetchLabRegisterDocuments(id));
        break;
      case 'activity':
        this.props.dispatch(
          fetchActivities(
            {
              associate_id: id,
              associate_with: 'lab_register',
              show_dependencies: true,
              limit: 30,
            },
            filters
          )
        );
        break;
      default:
        break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        isOpen,
      });
    }
  }

  async onSave(controls) {
    let { id, data } = this.state;
    data = saveControls(controls, data);
    data.id = id;
    let success = await this.props.dispatch(updateLabRegisterRecord(data));
    if (success) this.props.history.goBack();
  }

  onCancel() {
    this.props.history.goBack();
  }

  async onRemove() {
    const { id } = this.state;
    const { currentRegisterRecord } = this.props.lab;

    const confirm_text = `DELETE RECORD ${currentRegisterRecord.job_no?.toUpperCase() ?? 'NOW'}`;
    let confirm_display = `Removing lab record PERMANENTLY including all its associated samples and imports.`;
    confirm_display += `This action can not be reversed. If you are sure, please confirm this action by typing '${confirm_text}' in the input field below and press OK`;

    const confirmation = prompt(confirm_display);
    if (confirmation === confirm_text) {
      await this.props.dispatch(removeLabRegisterRecord(id));
      this.props.history.goBack();
    } else {
      alert('Invalid confirmation, delete aborted');
    }
  }

  render() {
    let { id, isOpen } = this.state;

    const { currentRegisterRecord } = this.props.lab;
    let record_name = '';
    let recordLabJobNo = '-';
    if (currentRegisterRecord.id) {
      if (currentRegisterRecord.area_rounds?.length > 0) {
        let round = currentRegisterRecord.area_rounds[0].area_round;
        record_name = `${round.area.property.idx}-${round.area.name}-${round.name}`;
      } else if (currentRegisterRecord.property_rounds?.length > 0) {
        let round = currentRegisterRecord.property_rounds[0].property_round;
        record_name = `${round.property.idx}-${round.name}`;
      }

      record_name += ` (${currentRegisterRecord.sample_type.name})`;
      recordLabJobNo = currentRegisterRecord.lab_job_no;
    }

    const iconName = 'flask';

    return (
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Lab Register{currentRegisterRecord.id ? `: ${record_name}` : ''}</h3>
          </div>
        </div>
        <div className="bg-light p-1 rounded">
          Lab Job No: <span className="text-corporate">{recordLabJobNo}</span>
        </div>

        <Nav tabs className="mt-2">
          <FormTab
            caption="Profile"
            tabId="1"
            tabTag="profile"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Samples"
            tabId="2"
            tabTag="samples"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Documents"
            tabId="3"
            tabTag="documents"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Activity"
            tabId="4"
            tabTag="activity"
            activeTab={this.state.activeTab}
            toggle={this.toggleTab}
          />
        </Nav>

        <TabContent activeTab={this.state.activeTab} className="border-bottom border-primary">
          <TabPane tabId="1" className="mb-2 p-1">
            <LabRegisterRecordProfile
              isOpen={isOpen}
              id={id}
              onSave={this.onSave}
              onCancel={this.onCancel}
              onRemove={this.onRemove}
            />
          </TabPane>
          <TabPane tabId="2" className="mb-2 p-1">
            <LabRegisterRecordSamples />
          </TabPane>
          <TabPane tabId="3" className="mb-2 p-1">
            <LabRegisterRecordDocuments />
          </TabPane>
          <TabPane tabId="4" className="mb-2 p-1">
            <LabRegisterRecordActivities />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    activities: store.activities,
    lab: store.lab,
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(LabRegisterRecord);
