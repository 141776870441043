import React from 'react';
import Listview from "../../../core/form/components/Listview";

const PipelineSubsamplesLsv = (props) => {
  const {
    rows
  } = props;

  const renderRows = (headers, subsample) => {
    let rowColour = "text-success mr-3"

    const tableTd = headers.map((header, index) => {
      const classes = `${rowColour} ${header.classes}`;
      switch (header.field) {
        default: return (
          <td key={index} className={classes}>{subsample[header.field]}</td>
        )
      }
    });
    return tableTd;
  }

  const headers = [
    { caption: 'Core ID', field: 'core_id', classes: 'text-left' },
    { caption: 'Core Position', field: 'core_position', classes: 'text-center' },
    { caption: 'Upper Depth', field: 'upper_depth', classes: 'text-right' },
    { caption: 'Lower Depth', field: 'lower_depth', classes: 'text-right' },
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} style={{ cursor: "pointer" }}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName = 'object-group';
  const emptyCaption = 'No sub-sample selections found for this round. You can download the round scans and run analysis R-Script locally and then upload the result... or alternativly, execute it on the server with Execute button';

  return (
    <React.Fragment>
      <Listview rows={rows || []} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </React.Fragment>
  );
}

export default PipelineSubsamplesLsv;
