import { defaults } from '../../../../lib/genericReducer';
export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    unlockTemplate: false,
    responseMessage: null,
    requirementMessage: null,
    requirementStatuses: [],
    requirementItems: [],
    itemCategories: [],
    templates: [],
    deadlines: [],
    stages: []
  }, action) {

  state = {
    ...state,
    ...defaults(action)
  }


  switch (action.type) {
    case "SET_UNLOCK_TEMPLATE": {
      return {
        ...state,
        unlockTemplate: action.payload
      }
    }
    case "UNSET_REQUIREMENT_ITEMS": {
      return {
        ...state,
        requirementItems: []
      }
    }
    case "FETCH_DEADLINES_FULFILLED": {
      return {
        ...state,
        deadlines: action.payload
      }
    }
    case "FETCH_STAGE_TEMPLATES_FULFILLED": {
      return {
        ...state,
        templates: action.payload
      }
    }
    case "FETCH_STAGE_REQUIREMENT_ITEMS_FULFILLED": {
      return {
        ...state,
        requirementItems: action.payload
      }
    }
    case "FETCH_REQUIREMENT_STATUSES_FULFILLED": {
      return {
        ...state,
        requirementStatuses: action.payload
      }
    }
    case "FETCH_REQUIREMENT_ITEM_CATEGORIES_FULFILLED": {
      return {
        ...state,
        itemCategories: action.payload
      }
    }
    

    case "CREATE_STAGE_REQUIREMENT_PENDING":
    case "UPDATE_STAGE_REQUIREMENT_FULFILLED":
    case "UPDATE_STAGE_REQUIREMENT_PENDING":
    case "CREATE_STAGE_REQUIREMENT_ITEM_PENDING": {
      return {
        ...state,
        requirementMessage: null
      }
    }

    case "CREATE_STAGE_REQUIREMENT_REJECTED":
    case "UPDATE_STAGE_REQUIREMENT_REJECTED":
    case "CREATE_STAGE_REQUIREMENT_ITEM_REJECTED": {
      return {
        ...state,
        requirementMessage: action.payload
      }
    }
    case "CREATE_STAGE_REQUIREMENT_ITEM_FULFILLED": {
      return {
        ...state,
        requirementMessage: null,
        stages: action.payload
      }
    }
    case "FETCH_STAGE_REQUIREMENTS_REJECTED": {
      return {
        ...state,
        responseMessage: action.payload
      }
    }
    case "FETCH_STAGE_REQUIREMENTS_FULFILLED": {
      const { stages } = state;

      const idx = stages.findIndex(stage => stage.id === action.payload.stage_id)
      if (idx > -1) {
        stages[idx].requirements = action.payload.requirements;
      }

      return {
        ...state,
        stages: stages
      }
    }
    case "CREATE_STAGE_REQUIREMENT_FULFILLED": {
      return {
        ...state,
        requirementMessage: null,
        stages: action.payload
      }
    }
    case "FETCH_STAGES_FULFILLED": {
      return {
        ...state,
        stages: action.payload
      }
    }
    default: break;
  }

  return state;
}
