import React from 'react';
import { Card, CardTitle, Button } from 'reactstrap';
import PageTitleH5 from 'jsx/components/core/form/components/PageTitleH5';

const ProjectAdvanced = ({ onRemove, hasProperties }) => (
  <>
    <PageTitleH5 title="Project settings" />
    <Card className="border rounded border-danger bg-light">
      <CardTitle className="m-0 p-2 bg-danger text-white">Delete Project</CardTitle>
      {hasProperties && (
        <div className="alert alert-warning mx-3 mt-3 mb-0" role="alert">
          You cannot delete a project that has properties assigned to it. Remove all associated
          properties first.
        </div>
      )}
      <div className="d-flex justify-content-between p-3">
        <div>
          Deleting a project is permanent and can not be undone. It also removes all associated
          stratas, site locations, cores and sample results... so click with caution!
        </div>
        <Button disabled={hasProperties} size="sm" color="danger" onClick={onRemove}>
          Delete Project
        </Button>
      </div>
    </Card>
  </>
);

export default ProjectAdvanced;
