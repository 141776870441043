import { cloneDeep } from 'lodash';
import React from 'react';

import GenericLsv from '../../../core/form/components/GenericLsv';

const LivestockPurchasesLsv = (props) => {
  const controls = cloneDeep(props.controls);
  // These controls are dynamically disabled in the modal for purchases

  if (controls && controls.dressing_pcnt && controls.live_weight_per_kg_head) {
    controls.dressing_pcnt.showInListview = false;
    controls.live_weight_per_kg_head.showInListview = false;
  }

  return <GenericLsv {...props} controls={controls} />;
};

export default LivestockPurchasesLsv;
