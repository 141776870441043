import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form } from 'reactstrap';

import {
  initControls,
  updateControls,
  updateControlOptions,
} from 'jsx/components/core/form/lib/validateForm';
import { GenericModal } from 'jsx/components/core/form/components/GenericModal';
import Icon from 'jsx/components/core/icons/Icon';
import ResponseMessageTab from 'jsx/components/core/form/components/ResponseMessageTab';
import { isEmpty, isNil } from 'lodash';

export class LivestockTransactionModal extends GenericModal {
  constructor(props) {
    super(props);

    this.filterProperties = this.filterProperties.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { modalTitle } = this.props;
      const controls = initControls(structuredClone(this.props.controls));
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        setModal: this.props.setModal,
        title: `New ${modalTitle}`,
      };

      if (this.props.data?.id) {
        const { data } = this.props;
        updatedState = {
          ...updatedState,
          controls: updateControls(controls, data),
          data,
          id: data.id,
          isNew: false,
          title: `Edit ${modalTitle}`,
        };
      }

      this.setState(updatedState);
    }
  }

  filterProperties(enterprise_id) {
    const { enterprises } = this.props.enterprises;
    if (isNil(enterprises?.rows) || isEmpty(enterprises?.rows)) return [];

    // Only display properties that have been previously allocated to the enterprise
    const { allocations = [] } = enterprises.rows.find(
      (enterprise) => enterprise.id === enterprise_id,
    );

    return allocations.map((allocation) => ({ ...allocation.property }));
  }

  render() {
    const { iconName, responseMessage, isOpen } = this.props;
    const { title, isNew } = this.state;
    let { controls } = this.state;

    controls = this.setOptions(controls);

    const enterprise_id = controls?.enterprise_id?.value ?? null;
    if (enterprise_id) {
      const filteredProperties = this.filterProperties(enterprise_id);
      controls = updateControlOptions(controls, 'property_id', filteredProperties);
      controls.property_id.showInEditor = filteredProperties.length > 1;
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <ResponseMessageTab responseMessage={responseMessage} />}
          <Form>{!isEmpty(controls) ? this.renderInputs(controls) : null}</Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            {isNew && (
              <Button
                size="sm"
                className="mr-2"
                color="primary"
                onClick={() => this.onSave({ closeModal: false })}
              >
                Save/Add New
              </Button>
            )}
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              {isNew ? 'Save/Close' : 'Save'}
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, direct_costs, enterprises }) => ({
  attributes,
  direct_costs,
  enterprises,
});

export default connect(mapStoreToProps)(LivestockTransactionModal);
