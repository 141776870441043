import { Controls } from 'jsx/components/core/form/components/FormBuilder';

export type projectRegisterDataKeys =
  | 'id'
  | 'lga'
  | 'participant_client_no'
  | 'nearest_town'
  | 'nrm_region'
  | 'delivery_officer_id'
  | 'abatement_volume'
  | 'planning_officer_id'
  | 'state'
  | 'declaration_date'
  | 'lodged_date';

export const projectRegisterForm: Controls<projectRegisterDataKeys> = {
  id: {
    name: 'id',
    path: 'id',
    value: '',
    type: 'hidden',
    validationRules: {},
  },
  lga: {
    name: 'lga',
    path: 'lga',
    caption: 'LGA',
    value: '',
    type: 'text',
    validationRules: {},
  },
  participant_client_no: {
    name: 'participant_client_no',
    path: 'participant_client_no',
    caption: 'Participant Client ID',
    value: '',
    type: 'text',
    validationRules: {},
  },
  nearest_town: {
    name: 'nearest_town',
    path: 'nearest_town',
    caption: 'Nearest Town',
    value: '',
    type: 'text',
    validationRules: {},
  },
  nrm_region: {
    name: 'nrm_region',
    path: 'nrm_region',
    caption: 'NRM Region',
    value: '',
    type: 'text',
    validationRules: {},
  },
  delivery_officer_id: {
    name: 'delivery_officer_id',
    path: 'delivery_officer_id',
    caption: 'Delivery Officer',
    value: '',
    type: 'select',
    options: [],
    validationRules: {},
  },
  abatement_volume: {
    name: 'abatement_volume',
    path: 'abatement_volume',
    caption: 'Abatement Volume',
    value: '',
    type: 'number',
    validationRules: {},
  },
  planning_officer_id: {
    name: 'planning_officer_id',
    path: 'planning_officer_id',
    caption: 'Planning Officer',
    value: '',
    type: 'select',
    options: [],
    validationRules: {},
  },
  state: {
    name: 'state',
    path: 'state',
    caption: 'State',
    value: '',
    type: 'text',
    validationRules: {},
  },
  declaration_date: {
    name: 'declaration_date',
    path: 'declaration_date',
    caption: 'Declaration Date',
    value: '',
    type: 'date',
    validationRules: {},
  },
  lodged_date: {
    name: 'lodged_date',
    path: 'lodged_date',
    caption: 'Lodged Date',
    value: '',
    type: 'date',
    validationRules: {},
  },
};
