import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';

interface PillProps {
  size?: 'sm' | 'md';
  caption: string;
  classes?: string;
  onDelete?: (id: string) => void;
  id: string;
  style?: React.CSSProperties;
}

const Pill = ({ size = 'md', caption, classes = '', onDelete, id, style }: PillProps) => {
  const padding = size === 'sm' ? 'p-0' : 'p-1';
  const classNames = `d-flex justify-content-between m-0 mr-2 text-white text-nowrap rounded ${padding} ${classes}`;
  const iconName = 'xmark';

  return (
    <small className={classNames} style={style}>
      <span className="ml-1 mr-1" style={{ lineHeight: '21px' }}>
        {caption}
      </span>
      {onDelete !== undefined && (
        <button
          type="button"
          className="mr-1 border-0 bg-transparent text-white"
          aria-label="Delete"
          onClick={() => onDelete(id)}
        >
          <Icon name={iconName} />
        </button>
      )}
    </small>
  );
};

export default Pill;
