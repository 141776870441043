import { get } from '../../../../lib/genericAction';

import { apiAttributes } from '../../../../constants/api';

export function fetchAttributes(params) {
  return async (dispatch) =>
    await get(
      dispatch,
      `FETCH_ATTRIBUTES${params.type ? `_${params.type.toUpperCase()}` : ''}`,
      `${apiAttributes}`,
      params
    );
}

export function fetchAttribute(params) {
  return async (dispatch) =>
    await get(
      dispatch,
      `FETCH_ATTRIBUTE`,
      `${apiAttributes}/${params.id ? params.id : `${params.type}/${params.tag}`}`
    );
}
