import { ReactElement } from 'react';

/**
 * Modify an already generated component instance to attach an extra className
 * @date 16/06/2023 - 15:01:38
 *
 * @param {ReactElement} element
 * @param {string} extraClassName
 * @returns {*}
 */
export const applyClassName = (element: ReactElement, extraClassName: string) => ({
  ...element,
  props: {
    ...element.props,
    className: `${element.props?.className || ''} ${extraClassName}`,
  },
});

/**
 * Apply a transform to all components in an entire component tree
 * @date 16/06/2023 - 15:03:08
 *
 * @param {ReactElement} rootElement
 * @param {(subject: ReactElement) => ReactElement} transformer
 * @returns {ReactElement}
 */
export const modifyChildElements = (
  rootElement: ReactElement,
  transformer: (subject: ReactElement) => ReactElement,
): ReactElement => {
  const hasChildrenList = Array.isArray(rootElement.props?.children);

  let newRoot = rootElement;
  if (hasChildrenList) {
    newRoot = {
      ...rootElement,
      props: {
        ...rootElement.props,
        children: rootElement.props.children
          .map((child: ReactElement) => {
            if (!child) return false;

            return modifyChildElements(child, transformer);
          })
          .filter(Boolean),
      },
    };
  }

  return transformer(newRoot);
};
