export default class TimeFormat {
  constructor() {
    this.output = 'YYYY-MM-DDTHH:mmZZ';
    this.day = 'yyyy-MM-DD';
    this.time = 'HH:mm';
    this.week = 'dddd';
  }

  asString(duration) {
    const includeNegatviveSign = duration < 0 ? '-' : '';
    return `${includeNegatviveSign}${this.asHours(duration)}:${this.asMinutes(duration)}`;
  }

  asHours(duration) {
    return Math.abs(parseInt(duration));
  }

  asMinutes(duration) {
    const minutes = Math.abs(((duration * 60) % 60).toFixed(0));
    return String(minutes).padStart(2, '0');
  }
}
