import { useMemo } from 'react';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import { Control, Controls } from 'jsx/components/core/form/components/FormBuilder';
import { cloneDeep, omit } from 'lodash';

export interface LegacyAdapterLsvProps {
  controls: Controls<string>;
  rows: Record<string, any>[];
}

const tableDefaults = {
  allowTotals: false,
  darkTable: false,
  rows: [],
  onClick: () => undefined,
  controls: {},
  iconName: undefined,
  emptyCaption: '',
  hideHeader: false,
  actions: [],
  totalFormattingRules: undefined,
};

export const migrateControlsFromLegacyFormat = (legacyControls: Record<string, unknown>) => {
  const newControls = {} as Controls<string>;

  Object.keys(legacyControls).forEach((key) => {
    const existingControl = legacyControls[key];
    const newControl = cloneDeep(existingControl) as any;
    if (newControl.options) {
      newControl.options = newControl.options.map((option: any) => ({
        ...option,
        value: option.id === null ? '' : option.id,
        label: option.label === undefined ? option.name : option.label,
      }));
    }

    newControls[key] = newControl as Control;
  });

  return newControls as Controls<string>;
};

export const migrateControlsToLegacyFormat = (newTypedControls: Controls<string>) => {
  const legacyControls = {} as Record<string, unknown>;

  Object.keys(newTypedControls).forEach((key) => {
    const existingControl = newTypedControls[key];

    // In GenericLSV, the listview options are flattened into the control object
    const listviewOptions = omit(existingControl?.listview, ['show']);
    legacyControls[key] = {
      ...existingControl,
      fieldName: existingControl.path,
      showInListview: existingControl?.listview?.show || existingControl.type !== 'hidden',
      // GenericLsv is looking for options by id, but new forms store it as value
      options:
        existingControl.type === 'select'
          ? existingControl.options.map((option) => ({ ...option, id: option.value }))
          : undefined,
      type: existingControl.type === 'switch' ? 'checkbox' : existingControl.type,
      ...listviewOptions,
    };
  });

  return legacyControls;
};

/**
 * Transforms the new controls to a format that the GenericLsv component can understand
 */
const GenericLsvAdapter = ({ rows, controls, ...props }: LegacyAdapterLsvProps) => {
  const controlsWithShowInListView = useMemo(
    () => migrateControlsToLegacyFormat(controls),
    [controls],
  );

  return (
    <div className="generic-wrapper-lsv">
      <GenericLsv {...tableDefaults} {...props} rows={rows} controls={controlsWithShowInListView} />
    </div>
  );
};

export default GenericLsvAdapter;
