import React, { createRef } from 'react';
import { connect } from 'react-redux';
import FieldBookForm from '../components/FieldBookForm';
import FormBase from '../../../core/form/components/FormBase';
import FieldBookLsv from '../components/FieldBookLsv';
import FieldBookHeader from '../components/FieldBookHeader';
import FieldBookSearch from '../components/FieldBookSearch';
import DirectDownload from '../../../core/form/components/DirectDownload';
import { controls } from '../forms/fieldbook';
import {
  validateChange,
  saveFormControls,
  updateControls,
} from '../../../core/form/lib/validateForm';

import Downloads from '../lib/downloads';

import {
  fetchFieldTrip,
  fetchFieldTripSites,
  fetchSites,
  updateSite,
  createFieldTripSite,
  removeFieldTripSite,
} from '../actions';

import Icon from 'jsx/components/core/icons/Icon';
import BreadcrumbsRoute from 'jsx/components/core/form/components/BreadcrumbsRoute';

class FieldBook extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: controls,
      formDisabled: true,
      site: {},
      searchValue: '',
      responseMessage: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.resetControls = this.resetControls.bind(this);

    this.dateRef = createRef();
    this.searchRef = createRef();
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    this.downloads = new Downloads();
    this.downloads.set(this.props.dispatch);

    this.props.dispatch(fetchFieldTrip(id));
    this.props.dispatch(fetchFieldTripSites({ fieldtrip_id: id }));
    this.props.dispatch({ type: 'UNSET_SITES' });
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_STRATAS' });
    this.props.dispatch({ type: 'UNSET_SITES' });
    this.props.dispatch({ type: 'UNSET_ROUND' });
  }

  componentDidUpdate() {
    const { sites } = this.props.sites;

    let { formDisabled, controls } = this.state;

    if (sites.length > 0 && formDisabled) {
      const site = sites[0];

      controls = updateControls(controls, site);
      this.setState(
        {
          responseMessage: `Core is ${site.core_key} from CEA${site.strata.round.area.name}-${site.strata.round.name}-${site.strata.name}`,
          site,
          formDisabled: false,
          controls,
          firstFocus: true,
        },
        () => {
          this.dateRef.current.focus();
        }
      );
    }
  }

  async onSave(event) {
    event.stopPropagation();
    event.preventDefault();

    const { controls, site } = this.state;
    const { id } = this.props.match.params;

    // Update Site
    const formData = saveFormControls(controls, site);
    await this.props.dispatch(updateSite(formData));

    // Associate FieldBook to site
    let bookSiteData = {
      fieldtrip_id: this.props.match.params.id,
      site_id: site.id,
    };
    await this.props.dispatch(createFieldTripSite(bookSiteData));

    this.props.dispatch({ type: 'UNSET_SITES' });
    this.props.dispatch(fetchFieldTripSites({ fieldtrip_id: id }));

    // Reset form
    this.resetControls();

    this.setState(
      {
        searchValue: '',
        formDisabled: true,
      },
      () => {
        this.searchRef.current.focus();
      }
    );
  }

  async onRemove(id) {
    const confirmed = window.confirm(
      `Removing the FieldBook association with site. This does NOT remove the site itself. Continue?`
    );
    if (confirmed) {
      await this.props.dispatch(removeFieldTripSite(id));
      this.props.dispatch(fetchFieldTripSites({ fieldtrip_id: this.props.match.params.id }));
    }
  }

  onCancel() {
    this.props.dispatch({ type: 'UNSET_SITES' });
    this.setState({
      site: {},
      formDisabled: true,
      searchValue: '',
    });

    this.searchRef.current.focus();
  }

  resetControls() {
    let { controls } = this.state;

    const keys = Object.keys(controls);
    keys.forEach((key) => {
      if (!controls[key].persist) {
        controls[key].value = '';
      }
    });

    this.setState({
      controls,
    });
  }

  handleChange(event) {
    event.preventDefault();

    const controls = validateChange(event, this.state.controls);
    this.setState({
      controls: controls,
      formChanged: true,
    });
  }

  handleSearchChange(event) {
    this.props.dispatch({ type: 'UNSET_SITES' });
    this.setState({
      site_id: null,
      formDisabled: true && this.checkAccess('fieldBookUpdate'),
      searchValue: event.target.value,
    });
  }

  onSearch(event) {
    const { searchValue } = this.state;

    if (event.keyCode === 13 || event.type === 'click') {
      event.preventDefault();
      this.props.dispatch({ type: 'UNSET_SITES' });
      this.props.dispatch(fetchSites({ core_id: searchValue }, null));
    }
  }

  render() {
    const { fieldtrip, fieldtrip_sites } = this.props.sites;
    const { controls, formDisabled, searchValue, responseMessage } = this.state;
    const iconName = 'book-open';
    const name = `Field Book: ${fieldtrip.name}`;

    // Link Document
    return (
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>{name}</h3>
          </div>
          <DirectDownload
            classNames="p-0 justify-content-end"
            buttonType="primary"
            handleDownload={(event) => this.downloads.handleFieldTripDownload(fieldtrip.id, event)}
            showLabel={true}
            showIcon={true}
          />
        </div>

        <BreadcrumbsRoute match={this.props.match} />

        <FieldBookHeader fieldtrip={fieldtrip || {}} />
        {this.checkAccess('fieldBookUpdate') && (
          <FieldBookSearch
            innerRef={this.searchRef}
            responseMessage={responseMessage}
            value={searchValue}
            handleChange={this.handleSearchChange}
            onSearch={this.onSearch}
          />
        )}
        {this.checkAccess('fieldBookUpdate') && (
          <FieldBookForm
            dateRef={this.dateRef}
            disabled={formDisabled}
            controls={controls}
            handleChange={this.handleChange}
            onSave={this.onSave}
            onCancel={this.onCancel}
          />
        )}
        {!this.checkAccess('fieldBookUpdate') && (
          <div className="p-3 text-danger text-center">No Permissions to edit this form</div>
        )}

        <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">
          Field Book Entries{fieldtrip_sites.length > 0 ? `: ${fieldtrip_sites.length}` : ''}
        </h5>

        <FieldBookLsv
          checkAccess={this.checkAccess}
          onRemove={this.onRemove}
          rows={fieldtrip_sites}
        />
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    sites: store.sites,
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(FieldBook);
