import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    activeTab: '1',
    property_other_incomes: {},
  },
  action
) {
  const types = ['PROPERTY_OTHER_INCOME'];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  switch (action.type) {
    case 'FETCH_PROPERTY_OTHER_INCOMES_FULFILLED': {
      return {
        ...state,
        property_other_incomes: action.payload,
      };
    }
    case 'UNSET_PROPERTY_OTHER_INCOMES_ATTRIBUTES': {
      return {
        ...state,
        property_other_incomes: [],
      };
    }
    default:
      break;
  }

  return state;
}
