import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import {
  Button
} from 'reactstrap';
import SingleSearch from "../../../core/form/components/SingleSearch";
import LabRegisterMenu from './LabRegisterMenu';

const LabRegisterToolbar = (props) => {
  const {
    refresh,
    onCreate,
    onSearch,
    handleSearchChange,
    handleDownloadCustodyChains,
    handleUpdateRecords,
    searchValue,
    menuDisabled,
    checkAccess
  } = props;

  return (
    <React.Fragment>
      <div className="bg-light p-2 d-flex justify-content-end mb-1">
        <SingleSearch handleSearchChange={handleSearchChange} onSearch={onSearch} placeholder="Search" value={searchValue} />
        <Button color='success' onClick={refresh} className="mr-2" size="sm">
          <Icon name="rotate" />
        </Button>
        <Button id="toggler" size="sm" color="warning" className="text-white ml-2">Filters</Button>
        <Button disabled={!checkAccess('labRecordCreate')} size="sm" color="success" className="ml-2 mr-2" onClick={onCreate} >Create Chain of Custody</Button>
        <LabRegisterMenu 
          handleDownloadCustodyChains={(event) => handleDownloadCustodyChains(event)}
          handleUpdateRecords={handleUpdateRecords}
          disabled={menuDisabled}
        />
      </div>
    </React.Fragment>
  );
}

export default LabRegisterToolbar;
