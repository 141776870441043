import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'reactstrap';
import { cloneDeep } from 'lodash';
import {
  updateControls,
  initControls,
  updateControlOptions,
} from '../../../core/form/lib/validateForm';
import FormBase from '../../../core/form/components/FormBase';
import FormInput from '../../../core/form/components/FormInput';
import { controls } from '../forms/labRegisterRecord';

import { fetchLabRegisterRecord, fetchLabStatuses, fetchLabContacts } from '../actions';
import { fetchAllCeas } from '../../areas/actions';
import { fetchDocumentDefs, fetchOrgs } from '../../../manage/actions';
import { fetchAllPropertyRounds } from '../../projects/actions/property_rounds';

class LabRegisterRecordProfile extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      data: {},
      id: null,
      controls: cloneDeep(controls),
    };

    this.renderInputs = this.renderInputs.bind(this);
  }

  async componentDidUpdate() {
    let { id, controls } = this.state;
    const { isOpen } = this.state;

    const { record_type, record_round_id } = this.props;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      controls = initControls(controls);

      controls.record_type.value = record_type;
      switch (record_type) {
        case 'area_round_id':
          controls.area_round_id.value = record_round_id;
          break;
        case 'property_round_id':
          controls.property_round_id.value = record_round_id;
          break;
        default:
          break;
      }

      this.props.dispatch(fetchAllCeas());
      this.props.dispatch(fetchAllPropertyRounds());
      this.props.dispatch(fetchLabStatuses());
      this.props.dispatch(fetchDocumentDefs({ group_key: 'lab_register' }));
      this.props.dispatch(fetchLabContacts());

      if (this.props.id) {
        ({ id } = this.props);

        this.props.dispatch(fetchLabRegisterRecord(id)).then((data) => {
          if (data.area_rounds.length > 0) {
            data.record_type = 'area_round_id';
            data.area_round_id = data.area_rounds[0].area_round_id;
          } else if (data.property_rounds.length > 0) {
            data.record_type = 'property_round_id';
            data.property_round_id = data.property_rounds[0].property_round_id;
          }
          controls = updateControls(controls, data);

          // Disable record type and round selection.
          controls.area_round_id.disabled = true;
          controls.property_round_id.disabled = true;
          controls.record_type.disabled = true;

          this.setState({ controls });
        });
      }

      this.props
        .dispatch(fetchOrgs({ category_tag: 'lab' }))
        .then((lab_orgs) => (controls.lab_orgs = lab_orgs));

      this.props
        .dispatch(fetchOrgs({ category_tag: 'transport' }))
        .then((transport_orgs) => (controls.transport_orgs = transport_orgs));

      this.setState({
        isOpen: this.props.isOpen,
        controls,
        id,
      });
    }
  }

  renderInputs(controls) {
    const { allceas } = this.props.areas;
    const { currentRegisterRecord, statuses, contacts } = this.props.lab;
    const { documentDefs } = this.props.manage;
    const { propertyRounds } = this.props.properties;
    const { transport_orgs, lab_orgs } = controls;

    const namedAreaRounds = allceas.map((round) => ({
      id: round.id,
      name: `${round.area.property.idx}-${round.area.name}-${round.name}`,
    }));

    const namedPropertyRounds = propertyRounds.map((round) => ({
      id: round.id,
      name: `${round.property.idx}-${round.name}`,
    }));

    controls = updateControlOptions(controls, 'area_round_id', namedAreaRounds);
    controls = updateControlOptions(controls, 'property_round_id', namedPropertyRounds);
    controls = updateControlOptions(controls, 'status_id', statuses);
    controls = updateControlOptions(controls, 'contact_user_id', contacts);
    controls = updateControlOptions(controls, 'sample_type_id', documentDefs);
    controls = updateControlOptions(controls, 'record_type', controls['record_type'].options);

    controls = updateControlOptions(controls, 'transport_org_id', transport_orgs);
    controls = updateControlOptions(controls, 'lab_org_id', lab_orgs);

    let sampled_by = '';
    let sampled_at;
    if (currentRegisterRecord.lab_register_samples) {
      const samples = currentRegisterRecord.lab_register_samples.filter(
        (sample) => sample.sampled_user !== null
      );

      const sample_users = samples.map(
        (sample) => `${sample.sampled_user.firstname.charAt(0)} ${sample.sampled_user.lastname}`
      );

      const unique_users = [...new Set(sample_users)];
      sampled_by = unique_users.join(', ');

      const sample_dates = samples.map((sample) => sample.sampled_at).filter(Boolean);
      if (sample_dates.length > 0) sampled_at = sample_dates[sample_dates.length - 1];
    }

    controls.sampled_by.value = sampled_by;
    controls.sampled_at.value = sampled_at;

    return controls;
  }

  render() {
    let { controls } = this.state;
    const { onSave, onCancel, onRemove } = this.props;
    const { responseMessage } = this.props.lab;

    controls = this.renderInputs(controls);

    const onChange = this.props.onChange ?? this.handleChange;

    return (
      <>
        {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
        <Form>
          <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Record</h5>
          <Row className="bg-light m-0 pb-2">
            <Col>
              <FormInput handleChange={onChange} control={controls.record_type} />
            </Col>
            <Col />
          </Row>
          <Row className="bg-light m-0 pb-2">
            <Col>
              {controls.record_type.value === 'area_round_id' && (
                <FormInput handleChange={onChange} control={controls.area_round_id} />
              )}
              {controls.record_type.value === 'property_round_id' && (
                <FormInput handleChange={onChange} control={controls.property_round_id} />
              )}
            </Col>
            <Col>
              <FormInput handleChange={onChange} control={controls.status_id} />
            </Col>
          </Row>
          <Row className="bg-light m-0 pb-2">
            <Col>
              <FormInput handleChange={onChange} control={controls.job_no} />
            </Col>
            <Col>
              <FormInput handleChange={onChange} control={controls.purchase_order_no} />
            </Col>
          </Row>
          <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Sample</h5>
          <Row xs="2" className="bg-light m-0 pb-2">
            <Col>
              <FormInput handleChange={onChange} control={controls.sample_type_id} />
            </Col>
          </Row>
          <Row xs="2" className="bg-light m-0 pb-2">
            <Col>
              <FormInput handleChange={onChange} disabled={true} control={controls.sampled_by} />
            </Col>
            <Col>
              <FormInput handleChange={onChange} disabled={true} control={controls.sampled_at} />
            </Col>
          </Row>
          <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Transport</h5>
          <Row xs="2" className="bg-light m-0 pb-2">
            <Col>
              <FormInput handleChange={onChange} control={controls.transport_org_id} />
            </Col>
            <Col>
              <FormInput handleChange={onChange} control={controls.transport_booking_no} />
            </Col>
          </Row>

          <h5 className="m-0 mt-3 ml-1 text-corporate border-bottom">Lab</h5>
          <Row className="bg-light m-0 pb-2">
            <Col>
              <FormInput handleChange={onChange} control={controls.lab_org_id} />
            </Col>
            <Col>
              <FormInput handleChange={onChange} control={controls.lab_quote_no} />
            </Col>
          </Row>
          <Row xs="2" className="bg-light m-0 pb-2">
            <Col>
              <FormInput handleChange={onChange} control={controls.lab_job_no} />
            </Col>
            <Col>
              <FormInput handleChange={onChange} control={controls.contact_user_id} />
            </Col>
          </Row>
          <Row className="bg-light m-0 pb-2">
            <Col>
              <FormInput handleChange={onChange} control={controls.test_special_instructions} />
            </Col>
          </Row>
        </Form>
        {onSave && (
          <div className="p-2 d-flex justify-content-center mt-2">
            <Button
              disabled={!this.checkAccess('labRecordUpdate')}
              color="success"
              className="m-1"
              onClick={() => onSave(controls)}
            >
              Save
            </Button>
            <Button color="light" className="m-1" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              disabled={!this.checkAccess('labRecordDelete')}
              color="danger"
              className="m-1"
              onClick={onRemove}
            >
              Delete
            </Button>
          </div>
        )}
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    areas: store.areas,
    lab: store.lab,
    manage: store.manage,
    realm: store.realm,
    properties: store.properties,
  };
};

export default connect(mapStoreToProps)(LabRegisterRecordProfile);
