import { controlDefaults } from "../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  name: {
    ...controlDefaults,
    name: 'name',
    type: 'text',
    caption: 'Name',
    disabled: false,
    validationRules: {
      isRequired: true
    },
  },
  key: {
    ...controlDefaults,
    name: 'key',
    type: 'text',
    caption: 'Script Name',
    disabled: false,
    validationRules: {
      isRequired: true
    },
  },
  group_key: {
    ...controlDefaults,
    name: 'group_key',
    type: 'text',
    caption: 'Script Group',
    disabled: false,
    validationRules: {
      isRequired: true
    },
  },
  definition: {
    ...controlDefaults,
    name: 'definition',
    type: 'json',
    caption: 'Definition',
    disabled: false,
    validationRules: {
      isRequired: true,
      isArray: true
    },
  }
}