import React from 'react';
import { connect } from 'react-redux';
import { omit, isNil, set, cloneDeep } from 'lodash';

import { deepFlatten } from 'jsx/lib/generic';

import { selectListviewFilters } from 'jsx/components/manage/selectors';
import {
  getProjects,
  getProjectStatuses,
  getProjectOfficers,
  saveProjectRegisterFilter,
  projectRegisterInstanceName,
  setProjectRegisterFilters,
  setProjectRegisterFilter,
} from '../actions/project_register';

import ProjectRegisterFilter from '../components/projects/ProjectRegisterFilter';
import ProjectRegisterLsv from '../components/projects/ProjectRegisterLsv';

/**
 * Strips out the uneccessary keys from the project register data before flattening
 */
const filterUneccessaryKeys = (list) => {
  const clonedList = cloneDeep(list);
  const keysToRemove = ['variations', 'audits', 'periods', 'notes', 'property_rounds', 'clients'];

  return clonedList.map((item) => {
    const { project } = item;
    const propertiesFiltered = project.properties.map((property) => omit(property, keysToRemove));
    set(item, 'project.properties', propertiesFiltered);

    return item;
  });
};

class ProjectRegister extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      flattenedProjects: [],
    };

    this.refresh = this.refresh.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    this.props.dispatch(getProjectStatuses());
    this.props.dispatch(getProjectOfficers());
  }

  async componentDidUpdate(prevProps) {
    const { dispatch, filters } = this.props;
    if (isNil(prevProps.filters) && !isNil(filters)) {
      await dispatch(setProjectRegisterFilters(filters));
      this.fetchData(false);
    }
  }

  refresh() {
    this.fetchData();
  }

  /**
   * Fetches the project register data
   */
  async fetchData() {
    const {
      filters: {
        projectOfficer: projectOfficerSelection = [],
        projectStatus: projectStatusSelection = [],
      },
    } = this.props.project_register;
    const projectFiltersAndParams = {
      params: { search_value: this.state.searchValue },
      filters: {
        assignedto_id: projectOfficerSelection,
        project_status_id: projectStatusSelection,
      },
    };
    await this.props.dispatch(getProjects(projectFiltersAndParams));

    this.projectRegisterMapping();
  }

  projectRegisterMapping() {
    const {
      projectRegisters: { rows: projectRegisters = [] },
    } = this.props.project_register;
    const filterProjects = filterUneccessaryKeys(projectRegisters);
    const flattenedProjects = deepFlatten(filterProjects);

    this.setState({ flattenedProjects });
  }

  handleSearchChange({ target, code }) {
    const value = target?.value;
    this.setState({ searchValue: value });

    if (code === 'Enter' || code === 'NumpadEnter') {
      this.handleSearch(value);
    }
  }

  handleSearch = () => {
    this.fetchData();
  };

  handleFilterChange = async (event, type) => {
    const value = event?.target?.value || '';
    await this.props.dispatch(setProjectRegisterFilter(type, value));
    this.props.dispatch(saveProjectRegisterFilter());
    this.fetchData();
  };

  handleRemoveFilter = async (value, type) => {
    await this.props.dispatch({
      type: 'UNSET_PROJECT_REGISTER_FILTER',
      payload: {
        filterToUpdate: type,
        value,
      },
    });
    this.props.dispatch(saveProjectRegisterFilter());
    this.fetchData();
  };

  render() {
    const { flattenedProjects, mappings, searchValue } = this.state;
    const {
      projectRegisters: { count: projectRegistersCount = 0 },
    } = this.props.project_register;

    return (
      <>
        <ProjectRegisterFilter
          handleSearchChange={this.handleSearchChange}
          handleSearch={this.handleSearch}
          searchValue={searchValue}
          projectCount={flattenedProjects.length}
          projectTotal={projectRegistersCount}
          onRefresh={this.refresh}
          handleFilterChange={this.handleFilterChange}
          handleRemoveFilter={this.handleRemoveFilter}
        />
        <ProjectRegisterLsv projects={flattenedProjects} mappings={mappings} />
      </>
    );
  }
}

const mapStoreToProps = (state) => ({
  projects: state.projects,
  project_register: state.project_register,
  filters: selectListviewFilters(state, projectRegisterInstanceName),
});

export default connect(mapStoreToProps)(ProjectRegister);
