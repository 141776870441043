import { Button, Label } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import FormInputSmall from 'jsx/components/core/form/components/FormInputSmall';

const AdminProbeSectionGroupEditor = (props) => {
  const {
    onSave = noop,
    toggleClose = noop,
    onHandleChange = noop,
    controls
  } = props;

  return (
    <div className="d-flex justify-content-end mr-2 bg-lightgray p-1 pr-2 pl-2 rounded">
      <Label className=" m-0 mt-1 mr-2">Group:</Label>
      <FormInputSmall handleChange={onHandleChange} control={controls.name} />
      <FormInputSmall handleChange={onHandleChange} control={controls.sequence}  style={{width:100}}/>

      <Button onClick={onSave} size="sm" color="success" className="ml-1 text-white">
        Save
      </Button>
      <Button onClick={() => toggleClose(false)} size="sm" color="light" className="ml-1">
        Cancel
      </Button>
    </div>
  );
};

export default AdminProbeSectionGroupEditor;
