import { cloneDeep } from 'lodash';

import {
  apiAttributeDefs,
  apiCostcodes,
  apiDataPreparations,
  apiDocumentDefs,
  apiInvitations,
  apiLeavecodes,
  apiMethodologies,
  apiOrgs,
  apiOutputMappings,
  apiPhasecodes,
  apiRoles,
  apiScripts,
  apiSoilTemplates,
  apiTestCodes,
  apiUsers,
} from '../../../constants/api';
import { get, post, put, remove, upload } from '../../../lib/genericAction';

export function fetchRoles() {
  return async (dispatch) => get(dispatch, 'FETCH_ROLES', apiRoles);
}

export function fetchUsersWithRole(role) {
  return async (dispatch) => get(dispatch, 'FETCH_USERS_WITH_ROLE', `${apiUsers}/roles/${role}`);
}

export function fetchUsers(params, filters) {
  return async (dispatch) => {
    // Apply filters to params
    const updatedParams = cloneDeep(params);
    if (filters?.users?.length > 0)
      Object.keys(filters).forEach((key) => {
        updatedParams[`filter_${key}`] = filters[key];
      });

    return get(dispatch, 'FETCH_USERS', apiUsers, updatedParams);
  };
}

export function fetchUser(id) {
  return async (dispatch) => get(dispatch, 'FETCH_USER', `${apiUsers}/${id}`);
}

export function fetchUserRoles(id) {
  return async (dispatch) => get(dispatch, 'FETCH_USER_ROLES', `${apiUsers}/${id}/roles`);
}

export function fetchInvitations() {
  return async (dispatch) => get(dispatch, 'FETCH_INVITED', apiInvitations);
}

export function updateUser(data) {
  return async (dispatch) => put(dispatch, 'UPDATE_USER', `${apiUsers}/${data.id}`, data);
}

export function generateUserToken(id) {
  return async (dispatch) => put(dispatch, 'UPDATE_USER_TOKEN', `${apiUsers}/${id}/generate_token`);
}

export function removeUser(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_USER', `${apiUsers}/${id}`);
}

export function removeInvite(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_USERINVITE', `${apiInvitations}/${id}`);
}

export function setUserParams(params) {
  return async (dispatch) => dispatch({ type: 'SET_USER_PARAMS', payload: params });
}

export function setUserFilters(filters) {
  return async (dispatch) => dispatch({ type: 'SET_USER_FILTERS', payload: filters });
}

export function inviteUser(user) {
  return async (dispatch) => post(dispatch, 'INVITE_USER', `${apiInvitations}`, user);
}

export function inviteLink(user) {
  return async (dispatch) => post(dispatch, 'INVITE_LINK', `${apiInvitations}/link`, user);
}

export function fetchInviteOrgs(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_INVITE_ORGS', `${apiInvitations}/orgs/inviting`, params);
}

export function fetchAttributeDefs(params) {
  return async (dispatch) => get(dispatch, 'FETCH_ATTRIBUTE_DEFS', `${apiAttributeDefs}`, params);
}

export function createAttributeDef(data) {
  return async (dispatch) => post(dispatch, 'CREATE_ATTRIBUTE_DEF', `${apiAttributeDefs}`, data);
}

export function updateAttributeDef(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_ATTRIBUTE_DEF', `${apiAttributeDefs}/${data.id}`, data);
}

export function removeAttributeDef(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_ATTRIBUTE_DEF', `${apiAttributeDefs}/${id}`);
}

export function fetchDocumentDefs(params) {
  return async (dispatch) => get(dispatch, 'FETCH_DOCUMENT_DEFS', `${apiDocumentDefs}`, params);
}

export function createDocumentDef(data) {
  return async (dispatch) => post(dispatch, 'CREATE_DOCUMENT_DEF', `${apiDocumentDefs}`, data);
}

export function updateDocumentDef(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_DOCUMENT_DEF', `${apiDocumentDefs}/${data.id}`, data);
}

export function removeDocumentDef(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_DOCUMENT_DEF', `${apiDocumentDefs}/${id}`);
}

export function fetchOrgs(params) {
  return async (dispatch) => get(dispatch, 'FETCH_ORGS', `${apiOrgs}`, params);
}

export function setOrgParams(params) {
  return async (dispatch) => {
    dispatch({ type: 'SET_ORG_PARAMS', payload: params });
    return dispatch(fetchOrgs(params));
  };
}

export function fetchOrg(id) {
  return async (dispatch) => get(dispatch, 'FETCH_ORG', `${apiOrgs}/${id}`);
}

export function createOrg(data) {
  return async (dispatch) => post(dispatch, 'CREATE_ORG', `${apiOrgs}`, data);
}

export function createClientOrg(data) {
  return async (dispatch) => post(dispatch, 'CREATE_ORG', `${apiOrgs}/client/create`, data);
}

export function updateOrg(data) {
  return async (dispatch) => put(dispatch, 'UPDATE_ORG', `${apiOrgs}/${data.id}`, data);
}

export function removeOrg(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_ORG', `${apiOrgs}/${id}`);
}

export function fetchOrgTestCodes(id, params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_ORG_TEST_CODES', `${apiOrgs}/${id}/sample_test_codes`, params);
}

export function createOrgTestCode(data) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_ORG_TEST_CODE', `${apiOrgs}/${data.id}/sample_test_codes`, data);
}

export function removeOrgTestCode(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_ORG_TEST_CODE', `${apiOrgs}/sample_test_codes/${id}`);
}

export function fetchTestCodes(params) {
  return async (dispatch) => get(dispatch, 'FETCH_TEST_CODES', `${apiTestCodes}`, params);
}

export function createTestCode(data) {
  return async (dispatch) => post(dispatch, 'CREATE_TEST_CODE', `${apiTestCodes}`, data);
}

export function updateTestCode(data) {
  return async (dispatch) => put(dispatch, 'UPDATE_TEST_CODE', `${apiTestCodes}/${data.id}`, data);
}

export function removeTestCode(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_TEST_CODE', `${apiTestCodes}/${id}`);
}

export function fetchSoilTemplates() {
  return async (dispatch) => get(dispatch, 'FETCH_SOIL_TEMPLATES', `${apiSoilTemplates}`);
}

export function createSoilTemplate(data) {
  return async (dispatch) => post(dispatch, 'CREATE_SOIL_TEMPLATE', `${apiSoilTemplates}`, data);
}

export function updateSoilTemplate(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_SOIL_TEMPLATE', `${apiSoilTemplates}/${data.id}`, data);
}

export function removeSoilTemplate(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_SOIL_TEMPLATE', `${apiSoilTemplates}/${id}`);
}

export function createSoilTemplateLayer(data) {
  return async (dispatch) =>
    post(
      dispatch,
      'CREATE_SOIL_TEMPLATE_LAYER',
      `${apiSoilTemplates}/${data.template_id}/layers`,
      data,
    );
}

export function updateSoilTemplateLayer(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_SOIL_TEMPLATE_LAYER', `${apiSoilTemplates}/layers/${data.id}`, data);
}

export function removeSoilTemplateLayer(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_SOIL_TEMPLATE_LAYER', `${apiSoilTemplates}/layers/${id}`);
}

export function createUserRole(data) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_USER_ROLE', `${apiUsers}/${data.user_id}/roles`, data);
}

export function removeUserRole(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_USER_ROLE', `${apiUsers}/roles/${id}`);
}

export function createUserOrg(data) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_USER_ORG', `${apiUsers}/${data.user_id}/orgs`, data);
}

export function removeUserOrg(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_USER_ORG', `${apiUsers}/orgs/${id}`);
}

export function fetchLeavecodes(params) {
  return async (dispatch) => get(dispatch, 'FETCH_LEAVECODES', `${apiLeavecodes}`, params);
}

export function fetchOutputMappings(params) {
  return async (dispatch) => get(dispatch, 'FETCH_OUTPUT_MAPPINGS', `${apiOutputMappings}`, params);
}

export function createOutputMapping(data) {
  return async (dispatch) => post(dispatch, 'CREATE_OUTPUT_MAPPING', `${apiOutputMappings}`, data);
}

export function updateOutputMapping(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_OUTPUT_MAPPING', `${apiOutputMappings}/${data.id}`, data);
}

export function removeOutputMapping(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_OUTPUT_MAPPING', `${apiOutputMappings}/${id}`);
}

export function fetchPhasecodes(params) {
  return async (dispatch) => get(dispatch, 'FETCH_PHASECODES', `${apiPhasecodes}`, params);
}

export function createPhasecodes(data) {
  return async (dispatch) => post(dispatch, 'CREATE_PHASECODES', `${apiPhasecodes}`, data);
}

export function removePhasecode(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_PHASECODE', `${apiPhasecodes}/${id}`);
}

export function fetchCostcodes(params) {
  return async (dispatch) => get(dispatch, 'FETCH_COSTCODES', `${apiCostcodes}`, params);
}

export function fetchCostcode(id) {
  return async (dispatch) => get(dispatch, 'FETCH_COSTCODE', `${apiCostcodes}/${id}`);
}

export function createCostcode(data) {
  return async (dispatch) => post(dispatch, 'CREATE_COSTCODE', apiCostcodes, data);
}

export function updateCostcode(data) {
  return async (dispatch) => put(dispatch, 'UPDATE_COSTCODE', `${apiCostcodes}/${data.id}`, data);
}

export function removeCostcode(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_COSTCODE', `${apiCostcodes}/${id}`);
}

export function uploadCostCodes(formData, onProgressChange) {
  return async (dispatch) =>
    upload(dispatch, 'UPLOAD_COSTCODE', `${apiCostcodes}/upload`, formData, onProgressChange);
}

export function fetchMethodologies() {
  return async (dispatch) => get(dispatch, 'FETCH_METHODOLOGIES', `${apiMethodologies}`);
}

export function fetchDataPreparations() {
  return async (dispatch) => get(dispatch, 'FETCH_DATA_PREPARATIONS', `${apiDataPreparations}`);
}

export function fetchSubsampleScripts() {
  return async (dispatch) =>
    get(dispatch, 'FETCH_SUBSAMPLE_SCRIPTS', `${apiScripts}?type=subsampling`);
}

export function fetchSocNirTypeScripts() {
  return async (dispatch) =>
    get(dispatch, 'FETCH_SOC_NIR_TYPE_SCRIPTS', `${apiScripts}?type=soc_nir_types`);
}
