import React from 'react';

import Listview from '../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../core/form/lib/fieldFormat';

const RoundsLsv = ({ rounds, areas }) => {
  let rows = rounds?.rounds;

  const renderRows = (headers, row) => {
    // Add constant values of CEA, EA, EEA
    row['cea'] = areas.find((area) => area?.area_type === 'CEA')?.area_ha || 0;
    row['ea'] = areas.find((area) => area?.area_type === 'EA')?.area_ha || 0;
    row['eea'] = areas.find((area) => area?.area_type === 'EAA')?.area_ha || 0;

    // Add sites types and values
    const siteTypes = [
      'target_sites',
      'reserve_sites',
      'active_sites',
      'abandon_sites',
      'actual_sites',
      'ignored_sites',
      'required_sites',
    ];

    for (let siteType of siteTypes) {
      const { value, isValid, message = null } = row.sites[0][siteType];
      row[siteType] = {
        value,
        isValid,
        message,
      };
    }

    const { strata_area_ha, strata_count } = row.stratas[0];

    const {
      isValid: strataIsValid,
      message: strataMessage = null,
      value: strataValue,
    } = strata_area_ha;

    const {
      isValid: strataCountIsValid,
      message: strataCountMessage = null,
      value: strataCountValue,
    } = strata_count;

    row.strata_area_ha = {
      value: strataValue,
      isValid: strataIsValid,
      message: strataMessage,
    };

    row.strata_count = {
      value: strataCountValue,
      isValid: strataCountIsValid,
      message: strataCountMessage,
    };

    const tableTd = headers.map((header, index) => {
      let { field, classes, formattingRules } = header;
      let caption = row[field]?.value ?? row[field];

      if (field !== 'name') {
        caption = parseFloat(caption).toFixed(2);
      }

      // Red background if value is invalid
      let tooltip;
      if (row[field]?.isValid === false) {
        classes += ' bg-danger text-light';
        tooltip = row[field]?.message;
      }
      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      return (
        <td key={index} className={classes} title={tooltip || null}>
          {caption}
        </td>
      );
    });

    return tableTd;
  };

  const upperHeaders = [
    { caption: null, cells: 1, classes: 'border-bottom-0' },
    { caption: 'Areas', cells: 3, classes: 'text-center bg-lightgray border border-lightgray' },
    { caption: 'Stratas', cells: 2, classes: 'text-center bg-lightgray border border-lightgray' },
    { caption: 'Sites', cells: 7, classes: 'text-center bg-lightgray border border-lightgray' },
  ];

  const headers = [
    {
      caption: 'Round',
      field: 'name',
      classes: 'text-left ',
    },
    {
      caption: 'CEA',
      field: 'cea',
      classes: 'text-right border-left',
      formattingRules: { includeCommas: true, includeDecimals: true },
    },
    {
      caption: 'EA',
      field: 'ea',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: true },
    },
    {
      caption: 'EAA',
      field: 'eea',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: true },
    },

    {
      caption: 'Total Area',
      field: 'strata_area_ha',
      classes: 'text-right border-left',
      formattingRules: { includeCommas: true, includeDecimals: true },
    },
    {
      caption: 'Count',
      field: 'strata_count',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: false },
    },
    {
      caption: 'Target',
      field: 'target_sites',
      classes: 'text-right border-left',
      formattingRules: { includeCommas: true, includeDecimals: false },
    },
    {
      caption: 'Required',
      field: 'required_sites',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: false },
    },
    {
      caption: 'Ignored',
      field: 'ignored_sites',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: false },
    },
    {
      caption: 'Reserves',
      field: 'reserve_sites',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: false },
    },
    {
      caption: 'Active',
      field: 'active_sites',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: false },
    },
    {
      caption: 'Abandoned',
      field: 'abandon_sites',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: false },
    },
    {
      caption: 'Actual',
      field: 'actual_sites',
      classes: 'text-right',
      formattingRules: { includeCommas: true, includeDecimals: false },
    },
  ];

  const upperHeadTh = upperHeaders.map(({ caption, cells, classes }) => (
    <th key={caption} colSpan={cells} className={classes}>
      {caption}
    </th>
  ));

  let totals = [];
  const tableHeadTh = headers.map(({ caption, classes }) => {
    return (
      <th key={caption} className={classes}>
        {caption}
      </th>
    );
  });

  let tableBodyTr = <tr></tr>;
  if (rows?.rows) rows = rows.rows;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  const iconName = 'farm';
  const emptyCaption = 'No rounds found.';

  return (
    <Listview
      rows={rows || []}
      tableHeadTh={tableHeadTh}
      upperHeadTh={upperHeadTh}
      tableBodyTr={tableBodyTr}
      totals={totals}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default RoundsLsv;
