import React from 'react';
import Listview from '../../../core/form/components/Listview';
import moment from 'moment';

const CoreArchiveSamplesLsv = (props) => {
  let { rows } = props;
  const { pagination, onLoadMore } = props;

  const iconName = 'vials';
  const emptyCaption = 'No Scan Samples found.';

  const renderRows = (headers, sample) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'core_key':
        case 'position':
          return (
            <td key={index} className={header.classes}>
              {sample[header.field]}
            </td>
          );
        case 'date_oven':
          return (
            <td key={index} className={header.classes}>
              {moment(sample[header.field]).format('Do MMM YY')}
            </td>
          );
        case 'tag':
          return (
            <td key={index} className={header.classes}>
              {sample[header.field] ? sample[header.field] : 'rescan'}
            </td>
          );
        case 'updated':
          return (
            <td key={index} className={header.classes}>
              {sample[header.field] ? moment(sample[header.field]).fromNow() : '-'}
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {sample[header.field] ? sample[header.field].toFixed(2) : '-'}
            </td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: 'Core Key', field: 'core_key', classes: 'text-left' },
    { caption: 'Tag', field: 'tag', classes: 'text-left' },
    { caption: 'Position (mm)', field: 'position', classes: 'text-left' },
    { caption: 'Volume Segement', field: 'volume_segment', classes: 'text-right' },
    { caption: 'Oven Date', field: 'date_oven', classes: 'text-left' },
    { caption: 'Tray (g)', field: 'weight_tray', classes: 'text-right' },
    { caption: 'Gross Wet (g)', field: 'weight_gross_wet_sample', classes: 'text-right' },
    { caption: 'Net Wet (g)', field: 'weight_net_wet_sample', classes: 'text-right' },
    { caption: 'Gross Dry (g)', field: 'weight_gross_dry_sample', classes: 'text-right' },
    { caption: 'Net Dry (g)', field: 'weight_net_dry_sample', classes: 'text-right' },
    { caption: 'Water in Sample (g)', field: 'weight_water_sample', classes: 'text-right' },
    { caption: 'VWC (g/cc)', field: 'weight_vwc', classes: 'text-right' },
    { caption: 'VWC (%)', field: 'pcnt_vwc', classes: 'text-right' },
    {
      caption: 'Gravimetric Water Content (g/g)',
      field: 'weight_gravimetric_water',
      classes: 'text-right',
    },
    { caption: 'BD (g/cc)', field: 'weight_bd', classes: 'text-right' },
    { caption: 'Updated', field: 'updated', classes: 'text-right' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  let rowsCount;

  if (rows?.rows && pagination) {
    rowsCount = rows.count;
    ({ rows } = rows);
  }
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  return (
    <React.Fragment>
      <Listview
        rows={rows}
        rowsCount={rowsCount}
        onLoadMore={onLoadMore}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default CoreArchiveSamplesLsv;
