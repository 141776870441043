import { Button } from 'reactstrap';
import { set } from 'lodash';
import { updateControls } from 'jsx/components/core/form/components/FormBuilder';
import { periodsForm as form } from 'jsx/components/modules/projects/forms/periods';
import GenericLsvAdapter from 'jsx/components/core/form/components/GenericLsvAdapter';
import { useCheckAccess } from 'jsx/lib/hooks';

import { addPropertyId } from '../../lib/util';

const PeriodsLsv = ({
  property: { id: propertyId, periods: rows },
  setModal,
  removeRecord,
  periodType,
}) => {
  const formWithPropertyId = addPropertyId(form, propertyId);
  const periodTypeOption = periodType.map(({ id: optionId, name }) => ({
    name,
    label: name,
    value: optionId,
  }));
  const formWithOptions = set(formWithPropertyId, 'period_type_id.options', periodTypeOption);

  const hasWriteAccess = useCheckAccess('projectRegisterUpdate');
  const actions = !hasWriteAccess
    ? []
    : [
        {
          func: async (id) => {
            const foundData = rows?.find(({ id: dataId }) => dataId === id);
            const updatedFormWithId = updateControls(formWithPropertyId, foundData);

            setModal(updatedFormWithId, 'period', 'edit');
          },
          iconName: 'pen-to-square',
          classes: 'text-primary ml-3',
        },
        {
          func: async (id) => {
            // eslint-disable-next-line no-alert
            const confirmed = window.confirm(
              'This will permanently delete the data. Are you sure?',
            );
            if (confirmed) {
              removeRecord('period', id);
            }
          },
          iconName: 'trash',
          classes: 'text-danger ml-3',
        },
      ];

  return (
    <div>
      <div className="d-flex justify-content-between border-bottom align-items-end pb-1">
        <h5 className="text-corporate mb-0">Periods</h5>
        {hasWriteAccess && (
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              setModal(formWithOptions, 'period', 'create');
            }}
          >
            Create New
          </Button>
        )}
      </div>
      {!rows?.length ? (
        <div>No periods</div>
      ) : (
        <GenericLsvAdapter actions={actions} rows={rows} controls={formWithOptions} />
      )}
    </div>
  );
};

export default PeriodsLsv;
