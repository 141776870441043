import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const SiteSampleChartGamma = (props) => {
  let {
    samples,
    height
  } = props;

  // Default chart height
  if (!height) {
    height = 300;
  }

  let chartData = [];
  if (samples && samples.length > 0) {
    const gamma_counts = samples.filter(sample => sample.reading_gamma > 0);
    chartData = gamma_counts.map(sample => {
      const gamma = parseInt(sample.reading_gamma);
      return {name: `${sample.position}`, gamma: gamma}
    })
  }

  return (
    <React.Fragment>
      <div>
        <div className="d-flex justify-content-between bg-light p-2">
          <small className="text-secondary">Top of Core</small>
          <div>Gamma Readings (Counts)</div>
          <small className="text-secondary">Bottom of Core</small>
        </div>
        <ResponsiveContainer height={height}>
          <LineChart title="asdfasdf" data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <Line type="monotone" dataKey="gamma" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="name" />
            <YAxis domain={['dataMin - 200', 'dataMax + 200']}/>
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </React.Fragment>
  );
}

export default SiteSampleChartGamma;
