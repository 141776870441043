import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  asset_id: {
    ...controlDefaults,
    name: 'asset_id',
    type: 'select',
    fieldName: 'name',
    caption: 'Asset #',
    validationRules: {
      isRequired: true,
    },
    options: [],
    includeEmptyOption: true,
    showInListview: false,
    showInEditor: true,
    col: 2,
  },
  reading_date: {
    ...controlDefaults,
    placeholder: 'Reading Date',
    name: 'reading_date',
    type: 'date',
    caption: 'Reading Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    col: 2,
  },

  value: {
    ...controlDefaults,
    name: 'value',
    type: 'number',
    caption: 'Reading',
    defaultValue: 0,
    showInListview: true,
    showInEditor: true,
    col: 1,
    validationRules: {
      greaterThanZero: true,
    },
  },
};
