import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const ActivityComment = (props) => {
  const { activity, className } = props;

  const { comment, detail } = activity;

  let [isOpen, setOpen] = useState(false);

  let rows = <div></div>;
  if (detail && detail.length > 0) {
    rows = detail.map((message, index) => (
      <div key={index} className={`${className} pt-2`}>
        {message}
      </div>
    ));
  }

  return (
    <React.Fragment>
      <div className={`d-flex justify-content-between align-items-center`}>
        {comment}
        {detail !== null && (
          <div>
            ({detail.length})
            <Icon
              name={`chevron-${isOpen ? 'up' : 'down'}`}
              className={'text-danger ml-2'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOpen(!isOpen);
              }}
            />
          </div>
        )}
      </div>
      <Collapse
        isOpen={isOpen}
        className={'mt-2 border border-bottom-0 border-left-0 border-right-0'}
      >
        {rows}
      </Collapse>
    </React.Fragment>
  );
};

export default ActivityComment;
