/**
 * Utilities/functions that are associated with actions
 */
import { isEmpty } from 'lodash';

/**
 * Takes an object of filters and adds `filter_` prefix to each key
 */
export const createParamsFromFilters = (filters: Record<string, string[]>) => {
  if (isEmpty(filters)) return filters;

  const paramsWithFilters: Record<string, string[]> = {};
  Object.keys(filters).forEach((key) => {
    if (!filters[key]) return; // skip empty filters
    paramsWithFilters[`filter_${key}`] = filters[key];
  });

  return paramsWithFilters;
};
