import React from 'react';
import { connect } from 'react-redux';
import FormBase from '../../../core/form/components/FormBase';

import CattleAnalyticsSales from '../components/CattleAnalyticsSales';
import CattleAnalyticsStockflow from '../components/CattleAnalyticsStockflow';
import SheepAnalyticsStockflow from '../components/SheepAnalyticsStockflow';
import LandcareAnalytics from '../components/LandcareAnalytics';
// import PropertyAnalytics from "../components/PropertyAnalytics";
// import LsuManagedAnalytics from "../components/LsuManagedAnalytics";
import { Row, Col } from 'reactstrap';

class DashboardProbes extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      activeTab: '1',
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  toggleTab(tab, tag) {
    this.setState({ activeTab: tab });

    switch (tag) {
      default:
        break;
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="p-0 h-100 w-100">
        {/* <PropertyAnalytics /> */}
        {/* <Row >
          <Col sm={6} className="p-0 m-0">
            <LsuManagedAnalytics />
          </Col>
          <Col sm={6} className="p-0 m-0">
            <LsuManagedAnalytics />
          </Col>
        </Row> */}
        <div className="ml-4 mr-4">
          <h5 className="text-left">Landcare Indicators</h5>
          <LandcareAnalytics />
        </div>
        <Row>
          <Col sm={6}>
            <CattleAnalyticsSales title="Cattle Sales/Purchases" />
          </Col>
          <Col sm={6}>
            <CattleAnalyticsSales title="Sheep Sales/Purchases" />
          </Col>
        </Row>
        <Row>
          <Col>
            <CattleAnalyticsStockflow title="Cattle Sales/Purchases" />
          </Col>
          <Col>
            <SheepAnalyticsStockflow title="Sheep Sales/Purchases" />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(DashboardProbes);
