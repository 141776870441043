import React from 'react';
import { connect } from 'react-redux';
import FormInput from 'jsx/components/core/form/components/FormInputSmall';
import FormBase from 'jsx/components/core/form/components/FormBase';
import Icon from 'jsx/components/core/icons/Icon';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { saveControls, updateControls } from '../../../core/form/lib/validateForm';
import { breakControls } from '../forms/daysheet_break';

import {
  deleteDaysheetBreak,
  fetchDaysheetBreaks,
  updateDaysheetBreak,
} from '../actions/daysheet_breaks';

const formatChars = {
  1: '[0-2]',
  2: '[0-9]',
  3: '[0-5]',
  4: '[0-9]',
};

class BreakPill extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(breakControls),
      isOpen: false,
    };

    this.onSave = this.onSave.bind(this);
    this.setModal = this.setModal.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async componentDidMount() {
    let { controls } = this.state;
    const { row } = this.props;

    if (row.from_datetime) controls.from_time.value = moment(row.from_datetime).format('HH:mm');
    controls = await updateControls(controls, row);

    this.setState({ controls });
  }

  setModal(isOpen) {
    this.setState({ isOpen });
  }

  async onRemove() {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm('Removing break permanently. Continue?');
    if (!confirmed) return;

    await this.props.dispatch(deleteDaysheetBreak(this.props.row.id));
    this.props.dispatch(fetchDaysheetBreaks(this.props.daysheet.id));
    this.props.loadActivities();
  }

  async onSave() {
    const { from_datetime, id } = this.props.daysheet;
    const { controls } = this.state;

    const data = saveControls(controls, this.props.row);
    if (from_datetime) {
      const from_date = moment(from_datetime).format('YYYY-MM-DD');
      data.from_datetime = `${from_date} ${controls.from_time.value}`;
    }

    delete data.name;
    delete data.id;

    const success = await this.props.dispatch(updateDaysheetBreak(data, this.props.row.id));
    if (success) {
      await this.props.dispatch(fetchDaysheetBreaks(id));
      this.props.loadActivities();
      this.setState({ isOpen: false });
    }
  }

  render() {
    const { row } = this.props;
    const { isOpen, controls } = this.state;

    const paid = row.is_paid ? 'Paid' : 'UnPaid';
    const stateClass = row.from_datetime ? 'bg-success' : 'bg-danger';
    const className = `d-flex p-1 ${stateClass} rounded-top text-white`;
    const description = `${row.name} (${paid} - ${row.hrs}hrs)`;

    const mask = '12:34';

    const breakTime = row.from_datetime ? moment(row.from_datetime).format('HH:mm') : '--:--';
    return (
      <div className="rounded-top m-1 p-0">
        <div className={className} onClick={() => this.setModal(!isOpen)}>
          <span className="ml-2">{description}</span>
          <span className="ml-2">Time: {breakTime}</span>

          <Icon
            className="ml-3 m-1"
            name="xmark"
            onClick={(event) => {
              this.onRemove(row.id);
              event.stopPropagation();
            }}
          />
        </div>

        {isOpen && (
          <div className="bg-light border border-danger rounded-bottom d-flex p-1">
            <div className="mt-1 ml-2">Time:</div>
            <InputMask
              mask={mask}
              onChange={this.handleChange}
              formatChars={formatChars}
              className="ml-2 p-0"
              name={controls.from_time.name}
              value={controls.from_time.value}
              style={{
                width: 60,
                border: '1px solid lightgray',
                borderRadius: 4,
                textAlign: 'center',
              }}
              alwaysShowMask
            />

            <span className="ml-2 mt-1">Hours: </span>
            <FormInput
              handleChange={this.handleChange}
              control={controls.hrs}
              className="ml-1"
              style={{ width: 50 }}
            />
            <Icon
              className="ml-3 m-2 text-success"
              name="check"
              onClick={this.onSave}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ wipstar }) => ({
  wipstar,
});

export default connect(mapStoreToProps)(BreakPill);
