import React from 'react';
import { connect } from 'react-redux';
import GenericLsv from '../../../core/form/components/GenericLsv';
import IndicatorTrends from '../components/IndicatorTrends.jsx';
import FormBase from '../../../core/form/components/FormBase';
import Mapster from '../../projects/containers/Mapster';
import { controls } from '../forms/indicators.js';
import { controls as filterControls } from '../forms/indicatorFilters.js';
import moment from 'moment';
import FormSlider from '../../../core/form/components/FormSlider';
import FormInput from '../../../core/form/components/FormInput';

import { Row, Col, Label } from 'reactstrap';
import { fakerIndicatorCharts, fakerIndicators } from '../lib/faker';

class Indicators extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      controls: controls,
      filterControls: filterControls,
      indicators: fakerIndicators(120),
      positiveCharts: fakerIndicatorCharts().slice(0, 6),
      negativeCharts: fakerIndicatorCharts().slice(7, 12),
      isModalOpen: false,
      isNew: false,
      data: null,
      mapSources: [],
      map: null,
      minDate: moment('2000-01-01'), // This should be set to date of oldest transaction in db for client
      maxDate: moment(), // This should be set to date current date
      fromDate: moment('2004-01-01'),
      toDate: moment('2019-01-01'),
    };

    this.onDateChange = this.onDateChange.bind(this);
  }

  componentDidUpdate() {
    let { map } = this.state;

    // Force a map redraw
    if (map) map.resize();
  }

  onDateChange(value) {
    let { fromDate, toDate, minDate } = this.state;

    const fromDiff = value[0] - moment(fromDate).diff(minDate, 'months');
    fromDate = moment(fromDate).add(fromDiff, 'months');

    const toDiff = value[1] - moment(toDate).diff(minDate, 'months');
    toDate = moment(toDate).add(toDiff, 'months');

    this.setState({ fromDate, toDate });
  }

  render() {
    const {
      controls,
      filterControls,
      indicators,
      positiveCharts,
      negativeCharts,
      mapSources,
      minDate,
      maxDate,
      fromDate,
      toDate,
    } = this.state;

    const iconName = 'tags';
    const emptyCaption = 'No Indicators/Metrics found for this date range';
    const lngLat = [150.7333, -23.1333];

    const maxValue = moment(maxDate).diff(minDate, 'months');
    const fromValue = moment(fromDate).diff(minDate, 'months');
    const toValue = moment(toDate).diff(minDate, 'months');
    const dateRange = `${fromDate.format('MMM-YYYY')} to ${toDate.format('MMM-YYYY')}`;

    return (
      <div className="p-0 h-100">
        <small>FILTERS</small>
        <Row className="bg-light border border-gray rounded p-2">
          <Col sm={2} className="d-flex justify-content-start">
            <Label className="mt-2 mr-2">Show Category</Label>
            <FormInput handleChange={this.handleChange} control={filterControls.category} />
          </Col>
          <Col sm={2} className="d-flex justify-content-start">
            <Label className="mt-2 mr-2">Show Sites</Label>
            <FormInput handleChange={this.handleChange} control={filterControls.location} />
          </Col>
          <Col sm={2} className="d-flex justify-content-start">
            <Label className="mt-2 mr-2">Show Metric</Label>
            <FormInput handleChange={this.handleChange} control={filterControls.metric} />
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="mt-2">{dateRange}</div>
            <FormSlider
              colour="#34a853"
              className="mt-3 w-25 mr-3 ml-3"
              max={maxValue}
              min={0}
              value={[fromValue, toValue]}
              onChange={(value) => this.onDateChange(value)}
              onAfterChange={() => {}}
              style={{ railbackground: '#cccccc' }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={9}>
            <IndicatorTrends positiveCharts={positiveCharts} negativeCharts={negativeCharts} />
          </Col>
          <Col sm={3}>
            <Mapster
              handleSourceVisibility={() => {}}
              expandMap={false}
              lngLatCenter={lngLat}
              toggleMap={() => {}}
              mapSources={mapSources}
              onMapLoad={this.onMapLoad}
            />
          </Col>
        </Row>

        <h3 className="bg-light border-bottom border-corporate mt-3">Data Attribute stream</h3>
        <GenericLsv
          controls={controls}
          iconName={iconName}
          emptyCaption={emptyCaption}
          onClick={() => {}}
          rows={indicators}
        />
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
    portal: store.farmportrait_portal,
  };
};

export default connect(mapStoreToProps)(Indicators);
