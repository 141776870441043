import React, { Fragment } from 'react';
import { Nav, TabContent, TabPane, Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import Icon from 'jsx/components/core/icons/Icon';

import About from '../components/About';
import EnterpriseDashboard from './EnterpriseDashboard';
import Reports from './Reports';
import Business from './Business';
import BusinessConfig from './BusinessConfig';
import Agistments from './Agistments';
import SheepEnterprises from './SheepEnterprises';
import CattleEnterprises from './CattleEnterprises';
import SugarEnterprises from './SugarEnterprises';
import CroppingEnterprises from './cropping/CroppingEnterprises';
import OtherLivestockEnterprises from './OtherLivestockEnterprises';
import OtherBusinessEnterprises from './OtherBusinessEnterprises';
import Properties from './Properties';
import Placeholder from '../components/Placeholder';

class Probe extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      authorised: true,
      authResponseMessage: null,
      enterpriseAllocations: [],
      errorMessage: null,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  toggleTab(tab, tag) {
    this.props.dispatch({ type: 'SET_PROBE_ACTIVETAB', payload: tab });

    switch (tag) {
      default:
        break;
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { activeTab, authorised, authResponseMessage, enterpriseAllocations } = this.state;
    const { transactional } = this.props.farmeye;

    const title = 'Profit Probe for Acacia Downs';

    const showCattle = enterpriseAllocations.find((allocation) => allocation.division === 'Cattle')
      ? true
      : false;
    const showSheep = enterpriseAllocations.find((allocation) => allocation.division === 'Sheep')
      ? true
      : false;
    const showOtherLivestock = enterpriseAllocations.find(
      (allocation) => allocation.division === 'Other Livestock'
    )
      ? true
      : false;
    const showSugar = enterpriseAllocations.find((allocation) => allocation.division === 'Sugar')
      ? true
      : false;
    const showCropping = enterpriseAllocations.find(
      (allocation) => allocation.division === 'Cropping'
    )
      ? true
      : false;
    const showOtherBusiness = enterpriseAllocations.find(
      (allocation) => allocation.division === 'Other Business'
    )
      ? true
      : false;

    const isLivestockEnterprise = showCattle || showSheep || showOtherLivestock;

    return (
      <div className="p-0 h-100">
        <ResponseMessage responseMessage={authResponseMessage} />

        {authorised && (
          <Fragment>
            <div className="bg-light p-2 border-bottom border-corporate d-flex justify-content-between">
              <div className="d-flex justify-content-start">
                <Button size="sm" color="success" onClick={this.goBack}>
                  <Icon size="1x" name="chevron-left" className="mr-2" />
                  Back
                </Button>
                <h5 className="m-1 ml-2">{title}</h5>
              </div>

              <div>
                <small className="text-danger">Draft Version: 3.0</small>
                <Button size="sm" color="success" className="ml-2">
                  Publish
                  <Icon size="1x" name="share" className="ml-2" />
                </Button>
              </div>
            </div>
            <Row className="p-0 m-0 h-100">
              <Col className="p-0 m-0 h-100 verticalnav">
                <Nav vertical className="mt-2">
                  <FormTab
                    caption="Probe Dashboard"
                    tabId="1"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    tabTag="dashboard"
                  />
                  <FormTab
                    caption="Assumptions"
                    tabId="2"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    tabTag="properties"
                  />
                  <FormTab
                    caption="Property Information"
                    tabId="3"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    tabTag="properties"
                  />
                  {!transactional && (
                    <FormTab
                      caption="Enterprise Allocation of land"
                      tabId="4"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      tabTag="documents"
                    />
                  )}
                  {isLivestockEnterprise && (
                    <FormTab
                      caption="Agistment"
                      tabId="5"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      tabTag="agistment"
                    />
                  )}
                  {showSheep && (
                    <FormTab
                      caption="Sheep Enterprises"
                      tabId="6"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      tabTag="users"
                    />
                  )}
                  {showCattle && (
                    <FormTab
                      caption="Cattle Enterprise"
                      tabId="7"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      tabTag="users"
                    />
                  )}
                  {showOtherLivestock && (
                    <FormTab
                      caption="Other Livestock Enterprise"
                      tabId="8"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      tabTag="otherlivestock"
                    />
                  )}
                  {showSugar && (
                    <FormTab
                      caption="Sugar Enterprise"
                      tabId="9"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      tabTag="otherlivestock"
                    />
                  )}
                  {showCropping && (
                    <FormTab
                      caption="Cropping Enterprise"
                      tabId="10"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      tabTag="cropping"
                    />
                  )}
                  {showOtherBusiness && (
                    <FormTab
                      caption="Other Business Enterprise"
                      tabId="11"
                      activeTab={activeTab}
                      toggle={this.toggleTab}
                      tabTag="otherbusiness"
                    />
                  )}
                  <FormTab
                    caption="Business Information"
                    tabId="12"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    tabTag="business"
                  />
                  <FormTab
                    caption="Landcare Indicators"
                    tabId="12"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    tabTag="business"
                  />
                  <FormTab
                    caption="Reports/Business Analysis"
                    tabId="13"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    tabTag="reports"
                  />
                  <FormTab
                    caption="About Profit Probe"
                    tabId="14"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    tabTag="clients"
                  />
                </Nav>
              </Col>
              <Col className="h-100">
                <TabContent activeTab={activeTab} className="h-100">
                  <TabPane tabId="1" className="p-1 h-100">
                    <EnterpriseDashboard />
                  </TabPane>
                  <TabPane tabId="2" className="mb-2 p-3">
                    <Placeholder rows={[]} title="Assumptions" />
                  </TabPane>
                  <TabPane tabId="3" className="mb-2 p-3">
                    <Properties />
                  </TabPane>
                  {}
                  <TabPane tabId="4" className="mb-2 p-2">
                    <BusinessConfig />
                  </TabPane>
                  <TabPane tabId="5" className="mb-2 p-2">
                    {isLivestockEnterprise && <Agistments />}
                  </TabPane>
                  <TabPane tabId="6" className="mb-2 p-2">
                    <SheepEnterprises />
                  </TabPane>
                  <TabPane tabId="7" className="mb-2 p-2">
                    <CattleEnterprises />
                  </TabPane>
                  <TabPane tabId="8" className="mb-2 p-2">
                    <OtherLivestockEnterprises />
                  </TabPane>
                  <TabPane tabId="9" className="mb-2 p-2">
                    <SugarEnterprises />
                  </TabPane>
                  <TabPane tabId="10" className="mb-2 p-2">
                    <CroppingEnterprises />
                  </TabPane>
                  <TabPane tabId="11" className="mb-2 p-2">
                    <OtherBusinessEnterprises />
                  </TabPane>
                  <TabPane tabId="12" className="mb-2 p-2">
                    <Business />
                  </TabPane>
                  <TabPane tabId="13" className="mb-2 p-2">
                    <Placeholder rows={[]} title="Landcare Indicators" />
                  </TabPane>
                  <TabPane tabId="14" className="mb-2 p-2">
                    <Reports />
                  </TabPane>
                  <TabPane tabId="15" className="mb-2 p-3">
                    <About />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    realm: store.realm,
    clients: store.clients,
    farmeye: store.farmeye_portal,
  };
};

export default connect(mapStoreToProps)(Probe);
