import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  property_round_name: {
    ...controlDefaults,
    fieldName: 'property_round.name',
    name: 'property_round_name',
    type: 'text',
    caption: 'Property Round',
    showInListview: true,
  },
  area_name: {
    ...controlDefaults,
    fieldName: 'area.name',
    name: 'area_name',
    type: 'text',
    caption: 'Area',
    showInListview: true,
  },
  layer_no: {
    ...controlDefaults,
    fieldName: 'layers.layer_no',
    name: 'area_name',
    type: 'text',
    caption: 'Layer',
    showInListview: true,
  },
  total_area: {
    ...controlDefaults,
    fieldName: 'total_area',
    name: 'total_area',
    type: 'number',
    caption: 'Area (ha)',
    showInListview: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  count_sites: {
    ...controlDefaults,
    fieldName: 'count_sites',
    name: 'count_sites',
    type: 'number',
    caption: 'Sites (#)',
    showInListview: true,
    formattingRules: {
      includeCommas: true,
    },
  },
  layer_upper_depth: {
    ...controlDefaults,
    fieldName: 'layers.upper_depth',
    name: 'layer_upper_depth',
    type: 'number',
    caption: 'Upper Depth',
    showInListview: true,
  },
  layer_lower_depth: {
    ...controlDefaults,
    fieldName: 'layers.lower_depth',
    name: 'layer_lower_depth',
    type: 'number',
    caption: 'Lower Depth',
    showInListview: true,
  },
  avg_soil_mass: {
    ...controlDefaults,
    fieldName: 'layers.results.avg_soil_mass',
    name: 'avg_soil_mass',
    type: 'number',
    caption: 'Avg Soil Mass',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  avg_whole_soc: {
    ...controlDefaults,
    fieldName: 'layers.results.avg_whole_soc',
    name: 'avg_whole_soc',
    type: 'number',
    caption: 'Avg Whole SOC',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  avg_whole_carbon_mass: {
    ...controlDefaults,
    fieldName: 'layers.results.avg_whole_carbon_mass',
    name: 'avg_whole_carbon_mass',
    type: 'number',
    caption: 'Avg Whole Carbon Mass',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  whole_carbon_mass: {
    ...controlDefaults,
    fieldName: 'layers.results.whole_carbon_mass',
    name: 'whole_carbon_mass',
    type: 'number',
    caption: 'Whole Carbon Mass',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  svar_whole_carbon_mass: {
    ...controlDefaults,
    fieldName: 'layers.results.svar_whole_carbon_mass',
    name: 'svar_whole_carbon_mass',
    type: 'number',
    caption: 'S.Var Whole Carbon Mass',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  svar_area_whole_carbon_mass: {
    ...controlDefaults,
    fieldName: 'layers.results.svar_area_whole_carbon_mass',
    name: 'svar_area_whole_carbon_mass',
    type: 'number',
    caption: 'S.Var Area Whole Carbon Mass',
    showInListview: true,
    totals: true,
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
  },
  spline_name: {
    ...controlDefaults,
    fieldName: 'layers.spline_name',
    name: 'spline_name',
    type: 'text',
    caption: 'Spline Type',
    showInListview: true,
  },
};
