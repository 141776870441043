import React, { Fragment } from 'react';
import Icon from 'jsx/components/core/icons/Icon';

import { Col, Row } from 'reactstrap';

const WidgetDivision = (props) => {
  const { row } = props;

  const renderChildren = (dataRows) => {
    return dataRows.map((dataRow, index) => {
      const valueClass = dataRow.value < 0 ? 'text-danger' : 'text-green';
      const nameClass = !dataRow.children ? 'text-primary' : 'text-bold text-black';
      const classNames = `${
        dataRow.children ? 'bg-lightgray' : ''
      } ml-3 mr-1 d-flex justify-content-between p-1 border-bottom`;

      let formatValue = 0;
      if (dataRow.value) {
        formatValue = `${dataRow.prefix || ''}${dataRow.value.toLocaleString(undefined, {
          minimumFractionDigits: 0,
        })}${dataRow.suffix || ''}`;
      }

      return (
        <Fragment key={index}>
          <div className={classNames}>
            <div className={nameClass}>{dataRow.name}</div>
            <div className={valueClass}>{formatValue}</div>
          </div>

          <div className="ml-2">{renderChildren(dataRow.children || [])}</div>
        </Fragment>
      );
    });
  };

  const renderTopLevel = (row) => {
    const dataRows = row?.data;

    return dataRows?.map(({ children, name, prefix, suffix, value }, index) => {
      const valueClass = value < 0 ? 'text-danger' : 'text-green';

      let formatValue = 0;
      if (value) {
        formatValue = `${prefix || ''}${value.toLocaleString(undefined, {
          minimumFractionDigits: 0,
        })}${suffix || ''}`;
      }

      return (
        <Fragment key={index}>
          <div className="text-black d-flex justify-content-between p-1 bg-light m-1">
            <div>
              <div>{name ?? ''}</div>
            </div>
            <div className={valueClass}>{formatValue}</div>
          </div>

          {renderChildren(children ?? [])}
        </Fragment>
      );
    });
  };

  const areaAllocated = `${
    row?.total_ha?.toLocaleString(undefined, {
      minimumFractionDigits: 0,
    }) || 0
  }ha`;
  const proportion = `${row?.proportion}%`;
  return (
    <div className="border border-primary rounded m-0">
      <h5 className="bg-primary text-white p-2 text-center">
        <Icon name={row?.icon_name} className="mr-2" />
        {row?.division_name} Division
      </h5>

      <div className="border-bottom border-primary mb-2">
        <Row className="m-1 border border-light-gray">
          <Col className="bg-lightgray text-right">Area Allocated</Col>
          <Col className="text-right">{areaAllocated}</Col>
          <Col className="bg-lightgray text-right">% of GP</Col>
          <Col className="text-right">{proportion}</Col>
        </Row>
      </div>

      {renderTopLevel(row)}
    </div>
  );
};

export default WidgetDivision;
