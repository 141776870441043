import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  property_id: {
    ...controlDefaults,
    name: 'property_id',
    type: 'hidden',
  },
  open_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'open_date',
    fieldName: 'open_date',
    type: 'date',
    caption: 'Open Date',
    validationRules: {
      isRequired: true,
    },
    showInListview: true,
    showInEditor: true,
  },
  close_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'close_date',
    fieldName: 'close_date',
    type: 'date',
    caption: 'Close Date',
    showInListview: true,
    showInEditor: true,
  },
  distributions: {
    ...controlDefaults,
    name: 'distributions',
    type: 'json',
    caption: 'Distributions',
    disabled: false,
    validationRules: {
      isArray: true,
    },
    showInListView: false,
    showInEditor: true,
  },
  distribution_default: {
    ...controlDefaults,
    placeholder: '',
    name: 'Distribution',
    type: 'number',
    caption: 'Distribution #',
    disabled: true,
    validationRules: {},
    formattingRules: {
      includeCommas: true,
    },
    showInListview: false,
    showInEditor: false,
    classes: 'text-center',
  },
};
