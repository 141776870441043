import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { fetchListviewConfiguration } from 'jsx/components/manage/actions/listview_configuration';
import { hectareNumberFormat } from 'jsx/lib/generic';

import { useDispatch } from 'react-redux';
import Listview from '../../../../core/form/components/Listview';
import PropertiesLsv from '../properties/PropertiesLsv';

const LSV_ID = 'projects-lsv';

const ProjectsLsv = ({
  projects,
  onClick,
  history,
  getProperties,
  checkAccess,
  onLoadMore,
  rowsCount,
}) => {
  const dispatch = useDispatch();
  const [openProperties, setOpenProperties] = useState([]);
  const [properties, setProperties] = useState({});

  useEffect(() => {
    async function fetchData() {
      dispatch(fetchListviewConfiguration(LSV_ID));
    }
    fetchData();
  }, [dispatch]);

  const isPropertyOpen = (id) => {
    const idx = openProperties.findIndex((projectId) => projectId === id);
    return idx > -1;
  };

  const handleOpenProperties = async (event, id) => {
    event.preventDefault();
    event.stopPropagation();

    if (isPropertyOpen(id)) {
      setOpenProperties(openProperties.filter((curr) => curr !== id));
    } else {
      setOpenProperties([...openProperties, id]);
    }
    const { rows } = await getProperties(id);
    const newProperties = { ...rows };
    newProperties[id] = rows;
    setProperties(newProperties);
  };

  const renderProperties = (project) => (
    <PropertiesLsv
      checkAccess={checkAccess}
      darkTable
      hideNew
      hideHeader={false}
      history={history}
      rows={properties[project.id]}
      filterColumns={['name', 'project', 'idx', 'area_ha', 'address', 'state']}
    />
  );

  const renderRows = (headers, project) => {
    // Set action toolbar
    const actions = (
      <div className="text-nowrap">
        <button
          onClick={(event) => handleOpenProperties(event, project.id)}
          title="Project Properties"
          className="text-primary mr-2 btn btn-sm btn-link"
        >
          Properties
        </button>
      </div>
    );

    let assignedTo = '-';
    if (project.assignedto) {
      assignedTo = project.assignedto.name;
    }

    let status = '-';
    if (project.lookup_status) {
      status = project.lookup_status.name;
    }

    const tableTd = headers.map(({ field, classes }, index) => {
      let extraClass = 'text-black';

      if (field === 'assigned_to' && assignedTo === '-') {
        extraClass = 'text-danger';
      }

      let declaration_date = '-';
      if (project?.declaration_date) {
        declaration_date = moment(project.declaration_date).format('YYYY-MM-DD');
      }

      let property_description = '';
      if (project?.stats?.project_property_ha) {
        property_description = `${project.stats.project_property_ha.toFixed(0)} Ha over ${
          project.stats.project_property_count
        } properties`;
      }

      let lotClass = 'text-success';
      if (parseInt(project?.stats?.project_property_lot_count, 10) === 0) {
        lotClass = 'text-danger';
      }

      let created = '-';
      if (project?.created) {
        created = moment(project.created).format('YYYY-MM-DD');
      }

      switch (field) {
        case 'declaration_date':
          return (
            <td key={field} className={`${classes}`}>
              {declaration_date}
            </td>
          );
        case 'assigned_to':
          return (
            <td key={field} className={`${classes} ${extraClass}`}>
              {assignedTo}
            </td>
          );
        case 'actions':
          return (
            <td key={field} className={`${classes}`}>
              {actions}
            </td>
          );
        case 'total_properties':
          return (
            <td key={field} className={`${classes}`}>
              {property_description}
            </td>
          );
        case 'lots':
          return (
            <td key={field} className={`${classes} ${lotClass}`}>
              {project?.stats?.project_property_lot_count}
            </td>
          );
        case 'status':
          return (
            <td key={field} className={`${classes}`}>
              {status}
            </td>
          );
        case 'created':
          return (
            <td key={field} className={`${classes}`}>
              {created}
            </td>
          );
        default:
          return (
            <td key={field} className={`${classes} ${extraClass}`}>
              {project[field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: 'Name', field: 'name', classes: 'p-1 pl-2 align-middle' },
    { caption: 'Status', field: 'status', classes: 'p-1 align-middle text-left' },
    {
      caption: 'Project Area (ha)',
      field: 'project_property_lot_area_ha',
      classes: 'p-1 align-middle text-right',
    },
    { caption: 'Total Lots', field: 'lots', classes: 'p-1 align-middle text-center' },
    { caption: 'Internal Job No', field: 'project_job_no', classes: 'p-1 align-middle ' },
    { caption: 'Project Officer', field: 'assigned_to', classes: 'p-1 align-middle ' },
    { caption: 'Declaration Created', field: 'declaration_date', classes: 'p-1 align-middle ' },
    { caption: 'ERF Project No', field: 'erf_project_no', classes: 'p-1 align-middle ' },
    { caption: 'Creation Date', field: 'created', classes: 'p-1 align-middle ' },
    { caption: '', field: 'actions', classes: 'p-1 align-middle text-right' },
  ];

  const formattedProjects = projects.map((project) => {
    const updatedProject = { ...project };
    if (updatedProject?.stats?.project_property_lot_area_ha) {
      updatedProject.project_property_lot_area_ha = hectareNumberFormat(
        project.stats.project_property_lot_area_ha,
      );
    }
    return updatedProject;
  });

  let tableBodyTr = <tr />;
  const haveRows = formattedProjects && formattedProjects.length > 0;

  if (haveRows) {
    tableBodyTr = formattedProjects.map((project, index) => (
      <React.Fragment key={index}>
        <tr onClick={() => onClick(project)} style={{ cursor: 'pointer' }}>
          {renderRows(headers, project)}
        </tr>
        {isPropertyOpen(project.id) && (
          <tr>
            <td className="bg-white" colSpan={headers.length}>
              {renderProperties(project)}
            </td>
          </tr>
        )}
      </React.Fragment>
    ));
  }

  const tableHeadTh = headers.map(({ classes, caption, field }, index) => (
    <th key={field || index} className={`${classes}`}>
      {caption}
    </th>
  ));

  const iconName = 'diagram-project';
  const emptyCaption = 'No Projects found';

  return (
    <Listview
      rows={formattedProjects}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
      onLoadMore={onLoadMore}
      rowsCount={rowsCount}
      isConfigurable
      rememberUserState
      instanceName="projects-lsv"
      headers={headers}
    />
  );
};

export default ProjectsLsv;
