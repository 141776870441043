import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  site_id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden'
  },
  position: {
    ...controlDefaults,
    placeholder: 'Sample Position',
    name: 'position',
    disabled: true,
    type: 'text',
    caption: 'Position',
    validationRules: {
      isRequired: true
    },
  },
  upper_depth_mm: {
    ...controlDefaults,
    name: 'upper_depth_mm',
    disabled: false,
    type: 'text',
    caption: 'Upper Depth (mm)',
    validationRules: {
      isRequired: true
    },
  },
  lower_depth_mm: {
    ...controlDefaults,
    name: 'lower_depth_mm',
    disabled: false,
    type: 'text',
    caption: 'Lower Depth (mm)',
    validationRules: {
      isRequired: true
    },
  },
  reading_red: {
    ...controlDefaults,
    name: 'reading_red',
    disabled: false,
    type: 'text',
    caption: 'Red Reading',
    validationRules: {
      isRequired: true
    },
  },
  reading_green: {
    ...controlDefaults,
    name: 'reading_green',
    disabled: false,
    type: 'text',
    caption: 'Green Reading',
    validationRules: {
      isRequired: true
    },
  },
  reading_blue: {
    ...controlDefaults,
    name: 'reading_blue',
    disabled: false,
    type: 'text',
    caption: 'Blue Reading',
    validationRules: {
      isRequired: true
    },
  },
  reading_gamma: {
    ...controlDefaults,
    name: 'reading_gamma',
    disabled: false,
    type: 'text',
    caption: 'Gamma Counts',
    validationRules: {
      isRequired: true
    },
  },
  scan_bulk_density: {
    ...controlDefaults,
    name: 'scan_bulk_density',
    disabled: true,
    type: 'text',
    caption: 'Bulk Density',
    validationRules: {
      isRequired: true
    },
  },
  scan_water_content: {
    ...controlDefaults,
    name: 'scan_water_content',
    disabled: true,
    type: 'text',
    caption: 'Water Content'
  },
  texture: {
    ...controlDefaults,
    name: 'texture',
    disabled: false,
    type: 'text',
    caption: 'Texture'
  },
  colour: {
    ...controlDefaults,
    name: 'colour',
    disabled: false,
    type: 'text',
    caption: 'Colour'
  },
  munsell_colour: {
    ...controlDefaults,
    name: 'colour',
    disabled: false,
    type: 'text',
    caption: 'Munsell Colour'
  },
  ph: {
    ...controlDefaults,
    name: 'ph',
    disabled: false,
    type: 'text',
    caption: 'pH'
  },
  water_content: {
    ...controlDefaults,
    name: 'water_content',
    disabled: false,
    type: 'text',
    caption: 'Water Content'
  },
  structure: {
    ...controlDefaults,
    name: 'structure',
    disabled: false,
    type: 'text',
    caption: 'Structure'
  },
  gravel_content: {
    ...controlDefaults,
    name: 'gravel_content',
    disabled: false,
    type: 'text',
    caption: 'Gravel Content'
  },
  lab_sample_code: {
    ...controlDefaults,
    name: 'lab_sample_code',
    disabled: false,
    type: 'text',
    caption: 'Lab Sample Code'
  },
  lab_soc_pcnt: {
    ...controlDefaults,
    name: 'lab_soc_pcnt',
    disabled: false,
    type: 'text',
    caption: 'Lab Carbon (%)'
  },
  lab_gravel_content: {
    ...controlDefaults,
    name: 'lab_gravel_content',
    disabled: false,
    type: 'text',
    caption: 'Lab Gravel Content'
  },
  lab_water_content: {
    ...controlDefaults,
    name: 'lab_water_content',
    disabled: false,
    type: 'text',
    caption: 'Lab Water Content'
  },
}
