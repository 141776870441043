import React from 'react';
import { connect } from 'react-redux';
import { Card, Nav, TabContent, TabPane } from 'reactstrap';
import { trim } from 'lodash';

import Icon from 'jsx/components/core/icons/Icon';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import Mapster from './Mapster';

import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import { updateControls } from '../../../core/form/lib/validateForm';
import { controls as formControls } from '../forms/project';

import ProjectPeriods from '../components/ProjectPeriodsLsv';
import ProjectOverview from '../components/projects/ProjectOverview';
import RegisterOverview from '../components/projects/RegisterOverview';
import PropertiesLsv from '../components/properties/PropertiesLsv';
import ProjectAdvanced from '../components/projects/ProjectAdvanced';
import ActivitiesLsv from '../components/ActivitiesLsv';
import GroupsLsv from '../components/groups/GroupsLsv';

import {
  fetchProject,
  updateProject,
  removeProject,
  fetchProjectOfficers,
  fetchProjectStatuses,
} from '../actions/projects';

import { fetchProperties } from '../actions/properties';
import { fetchPeriods } from '../actions/periods';
import { fetchCompany, fetchCompanies } from '../actions/crm';
import { buildFeatureCollection, buildFeature } from '../lib/mapster';
import { fetchDocumentGroups } from '../actions/document_groups';
import BreadcrumbsRoute from '../../../core/form/components/BreadcrumbsRoute';

class Project extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      project: {},
      activeTab: 'register',
      expandMap: false,
      showMiniMap: false,
      controls: formControls,
      backlink: '/home/projects',
      isValid: true,
      mapSources: [],
      sections: {
        contacts: { show: true },
        registration: { show: true },
        internal: { show: true },
      },
      data: {},
    };

    this.updateActiveTabFromURL = this.updateActiveTabFromURL.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.toggleMiniMap = this.toggleMiniMap.bind(this);
    this.handleSourceVisibility = this.handleSourceVisibility.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.unlockTemplate = this.unlockTemplate.bind(this);
  }

  updateActiveTabFromURL() {
    const {
      match: { path = '' },
      tabRoutes = [],
    } = this.props;

    const currentRoute = tabRoutes.find((route) => path.includes(route));
    const activeTab = currentRoute || 'register';

    if (activeTab !== this.state.activeTab) this.setState({ activeTab });
  }

  async componentDidMount() {
    let { data, controls } = this.state;

    const { id } = this.props.match.params;

    data = await this.props.dispatch(fetchProject(id));
    controls = updateControls(controls, data);

    this.props.dispatch(fetchCompanies());
    this.props.dispatch(fetchProperties({ project_id: id }));
    this.props.dispatch(fetchDocumentGroups({ project_id: id }));
    this.props.dispatch(fetchPeriods(id));
    this.props.dispatch(fetchProjectOfficers());
    this.props.dispatch(fetchProjectStatuses());

    this.resetGeometry();
    this.setPropertiesMap();

    this.setState({
      data,
      controls,
    });

    this.updateActiveTabFromURL();
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_PROJECT' });
  }

  toggleTab(tab) {
    if (tab !== this.state.activeTab) {
      // Update state
      this.setState({
        activeTab: tab,
      });

      // Update URL
      if (this.props.match.params.id)
        this.props.history.replace(`/home/projects/${this.props.match.params.id}/${tab}`);
    }
  }

  handleCompanyChange(event) {
    this.handleChange(event);

    // Get CRM company details
    const crm_id = event.target.value;
    if (crm_id) {
      this.props.dispatch(fetchCompany(crm_id));
    }
  }

  updateControls() {
    const { currentProject } = this.props.projects;
    const { controls } = this.state;

    if (currentProject && this.state.data.id !== currentProject.id) {
      const keys = Object.keys(controls);
      keys.forEach((key) => {
        const fieldName = controls[key].name;
        controls[key].value = currentProject[fieldName];
      });

      // Get CRM company
      if (currentProject.crm_id) {
        this.props.dispatch(fetchCompany(currentProject.crm_id));
      }

      this.setState({
        data: currentProject,
        controls,
      });
    }
  }

  async onSave() {
    const { data, controls, backlink } = this.state;

    const keys = Object.keys(controls);
    keys.forEach((key) => {
      data[key] = controls[key].value;
    });

    const success = await this.props.dispatch(updateProject(data));

    if (success) this.props.history.push(backlink);
  }

  async onRemove() {
    const {
      data: { name, id },
    } = this.state;

    const confirm_text = 'DELETE NOW';
    let confirm_display = `Removing the ${name.toUpperCase()} project permanently with all its associated data.`;
    confirm_display += `\n\nIf you are sure, please confirm this action by typing '${confirm_text}' in the input field below and press OK.`;

    // eslint-disable-next-line no-alert
    const confirmation = prompt(confirm_display);

    if (trim(confirmation) === confirm_text) {
      await this.props.dispatch(removeProject(id));
      this.props.history.push(this.state.backlink);
    } else if (confirmation !== null) {
      // When confirmation is null, it means the user clicked cancel.
      // eslint-disable-next-line no-alert
      alert('Confirmation does not match the text. Please try again.');
      this.onRemove();
    }
  }

  resetGeometry() {
    const mapSources = [
      {
        id: 'properties',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Property Boundaries',
        count: 0,
        style: 'Polygon',
      },
    ];
    this.setState({ mapSources });
  }

  setPropertiesMap() {
    const { rows: properties } = this.props.properties.properties;
    const featureColour = this.props.mapster.colours.property;
    const { mapSources } = this.state;

    if (properties?.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'properties');
      const featureCollection = buildFeatureCollection();

      properties.forEach((property) => {
        if (property.geom) {
          const feature = buildFeature(property.geom, {
            title: `Property ${property.lotplan}`,
            colour: featureColour.fill,
            outline: featureColour.outline,
          });
          featureCollection.features.push(feature);
        }
      });

      if (mapSources[idx].load && featureCollection.features.length > 0) {
        if (mapSources[idx].visible === true) {
          mapSources[idx].source = featureCollection;
        } else {
          mapSources[idx].source = buildFeature(null, {});
        }
        mapSources[idx].load = false;
        mapSources[idx].source = featureCollection;
        mapSources[idx].count = featureCollection.features.length;
        this.setState({ mapSources });
      }
    }
  }

  handleSourceVisibility(event) {
    const { mapSources } = this.state;
    const idx = event.target.value;

    mapSources[idx].visible = !mapSources[idx].visible;
    mapSources[idx].load = true;

    this.setState({
      mapSources,
    });
  }

  toggleSection(name) {
    const { sections } = this.state;
    const show = !sections[name].show;

    if (sections[name].show !== show) {
      sections[name].show = show;
      this.setState({
        sections,
      });
    }
  }

  toggleMap() {
    this.setState({
      expandMap: !this.state.expandMap,
    });
  }

  toggleMiniMap() {
    this.setState({
      showMiniMap: !this.state.showMiniMap,
    });
  }

  unlockTemplate(event) {
    this.props.dispatch({ type: 'SET_UNLOCK_TEMPLATE', payload: event.target.checked });
  }

  render() {
    const { projectStatuses, responseMessage, projectOfficers } = this.props.projects;
    const { rows: properties, count: propertyCount } = this.props.properties.properties;
    const { periods } = this.props.periods;
    const { groups } = this.props.document_groups;
    const { company, companies } = this.props.crm;
    const project_id = this.props.match.params.id;
    const { controls, sections, isValid, mapSources, expandMap, activeTab } = this.state;
    const lngLat = [150.7333, -23.1333];
    const iconName = 'diagram-project';

    return (
      <div>
        {!this.state.expandMap && (
          <div className="p-3">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-row">
                <Icon size="2x" name={iconName} className="appForeTint mr-2" />
                <h3>{controls.name.value}</h3>
              </div>
            </div>
            <div className="d-flex justify-content-between bg-light rounded p-2 border">
              <div>Manage projects, properties, areas and stratas</div>
              <div>
                <Icon name="list" style={{ color: '#bbbbbb' }} className="mr-3" />
                <Icon
                  name="location-pin"
                  className="text-primary mr-3"
                  style={{ cursor: 'pointer' }}
                  onClick={this.toggleMiniMap}
                />
              </div>
            </div>

            <BreadcrumbsRoute match={this.props.match} />

            {this.state.showMiniMap && (
              <Card className="border rounded mt-2" style={{ minHeight: '300px' }}>
                <Mapster
                  handleSourceVisibility={this.handleSourceVisibility}
                  expandMap={expandMap}
                  lngLatCenter={lngLat}
                  toggleMap={this.toggleMap}
                  mapSources={mapSources}
                />
              </Card>
            )}

            <Nav tabs className="mt-2">
              <FormTab
                caption="Register"
                tabId="register"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Details"
                tabId="details"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Properties"
                tabId="properties"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Document Requirements"
                tabId="document-requirements"
                activeTab={activeTab}
                toggle={this.toggleTab}
                disabled={false}
              />
              <FormTab
                caption="Timeline"
                tabId="timeline"
                activeTab={activeTab}
                toggle={this.toggleTab}
                disabled
              />
              <FormTab
                caption="Reporting Periods"
                tabId="reporting-periods"
                activeTab={activeTab}
                toggle={this.toggleTab}
                disabled={false}
              />
              <FormTab
                caption="Monitoring"
                tabId="monitoring"
                activeTab={activeTab}
                toggle={this.toggleTab}
                disabled
              />
              <FormTab
                caption="Jobs"
                tabId="jobs"
                activeTab={activeTab}
                toggle={this.toggleTab}
                disabled
              />
              <FormTab
                caption="Comment/Activity"
                tabId="comment"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Advanced"
                tabId="advanced"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
            </Nav>

            <TabContent activeTab={activeTab} className="border-bottom border-light">
              <TabPane tabId="register" className="mb-2 p-3">
                {activeTab === 'register' && (
                  <ProjectOverview
                    isValid={isValid && this.checkAccess('projectUpdate')}
                    responseMessage={responseMessage}
                    onCancel={this.onCancel}
                    onSave={this.onSave}
                    toggleSection={this.toggleSection}
                    sections={sections}
                    controls={controls}
                    handleChange={this.handleChange}
                    toggleMiniMap={this.toggleMiniMap}
                    company={company}
                    companies={companies}
                    handleCompanyChange={this.handleCompanyChange}
                    projectOfficers={projectOfficers}
                    projectStatuses={projectStatuses}
                  />
                )}
              </TabPane>

              <TabPane tabId="details" className="mb-2 p-3">
                {activeTab === 'details' && <RegisterOverview />}
              </TabPane>

              <TabPane tabId="properties" className="mb-2 p-3">
                {activeTab === 'properties' && (
                  <PropertiesLsv
                    tabId="properties"
                    rows={properties}
                    project_id={project_id}
                    history={this.props.history}
                    checkAccess={this.checkAccess}
                  />
                )}
              </TabPane>

              <TabPane tabId="document-requirements" className="mb-2 p-3">
                {activeTab === 'document-requirements' && (
                  <GroupsLsv
                    rows={groups}
                    history={this.props.history}
                    project_id={project_id}
                    checkAccess={this.checkAccess}
                    unlockTemplate={this.unlockTemplate}
                    unlock={this.props.stages.unlockTemplate}
                  />
                )}
              </TabPane>

              <TabPane tabId="timeline" className="mb-2 p-3">
                <div className="text-center p-3">Placeholder - TODO</div>
              </TabPane>

              <TabPane tabId="reporting-periods" className="mb-2 p-3">
                {activeTab === 'reporting-periods' && (
                  <ProjectPeriods project_id={project_id} rows={periods || []} />
                )}
              </TabPane>

              <TabPane tabId="monitoring" className="mb-2 p-3">
                <div className="text-center p-3">Placeholder - TODO</div>
              </TabPane>

              <TabPane tabId="jobs" className="mb-2 p-3">
                <div className="text-center p-3">Placeholder - TODO</div>
              </TabPane>

              <TabPane tabId="comment" className="mb-2 p-3">
                {activeTab === 'comment' && <ActivitiesLsv rows={[]} />}
              </TabPane>

              <TabPane tabId="advanced" className="mb-2 p-3">
                {activeTab === 'advanced' && (
                  <ProjectAdvanced hasProperties={propertyCount > 0} onRemove={this.onRemove} />
                )}
              </TabPane>
            </TabContent>
          </div>
        )}
        {this.state.expandMap && (
          <Mapster
            handleSourceVisibility={this.handleSourceVisibility}
            expandMap={expandMap}
            lngLatCenter={lngLat}
            toggleMap={this.toggleMap}
            mapSources={mapSources}
          />
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({
  crm,
  document_groups,
  mapster,
  periods,
  projects,
  properties,
  realm,
  stages,
}) => ({
  crm,
  document_groups,
  mapster,
  periods,
  projects,
  properties,
  realm,
  stages,
});

export default connect(mapStoreToProps)(withContainerError(Project));
