import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { updateControls, initControls, saveControls } from '../../../core/form/lib/validateForm';
import { controls } from '../forms/fieldtrip.js';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import Icon from 'jsx/components/core/icons/Icon';

import { fetchFieldTrips, createFieldTrip, fetchFieldTrip, updateFieldTrip } from '../actions';

class FieldTripModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Field Trip',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls,
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  async componentDidUpdate() {
    let { isOpen, id, isNew, title, controls } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = `New Field Trip`;
      this.setState({ controls: initControls(this.state.controls) });

      if (this.props.id) {
        isNew = false;
        title = `Field Trip`;
        id = this.props.id;
        const result = this.props.dispatch(fetchFieldTrip(id));
        result.then((data) => {
          controls = updateControls(controls, data);
          this.setState({ controls });
        });
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        setModal: this.props.setModal,
      });
    }
  }

  async onSave() {
    let { data, controls, isNew } = this.state;

    data = saveControls(controls, data);

    let success;
    if (isNew) {
      delete data.id;
      success = await this.props.dispatch(createFieldTrip(data));
    } else {
      success = await this.props.dispatch(updateFieldTrip(data));
    }

    if (success) {
      this.props.dispatch(fetchFieldTrips());
      this.state.setModal(false);
      this.setState({ isOpen: false });
      if (this.props.onSave) {
        this.props.onSave(success.id);
      }
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      `Removing ${data.name.toUpperCase()} period permanently. Continue?`
    );
    if (confirmed) {
      // const success = await this.props.dispatch(removePeriod(data.id));
      // if (success) {
      //   // this.props.dispatch(fetchPeriods(data.project_id));
      //   this.state.setModal(false);
      //   this.setState({isOpen: false});
      // };
    }
  }

  render() {
    const { controls, isOpen, title } = this.state;

    const responseMessage = null;

    const iconName = 'truck-pickup';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput handleChange={this.handleChange} control={controls.drillrig} />
            <FormInput handleChange={this.handleChange} control={controls.subcontractor} />
            <FormInput handleChange={this.handleChange} control={controls.crew} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
            <Button
              disabled={!this.checkAccess('fieldTripUpdate')}
              size="sm"
              color="success"
              onClick={this.onSave}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    sites: store.sites,
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(FieldTripModal);
