import { noop } from 'lodash';
import React, { useState } from 'react';
import { Label } from 'reactstrap';
import SingleSearch from '../../../core/form/components/SingleSearch';
import PropertiesLsv from '../components/properties/PropertiesLsv';
import { DEFAULT_PAGINATION } from '../constants';

const ProjectProperties = ({ getProperties = noop, properties = [], rowsCount = 0, history }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(DEFAULT_PAGINATION);
  const displayCount = limit > properties.length ? properties.length : limit;

  const onLoadMore = () => {
    setLimit(limit + DEFAULT_PAGINATION);
    getProperties(null, { limit: limit + DEFAULT_PAGINATION, search_value: searchTerm });
  };

  return (
    <>
      <div className="bg-light p-1">
        <div className="m-1 mr-0 d-flex justify-content-end">
          <Label className="m-1 mr-2 text-secondary">
            Showing {displayCount} of {rowsCount} results
          </Label>
          <SingleSearch
            handleSearchChange={({ target, code }) => {
              const searchValue = target.value;
              setSearchTerm(searchValue);
              if (code === 'Enter' || code === 'NumpadEnter') {
                getProperties(null, { limit, search_value: searchValue });
              }
            }}
            onSearch={() => {
              getProperties(null, { limit, search_value: searchTerm });
            }}
            placeholder="Filter by keyword"
            value={searchTerm}
          />
        </div>
      </div>
      <div className="mt-2">
        <PropertiesLsv
          hideNew={true}
          rows={properties}
          rowsCount={rowsCount}
          onLoadMore={onLoadMore}
          history={history}
        />
      </div>
    </>
  );
};

export default ProjectProperties;
