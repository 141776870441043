import React from 'react';
import { connect } from 'react-redux';
import { Card, Form, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { controls } from '../forms/reset_password';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import { updateControls, saveControls } from '../../../core/form/lib/validateForm';

import { fetchHashUser, updatePassword } from '../actions/';

class ResetPassword extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      pwdMsg: '',
      validationMessage: '',
      controls: controls,
      reset_hash: null,
    };

    this.onSave = this.onSave.bind(this);
  }

  async componentDidMount() {
    let { controls, data, validationMessage } = this.state;

    const reset_hash = this.props.match.params.hash;
    data = await this.props.dispatch(fetchHashUser(reset_hash));

    if (!data || !reset_hash) {
      validationMessage = 'Invalid Reset Hash. Ask your administrator to send password reset!';
      this.setState({
        validationMessage,
      });
    } else {
      controls = updateControls(controls, data);
      this.setState({
        controls,
        data,
        reset_hash,
      });
    }
  }

  async onSave() {
    const { controls, data, reset_hash } = this.state;

    const user = saveControls(controls, data);
    user.reset_hash = reset_hash;
    delete user.id;

    let success = await this.props.dispatch(updatePassword(user));
    if (success) {
      this.props.history.push('/login');
    }
  }

  render() {
    const { controls, validationMessage } = this.state;

    const { resetMessage, responseMessage } = this.props.auth;

    const { currentApp } = this.props.realm;

    return (
      <div className="container-fluid p-0 h-100 bg-light row align-items-center d-flex justify-content-center">
        <Card className="p-2 card-border-color border-corporate login text-center">
          <Icon size="4x" name={currentApp.icon} className="text-corporate m-3" />
          <h3 className="text-secondary mt-2">{currentApp.title}</h3>
          {resetMessage && <div className="text-danger text-center">{resetMessage}</div>}
          {validationMessage && <div className="text-danger text-center">{validationMessage}</div>}
          {responseMessage && <div className="text-danger text-center">{responseMessage}</div>}
          {!resetMessage && (
            <div className="m-4 text-left">
              <Form>
                <FormInput handleChange={this.handleChange} control={controls.password} />
                <FormInput handleChange={this.handleChange} control={controls.password2} />
              </Form>
              <Button onClick={this.onSave} className="bg-corporate mt-3 w-100">
                Set Password
              </Button>
              <div className="m-2 d-flex justify-content-end">
                <small>
                  <a href="/">Login</a>
                </small>
              </div>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    auth: store.auth,
    realm: store.realm,
  };
};

export default connect(mapStoreToProps)(ResetPassword);
