import React from 'react';
import { connect } from 'react-redux';
import {
  Nav,
  TabContent,
  TabPane,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from 'reactstrap';
import { trim } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import Mapster from './Mapster';
import { buildFeatureCollection, buildFeature } from '../lib/mapster';

import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';

import Downloads from '../lib/downloads';
import Uploads from '../lib/uploads';

import AreaUploadModal from '../../areas/containers/AreaUploadModal';
import SiteUploadModal from '../../sites/containers/SiteUploadModal';
import PropertyProfile from '../components/properties/PropertyProfile';
import PropertyLotsLsv from '../components/properties/PropertyLotsLsv';
import PropertyRoundsLsv from '../components/property_rounds/PropertyRoundsLsv';
import AreasLsv from '../../areas/components/AreasLsv';
import PropertyMenu from '../components/properties/PropertyMenu';
import DownloadModal from '../../../core/form/components/DownloadModal';
import ProjectPropertyResults from './ProjectPropertyResults';
import ProjectPropertyComparisons from './ProjectPropertyComparisons';

import {
  validateChange,
  initControls,
  updateControls,
  saveFormControls,
  saveControls,
  validateFormFieldControls,
  updateControlOptions,
} from '../../../core/form/lib/validateForm';
import { controls as projectPropertiesControls } from '../forms/projectProperties';
import { controls as downloadPropertyReportingControls } from '../forms/downloadPropertyReporting';
import { controls as downloadPropertyMapsControls } from '../forms/downloadPropertyMaps';
import { controls as downloadRoundComparisonControls } from '../forms/downloadRoundComparison';

import {
  createProperty,
  fetchProperty,
  updateProperty,
  removeProperty,
  uploadSites,
  exportGeoJson,
  fetchPropertyInsights,
  generatePropertyInsights,
} from '../actions/properties';

import { fetchPropertyLots, uploadLots } from '../actions/property_lots';
import { fetchAreas, uploadAreas, fetchAreaUploadStatus } from '../../areas/actions';
import { generateSites, fetchPropertyRounds } from '../actions/property_rounds';
import { sendHashLinkEmail } from '../../portal/actions';
import { fetchPeriods } from '../actions/periods';
import { addSelectedLot, setSelectedLots } from '../lib/propertySearchUtils';
import { fetchMethodologies, fetchSoilTemplates } from '../../../manage/actions/index';
import BreadcrumbsRoute from '../../../core/form/components/BreadcrumbsRoute';

class ProjectProperty extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.getActiveTabFromURL(),
      errorMessage: null,
      expandMap: false,
      showMiniMap: false,
      showGraph: false,
      showDownloadModal: false,
      controls: projectPropertiesControls,
      showAreaChart: false,
      backlink: '/home/projects',
      isValid: true,
      mapSources: [],
      currentInsightsLot: null,
      data: {},
      project_id: null,
      id: null,
      areaUploadIsOpen: false,
      areaUploadCurrentRows: [],
      sitesUploadIsOpen: false,
      isEmailSearchModalOpen: false,
      geocoderAddress: '',
    };

    this.downloads = new Downloads();
    this.uploads = new Uploads();

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleAreaChart = this.toggleAreaChart.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.toggleGraph = this.toggleGraph.bind(this);
    this.toggleMiniMap = this.toggleMiniMap.bind(this);
    this.toggleDownloadModal = this.toggleDownloadModal.bind(this);
    this.getDownloadControlConfig = this.getDownloadControlConfig.bind(this);
    this.handleLotUpload = this.handleLotUpload.bind(this);
    this.handleAreaUpload = this.handleAreaUpload.bind(this);
    this.handleSiteUpload = this.handleSiteUpload.bind(this);
    this.handleSiteGenerate = this.handleSiteGenerate.bind(this);
    this.handleInsightsGenerate = this.handleInsightsGenerate.bind(this);
    this.openAreaUpload = this.openAreaUpload.bind(this);
    this.openSiteUpload = this.openSiteUpload.bind(this);
    this.handleSourceVisibility = this.handleSourceVisibility.bind(this);
    this.openGeometry = this.openGeometry.bind(this);
    this.exportGeoJson = this.exportGeoJson.bind(this);
    this.openEmailPropertySearch = this.openEmailPropertySearch.bind(this);
    this.handleEmailSearchChange = this.handleEmailSearchChange.bind(this);
    this.placeMarker = this.placeMarker.bind(this);
    this.onCancelEmailSearch = this.onCancelEmailSearch.bind(this);
    this.onSendEmailSearch = this.onSendEmailSearch.bind(this);
  }

  async componentDidMount() {
    this.props.dispatch({ type: 'UNSET_PROPERTY_LOTS' });
    const { id, project_id } = this.props.match.params;
    const backlink = `/home/projects/${project_id}`;

    let { controls, data } = this.state;
    controls = initControls(controls);

    this.resetGeometry();

    this.setState({
      id,
      project_id,
      backlink,
      controls,
    });

    // Set defaults for upload/download functions.
    this.uploads.set(id, this.props.dispatch);
    this.downloads.set(this.props.dispatch);

    if (id !== 'new') {
      data = await this.props.dispatch(fetchProperty(id));
      this.props.dispatch(fetchPropertyLots(id, true));
      controls = updateControls(controls, data);
      this.setMaps();
      this.setState({ data, controls });
    }
    this.toggleTab(this.state.activeTab);
  }

  componentDidUpdate(_, prevState) {
    const { id, showMiniMap, expandMap, currentInsightsLot } = this.state;

    if (showMiniMap) {
      if (this.props.properties.currentInsightsLot !== currentInsightsLot) {
        this.setState({
          currentInsightsLot: this.props.properties.currentInsightsLot,
        });
        this.props.dispatch(fetchPropertyInsights(id, true));
      } else {
        this.setMaps();
      }
    }

    if (prevState.showMiniMap !== showMiniMap && showMiniMap) {
      this.props.dispatch(fetchAreas(id, showMiniMap || expandMap));
      this.props.dispatch(fetchPropertyInsights(id, true));
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_PROPERTY' });
    this.props.dispatch({ type: 'UNSET_AREAS' });
    this.props.dispatch({ type: 'UNSET_PROPERTY_INSIGHTS' });
    this.props.dispatch({ type: 'UNSET_PROPERTY_MASS_ROUND' });
    this.props.dispatch({ type: 'UNSET_PROPERTY_COMPARISON' });
  }

  getActiveTabFromURL() {
    const {
      match: { path = '' },
      tabRoutes = [],
    } = this.props;
    const isActiveTab = tabRoutes.find((curr) => path.includes(curr));
    return isActiveTab || 'profile';
  }

  setMaps() {
    this.setLotMaps();
    this.setAreaMaps();
    this.setInsightMaps();
  }

  openGeometry() {
    const { currentPropertyLots } = this.props.properties;

    setSelectedLots(null);
    currentPropertyLots.map((property) => {
      addSelectedLot(property);
      return false;
    });

    const lnk = '/home/propertysearch';
    this.props.history.push(lnk);
  }

  resetGeometry() {
    const mapSources = [
      {
        id: 'insights',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        keys: ['inclusions', 'exclusions', 'inclusion', 'exclusion'],
        load: true,
        title: 'Insights',
        count: 0,
        style: 'Polygon',
      },
      {
        id: 'insights_detailed',
        visible: false,
        source: { type: 'FeatureCollection', features: [] },
        keys: ['inclusions', 'exclusions-slope', 'exclusions-forest', 'exclusions-forest-slope'],
        load: true,
        title: 'Insights - Detail',
        count: 0,
        style: 'Polygon',
      },
      {
        id: 'lots',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Lots',
        count: 0,
        style: 'Line',
        showLabels: true,
      },
      {
        id: 'areas',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Areas',
        count: 0,
        style: 'Polygon',
        showLabels: true,
      },
    ];
    this.setState({ mapSources });
  }

  async onSave() {
    const { data, controls, backlink, id, project_id } = this.state;

    const formData = saveFormControls(controls, data);

    let success;
    if (id === 'new') {
      formData.project_id = project_id;
      success = await this.props.dispatch(createProperty(formData));
    } else {
      success = await this.props.dispatch(updateProperty(formData));
    }

    if (success) this.props.history.push(backlink);
  }

  onCancel() {
    this.props.history.push(this.state.backlink);
  }

  async onRemove() {
    const {
      data: { name, id },
    } = this.state;

    const confirm_text = 'DELETE NOW';
    let confirm_display = `Removing the ${name.toUpperCase()} property permanently with all its associated data. This action can not be reversed.`;
    confirm_display += `\n\nIf you are sure, please confirm this action by typing '${confirm_text}' in the input field below and press OK.`;

    // eslint-disable-next-line no-alert
    const confirmation = prompt(confirm_display);

    if (trim(confirmation) === confirm_text) {
      await this.props.dispatch(removeProperty(id));
      this.props.history.push(this.state.backlink);
    } else if (confirmation !== null) {
      // When confirmation is null, it means the user clicked cancel.
      // eslint-disable-next-line no-alert
      alert('Confirmation does not match the text. Please try again.');
      this.onRemove();
    }
  }

  async handleLotUpload(event) {
    if (event.target.files.length === 0) return;

    const [file] = event.target.files;

    const formData = new FormData();
    formData.append('geom', file);
    formData.append('property_id', this.state.data.id);

    await this.props.dispatch(uploadLots(formData));
  }

  async openAreaUpload() {
    // Check existing property areas
    const property_id = this.state.data.id;

    const exists = await this.props.dispatch(fetchAreaUploadStatus(property_id));

    // Open AreaUploadModal with response.
    this.setState({
      areaUploadIsOpen: true,
      areaUploadCurrentRows: exists,
    });
  }

  async openSiteUpload() {
    // Open AreaUploadModal with response.
    this.setState({
      sitesUploadIsOpen: true,
    });
  }

  async handleAreaUpload(files, onProgressChange, reason) {
    const property_id = this.state.data.id;

    if (files.length === 0) return false;

    const [file] = files;

    const formData = new FormData();
    formData.append('geom', file);
    formData.append('property_id', property_id);
    if (reason != null) {
      formData.append('reason', reason);
    }

    const success = await this.props.dispatch(uploadAreas(formData, onProgressChange));
    if (success) {
      this.setAreaMaps();
      this.setState({
        areaUploadIsOpen: false,
      });
    }

    return success;
  }

  async handleSiteUpload(files, onProgressChange, site_script_id) {
    if (files.length === 0) return false;

    const file = files[0];

    const formData = new FormData();
    formData.append('geom', file);
    formData.append('property_id', this.state.data.id);
    formData.append('site_script_id', site_script_id);

    const success = await this.props.dispatch(uploadSites(formData, onProgressChange));
    if (success) {
      this.setState({
        sitesUploadIsOpen: false,
      });
    }

    return success;
  }

  async handleSiteGenerate(property_round_id) {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(
      'WARNING: This will overwrite all previous site information and re-generate using Carbonizer. Continue?',
    );
    if (confirmed) {
      await this.props.dispatch(generateSites(property_round_id));
    }
  }

  async handleInsightsGenerate(model_type) {
    if (this.props.properties.currentPropertyLots.length === 0) {
      this.props.dispatch({
        type: 'GENERATE_PROPERTY_INSIGHTS_REJECTED',
        payload: 'No Property Lots found. Aborting Insights Generation',
      });
      return false;
    }
    const { id } = this.state.data;
    await this.props.dispatch(generatePropertyInsights(id, { model_type }));
  }

  setAreaMaps() {
    const areas = this.props.areas.currentAreas;
    const { mapSources } = this.state;
    const areaColours = this.props.mapster.colours.areas;

    if (areas.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'areas');
      const featureCollection = buildFeatureCollection();
      const legends = [];

      areas.forEach((area, index) => {
        const areaColour = areaColours[parseInt(area.name, 10)];
        if (area.geom) {
          let title = null;
          if (area.area_type.name === 'CEA') title = `CEA${area.name}`;
          const feature = buildFeature(area.geom, {
            title,
            colour: areaColour,
            outline: '#000000',
          });
          featureCollection.features.push(feature);
          if (title)
            legends.push(
              <div key={index} className="d-flex">
                <div className="m-1 p-2" style={{ backgroundColor: areaColour }} />
                <div>{`${title}: ${area.area_ha}ha`}</div>
              </div>,
            );
        }
      });

      if (mapSources[idx].load && featureCollection.features.length > 0) {
        mapSources[idx].load = false;
        if (mapSources[idx].visible === true) {
          mapSources[idx].source = featureCollection;
        } else {
          mapSources[idx].source = buildFeatureCollection();
        }
        mapSources[idx].legends = legends;
        mapSources[idx].count = featureCollection.features.length;
        this.setState({ mapSources });
      }
    }
  }

  setLotMaps() {
    const { currentPropertyLots } = this.props.properties;
    const { mapSources } = this.state;
    const featureColour = this.props.mapster.colours.lot;

    if (currentPropertyLots.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'lots');
      const featureCollection = buildFeatureCollection();
      let total_ha = 0.0;

      currentPropertyLots.forEach((propertyLot) => {
        if (propertyLot.geom) {
          const feature = buildFeature(propertyLot.geom, {
            title: `${propertyLot.lotplan}`,
            colour: featureColour.fill,
            outline: featureColour.outline,
          });
          featureCollection.features.push(feature);
          total_ha += propertyLot.ha;
        }
      });

      if (mapSources[idx].load && featureCollection.features.length > 0) {
        if (mapSources[idx].visible === true) {
          mapSources[idx].source = featureCollection;
        } else {
          mapSources[idx].source = buildFeatureCollection();
        }

        // Had to add a key attribute to the div cause its in an array even though it only has one element
        mapSources[idx].legends = [
          <div key={1}>{`Total area: ${Math.round(total_ha * 100) / 100}ha`}</div>,
        ];
        mapSources[idx].load = false;
        mapSources[idx].count = featureCollection.features.length;
        this.setState({ mapSources });
      }
    }
  }

  setInsightMaps() {
    const { currentPropertyInsights } = this.props.properties;
    const { mapSources } = this.state;
    const insightColours = this.props.mapster.colours.insights;

    let changed = false;
    const sources = ['insights', 'insights_detailed'];
    for (const i in sources) {
      const idx = mapSources.findIndex((source) => source.id === sources[i]);
      if (currentPropertyInsights.length > 0) {
        const featureCollection = buildFeatureCollection();
        const legends = [];
        let checksum = 0;

        currentPropertyInsights.forEach((insight, index) => {
          const { key } = insight;
          if (mapSources[idx].keys && !mapSources[idx].keys?.includes(key)) return false;

          const colour = insightColours[key];
          checksum += Math.floor(insight.area_ha);
          const title = key
            .split('-')
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join(' ');

          if (insight.geom) {
            const feature = buildFeature(insight.geom, {
              title,
              colour,
              outline: colour,
              fillOpacity: insightColours.opacity,
            });
            featureCollection.features.push(feature);

            legends.push(
              <div key={index} className="d-flex">
                <div className="m-1 p-2" style={{ backgroundColor: colour }} />
                <div>{`${title} area: ${insight.area_ha}ha`}</div>
              </div>,
            );
          }
        });

        if (
          (mapSources[idx].load || mapSources[idx].checksum !== checksum) &&
          featureCollection.features.length > 0
        ) {
          if (mapSources[idx].visible === true) {
            mapSources[idx].source = featureCollection;
          } else {
            mapSources[idx].source = buildFeatureCollection();
          }
          mapSources[idx].legends = legends;
          mapSources[idx].load = false;
          mapSources[idx].checksum = checksum;
          mapSources[idx].count = featureCollection.features.length;
          changed = true;
        }
      } else if (mapSources[idx].count > 0) {
        mapSources[idx] = {
          ...mapSources[idx],
          source: buildFeatureCollection(),
          count: 0,
          legends: [],
        };
        changed = true;
      }
      if (changed) {
        this.setState({ mapSources });
      }
    }
  }

  async exportGeoJson() {
    const { currentProperty, currentPropertyLots } = this.props.properties;

    const featureCollection = buildFeatureCollection();
    currentPropertyLots.forEach((property) => {
      let { geom } = property;
      if (typeof geom === 'string') {
        geom = JSON.parse(property.geom);
      }

      const hectare = parseFloat(property.ha);
      const feature = buildFeature(geom, {
        id: property.id,
        lotplan: property.lotplan,
        ha: hectare,
      });
      featureCollection.features.push(feature);
    });

    return this.props.dispatch(
      exportGeoJson(currentProperty.name.replace(' ', '_'), featureCollection),
    );
  }

  openEmailPropertySearch() {
    this.setState({ isEmailSearchModalOpen: true });
  }

  async onSendEmailSearch() {
    const { controls, proponentEmailAddress, id } = this.state;

    if (!controls.address) {
      // eslint-disable-next-line no-alert
      window.alert('Missing property address. Email aborted');
      return false;
    }

    if (!proponentEmailAddress) {
      // eslint-disable-next-line no-alert
      window.alert('Missing or invalid proponent email address. Email aborted!');
      return false;
    }

    // Build Hash package
    const data = {
      proponent_email: proponentEmailAddress,
      property_address: controls.address.value,
      property_id: id,
      property: {
        name: controls.name.value,
        id,
      },
    };

    let message = `This will send an email directly to ${proponentEmailAddress} with a link to Property Search.\n\n`;
    message +=
      'It will also remove all existing lots for this property! Please type DELETE LOTS to continue.';

    // eslint-disable-next-line no-alert
    const prompt = window.prompt(message);
    if (prompt && prompt.toLowerCase() === 'delete lots') {
      const response = await this.props.dispatch(sendHashLinkEmail('propertysearch', data));
      if (response) {
        this.setState({ isEmailSearchModalOpen: false });

        // eslint-disable-next-line no-alert
        window.alert(`Email sent to ${proponentEmailAddress} and existing lots cleared`);
      }
    } else if (prompt !== null) {
      // eslint-disable-next-line no-alert
      window.alert('Invalid confirmation');
      return false;
    }
  }

  onCancelEmailSearch() {
    this.setState({ isEmailSearchModalOpen: false });
  }

  handleEmailSearchChange(event) {
    this.setState({ proponentEmailAddress: event.target.value });
  }

  handleSourceVisibility(idx) {
    const { mapSources } = this.state;

    mapSources[idx].visible = !mapSources[idx].visible;
    mapSources[idx].load = true;

    this.setState({
      mapSources,
    });
  }

  handleChange(event) {
    const controls = validateChange(event, this.state.controls);
    const changes = saveControls(controls, this.state.data);
    const { isValid } = validateFormFieldControls(changes, controls);

    this.setState({
      controls,
      formChanged: true,
      isValid,
    });
  }

  toggleTab(tab) {
    const { id: propertyId, project_id, showMiniMap, expandMap } = this.state;
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      this.props.history.replace(`${this.state.backlink}/properties/${propertyId}/${tab}`);

      switch (tab) {
        case 'profile': {
          this.props.dispatch(fetchPropertyRounds(propertyId));
          this.props.dispatch(fetchAreas(propertyId, showMiniMap || expandMap));
          break;
        }
        case 'areas': {
          // areas
          this.props.dispatch(fetchPeriods(project_id));
          this.props.dispatch(fetchAreas(propertyId, showMiniMap || expandMap));
          break;
        }
        case 'lots': {
          // lots
          this.props.dispatch(fetchPropertyLots(propertyId));
          break;
        }
        case 'rounds': {
          // rounds
          this.props.dispatch(fetchPropertyRounds(propertyId));
          break;
        }
        case 'round-results':
        case 'round-comparison': {
          this.props.dispatch(fetchMethodologies());
          this.props.dispatch(fetchSoilTemplates());
          this.props.dispatch(fetchPropertyRounds(propertyId));
          break;
        }
        default: {
          break;
        }
      }
    } else {
      // These occur on mount
      if (tab === 'profile') {
        this.props.dispatch(fetchPropertyRounds(propertyId));
        this.props.dispatch(fetchAreas(propertyId, showMiniMap || expandMap));
      }
    }
  }

  toggleMap() {
    this.setState({
      expandMap: !this.state.expandMap,
    });
  }

  toggleMiniMap() {
    this.setState({
      showMiniMap: !this.state.showMiniMap,
    });
  }

  toggleGraph() {
    this.setState({
      showGraph: !this.state.showGraph,
    });
  }

  toggleDownloadModal(type) {
    let { showDownloadModal } = this.state;
    const { id } = this.state;

    showDownloadModal = !showDownloadModal;
    if (showDownloadModal) {
      switch (type) {
        case 'round_comparison':
        case 'property_reporting':
        default:
          // Load select options
          this.props.dispatch(fetchMethodologies());
          this.props.dispatch(fetchSoilTemplates());
          this.props.dispatch(fetchPropertyRounds(id));
          break;
      }
    }
    this.setState({
      showDownloadModal,
      downloadType: type,
    });
  }

  toggleAreaChart() {
    this.setState({
      showAreaChart: !this.state.showAreaChart,
    });
  }

  getDownloadControlConfig() {
    const { id, downloadType } = this.state;
    const { methodologies, soilTemplates } = this.props.manage;
    const { propertyRounds } = this.props.properties;

    let config;

    switch (downloadType) {
      case 'maps':
        config = {
          title: 'Download Map Pack',
          controls: downloadPropertyMapsControls,
          options: {},
          onSubmit: this.downloads.handlePropertyMapPackDownload,
          asyncDownload: true,
        };

        config.controls.property_id.value = id;
        config.controls.output_type.value = 'json';
        config.controls.output_srid.value = 4283;
        break;
      case 'round_comparison':
        config = {
          title: 'Download Round Comparison',
          controls: downloadRoundComparisonControls,
          options: {
            method: methodologies.map((method) => ({ id: method.tag, name: method.name })),
            template_id: soilTemplates,
            baseline_id: propertyRounds,
          },
          onSubmit: this.downloads.handleRoundComparisonDownload,
        };
        break;
      case 'property_reporting':
      default:
        config = {
          title: 'Download Reporting Pack',
          controls: downloadPropertyReportingControls,
          options: {
            method: methodologies.map((method) => ({ id: method.tag, name: method.name })),
            template_id: soilTemplates,
            baseline_id: propertyRounds,
            excluded_round_id: propertyRounds,
          },
          onSubmit: this.downloads.handlePropertyReportingPackDownload,
          asyncDownload: true,
        };

        config.controls.property_id.value = id;
        break;
    }
    Object.entries(config.options).forEach(([key, value]) => {
      updateControlOptions(config.controls, key, value);
    });

    return config;
  }

  placeMarker() {
    const { controls } = this.state;

    this.setState({
      showMiniMap: true,
      geocoderAddress: controls.address.value,
    });
  }

  render() {
    const {
      currentProperty,
      currentPropertyLots,
      currentInsightsStatus,
      propertyRounds,
      responseMessage,
      insightsMessage,
    } = this.props.properties;
    const { periods } = this.props.periods;
    const { currentAreas } = this.props.areas;

    const areaResponseMessage = this.props.areas.responseMessage;
    const mapsterColours = this.props.mapster.colours;

    const {
      activeTab,
      id,
      controls,
      isValid,
      mapSources,
      showMiniMap,
      showGraph,
      showAreaChart,
      showDownloadModal,
      expandMap,
      areaUploadIsOpen,
      areaUploadCurrentRows,
      sitesUploadIsOpen,
      isEmailSearchModalOpen,
      proponentEmailAddress,
      geocoderAddress,
    } = this.state;

    const iconName = 'farm';
    let property = {};
    if (currentProperty) {
      property = currentProperty;
    }

    const disableExport = currentPropertyLots.length === 0;

    const lngLat = [150.7333, -23.1333];

    const downloadOptions = this.getDownloadControlConfig();

    return (
      <div>
        {!this.state.expandMap && (
          <div className="p-3">
            <div className="d-flex flex-row justify-content-between">
              <h3>
                <Icon name={iconName} className="appForeTint mr-2" />
                Property: {property.name}
              </h3>

              <div className="d-flex">
                <PropertyMenu
                  handleInsightsGenerate={this.handleInsightsGenerate}
                  isInsightsGenerating={currentInsightsStatus === 'running'}
                  handleMapPackDownload={() => this.toggleDownloadModal('maps')}
                  handleReportingPackDownload={() => this.toggleDownloadModal('property_reporting')}
                  handleComparisonDownload={() => this.toggleDownloadModal('round_comparison')}
                  toggleMiniMap={this.toggleMiniMap}
                  showMiniMap={showMiniMap}
                  toggleGraph={this.toggleGraph}
                  showGraph={showGraph}
                />
              </div>
            </div>

            <BreadcrumbsRoute match={this.props.match} />

            {this.state.showMiniMap && (
              <div>
                <Card className="border rounded mt-2" style={{ minHeight: '300px' }}>
                  <Mapster
                    handleSourceVisibility={this.handleSourceVisibility}
                    expandMap={expandMap}
                    lngLatCenter={lngLat}
                    toggleMap={this.toggleMap}
                    mapSources={mapSources}
                    geocoderAddress={geocoderAddress}
                  />
                </Card>
              </div>
            )}

            <DownloadModal
              setModal={this.toggleDownloadModal}
              isOpen={showDownloadModal}
              title={downloadOptions.title}
              controls={downloadOptions.controls}
              controlOptions={downloadOptions.options}
              onSubmit={downloadOptions.onSubmit}
              asyncDownload={downloadOptions.asyncDownload}
            />
            <Nav tabs className="mt-2">
              <FormTab
                caption="Profile"
                tabTag="profile"
                tabId="profile"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Rounds"
                tabTag="rounds"
                tabId="rounds"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Areas"
                tabTag="areas"
                tabId="areas"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Lots"
                tabTag="lots"
                tabId="lots"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Round Results"
                tabTag="round_results"
                tabId="round-results"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
              <FormTab
                caption="Round Comparison"
                tabTag="round_compare"
                tabId="round-comparison"
                activeTab={activeTab}
                toggle={this.toggleTab}
              />
            </Nav>

            <TabContent activeTab={activeTab} className="border-bottom border-light">
              <TabPane tabId="profile" className="mb-2 p-3">
                <ResponseMessage responseMessage={insightsMessage} />
                <ResponseMessage responseMessage={responseMessage} colour="text-info" />
                <PropertyProfile
                  isValid={isValid && this.checkAccess('propertyUpdate')}
                  onCancel={this.onCancel}
                  onRemove={this.onRemove}
                  onSave={this.onSave}
                  controls={controls}
                  handleChange={this.handleChange}
                  disableExport={disableExport}
                  openGeometry={this.openGeometry}
                  exportGeoJson={this.exportGeoJson}
                  openEmailPropertySearch={this.openEmailPropertySearch}
                  placeMarker={this.placeMarker}
                  propertyRounds={propertyRounds}
                  propertyAreas={currentAreas}
                />
              </TabPane>

              <TabPane tabId="areas" className="mb-2 p-3">
                <AreaUploadModal
                  isOpen={areaUploadIsOpen}
                  rows={areaUploadCurrentRows}
                  onSave={this.handleAreaUpload}
                  setModal={(toggle) => {
                    this.setState({ areaUploadIsOpen: toggle });
                  }}
                />
                <SiteUploadModal
                  isOpen={sitesUploadIsOpen}
                  onSave={this.handleSiteUpload}
                  setModal={(toggle) => {
                    this.setState({ sitesUploadIsOpen: toggle });
                  }}
                />
                <AreasLsv
                  showAreaChart={showAreaChart}
                  toggleAreaChart={this.toggleAreaChart}
                  mapsterColours={mapsterColours}
                  responseMessage={areaResponseMessage}
                  periods={periods}
                  match={this.props.match}
                  history={this.props.history}
                  rows={currentAreas || []}
                  openAreaUpload={this.openAreaUpload}
                  openSiteUpload={this.openSiteUpload}
                  uploads={this.uploads}
                  checkAccess={this.checkAccess}
                />
              </TabPane>

              <TabPane tabId="lots" className="mb-2 p-3">
                <PropertyLotsLsv
                  responseMessage={responseMessage}
                  rows={currentPropertyLots || []}
                  handleLotUpload={this.handleLotUpload}
                  checkAccess={this.checkAccess}
                />
              </TabPane>

              <TabPane tabId="rounds" className="mb-2 p-3">
                <PropertyRoundsLsv
                  match={this.props.match}
                  responseMessage={responseMessage}
                  rows={propertyRounds || []}
                  history={this.props.history}
                  checkAccess={this.checkAccess}
                  downloads={this.downloads}
                  handleSiteGenerate={this.handleSiteGenerate}
                />
              </TabPane>

              <TabPane tabId="round-results" className="mb-2 p-3">
                <ProjectPropertyResults id={id} />
              </TabPane>

              <TabPane tabId="round-comparison" className="mb-2 p-3">
                <ProjectPropertyComparisons id={id} />
              </TabPane>
            </TabContent>

            <Modal isOpen={isEmailSearchModalOpen}>
              <ModalHeader className="bg-corporate text-white">
                <Icon size="1x" name="magnifying-glass" className="mr-2" />
                Send Property Search
              </ModalHeader>
              <ModalBody>
                <p>
                  Email a property search to the client/proponent with links to tools enabling them
                  to identify all the lots making up this property. No authentication will be
                  required by the proponent. However this link will expiry after a set time.
                </p>

                <p>
                  Before sending this, make sure that the Property Address field for this property
                  has a full street address including town. For example:{' '}
                  <span className="text-success">15 Morgan Street, Gladstone</span>
                </p>
                <p>
                  To verify property address, send an email to yourself first to verify that the map
                  pinpoints the correct address on the map.
                </p>

                <p className="text-primary">Please note that this link will expiry in 48 hours.</p>
                <Input
                  placeholder="Proponent Email Address"
                  onChange={this.handleEmailSearchChange}
                  value={proponentEmailAddress}
                />
                <ResponseMessage responseMessage={responseMessage} />
              </ModalBody>
              <ModalFooter className="d-flex justify-content-end">
                <Button size="sm" color="success" onClick={() => this.onSendEmailSearch()}>
                  Email Link
                </Button>
                <Button size="sm" color="light" onClick={this.onCancelEmailSearch}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        )}
        {this.state.expandMap && (
          <Mapster
            handleSourceVisibility={this.handleSourceVisibility}
            expandMap={expandMap}
            lngLatCenter={lngLat}
            toggleMap={this.toggleMap}
            mapSources={mapSources}
          />
        )}
      </div>
    );
  }
}

const mapStoreToProps = (store) => ({
  properties: store.properties,
  areas: store.areas,
  periods: store.periods,
  mapster: store.mapster,
  realm: store.realm,
  manage: store.manage,
});

export default connect(mapStoreToProps)(withContainerError(ProjectProperty));
