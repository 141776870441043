import { controlDefaults } from '../../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  open_date: {
    ...controlDefaults,
    placeholder: '',
    group: 'Agistment Dates',
    name: 'open_date',
    fieldName: 'open_date',
    type: 'date',
    caption: 'Start Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  close_date: {
    ...controlDefaults,
    placeholder: '',
    group: 'Agistment Dates',
    name: 'close_date',
    fieldName: 'close_date',
    type: 'date',
    caption: 'End Date',
    validationRules: {},
    showInEditor: true,
    showInListview: true,
  },
  total_weeks_count: {
    ...controlDefaults,
    classes: 'border-left border-right text-right',
    placeholder: '',
    name: 'total_weeks_count',
    type: 'number',
    group: 'Agistment Dates',
    caption: 'Total Number of Weeks',
    validationRules: {},
    formattingRules: {
      includeDecimals: false,
    },
    showInEditor: true,
    disabled: true,
    showInListview: true,
    totals: true,
  },
  enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'Animals',
    fieldName: 'enterprise.name',
    name: 'enterprise_id',
    type: 'select',
    caption: 'Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  property_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'Animals',
    fieldName: 'property.name',
    name: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'Animals',
    fieldName: 'animal_class.name',
    name: 'animal_class_id',
    type: 'select',
    caption: 'Animal Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    inlcudeEmptyOption: true,
  },
  quantity_head: {
    ...controlDefaults,
    placeholder: '',
    group: 'Animals',
    name: 'quantity_head',
    type: 'number',
    caption: '# of Head',
    validationRules: {
      isRequired: true,
      greaterThanZero: true,
    },
    formattingRules: {
      includeDecimals: true,
      includeCommas: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  au_rating_lsu: {
    ...controlDefaults,
    fieldName: 'au_rating_lsu',
    placeholder: '',
    group: 'Animals',
    unitType: 'lsu',
    name: 'au_rating_lsu',
    type: 'number',
    caption: 'AU Rating',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  area_ha: {
    ...controlDefaults,
    placeholder: '',
    group: 'Animals',
    name: 'area_ha',
    fieldName: 'area_ha',
    type: 'text',
    caption: 'Area (ha)',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  comments: {
    ...controlDefaults,
    placeholder: 'Comments',
    name: 'comments',
    group: 'Animals',
    type: 'textarea',
    caption: 'Comments',
    persist: false,
    validationRules: {},
    showInEditor: true,
  },
  transaction_type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_type_id',
    fieldName: 'transaction_type_id',
    caption: 'Transaction Type',
    disabled: true,
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    showInListview: false,
    showInEditor: false,
  },

  weight_gained: {
    ...controlDefaults,
    placeholder: '',
    name: 'weight_gained',
    unitType: 'kg',
    group: 'Production',
    type: 'number',
    caption: 'Weight Gained (kg/head)',
    validationRules: {},
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
  },

  weaned_quantity: {
    ...controlDefaults,
    placeholder: '',
    name: 'weaned_quantity',
    unitType: 'kg',
    group: 'Production',
    type: 'number',
    caption: '# Weaned (head)',
    validationRules: {},
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  weaned_weight_kg: {
    ...controlDefaults,
    placeholder: '',
    name: 'weaned_weight_kg',
    unitType: 'kg',
    group: 'Production',
    type: 'number',
    caption: 'Weaning Weight (kg)',
    validationRules: {},
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
  },

  value_per_head_week: {
    ...controlDefaults,
    group: 'Value',
    name: 'value_per_head_week',
    type: 'number',
    caption: 'Value Per Head ($/head/week)',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
      includeDecimals: false,
    },
    showInEditor: true,
    showInListview: true,
    tracking: ['amount'],
    user_entry: false,
  },
  total_value: {
    ...controlDefaults,
    formula_priority: 1,
    placeholder: '',
    group: 'Value',
    name: 'total_value',
    type: 'number',
    formattingRules: {
      includeDecimals: true,
      includeCommas: true,
    },
    caption: 'Total Value ($)',
    disabled: true,
    showInListview: true,
    showInEditor: true,
  },
};
