
import { connect } from 'react-redux';
import FormBase from 'jsx/components/core/form/components/FormBase';
import ReportMetrics from 'jsx/components/manage/containers/ReportMetrics';
import PageTitle from 'jsx/components/core/form/components/PageTitle';

class ReportAdmin extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
  }

  render() {
    return (
      <div className="p-3 h-100">
        <div className="p-1">
            <PageTitle iconName="table" title="Report Metrics" />
        </div>
        <ReportMetrics history={this.props.history} />
      </div>
    );
  }
}

const mapStoreToProps = () => {};

export default connect(mapStoreToProps)(ReportAdmin);