import React from 'react';
import { connect } from 'react-redux';
import { Card, Form, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from 'jsx/components/core/form/components/FormInput';
import FormBase from 'jsx/components/core/form/components/FormBase';
import { updateControls, saveControls } from 'jsx/components/core/form/lib/validateForm';
import { controls as inviteControls } from '../forms/invite';

import { register, associate, fetchInvite } from '../actions';

class Invite extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      user: {},
      pwdMsg: '',
      showOrgSection: false,
      validationMessage: '',
      invitationFailMessage: null,
      controls: inviteControls,
      userAssociated: false,
    };

    // this.handleChange = this.handleChange.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.validationCheck = this.validationCheck.bind(this);
    this.onAssociate = this.onAssociate.bind(this);
    this.passwordCheck = this.passwordCheck.bind(this);
  }

  async componentDidMount() {
    let { controls } = this.state;
    const { id } = this.props.match.params;
    const data = await this.props.dispatch(fetchInvite(id));

    if (!data) {
      this.setState({ invitationFailMessage: 'This is not a valid invitation!' });
      return;
    }

    if (data?.userAssociated) this.setState({ userAssociated: true });

    controls = updateControls(controls, data);
    this.setState({
      controls,
      data,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  passwordCheck(pwd, pwd2) {
    if (pwd?.length > 0 && pwd2?.length > 0) {
      if (pwd !== pwd2) {
        return 'Passwords do not match';
      }
      if (pwd.length < 8) {
        return 'Password must be minimum of 8 characters.';
      }
    } else {
      return 'Must add password';
    }
    return null;
  }

  validationCheck() {
    const { controls } = this.state;

    const msg = this.passwordCheck(controls.password.value, controls.password2.value);
    if (msg) {
      this.setState({ validationMessage: msg });
      return false;
    }

    if (!controls.firstname.value || !controls.lastname.value || !controls.email.value) {
      this.setState({ validationMessage: 'Must fill in First Name, Last Name and Email.' });
      return false;
    }

    this.setState({ validationMessage: null });
    return true;
  }

  async onRegister() {
    const { controls, data } = this.state;

    const user = saveControls(controls, data);
    user.invitation_id = controls.id.value;
    user.email = controls.email.value;
    user.firstname = controls.firstname.value;
    user.lastname = controls.lastname.value;
    user.mobile = controls.mobile.value;
    delete user.id;

    if (this.validationCheck()) {
      delete user.password2;
      const success = await this.props.dispatch(register(user));
      if (success) {
        this.props.history.push('/login');
      }
    }
  }

  onAssociate() {
    const { data } = this.state;
    this.props.dispatch(associate(data));
  }

  render() {
    const { data, user, userAssociated, validationMessage, controls } = this.state;
    const { associated, registered, registerMessage } = this.props.auth;
    const { currentApp } = this.props.realm;

    let userAssociationComment = '';
    let orgName = '';
    if (data && data.org) {
      userAssociationComment = `You have been invited to join ${data.org.name}/${data.realm_id}`;
      orgName = data.org.name;
    }

    return (
      <div className="container-fluid p-0 h-100 bg-light row align-items-center d-flex justify-content-center">
        <Card className="p-2 card-border-color border-corporate login text-center">
          <Icon size="4x" name={currentApp.icon} className="text-corporate m-3" />
          <h3 className="text-secondary mt-2">{currentApp.title}</h3>
          {registered && (
            <p className="m-4 text-success">
              Succesfully registered <strong>{user.email}</strong>. You can now{' '}
              <a href="/login">login</a>
            </p>
          )}
          {associated && (
            <p className="m-4 text-success">
              Succesfully associated <strong>{user.email}</strong> to {orgName}/{data.realm_id}. You
              can now <a href="/login">login</a>
            </p>
          )}
          {validationMessage && <p className="text-danger mt-2">{validationMessage}</p>}
          {!registered && registerMessage && <p className="text-danger">{registerMessage}</p>}

          {userAssociated && !associated && !registered && data.id && (
            <div className="m-4 text-left">
              <div className="text-center text-corporate">{userAssociationComment}</div>
              <Button onClick={this.onAssociate} className="bg-corporate mt-3 w-100">
                Accept Invitation
              </Button>
              <div className="m-2 d-flex justify-content-between">
                <small>
                  <a href="/forgotpassword">Forgot Password?</a>
                </small>
                <small>
                  <a href="/login">Login</a>
                </small>
              </div>
            </div>
          )}

          {!registered && !userAssociated && data.id && (
            <>
              <small className="text-secondary">Please enter your user information</small>
              <div className="m-4 text-left">
                <Form>
                  <FormInput handleChange={this.handleChange} control={controls.firstname} />
                  <FormInput handleChange={this.handleChange} control={controls.lastname} />
                  <FormInput handleChange={this.handleChange} control={controls.email} />
                  {currentApp.id === 'wipstar' && (
                    <FormInput handleChange={this.handleChange} control={controls.mobile} />
                  )}
                  <FormInput handleChange={this.handleChange} control={controls.password} />
                  <FormInput
                    className="mt-1"
                    handleChange={this.handleChange}
                    control={controls.password2}
                  />
                </Form>

                <Button onClick={this.onRegister} className="bg-corporate mt-3 w-100 border-0">
                  Register
                </Button>
                <div className="m-2 d-flex justify-content-between">
                  <small>
                    <a href="/forgotpassword">Forgot Password?</a>
                  </small>
                  <small>
                    <a href="/login">Login</a>
                  </small>
                </div>
              </div>
            </>
          )}
        </Card>
      </div>
    );
  }
}

const mapStoreToProps = ({ auth, realm }) => ({ auth, realm });

export default connect(mapStoreToProps)(Invite);
