import {
  fetchAreaRoundStratas,
  uploadSites,
  uploadStratas
} from '../actions';

import {
  fetchSites
} from '../../sites/actions';

export default class Uploads {
  set(round_id, dispatch) {
    this.round_id = round_id;
    this.dispatch = dispatch;
  }

  async handleSiteUpload(event) {
    if (event.target.files.length === 0 ) return;

    const file = event.target.files[0];

    let formData = new FormData();
    formData.append('geom', file);
    formData.append('round_id', this.round_id);

    await this.dispatch(uploadSites(formData));
    this.dispatch(fetchAreaRoundStratas(this.round_id))
    this.dispatch(fetchSites({round_id: this.round_id}))
  }

  async handleStrataUpload(event) {
    if (event.target.files.length === 0 ) return;

    const file = event.target.files[0];

    let formData = new FormData();
    formData.append('geom', file);
    formData.append('round_id', this.round_id);

    await this.dispatch(uploadStratas(formData));
    // if (success) this.setStrataMaps();
  }
}

