import React from 'react';
import { connect } from 'react-redux';

import Mapster from '../../projects/containers/Mapster.jsx';
import { buildFeatureCollection, buildFeature } from '../../projects/lib/mapster.js';
import SiteSampleChartModal from '../../sites/components/SiteSampleChartModal';

import { fetchSiteSamples } from '../../sites/actions';

class AreaRoundMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapSources: [],
      chartIsOpen: false,
      currentSite: {},
    };

    this.handleSourceVisibility = this.handleSourceVisibility.bind(this);
    this.toggleChart = this.toggleChart.bind(this);
    this.fetchSamples = this.fetchSamples.bind(this);
  }

  componentDidMount() {
    this.resetGeometry();
  }

  toggleChart(site_id) {
    const { sites } = this.props.sites;

    const chartIsOpen = !this.state.chartIsOpen;
    this.setState({ chartIsOpen });

    if (chartIsOpen) {
      const site = sites.find((site) => site.id === site_id);
      this.fetchSamples(site);
    }
  }

  resetGeometry() {
    const mapSources = [
      {
        id: 'stratas',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Stratas',
        count: 0,
        style: 'Polygon',
        showLabels: true,
      },
      {
        id: 'heatmap',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Sites',
        count: 0,
        style: 'Heatmap',
      },
      {
        id: 'sites',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Sites',
        count: 0,
        style: 'Point',
        onClick: this.toggleChart,
      },
    ];
    this.setState({ mapSources });
  }

  setStrataMaps() {
    const stratas = this.props.areas.stratas;
    const mapSources = this.state.mapSources;
    const featureColour = this.props.mapster.colours.strata;

    if (stratas.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'stratas');
      const featureCollection = buildFeatureCollection();

      stratas.forEach((strata) => {
        if (strata.geom) {
          const feature = buildFeature(strata.geom, {
            title: `${strata.name}`,
            colour: featureColour.fill,
            outline: featureColour.outline,
          });
          featureCollection.features.push(feature);
        }
      });

      if (mapSources[idx].load && featureCollection.features.length > 0) {
        if (mapSources[idx].visible === true) {
          mapSources[idx].source = featureCollection;
        } else {
          mapSources[idx].source = buildFeatureCollection();
        }
        mapSources[idx].load = false;
        mapSources[idx].count = featureCollection.features.length;
        this.setState({ mapSources });
      }
    }
  }

  setSiteMaps() {
    const sites = this.props.sites.sites;
    const mapSources = this.state.mapSources;

    if (sites.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'sites');
      const featureCollection = buildFeatureCollection();

      sites.forEach((site) => {
        if (site.geom) {
          // Target/Reserve colour
          let featureColour = this.props.mapster.colours.site_target;
          if (site.type_key === 'R') {
            featureColour = this.props.mapster.colours.site_reserve;
          }
          // colour if scanned
          if (site.scan_samples_exists) {
            featureColour = this.props.mapster.colours.site_scanned;
          }
          const feature = buildFeature(site.geom, {
            id: site.id,
            core_id: site.core_id,
            scan_samples_exists: site.scan_samples_exists,
            title: `SITE ${site.name}`,
            colour: featureColour.fill,
            outline: featureColour.outline,
          });
          featureCollection.features.push(feature);
        }
      });

      if (
        mapSources[idx].source.features.length !== sites.length &&
        featureCollection.features.length > 0
      ) {
        mapSources[idx].load = false;
        mapSources[idx].source = featureCollection;
        mapSources[idx].count = featureCollection.features.length;
        this.setState({ mapSources });
      }
    }
  }

  setSiteHeatMaps() {
    const sites = this.props.sites.sites;
    const mapSources = this.state.mapSources;

    if (sites.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'heatmap');
      const featureCollection = buildFeatureCollection();

      sites.forEach((site) => {
        if (site.geom && site.calc_carbon_stock_tph) {
          const feature = buildFeature(site.geom, {
            title: `SITE ${site.name}`,
            tonnes: site.calc_carbon_stock_tph,
          });
          featureCollection.features.push(feature);
        }
      });

      if (
        mapSources[idx].source.features.length !== sites.length &&
        featureCollection.features.length > 0
      ) {
        mapSources[idx].load = false;
        mapSources[idx].source = featureCollection;
        mapSources[idx].count = featureCollection.features.length;
        this.setState({ mapSources });
      }
    }
  }

  handleSourceVisibility(event) {
    let mapSources = this.state.mapSources;
    const idx = event.target.value;

    mapSources[idx].visible = !mapSources[idx].visible;
    mapSources[idx].load = true;

    this.setState({
      mapSources,
    });
  }

  fetchSamples(site) {
    this.props.dispatch(fetchSiteSamples({ site_id: site.id }));
    this.setState({ currentSite: site });
  }

  render() {
    const { expandMap, toggleMap } = this.props;

    this.setStrataMaps();
    this.setSiteMaps();
    this.setSiteHeatMaps();

    const { mapSources, chartIsOpen, currentSite } = this.state;

    const { sites, samples } = this.props.sites;

    const lngLat = [0, 0];

    return (
      <div>
        <SiteSampleChartModal
          toggle={this.toggleChart}
          isOpen={chartIsOpen}
          samples={samples}
          fetchSamples={this.fetchSamples}
          rows={sites}
          currentSite={currentSite}
        />
        <Mapster
          handleSourceVisibility={this.handleSourceVisibility}
          expandMap={expandMap}
          lngLatCenter={lngLat}
          toggleMap={toggleMap}
          mapSources={mapSources}
        />
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    areas: store.areas,
    sites: store.sites,
    mapster: store.mapster,
  };
};

export default connect(mapStoreToProps)(AreaRoundMap);
