import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  version_id: {
    ...controlDefaults,
    fieldName: 'version_id',
    name: 'version_id',
    type: 'hidden',
    caption: 'Version ID',
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    fieldName: 'name',
    type: 'text',
    caption: 'Enterprise Name',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    description: 'Name of this Enterprise. This field can not be blank.',
  },
  division_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'division_id',
    fieldName: 'division_id',
    type: 'select',
    caption: 'Division',
    validationRules: {
      isRequired: true,
    },
    options: [],
    showInEditor: true,
    showInListview: true,
  },
  type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'type_id',
    fieldName: 'type_id',
    type: 'select',
    caption: 'Type',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    description: 'Subtype of Division. Dependent on the Division selection',
  },
  type: {
    ...controlDefaults,
    placeholder: '',
    name: 'type',
    fieldName: 'type',
    type: 'text',
    caption: 'Type',
    validationRules: {},
    showInEditor: false,
    showInListview: true,
  },
  production_units_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'production_units_id',
    fieldName: 'production_units_id',
    type: 'select',
    caption: 'Production Units',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
    description: 'Reporting units are dependent on Division Selection',
    showInEditor: true,
    showInListview: true,
  },
  reporting_units_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'reporting_units_id',
    fieldName: 'reporting_units_id',
    type: 'select',
    caption: 'Reporting Units',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
    description: 'Reporting units are dependent on Division Selection',
    showInEditor: true,
    showInListview: true,
  },
  allocations: {
    ...controlDefaults,
    name: 'definition',
    type: 'json',
    caption: 'Definition',
    disabled: false,
    validationRules: {
      isArray: true,
    },
    value: [],
  },
};
