import { apiAdminAdvisors } from 'jsx/constants/api';
import { get } from 'jsx/lib/genericAction';

export function fetchAdminAdvisors(params) {
  return async (dispatch) => get(dispatch, 'FETCH_ADMIN_ADVISORS', `${apiAdminAdvisors}`, params);
}

export function submitAnalysis(params) {
  return async (dispatch) => get(dispatch, 'SUBMIT_ANALYSIS', `${apiAdminAdvisors}/submitanalysis`, params );
}
