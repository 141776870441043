import React, { useState } from 'react';
import { Input } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';
import Listview from '../../../core/form/components/Listview';
import ImagePreviewModal from '../../../core/modals/ImagePreviewModal';
import { convertBufferToImageSource } from '../../../../lib/image';

const LabRegisterRecordSamplesLsv = (props) => {
  const { currentRecord, pagination, onLoadMore, handleSelect, rowSelection, fetchImage } = props;
  let { rows } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [imageId, setImageId] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [imageBody, setImageBody] = useState(<div />);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const getImage = async (id, type) => {
    if (rows) {
      const row = rows.find(({ id: sample_id }) => sample_id === id);
      if (row) {
        const { core_id, core_position } = row;
        // Fetch image from db
        switch (type) {
          case 'sample_image':
            return fetchImage({ image_type: type, sample_id: row.sample_id });
          case 'core_image':
            return fetchImage({
              image_type: type,
              core_id,
              core_position,
            });
          default:
            break;
        }
      }
    }

    return null;
  };

  const renderImage = async (id, type) => {
    // Fetch image from api . Use type state variable if parameter is null
    const image = await getImage(id, type || imageType);

    if (image) {
      const imgSrc = convertBufferToImageSource(image.data);
      return (
        <div className="d-flex justify-content-center">
          <img
            alt={`Scan sample`}
            src={imgSrc}
            style={{ width: 'auto', maxWidth: '100%', maxHeight: '1000px' }}
          />
        </div>
      );
    }
    return <span>Image not found</span>;
  };

  const viewImage = async (type, id) => {
    setImageType(type);
    setImageId(id);
    setImageBody(await renderImage(id, type));
    toggle();
  };

  const sampleValidity = (row) => {
    const { weight_soil_air_dry, weight_soil_oven_dry, residual } = row;
    const sample_type = row.lab_register?.sample_type.name ?? '-';

    const warning_classes = 'bg-warning font-weight-bold text-white';
    const warning_message = <Icon name="exclamation-circle" />;

    if (weight_soil_air_dry < weight_soil_oven_dry)
      return {
        tooltip: 'Oven Dry larger than Air Dry',
        classes: warning_classes,
        message: warning_message,
      };

    if (sample_type === 'lr_gravel_selection' && weight_soil_air_dry < 200)
      return {
        tooltip: 'Air Dry less than 200g',
        classes: warning_classes,
        message: warning_message,
      };

    if (sample_type === 'lr_subsample_selection' && weight_soil_air_dry > 250)
      return {
        tooltip: 'Air Dry greater than 250g',
        classes: warning_classes,
        message: warning_message,
      };

    if (residual > 2)
      return {
        tooltip: 'WARNING: Residual evaulation required',
        classes: warning_classes,
        message: warning_message,
      };
    if (residual === null)
      return {
        tooltip: 'No residual values found in local_global_actual',
        classes: 'text-gray',
        message: warning_message,
      };
    return {
      tooltip: 'Residual within expected parameters',
      classes: 'text-success',
      message: residual,
    };
  };

  const renderRows = (headers, row) => {
    const {
      sample_image_exists: hasSampleImage,
      scan_image_exists: hasCoreImage,
      sampled_user,
      id: rowId,
    } = row;
    let { sampled_at } = row;
    const isChecked = rowSelection ? rowSelection.findIndex((id) => id === rowId) > -1 : false;
    const test_code = row.test_codes && row.test_codes[0] ? row.test_codes[0].code : '';
    const validity_info = sampleValidity(row);

    let sampled_info = null;
    if (sampled_at && sampled_user) {
      sampled_at = moment(sampled_at).format('YYYY-MM-DD HH:mm');
      sampled_info = `Sampled at ${sampled_at} by ${sampled_user.firstname.charAt(0)} ${
        sampled_user.lastname
      }`;
    }

    const tableTd = headers.map(({ field, classes }, index) => {
      switch (field) {
        case 'checkbox':
          return (
            <td key={index}>
              <Input
                type="checkbox"
                className="m-0 mt-1 ml-n1"
                onChange={handleSelect}
                value={rowId}
                checked={isChecked}
              />
            </td>
          );
        case 'core_id':
          let { core_id } = row;
          if (
            currentRecord.sample_type &&
            currentRecord.sample_type.name === 'lr_composite_subsample_selection'
          ) {
            core_id = row.composite_core_ids.map((id) => <div>{id}</div>);
          }
          return (
            <td key={index} className={classes}>
              {core_id}
            </td>
          );
        case 'sample_image':
          return (
            <td key={index} title={sampled_info} className={`${classes} text-primary`}>
              {hasSampleImage ? (
                <Icon name="magnifying-glass" onClick={() => viewImage(field, rowId)} />
              ) : (
                ''
              )}
            </td>
          );
        case 'core_image':
          return (
            <td key={index} className={`${classes} text-primary`}>
              {hasCoreImage ? (
                <Icon name="magnifying-glass" onClick={() => viewImage(field, rowId)} />
              ) : (
                ''
              )}
            </td>
          );
        case 'validity':
          return (
            <td
              key={index}
              className={`${classes} ${validity_info.classes}`}
              title={validity_info.tooltip}
            >
              {validity_info.message}
            </td>
          );
        case 'lab_test_code':
          return (
            <td key={index} className={`${classes} font-weight-bold text-danger`}>
              {test_code}
            </td>
          );
        default:
          return (
            <td key={index} className={classes}>
              {row[field]}
            </td>
          );
      }
    });
    return tableTd;
  };

  const viewPrev = async () => {
    const idx = rows.findIndex((sample) => sample.id === imageId);
    let prev_idx = idx - 1;
    if (prev_idx < 0) {
      prev_idx = rows.length - 1;
    }

    setImageId(rows[prev_idx].id);
    setImageBody(await renderImage(rows[prev_idx].id));
  };

  const viewNext = async () => {
    const idx = rows.findIndex((sample) => sample.id === imageId);
    let next_idx = idx + 1;
    if (next_idx >= rows.length) {
      next_idx = 0;
    }

    setImageId(rows[next_idx].id);
    setImageBody(await renderImage(rows[next_idx].id));
  };

  const upperHeaders = [
    { caption: null, cells: 0, classes: 'border-bottom-0' },
    { caption: 'Sample', cells: 2, classes: 'text-center bg-lightgray border border-lightgray' },
    { caption: 'Core', cells: 3, classes: 'text-center bg-lightgray border border-lightgray' },
    { caption: 'Depth', cells: 2, classes: 'text-center bg-lightgray border border-lightgray' },
    { caption: null, cells: 3, classes: 'border-bottom-0' },
    { caption: 'Lab', cells: 6, classes: 'text-center bg-lightgray border border-lightgray' },
    { caption: null, cells: 1, classes: 'border-bottom-0' },
  ];

  const headers = [
    { caption: 'ID', field: 'sample_id', classes: 'text-center border-left' },
    { caption: 'Image', field: 'sample_image', classes: 'text-center border-right' },
    { caption: 'ID', field: 'core_id', classes: 'text-center border-left' },
    { caption: 'Position (mm)', field: 'core_position', classes: 'text-center' },
    { caption: 'Image', field: 'core_image', classes: 'text-center border-right' },
    { caption: 'Upper (cm)', field: 'upper_depth', classes: 'text-center border-left' },
    { caption: 'Lower (cm)', field: 'lower_depth', classes: 'text-left border-right' },
    { caption: 'Layer No', field: 'layer_no', classes: 'text-center' },
    { caption: 'Set', field: 'set_type', classes: 'text-center' },
    { caption: 'Test Code', field: 'lab_test_code', classes: 'text-center' },
    {
      caption: 'Air Dry Mass (g)',
      field: 'weight_soil_air_dry',
      classes: 'text-center  border-left',
    },
    {
      caption: 'Oven Dry Equivalent Mass (g)',
      field: 'weight_soil_oven_dry',
      classes: 'text-center',
    },
    { caption: 'Total moisture content (%)', field: 'pcnt_water_field', classes: 'text-center' },
    {
      caption: 'Gravimetric water content (g water/g oven-dry mass)',
      field: 'ratio_grav_water_oven_dry',
      classes: 'text-center',
    },
    { caption: 'Gravel Content (g)', field: 'weight_gravel', classes: 'text-center' },
    {
      caption: 'Total Organic Carbon (% C oven dried basis)',
      field: 'pcnt_carbon',
      classes: 'text-center border-right',
    },
    { caption: 'Validity', field: 'validity', classes: 'text-center' },
  ];

  if (rowSelection) {
    upperHeaders[0].cells += 1;
    headers.splice(0, 0, { caption: '-', field: 'checkbox', classes: 'text-center' });
  } else upperHeaders.splice(0, 1);

  const upperHeadTh = upperHeaders.map(({ cells, caption, classes }, index) => (
    <th key={index} colSpan={cells} className={classes}>
      {caption}
    </th>
  ));

  const tableHeadTh = headers.map(({ classes, caption }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = <tr />;
  let rowsCount;
  let imgRecord;
  if (rows && rows.rows && pagination) {
    rowsCount = rows.count;
    ({ rows } = rows);
  }

  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
    imgRecord = rows.find((row) => row.id === imageId);
  }

  const iconName = 'flask';
  const emptyCaption = 'No samples found';

  let imgTitle = '';
  if (imgRecord)
    imgTitle = `Image for Sample ${imgRecord.sample_id} (Core ${imgRecord.core_id}, Position ${imgRecord.core_position})`;

  return (
    <>
      <ImagePreviewModal
        imgSrc={imageBody}
        title={imgTitle}
        toggle={toggle}
        viewNext={viewNext}
        viewPrev={viewPrev}
        isOpen={isOpen}
        onClickOutside={() => setIsOpen(false)}
      />
      <Listview
        rows={rows}
        rowsCount={rowsCount}
        onLoadMore={onLoadMore}
        upperHeadTh={upperHeadTh}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </>
  );
};

export default LabRegisterRecordSamplesLsv;
