import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    name: 'name',
    type: 'text',
    caption: 'Name',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  description: {
    ...controlDefaults,
    name: 'description',
    type: 'textarea',
    caption: 'Description',
    rows: 2,
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: true,
  },
  is_active: {
    ...controlDefaults,
    name: 'is_active',
    field_name: 'is_active',
    controlType: 'switch',
    type: 'checkbox',
    caption: 'Active',
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    validationRules: {
      isRequired: true,
    },
    defaultValue: true,
    showInEditor: true,
    showInListview: true,
  },
};
