import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { GenericModal } from '../../../core/form/components/GenericModal';
import {
  initControls,
  updateControls,
  updateControlOptions,
} from '../../../core/form/lib/validateForm';

class OtherLivestockModal extends GenericModal {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      setModal: this.props.setModal,
    };
    this.onChange = this.onChange.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { modalTitle } = this.props;
      const controls = initControls(structuredClone(this.props.controls));

      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: `New ${modalTitle}`,
      };

      if (this.props.data?.id) {
        const { data } = this.props;

        updatedState = {
          ...updatedState,
          controls: updateControls(controls, data),
          data,
          id: data.id,
          isNew: false,
          title: `Edit ${modalTitle}`,
        };
      }

      this.setState(updatedState);
    }
  }

  filterProperties(enterprise_id) {
    const { enterprises } = this.props.enterprises;

    // Only display properties that have been previously allocated to the enterprise
    const { allocations = [] } = enterprises?.rows.find(
      (enterprise) => enterprise.id === enterprise_id
    );

    return allocations.map((allocation) => ({ ...allocation.property }));
  }

  onChange(event) {
    const { name, value } = event.target;

    switch (name) {
      case 'transaction_type_id':
        {
          const updatedControls = this.state.controls;

          const currentTransactionType = updatedControls.transaction_type_id.options.find(
            (transaction) => transaction.id === value
          );

          if (currentTransactionType.tag === 'sale') {
            updatedControls.dressing_pcnt.showInEditor = true;
          } else {
            updatedControls.dressing_pcnt.showInEditor = false;
          }

          this.setState({
            controls: updatedControls,
          });
        }
        break;
      default: {
        break;
      }
    }

    this.handleChange(event);
  }

  loadProperties(enterprise_id) {
    const { enterprises } = this.props.enterprises;

    // Only display properties that have been previously allocated to the enterprise
    const { allocations = [] } = enterprises?.rows.find(
      (enterprise) => enterprise.id === enterprise_id
    );

    return allocations.map((allocation) => ({ ...allocation.property }));
  }

  render() {
    let { controls } = this.state;
    const { title, isNew, description } = this.state;
    const { iconName, isOpen } = this.props;

    controls = this.setOptions(controls);

    const enterprise_id = controls?.enterprise_id?.value ?? null;
    if (enterprise_id) {
      const filteredProperties = this.filterProperties(enterprise_id);
      controls = updateControlOptions(controls, 'property_id', filteredProperties);
      controls.property_id.showInEditor = filteredProperties.length > 1;
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon size="1x" name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody>
          {description && <p>{description}</p>}
          {this.props.responseMessage && (
            <div className="text-center text-danger">{this.props.responseMessage}</div>
          )}
          <Form>{this.renderInputs(controls, this.onChange)}</Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ enterprises }) => ({
  enterprises,
});

export default connect(mapStoreToProps)(OtherLivestockModal);
