import React from 'react';
import { ComposedChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ResourceUsagePercentByEntities = (props) => {
  const {
    data,
    orgs
  } = props;

  const toPercent = (decimal) => `${(decimal * 100).toFixed(0)}%`;

  const areas = orgs.map((org, index) => {
    return (
      <Area key={index} type="monotone" dataKey={org.name} name={org.name} stackId="1" stroke={org.colour} fill={org.colour} unit="%" yAxisId="left"/>
    )
  });

  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          stackOffset="expand"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <Legend />
          <Tooltip />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="week" />

          <YAxis yAxisId="left" tickFormatter={toPercent} />
          <YAxis yAxisId="right" orientation="right"/>

          {areas}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ResourceUsagePercentByEntities;
