import React from 'react';
import {
  Button
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const EnterpriseAllocationsToolbar = (props) => {
  const {
    setModal,
    onRefresh
  } = props;

  return (
    <div className="d-flex justify-content-end">
      <Button onClick={() => setModal(true)} size="sm" color="success" className="ml-1">
        <Icon name="plus" />
      </Button>
      <Button onClick={onRefresh} size="sm" color="success" className="ml-1">
        <Icon name="rotate" />
      </Button>
    </div>
  );
}

export default EnterpriseAllocationsToolbar
