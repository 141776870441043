import React from 'react';
import { connect } from "react-redux";

import { controls } from "../forms/forgot";
import FormBase from "../../form/components/FormBase";
import { Redirect } from 'react-router-dom';

import {
  masquerade
} from "../actions"

class Masquerade extends FormBase {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      pwdMsg: '',
      showOrgSection: false,
      validationMessage: '',
      controls: controls,
      sent: false
    };
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    await this.props.dispatch(masquerade(id));
  }

  render() {
    const {
      currentApp
    } = this.props.realm;

    const {
      authenticated
    } = this.props.auth

    if (authenticated && this.props.match.path === '/masquerade') {
      return <Redirect to={ currentApp.defaultPage } />;
    }

    return (
      <div className="">
        Masquerading...
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    auth: store.auth,
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(Masquerade);
