import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    activeTab: '1',
    pipelines: [],
    files: [],
    documents: [],
    rounds: [],
    tabParams: {
      1: { type: 'area_round' },
      2: { type: 'property_round' },
    },
  },
  action
) {
  // Set expected reducer types
  const types = ['PIPELINE'];
  if (!allowed(types, action)) return state;

  state = {
    ...state,
    ...defaults(action),
  };

  const updatePipeline = (pipeline) => {
    // Manage status icon, class and spinner
    pipeline.isSpinning = false;
    pipeline.statusIcon = null;
    pipeline.statusClass = 'primary';
    switch (pipeline.status_key) {
      case 'processing':
        pipeline.statusIcon = null;
        pipeline.statusClass = 'primary';
        pipeline.isSpinning = true;
        break;
      case 'completed':
        pipeline.statusIcon = 'check';
        pipeline.statusClass = 'success';
        break;
      case 'fail':
        pipeline.statusIcon = 'triangle-exclamation';
        pipeline.statusClass = 'danger';
        break;
      case 'scheduled':
        pipeline.statusIcon = 'clock';
        pipeline.statusClass = 'primary';
        pipeline.isSpinning = true;
        break;
      default:
        pipeline.statusIcon = null;
        pipeline.statusClass = 'primary';
        pipeline.isSpinning = false;
        break;
    }

    // Set last message to null if empty (bit of ahack :o )
    if (pipeline.last_message && pipeline.last_message.length === 0) {
      pipeline.last_message = null;
    }
    pipeline.message = [pipeline.status_key, pipeline.last_message].join(' - ');

    const { id } = pipeline;

    const { pipelines } = state;
    const idx = pipelines.findIndex((p) => p.id === id);
    if (idx > -1) pipelines[idx] = pipeline;
    else pipelines.push(pipeline);
  };

  switch (action.type) {
    case 'UNSET_PIPELINES':
    case 'REMOVE_PIPELINES_FULFILLED': {
      return {
        ...state,
        pipelines: [],
        documents: [],
      };
    }
    case 'FETCH_PIPELINES_FULFILLED': {
      const pipelines = action.payload;
      for (const index in pipelines) {
        updatePipeline(pipelines[index]);
      }
      return state;
    }
    case 'UPDATE_PIPELINE_TOPIC':
    case 'UPDATE_PIPELINE_FULFILLED':
    case 'FETCH_PIPELINE_FULFILLED': {
      updatePipeline(action.payload);
      return state;
    }
    case 'FETCH_PIPELINE_FILES_FULFILLED': {
      return {
        ...state,
        files: action.payload,
      };
    }
    case 'FETCH_PIPELINE_DOCUMENTS_FULFILLED': {
      return {
        ...state,
        documents: action.payload,
      };
    }
    case 'FETCH_PIPELINE_DOCUMENT_FULFILLED': {
      const { documents } = state;
      const { payload } = action;
      const idx = documents.findIndex((document) => document.id === payload.id);
      if (idx > -1) documents[idx] = payload;
      else documents.push(payload);

      return {
        ...state,
        documents,
      };
    }

    case 'REMOVE_PIPELINE_DOCUMENTS_FULFILLED': {
      const { pipeline_id } = action.payload;
      const documents = state.documents.filter((document) => document.pipeline_id !== pipeline_id);

      return {
        ...state,
        documents,
      };
    }

    case 'SET_PIPELINES_ACTIVE_TAB': {
      return {
        ...state,
        activeTab: action.payload,
      };
    }

    case 'SET_PIPELINE_TAB_PARAMS': {
      const { tabParams } = state;
      const { tab } = action.payload;
      tabParams[tab] = action.payload.params;

      return {
        ...state,
        tabParams,
      };
    }

    case 'UNSET_PIPELINE_ROUNDS':
    case 'FETCH_PIPELINE_ROUNDS_PENDING': {
      return {
        ...state,
        rounds: [],
      };
    }

    case 'FETCH_PIPELINE_ROUNDS_FULFILLED': {
      return {
        ...state,
        rounds: action.payload,
      };
    }

    default:
      break;
  }

  return state;
}
