import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';

import FormBase from '../../../core/form/components/FormBase';
import { controls } from '../forms/paddocks';
import Mapster from '../../projects/containers/Mapster';
import GenericLsv from '../../../core/form/components/GenericLsv';
import moment from 'moment';

class PropertyPaddocks extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      controls: controls,
      mapSources: [],
      map: null,
      propertyPaddocks: [
        {
          id: 1,
          open_date: moment('2002-12-01').format(),
          close_date: moment('2004-01-01').format(),
          name: 'South-East Paddock',
          area_ha: '120',
        },
        {
          id: 2,
          open_date: moment('2004-01-02').format(),
          close_date: null,
          name: 'South-East Paddock',
          area_ha: '165',
        },
        {
          id: 3,
          open_date: moment('2002-12-01').format(),
          close_date: null,
          name: 'South-West Paddock',
          area_ha: '120',
        },
      ],
    };
  }

  componentDidUpdate() {
    let { map } = this.state;

    // Force a map redraw
    if (map) map.resize();
  }

  render() {
    const { controls, mapSources, propertyPaddocks } = this.state;

    let lngLat = [150.7333, -23.1333];
    let iconName = 'draw-polygon';
    let emptyCaption = 'No Paddocks found';

    return (
      <Row className="h-100 p-0">
        <Col sm={5} className="m-0 p-2">
          <div className="d-flex justify-content-between border-bottom border-lightgray pb-2">
            <h5>Paddock History</h5>
            <Button size="sm" color="success">
              Add Paddock
            </Button>
          </div>
          <GenericLsv
            controls={controls}
            iconName={iconName}
            emptyCaption={emptyCaption}
            onClick={() => {}}
            rows={propertyPaddocks}
          />
        </Col>
        <Col sm={7} className="border border-secondary m-0 mb-2 p-2 h-10">
          <Mapster
            handleSourceVisibility={() => {}}
            expandMap={false}
            lngLatCenter={lngLat}
            toggleMap={() => {}}
            mapSources={mapSources}
            onMapLoad={this.onMapLoad}
          />
        </Col>
      </Row>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    manage: store.manage,
    portal: store.farmportrait_portal,
  };
};

export default connect(mapStoreToProps)(PropertyPaddocks);
