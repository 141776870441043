import { get, post, put, remove } from '../../../../../lib/genericAction';
import { apiRosterShifts } from 'jsx/constants/api';

const name = 'ROSTER_SHIFT';

export function fetchShifts(params) {
  return (dispatch) => get(dispatch, `FETCH_${name}S`, `${apiRosterShifts}`, params);
}

export function fetchShift(params) {
  return (dispatch) => get(dispatch, `FETCH_${name}`, `${apiRosterShifts}/${params.id}`, params);
}

export function createShift(data) {
  return (dispatch) => post(dispatch, `CREATE_${name}`, `${apiRosterShifts}`, data);
}

export function updateShift(data) {
  return (dispatch) => put(dispatch, `UPDATE_${name}`, `${apiRosterShifts}/${data.id}`, data);
}

export function removeShift(id) {
  return (dispatch) => remove(dispatch, `REMOVE_${name}`, `${apiRosterShifts}/${id}`);
}
