import React from 'react';
import { connect } from 'react-redux';
import FieldUploadSitesLsv from '../components/FieldUploadSitesLsv';
import FormBase from '../../../core/form/components/FormBase';
import PageTitle from '../../../core/form/components/PageTitle';
import Mapster from '../../projects/containers/Mapster.jsx';
import FieldUploadSitesToolbar from '../components/FieldUploadSitesToolbar';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import {
  buildFeatureCollection,
  buildFeature,
  buildGeometryFromCoordinates,
  drawLinesBetweenPoints,
} from '../../projects/lib/mapster.js';

import { Card, Alert } from 'reactstrap';

import { fetchFieldUpload } from '../actions';
import { downloadFieldUploadSites } from '../actions/downloads';
import BreadcrumbsRoute from 'jsx/components/core/form/components/BreadcrumbsRoute';

class FieldUpload extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      responseMessage: null,
      mapSources: [],
      max_distance: 1,
    };

    this.resetGeometry = this.resetGeometry.bind(this);
    this.zoomSite = this.zoomSite.bind(this);
    this.handleSourceVisibility = this.handleSourceVisibility.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.setMaxDistance = this.setMaxDistance.bind(this);
  }

  async componentDidMount() {
    const id = this.props.match.params.id;

    this.resetGeometry();

    await this.props.dispatch(fetchFieldUpload(id, this.state.max_distance));
  }

  componentDidUpdate(prevProps, prevState) {
    const { mapSources } = this.state;

    if (mapSources.length > 0) {
      this.setStrataMaps();
      this.setSiteMaps();
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_FIELDUPLOAD' });
    this.props.dispatch({ type: 'SET_ZOOM_COORDS', payload: [] });
  }

  resetGeometry() {
    const mapSources = [
      {
        id: 'stratas',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Stratas',
        count: 0,
        style: 'Polygon',
        onClick: () => {},
      },
      {
        id: 'site_lines',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Distance Lines',
        count: 0,
        style: 'Line',
        onClick: () => {},
      },
      {
        id: 'sites',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Sites',
        count: 0,
        style: 'Point',
        onClick: () => {},
      },
      {
        id: 'site_labels',
        visible: true,
        source: { type: 'FeatureCollection', features: [] },
        load: true,
        title: 'Distance Labels',
        count: 0,
        style: 'PointLabel',
        onClick: () => {},
      },
    ];
    this.setState({ mapSources });
  }

  setStrataMaps() {
    const stratas = this.props.sites.fieldupload.stratas;
    const mapSources = this.state.mapSources;
    const featureColour = this.props.mapster.colours.strata;

    if (stratas && stratas.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'stratas');
      const featureCollection = buildFeatureCollection();

      stratas.forEach((strata) => {
        if (strata.geom) {
          const feature = buildFeature(strata.geom, {
            title: `${strata.name}`,
            colour: featureColour.fill,
            outline: featureColour.outline,
          });
          featureCollection.features.push(feature);
        }
      });

      if (mapSources[idx].load && featureCollection.features.length > 0) {
        if (mapSources[idx].visible === true) {
          mapSources[idx].source = featureCollection;
        } else {
          mapSources[idx].source = buildFeatureCollection();
        }

        mapSources[idx].load = false;
        mapSources[idx].count = featureCollection.features.length;
        this.setState({ mapSources });
      }
    }
  }

  setSiteMaps() {
    const sites = this.props.sites.fieldupload.sites;

    const { mapSources, max_distance } = this.state;

    if (sites && sites.length > 0) {
      const idx = mapSources.findIndex((source) => source.id === 'sites');
      const labelIdx = mapSources.findIndex((source) => source.id === 'site_labels');
      const lineIdx = mapSources.findIndex((source) => source.id === 'site_lines');

      const featureCollection = buildFeatureCollection();

      sites.forEach((site) => {
        // Get Site Locations
        const target = [site.target_longitude, site.target_latitude];
        const actual = [site.actual_longitude, site.actual_latitude];

        // Draw lines between Actual and Target
        const line_geom = drawLinesBetweenPoints([target, actual]);
        const lineFeature = buildFeature(line_geom, { outline: 'red' });
        featureCollection.features.push(lineFeature);

        // Target sites
        const target_geom = buildGeometryFromCoordinates(target);
        const targetFeatureColour = this.props.mapster.colours.site_target;

        const targetFeature = buildFeature(target_geom, {
          id: site.id,
          core_id: site.core_id,
          title: `SITE ${site.name}`,
          colour: targetFeatureColour.fill,
          outline: targetFeatureColour.outline,
        });
        featureCollection.features.push(targetFeature);

        // Actual Sites
        const actual_geom = buildGeometryFromCoordinates(actual);

        let actualFeatureColour = this.props.mapster.colours.site_actual;
        let actualLabelColour = this.props.mapster.colours.site_actual.label;
        if (site.distance > max_distance) {
          actualFeatureColour = this.props.mapster.colours.site_actual_outside;
          actualLabelColour = this.props.mapster.colours.site_actual_outside;
        }

        const actualFeature = buildFeature(actual_geom, {
          id: site.id,
          core_id: site.core_id,
          title: `SITE ${site.name}`,
          labelColour: actualLabelColour.label,
          labelCaption: site.distance.toFixed(1),
          colour: actualFeatureColour.fill,
          outline: actualFeatureColour.outline,
        });
        featureCollection.features.push(actualFeature);
      });

      if (
        mapSources[idx].load &&
        mapSources[idx].source.features.length !== sites.length &&
        featureCollection.features.length > 0
      ) {
        mapSources[lineIdx].load = false;
        mapSources[lineIdx].source = featureCollection;
        mapSources[lineIdx].count = featureCollection.features.length;

        mapSources[idx].load = false;
        mapSources[idx].source = featureCollection;
        mapSources[idx].count = featureCollection.features.length;

        mapSources[labelIdx].load = false;
        mapSources[labelIdx].source = featureCollection;
        mapSources[labelIdx].count = featureCollection.features.length;

        this.setState({ mapSources });
      }
    }
  }

  onCancel() {
    this.props.dispatch({ type: 'UNSET_SITES' });
    this.setState({
      site: {},
      formDisabled: true,
      searchValue: '',
    });

    this.searchRef.current.focus();
  }

  handleSourceVisibility(idx) {
    const mapSources = this.state.mapSources;

    mapSources[idx].visible = !mapSources[idx].visible;
    mapSources[idx].load = true;

    this.setState({
      mapSources,
    });
  }

  zoomSite(row) {
    this.props.dispatch({
      type: 'SET_ZOOM_COORDS',
      payload: [[row.actual_longitude, row.actual_latitude]],
    });
  }

  async handleDownload(onProgressChange) {
    // Download Field Upload Sites
    const id = this.props.match.params.id;
    return await this.props.dispatch(downloadFieldUploadSites(id, onProgressChange));
  }

  setMaxDistance(event) {
    const { mapSources } = this.state;

    const idx = mapSources.findIndex((source) => source.id === 'sites');
    mapSources[idx].load = true;

    this.setState({
      max_distance: parseFloat(event.target.value),
      mapSources,
    });
  }

  render() {
    const { mapSources, max_distance } = this.state;
    const { sites } = this.props.sites.fieldupload;

    const iconName = 'upload';
    const lngLat = [150.7333, -23.1333];
    const alertMessage = `Showing any Actual location with a distance greater than ${max_distance}m from its Target location or outside its nominated Strata as RED`;
    const title = `Field Instrument Upload for "${this.props.sites.fieldupload.name}"`;

    return (
      <div className="p-3">
        <PageTitle title={title} iconName={iconName} />
        <BreadcrumbsRoute match={this.props.match} />
        <div>
          <Card className="border rounded mt-2" style={{ minHeight: '500px' }}>
            <Mapster
              handleSourceVisibility={this.handleSourceVisibility}
              expandMap={false}
              lngLatCenter={lngLat}
              toggleMap={() => {}}
              mapSources={mapSources}
            />
          </Card>
        </div>
        <Alert color="primary">{alertMessage}</Alert>
        <FieldUploadSitesToolbar
          handleDownload={this.handleDownload}
          max_distance={max_distance}
          setMaxDistance={this.setMaxDistance}
        />
        <FieldUploadSitesLsv max_distance={max_distance} rows={sites} clickRow={this.zoomSite} />
      </div>
    );
  }
}

const mapStoreToProps = ({ sites, realm, mapster }) => ({
  sites,
  realm,
  mapster,
});

export default connect(mapStoreToProps)(withContainerError(FieldUpload));
