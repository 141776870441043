import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { initControls, saveFormControls } from '../../../core/form/lib/validateForm';
import { controls } from '../forms/instrumentUpload.js';
import FormInput from '../../../core/form/components/FormInput';
import FormSearch from '../../../core/form/components/FormSearch';
import FormInputFile from '../../../core/form/components/FormInputFile';
import FormBase from '../../../core/form/components/FormBase';

import Icon from 'jsx/components/core/icons/Icon';

import { fetchFieldUploads, createFieldUpload, fetchFieldInstruments } from '../actions';
import { fetchProperties } from '../../../modules/projects/actions/properties';

class FieldUploadModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Field Instrument Upload',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls,
      instruments: [],
      propertySelected: false,
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleComboChange = this.handleComboChange.bind(this);
    this.onPropertySelect = this.onPropertySelect.bind(this);
    this.renderPropertySearch = this.renderPropertySearch.bind(this);
  }

  onPropertySelect({ name, property_id }) {
    const { controls } = this.state;

    controls.property_id.value = property_id;
    controls.property_search.value = name;

    this.setState({ propertySelected: true, controls });
  }

  async componentDidMount() {
    const { controls } = this.state;

    this.props.dispatch(fetchProperties({}));

    // Select first item in instrument list
    const fieldInstruments = await this.props.dispatch(fetchFieldInstruments());
    if (fieldInstruments) {
      controls.instrument_id.options = fieldInstruments.map((instrument) => {
        return {
          id: instrument.id,
          name: instrument.name,
        };
      });
    }

    controls.instrument_id.options.push({
      id: '',
      name: 'Select Instrument',
    });

    this.setState({ controls, instruments: fieldInstruments });
  }

  async componentDidUpdate() {
    const { isOpen, id } = this.state;
    let { isNew, title, controls } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = `New Instrument Upload`;

      controls = initControls(controls);
      controls.name.value = '';
      controls.instrument_id.value = '';

      // Ensure that file is not set from previous upload
      delete controls.logfile.file;

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        controls,
        data: [],
        setModal: this.props.setModal,
      });
    }
  }

  async onSave() {
    const { data, controls } = this.state;

    const formData = saveFormControls(controls, data);

    delete data.id;
    const success = await this.props.dispatch(createFieldUpload(formData));

    if (success) {
      this.props.dispatch(fetchFieldUploads());
      this.state.setModal(false);
      this.setState({ isOpen: false });
    } else {
      this.setState({ data: [] });
    }
  }

  handleComboChange(event) {
    const { instruments, controls } = this.state;

    // Set acceptable upload file type
    const instrument = instruments.find(({ id }) => id === event.target.value);

    // If not found, the user has unselected the instrument type.
    if (instrument) controls.logfile.accept = `.${instrument.allowed_extension}`;
    else delete controls.logfile.accept;

    this.setState({ controls });

    this.handleChange(event);
  }

  onChange(event) {
    const { controls } = this.state;

    this.handleChange(event);

    controls.property_id.value = null;

    this.setState({
      controls,
      propertySelected: false,
    });
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  renderPropertySearch(controls) {
    const { properties } = this.props.properties;

    // Load property_search options
    const property_search_options = [];
    properties?.rows?.forEach((property) => {
      property_search_options.push({
        property_id: property.id,
        name: `${property.name} (${property.idx})`,
      });
    });

    controls.property_search.options = property_search_options;

    return controls;
  }

  render() {
    const { isOpen, title, propertySelected } = this.state;
    let { controls } = this.state;

    const { responseMessage } = this.props.sites;

    controls = this.renderPropertySearch(controls);

    const iconName = 'truck-pickup';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.name} />
            <FormInput handleChange={this.handleComboChange} control={controls.instrument_id} />
            <FormSearch
              handleChange={this.onChange}
              handleSelect={this.onPropertySelect}
              minimumLength={controls.property_search.validationRules?.minLength ?? 3}
              control={controls.property_search}
              selected={propertySelected}
            />
            <FormInput control={controls.property_id} />
            <FormInputFile handleChange={this.handleChange} control={controls.logfile} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
            <Button
              disabled={!this.checkAccess('fieldTripUpdate')}
              size="sm"
              color="success"
              onClick={this.onSave}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ properties, realm, sites }) => {
  return {
    properties,
    realm,
    sites,
  };
};

export default connect(mapStoreToProps)(FieldUploadModal);
