import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  enterprise_id: {
    ...controlDefaults,
    caption: 'Enterprise',
    fieldName: 'enterprise_id',
    type: 'select',
    name: 'enterprise_id',
    validationRules: { isRequired: true },
  },
  property_id: {
    ...controlDefaults,
    caption: 'Property',
    fieldName: 'property_id',
    type: 'select',
    name: 'property_id',
    validationRules: { isRequired: true },
  },
  distribution_amount: {
    caption: '$ Distribution',
    fieldName: 'distribution_amount',
    type: 'number',
    name: 'distribution_amount',
    validationRules: { isRequired: true },
  },
  distribution_pcnt: {
    caption: '% Distribution',
    fieldName: 'distribution_pcnt',
    name: 'distribution_pcnt',
    type: 'number',
    validationRules: { isRequired: true },
    disabled: true,
  },
};
