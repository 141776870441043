import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiJobs } from '../../../../constants/api';
import { createParamsFromFilters } from 'jsx/lib/actions';

export function fetchJobs(params, filters) {
  const paramsWithFilters = { ...params, ...createParamsFromFilters(filters) };

  return async (dispatch) => await get(dispatch, `FETCH_JOBS`, `${apiJobs}`, paramsWithFilters);
}

export function fetchJob(id) {
  return async (dispatch) => await get(dispatch, `FETCH_JOB`, `${apiJobs}/${id}`);
}

export function createJob(data) {
  return async (dispatch) => await post(dispatch, `CREATE_JOB`, `${apiJobs}`, data);
}

export function updateJob(data) {
  return async (dispatch) => await put(dispatch, `UPDATE_JOB`, `${apiJobs}/${data.id}`, data);
}

export function removeJob(id) {
  return async (dispatch) => await remove(dispatch, `REMOVE_JOB`, `${apiJobs}/${id}`);
}
