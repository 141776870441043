import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiLabours } from '../../../../constants/api';
import { isEmpty } from 'lodash';

export function fetchFTESummary(params) {
  return async (dispatch) => await get(dispatch, `FETCH_FTE_SUMMARY`, `${apiLabours}/fte`, params);
}

export function fetchLabours(params, filters) {
  return async (dispatch) => {
    // Store params in reducer
    if (!isEmpty(params)) dispatch({ type: 'SET_LABOUR_PARAMS', payload: params });

    // Handle filters
    if (filters) params = addFiltersToParams(params, filters);

    return get(dispatch, `FETCH_LABOURS`, `${apiLabours}`, params);
  };
}

export function fetchLabour(id) {
  return async (dispatch) => await get(dispatch, 'FETCH_LABOUR', `${apiLabours}/${id}`);
}

export function fetchLabourflows(params, filters) {
  return async (dispatch) => {
    // Store params in reducer
    if (!isEmpty(params)) dispatch({ type: 'SET_LABOUR_PARAMS', payload: params });

    // Handle filters
    if (filters) params = addFiltersToParams(params, filters);

    return get(dispatch, 'FETCH_LABOURFLOWS', `${apiLabours}/flows`, params);
  };
}

export function createLabour(data) {
  return async (dispatch) => await post(dispatch, `CREATE_LABOUR`, `${apiLabours}`, data);
}

export function updateLabour(data) {
  return async (dispatch) => await put(dispatch, 'UPDATE_LABOUR', `${apiLabours}/${data.id}`, data);
}

export function removeLabour(id) {
  return async (dispatch) => await remove(dispatch, 'REMOVE_LABOUR', `${apiLabours}/${id}`);
}

export function setLabourGroupFilters(params, filters) {
  return async (dispatch) => {
    // Store filters in reducer
    dispatch({ type: 'SET_LABOUR_FILTERS', payload: filters });

    // Retrieve and store filtered assets
    dispatch(fetchLabourflows(params, filters));
  };
}

export function setLabourCategoryFilters(params, filters) {
  return async (dispatch) => {
    // Store filters in reducer
    dispatch({ type: 'SET_LABOUR_FILTERS', payload: filters });

    // Retrieve and store filtered assets
    dispatch(fetchLabourflows(params, filters));
    dispatch(fetchLabours(params, filters));
  };
}

const addFiltersToParams = (params, filters) => {
  Object.keys(filters).forEach((key) => (params[`filter_${key}`] = filters[key]));
  return params;
};
