import React from 'react';
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Row,
  Col
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { initControls, saveControls } from "../../../core/form/lib/validateForm";
import FormBase from "../../../core/form/components/FormBase";
import FormInput from "../../../core/form/components/FormInput";

import { controls } from '../forms/labRegisterBulk';

import {
  fetchLabRegister,
  bulkUpdateLabRegisterRecords,
} from '../actions';

class LabRegisterBulkUpdateModal extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      isValid: true,
      isOpen: false,
      title: 'Update Lab Register Records',
      data: {},
      setModal: () => { },
      controls: controls
    })

    this.onSave = this.onSave.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen } = this.state;
    let { controls } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      controls = initControls(controls)
      controls.records.value = this.props.recordSelection;

      this.setState({
        isOpen: this.props.isOpen,
        setModal: this.props.setModal,
        controls
      })
    }
  }

  async onSave() {
    let { data } = this.state;
    const { controls } = this.state;
    const {  params, filters } = this.props.lab;

    data = saveControls(controls, data)
    for (const key in data) {
      if (data[key] === null) delete data[key]
    }    

    const success = await this.props.dispatch(bulkUpdateLabRegisterRecords(data));

    if (success) {
      this.props.dispatch(fetchLabRegister(params, filters));
      this.state.setModal(false)
      this.setState({ isOpen: false });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false });
  }

  render() {
    const {
      controls,
      isOpen,
      title
    } = this.state;

    const {
      responseMessage
    } = this.props.lab;

    const iconName = 'circle-notch';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white" >
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && (
            <div className="text-center text-danger">{responseMessage}</div>
          )}
          <Form>
            <Row className="bg-light m-0 pb-2">
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.job_no} />
              </Col>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.purchase_order_no} />
              </Col>
            </Row>
            <Row xs="2" className="bg-light m-0 pb-2">
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.transport_booking_no} />
              </Col>
              <Col>
                <FormInput handleChange={this.handleChange} control={controls.lab_quote_no} />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>Cancel</Button>
            <Button disabled={!this.checkAccess('labRecordCreate')} size="sm" color="success" onClick={this.onSave}>Save</Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    lab: store.lab,
    realm: store.realm
  }
}

export default connect(mapStoreToProps)(LabRegisterBulkUpdateModal);
