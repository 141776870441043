import { noop } from 'lodash';
import Listview from '../../../../core/form/components/Listview';

const BenchmarksLsv = ({ rows, onClick = noop }) => {
  const renderRows = (headers, record) => {
    const extraClass = '';

    const tableTd = headers.map(({ caption, field, classes, sortColumn, styles }, index) => {
      switch (field) {
        case 'action': {
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>
              {record[field] && record[field].name ? record[field].name : record[field]}
            </td>
          );
        }
        case 'period': {
          return ( <td key={index} className={classes} >{record?.period_rolling_description}</td>);
        }
        case 'advisor': {
          return ( <td key={index} className={classes} >{record?.property?.advisor?.name}</td>);
        }
        case 'property': {
          return ( <td key={index} className={classes} >{record?.property?.name}</td>);
        }
        case 'metric': {
          return ( <td key={index} className={classes} >{record.metric.key}</td>);
        }
        default:
          return (
            <td key={index} className={classes + extraClass}  style={styles}>{record[field]}</td>
          );
      }
    });

    return tableTd;
  };

  const iconName = 'leaf';
  const emptyCaption = 'No Benchmarks found';

  const headers = [
    { caption: 'Benchmark Period', field: 'period', classes: 'text-left', styles: {width: '150px'} },
    { caption: 'Property', field: 'property', classes: 'text-left' },
    { caption: 'Advisor', field: 'advisor', classes: 'text-left', styles: {width: '150px'} },
    { caption: 'Updated', field: 'updated', classes: 'text-left', styles: {width: '250px'} }
  ];

  const tableHeadTh = headers.map(({ caption, classes, styles }, index) => (
    <th key={index} className={classes} style={styles}>
      {caption}
    </th>
  ));

  let tableBodyTr = [];
  let finalRows = rows;
  if (rows?.rows) finalRows = rows.rows;

  const haveRows = finalRows && finalRows.length > 0;
  if (haveRows) {
    tableBodyTr = finalRows.map((row) => (
      <tr key={row.id} onClick={() => onClick(row)} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={finalRows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default BenchmarksLsv;
