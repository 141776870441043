import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import DirectUpload from "../../../core/form/components/DirectUpload";
import SingleSearch from "../../../core/form/components/SingleSearch";

import {
  Button
} from 'reactstrap';

const CoreArchivesToolbar = (props) => {
  let {
    handleUpload,
    refresh,
    onSearch,
    handleSearchChange,
    coreId
  } = props;

  return (
    <div className="bg-light p-2 d-flex justify-content-end">
      <SingleSearch handleSearchChange={handleSearchChange} onSearch={onSearch} placeholder="Search Core ID" value={coreId} />

      <DirectUpload
        classNames="ml-2 mr-2"
        handleUpload={(event, onProgressChange) => handleUpload(event, onProgressChange)}
        caption="Upload Sample Attributes"
        size="sm"
        disabled={false}
        showLabel={false}
        width={135}
      />

      <Button color='success' onClick={refresh} className="mr-2" size="sm">
        <Icon name="rotate" />
      </Button>
    </div>
  );
}

export default CoreArchivesToolbar;
