import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';

import FormBase from '../../../core/form/components/FormBase';
import { controls } from '../forms/propertyRainfalls';
import Mapster from '../../projects/containers/Mapster';
import PropertyRainfallsModal from './PropertyRainfallsModal';

import { fetchPropertyRainfalls } from '../actions/propertyRainfalls';
import GenericLsv from '../../../core/form/components/GenericLsv';
import { cloneDeep } from 'lodash';

class PropertyRainfalls extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      data: {},
      controls: cloneDeep(controls),
      mapSources: [],
      map: null,
    };

    this.setModal = this.setModal.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }

  componentDidUpdate() {
    const { map } = this.state;
    // Force a map redraw
    if (map) map.resize();
  }

  goBack() {
    this.props.history.goBack();
  }

  onRefresh() {
    this.props.dispatch(fetchPropertyRainfalls({ property_id: this.props.property.id }));
  }

  async setModal(isModalOpen, id = null) {
    const newState = {
      id,
      isModalOpen,
    };

    this.setState(newState);
  }

  render() {
    const { isModalOpen, id, mapSources } = this.state;
    const { property_rainfalls } = this.props.property_rainfalls;
    const { property } = this.props;

    const emptyCaption = 'No rainfall records found';
    const lngLat = [150.7333, -23.1333];
    const iconName = 'draw-polygon';

    return (
      <Row className="h-100">
        <Col sm={7} className="m-0 p-2">
          <div className="d-flex justify-content-between border-bottom border-lightgray pb-2">
            <h5>Rainfall</h5>
            <Button
              onClick={() => {
                this.setModal(true);
              }}
              size="sm"
              color="success"
            >
              Add Rainfall
            </Button>
          </div>
          <PropertyRainfallsModal
            onRefresh={this.onRefresh}
            setModal={this.setModal}
            id={id}
            isOpen={isModalOpen}
            isNew={id === null}
            property={property}
          />
          <GenericLsv
            controls={controls}
            iconName={iconName}
            emptyCaption={emptyCaption}
            onClick={(id) => {
              this.setModal(true, id);
            }}
            rows={property_rainfalls}
          />
        </Col>
        <Col sm={5} className="border border-secondary m-0 mb-2 p-2 h-10">
          <Mapster
            handleSourceVisibility={() => {}}
            expandMap={false}
            lngLatCenter={lngLat}
            toggleMap={() => {}}
            mapSources={mapSources}
            onMapLoad={this.onMapLoad}
          />
        </Col>
      </Row>
    );
  }
}

const mapStoreToProps = ({ manage, farmportrait_portal, property_rainfalls }) => {
  return {
    manage,
    farmportrait_portal,
    property_rainfalls,
  };
};

export default connect(mapStoreToProps)(PropertyRainfalls);
