import React from 'react';
import { noop, isEmpty } from 'lodash';
import { Card, Collapse, Button } from 'reactstrap';
import { kebabCase } from 'lodash';

import Icon from 'jsx/components/core/icons/Icon';
import InlineCell from './InlineCell';

export const CollapsibleHeader = ({
  id,
  propertyId = '',
  toggle = noop,
  label = '',
  isOpen = false,
  summary,
  getData = noop,
}) => {
  const onClick = () => {
    toggle(id);
    if (!isOpen) {
      getData(propertyId);
    }
  };
  return (
    <Button className="d-block px-0 w-100 border border-grey mb-2" color="light" onClick={onClick}>
      <Icon className="mr-2" name={isOpen ? 'circle-minus' : 'circle-plus'} />
      <div style={{ width: '95%' }} className="d-inline-flex justify-content-between">
        <span>{label}</span>
        {!isEmpty(summary) && summary.length && (
          <div>
            {summary.map(({ label, value, isValid, message }) => (
              <InlineCell
                wrapperClassName="d-inline-block"
                key={`cell-${kebabCase(label)}`}
                label={label}
                value={value}
                isValid={isValid}
                message={message}
                labelClassName="ml-2"
                valueClassName="border border-grey"
              />
            ))}
          </div>
        )}
        {!isEmpty(summary) && !summary?.length && (
          <InlineCell
            label={summary.label}
            value={summary.value}
            message={summary.message}
            valueClassName="border border-grey"
          />
        )}
      </div>
    </Button>
  );
};

export const CollapsibleBody = ({ isOpen, children = null }) => {
  return (
    <Collapse isOpen={isOpen}>
      {children && <Card className="p-2 border-grey border">{children}</Card>}
    </Collapse>
  );
};
