import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Nav,
  TabContent,
  TabPane,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../core/form/components/FormInput';
import FormBase from '../../core/form/components/FormBase';
import FormTab from '../../core/form/components/FormTab';
import { initControls, saveControls, updateControls } from '../../core/form/lib/validateForm';
import { controls } from '../forms/org';

import OrgTestCodesLsv from './OrgTestCodesLsv';
import AttributesLsv from './AttributesLsv';

import {
  createOrg,
  updateOrg,
  removeOrg,
  fetchOrg,
  fetchOrgTestCodes,
  fetchDocumentDefs,
  fetchTestCodes,
  removeOrgTestCode,
} from '../actions';

class OrgModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isOpen: false,
      title: 'Organisation',
      data: {},
      id: null,
      isNew: false,
      setModal: null,
      controls: controls,
      activeTab: null,
      categoryTags: [],
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onTestCodeRemove = this.onTestCodeRemove.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen } = this.state;
    let { id, isNew, title, controls, data } = this.state;

    const { orgs } = this.props.manage;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      title = `New Organisation`;
      controls = initControls(controls);

      if (this.props.id) {
        isNew = false;
        ({ id } = this.props);
        title = `Edit Organisation`;

        data = orgs.find((org) => org.id === id);
        this.props.dispatch(fetchOrg(id)).then((result) => {
          controls = updateControls(controls, result);

          const resultCategories = result.org_categories.map((category) => {
            return {
              value: category.category_tag.id,
              label: category.category_tag.name,
            };
          });

          this.setState({
            controls,
            categoryTags: resultCategories,
          });
        });
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        controls,
      });

      this.toggleTab('1', 'profile');
    }
  }

  toggleTab(tab, tag) {
    const { id } = this.state;
    switch (tag) {
      case 'lab_codes':
        this.props.dispatch(fetchOrgTestCodes(id, { type: 'lab' }));
        this.props.dispatch(fetchDocumentDefs({ group_key: 'lab_register' }));
        this.props.dispatch(fetchTestCodes({ type: 'lab' }));
        break;
      default:
        break;
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  onSelectChange(selectValue) {
    const updatedCategoryTags = selectValue;
    this.setState({ categoryTags: updatedCategoryTags });
  }

  async onSave() {
    const { controls, isNew } = this.state;
    let { data } = this.state;
    const { categoryTags } = this.state;

    data = saveControls(controls, data);
    data.attributes = controls.attributes.value;
    data.categoryTags = categoryTags;

    let success;
    if (isNew) {
      delete data.id;
      success = await this.props.dispatch(createOrg(data));
    } else {
      success = await this.props.dispatch(updateOrg(data));
    }

    if (success) {
      this.props.onRefresh();
      this.state.setModal(false);
      this.setState({ isOpen: false, categoryTags: [] });
    }
  }

  onCancel() {
    this.state.setModal(false);
    this.setState({ isOpen: false, categoryTags: [] });
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(
      `Removing ${data.name.toUpperCase()} org permanently. Continue?`
    );
    if (confirmed) {
      const success = await this.props.dispatch(removeOrg(data.id));
      if (success) {
        this.props.onRefresh();
        this.state.setModal(false);
        this.setState({ isOpen: false, categoryTags: [] });
      }
    }
  }

  async onTestCodeRemove(test_code_id) {
    const success = await this.props.dispatch(removeOrgTestCode(test_code_id));
    if (success) {
      this.props.dispatch(fetchOrgTestCodes(this.state.id, { type: 'lab' }));
    }
  }

  render() {
    const { controls, isOpen, title, isNew, id, categoryTags } = this.state;

    const { responseMessage, orgCodes } = this.props.manage;
    const { category_tags } = this.props.attributes;

    const categoryOptions = category_tags.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    const iconName = 'industry';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Nav tabs className="mt-2">
            <FormTab
              tabTag="profile"
              caption="Profile"
              tabId="1"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
            />
            <FormTab
              tabTag="lab_codes"
              caption="Lab Codes"
              tabId="2"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
              disabled={false}
            />
            <FormTab
              tabTag="custom_attributes"
              caption="Custom Attributes"
              tabId="3"
              activeTab={this.state.activeTab}
              toggle={this.toggleTab}
              disabled={false}
            />
          </Nav>
          <TabContent activeTab={this.state.activeTab} className="border-primary">
            <TabPane tabId="1" className="mb-2 p-1">
              <Form>
                <FormInput handleChange={this.handleChange} control={controls.name} />
                <Label className="mt-2">Category Tags</Label>
                <Select
                  isMulti
                  options={categoryOptions}
                  value={categoryTags}
                  onChange={this.onSelectChange}
                />
              </Form>
            </TabPane>

            <TabPane tabId="2" className="mb-2 p-1">
              <OrgTestCodesLsv
                checkAccess={this.checkAccess}
                orgId={id}
                rows={orgCodes || []}
                type={'lab'}
                onRemove={this.onTestCodeRemove}
              />
            </TabPane>
            <TabPane tabId="3" className="mb-2 p-1">
              <AttributesLsv rows={controls.attributes?.value || []} ignoreFields={['owner']} />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
          <div>
            <Button size="sm" className="mr-2" color="light" onClick={this.onCancel}>
              Cancel
            </Button>
            <Button size="sm" color="success" onClick={this.onSave}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ manage, realm, attributes }) => {
  return {
    manage,
    realm,
    attributes,
  };
};

export default connect(mapStoreToProps)(OrgModal);
