import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import DirectDownload from '../../../core/form/components/DirectDownload';
import TimekeeperSummaryMenu from './TimekeeperSummaryMenu';

const TimekeeperWeekBar = (props) => {
  const {
    teamSummaryExports,
    showExportButtons,
    showActions,
    setWeek,
    week,
    reload,
    handleDownloadCsv,
    handleDownloadApproverReport,
    handleTimesheetAction,
    showAddButton,
    onAdd,
    children,
    teamSummaryExportEnabled,
  } = props;

  let headerText = null;
  if (week.from_date) {
    const dateFormat = 'dddd, DD MMMM YYYY';
    const fromDateText = moment(week.from_date).format(dateFormat);
    const toDateText = moment(week.to_date).format(dateFormat);
    const weekNo = moment(week.from_date).isoWeek();

    headerText = `Week ${weekNo} - ${fromDateText} to ${toDateText}`;
  }

  return (
    <div className="bg-light border-bottom border-primary p-2 d-flex justify-content-between text-white">
      <span>
        <Button size="sm" color="primary" onClick={() => setWeek(-1)}>
          <Icon name="arrow-left" className="mr-1" />
          Prev Week
        </Button>
      </span>
      <p className="text-secondary m-0 mt-1">{headerText}</p>
      <span>
        {showAddButton && (
          <Button size="sm" color="success" className="mr-2" onClick={() => onAdd()}>
            <Icon name="plus" />
          </Button>
        )}
        {children}
        <Button size="sm" color="success" className="mr-2" onClick={() => reload()}>
          <Icon name="rotate" />
        </Button>

        {(showExportButtons || teamSummaryExports) && (
          <DirectDownload
            classNames="ml-2 mr-2 d-flex float-left"
            handleDownload={(onProgressChange) => handleDownloadCsv(onProgressChange)}
            caption="Export"
            showLabel={false}
            buttonType="success"
            size="sm"
            width={80}
            disabled={!teamSummaryExportEnabled}
          />
        )}
        {showExportButtons && (
          <DirectDownload
            classNames="mr-2 d-flex float-left"
            handleDownload={(onProgressChange) => handleDownloadApproverReport(onProgressChange)}
            caption="Download Approval Report"
            showLabel={false}
            buttonType="success"
            size="sm"
            width={80}
          />
        )}
        {showActions && (
          <TimekeeperSummaryMenu
            classNames="mr-2 d-flex float-left"
            handleTimesheetsApprove={handleTimesheetAction}
          />
        )}
        <Button size="sm" color="primary" onClick={() => setWeek(+1)}>
          Next Week
          <Icon name="arrow-right" className="ml-1" />
        </Button>
      </span>
    </div>
  );
};

export default TimekeeperWeekBar;
