import React from 'react';
import { Alert } from 'reactstrap';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

import Listview from 'jsx/components/core/form/components/Listview';
import { projectRegisterInstanceName } from '../../actions/project_register';

const ProjectRegisterLsv = ({ projects }) => {
  const history = useHistory();

  if (!projects?.length) {
    return (
      <Alert color="info" className="mt-2">
        No Project Registers can be found
      </Alert>
    );
  }

  const headers = [
    { caption: 'Project Name', fieldName: 'project.name', style: { minWidth: '320px' } },
    {
      caption: 'Property Name',
      fieldName: 'project.properties.name',
      style: { minWidth: '320px' },
    },
    { caption: 'Property Code', fieldName: 'project.properties.idx', style: { minWidth: '150px' } },
    {
      caption: 'Planning Officer',
      fieldName: 'project.properties.register.planning_officer.name',
      style: { minWidth: '150px' },
    },
    {
      caption: 'Delivery Officer',
      fieldName: 'project.properties.register.delivery_officer.name',
      style: { minWidth: '150px' },
    },
    {
      caption: 'Carbon Farming Advisor',
      fieldName: 'project.properties.register.cfa.name',
      style: { minWidth: '180px' },
    },
    {
      caption: 'Current Period',
      fieldName: 'project.properties.current_period.type.name',
      style: { minWidth: '150px' },
    },
    {
      caption: 'State',
      fieldName: 'project.properties.register.state',
      style: { minWidth: '150px' },
    },
    {
      caption: 'NRM Region',
      fieldName: 'project.properties.register.nrm_region',
      style: { minWidth: '150px' },
    },
    {
      caption: 'Local Government Area',
      fieldName: 'project.properties.register.lga',
      style: { minWidth: '150px' },
    },
    {
      caption: 'Nearest Town',
      fieldName: 'project.properties.register.nearest_town',
      style: { minWidth: '120px' },
    },
    {
      caption: 'Property Address',
      fieldName: 'project.properties.address',
      style: { minWidth: '290px' },
    },
    { caption: 'ERF Project Number', fieldName: 'erf_project_no', style: { minWidth: '200px' } },
    {
      type: 'date',
      caption: 'Application Lodged Date',
      fieldName: 'project.properties.register.lodged_date',
      style: { minWidth: '200px' },
    },
    {
      type: 'date',
      caption: 'Declaration Date',
      fieldName: 'project.properties.register.declaration_date',
      style: { minWidth: '200px' },
    },
    {
      caption: 'Methodology (fieldName)',
      fieldName: 'orig_methodology',
      style: { minWidth: '200px' },
    },
    {
      caption: 'Methodology (transferred)',
      fieldName: 'tx_methodology',
      style: { minWidth: '200px' },
    },
    { caption: 'Permanence Period', fieldName: 'permanence_period', style: { minWidth: '150px' } },
    {
      caption: 'Intended Abatement Volume',
      fieldName: 'project.properties.register.abatement_volume',
      style: { minWidth: '200px' },
    },
    { caption: 'Participant', fieldName: 'participant' },
    {
      caption: 'Declaration on gaining EIHC',
      fieldName: 'eihc_required',
      style: { minWidth: '190px' },
    },
    {
      caption: 'Primary Client',
      fieldName: 'project.properties.primary_client.company_name',
      style: { minWidth: '150px' },
    },
    {
      caption: 'Primary Client Contact',
      fieldName: 'project.properties.primary_client.primary_client_contact',
      style: { minWidth: '150px' },
    },
    {
      caption: 'Primary Client Phone',
      fieldName: 'project.properties.primary_client.phone',
      style: { minWidth: '150px' },
    },
    {
      caption: 'Primary Client Email',
      fieldName: 'project.properties.primary_client.email',
      style: { minWidth: '150px' },
    },
    { caption: 'ANREU Account Holder', fieldName: 'anreu_holder', style: { minWidth: '190px' } },
    { caption: 'ANREU', fieldName: 'anreu_ref', style: { minWidth: '150px' } },
    { caption: 'ANREU Primary Rep', fieldName: 'anreu_primary_rep', style: { minWidth: '190px' } },
    {
      caption: 'ANREU Secondary Rep',
      fieldName: 'anreu_secondary_rep',
      style: { minWidth: '150px' },
    },
    {
      caption: 'ANREU Tertiary Rep',
      fieldName: 'anreu_tertiary_rep',
      style: { minWidth: '150px' },
    },
    { caption: 'ANREU Client Number', fieldName: 'anreu_client_no', style: { minWidth: '150px' } },
  ];

  const tableHeadTh = headers.map(({ caption, fieldName, style = {} }) => (
    <th key={fieldName} style={style}>
      {caption}
    </th>
  ));

  const tableBodyTr = projects.map((project, projectIndex) => {
    const cells = headers.map(({ fieldName, type }, index) => {
      let data = get(project, fieldName) || '';
      if (type === 'date') {
        data = data ? moment(data).format('Do MMM YYYY') : '';
      }
      return <td key={`body-${project.project_id}-${index}`}>{data}</td>;
    });

    return (
      <tr
        key={`${project.project_id}-${projectIndex}`}
        onClick={() => history.push(`/home/projects/${project.project_id}/details`)}
        style={{ cursor: 'pointer' }}
      >
        {cells}
      </tr>
    );
  });

  return (
    <div className="my-0 mx-auto" style={{ width: 'calc(100% - 10px)' }}>
      <Listview
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        rows={projects}
        isHorizontalScrollEnabled
        isConfigurable
        rememberUserState
        instanceName={projectRegisterInstanceName}
        headers={headers}
      />
    </div>
  );
};

export default ProjectRegisterLsv;
