import { initControls, updateControlOptions, validateForm } from './validateForm';

/**
 * For listviews, the data will be in the form of an array of objects (as opposed to an object).
 * We want to be able to store details about each individual object of each array item (eg. error messages),
 * so here we create an array of controls, using a 'blueprint' control (taken from the form control
 * definition), that correlates to the data (ie. using the same indexes as the data)
 *
 * @param data
 * @param blueprintControls
 * @return updatedControls[]
 *
 * Returns an array of controls
 */
export const initListviewControls = (data, blueprintControls) => {
  let updatedControls = [];
  if (data.length > 0) {
    updatedControls = data.map((row) => {
      const rowControls = { ...blueprintControls };
      const keys = Object.keys(row);
      keys.forEach((key) => {
        const control = rowControls[key];
        control.valid = false;
        control.touched = false;
        control.message = null;
        control.value = null;
        if (rowControls[key].defaultValue !== undefined)
          rowControls[key].value = rowControls[key].defaultValue;
      });
      return rowControls;
    });
  } else {
    updatedControls.push(initControls(blueprintControls));
  }

  return updatedControls;
};

/**
 * Similar to the validateFormFieldControls.  Listviews will have multiple 'rows'
 * of data, so we match the row/field of data within the corresponding control array
 * (which will have been created in initListviewControls()), and sets whether the control
 * item is valid/invalid, and any error messages.
 *
 * @param data[]
 * @param controls[]
 * @returns { isListValid, updatedListControls }
 */
export const validateListviewControls = (data, controls) => {
  let isListValid = true;
  const updatedListControls = [...controls];

  data.forEach((row, rowIdx) => {
    const keys = Object.keys(row);
    keys.forEach((fieldIdx) => {
      const control = { ...controls[rowIdx][fieldIdx] };
      let rules;

      // Set form fields to valid if there are no validation rules
      if (!control.validationRules) {
        control.valid = true;
      } else {
        rules = control.validationRules;
        // Validate form field value
        const validation = validateForm(data[rowIdx][fieldIdx], rules);
        control.valid = validation.isValid;
        control.message = validation.message;
      }
      if (controls[rowIdx][fieldIdx]) {
        updatedListControls[rowIdx][fieldIdx] = control;
      }
      // Validate control
      if (control.valid === false) isListValid = false;
    });
  });
  return { isListValid, updatedListControls };
};

/**
 * Caters for adding the list of options for the key for each row of the listview.
 *
 * @param controls[]
 * @param key
 * @param data[]
 * @returns controls[]
 */
export const updateListviewControlOptions = (controls, key, data) => {
  controls.forEach(
    (control, index) => (controls[index] = updateControlOptions(control, key, data))
  );
  return controls;
};
