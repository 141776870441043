export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    error: null,
    responseMessage: null,
    subscriptions: [],
    currentUser: {
      _id: null,
    },
  },
  action
) {
  switch (action.type) {
    case 'FETCH_CURRENTUSER_PENDING': {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case 'FETCH_CURRENTUSER_FULFILLED': {
      const currentUser = action.payload;
      const subscriptions = currentUser?.user_orgs[0].org.org_subscriptions;

      return {
        ...state,
        fetching: false,
        fetched: true,
        currentUser,
        subscriptions
      };
    }
    case 'FETCH_CURRENTUSER_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }
    case 'FETCH_CURRENTINVITATIONS_PENDING': {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case 'FETCH_CURRENTINVITATIONS_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        invitations: action.payload,
      };
    }
    case 'FETCH_CURRENTINVITATIONS_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }
    case 'UPDATE_CURRENTUSER_PENDING': {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case 'UPDATE_CURRENTUSER_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }
    case 'UPDATE_CURRENTUSER_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }

    default:
      break;
  }
  return state;
}
