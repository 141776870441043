
import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    topChartSettings: {},
    propertyFields: [
      {dataKey: 'client_entered', name: 'Your $/kg', chart: 'Bar', colour: 'blue', index: 1}, // property index enabling defaults for multiple properties
      {dataKey: 'benchmark_values', name: 'Standardised $/kg', chart: 'Bar', colour: 'orange', index: 1},
      // {dataKey: 'avg3yr', name: '3yr Avg', chart: 'None', colour: 'purple', sequence: 1}
    ],
    groupFields: [
      {dataKey: 'benchmark_1yr_average', name: 'Benchmark 1yr Avg', chart: 'Line', colour: 'green', index: 1}, // group index enabling defaults for multiple groups
      {dataKey: 'benchmark_top20', name: 'Benchmark Top 20', chart: 'Line', colour: 'red', index: 1},
      {dataKey: 'minMaxGrpAvg',  name: 'Benchmark 1yr Grp Band', chart: 'Band', colour: '#cccccc', index: 1},
      // {dataKey: 'benchmark_3yr_avg',name: 'Benchmark 3yr Avg', chart: 'None', colour: 'purple', sequence: 1}
    ],
    templateSettings: {
      leftAxis: {
        title: null,
        metric_id: null,
        yAxis: 'left',
        properties: [],
        groups: []
      },
      rightAxis: {
        title: null,
        metric_id: null,
        yAxis: 'right',
        properties: [],
        groups: []
      }
    }
  },
  action,
) {
  const types = ['SCHEDULE', 'WAREHOUSE', 'BATCH', 'LOG', 'TREND'];
  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };
  
  switch (action.type) {
    case 'SET_TREND_PROPERTY_FIELD_DEFAULT_FULFILLED': {
      return {
        ...state,
        propertyFields: action.payload,
        fetching: false
      };
    }
    case 'SET_TREND_GROUP_FIELD_DEFAULT_FULFILLED': {
      return {
        ...state,
        groupFields: action.payload,
        fetching: false
      };
    }
    case 'FETCH_WAREHOUSE_DISTINCT_PROPERTIES_FULFILLED': {
      return {
        ...state,
        distinct_properties: action.payload,
        fetching: false
      };
    }

    default:
      break;
  }

  return updatedState;
}
