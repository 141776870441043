import axios from 'axios';
import {
    apiReconTrips,
} from '../../../../constants/api.js';
import errorResponse from '../../../../lib/errorResponse.js';

export function fetchReconTrips() {
  return function(dispatch) {
    dispatch({type: 'FETCH_RECON_TRIPS_PENDING'});
    
    axios({
      method: 'GET',
      url: apiReconTrips,
    })
    .then((response) => {
      dispatch({type: 'FETCH_RECON_TRIPS_FULFILLED', payload: response.data.result});
    })
    .catch((err) => {
      errorResponse(dispatch, err, 'FETCH_RECON_TRIPS_REJECTED');
    })
  }
}

export function fetchSites(trip) {
  return function(dispatch) {
    dispatch({type: 'FETCH_RECON_SITES_PENDING'});
    dispatch({type: 'FETCH_RECON_SITES_FULFILLED', payload: trip});
  }
}
