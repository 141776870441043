import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from 'reactstrap';
import SingleSearch from "../../../core/form/components/SingleSearch";

const SitesToolbar = (props) => {
  const {
    statuses,
    updateSelected,
    refresh,
    modifyDisabled,
    handleSearchChange,
    onSearch,
    searchValue,
    sites,
    coreSelection,
    checkAccess
  } = props;

  const statusItems = statuses.map((status, index) => {
    if (status.id) {
      return (<DropdownItem key={index} onClick={() => updateSelected('status_id', status.id)}>{status.name}</DropdownItem>)
    }
    return false;
  })

  let sitesCount = null;
  if (sites) {
    sitesCount = `Showing ${sites.length} sites`
    if (coreSelection && coreSelection.length > 0) {
      sitesCount = `${sitesCount} ( ${coreSelection.length} selected )`
    }
  }

  return (
    <React.Fragment>
      <div className="bg-light p-2 d-flex justify-content-between rounded mb-1">
        <div className="mt-1 text-secondary">{sitesCount}</div>
        <div className="d-flex justify-content-end">
          <SingleSearch handleSearchChange={handleSearchChange} onSearch={onSearch} placeholder="Search single Core ID" value={searchValue} />

          <Button color='success' onClick={refresh} className="mr-2" size="sm">
            <Icon name="rotate" />
          </Button>

          <UncontrolledButtonDropdown>
            <DropdownToggle disabled={modifyDisabled || !checkAccess('siteUpdate')} caret color='primary' size="sm">Modify Selected </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header className="bg-light">Set Status</DropdownItem>
              {statusItems}

            </DropdownMenu>
          </UncontrolledButtonDropdown>

          <Button id="toggler" size="sm" color="warning" className="text-white ml-2">Filters</Button>
        </div>

      </div>
    </React.Fragment>
  );
}

export default SitesToolbar;
