import React from 'react';
import {
  Input
} from 'reactstrap';

const ConfigControlColumns = (props) => {
  const {
    dataset,
    name,
    onUpdateWidgetDataset,
    availableDatasets
  } = props;

  // Find current dataset in availableDatasets
  let currentDataset = availableDatasets.find(availableDataset => availableDataset.id === dataset.sourceId);
  if (!currentDataset && availableDatasets.length > 0) {
    currentDataset = availableDatasets[0];
  }

  // Load options from currentDataset
  let columnOptions = <option />
  if (currentDataset) {
    columnOptions = currentDataset.columns.map((column, index) => {
      let caption = column.name;
      if (column.caption) {
        caption = column.caption;
      }
      return (
        <option key={index} value={column.name}>{caption}</option>
      )
    })
  }

  // Column selection
  let selectedColumn = '';
  if (dataset && dataset.selected) {
    selectedColumn = dataset.selected.name;
  }

  return (
    <React.Fragment>
      <Input
        onChange={(e) => onUpdateWidgetDataset(e, dataset)}
        name={name}
        value={selectedColumn}
        type="select"
        bsSize="sm"
        style={{width: '100%'}}
      >
        {columnOptions}
      </Input>
    </React.Fragment>
  );
}

export default ConfigControlColumns;
