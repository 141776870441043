import SingleSearch from 'jsx/components/core/form/components/SingleSearch';
import { noop } from 'lodash';
import { Button, Label } from 'reactstrap';

const SearchToolbar = ({
  rows = [],
  searchValue = '',
  onChange = noop,
  handleSearchChange = noop,
  setModal = noop,
  countLabel = '',
  buttonLabel = 'Add New',
  addNewButton = true,
}) => {

  const count = `Showing ${rows.rows.length} of ${rows.count} ${countLabel}`;
  return (
    <div className="m-1 d-flex justify-content-end">
      <Label className="m-1 mr-2 text-secondary">{count}</Label>
      <SingleSearch
        handleSearchChange={handleSearchChange}
        placeholder="Filter by keyword"
        value={searchValue}
      />
      <Button onClick={onChange} size="sm" color="success" className="mr-2">
        Go
      </Button>
      {addNewButton && (
        <Button onClick={() => setModal(true)} size="sm" color="success">
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

export default SearchToolbar;
