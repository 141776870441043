import React from 'react';
import { connect } from "react-redux";

import LabRegisterRecordSamplesLsv from '../../lab/components/LabRegisterRecordSamplesLsv';

class AreaRoundPanelLab extends React.Component {
  constructor(props) {
    super(props)

    this.state = ({
      data: {},
    })
  }

  render() {
    const {
      samples
    } = this.props.lab;

    return (
      <div>
        <LabRegisterRecordSamplesLsv
          currentRecord={this.props.round}
          rows={samples}
        />
      </div>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    lab: store.lab,
  }
}

export default connect(mapStoreToProps)(AreaRoundPanelLab);
