import { controlDefaults } from '../../../core/form/lib/validateForm';

export const breakControls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    type: 'text',
    name: 'name',
    caption: 'Break Name',
    allowNull: false,
    validationRules: {
      isRequired: true,
    },
    showInListview: true,
    showInEditor: true,
  },
  from_datetime: {
    ...controlDefaults,
    name: 'from_datetime',
    type: 'date',
    caption: 'Time of Break',
    allowNull: false,
    validationRules: {
      isRequired: true,
      greaterThanZero: true,
    },
    showInListview: true,
    showInEditor: true,
    classes: 'text-left',
  },
  from_time: {
    ...controlDefaults,
    name: 'from_time',
    type: 'text',
    caption: 'Time of Break',
    allowNull: false,
    validationRules: {
      isRequired: true,
      greaterThanZero: true,
    },
    showInListview: true,
    showInEditor: true,
    classes: 'text-left',
  },
  hrs: {
    ...controlDefaults,
    name: 'hrs',
    type: 'number',
    caption: 'Duration (Hours)',
    allowNull: false,
    defaultValue: 0,
    validationRules: {
      isRequired: true,
      greaterThanZero: true,
    },
    showInListview: true,
    showInEditor: true,
    classes: 'text-left',
  }
};
