import moment from 'moment';
import { cloneDeep, isEmpty, isNaN, isNil, omit } from 'lodash';
import { getCopyValueEntry } from './copyValue';
import { evalFormula } from './formula';
import { isFieldShown } from './showRules';
import { compareFields } from './compareFields';

/**
 * minLength Val
 *
 * @param  value
 * @param  minLength
 * @return
 */
const minLengthValidator = (value, minLength) => {
  let target = value;
  if (typeof value === 'number') {
    target = value.toString();
  }

  return target?.length >= minLength;
};

const greaterThanValidator = (value, targetValue) => value > targetValue;

/**
 * len Val
 *
 * @param  value
 * @param  len
 * @return
 */
const lengthValidator = (value, length) => value?.length === length;

/**
 * Check to confirm that field is required
 *
 * @param  value
 * @return
 */
const requiredValidator = (value) => value !== null && String(value).trim() !== '';

const alphaNumericValidator = (value) => value?.match && value.match(/^[a-zA-Z0-9]*$/);

/**
 * Email validation
 *
 * @param value
 * @return
 */
const emailValidator = (value) => {
  const regex =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(value).toLowerCase());
};

const fileValidator = (rawValue, type) => {
  const value = rawValue.toLowerCase();
  switch (type) {
    case 'xls':
      return value.endsWith(type) || value.endsWith('xlsx');
    case 'jpg':
      return value.endsWith(type) || value.endsWith('jpe') || value.endsWith('jpeg');
    case 'json':
      return value.endsWith(type) || value.endsWith('geojson');
    case 'geotiff':
      return value.endsWith('tif') || value.endsWith('tiff');
    case 'pdf':
    case 'csv':
    case 'png':
    case 'msg':
    case 'txt':
    default:
      return value.endsWith(type);
  }
};

const validators = {
  len: {
    validator: lengthValidator,
    message: (ruleValue) => `Field needs to be exactly ${ruleValue} characters.`,
  },
  minLength: {
    validator: minLengthValidator,
    message: (ruleValue) => `Field needs to have at least ${ruleValue} characters.`,
  },
  greaterThanZero: {
    validator: (inputValue, ruleValue) =>
      ruleValue === false || greaterThanValidator(inputValue, 0),
    message: () => 'Must be greater than zero.',
  },
  isArray: {
    validator: Array.isArray,
    message: () => 'This field must be an array.',
  },
  isRequired: {
    validator: (inputValue, ruleValue) => {
      if (ruleValue === false) {
        return true;
      }

      switch (typeof inputValue) {
        case 'boolean': {
          return true;
        }
        case 'object': {
          const arrayMinLength = 1;
          return Array.isArray(inputValue)
            ? minLengthValidator(inputValue, arrayMinLength)
            : requiredValidator(inputValue);
        }
        default: {
          return requiredValidator(inputValue);
        }
      }
    },
    message: () => 'This field is required.',
  },
  isEmail: {
    validator: emailValidator,
    message: () => 'Not a valid email yet.',
  },
  fileType: {
    validator: fileValidator,
    message: () => 'Not a valid file type.',
  },
  isAlphaNumeric: {
    validator: alphaNumericValidator,
    message: () => 'This field must contain only letters and numbers.',
  },
  isNumeric: {
    // check if value is a number
    validator: (inputValue) => inputValue?.match && inputValue.match(/^\d+$/) !== null,
    message: () => 'This field must contain only numbers.',
  },
};

export const validateForm = (input, rules) => {
  if (!rules) return { isValid: true, message: '' };
  const messages = Object.entries(rules)
    .map(([ruleName, ruleValue]) => {
      const validationForRule = validators[ruleName];

      if (!validationForRule) {
        // The rule is unknown, so just assume it's valid
        return null;
      }
      const inputPassesRule = validationForRule.validator(input, ruleValue);
      if (!inputPassesRule) {
        // Return a valid message for this rule failure
        return validationForRule.message(ruleValue);
      }

      // It must be valid, return null
      return null;
    })
    .filter((result) => !isNil(result));

  return { isValid: messages.length === 0, message: messages.join(' ') };
};

const getConflictedMessage = () => 'Conflicting fields. Remove 1 to continue..';

const setConflicted = (control) => ({
  ...control,
  message: getConflictedMessage(),
  warning: true,
});

const setResolved = (control) => ({
  ...control,
  message: '',
  valid: true,
  warning: false,
});

const applyCopyValueRules = (controls) => {
  Object.keys(controls).forEach((key) => {
    const control = controls[key];
    if (control?.copyValue) {
      const entry = getCopyValueEntry(control, controls);
      if (entry) controls[entry.key].value = entry.value;
    }
  });
  return controls;
};

export const validateFormControls = (controls) => {
  const keys = Object.keys(controls);
  let isValid = true;
  keys.forEach((key) => {
    if (controls[key].valid === false) {
      isValid = false;
    }
  });
  return isValid;
};

export const initControls = (controls) => {
  const keys = Object.keys(controls);
  keys.forEach((key) => {
    const control = controls[key];
    control.valid = false;
    control.touched = false;
    control.message = null;
    control.value = control.type === 'checkbox' ? false : null;
    if (controls[key].defaultValue !== undefined) controls[key].value = controls[key].defaultValue;
  });
  return controls;
};

export const updateControlOptions = (controls, key, data) => {
  const control = controls[key];

  if (!control) return controls;
  if (!data || data?.length === 0) {
    control.options = [];
    return controls;
  }
  const options = [];
  const emptyValue = control.placeholder !== '' ? control.placeholder : '-';
  const selectIdx = data.findIndex((row) => row.id === '');

  if (
    (control.validationRules.isRequired !== true || control.includeEmptyOption === true) &&
    data[0].id !== null
  ) {
    if (selectIdx === -1) options.push({ id: null, name: emptyValue });
  } else if (control.value === null || data.findIndex((row) => row.id === control.value) === -1) {
    // If we have a 'Select to Filter' option, default to this
    if (selectIdx > -1) {
      control.value = data[selectIdx].id;
    } else {
      control.value = data[0].id;
    }
  }

  control.options = options.concat(data);

  if (control.value === emptyValue) {
    control.value = null;
  }

  return controls;
};

export const controlDefaults = {
  value: '',
  placeholder: '',
  valid: false,
  message: null,
  name: '',
  type: 'text',
  caption: '',
  validationRules: {},
  touched: false,
  loading: false,
};

/**
 * Update controls with form data
 *
 * @param controls
 * @param data
 * @return controls
 */
export const updateControls = (controls, data) => {
  Object.keys(controls).forEach((key) => {
    const fieldName = key;

    if (!controls[key].persist || data[fieldName]) {
      // Populate control value with given data and set to valid
      controls[key].value = data[fieldName];
      controls[key].valid = true;

      // Overwrite if unit type exists
      if (data[fieldName]?.unit_type) {
        controls[key].value = data[fieldName].value;
        controls[key].unitType = data[fieldName].unit_type;
      }
    }

    if (controls[key].type === 'file') {
      const uploadExistFieldName = `${fieldName.substring(7)}_exists`;
      controls[key].fileUploaded = data[uploadExistFieldName];
    }

    if (controls[key].type === 'file' && key === 'document') {
      controls[key].fileUploaded = data.document ? true : false;
    }

    if (controls[key].options?.length > 0) {
      controls = updateControlOptions(controls, key, controls[key].options);
    }

    if (controls[key].formula && !controls[key].value) {
      try {
        controls[key].value = evalFormula(controls, controls[key].formula, data);
      } catch (error) {
        console.warn(`Formula error (${key}): ${error}`);
      }
    }
  });

  /** Apply copyValue rules. This is run after the first key iteration due the need of extra controls being created due to evalFormula */
  controls = applyCopyValueRules(controls);

  return controls;
};

export const updateControlFormulas = (controls) => {
  const updatedControls = cloneDeep(controls);
  Object.entries(updatedControls)
    .sort(([, a], [, b]) => a.formula_priority - b.formula_priority)
    .forEach(([key]) => {
      let control = cloneDeep(updatedControls[key]);
      const controlIntegerValue = parseInt(control.value);

      // Set control back to an automatic field
      if (isEmpty(control.value) && (controlIntegerValue === 0 || isNaN(controlIntegerValue))) {
        control.user_entry = false;
        control = setResolved(control);

        // Check and reset tracked fields
        if (control.tracking) {
          control.tracking.forEach((field) => {
            const target = cloneDeep(updatedControls[field]);

            if (target && target?.user_entry) {
              updatedControls[field] = setResolved(target);

              // Recalculate current field after resolving target field.
              control.value = evalFormula(updatedControls, control?.formula);
            }
          });
        }
      }

      // Do not calculate current field
      if (control.user_entry) {
        updatedControls[key] = control;
        return;
      }

      /**
       * Do not calculate current field if empty.
       * Toggle reset property to true so calculations run if other form fields are being changed.
       */
      if (!control?.reset && control.value === '') {
        updatedControls[key] = { ...control, reset: true };
        return;
      }

      if (control.formula) {
        try {
          const calculatedValue = evalFormula(updatedControls, control.formula);
          updatedControls[key] = { ...control, value: calculatedValue };
        } catch (error) {
          console.warn(`Formula error (${key}): ${error}`);
        }
      }
    });

  return updatedControls;
};

/**
 * Push control values to formdata object
 *
 * @param controls
 * @param data
 * @return formData
 */
export const saveFormControls = (controls, data) => {
  const formData = new FormData();

  const keys = Object.keys(controls);
  keys.forEach((key) => {
    const fieldName = key;

    // Save changed values only
    if (data[fieldName] !== controls[key].value || fieldName === 'id') {
      data[fieldName] = controls[key].value;

      switch (controls[key].type) {
        case 'file': {
          data[fieldName] = controls[key].file;
          break;
        }
        case 'number': {
          data[fieldName] = controls[key].value === '' ? null : controls[key].value;
          break;
        }
        case 'date':
        case 'datetime':
        case 'datetime-local': {
          if (
            (typeof data[fieldName] === 'string' &&
              data[fieldName]?.toLowerCase() === 'invalid date') ||
            data[fieldName] === '' ||
            !data[fieldName]
          ) {
            data[fieldName] = null;
          } else {
            data[fieldName] = moment(data[fieldName]).format('YYYY-MM-DDTHH:mmZZ');
          }
          break;
        }
        case 'select': {
          if (data[fieldName] === '-') data[fieldName] = null;
          break;
        }
        default: {
          break;
        }
      }

      formData.append(fieldName, data[fieldName]);
    }
  });
  return formData;
};

/**
 * Push control values to data object
 *
 * @param controls
 * @param data
 * @return data
 */

export const saveControls = (controls, data) => {
  const keys = Object.keys(controls);
  const formData = {};

  keys.forEach((key) => {
    const fieldName = key;

    // Clear any values that aren't being shown
    if (!isFieldShown(controls, controls[key])) {
      controls[key].value = null;
    }

    if (data[fieldName] !== controls[key].value || fieldName === 'id') {
      formData[fieldName] = controls[key].value;
      if (controls[key].unitType) {
        formData[fieldName] = { unit_type: controls[key].unitType, value: controls[key].value };
      }
      switch (controls[key].type) {
        case 'json': {
          formData[fieldName] = JSON.stringify(formData[fieldName]);
          break;
        }
        case 'number': {
          formData[fieldName] = formData[fieldName] === '' ? null : formData[fieldName];
          break;
        }
        case 'date': {
          formData[fieldName] = (formData[fieldName] ? moment(formData[fieldName]).format('YYYY-MM-DD') : null);
          break;
        }
        case 'datetime':
        case 'datetime-local': {
          if (
            (typeof formData[fieldName] === 'string' &&
              formData[fieldName]?.toLowerCase() === 'invalid date') ||
            formData[fieldName] === '' ||
            !formData[fieldName]
          ) {
            formData[fieldName] = null;
          } else {
            if (formData[fieldName].length === 11) {
              const updatedDate = `${formData[fieldName].slice(0, 10)}T${moment().format(
                'HH:mmZZ',
              )}`;
              formData[fieldName] = moment(updatedDate).format('YYYY-MM-DDTHH:mmZZ');
            } else {
              formData[fieldName] = moment(formData[fieldName]).format('YYYY-MM-DDTHH:mmZZ');
            }
          }
          break;
        }
        case 'select': {
          if (formData[fieldName] === '-') formData[fieldName] = null;
          break;
        }
        default: {
          break;
        }
      }
    }
  });

  return formData;
};

/**
 * Validate and update controls from form changes
 *
 * @param changes
 * @param controls
 * @return { isValid, updatedControls }
 */
export const validateFormFieldControls = (changes, controls) => {
  const updatedControls = { ...controls };
  let isValid = true;
  const keys = Object.keys(updatedControls);
  keys.forEach((key) => {
    const control = { ...updatedControls[key] };
    const disableOverride = control?.validationOptions?.override?.disabled;
    if (control.disabled && !disableOverride) return false;

    const rules = control.validationRules;
    if (!rules) return false;

    // Set form fields to valid if there are no validation rules
    if (Object.entries(rules).length === 0) {
      control.valid = true;
    }

    if (isFieldShown(controls, control)) {
      // Validate form field value
      const validation = validateForm(control.value, rules);
      control.valid = validation.isValid;
      control.message = validation.message;

      if (control.warning) {
        control.valid = false;
        control.message = getConflictedMessage();
      }
    } else {
      control.valid = true;
    }

    // Validate relationships between fields
    for (const rule in rules) {
      switch (rule) {
        // Check if corresponding field value provided
        case 'correspondingFields':
        case 'compareFields': {
          // Compare fields values
          const { isValid, message } = compareFields(controls, control);
          if (!isValid) {
            control.valid = false;
            control.message = message;
          }
          break;
        }

        default: {
          break;
        }
      }
    }

    updatedControls[key] = control;

    // Validate control
    if (control.valid === false) isValid = false;
  });
  return { isValid, updatedControls };
};

export const removeIsRequired = (controls, fields) =>
  fields.forEach(
    (field) =>
      (controls[field].validationRules = omit(controls[field].validationRules, ['isRequired'])),
  );

export const setIsRequired = (controls, fields) =>
  fields.forEach(
    (field) =>
      (controls[field].validationRules = {
        ...controls[field].validationRules,
        isRequired: true,
      }),
  );

/**
 * Return invalidated control with optional custom message.
 * @param {Object} control target control
 * @param {String} custom_message
 * @param {Boolean} override  override with custom message.
 * @returns {Object}
 */
export const setInvalidField = ({ control, custom_message = null, override = false }) => {
  if (custom_message && override) return { ...control, message: custom_message, valid: false };

  const { value, validationRules } = control;
  let { message } = validateForm(value, validationRules);

  if (custom_message) message += custom_message;

  return { ...control, message, valid: false };
};

export const setValidField = (control) => ({ ...control, message: null, valid: true });

/**
 * Change Validation and update
 *
 * @param event
 * @param updatedControls
 * @return updatedControls
 */
export const validateChange = (event, updatedControls) => {
  const name =
    event.target.name.split('.').length === 2 ? event.target.name.split('.')[0] : event.target.name;
  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

  let updatedFormElement = {
    ...updatedControls[name],
  };

  if (event.target.type === 'file' && event.target.files.length > 0) {
    const [file] = event.target.files;
    updatedFormElement.file = file;
  }

  updatedFormElement.value = value;

  const emptyValue = updatedFormElement.placeholder !== '' ? updatedFormElement.placeholder : '-';
  const selectTypes = ['select', 'reactselect'];
  if (
    selectTypes.includes(updatedFormElement.type) &&
    (updatedFormElement.includeEmptyOption === true ||
      updatedFormElement.validationRules.isRequired === false) &&
    (value === emptyValue || value === '')
  ) {
    updatedFormElement.value = null;
  }

  updatedFormElement.touched = true;
  updatedFormElement.user_entry = true; // Used in updateControlFormulas
  updatedFormElement.reset = false; // Used in updateControlFormulas

  const validation = validateForm(value, updatedFormElement.validationRules);
  updatedFormElement.valid = validation.isValid;
  updatedFormElement.message = validation.message;

  // Set message if calculated fields have been overriden by user entry
  if (updatedFormElement.valid && updatedFormElement?.tracking) {
    updatedFormElement.tracking.forEach((field) => {
      const target = updatedControls[field];

      // Validate and check target is a user entered field
      if (target && target?.user_entry) {
        // Set conflicted fields
        updatedFormElement = setConflicted(updatedFormElement);
        updatedControls[field] = setConflicted(target);
      }
    });
  }

  updatedControls[name] = updatedFormElement;

  // Update any formula based controls
  updatedControls = updateControlFormulas(updatedControls);

  // Check and apply copy value rules
  updatedControls = applyCopyValueRules(updatedControls);

  return updatedControls;
};
