import React from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

const CroppingEnterpriseMenu = ({
  setAdjustmentModal = noop,
  setDirectCostModal = noop,
  setGrazingModal = noop,
  setTransferModal = noop,
  setTransactionModal = noop,
}) => (
  <UncontrolledButtonDropdown>
    <DropdownToggle caret color="success" size="sm">
      <Icon name="plus" className="mr-1" />
      Action{' '}
    </DropdownToggle>
    <DropdownMenu right>
      <DropdownItem toggle onClick={() => setTransactionModal(true)}>
        Add Sale/Purchase
      </DropdownItem>
      <DropdownItem toggle onClick={() => setAdjustmentModal(true)}>
        Add Adjustment
      </DropdownItem>
      <DropdownItem toggle onClick={() => setAdjustmentModal(true, null, true)}>
        Add Opening Value
      </DropdownItem>
      <DropdownItem toggle onClick={() => setTransferModal(true)}>
        Add Transfer
      </DropdownItem>
      <DropdownItem toggle onClick={() => setGrazingModal(true)}>
        Add Grazing
      </DropdownItem>
      <DropdownItem toggle onClick={() => setDirectCostModal(true)}>
        Add Direct Cost
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledButtonDropdown>
);

export default CroppingEnterpriseMenu;
