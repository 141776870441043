import axios from 'axios';
import errorResponse from '../../../../lib/errorResponse.js';
import { post, upload, get } from '../../../../lib/genericAction';

import { apiProperties, apiRounds, apiStratas } from '../../../../constants/api.js';

export function fetchAreas(property_id, showMap = true) {
  const dispatch_prefix = 'FETCH_AREAS';
  return function (dispatch) {
    dispatch({ type: 'FETCH_AREAS_PENDING' });

    const params = {};
    if (!showMap) {
      params['exclude'] = 'geom';
    }

    axios({
      method: 'GET',
      url: `${apiProperties}/${property_id}/areas`,
      params,
    })
      .then((response) => {
        dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: response.data.result });
      })
      .catch((err) => {
        errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      });
  };
}

export function fetchAreasSync(property_id) {
  const dispatch_prefix = 'FETCH_AREAS';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });
    try {
      const response = await axios({
        method: 'GET',
        url: `${apiProperties}/${property_id}/areas`,
      });

      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: response.data.result });
      return response.data.result;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return false;
    }
  };
}

// Synchronous round fetch
export function fetchAreaRound(id) {
  const dispatch_prefix = 'FETCH_AREA_ROUND';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });
    try {
      const success = await axios({
        method: 'GET',
        url: `${apiRounds}/${id}`,
      });

      dispatch({ type: `${dispatch_prefix}_FULFILLED` });
      return success.data.result;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return false;
    }
  };
}

export function fetchAreaRoundStatus(id) {
  const dispatch_prefix = 'FETCH_AREA_ROUND_STATUS';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });
    try {
      const success = await axios({
        method: 'GET',
        url: `${apiRounds}/${id}/status`,
      });

      dispatch({ type: `${dispatch_prefix}_FULFILLED` });
      return success.data.result;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return false;
    }
  };
}

// Synchronous round fetch
export function fetchAllCeas(params, inBackground = false) {
  const dispatch_prefix = 'FETCH_ALLCEAS';
  return function (dispatch) {
    dispatch({ type: `${dispatch_prefix}_PENDING`, meta: { inBackground } });

    if (!params) params = {};

    params = {
      ...params,
      allceas: true,
    };

    axios({
      method: 'GET',
      url: `${apiRounds}`,
      params,
    })
      .then((response) => {
        dispatch({
          type: `${dispatch_prefix}_FULFILLED`,
          payload: response.data.result,
          meta: { inBackground },
        });
      })
      .catch((err) => {
        errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      });
  };
}

export function setAllCeasParams(params) {
  return function (dispatch) {
    dispatch({ type: 'SET_ALLCEAS_PARAMS', payload: params });
    dispatch(fetchAllCeas(params));
  };
}

export function createAreaRound(data) {
  const dispatch_prefix = 'CREATE_AREA_ROUND';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });
    try {
      const success = await axios({
        method: 'POST',
        url: `${apiRounds}`,
        data,
      });

      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: success.data.result });
      return true;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return false;
    }
  };
}

export function updateAreaRound(data) {
  const dispatch_prefix = 'UPDATE_AREA_ROUND';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });

    try {
      const success = await axios({
        method: 'PUT',
        url: `${apiRounds}/${data.id}`,
        data: data,
      });
      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: success });
      return true;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return false;
    }
  };
}

export function fetchAreaRoundStratas(round_id, showMap = true) {
  const dispatch_prefix = 'FETCH_AREA_ROUND_STRATAS';
  return async (dispatch) => {
    const params = {};
    if (!showMap) {
      params['exclude'] = 'geom';
    }

    return await get(dispatch, dispatch_prefix, `${apiRounds}/${round_id}/stratas`, params);
  };
}

// Syncronous fetch
export function fetchAreaRoundStratasSync(round_id) {
  const dispatch_prefix = 'FETCH_AREA_ROUND_STRATAS_SYNC';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });

    try {
      const response = await axios({
        method: 'GET',
        url: `${apiRounds}/${round_id}/stratas`,
      });

      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: response.data.result });
      return response.data.result;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return err.response;
    }
  };
}

export function fetchAreaRoundStrata(id) {
  const dispatch_prefix = 'FETCH_AREA_ROUND_STRATA';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });

    try {
      const success = await axios({
        method: 'GET',
        url: `${apiStratas}/${id}`,
      });

      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: success.data.result });
      return success.data.result;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return err.response;
    }
  };
}

export function updateAreaRoundStrata(data) {
  const dispatch_prefix = 'UPDATE_AREA_ROUND_STRATA';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });

    try {
      const success = await axios({
        method: 'PUT',
        url: `${apiStratas}/${data.id}`,
        data: data,
      });
      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: success });
      return true;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return false;
    }
  };
}

export function removeAreaRoundStrata(id) {
  const dispatch_prefix = 'REMOVE_AREA_ROUND_STRATA';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });

    try {
      await axios({
        method: 'DELETE',
        url: `${apiStratas}/${id}`,
      });
      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: { id: id } });
      return true;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return false;
    }
  };
}

export function fetchAreaUploadStatus(property_id) {
  const dispatch_prefix = 'FETCH_AREA_UPLOADS';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });
    try {
      const success = await axios({
        method: 'GET',
        url: `${apiProperties}/${property_id}/areas/upload_status`,
      });

      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: success.data.result });
      return success.data.result;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return err.response;
    }
  };
}

export function uploadAreas(formData, onProgressChange) {
  const dispatch_prefix = 'UPLOAD_AREAS';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });
    try {
      const success = await axios.put(
        `${apiProperties}/${formData.get('property_id')}/areas/upload`,
        formData,
        {
          onUploadProgress: (progressEvent) =>
            onProgressChange(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
        },
      );

      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: success.data.result });
      return true;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return false;
    }
  };
}

export function uploadStratas(formData) {
  const dispatch_prefix = 'UPLOAD_AREA_ROUND_STRATAS';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });
    try {
      const success = await axios({
        method: 'PUT',
        url: `${apiRounds}/${formData.get('round_id')}/stratas/upload`,
        data: formData,
      });

      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: success.data.result });
      return true;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return false;
    }
  };
}

export function uploadLabAnalysis(formData) {
  const dispatch_prefix = 'UPLOAD_AREA_ROUND_LABANALYSIS';
  return async (dispatch) => {
    dispatch({ type: `${dispatch_prefix}_PENDING` });
    try {
      const success = await axios({
        method: 'PUT',
        url: `${apiRounds}/${formData.get('round_id')}/documents/upload/labanalysis`,
        data: formData,
      });

      dispatch({ type: `${dispatch_prefix}_FULFILLED`, payload: success.data.result });
      return true;
    } catch (err) {
      errorResponse(dispatch, err, `${dispatch_prefix}_REJECTED`);
      return false;
    }
  };
}

export function uploadSites(formData, onProgressChange) {
  return async (dispatch) =>
    await upload(
      dispatch,
      'UPLOAD_SITES',
      `${apiRounds}/${formData.get('round_id')}/sites/upload`,
      formData,
      onProgressChange,
    );
}

export function generateSites(round_id) {
  return async (dispatch) =>
    await post(dispatch, 'GENERATE_SITES', `${apiRounds}/${round_id}/sites/generate`);
}

export function fetchDashboardStatsByAreas() {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_DASHBOARD_STATS_BY_AREAS',
      `${apiRounds}/statistics/dashboard`,
      null,
      null,
      true,
    );
}
