import React, { useState } from 'react';
import {
  Button
} from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import Listview from "../../core/form/components/Listview";
import PageTitleH5 from "../../core/form/components/PageTitleH5";
import DocumentDefModal from './DocumentDefModal';

const DocumentDefsLsv = (props) => {
  const {
    rows
  } = props;

  const [isModalOpen, setModal] = useState(false);
  const [id, setId] = useState(null);

  const openModal = (id) => {
    setId(id);
    setModal(true);
  }

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'edit': return (
          <td key={index} className={header.classes}><Icon name="pen-to-square" style={{ cursor: 'pointer' }} onClick={() => openModal(row.id)} /></td>
        )
        default: return (
          <td key={index} className={header.classes}>{row[header.field]}</td>
        )
      }
    });

    return tableTd;
  }

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Script Name', field: 'key', classes: 'text-left' },
    { caption: 'Script Group', field: 'group_key', classes: 'text-left' },
    { caption: '', field: 'edit', classes: 'text-right text-success' }
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => openModal(row.id)} style={{cursor: 'pointer'}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName = 'clipboard-list';
  const emptyCaption = 'No definitions found';
  const title = 'Document Definitions';

  return (
    <React.Fragment>
      <DocumentDefModal setModal={setModal} id={id} isOpen={isModalOpen} />
      <div className="d-flex justify-content-between border-bottom border-corporate bg-light p-1 rounded">
        <PageTitleH5 iconName={iconName} title={title} />
        <div>
          <Button color="success" size="sm" onClick={() => openModal(null)}>Add New Definition</Button>
        </div>
      </div>

      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </React.Fragment>
  );
}

export default DocumentDefsLsv;
