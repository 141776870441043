import { downloadBlob, get, post, put, remove } from '../../../../lib/genericAction';
import {
  apiExportEnterpriseAnalysis,
  apiExportReports,
  apiReportMetrics,
  apiReports,
} from '../../../../constants/api';

export function updateReportSectionRowCol(id, data) {
  return async (dispatch) =>
    put(
      dispatch,
      'UPDATE_REPORT_SECTION_ROW_COL',
      `${apiReports}/_/versions/_/sections/_/rows/_/cols/${id}`,
      data,
    );
}

export function removeReportSectionRow(report_id, version_id, section_id, id) {
  return async (dispatch) =>
    remove(
      dispatch,
      'REMOVE_REPORT_SECTION_ROW',
      `${apiReports}/${report_id}/versions/${version_id}/sections/${section_id}/rows/${id}`,
    );
}

export function updateReportSectionRow(report_id, version_id, section_id, id, data) {
  return async (dispatch) =>
    put(
      dispatch,
      'UPDATE_REPORT_SECTION_ROW',
      `${apiReports}/${report_id}/versions/${version_id}/sections/${section_id}/rows/${id}`,
      data,
    );
}

export function reorderReportSectionRows(report_id, version_id, section_id, data) {
  return async (dispatch) =>
    put(
      dispatch,
      'REORDER_REPORT_SECTION_ROWS',
      `${apiReports}/${report_id}/versions/${version_id}/sections/${section_id}/reorder`,
      data,
    );
}

export function createReportSectionRow(report_id, version_id, section_id) {
  return async (dispatch) =>
    post(
      dispatch,
      'CREATE_REPORT_SECTION_ROW',
      `${apiReports}/${report_id}/versions/${version_id}/sections/${section_id}/rows`,
    );
}

export function removeReportSection(report_id, version_id, id) {
  return async (dispatch) =>
    remove(
      dispatch,
      'REMOVE_REPORT_SECTION',
      `${apiReports}/${report_id}/versions/${version_id}/sections/${id}`,
    );
}

export function updateReportSection(report_id, version_id, id, data) {
  return async (dispatch) =>
    put(
      dispatch,
      'UPDATE_REPORT_SECTION',
      `${apiReports}/${report_id}/versions/${version_id}/sections/${id}`,
      data,
    );
}

export function createReportSection(report_id, version_id, data) {
  return async (dispatch) =>
    post(
      dispatch,
      'CREATE_REPORT_SECTION',
      `${apiReports}/${report_id}/versions/${version_id}/sections`,
      data,
    );
}

export function createReport(data) {
  return async (dispatch) => post(dispatch, 'CREATE_REPORT', `${apiReports}`, data);
}

export function fetchReport(id) {
  return async (dispatch) => get(dispatch, 'FETCH_REPORT', `${apiReports}/${id}`);
}

export function fetchReports(params, inBackground = false) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_REPORTS', `${apiReports}`, params, null, inBackground);
}

export function exportReports(params, inBackground = true) {
  return async (dispatch) =>
    downloadBlob(dispatch, 'EXPORT_REPORTS', `${apiExportReports}`, params, null, inBackground);
}

export function exportEnterpriseAnalysis(params, inBackground = true) {
  return async (dispatch) =>
    downloadBlob(
      dispatch,
      'EXPORT_ENTERPRISE_ANALYSIS',
      `${apiExportEnterpriseAnalysis}`,
      params,
      null,
      inBackground,
    );
}
export function reorderReports(data) {
  return async (dispatch) => put(dispatch, 'REORDER_REPORTS', `${apiReports}/reorder`, data);
}

export function updateReport(id, data) {
  return async (dispatch) => put(dispatch, 'UPDATE_REPORT', `${apiReports}/${id}`, data);
}

export function fetchReportVersions(id) {
  return async (dispatch) => get(dispatch, 'FETCH_REPORT_VERSIONS', `${apiReports}/${id}/versions`);
}

export function fetchReportVersion(report_id, version_id, inBackground = false) {
  return async (dispatch) =>
    get(
      dispatch,
      'FETCH_REPORT_VERSION',
      `${apiReports}/${report_id}/versions/${version_id}`,
      null,
      null,
      inBackground,
    );
}

export function updateReportVersion(report_id, version, data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_REPORT_VERSION', `${apiReports}/${report_id}/versions/${version}`, data);
}

export function createVersion(id, params) {
  return async (dispatch) =>
    post(dispatch, 'CREATE_REPORT_VERSION', `${apiReports}/${id}/versions`, params);
}

export function fetchLookupMetrics(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_LOOKUP_METRICS', `${apiReportMetrics}/list/all`, params);
}

export function fetchReportMetrics(params) {
  return async (dispatch) => get(dispatch, 'FETCH_REPORT_METRICS', `${apiReportMetrics}`, params);
}

export function fetchReportMetric(params, inBackground = false, cancelToken) {
  return async (dispatch) =>
    get(
      dispatch,
      'FETCH_REPORT_METRIC',
      `${apiReportMetrics}/${params.id}`,
      params,
      null,
      inBackground,
      cancelToken,
    );
}

export function fetchReportDivisionMetrics(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_REPORT_DIVISION_METRIC', `${apiReportMetrics}/divisions/all`, params);
}

export function createReportMetric(data) {
  return async (dispatch) => post(dispatch, 'CREATE_REPORT_METRIC', `${apiReportMetrics}`, data);
}

export function updateReportMetric(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_REPORT_METRIC', `${apiReportMetrics}/${data.id}`, data);
}

export function removeReportMetric(id) {
  return async (dispatch) => remove(dispatch, 'REMOVE_REPORT_METRIC', `${apiReportMetrics}/${id}`);
}

export function fetchMetricFromFormula(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_METRIC_FROM_FORMULA', `${apiReportMetrics}/generate`, params);
}

export function fetchReportMatrix(params) {
  return async (dispatch) => get(dispatch, 'FETCH_REPORT_MATRIX', `${apiReports}/matrix`, params);
}

export function clearCache(params) {
  return async (dispatch) =>
    remove(dispatch, 'CLEAR_REPORT_METRICS_CACHE', `${apiReportMetrics}/cache`, params);
}
