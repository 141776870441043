import React from 'react';
import { Button, Alert } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const AssetsToolbar = (props) => {
  const { onAddNewAsset, onAddNewTransaction, alertText, filterClick } = props;
  const assetButtonText = 'Add Asset';
  const transactionButtonText = 'Add Transaction';

  return (
    <Alert color="primary" className="d-flex justify-content-end p-2">
      <div>{alertText}</div>
      <Button onClick={onAddNewAsset} size="sm" color="success" className={'mr-2'}>
        <Icon name='plus' className='mr-2' />
        {assetButtonText}
      </Button>
      <Button onClick={onAddNewTransaction} size="sm" color="success">
        <Icon name='plus' className='mr-2' />
        {transactionButtonText}
      </Button>
      {filterClick && (
        <Button
          id="toggler"
          size="sm"
          color="warning"
          className="text-white ml-2"
          onClick={filterClick}
        >
          Filter
        </Button>
      )}
    </Alert>
  );
};

export default AssetsToolbar;
