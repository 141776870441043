import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import { Button, Nav, TabContent, TabPane } from 'reactstrap';

import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import FormBase from '../../../core/form/components/FormBase';
import FormTab from '../../../core/form/components/FormTab';
import { controls } from '../forms/fieldbook';
import FieldTripsLsv from '../components/FieldTripsLsv';
import FieldTripModal from './FieldTripModal';
import FieldUploadsLsv from '../components/FieldUploadsLsv';
import FieldUploadModal from './FieldUploadModal';
import FieldTripFilter from '../components/FieldTripFilter';
import SitesLsv from '../components/SitesLsv';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import {
  fetchFieldTrips,
  removeFieldTrip,
  fetchFieldUploads,
  removeFieldUpload,
  setFieldtripFilters,
} from '../actions';
import BreadcrumbsRoute from 'jsx/components/core/form/components/BreadcrumbsRoute';

class FieldTrips extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      coreSelection: [],
      modifyDisabled: true,
      controls,
      isOpen: false,
      isNew: false,
      isInstrumentOpen: false,
      isInstrumentNew: false,
      id: null,
      siteSelections: [],
    };

    this.refresh = this.refresh.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setInstrumentModal = this.setInstrumentModal.bind(this);
    this.onUploadRemove = this.onUploadRemove.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.handleUpdateFilters = this.handleUpdateFilters.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.handleSingleSiteSelection = this.handleSingleSiteSelection.bind(this);
    this.handleAllSiteSelections = this.handleAllSiteSelections.bind(this);
    this.redirectToFieldBook = this.redirectToFieldBook.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchFieldTrips());
    this.props.dispatch(fetchFieldUploads());
    const { recentlyCollectedFilters } = this.props.sites;
    this.props.dispatch(setFieldtripFilters(recentlyCollectedFilters));
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'UNSET_STRATAS' });
    this.props.dispatch({ type: 'UNSET_SITES' });
    this.props.dispatch({ type: 'UNSET_ROUND' });
  }

  refresh() {
    this.props.dispatch(fetchFieldTrips());
  }

  async onRemove(event, id) {
    event.stopPropagation();

    const confirmed = window.confirm(`Removing the Field Trip permanently. Continue?`);
    if (confirmed) {
      await this.props.dispatch(removeFieldTrip(id));
      this.props.dispatch(fetchFieldTrips());
    }
  }

  onEdit(event, id) {
    event.stopPropagation();
    this.setState({ id });
    this.setModal(true, false);
  }

  async onUploadRemove(event, id) {
    event.stopPropagation();

    let message = `Removing the Field Upload set permanently. This will delete all associated field instrument locations for this upload. `;
    message += `It will NOT remove any sites from the database. However, actual location coordinates will still remain in each site. `;
    message += `Continue?`;

    const confirmed = window.confirm(message);
    if (confirmed) {
      await this.props.dispatch(removeFieldUpload(id));
      this.props.dispatch(fetchFieldUploads());
    }
  }

  setModal(isOpen, isNew) {
    this.setState({
      isOpen,
      isNew,
    });
  }

  setInstrumentModal(isInstrumentOpen, isInstrumentNew) {
    this.setState({
      isInstrumentOpen,
      isInstrumentNew,
    });
  }

  toggleTab(tab) {
    this.props.dispatch({ type: 'SET_ACTIVETAB_FIELDTRIPS', payload: tab });

    if (this.props.sites.activeTabFieldTrips !== tab) {
      this.props.history.replace(`/home/fieldtrips/${tab}`);
    }
  }

  handleUpdateFilters({
    from_collected_date = null,
    search_value = null,
    limit = 0,
    site_selections = [],
  }) {
    const { recentlyCollectedFilters } = this.props.sites;

    if (from_collected_date !== null) {
      recentlyCollectedFilters.from_collected_date =
        moment(from_collected_date).format('YYYY-MM-DD');

      if (from_collected_date === '') recentlyCollectedFilters.from_collected_date = '';
    }
    if (search_value !== null) recentlyCollectedFilters.search_value = search_value;
    if (limit !== 0) recentlyCollectedFilters.limit = limit;
    if (site_selections.length > 0) recentlyCollectedFilters.site_selections = site_selections;

    this.props.dispatch(setFieldtripFilters(recentlyCollectedFilters));
  }

  onLoadMore() {
    this.handleUpdateFilters({ limit: this.props.sites.recentlyCollectedFilters.limit + 30 });
  }

  handleSingleSiteSelection(event) {
    const isNotChecked = event.target.checked;
    const selected_id = event.target.value;
    let newSiteSelections = [...this.state.siteSelections];
    if (isNotChecked) {
      newSiteSelections.push(selected_id);
    } else {
      newSiteSelections = newSiteSelections.filter((item) => item !== selected_id);
    }

    this.setState({ siteSelections: newSiteSelections });
  }

  handleAllSiteSelections(event) {
    const selectAll = event.target.checked;
    const allSiteIds = this.props.sites.sites.rows.map(({ id }) => id);
    let updateState = { siteSelections: allSiteIds };

    if (!selectAll) updateState = { siteSelections: [] };

    this.setState(updateState);
  }

  redirectToFieldBook(selectedFieldTrip) {
    this.props.history.push(`/home/fieldtrips/fieldbook/${selectedFieldTrip}`);
  }

  render() {
    const { id, isOpen, isNew, isInstrumentOpen, isInstrumentNew } = this.state;
    const { authorised, authResponseMessage, fieldtrips, fielduploads, activeTabFieldTrips } =
      this.props.sites;
    const iconName = 'truck-pickup';

    return (
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Field Trips</h3>
          </div>
        </div>

        <BreadcrumbsRoute match={this.props.match} />

        <ResponseMessage responseMessage={authResponseMessage} />
        {authorised && (
          <>
            <Nav tabs className="mt-2">
              <FormTab
                caption="Field Data"
                tabId="field-trip"
                tabTag="field-trip"
                activeTab={activeTabFieldTrips}
                toggle={() => this.toggleTab('field-trip')}
              />
              <FormTab
                caption="Instrument Uploads"
                tabId="field-uploads"
                tabTag="field-uploads"
                activeTab={activeTabFieldTrips}
                toggle={() => this.toggleTab('field-uploads')}
              />
              <FormTab
                caption="Recently Collected Sites"
                tabTag="recently-collected"
                tabId="recently-collected"
                activeTab={activeTabFieldTrips}
                toggle={() => this.toggleTab('recently-collected')}
              />
            </Nav>

            <TabContent activeTab={activeTabFieldTrips} className="border-bottom border-primary">
              <TabPane tabId="field-trip" className="mb-2 mt-2">
                {activeTabFieldTrips === 'field-trip' && (
                  <>
                    <div className="d-flex justify-content-end bg-light p-2 rounded ">
                      <Button color="success" onClick={this.refresh} className="mr-2" size="sm">
                        <Icon name="rotate" />
                      </Button>
                      <Button
                        disabled={!this.checkAccess('fieldTripCreate')}
                        color="success"
                        size="sm"
                        onClick={() => this.setModal(true, true)}
                      >
                        Add Field Trip
                      </Button>
                    </div>

                    <FieldTripModal
                      setModal={this.setModal}
                      id={id}
                      isOpen={isOpen}
                      isNew={isNew}
                    />
                    <FieldTripsLsv
                      onRemove={this.onRemove}
                      onEdit={this.onEdit}
                      history={this.props.history}
                      rows={fieldtrips}
                      checkAccess={this.checkAccess}
                    />
                  </>
                )}
              </TabPane>
              <TabPane tabId="field-uploads" className="mb-2 mt-2">
                {activeTabFieldTrips === 'field-uploads' && (
                  <>
                    <div className="d-flex justify-content-end bg-light p-2 rounded ">
                      <Button
                        disabled={!this.checkAccess('fieldTripCreate')}
                        color="success"
                        size="sm"
                        onClick={() => this.setInstrumentModal(true, true)}
                      >
                        Add Instrument Upload
                      </Button>
                    </div>
                    <FieldUploadModal
                      setModal={this.setInstrumentModal}
                      id={id}
                      isOpen={isInstrumentOpen}
                      isNew={isInstrumentNew}
                    />
                    <FieldUploadsLsv
                      rows={fielduploads}
                      checkAccess={this.checkAccess}
                      history={this.props.history}
                      onRemove={this.onUploadRemove}
                    />
                  </>
                )}
              </TabPane>
              <TabPane tabId="recently-collected" className="mb-2 mt-2">
                {activeTabFieldTrips === 'recently-collected' && (
                  <>
                    <FieldTripFilter
                      sites={this.props.sites}
                      filters={this.props.sites.recentlyCollectedFilters}
                      handleUpdateFilters={this.handleUpdateFilters}
                      rowsCount={this.props.sites.sites.count}
                      onSaveRedirect={this.redirectToFieldBook}
                      siteSelections={this.state.siteSelections}
                    />
                    <SitesLsv
                      homeLink=""
                      match={this.props.match}
                      history={this.props.history}
                      rows={this.props.sites.sites.rows || []}
                      rowsCount={this.props.sites.sites.count}
                      onLoadMore={this.onLoadMore}
                      coreSelection={this.state.siteSelections}
                      disableCheckboxes={false}
                      handleSelect={this.handleSingleSiteSelection}
                      handleSelectAll={this.handleAllSiteSelections}
                      handleDownload={(siteId, event) =>
                        this.downloads.handleSiteDownload(siteId, event)
                      }
                      fetchSamples={() => undefined}
                      samples={null}
                      filterColumns={[
                        'core_key',
                        'name',
                        'strata',
                        'type',
                        'target_depth',
                        'stickup_height',
                        'actual_depth',
                        'core_length',
                        'compression_ratio',
                        'collected_at',
                        'fieldtrip_sites_exists',
                      ]}
                    />
                  </>
                )}
              </TabPane>
            </TabContent>
          </>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ sites, realm }) => ({ sites, realm });

export default connect(mapStoreToProps)(withContainerError(FieldTrips));
