import {
    apiCrm,
} from '../../../../constants/api.js';
import { post, get } from '../../../../lib/genericAction';

export function fetchCompanies() {
  return async dispatch => await get(
    dispatch,
    'FETCH_CRM_COMPANIES',
    `${apiCrm}/companies`
  )
}

export function searchCompanies(name) {
  return async dispatch => await post(
    dispatch,
    'SEARCH_CRM_COMPANIES',
    `${apiCrm}/companies/search`,
    {query: name}
  )
}

export function fetchCompany(id) {
  return async dispatch => await get(
    dispatch,
    'FETCH_CRM_COMPANY',
    `${apiCrm}/companies/${id}`
  )
}
