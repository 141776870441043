import React from 'react';
import StatsChart from '../../../core/form/components/StatsChart';

import {
  Row,
  Col
} from 'reactstrap';

const IndicatorTrends = (props) => {
  const {
    positiveCharts,
    negativeCharts
  } = props;

  const postiveStatCharts = positiveCharts.map((positiveChart, index) => {
    const bottomClasses = `bg-${positiveChart.colour} text-center`;
    return (
      <Col sm={2} key={index}>
        <StatsChart
          bottomClasses={bottomClasses}
          key={index}
          value={positiveChart.value}
          unit={'%'}
          title={`${positiveChart.name}`}
          data={positiveChart.data}
          iconName='arrow-up'
          lineColour="green"
        />
      </Col>
    );
  })

  const negativeStatCharts = negativeCharts.map((negativeChart, index) => {
    const bottomClasses = `bg-${negativeChart.colour} text-center`;
    return (
      <Col sm={2} key={index}>
        <StatsChart
          bottomClasses={bottomClasses}
          key={index}
          value={negativeChart.value}
          unit={'%'}
          title={`${negativeChart.name}`}
          data={negativeChart.data}
          iconName='arrow-down'
          lineColour="red"
        />
      </Col>
    );
  })
  return (
    <div className="w-100">
      <h3 className="bg-light text-success border-bottom border-corporate">Improving and Healthy Metrics</h3>
      <Row>
        {postiveStatCharts}
      </Row>

      <h3 className="bg-light text-danger border-bottom border-corporate mt-3">Declining Metrics</h3>
      <Row>
        {negativeStatCharts}
      </Row>
    </div>
  );
}

export default IndicatorTrends;
