import { apiListviewConfiguration } from 'jsx/constants/api';
import { get, post } from 'jsx/lib/genericAction';

export function fetchListviewConfiguration(listviewId) {
  return (dispatch) =>
    get(dispatch, 'FETCH_LISTVIEW_CONFIGURATION', `${apiListviewConfiguration}/${listviewId}`);
}

export function updateListviewConfiguration(listview_tag, configuration, rememberUserState) {
  return (dispatch) => {
    if (rememberUserState) {
      return post(
        dispatch,
        'UPDATE_LISTVIEW_CONFIGURATION',
        `${apiListviewConfiguration}/${listview_tag}`,
        { configuration },
        true,
      );
    } else {
      return dispatch({
        type: 'SET_LISTVIEW_CONFIGURATION',
        payload: [
          {
            configuration,
            listview_tag,
          },
        ],
      });
    }
  };
}
