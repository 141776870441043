import React from 'react';
import { connect } from 'react-redux';
import { Form, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { cloneDeep } from 'lodash';

import FormBase from '../../../core/form/components/FormBase';
import FormInput from '../../../core/form/components/FormInput';

import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';

import { fetchAttributes } from '../actions/attributes';
import { controls as transactionControls } from '../forms/liabilityTransactions';
import {
  createLiabilityTransaction,
  fetchLiabilityTransaction,
  removeLiabilityTransaction,
  updateLiabilityTransaction,
} from '../actions/liabilities';
import FormIntervalDatePicker from '../../../core/form/components/FormIntervalDatePicker';

class LiabilityTransactionModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(transactionControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Liability Transaction',
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.setOptions = this.setOptions.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const controls = initControls(cloneDeep(transactionControls));
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Liability Transaction',
      };
      this.props.dispatch(fetchAttributes({ type: 'liability_transaction_types' }));
      if (this.props.id) {
        const { id } = this.props;
        updatedState = {
          ...updatedState,
          id,
          isNew: false,
          title: 'Edit Liability Transaction',
        };

        this.props.dispatch(fetchLiabilityTransaction(id)).then((data) => {
          this.setState({
            data,
            controls: updateControls(controls, data),
          });
        });
      }

      this.setState(updatedState);
    }
  }

  async onSave() {
    let { data, controls, isNew } = this.state;

    data = saveControls(controls, data);
    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    if (isValid) {
      let success;
      if (isNew) {
        delete data.id;
        success = await this.props.dispatch(createLiabilityTransaction(data));
      } else {
        success = await this.props.dispatch(updateLiabilityTransaction(data));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing transaction permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeLiabilityTransaction(data.id));
      if (success) this.onClose(true);
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
  }

  setOptions(controls) {
    const { isNew } = this.state;
    let { liability_transaction_types } = this.props.attributes;
    const { liabilities } = this.props.liabilities;

    // Sort selections alphabetically
    const toEnd = 1;
    liabilities?.rows?.sort((a, b) => (a?.name ? a.name.localeCompare(b.name) : toEnd));

    liability_transaction_types?.sort((a, b) => (a?.tag ? a.tag.localeCompare(b.tag) : toEnd));

    controls.liability_id.disabled = isNew ? false : true;
    controls = updateControlOptions(controls, 'liability_id', liabilities?.rows);

    const index = liabilities?.rows?.findIndex(
      (liability) => liability.id === controls.liability_id.value,
    );
    let category_tag;
    if (index >= 0) {
      category_tag = liabilities?.rows[index]?.category?.tag;
    }

    liability_transaction_types = liability_transaction_types?.filter(
      (transaction_type) =>
        transaction_type.rules === null ||
        transaction_type?.rules?.allowedCategories.findIndex(
          (category) => category === category_tag,
        ) >= 0,
    );

    controls = updateControlOptions(controls, 'transaction_type_id', liability_transaction_types);

    return controls;
  }

  render() {
    let { title, isNew, controls } = this.state;
    const { isOpen } = this.props;
    const { transaction_intervals } = this.props.attributes;
    const { responseMessage } = this.props.liabilities;

    controls = this.setOptions(controls);

    const iconName = 'clipboard-list';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.liability_id} />
            <FormIntervalDatePicker
              handleChange={this.handleChange}
              controls={controls}
              intervals={transaction_intervals}
              intervalKey={'transaction_interval_id'}
              dateKey={'transaction_date'}
            />
            <FormInput handleChange={this.handleChange} control={controls.transaction_type_id} />
            <FormInput handleChange={this.handleChange} control={controls.notes} />
            <FormInput handleChange={this.handleChange} control={controls.amount} />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    attributes: store.attributes,
    liabilities: store.liabilities,
  };
};

export default connect(mapStoreToProps)(LiabilityTransactionModal);
