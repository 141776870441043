import { get, post, download, remove, put } from '../../../../lib/genericAction';

import { apiTimesheets, apiLeaves } from '../../../../constants/api.js';

export function fetchResourceUsage(params) {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_RESOURCE_USAGE',
      `${apiTimesheets}/report/resource/usage/distribution`,
      params
    );
}

export function fetchResourceUsageSummary(params) {
  return async (dispatch) =>
    await get(
      dispatch,
      'FETCH_RESOURCE_USAGE_SUMMARY',
      `${apiTimesheets}/report/resource/usage/summary`,
      params
    );
}

export function fetchTimesheets(params) {
  return async (dispatch) => await get(dispatch, 'FETCH_TIMESHEETS', `${apiTimesheets}`, params);
}

export function fetchTimesheet(id) {
  return async (dispatch) => await get(dispatch, 'FETCH_TIMESHEET', `${apiTimesheets}/${id}`);
}

export function fetchTimesheetSummaries(params) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_TIMESHEET_SUMMARIES', `${apiTimesheets}/report/summaries`, params);
}

export function createTimesheet(data) {
  return async (dispatch) => await post(dispatch, 'CREATE_TIMESHEET', `${apiTimesheets}`, data);
}

export function downloadCsv(params, onProgressChange) {
  return async (dispatch) =>
    await download(
      dispatch,
      'DOWNLOAD_TIMESHEET_CSV',
      `${apiTimesheets}/report/export`,
      onProgressChange,
      params
    );
}

export function downloadApproverReport(params, onProgressChange) {
  return async (dispatch) =>
    await download(
      dispatch,
      'DOWNLOAD_TIMESHEET_APPROVER_REPORT',
      `${apiTimesheets}/report/approvals`,
      onProgressChange,
      params
    );
}

export function removeTimesheet(id) {
  return async (dispatch) => await remove(dispatch, 'REMOVE_TIMESHEET', `${apiTimesheets}/${id}`);
}

export function updateTimesheet(data) {
  return async (dispatch) =>
    await put(dispatch, 'UPDATE_TIMESHEET', `${apiTimesheets}/${data.id}`, data);
}

export function bulkApproveTimesheets(data) {
  return async (dispatch) =>
    await put(dispatch, 'BULK_APPROVE_TIMESHEETS', `${apiTimesheets}/approve`, data);
}

export function fetchLeaves(params) {
  return async (dispatch) => await get(dispatch, 'FETCH_LEAVES', `${apiLeaves}`, params);
}

export function fetchLeave(id) {
  return async (dispatch) => await get(dispatch, 'FETCH_LEAVE', `${apiLeaves}/${id}`);
}

export function removeLeave(id) {
  return async (dispatch) => await remove(dispatch, 'REMOVE_LEAVE', `${apiLeaves}/${id}`);
}

export function updateLeave(data) {
  return async (dispatch) => await put(dispatch, 'UPDATE_LEAVE', `${apiLeaves}/${data.id}`, data);
}

export function createLeave(data) {
  return async (dispatch) => await post(dispatch, 'CREATE_LEAVE', `${apiLeaves}`, data);
}
