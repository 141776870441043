import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'enterprise.name',
    name: 'enterprise_id',
    type: 'select',
    caption: 'Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    disabled: true,
  },
  property_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'property_id',
    fieldName: 'property.name',
    type: 'select',
    caption: 'Property',
    validationRules: { isRequired: true },
    showInEditor: true,
    showInListview: true,
    disabled: true,
  },
  product_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'product_id.name',
    name: 'product_id',
    type: 'select',
    caption: 'Product',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    disabled: true,
  },
  date: {
    ...controlDefaults,
    placeholder: '',
    name: 'date',
    type: 'date',
    caption: 'Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    disabled: true,
  },
  value: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'value',
    name: 'value',
    type: 'number',
    caption: 'Value $/unit',
    formattingRules: {
      includeCommas: true,
      includeDecimals: false,
    },
    showInEditor: true,
  },
  // Listview controls
  date_listview: {
    ...controlDefaults,
    placeholder: '',
    name: 'date',
    type: 'date',
    caption: 'Date',
    showInListview: true,
    sortColumn: 'date',
  },
  enterprise_listview: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'enterprise.name',
    name: 'enterprise_id',
    type: 'select',
    caption: 'Enterprise',
    showInListview: true,
  },
  production_unit_listview: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'enterprise.production_units.name',
    type: 'select',
    caption: 'Production Unit',
    showInListview: true,
  },
  product_listview: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'product.name',
    name: 'product_id',
    type: 'select',
    caption: 'Product',
    showInListview: true,
  },
  value_listview: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'value',
    name: 'value',
    type: 'number',
    caption: 'Value $/unit',
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
      includeDollarSign: true,
    },
    showInListview: true,
  },
};
