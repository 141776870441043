import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  default_org_id: {
    ...controlDefaults,
    name: 'default_org_id',
    type: 'select',
    caption: 'Invite to',
    disabled: false,
    options: [],
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  client_name: {
    ...controlDefaults,
    placeholder: '',
    name: 'client_name',
    type: 'text',
    caption: 'Client Name',
    description: 'Full name of the client company/financial entity.',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  property_name: {
    ...controlDefaults,
    placeholder: '',
    name: 'property_name',
    type: 'text',
    caption: 'Property Name',
    description: 'Name of the client property.',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    groupTitle: 'Client Property Details',
  },
  property_address: {
    ...controlDefaults,
    placeholder: '',
    name: 'property_address',
    type: 'text',
    caption: 'Property Address',
    description: 'Property street address with town and state comma seperated.',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  firstname: {
    ...controlDefaults,
    placeholder: '',
    name: 'firstname',
    type: 'text',
    caption: 'First Name',
    description: 'Leave this blank if you do not want to send a user invite',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    groupTitle: 'Primary User Details - Only fill this in if you want to send an email invite',
  },
  lastname: {
    ...controlDefaults,
    placeholder: '',
    name: 'lastname',
    type: 'text',
    caption: 'Last Name',
    description: 'Leave this blank if you do not want to send a user invite',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  email: {
    ...controlDefaults,
    placeholder: '',
    name: 'email',
    type: 'text',
    caption: 'Email',
    description: 'Leave this blank if you do not want to send a user invite',
    validationRules: {
      isRequired: true,
      isEmail: true,
    },
    showInEditor: true,
  },
};
