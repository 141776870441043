import React from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import { HalfCircleSpinner } from 'react-epic-spinners';

const FormSearch = (props) => {
  const {
    bsSize,
    handleChange,
    handleSelect,
    control,
    minimumLength,
    selected,
    disabled,
    innerRef,
    autoFocus,
    className,
    forceOptions,
    dark = true,
    isLoading = false,
    width = null,
  } = props;

  const options = control.options;
  const value = control.value;
  const checkLength = minimumLength ?? 3;

  const createOptions = () => {
    if (
      !forceOptions &&
      (!options?.length > 0 || value === null || value?.length < checkLength || selected === true)
    )
      return [];

    return options
      .filter((option) => {
        if (forceOptions) return true;
        const check = option.name?.toLowerCase();
        const target = value?.toLowerCase();
        return check.includes(target);
      })
      .map((option, index) => (
        <div
          key={index}
          className={dark ? 'text-white' : 'text-dark'}
          style={{
            backgroundColor: dark ? '#333333' : 'white',
            margin: 2,
            padding: 5,
            cursor: 'pointer',
          }}
          onClick={() => handleSelect(option)}
        >
          {option.name}
        </div>
      ));
  };

  const showOptions = (!selected && value?.length >= checkLength) || forceOptions;

  return (
    <>
      <FormGroup row className="mt-0 mb-0" style={width && { width }}>
        {control.caption && (
          <Label for={control.name} sm={12} className="font-weight-bold">
            {control.caption}
          </Label>
        )}
        <Col sm={12}>
          <div className="position-relative">
            <Input
              bsSize={bsSize}
              className={className}
              autoFocus={autoFocus}
              innerRef={innerRef}
              type={control.type}
              name={control.name}
              id={control.id ? control.id : control.name}
              value={value || ''}
              placeholder={control.placeholder}
              onChange={handleChange}
              disabled={disabled || control.disabled}
              autoComplete="off"
            />

            {showOptions && (
              <div style={{ zIndex: 10 }} className="position-absolute bg-white border mt-1 w-100">
                {createOptions()}
                {isLoading && !options.length && (
                  <div className="p-2 d-flex">
                    Loading{' '}
                    <HalfCircleSpinner size={20} color="grey" className="ml-2 d-inline-block" />
                  </div>
                )}
              </div>
            )}
          </div>
          {!control.valid && (
            <small className="text-danger">
              {control.message}
              {control.message && control.description && <br />}
            </small>
          )}
          {control.description && (
            <small className="text-success ml-1">{control.description}</small>
          )}
        </Col>
      </FormGroup>
    </>
  );
};

export default FormSearch;
