import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    placeholder: 'Requirement Description',
    name: 'name',
    type: 'textarea',
    caption: 'Description',
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  },
  notes: {
    ...controlDefaults,
    placeholder: 'Comments and Notes on progress and issues for this requirement...',
    name: 'notes',
    type: 'textarea',
    caption: 'Notes',
    validationRules: {},
  },
  created: {
    ...controlDefaults,
    placeholder: 'Created',
    name: 'created',
    type: 'text',
    caption: 'Created',
    disabled: true,
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  },
  status_id: {
    ...controlDefaults,
    name: 'status_id',
    fieldName: 'status_id',
    type: 'select',
    caption: 'Status'
  },
  due: {
    ...controlDefaults,
    placeholder: 'Due',
    name: 'due',
    type: 'date',
    caption: 'Due Date',
    validationRules: {
      minLength: 6,
      isRequired: true
    },
  },
  dependent_id: {
    ...controlDefaults,
    placeholder: 'Depends On',
    name: 'dependent_id',
    type: 'select',
    caption: 'Dependent On',
    disabled: true,
    validationRules: {},
  },
  sequence: {
    ...controlDefaults,
    placeholder: 'Numeric sort value',
    name: 'sequence',
    type: 'text',
    caption: 'Sort Sequence',
    disabled: false,
    validationRules: {},
  },
}