import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiCapitalExpenditures } from '../../../../constants/api';

export function fetchCapitalExpenditures(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_CAPITAL_EXPENDITURES`, `${apiCapitalExpenditures}`, params);
}

export function fetchCapitalExpenditure(id) {
  return async (dispatch) =>
    await get(dispatch, 'FETCH_CAPITAL_EXPENDITURE', `${apiCapitalExpenditures}/${id}`);
}

export function createCapitalExpenditure(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_CAPITAL_EXPENDITURE`, `${apiCapitalExpenditures}`, data);
}

export function updateCapitalExpenditure(data) {
  return async (dispatch) =>
    await put(dispatch, 'UPDATE_CAPITAL_EXPENDITURE', `${apiCapitalExpenditures}/${data.id}`, data);
}

export function removeCapitalExpenditure(id) {
  return async (dispatch) =>
    await remove(dispatch, 'REMOVE_CAPITAL_EXPENDITURE', `${apiCapitalExpenditures}/${id}`);
}
