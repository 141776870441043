import React from 'react';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';

const ProfileContactsToolbar = (props) => {
  const { setInviteModal } = props;

  return (
    <div className="bg-light rounded border-bottom border-corporate text-corporate p-2 d-flex justify-content-between">
      <div>
        <Icon size="1x" name="users" className="text-corporate mr-2" />
        PORTAL USERS
      </div>
      <Button
        size="sm"
        color="success"
        disabled={false}
        onClick={() => {
          setInviteModal(true);
        }}
      >
        Invite
      </Button>
    </div>
  );
};

export default ProfileContactsToolbar;
