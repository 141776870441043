import { get, put } from '../../../../lib/genericAction';

import { apiDaysheets } from '../../../../constants/api';

export function fetchDaysheetActivityApprovals(params) {
  return async (dispatch) =>
    get(
      dispatch,
      `FETCH_DAYSHEET_ACTIVITY_APPROVALS`,
      `${apiDaysheets}/activity_approvals`,
      params,
    );
}

export function approveActivities(params) {
  return async (dispatch) =>
    put(
      dispatch,
      `UPDATE_DAYSHEET_ACTIVITY_APPROVALS`,
      `${apiDaysheets}/activity_approvals`,
      params,
    );
}
