import React from 'react';
import { Label } from 'reactstrap';
import DatePicker from 'react-datepicker';

const MonthPicker = ({
  id,
  caption = '',
  selected,
  minimum,
  maximum,
  handleChange = () => {},
  className = '',
  popperPlacement = 'bottom',
  popperModifiers = [],
}) => (
  <div className={`d-flex align-items-center ${className}`}>
    <Label for={id} className="mr-2 mb-0">
      {caption}
    </Label>
    <DatePicker
      id={id}
      selected={selected ? new Date(selected) : new Date()}
      minDate={minimum ? new Date(minimum) : null}
      maxDate={maximum ? new Date(maximum) : null}
      onChange={handleChange}
      className="month-picker"
      dateFormat="MMM-yyyy"
      showMonthYearPicker
      popperPlacement={popperPlacement}
      popperModifiers={popperModifiers}
    />
  </div>
);
export default MonthPicker;
