import React from 'react';
import { Input } from 'reactstrap';
import Listview from '../../../core/form/components/Listview';

const LabRegisterLsv = (props) => {
  let { history, rows, pagination, onLoadMore, handleSelect, recordSelection } = props;

  const edit = (record) => {
    const lnk = `/home/lab/${record.id}`;
    history.push(lnk);
  };

  const renderRows = (headers, record) => {
    const isChecked = recordSelection
      ? recordSelection.findIndex((id) => id === record.id) > -1
      : false;

    let assoc_type = '';
    let round_name = '';
    if (record.area_rounds?.length > 0) {
      assoc_type = 'CEA';
      let round = record.area_rounds[0].area_round;
      round_name = `${round.area.property.idx}-${round.area.name}-${round.name}`;
    } else if (record.property_rounds?.length > 0) {
      assoc_type = 'Round';
      let round = record.property_rounds[0].property_round;
      round_name = `${round.property.idx}-${round.name}`;
    }

    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'checkbox':
          return (
            <td
              key={index}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Input
                type="checkbox"
                className="m-0 mt-1"
                onChange={handleSelect}
                value={record.id}
                checked={isChecked}
              />
            </td>
          );
        case 'assoc_type':
          return (
            <td key={index} className={header.classes}>
              {assoc_type}
            </td>
          );
        case 'round_name':
          return (
            <td key={index} className={header.classes}>
              {round_name}
            </td>
          );
        case 'transport_org_id':
          return (
            <td key={index} className={header.classes}>
              {record.transport_org ? record.transport_org.name : ''}
            </td>
          );
        case 'sample_type_id':
          return (
            <td key={index} className={header.classes}>
              {record.sample_type.name}
            </td>
          );
        case 'lab_org_id':
          return (
            <td key={index} className={header.classes}>
              {record.lab_org ? record.lab_org.name : ''}
            </td>
          );
        case 'status_id':
          return (
            <td key={index} className={header.classes} style={{ color: record.status.colour }}>
              {record.status.name}
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {record[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const upperHeaders = [
    { caption: null, cells: 2, classes: 'border-bottom-0' },
    {
      caption: 'Association',
      cells: 2,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    { caption: null, cells: 2, classes: 'border-bottom-0' },
    { caption: 'Transport', cells: 2, classes: 'text-center bg-lightgray border border-lightgray' },
    { caption: null, cells: 3, classes: 'border-bottom-0' },
    { caption: 'Lab', cells: 3, classes: 'text-center bg-lightgray border border-lightgray' },
  ];

  const headers = [
    { caption: '-', field: 'checkbox', classes: 'text-center w-20' },
    { caption: 'Job ID', field: 'job_no', classes: 'text-center' },
    { caption: 'Type', field: 'assoc_type', classes: 'text-center border-left' },
    { caption: 'Name', field: 'round_name', classes: 'text-center border-right' },
    { caption: 'Purchase Order', field: 'purchase_order_no', classes: 'text-center' },
    { caption: 'Status', field: 'status_id', classes: 'text-center' },
    { caption: 'Name', field: 'transport_org_id', classes: 'text-left border-left' },
    { caption: 'Booking No', field: 'transport_booking_no', classes: 'text-center border-right' },
    { caption: 'Sample Type', field: 'sample_type_id', classes: 'text-center' },
    { caption: 'Sample Count', field: 'total_samples', classes: 'text-center' },
    { caption: 'Collected Samples', field: 'completed_samples', classes: 'text-center' },
    { caption: 'Name', field: 'lab_org_id', classes: 'text-left border-left' },
    { caption: 'Quote No', field: 'lab_quote_no', classes: 'text-center' },
    { caption: 'Job No', field: 'lab_job_no', classes: 'text-center border-right' },
  ];

  const upperHeadTh = upperHeaders.map((upperHeader, index) => (
    <th key={index} colSpan={upperHeader.cells} className={upperHeader.classes}>
      {upperHeader.caption}
    </th>
  ));

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  let rowsCount;
  if (rows && rows.rows && pagination) {
    rowsCount = rows.count;
    rows = rows.rows;
  }
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => (
      <tr key={index} onClick={() => edit(row)}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  const iconName = 'flask';
  const emptyCaption = 'No records found';

  return (
    <React.Fragment>
      <Listview
        rows={rows}
        rowsCount={rowsCount}
        onLoadMore={onLoadMore}
        upperHeadTh={upperHeadTh}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default LabRegisterLsv;
