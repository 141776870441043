import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { isEmpty } from 'lodash';
import { Button, Col, Label, Row } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import Listview from '../../core/form/components/Listview';

const UserRolesLsv = ({ rows = [], userOrgs, lookupOptions: roles, onAdd, onRemove }) => {
  const [org, setOrg] = useState(null);
  const [orgOptions, setOrgOptions] = useState([]);
  const [role, setRole] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);

  useEffect(() => {
    if (!isEmpty(userOrgs)) {
      const mappedOrgOptions = userOrgs.map((userOrg) => ({
        value: userOrg.org.id,
        label: userOrg.org.name,
      }));
      setOrgOptions(mappedOrgOptions);

      const [defaultOrg] = mappedOrgOptions;
      setOrg(defaultOrg);
    }
  }, [userOrgs]);

  useEffect(() => {
    if (!isEmpty(roles) && org) {
      const mappedRoleOptions = roles
        .map((option) => {
          const idx = rows.findIndex(
            (row) => row.role_id === option.id && row.org_id === org.value,
          );
          if (idx === -1) return { value: option.id, label: option.name };
          else return false;
        })
        .filter(Boolean);
      setRoleOptions(mappedRoleOptions);

      const [defaultRole] = mappedRoleOptions;
      setRole(defaultRole);
    }
  }, [roles, org, rows]);

  const onOrgChange = (selectedOption) => setOrg(selectedOption);
  const onRoleChange = (selectedOption) => setRole(selectedOption);

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'remove':
          return (
            <td key={index} className={header.classes}>
              <Icon
                name="trash"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onRemove(row);
                }}
              />
            </td>
          );
        case 'org_name':
          return (
            <td key={index} className={header.classes}>
              {row.org?.name || '-'}
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {row.role[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Organisation', field: 'org_name', classes: 'text-left' },
    { caption: 'Description', field: 'description', classes: 'text-left' },
    { caption: '', field: 'remove', classes: 'text-center text-danger' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  const iconName = 'user-tag';
  const emptyCaption = 'No Roles found';

  return (
    <>
      <Row className="d-flex justify-content-end align-items-end bg-light p-1 pb-3 mb-2 rounded">
        <Col xs="7" />
        <Col xs="2">
          <Label className="font-weight-bold mt-2">Organisation</Label>
          <ReactSelect
            options={orgOptions}
            placeholder="Select Organisation"
            onChange={onOrgChange}
            value={org}
          />
        </Col>
        <Col xs="2">
          <Label className="font-weight-bold mt-2">Role</Label>
          <ReactSelect
            options={roleOptions}
            placeholder="Select Role"
            onChange={onRoleChange}
            value={role}
          />
        </Col>
        <Col xs="1">
          <Button
            className="p-2"
            color="success"
            size="sm"
            onClick={() => onAdd({ role_id: role.value, org_id: org.value })}
          >
            Add Role
          </Button>
        </Col>
      </Row>
      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </>
  );
};

export default UserRolesLsv;
