import { get, post, put, remove } from '../../../../lib/genericAction';

import { apiEnterprises } from '../../../../constants/api';

const apiEnterpriseWools = `${apiEnterprises}/wools`;
const apiEnterpriseWoolValues = `${apiEnterpriseWools}/values`;

export function fetchWoolSummaries(params) {
  return async (dispatch) =>
    get(dispatch, `FETCH_WOOL_SUMMARIES`, `${apiEnterpriseWools}/summaries`, params);
}

export function fetchWoolflows(params) {
  return async (dispatch) =>
    get(dispatch, 'FETCH_WOOLFLOWS', `${apiEnterpriseWools}/woolflows`, params);
}

// Transactions
export function fetchWoolTransactions(type, params) {
  return async (dispatch) =>
    get(dispatch, `FETCH_WOOL_${type.toUpperCase()}`, `${apiEnterpriseWools}`, params);
}

export function fetchWoolTransaction(id) {
  return async (dispatch) => get(dispatch, 'FETCH_WOOL_TRANSACTION', `${apiEnterpriseWools}/${id}`);
}

export function createWoolTransaction(data) {
  return async (dispatch) =>
    post(dispatch, `CREATE_WOOL_TRANSACTION`, `${apiEnterpriseWools}`, data);
}

export function updateWoolTransaction(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_WOOL_TRANSACTION', `${apiEnterpriseWools}/${data.id}`, data);
}

export function removeWoolTransaction(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_WOOL_TRANSACTION', `${apiEnterpriseWools}/${id}`);
}

// Values
export function fetchWoolValues(params) {
  return async (dispatch) =>
    get(dispatch, `FETCH_WOOL_VALUES`, `${apiEnterpriseWoolValues}`, params);
}

export function fetchWoolValue(id) {
  return async (dispatch) => get(dispatch, 'FETCH_WOOL_VALUE', `${apiEnterpriseWoolValues}/${id}`);
}

export function createWoolValue(data) {
  return async (dispatch) =>
    post(dispatch, `CREATE_WOOL_VALUE`, `${apiEnterpriseWoolValues}`, data);
}

export function updateWoolValue(data) {
  return async (dispatch) =>
    put(dispatch, 'UPDATE_WOOL_VALUE', `${apiEnterpriseWoolValues}/${data.id}`, data);
}

export function removeWoolValue(id) {
  return async (dispatch) =>
    remove(dispatch, 'REMOVE_WOOL_VALUE', `${apiEnterpriseWoolValues}/${id}`);
}
