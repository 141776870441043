import { apiSamplingPlans } from 'jsx/constants/api';
import { get, post, put, remove } from 'jsx/lib/genericAction';

export function fetchSamplingPlans() {
  return async (dispatch) => get(dispatch, 'FETCH_SAMPLING_PLANS', `${apiSamplingPlans}`);
}

export function fetchSamplingPlan(params) {
  return (dispatch) =>
    get(dispatch, 'FETCH_SAMPLING_PLANS', `${apiSamplingPlans}/${params.id}`, params);
}

export function updateSamplingPlan(data) {
  return (dispatch) =>
    put(dispatch, 'UPDATE_SAMPLING_PLAN', `${apiSamplingPlans}/${data.id}`, data);
}

export function createSamplingPlan(data) {
  return (dispatch) => post(dispatch, 'CREATE_SAMPLING_PLAN', `${apiSamplingPlans}`, data);
}

export function removeSamplingPlan(id) {
  return (dispatch) => remove(dispatch, 'REMOVE_SAMPLING_PLAN', `${apiSamplingPlans}/${id}`);
}
