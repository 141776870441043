import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep, noop } from 'lodash';

import { Nav, TabContent, TabPane } from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import SearchToolbar from 'jsx/components/core/form/components/SearchToolbar';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import AssetReadings from './AssetReadings';
import AssetsLsv from '../components/AssetsLsv';
import GenericModal from '../../../core/form/components/GenericModal';

import {
  fetchPlantAssets,
  fetchPlantAsset,
  createPlantAsset,
  updatePlantAsset,
} from '../actions/assets';
import { fetchAttributes } from '../../portrait/actions/attributes';
import { fetchOrgs, fetchUsers } from '../../../manage/actions/index';

import { controls as assetControls } from '../forms/asset';
import { controls as userProjectControls } from '../forms/user_project';

import {
  createUserProject,
  fetchUserProject,
  fetchUserProjects,
  removeUserProject,
  updateUserProject,
} from '../actions/user_projects';
import UserProjectsModal from './UserProjectsModal';
import { fetchProjects } from '../../projects/actions/projects';

class Assets extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      filteredAssets: [],
      id: null,
      isNew: false,
      isUserProjectsModalOpen: false,
      isModalOpen: false,
      modalData: null,
      modalType: 'register',
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.loadAssets = this.loadAssets.bind(this);
    this.loadProjects = this.loadProjects.bind(this);
    this.loadUserProjects = this.loadUserProjects.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.refresh = this.refresh.bind(this);
    this.setUserProjectsModal = this.setUserProjectsModal.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
  }

  async componentDidMount() {
    Promise.all([
      this.props.dispatch(fetchAttributes({ type: 'plant_asset_types' })),
      this.props.dispatch(fetchAttributes({ type: 'plant_asset_categories' })),
      this.props.dispatch(fetchAttributes({ type: 'plant_asset_statuses' })),
      this.props.dispatch(fetchAttributes({ type: 'plant_asset_meterunits' })),
      this.props.dispatch(fetchOrgs({ category_tag: 'owners' })),
      this.props.dispatch(fetchUsers()),
    ]);

    const { activeTabAssets } = this.props.wipstar;
    this.toggleTab(activeTabAssets);
  }

  handleSearchChange(search_value) {
    const { activeTabAssets } = this.props.wipstar;

    switch (activeTabAssets) {
      case 'register': {
        this.loadAssets({ search_value });
        break;
      }
      case 'user-projects': {
        this.loadUserProjects({ search_value });
        break;
      }
      default: {
        break;
      }
    }
  }

  loadAssets(params = {}) {
    let assetParams = params;
    if (Object.keys(params).length > 0) this.setState({ assetParams });
    else ({ assetParams } = this.state);

    this.props.dispatch(fetchPlantAssets(assetParams));
  }

  loadProjects(params = {}) {
    this.props.dispatch(fetchProjects(params));
  }

  loadUserProjects(params = {}) {
    let userProjectParams = params;
    if (Object.keys(params).length > 0) this.setState({ userProjectParams });
    else ({ userProjectParams } = this.state);
    this.props.dispatch(fetchUserProjects(userProjectParams));
  }

  onClose() {
    this.refresh();
  }

  onRemove(id) {
    const { modalType } = this.state;
    let removeMethod = noop;

    switch (modalType) {
      case 'user-projects': {
        removeMethod = removeUserProject;
        break;
      }
      default: {
        break;
      }
    }

    if (removeMethod) return this.props.dispatch(removeMethod(id));
    return null;
  }

  onSave(data, isNew) {
    const { modalType } = this.state;

    const updatedData = cloneDeep(data);
    if (isNew) delete updatedData.id;

    let saveMethod;
    switch (modalType) {
      case 'register': {
        saveMethod = isNew ? createPlantAsset : updatePlantAsset;
        break;
      }
      case 'user-projects': {
        saveMethod = isNew ? createUserProject : updateUserProject;
        break;
      }
      default:
        break;
    }

    return this.props.dispatch(saveMethod(updatedData));
  }

  refresh() {
    this.toggleTab(this.props.wipstar.activeTabAssets);
  }

  async setUserProjectsModal(isUserProjectsModalOpen, id = null) {
    const modalData = id ? await this.props.dispatch(fetchUserProject(id)) : null;

    // Fetch data to populate select options.
    this.loadProjects();

    this.setState({
      id,
      isUserProjectsModalOpen,
      modalData,
    });
  }

  async setModal(isModalOpen, modalType = null, modalId = null) {
    let modalData;

    // Refresh dependant lookups based on type
    if (isModalOpen) {
      switch (modalType) {
        case 'register': {
          if (modalId) modalData = await this.props.dispatch(fetchPlantAsset(modalId));
          break;
        }
        default:
          break;
      }
    }

    const state = {
      isModalOpen,
      modalData,
    };
    if (modalType) state.modalType = modalType;

    this.setState(state);
  }

  setModalOptions(type) {
    const {
      plant_asset_types,
      plant_asset_categories,
      plant_asset_statuses,
      plant_asset_meterunits,
    } = this.props.attributes;
    const { orgs, users } = this.props.manage;
    const { rows: projects } = this.props.projects.projects;

    let options = {};
    switch (type) {
      case 'register':
        options = {
          allowRemoveMethod: false,
          title: 'Plant Register',
          iconName: 'list',
          controls: cloneDeep(assetControls),
          options: {
            type_id: plant_asset_types,
            category_id: plant_asset_categories,
            status_id: plant_asset_statuses,
            meterunit_id: plant_asset_meterunits,
            org_id: orgs,
          },
        };
        break;
      case 'user-projects': {
        options = {
          title: 'User/Project Association',
          iconName: 'users',
          controls: cloneDeep(userProjectControls),
          options: {
            user_id: users,
            project_id: projects,
          },
        };
        break;
      }
      default:
        break;
    }

    return options;
  }

  toggleTab(tab) {
    let { modalType } = this.state;

    switch (tab) {
      case 'readings': {
        modalType = 'readings';
        break;
      }
      case 'register': {
        modalType = 'register';
        this.loadAssets();
        break;
      }
      case 'user-projects': {
        modalType = 'user-projects';
        const { search_value } = this.props.user_projects.params;

        this.loadUserProjects({ search_value });
        break;
      }
      default: {
        // Do not update modal type
        modalType = null;
        break;
      }
    }

    // Set current tab in reducer
    if (this.props.wipstar.activeTabAssets !== tab)
      this.props.dispatch({ type: 'SET_PLANT_ASSETS_TAB', payload: tab });

    this.setState({ modalType });
  }

  render() {
    const { filteredAssets, isUserProjectsModalOpen, isModalOpen, modalData, modalType } =
      this.state;
    const { activeTabAssets, assets, responseMessage } = this.props.wipstar;
    const { userProjects, responseMessage: userProjectsResponseMessage } = this.props.user_projects;
    const iconName = 'engine';

    const modalOptions = this.setModalOptions(modalType);

    let rows = assets.rows || [];
    if (filteredAssets.length > 0) rows = filteredAssets;

    return (
      <div className="p-2 h-100 d-flex flex-column">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row m-2">
            <Icon size="2x" name={iconName} className="appForeTint mr-3" />
            <h3>Plant</h3>
          </div>
        </div>
        <GenericModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setModal}
          data={modalData}
          isOpen={isModalOpen}
          iconName={modalOptions.iconName}
          onClose={this.onClose}
          onSave={this.onSave}
          responseMessage={responseMessage}
        />

        <Nav tabs className="mt-2">
          <FormTab
            iconRightClass="primary"
            iconRightName="list"
            caption="Plant Register"
            tabId="register"
            activeTab={activeTabAssets}
            toggle={this.toggleTab}
          />
          <FormTab
            iconRightClass="primary"
            iconRightName="barcode-read"
            caption="Readings/Service Due"
            tabId="readings"
            activeTab={activeTabAssets}
            toggle={this.toggleTab}
          />
          <FormTab
            iconRightClass="danger"
            iconRightName="bone-break"
            caption="Faults"
            tabId=""
            activeTab={activeTabAssets}
            toggle={this.toggleTab}
            disabled={true}
          />
          <FormTab
            iconRightClass="primary"
            iconRightName="users"
            caption="User Projects"
            tabId="user-projects"
            activeTab={activeTabAssets}
            toggle={this.toggleTab}
          />
          <FormTab
            iconRightClass="primary"
            iconRightName="gear"
            caption="Settings"
            tabId="settings"
            activeTab={activeTabAssets}
            toggle={this.toggleTab}
            disabled={true}
          />
        </Nav>

        <TabContent activeTab={activeTabAssets} className="d-flex flex-column flex-grow-1">
          <TabPane tabId="register" className="mb-2 p-1">
            <SearchToolbar
              onToggleAdd={() => this.setModal(true, 'register')}
              onReload={() => this.loadAssets()}
              handleSearchChange={this.handleSearchChange}
              includeGoButton={true}
            />
            <AssetsLsv rows={rows} onClick={this.setModal} />
          </TabPane>
          <TabPane tabId="readings" className="mb-2 p-1 h-100">
            <AssetReadings />
          </TabPane>
          <TabPane tabId="faults" className="mb-2 p-1 h-100">
            {/* <AssetFaults /> */}
          </TabPane>

          <TabPane tabId="user-projects" className="mb-2 p-1 h-100">
            <SearchToolbar
              onToggleAdd={() => this.setUserProjectsModal(true)}
              handleSearchChange={this.handleSearchChange}
              includeReloadButton={false}
              includeGoButton={true}
            />
            <UserProjectsModal
              controls={modalOptions.controls}
              controlOptions={modalOptions.options}
              modalTitle={modalOptions.title}
              setModal={this.setUserProjectsModal}
              data={modalData}
              isOpen={isUserProjectsModalOpen}
              iconName={modalOptions.iconName}
              onClose={this.onClose}
              onSave={this.onSave}
              onRemove={this.onRemove}
              responseMessage={userProjectsResponseMessage}
            />
            {activeTabAssets === 'user-projects' && (
              <GenericLsv
                controls={modalOptions.controls}
                emptyCaption="No User Project assocations found."
                iconName="users"
                onClick={(id) => this.setUserProjectsModal(true, id)}
                rows={userProjects}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ attributes, manage, projects, user_projects, wipstar }) => ({
  attributes,
  manage,
  projects,
  user_projects,
  wipstar,
});

export default connect(mapStoreToProps)(withContainerError(Assets));
