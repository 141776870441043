import React from 'react';
import { connect } from "react-redux";
import {
  Row,
  Col
} from 'reactstrap';
import FormBase from "../../../core/form/components/FormBase";
import { controls } from "../forms/profile.js";
import FormInput from "../../../core/form/components/FormInput";
import { updateControls } from "../../../core/form/lib/validateForm";

class Profile extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      errorMessage: null,
      expandMap: false,
      showMiniMap: true,
      controls: controls,
      backlink: '/home/portal',
      mapSources: [],
      data: {}
    })

    this.onSave = this.onSave.bind(this);
  }

  componentDidUpdate() {
    let {
      data,
      controls
    } = this.state;

    if (this.props.data.id !== data.id) {
      data =  this.props.data;
      controls = updateControls(controls, data);
  
      this.setState({
        data,
        controls
      });
    }
  }

  async onSave() {}

  render() {
    const {
      controls
    } = this.state;

    return (
      <Row>
        <Col sm={6}>
          <FormInput handleChange={this.handleChange} control={controls.name} />
        </Col>
      </Row>
    )
  }
}

const mapStoreToProps = (store) => {
  return {
    clients: store.clients,
  }
}

export default connect(mapStoreToProps)(Profile);
