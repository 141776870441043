import { get, put, post, remove } from '../../../../lib/genericAction';

import { apiPropertyOtherIncomes } from '../../../../constants/api';

export function fetchPropertyOtherIncomes(params) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_PROPERTY_OTHER_INCOMES`, `${apiPropertyOtherIncomes}`, params);
}

export function fetchPropertyOtherIncome(id) {
  return async (dispatch) =>
    await get(dispatch, `FETCH_PROPERTY_OTHER_INCOME`, `${apiPropertyOtherIncomes}/${id}`);
}

export function createPropertyOtherIncome(data) {
  return async (dispatch) =>
    await post(dispatch, `CREATE_PROPERTY_OTHER_INCOME`, `${apiPropertyOtherIncomes}`, data);
}

export function updatePropertyOtherIncome(data) {
  return async (dispatch) =>
    await put(
      dispatch,
      `UPDATE_PROPERTY_OTHER_INCOME`,
      `${apiPropertyOtherIncomes}/${data.id}`,
      data
    );
}

export function removePropertyOtherIncome(id) {
  return async (dispatch) =>
    await remove(dispatch, `REMOVE_PROPERTY_OTHER_INCOME`, `${apiPropertyOtherIncomes}/${id}`);
}
