import React from 'react';
import { connect } from 'react-redux';
import { Card, CardTitle, Row, Col } from 'reactstrap';
import { cardFormat } from '../../../../core/form/lib/fieldFormat';

class CroppingEnterpriseCharts extends React.Component {
  render() {
    const { summaries } = this.props.cropping;

    return (
      <Row className="justify-content-start">
        <Col sm={2}>
          <Card className="border border-success rounded text-center">
            <CardTitle className="bg-success text-white">Opening Valuation</CardTitle>
            <h1>{cardFormat(summaries?.opening)}</h1>
          </Card>
        </Col>
        <Col sm={2}>
          <Card className="border border-success rounded text-center">
            <CardTitle className="bg-success text-white">Purchase Value</CardTitle>
            <h1>{cardFormat(summaries?.purchases)}</h1>
          </Card>
        </Col>
        <Col sm={2}>
          <Card className="border border-primary rounded text-center">
            <CardTitle className="bg-primary text-white">Sale Value</CardTitle>
            <h1>{cardFormat(summaries?.sales)}</h1>
          </Card>
        </Col>
        <Col sm={2}>
          <Card className="border border-success rounded text-center">
            <CardTitle className="bg-success text-white">Closing Valuation</CardTitle>
            <h1>{cardFormat(summaries?.closing)}</h1>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStoreToProps = ({ cropping }) => {
  return {
    cropping,
  };
};

export default connect(mapStoreToProps)(CroppingEnterpriseCharts);
