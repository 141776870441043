import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  source_enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'source_enterprise.name',
    name: 'source_enterprise_id',
    group: 'source',
    type: 'select',
    caption: 'Source Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  source_property_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'source_property.name',
    name: 'source_property_id',
    group: 'source',
    type: 'select',
    caption: 'Source Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  product_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'product.name',
    name: 'product_id',
    group: 'source',
    type: 'select',
    caption: 'Product',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  target_enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'target_enterprise.name',
    name: 'target_enterprise_id',
    group: 'target',
    type: 'select',
    caption: 'Target Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  target_property_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'target_property.name',
    name: 'target_property_id',
    group: 'target',
    type: 'select',
    caption: 'Target Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  target_animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'animal_class.name',
    name: 'target_animal_class_id',
    group: 'target',
    type: 'select',
    caption: 'Animal Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    type: 'date',
    caption: 'Transfer Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    sortColumn: 'transaction_date',
  },
  quantity_kg: {
    ...controlDefaults,
    placeholder: '',
    name: 'quantity_kg',
    type: 'number',
    caption: '# of Units',
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  amount: {
    ...controlDefaults,
    placeholder: '',
    name: 'amount',
    type: 'number',
    caption: 'Total Amount ($)',
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
      includeDollarSign: true,
    },
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  unit_amount: {
    ...controlDefaults,
    placeholder: '',
    name: 'unit_amount',
    type: 'number',
    caption: 'Unit Amount ($)',
    formula: '##amount## / ##quantity_kg##',
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
      includeDollarSign: true,
    },
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
};
