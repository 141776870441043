import { createSelector } from 'reselect';

const selectManage = (state) => state.manage ?? {};

const listviewConfigFromManage = (manage, key) => {
  const { listview_configuration = {} } = manage;
  const configForKey = listview_configuration[key];
  const configuration = configForKey?.configuration ?? {};

  /* This is for backwards compatibility as the database initially stored jsut the array of columns,
   * but it now stores an entire object of keys, including columns */
  if (Array.isArray(configuration)) {
    return {
      columns: configuration,
    };
  }
  return configuration;
};

const columnsFromConfig = (manage, key) => {
  const config = listviewConfigFromManage(manage, key);
  return config?.columns ?? [];
};

const filtersFromConfig = (manage, key) => {
  const config = listviewConfigFromManage(manage, key);
  return config?.filters;
};

const sortParamsFromConfig = (manage, key) => {
  const config = listviewConfigFromManage(manage, key);
  return config?.sortParams;
};

export const selectListviewConfiguration = createSelector(
  [selectManage, (state, key) => key],
  listviewConfigFromManage,
);

export const selectListviewColumns = createSelector(
  [selectManage, (state, key) => key],
  columnsFromConfig,
);

export const selectListviewFilters = createSelector(
  [selectManage, (state, key) => key],
  filtersFromConfig,
);

export const selectListviewSortParams = createSelector(
  [selectManage, (state, key) => key],
  sortParamsFromConfig,
);
