import React, { useState } from 'react';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import { Input } from 'reactstrap';
import Listview from '../../../core/form/components/Listview';
import DirectDownload from '../../../core/form/components/DirectDownload';
import UploadDots from '../../../core/form/components/UploadDots';
import SiteSampleChartModal from './SiteSampleChartModal';

import { scanValidity } from '../lib/scan_status';

const CoresLsv = (props) => {
  let { rows } = props;
  const {
    pagination,
    onLoadMore,
    history,
    handleSelect,
    handleDownload,
    samples,
    fetchSamples,
    currentSite,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const openChart = (site) => {
    fetchSamples(site);
    toggle();
  };

  const edit = (site, tabId = '1') => {
    const project_id = site.strata.round.area.property.project.id;
    const property_id = site.strata.round.area.property.id;
    const area_id = site.strata.round.area.id;
    const round_id = site.strata.round.id;
    const strata_id = site.strata.id;
    const site_id = site.id;

    const lnk = `/home/projects/${project_id}/properties/${property_id}/areas/${area_id}/rounds/${round_id}/stratas/${strata_id}/sites/${site_id}/${tabId}`;

    history.push(lnk);
  };

  const renderRows = (headers, site) => {
    let download = <div className="text-secondary">--</div>;
    if (site.scanned_at) {
      download = (
        <DirectDownload
          classNames="p-0"
          buttonType="primary"
          handleDownload={(onProgressChange) => handleDownload(site.id, onProgressChange)}
          showLabel={true}
          showIcon={true}
        />
      );
    }
    const scanned_at = moment.utc(site.scanned_at).local().fromNow();
    const spectra = site.scan_samples_exists ? (
      <Icon name="chart-line" className="mr-3 text-success" onClick={() => openChart(site)} />
    ) : (
      <div />
    );

    // Check if scannedby is set
    let scanned_by = '-';
    if (site.scannedby) {
      scanned_by = `${site.scannedby.firstname} ${site.scannedby.lastname}`;
    }

    // Build cea/round/strata name
    const strata = `CEA${site.strata.round.area.name}/${site.strata.round.name}/${site.strata.name}`;

    // Check file uploads
    const files = [
      { name: 'scan_samples', id: 'ss', exists: site.scan_samples_exists },
      { name: 'scan_params', id: 'pa', exists: site.scan_params_exists },
      { name: 'scan_spec_cal', id: 'sc', exists: site.scan_spec_cal_exists },
      { name: 'scan_gamma_cal', id: 'gc', exists: site.scan_gamma_cal_exists },
    ];

    const scan_validity = scanValidity(site, edit);

    // Set class styles for compression ratio
    let compressionClassName = 'text-success';
    const compression_ratio = site.compression_ratio.toFixed(2);
    if (site.compression_ratio < 0.85) {
      compressionClassName = 'text-warning';
    }
    if (site.compression_ratio < 0.45) {
      compressionClassName = 'text-light bg-warning';
    }
    if (site.compression_ratio > 1.02) {
      compressionClassName = 'text-danger';
    }

    let { stickup_height } = site;
    let stickup_height_bg = '';
    if (stickup_height === null) {
      stickup_height = 'Missing';
      stickup_height_bg = 'bg-danger text-white';
    }

    const tableTd = headers.map((header, index) => {
      const typeClassName = `${header.classes} ${
        site.type_key === 'T' ? 'text-success' : 'text-warning'
      }`;
      const scannedClassName = `${header.classes} ${
        site.scanned_at ? 'text-success' : 'text-danger'
      }`;

      switch (header.field) {
        case 'checkbox':
          return (
            <td key={index}>
              <Input type="checkbox" className="m-0 mt-1" onChange={handleSelect} value={site.id} />
            </td>
          );
        case 'core_key':
          return (
            <td key={index} className={header.classes}>
              {site.core_key}
            </td>
          );
        case 'type':
          return (
            <td key={index} className={typeClassName}>
              {site.type_key === 'T' ? 'Target' : 'Reserve'}
            </td>
          );
        case 'status_id':
          return (
            <td key={index} style={{ color: site.status.colour }}>
              {site.status.name}
            </td>
          );
        case 'scanned_at':
          return (
            <td key={index} className={scannedClassName}>
              {scanned_at}
            </td>
          );
        case 'scanned_by':
          return <td key={index}>{scanned_by}</td>;
        case 'corescanner_name':
          return <td key={index}>{site.corescanner?.name || '-'}</td>;
        case 'upload_status':
          return (
            <td key={index}>
              <UploadDots files={files} />
            </td>
          );
        case 'scan_valid':
          return <td key={index}>{scan_validity.message}</td>;
        case 'actual_depth':
        case 'core_length':
          return (
            <td key={index} className={`${header.classes} ${scan_validity.classes}`}>
              {site[header.field]}
            </td>
          );
        case 'strata':
          return (
            <td key={index} className={header.classes}>
              {strata}
            </td>
          );
        case 'scan_result':
          return (
            <td key={index} className={header.classes} style={{ cursor: 'pointer' }}>
              {download}
            </td>
          );
        case 'compression_ratio':
          return (
            <td key={index} className={`${header.classes} ${compressionClassName}`}>
              {compression_ratio}
            </td>
          );
        case 'stickup_height':
          return (
            <td key={index} className={`${header.classes} ${stickup_height_bg}`}>
              {stickup_height}
            </td>
          );
        default:
          return (
            <td key={index} className={header.classes}>
              {site[header.field]}
            </td>
          );
      }
    });

    tableTd.push(
      <td
        key={headers.length + 1}
        className="d-flex justify-content-center"
        style={{ cursor: 'pointer' }}
      >
        {spectra}
        <Icon name="pen-to-square" className="text-primary" onClick={() => edit(site)} />
      </td>,
    );
    return tableTd;
  };

  const headers = [
    { caption: '-', field: 'checkbox', classes: 'text-left' },
    { caption: 'Core Key', field: 'core_key', classes: 'text-left' },
    { caption: 'Cea/Round/Strata', field: 'strata', classes: 'text-left' },
    { caption: 'Scanned', field: 'scanned_at', classes: 'text-center' },
    { caption: 'By', field: 'scanned_by', classes: 'text-left' },
    { caption: 'Scanner', field: 'corescanner_name', classes: 'text-left' },
    { caption: 'Upload Status', field: 'upload_status', classes: 'text-center' },
    { caption: 'Validation', field: 'scan_valid', classes: 'text-left' },
    { caption: 'Scan Result', field: 'scan_result', classes: 'text-center' },
    { caption: 'Status', field: 'status_id', classes: 'text-left' },
    { caption: 'Type', field: 'type', classes: 'text-left' },
    { caption: 'T.Depth', field: 'target_depth', classes: 'text-right' },
    { caption: 'Stickup', field: 'stickup_height', classes: 'text-right' },
    { caption: 'A.Depth', field: 'actual_depth', classes: 'text-right' },
    { caption: 'C.Length', field: 'core_length', classes: 'text-right' },
    { caption: 'C.Ratio', field: 'compression_ratio', classes: 'text-right' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));
  tableHeadTh.push(<th key={headers.length + 1}></th>);

  let tableBodyTr = <tr></tr>;
  let rowsCount;
  if (rows && pagination) {
    rowsCount = rows.count;
    rows = rows.rows;
  }
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  const iconName = 'vial';
  const emptyCaption = 'No Sites found';

  return (
    <React.Fragment>
      <SiteSampleChartModal
        toggle={toggle}
        isOpen={isOpen}
        samples={samples}
        fetchSamples={fetchSamples}
        rows={rows}
        currentSite={currentSite}
      />
      <Listview
        rows={rows}
        rowsCount={rowsCount}
        onLoadMore={onLoadMore}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </React.Fragment>
  );
};

export default CoresLsv;
