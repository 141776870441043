import moment from 'moment';
import { noop } from 'lodash';
import Listview from '../../../../core/form/components/Listview';

const AdminScheduledJobLogsLsv = ({ rows, onClick = noop, darkTable }) => {
  const renderRows = (headers, record) => {
    let extraClass = '';
    // if (record.type_tag !== 'success') extraClass='text-danger';

    const tableTd = headers.map(({ caption, field, classes, sortColumn, styles }, index) => {
      switch (field) {
        case 'created': {
          return (
            <td key={index} className={`${classes} ${extraClass}`} data-sort-value={record[sortColumn]}>
              {moment(record.created).format('YYYY-MM-DD HH:mm:ss') }
            </td>
          );
        }

        default:
          return (
            <td key={index} className={`${classes} ${extraClass}`} styles={styles}>{record[field]}</td>
          );
      }

    });
    return tableTd;
  };

  const iconName = 'timer';
  const emptyCaption = 'No Logs found';

  const headers = [
    { caption: 'Created', field: 'created', classes: 'text-left', styles: {width: '200px' }},
    { caption: 'Message', field: 'message', classes: 'text-left' },
    { caption: 'Type', field: 'type_tag', classes: 'text-left', styles: {width: '100px' }}
  ];

  const tableHeadTh = headers.map(({ caption, classes, styles }, index) => (
    <th key={index} className={classes} styles={styles}>
      {caption}
    </th>
  ));

  let tableBodyTr = [];
  let finalRows = rows;
  if (rows?.rows) finalRows = rows.rows;

  const haveRows = finalRows && finalRows.length > 0;
  if (haveRows) {
    tableBodyTr = finalRows.map((row) => (
      <tr key={row.id} >
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={finalRows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
      darkTable={darkTable}
    />
  );
};

export default AdminScheduledJobLogsLsv;
