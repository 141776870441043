import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';

const PageTitleH5 = (props) => {
  const {
    title,
    iconName
  } = props;

  return (
    <div>
      <div className="d-flex flex-row m-1">    
        <h5 className="text-black">
          <Icon name={iconName} className="appForeTint mr-2" />
          {title}
        </h5>
      </div>
    </div>
  )
}

export default PageTitleH5;
