import React from 'react';
import { connect } from "react-redux";
import LandcareAnalyticsCell from "./LandcareAnalyticsCell";

import {
  Row,
  Col
} from 'reactstrap';

class LandcareAnalytics extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col sm={3}>
            <LandcareAnalyticsCell colour="success" fromText="Declining" toText="No Problem" title="Water Quality" question="What is the quality of water resources on your property?"/>
          </Col>
          <Col sm={3}>
            <LandcareAnalyticsCell colour="danger" fromText="More than 75%" toText="50-75%" title="Ground Cover Percent" question="What is the quality of water resources on your property?"/>
          </Col>
          <Col sm={3}>
            <LandcareAnalyticsCell colour="success" fromText="Static" toText="Decreasing" title="Woody weed Infestation" question=""/>
          </Col>
          <Col sm={3}>
            <LandcareAnalyticsCell colour="danger" fromText="Average" toText="Bad" title="Water Cycle State" question=""/>
          </Col>
          <Col sm={3}>
            <LandcareAnalyticsCell colour="success" fromText="Don't Know" toText="Increasing" title="Water Cycle Trend" question=""/>
          </Col>
          <Col sm={3}>
            <LandcareAnalyticsCell colour="success" fromText="Bad" toText="Average" title="Energy Flow State" question=""/>
          </Col>
          <Col sm={3}>
            <LandcareAnalyticsCell colour="success" fromText="Don't Know" toText="Static" title="Energy Flow Trend" question=""/>
          </Col>
          <Col sm={3}>
            <LandcareAnalyticsCell colour="success" fromText="Average" toText="Good" title="Mineral Flow" question=""/>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => {
    return {
      realm: store.realm,
      probes: store.probes
    }
  }
  
  export default connect(mapStoreToProps)(LandcareAnalytics);
  
