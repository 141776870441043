import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  property_id: {
    ...controlDefaults,
    caption: 'Property',
    fieldName: 'property_id',
    type: 'select',
    name: 'property_id',
    validationRules: {
      isRequired: true,
    },
  },
  estimated_ha: {
    caption: 'Estimated Area (ha)',
    fieldName: 'estimated_ha',
    type: 'number',
    name: 'estimated_ha',
    formattingRules: { includeCommas: true },
    validationRules: {},
  },
  open_date: {
    caption: 'Open Date',
    fieldName: 'open_date',
    name: 'open_date',
    type: 'date',
    validationRules: {
      isRequired: true,
    },
  },
  close_date: {
    caption: 'Close Date',
    fieldName: 'close_date',
    name: 'close_date',
    type: 'date',
    validationRules: {},
  },
};
