import React from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { noop } from 'lodash';

type Comment = {
  comment_createdby: {
    firstname: string;
    lastname: string;
    id: string;
  };
  created: string;
  comment: string;
  id: string;
  job_id: string;
};

interface CommentsProp {
  comments: Comment[];
  setEditMode: (job_id: string, id: string, comment: string) => void;
  currentUserId: string | null;
  onCommentDelete: (job_id: string, id: string) => void;
}

const Comments = ({
  comments = [],
  setEditMode = noop,
  currentUserId = null,
  onCommentDelete = noop,
}: CommentsProp) => {
  if (comments.length === 0) {
    return <div>No comments yet</div>;
  }

  return (
    <div
      style={{
        maxHeight: 'calc(70vh - 210px)',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {comments.map(({ comment_createdby, created, comment, id, job_id }) => (
        <div key={id} className="mt-1 bg-light rounded p-1">
          <Row>
            <Col className="d-flex justify-content-between border-bottom border-lightgray m-0 ml-1">
              <small className="font-weight-bold text-corporate">
                {comment_createdby.firstname} {comment_createdby.lastname}
              </small>
            </Col>
            <Col className="d-flex justify-content-end m-0 mr-1">
              <small className="font-weight-bold">
                {moment(created).format('YYYY-MM-DD HH:MM')}
              </small>
            </Col>
          </Row>
          <Row className="mt-1 p-1 pt-2 pb-2">
            <Col>{comment}</Col>
          </Row>
          {currentUserId === comment_createdby.id && (
            <Row className="mt-1">
              <Col className="ml-1">
                <small
                  role="presentation"
                  className="comment-link cursor-pointer text-primary"
                  onClick={() => setEditMode(job_id, id, comment)}
                  onKeyDown={() => setEditMode(job_id, id, comment)}
                >
                  Edit
                </small>
                <small
                  role="presentation"
                  className="ml-2 cursor-pointer text-danger"
                  onClick={() => onCommentDelete(job_id, id)}
                  onKeyDown={() => onCommentDelete(job_id, id)}
                >
                  Delete
                </small>
              </Col>
            </Row>
          )}
        </div>
      ))}
    </div>
  );
};

export default Comments;
