import { Component } from 'react';
import { Card, Alert, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import moment from 'moment';

import DirectDownload from 'jsx/components/core/form/components/DirectDownload';
import Icon from 'jsx/components/core/icons/Icon';
import { fetchDownload, downloadFile } from '../actions/downloads';

type DownloadMeta = {
  created?: string;
  name?: string;
};
interface RouteParams {
  id: string;
}
export interface DownloadAsyncReportProps extends RouteComponentProps<RouteParams> {
  dispatch: Dispatch;
  download_reports: Record<string, any>;
}
export interface DownloadAsyncReportState {
  downloadMeta?: DownloadMeta;
  failedMessage?: string;
  downloadId?: string;
}

class DownloadAsyncReport extends Component<DownloadAsyncReportProps, DownloadAsyncReportState> {
  state = {
    downloadMeta: {} as DownloadMeta,
    failedMessage: '',
    downloadId: '',
  };

  async componentDidMount() {
    const downloadId = this.props.match.params.id;
    this.props.dispatch<any>(fetchDownload(downloadId));
    this.setState({ downloadId });
  }

  render() {
    const { downloadId } = this.state;
    const { downloadFileMeta, failedMessage } = this.props.download_reports;

    return (
      <div className="h-100 bg-light">
        <Row style={{ height: '80px' }} className="bg-white m-0 border-bottom flex-0">
          <Col>
            <div className="p-3 pt-4 d-flex bg-white w-100">
              <Icon size="2x" name="download" className="text-corporate" />
              <h2 className="m-0 p-0 ml-2 text-corporate d-inline">
                Your file is ready to download
              </h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="p-5 m-3 text-center card">
              {(!downloadId || failedMessage) && (
                <Alert color="danger">
                  There was an error retrieving your file. Please re-check the link you were sent
                  and try again.
                  {failedMessage && <div className="mt-3">{failedMessage}</div>}
                </Alert>
              )}
              {downloadId && !failedMessage && (
                <>
                  <div>
                    <div>Your file, {downloadFileMeta?.filename}, is ready to download.</div>
                    <small>
                      {downloadFileMeta?.created &&
                        `Generated ${moment(downloadFileMeta.created).format('YYYY-MM-DD')}`}
                    </small>
                  </div>
                  <DirectDownload
                    classNames="mt-3"
                    caption="Download my file"
                    buttonType="primary"
                    handleDownload={async (onProgressChange: any) =>
                      // eslint-disable-next-line implicit-arrow-linebreak
                      this.props.dispatch<any>(downloadFile(downloadId, onProgressChange))
                    }
                    showIcon
                  />
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStoreToProps = ({ download_reports }: any) => ({ download_reports });
export default connect(mapStoreToProps)(DownloadAsyncReport);
