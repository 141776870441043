import Icon from 'jsx/components/core/icons/Icon';
import React from 'react';
import { connect } from "react-redux";
import { Collapse } from 'reactstrap';
import moment from 'moment';

import DirectDownload from '../../form/components/DirectDownload';
import FormBase from '../../form/components/FormBase';
import Listview from '../../form/components/Listview';

import { downloadPipelineFile } from '../actions';

class PipelinePanelFiles extends FormBase {
  constructor(props) {
    super(props)

    this.state = ({
      sectionsOpen: []
    })

    this.toggleSection = this.toggleSection.bind(this);

    this.renderHeaders = this.renderHeaders.bind(this);
    this.renderSection = this.renderSection.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  toggleSection(key) {
    const { sectionsOpen } = this.state;

    const idx = sectionsOpen.findIndex(section => section === key)
    if (idx > -1) delete sectionsOpen[idx];
    else sectionsOpen.push(key)

    this.setState({
      sectionsOpen
    })
  }

  renderHeaders() {
    const headers = [
      { caption: 'Name', field: 'key', classes: 'text-left' },
      { caption: 'Pipeline', field: 'pipeline_name', classes: 'text-left' },
      { caption: 'Download', field: 'download', classes: 'text-center' },
      { caption: 'Uploaded', field: 'created', classes: 'text-center' },
    ]

    return {
      headers,
      tableHeadTh: headers.map((header, index) =>
        <th key={index} className={header.classes}>{header.caption}</th>
      )
    }
  }

  renderSection(row) {

    const {
      sectionsOpen
    } = this.state;

    const isOpen = sectionsOpen.findIndex(section => section === row.key) > -1

    const {
      headers,
      tableHeadTh
    } = this.renderHeaders();

    const tableBodyTr = row.files.map((row, index) =>
      <tr key={index} style={{ cursor: 'pointer' }}>
        {this.renderRow(headers, row)}
      </tr>
    );

    return (<div className="border rounded bg-light mb-2">
      <div className="d-flex justify-content-between form-section border-bottom" onClick={() => this.toggleSection(row.key)}>
        <h5 className="m-0">{`${row.key ? row.key : 'Latest'}`}</h5>
        <small className="mt-1 mr-2"><span style={{ cursor: 'pointer' }} className="text-white" href="#">{`${isOpen ? 'Hide' : 'Show'}`}</span></small>
      </div>
      <Collapse isOpen={isOpen} className="p-2">
        <Listview rows={row.files} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} />
      </Collapse>
    </div>)
  }

  renderRow(headers, row) {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        case 'pipeline_name': return (
          <td className={header.classes} key={index}>{row.pipeline?.name ?? '-'}</td>
        )
        case "download": return (
          <td key={index} className={header.classes} style={{ cursor: 'pointer' }}>
            <DirectDownload
              classNames="p-0"
              buttonType="primary"
              handleDownload={async (onProgressChange) => await this.props.dispatch(downloadPipelineFile(row.id, onProgressChange))}
              showLabel={true}
              showIcon={true}
            />
          </td>
        )
        case 'created': return (
          <td className={header.classes} key={index}>
            {(row[header.field] ? moment(row[header.field]).format('YYYY-MM-DD hh:mm') : '-')}
          </td>
        )
        default: return (
          <td className={header.classes} key={index}>{row[header.field]}</td>
        )
      }
    });
    return tableTd;
  }

  render() {
    const {
      files
    } = this.props.pipelines;

    let sections = (<div />);
    const haveRows = (files && files.length > 0);
    if (haveRows) {
      sections = files.map((file) => this.renderSection(file));
    }

    const iconName = 'file';
    const emptyCaption = 'No pipeline files found for this round.';

    return (
      <React.Fragment>
        {sections}

        {!haveRows && (
          <div className="p-5 text-center">
            <div><Icon size="3x" name={iconName} className="text-corporate" /></div>
            <div className="mt-3">{emptyCaption}</div>
          </div>
        )}
      </React.Fragment>
    )
  }

}


const mapStoreToProps = (store) => {
  return {
    pipelines: store.pipelines
  }
}

export default connect(mapStoreToProps)(PipelinePanelFiles);