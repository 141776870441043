import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
    YAxis,
    Bar,
    Cell,
    Line,
    Scatter
  } from 'recharts';

import {
  updateWidgetDataset,
  selectChartCell
} from '../actions';

class ChartBase extends React.Component {
  constructor(props) {
    super(props)

    this.togglePane = this.togglePane.bind(this);
    this.onChangeWidgetDataset = this.onChangeWidgetDataset.bind(this);
    this.onUpdateWidgetDataset = this.onUpdateWidgetDataset.bind(this);
    this.onRemoveWidgetDataset = this.onRemoveWidgetDataset.bind(this);
    this.onAddWidgetDataset = this.onAddWidgetDataset.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectPosition = this.onSelectPosition.bind(this);
    this.renderBarLines = this.renderBarLines.bind(this);
  }

  componentDidMount() {
    const {
      margin,
      height,
      singleSelection
    } = this.props;

    if (margin) {
      this.setState({margin})
    }

    if (height) {
      this.setState({height})
    }

    if (singleSelection) {
      this.setState({singleSelection})
    }

    this.loadDatasets();
  }

  // Placeholder for Vertical and Horizontal charts
  async loadDatasets() {}

  togglePane() {
    this.setState({isPaneOpen: !this.state.isPaneOpen})
  }

  getRandomColor () {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getNextColour(index) {
    const {
      colours
    } = this.props.analytics;

    if (index > colours.length -1) {
      return this.getRandomColor();
    }
    return colours[index];
  }

  onSelect(core_id) {
    const {
      selectionKey
    } = this.props;
  
    const {
      singleSelection
    } = this.state;

    this.props.dispatch(selectChartCell(core_id, selectionKey, singleSelection));
    this.setState({reload: true})
  }

  onSelectPosition(position) {
    console.log("position", position)
    const depth = {
      upper: (position / 10) - 2.5,
      lower: (position / 10) + 2.5
    }

    this.props.dispatch({type: 'SET_DEPTH_FULLFILLED', payload: depth});
  }

  onAddWidgetDataset() {
    const {
      widget,
      group
    } = this.state;

    const availableDatasets = this.props.analytics.datasets[group];
    if (availableDatasets.length > 0) {
      widget.datasets.push({
        id: uuidv4(),
        sourceId: availableDatasets[0].id,
        data: availableDatasets[0].data,
        series: 'left',
        type: 'bar',
        colour: '#cc0000',
        rowName: availableDatasets[0].rowName,
        selected: availableDatasets[0].columns[0]
      })
    }

    this.setState({widget}, () => this.loadDatasets());
  }

  onRemoveWidgetDataset(id) {
    const {
      widget
    } = this.state;

    const idx = widget.datasets.findIndex(dataset => dataset.id === id);
    widget.datasets.splice(idx,1);

    this.setState({widget}, () => this.loadDatasets());
  }

  onUpdateWidgetDataset(event, dataset) {
    const {
      widget,
      group
    } = this.state;

    // Find dataset being updated within the widget.
    const datasetIdx = widget.datasets.findIndex(widgetDataset => widgetDataset.id === dataset.id)

    // Special handling for selected column
    if (event.target.name === 'selected') {
      // Find the availabledataset and list of columns for selected dataset
      // const availableDatasets = this.props.analytics.datasets[group]
      let availableDatasets = [];
      if (this.props.datasets && this.props.datasets.length > 0) {
        availableDatasets = this.props.datasets;
      } else {
        availableDatasets = this.props.analytics.datasets[group];
      }

      const currentDataset = availableDatasets.find(availableDataset => availableDataset.id === dataset.sourceId);
      const currentColumn = currentDataset.columns.find(column => column.name === event.target.value)

      widget.datasets[datasetIdx][event.target.name] = currentColumn;
    } else {
      widget.datasets[datasetIdx][event.target.name] = event.target.value;  
    }

    this.setState({widget}, () => this.loadDatasets());
    console.log(widget, dataset)
  }

  onChangeWidgetDataset(event, datasetId) {
    const {
      widgetId,
      group
    } = this.state;

    const widgetDatasetId = event.target.value;
    const availableDatasets = this.props.analytics.datasets[group]
    const selectedDataset = availableDatasets.find(availableDataset => availableDataset.id === widgetDatasetId);

    this.props.dispatch(updateWidgetDataset(widgetId, group, datasetId, selectedDataset))
  }

  tickFormatter(label) {
    return (String(label).substring(0,1) === '-' ? String(label).substring(1) : label)
  }

  renderAxis(series) {
    const {
      widget
    } = this.state;

    const yAxis = widget.datasets.filter(dataset => dataset.series === series);
    let unit = null;
    let colour = 'black';

    if (yAxis.length === 1) {
      unit = yAxis[0].selected.unit;
      colour = yAxis[0].colour;
    }

    return (
      <YAxis
        yAxisId={series}
        orientation={series}
        unit={unit}
        stroke={colour}
        name="Actual (%)"
        tickFormatter={this.tickFormatter}
        dataKey={widget.dataKeyY}
      />
    );
  }

  renderBarLines() {
    const {
      chartData,
      filterSelections,
      widget
    } = this.state;
  
    return widget.datasets.map((dataset, index) => {
      const selectedColumn = dataset.selected;
      const columnName = dataset.id;
      const series = dataset.series;

      switch (dataset.type) {
        case 'scatter':
          return (
            <Scatter
              aclassName="clickable"
              key={index}
              yAxisId={series}
              name={dataset.selected.name}
              data={dataset.data}
              fill={dataset.colour}
              unit={selectedColumn.unit}
            />
          )

        case 'stack': 
          const chartKeys = Object.keys(chartData[0]).filter(key => key !== 'name');
          const stack = chartKeys.map((chartKey, chartIndex) => {
            return (
              <Bar
                className="clickable"
                key={chartIndex}
                // name={selectedColumn.name}
                yAxisId={series}
                dataKey={chartKey}
                fill={this.getNextColour(chartIndex)}
                unit={selectedColumn.unit}
                stackId={dataset.stackId}
              >
              {/* {
                chartData.map((entry, index) => (
                  <Cell key={index} stroke={filterSelections.includes(chartData[index].name) ? '#000000' : this.getNextColour(chartIndex) }/>
                ))
              } */}
              </Bar>
            )
          })

          return stack;
        case 'bar': 
          return (
            <Bar
              className="clickable"
              key={index}
              name={selectedColumn.name}
              yAxisId={series}
              dataKey={columnName}
              fill={dataset.colour}
              unit={selectedColumn.unit}
            >
              {
                chartData.map((entry, index) => (
                  <Cell key={index} fill={filterSelections.includes(chartData[index].name) ? '#000000' : dataset.colour }/>
                ))
              }
            </Bar>
          )
        case 'line': 
          return (
            <Line className="clickable" key={index} name={selectedColumn.name} yAxisId={series} dataKey={columnName} stroke={dataset.colour} strokeWidth="2" unit={selectedColumn.unit}>
              {
                chartData.map((entry, index) => (
                  <Cell key={index} fill={filterSelections.includes(chartData[index].name) ? '#000000' : dataset.colour }/>
                ))
              }
            </Line>
          )
        default: break;
      }

      return false;
    });
  }

  render() {
     return (
      <React.Fragment></React.Fragment> 
    );
  }
}

export default ChartBase;
