import React from 'react';
import Listview from '../../../core/form/components/Listview';

const WidgetLsv = (props) => {
  const { rows } = props;

  const renderRows = (headers, record) => {
    const tableTd = headers.map((header, index) => {
      switch (header.field) {
        default:
          return (
            <td key={index} className={header.classes}>
              {record[header.field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const iconName = 'chart';
  const emptyCaption = 'No Widgets found';

  const headers = [
    { caption: 'Name', field: 'name', classes: 'text-left' },
    { caption: 'Value', field: 'value', classes: 'text-right' },
  ];

  const tableHeadTh = headers.map((header, index) => (
    <th key={index} className={header.classes}>
      {header.caption}
    </th>
  ));

  let tableBodyTr = <tr></tr>;
  let rowsCount;

  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    tableBodyTr = rows.map((row, index) => <tr key={index}>{renderRows(headers, row)}</tr>);
  }

  return (
    <div className="b">
      <Listview
        rows={rows}
        rowsCount={rowsCount}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
      />
    </div>
  );
};

export default WidgetLsv;
