import React from 'react';
import FormInput from '../../../core/form/components/FormInput';
import Select from 'react-select';
import { noop } from 'lodash';
import { Button, Row, Col, Label } from 'reactstrap';

const AssetReadingsInlineEntry = ({
  controls,
  handleChange = noop,
  onSave = noop,
  onClear = noop,
  onSelectChange = noop,
}) => {
  const options = controls?.asset_id?.options;

  const { label, value } = controls.asset_id;

  const handleClear = () => {
    controls.asset_id.value = '';
    controls.asset_id.label = '';
    onClear();
  };

  const handleSave = () => {
    onSave();
    handleClear();
  };

  const isSaveEnabled =
    controls.asset_id.value && controls.reading_date.value && controls.value.value ? false : true;

  return (
    <div className="bg-lightgray p-1 border-bottom border-primary">
      <Row className="m-0 p-0 align-items-end mb-1">
        <Col sm={3} className="mt-1">
          <Label className="">Select Asset</Label>
          <Select options={options} onChange={onSelectChange} value={{ label, value }} />
        </Col>
        <Col sm={3}>
          <FormInput handleChange={handleChange} control={controls.reading_date} />
        </Col>

        <Col sm={3}>
          <FormInput handleChange={handleChange} control={controls.value} />
        </Col>
        <Col sm={2} className="mb-1">
          <Button color="success" size="sm" onClick={handleSave} disabled={isSaveEnabled}>
            Add
          </Button>
          <Button color="link" size="sm" onClick={handleClear}>
            Clear
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AssetReadingsInlineEntry;
