import DirectUpload from 'jsx/components/core/form/components/DirectUpload';
import { noop } from 'lodash';
import { Button } from 'reactstrap';

const OverheadsToolbar = ({
  displayAddNewButton = true,
  onAddNew = noop,
  onImport = noop,
  filterClick = noop,
}) => (
  <div className="d-flex justify-content-end m-2">
    <DirectUpload
      caption="Import"
      size="sm"
      classNames="mr-2"
      handleUpload={onImport}
      schemaType="overheads"
    />
    {/* Conditionally render add new overhead button due to on-screen-data-entry feature flag. */}
    {displayAddNewButton && (
      <Button onClick={onAddNew} size="sm" color="success">
        Add Overhead
      </Button>
    )}
    <Button
      id="toggler"
      size="sm"
      color="warning"
      className="text-white ml-2"
      onClick={filterClick}
    >
      Filter
    </Button>
  </div>
);

export default OverheadsToolbar;
