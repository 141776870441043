import React, { Fragment } from 'react';
import {
  Button
} from 'reactstrap';
import PropertySearchLibrarySource from './PropertySearchLibrarySource';

const PropertySearchLibrarySources = (props) => {
  const {
    sources,
    addSource
  } = props;

  let librarySources = (<div className="text-center text-corporate p-2">No Library Sources found</div>)
  if (sources.length > 0) {
    librarySources = sources.map((source, index) => {
      return (<PropertySearchLibrarySource key={index} source={source} addSource={addSource} />);
    })
  }

  return (
    <Fragment>
      <div className="m-2 text-center">
        <Button size="sm" color='success' disabled={true}>Add External Library Source</Button>

        <div className="mt-2 text-left">
          {librarySources}
        </div>
      </div>

    </Fragment>

  );
}

export default PropertySearchLibrarySources;
